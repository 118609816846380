import React, { useEffect, useState } from "react";
import { formatAccountsNumber } from "../../../utils/UtilityFunctions";


const DefaultSummaryCorrection = (props) => {

    const { apiData } = props;

    const [data, setData] = useState([]);


    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    short_pay_def_amt: item[0],
                    short_pay_amt: item[1],
                    short_pay_payable: item[2],

                    short_coll_def_amt: item[3],
                    short_coll_amt: item[4],
                    short_coll_payable: item[5],

                    int_short_def_amt: item[6],
                    int_short_amt: item[7],
                    int_short_payable: item[8],

                    int_late_def_amt: item[9],
                    int_late_amt: item[10],
                    int_late_payable: item[11],

                    int_late_add_def_amt: item[12],
                    int_late_add_amt: item[13],
                    int_late_add_payable: item[14],                    

                    int_shrt_coll_def_amt: item[15],
                    int_shrt_coll_amt: item[16],
                    int_shrt_coll_payable: item[17],

                    int_late_coll_def_amt: item[18],
                    int_late_coll_amt: item[19],
                    int_late_coll_payable: item[20],

                    int_late_add_coll_def_amt: item[21],
                    int_late_add_coll_amt: item[22],
                    int_late_add_coll_payable: item[23],

                    short_pay_acc_def_amt: item[24],
                    short_pay_acc_amt: item[25],
                    short_pay_acc_payable: item[26],

                    short_pay_acc_add_def_amt: item[27],
                    short_pay_acc_add_amt: item[28],
                    short_pay_acc_add_payable: item[29],

                    interest_def_amt: item[30],
                    interest_amt: item[31],
                    interest_payable: item[32],
                    
                    payable: item[33],
                    rounding_off: item[34],
                    net_payable: item[35]
                })
            })
            return newData
        }
    };


    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);


    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab01" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">
                    <div className="card">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <table class="table table-striped exptds_justformtype_table mb-0">


                                <thead>
                                    <tr class="">
                                        <th width="10">
                                            Sr. No.
                                        </th>
                                        <th width="450">
                                            Type of Defaults
                                        </th>
                                        <th width="150">
                                            Default Amount(Rs.)
                                        </th>
                                        <th width="250">
                                            Amount reported as ‘Interest/ Others’ claimed in the statement(Rs.)
                                        </th>
                                        <th width="100">
                                            Payable(Rs.)
                                        </th>
                                    </tr>
                                    <tr class="" style={{borderTop: '1px solid black'}}>
                                        <th width="10">
                                            1
                                        </th>
                                        <th width="450">
                                            2
                                        </th>
                                        <th width="150">
                                            3
                                        </th>
                                        <th width="250">
                                            4
                                        </th>
                                        <th width="100">
                                            5
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">1</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Short Payment ">
                                                Short Payment

                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">2</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Short Payment ">
                                                Short Collection

                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_coll_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_coll_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_coll_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">3</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title=" Interest on payments default u/s 206C(7)">
                                                Interest on payments default u/s 206C(7)

                                            </span>
                                        </td>
                                        <td class="text-right">

                                        </td>
                                        <td class="text-right">

                                        </td>
                                        <td class="text-right">

                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(a)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Interest on short payment">
                                                Interest on short payment
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(b)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Interest on late payment">
                                                Interest on late payment
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(c)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Additional late payment interest against the processing of latest correction">
                                                Additional late payment interest against the processing of latest correction
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_add_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_add_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_add_payable) ?? 0}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">4</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Interest on collection default u/s 206C(7)">
                                                Interest on collection default u/s 206C(7)
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            
                                        </td>
                                        <td class="text-right">
                                            
                                        </td>
                                        <td class="text-right">
                                            
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(a)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Interest on short collection">
                                                Interest on short collection
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_shrt_coll_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_shrt_coll_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_shrt_coll_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(b)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Interest on late collection">
                                                Interest on late collection
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_coll_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_coll_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_coll_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(c)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Additional late collection interest against the processing of latest correction">
                                                Additional late collection interest against the processing of latest correction
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_add_coll_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_add_coll_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_add_coll_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">5</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Short Payment on account of Late Filing Fee u/s 234E">
                                            Short Payment on account of Late Filing Fee u/s 234E
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            
                                        </td>
                                        <td class="text-right">
                                            
                                        </td>
                                        <td class="text-right">
                                            
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(a)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Short Payment on account of Late Filing Fee ">
                                                Short Payment on account of Late Filing Fee 
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_acc_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_acc_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_acc_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(b)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Additional Short Payment on account of Late Filing Fee against the processing of latest correction">
                                                Additional Short Payment on account of Late Filing Fee against the processing of latest correction
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_acc_add_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_acc_add_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_acc_add_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">6</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Interest u/s 220(2)">
                                                Interest u/s 220(2)
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.interest_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.interest_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.interest_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="4" class="text-right">
                                            Payable(Rs.)
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.payable) ?? 0}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right">
                                            Rounding-Off(to the nearest multiple of ten rupees)(Rs.)
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.rounding_off) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="4" class="text-right">
                                            Net Payable(Rs.)
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.net_payable) ?? 0}
                                        </td>
                                    </tr>


                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default DefaultSummaryCorrection;