/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { BASE_URL, BASE_URL_GO } from "../app/constants";
import {
  handleSuccess,
  handleError,
  handleErrorWithCode, getFirstError
} from "./responseHandler";
import { getConfig } from "./common";
import { API_BASE_URL_GO } from "../config";


export async function getDeducteesV2(data, set_header = true) {
  const url = `${API_BASE_URL_GO}/api/v1/deductee/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data, set_header);
  } catch (err) {
    throw handleError(err);
  }
}

export async function getCorrectionDeducteesV1(data) {
  const url = `${BASE_URL_GO}/api/v1/correction/deductee/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleError(err);
  }
}

export async function getDeducteeDetails(getParams, headerUpdate = true) {
  const url = `${BASE_URL_GO}/api/v1/deductee/details${getParams}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }
    if (headerUpdate) {
      return handleSuccess(deductorData.data);
    } else {
      return deductorData.data;
    }
  } catch (err) {
    throw handleError(err);
  }
}

export async function getCorrectionDeducteeDetails(getParams) {
  const url = `${BASE_URL_GO}/api/v1/correction/deductee/details${getParams}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }
    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getDeducteesAutocomplete(getParams) {
  const url = `${BASE_URL}/api/v1/deductee/autocomplete${getParams}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getCorrectionDeducteesAutocomplete(getParams) {
  const url = `${BASE_URL_GO}/api/v1/correction/transaction/autocomplete${getParams}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function addDeductee(data) {
  const url = `${API_BASE_URL_GO}/api/v1/deductee`;

  try {
    const deductorData = await axios.post(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function addCorrectionDeductee(data) {
  const url = `${BASE_URL_GO}/api/v1/correction/deductee`;

  try {
    const deductorData = await axios.post(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function updateDeductee(data) {
  const url = `${API_BASE_URL_GO}/api/v1/deductee`;

  try {
    const deductorData = await axios.put(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function updateCorrectionDeductee(data) {
  const url = `${BASE_URL_GO}/api/v1/correction/deductee`;

  try {
    const deductorData = await axios.put(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function excelDownloadTemplateV2() {
  const url = `${BASE_URL_GO}/api/v1/deductee/excel-deductee-upload-template`;

  try {
    const config = { ...getConfig() };
    config.responseType = "blob";
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function exportDeductee(deductor_id) {
  const url = `${BASE_URL_GO}/api/v1/deductee/export-excel?deductor_id=${deductor_id}`;

  try {
    const config = { ...getConfig() };
    config.responseType = "blob";
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function importDeductees(data) {
  const url = `${BASE_URL_GO}/api/v1/deductee/import-deductee`;

  try {
    const config = { ...getConfig(), "Content-Type": "multipart/form-data" };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function exportDeducteeTdsTcs(getParams) {
  const url = `${BASE_URL_GO}/api/v1/deductee/contact/export${getParams}`;

  try {
    const config = { ...getConfig() };
    config.responseType = "blob";
    const result = await axios.get(url, config);
    return result;
  } catch (err) {
    throw handleError(err);
  }
}

export async function importDeducteesTdsTcs(data) {
  const url = `${BASE_URL_GO}/api/v1/deductee/contact/import`;

  try {
    const config = { ...getConfig(), "Content-Type": "multipart/form-data" };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadPANTemplate() {
  const url = `${BASE_URL_GO}/api/v1/pan-verification/traces/download-pan-template`;

  try {
    const config = { ...getConfig() };
    config.responseType = "blob";
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function deducteeMasterSummary(data) {
  const url = `${BASE_URL_GO}/api/v1/deductee/list-summary`;

  try {
    const deductorData = await axios.post(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw deductorData.data.message;
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}


export async function getImportDeducteeStatus(deductorId) {
  const url = `${BASE_URL_GO}/api/v1/deductee/import-deductee-status?deductor_id=${deductorId}`;
  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return handleSuccess(deductorData.data);
  } catch (err) {
    throw handleError(err);
  }
};

export async function downloadDeducteeImportErrorFile(params) {
  const url = `${BASE_URL_GO}/api/v1/deductee/download-error-file${params}`;

  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    return result;
  } catch (err) {
    let errorString = ""
    if (
      err.request.responseType === 'blob' &&
      err.response.data instanceof Blob &&
      err.response.data.type &&
      err.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      errorString = getFirstError(JSON.parse(await err.response.data.text()));
    } else {
      errorString = handleError(err);
    }
    throw errorString;

  }
}
