import React, { useEffect } from 'react';
import { IS_COBRAND, MAINTENANCE_MESSAGE_TO_SHOW } from '../app/constants';
import './index.css';

const Maintenance = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="maintenance_page">
      {!IS_COBRAND ? (
      <span className="backtohomeclr">
        <a href="https://www.expresstds.com"> &lt; Back to Home </a>
      </span> ): null}
      <div className="mainwrapp">
        <h4 className="maintoptitle">Under Maintenance</h4>
        <h5 className="subtitll">We'll Be Back Soon!</h5>
        <div className="animationBox">
          <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_r0tkeuzo.json"
            background="transparent" speed="1.5" style={{ width: "500px", height: "500px" }} loop autoplay></lottie-player>
        </div>


        <p className="bootomtext" dangerouslySetInnerHTML={{ __html: MAINTENANCE_MESSAGE_TO_SHOW }}></p>

      </div>
    </div>
  );
}

export default Maintenance;