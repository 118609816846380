/*eslint-disable no-useless-escape */
import React from 'react';

const nPattern = new RegExp(/^[-]?([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)$/);

const NegativeNumericInputWithDecimalTwo = (props) => {
    const { value, onChange, ...rest } = props;
    return (
        <input
            value={value}
            onChange={(e) => {
                if (nPattern.test(e.target.value) || e.target.value === '' || (e.target.value === '-' && e.target.value.length === 1)) {
                    onChange(e);
                }
            }}
            onKeyUp={(e) => e.target.value}
            {...rest}
        />
    );
};

export default NegativeNumericInputWithDecimalTwo;
