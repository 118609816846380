import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CustomCell } from '../../../components/grid/CustomCell';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import Skeleton6ColumnGrid from '../../../components/Skeleton/Skeleton6ColumnGrid';
import { getDeducteesTransactionsMismatchReports } from '../../../api/deducteeTransactionsAPI';
import $ from 'jquery';
import Spinner from 'react-bootstrap/Spinner';

const LateInterestPopup = (props) => {
  const { returnId, deductorId, onClose, sectionData, totalPopups, index = 0, filterKey, sectionKey } = props;

  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [transactionsList, setTransactionsList] = useState([]);
  const [dataState, setDataState] = useState({});
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  let limit = 100;

  const [prevPage, setPrevPage] = useState(0);
  const [isScroll, setIsScroll] = useState(false);

  const getTransactionData = async (filterData = dataState) => {

    let request = {
      deductor_id: deductorId,
      return_id: returnId,
      limit: limit,
      page: cursor?.next_page ?? 1
    };

    setPrevPage(request?.page);

    if (filterData.page) {
      request.page = filterData.page;
    }

    if (filterData.filter) {
      request.filters = filterData?.filter?.filters;
    }

    if (filterData?.sort && filterData.sort.length > 0) {
      request.sort_on = filterData.sort[0].field;
      request.sort_by = filterData.sort[0].dir;
    }


    try {
      request.additional_filter = filterKey;

      if (prevPage !== cursor?.next_page) { setShowMoreLoading(true); }

      const response = await getDeducteesTransactionsMismatchReports(request);

      if (response?.data?.cursor?.current_page > 1) {
        setTransactionsList(transactionsList.concat(response?.data?.data ?? []));
      } else {
        setTransactionsList(response?.data?.data ?? []);
      }

      setCursor(response?.data?.cursor);

    } catch (err) {
      console.error(err);
    } finally {
      setShowSkeleton(false)
      setShowMoreLoading(false);
    }
  };

  const dataStateChange = (dataState) => {
    setDataState(dataState);
    getTransactionData(dataState);
    setIsScroll(false);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };


  useEffect(() => {
    if (returnId && deductorId) {
      getTransactionData()
    }

    // eslint-disable-next-line
  }, [returnId, deductorId])

  useEffect(() => {
    if (isScroll && cursor?.next_page > 1) {
      getTransactionData()
    }
    // eslint-disable-next-line
  }, [isScroll])


  const rowRender = (trElement, props,) => {
    const data = props.dataItem;
    const rowCounter = trElement._owner.index;

    return (
      <tr data-index={rowCounter} role="row">
        <td className={'text-left'}>{data?.deductee_name ?? '-'}</td>
        <td className={`text-right`}>{formatNumber(data?.total_tds_amtp ?? 0)}</td>
        {
          sectionKey === 'LATE_DEDUCTION_INTEREST'
            ?
            <>
              <td className={`text-center`}>
                {
                  data?.payment_date ?? '-'
                }
              </td>
              <td className="text-center">
                {
                  data?.deduction_date ?? '-'
                }
              </td>
            </>
            :
            <>
              <td className={`text-center`}>
                {
                  data?.deduction_date ?? '-'
                }
              </td>
              <td className="text-center">
                {
                  data?.due_date_payment ?? '-'
                }
              </td>
              <td className="text-center">
                {
                  data?.challan_deposit_date && data?.challan_deposit_date !== '' ? data?.challan_deposit_date :  '-'
                }
              </td>
            </>
        }

        <td className="text-center">{data?.default_months ?? '-'}</td>
        <td className="text-right">{formatNumber(sectionKey === 'LATE_DEDUCTION_INTEREST' ? (data?.late_tds_interest_amtp ?? 0) : (data?.late_payment_interest_amtp ?? 0))}</td>
      </tr>
    )
  }

  const getScrollbarHeight = (el) => {
    return el.scrollHeight
  };
  $(function () {
    $(".k-grid-content").scroll(function () {
      if (getScrollbarHeight(document.querySelector('.k-grid-content')) < $(".k-grid-content").scrollTop() + $(".k-grid-content").height() + 3) {
        if (!isScroll && cursor?.next_page > 1) {
          setIsScroll(true);
        }
      }
    });
  });


  return (
    <>
      <WindowWrapperFixed
        title={
          <h4 className="modal-title w-100">
            <div class="row col-md-12">
              <div class="col-md-7">
                {sectionData?.title}
              </div>
              <div class="col-md-5">
                <span class="latededuct_amtfs">
                  {formatNumber(sectionData?.amt ?? 0)}
                </span>
              </div>
            </div>
          </h4>
        }
        onClose={onClose}
        totalPopups={totalPopups}
        index={index}
        isModalView={true}
        initialHeight={'auto'}
        initialLeft={150}
        className={`compu-health-late-deduction-interest-data`}
      >

        <div className="row card mb-2">
          <div className="col-md-12">

            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
              {
                showSkeleton ? <Skeleton6ColumnGrid />
                  :
                  <>
                    <Tooltip anchorElement="pointer" position="right">
                      <Grid
                        className="table table-striped comp_health_late_deduc_popup Deducteetrans_deducteewise_tb mb-0"
                        style={{maxHeight: '445px', overflowY: "auto" }}
                        data={transactionsList}
                        {...dataState}
                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                        filterOperators={{
                          text: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                          ],
                          numeric: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                          ],
                          date: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                          ],
                          textWithEmpty: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                          ],
                          dropdown: [
                            { text: 'grid.filterContainsOperator', operator: 'in' },
                          ],
                          pan: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterContainsOperator', operator: 'in' },
                          ],
                          challan: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                          ],
                        }}
                        sortable={{
                          allowUnsort: true,
                        }}
                        rowRender={rowRender}
                      >

                        <Column
                          width={200}
                          field="deductee_name"
                          headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                          title={<CustomCell title={'Deductee Name'} field="deductee_name" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="textWithEmpty"
                          columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                        />
                        <Column
                          width={150}
                          field="total_tds_amtp"
                          headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                          title={<CustomCell title={'TDS Deducted'} field="total_tds_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="numeric"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        {/* <Column
                          width={150}
                          field="payment_date"
                          headerClassName={isColumnActive('payment_date') ? 'active' : ''}
                          title={<CustomCell title={'Payment Date'} field="payment_date" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="date"
                          columnMenu={(props) => <ColumnMenu {...props} />} 
                        />*/}
                        <Column
                          width={150}
                          field={sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'payment_date' : 'deduction_date'}
                          headerClassName={isColumnActive(sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'payment_date' : 'deduction_date') ? 'active' : ''}
                          title={<CustomCell title={sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'Payment Date' : 'Deduction Date'} field={sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'payment_date' : 'deduction_date'} dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="date"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        {/* <Column
                          width={150}
                          field="deduction_date"
                          headerClassName={isColumnActive('deduction_date') ? 'active' : ''}
                          title={<CustomCell title={'Deduction Date'} field="deduction_date" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="date"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        /> */}
                        <Column
                          width={150}
                          field={sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'deduction_date' : 'due_date_payment'}
                          headerClassName={isColumnActive(sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'deduction_date' : 'due_date_payment') ? 'active' : ''}
                          title={<CustomCell title={sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'Deduction Date' : 'Due Date'} field={sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'deduction_date' : 'due_date_payment'} dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="date"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        {
                          sectionKey === 'LATE_DEPOSIT_INTEREST' && 
                          <Column
                          width={150}
                          field="challan_deposit_date"
                          headerClassName={isColumnActive('challan_deposit_date') ? 'active' : ''}
                          title={<CustomCell title={'Deposit Date'} field="challan_deposit_date" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="date"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        }
                        <Column
                          width={150}
                          field="default_months"
                          headerClassName={isColumnActive('default_months') ? 'active' : ''}
                          title={<CustomCell title={'Default Months'} field="default_months" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="numeric"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        <Column
                          width={150}
                          field={sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'late_tds_interest_amtp' : 'late_payment_interest_amtp'}
                          headerClassName={isColumnActive(sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'late_tds_interest_amtp' : 'late_payment_interest_amtp') ? 'active' : ''}
                          title={<CustomCell title={'Interest Amount'} field={sectionKey === 'LATE_DEDUCTION_INTEREST' ? 'late_tds_interest_amtp' : 'late_payment_interest_amtp'} dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="numeric"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />

                        <GridNoRecords>
                          No Record Found
                        </GridNoRecords>

                      </Grid>
                    </Tooltip>

                    {showMoreLoading && (
                      <span style={{
                        position: 'absolute',
                        left: '50%',
                        top: '-20px',
                      }}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    )}

                  </>
              }
            </div>
          </div>
        </div>
      </WindowWrapperFixed>

    </>

  );
};

export default LateInterestPopup;
