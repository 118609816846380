import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_PATH, FORM_3CD_START_YEAR } from "../../app/constants";
import './form3CDTimeline.scss';


const Form3CDTimeLine = (props) => {
    const { currentPage, getPan, getFinYear } = props;
    const paramPan = getPan ? getPan : "";
    const paramFy = getFinYear ? getFinYear : FORM_3CD_START_YEAR;




    return (
        <div className="form3cdrem_timelinesection_sticky mt-1">

        
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="tds_form3CDRevamp_timeline">
                            <Link
                                className="btn btn-default tds_form3CDRevamp_backbtn"
                                to={`${BASE_PATH}list/recent`}>&lt; Back to Deductor Master
                            </Link>
                            <div className="btn-group btn-group-md tds_form3CDRevamp_btngrpbox tds_form3CDRevamp_box01">
                                <Link className={`tds_form3CDRevamp_linkbox ${currentPage === "ListAllDeductors" ? "active" : ""}`}
                                    to={`${BASE_PATH}form3CD/incomplete-deductors?pan=${paramPan}&fy=${paramFy}`}>
                                    <span className={`tds_form3CDRevamp_nobox ${currentPage === "ListAllDeductors" ? "active" : ""}`}>

                                        <span className="no_textfs ">1</span>
                                    </span>
                                    <span className={`tds_form3CDRevamp_innertital  ${currentPage === "ListAllDeductors" ? "active" : ""}`}>TAN Wise PAN Info</span>
                                </Link>
                                <span className="tds_form3CDRevamp_timeline_midborder timelinemid_boder01"></span>
                            </div>
                            <div className="btn-group btn-group-md tds_form3CDRevamp_btngrpbox tds_form3CDRevamp_box02">
                                {paramPan ? <Link className={`tds_form3CDRevamp_linkbox ${currentPage === "returnSummary" ? "active" : ""}`}
                                    to={`${BASE_PATH}form3CD/return-summary?pan=${paramPan}&fy=${paramFy}`}>
                                    <span className={`tds_form3CDRevamp_nobox ${currentPage === "returnSummary" ? "active" : ""}`}>
                                        <span className="no_textfs ">2</span>
                                    </span>
                                    <span className={`tds_form3CDRevamp_innertital  ${currentPage === "returnSummary" ? "active" : ""}`}>TAN - Form - Quarter Summary</span>
                                </Link>
                                    :
                                    <div className="tds_form3CDRevamp_linkbox" >
                                        <span className="tds_form3CDRevamp_nobox">
                                            <span className="no_textfs ">2</span>
                                        </span>
                                        <span className="tds_form3CDRevamp_innertital ">TAN - Form - Quarter Summary</span>
                                    </div>
                                }
                                <span className="tds_form3CDRevamp_timeline_midborder timelinemid_boder02"></span>
                            </div>
                            <div className="btn-group btn-group-md tds_form3CDRevamp_btngrpbox tds_form3CDRevamp_box04">

                                {paramPan ? <Link className={`tds_form3CDRevamp_linkbox ${currentPage === "report34a" ? "active" : ""}`}
                                    to={`${BASE_PATH}form3CD/report-clause-34a?pan=${paramPan}&fy=${paramFy}`}>
                                    <span className={`tds_form3CDRevamp_nobox  ${currentPage === "report34a" ? "active" : ""}`}>
                                        <span className="no_textfs">3</span>
                                    </span>
                                    <span className={`tds_form3CDRevamp_innertital  ${currentPage === "report34a" ? "active" : ""}`}>Clause 34(a)</span>
                                </Link> :
                                    <div className="tds_form3CDRevamp_linkbox" >
                                        <span className="tds_form3CDRevamp_nobox">
                                            <span className="no_textfs ">3</span>
                                        </span>
                                        <span className="tds_form3CDRevamp_innertital ">Clause 34(a)</span>
                                    </div>
                                }
                                <span className="tds_form3CDRevamp_timeline_midborder timelinemid_boder04"></span>
                            </div>
                            <div className="btn-group btn-group-md tds_form3CDRevamp_btngrpbox tds_form3CDRevamp_box05">
                                {paramPan ? <Link className={`tds_form3CDRevamp_linkbox ${currentPage === "report34b" ? "active" : ""}`}
                                    to={`${BASE_PATH}form3CD/report-clause-34b?pan=${paramPan}&fy=${paramFy}`}>
                                    <span className={`tds_form3CDRevamp_nobox ${currentPage === "report34b" ? "active" : ""}`}>
                                        <span className="no_textfs ">4</span>
                                    </span>
                                    <span className={`tds_form3CDRevamp_innertital  ${currentPage === "report34b" ? "active" : ""}`}>Clause 34(b)</span>
                                </Link> :
                                    <div className="tds_form3CDRevamp_linkbox" >
                                        <span className="tds_form3CDRevamp_nobox">
                                            <span className="no_textfs ">4</span>
                                        </span>
                                        <span className="tds_form3CDRevamp_innertital ">Clause 34(b)</span>
                                    </div>
                                }
                                <span className="tds_form3CDRevamp_timeline_midborder timelinemid_boder05"></span>
                            </div>
                            <div className="btn-group btn-group-md tds_form3CDRevamp_btngrpbox tds_form3CDRevamp_box06">
                                {paramPan ? <Link className={`tds_form3CDRevamp_linkbox ${currentPage === "report34c" ? "active" : ""}`}
                                    to={`${BASE_PATH}form3CD/report-clause-34c?pan=${paramPan}&fy=${paramFy}`}>
                                    <span className={`tds_form3CDRevamp_nobox  ${currentPage === "report34c" ? "active" : ""}`}>
                                        <span className="no_textfs">5</span>
                                    </span>
                                    <span className={`tds_form3CDRevamp_innertital  ${currentPage === "report34c" ? "active" : ""}`}>Clause 34(c)</span>
                                </Link>
                                    :
                                    <div className="tds_form3CDRevamp_linkbox" >
                                        <span className="tds_form3CDRevamp_nobox">
                                            <span className="no_textfs ">5</span>
                                        </span>
                                        <span className="tds_form3CDRevamp_innertital ">Clause 34(c)</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form3CDTimeLine;
