import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { Alert } from 'react-bootstrap';
import { addReturn } from '../../../api/returnAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


const CreateManuallyPopup = (props) => {
    const { handleClose, show, financialYear, deductorId, setNewReturnId, setImportFilePopup, updateFilingData, alreadyCreatedReturn, indiviualQuarterSync, successProceed } = props;

    const [createReturn, setCreateReturn] = useState({ return_type: "REGULAR", quarter: "Q1", form_type: "24Q" });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (name, value) => {

        createReturn[name] = value;
        setCreateReturn({ ...createReturn });

    }

    const CreateReturnOnServer = async () => {
        setLoading(true);
        const payload = {
            deductor_id: deductorId,
            form_type: createReturn?.form_type ?? "",
            quarter: createReturn?.quarter ?? "",
            return_type: createReturn?.return_type ?? "",
            year: financialYear

        }
        const ifAlreadyfilled_return = createReturn.return_type === "CORRECTION" ? true : false;

        try {
            const result = await addReturn(payload, ifAlreadyfilled_return);
            setNewReturnId(result.data.return_id ?? "");
            if (createReturn.return_type === "REGULAR") {
                indiviualQuarterSync({return_id: result.data.return_id});
                successProceed();

            } else if (createReturn.return_type === "CORRECTION") {
                setImportFilePopup(true);
                updateFilingData();
            }
            handleClose()

        } catch (err) {
            if (err?.status === 409 && createReturn.return_type === "CORRECTION") {
                alreadyCreatedReturn(createReturn);
            } else {
                if (err.message) setError(err.message);
                else setError(err);
            }


        } finally {
            setLoading(false);
        }
    }




    return (
        <>

            {loading && <LoadingIndicator />}

            <Modal className="deductordashboadscss StartFiling_modalbox Createnewreturn_modalwidth" backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton className="StartFiling_modalbox_header">
                    <Modal.Title className="gst_verifyyremailid_title" >{`Create Return ${financialYear}-${financialYear + 1}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="StartFiling_craetenewret_frmbox">
                                <div className="row">
                                    <div className="col-md-4 pr-0">
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-label" for="radio2">Return Type</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 crret_type">
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-inline" for="radio2">
                                                    <input type="radio" className="form-check-input" name="return_type"
                                                        value="REGULAR"
                                                        checked={createReturn.return_type === "REGULAR"}
                                                        onChange={(e) => {
                                                            handleChange("return_type", "REGULAR");
                                                        }}
                                                    />Regular
                                                </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="form-check-label" for="radio2">
                                                    <input type="radio" className="form-check-input" name="return_type"
                                                        value="CORRECTION"
                                                        checked={createReturn.return_type === "CORRECTION"}
                                                        onChange={(e) => {
                                                            handleChange("return_type", "CORRECTION");
                                                        }}
                                                    />
                                                    <span style={{ top: "0px", position: "absolute", display: "inline-flex" }}>Correction </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4 pr-0">
                                        <div className="form-group">
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label" for="radio2">Quarter</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 crretquarter" >
                                        <div className="form-group">
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label return-flex-center" for="radio2">
                                                    <input type="radio" className="form-check-input"
                                                        name="quarter"

                                                        checked={createReturn.quarter === "Q1"}
                                                        onChange={(e) => {
                                                            handleChange("quarter", "Q1");
                                                        }}
                                                    />
                                                    <span className="createnewret_radioalign">Q1</span>
                                                </label>
                                            </div>
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label return-flex-center" for="radio2">
                                                    <input type="radio" className="form-check-input" name="quarter"
                                                        checked={createReturn.quarter === "Q2"}
                                                        onChange={(e) => {
                                                            handleChange("quarter", "Q2");
                                                        }}
                                                    />
                                                    <span className="createnewret_radioalign">Q2</span>
                                                </label>
                                            </div>
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label return-flex-center" for="radio2">
                                                    <input type="radio" className="form-check-input" name="quarter"
                                                        checked={createReturn.quarter === "Q3"}
                                                        onChange={(e) => {
                                                            handleChange("quarter", "Q3");
                                                        }}
                                                    />
                                                    <span className="createnewret_radioalign">Q3</span>
                                                </label>
                                            </div>
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label return-flex-center" for="radio2">
                                                    <input type="radio" className="form-check-input" name="quarter"
                                                        checked={createReturn.quarter === "Q4"}
                                                        onChange={(e) => {
                                                            handleChange("quarter", "Q4");
                                                        }}
                                                    />
                                                    <span className="createnewret_radioalign">Q4</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 pr-0" style={{ marginTop: "6px" }}>
                                        <div className="form-group">
                                            <label className="form-check-label" for="sel3">Form Type</label>
                                        </div>
                                    </div>
                                    <div className="col-md-8 pl-0">
                                        <div className="form-group">
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label return-flex-center" for="radio2">
                                                    <input type="radio" className="form-check-input"
                                                        checked={createReturn.form_type === "24Q"}
                                                        onChange={(e) => {
                                                            handleChange("form_type", "24Q");
                                                        }}
                                                    />
                                                    <span className="createnewret_radioalign">24Q</span>
                                                </label>
                                            </div>
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label return-flex-center" for="radio2">
                                                    <input type="radio" className="form-check-input"
                                                        checked={createReturn.form_type === "26Q"}
                                                        onChange={(e) => {
                                                            handleChange("form_type", "26Q");
                                                        }}
                                                    />
                                                    <span className="createnewret_radioalign">26Q</span>
                                                </label>
                                            </div>
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label return-flex-center" for="radio2">
                                                    <input type="radio" className="form-check-input"
                                                        checked={createReturn.form_type === "27Q"}
                                                        onChange={(e) => {
                                                            handleChange("form_type", "27Q");
                                                        }}
                                                    />
                                                    <span className="createnewret_radioalign">27Q</span>
                                                </label>
                                            </div>
                                            <div className="form-check-inline mt-2">
                                                <label className="form-check-label return-flex-center" for="radio2">
                                                    <input type="radio" className="form-check-input"
                                                        checked={createReturn.form_type === "27EQ"}
                                                        onChange={(e) => {
                                                            handleChange("form_type", "27EQ");
                                                        }}
                                                    />
                                                    <span className="createnewret_radioalign">27EQ</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {error
                                    ? (
                                        // <Alert variant="danger" style={{fontSize: "12px"}} onClose={() => setError('')} dismissible>{error}</Alert>
                                        <div style={{ color: 'red', fontSize: '11px', marginBottom: '5px' }}>
                                            <FontAwesomeIcon icon={faExclamationCircle} />
                                            {' '}
                                            {error}
                                        </div>
                                    )
                                    : null}
                                {success
                                    ? (
                                        <Alert variant="success" style={{ fontSize: "12px" }} onClose={() => setSuccess('')}>{success}</Alert>
                                    )
                                    : null}

                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <div className="createnewret_footerbtnbox">
                                            <button className="btn btn-default createnewret_submitbtn" onClick={() => CreateReturnOnServer()}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateManuallyPopup