/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import './FileUploadPopupV3.scss';
import { importReturnData } from '../../../api/returnAPI';
import ConfirmPopup from '../../../components/ConfirmPopup';
import { MAX_IMPORT_FILE_SIZE_MB } from '../../../app/constants';
import { formatBytes } from '../../../utils/UtilityFunctions';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import exportIcon from '../../../images/icons/export_icon.png';
import importIcon from '../../../images/icons/import_icon.png';
import excelIcon from '../../../images/excelicon.png';
import handIcon from '../../../images/handicon.png';
import $ from 'jquery';

const FileUploadPopupV3 = ({ history, ...props }) => {
    const {
        handleClose, handleProceed, deductorId, returnId, importExelError, downloadExcelTemp = null, showFromErrorReImport
    } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [files, setFiles] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [overWriteData, setOverWriteData] = useState(false);
    const [buttonClick, setButtonClick] = useState(false);
    const ConfirmMsgAppend = "You have chosen to append the existing data with new data.";
    const ConfirmMsgOverWrite = "You have chosen to overwrite the existing data with new data. This action will delete the existing data and add new data.";

    const [uploadedFileType, setUploadedFileType] = useState(null);

    const {
        isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject,
    } = useDropzone({
        accept: '.txt,.xls,.xlsx,.xlsm',
    });
    let JSZip = require("jszip");

    const removeFile = (targetFile) => {
        const newFiles = [];
        files.map((file) => {
            if (file.path !== targetFile.path) {
                newFiles.push(file);
            }
            return true;

        });
        setFiles(newFiles);
    };



    const renderFiles = files.map((file) => (
        <li key={file.path} style={{ margin: '5px' }}>
            <span style={{ float: 'left' }}>
                {file.path}
                {' '}
                -
                {formatBytes(file.size)}
            </span>
            <Button
                variant="danger"
                style={{
                    fontSize: '12px', padding: '0px', paddingLeft: '5px', paddingRight: '5px', marginLeft: '10px', float: 'right'
                }}
                onClick={() => { removeFile(file); setButtonClick(false) }}
            >
                Remove
            </Button>
        </li>
    ));

    const uploadFiles = async () => {
        setShowConfirm(false);
        setLoading(true);

        const formData = new FormData();

        if (uploadedFileType !== 'txt') {

            files.map((file) => {
                formData.append('file', file);
                formData.append('deductor_id', deductorId);
                formData.append('return_id', returnId);
                formData.append('created_at', moment(file.lastModified).format('YYYY-MM-DD'));
                formData.append('error_on_insuff_unconsumed_amt', false);
                return true;
            });
        }

        if (uploadedFileType === 'txt') {

            formData.append('deductor_id', deductorId);
            formData.append('return_id', returnId);
            formData.append('created_at', moment(files[0].lastModified).format('YYYY-MM-DD'));
            formData.append('error_on_insuff_unconsumed_amt', false);

            let ddtZip = new JSZip();

            for (let file of files) {
                let filename = file.name;
                ddtZip.file(`${filename}`, file, { binary: true });
            }

            const ddtZipBlob = await ddtZip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions: { level: 0 } })
            formData.append('file', ddtZipBlob, "deductorFile.zip");
            if (ddtZipBlob.size / (1024 * 1024) > MAX_IMPORT_FILE_SIZE_MB) {
                setLoading(false);
                return setError(`Max size of the file that can be uploaded is ${MAX_IMPORT_FILE_SIZE_MB} MB.`);
            }

        }

        if (overWriteData) {
            formData.append('overwrite', true);
        }

        try {
            const result = await importReturnData(formData);
            handleProceed(result);
        } catch (err) {
            if (err.status === '0' && err.errors && Object.keys(err.errors).length > 0) {
                importExelError(err.errors);
            } else if (err.errors && Object.keys(err.errors).length > 0) {
                setError(Object.values(err.errors));
            } else {
                setError(err.message);
            }
        }
        setLoading(false);
    };

    const getFileExt = (filename) => {
        let fileExt = '';
        if (filename) {
            const FileData = String(filename).split('.');
            if (FileData.length) {
                fileExt = FileData[FileData.length - 1];
            }
        }
        return fileExt;
    }

    useEffect(() => {
        const newFiles = acceptedFiles;
        const uniqueFiles = [];
        const flags = [];
        const l = newFiles.length;
        let i;
        for (i = 0; i < l; i++) {
            if (flags[newFiles[i].path]) continue;
            flags[newFiles[i].path] = true;
            uniqueFiles.push(newFiles[i]);

        }

        setOverWriteData(false);
        const fileExt = getFileExt(uniqueFiles[0]?.name ?? uniqueFiles[0]?.path);
        setUploadedFileType(fileExt);
        if (fileExt === 'txt') {
            setOverWriteData(true);
        }

        setFiles(uniqueFiles);
    }, [acceptedFiles]);

    const showConfirmPopup = () => {
        if (files[0].size / (1024 * 1024) > MAX_IMPORT_FILE_SIZE_MB) {
            return setError(`Max size of the file that can be uploaded is ${MAX_IMPORT_FILE_SIZE_MB} MB.`);
        }
        setShowConfirm(true);
    };

    $('#append').click(function () {
        $(this).removeClass('balnktemplate_btnbgclr_append').addClass('balnktemplate_btnbgclr_coral');
        $('#over').addClass('balnktemplate_btnbgclr_over');
        $('#over').removeClass('balnktemplate_btnbgclr_coral');
    });

    $('#over').click(function () {
        $(this).removeClass('balnktemplate_btnbgclr_over').addClass('balnktemplate_btnbgclr_coral');
        $('#append').addClass('balnktemplate_btnbgclr_append');
        $('#append').removeClass('balnktemplate_btnbgclr_coral');
    });


    $('#onlyover').click(function () {
        $(this).removeClass('balnktemplate_btnbgclr_over').addClass('balnktemplate_btnbgclr_coral');
    });


    return (
        <>
            <ConfirmPopup
                show={showConfirm}
                handleProceed={() => {
                    uploadFiles()
                }}
                confirmText={overWriteData ? ConfirmMsgOverWrite : ConfirmMsgAppend}
                confirmButtonText="Proceed"
                handleClose={() => {
                    setShowConfirm(false);
                }}
                changeSecondBtnText="Change Selection"
                changeBtnColor={true}
            />
            <WindowWrapperFixed
                title={<kendo-dialog-titlebar>
                    <h4 className='my-3' style={{ fontSize: "16px" }}>
                        {'Import Return From Excel or TXT'}
                    </h4>
                </kendo-dialog-titlebar>}
                onClose={handleClose}
                totalPopups={0}
                initialTop={50}
                index={1}
                className="Deductee_trans_importmodal"
                initialHeight={'auto'}
                style={{ maxHeight: '540px', overflowX: 'hidden' }}
                initialWidth={475}
                initialLeft={0}
                isModalView={true}
            >
                <div className="modal-body">
                    <div className="row">
                        {downloadExcelTemp && uploadedFileType !== 'txt' &&
                            <div className="col-md-12">
                                <span className="Deductee_trans_importmodal_iconbox pr-2">
                                    <img src={exportIcon} width="18" alt='export icon' />
                                </span>
                                <button className="btn btn-default balnktemplate_textclr" onClick={() => downloadExcelTemp('blank')} title='Download Blank Template'>
                                    Blank Template
                                </button>
                                <span className="Deductee_trans_importmodal_Ortextfs">
                                    OR
                                </span>
                                <button className="btn btn-default balnktemplate_textclr" onClick={() => downloadExcelTemp('filled')} title='Download Filled Template'>
                                    Pre-Filled Template
                                </button>
                            </div>
                        }
                        {files?.length <= 0 &&
                            <div className="col-md-12 pt-4">
                                <span className="Deductee_trans_importmodal_iconbox pr-2">
                                    <img src={importIcon} width="18" alt='import icon' />
                                </span>
                                <button className="btn btn-default balnktemplate_dragdropfilebtnbgclr" {...getRootProps({ className: 'fileuppopup_dropzone' })} style={{ paddingLeft: '20px' }}>
                                    <input {...getInputProps({ accept: '.txt,.xls,.xlsx,.xlsm', multiple: false })} />
                                    {!isDragActive && 'Drag \'n\' drop files here, or click to select files'}
                                    {isDragActive && !isDragReject && 'Drop files here'}
                                    {isDragReject && 'File type not accepted, sorry!'}
                                </button>

                            </div>
                        }
                        {files?.length > 0 &&
                            <div className="col-md-12 pt-3">
                                <span className="Deductee_trans_importmodal_iconbox pr-2">
                                    <img src={excelIcon} width="18" alt='excel icon' />
                                </span>
                                <button className="btn btn-default balnktemplate_filenamebtn">
                                    <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
                                </button>
                            </div>
                        }

                        {uploadedFileType === 'txt'
                            ?
                            <div className="col-md-12 pt-3">
                                <span className="Deductee_trans_importmodal_iconbox pr-2">
                                    <img src={handIcon} width="18" alt='hand icon' />
                                </span>
                                <button id='onlyover' className={`btn btn-default ${files.length > 0 ? 'balnktemplate_btnbgclr_over' : 'balnktemplate_btnbgclr_grey'} ${showFromErrorReImport || files.length === 0 ? "disabled" : ""}`}
                                    onClick={() => { setOverWriteData(true); setButtonClick(true) }}
                                >
                                    Overwrite
                                </button>
                            </div>
                            :
                            <div className="col-md-12 pt-3">
                                <span className="Deductee_trans_importmodal_iconbox pr-2">
                                    <img src={handIcon} width="18" alt='hand icon' />
                                </span>
                                <button id='append' className={`btn btn-default ${files.length > 0 ? 'balnktemplate_btnbgclr_append' : 'balnktemplate_btnbgclr_grey'} ${files.length === 0 ? "disabled" : ""}`}
                                    onClick={() => { setOverWriteData(false); setButtonClick(true) }}
                                >
                                    Append
                                </button>
                                <span className="Deductee_trans_importmodal_Ortextfs">
                                    OR
                                </span>
                                <button id='over' className={`btn btn-default ${files.length > 0 ? 'balnktemplate_btnbgclr_over' : 'balnktemplate_btnbgclr_grey'} ${showFromErrorReImport || files.length === 0 ? "disabled" : ""}`}
                                    onClick={() => { setOverWriteData(true); setButtonClick(true) }}
                                >
                                    Overwrite
                                </button>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-12 pt-3">
                            <p className="importexcel_textfs_style mb-2">
                                <span style={{ color: '#fc7d72' }}> Append</span> adds the new data from the file to the existing data.
                            </p>
                        </div>
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-12">
                            <p className="importexcel_textfs_style mb-2">
                                <span style={{ color: '#fc7d72' }}> Overwrite</span>  will first delete all the transactions and salary records (if any),
                                and then import the records from the file.
                            </p>
                        </div>

                        <div className="col-md-12">
                            <p className="importexcel_notetextfs_clr mb-2">
                                Note: New challans in the file will only be appended. Existing challan data will NOT be over-written.
                            </p>
                        </div>
                        <div className="col-lg-12 text-center pt-3">
                            {error
                                ? (
                                    <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>
                                )
                                : null}
                            <button class="btn  btn-default Deductee_trans_importmodal_submitbnt" onClick={showConfirmPopup} disabled={loading || (files.length === 0 || buttonClick !== true)}>
                                {loading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Loading...
                                    </>
                                ) : 'Submit'}
                            </button>
                            <button className="btn  btn-default Deductee_trans_importmodal_calbtn ml-2" onClick={() => handleClose()}>Cancel</button>
                        </div>
                    </div>


                </div>

            </WindowWrapperFixed>
        </>
    );
};

export default FileUploadPopupV3;
