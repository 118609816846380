import React from 'react'
import { formatNumber } from '../../../utils/UtilityFunctions'

const ViewDeduction = (props) => {
    const { data } = props;

    return (
        <>
            <tr className="">
                <th width="10">

                </th>
                <th className="text-left">
                    Less : Deduction u/s 16
                </th>
                <th className="text-right">
                    {formatNumber(data?.total_deduction_amtp ?? 0)}
                </th>

            </tr>

            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                        <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">

                            <tbody>
                                <tr className="">
                                    <td width="45"></td>
                                    <td width="550" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop">
                                        Standard Deduction

                                    </td>
                                    <td width="150" className="text-left salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright">
                                        {formatNumber(data?.standard_deduction_amtp ?? 0)}
                                    </td>

                                </tr>

                                <tr className="">
                                    <td width=""></td>

                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft ">
                                        Ent Allowance
                                    </td>
                                    <td width="" className="text-left salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright">
                                        {formatNumber(data?.ent_allowance_amtp ?? 0)}
                                    </td>


                                </tr>

                                <tr className="">
                                    <td width=""></td>

                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom">
                                        Profession Tax
                                    </td>
                                    <td width="" className="text-left salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom salarytaxreg_innertb_borderright">
                                        {formatNumber(data?.professional_tax_amtp ?? 0)}
                                    </td>

                                </tr>



                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewDeduction