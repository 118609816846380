/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

// -- Validation Rules
export const raiseRequestFormPopupValidationSchema = Yup.object().shape({

  request_type: Yup.array()
    .of(
      Yup.string().required('This field is required'),
    ),

  ft: Yup.string()
    .required('This field is required'),

  fy: Yup.string()
    .required('This field is required'),

  quarter: Yup.string()
    .required('This field is required'),

  token: Yup.string()
    .min(15, 'PRN is invalid.')
    .required('This field is required'),

  bsr_code: Yup.string()
    .when('form1input', {
      is: (val) => val === '1',
      then: Yup.string()
        .required('This field is required')
        .matches(/[0-9]{7}$/, 'Invalid BSR Code'),
    }),

  bsr_code2: Yup.string()
    .when('form2input', {
      is: (val) => val === '1',
      then: Yup.string()
        .required('This field is required')
        .matches(/[0-9]{7}$/, 'Invalid Receipt Number'),
    }),
  challan_no: Yup.number()
    .when('form1input', {
      is: (val) => val === '1',
      then: Yup.number()
        .min(1, 'Too Short!')
        .max(99999, 'Too Long!')
        .required('This field is required'),
    }),

  challan_amount: Yup.number()
    .when('form1input', {
      is: (val) => val === '1',
      then: Yup.number()
        .min(0, 'Too Short!')
        .required('This field is required'),
    }),

  deposit_date: Yup.string()
    .when('form1input', {
      is: (val) => val === '1',
      then: Yup.string().trim().required('This field is required'),
    }),

  deposit_date2: Yup.string()
    .when('form2input', {
      is: (val) => val === '1',
      then: Yup.string().trim().required('This field is required'),
    }),
  transfer_voucher_amount: Yup.number()
    .when('form2input', {
      is: (val) => val === '1',
      then: Yup.number()
        .required('This field is required'),
    }),

  government_deductor_ddo_number: Yup.string()
    .when('form2input', {
      is: (val) => val === '1',
      then: Yup.string().trim().required('This field is required'),
    }),


  pan_no: Yup.array()
    .of(
      Yup.string()
        .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number').nullable(),
    ),

  // pan_no: Yup.array()
  // .of(
  //   Yup.string()
  //     .when('form4input', {
  //       is: (val) => val === '1',
  //       then: Yup.string()
  //         .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number').nullable(),
  //     }),
  // ),

  // .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
  // .min(3, 'Minimum of 3 friends'),

  // tax_deducted: Yup.array()
  //   .of(
  //     Yup.string()
  //       .when('form4input', {
  //         is: (val) => val === '1',
  //         then: Yup.string().required('Tax Deducted Amount is required'),
  //       }),

  //   ),

  // tax_deducted: Yup.array()
  //   .of(
  //     Yup.string().required('Tax Deducted Amount is required'),
  //   ),

  // captcha: Yup.string().required('captcha is required'),
});
