import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDeductorEfilingCredentials, importChallanFromIncomeTaxPortal } from '../../api/deductorAPI';
import { verifyEFilingCredsFromConnector, importedChallansFromConnector } from '../../api/localConnectorAPI';
import { selectHeaderData } from '../header/headerSlice';
import ShowAlert from '../../components/ShowAlert';
import UpdateEFilingCredentialForImportChallan from './UpdateEFilingCredentialForImportChallan';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

export default function EFilingConnectorForImportChallan(props) {

  const { handleProceed, deductorId, returnId, port } = props;
  
  const headerDataDetails = useSelector(selectHeaderData);
  const [error, setError] = useState(false);
  const [showValidationFailPopup, setShowValidationFailPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchTracesCredentials = (deductorId) => {
    
    async function fetchEverything() {
      setLoading(true);
      try { 
        const result = await getDeductorEfilingCredentials(`?deductor_id=${deductorId}`, false);
    
        const verifyLoginData = {
              'itdportal_username': result.data.itdportal_username ?? '',
              'itdportal_password': result.data.itdportal_password ?? ''
            }
       
        const loginResult = await verifyEFilingCredsFromConnector(port, verifyLoginData);        
   
        const importChallanRequestData = {
          "deductor_id": deductorId,
          "captcha_ref": loginResult.data.captcha_ref ?? '',              
        }
        
        try{
            
          const loginVerified = await importChallanFromIncomeTaxPortal(importChallanRequestData);

          let extraHeader = {};
          const LoginVerifiedResult = loginVerified.data 
          const responseHeader = loginVerified.headers ?? null;                

          const connectorRequest = {
            "data" : LoginVerifiedResult.data
          };
          
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null; 
          
          await importedChallansFromConnector(port, connectorRequest, extraHeader);
          
          //everything ok
          handleProceed();

        }catch(err) {
            console.error('1 error: ', err);
            const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message;
            setError(errmsg);
        }

      } catch (err) {  
        console.error('2 error: ', err);

        if (err.code === 424 || err.message?.[0] === 'itefil.invalid_password') { 
          //Show form to update Income Tax Login Credentials
          setShowValidationFailPopup(true);

        } else {
          const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message;
          setError(errmsg);            
        }
      }finally{
        setLoading(false);
      } 
    }
    
    fetchEverything();
  };
  
  useEffect(() => {
      fetchTracesCredentials(deductorId);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductorId]);  

  return (
  <>
    {loading && <LoadingIndicator />  }
    {error &&
      <ShowAlert
        error={error}
        onClose={() => setError(null)}
      />
    }
    
    {showValidationFailPopup &&
        <UpdateEFilingCredentialForImportChallan
          show={showValidationFailPopup}
          handleClose={() => setShowValidationFailPopup(false)}
          deductorId={deductorId ?? ''}
          returnId={returnId ?? ''}
          tan={headerDataDetails.tan ?? ''}
          port={port ?? 0}
          handleProceed={() => {
            handleProceed()
          }}
        />
    }    
  </>

  )
}