import { useEffect } from 'react';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../../app/constants';
import { getLdcDataForTransaction } from '../../../api/serverConnectorAPI';
import { importLdcListFromPortal } from '../../../api/localConnectorAPI';
const VerifyLdcCertificiate = (props) => {
    const { deductorId, returnId, port, captchaRef, setError, setSuccess, getLdcDataForReturn } = props;
    useEffect(() => {

        const bulkLdcVerification = (deductorId, returnId, port, captchaRef, setError) => {
            async function fetchEverything() {
                try {
                    const data = {
                        deductor_id: deductorId,
                        captcha_ref: captchaRef,
                        return_id: returnId
                    }

                    const resultData = await getLdcDataForTransaction(data);
                    const responseData = resultData.data ?? null;
                    const decodeResponse = JSON.parse(atob(responseData?.data))
                    if (decodeResponse?.ldc_verify_list && decodeResponse?.ldc_verify_list.length > 0) {
                        const responseHeader = resultData.headers ?? null;
                        //Connector request data
                        const connectorRequest = {
                            "data": responseData?.data
                        };
                        let extraHeader = {};
                        extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                        try {
                            await importLdcListFromPortal(port, connectorRequest, extraHeader);
                            setSuccess("LDC Verification has been started")
                            setTimeout(() => {
                                getLdcDataForReturn(deductorId, returnId)
                            }, 1500 * decodeResponse.ldc_verify_list.length);

                        } catch (err) {
                            setError(CONNECTOR_ERRORS_MAPPING[err.message]);
                        }
                    } else {
                        setError("No Records Found")
                    }

                } catch (err) {
                    setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
                }
            }
            fetchEverything();
        };

        bulkLdcVerification(deductorId, returnId, port, captchaRef, setError);

        //eslint-disable-next-line
    }, [deductorId, returnId, port, captchaRef]);


    return (null);
};

export default VerifyLdcCertificiate;
