import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CustomCell } from '../../../components/grid/CustomCell';
import { formatNumber } from '../../../utils/UtilityFunctions';
//import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { gocallApi } from '../../../api/issueCertificateAPI';

const ConsumptionTablePopup = (props) => {
  const { onClose, returnId, deductorId, finanicalYear, certData, setError } = props;
  
  const [data, setData] = useState([]);
  const [dataState, setDataState] = useState({});

  const getComsumptionDetails = async (returnId, deductorId, certData) => {

    const url = `/api/v1/report/health/ldc/consumption-details?deductor_id=${deductorId}&return_id=${returnId}&certificate_no=${certData?.certi_no}&section=${certData?.nature_of_payment_code}&financial_year=${finanicalYear}`;
    
    try {
      const result = await gocallApi("get", url);
      const data = result?.data?.consumption_details ?? [];
      setData(data);
    } catch (err) {
      console.error(err);
      if (err.message) setError(err.message);
      else setError(err);
    } finally {
      //setShowSkeleton(false);
    }
  }

  const dataStateChange = (dataState) => {
    setDataState(dataState);
  }

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };


  useEffect(() => {
    getComsumptionDetails(returnId, deductorId, certData);
    // eslint-disable-next-line
  },[returnId, deductorId, certData])


  const rowRender = (trElement, props,) => {
    const data = props.dataItem;
    const rowCounter = trElement._owner.index;
    return (
      <tr data-index={rowCounter} role="row">
        <td className={'text-center'}>{data?.formtype ?? '-'}</td>
        <td className={`text-center`}>{data?.quarter ?? '-'}</td>
        <td className={`text-center`}>{formatNumber(data?.consamt ?? 0)}</td>        
      </tr>
    )
  }


  return (
    <>      

      <WindowWrapperFixed       
        title={<kendo-dialog-titlebar>         
          <h6 className="modal-title">
              <span className="challanno_textfs"> <span>Consumption Details of Certificate: </span><span className="challan_numtextfsclr">{certData?.certi_no ?? ''}</span></span> 
              <span className="challanno_textfs"> <span>for Section: </span><span className="challan_numtextfsclr toCapital">{certData?.nature_of_payment_code ?? ''}-{`(${String(certData?.nature_of_payment ?? '')?.toLowerCase() ?? ''})`}</span></span>
              <br />
              <span className="challanno_textfs"> <span>Certificate Amount: </span><span className="challan_numtextfsclr">{formatNumber(Number(certData?.consumed_amtp ?? 0) + Number(certData?.uncomsumed_amtp ?? 0))}</span></span> |
              <span className="challanno_textfs"> <span>Total Consumed: </span><span className="challan_numtextfsclr">{formatNumber(certData?.consumed_amtp ?? 0)}</span></span> | 
              <span className="challanno_textfs"> <span>Remaining Cert Amount: </span><span className="challan_numtextfsclr">{formatNumber(certData?.uncomsumed_amtp ?? 0)}</span></span>
          </h6>
      </kendo-dialog-titlebar>}
        onClose={onClose}
        totalPopups={1}
        index={1}
        isModalView={true}
        initialHeight={'auto'}
        initialLeft={150}
        initialTop={40}
        className={`compu-health-late-deduction-interest-data`}
      >

        <div className="row card mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <div className="col-md-12">
            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
              {
                <>
                  <Tooltip anchorElement="pointer" position="right">
                    <Grid
                      className="table table-striped comp_health_late_deduc_popup Deducteetrans_deducteewise_tb mb-0"
                      style={{ maxHeight: '410px', overflowY: "auto" }}
                      {...dataState}
                      onDataStateChange={(event) => dataStateChange(event.dataState)}
                      data={data}
                      fixedScroll={true}
                      filterOperators={{
                        text: [
                          { text: 'grid.filterContainsOperator', operator: 'contains' },
                        ],
                        numeric: [
                          { text: 'grid.filterGteOperator', operator: 'gte' },
                          { text: 'grid.filterLteOperator', operator: 'lte' },
                          { text: 'grid.filterLtOperator', operator: 'lt' },
                          { text: 'grid.filterGtOperator', operator: 'gt' },
                          { text: 'grid.filterEqOperator', operator: 'eq' },
                          { text: 'grid.filterNotEqOperator', operator: 'neq' },
                        ],
                        date: [
                          { text: 'grid.filterGteOperator', operator: 'gte' },
                          { text: 'grid.filterLteOperator', operator: 'lte' },
                          { text: 'grid.filterLtOperator', operator: 'lt' },
                          { text: 'grid.filterGtOperator', operator: 'gt' },
                          { text: 'grid.filterEqOperator', operator: 'eq' },
                        ],
                        textWithEmpty: [
                          { text: 'grid.filterContainsOperator', operator: 'contains' },
                          { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                        ],
                        dropdown: [
                          { text: 'grid.filterContainsOperator', operator: 'in' },
                        ],
                        pan: [
                          { text: 'grid.filterContainsOperator', operator: 'contains' },
                          { text: 'grid.filterContainsOperator', operator: 'in' },
                        ],
                        challan: [
                          { text: 'grid.filterContainsOperator', operator: 'contains' },
                          { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                          { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                        ],
                      }}
                      sortable={{
                        allowUnsort: false,
                      }}
                      rowRender={rowRender}
                    >

                      <Column
                        field="formtype"
                        headerClassName={isColumnActive('formtype') ? 'active action' : 'action'}
                        title={<CustomCell title={'Form Type'} field="formtype" dataStateChange={dataStateChange} dataState={dataState} />}
                        filter="textWithEmpty"
                        sortable={false}
                        //columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                      />
                      <Column
                        field="quarter"
                        headerClassName={isColumnActive('quarter') ? 'active action' : 'action'}
                        title={<CustomCell title={'Quarter'} field="quarter" dataStateChange={dataStateChange} dataState={dataState} />}
                        filter="textWithEmpty"
                        sortable={false}
                        //columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                      />
                      <Column
                        field="consamt"
                        headerClassName={isColumnActive('consamt') ? 'active action' : 'action'}
                        title={<CustomCell title={'Consumed Amount'} field="consamt" dataStateChange={dataStateChange} dataState={dataState} />}
                        filter="numeric"
                        sortable={false}
                        //columnMenu={(props) => <ColumnMenu {...props} />}
                      />                      

                      <GridNoRecords>
                        No Record Found
                      </GridNoRecords>

                    </Grid>
                  </Tooltip>                 

                </>
              }
            </div>
          </div>         
        </div>
      </WindowWrapperFixed>

    </>

  );
};

export default ConsumptionTablePopup;
