/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './FailImportChallanList.css';

const FailImportChallanList = (props) => {
  const {
    show, handleClose, failImportChallanListErrors, openImportPopUp,
  } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="importdatapupm"
      >
        <Modal.Header closeButton className=" pb-0 pt-1">
          <Modal.Title className="dataimports tclrf">Data Import Failed </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="container">
            <div className="row">
              <div className="col-md-12 justify-content-center">
                <div className="wefoundtext tdif">
                  <p>We found below errors while importing the return</p>
                </div>
                <div className="kdk_data_table impdeductortbl" style={{ minHeight: '350px' }}>
                  <table className="table table-striped table-responsive impotdatafaildtb">
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Error</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(failImportChallanListErrors).map((error, key) => (

                        <tr key={key}>
                          <td>{error}</td>
                          <td>
                            {failImportChallanListErrors[error]}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex justify-content-center">
                  <Button className="btn btn-default retry  pb-1" onClick={openImportPopUp}>Retry</Button>

                </div>
              </div>

            </div>
          </div>

        </Modal.Body>

      </Modal>
    </>
  );
};

export default FailImportChallanList;
