/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import FileUploadPopup from './FileUploadPopup';

const ImportFUVFile = (props) => {
  const {
    show, handleClose, deductorId, returnId, handleProceedImportFUVFile, importFUVFileData
  } = props;
  const [, setShowFileUpload] = useState(true);
  const [, setShowResult] = useState(false);
  const [, setResult] = useState({ data: [] });

  if (show) {
    return (
      <>
        <FileUploadPopup
          key="1"
          show={show}
          handleClose={() => {
            handleClose();
          }}
          handleProceed={(res) => {
            setResult(res);
            setShowFileUpload(false);
            setShowResult(true);
          }}
          deductorId={deductorId}
          returnId={returnId}
          importFUVFileData={importFUVFileData}
          handleProceedImportFUVFile={handleProceedImportFUVFile}
        />
      </>
    );
  }

  return null;
};

export default ImportFUVFile;
