import React from 'react'
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';
import { ALLOWANCEEXEMPT_DROPDOWN } from '../salaryHelper';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const AllowancesDetails = (props) => {
    const {
        data, headingCodeListAllowance, calcIcon, deleteIcon, USER_TYPE, allowanceObj, handleArrayObjADD, handleArrayNumberInputOnBlur,
        showCalcPopupsfunc, handleArrayTypeInputChange, drowdownList, handleArrayObjDel, taxRegime
    } = props;

     // Calculate the total of the amt key in an array of objects.
     const calctotalArray = (calcData, drpdwnObj) => {

        if (calcData?.length > 0) {
            const total = calcData.reduce((prev, curr) => {
                if (drpdwnObj?.has(curr?.allowance_type_id)) {
                    prev += convertNum(curr.received_amtp) - convertNum(curr.exempt_amtp);
                }
                return prev;
            }, 0);
            return FormatNumberDecimal(total);
        } else {
            return 0;
        }

    };

    return (
        <>
            <tr className="">
                <td width=""></td>
                <td id="accordion" className="accordion text-left salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom">
                    <span className="salarytaxregime_edit_collapse collapsed mr-1" data-toggle="collapse" href="#salarytaxregime_edit_collap04" aria-expanded="false"></span>
                    Allowances
                </td>
                <td className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom">
                    <BlurOnChangeInput
                        type="text"
                        className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                        value={calctotalArray(data.allowances ?? [], headingCodeListAllowance)}
                        data-toggle="tooltip" data-placement="top" title={""}
                        placeholder=""
                    />
                </td>

            </tr>

            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_collap04" className="in collapse">
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                    <thead>
                        <tr>
                            <th width="38"></th>
                            <th width="200" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop">Particulars</th>
                            <th width="15" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop"></th>
                            <th width="100" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop">Paid to employee</th>
                            <th width="100" className="salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_bordertop">Exempted</th>
                            <th width="100" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_bordertop">Taxable</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.allowances?.length > 0 &&
                            data?.allowances.map((item, index) => {

                                if (!headingCodeListAllowance?.get(item?.allowance_type_id)) return null;

                                return (
                                    <tr className="salarytaxregime_edit_alternetbgclr_grey" key={index}>
                                        <td width=""></td>
                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft`}>
                                            {headingCodeListAllowance?.get(item?.allowance_type_id)?.description ?? ""}
                                        </td>
                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell`}>
                                            {
                                                headingCodeListAllowance?.get(item?.allowance_type_id)?.calc_id
                                                && <img src={calcIcon} alt="calculator icon" width="16" className="cursorpointer"
                                                    onClick={() => {
                                                        showCalcPopupsfunc
                                                            (headingCodeListAllowance?.get(item?.allowance_type_id)?.calc_id, index,
                                                                "allowances", "allowance_details", item.allowance_details)
                                                    }} />
                                            }
                                        </td>
                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell`}>
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                value={FormatNumberDecimal(item.received_amtp ?? "")}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                onChange={(e) => {
                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                    if (!item.exempt_amtp) handleArrayTypeInputChange("allowances", index, "exempt_amtp", 0);
                                                    handleArrayTypeInputChange("allowances", index, "received_amtp", value);
                                                }}
                                                onBlur={(e) => handleArrayNumberInputOnBlur("allowances", index, "received_amtp", item.received_amtp)}
                                            />
                                        </td>
                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell`}>
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                value={FormatNumberDecimal(item.exempt_amtp ?? "")}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                onChange={(e) => {
                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                    handleArrayTypeInputChange("allowances", index, "exempt_amtp", value);
                                                }}
                                                onBlur={(e) => handleArrayNumberInputOnBlur("allowances", index, "exempt_amtp", item.exempt_amtp)}
                                                disabled={taxRegime === "NEW"}
                                            />
                                        </td>
                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_borderright `}>
                                            <span className="d-flex">

                                                <BlurOnChangeInput
                                                    type="text"
                                                    className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                    value={FormatNumberDecimal(convertNum(item.received_amtp) - convertNum(item.exempt_amtp))}
                                                    data-toggle="tooltip" data-placement="top" title={""}
                                                    placeholder=""
                                                />
                                                <span className="deleicon_pos">

                                                    <img src={deleteIcon} alt="addicon"
                                                        width="16"
                                                        onClick={() => {
                                                            handleArrayObjDel("allowances", index, "allowanceExempt", ALLOWANCEEXEMPT_DROPDOWN?.[USER_TYPE] ?? [], "allowance_type_id");
                                                        }}
                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                    />

                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        {drowdownList?.allowanceExempt?.length > 0 ?
                            <tr className="salarytaxregime_edit_alternetbgclr_white">
                                <td width=""></td>
                                <td width="" colSpan={4} className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom align-bottom">
                                    <span className="" style={{ display: "flex" }}>
                                        <DropDownList
                                            data={drowdownList?.allowanceExempt ?? []}
                                            className="header_select headerselect w-100 drpdwnlist_changedesign"
                                            popupSettings={{ className: 'headerselectoption' }}
                                            textField="description"
                                            value={{description: "Add additional detail", particular_code: ""}}
                                            onChange={(e) => {
                                                allowanceObj.allowance_type_id = e.target.value.allowance_type_id ?? "";
                                                handleArrayObjADD("allowances", allowanceObj, "allowanceExempt", e.target.value, "allowance_type_id");
                                            }}
                                            placeholder="Add additional detail"
                                            dataItemKey="allowance_type_id"
                                            id="yearDropDownPopup"
                                        />
                                    </span>
                                </td>

                                <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom">
                                    <BlurOnChangeInput
                                        type="text"
                                        className={`form-control salarytaxregime_edit_inputbox text-right disabled`}
                                        data-toggle="tooltip" data-placement="top" title={""}
                                        placeholder=""
                                        disabled
                                    />
                                </td>


                            </tr> : <tr>
                                <td></td>
                                <td colSpan={5} className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom"></td>
                            </tr>}

                    </tbody>
                </table>
            </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default AllowancesDetails;
