import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { BASE_PATH, DASHBOARD_PATH } from '../../app/constants';
import Modal from 'react-bootstrap/Modal'; import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye, faEyeSlash, faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../features/header/headerSlice';
import "./ITConnector.scss";

const ITConnector = (props) => {
    const { show, handleClose, syncData, verfiyCredentialApi, loading, error1, latestSyncStatus } = props;

    // const [error, setError] = useState(false);
    const [eFilingPswrd, setEFilingPswrd] = useState(syncData?.itdportal_password ?? "");
    const [formErrors, setFormErrors] = useState({});
    const [showTracesPassword, setShowTracesPassword] = useState(false);

    const headerDataDetails = useSelector(selectHeaderData);
    const location = useLocation();
    const history = useHistory();
    const pathName = location.pathname;

    const submitForm = (e) => {
        e.preventDefault();
        let fErrors = {};
        if (eFilingPswrd === '') { fErrors['eFilingPassword'] = 'E-Filing Password is required'; }

        setFormErrors(fErrors);
        if (Object.keys(fErrors).length > 0) return;
        syncData["itdportal_password"] = eFilingPswrd;
        verfiyCredentialApi(syncData)
    };

    const closeModel = () => {
        handleClose();

        if (headerDataDetails?.tan && headerDataDetails?.mobile_number && pathName === DASHBOARD_PATH) {
            // let InsightsGuide = JSON.parse(localStorage.getItem('Insights-guide'));
            // const userMobile = headerDataDetails?.mobile_number;
            // const userTan = headerDataDetails?.tan;      
            // if (!InsightsGuide || !InsightsGuide[userMobile] || !InsightsGuide[userMobile].includes(userTan)) {        
            if (latestSyncStatus?.sync_status === null) {
                //Deductor not exist in insights guide
                history.push(`${BASE_PATH}list/recent`);
            }
        }

    }

    return (
        <Modal
            show={show}
            onHide={closeModel}
            backdrop="static"
            keyboard={false}
            className="incometaxcredential"
        >
            <Modal.Header closeButton className="mpoptextbor">
                <Modal.Title>Income Tax Credentials</Modal.Title>
            </Modal.Header>

            <form onSubmit={submitForm}>
                <Modal.Body className="pb-0 px-0">

                    {/* <ShowAlert
                        error={error1}
                        onClose={() => { setError1(null); }}
                    /> */}

                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {/* <p className="mb-0 pb-0">Please provide Income Tax Credentials below </p> */}
                                {error1
                                    ? (
                                        <div style={{ color: 'red', fontSize: '11px', marginBottom: '5px' }}>
                                            <FontAwesomeIcon icon={faExclamationCircle} />
                                            {' '}
                                            {error1}
                                        </div>
                                    )
                                    : null}
                                <div className="row">
                                    <div className="col-md-12">

                                        <div className="form-group">
                                            <label className="control-label" htmlFor="">E-Filing Username <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                disabled
                                                type="search"
                                                className="form-control"
                                                value={syncData?.itdportal_username ?? ""}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="control-label" htmlFor="">E-Filing Password <span style={{ color: "red" }}>*</span> </label>
                                            {showTracesPassword ?
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="eFilingPassword"
                                                        name="eFilingPassword"
                                                        placeholder="Enter E-Filing Password"
                                                        onChange={(e) => setEFilingPswrd(e.target.value)}
                                                        value={eFilingPswrd}
                                                    />
                                                    <div
                                                        className="input-group-prepend eyeipbox-create-deductor"
                                                        onClick={() => setShowTracesPassword(false)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <FontAwesomeIcon icon={faEyeSlash} />
                                                    </div>
                                                </div>
                                                :
                                                <div className="input-group">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="eFilingPassword"
                                                        name="eFilingPassword"
                                                        placeholder="Enter E-Filing Password"
                                                        onChange={(e) => setEFilingPswrd(e.target.value)}
                                                        value={eFilingPswrd}
                                                    />
                                                    <div
                                                        className="input-group-prepend eyeipbox-create-deductor"
                                                        onClick={() => setShowTracesPassword(true)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </div>
                                                </div>}
                                            {formErrors.eFilingPassword ? <span className="error_message">{formErrors.eFilingPassword}</span> : null}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-12 mb-2 text-center">

                                    <Button type="submit" className="tracesbtnsubmit" onClick={(event) => submitForm(event)} disabled={loading}> Save </Button>
                                </div>
                            </div>

                        </div>

                    </div>

                </Modal.Body>

            </form>
        </Modal>
    )
}

export default ITConnector