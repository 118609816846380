import React, { useEffect, useState } from 'react'
import { gocallApi } from '../../../../api/issueCertificateAPI';
import LoadingIndicator from '../../../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../../../components/ShowAlert';
import PersonalInfo from './PersonalInfo';
import TaxCalculation from './TaxCalculation';
import Salary from './Salary';
import Deduction from './Deduction';
import WindowWrapperFixed from '../../../../components/grid/WindowWrapperFixed';
import './computation.scss';
import '../SalaryDetails.scss';

const Computation = (props) => {

    const { onClose, deductorId, financial_year, deducteeId } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [data, setData] = useState({})
    const [userStatus, setUserStatus] = useState(1)
    const API_URL = '/api/v1/salary-tds-calculator/deductee-computation';

    const callComputationData = async () => {
        setLoading(true);
        try {
            const result = await gocallApi("get", `${API_URL}?deductor_id=${deductorId}&financial_year=${financial_year}&deductee_id=${deducteeId}`, null);

            if (result?.data) {
                setUserStatus(1)
                setData(() => {
                    return result?.data
                })
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (deductorId && financial_year) {
            callComputationData(deductorId, financial_year);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deductorId, financial_year]);

    return (
        <>
            <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ width: '100%' }}>
                    <div className="row" style={{ height: '55px' }}>
                        <div className="col-md-8">
                            <h4 className="userexcel_salarydetails_titlefs" style={{paddingTop: '15px'}}>
                                Computation Details
                            </h4>
                        </div>                        
                    </div>
                </kendo-dialog-titlebar>}
                onClose={onClose}
                totalPopups={0}
                initialTop={25}
                index={1}
                className="userexcel_salarydetails_modal userexcel_salarydetailsTDS_widthmodal"
                initialHeight={'auto'}
                initialWidth={1150}
                initialLeft={200}
            >

                {loading ? <LoadingIndicator /> : null}

                <ShowAlert
                    error={error}
                    success={success}
                    onClose={() => { setError(null); setSuccess(null); }} />

                <div className="container expit_comp_contbox_leftsidepadd">

                    <div className='main-computation'>
                        <div className="row mt-3 main-computation-head pb-1">
                            <div className="col-md-12 text-center">
                                <h4 className="exprit_compofincometitlefs text_black">Computation of Income</h4>
                            </div>

                        </div>
                        <PersonalInfo userStatus={userStatus} data={data} />
                        <Salary data={data} />
                        <Deduction data={data} />
                        <TaxCalculation data={data} />
                        <div className='row'>
                            <div className='col-md-12'>
                                <hr className='hrline_borderclr' />
                            </div>
                        </div>
                    </div>
                </div>

            </WindowWrapperFixed>


        </>

    )
}

export default Computation