import {
    individualsTaxRates, individualsInDateRangeTaxRates, NoneIndividualsTaxRates, NoneIndividualsInDateRangeTaxRates, NoPanTaxRates, DateRangeTaxRate27EQ, PanTaxRates27EQ, NoPanTaxRates27EQ, DeductionReasonURate, DeductionReasonIRate
    , DeductionReasonJRate27Q, TaxRatesPanAvialbleDateRange27Q, TaxRatesPanAvialbleDateRange27QOther, TaxRatePanNotAvailable27Q, TaxRatePanAvailable27Q, TaxRatePanAvailable27QOthers,
    NonFilerDeductionReasonURate, NonFilerDeductionReasonIRate,
    Budget2024_DeductionReasonURate,
    Budget2024_NonFilerDeductionReasonURate,
    Budget2024_individualsInDateRangeTaxRates,
    Budget2024_NoneIndividualsInDateRangeTaxRates,
    Budget2024_individualsTaxRates,
    Budget2024_NoneIndividualsTaxRates,
    Budget2024_NoPanTaxRates,
} from './taxRates';


export const calculation26QTaxRate = (pan, deductee_category, payment_date, deduction_date, startDate, endDate, paymentCode, deduction_reason, panComplianceStatus = null, headerData) => {

    let isBudget2024ValidationApplied = false;

    if(
        (headerData && (parseInt(headerData?.financial_year_formatted ?? 0) === 2024 && ['Q3','Q4'].includes(headerData?.quarter ?? '')))
        ||
        (headerData && parseInt(headerData?.financial_year_formatted ?? 0) > 2024)
    ) {
        isBudget2024ValidationApplied = true;
    }

    let tax_rates = 0;    

    //Reason U 
    if (pan && deduction_reason === 'U') {

        //console.log("Reason U - paymentCode", paymentCode);
        paymentCode = (deductee_category === 'K' && (parseInt(paymentCode) === 28 || parseInt(paymentCode) === 46)) ? paymentCode + 'K' : paymentCode;
        tax_rates = isBudget2024ValidationApplied ? Budget2024_DeductionReasonURate[paymentCode]  : DeductionReasonURate[paymentCode];
        if (panComplianceStatus === 'NON FILER') {
            tax_rates = isBudget2024ValidationApplied ? Budget2024_NonFilerDeductionReasonURate[paymentCode] : NonFilerDeductionReasonURate[paymentCode];
        }

        return tax_rates;
    }

    /* *payment_date is not null and deduction_date is not null 
       *and payment_date between 14May2020 and 31March2021 
       *and deduction_date between 14May2020 and 31March2021
       *and pan valid*/
    if (pan && payment_date && deduction_date &&
        payment_date >= startDate && payment_date <= endDate &&
        deduction_date >= startDate && deduction_date <= endDate) {
        //deductor_category is defined and deductor_category = individual/huf (non_company)
        if (deductee_category && deductee_category === 'Q') {
            //console.log("individualsInDateRangeTaxRates - paymentCode", paymentCode);
            tax_rates = isBudget2024ValidationApplied ? Budget2024_individualsInDateRangeTaxRates[paymentCode] : individualsInDateRangeTaxRates[paymentCode];
            return tax_rates;
        }

        //Non-individual/huf (Company)
        //console.log("NoneIndividualsInDateRangeTaxRates - paymentCode", paymentCode);
        tax_rates = isBudget2024ValidationApplied ? Budget2024_NoneIndividualsInDateRangeTaxRates[paymentCode] : NoneIndividualsInDateRangeTaxRates[paymentCode];
        return tax_rates;

    }

    /* deductor_category is defined 
       *and deductor_category = individual/huf (non_company)
       *pan valid */
    if (pan && deductee_category && deductee_category === 'Q') {
        //console.log("individualsTaxRates - paymentCode", paymentCode);
        tax_rates = isBudget2024ValidationApplied ? Budget2024_individualsTaxRates[paymentCode] : individualsTaxRates[paymentCode];
        return tax_rates;
    }

    /* regex(deductee.pan) */
    if (pan) {
        //console.log("NoneIndividualsTaxRates - paymentCode", paymentCode);
        tax_rates = isBudget2024ValidationApplied ? Budget2024_NoneIndividualsTaxRates[paymentCode] : NoneIndividualsTaxRates[paymentCode];
        return tax_rates;
    }

    //deductee.pan is undefined || !regex(deductee.pan)
    //console.log("NoPanTaxRates - paymentCode", paymentCode);
    tax_rates = isBudget2024ValidationApplied ? Budget2024_NoPanTaxRates[paymentCode] : NoPanTaxRates[paymentCode];
    return tax_rates;

}


export const calculation27EQTaxRate = (pan, payment_date, deduction_date, startDate, endDate, paymentCode, deduction_reason, panComplianceStatus = null, headerData) => {
    const financial_year_formatted = headerData.financial_year_formatted;
    const financial_year = parseInt(financial_year_formatted.split("-")?.[0] ?? 0);
    if (parseInt(paymentCode) === 57 && (financial_year > 2023 || (financial_year === 2023 && headerData?.quarter >= "Q3" ))) {
        return 20
    }

    let tax_rates = 0;
    //Reason I 
    if (pan && deduction_reason === 'I') {
        tax_rates = DeductionReasonIRate[paymentCode];
        if (panComplianceStatus === 'NON FILER') {
            tax_rates = NonFilerDeductionReasonIRate[paymentCode];
        }
        return tax_rates;
    }

    /* *payment_date is not null and deduction_date is not null 
       *and payment_date between 14May2020 and 31March2021 
       *and deduction_date between 14May2020 and 31March2021
       *and regex(deductee.pan) */

    if (pan && payment_date && deduction_date &&
        payment_date >= startDate && payment_date <= endDate &&
        deduction_date >= startDate && deduction_date <= endDate) {

        //console.log("DateRangeTaxRate27EQ - paymentCode", paymentCode);
        tax_rates = DateRangeTaxRate27EQ[paymentCode];
        return tax_rates;
    }

    /* regex(deductee.pan) */
    if (pan) {
        //console.log("PanTaxRates27EQ - paymentCode", paymentCode);
        tax_rates = PanTaxRates27EQ[paymentCode];
        return tax_rates;
    }

    //deductee.pan is undefined || !regex(deductee.pan)
    //console.log("NoPanTaxRates27EQ - paymentCode", paymentCode);
    tax_rates = NoPanTaxRates27EQ[paymentCode];
    return tax_rates;
}


export const calculation27QTaxRate = (pan, deductee_category, payment_date, deduction_date, startDate, endDate, paymentCode, deduction_reason, applicable_tds_rate) => {
    let tax_rates = 0;
    if (applicable_tds_rate && applicable_tds_rate === 'B') {
        return '';
    }
    //Reason J
    if (pan && deduction_reason === 'J' && ![46, 36, 53, 41].includes(paymentCode)) {
        tax_rates = DeductionReasonJRate27Q[paymentCode];
        return tax_rates;
    }

    /* *payment_date is not null and deduction_date is not null 
       *and payment_date between 14May2020 and 31March2021 
       *and deduction_date between 14May2020 and 31March2021
       *and pan valid*/
    if (pan && payment_date && deduction_date &&
        payment_date >= startDate && payment_date <= endDate &&
        deduction_date >= startDate && deduction_date <= endDate) {
        if (deductee_category && deductee_category === 'K') {
            tax_rates = TaxRatesPanAvialbleDateRange27QOther[paymentCode];
            return tax_rates;
        }

        tax_rates = TaxRatesPanAvialbleDateRange27Q[paymentCode];
        return tax_rates;

    }
    if (pan) {
        if (deductee_category && deductee_category === 'K') {
            tax_rates = TaxRatePanAvailable27QOthers[paymentCode];
            return tax_rates;
        }
        tax_rates = TaxRatePanAvailable27Q[paymentCode];
        return tax_rates;
    }

    //deductee.pan is undefined || !regex(deductee.pan)
    tax_rates = TaxRatePanNotAvailable27Q[paymentCode];
    return tax_rates;

}