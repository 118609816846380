import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from 'query-string';
import ReturnFlowTimeLine from "./ReturnFlowTimeLine";
import { BASE_PATH, HEADER_FILE_NAME } from "../../app/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import ShowAlert from "../../components/ShowAlert";
import { exportRegularReturn, downloadRegularReturnErrorFile } from '../../api/returnAPI';
import { downloadExcelTemplate } from '../../api/deductorAPI';
import { getNatureOfPaymentCodes } from '../../api/challanAPI';
import { getDeducteesSectionV3Summary, getSectionWiseTransactionV2 } from "../../api/deducteeTransactionsAPI";
import ImportReturnFromExcelV2 from "../returnDashboard/importReturnFromExcel/ImportReturnFromExcelV2";
import SectionWiseTransactionPopup from "./SectionWiseTransactionPopup";
import { selectHeaderData } from "../header/headerSlice";
import exportReturnIcon from '../../images/exceldownload4.png';
import downloadIcon from '../../images/downloadicon_white.png';
import EyeIcon from '../../images/eyeiconcolr.png';
import EyeCloseIcon from "../../images/eye_close_icon_coralclr.png";
import CM_gif from '../../images/CM_gif.gif'
import ConflictPopup from './ConflictPopup';
import Skeleton12ColumnGrid from "../../components/Skeleton/Skeleton12ColumnGrid";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomCell, AddDeducteeTransactionsIcon } from "../../components/grid/CustomCell";
import { ColumnMenu } from "../../components/grid/ColumnMenu";
import { parseDate } from '@telerik/kendo-intl';
import './DeducteeTransactionsV2.scss';
import { formatNumber } from "../../utils/UtilityFunctions";
import Pagination from "../../components/pagination/Pagination";
import { gocallApi } from "../../api/issueCertificateAPI";
import DownloadFile from '../../components/DownloadFile';
import Spinner from 'react-bootstrap/Spinner';


const SectionWiseListing = ({ history }) => {

    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);
    const headerDataDetails = useSelector(selectHeaderData);
    const limit = 17;

    const PAN_LIST = [
        { label: 'PAN APPLIED', value: 'PANAPPLIED' },
        { label: 'PAN INVALID', value: 'PANINVALID' },
        { label: 'PAN NOT AVAILABLE', value: 'PANNOTAVBL' },
    ];

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [importReturn, setImportReturn] = useState(false);
    const [showConflictPopup, setShowConflictPopup] = useState(false);
    const [skeletonList, setSkeletonList] = useState(true);
    const [dataState, setDataState] = useState({});
    const [paymentCodes, setPaymentCodes] = useState([]);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [sectionWiseList, setSectionWiseList] = useState([]);
    const [sectionSummary, setSectionSummary] = useState(null)
    const [visibleSections, setVisibleSections] = useState(new Set());
    const [topSummary, setTopSummary] = useState(null);
    const [downloadExcelFile, setDownloadExcelFile] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [formType, setFormType] = useState('');
    const [summaryColumnSkelton, setSummaryColumnSkelton] = useState(true);
    const [disabledTimeline, setDisabledTimeline] = useState(false);

    const getSummary = async (data) => {
        try {
            if (data.summary_action === true) {
                setSummaryColumnSkelton(true);
                const resultSummary = await getDeducteesSectionV3Summary(data);
                setSectionSummary(resultSummary?.data ?? {
                    "deductee_count": '',
                    "mapped": '',
                    "mapped_challan": '',
                    "section_count": '',
                    "sum_paid_amtp": '',
                    "sum_total_tds_amtp": '',
                    "trans_count": '',
                    "unmapped": ''
                });
            }
        } catch (err) {
            console.error(err);
            setError(err)
        } finally {
            setSummaryColumnSkelton(false);
        }

    }

    const getDeducteeSectionWiseList = (data) => {
        setLoading(true);
        async function fetchEverything() {
            try {
                const result = await getSectionWiseTransactionV2(data);
                if (result?.data) {
                    setSectionWiseList(result?.data?.data ?? []); 
                    getSummary(data);                   
                    if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page;
                    setCursor(result.data.cursor);
                }
            } catch (err) {
                setError(err)
            } finally {
                setLoading(false);
                setSkeletonList(false);
            }
        }
        fetchEverything()
    }

    const getParamsHistoryUrl = () => {

        const postData = {
            deductor_id: params.deductor_id,
            return_id: params.return_id,
            sort_on: params.sort_on ? params.sort_on : '',
            sort_by: params.order_by ?? "",
            summary_action: true,
        };

        if (params?.filter_deductee_id) {
            postData.filter_deductee_id = params?.filter_deductee_id;
        }

        if (params.sort) {
            dataState.sort = JSON.parse(atob(params.sort));
            postData.sort = JSON.parse(atob(params.sort));
        }

        if (postData.sort_on !== '') {
            postData.summary_action = false;
        }

        if (params.page) {
            if (postData.page > 1) {
                postData.summary_action = false;
            }
        }

        if (params.filters) {
            postData.summary_action = true;

            // IN getParamsHistoryUrl
            let frs = JSON.parse(atob(params.filters));
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'payment_date' || frs[i].filters[j].field === 'deduction_date') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                }
            }

            dataState.filter = { filters: [] };
            dataState.filter.filters = frs;
            postData.filters = frs;
        }
        if (params.page) {
            postData.page = parseInt(params.page);
        }
        if (params.action) {
            postData.action = params.action.split(",");
        }

        postData.limit = params.limit ?? limit;
        setDataState(dataState);
        return postData;
    };

    const fetchPaymentCodesFunc = (params) => {
        async function fetchEverything() {
            async function fetchPaymentCodes() {
                const result = await getNatureOfPaymentCodes(params);
                const paymentCodeData = [];
                const payDataKey = {};
                result.data.forEach((paymentCode) => {
                    paymentCodeData.push({
                        label: `${paymentCode.code} - ${paymentCode.description}`,
                        value: paymentCode.nature_of_payment_id,
                    });
                    payDataKey[paymentCode.code] = paymentCode;
                });
                setPaymentCodes(paymentCodeData);
            }
            try {
                await Promise.all([
                    fetchPaymentCodes(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        let additional_filter = [];
        let action = '';
        if (params.additional_filter) {
            additional_filter = params.additional_filter.split(",");
        }
        if (params.action) {
            action = params.action;
        }
        createHistoryUrl(dataState, action, additional_filter);
    };

    const createHistoryUrl = (dataState = [], action = '', additional_filter = []) => {
        let filterStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;

        if (params?.filter_deductee_id) {
            filterStr += `&filter_deductee_id=${params.filter_deductee_id}`;
        }

        if (params.tab) {
            filterStr += `&tab=${params.tab}`;
        }

        if (params.action) {
            let all_status = params.action.split(",");
            let array_index = all_status.indexOf(action);
            if (array_index !== -1) {
                all_status.splice(array_index, 1);
            }
            else {
                all_status.push(action);
            }
            filterStr += all_status.length > 0 ? `&action=${all_status.join(",")}` : '';
        }
        // else {
        //     filterStr += `&action=${action}`;
        // }

        if (additional_filter.length > 0) {
            filterStr += `&additional_filter=${additional_filter.join(",")}`;
        }

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }

        // if (dataState.sort && dataState.sort.length > 0) {
        //     filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
        // }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${dataState.sort[0].field}&order_by=${dataState.sort[0].dir}`;
        }

        history.push(filterStr);
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    function getFirstItemOfSet(set) {
        for (let item of set) {
            if (item) {
                return item;
            }
        }
        return undefined;
    };

    const rowRender = (trElement, props,) => {

        const index = props.dataIndex;

        return (
            <tr data-index={index} className={index % 2 === 0 ? 'deducteewise_tb_alternetrowbgclr_grey ' : 'deducteewise_tb_alternetrowbgclr_white '} role="row">
                <td className="text-center">{props?.dataItem?.display_code ?? ""} </td>
                <td className="text-left" data-toggle="tooltip" data-placement="top" title={props?.dataItem?.description ?? ""}>{props?.dataItem?.description ?? ""} </td>
                <td className="text-center">{formatNumber(props?.dataItem?.tds_rate_b100) ?? ""} </td>
                <td className="text-right">{formatNumber(parseFloat(props?.dataItem?.deductee_paid_amtp).toFixed(2)) ?? 0} </td>
                <td className="text-right">{formatNumber(parseFloat(props?.dataItem?.deductee_total_tds_amtp).toFixed(2)) ?? 0} </td>
                <td className="text-center">
                    <span>
                        <img alt="" width="16" style={{ cursor: 'pointer' }}
                            onClick={() => {
                                let newVisibleSections = new Set(visibleSections);
                                if (newVisibleSections.has(props?.dataItem?.nature_of_payment_id)) {
                                    newVisibleSections.delete(props?.dataItem?.nature_of_payment_id);
                                } else {
                                    newVisibleSections.add(props?.dataItem?.nature_of_payment_id);
                                    if (newVisibleSections.size === 3) {
                                        const firstItem = getFirstItemOfSet(newVisibleSections);
                                        newVisibleSections.delete(firstItem);
                                    }
                                }
                                setVisibleSections(newVisibleSections);
                            }}
                            src={visibleSections.has(props?.dataItem?.nature_of_payment_id) ? EyeCloseIcon : EyeIcon}
                        />
                    </span>
                </td>

            </tr>
        )
    }

    const downloadErrorFile = async () => {
        setLoading(true);
        try {
            let payload = `?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`;
            const result = await downloadRegularReturnErrorFile(payload);
            const csiData = new Blob([result?.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS-ReturnErrorData.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            setError(err);
        }
        setLoading(false);
    };

    const downloadExcelTemp = async () => {
        setLoading(true);
        try {
            let params = `?form_type=${headerDataDetails.form_type}&quarter=${headerDataDetails.quarter}&return_type=REGULAR`;
            const result = await downloadExcelTemplate(params);
            const csiData = new Blob([result], { type: 'application/xls' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            let formType = headerDataDetails.form_type;
            link.setAttribute('download', 'ExpressTDS_' + formType + '_Template.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPaymentCodesFunc();
    }, []);

    useEffect(() => {
        const postData = getParamsHistoryUrl();
        getDeducteeSectionWiseList(postData);
        // eslint-disable-next-line
    }, [searchStr]);

    let popupIndex = 0;
    let totalPopups = 0;
    Array.from(visibleSections).forEach((uniqueID) => {
        if (visibleSections.has(uniqueID)) {
            totalPopups++;
        }
    });

    const getSelectedDeducteeData = (uniqueID) => {
        const selectedDeducteeRecord = sectionWiseList.filter((v) => v.nature_of_payment_id === uniqueID);
        return selectedDeducteeRecord?.[0] ?? [];

    }

    const getTopSummary = async (deductorId, returnId) => {
        if (topSummary === null) {
            let url = `/api/v2/transaction/summary-count?deductor_id=${deductorId}&return_id=${returnId}`
            try {
                const result = await gocallApi("get", url);
                setTopSummary(result?.data ?? {})

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
            }
        }
    }

    useEffect(() => {
        getTopSummary(params?.deductor_id, params?.return_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.deductor_id, params?.return_id])

    useEffect(() => {
        setFormType(headerDataDetails?.form_type);
    }, [headerDataDetails?.form_type])

    const getDeducteeLabel = (formType) => {
        switch (formType) {
            case '24Q': return 'Employee';
            case '27EQ': return 'Collectee';
            default: return 'Deductee';
        }
    }

    return (
        <>
            {loading ? <LoadingIndicator /> : null}

            {downloadExcelFile &&
                <DownloadFile
                    apiFunction={exportRegularReturn}
                    setDownloadLoading={setDownloadLoading}
                    setError={setError}
                    fileName={'ExpressTDS-ReturnData'}
                    setDownloadExcelFile={setDownloadExcelFile}
                    sessionStorageKey={`export-return-ref-key-${params?.deductor_id}-${params?.return_id}-${params?.financial_year}`}
                    params={`?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`}
                    handleSuccessProceed={() => {
                        setDownloadExcelFile(false);
                    }}
                    handleErrorProceed={(msg) => {
                        setDownloadExcelFile(false);
                    }}
                />
            }

            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            <ImportReturnFromExcelV2
                show={importReturn}
                handleClose={(refresh) => {
                    setImportReturn(false);
                }}
                deductorId={params?.deductor_id}
                returnId={params.return_id}
                importExelError={() => { setImportReturn(false) }}
                showImportReturnFromTxt={false}
                downloadExcelTemp={downloadExcelTemp}
                fetchTransactionOverView={() => null}
                setDisabledTimeline={setDisabledTimeline}

            />

            {showConflictPopup ? (
                <ConflictPopup
                    show={showConflictPopup}
                    closeModal={() => { setShowConflictPopup(false); }}
                    onHide={() => {
                        setShowConflictPopup(false);
                    }}
                    deductorId={params.deductor_id}
                    returnId={params.return_id}
                    PAN_LIST={PAN_LIST}
                    paymentCodes={paymentCodes}
                />

            ) : null}

            {Array.from(visibleSections).map((uniqueID) => {
                popupIndex = popupIndex + 1;
                const parms = {
                    onClose: () => {
                        const newVisibleSections = new Set(visibleSections);
                        newVisibleSections.delete(uniqueID);
                        setVisibleSections(newVisibleSections);
                    },
                    index: popupIndex - 1,
                    totalPopups: totalPopups,
                }
                return (
                    <SectionWiseTransactionPopup
                        deductorId={params?.deductor_id}
                        returnId={params?.return_id}
                        uniqueID={uniqueID}
                        data={getSelectedDeducteeData(uniqueID)}
                        setError={setError}
                        {...parms}
                    />
                )
            })}


            <div className="container-fluid">
                <ReturnFlowTimeLine returnId={params.return_id} deductorId={params.deductor_id} currentPage={`TransactionListing`} disabledTimeline={disabledTimeline}/>
            </div>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12" > </div>
                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <nav className="Deducteetrans_nav d-flex justify-content-center">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise showbox"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    Transaction Wise  <br />
                                    <span className="Deducteetrans_dedwise_count_text">({topSummary?.trans_count ?? 0})</span>
                                </Link>
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise Deducteetrans_defaulttab showbox"
                                    to={`${BASE_PATH}deductee-wise-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    {getDeducteeLabel(formType)} Wise<br />
                                    <div className="Deducteetrans_dedwise_count_text">({topSummary?.deductee_count ?? 0})</div>
                                </Link>
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise active Deducteetrans_defaulttab showbox"
                                    to={`${BASE_PATH}section-wise-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    {`${formType === '27EQ' ? 'Collectee Code Wise' : 'Section Wise'}`}<br />
                                    <span className="Deducteetrans_dedwise_count_text">({topSummary?.section_count ?? 0})</span>
                                </Link>
                            </div>
                        </nav>
                    </div>
                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 text-right">
                        <button className="btn btn-default errorrecodsbtn mr-1" onClick={() => setDownloadExcelFile(true)} title='Export Report'>
                            <span className="pr-1">
                                {
                                    downloadLoading
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}</>
                                        : <img src={exportReturnIcon} width="16" alt="Export Return" />
                                }
                            </span>
                            Return
                        </button>

                        <button className="btn btn-default errorrecodsbtn" onClick={() => downloadErrorFile()} title='Download Error Report'>
                            <span className="pr-1">
                                <img src={downloadIcon} width="16" alt="Download Error Report" />
                            </span>
                            Error Records
                        </button>
                    </div>
                </div>
                <div className="row  mt-2 ">
                    <div className="col-md-12">
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show" id="Deducteetrans_dedwise_tab02" role="tabpanel" aria-labelledby="nav-home-tab" style={{ display: 'block' }}>
                                <div className="card p-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                skeletonList ? <Skeleton12ColumnGrid rowNo={7} />
                                                    :
                                                    <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                        <Tooltip anchorElement="pointer" position="right">
                                                            <Grid
                                                                className="table table-striped listofsalarydetailstb1  deducteewisetrns_heightfixed mb-0"
                                                                data={sectionWiseList}
                                                                {...dataState}
                                                                onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                                filterOperators={{
                                                                    text: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                    ],
                                                                    numeric: [
                                                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                        { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                                    ],
                                                                    date: [
                                                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                    ],
                                                                    textWithEmpty: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                                    ],
                                                                    dropdown: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'in' },
                                                                    ],
                                                                    pan: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterContainsOperator', operator: 'in' },
                                                                    ],
                                                                    challan: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                                                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                                    ],
                                                                }}
                                                                sortable={{
                                                                    allowUnsort: true,
                                                                }}
                                                                rowRender={rowRender}
                                                            >

                                                                <Column
                                                                    field="code"
                                                                    className="text-center"
                                                                    filter="dropdown"
                                                                    title={<CustomCell title={'Section'} field="" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                    width='100'
                                                                    headerClassName={isColumnActive('code', dataState) ? 'active' : ''}
                                                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                                                                    sortable={true}
                                                                />
                                                                <Column
                                                                    field="description"
                                                                    className="text-left"
                                                                    filter="textWithEmpty"
                                                                    title={<CustomCell title={'Description'} field="" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                    width='370'
                                                                    headerClassName={isColumnActive('description', dataState) ? 'active' : ''}
                                                                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                                    sortable={true}
                                                                />
                                                                <Column
                                                                    field="tds_rate_b100"
                                                                    className="text-center"
                                                                    filter="numeric"
                                                                    title={<CustomCell title={'Rate(%)'} field="" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                    width='125'
                                                                    headerClassName={isColumnActive('tds_rate_b100', dataState) ? 'active' : ''}
                                                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                                                    sortable={true}
                                                                />
                                                                <Column
                                                                    width='125'
                                                                    field="deductee_paid_amtp"
                                                                    className="text-center"
                                                                    filter="numeric"
                                                                    headerClassName={isColumnActive('deductee_paid_amtp', dataState) ? 'active' : ''}
                                                                    title={<AddDeducteeTransactionsIcon field="deductee_paid_amtp" dataState={dataState}
                                                                        dataStateChange={dataStateChange}
                                                                        title='Amount Paid'
                                                                        subtextData={{ subText: sectionSummary?.sum_paid_amtp ?? 0, color: '' }}
                                                                        showButton={false}
                                                                        summaryColumnSkelton={summaryColumnSkelton}
                                                                    />}
                                                                    columnMenu={ColumnMenu}
                                                                    sortable={false}
                                                                />
                                                                <Column
                                                                    width='125'
                                                                    field="deductee_total_tds_amtp"
                                                                    className="text-center"
                                                                    filter="numeric"
                                                                    headerClassName={isColumnActive('deductee_total_tds_amtp', dataState) ? 'active' : ''}
                                                                    title={<AddDeducteeTransactionsIcon field="deductee_total_tds_amtp" dataState={dataState}
                                                                        dataStateChange={dataStateChange}
                                                                        title='Total Tax Deducted'
                                                                        subtextData={{ subText: sectionSummary?.sum_total_tds_amtp ?? 0, color: '' }}
                                                                        showButton={false}
                                                                        summaryColumnSkelton={summaryColumnSkelton}
                                                                    />}
                                                                    columnMenu={ColumnMenu}
                                                                    sortable={false}
                                                                />
                                                                <Column
                                                                    field=""
                                                                    sortable={false}
                                                                    title=""
                                                                    headerClassName={'sorting_disabled'}
                                                                    width={80}
                                                                />

                                                            </Grid>

                                                        </Tooltip>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <Pagination cursor={cursor} />
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="challans_leftsidepopupbox" data-toggle="modal" data-target="#tdsreimport_popup">
                <div className="challans_AnnouncementsIcon" data-toggle="modal" data-target="#updatebooks2adatam">
                    <div className="challans_Announcements">
                        <div className="challans_Announcements-wrapper challans_has-unseen-items Dropdown">
                            <div className="Dropdown-toggle Button">
                                <div className="challans_Announcements-bubble">&nbsp;</div>
                                <div>
                                    <FontAwesomeIcon icon={faBullhorn} style={{ color: '#fff', fontSize: '11px', transform: 'rotate(-38deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span
                    onClick={() => { setImportReturn(true); }}
                    className="mb-0 btn challans_textfs"
                    type="button"
                > Import Return </span>
            </div>

            <div className="return_flow_gifimgmainbox" onClick={() => setShowConflictPopup(true)}>
                <span className="bottomrightsidegifimgbox">
                    <img src={CM_gif} alt="Deducted " width="105" />
                </span>
            </div>
        </>
    );

};

export default SectionWiseListing;