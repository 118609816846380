import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { getTenantCurrentPlan, getTenantPlanHistory, downloadInvoiceFile } from '../../api/profileAPI';
import ShowAlert from '../../components/ShowAlert';
import { BASE_PATH } from '../../app/constants';
import { formatAccountsNumber } from '../../utils/UtilityFunctions';
import AddOnPopup from './AddOnPopup';
import Billing from '../billing/Billing';
//import invoiceIcon from "../../images/invoice.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import './Subscription.css';


const Subscription = ({ history, ...props }) => {
  const { userId } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [userPlanData, setUserPlanData] = useState({});
  const [tenantPlanHistory, setTenantPlanHistory] = useState({});
  const [showAddOnPopup, setShowAddOnPopup] = useState(false);
  const [planParameters, setPlanParameters] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [showPlanBilling, setShowPlanBilling] = useState(false);
  //eslint-disable-next-line
  const [showPaymentmode, setShowPaymentmode] = useState(false);
  //eslint-disable-next-line
  const [allowOtherPaymentmode, setAllowOtherPaymentmode] = useState('');
  const [billingDetails, setBillingDetails] = React.useState({
    tenant_address: {
      address_detail: '',
      city: '',
      company_gst_number: '',
      company_name: '',
      pincode: '',
      state_id: '',
    },
    tenant_contact_person: {
      email: '',
      full_name: '',
      is_active: false,
      mobile_no: '',
    },
  });

  const renderTenantCurrentPlan = async () => {
    let searchStr = '';
    if (userId) {
      searchStr = `?user_id=${userId}`;
    }

    try {
      setLoading(true);
      const result = await getTenantCurrentPlan(searchStr);
      setUserPlanData(result.data);
      setPlanParameters(result.data.plan_parameters);
    } catch (err) {
      //console.error('Error in getting company profile: ', err);
    }
    setLoading(false);
  };

  const renderTenantPlanHistory = async () => {
    let searchStr = '';
    if (userId) {
      searchStr = `?user_id=${userId}`;
    }

    try {
      setLoading(true);
      const result = await getTenantPlanHistory(searchStr);
      setTenantPlanHistory(result.data);
    } catch (err) {
      //console.error('Error in getting company profile: ', err);
    }
    setLoading(false);
  };

  const downloadInvoiceDetail = async (data) => {
    setLoading(true);

    try {
      const payload = {
        "subscription_id": data?.subscription_id ?? null,
        "subscription_addon_id": data?.subscription_addon_id ?? null
      }
      await downloadInvoiceFile(payload);
    } catch (err) {
      setError(err.message)
    }
    setLoading(false);
  }

  useEffect(() => {
    renderTenantCurrentPlan();
    renderTenantPlanHistory();
    //eslint-disable-next-line
  }, []);


  const startBilling = () => {
    setShowPlanBilling(true);
  }


  return (
    <>

      {showPlanBilling === true ?
        <>
          <Billing
            planData={[]}
            addOnData={planData}
            allowOtherPaymentmode={allowOtherPaymentmode}
            setShowPaymentmode={setShowPaymentmode}
            setBillingDetails={setBillingDetails}
            billingDetails={billingDetails}
            sharedplanid={null}
            history={history}
            setShowPlanBilling={setShowPlanBilling}
          />
        </>
        :

        <>

          {loading ? <LoadingIndicator /> : null}

          <ShowAlert
            error={error}
            success={success}
            onClose={() => { setError(null); setSuccess(null); }}
          />

          {showAddOnPopup &&
            <AddOnPopup
              show={showAddOnPopup}
              handleClose={() => { setShowAddOnPopup(false) }}
              apiData={planParameters}
              setPlanData={setPlanData}
              startBilling={startBilling}
            />
          }

          <div className="container mt-3">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 text-left">
                <Button className="f-12 pl-0 mysubplan_backbtn-toppadd" variant="link" style={{ color: '#FC7D72', padding:'0px' }}>
                  <Link className="backtoclr" to={BASE_PATH}>
                    {'< '}
                    Back to Deductor List
                  </Link>
                </Button>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <h4 className="mysubcribed-plantextfs">My Subscribed Plan</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h4 className="mysubgstplan-fs">{userPlanData && userPlanData.plan_name ? userPlanData.plan_name : ''}</h4>
              </div>



              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                {
                  parseInt(userPlanData?.addon_available ?? 0) === 1 &&
                  <button className="btn btn-default gstrretregbtnclr" data-toggle="modal" data-target="#mysubplan_addon_modalbox" style={{ marginRight: '5px' }}
                    onClick={() => { setShowAddOnPopup(true); }}>
                    Addon
                  </button>
                }

                {parseInt(userPlanData?.can_change_plan ?? 0) === 1 &&
                  <Link className="btn btn-default gstrretregbtnclr" to={`${BASE_PATH}plans?change_plan=1`} style={{ marginRight: '5px', }}>Change Plan</Link>
                }

                {parseInt(userPlanData?.can_sub ?? 1) === 1 &&
                  <Link className="btn btn-default gstrretregbtnclr" to={`${BASE_PATH}plans`} style={{ marginRight: '5px' }}>
                    {parseInt(userPlanData?.is_next_sub_renewal ?? 0) === 1 ? "Renew Now" : "Subscribe Now"}
                  </Link>
                }

              </div>

            </div>
            <div className="row">
              <div className="col-xl-12">
                <span className="form-check-inline mysub-plan-Subscribed-box">
                  <label className="form-check-label mysub-plan-Subtextfsbold subplantxtclr-coral">
                    {userPlanData && userPlanData.plan_status && userPlanData.plan_status.text ? userPlanData.plan_status.text : ''}
                  </label>

                  {userPlanData && userPlanData.start_at &&
                    <>
                      <label className="form-check-label Subtext-borderclr">| </label>
                      <label className="form-check-label mysub-plan-Subtextfs">Subscribed On:</label>
                      <label className="form-check-label mysub-plan-Subtextfs textboldsize">{userPlanData.start_at}</label>
                    </>
                  }

                  {userPlanData && userPlanData.end_at &&
                    <>
                      <label className="form-check-label Subtext-borderclr">| </label>
                      <label className="form-check-label mysub-plan-Subtextfs">Valid Till:</label>
                      <label className="form-check-label mysub-plan-Subtextfs textboldsize">{userPlanData.end_at}</label>
                    </>
                  }

                </span>
              </div>
              <div className="col-xl-12">
                <span className="form-check-inline mysub-plan-Subscribed-box">
                  {userPlanData && userPlanData.plan_type &&
                    <>
                      <label className="form-check-label mysub-plan-Subtextfs">Plan Type:</label>
                      <label className="form-check-label mysub-plan-Subtextfs textboldsize">{userPlanData.plan_type}</label>
                    </>
                  }

                  {(userPlanData && userPlanData.plan_type && userPlanData.plan_type === 'CA') &&
                    (userPlanData && userPlanData.plan_base_amt)
                    &&
                    <>
                      <label className="form-check-label Subtext-borderclr">| </label>
                      <label className="form-check-label mysub-plan-Subtextfs">Plan Price: </label>
                      <label className="form-check-label mysub-plan-Subtextfs textboldsize">{userPlanData.plan_base_amt}</label>
                    </>
                  }

                </span>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-md-12">
                <div className="card">
                  <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                    <table className="table table-striped mysubplan-table mb-0">
                      <thead>
                        <tr>
                          <th width="150">Feature</th>
                          <th width="150">Base Quantity</th>
                          {parseInt(userPlanData?.addon_available ?? 0) === 1 &&
                            <th width="150">Addon Quantity</th>}
                          <th width="150">Used Quantity</th>
                        </tr>

                      </thead>
                      <tbody>
                        {
                          userPlanData &&
                            userPlanData.plan_parameters &&
                            userPlanData.plan_parameters.length > 0
                            ?
                            userPlanData.plan_parameters.map(function (item) {
                              return (
                                <tr>
                                  <td className="mysubplan-overflow">
                                    <span data-toggle="tooltip" data-placement="top" title="No. of Returns">
                                      {item.parameter_name}
                                    </span>
                                  </td>
                                  <td className="text-center">{item?.base_parameter_value ?? 0}</td>
                                  {parseInt(userPlanData?.addon_available ?? 0) === 1 &&
                                    <td className="text-center">{item?.addon_parameter_value ?? 0}</td>
                                  }
                                  <td className="text-center"> <span className="mysubplan-tdtextbold">{item.used_value}</span></td>
                                </tr>
                              )
                            })
                            :
                            <tr><td colSpan={3} style={{ textAlign: 'center' }}>No Data!!</td></tr>

                        }

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-md-12 pt-3">
                <h4 className="mysubplan-histfs">History</h4>
                <div className="card">
                  <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                    <table className="table table-striped mysubplan-table mb-0">
                      <thead>
                        <tr>
                          <th width="150">Event Type</th>
                          <th width="150">Event Date</th>
                          <th width="150">Plan</th>
                          <th width="150">Amount Paid</th>
                          <th width="50"></th>
                        </tr>

                      </thead>
                      <tbody>
                        {
                          tenantPlanHistory && tenantPlanHistory.length > 0
                            ?
                            tenantPlanHistory.map(function (item) {
                              return (
                                <tr>
                                  <td className="mysubplan-overflow">
                                    <span data-toggle="tooltip" data-placement="top" title="Addon">
                                      {item.event_type}
                                    </span>
                                  </td>
                                  <td className="text-center">{item.start_at}</td>
                                  <td className="text-center">{item.plan_name}</td>
                                  
                                  <td className={`${item.plan_paid_amt === "-" ? "text-center" : "text-right"}`}>
                                    <span>{formatAccountsNumber(item.plan_paid_amt, '-')}</span>
                                  </td>
                                  <td className="text-center">
                                    {(item?.invoice_id ?? null) !== null &&
                                      (item?.subscription_id ?? null) !== null &&
                                      <FontAwesomeIcon icon={faDownload} onClick={() => { downloadInvoiceDetail(item) }} style={{ cursor: 'pointer' }} title="Download Invoice" />
                                    }
                                  </td>
                                </tr>
                              )
                            })
                            :
                            <tr>
                              <td colSpan={5} style={{ textAlign: 'center' }}>No Plan History!!</td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }

    </>

  );
};

export default Subscription;
