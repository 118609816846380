import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonGraphCircle = () => {
    return (
        <div style={{"display":"flex","justifyContent":"center"}}>
          
            <div>
            <Skeleton animation="wave" sx={{ textAlign: 'center' }} variant="circular" width={"200px"} height={"200px"} />
            </div>

            <div style={{marginLeft: "2rem", width: "10%"}}>
                <Skeleton animation="wave" variant='text' />
                <Skeleton animation="wave" variant='text'  />
                 <Skeleton animation="wave" variant='text' />
            </div>
        </div>


    )
}

export default SkeletonGraphCircle