import { useEffect } from 'react';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import {getUnconsumedChallanInfoToConnector} from '../../api/serverConnectorAPI'
import { importTracesUnconsumedChallansFromConnector } from '../../api/localConnectorAPI';

const ImportChallan = (props) => {

  const { deductorId, returnId, port, captchaRef, setError, statusKey, setStartImportOltasChallans } = props;


  useEffect(() => {

    const bulkPanVerification = (deductorId, returnId, port, captchaRef, setError, statusKey) => {

      async function fetchEverything() {
        try {
          // //call PAN listing API from server   
           
         const data = {
          deductor_id : deductorId,
          captcha_ref : captchaRef
         }
          
          const bulkPanResult = await getUnconsumedChallanInfoToConnector(data);
          const responseData = bulkPanResult.data ?? null;
          const responseHeader = bulkPanResult.headers ?? null;

          //Connector request data
          const connectorRequest = {
            "data": responseData.data
          };
          let extraHeader = {};
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          try {
            await importTracesUnconsumedChallansFromConnector(port, connectorRequest, extraHeader);

          } catch (err) {
            setError(CONNECTOR_ERRORS_MAPPING[err.message]);
          }

        } catch (err) {
          setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
        } finally {
          setStartImportOltasChallans(true);
        }
      }

      fetchEverything();
    };

    bulkPanVerification(deductorId, returnId, port, captchaRef, setError, statusKey);

    //eslint-disable-next-line
  }, [deductorId, returnId, port, captchaRef]);


  return (null);
};

export default ImportChallan;
