import React, { useEffect, useState } from 'react'
import WindowWrapperFixed from '../../components/grid/WindowWrapperFixed';
import ViewSalaryDetail from './ViewSalaryDetail';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import { downloadPDFSalary, getDeductionVIAParticulars, getEmployeeSalaryDetailV2 } from '../../api/EmployeeApi';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { getCurrentFinancialYear } from '../form3CD/Form3CDUtilityFunctions';
import "./SalaryDetail.scss";

const ViewSalaryDetailPopup = (props) => {

    const width1 = document.body.clientWidth;
    const { onClose, salaryId, deductorId, returnId } = props;

    const headerDataDetails = useSelector(selectHeaderData);
    const USER_TYPE = headerDataDetails?.organization_type === "CORPORATE" ? "Details" : "Lite";
    const popupWidth = 910;
    let financialYear = getCurrentFinancialYear();
    if (headerDataDetails.financial_year_formatted) {
        const getYearIndex = headerDataDetails.financial_year_formatted.split("-");
        if (getYearIndex[0]) financialYear = parseInt(getYearIndex[0]);
    }
    const [data, setData] = useState({});
    const [deducteeName, setDeducteeName] = useState("Karamjit | ASDPM1111F");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading,] = useState(false);
    const [drowdownList, setDropdownList] = useState({
        salary17one: [],
        perquisites_17_2: [],
        profit_17_3: [],
        othinc_off_det: [],
        deductionVI_A: {},
        allowanceExempt: [],
        retirebenfits: [],
    });



    async function fetchEmployeeSalaryDetail(salaryId, deductorId, returnId) {
        const result = await getEmployeeSalaryDetailV2(salaryId, deductorId, returnId);
        let apiData = result.data ?? {};
        let deductee_name = `${apiData.deductee_name} - ${apiData.pan}`;

        apiData.is_government_deductor = headerDataDetails.is_government_deductor;

        setDeducteeName(deductee_name);
        setData({ ...apiData });
    }

    const getEmployeeDetail = (salaryId, deductorId, returnId) => {
        async function fetchEverything() {
            try {
                await Promise.all([
                    fetchEmployeeSalaryDetail(salaryId, deductorId, returnId),
                ]);
                setError(null);
            } catch (err) {
                if (err.message) {
                    setError(err?.message);
                } else {
                    setError(err);
                }
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    const getDeductionVIAParticularsList = () => {
        async function fetchEverything() {
            async function fetchDeductionVIAParticulars() {
                const result = await getDeductionVIAParticulars(financialYear, headerDataDetails?.deductor_category);
                drowdownList.deductionVI_A = result?.data ?? {};
                setDropdownList({ ...drowdownList })
            }
            try {
                await Promise.all([
                    fetchDeductionVIAParticulars(),
                ]);
            } catch (err) {
            }
        }
        fetchEverything();
    };

    const downloadSalaryPdf = (salaryId, returnId, deductorId) => {
        async function fetchEverything() {
            const param = `?salary_id=${salaryId}&return_id=${returnId}&deductor_id=${deductorId}`;
            try {
                await downloadPDFSalary(param)
                setError(null);
            } catch (err) {
                if (err.message) {
                    setError(err?.message);
                } else {
                    setError(err);
                }
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };


    useEffect(() => {
        getEmployeeDetail(salaryId, deductorId, returnId);
        getDeductionVIAParticularsList()
        //eslint-disable-next-line
    }, [salaryId, deductorId]);

    return (
        <>
            {loading && <LoadingIndicator />}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => {
                    setError(null);
                    setSuccess(null);
                }}
            />

            <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ padding: '8px 0px 3px 0px', width: "1000%" }}>

                    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <div className="col-md-4">
                                <h4 className="viewmodetitltextfs_only" title={deducteeName ?? ""}>
                                    {deducteeName ?? ""}
                                </h4>

                            </div>
                            <div className="col-md-2">
                                <span className="taxregim_textfs_view pr-3">
                                    {`Tax Regime : ${data.tax_regime === "NEW" ? "New" : "Old"}`}
                                </span>
                                
                            </div>
                            <div className="col-md-6">

                                <span className="input-group salarytaxreg_daterangegrp_view mb-2">
                                    <span className="salarytaxreg_daterangegrp_textfs pr-1">Employment Period<span className="textred">*</span> : </span>
                                    <DateRangePicker
                                        title="trammmsdifaowjfoewjfoewjfoewjfowejf"
                                        value={
                                            {
                                                start: data.employment_period_from ? new Date(`${moment(data.employment_period_from, "DD/MM/YYYY")}`) : null,
                                                end: data.employment_period_to ? new Date(`${moment(data.employment_period_to, "DD/MM/YYYY")}`) : null
                                            }
                                        }
                                        onChange={(e) => {
                                            data.employment_period_from = e.target.value?.start ? moment(e.target.value?.start).format("DD/MM/YYYY") : null;
                                            data.employment_period_to = e.target.value?.end ? moment(e.target.value?.end).format("DD/MM/YYYY") : null;
                                            setData({ ...data });
                                        }}
                                        min={new Date(`${moment(`01/04/${financialYear}`, "DD/MM/YYYY")}`)}
                                        max={new Date(`${moment(`31/03/${financialYear + 1}`, "DD/MM/YYYY")}`)}
                                        disabled
                                        endDateInputSettings={{
                                            title: ``,
                                            className: `toerrorclass`,
                                            format: "dd/MM/yyyy",
                                            formatPlaceholder: {
                                                year: "YYYY",
                                                month: "MM",
                                                day: "DD"
                                            }

                                        }}
                                        startDateInputSettings={{
                                            title: ``,
                                            className: `fromerrorclass`,
                                            format: "dd/MM/yyyy",
                                            formatPlaceholder: {
                                                year: "YYYY",
                                                month: "MM",
                                                day: "DD"
                                            }

                                        }}
                                    />

                                <button className="btn btn-default salary_taxreg_downloadpdf ml-2" onClick={() => downloadSalaryPdf(salaryId, returnId, deductorId)}>Download</button>
                                </span>
                            </div>
                        </div>

                    </div>
                </kendo-dialog-titlebar>}
                onClose={onClose}
                initialHeight={'auto'}
                initialWidth={900}
                initialTop={50}
                totalPopups={1}
                isModalView={true}
                initialLeft={width1 - popupWidth}
                className={'salarary_detail_popup viewonlysalarydetail'}
            >
                <ViewSalaryDetail data={data} drowdownList={drowdownList} USER_TYPE={USER_TYPE}
                />
            </WindowWrapperFixed>
        </>
    )
}

export default ViewSalaryDetailPopup