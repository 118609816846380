/* eslint-disable react/prop-types */
/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
    Formik, Form, Field,
} from 'formik';
import './SalaryDetails.css';
import deleteIcon from '../../../../images/icons/delete.svg';
import { manageSalaryData, tdsReturnValidNumber, roundUpValue } from '../../../../utils/UtilityFunctions';
import addBtnRoundIcon from '../../../../images/icons/add_btn_round.svg';
import './SalaryDetails.css';
import PANInput from '../../../../components/PANInput';
import NumericInputWithDecimalTwo from '../../../../components/NumericInputWithDecimalTwo';
import NumericInputWithDecimalFour from '../../../../components/NumericInputWithDecimalFour';
import { otherDetailsvalidationSchema } from './validationSchema';
import DateRangePickers from '../../../../components/datePicker/DateRangePicker';
import { getInitializeData, resetInitialData } from './salaryHelper';
import { calculateTaxComputed } from './calculationScema';
import $ from 'jquery';

const OtherDetails = (props) => {
    const { otherData, addSalary, viewComputation, headerDataDetails, componentImport, actionStatus } = props;

    const landlordHandleChange = (value, key, index, setFieldValue, values) => {
        // landlord values set on change
        let landlordBreakup = values.landlord_details;
        landlordBreakup[index][key] = value;
        setFieldValue('landlord_details', landlordBreakup);
    }

    const lenderHandleChange = (value, key, index, setFieldValue, values) => {
        // lender values set on change
        let lenderBreakup = values.lender_details;
        lenderBreakup[index][key] = value;
        setFieldValue('lender_details', lenderBreakup);
    }

    const superannuationHandleChange = (e, key, setFieldValue, values, isRoundUpValue=false) => {
        // Superannuation Fund values set on change
        values[key] = isRoundUpValue ? roundUpValue(e.target.value) : e.target.value;
        setFieldValue(key, isRoundUpValue ? roundUpValue(e.target.value) : e.target.value);
        if (key === "tax_rate_b100" || key === 'fund_received_amtp') {
            values.fund_received_amtp = parseInt(values.fund_received_amtp) > 0 ? values.fund_received_amtp : 0;
            values.tax_rate_b100 = parseInt(values.tax_rate_b100) > 0 ? values.tax_rate_b100 : 0;
            let TdsDeducted = parseFloat(values.fund_received_amtp) * parseFloat(values.tax_rate_b100) / 100;
            setFieldValue('tds_deducted_amtp', TdsDeducted.toFixed(2));
            values.tds_deducted_amtp = TdsDeducted.toFixed(2);
        }
        totalNetTaxableIncome(values, setFieldValue);
    }
    const totalNetTaxableIncome = (values, setFieldValue) => {
        let total_net_taxable_income_amtp = 0;
        total_net_taxable_income_amtp = (parseFloat(otherData.total_gross_income_amtp) - parseFloat(otherData.total_deduction_vi_a_amtp)).toFixed(2);

        let net_taxable_income = otherData.net_taxable_income;
        net_taxable_income.total_net_taxable_income_amtp = total_net_taxable_income_amtp;
        otherData.other_details = values;
        net_taxable_income = calculateTaxComputed(net_taxable_income, otherData, parseInt(headerDataDetails?.financial_year_formatted.split("-")?.[0] ?? 0));
        setFieldValue('net_taxable_income', net_taxable_income);
    }

    const addLandlord = (setFieldValue, values) => {
        //add landlord when cliked on add icon  
        let landlord_details = values.landlord_details;
        if (landlord_details.length < 4) {
            landlord_details.push({
                name_of_landlord: '', pan_of_landlord: '', rent_amount_amtp: 0
            });
            setFieldValue('landlord_details', landlord_details);
        }
    }

    const removeLandlord = (landlordIndex, setFieldValue, values) => {
        //delete landlord when cliked on delete icon
        let landlord_details = values.landlord_details;
        landlord_details = landlord_details.filter((data, index) => { return landlordIndex !== index; });
        setFieldValue('landlord_details', landlord_details);
    }

    const removeAllLandlord = (setFieldValue, values) => {
        //blank landlord in case of no radio button
        let landlord_details = [];
        if (values.whether_total_rent_greater_onelakh === 1) {
            landlord_details.push({
                name_of_landlord: '', pan_of_landlord: '', rent_amount_amtp: 0
            });
        }
        setFieldValue('landlord_details', landlord_details);
    }

    const addLender = (setFieldValue, values) => {
        //add lender when cliked on add icon
        let lender_details = values.lender_details;
        if (lender_details.length < 4) {
            lender_details.push({
                name_of_lender: '', pan_of_lender: '', interest_amount_amtp: 0
            });
            setFieldValue('lender_details', lender_details);
        }
    }

    const removeLender = (lenderIndex, setFieldValue, values) => {
        //delete lender when cliked on delete icon
        let lender_details = values.lender_details;
        lender_details = lender_details.filter((data, index) => { return lenderIndex !== index; });
        setFieldValue('lender_details', lender_details);
    }

    const removeAllLender = (setFieldValue, values) => {
        let lender_details = [];
        if (values.whether_interest_paid_to_lender === 1) {
            lender_details.push({
                name_of_lender: '', pan_of_lender: '', interest_amount_amtp: 0
            });
        }
        setFieldValue('lender_details', lender_details);
    }

    const removeSuperannuationValues = (setFieldValue, values) => {
        //blank superannuation all fields in case of no radio button

        if (values.whether_amtrec_from_superannuation_fund === 1) {
            setFieldValue('fund_name', '');
            setFieldValue('fund_from', '');
            setFieldValue('fund_to', '');
            setFieldValue('fund_received_amtp', '0');
            setFieldValue('tax_rate_b100', '0');
            setFieldValue('tds_deducted_amtp', '0')
        }
    }

    const submitPeriodDateRangeBox = (fieldValue, setFieldValue) => {
        // select date from - to
        if (fieldValue) {
            let dateValue = fieldValue.split('-');
            setFieldValue('fund_from', dateValue[0].trim());
            setFieldValue('fund_to', dateValue[1].trim());
        }
    };


    const disable_field = componentImport && actionStatus !== 'ADDED' && otherData.salary_id ? true : false;
    useEffect(() => {
        if (componentImport && actionStatus !== 'ADDED' && otherData.salary_id) {
            $("#other_details_form :input").attr("disabled", disable_field);
        }
        $(".daterangepicker").addClass('otherDetailsDatePicker');
    }, []);

    return (
        <div className="container">
            <Formik
                initialValues={otherData.other_details}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={otherDetailsvalidationSchema}
                onSubmit={async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
                    let submitValues = { ...values };
                    let save_success = await addSalary({ ...otherData, ...{ save_type: values.save_type }, ...{ other_details: submitValues } });
                    if (save_success) {
                        resetForm(getInitializeData(otherData.deductor_id, otherData.is_government_deductor, otherData.financial_year));
                    }
                    else {
                        resetInitialData(otherData);
                    }
                }}
            >
                {({
                    errors, values, setFieldValue,
                }) => {

                    if (!otherData.salary_id) {
                        let submitValues = otherData;
                        submitValues.other_details = values;
                        setTimeout(function () {
                            manageSalaryData('save', headerDataDetails.tan, otherData.financial_year, submitValues);
                        }, 1000);
                    }

                    if (Object.keys(errors).length > 0) {
                        document.getElementById('save-other-details').disabled = false;
                        document.getElementById('save-new-other-details').disabled = false;
                    }
                    return (
                        <Form key={1} autoComplete="off" className="other_details_form">
                            <div className="row" id="other_details_form">
                                <div className="col-md-12 pt-2">
                                    <div className="card grosssalaryph3card">
                                        <div className="card-header">
                                            Whether Total Rent Paid 1 Lakh?
                                            <span className="float-right">
                                                <div className="form-check form-check-inline">
                                                    <input id="wether_total_rent_yes" name="whether_total_rent_greater_onelakh" disabled={disable_field} value="1" checked={Number(values.whether_total_rent_greater_onelakh) === 1 ? true : false} onChange={() => { setFieldValue('whether_total_rent_greater_onelakh', 1) }} className="form-check-input" type="radio" />
                                                    <label className="form-check-label" for="wether_total_rent_yes">Yes</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input id="wether_total_rent_no" name="whether_total_rent_greater_onelakh" disabled={disable_field} value="0" checked={Number(values.whether_total_rent_greater_onelakh) === 0 ? true : false} onChange={() => { setFieldValue('whether_total_rent_greater_onelakh', 0); removeAllLandlord(setFieldValue, values) }} className="form-check-input" type="radio" />
                                                    <label className="form-check-label" for="wether_total_rent_no">No</label>
                                                </div>
                                            </span>
                                        </div>

                                        {values.landlord_details.map((landlord_data, landlord_index) => (
                                            <div className="card-body rentPaid" key={landlord_index}>

                                                <div className="row">

                                                    <div className="form-group col-md-4">
                                                        <label for="" className="">
                                                            Name of Landlord
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            id="name_of_landlord"
                                                            placeholder="Name of Lender"
                                                            name="name_of_landlord"
                                                            maxLength={75}
                                                            value={landlord_data.name_of_landlord}
                                                            disabled={!values.whether_total_rent_greater_onelakh}
                                                            onChange={(e) => {
                                                                landlordHandleChange(e.target.value, 'name_of_landlord', landlord_index, setFieldValue, values);
                                                            }}
                                                        />
                                                        {errors.landlord_details && errors.landlord_details[landlord_index] && errors.landlord_details[landlord_index].name_of_landlord ? (
                                                            <span className="error_message">
                                                                {errors.landlord_details[landlord_index].name_of_landlord}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label for="" className="">
                                                            PAN of Landlord
                                                        </label>
                                                        <PANInput
                                                            value={landlord_data.pan_of_landlord}
                                                            disabled={!values.whether_total_rent_greater_onelakh}
                                                            id="pan_of_landlord"
                                                            onChange={(v) => {
                                                                landlordHandleChange(v.trim().toUpperCase(), 'pan_of_landlord', landlord_index, setFieldValue, values);
                                                            }}
                                                            data={['GOVERNMENT', 'NONRESIDENT', 'OTHERVALUE']}
                                                        />
                                                        {errors.landlord_details && errors.landlord_details[landlord_index] && errors.landlord_details[landlord_index].pan_of_landlord ? (
                                                            <span className="error_message">
                                                                {errors.landlord_details[landlord_index].pan_of_landlord}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label for="" className="">
                                                            Rent Amt
                                                        </label>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            disabled={!values.whether_total_rent_greater_onelakh}
                                                            className="form-control text-right"
                                                            id="rent_amount_amtp"
                                                            value={landlord_data.rent_amount_amtp}
                                                            placeholder="Enter Rent Amount"
                                                            onChange={(e) => {
                                                                if (!tdsReturnValidNumber(e.target.value)) return;

                                                                landlordHandleChange(e.target.value, 'rent_amount_amtp', landlord_index, setFieldValue, values);
                                                            }}
                                                            onBlur={(e) => {
                                                                if (!tdsReturnValidNumber(e.target.value)) return;

                                                                landlordHandleChange(roundUpValue(e.target.value), 'rent_amount_amtp', landlord_index, setFieldValue, values);
                                                            }}
                                                        />
                                                        {errors.landlord_details && errors.landlord_details[landlord_index] && errors.landlord_details[landlord_index].rent_amount_amtp ? (
                                                            <span className="error_message">
                                                                {errors.landlord_details[landlord_index].rent_amount_amtp}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <span className="whethericonsect" style={values.whether_total_rent_greater_onelakh === 0 ? { pointerEvents: 'none', opacity: '0.5' } : { pointerEvents: 'auto', opacity: '1' }}>
                                                            {landlord_index === parseInt(values.landlord_details.length) - 1 && landlord_index < 3 && <span className="Wplusiconleft"><img src={addBtnRoundIcon} alt="" onClick={() => { addLandlord(setFieldValue, values) }} /> </span>}
                                                            {landlord_index > 0 ? <span className=""><img src={deleteIcon} alt="" onClick={() => { removeLandlord(landlord_index, setFieldValue, values) }} /></span> : null}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                                <div className="col-md-12 pt-2">
                                    <div className="card grosssalaryph3card">
                                        <div className="card-header">
                                            Whether Interest Paid to Lender
                                            <span className="float-right">
                                                <div className="form-check form-check-inline">
                                                    <input id="wether_interest_Yes" name="whether_interest_paid_to_lender" disabled={disable_field} checked={Number(values.whether_interest_paid_to_lender) === 1 ? true : false} onChange={() => { setFieldValue('whether_interest_paid_to_lender', 1) }} className="form-check-input" type="radio" />
                                                    <label className="form-check-label" for="">Yes</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input id="wether_interest_no" name="whether_interest_paid_to_lender" disabled={disable_field} checked={Number(values.whether_interest_paid_to_lender) === 0 ? true : false} className="form-check-input" onChange={() => { setFieldValue('whether_interest_paid_to_lender', 0); removeAllLender(setFieldValue, values); }} type="radio" />
                                                    <label className="form-check-label" for="">No</label>
                                                </div>
                                            </span>
                                        </div>

                                        {values.lender_details.map((lender_data, lender_index) => (
                                            <div className="card-body interestPaid" key={lender_index}>

                                                <div className="row">

                                                    <div className="form-group col-md-4">
                                                        <label for="" className="">
                                                            Name of Lender
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            disabled={!values.whether_interest_paid_to_lender}
                                                            className="form-control"
                                                            id="name_of_lender"
                                                            placeholder="Name of Lender"
                                                            name="name_of_lender"
                                                            maxLength={75}
                                                            value={lender_data.name_of_lender}
                                                            onChange={(e) => {
                                                                lenderHandleChange(e.target.value, 'name_of_lender', lender_index, setFieldValue, values);
                                                            }}
                                                        />
                                                        {errors.lender_details && errors.lender_details[lender_index] && errors.lender_details[lender_index].name_of_lender ? (
                                                            <span className="error_message">
                                                                {errors.lender_details[lender_index].name_of_lender}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label for="" className="">
                                                            PAN of Lender
                                                        </label>
                                                        <PANInput
                                                            value={lender_data.pan_of_lender}
                                                            disabled={!values.whether_interest_paid_to_lender}
                                                            id="pan_of_lender"
                                                            onChange={(v) => {
                                                                lenderHandleChange(v.trim().toUpperCase(), 'pan_of_lender', lender_index, setFieldValue, values);
                                                            }}
                                                            data={['GOVERNMENT', 'NONRESIDENT', 'OTHERVALUE']}
                                                        />
                                                        {errors.lender_details && errors.lender_details[lender_index] && errors.lender_details[lender_index].pan_of_lender ? (
                                                            <span className="error_message">
                                                                {errors.lender_details[lender_index].pan_of_lender}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label for="" className="">
                                                            Interest Amt
                                                        </label>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            disabled={!values.whether_interest_paid_to_lender}
                                                            className="form-control text-right"
                                                            id="interest_amount_amtp"
                                                            value={lender_data.interest_amount_amtp}
                                                            placeholder="Enter Interest Amount"
                                                            onChange={(e) => {
                                                                if (!tdsReturnValidNumber(e.target.value)) return;

                                                                lenderHandleChange(e.target.value, 'interest_amount_amtp', lender_index, setFieldValue, values);
                                                            }}
                                                            onBlur={(e) => {
                                                                if (!tdsReturnValidNumber(e.target.value)) return;

                                                                lenderHandleChange(roundUpValue(e.target.value), 'interest_amount_amtp', lender_index, setFieldValue, values);
                                                            }}
                                                        />
                                                        {errors.lender_details && errors.lender_details[lender_index] && errors.lender_details[lender_index].interest_amount_amtp ? (
                                                            <span className="error_message">
                                                                {errors.lender_details[lender_index].interest_amount_amtp}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <span className="whethericonsect" style={values.whether_interest_paid_to_lender === 0 ? { pointerEvents: 'none', opacity: '0.5' } : { pointerEvents: 'auto', opacity: '1' }}>
                                                            {lender_index === parseInt(values.lender_details.length) - 1 && lender_index < 3 && <span className="Wplusiconleft"><img src={addBtnRoundIcon} alt="" onClick={() => { addLender(setFieldValue, values) }} /> </span>}
                                                            {lender_index > 0 ? <span className=""><img src={deleteIcon} alt="" onClick={() => { removeLender(lender_index, setFieldValue, values) }} /></span> : null}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                                <div className="col-md-12 pt-2 pb-5">
                                    <div className="card grosssalaryph3card">
                                        <div className="card-header">
                                            Whether Amt Rec. from Superannuation Fund?
                                            <span className="float-right">
                                                <div className="form-check form-check-inline">
                                                    <input name="whether_amtrec_from_superannuation_fund" disabled={disable_field} checked={Number(values.whether_amtrec_from_superannuation_fund) === 1 ? true : false} onChange={() => { setFieldValue('whether_amtrec_from_superannuation_fund', 1) }} className="form-check-input" id="yes" type="radio" />
                                                    <label className="form-check-label" for="yes">Yes</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input name="whether_amtrec_from_superannuation_fund" disabled={disable_field} checked={Number(values.whether_amtrec_from_superannuation_fund) === 0 ? true : false} onChange={() => { setFieldValue('whether_amtrec_from_superannuation_fund', 0); removeSuperannuationValues(setFieldValue, values); }} className="form-check-input" id="no" type="radio" />
                                                    <label className="form-check-label" for="no">No</label>
                                                </div>
                                            </span>
                                        </div>
                                        <div className="card-body">

                                            <div className="row">

                                                <div className="form-group col-md-4">
                                                    <label for="" className="">
                                                        Name of Fund
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        disabled={!values.whether_amtrec_from_superannuation_fund}
                                                        className="form-control"
                                                        id="fund_name"
                                                        placeholder="Name of Fund"
                                                        name="fund_name"
                                                        maxLength={75}
                                                        value={values.fund_name}
                                                        onChange={(e) => {
                                                            superannuationHandleChange(e, 'fund_name', setFieldValue, values);
                                                        }}
                                                    />
                                                    {errors.fund_name ? (
                                                        <span className="error_message">
                                                            {errors.fund_name}
                                                        </span>
                                                    ) : null}
                                                    {(componentImport && otherData && otherData.other_details && otherData.other_details.old_fund_name) ? (<div className="error_message text-right" style={{ color: '#0000ff' }}>{otherData.other_details.old_fund_name}</div>) : null}

                                                </div>
                                                <div className="form-group col-md-8">
                                                    <label htmlFor="" className="">From - To<span className="red_text">*</span></label>
                                                    <DateRangePickers

                                                        initialSettings={
                                                            values.fund_from && values.fund_to ?
                                                                {
                                                                    startDate: values.fund_from, endDate: values.fund_to,
                                                                    minDate: `01/04/${otherData.financial_year}`,
                                                                    maxDate: `31/03/${parseInt(otherData.financial_year, 10) + 1}`,
                                                                    locale: {
                                                                        format: 'DD/MM/YYYY',
                                                                    },
                                                                } :
                                                                {
                                                                    minDate: `01/04/${otherData.financial_year}`,
                                                                    maxDate: `31/03/${parseInt(otherData.financial_year, 10) + 1}`,
                                                                    autoUpdateInput: false,
                                                                    locale: {
                                                                        format: 'DD/MM/YYYY',
                                                                    },
                                                                }
                                                        }
                                                        disabled={!values.whether_amtrec_from_superannuation_fund}
                                                        onApply={(value) => {
                                                            submitPeriodDateRangeBox(value, setFieldValue);
                                                        }}
                                                        onHide={(value) => {
                                                            submitPeriodDateRangeBox(value, setFieldValue);
                                                        }}
                                                    />

                                                    {errors.fund_from && !errors.fund_to ? (
                                                        <span className="error_message">
                                                            {errors.fund_from}
                                                        </span>
                                                    ) : null}

                                                    {errors.fund_to && !errors.fund_from ? (
                                                        <span className="error_message">
                                                            {errors.fund_to}
                                                        </span>
                                                    ) : null}

                                                    {errors.fund_to && errors.fund_from ? (
                                                        <span className="error_message">
                                                            {errors.fund_from}
                                                        </span>
                                                    ) : null}
                                                    {(componentImport && otherData && otherData.other_details && otherData.other_details.old_fund_from) ? (<div className="error_message text-right" style={{ color: '#0000ff' }}>{otherData.other_details.old_fund_from + ' - ' + otherData.other_details.old_fund_to}</div>) : null}
                                                </div>

                                            </div>
                                            <div className="row">

                                                <div className="form-group col-md-4">
                                                    <label for="" className="">
                                                        Amt Received
                                                    </label>
                                                    <NumericInputWithDecimalTwo
                                                        type="text"
                                                        disabled={!values.whether_amtrec_from_superannuation_fund}
                                                        className="form-control text-right"
                                                        id="fund_received_amtp"
                                                        value={values.fund_received_amtp}
                                                        placeholder="Enter Received Amount"
                                                        onChange={(e) => {
                                                            if (!tdsReturnValidNumber(e.target.value)) return;

                                                            superannuationHandleChange(e, 'fund_received_amtp', setFieldValue, values);
                                                        }}
                                                        onBlur={(e) => {
                                                            if (!tdsReturnValidNumber(e.target.value)) return;

                                                            superannuationHandleChange(e, 'fund_received_amtp', setFieldValue, values, true);
                                                        }}
                                                    />
                                                    {errors.fund_received_amtp ? (
                                                        <span className="error_message">
                                                            {errors.fund_received_amtp}
                                                        </span>
                                                    ) : null}
                                                    {(componentImport && otherData && otherData.other_details && otherData.other_details.old_fund_received_amtp && otherData.other_details.old_fund_received_amtp > 0) ? (<div className="error_message text-right" style={{ color: '#0000ff' }}>{otherData.other_details.old_fund_received_amtp}</div>) : null}
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label for="" className="">Tax Rate</label>
                                                    <NumericInputWithDecimalFour
                                                        type="text"
                                                        disabled={!values.whether_amtrec_from_superannuation_fund}
                                                        className="form-control text-right"
                                                        id="tax_rate_b100"
                                                        value={values.tax_rate_b100}
                                                        placeholder="Enter Tax Rate"
                                                        onChange={(e) => {
                                                            if (!tdsReturnValidNumber(e.target.value)) return;

                                                            superannuationHandleChange(e, 'tax_rate_b100', setFieldValue, values);
                                                        }}
                                                    />
                                                    {errors.tax_rate_b100 ? (
                                                        <span className="error_message">
                                                            {errors.tax_rate_b100}
                                                        </span>
                                                    ) : null}
                                                    {(componentImport && otherData && otherData.other_details && otherData.other_details.old_tax_rate_b100 && otherData.other_details.old_tax_rate_b100 > 0) ? (<div className="error_message text-right" style={{ color: '#0000ff' }}>{otherData.other_details.old_tax_rate_b100}</div>) : null}
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label for="" className=""> TDS Deducted </label>
                                                    <NumericInputWithDecimalTwo
                                                        type="text"
                                                        disabled={!values.whether_amtrec_from_superannuation_fund}
                                                        className="form-control text-right"
                                                        id="tds_deducted_amtp"
                                                        value={roundUpValue(values.tds_deducted_amtp)}
                                                        placeholder="Enter TDS"
                                                        onChange={(e) => {
                                                            superannuationHandleChange(e, 'tds_deducted_amtp', setFieldValue, values);
                                                        }}
                                                        onBlur={(e) => {
                                                            superannuationHandleChange(e, 'tds_deducted_amtp', setFieldValue, values, true);
                                                        }}
                                                    />
                                                    {errors.tds_deducted_amtp ? (
                                                        <span className="error_message">
                                                            {errors.tds_deducted_amtp}
                                                        </span>
                                                    ) : null}
                                                    {(componentImport && otherData && otherData.other_details && otherData.other_details.old_tds_deducted_amtp && otherData.other_details.old_tds_deducted_amtp > 0) ? (<div className="error_message text-right" style={{ color: '#0000ff' }}>{otherData.other_details.old_tds_deducted_amtp}</div>) : null}
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    {/* <div className="savebtnxsdisblock"> */}
                                        <button type="submit" id="save-new-other-details" onClick={() => setFieldValue('save_type', 'new')} className="btn btn-default grossph3btnblueclr mb-1">SAVE & NEW </button>
                                        <button type="submit" id="save-other-details" data-popup="salary_details" onClick={() => setFieldValue('save_type', 'other_details')} className="btn btn-default grossph3btnblueclr mr-2 ml-2 mb-1">SAVE</button>
                                        <button type="button" className="btn btn-default grossph3btnblueclr mb-1"
                                            onClick={() => {
                                                let submitValues = otherData;
                                                submitValues.other_details = values;
                                                viewComputation(submitValues);
                                            }}
                                        >VIEW COMPUTATION</button>
                                    {/* </div> */}
                                </div>
                            </div>
                        </Form>
                    );

                }}
            </Formik>
        </div >
    );
};

export default OtherDetails;
