import React from 'react';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import Profile from '../../components/profile/Profile';

const UserProfile = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const userId = (params?.["user_id"] ?? "");

  return (
    <div>
      <Profile userId={userId} />
    </div>
  );
};

export default UserProfile;
