/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable require-jsdoc */
/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Formik, Form, Field,
} from 'formik';
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import { correctionDeducteeFormValidationSchema } from './DeducteeFormSchema';
import { deducteeFormFields } from './DeducteeFormHelper';
import ErrorFocus from '../ErrorFocus';
import './CreateDeductee.css';
import horizontalIcon from '../../images/horizontal_view.png';
import verticalIcon from '../../images/vertical_view.png';
import AlphaNumericInput from '../AlphaNumericInput';
import PANInput from '../PANInput';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import { selectHeaderData } from '../../features/header/headerSlice';
import ShowAlert from '../../components/ShowAlert';
import ConnectorAdapter from '../kdkConnector/ConnectorAdapter';
import TracesConnector from '../kdkConnector/TracesConnector';
import SinglePanVerify from '../verifyPAN/SinglePanVerify';
import SingleSelect from '../singleSelect/SingleSelect';
import { COUNTRTY_LIST, DEDUCTEE_TYPE_CORRECTION_RETURN } from '../../app/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import NumericInput from '../NumericInput';

const pattPAN = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

const DEDUCTEE_TYPE = [
  { label: 'Company', value: 'COMPANY' },
  { label: 'Non-Company', value: 'OTHER_THAN_COMPANY' },
];

export default function CreateCorrectionDeducteeForm(props) {

  const {
    deducteeInfo, createDeductee, onClose, deducteeId, deductorId, showDeducteeFormLeft, updateDeducteeInfo, deducteeList, currentDeductee, setCurrentDeductee, editDatainfo
  } = props;

  const [horizontalView, setHorizontalView] = useState(props.horizontalView ? props.horizontalView : false);
  const [verifyPANResult, setVerifyPANResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [showSinglePAN, setShowSinglePAN] = useState(false);
  const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);

  const [deducteeTypeOldList, setDeducteeTypeOldList] = useState(true)
  const headerDataDetails = useSelector(selectHeaderData);
  const defaultCountry = COUNTRTY_LIST[111];
  const [deducteeTypes, setDeducteeTypes] = useState(DEDUCTEE_TYPE);



  useEffect(() => {
    if (headerDataDetails.form_type === "27EQ" || headerDataDetails.form_type === "27Q") {
      if (headerDataDetails.financial_year_formatted.split("-")[0] >= 2023) {
        setDeducteeTypeOldList(false)
        setDeducteeTypes(DEDUCTEE_TYPE_CORRECTION_RETURN)
      }
    }

  }, [])
  useEffect(() => {
    if (!deducteeTypeOldList) {
      if ((deducteeInfo?.pan ?? '') !== '') {
        resetDeducteeTypeOptions(deducteeInfo?.pan);
      }
    }

    function initResizeElement() {
      const popups = document.getElementsByClassName('deductee-popup');
      let element = null;
      let startX; let startY; let startWidth; let
          startHeight;

      function doDragWidth(e) {
        element.style.width = `${startWidth - e.clientX + startX}px`;
      }

      function doDragHeight(e) {
        const cardHeight = $('.card').height() + 30;
        const newHeight = startHeight - e.clientY + startY;
        element.style.height = `${Math.min(cardHeight, newHeight)}px`;
      }

      function stopDrag() {
        document.documentElement.removeEventListener('mousemove', doDragWidth, false);
        document.documentElement.removeEventListener('mousemove', doDragHeight, false);
        document.documentElement.removeEventListener('mouseup', stopDrag, false);
      }

      function initDragWidth(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10,
        );
        document.documentElement.addEventListener('mousemove', doDragWidth, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      function initDragHeight(e) {
        element = this.parentPopup;

        startY = e.clientY;
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10,
        );
        document.documentElement.addEventListener('mousemove', doDragHeight, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      for (let i = 0; i < popups.length; i++) {
        const p = popups[i];

        const right = document.createElement('div');
        right.className = 'resizer-left';
        p.appendChild(right);
        right.addEventListener('mousedown', initDragWidth, false);
        right.parentPopup = p;

        const bottom = document.createElement('div');
        bottom.className = 'resizer-up';
        p.appendChild(bottom);
        bottom.addEventListener('mousedown', initDragHeight, false);
        bottom.parentPopup = p;
      }
    }

    $('#create-deductor').animate({
      opacity: '1',
    });

    // initDragElement();
    initResizeElement();

    $(window).keydown((e) => {
      if (e.altKey && !e.shiftKey && e.keyCode === 83) { /* alt+s */
        e.preventDefault();
        $('#save-deductee').trigger('click');
        $('#save-deductee').attr('disabled', true);
      }

      if (e.altKey && e.shiftKey && e.keyCode === 83) { /* alt+s */
        e.preventDefault();
        $('#save-deductee-new').trigger('click');
        $('#save-deductee-new').attr('disabled', true);
      }
    });
    $('#deductee_name').focus();
  }, []);

  const toggleHorizotalView = () => {
    setHorizontalView(!horizontalView);
  };


  const verifyTraces = async () => {
    setShowSinglePAN(true);
  }

  const prevDeductee = () => {
    let selectPage = 0;
    if (currentDeductee > 0) {
      selectPage = currentDeductee - 1;
    }
    if (currentDeductee !== selectPage) {
      setCurrentDeductee(selectPage);
      updateDeducteeInfo(deducteeList[selectPage], horizontalView);
    }
    else {
      onClose();
    }
  }

  const nextDeductee = () => {
    const selectPage = currentDeductee + 1;
    if (currentDeductee !== selectPage && selectPage < deducteeList.length) {
      setCurrentDeductee(selectPage);
      updateDeducteeInfo(deducteeList[selectPage], horizontalView);
    }
    else {
      onClose();
    }
  }


  const resetDeducteeTypeOptions = (InputPan, setFieldValue) => {
    let deducteeTypeOptions = [];
    let pan = InputPan.toUpperCase()
    let regexPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (['PANAPPLIED', 'PANINVALID', 'PANNOTAVBL'].includes(pan)) {
      deducteeTypeOptions = [{ label: 'Others', value: 'OTH_10' }];
    }
    else if (!regexPan.test(pan)) {
      deducteeTypeOptions = DEDUCTEE_TYPE_CORRECTION_RETURN
    }

    else {
      const chr = pan.charAt(3);
      switch (chr) {
        case 'C':
          deducteeTypeOptions = [{ label: 'Company, other than domestic company', value: 'COMPANY_01' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'COMPANY_01');
          break;
        case 'P':
          deducteeTypeOptions = [{ label: 'Individual', value: 'INDIVIDUAL_02' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'INDIVIDUAL_02');
          break;
        case 'H':
          deducteeTypeOptions = [{ label: 'Hindu Undivided Family', value: 'HUF_03' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'HUF_03');
          break;
        case 'F':
          deducteeTypeOptions = [{ label: 'Firm', value: 'FIRM_07' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'FIRM_07');
          break;
        case 'B':
          deducteeTypeOptions = [{ label: 'Body of individuals', value: 'BOI_08' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'BOI_08');
          break;
        case 'J':
          deducteeTypeOptions = [{ label: 'Artificial juridical person-AJP', value: 'AJP_09' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'AJP_09');
          break;
        case 'A':
          deducteeTypeOptions = [
            { label: "Association of Persons (AOP)-No Company Member", value: "AOP_04" },
            { label: "Association of Persons (AOP)- Only Company Members", value: "AOP_05" },
            { label: "Co-operative Society", value: "COOP_06" },
          ];
          break;
        default:
          deducteeTypeOptions = DEDUCTEE_TYPE_CORRECTION_RETURN;
          if (setFieldValue !== undefined) setFieldValue('deductee_type', '');
      }
    }
    setDeducteeTypes((prev) => {
      prev = deducteeTypeOptions
      return [...prev]
    });
  }

  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        onClose={() => { setError(null); }}
      />

      <div className="deductee-overlay" />
      <div className={horizontalView ? 'deductee-popup deductee-popup-horizontal creatededcandemp' : showDeducteeFormLeft ? 'deductee-popup deductee-popup-vertical-left' : 'deductee-popup deductee-popup-vertical '} id="create-deductor">
        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 deductee-form" style={{ padding: '0px' }}>
          <div className="float_card" style={{ borderBottom: '0px' }}>
            <Formik
              initialValues={deducteeInfo}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={correctionDeducteeFormValidationSchema}
              onSubmit={(values) => {
                // same shape as initial values
                createDeductee(values);
                $('#save-deductee, #save-deductee-new').attr('disabled', false);
              }}
            >
              {({
                errors, values, setFieldValue, validateForm, setErrors, resetForm,
              }) => {
                const submitAndResetForm = () => {
                  validateForm().then(async (data) => {
                    if (Object.keys(data).length > 0) {
                      setErrors(data);
                    } else {
                      const result = await createDeductee(values, false);
                      if (result) {
                        resetForm(deducteeFormFields);
                        $(".MyDatePicker input").val('');
                      }
                    }
                    $('#save-deductee, #save-deductee-new').attr('disabled', false);
                  });
                };

                if (Object.keys(errors).length > 0) {
                  $('#save-deductee, #save-deductee-new').attr('disabled', false);
                }

                return (
                  <Form key={1} autoComplete="off">

                    {showSinglePAN ?
                      <ConnectorAdapter
                        show={showSinglePAN}
                        handleClose={() => setShowSinglePAN(false)}
                        setPort={setPort}
                        handleProceed={() => setCheckTracesCredentials(true)}
                      />
                      : null}

                    {checkTracesCredentials && port
                      ? <TracesConnector
                        deductorId={deductorId}
                        returnId={null}
                        handleProceed={() => {
                          setShowSinglePAN(false);
                          setCheckTracesCredentials(false);
                        }}
                        port={port}
                        setCaptchaRef={setCaptchaRef}
                        setVerifyCreds={() => null}
                        creds={null}
                      />
                      : null
                    }

                    {captchaRef && port
                      ? <SinglePanVerify
                        deductorId={deductorId}
                        port={port}
                        captchaRef={captchaRef}
                        setError={setError}
                        setVerifyPANResult={setVerifyPANResult}
                        pan={values.pan}
                        setLoading={setLoading}
                        handleProceed={() => null}
                      />
                      : null
                    }



                    <ErrorFocus />
                    <div className="sticky_top">
                      <div className="deductee-popup-header">
                        <div className="row">

                          <div className="col-md-5 col-sm-5 col-lg-5 col-xl-5">
                            {horizontalView
                              ? (
                                <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12" style={{ display: 'flex', flexDirection: 'row' }}>
                                  <h6 style={{ cursor: 'default' }} className="col-md-6 col-sm-6 col-lg-6 col-xl-6 tlfsize">
                                    {deducteeList && <span style={{ cursor: 'pointer', color: '#0000ff' }} onClick={() => prevDeductee()} className="pr-1">&lt;</span>}
                                    {deducteeId ? 'Update' : 'Create'}
                                    Deductee/Employee
                                    {deducteeList && <span style={{ cursor: 'pointer', color: '#0000ff' }} onClick={() => nextDeductee()} className="pl-1">&gt;</span>}
                                  </h6>
                                </div>
                              )

                              : (
                                <h6 style={{ cursor: 'default' }} className="createDempfs">
                                  {deducteeList && <span style={{ cursor: 'pointer', color: '#0000ff' }} onClick={() => prevDeductee()} className="pr-1">&lt;</span>}
                                  {deducteeId ? 'Update' : 'Create'}
                                  Deductee/Employee
                                  {deducteeList && <span style={{ cursor: 'pointer', color: '#0000ff' }} onClick={() => nextDeductee()} className="pl-1">&gt;</span>}
                                </h6>
                              )}
                          </div>


                          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 view_btn" style={{ paddingTop: '5px' }}>
                            <Button
                              variant="link"
                              style={{
                                margin: '-8px', padding: '5px 0px', fontSize: '12px', fontWeight: '500', float: 'right',
                              }}
                              onClick={() => toggleHorizotalView()}
                              className="view_btn dark_color_text"
                            >
                              <img src={horizontalView ? verticalIcon : horizontalIcon} alt="" />
                              {' '}
                              Switch View
                            </Button>
                          </div>
                          <div
                            className="col-md-1 col-sm-1 col-lg-1 col-xl-1"
                            onClick={() => onClose()}
                            style={{ cursor: 'pointer' }}
                          >
                            <span style={{ float: 'right', fontSize: '0.8rem' }}>
                              <FontAwesomeIcon icon={faTimes} className='closeicon_clr' />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cdf mt-2">
                      <div className="row">
                        <div className={horizontalView ? 'col-lg-6 border-right' : 'col-lg-12'}>
                          <div className="">

                            <div className={horizontalView ? 'row' : 'row mt-2'}>
                              <div className="form-group col-md-6 pr-2">
                                <label htmlFor="deductee_name">
                                  Deductee/Employee Name
                                  <span className="red_text">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="deductee_name"
                                  placeholder="Enter Deductee/Employee Name"
                                  name="deductee_name"
                                  maxLength={75}
                                />
                                {(editDatainfo && editDatainfo.old_deductee_name) ? (<span className="error_message" style={{ color: editDatainfo.deductee_name_color ? `#${editDatainfo.deductee_name_color}` : '#0000ff' }}>{editDatainfo.old_deductee_name === '-' ? 'No previous value' : editDatainfo.old_deductee_name}</span>) : null}
                                {errors.deductee_name ? <span className="error_message">{errors.deductee_name}</span> : null}
                                {values?.name_on_pan && values?.name_on_pan !== "Not Available" ? <span>Name as per PAN : <span style={{ color: 'red' }}>{values?.name_on_pan}</span></span> : null}
                              </div>
                              <div className="form-group col-md-3 pl-2 zero_margin_bottom smmboxml">
                                <label htmlFor="pan">
                                  PAN
                                  <span className="red_text">*</span>
                                </label>
                                <PANInput
                                  value={values.pan}
                                  onChange={(v) => {
                                    setVerifyPANResult(null);
                                    setShowSinglePAN(null);
                                    setPort(0);
                                    setCaptchaRef(null);
                                    setFieldValue('pan', v.trim().toUpperCase());
                                    if (!deducteeTypeOldList) {
                                      resetDeducteeTypeOptions(v, setFieldValue)
                                    }


                                  }}
                                  data={['PANAPPLIED', 'PANINVALID', 'PANNOTAVBL']}
                                />
                                {errors.pan ? <span className="error_message">{errors.pan}</span> : null}

                                {verifyPANResult ? (<span className={verifyPANResult.toLowerCase() === 'active' ? 'float-right dark_green_color_text mt-1' : 'float-right dark_red_color_text mt-1'} style={{ fontSize: '11px' }}>{verifyPANResult}</span>)
                                  : (
                                    <div>
                                      {pattPAN.test(values.pan) ? (
                                        <small
                                          className="float-right dark_color_text mt-1 mb-2"
                                          style={{ cursor: 'pointer', fontSize: '11px' }}
                                          onClick={() => verifyTraces()}
                                        >
                                          Verify from TRACES
                                        </small>
                                      ) : null}
                                    </div>
                                  )}

                                {(editDatainfo && editDatainfo.old_pan && editDatainfo.old_pan.pan) ? (<span className="error_message" style={{ color: editDatainfo.old_pan.color ? `#${editDatainfo.old_pan.color}` : '#0000ff' }}>{editDatainfo.old_pan.pan === '-' ? 'No previous value' : editDatainfo.old_pan.pan}</span>) : null}


                              </div>
                              {(values.pan === 'PANAPPLIED' || values.pan === 'PANINVALID' || values.pan === 'PANNOTAVBL') ? (
                                <div className="form-group col-md-3 pl-0">
                                  <label htmlFor="ref_number">
                                    Ref No.
                                  </label>
                                  <AlphaNumericInput
                                    type="text"
                                    className="form-control"
                                    id="ref_number"
                                    placeholder="Enter Ref No."
                                    name="ref_number"
                                    value={values.ref_number}
                                    maxLength={10}
                                    onChange={(e) => {
                                      setFieldValue('ref_number', e.target.value.trim().toUpperCase());
                                    }}
                                  />
                                  {errors.ref_number ? <span className="error_message">{errors.ref_number}</span> : null}
                                  {(editDatainfo && editDatainfo.old_ref_number) ? (<span className="error_message" style={{ color: editDatainfo.ref_number_color ? `#${editDatainfo.ref_number_color}` : '#0000ff' }}>{editDatainfo.old_ref_number === '-' ? 'No previous value' : editDatainfo.old_ref_number}</span>) : null}

                                </div>
                              ) : null}
                            </div>
                            <div className={horizontalView ? 'row' : 'row mt-2'}>
                              <div className="form-group col-md-6 smmboxml">
                                <label htmlFor="deductee_type">
                                  Deductee Type
                                  <span className="red_text">*</span>
                                </label>
                                <Field
                                  as="select"
                                  className="form-control f-14"
                                  id="deductee_type"
                                  name="deductee_type"
                                >
                                  <option value="" disabled>Choose Type</option>
                                  {
                                    deducteeTypes.map((item) => {
                                      return (
                                        <option value={item?.value}>{item?.label}</option>
                                      )
                                    })
                                  }
                                </Field>
                                {errors.deductee_type ? <span className="error_message">{errors.deductee_type}</span> : null}
                                {(editDatainfo && editDatainfo.old_deductee_type) ? (<span className="error_message" style={{ color: editDatainfo.deductee_type_color ? `#${editDatainfo.deductee_type_color}` : '#0000ff' }}>{editDatainfo.old_deductee_type === '-' ? 'No previous value' : editDatainfo.old_deductee_type === "COMPANY" ? "Company" : editDatainfo.old_deductee_type === "OTHER_THAN_COMPANY" ? "Non Company" : ''}</span>) : null}

                              </div>

                              <div className="form-group col-md-6 smmboxml">
                                <label htmlFor="salary_category">Category</label>
                                <Field
                                  as="select"
                                  className="form-control f-14"
                                  name="salary_category"
                                  disabled={values.dob ? true : false}
                                >
                                  <option value="">Choose Category</option>
                                  <option value="W">Woman</option>
                                  <option value="G">Other</option>
                                  <option value="S">Senior Citizen</option>
                                  <option value="O"> Super Senior Citizen</option>
                                </Field>
                                {errors.salary_category ? <span className="error_message">{errors.salary_category}</span> : null}
                                {(editDatainfo && editDatainfo.old_salary_user_category) ? (<span className="error_message" style={{ color: editDatainfo.salary_user_category_color ? `#${editDatainfo.salary_user_category_color}` : '#0000ff' }}>
                                  {editDatainfo.old_salary_user_category === '-' ? 'No previous value' : editDatainfo.old_salary_user_category === "G" ? "Other" : editDatainfo.old_salary_user_category === "W" ? "Woman" : editDatainfo.old_salary_user_category === "S" ? "Senior Citizen" : editDatainfo.old_salary_user_category === "O" ? "Super Senior Citizen" : ''}
                                </span>)
                                  : null}

                                  </div>
                            </div>
                          </div>
                        </div>
                        <div className={horizontalView ? 'col-lg-6' : 'col-lg-12'}>
                          <div className="row pl-3">

                            {headerDataDetails && headerDataDetails.form_type === '27Q' ? (<>
                              <div className={horizontalView ? 'form-group col-md-3 pl-0' : 'form-group col-md-6 pl-0'}>
                                <label htmlFor="mobile_no">
                                  Mobile No.
                                </label>
                                <NumericInput
                                  type="text"
                                  className="form-control"
                                  id="mobile_no"
                                  placeholder="Enter Mobile No"
                                  name="mobile_no"
                                  maxLength={10}
                                  value={values.mobile_no}
                                  onChange={(e) => setFieldValue('mobile_no', e.target.value)}
                                />
                                {errors.mobile_no ? <span className="error_message">{errors.mobile_no}</span> : null}
                              </div>
                              <div className={horizontalView ? 'form-group col-md-3 pl-0' : 'form-group col-md-6 pl-0'}>
                                <label htmlFor="email">
                                  Email
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  placeholder="Enter Email"
                                  name="email"
                                />
                                {errors.email ? <span className="error_message">{errors.email}</span> : null}
                              </div>
                              <div className={horizontalView ? 'form-group col-md-3 pl-0' : 'form-group col-md-6 pl-0'}>
                                <label htmlFor="address">
                                  Address
                                </label>
                                <Field
                                  type="uniquecode*"
                                  className="form-control"
                                  id="address"
                                  placeholder="Enter Address"
                                  name="address"
                                />
                                {errors.address ? <span className="error_message">{errors.address}</span> : null}
                              </div></>
                            ) : null}


                            {headerDataDetails && headerDataDetails.form_type === '27EQ' ? (
                              <div className='form-group col-md-6 pl-0'>
                                <div>
                                  <label htmlFor="non_resident">Is Non-Resident?</label>
                                  <Field
                                    as="select"
                                    className="form-control f-14"
                                    name="non_resident"
                                  >
                                    <option value="">Select</option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </Field>
                                  {errors.non_resident ? <span className="error_message">{errors.non_resident}</span> : null}
                                </div>
                              </div>
                            ) : null}

                            {headerDataDetails && headerDataDetails.form_type === '27EQ' ? (
                              <div className='form-group col-md-6 pl-0'>
                                <div>
                                  <label htmlFor="permanent_establishment">Permanent Establishment in India?</label>
                                  <Field
                                    as="select"
                                    className="form-control f-14"
                                    name="permanent_establishment"
                                  >
                                    <option value="">Select</option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </Field>
                                  {errors.permanent_establishment ? <span className="error_message">{errors.permanent_establishment}</span> : null}
                                </div>
                              </div>
                            ) : null}

                            {headerDataDetails && headerDataDetails.form_type === '27Q' && <>
                              <div className={horizontalView ? 'form-group col-md-4' : 'form-group col-md-6 pl-0'}>
                                <label htmlFor="country_id">
                                  Country
                                </label>

                                <SingleSelect
                                  key={defaultCountry.value}
                                  value={COUNTRTY_LIST[COUNTRTY_LIST.findIndex((item) => String(item.value) === String(values.country_id))] ?? defaultCountry}
                                  onChange={(selected) => setFieldValue('country_id', selected.value)}
                                  options={COUNTRTY_LIST}
                                  placeholder="Select Country"
                                />
                                {errors.country_id ? <span className="error_message">{errors.country_id}</span> : null}
                                {errors && errors.country_id && errors.country_id.length > 0 ?
                                  <span className="error_message">{errors.country_id[0]}</span>
                                  : null}
                              </div>
                              <div className={horizontalView ? 'form-group col-md-2 px-0 smmboxml' : 'form-group col-md-6 pl-0'}>
                                <label htmlFor="tax_identification_no">Tax identification Number</label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="tax_identification_no"
                                  placeholder="Enter Tax Identification No."
                                  name="tax_identification_no"
                                  maxLength={25}
                                  onChange={(e) => {
                                    e.target.value.match(/^[a-zA-Z0-9]*$/) && setFieldValue('tax_identification_no', e.target.value)
                                  }}
                                />
                                {errors.tax_identification_no ? <span className="error_message">{errors.tax_identification_no}</span> : null}
                                {errors && errors.tax_identification_no && errors.tax_identification_no.length > 0 ?
                                  <span className="error_message">{errors.tax_identification_no[0]}</span>
                                  : null}
                              </div>

                            </>

                            }
                            {headerDataDetails.financial_year_formatted.split("-")[0] >= 2023 ? <div className={horizontalView ? 'form-group col-md-2 px-0 smmboxml' : 'form-group col-md-6 pl-0'}>
                              <label htmlFor="tax_regime">Tax Regime</label> <br />
                              <Field
                                as="select"
                                className="form-control f-14"
                                name="tax_regime"
                              >
                                <option value="">Select</option>
                                <option value="OLD">Old</option>
                                <option value="NEW">New</option>
                              </Field>

                            </div>
                              : null}
                          </div>
                        </div>
                        <div className={horizontalView ? 'text-center col-sm-12 sticky_bottom_create_deductee mt-2' : 'col-sm-12 sticky_bottom_create_deductee mt-4'}>
                          <button
                            type="submit"
                            className="light_color_bg listdupdeempbtn"
                            style={{ marginRight: '5px' }}
                            id="save-deductee"
                          >
                            Save
                          </button>
                          {!deducteeId ? (
                            <button
                              type="button"
                              className="dark_color_bg listdupdeempbtnblu"
                              style={{ marginRight: '5px', padding: '5px 15px' }}
                              onClick={() => submitAndResetForm()}
                              id="save-deductee-new"
                            >
                              Save and New
                            </button>
                          ) : null}
                          <button
                            type="button"
                            className="light-btn listdupdeempbtngry"
                            onClick={() => onClose()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>

                    </div>
                  </Form>
                );
              }}
            </Formik>

          </div>
        </div>
      </div>
    </>
  );
}
