import React from 'react'

const PersonalInfo = (props) => {
    const { data, userStatus } = props

    return (
        <>
            <div className="row mt-0">
                <div className="col-md-12">
                    <p className="expit_comp_personalinfo_fs text_black">Personal Information</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                Name of Employee
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs hide-extra-space">
                                {(data?.PersonalInfo?.NameOfAssessee)}
                            </label>
                        </div>
                    </form>
                    <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                PAN
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.PAN)}
                            </label>
                        </div>
                    </form>
                    <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                            Financial Year
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.FinancialYear)}

                            </label>
                        </div>
                    </form>
                    <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                Status
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.Status)}

                            </label>
                        </div>
                    </form>
                    <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                Regime
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.Regime)}

                            </label>
                        </div>
                    </form>
                    {/* <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                Filing Status
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.FilingStatus)} Return


                            </label>
                        </div>
                    </form> */}
                </div>
                <div className="col-md-6">
                    {/* {[1].includes(userStatus) &&
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    Father's Name
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.FatherName)}
                                </label>
                            </div>
                        </form>
                    } */}
                    <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                {[1].includes(userStatus) ? 'Date of Birth' : 'Incorporation Date'}
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.DateOfBirthOrIncorporation)}
                            </label>
                        </div>
                    </form>
                    {/* {[1].includes(userStatus) && <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                Gender
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.Gender)}
                            </label>
                        </div>
                    </form>} */}
                    <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                E-mail
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs hide-extra-space">
                                {(data?.PersonalInfo?.Email)}
                            </label>
                        </div>
                    </form>
                    <form className="form-row expit_comp_personalinfo_frmfs">
                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs">
                                Residential Status
                            </label>
                        </div>
                        <div className="form-group col-md-9">
                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.ResidentialStatus)}
                            </label>
                        </div>
                    </form>
                </div>


                <div className="col-md-12 pl-0 pr-0" style={{display:"flex",    flexWrap: "wrap"}}>

                    {/* {(data?.PersonalInfo?.Aadhaar ?? null) !== null &&
                        <div className="col-md-6">
                            <form className="form-row expit_comp_personalinfo_frmfs">
                                <div className="form-group col-md-3">
                                    <label className="expit_comp_personalinfo_lablefs">
                                        Aadhaar
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <label className="expit_comp_personalinfo_lable_inpboxfs">
                                        {(data?.PersonalInfo?.Aadhaar)}
                                    </label>
                                </div>
                            </form>
                        </div>
                    } */}

                    {(data?.PersonalInfo?.SpouseName ?? null) !== null &&
                        <div className="col-md-6">
                            <form className="form-row expit_comp_personalinfo_frmfs">
                                <div className="form-group col-md-3">
                                    <label className="expit_comp_personalinfo_lablefs">
                                        Spouse Name
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <label className="expit_comp_personalinfo_lable_inpboxfs">
                                        {(data?.PersonalInfo?.SpouseName)}
                                    </label>
                                </div>
                            </form>
                        </div>
                    }

                    {(data?.PersonalInfo?.PassportNo ?? null) !== null &&
                        <div className="col-md-6">
                            <form className="form-row expit_comp_personalinfo_frmfs">
                                <div className="form-group col-md-3">
                                    <label className="expit_comp_personalinfo_lablefs">
                                        Passport No
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <label className="expit_comp_personalinfo_lable_inpboxfs">
                                        {(data?.PersonalInfo?.PassportNo)}
                                    </label>
                                </div>
                            </form>
                        </div>
                    }

                    {(data?.PersonalInfo?.PrimaryMobileNo ?? null) !== null &&
                        <div className="col-md-6">
                            <form className="form-row expit_comp_personalinfo_frmfs">
                                <div className="form-group col-md-3">
                                    <label className="expit_comp_personalinfo_lablefs">
                                        Mobile No
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <label className="expit_comp_personalinfo_lable_inpboxfs">
                                        {(data?.PersonalInfo?.PrimaryMobileNo)}
                                    </label>
                                </div>
                            </form>
                        </div>
                    }
                    {(data?.PersonalInfo?.LLPIN ?? null) !== null &&
                        <div className="col-md-6">
                            <form className="form-row expit_comp_personalinfo_frmfs">
                                <div className="form-group col-md-3">
                                    <label className="expit_comp_personalinfo_lablefs">
                                        LLP-IN
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <label className="expit_comp_personalinfo_lable_inpboxfs">
                                        {(data?.PersonalInfo?.LLPIN)}
                                    </label>
                                </div>
                            </form>
                        </div>
                    }
                    {(data?.PersonalInfo?.CINorFCRN ?? null) !== null &&
                        <div className="col-md-6">
                            <form className="form-row expit_comp_personalinfo_frmfs">
                                <div className="form-group col-md-3">
                                    <label className="expit_comp_personalinfo_lablefs">
                                        {[14,16].includes(userStatus) ? 'FCRN No' : 'CIN No'}
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <label className="expit_comp_personalinfo_lable_inpboxfs">
                                        {(data?.PersonalInfo?.CINorFCRN)}
                                    </label>
                                </div>
                            </form>
                        </div>
                    }
                    {(data?.PersonalInfo?.EmployeeID ?? null) !== null &&
                        <div className="col-md-6">
                            <form className="form-row expit_comp_personalinfo_frmfs">
                                <div className="form-group col-md-3">
                                    <label className="expit_comp_personalinfo_lablefs">
                                    Employee ID
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <label className="expit_comp_personalinfo_lable_inpboxfs">
                                        {(data?.PersonalInfo?.EmployeeID)}
                                    </label>
                                </div>
                            </form>
                        </div>
                    }
                    {(data?.PersonalInfo?.CategoryType ?? null) !== null &&
                        <div className="col-md-6">
                            <form className="form-row expit_comp_personalinfo_frmfs">
                                <div className="form-group col-md-3">
                                    <label className="expit_comp_personalinfo_lablefs">
                                    Category Type
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <label className="expit_comp_personalinfo_lable_inpboxfs">
                                        {(data?.PersonalInfo?.CategoryType)}
                                    </label>
                                </div>
                            </form>
                        </div>
                    }
                </div>






















                {/* <div className="col-md-6">
                    <form className="form-row expit_comp_personalinfo_frmfs form-inline">

                        <div className="form-group col-md-3">
                            <label className="expit_comp_personalinfo_lablefs expit_comp_personalinfo_frmfslabw">
                                Address
                            </label>
                        </div>
                        <div className="form-group col-md-9">

                            <label className="expit_comp_personalinfo_lable_inpboxfs">
                                {(data?.PersonalInfo?.Address)}
                            </label>
                        </div>
                    </form>
                </div> */}
                {(data?.PersonalInfo?.BankAccountDetails || data?.PersonalInfo?.ForeignBankAccountDetails) &&
                    <div className="col-md-12 py-2">
                        <p className="expit_comp_personalinfo_nankdet_textfs mb-0">Bank Details</p>
                    </div>
                }
            </div>

            {data?.PersonalInfo?.BankAccountDetails &&
                //  && data?.PersonalInfo?.BankAccountDetails?.length > 0 &&
                //     data?.PersonalInfo?.BankAccountDetails.map((bankItem, i) => {

                //         return (
                <div className="row">
                    <div className="col-md-6">
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    Account Number
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.BankAccountDetails?.AccountNumber)}
                                </label>
                            </div>
                        </form>
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    IFSC Code
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.BankAccountDetails?.IFSC)}
                                </label>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    Bank Name
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.BankAccountDetails?.BankName)}
                                </label>
                            </div>
                        </form>
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    Account    Type
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.BankAccountDetails?.AccountType)}
                                </label>
                            </div>
                        </form>

                    </div>

                </div>
            }



            {(data?.PersonalInfo?.ForeignBankAccountDetails ?? null) !== null &&
                //  && data?.PersonalInfo?.BankAccountDetails?.length > 0 &&
                //     data?.PersonalInfo?.BankAccountDetails.map((bankItem, i) => {

                //         return (
                <div className="row">
                    <div className="col-md-6">
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    IBAN
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.ForeignBankAccountDetails?.IBAN)}
                                </label>
                            </div>
                        </form>
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    Swift Code
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.ForeignBankAccountDetails?.SwiftCode)}
                                </label>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    Bank Name
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.ForeignBankAccountDetails?.BankName)}
                                </label>
                            </div>
                        </form>
                        <form className="form-row expit_comp_personalinfo_frmfs">
                            <div className="form-group col-md-3">
                                <label className="expit_comp_personalinfo_lablefs">
                                    Account    Type
                                </label>
                            </div>
                            <div className="form-group col-md-9">
                                <label className="expit_comp_personalinfo_lable_inpboxfs">
                                    {(data?.PersonalInfo?.ForeignBankAccountDetails?.AccountType)}
                                </label>
                            </div>
                        </form>

                    </div>

                </div>
            }








        </>


    )
}

export default PersonalInfo