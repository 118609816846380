import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DEDUCTOR_CATEGORIES_LIST } from '../../app/constants';

const UpdateDeductorCategoryPopup = (props) => {
  const { selectedDedWithNoCat, selectedDedIndex, handleClose, callFunc } = props;
  const [selectedCat, setSelectedCat] = useState("")
  return (
    <Modal
      show={selectedDedWithNoCat ? true : false}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="incometaxcredential"
    >
      <Modal.Header closeButton className="mpoptextbor">
        <Modal.Title>Enter Deductor Type</Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-0 px-0">

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className='cat-head-title '>Please provide Deductor Type below</div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="">Deductor Type </label>
                    <select value={selectedCat} onChange={(e) => {
                      setSelectedCat(e.target.value)
                    }} style={{ maxWidth: "175px", float: "right" }}>
                      {<option value="" >Choose Category</option>}
                      {Object.keys(DEDUCTOR_CATEGORIES_LIST).map((categoryId) =>
                        <option key={categoryId} value={categoryId}>{DEDUCTOR_CATEGORIES_LIST[categoryId]}</option>
                      )
                      }
                    </select>
                  </div>
                  <div className="cat-update-btn"
                    onClick={() => {
                      const parameter = {
                        value: selectedCat ?? "",
                        index: selectedDedIndex ?? 0,
                        name: "deductor_category",
                        deductor_id: selectedDedWithNoCat.deductor_id ?? "",
                        deductor_name: selectedDedWithNoCat.deductor_name ?? ""
                      }
                      callFunc(parameter)
                      handleClose()
                    }
                    }
                  > Submit </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </Modal.Body>

    </Modal >
  )
}

export default UpdateDeductorCategoryPopup