import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BASE_PATH, DEDUCTOR_CATEGORIES, DEDUCTOR_CATEGORIES_LIST, GLOBAL_DV_CODE } from '../../app/constants';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomCellList } from '../../components/grid/CustomCell';
import returnRegIcon from "../../images/return-register.png";
import editIcon from '../../images/icons/edit.svg';
import infoiconalertbox from '../../images/icons/info.svg';
import clickhandexpire from '../../images/icons/clickhand_expire.gif';
import dwnload4 from "../../images/icons/export_icon.png";
import $ from 'jquery'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { gocallApi } from '../../api/issueCertificateAPI';
import { getCurrentFinancialYearByQuarter } from '../../utils/UtilityFunctions';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import { downloadExcelDeductorlist } from '../../api/deductorAPI';
import UpdateDeductorCategoryPopup from './UpdateDeductorCategoryPopup';
import letsGetStartIcon from '../../images/lets_get_started.gif';
import "./DeductorAllList.css";

import ExpressRecoadCloud from '../../images/Express_Reco_ad_cloud.png'

const DeductorRecentList = () => {


    const history = useHistory();
    const headerData = useSelector(selectHeaderData);
    const current_year = getCurrentFinancialYearByQuarter();

    const [deductorList, setDeductorList] = useState(null);
    const [deducteeSummary, setDeducteeSummary] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [intialDelayFirstTime, setIntialDelayFirstTime] = useState(100000)
    const [selectedDedWithNoCat, SetSelectedDedWithNoCat] = useState(null)
    const [selectedDedIndex, setSelectedDedIndex] = useState(0);
    //const [showNoRecordIcon, setShowNoRecordIcon] = useState(false);

    const deductorTypeUpdate = async (parameter) => {
        setLoading(true);
        const url = "/api/v1/deductor"
        const payload = {
            deductor_id: parameter?.deductor_id ?? "",
            deductor_name: parameter?.deductor_name ?? "",
            deductor_category: parameter?.value ?? "",
            deductor_status: 1,
        }
        try {
            const result = await gocallApi("put", url, payload)
            setSuccess(result?.data?.message ?? "");
            deductorList[parameter.index][parameter.name] = parameter.value;
            setDeductorList([...deductorList]);
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false)
        }
    }


    const rowRender = (trElement, props) => {
        let dataItem = props.dataItem ?? {};
        let city = dataItem.city ? dataItem.city?.toLowerCase() : "";
        const dataIndex = props.dataIndex ?? 0;
        if (dataItem?.deductor_category === "Z") dataItem.deductor_category = "";
        return (
            <tr className={`${!dataItem.deductor_status ? 'inactive' : null}`}>
                <td className='text-left textoverflow overflowtooltipconttdsamt'>
                    {dataItem.deductor_status ? (
                        !dataItem?.deductor_category ? <div style={{ cursor: "pointer" }} onClick={() => { SetSelectedDedWithNoCat(dataItem); setSelectedDedIndex(dataIndex) }}>
                            <span title={`${dataItem.deductor_name ?? ""}`} className="dark_color_text">
                                {dataItem.deductor_name}
                            </span>
                        </div>
                            :
                            <Link to={`${BASE_PATH}deductor?deductor_id=${dataItem.deductor_id}`}>
                                <span title={`${dataItem.deductor_name ?? ""}`} className="dark_color_text">
                                    {dataItem.deductor_name}
                                </span>
                            </Link>
                    ) : (
                        <div>
                            <span title={`${dataItem.deductor_name ?? ""}`} className="inactive_dark_color">
                                {dataItem.deductor_name}
                            </span>
                        </div>
                    )}
                </td>
                <td className="text-left">{dataItem.tan ?? "-"}</td>
                <td className="text-left textoverflow overflowtooltipconttdsamt text-capitalize" title={`${dataItem.city?.length >= 10 ? city : ""}`} >{city ?? "-"}</td>
                <td className="text-left textoverflow overflowtooltipconttdsamt" title={`${DEDUCTOR_CATEGORIES?.[dataItem.deductor_category] ?? ""}`}>
                    {!dataItem?.deductor_category && <span>
                        <select className='planexpiredalert' value={dataItem.deductor_category} onChange={(e) => {
                            const parameter = {
                                value: e.target.value ?? "",
                                index: dataIndex ?? 0,
                                name: "deductor_category",
                                deductor_id: dataItem.deductor_id ?? "",
                                deductor_name: dataItem.deductor_name ?? ""
                            }
                            if (e.target.value) deductorTypeUpdate(parameter)

                        }} style={{ maxWidth: "185px" }}>
                            {<option value="" >Choose Category</option>}
                            {Object.keys(DEDUCTOR_CATEGORIES_LIST).map((categoryId) =>
                                <option key={categoryId} className='planexpiredalert' value={categoryId}>{DEDUCTOR_CATEGORIES_LIST[categoryId]}</option>
                            )
                            }
                        </select>
                    </span>}
                    {dataItem?.deductor_category && <span>{DEDUCTOR_CATEGORIES?.[dataItem.deductor_category] ?? "-"}</span>}
                </td>
                <td className="text-left">{dataItem.mobile_no ?? "-"}</td>
                <td className="text-left textoverflow overflowtooltipconttdsamt" title={`${dataItem.email ?? ""}`}>{dataItem.email ?? "-"}</td>
                <td className="text-center">
                    <Link to={`${BASE_PATH}edit-deductor/${dataItem.deductor_id}`} className='planexpiredalert'>
                        <span>
                            <img src={editIcon} alt="editIcon" width="16" />
                        </span>
                    </Link>

                </td>
            </tr>
        )
    }


    const getRecentDeductorList = async () => {
        setLoading(true);
        let url = `/api/v1/deductor/list-recent`
        let payload = {
            limit: 17
        }

        try {
            const result = await gocallApi("post", url, payload)
            setDeductorList(result?.data?.data ?? []);
            setDeducteeSummary(result?.data?.summary ?? {})
            // if ((result?.data?.data ?? []).length === 0) {
            //     setShowNoRecordIcon(true);
            // }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const excelDeductorList = async () => {
        setLoading(true);
        try {
            await downloadExcelDeductorlist()
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false)
        }
    }

    // const exportDeductorsToEmail = async () => {
    //     setLoading(true);
    //     try {
    //         const result = await exportEmailList();
    //         setSuccess(result.message);
    //     } catch (err) {
    //         setError(err.toString());
    //     }
    //     setLoading(false);
    // };

    const addNewClient = () => {
        history.push(`${BASE_PATH}create-deductor`)
    }

    useEffect(() => {
        getRecentDeductorList();
    }, [])

    if (intialDelayFirstTime > 0) {
        $("body").mousemove(function () {
            setIntialDelayFirstTime(0)
        });
    }

    return (
        <>
            {loading && <LoadingIndicator />}
            {selectedDedWithNoCat && <UpdateDeductorCategoryPopup
                selectedDedWithNoCat={selectedDedWithNoCat}
                handleClose={() => {
                    SetSelectedDedWithNoCat(null);
                }}
                callFunc={deductorTypeUpdate}
                selectedDedIndex={selectedDedIndex}
            />}

            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            <div className="container deductormasterlist mt-0">
                {/* <div className="row">
                <div className="col-sm-12 text-center">
                    
                </div>
            </div> */}
                <div className="row pt-2">
                    <div className="col-6 col-xl-4 col-lg-4 col-md-1 col-sm-12"></div>
                    <div className="col-6 col-xl-3 col-lg-3 col-md-5 col-sm-12 text-center pr-0" >
                        <h4 className="createdeductor_titlefs ">
                            Deductor Master
                            <span className='ml-1 ' title='Download Deductor Master' onClick={(e) => {
                                excelDeductorList();
                            }}>
                                <img src={dwnload4} alt="excel download icon " width="22" />
                            </span>
                        </h4>
                    </div>

                    <div className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12 text-right pr-0" style={{ paddingTop: "9px" }}>
                        <Link className="btn btn-default tds_creatdeduct_pendbtnbgclr mr-2 planexpiredalert" to={`${BASE_PATH}notice-orders`}>Notice & Orders</Link>
                        <Link className="btn btn-default tds_creatdeduct_pendbtnbgclr mr-2 planexpiredalert" to={`${BASE_PATH}pending-requests`}>Check Status of Raised Requests</Link>


                    </div>

                </div>

                <div className="row borderdeductor mt-2">
                    <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-12 pl-0">
                        <nav className="tds_listof_deduct_nav">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">

                                <Link className="nav-item  tds_listof_deduct_tab active tds_listof_deduct_tabretborderrightnon searchbox_show_less" id="nav-home-tab" to={`${BASE_PATH}list/recent`}>
                                    Recent <span className="tds_listof_deduct_count_text">({`${deducteeSummary?.recent_count ?? 0}`})</span>
                                </Link>
                                <Link className="nav-item tds_listof_deduct_tab searchbox_show_details" id="nav-home-tab" to={`${BASE_PATH}list/all`}>
                                    All <span className="tds_listof_deduct_count_text">({`${deducteeSummary?.all_count ?? 0}`})</span>
                                </Link>
                                <button className="btn btn-default List_of_deductor_incompdeductbtn planexpiredalert" onClick={() => history.push(`${BASE_PATH}list/incomplete`)}>
                                    Incomplete Deductors <span className="tds_listof_deduct_count_text">({`${deducteeSummary?.tentative_count ?? 0}`})</span>
                                </button>
                            </div>
                        </nav>
                    </div>

                    <div className="col-12 col-xl-6 col-lg-6 col-md-4 col-sm-12 text-right pr-0">
                        {headerData?.organization_type === "CORPORATE" && 
                            <Link className="btn btn-default tds_creatdeduct_pendingbtnbgclr mx-2 planexpiredalert" to={`${BASE_PATH}form3CD/incomplete-deductors?financial_year=${current_year}`}>
                                Clause 34-Form 3CD Annex
                            </Link>
                        }
                        <Link className="btn btn-default tds_creatdeduct_pendingbtnbgclr mx-2 planexpiredalert" to={`${BASE_PATH}return-register/return-wise?financial_year=${current_year}`}>
                            <img width="14" alt="Return Register" src={returnRegIcon} style={{ paddingBottom: "2px", paddingRight: "2px" }} />
                            Return Register
                        </Link>

                    </div>

                    <div className="col-md-12 px-0">
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade active show" id="tds_listof_deduct_tab01" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs text-center">
                                            {
                                                (deductorList && deductorList.length === 0)
                                                    ? <>
                                                    <Link title={`Create New Client`} className="" to={`${BASE_PATH}create-deductor`}>
                                                        <img src={letsGetStartIcon} alt='' width="960" height="432" />
                                                    </Link>
                                                        </>
                                                    :
                                                    <>
                                                        <Tooltip anchorElement="pointer" position="right" openDelay={intialDelayFirstTime}>
                                                            <Grid
                                                                className="table table-striped listofdedtb scrollvisible recentlist"
                                                                data={deductorList}
                                                                rowRender={rowRender}
                                                            >
                                                                <Column
                                                                    width="250"
                                                                    field="deductor_name"
                                                                    filter="text"
                                                                    title={<CustomCellList className="exp_ITC_cl_verflowtext planexpiredalert" title="Deductor Name"
                                                                        field="deductor_name"
                                                                        handleClick={() => {

                                                                            addNewClient()

                                                                        }} addnewRow={true} />}

                                                                />

                                                                <Column
                                                                    width="100"
                                                                    field="tan"
                                                                    filter="text"
                                                                    title='TAN'
                                                                />

                                                                <Column
                                                                    width="90"
                                                                    field="city"
                                                                    title='Location'

                                                                />
                                                                <Column
                                                                    width="180"
                                                                    field="deductor_category"
                                                                    title='Deductor Type'
                                                                />

                                                                <Column
                                                                    width="90"
                                                                    field="mobile_no"
                                                                    title="Mobile No."
                                                                />

                                                                <Column
                                                                    width="180"
                                                                    field="email"
                                                                    title="Email Address"
                                                                />

                                                                <Column
                                                                    width="70"
                                                                    field="status"
                                                                    title="Actions"
                                                                />

                                                            </Grid>
                                                        </Tooltip>
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(
                headerData.plan_valid === false
                || ((headerData.plan_valid_until ?? '') !== '')
            ) &&

                < div className="deductormasterlist container-fluid mt-2">

                    <div className="row mt-2">
                        <div className="card uexpirepaddtopbrem">
                            <div className="userexpirebox text-center">
                                <img className="info_imgalertbox" src={infoiconalertbox} alt="" />

                                {headerData.plan_valid === false ?
                                    (
                                        <> <span className="pl-2 userexpiretextfs">
                                            Your subscription has expired. Please subscribe to a paid plan to enjoy uninterrupted services.
                                        </span>
                                            <span className="text-center pl-3">
                                                <button className="btn btn-default paynowbtn expirebtnboxfs" onClick={() => history.push(`${BASE_PATH}plans`)}>{parseInt(headerData?.is_next_sub_renewal ?? 0) === 1 ? " Renew now" : "Subscribe Now"}</button>
                                            </span></>

                                    ) :
                                    (
                                        <>
                                            <span className="pl-2 userexpiretextfs">
                                                Your subscription is going to Expire in
                                                <span className="expalerttext ml-1">{headerData.plan_valid_until}</span>
                                                . Please subscribe to a paid plan & enjoy uninterrupted services.
                                            </span>
                                            <span className="text-center pl-3">
                                                <button className="btn btn-default paynowbtn expirebtnboxfs" onClick={() => history.push(`${BASE_PATH}plans`)}>{parseInt(headerData?.is_next_sub_renewal ?? 0) === 1 ? " Renew now" : "Subscribe Now"}</button>
                                            </span>
                                        </>
                                    )
                                }

                                {GLOBAL_DV_CODE &&
                                    <a href={`${BASE_PATH}plans`}>
                                        <span className="text-center pl-3">
                                            <span className='exprit_expriebox_btnbox'>
                                                <span className=" ml-1"> Apply Voucher Code  <br></br>
                                                    <span className='expalerttext' style={{ fontSize: '16px' }}> {GLOBAL_DV_CODE} </span> <br></br> to Avail Discount</span>
                                                <span className='clickimg_icon'>
                                                    <img className="" src={clickhandexpire} alt="Click here to avail discount" width="40" />
                                                </span>
                                            </span>
                                        </span>
                                    </a>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className='container-fluid'>
                <div className="clientmaster_leftbottom_bannerbox">
                    <a target='_blank' rel='noreferrer' href='https://www.expressreco.com/?utm_source=disp_ExpressTDS_app&utm_medium=cloud-software&utm_campaign=ExpressRECO2024 '>
                        <div className='clientmaster_leftbottom_banner_innercontent'>
                            <img width="100%" src={ExpressRecoadCloud} alt="" />

                        </div>
                    </a>
                </div>
            </div>

        </>
    )
}

export default DeductorRecentList;
