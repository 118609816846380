/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

// -- Validation Rules
export const deducteeTransactionsFormValidationSchema = (form_type) => {
  return (Yup.object().shape({
    // deductee_name: Yup.string()
    //   .trim()
    //   .min(1, 'Too Short!')
    //   .max(75, 'Too Long!')
    //   .required('Deductee name is required'),

    // challan_id: Yup.string()
    //   .when('mapChallanVal', {
    //     is: (val) => val === '1',
    //     then: Yup.string()
    //       .required('Please select Challan Number')
    //   }),

    deductee_id: Yup.string().required('This field is required'),

    nature_of_payment_id: Yup.string().required('This field is required'),
    deduction_date: Yup.string()
      .when('tds_rate_b100', {
        is: (val) => val > 0,
        then: Yup.string()
          .required('This field is required')
      })
      .when('tds_amtp', {
        is: (val) => val > 0,
        then: Yup.string()
          .required('This field is required')
      }),

    // deduction_date: Yup.string().required('Date of Deduction is required'),

    payment_date: Yup.string().required('This field is required'),

    tds_rate_b100: Yup.number().required('This field is required').max(100, 'Max allowed value is 100'),

    paid_amtp: Yup.number().min(0.1, 'Amount paid should be greater than 0').required('This field is required'),

    tds_amtp: Yup.number().nullable().required('This field is required'),

    surcharge_paid_amtp: Yup.number().required('This field is required'),

    education_cess_amtp: Yup.number().required('This field is required'),

    // deduction_reason:Yup.string().required('Deduction Reason is required'),

    // lower_deduction_certificate_no:Yup.string().required('Lower Deduction Certificate no. is required'),

    cash_withdrawal_option: Yup.string().nullable()
    .when('nature_of_payment_id', {
      is: (val) => ([53,82].includes(Number(val))),
      then: Yup.string()
        .required('This field is required')
    }),

    cash_withdrawal_amtp: Yup.number().nullable()
    .when('nature_of_payment_id', {
      is: (val) => ([36,81,53,82].includes(Number(val))),
      then: Yup.number()
       .min(0.1, 'Cash withdrawal amount should be greater than 0')
      .required('This field is required')
      .max(Yup.ref("paid_amtp"), 'Withdrawal amount must be less or equal to paid amount')      
    }),

    tds_challan_no: Yup.number()
      .when('tds_liable', {
        is: (val) => (val === true || Number(val) === 1),
        then: Yup.number()
          .required('This field is required')
      }),

    tds_payment_date: Yup.string().nullable()
      .when('tds_liable', {
        is: (val) => (val === true || Number(val) === 1),
        then: Yup.string()
          .required('This field is required')
      }),
    acknowledgment_no_15ca: Yup.string().nullable().trim()
      .matches(/^[a-zA-Z0-9]*$/g, 'Invalid 15CA Acknowledgment No.')
      .min(12, '15CA Acknowledgment No. should be atleast 12 characters long.')
      .max(15, '15CA Acknowledgment No. cannot be more than 15 characters long.'),
    nature_of_remittance: Yup.string().nullable()
      .when({
        is: () => form_type === '27Q' ? true : false,
        then: Yup.string().required('This field is required'),
      }), lower_deduction_certificate_no: Yup.string().nullable()
        .when('deduction_reason', {
          is: (val) => (val === 'A' || val === 'B'),
          then: Yup.string().nullable()
            .when('nature_of_payment_id', {
              is: (val) => ([3, 66, 67, 68, 69, 70, 71, 72, 73].includes(Number(val))),
              then: Yup.string().nullable().trim()
                .min(10, 'Lower Deduction Certificate No. should be 10 characters long.')
                .max(10, 'Lower Deduction Certificate No. should be 10 characters long.')
            })
        }),

  }))
};


// -- correction Validation Rules
export const correctionDeducteeTransactionsFormValidationSchema = Yup.object().shape({
  deductee_name: Yup.string()
    .trim()
    .min(1, 'Too Short!')
    .max(75, 'Too Long!')
    .required('Deductee name is required'),

  challan_id: Yup.string()
    .when('mapChallanVal', {
      is: (val) => val === '1',
      then: Yup.string()
        .required('Please select Challan Number')
    }),

  deductee_id: Yup.string().nullable().required('Please select valid Deductee Name'),

  nature_of_payment_id: Yup.string().required('Section is required'),

  deduction_date: Yup.string()
    .when('tds_rate_b100', {
      is: (val) => val > 0,
      then: Yup.string()
        .required('Date of Deduction is required')
    })
    .when('tds_amtp', {
      is: (val) => val > 0,
      then: Yup.string()
        .required('Date of Deduction is required')
    }),

  //paid_amtp: Yup.number().min(0.1, 'Amount paid should be greater than 0').required('Amount Paid required'),
  paid_amtp: Yup.number()
    .when('mark_nil', {
      is: (val) => (val === false || Number(val) === 0),
      then: Yup.number()
        .min(0.1, 'Amount paid should be greater than 0').required('Amount Paid required'),
    }),
    

  payment_date: Yup.string().required('Date of Payment is required'),

  tds_amtp: Yup.number().nullable().required('TDS Amount required'),

  surcharge_paid_amtp: Yup.number().required('Surcharge Amount required'),

  education_cess_amtp: Yup.number().required('Health and Education Cess amount required'),

  tds_challan_no: Yup.number()
    .when('tds_liable', {
      is: (val) => (val === true || Number(val) === 1),
      then: Yup.number()
        .required('Please select tds challan...')
    }),

  tds_payment_date: Yup.string().nullable()
    .when('tds_liable', {
      is: (val) => (val === true || Number(val) === 1),
      then: Yup.string()
        .required('Please select tds payment...')
    }),

});
