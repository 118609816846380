import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CustomCell } from '../../../components/grid/CustomCell';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import ConnectorAdapter from '../../../components/kdkConnector/ConnectorAdapter';
import TracesConnector from '../../../components/kdkConnector/TracesConnector';
import VerifyLdcCertificiate from './VerifyLdcCertificiate';
import ShowAlert from '../../../components/ShowAlert';
import editIcon from '../../../images/edite02.png';
//import Skeleton6ColumnGrid from '../../../components/Skeleton/Skeleton6ColumnGrid';
import { process } from "@progress/kendo-data-query";
import { deepClone } from '../../../utils/UtilityFunctions';
import moment from 'moment';
import Skeleton12ColumnGrid from '../../../components/Skeleton/Skeleton12ColumnGrid';
//import { getNatureOfPaymentCodes } from '../../../api/challanAPI';
//import { Spinner } from 'react-bootstrap';
//import $ from 'jquery';

const initialDataState = {
  skip: 0,
  take: 16,
};

const LowerDeductionCertPopup = (props) => {
  const { onClose, returnId, deductorId, ldcListData, getLdcDataForReturn, showType, handleEditTransaction, showConsumptionTable, showSkeleton, viewListAll } = props;
  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [startReVerifyLdc, setStartReVerifyLdc] = useState(false);
  const [startLdcVerifyProcess, setStartLdcVerifyProcess] = useState(false);
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [dataState, setDataState] = useState(initialDataState);
  const [data, setData] = useState([]);
  //const [paymentCodes, setPaymentCodes] = useState([]);
  const [viewAll, setViewAll] = useState(true);

  const [inititalData, setInititalData] = useState([]);

  const LDCStatus = [
    { label: "Invalid", value: "Invalid" },
    { label: "Section Mismatch", value: "Section Mismatch" },
    { label: "Rate Mismatch", value: "Rate Mismatch" },
    { label: "Expired", value: "Expired" },
    { label: "Date not in Cert. date range", value: "Date not in Cert. date range" },
    { label: "Valid", value: "Verified" },
    { label: "Overutilized", value: "Overutilized" },
    { label: "Unverified", value: "Unverified" },
  ]

  const dataStateChange = (dataState) => {

    if (dataState.filter || (dataState.sort && dataState?.sort?.length > 0)) {
      const filter_state = deepClone(dataState);
      if (filter_state?.filter?.filters?.length > 0) {
        filter_state.filter.filters.forEach((item) => {
          if (item?.filters?.length > 0) {
            item.filters.forEach((itemObj) => {
              if (["gte", "lte", 'lt', 'gt', "neq"].includes(itemObj?.operator)) {
                if (itemObj.field !== "payment_date") {
                  itemObj.value = Number(itemObj.value);
                }
              }
              else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                itemObj.value = itemObj.value?.[0] ?? "";
              }
              else if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                let array = []
                item.logic = "or"
                if (itemObj?.value?.length > 0) {
                  itemObj?.value.forEach((keyValue) => {
                    if(itemObj.field === 'remarks' && keyValue === 'Verified') {
                      array.push({ field: itemObj.field, operator: 'eq', value: 'Valid' })
                    }else{
                      array.push({ field: itemObj.field, operator: 'contains', value: keyValue })
                    }                    
                  })
                  item.filters = array;
                }
              }
            })
          }
        })
      }
      const dataProcess = process(ldcListData, { ...filter_state });
      setDataState({ ...dataState });
      setData(dataProcess.data ?? []);

    } else {

      setDataState({});
      const defaultData = JSON.parse(JSON.stringify(inititalData));
      setData(defaultData ?? []);
    }

  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };



  const rowRender = (trElement, props,) => {
    const data = props.dataItem;
    const rowCounter = trElement._owner.index;
    return (
      <tr data-index={rowCounter} role="row">
        <td className={'text-center'} style={{paddingLeft: '0px'}}>{data?.pan ?? '-'}</td>
        <td className={`text-center`} style={{paddingRight: '30px'}}>{data?.nature_of_payment_code ?? '-'}</td>
        <td className={`text-center`} style={{paddingRight: '25px'}}>{data?.rate ?? '-'}</td>
        <td className={`text-center`} style={{paddingRight: '25px'}}>{data?.payment_date ? moment(data?.payment_date).format('DD/MM/YYYY') : '-'}</td>
        <td className={`text-right`}>{formatNumber(data?.paid_amtp ?? '0')}</td>
        <td className={`text-center link`} onClick={() => showConsumptionTable(data)} >{data?.certi_no ?? '-'}</td>
        <td className="text-right">{formatNumber(data?.uncomsumed_amtp ?? '0')}</td>
        <td className="text-center" style={{color: `${data?.remarks === "Valid" ? 'green' : 'red'}`}}>{data?.remarks ?? '-'}</td>
        <td className="text-center">
          <img src={editIcon} alt='' width={16} className='link' onClick={() => { handleEditTransaction(data?.deductee_transaction_id) }} />
        </td>
      </tr>
    )
  }



  useEffect(() => {
    setInititalData(deepClone(ldcListData));

    if (showType === 'VALID') {
      const dataStateFilter = {
        "filter": {
          "filters": [
            {
              "logic": "and",
              "filters": [
                {
                  "field": "remarks",
                  "operator": "in",
                  "value": ["Verified", "Section Mismatch", "Rate Mismatch", "Expired", "Date not in Cert. date range", "Overutilized", "Invalid",]
                }
              ]
            }
          ],
          "logic": "and"
        },
        "skip": 0
      }     
      dataStateChange(dataStateFilter);

    } else if (showType === 'RELOOKED') {

      if(viewListAll){
        setViewAll(viewListAll);
      }

      const dataStateFilter = {
        "filter": {
          "filters": [
            {
              "logic": "and",
              "filters": [
                {
                  "field": "remarks",
                  "operator": "in",
                  "value": ["Section Mismatch", "Rate Mismatch", "Expired", "Date not in Cert. date range", "Overutilized", "Invalid", "Unverified"]
                }
              ]
            }
          ],
          "logic": "and"
        },
        "skip": 0
      }
      dataStateChange(dataStateFilter);
    } else {
      setData(ldcListData);
      setViewAll(false);
    }
    // eslint-disable-next-line
  }, [ldcListData, showType])


  const handleViewAll = () => {   
    setViewAll(false)
    dataStateChange({});
  }


  return (
    <>
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      {
        startReVerifyLdc
          ? <ConnectorAdapter
            show={startReVerifyLdc}
            handleClose={() => setStartReVerifyLdc(false)}
            setPort={setPort}
            handleProceed={() => setStartLdcVerifyProcess(true)}
            title={'Login at Income Tax Portal'}
            section={'EFILING'}
          />
          : null
      }
      {startLdcVerifyProcess && port
        ?
        <TracesConnector
          deductorId={deductorId}
          returnId={returnId}
          handleProceed={() => {
            setStartReVerifyLdc(false);
            setStartLdcVerifyProcess(false);
          }}
          port={port}
          setCaptchaRef={setCaptchaRef}
          setVerifyCreds={() => null}
          creds={null}
        />
        : null
      }

      {captchaRef && port ? (
        <VerifyLdcCertificiate
          deductorId={deductorId}
          returnId={returnId}
          port={port}
          captchaRef={captchaRef}
          setError={setError}
          setSuccess={(msg) => {
            setSuccess(msg);
            setTimeout(() => {
              window.location.reload();
            },5000)
          }}
          getLdcDataForReturn={getLdcDataForReturn}
        />
      ) : null
      }

      <WindowWrapperFixed
        title={
          <h4 className="modal-title w-100">
            <div class="row col-md-12">
              <div class="col-md-7">
                Validate Lower Deduction Certificate
              </div>
              <div class="col-md-5">
                <span class="latededuct_amtfs"> <span style={{ fontSize: '12px' }}>Show All</span>
                  <span className="pl-1" data-toggle="modal" data-target=".bd-example-modal-lg">
                    <label className="switch listofldcswitch">
                      <input type="checkbox" checked={viewAll} onChange={() => handleViewAll()} />
                      <span className="slider round listofldcswround" />
                    </label>
                  </span>
                </span>
              </div>
            </div>
          </h4>
        }
        initialWidth={1450}
        onClose={onClose}
        totalPopups={1}
        index={1}
        isModalView={true}
        initialHeight={'auto'}
        initialLeft={70}
        initialTop={40}
        className={`compu-health-late-deduction-interest-data`}
      >

        <div className="row card mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <div className="col-md-12">
            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
              {
                showSkeleton ? <Skeleton12ColumnGrid rowNo={7} />
                  :
                  <>
                    <Tooltip anchorElement="pointer" position="right">
                      <Grid
                        className="table table-striped comp_health_late_deduc_popup Deducteetrans_deducteewise_tb mb-0"
                        style={{ maxHeight: '410px', overflowY: "auto" }}
                        {...dataState}
                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                        //data={filterBy(ldcListData, filter)}
                        data={data}
                        //filter={filter}   
                        fixedScroll={true}
                        filterOperators={{
                          text: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                          ],
                          numeric: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                          ],
                          date: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                          ],
                          textWithEmpty: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                          ],
                          dropdown: [
                            { text: 'grid.filterContainsOperator', operator: 'in' },
                          ],
                          pan: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterContainsOperator', operator: 'in' },
                          ],
                          challan: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                          ],
                        }}
                        sortable={{
                          allowUnsort: true,
                        }}
                        rowRender={rowRender}
                      >

                        <Column
                          width={100}
                          field="pan"
                          headerClassName={isColumnActive('pan') ? 'active' : ''}
                          title={<CustomCell title={'PAN'} field="pan" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="textWithEmpty"
                          columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                        />
                        <Column
                          width={120}
                          field="nature_of_payment_code"
                          headerClassName={isColumnActive('nature_of_payment_code') ? 'active' : ''}
                          title={<CustomCell title={'Section'} field="nature_of_payment_code" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="textWithEmpty"
                          columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                        />
                        <Column
                          width={100}
                          field="rate"
                          headerClassName={isColumnActive('rate') ? 'active' : ''}
                          title={<CustomCell title={'Rate'} field="rate" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="numeric"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        <Column
                          width={120}
                          field="payment_date"
                          headerClassName={isColumnActive('payment_date') ? 'active' : ''}
                          title={<CustomCell title={'Payment Date'} field="payment_date" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="date"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        <Column
                          width={120}
                          field="paid_amtp"
                          headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                          title={<CustomCell title={'Amount'} field="paid_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="numeric"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        <Column
                          width={150}
                          field="certi_no"
                          headerClassName={isColumnActive('certi_no') ? 'active' : ''}
                          title={<CustomCell title={'Certificate Number'} field="certi_no" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="textWithEmpty"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        <Column
                          width={150}
                          field="uncomsumed_amtp"
                          headerClassName={isColumnActive('uncomsumed_amtp') ? 'active' : ''}
                          title={<CustomCell title={'Unconsumed Balance'} field="uncomsumed_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="numeric"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                        />
                        <Column
                          width={200}
                          field="remarks"
                          headerClassName={isColumnActive('remarks') ? 'active' : ''}
                          title={<CustomCell title={'Status'} field="remarks" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="dropdown"
                          columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={LDCStatus} />}
                        />
                        <Column
                          width={50}
                          field="editIcon"
                          headerClassName={`action text-center`}
                          sortable={false}
                          title="Action"
                          style={{cursor: 'default'}}
                        />

                        <GridNoRecords>
                          No Record Found
                        </GridNoRecords>

                      </Grid>
                    </Tooltip>

                  </>
              }
            </div>
          </div>
          <div className="col-md-12 text-center pt-3 pb-1">
            <button className="btn btn-default ReverifyTransbtnbgclr mr-2" onClick={() => setStartReVerifyLdc(true)}>Re-verify Transactions</button>
            <button className="btn btn-default ReverifyTransbtnbgclrdone" onClick={onClose}>Done</button>
          </div>
        </div>
      </WindowWrapperFixed>

    </>

  );
};

export default LowerDeductionCertPopup;
