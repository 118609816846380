import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { BASE_PATH, HEADER_CONNECTOR_DIGEST_KEY, HEADER_FILE_NAME } from '../../app/constants'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { checkRaiseRequestFromConnector, getDownloadPdfRaiseRequest, getDscList, getIssueRequestStatus, gocallApi, postPdfGenFromConnector, updateIssueRequestStatus } from '../../api/issueCertificateAPI';
import moment from 'moment';
import $ from 'jquery';
import { getFinancialYears } from '../../utils/UtilityFunctions';
import DSCertificatePopup from './DSCertificatePopup';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import RaiseRequestPopup from '../returnRaise/RaiseRequestPopup';
import ProgressBarGenPdfPopup from './ProgressBarGenPdfPopup';
import "./TdsTcsCertificateV1.scss";
import { selectHeaderData } from '../../features/header/headerSlice';


const TdsTcsCertificateV1 = (props) => {
    const headerData = useSelector(selectHeaderData);
    const location = useLocation();
    const params = queryString.parse(location.search);
    const { deductorId } = props.match.params;
    const history = useHistory();    

    const [loading, setLoading] = useState(false);
    const [requestStatusData, setRequestStatusData] = useState({ financial_year: params?.year ?? null, form_type: params?.form_type ?? null, quarter: params?.quarter ?? null });
    const [requestStatusLeast, setRequestStatusLeast] = useState({})
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [port, setPort] = useState(0);
    const [captchaRef, setCaptchaRef] = useState(null);
    const [verifyTraceCredentials, setVerifyTraceCredentials] = useState(false);
    const [isConnectorRunning, setIsConnectorRunning] = useState(false);
    const [credentialAvailable, setCredentialAvailable] = useState(false)
    const [showRaiseRequestPopup, setShowRaiseRequestPopup] = useState(false);
    const [dscertificatePopup, setDscertificatePopup] = useState(false);
    const [clientTan, setClientTan] = useState(null)
    const [currentStep, setCurrentStep] = useState(null)
    const [dscCertificates, setDscCertificates] = useState([])
    const [dscIndex, setDscIndex] = useState(null)
    const [verifyLogin, setVerifyLogin] = useState(true)
    const [runningUnsignPdf, setRunningUnsignPdf] = useState(false)
    const [runningSignPdf, setRunningSignPdf] = useState(false)
    const [localUnSignPdf, setLocalUnSignPdf] = useState(JSON.parse(localStorage.getItem('expresstdsform-unsign-pdf')))
    const [localSignPdf, setLocalSignPdf] = useState(JSON.parse(localStorage.getItem('expresstdsform-sign-pdf')))
    const [reSign, setReSign] = useState(false);
    const [emailDownloadStatus, setEmailDownloadStatus] = useState({});

    const step1ValidStatus = ['AVAILABLE', 'SUBMITTED', 'PORTAL_AVAILABLE_NOT_DOWNLOADED', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF']
    const step2ValidStatus = ['AVAILABLE', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF']
    const step3ValidStatus = ['AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF']

    const step1 = step1ValidStatus.includes(requestStatusLeast?.request_status) ? true : false;
    const step2 = step2ValidStatus.includes(requestStatusLeast?.request_status) ? true : false;
    const step3 = step3ValidStatus.includes(requestStatusLeast?.request_status) ? true : false;;

    //call latest reaise request status on page refresh ,or after  connector work done 
    const callRequestStatus = useCallback(async (deductor_id, intialEmailStatusCall) => {
        if (params?.form_type && params?.quarter && params?.year) {

            checkNewRequest();
            if (intialEmailStatusCall) {
                const data = {
                    financial_year: params?.year,
                    quarter: params?.quarter,
                    form_type: params?.form_type,
                };
                checkUserActionStatus(data);
            }
            return null;
        }
        setLoading(true);
        try {
            const result = await getIssueRequestStatus(deductor_id);
            if (result?.data) {

                if (result?.data?.financial_year !== requestStatusData?.financial_year || result?.data?.form_type !== requestStatusData?.form_type || result?.data?.quarter !== requestStatusData?.quarter) {
                    setRequestStatusData((prev) => {
                        prev.financial_year = result?.data?.financial_year
                        prev.form_type = result?.data?.form_type
                        prev.quarter = result?.data?.form_type === "24Q" ? "Q4" : result?.data?.quarter
                        prev.pdf_count = result?.data?.pdf_count

                        return { ...prev }
                    })
                }
                if (intialEmailStatusCall) {
                    checkUserActionStatus(result?.data ?? {});
                }
                combineRaiseRequest(result?.data?.request_details ?? [])
                // setClientMobile((result?.header?.mobile_number))
                setClientTan((result?.header?.tan))
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestStatusData]);

    const changeRaiseRequest = (name, value) => {
        requestStatusData[name] = value
        if (name === 'form_type' && value === '24Q') { requestStatusData.quarter = 'Q4' }
        setRequestStatusData(() => { return { ...requestStatusData } })
        history.push(`${BASE_PATH}issue-tds-tcs-certificates/${deductorId}?form_type=${requestStatusData?.form_type}&quarter=${requestStatusData?.quarter}&year=${requestStatusData?.financial_year}`);
        checkNewRequest();
        checkUserActionStatus(requestStatusData);
        if (verifyTraceCredentials) setVerifyTraceCredentials(false);

    };

    //check raise request status for given qt, time and form 
    const checkNewRequest = () => {
        const data = {
            deductor_id: deductorId,
            financial_year: requestStatusData?.financial_year ?? params?.year,
            quarter: requestStatusData?.quarter ?? params?.quarter,
            form_type: requestStatusData?.form_type ?? params?.form_type,
        };
        async function callNewRaiseRequestCheck() {
            // setLoading(true);
            try {
                const result = await updateIssueRequestStatus(data);
                if (result?.data) {
                    if (result?.data?.financial_year !== requestStatusData?.financial_year || result?.data?.form_type !== requestStatusData?.form_type
                        || result?.data?.quarter !== requestStatusData?.quarter || result?.data?.pdf_count !== requestStatusData?.pdf_count) {
                        setRequestStatusData((prev) => {
                            prev.financial_year = result?.data?.financial_year
                            prev.form_type = result?.data?.form_type
                            prev.quarter = result?.data?.quarter
                            prev.pdf_count = result?.data?.pdf_count

                            return { ...prev }
                        })
                    }
                    combineRaiseRequest(result?.data?.request_details ?? [])
                    // setClientMobile((result?.header?.mobile_number))
                    setClientTan((result?.header?.tan))
                }
            } catch (err) {
                setError(err);
            }
            // setLoading(false);
        }
        callNewRaiseRequestCheck();
    };


    //create new raise request
    const submitProceedBtn1 = async () => {
        setCurrentStep(1)
        if (credentialAvailable) {
            setShowRaiseRequestPopup(true);
            return null;
        }
        setIsConnectorRunning(true);
    };

    //process for unsign pdf start
    const genUnsignPdf = () => {
        localStorage.setItem('tds-status', requestStatusLeast?.request_status);
        localStorage.setItem('tds-status-change', requestStatusLeast?.request_status);
        localStorage.setItem('complete-process-ids', JSON.stringify([]))
        setCurrentStep(3)
        if (!isConnectorRunning && !port) {
            setIsConnectorRunning(true);
        }
        if (port && currentStep === 3) {
            genUnsignPdfConnector(port);
        }
    }




    const genUnsignPdfConnector = async () => {
        async function checkPdfStatus(trackId) {
            setLoading(true)
            try {
                const url = `/api/v1/usuc/traces/tds-tcs-cert/generate-pdf`
                const result = await gocallApi("post", url, { deductor_id: deductorId, request_no: trackId, }, true);
                const responseData = result.data ?? null;
                const responseHeader = result.headers ?? null;
                const connectorRequest = {
                    "data": responseData?.data
                };
                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                try {
                    const connectorResponse = await postPdfGenFromConnector(port, connectorRequest, extraHeader);
                    if (Number(connectorResponse.status) === 1) {
                        setSuccess(connectorResponse?.message);
                        if (connectorResponse?.data?.process_id) {
                            addProcessIdsInLocal(connectorResponse?.data?.process_id, trackId, 'expresstdsform-unsign-pdf')
                        }
                    }
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(true);
                }

            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }
        const trackIds = requestStatusLeast?.request_no;
        trackIds.forEach((trackId) => {
            checkPdfStatus(trackId);
        })
    };

    //process for unsign pdf end




    //process for sign pdf start

    const genSignPdf = () => {
        setCurrentStep(4)
        localStorage.setItem('tds-status', requestStatusLeast?.request_status);
        localStorage.setItem('tds-status-change', requestStatusLeast?.request_status);
        localStorage.setItem('complete-process-ids', JSON.stringify([]))
        if (!isConnectorRunning) {
            setIsConnectorRunning(true);
        }
        if (port && currentStep === 4) {
            getDscListFromConnector(port);
        }
    }


    const getDscListFromConnector = async (port) => {
        const checkDscList = async (port) => {
            try {
                const statusResult = await getDscList(port);
                if (parseInt(statusResult.status) === 1) {
                    if (statusResult?.data?.length > 0) {
                        setDscCertificates(() => { return [...statusResult?.data] })
                        if (statusResult?.data?.length === 1) {
                            setDscIndex(0)
                        }
                    } else {
                        setDscCertificates(() => [])
                    }
                    setDscertificatePopup(true)
                    // $('#certificatesselradiobox_modal').modal('show');
                }
            } catch (err) {
            } finally {

            }
        }
        checkDscList(port);

    };


    const genSignPdfConnector = async () => {
        async function checkPdfStatus(trackId) {
            setLoading(true)
            try {
                const url = `/api/v1/usuc/traces/tds-tcs-cert/generate-pdf`
                const result = await gocallApi("post", url, { deductor_id: deductorId, request_no: trackId, dsc_token: { name: dscCertificates?.[dscIndex]?.name, serial_no: dscCertificates?.[dscIndex]?.serial_no } }, true);
                const responseData = result.data ?? null;
                const responseHeader = result.headers ?? null;
                const connectorRequest = {
                    "data": responseData?.data
                };
                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                try {
                    const connectorResponse = await postPdfGenFromConnector(port, connectorRequest, extraHeader);
                    if (Number(connectorResponse.status) === 1) {
                        setSuccess(connectorResponse?.message);
                        if (connectorResponse?.data?.process_id) {
                            addProcessIdsInLocal(connectorResponse?.data?.process_id, trackId, 'expresstdsform-sign-pdf')
                        }
                    }
                } catch (err) {
                    setError(err?.message?.[0] ?? err?.message ?? err);
                    // Reset the states of currentStep, runningSignPdf, and runningUnsignPdf to false if they are currently true
                    if (currentStep) setCurrentStep(false);
                    if (runningSignPdf) setRunningSignPdf(false);
                    if (runningUnsignPdf) setRunningUnsignPdf(false);

                } finally {
                    setLoading(true);
                }

            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }

        }
        const trackIds = requestStatusLeast?.request_no;
        trackIds.forEach((trackId) => {
            checkPdfStatus(trackId);
        })
    };

    //process for sign pdf end




    const callStatusCheck = async () => {
        setLoading(true)
        try {
            const url = `/api/v1/usuc/traces/pending-raise-request`
            const result = await gocallApi("post",
                url,
                { deductor_id: deductorId, captcha_ref: captchaRef, financial_year: requestStatusData?.financial_year, quarter: requestStatusData?.quarter, form_type: requestStatusData?.form_type },
                true);
            const responseData = result?.data ?? null;
            const responseHeader = result?.headers ?? null;
            const connectorRequest = {
                "data": responseData?.data
            };
            let extraHeader = {};
            extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

            try {
                const connectorResponse = await checkRaiseRequestFromConnector(port, connectorRequest, extraHeader);
                if (Number(connectorResponse.status) === 1) {
                    setSuccess(connectorResponse?.message?.length > 0 ? connectorResponse?.message : "The status has been updated successfully");
                    let timerId = setInterval(() => {
                        callRequestStatus(deductorId);
                        if (localStorage.getItem('tds-status') !== requestStatusLeast?.request_status) {
                            setCurrentStep(null)
                            clearInterval(timerId)
                        }
                    }, 2000);
                    setTimeout(() => {
                        clearInterval(timerId); setCurrentStep(null)
                    }, 20000); //check status only for 20 sec maximum
                }
            } catch (err) {
                // setError(err.message);
            } finally {
                setLoading(true);
            }

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };




    const combineRaiseRequest = (requestStatusDetail) => {
        const preDefineObj = JSON.parse(JSON.stringify({ request_status: [], pdf_processing_status: [], request_for: [], request_no: [], raise_request_date: [], dsc_sign_name: [], dsc_signed_at: [] }))
        if (requestStatusDetail && requestStatusDetail?.length > 0) {
            requestStatusDetail.forEach((item) => {
                item?.request_no && preDefineObj.request_no.push(item?.request_no)
                item?.raise_request_date && preDefineObj.raise_request_date.push(item?.raise_request_date)
                item?.dsc_sign_name && preDefineObj.dsc_sign_name.push(item?.dsc_sign_name)
                item?.dsc_signed_at && preDefineObj.dsc_signed_at.push(item?.dsc_signed_at)
                item?.request_status && preDefineObj.request_status.push(item?.request_status);
                item?.pdf_processing_status && preDefineObj.pdf_processing_status.push(item?.pdf_processing_status);
                item?.request_for && preDefineObj.request_for.push(item?.request_for);
            })
            let finalStatus = null
            for (const status of ['NOT_AVAILABLE', 'SUBMITTED', 'PORTAL_AVAILABLE_NOT_DOWNLOADED', 'AVAILABLE', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF']) {
                if (preDefineObj.request_status.includes(status)) {
                    finalStatus = status;
                    const inProgIndex = preDefineObj.pdf_processing_status.findIndex(item => item.startsWith("IN_PROGRESS_"));

                    if (inProgIndex >= 0) {
                        localStorage.setItem('tds-status', finalStatus);
                    } else {
                        localStorage.setItem('tds-status', "UNKNOWN");
                    }

                    break;
                }
            }

            localStorage.setItem('tds-status-change', finalStatus ?? "NOT_AVAILABLE");

            // if (requestStatusLeast?.request_status !== finalStatus || JSON.stringify(requestStatusLeast?.request_no) !== JSON.stringify(preDefineObj?.request_no))
            setRequestStatusLeast((prev) => {
                prev.request_no = preDefineObj?.request_no
                prev.request_status = finalStatus ?? "NOT_AVAILABLE"
                prev.raise_request_date = preDefineObj?.raise_request_date[0] ?? null
                prev.dsc_sign_name = preDefineObj?.dsc_sign_name[0] ?? null
                prev.dsc_signed_at = preDefineObj?.dsc_signed_at[0] ?? null
                prev.pdf_processing_status = preDefineObj.pdf_processing_status ?? [];
                prev.request_for = preDefineObj.request_for ?? [];

                return { ...prev }
            })

        } else {
            setRequestStatusLeast((prev) => {
                prev.request_no = null
                prev.request_status = "NOT_AVAILABLE"
                prev.raise_request_date = null
                return { ...prev }
            })
        }

    }
    const addProcessIdsInLocal = (processId, trackId, localId) => {
        const localGetTrackIds = localStorage.getItem(localId)
        let localTrackPdfData = null;

        if (localGetTrackIds && Array.isArray(JSON.parse(localGetTrackIds))) {
            const newObj = { processId: processId, trackId: trackId, fy: requestStatusData?.financial_year, qt: requestStatusData?.quarter, ft: requestStatusData?.form_type, tan: clientTan }
            localTrackPdfData = JSON.parse(localGetTrackIds)
            const index = localTrackPdfData.findIndex(item => item?.trackId === trackId && item.fy === requestStatusData?.financial_year && item.ft === requestStatusData?.form_type && item.qt === requestStatusData?.quarter && item.tan === clientTan);
            if (index < 0) {
                localTrackPdfData.push(newObj)
            } else { localTrackPdfData[index].processId = processId }
        }
        else {
            localTrackPdfData = [{ processId: processId, trackId: trackId, fy: requestStatusData?.financial_year, qt: requestStatusData?.quarter, ft: requestStatusData?.form_type, tan: clientTan }]
        }
        if (localId === "expresstdsform-unsign-pdf") {
            setLocalUnSignPdf(() => {
                return [...localTrackPdfData]
            })
        } else if (localId === "expresstdsform-sign-pdf") {
            setLocalSignPdf(() => {
                return [...localTrackPdfData]
            })
        }
        localStorage.setItem(localId, JSON.stringify(localTrackPdfData));
    }

    const downloadPdf = (type) => {
        async function callDownloadConsoAPI(trackId, fileType) {
            async function donwloadFile() {
                setLoading(true);
                const downloadFileStr = { deductor_id: deductorId, request_no: trackId, pdf_for: fileType };
                let result = await getDownloadPdfRaiseRequest(downloadFileStr);
                const csiData = new Blob([result.data], { type: 'application/zip' });
                const file_name = result.headers?.[HEADER_FILE_NAME] ?? `${requestStatusData.form_type}-FY${requestStatusData.financial_year}-${requestStatusData.quarter}-${fileType}.zip`;
                const link = document.createElement('a');
                link.href = URL.createObjectURL(csiData);
                link.setAttribute('download', file_name);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            }
            try {
                await Promise.all([
                    donwloadFile(),
                ]);
                checkUserActionStatus(requestStatusData);
            } catch (err) {
                if (err) {
                    setError(err.toString());
                }
                else {
                    setError("File not found on specified file path");
                }

            } finally {
                setLoading(false);
            }
        }

        requestStatusLeast && requestStatusLeast?.request_no?.length > 0 &&
            requestStatusLeast?.request_no.forEach((item) => {
                callDownloadConsoAPI(item, type);

            })

    }

    const reSignPdf = () => {
        localStorage.setItem('complete-process-ids', JSON.stringify([]))
        setReSign(true)
        genSignPdf()
    }

    const closeModal = () => {
        setReSign(false);
        setDscertificatePopup(false);
    }

    const checkUserActionStatus = async (requestStatusData,) => {
        const type = 'get';
        const urlparams1 = `?deductor_id=${deductorId}&financial_year=${requestStatusData?.financial_year ?? ""}&quarter=${requestStatusData?.quarter ?? ""}`
        const urlparams2 = `&form_type=${requestStatusData.form_type}`;
        const geturl = `/api/v1/tds-tcs-cert/action-status${urlparams1}${urlparams2}`;
        try {
            const result = await gocallApi(type, geturl);
            setEmailDownloadStatus(result.data ?? {});
        } catch (err) {
            if (err.message) {
                setError(err?.message?.[0] ?? err.message);
            } else {
                setError(err);
            }
        }
    }

    //on page load check latet raise request and status
    useEffect(() => {
        const intialEmailStatusCall = true;
        callRequestStatus(deductorId, intialEmailStatusCall);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deductorId]);

    //process for sign and unsign pdf start
    useEffect(() => {
        if (currentStep === 3 && port) {
            genUnsignPdfConnector();
        } else if (currentStep === 4 && port) {
            getDscListFromConnector(port);
        } else if (currentStep === 5 && port) {
            genSignPdfConnector(port);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, port])

    useEffect(() => {
        const unsignPdf = JSON.parse(localStorage.getItem('expresstdsform-unsign-pdf'));
        const signpdf = JSON.parse(localStorage.getItem('expresstdsform-sign-pdf'));
        if (unsignPdf && currentStep !== 4 && currentStep !== 5) {
            requestStatusLeast && requestStatusLeast?.request_no?.length > 0 && requestStatusLeast?.request_no.forEach((tid) => {
                const index = unsignPdf.findIndex(item => tid === item?.trackId && item.fy === requestStatusData?.financial_year && item.ft === requestStatusData?.form_type && item.qt === requestStatusData?.quarter && item.tan === clientTan);
                if (index !== -1 && unsignPdf[index]?.processId && ['AVAILABLE', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF'].includes(requestStatusLeast?.request_status)) {
                    setVerifyLogin(false)
                    setRunningUnsignPdf(true)

                }
            })

        } else if (signpdf && currentStep !== 3 && currentStep !== 4) {
            requestStatusLeast && requestStatusLeast?.request_no?.length > 0 && requestStatusLeast?.request_no.forEach((tid) => {
                const index = signpdf.findIndex(item => tid === item?.trackId && item.fy === requestStatusData?.financial_year && item.ft === requestStatusData?.form_type && item.qt === requestStatusData?.quarter && item.tan === clientTan);
                if (index !== -1 && signpdf[index]?.processId && ['AVAILABLE', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF'].includes(requestStatusLeast?.request_status)) {
                    setVerifyLogin(false)
                    setRunningSignPdf(true)                    
                }
            })
        }
    }, [localUnSignPdf, localSignPdf, requestStatusData, clientTan, currentStep, requestStatusLeast, port])
    //process for sign and unsign pdf end

    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            {isConnectorRunning
                ? <ConnectorAdapter
                    show={isConnectorRunning}
                    handleClose={() => {
                        setIsConnectorRunning(false);
                    }}
                    setPort={setPort}
                    handleProceed={() => {
                        (currentStep !== 3 && currentStep !== 4 && currentStep !== 5 && verifyLogin) && setVerifyTraceCredentials(true);
                    }}
                />
                : null
            }

            {verifyTraceCredentials && port
                ?
                <>
                    <TracesConnector
                        dontLogin={currentStep === 1 ? true : false}
                        deductorId={deductorId}
                        returnId={null}
                        handleProceed={() => {
                            setIsConnectorRunning(false);
                            setVerifyTraceCredentials(false);
                        }}
                        port={port}
                        setVerifyCreds={() => {
                            setCredentialAvailable(true)
                            currentStep === 1 && setShowRaiseRequestPopup(true);
                            currentStep === 2 && callStatusCheck();
                        }}
                        creds={null}
                        setCaptchaRef={setCaptchaRef}
                        callCaptchaRef={true}
                        tan={clientTan}
                    />
                </>
                : null
            }

            {showRaiseRequestPopup ?
                <RaiseRequestPopup
                    show={showRaiseRequestPopup}
                    handleClose={() => {
                        setShowRaiseRequestPopup(false);
                    }}
                    deductorId={deductorId}
                    pan={""}
                    captchaRef={captchaRef}
                    port={port}
                    setSuccess={setSuccess}
                    preDefineRaiseData={requestStatusData}
                    handleCallOnSuccess={() => {
                        checkNewRequest();
                        $('#raisedreqsuccessfully_modal').modal('show');

                    }}
                />
                : null}
            {dscertificatePopup &&
                <DSCertificatePopup
                    show={dscertificatePopup}
                    closeModal={closeModal}
                    dscCertificates={dscCertificates}
                    setReSign={setReSign}
                    setDscIndex={setDscIndex}
                    dscIndex={dscIndex}
                    setCurrentStep={setCurrentStep}
                    setDscCertificates={setDscCertificates}
                    setDscertificatePopup={setDscertificatePopup}
                />
            }

            {(currentStep === 5 || runningSignPdf) &&
                requestStatusLeast?.request_no?.map((item, index) => {
                    return (<ProgressBarGenPdfPopup
                        setVerifyLogin={setVerifyLogin} setIsConnectorRunning={setIsConnectorRunning}
                        port={port} requestStatusLeast={requestStatusLeast}
                        requestStatusData={requestStatusData} localId="expresstdsform-sign-pdf"
                        localStorgePdfs={localSignPdf} clientTan={clientTan}
                        deductorId={deductorId} callRequestStatus={callRequestStatus}
                        setError={setError} setCurrentStep={setCurrentStep}
                        setRunningPdfStatus={setRunningSignPdf} setReSign={setReSign}
                        totalFiles={requestStatusData?.pdf_count}
                        form_type={requestStatusData.form_type}
                        parentIndex={index}
                        bottomMargin={index === 1 ? "27%" : ""}
                        callEmailStatusApi={() => {
                            const payload = {
                                financial_year: requestStatusData?.financial_year ?? params?.year,
                                quarter: requestStatusData?.quarter ?? params?.quarter,
                                form_type: requestStatusData?.form_type ?? params?.form_type,
                            };
                            checkUserActionStatus(payload);
                        }}
                    />)
                })


            }

            {(currentStep === 3 || runningUnsignPdf) &&
                requestStatusLeast?.request_no?.map((item, index) => {
                    return (<ProgressBarGenPdfPopup
                        setVerifyLogin={setVerifyLogin} setIsConnectorRunning={setIsConnectorRunning}
                        port={port} requestStatusLeast={requestStatusLeast}
                        requestStatusData={requestStatusData} localId="expresstdsform-unsign-pdf"
                        localStorgePdfs={localUnSignPdf} clientTan={clientTan}
                        deductorId={deductorId} callRequestStatus={callRequestStatus}
                        setError={setError} setCurrentStep={setCurrentStep}
                        setRunningPdfStatus={setRunningUnsignPdf} setReSign={setReSign}
                        parentIndex={index}
                        bottomMargin={index === 1 ? "27%" : ""}

                    />)
                })

            }
            <div className="tdstcs_certificate_parent">
                <div className="container-fluid">
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <Link className="backbtntextclr issuescon_backbtn" style={{ float: 'left' }} to={`${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${params.financial_year}`}>
                                &lt;  Back to Dashboard
                            </Link>
                        </div>
                        <div className="col-md-8 d-flex">
                            <h4 className="issuestdstcscert_titlefs">Issue TDS / TCS Certificates</h4>
                            <div className="d-flex justify-content-center tds_issuesconnection_selbox">
                                    <form className="form-inline">
                                        <select className="tds_issuesconnection_selboxfs_yr" value={requestStatusData?.financial_year}
                                            onChange={(e) => (changeRaiseRequest("financial_year", e.target.value))}>
                                            {getFinancialYears().map((item, i) => (
                                                <option key={i} value={item?.value}>{item?.label}</option>
                                            ))}
                                        </select>
                                        <select className="tds_issuesconnection_selboxfs_26qno mx-2" value={requestStatusData?.form_type}
                                            onChange={(e) => (changeRaiseRequest("form_type", e.target.value))}>
                                            <option value="24Q">24Q</option>
                                            <option value="26Q">26Q</option>
                                            <option value="27EQ">27EQ</option>
                                            <option value="27Q">27Q</option>
                                        </select>
                                        <select className="tds_issuesconnection_selboxfs_q01" value={requestStatusData?.quarter}
                                            onChange={(e) => (changeRaiseRequest("quarter", e.target.value))}>
                                            {requestStatusData?.form_type !== '24Q' && <>
                                                <option value="Q1">Q1</option>
                                                <option value="Q2">Q2</option>
                                                <option value="Q3">Q3</option></>}
                                            <option value="Q4">Q4</option>
                                        </select>
                                    </form>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mb-2 pt-2">
                        <div className="col-12 col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 pt-2">
                            <div className="card px-2 py-2 tdstcs_certificates_cardbox">
                                <div className="row pt-3">
                                    <div className="col-md-1 form16_certif_colwidth_leftside mt-1">
                                        <div className="tds_form16_step-borboxtime pl-4">
                                            <ul className="tds_form16_steptimeline">
                                                <li className="tds_form16_step01 ">
                                                    <span className={`tds_form16_cercilebox  ${!step1 ? "activebg03" : "tds_form16_bg_greyclr"} `} ></span>
                                                    <span className={`tds_form16_verticallinebor ${!step1 ? "activebg03" : "tds_form16_bg_greyclr"}`} ></span>
                                                </li>

                                                <li className="tds_form16_step03">
                                                    <span className={`tds_form16_cercilebox  ${!step2 ? "activebg03" : "tds_form16_bg_greyclr02"}`}></span>
                                                    <span className={`tds_form16_verticallinebor stp03  ${!step2 ? "activebg03" : "tds_form16_bg_greyclr"}`}></span>
                                                </li>
                                                <li className="tds_form16_step02" >
                                                    <span className={`tds_form16_cercilebox  ${!step2 ? "activebg03" : "tds_form16_bg_greyclr03"}`}></span>
                                                    <span className={`tds_form16_verticallinebor stp02 ${!step2 ? "activebg03" : "tds_form16_bg_greyclr"}`}></span>
                                                </li>
                                                <li className="tds_form16_step04" >
                                                    <span className={`tds_form16_cercilebox  ${!step3 ? "activebg03" : "tds_form16_bg_greyclr04"}`}></span>
                                                    <span className={`tds_form16_verticallinebor stp04 ${!step3 ? "activebg03" : "tds_form16_bg_greyclr"}`}></span>
                                                </li>
                                                <li className="tds_form16_step05" >
                                                    <span className={`tds_form16_cercilebox  ${!step3 ? "activebg03" : "tds_form16_bg_greyclr05"}`}></span>
                                                    <span className={`tds_form16_verticallinebor stp05 tds_form16_bg_greyclr05 ${!step3 ? "activebg03" : "tds_form16_bg_greyclr"}`}></span>
                                                </li>
                                                <li className="tds_form16_step06" >
                                                    <span className={`tds_form16_cercilebox  ${((emailDownloadStatus.unsigned_download === 1 || emailDownloadStatus.signed_download === 1) || (emailDownloadStatus.signed_email === 1 || emailDownloadStatus.unsigned_email === 1)) ? "tds_form16_bg_greyclr06" : "activebg03"}`}></span>
                                                    <span className={`tds_form16_verticallinebor stp06 tds_form16_bg_greyclr05 ${((emailDownloadStatus.unsigned_download === 1 || emailDownloadStatus.signed_download === 1) || (emailDownloadStatus.signed_email === 1 || emailDownloadStatus.unsigned_email === 1)) ? "tds_form16_bg_greyclr" : "activebg03"}`}></span>
                                                </li>
                                                <li className="tds_form16_step07" >
                                                    <span className={`tds_form16_cercilebox  ${((emailDownloadStatus.unsigned_download === 1 || emailDownloadStatus.signed_download === 1) || (emailDownloadStatus.signed_email === 1 || emailDownloadStatus.unsigned_email === 1)) ? "tds_form16_bg_greyclr06" : "activebg03"}`}></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-11 pl-5 mb-1 issuesconnection_colwidth_rightside tds_issuesconnection_rightbox_heightfixed">
                                        <div className="row">
                                            {/* <!-- inner col-10--> */}
                                            <div className="col-md-12">
                                                <div className="stp01box">
                                                    {['AVAILABLE', 'SUBMITTED', 'PORTAL_AVAILABLE_NOT_DOWNLOADED', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF'].includes(requestStatusLeast?.request_status) ?
                                                        <div className="media">
                                                            <div className="media-body mt-1">
                                                                <p className="issuesconn_wetextfs mb-1">
                                                                    <span className="reqsuccess_textclrgreen">Request has been raised successfully</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="media">
                                                            <span className=""></span>
                                                            <div className="media-body">
                                                                {/* <p className="issuesconn_wetextfs">
                                                                    We have not found any raised request for the selected combination of F.Y., Quarter and Form. Please start by raising one. Once you raise a request, Please come back after 2 hours to check the status and we will send you a whats app at  {clientMobile}.
                                                                </p> */}
                                                                <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr" onClick={() => submitProceedBtn1()} >Submit Request</button>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                                <div className="stp02box">
                                                    <div className="leftside_contentbox">
                                                        {['AVAILABLE', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF'].includes(requestStatusLeast?.request_status) ?
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <p className="textbox_checkst_fsclr mb-1">
                                                                        <span className="reqsuccess_textclrgreen">
                                                                            {requestStatusLeast?.request_no?.length > 1 ? <> {`Requests No.: ${requestStatusLeast?.request_no[0]}, ${requestStatusLeast?.request_no[1]} `} </>
                                                                                :
                                                                                requestStatusLeast?.request_no ? <> {`Request No.: ${requestStatusLeast?.request_no[0]}`} </>
                                                                                    :
                                                                                    <> </>
                                                                            }
                                                                            submitted on {moment(requestStatusLeast?.raise_request_date).format('DD/MM/YYYY')} is available
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            :
                                                            ['SUBMITTED', 'PORTAL_AVAILABLE_NOT_DOWNLOADED'].includes(requestStatusLeast?.request_status) ?
                                                                <div className="media">
                                                                    <div className="media-body">
                                                                        <p className="textbox_checkst_fsclr mb-0 pt-0">
                                                                            {requestStatusLeast?.request_no?.length > 1 ? <> {`Requests No.: ${requestStatusLeast?.request_no[0]}, ${requestStatusLeast?.request_no[1]} `} </>
                                                                                :
                                                                                requestStatusLeast?.request_no ? <> {`Request No.: ${requestStatusLeast?.request_no[0]}`} </>
                                                                                    :
                                                                                    <> </>
                                                                            }
                                                                            submitted on {moment(requestStatusLeast?.raise_request_date).format('DD/MM/YYYY')} is not available yet.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="media">
                                                                    <div className="media-body">
                                                                        <p className="textbox_checkst_fsclr reqpending_textgrey pt-0">Check Request Status</p>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="stp03box stepbox-paddleft">
                                                    <div className="leftside_contentbox">
                                                        {['AVAILABLE', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF'].includes(requestStatusLeast?.request_status) ?
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <p className="textbox_checkst_fsclr mb-1 pt-2">
                                                                        <span className="reqsuccess_textclrgreen">
                                                                            Zip File is available now. Do you want to generate PDF files?
                                                                        </span>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <p className="textbox_checkst_fsclr mb-1 tds-conntext_greyclr pt-0">
                                                                        <span className="reqpending_textgrey">
                                                                            Zip File is available now. Do you want to generate PDF files?
                                                                        </span>
                                                                    </p>


                                                                </div>
                                                            </div>}
                                                    </div>
                                                </div>

                                                <div className="stp04box stepbox-paddleft">
                                                    <div className="leftside_contentbox">
                                                        {['AVAILABLE_ALL_PDF'].includes(requestStatusLeast?.request_status) ?
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <div className="row margin_inherit">
                                                                        <button className="btn btn-default tds_issuesconnection_unsignpdf_btnbgclr disabled">
                                                                            Generate Unsigned PDF
                                                                        </button>
                                                                        <button className="btn btn-default tds_issuesconnection_unsignpdf_btnbgclr disabled">
                                                                            Generate DSC Signed PDF
                                                                        </button>
                                                                        <span className="">
                                                                            {reSign && currentStep === 5 ?
                                                                                <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr">
                                                                                    Resigning PDF
                                                                                </button>
                                                                                :
                                                                                <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr" onClick={() => reSignPdf()}>
                                                                                    Resign PDF
                                                                                </button>
                                                                            }
                                                                        </span>
                                                                        <span className="digitalsignator_detail">{`Signed
                                                                        By: ${requestStatusLeast?.dsc_sign_name} | Signed On:
                                                                        ${requestStatusLeast?.dsc_signed_at}`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ['AVAILABLE_UNSIGNED_PDF'].includes(requestStatusLeast?.request_status) ?

                                                                <div className="media">
                                                                    <div className="media-body">
                                                                        <div className="row margin_inherit">
                                                                            <button className="btn btn-default tds_issuesconnection_unsignpdf_btnbgclr disabled" data-toggle="modal" data-target="#tds_form16_certif_DSCmodal01">
                                                                                Generate Unsigned PDF
                                                                            </button>
                                                                            <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr"
                                                                                onClick={() => genSignPdf()}
                                                                            >
                                                                                Generate DSC Signed PDF
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ['AVAILABLE_SIGNED_PDF'].includes(requestStatusLeast?.request_status) ?
                                                                    <div className="media">
                                                                        <div className="media-body">
                                                                            <div className="row margin_inherit">

                                                                                <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr"
                                                                                    onClick={() => genUnsignPdf()}
                                                                                >
                                                                                    Generate Unsigned PDF
                                                                                </button>
                                                                                <button className="btn btn-default tds_issuesconnection_unsignpdf_btnbgclr disabled">
                                                                                    Generate DSC Signed PDF
                                                                                </button>
                                                                                <span className="">
                                                                                    {reSign && currentStep === 5 ?
                                                                                        <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr">
                                                                                            Resigning PDF
                                                                                        </button>
                                                                                        :
                                                                                        <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr" onClick={() => reSignPdf()}>
                                                                                            Resign PDF
                                                                                        </button>
                                                                                    }
                                                                                </span>
                                                                                <span className="digitalsignator_detail">{`Signed
                                                                                By: ${requestStatusLeast?.dsc_sign_name} | Signed On:
                                                                                ${requestStatusLeast?.dsc_signed_at}`}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ['AVAILABLE'].includes(requestStatusLeast?.request_status) ?
                                                                        <div className="media">
                                                                            <div className="media-body">
                                                                                <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr"
                                                                                    onClick={() => genUnsignPdf()}
                                                                                >
                                                                                    Generate Unsigned PDF
                                                                                </button>
                                                                                <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr"
                                                                                    onClick={() => genSignPdf()}
                                                                                >
                                                                                    Generate DSC Signed PDF
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        : <div className="media">
                                                                            <span className=""></span>
                                                                            <div className="media-body">
                                                                                <p className="textbox_checkst_fsclr reqpending_textgrey pt-0">Start PDF Generation</p>
                                                                            </div>
                                                                        </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="stp05box stepbox-paddleft">
                                                    <div className="leftside_contentbox">
                                                        {
                                                            ['AVAILABLE_ALL_PDF'].includes(requestStatusLeast?.request_status)
                                                                ?
                                                                <div className="media">
                                                                    <div className="media-body" style={{position: 'relative', top: '11px'}}>
                                                                        <p className="textbox_checkst_fsclr mb-0 pt-0">
                                                                            <span className="reqsuccess_textclrgreen">{requestStatusData?.pdf_count} Digitally Signed PDF's generated successfully </span>
                                                                        </p>
                                                                        <p className="textbox_checkst_fsclr mb-0">
                                                                            <span className="reqsuccess_textclrgreen">{requestStatusData?.pdf_count} Unsigned PDF's generated successfully</span>
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                :
                                                                ['AVAILABLE_SIGNED_PDF'].includes(requestStatusLeast?.request_status) ?
                                                                    <div className="media">
                                                                        <div className="media-body" style={{position: 'relative', top: '11px'}}>
                                                                            <p className="textbox_checkst_fsclr pt-0">
                                                                                <span className="reqsuccess_textclrgreen">{requestStatusData?.pdf_count} Digitally Signed PDF's generated successfully </span>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    ['AVAILABLE_UNSIGNED_PDF'].includes(requestStatusLeast?.request_status) ?
                                                                        <div className="media">
                                                                            <div className="media-body" style={{position: 'relative', top: '11px'}}>
                                                                                <p className="textbox_checkst_fsclr pt-0">
                                                                                    <span className="reqsuccess_textclrgreen">{requestStatusData?.pdf_count} Unsigned PDF's generated successfully</span>
                                                                                </p>
                                                                                <p className="textbox_checkst_fsclr mb-0 pt-3"></p>

                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="media">
                                                                            <div className="media-body" style={{position: 'relative', top: '11px'}}>
                                                                                <p className="textbox_checkst_fsclr pt-0">
                                                                                    <span className="reqpending_textgrey">PDF have been generated</span>
                                                                                </p>
                                                                                <p className="textbox_checkst_fsclr mb-0 pt-1"></p>

                                                                            </div>
                                                                        </div>
                                                        }

                                                    </div>
                                                </div>

                                                <div className="stp06box stepbox-paddleft">
                                                    <div className="leftside_contentbox">

                                                        <div className="media">
                                                            <div className="media-body" style={{position: 'relative', top: '18px'}}>
                                                                {['AVAILABLE_ALL_PDF'].includes(requestStatusLeast?.request_status) ?
                                                                    <>
                                                                        <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr mr-1"
                                                                            onClick={() => {
                                                                                downloadPdf("UNSIGNED");
                                                                            }}
                                                                        >
                                                                            Download Unsigned PDF
                                                                        </button>
                                                                        <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr mr-1"
                                                                            onClick={() => {
                                                                                downloadPdf("SIGNED");
                                                                            }}
                                                                        >
                                                                            Download Signed PDF
                                                                        </button>
                                                                        {headerData?.organization_type === "CORPORATE" && 
                                                                        <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr"
                                                                            onClick={() => {
                                                                                history.push(`${BASE_PATH}download-tds-tcs-certificates/${deductorId}?email_type=signed&form_type=${requestStatusData?.form_type}&quarter=${requestStatusData?.quarter}&year=${requestStatusData?.financial_year}`);
                                                                            }}
                                                                        >
                                                                            Email Signed PDF
                                                                        </button>
                                                                        }
                                                                    </>
                                                                    :
                                                                    ['AVAILABLE_SIGNED_PDF'].includes(requestStatusLeast?.request_status) ?
                                                                        <>
                                                                            <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr mr-1"
                                                                                onClick={() => {
                                                                                    downloadPdf("SIGNED");
                                                                                }}
                                                                            >
                                                                                Download Signed PDF
                                                                            </button>
                                                                            {headerData?.organization_type === "CORPORATE" && 
                                                                            <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr"
                                                                                onClick={() => {
                                                                                    history.push(`${BASE_PATH}download-tds-tcs-certificates/${deductorId}?email_type=signed&form_type=${requestStatusData?.form_type}&quarter=${requestStatusData?.quarter}&year=${requestStatusData?.financial_year}`);
                                                                                }}
                                                                            >
                                                                                Email Signed PDF
                                                                            </button>
                                                                            }
                                                                        </>
                                                                        :
                                                                        ['AVAILABLE_UNSIGNED_PDF'].includes(requestStatusLeast?.request_status) ?
                                                                            <>
                                                                                <button className="btn btn-default tds_issuesconnection_raisenew_btnbgclr mr-1"
                                                                                    onClick={() => {
                                                                                        downloadPdf("UNSIGNED");
                                                                                    }}
                                                                                >
                                                                                    Download Unsigned PDF
                                                                                </button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <p class="textbox_checkst_fsclr reqpending_textgrey pt-0">{
                                                                                `${headerData?.organization_type === "CORPORATE" ? "Download / Email PDF" : "Download"}`
                                                                                }</p>
                                                                            </>
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="stp07box stepbox-paddleft">
                                                    <div className="leftside_contentbox">
                                                        <div className="media">
                                                            <div className="media-body"style={{position: 'relative', top: '18px'}} >
                                                                {((emailDownloadStatus.unsigned_download === 1 || emailDownloadStatus.signed_download === 1) && (emailDownloadStatus.signed_email === 1 || emailDownloadStatus.unsigned_email === 1)) ?
                                                                    <p className="textbox_checkst_fsclr mb-1 pt-0" style={{position: 'relative'}}>
                                                                        <span className="reqsuccess_textclrgreen">
                                                                            {`Download complete/Email Sent Successfully.`}
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    (emailDownloadStatus.unsigned_download === 1 || emailDownloadStatus.signed_download === 1) ?
                                                                        <p className="textbox_checkst_fsclr mb-1 pt-0">
                                                                            <span className="reqsuccess_textclrgreen">
                                                                                {`Download complete.`}
                                                                            </span>
                                                                        </p>
                                                                        :
                                                                        (emailDownloadStatus.signed_email === 1 || emailDownloadStatus.unsigned_email === 1) ?
                                                                            <p className="textbox_checkst_fsclr mb-1 pt-0">
                                                                                <span className="reqsuccess_textclrgreen">

                                                                                    Email Sent Successfully.
                                                                                </span>
                                                                            </p>
                                                                            :
                                                                            <p class="textbox_checkst_fsclr reqpending_textgrey pt-0">{
                                                                                `${headerData?.organization_type === "CORPORATE" ? "Email/Download Status" : "Download Status"}`
                                                                            }</p>
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>




                                            </div>
                                            {/* <!-- inner col-10--> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                {/* <!-- end container--> */}
            </div>
        </>

    )
}

export default TdsTcsCertificateV1
