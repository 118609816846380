import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import './PanWiseTanDetails.scss';
import Form3CDTimeLine from './Form3CDTimeLine';
import queryString from 'query-string';
import downloadicon from "../../images/downloadicon_coral.png";
import { GetDataClause34a, exportform3cdReport } from '../../api/form3cdApi.js'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getCurrentFinancialYear, getFinancialYearsList } from './Form3CDUtilityFunctions';
import Spinner from 'react-bootstrap/Spinner';
import { BASE_PATH, HEADER_FILE_NAME, FORM_3CD_START_YEAR } from '../../app/constants';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { getNatureOfPaymentCodes } from '../../api/challanAPI';
import { formatNumber } from '../../utils/UtilityFunctions.js'
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Link } from 'react-router-dom';
import TransDataSourcePopup from './TransDataSourcePopup';

import sys_btn from "../../images/icons/sys_btn_icon.svg";

const ReportClause34a = () => {


    const location = useLocation();
    const params = queryString.parse(location.search);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [filterYear, setFilterYear] = useState({});
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [dataState, setDataState] = useState({});
    const [finYear, setFinYear] = useState(params.fy);
    const [paymentCodes, setPaymentCodes] = useState([]);
    const [paymentNature, setPaymentNature] = useState([]);
    const [filingPopup, setFilingPopup] = useState(false);

    const [totals, setTotals] = useState({
        total_payment_amtp: 0,
        total_payment_taxliab_amtp: 0,
        total_payment_tds_tcs_sprt_amtp: 0,
        total_tds_tcs_sprt_amtp: 0,
        total_payment_tds_tcs_shrt_amtp: 0,
        total_tds_tcs_shrt_amtp: 0,
        total_tds_tcs_nodep_amtp: 0
    });

    const getDataReportClause34a = async (data) => {
        setLoading(true);
        try {

            const result = await GetDataClause34a(data);

            if (result?.data && result?.data.length > 0) {
                // Use reduce to transform the data into the desired format
                let transformedData = result?.data.reduce((acc, obj) => {
                    // Check if TAN key already exists in accumulator, if not, initialize it
                    if (!acc[obj.tan]) {
                        acc[obj.tan] = [];
                    }
                    // Push the object into the array corresponding to the TAN key
                    acc[obj.tan].push(obj);
                    return acc;
                }, {});

                // Convert the transformedData object back to an array of objects
                let newResult = Object.entries(transformedData).map(([key, value]) => ({
                    [key]: value
                }));

                //setReportData(result.data ?? []);
                setReportData(newResult ?? []);

                // Calculate totals when reportData changes                
                const initialTotals = {
                    total_payment_amtp: 0,
                    total_payment_taxliab_amtp: 0,
                    total_payment_tds_tcs_sprt_amtp: 0,
                    total_tds_tcs_sprt_amtp: 0,
                    total_payment_tds_tcs_shrt_amtp: 0,
                    total_tds_tcs_shrt_amtp: 0,
                    total_tds_tcs_nodep_amtp: 0
                };

                result?.data.forEach(item => {
                    initialTotals.total_payment_amtp += item.total_payment_amtp || 0;
                    initialTotals.total_payment_taxliab_amtp += item.total_payment_taxliab_amtp || 0;
                    initialTotals.total_payment_tds_tcs_sprt_amtp += item.total_payment_tds_tcs_sprt_amtp || 0;
                    initialTotals.total_tds_tcs_sprt_amtp += item.total_tds_tcs_sprt_amtp || 0;
                    initialTotals.total_payment_tds_tcs_shrt_amtp += item.total_payment_tds_tcs_shrt_amtp || 0;
                    initialTotals.total_tds_tcs_shrt_amtp += item.total_tds_tcs_shrt_amtp || 0;
                    initialTotals.total_tds_tcs_nodep_amtp += item.total_tds_tcs_nodep_amtp || 0;
                });

                setTotals(initialTotals);

            } else {
                setReportData([]);
                setTotals({
                    total_payment_amtp: 0,
                    total_payment_taxliab_amtp: 0,
                    total_payment_tds_tcs_sprt_amtp: 0,
                    total_tds_tcs_sprt_amtp: 0,
                    total_payment_tds_tcs_shrt_amtp: 0,
                    total_tds_tcs_shrt_amtp: 0,
                    total_tds_tcs_nodep_amtp: 0
                });
            }

            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    const rowRender = (trElement, props) => {
        // const dataItem = props.dataItem ?? {}; // Ensure dataItem is defined
        const { dataItem } = props;

        // Extract the TAN and items array
        const tan = Object.keys(dataItem)[0]; // Assuming there's only one key
        const items = dataItem[tan];

        return (
            <tr className='even_oddrowbgclr'>
                {/* Render TAN only in the first row of each group */}
                <td className="text-center report34a_tancol_bglcr_white report34a_vertical form3cd_borderbottom_grey" width="100" style={{ backgroundColor: 'white', borderRight: '1px solid #0000001c' }}>
                    {tan}
                </td>
                <td colSpan={9} style={{ padding: '0px', background: 'none' }} width="100%">
                    <table className='table table-striped form3cd34c report-grid34a-table scroll-table' style={{ border: '0px' }}>
                        <tbody>
                            {/* Iterate over items for each TAN */}
                            {items.map((item, index) => (
                                <tr>
                                    <td className="text-center report34a_vertical" width="120">
                                        {item.section ?? ""}
                                    </td>
                                    <td className="text-left report34a_vertical form3cd_nature_overflowtext" width="220">

                                        <span className="pl-0" title={item.nature_of_payment ?? ""}>
                                            {item.nature_of_payment ?? ""}
                                        </span>
                                    </td>
                                    <td className="text-right report34a_vertical" width="200">
                                        {formatNumber(parseFloat(item.total_payment_amtp).toFixed(2)) ?? ""}
                                    </td>
                                    <td className="text-right report34a_vertical" width="200">
                                        {formatNumber(parseFloat(item.total_payment_taxliab_amtp).toFixed(2)) ?? ""}
                                    </td>
                                    <td className="text-right report34a_vertical" width="200">
                                        {formatNumber(parseFloat(item.total_payment_tds_tcs_sprt_amtp).toFixed(2)) ?? ""}
                                    </td>
                                    <td className="text-right report34a_vertical" width="210">
                                        {formatNumber(parseFloat(item.total_tds_tcs_sprt_amtp).toFixed(2)) ?? ""}
                                    </td>
                                    <td className="text-right report34a_vertical" width="210">
                                        {formatNumber(parseFloat(item.total_payment_tds_tcs_shrt_amtp).toFixed(2)) ?? ""}
                                    </td>
                                    <td className="text-right report34a_vertical" width="210">
                                        {formatNumber(parseFloat(item.total_tds_tcs_shrt_amtp).toFixed(2)) ?? ""}
                                    </td>
                                    <td className="text-right report34a_vertical" width="210">
                                        {formatNumber(parseFloat(item.total_tds_tcs_nodep_amtp).toFixed(2)) ?? ""}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </td>
            </tr>
        );
    };


    const getParamsHistoryUrl = () => {
        const requestData = {
            pan: params?.pan ?? ""
        };

        if (params?.fy) {
            requestData.financial_year = params.fy;
        }

        if (params.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.sort_by ?? 'asc' }];
            requestData.sort_on = params.sort_on;
            requestData.sort_by = params.sort_by;
        }

        dataState.filter = { filters: [] };
        if (params.filters) {
            let frs = JSON.parse(atob(params.filters));
            dataState.filter.filters = frs;
            requestData.filters = JSON.parse(atob(params.filters));
        }

        setDataState(dataState);
        return requestData;
    };


    useEffect(() => {
        // set year and month dropdown
        const year_list = getFinancialYearsList(FORM_3CD_START_YEAR);
        setYearList(year_list);

        const queryData = getParamsHistoryUrl();
        const param_year = !isNaN(queryData.financial_year) ? parseInt(queryData.financial_year) : getCurrentFinancialYear();
        const selected_year = year_list.filter((year) => parseInt(year.value) === param_year);
        setFilterYear(selected_year[0]);

        getDataReportClause34a(queryData);
        // eslint-disable-next-line
    }, [location.search]);

    const changeYearFilter = (value) => {
        setFilterYear(value);
        let searchStr = '';
        Object.keys(params).forEach((key) => {
            if (key && key !== 'fy') {
                searchStr = `${searchStr}&${key}=${params[key]}`;
            }
        });
        setFinYear(value.value);
        history.push(`?${searchStr}&fy=${value.value}`)
    };

    const downloadDeducteeExcel = async () => {

        setDownloadLoading(true)
        const urlPayload = `?pan=${params?.pan}&financial_year=${params?.fy}`
        try {
            const result = await exportform3cdReport(urlPayload);
            const xlsxData = new Blob([result?.data]);
            const file_name = result.headers[HEADER_FILE_NAME] ?? 'ExpressTDS-Clause34Report.xlsx';
            const link = document.createElement('a');
            link.href = URL.createObjectURL(xlsxData);
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (err) {
            console.error('Error in export: ', err);
        } finally {
            setDownloadLoading(false)
        }
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        createHistoryUrl(dataState);
    };

    const createHistoryUrl = (dataState = []) => {
        let filterStr = `?pan=${params.pan}&fy=${params.fy}`;

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }

        if (dataState.sort && dataState.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${dataState.sort[0].field}&sort_by=${dataState.sort[0].dir}`;
        }

        history.push(filterStr);
    };

    // const renderHeadTextTitle = (title, txtNumber) => {
    //     return (
    //         <div>
    //             <div>
    //                 {title}
    //             </div>
    //             <div className='custom-header' style={{ textAlign: 'center' }}>
    //                 ({formatNumber(parseFloat(txtNumber).toFixed(2)) ?? ""})
    //             </div>

    //         </div>

    //     );
    // }

    const renderSubTextTitle = (txtNumber) => {
        return (
            <div className='custom-subheader' style={{ textAlign: 'center' }}>
                {formatNumber(parseFloat(txtNumber).toFixed(2)) ?? ""}
            </div>
        );
    }


    const fetchPaymentCodesFunc = (params) => {
        async function fetchEverything() {
            async function fetchPaymentCodes() {
                const result = await getNatureOfPaymentCodes(params);
                const paymentCodeData = [];
                const paymentNatureData = [];
                const payDataKey = {};
                result.data.forEach((paymentCode) => {
                    paymentCodeData.push({
                        label: `${paymentCode.code} - ${paymentCode.description}`,
                        value: paymentCode.nature_of_payment_id,
                    });
                    paymentNatureData.push({
                        label: `${paymentCode.description}`,
                        value: paymentCode.nature_of_payment_id,
                    });

                    payDataKey[paymentCode.code] = paymentCode;
                });
                setPaymentCodes(paymentCodeData);
                setPaymentNature(paymentNatureData);
            }
            try {
                await Promise.all([
                    fetchPaymentCodes(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.toString());
            } finally {
                //  setLoading(false);
            }
        }
        // setLoading(true);
        fetchEverything();
    };

    useEffect(() => {
        fetchPaymentCodesFunc();
    }, []);
    return (

        <>
            {loading ? <LoadingIndicator /> : null}

            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            {filingPopup &&
                <TransDataSourcePopup
                    show={filingPopup}
                    handleClose={() => {
                        setFilingPopup(false);
                    }}
                    params={params}
                />
            }


            
                <Form3CDTimeLine currentPage="report34a" getPan={params.pan} getFinYear={finYear} />

                <div className="container-fluid mt-1">

                <div className="row">

                    <div className="col-md-12 text-right">
                        <div className="dropdown tds_form3CDRevamp_yr_dropdownbox">
                            <Link to={`${BASE_PATH}form3CD/incomplete-deductors?pan=${params.pan}&fy=${params.fy}`}>
                                <button className="btn btn-default form3cd_sync_btn mb-2 mr-2">
                                    <span className="form3cd_returnreg_imgbox">
                                        <img src={sys_btn} alt='sys_btn' style={{ paddingRight: "3px", marginTop: "-2px" }} />
                                        <span className="form3cd_returnreg_textfs pl-1">Sync Now</span>
                                    </span>
                                </button>
                            </Link>
                            <span className="tds_form3CDRevamp_downloadicon pr-1">
                                {
                                    downloadLoading
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}</>
                                        : <img src={downloadicon} width="25" alt="Export Report" title='Download Clause 34 of Form 3CD Report' onClick={() => downloadDeducteeExcel()} />
                                }
                            </span>
                            <span className="dropdwonfy ml-2" >F.Y.</span><span className="dropdwonyearlist">
                            <DropDownList
                                data={yearList}
                                className="header_select headerselect drpdwn_width dropdown tds_form3CDRevamp_yr_dropbtn ml-2"
                                popupSettings={{ className: 'headerselectoption tds_form3CDRevamp_yr_dropmenu' }}
                                textField="text"
                                value={filterYear}
                                onChange={(e) => { changeYearFilter(e.target.value); }}
                                dataItemKey="value"
                                id="yearDropDownPopup"
                            /></span>
                        </div>
                    </div>

                    <div className="col-md-12 text-center pt-2">
                        <div className="card tds_form3CDRevamp_report34a_cardbox mb-2">
                            <div className='row'> <div className='col-12 col-xl-3 col-lg-4 col-md-3 col-sm-12 text-left'>
                                    <span onClick={() => setFilingPopup(true)} className='tds_form3CDRevamp_underst_textfs'>
                                        Click here to understand the source of data
                                    </span>
                                </div>
                                <div className='col-12 col-xl-9 col-lg-8 col-md-9 col-sm-12 text-left'> <h4 className="report34a_clasuse34_title" style={{textAlign:'justify'}}>
                                    Clause 34(a)  - Whether the assessee is required to deduct or collect tax as per the provisions of Chapter XVII-B or Chapter XVII-BB
                                </h4>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <Tooltip anchorElement="target" position="top">
                                    <Grid
                                        className="table table-striped report34a_tb mb-0"
                                        data={reportData}
                                        rowRender={rowRender}
                                        {...dataState}
                                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                                        filterOperators={{
                                            text: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            ],
                                            numeric: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                            ],
                                            date: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                            ],
                                            dropdown: [
                                                { text: 'grid.filterContainsOperator', operator: 'in' },
                                            ]
                                        }}
                                        sortable={{
                                            allowUnsort: true,
                                        }}
                                    >

                                        <Column title='(1)' width={90} >
                                            <Column
                                                width="90"
                                                field="tan"
                                                title='TAN'
                                                filter="text"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                headerClassName={isColumnActive('tan') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title='' width={90} />
                                            </Column>
                                        </Column>
                                        <Column title='(2)' width={90} >
                                            <Column
                                                width="90"
                                                field="section"
                                                filter="dropdown"
                                                title='Section'
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                                                headerClassName={isColumnActive('section') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title='' width={90} />
                                            </Column>
                                        </Column>
                                        <Column title='(3)' width={90} >
                                            <Column
                                                width="175"
                                                field="nature_of_payment"
                                                title='Nature of Payment'
                                                filter='dropdown'
                                                className='long-column-title'
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentNature} />}
                                                headerClassName={isColumnActive('nature_of_payment') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title='' width={175} />
                                            </Column>
                                        </Column>
                                        <Column title='(4)' width={90} >
                                            <Column
                                                width="150"
                                                field="total_payment_amtp"
                                                title='Total amount of payment/receipt of the nature specified in column (3)'
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                headerClassName={isColumnActive('total_payment_amtp') ? 'active cursor-pointer' : 'cursor-pointer'}                                        >
                                                <Column title={renderSubTextTitle(totals.total_payment_amtp)} width={150} />
                                            </Column>
                                        </Column>
                                        <Column title='(5)' width={90} >
                                            <Column
                                                width="150"
                                                field="total_payment_taxliab_amtp"
                                                title='Total amount on which tax was required to be deducted or collected out of (4)'
                                                className='long-column-title'
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                headerClassName={isColumnActive('total_payment_taxliab_amtp') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title={renderSubTextTitle(totals.total_payment_taxliab_amtp)} width={150} />
                                            </Column>
                                        </Column>
                                        <Column title='(6)' width={90} >
                                            <Column
                                                width="150"
                                                field="total_payment_tds_tcs_sprt_amtp"
                                                title='Total amount on which tax was deducted or collected at specified rate out of (5)'
                                                className='long-column-title'
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                headerClassName={isColumnActive('total_payment_tds_tcs_sprt_amtp') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title={renderSubTextTitle(totals.total_payment_tds_tcs_sprt_amtp)} width={150} />
                                            </Column>
                                        </Column>
                                        <Column title='(7)' width={90} >
                                            <Column
                                                width="150"
                                                field="total_tds_tcs_sprt_amtp"
                                                className='long-column-title'
                                                title='Amount of tax deducted/collected out of (6)'
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                headerClassName={isColumnActive('total_tds_tcs_sprt_amtp') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title={renderSubTextTitle(totals.total_tds_tcs_sprt_amtp)} width={150} />
                                            </Column>
                                        </Column>
                                        <Column title='(8)' width={90} >
                                            <Column
                                                width="150"
                                                field="total_payment_tds_tcs_shrt_amtp"
                                                className='long-column-title'
                                                title='Total amount on which tax was deducted/ collected at less than specified rate out of (5)'
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                headerClassName={isColumnActive('total_payment_tds_tcs_shrt_amtp') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title={renderSubTextTitle(totals.total_payment_tds_tcs_shrt_amtp)} width={150} />
                                            </Column>
                                        </Column>
                                        <Column title='(9)' width={90} >
                                            <Column
                                                width="150"
                                                field="total_tds_tcs_shrt_amtp"
                                                className='long-column-title'
                                                title='Amount of tax deducted/collected out of (8)'
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                headerClassName={isColumnActive('total_tds_tcs_shrt_amtp') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title={renderSubTextTitle(totals.total_tds_tcs_shrt_amtp)} width={150} />
                                            </Column>
                                        </Column>
                                        <Column title='(10)' width={90} >
                                            <Column
                                                width="150"
                                                field="total_tds_tcs_nodep_amtp"
                                                className='long-column-title'
                                                title='Amount of tax deducted/collected not deposited to the credit of the Central Government out of (6) and (8)'
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                headerClassName={isColumnActive('total_tds_tcs_nodep_amtp') ? 'active cursor-pointer' : 'cursor-pointer'}
                                            >
                                                <Column title={renderSubTextTitle(totals.total_tds_tcs_nodep_amtp)} width={150} />
                                            </Column>
                                        </Column>
                                    </Grid>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportClause34a;
