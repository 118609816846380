import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '../../../app/constants';
import Chart from 'react-google-charts';
import SkeletonGraphCircle from '../../../components/Skeleton/SkeletonGraphCircle';
//import ReactApexChart from 'react-apexcharts'
//import PieChart from './PieChart';

const ComplianceCheckChart = (props) => {

  const { returnId, deductorId, chartDataValue, chartDataColor, skeletonChartLoading, finanicalYear, formType } = props;

  const [noData, setNoData] = useState(true);

  //const { returnId, deductorId, chartDataValue, chartDataColor, skeletonChartLoading, complianceStatusData, chartDataValue1 } = props;
  //const chartDataLabel = Object.values(COMPLIANCE_PIECHART_LIST);

  useEffect(() => {
    if (chartDataValue.length > 0) {
      let dataFound = true;
      chartDataValue.forEach((item) => {
        if (item?.[0] !== 'Task' && parseInt(item?.[1]) > 0) {
          dataFound = false
        }
      })
      setNoData(dataFound);
    }
  }, [chartDataValue])


  return (
    <>
      <fieldset className="complincheck_fs h-100">
        <legend className="complincheck_leg">
          <div className="complincheck_inndev">
            <div className="complincheck_leftside">
              <h4>
                Compliance Check
              </h4>
            </div>
            <div className="complincheck_innerhieghtdiv"></div>
            <div className="complincheck_rightsidediv">
              <Link
                className="fffClr mt-1 mb-1"
                to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&financial_year=${finanicalYear}`}
                target="_blank"
                tabIndex="-1"
              >
                <button className="btn btn-default complincheck_viewbtn">View Details</button>
              </Link>
            </div>
            <div className="complincheck_topbormiddle"></div>
          </div>
        </legend>
        <div className="compcheck_chartbox text-center">
          {
            skeletonChartLoading ? <SkeletonGraphCircle />
              :
              noData === false
                ?
                <Chart
                  width="100%"
                  height={formType === '24Q' ? '150px' : '230px'}  
                  chartType="PieChart"
                  //loader={<div style={{ paddingTop: '25px', paddingLeft: '280px', fontSize: '12px' }}>Loading Chart...</div>}
                  data={chartDataValue}
                  options={{
                    title: '',
                    legend: { position: 'bottom', labeledValueText: 'both', },
                    colors: chartDataColor,
                    tooltip: { text: 'both' },
                    is3D: true,
                    //sliceVisibilityThreshold: 0,
                  }}
                  rootProps={{ 'data-testid': '1' }}
                />
                :
                <div className='h-100' style={{ paddingTop: `${formType === '24Q' ? '60px' : '130px'}` }}>No Such data to Display</div>
          }

        </div>
      </fieldset>
    </>

  );
};

export default ComplianceCheckChart;
