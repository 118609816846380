import React, { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { getDeducteesTransactionsMismatchReports } from "../../api/deducteeTransactionsAPI";
import ShowAlert from "../../components/ShowAlert";
import RateMismatch from "./RateMismatch";
import DateMismatch from "./DateMismatch";
import './DeducteeTransactionsV2.scss';
import './MismatchReport.scss';

const ConflictPopup = ({ history, ...props }) => {
    const { show, closeModal, deductorId, returnId, PAN_LIST, paymentCodes } = props;

    const [loading, setLoading] = useState(false);
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [error, setError] = useState(false);
    const [rateWiseConflictList, setRateWiseConflictList] = useState([]);
    const [dateWiseConflictList, setDateWiseConflictList] = useState([]);
    const [dataState, dataStateChange] = useState({ take: 10, skip: 0, sort: false, filter: { filters: [] } });
    const [dateDataState, setDateDataState] = useState({ take: 10, skip: 0, sort: false, filter: { filters: [] } });
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [cursor1, setCursor1] = useState({ current_page: 1, next_page_url: null });

    const getRateWiseConflict = (data) => {
        async function fetchEverything() {
            async function fetchRateWiseConflictList() {
                data.additional_filter = 'rate_mismatch';
                const result = await getDeducteesTransactionsMismatchReports(data);
                if (result.data.cursor.current_page > 1) {
                    setRateWiseConflictList(rateWiseConflictList.concat(result.data.data ?? []));
                } else {
                    setRateWiseConflictList(result.data.data ?? []);
                }
                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page;
                setCursor(result?.data?.cursor);
            }
            try {
                await fetchRateWiseConflictList();
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.message);
            } finally {
                setLoading(false);
                setShowMoreLoading(false);
            }
        }

        if (data.page > 1) { setShowMoreLoading(true); } else { setLoading(true); }
        fetchEverything();
    };

    const getDateWiseConflict = (data) => {

        async function fetchEverything() {
            async function fetchPosConflictList() {
                data.additional_filter = 'date_mismatch';
                const result = await getDeducteesTransactionsMismatchReports(data);
                setDateWiseConflictList(result?.data?.data ?? []);
                if (result.data.cursor.current_page > 1) {
                    setDateWiseConflictList(dateWiseConflictList.concat(result.data.data ?? []));
                } else {
                    setDateWiseConflictList(result.data.data ?? []);
                }
                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page;
                setCursor1(result?.data?.cursor);
            }
            try {
                await fetchPosConflictList();
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.message);
            } finally {
                setLoading(false);
                setShowMoreLoading(false);
            }
        }

        if (data.page > 1) { setShowMoreLoading(true); } else { setLoading(true); }
        fetchEverything();
    };

    const fetchData = (filterData = dataState) => {
        let request = {
            deductor_id: deductorId,
            return_id: returnId,
            limit: 20
        };

        if (filterData.sort && filterData.sort.length > 0) {
            request.sort_on = filterData.sort[0].field;
            request.order_by = filterData.sort[0].dir;
        }

        if (filterData.filter && filterData.filter.filters) {
            request.filters = filterData.filter.filters;
        }

        if (filterData.page) {
            request.page = filterData.page;
        }

        getRateWiseConflict(request);
    }

    const fetchDateWiseConflictData = (filterData = dateDataState) => {
        let request = {
            deductor_id: deductorId,
            return_id: returnId,
            limit: 20
        };

        if (filterData.sort && filterData.sort.length > 0) {
            request.sort_on = filterData.sort[0].field;
            request.order_by = filterData.sort[0].dir;
        }

        if (filterData.filter && filterData.filter.filters) {
            request.filters = filterData.filter.filters;
        }

        if (filterData.page) {
            request.page = filterData.page;
        }

        getDateWiseConflict(request);
    }

    const onChangeFilter = (newDataState) => {
        dataStateChange(newDataState);
        fetchData(newDataState);
    }

    const onChangePosFilter = (newDataState) => {
        setDateDataState(newDataState);
        fetchDateWiseConflictData(newDataState);
    }

    const isColumnActive = (field, dataStateVal = dataState) => {
        let active = false;
        if (dataStateVal && dataStateVal.filter) {
            dataStateVal.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return false;
            });
        }
        return active;
    };

    const isposColumnActive = (field, dataStateVal = dataState) => {
        let active = false;
        if (dataStateVal && dataStateVal.filter) {
            dataStateVal.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return false;
            });
        }
        return active;
    };

    useEffect(() => {
        fetchData(dataState);
        fetchDateWiseConflictData(dateDataState);
        //eslint-disable-next-line
    }, [deductorId, returnId]);


    return (
        <>
            <div className="mismatchmodal">
                <ShowAlert
                    success={null}
                    error={error}
                    onClose={() => {
                        setError(null);
                    }}
                />
                {show && (
                    <Dialog
                        title="Mismatch Report"
                        onClose={closeModal}
                        className="Mismatch_report_modal"
                        width="100%"
                    >
                        {loading ? <LoadingIndicator /> : null}

                        <div className="container-fluid">
                            <div className="row mt-2">
                                <div class="col-md-12">
                                    <nav class="Mismatch_report_nav">
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <a class="nav-item nav-link Mismatch_report_ratemist active Mismatch_report_borderrightnon" id="nav-home-tab" data-toggle="tab" href="#Mismatch_report_ratemist_tab01" role="tab" aria-controls="nav-home" aria-selected="true">
                                                Rate Mismatch
                                            </a>
                                            <a class="nav-item nav-link Mismatch_report_ratemist" id="nav-home-tab" data-toggle="tab" href="#Mismatch_report_ratemist_tab02" role="tab" aria-controls="nav-home" aria-selected="true">
                                                Date Mismatch
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                                <div class="col-md-12 pt-2">
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="Mismatch_report_ratemist_tab01" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div class="card px-0 pb-0">
                                                <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <RateMismatch
                                                        rateWiseConflictList={rateWiseConflictList}
                                                        dataState={dataState}
                                                        onChangeFilter={onChangeFilter}
                                                        isColumnActive={isColumnActive}
                                                        loading={loading}
                                                        cursor={cursor}
                                                        dataStateChange={dataStateChange}
                                                        fetchData={fetchData}
                                                        showMoreLoading={showMoreLoading}
                                                        PAN_LIST={PAN_LIST}
                                                        paymentCodes={paymentCodes}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade show" id="Mismatch_report_ratemist_tab02" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div class="card px-0 pb-0">
                                                <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <DateMismatch
                                                        dateWiseConflictList={dateWiseConflictList}
                                                        dataState={dateDataState}
                                                        onChangeFilter={onChangePosFilter}
                                                        isColumnActive={isposColumnActive}
                                                        loading={loading}
                                                        cursor={cursor1}
                                                        dataStateChange={setDateDataState}
                                                        fetchData={fetchDateWiseConflictData}
                                                        showMoreLoading={showMoreLoading}
                                                        PAN_LIST={PAN_LIST}
                                                        paymentCodes={paymentCodes}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        </>
    )
}

export default ConflictPopup;