/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} base   Object to compare with
 * @param  {Object} object Object compared
 * @return {Object}        Return a new object who represent the diff
 */
export function objectDiff(base, object) {
  function changes(obj, baseObj) {
    return _.transform(obj, (result, value, key) => {
      if (!_.isEqual(value, baseObj[key])) {
        result[key] = (_.isObject(value) && _.isObject(baseObj[key])) ? changes(value, baseObj[key]) : value;
      }
    });
  }
  return changes(object, base);
}
