import React, { useEffect, useState } from 'react'
import { gocallApi } from '../../../api/issueCertificateAPI';
import ShowAlert from '../../../components/ShowAlert';
import { convertNum, formatNumber } from '../../../utils/UtilityFunctions';
import { BASE_PATH } from '../../../app/constants';
import ReactApexChart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSquare
} from '@fortawesome/free-solid-svg-icons';

const AllChallans = (props) => {
    const { deductorId, financialYear } = props;

    const defaultOptions = {
        chart: {
            height: 350,
            type: 'radialBar',
            events: {
                // click:(e, chart, options) => console.log("e", options),
                dataPointSelection: (event, chartContext, config) => {
                    if(config.dataPointIndex >= 0 && challanHeading?.[config.dataPointIndex]){
                        const pathList = config?.w?.config?.responsive ?? []
                        if(pathList?.length > 0){
                            const path = pathList?.[config.dataPointIndex];
                            window.open(`${path?.path}?deductor_id=${path?.deductorId}&financial_year=${path?.financial_year}`, '_blank');
                        }
                    }
                }
            },
        },
        colors: [],
        plotOptions: {
            hollow: {
                dropShadow: {
                    enabled: true,
                    top: 25,
                    left: 35,
                    blur: 0.5,
                    opacity: 0.6,
                    color: "#ff0000",
                },
                size: "50%"

            },
            radialBar: {
                dataLabels: {
                    show: false,
                },
                hollow: {
                    size: '20%', // Adjust this value to control the size of the inner circle
                },
                track: {
                    show: true,
                    strokeWidth: '80%', // Adjust this value to control the width of the track (outer circle)
                },
            }
            
        },
        responsive: [],
        labels: [],

    }

    const challanHeading = [
        {
            heading: "Total Verified Challans",
            key: "verified_challan_amtp", 
            color: "#008000", 
            path: `${BASE_PATH}challan-list/verified`
        },
        {
            heading: "Total Unverified Challans",
            key: "unverified_challan_amtp",
            color: "#ff0000", 
            path: `${BASE_PATH}challan-list/unverified`
        },
        {
            heading: "Total Unconsumed that can be utilized",
            key: "unconsumed_challan_amtp",
            color: "#ff9802", 
            path: `${BASE_PATH}challan-list/unconsumed`
        }
    ]

    const [challans, setChallans] = useState({});
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [option, setOption] = useState(defaultOptions);
    const [series, setSeries] = useState([0, 0, 0]);

    const getChallansList = async (deductorId, financialYear) => {
        let url = `/api/v1/report/deductor/fy-challan-summary?deductor_id=${deductorId}&financial_year=${financialYear}`
        try {
            const result = await gocallApi("get", url);
            const summaryTotal = convertNum(result?.data?.unconsumed_challan_amtp) + convertNum(result?.data?.unverified_challan_amtp) + convertNum(result?.data?.verified_challan_amtp);
            const labelPercentageValues = [];
            
            challanHeading.forEach((item) => {
                const total = summaryTotal;
                const value = result?.data?.[item.key]
                const perValue = total ? (value / total) * 100 : 0;
            
                labelPercentageValues.push(Math.ceil(perValue));
                defaultOptions.labels.push(item.heading);
                defaultOptions.colors.push(item.color);
                const obj = {
                    path: item.path,
                    financial_year: financialYear, 
                    deductorId: deductorId
                }
                defaultOptions.responsive.push(obj);
            })

            setOption(defaultOptions);
            setSeries(labelPercentageValues);
            setChallans(result?.data ?? {});

        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        }
    }

    useEffect(() => {
        getChallansList(deductorId, financialYear)
        // eslint-disable-next-line 
    }, [deductorId, financialYear])


    return (
        <>

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />


            <div class="col-12 col-xl-5 col-lg-6 col-md-12 col-sm-12">
                <fieldset class="Deductor_dashboard_allchallan_fs">
                    <legend class="Deductor_dashboard_allchallan_leg">
                        <h4>
                            <span class="Deductor_dashboard_allchallan_titalfs">
                                Challans Overview
                            </span>
                        </h4>
                    </legend>

                    <div className='row'>
                        <div className='col-md-5'>
                            <div className=''>
                                {/* <img src={totalchartimg} alt='chart ' width="100%" /> */}
                                <ReactApexChart
                                    height={"240x"}
                                    width={"260px"}
                                    options={option}
                                    series={series}
                                    type="radialBar"
                                    dropShadow={{
                                        enabledOnSeries: [55, 67, 83],
                                        color: ["#008000", "#ff0000", "#ff9802"]
                                    }}
                                    data-label={{
                                        firstPath: "newpath",
                                        secondPath: "secondPath"
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <ul className='allchallan_listbox'>
                                <li className='allchallan_listbox_li'>
                                    <span className='allchallan_listbox_bullticon_blue'>
                                        <FontAwesomeIcon icon={faSquare} className='' />
                                    </span>
                                    <span className='allchallan_listbox_textfs cursor_pointer'
                                        onClick={() => {
                                            window.open(`${BASE_PATH}challan-list/verified?deductor_id=${deductorId}&financial_year=${financialYear}`, '_blank')
                                        }}
                                    >
                                        {`Total verified challans (${formatNumber(challans.verified_challan_amtp ?? 0)})`}
                                    </span>
                                </li>
                                <li className='allchallan_listbox_li'>
                                    <span className='allchallan_listbox_bullticon_red'>
                                        <FontAwesomeIcon icon={faSquare} className='' />
                                    </span>
                                    <span className='allchallan_listbox_textfs cursor_pointer'
                                        onClick={() => {
                                            window.open(`${BASE_PATH}challan-list/unverified?deductor_id=${deductorId}&financial_year=${financialYear}`, '_blank')
                                        }}
                                    >
                                        {`Total unverified challans (${formatNumber(challans.unverified_challan_amtp ?? 0)})`}
                                    </span>
                                </li>
                                <li className='allchallan_listbox_li'>
                                    <span className='allchallan_listbox_bullticon_amber'>
                                        <FontAwesomeIcon icon={faSquare} className='' />
                                    </span>
                                    <span className='allchallan_listbox_textfs cursor_pointer'
                                        onClick={() => {
                                            window.open(`${BASE_PATH}challan-list/unconsumed?deductor_id=${deductorId}&financial_year=${financialYear}`, '_blank')
                                        }}
                                    >
                                        {`Total unconsumed that can be utilized (${formatNumber(challans.unconsumed_challan_amtp ?? 0)})`}
                                    </span>
                                </li>

                            </ul>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )
}

export default AllChallans;