import * as Yup from 'yup';

export const InsightFormValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(1, 'Too Short!')
        .max(125, 'Too Long!')
        .required('This field is required'),

    pan: Yup.string()
        .required('This field is required')
        .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number'),

    building: Yup.string()
        .trim()
        .min(1, 'Too Short!')
        .max(75, 'Too Long!')
        .matches(/^[a-zA-Z0-9 ()\-,./"']+$/i, "Special characters not allowed")
        .required('This field is required'),

    street: Yup.string()
        .trim()
        .min(1, 'Too Short!')
        .max(125, 'Too Long!')
        .matches(/^[a-zA-Z0-9 ()\-,./"']+$/i, "Special characters not allowed")
        .required('This field is required'),

    area: Yup.string()
        .trim()
        .min(1, 'Too Short!')
        .max(75, 'Too Long!')
        .matches(/^[a-zA-Z0-9 ()\-,./"']+$/i, "Special characters not allowed")
        .required('This field is required'),

    city: Yup.string()
        .trim()
        .min(1, 'Too Short!')
        .max(50, 'Too Long!')
        .matches(/^[a-zA-Z ,]+$/i,"Only Characters and Spaces are allowed")
        .required('This field is required'),

    pincode: Yup.number()
        .required('This field is required')
        .min(110001, 'Invalid Pincode')
        .max(999999, 'Invalid Pincode'),

    state_id: Yup.string()
        .trim()
        .required('This field is required'),
    
    country_id: Yup.string()
        .trim()
        .required('This field is required')
        .when('state_id', {
            is: (value) => (Number(value) === 99),
            then: Yup.string().matches(/^(?!91).*$/, 'Select Other than India'),
        }).when('state_id', {
            is: (value) => (value && Number(value) !== 99),
            then: Yup.string().matches(/^(91).*$/, 'Select India '),
        }),

    principal_officer_details: Yup.object().shape({
        pan: Yup.string()
            .required('This field is required')
            .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number'),

        first_name: Yup.string()
            .trim()
            .min(1, 'Too Short!')
            .max(70, 'Too Long!')
            .matches(/^[a-zA-Z ,]+$/i,"Only Characters and Spaces are allowed")
            .required('This field is required'),

        middle_name: Yup.string()
            .trim()
            .max(70, 'Too Long!')
            .matches(/^[a-zA-Z ,]+$/i,"Only Characters and Spaces are allowed"),

        last_name: Yup.string()
            .trim()
            .min(1, 'Too Short!')
            .max(70, 'Too Long!')
            .matches(/^[a-zA-Z ,]+$/i,"Only Characters and Spaces are allowed")
            .required('This field is required'),

        designation: Yup.string()
            .trim()
            .min(1, 'Too Short!')
            .max(70, 'Too Long!')
            .matches(/^[a-zA-Z0-9 ()\-,./"']+$/i, "Special characters not allowed")
            .required('This field is required'),

        mobile_no: Yup.number()
            .required('This field is required.')
            .min(6000000000, 'Invalid Mobile Number')
            .max(9999999999, 'Invalid Mobile Number'),

        email: Yup.string()
            .trim()
            .min(1, 'Too Short!')
            .max(125, 'Too Long!')
            .required('This field is required')
            .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

        alternate_email: Yup.string()
            .trim()
            .max(125, 'Too Long!')
            .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

        building: Yup.string()
            .trim()
            .min(1, 'Too Short!')
            .max(75, 'Too Long!')
            .matches(/^[a-zA-Z0-9 ()\-,./"']+$/i, "Special characters not allowed")
            .required('This field is required'),

        street: Yup.string()
            .trim()
            .min(1, 'Too Short!')
            .max(125, 'Too Long!')
            .matches(/^[a-zA-Z0-9 ()\-,./"']+$/i, "Special characters not allowed")
            .required('This field is required'),
    
        area: Yup.string()
            .trim()
            .min(1, 'Too Short!')
            .max(75, 'Too Long!')
            .matches(/^[a-zA-Z0-9 ()\-,./"']+$/i, "Special characters not allowed")
            .required('This field is required'),

        city: Yup.string()
            .trim()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .matches(/^[a-zA-Z ,]+$/i,"Only Characters and Spaces are allowed")
            .required('This field is required'),

        pincode: Yup.number()
            .required('This field is required')
            .min(110001, 'Invalid Pincode')
            .max(999999, 'Invalid Pincode'),

        state_id: Yup.string()
            .trim()
            .required('This field is required'),

        country_id: Yup.string()
            .trim()
            .required('This field is required')
            .when('state_id', {
                is: (value) => (Number(value) === 99),
                then: Yup.string().matches(/^(?!91).*$/, 'Select Other than India'),
            }).when('state_id', {
                is: (value) => (value && Number(value) !== 99),
                then: Yup.string().matches(/^(91).*$/, 'Select India '),
            }),
    })

})