/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import UpdateEFilingCredsWithoutVerify from './UpdateEFilingCredsWithoutVerify';
import { getDeductorEfilingCredentials } from '../../api/deductorAPI';
import { downloadCSI } from '../../api/challanAPI';
import ShowAlert from '../../components/ShowAlert';
import { selectHeaderData } from '../header/headerSlice';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

const DownloadCSI = ({ history, ...props }) => {
  const {handleClose, handleProceed, deductorId,  } = props;
  const [loading, setLoading] = useState(false);
  const headerDataDetails = useSelector(selectHeaderData);

  const [error, setError] = useState(null);

  const [updateCreds, setUpdateCreds] = useState(false);

  //eslint-disable-next-line
  const [showRedirectPopup, setShowRedirectPopup] = useState(false);

  const csiFileDownload = (deductorId) => {

    async function fetchEverything() {
      try {
        setLoading(true);
        const result = await getDeductorEfilingCredentials(`?deductor_id=${deductorId}`, false);

        const requestParams = {
          "deductor_id": deductorId,
          "password": result.data.itdportal_password
        }

        const downloadResult = await downloadCSI(requestParams);

        const csiData = new Blob([downloadResult.data], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csiData);
        link.setAttribute('download', `${downloadResult.header.tan}.csi`);
        document.body.appendChild(link);
        link.click();

        handleProceed();


      } catch (err) {
        if (err.code === 424) {
          setUpdateCreds(true);
          setError(err.message);
        } else {
          setError(err.message);
        }
      }finally{
        setLoading(false);
      }

    }
    fetchEverything();

  };

  useEffect(() => {
    csiFileDownload(deductorId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductorId]);


  return (
    <>

    {loading ? <LoadingIndicator /> : null}

      {error &&
        <ShowAlert
          error={error}
          onClose={() => setError(null)}
        />
      }

      {updateCreds &&
        <UpdateEFilingCredsWithoutVerify
          show={updateCreds}
          handleClose={handleClose}
          deductorId={deductorId ?? ''}
          tan={headerDataDetails.tan ?? ''}
          handleProceed={() => {
            csiFileDownload(deductorId);
            setUpdateCreds(false);
          }}

        />}
    </>
  );
};

export default DownloadCSI;
