import React from 'react'
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1, roundUpValueWithoutDecimal } from '../../../utils/UtilityFunctions';
import PANInput from '../../../components/PANInput';
import moment from 'moment';


const OtherDetails = (props) => {
    const { data, setData, updateEmployeeDetail, otherDetailError, handleParentChildArrayInputChange, handleParentChildArrayNumberInputOnBlur,
        deleteIcon, addBtnRoundIcon, landlordRowObj, handleObjInputChange, financialYear, deducteeDet, setDeducteeDet, setOtherDetailError,
        handleObjNumberInputOnBlur
    } = props;

    const calculateTotal = (calcData, key) => {
        let total = 0;
        if (calcData?.length > 0) {

            total = calcData.reduce((prev, curr) => {
                return prev += convertNum(curr?.[key] ?? 0);
            }, 0);

        }

        return total;
    };

    return (
        <>
            <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse collapsed" data-toggle="collapse" href="#salarytaxregime_edit_otherdetailscollap01" aria-expanded="false"></span>

                </th>
                <th width="550" className="text-left">
                    Other Details
                </th>
                <th width="120" className="text-right">
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_otherdetailscollap01" className="in collapse">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>
                                    <tr className="">
                                        <th width="10" id="accordion" className="accordion">
                                            <span className="salarytaxregime_edit_collapse collapsed" data-toggle="collapse" href="#salarytax_edit_otherdetails_innercollap01" aria-expanded="false"></span>
                                        </th>
                                        <th width="550" className="text-left">
                                            Whether Total Rent Paid 1 Lakh?
                                            <span className="salarytax_otherdetails_Yesbtnbox pl-2">
                                                <button
                                                    className={`btn btn-default ${data?.other_details?.whether_total_rent_greater_onelakh ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"} mr-2`}
                                                    onClick={() => {
                                                        if (data?.other_details) {
                                                            data.other_details.whether_total_rent_greater_onelakh = true;
                                                            setData({ ...data });
                                                            updateEmployeeDetail(data);
                                                        }
                                                    }}
                                                >Yes</button>
                                                <button
                                                    className={`btn btn-default ${!data?.other_details?.whether_total_rent_greater_onelakh ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"}`}
                                                    onClick={() => {
                                                        if (data?.other_details) {
                                                            data.other_details.landlord_details = [{}]
                                                            data.other_details.whether_total_rent_greater_onelakh = false;
                                                            otherDetailError.landlord_details = [];
                                                            setOtherDetailError({...otherDetailError});
                                                            setData({ ...data });
                                                            updateEmployeeDetail(data);
                                                        }

                                                    }}
                                                >No</button>
                                            </span>
                                        </th>
                                        <th width="120" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={calculateTotal(data?.other_details?.landlord_details ?? [], "rent_amount_amtp")}
                                                placeholder=""

                                            />
                                        </th>

                                    </tr>

                                    <tr className="hide-table-padding">
                                        <td colspan="15" className="p-0">
                                            <div id="salarytax_edit_otherdetails_innercollap01" className="in collapse">
                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow-visible">
                                                    <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="37"></th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop  ">Name of Landlord</th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop ">PAN of Landlord</th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop">Rent Amt</th>
                                                                <th width="20" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.other_details?.landlord_details?.length > 0 &&
                                                                data?.other_details?.landlord_details.map((item, index) => {
                                                                    const inputDisabled = data?.other_details?.whether_total_rent_greater_onelakh ? false : true;
                                                                    const bdr_btm = (index + 1) === data?.other_details?.landlord_details?.length ? "salarytaxreg_innertb_borderbottom" : "";
                                                                    const errsObj = otherDetailError?.landlord_details?.[index] ?? {};

                                                                    return (<tr className="" key={index}>
                                                                        <td width=""></td>
                                                                        <td width=""
                                                                            className={`salarytaxreg_innertb_bgclr_lightyell ${bdr_btm} salarytaxreg_innertb_borderleft `}>
                                                                            <BlurOnChangeInput
                                                                                type="text"
                                                                                className={`form-control salarytaxregime_edit_inputbox text-left ${errsObj.name_of_landlord ? "input-error" : ""}`}
                                                                                value={item?.name_of_landlord ?? ""}
                                                                                data-toggle="tooltip" data-placement="top" data-original-title={errsObj.name_of_landlord ? errsObj.name_of_landlord : ""}
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    handleParentChildArrayInputChange("other_details", "landlord_details", index, "name_of_landlord", e.target.value)
                                                                                }}
                                                                                onBlur={(e) => updateEmployeeDetail(data)}
                                                                                disabled={inputDisabled}
                                                                            />
                                                                        </td>
                                                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell ${bdr_btm}`}>

                                                                            <PANInput
                                                                                value={item?.pan_of_landlord ?? ""}
                                                                                disabled={inputDisabled}
                                                                                className={`salarytaxregime_edit_inputbox text-left ${errsObj.pan_of_landlord ? "input-error" : ""}`}
                                                                                id="pan_of_lender"
                                                                                data-toggle="tooltip" data-placement="top" data-original-title={errsObj.pan_of_landlord ? errsObj.pan_of_landlord : ""}
                                                                                onChange={(v) => {
                                                                                    const value = v?.trim()?.toUpperCase();
                                                                                    handleParentChildArrayInputChange("other_details", "landlord_details", index, "pan_of_landlord", value);
                                                                                }}
                                                                                data={['GOVERNMENT', 'NONRESDENT', 'OTHERVALUE']}
                                                                                onBlur={(e) => updateEmployeeDetail(data)}
                                                                            />
                                                                        </td>

                                                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell ${bdr_btm}`}>

                                                                            <BlurOnChangeInput
                                                                                type="text"
                                                                                className={`form-control salarytaxregime_edit_inputbox text-right ${errsObj.rent_amount_amtp ? "input-error" : ""}`}
                                                                                value={FormatNumberDecimal(item?.rent_amount_amtp, ...Array(2), false)}
                                                                                data-toggle="tooltip" data-placement="top" data-original-title={errsObj.rent_amount_amtp ? errsObj.rent_amount_amtp : ""}
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e)
                                                                                    handleParentChildArrayInputChange("other_details", "landlord_details", index, "rent_amount_amtp", value)
                                                                                }}
                                                                                onBlur={(e) => handleParentChildArrayNumberInputOnBlur("other_details", "landlord_details", index, "rent_amount_amtp", item?.rent_amount_amtp)}
                                                                                disabled={inputDisabled}

                                                                            />

                                                                        </td>
                                                                        <td className={`salarytaxreg_innertb_bgclr_lightyell ${bdr_btm} salarytaxreg_innertb_borderright`}>
                                                                            <span className="">
                                                                                {index > 0 &&
                                                                                    <img src={deleteIcon} alt="addicon" width="16"
                                                                                        onClick={() => {
                                                                                            data.other_details.landlord_details.splice(index, 1);
                                                                                            setData({ ...data });
                                                                                            updateEmployeeDetail(data);

                                                                                        }}
                                                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                                    />
                                                                                }

                                                                                {(index + 1) !== 4 &&
                                                                                    (index + 1) === data?.other_details?.landlord_details?.length &&
                                                                                    <img src={addBtnRoundIcon} alt="addicon" width="16" 
                                                                                       className={`${!data?.other_details?.whether_total_rent_greater_onelakh ? "disabled" : ""}`}
                                                                                        onClick={() => {
                                                                                            data.other_details.landlord_details.push(landlordRowObj);
                                                                                            setData({ ...data });
                                                                                        }}
                                                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                                    />
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                    </tr>)
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>



                                    <tr className="">
                                        <th width="10" id="accordion" className="accordion">
                                            <span className="salarytaxregime_edit_collapse collapsed" data-toggle="collapse" href="#salarytax_edit_otherdetails_innercollap02" aria-expanded="false"></span>
                                        </th>
                                        <th className="text-left">
                                            Whether Interest Paid to Lender
                                            <span className="salarytax_otherdetails_Yesbtnbox pl-2">
                                                <button
                                                    className={`btn btn-default ${data?.other_details?.whether_interest_paid_to_lender ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"} mr-2`}
                                                    onClick={() => {
                                                        if (data?.other_details) {
                                                            data.other_details.whether_interest_paid_to_lender = true;
                                                            setData({ ...data });
                                                            updateEmployeeDetail(data);
                                                        }
                                                    }}
                                                >Yes</button>
                                                <button
                                                    className={`btn btn-default ${!data?.other_details?.whether_interest_paid_to_lender ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"}`}
                                                    onClick={() => {
                                                        if (data?.other_details) {
                                                            data.other_details.whether_interest_paid_to_lender = false;
                                                            data.other_details.lender_details = [{}];
                                                            otherDetailError.lender_details = [];
                                                            setOtherDetailError({...otherDetailError});
                                                            setData({ ...data });
                                                            updateEmployeeDetail(data);
                                                        }
                                                    }}
                                                >No</button>
                                            </span>
                                        </th>
                                        <th className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={calculateTotal(data?.other_details?.lender_details ?? [], "interest_amount_amtp")}
                                                placeholder=""

                                            />
                                        </th>

                                    </tr>

                                    <tr className="hide-table-padding">
                                        <td colspan="15" className="p-0">
                                            <div id="salarytax_edit_otherdetails_innercollap02" className="in collapse">
                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow-visible">
                                                    <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="37"></th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop  ">Name of Lender</th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop ">PAN of Lender</th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop">Interest Amt</th>
                                                                <th width="20" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.other_details?.lender_details?.length > 0 &&
                                                                data?.other_details?.lender_details.map((item, index) => {
                                                                    const inputDisabled = data?.other_details?.whether_interest_paid_to_lender ? false : true;
                                                                    const bdr_btm = (index + 1) === data?.other_details?.lender_details?.length ? "salarytaxreg_innertb_borderbottom" : "";
                                                                    const errsObj = otherDetailError?.lender_details?.[index] ?? {};

                                                                    return (<tr className="" key={index}>
                                                                        <td width=""></td>
                                                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell ${bdr_btm} salarytaxreg_innertb_borderleft `}>
                                                                            <BlurOnChangeInput
                                                                                type="text"
                                                                                className={`form-control salarytaxregime_edit_inputbox text-left ${errsObj.name_of_lender ? "input-error" : ""}`}
                                                                                value={item?.name_of_lender ?? ""}
                                                                                data-toggle="tooltip" data-placement="top" data-original-title={errsObj.name_of_lender ? errsObj.name_of_lender : ""}
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    handleParentChildArrayInputChange("other_details", "lender_details", index, "name_of_lender", e.target.value)
                                                                                }}
                                                                                onBlur={(e) => updateEmployeeDetail(data)}
                                                                                disabled={inputDisabled}

                                                                            />
                                                                        </td>
                                                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell ${bdr_btm}`}>
                                                                            <PANInput
                                                                                value={item?.pan_of_lender ?? ""}
                                                                                disabled={inputDisabled}
                                                                                className={`salarytaxregime_edit_inputbox text-left ${errsObj.pan_of_lender ? "input-error" : ""}`}
                                                                                data-toggle="tooltip" data-placement="top" data-original-title={errsObj.pan_of_lender ? errsObj.pan_of_lender : ""}
                                                                                id="pan_of_lender"
                                                                                onChange={(v) => {
                                                                                    const value = v?.trim()?.toUpperCase();
                                                                                    handleParentChildArrayInputChange("other_details", "lender_details", index, "pan_of_lender", value);
                                                                                }}
                                                                                data={['GOVERNMENT', 'NONRESDENT', 'OTHERVALUE']}
                                                                                onBlur={() => updateEmployeeDetail(data)}
                                                                            />
                                                                        </td>

                                                                        <td width="" className={`salarytaxreg_innertb_bgclr_lightyell ${bdr_btm} `}>

                                                                            <BlurOnChangeInput
                                                                                type="text"
                                                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                                                value={FormatNumberDecimal(item?.interest_amount_amtp, ...Array(2), false)}
                                                                                data-toggle="tooltip" data-placement="top" data-original-title={""}
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e)
                                                                                    handleParentChildArrayInputChange("other_details", "lender_details", index, "interest_amount_amtp", value)
                                                                                }}
                                                                                onBlur={(e) => handleParentChildArrayNumberInputOnBlur("other_details", "lender_details", index, "interest_amount_amtp", item?.interest_amount_amtp)}
                                                                                disabled={inputDisabled}

                                                                            />

                                                                        </td>
                                                                        <td className={`salarytaxreg_innertb_bgclr_lightyell ${bdr_btm} salarytaxreg_innertb_borderright`}>
                                                                            <span className="deleicon_pos" style={{ width: "25%" }}>
                                                                                {index > 0 &&
                                                                                    <img src={deleteIcon} alt="addicon" width="16"
                                                                                        onClick={() => {
                                                                                            data.other_details.lender_details.splice(index, 1);
                                                                                            setData({ ...data });
                                                                                            updateEmployeeDetail(data);

                                                                                        }}
                                                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                                    />
                                                                                }

                                                                                {(index + 1) !== 4 &&
                                                                                    (index + 1) === data?.other_details?.lender_details?.length &&
                                                                                    <img src={addBtnRoundIcon} alt="addicon" width="16"
                                                                                    className={`${!data?.other_details?.whether_interest_paid_to_lender ? "disabled" : ""}`}
                                                                                        onClick={() => {
                                                                                            data.other_details.lender_details.push(landlordRowObj);
                                                                                            setData({ ...data });
                                                                                        }}
                                                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                                    />
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                    </tr>)
                                                                })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <th width="10" id="accordion" className="accordion">
                                            <span className="salarytaxregime_edit_collapse collapsed" data-toggle="collapse" href="#salarytax_edit_otherdetails_innercollap03" aria-expanded="false"></span>
                                        </th>
                                        <th className="text-left">
                                            Whether Amt Rec. from Superannuation Fund?
                                            <span className="salarytax_otherdetails_Yesbtnbox pl-2">
                                                <button
                                                    className={`btn btn-default ${data?.other_details?.whether_amtrec_from_superannuation_fund ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"} mr-2`}
                                                    onClick={() => {
                                                        if (data?.other_details) {
                                                            data.other_details.whether_amtrec_from_superannuation_fund = true;
                                                            setData({ ...data });
                                                            updateEmployeeDetail(data);
                                                        }
                                                    }}
                                                >Yes</button>
                                                <button
                                                    className={`btn btn-default ${!data?.other_details?.whether_amtrec_from_superannuation_fund ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"}`}
                                                    onClick={() => {
                                                        if (data?.other_details) {
                                                            data.other_details.fund_name = "";
                                                            data.other_details.fund_from = null;
                                                            data.other_details.fund_to = null;
                                                            data.other_details.fund_received_amtp = "";
                                                            data.other_details.tax_rate_b100 = "";
                                                            data.other_details.tds_deducted_amtp = "";
                                                            data.other_details.whether_amtrec_from_superannuation_fund = false;
                                                            deducteeDet.supAnnDateRange.start = null;
                                                            deducteeDet.supAnnDateRange.end = null;
                                                            otherDetailError.from_superannuation_fund = {};
                                                            setOtherDetailError({...otherDetailError});
                                                            setDeducteeDet({...deducteeDet});
                                                            setData({ ...data });
                                                            updateEmployeeDetail(data);
                                                        }

                                                    }}
                                                >No</button>
                                            </span>
                                        </th>
                                        <th className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={data?.other_details?.fund_received_amtp ?? 0}
                                            />
                                        </th>

                                    </tr>

                                    <tr className="hide-table-padding">
                                        <td colspan="15" className="p-0">
                                            <div id="salarytax_edit_otherdetails_innercollap03" className="in collapse">
                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="37"></th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop  ">Name of Fund</th>
                                                                {/* <th colspan="" width="120" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop">From</th> */}
                                                                <th colspan="2" width="150"
                                                                    className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop"
                                                                >

                                                                    <span className="supannuatioheadingcombine">
                                                                        <span>From</span>
                                                                        <span>To</span>
                                                                    </span>
                                                                </th>
                                                                <th className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="">
                                                                <td width=""></td>
                                                                <td width="" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft ">
                                                                    <BlurOnChangeInput
                                                                        type="text"
                                                                        className={`form-control salarytaxregime_edit_inputbox ${otherDetailError?.from_superannuation_fund?.fund_name ? "input-error" : ""}`}
                                                                        value={data?.other_details?.fund_name ?? ""}
                                                                        data-toggle="tooltip" data-placement="top"
                                                                        data-original-title={otherDetailError?.from_superannuation_fund?.fund_name ? otherDetailError?.from_superannuation_fund?.fund_name : ""}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            handleObjInputChange("other_details", "fund_name", e.target.value);
                                                                        }}
                                                                        onBlur={(e) => updateEmployeeDetail(data)}
                                                                        disabled={!data?.other_details?.whether_amtrec_from_superannuation_fund ? true : false}
                                                                    />
                                                                </td>

                                                                <td colspan="2" width=""
                                                                    className="salarytaxreg_innertb_bgclr_lightyell supnatdaterangepicker">
                                                                    <Tooltip anchorElement="pointer" position="right" >

                                                                        <DateRangePicker
                                                                            value={
                                                                                {
                                                                                    start: deducteeDet?.supAnnDateRange?.start,
                                                                                    end: deducteeDet?.supAnnDateRange?.end
                                                                                }
                                                                            }
                                                                            className={`${otherDetailError?.from_superannuation_fund?.fund_from ? "frminperr" : ""} ${otherDetailError?.from_superannuation_fund?.fund_to ? "toinperr" : ""}`}
                                                                            onChange={(e) => {
                                                                                const startDate = e.target.value?.start ? moment(e.target.value?.start).format("DD/MM/YYYY") : null;
                                                                                const toDate = e.target.value?.end ? moment(e.target.value?.end).format("DD/MM/YYYY") : null;
                                                                                // show date different state(deducteedet)
                                                                                deducteeDet.supAnnDateRange.start = e.target.value?.start;
                                                                                deducteeDet.supAnnDateRange.end = e.target.value?.end;

                                                                                handleObjInputChange("other_details", "fund_from", startDate);
                                                                                handleObjInputChange("other_details", "fund_to", toDate);
                                                                                setDeducteeDet({ ...deducteeDet });
                                                                                setData({ ...data });
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                updateEmployeeDetail(data);
                                                                            }}

                                                                            endDateInputSettings={{
                                                                                title: `${otherDetailError?.from_superannuation_fund?.fund_to ? otherDetailError?.from_superannuation_fund?.fund_to : ""}`,
                                                                                className: `toerrorclass`,
                                                                                format: "dd/MM/yyyy",
                                                                                formatPlaceholder: {
                                                                                    year: "YYYY",
                                                                                    month: "MM",
                                                                                    day: "DD"
                                                                                },

                                                                            }}
                                                                            min={new Date(`${moment(`01/04/${financialYear}`, "DD/MM/YYYY")}`)}
                                                                            max={new Date(`${moment(`31/03/${financialYear + 1}`, "DD/MM/YYYY")}`)}

                                                                            startDateInputSettings={{
                                                                                title: `${otherDetailError?.from_superannuation_fund?.fund_from ? otherDetailError?.from_superannuation_fund?.fund_from : ""}`,
                                                                                className: `fromerrorclass`,
                                                                                format: "dd/MM/yyyy",
                                                                                formatPlaceholder: {
                                                                                    year: "YYYY",
                                                                                    month: "MM",
                                                                                    day: "DD"
                                                                                }
                                                                            }}
                                                                            disabled={!data?.other_details?.whether_amtrec_from_superannuation_fund ? true : false}
                                                                        />
                                                                    </Tooltip>
                                                                </td>
                                                                <td className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright"></td>
                                                            </tr>
                                                            <tr>
                                                                <th width="37"></th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft  ">Amt Received</th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell  ">Tax Rate</th>
                                                                <th width="150" className="salarytaxreg_innertb_bgclr_lightyell  ">TDS Deducted</th>
                                                                <th width="20" className="salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_borderright"></th>
                                                            </tr>

                                                            <tr className="">
                                                                <td width=""></td>
                                                                <td width="" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom">
                                                                    <BlurOnChangeInput
                                                                        type="text"
                                                                        className={`form-control salarytaxregime_edit_inputbox text-right ${otherDetailError?.from_superannuation_fund?.fund_received_amtp ? "input-error" : ""}`}
                                                                        value={FormatNumberDecimal(data?.other_details?.fund_received_amtp ?? "", ...Array(2), false)}
                                                                        data-toggle="tooltip" data-placement="top"
                                                                        data-original-title={otherDetailError?.from_superannuation_fund?.fund_received_amtp ? otherDetailError?.from_superannuation_fund?.fund_received_amtp : ""}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                                            const tdsDed = (convertNum(value) * convertNum(data?.other_details?.tax_rate_b100)) / 100; 

                                                                            handleObjInputChange("other_details", "fund_received_amtp", value);
                                                                            handleObjInputChange("other_details", "tds_deducted_amtp", tdsDed);
                                                                        }}
                                                                        onBlur={(e) => handleObjNumberInputOnBlur("other_details", "fund_received_amtp", data?.other_details?.fund_received_amtp)}
                                                                        disabled={!data?.other_details?.whether_amtrec_from_superannuation_fund ? true : false}
                                                                    />                                                                                    </td>
                                                                <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom">
                                                                    <BlurOnChangeInput
                                                                        type="text"
                                                                        className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                                        value={FormatNumberDecimal(data?.other_details?.tax_rate_b100 ?? "", ...Array(2), false)}
                                                                        data-toggle="tooltip" data-placement="top" data-original-title={""}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            if(convertNum(e.target.value) <= 100){
                                                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                                                const tdsDed = roundUpValueWithoutDecimal((convertNum(data?.other_details?.fund_received_amtp) * convertNum(value)) / 100); 

                                                                                handleObjInputChange("other_details", "tax_rate_b100", value);
                                                                                handleObjInputChange("other_details", "tds_deducted_amtp", tdsDed);
                                                                            }
                                                                            
                                                                        }}
                                                                        onBlur={(e) => updateEmployeeDetail(data)}
                                                                        disabled={!data?.other_details?.whether_amtrec_from_superannuation_fund ? true : false}
                                                                    />
                                                                </td>

                                                                <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom">
                                                                    <BlurOnChangeInput
                                                                        type="text"
                                                                        className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                                        value={FormatNumberDecimal(data?.other_details?.tds_deducted_amtp ?? "", ...Array(2), false)}
                                                                        data-toggle="tooltip" data-placement="top" data-original-title={""}
                                                                        placeholder=""
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom"></td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default OtherDetails