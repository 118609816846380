import React from 'react';
import Modal from 'react-bootstrap/Modal';
import certiPandrive from './image/certif_pandrive.gif';
import "./DSCertificatePopup.scss";
import moment from 'moment';

const DSCertificatePopup = (props) => {
    const { show, closeModal, dscCertificates, setReSign, setDscIndex, dscIndex, setCurrentStep, setDscCertificates, setDscertificatePopup } = props;

    return (
        <Modal
            show={show}
            onHide={closeModal}
            animation={false}
            className="tdstcs_certificate_parent tds_form16_certif_DSCpopup "
        >
            <Modal.Header className="tds_form16_certif_DSCheader">
                <h5 className="modal-title py-0" id="exampleModalLongTitle">Certificates</h5>
                <button type="button" className="close" onClick={() => closeModal()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="imgsledscbox py-2 text-center">
                                <img src={certiPandrive} alt="pandrive" width="250" />
                            </div>
                            {dscCertificates?.length > 0 ?
                                <>
                                    <div
                                        class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                                        <table class="table table-striped form16_certificate_lastsign_tb mb-0">
                                            <thead>
                                                <tr class="form16_emailselected_toprow">
                                                    <th width="20"></th>
                                                    <th width="200">
                                                        Name of the Signing Person
                                                    </th>
                                                    <th width="100">
                                                        Expiring On
                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {dscCertificates.map((item, i) => (
                                                    <tr>
                                                        <td class="text-center">
                                                            <input
                                                                type="radio"
                                                                class="form16_emailselected_radio"
                                                                checked={String(i) === String(dscIndex)}
                                                                onChange={() => setDscIndex(i)}
                                                            />
                                                        </td>
                                                        <td class="form16_signing_overflowtext text-left">
                                                            <span data-toggle="tooltip" data-placement="top"
                                                                title={item?.name ?? ""}>{item?.name ?? ""}</span>
                                                        </td>
                                                        <td class="text-center">
                                                            {item?.expiration ? moment(item.expiration).format("DD/MM/YYYY") : ""}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="col-md-12 pt-2 text-center">
                                            <button className={`btn btn-default certificates_okaybtnbgclr mr-2 ${dscIndex === null ? "disabled" : ""}`} data-dismiss="modal" onClick={() => {

                                                if (typeof dscIndex === 'number') {
                                                    setCurrentStep(5);
                                                    setDscertificatePopup(false);
                                                } 

                                            }}>Okay</button>
                                            <button className="btn btn-default certificates_calcelbtnbgclr" data-dismiss="modal" onClick={() => {
                                                setDscertificatePopup(false);
                                                setReSign(false); setDscCertificates(() => []);
                                            }}>Cancel</button>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <p className="certificates_textfs pt-3">No token found. Please insert a valid DSC Token in the system and retry.
                                        </p>
                                        <button className="btn btn-default certificates_okaybtnbgclr" onClick={() => {
                                            setDscertificatePopup(false);
                                            setReSign(false);
                                        }}>Okay</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DSCertificatePopup