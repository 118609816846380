/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './RaiseRequestPopup.css';

import AddRaiseRequestFormPopup from './AddRaiseRequestFormPopup';
import { getPreRaiseRequestData } from '../../api/returnAPI';

const RaiseRequestPopup = (props) => {
  const {
    show, handleClose, deductorId, pan, credetialMessage,captchaRef,port,setSuccess,preDefineRaiseData=null ,handleCallOnSuccess= null, checkJustification, checkConso
  } = props;
  const [importFUVFileValues, setImportFUVFileValues] = useState({});
  const [, setShowImportFUVFile] = useState(false);
  const [importFUVFile] = useState(false);
  const [submitBtnProceed] = useState(false);
  const [inputVal, setInputVal] = useState(false);

  const [updateRaiseReq, setUpdateRaiseReq] = useState(false);
  const handleProceedImportFUVFile2 = (result) => {
    setImportFUVFileValues(result);
    setInputVal(true);
  };

  const fetchPreRaiseRequestData = async (paramsVal) => {
    try {
      const result = await getPreRaiseRequestData(`?deductor_id=${deductorId}${paramsVal}`);
      if (result.status === 1) {
        if(result?.data?.challan_details){
          if(result?.data?.challan_details?.bsr_code === null){
            result.data.challan_details.bsr_code = "";
          }
          if(result?.data?.challan_details?.challan_no === null){
            result.data.challan_details.challan_no = "";
          }          
        }        
        setImportFUVFileValues(result);
        setInputVal(true);
      }
    } catch (err) {
      console.error('Error: ', err);
    }
  };

  const setInputValFunc = async () => {
    setInputVal(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="raisenewreqpopup"
      >
        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title>Submit Request</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="container">
            <AddRaiseRequestFormPopup
              onClose={handleClose}
              deductorId={deductorId}
              setShowImportFUVFile={setShowImportFUVFile}
              importFUVFileValues={importFUVFileValues}
              importFUVFile={importFUVFile}
              submitBtnProceed={submitBtnProceed}
              deductorPan={pan}
              updateRaiseReq={updateRaiseReq}
              handleProceedImportFUVFile2={handleProceedImportFUVFile2}
              fetchPreRaiseRequestData={fetchPreRaiseRequestData}
              setInputValFunc={setInputValFunc}
              inputVal={inputVal}
              captchaRef={captchaRef}
              port={port}
              setSuccess={setSuccess}
              preDefineRaiseData={preDefineRaiseData}
              handleCallOnSuccess={handleCallOnSuccess}
              checkJustification={checkJustification}
              checkConso = {checkConso}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>

  );
};

export default RaiseRequestPopup;
