/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

// -- Validation Rules
export const challanFormValidationSchema = Yup.object().shape({
  challan_no: Yup.string()
    .when('challanDeposited', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .matches(/[0-9]$/, 'Invalid Challan Number'),
    }),

  bsr_code: Yup.string()
    .when('challanDeposited', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .matches(/[0-9]{7}$/, 'Invalid BSR Code'),
    }),

  nature_of_payment_id: Yup.number()
  .when('challanDeposited', {
    is: false,
    then: Yup.number().required('This field is required'),
  }),

   assessment_year: Yup.string()
   .when('challanDeposited', {
     is: false,
     then: Yup.string().required('This field is required'),
   }),

  //  cheque_dd_no: Yup.string()
  //  .when('challanDeposited', {
  //    is: true,
  //    then: Yup.string().required('Cheque No is required'),
  //  }),

  deposit_date: Yup.string()
    .when('challanDeposited', {
      is: true,
      then: Yup.string().required('This field is required'),
    }),

  deductee_status: Yup.string()
    .when('challanDeposited', {
      is: false,
      then: Yup.string().required('This field is required'),
    }),

  mode_of_payment: Yup.string()
    .when('challanDeposited', {
      is: false,
      then: Yup.string().required('This field is required'),
    }),


  bank_name: Yup.string()
  .when('challanDeposited', {
    is: false,
    then: Yup.string().when('mode_of_payment', {
      is: (mode_of_payment) => {
          if(mode_of_payment === 'NET_BANKING' || 
            mode_of_payment === 'DEBIT_CARD' || 
            mode_of_payment === 'PAYMENT_GATEWAY'
          ) {
            return true;
          }         
        },
      then: Yup.string().required('This field is required')
    })
  }),   

  mode_of_payment_offline: Yup.string()
  .when('challanDeposited', {
    is: false,
    then: Yup.string().when('mode_of_payment', {
      is: 'PAY_AT_BANK_COUNTER',
      then: Yup.string().required('This field is required')
    })
  }),

  // cheque_no: Yup.string()
  //   .when('challanDeposited', {
  //     is: false,
  //       then: Yup.string().when('mode_of_payment_offline', {
  //         is: 'CHEQUE',
  //         then: Yup.string().trim().required('Cheque No is required.'),
  //       }),
  //     }),
    

  cheque_deposit_date: Yup.string()
    .when('challanDeposited', {
      is: true,
      then: Yup.string().trim().required('This field is required'),
      }),

  // date_as_on_cheque: Yup.string()
  //   .when('challanDeposited', {
  //     is: false,
  //     then: Yup.string().when('mode_of_payment_offline', {
  //       is: 'CHEQUE',
  //       then: Yup.string().trim().required('Date as on cheque is required.'),
  //       }),
  //     }),

  tds_amtp: Yup.number().required('This field is required'),

  surcharge_amtp: Yup.number().required('This field is required'),

  edu_cess_amtp: Yup.number().required('This field is required'),

  interest_amtp: Yup.number().required('This field is required'),

  fees_amtp: Yup.number().required('This field is required'),

  other_amtp: Yup.number().required('This field is required'),

  type_of_payment: Yup.string().required('This field is required'),
});
