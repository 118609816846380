import React, { useState, useEffect, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import ConnectionConnectorImg from '../../images/connector_img.gif';
//mport ConnectionSuccessImg from '../../images/connector-success-icon.gif';
import ConnectionCloseImg from '../../images/connector-close-icon.gif';
import ConnectionDownloadImg from '../../images/connector-download-icon.gif';
import { CONNECTOR_DOWNLOAD_LINK, CONNECTOR_FAQ_LINK } from '../../app/constants';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRunningConnectorUsability } from '../../utils/UtilityFunctions';
import './KDKConnector.css';

export default function ConnectorAdapter(props) {  

  const { show, handleClose, setPort, handleProceed, title = null, section='TRACES' } = props; 

  // Following statuses (scenarios) are possible
  // 0 - Connection Establishment in Progress
  // 1 - Connection success and Connector is up to date. All good
  // 2 - Connector is not running or not installed yet. Need to install or Start.
  // 3 - Connector is installed, but stale version. Need fresh install.
  // 4 - Connector is installed, has old version and can be updated. However, work can still go on.
  // First time, connection establishment in Progress, so we will default to 0.
  const [connectorStatus, setConnectorStatus] = useState(0);
  const timer = useRef(null);

  useEffect(() => {  

    // Run a continuous loop every 2 seconds
    const runConnectorWhenFound = async () => {

      // Keep finding running Connector details - until we are in success or updatable scenario
      const runningConnectorResult = await getRunningConnectorUsability();
      let connectorRunning = false;
      if (runningConnectorResult.port === 0) {
        // Connector is not running or not installed yet. Need to install or Start.
        setConnectorStatus(2);
        connectorRunning = false;
      } else if (runningConnectorResult.install === 1) {
        // Connector is installed, but stale version. Need fresh install.
        setConnectorStatus(3);
        connectorRunning = false;
      } else if (runningConnectorResult.update === 1) {
        // Connector is installed, has old version and can be updated. However, work can still go on.
        setConnectorStatus(4);
        connectorRunning = true;
      } else {
        // Connection success and Connector is up to date. All good
        setConnectorStatus(1);
        connectorRunning = true;
      }

      if (connectorRunning === true) {
        setPort(runningConnectorResult.port);
        handleProceed(runningConnectorResult.port);
        handleClose();
        
        return;
      }

      // Connector Still not Running. Run again after 2 seconds
      timer.current = setTimeout(() => runConnectorWhenFound(), 2000);
    }

    runConnectorWhenFound();

    // Clear timeout on unmount
    return () => clearTimeout(timer.current);

    //eslint-disable-next-line
  }, []);  


  return (
    <div>

      <Modal
        className="modal cashcreditledgerpoup connectoraddapter"
        show={show}
        onHide={handleClose}
      >

        <Modal.Header closeButton  className={`modal-header cashhead`}>
          <div className="modal-title cashledgertext">
            { title !== null ? title : 'Login at Govt. Portal' }
          </div>
        </Modal.Header>

        <Modal.Body className="px-2">

          <div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 pt-3 text-center">
                  <div className="connector-imgbox">
                    <img src={ConnectionConnectorImg} alt='' width="50%" />
                  </div>
                </div>
              </div>

            </div>

            {/* Scenario 0: Connection Establishment in Progress. */}
            {connectorStatus === 0 &&
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>
                    <span>Trying to establish connection with Express Connector.</span>
                  </p>
                </div>
              </div>
            }

            {/* Scenario 2 OR 3: Connector Not Running (no port found) OR Connector Requires Installation (stale version) */}
            {(connectorStatus === 2 || connectorStatus === 3) &&
              <div className="row pt-2">
                <div className="col-md-12 text-center">
                  <p>
                    <span>
                      <img src={ConnectionCloseImg} alt='' width="4%" />
                    </span>
                    <span className="connector-trying-textfsbgclr">{connectorStatus === 2 ? "Connection with Express Connector could not be established." : "Connection with Express Connector established, but the installed version is out of date."}</span>
                  </p>
                </div>

                <div className="col-md-12 text-center">
                  <h4 className="infoicon-left pt-3">
                    <span className="connector-infoicon-clr">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <span className="connector-mandatory-textfs">It is required to {connectorStatus === 2 ? "install" : "update"} the Express Connector for Login at {section === 'EFILING' ? "Income Tax Efiling" : "Govt."} Portal.</span>
                  </h4>
                </div>

                <div className="col-md-5 text-right">
                  <a href={CONNECTOR_DOWNLOAD_LINK}>
                    <button className="btn btn-default download-btnbgclr">
                      <span className="download-right-iconfs">
                        <img src={ConnectionDownloadImg} alt='' width="25%" />
                      </span>
                      <br />Click here to Download
                    </button>
                  </a>
                </div>
                <div className="col-md-6 pt-2">
                  <div className="leftside-border">
                    <p className="down-alraedytextfs">Please download the Installer and install the Express Connector. If it is already installed, please ensure that the Connector is running.</p>
                    {CONNECTOR_FAQ_LINK
                      ? (<p className="down-alraedytextfs">Need more help ? <a href={CONNECTOR_FAQ_LINK} className="clickhererefer-fs">Click here to refer installation guide </a></p>)
                      : (<p className="down-alraedytextfs">Need more help ? Kindly contact our Support team, by using the Call Me option.</p>)
                    }
                  </div>
                </div>
              </div>
            }

            {/* Scenario 1: Connection success and Connector is up to date. All good */}
            {/* {(connectorStatus === 1 || connectorStatus === 4) &&

              <div className="row">
                <div className="col-md-12 text-center">
                  <p className="mb-1">
                    <span>
                      <img src={ConnectionSuccessImg} alt='' width="4%" />
                    </span>
                    <span className="connector-successtextfs-fs">
                      Connection with Express Connector is established successfully!
                      <br />
                      Please visit another browser window, and enter Captcha to Login at TRACES Portal. You may close this dialog.
                    </span>
                  </p>
                </div>
              </div>
            } */}

            {connectorStatus === 4 &&
              <>
                <hr />
                <div className="row pt-2">
                  <div className="col-md-12 text-center">
                    <p>
                    <span className="connector-infoicon-clr">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                      <span className="connector-trying-textfsbgclr">The installed version of Express Connector is out of date. You may update the same.</span>
                    </p>
                  </div>

                  <div className="col-md-5 text-right">
                    <a href={CONNECTOR_DOWNLOAD_LINK}>
                      <button className="btn btn-default download-btnbgclr">
                        <span className="download-right-iconfs">
                          <img src={ConnectionDownloadImg} alt='' width="25%" />
                        </span>
                        <br />Click here to Download
                      </button>
                    </a>
                  </div>
                  <div className="col-md-6 pt-2">
                    <div className="leftside-border">
                      <p className="down-alraedytextfs">Please download the Installer and install the Express Connector. If it is already installed, please ensure that the Connector is running.</p>
                      {CONNECTOR_FAQ_LINK
                        ? (<p className="down-alraedytextfs">Need more help ? <a href={CONNECTOR_FAQ_LINK} className="clickhererefer-fs">Click here to refer installation guide </a></p>)
                        : (<p className="down-alraedytextfs">Need more help ? Kindly contact our Support team, by using the Call Me option.</p>)
                      }
                    </div>
                  </div>
                </div>
              </>
            }

          </div>

        </Modal.Body>

      </Modal>

    </div>
  )
}
