import * as React from 'react';
//import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";
import $ from 'jquery';
import './CustomFilterUI.css';

export class CustomFilterUI extends React.Component {

    state = {
        filterList: this.props.filterList ? this.props.filterList : [],
    };

    componentDidMount = () => {
        if (this.props.secondFilterProps && !this.props.secondFilterProps.value && !this.props.hideSecondFilter) {
            this.props.secondFilterProps.onChange({
                value: this.props.secondFilterProps.value,
                operator: this.props.secondFilterProps.operators[1].operator
            });
        }

        $(document).on('keydown', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                $("form.k-filter-menu button.k-primary").click();
            }
        });

    }

    handleFirstValueChange = (event) => {
        let value = event.value.value ? event.value.value : event.value;
        if (Array.isArray(event.value)) {
            value = [];
            event.value.map((event_val, index) => {
                value.push(event_val.value);
                return true;
            })
            value = value.length > 0 ? value : '';
        }

        const { firstFilterProps } = this.props;
        firstFilterProps.onChange({
            value,
            operator: firstFilterProps.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleSecondValueChange = (event) => {
        const value = event.value;
        const { secondFilterProps } = this.props;

        secondFilterProps.onChange({
            value,
            operator: secondFilterProps.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleFirstOperatorChange = (event) => {
        const value = event.value;
        const { firstFilterProps } = this.props;
        firstFilterProps.onChange({
            value: value.operator === 'isempty' ? ' ' : firstFilterProps.value,
            operator: value.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleSecondOperatorChange = (event) => {
        const value = event.value
        const { secondFilterProps } = this.props;
        secondFilterProps.onChange({
            value: secondFilterProps.value,
            operator: value.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    filterChange = (event) => {
        this.setState({
            filterList: filterBy(this.props.filterList.slice(), event.filter),
        });
    };

    itemRender = (li, itemProps) => {
        const itemChildren = (
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'revert' }} title={itemProps.dataItem.label}>
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    render() {

        let { column, firstFilterProps, secondFilterProps, hideSecondFilter, logicData, logicValue, onLogicChange } = this.props;
        firstFilterProps.operators.map((operator, index) => {
            if (operator.operator === 'in') { firstFilterProps.operators[index].text = 'In'; }
            if (operator.operator === 'isempty') { firstFilterProps.operators[index].text = 'Blank'; }
            return true;
        });

        secondFilterProps.operators.map((operator, index) => {
            if (operator.operator === 'in') { secondFilterProps.operators[index].text = 'In'; }
            if (operator.operator === 'isempty') { secondFilterProps.operators[index].text = 'Blank'; }
            return true;
        });

        let firstOperator = firstFilterProps.operators.filter((operator) => { return operator.operator === firstFilterProps.operator });
        let secondOperator = secondFilterProps.operators.filter((operator) => { return operator.operator === secondFilterProps.operator });

        let firstDropdownProps = [];
        if (column && column.filter === 'dropdown' && firstFilterProps.value) {
            firstDropdownProps = this.state.filterList.filter((data, index) => { return firstFilterProps.value.includes(data.value); });
        }

        return (
            <div className="filter_ui">
                <DropDownList
                    data={firstFilterProps.operators}
                    textField="text"
                    dataItemKey="operator"
                    value={firstOperator[0]}
                    className="filter_ui_select"
                    popupSettings={{ className: 'filter_ui_option' }}
                    onChange={this.handleFirstOperatorChange}
                />

                {column.filter && this.props.column.filter === 'numeric' ?
                    <Input type="number" value={firstFilterProps.value} onChange={this.handleFirstValueChange} />
                    : column.filter && this.props.column.filter === 'date' ?
                        <DatePicker format={'dd/MM/yyyy'} value={firstFilterProps.value} onChange={this.handleFirstValueChange} />
                        : column.filter && column.filter === 'dropdown' ?
                            <MultiSelect data={this.state.filterList} textField="label" value={firstDropdownProps} dataItemKey="value" onChange={this.handleFirstValueChange} filterable={true} className="filter_ui_select" popupSettings={{ className: 'filter_ui_option' }} onFilterChange={this.filterChange} itemRender={this.itemRender} />
                            : <Input value={firstFilterProps.value} onChange={this.handleFirstValueChange} />}

                {!hideSecondFilter ?
                    <>
                        <DropDownList
                            data={logicData}
                            textField="text"
                            dataItemKey="operator"
                            value={logicValue}
                            onChange={onLogicChange}
                            className="filter_ui_select"
                            popupSettings={{ className: 'filter_ui_option' }}
                            style={{ width: '70px' }}
                        />

                        <DropDownList
                            data={secondFilterProps.operators}
                            textField="text"
                            dataItemKey="operator"
                            value={secondOperator[0]}
                            className="filter_ui_select"
                            popupSettings={{ className: 'filter_ui_option' }}
                            onChange={this.handleSecondOperatorChange}
                        />

                        {column.filter && this.props.column.filter === 'numeric' ?
                            <Input type="number" value={secondFilterProps.value} onChange={this.handleSecondValueChange} />
                            : column.filter && this.props.column.filter === 'date' ?
                                <DatePicker format={'dd/MM/yyyy'} value={secondFilterProps.value} onChange={this.handleSecondValueChange} />
                                : <Input value={secondFilterProps.value} onChange={this.handleSecondValueChange} />}

                    </>
                    : null}
            </div>
        );

    }
}