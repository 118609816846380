import React from 'react'
import { FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';

const IncHouseProperty = (props) => {
    const { data, calcIcon, showCalcHousePrtyPopupFunc, setData, handleNumberInputOnBlur } = props;

    // simple obj update function 
    const handleInputChange = (name, value) => {
        data[name] = value;
        if(name === "self_occupied_amtp" && data?.self_occupied_breakup){
            data.self_occupied_breakup = null;
        }else if(name === "let_out_amtp" && data?.let_out_breakup){
            data.let_out_breakup = null;
        }
        setData({ ...data });
    }

    return (
        <>
            <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse collapsed" data-toggle="collapse" href="#salarytaxregime_edit_incomeforhouse_collap01" aria-expanded="false"></span>

                </th>
                <th width="550" className="text-left">
                    Income From House Property
                </th>
                <th width="140" className="text-right">
                    {FormatNumberDecimal(data.total_house_property_income_amtp ?? 0, ...Array(3))}
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_incomeforhouse_collap01" className="in collapse">

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>
                                    <tr className="">
                                        <th width="10" id="accordion" className="accordion">
                                        </th>
                                        <th width="150" className="text-left">
                                            Self Occupied
                                        </th>
                                        <th width="450">
                                            <img src={calcIcon} alt="calculator icon" width="16" className="cursorpointer pt-1 text-left"
                                                onClick={() => showCalcHousePrtyPopupFunc("SelfOccupiedPopup", data.self_occupied_breakup)} />
                                        </th>
                                        <th width="120" className="text-right">

                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                value={FormatNumberDecimal(data.self_occupied_amtp ?? "", ...Array(2), false)}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                onChange={(e) => {
                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                    handleInputChange("self_occupied_amtp", value)
                                                }}
                                                onBlur={(e) => handleNumberInputOnBlur("self_occupied_amtp", data.self_occupied_amtp)}
                                            />
                                        </th>

                                    </tr>


                                    <tr className="">
                                        <th width="10" id="accordion" className="accordion">
                                        </th>
                                        <th width="150" className="text-left">
                                            Let Out Property
                                        </th>
                                        <th width="450">
                                            <img src={calcIcon} alt="calculator icon" width="16" className="cursorpointer pt-1 text-left"
                                                onClick={() => showCalcHousePrtyPopupFunc("letOutPropertyPopup", data.let_out_breakup)} />
                                        </th>
                                        <th width="120" className="text-right">

                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox  text-right`}
                                                value={FormatNumberDecimal(data.let_out_amtp ?? "", ...Array(3))}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                onChange={(e) => {
                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(1), true, true, e);
                                                    handleInputChange("let_out_amtp", value)
                                                }}
                                                onBlur={(e) => handleNumberInputOnBlur("let_out_amtp", data.let_out_amtp)}
                                            />
                                        </th>

                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </td>
            </tr>

        </>
    )
}

export default IncHouseProperty