/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/*eslint-disable  no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import $ from 'jquery';
import moment from 'moment';
import './CreateRaiseRequestForm.css';
import DatePicker from '../datePicker/DatePicker';
import { raiseRequestFormValidationSchema } from './RaiseRequestFormSchema';
import ErrorFocus from '../ErrorFocus';
import { searchBSRCodes } from '../../api/challanAPI';

import NumericInput from '../NumericInput';
import PRNInput from '../PRNInput';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import ImportFUVFile from '../../features/returnRaise/importFUVFile/ImportFUVFile';
import ConnectorAdapter from '../kdkConnector/ConnectorAdapter';
import TracesConnector from '../kdkConnector/TracesConnector';

const CreateRaiseRequestForm = (props) => {
  const {
    raiseRequestFormInfo,
    createRaiseRequest,
    submitBtnProceed,
    submitUnsetBtnProceed,
    setInputValFunc,
    inputVal,
    importFUVFileValues,
    regularReturnAvailble,
    setRaiseRequestFormType,
    deductorId,
    returnId
  } = props;
  const [bsrCodes, setBSRCodes] = useState((localStorage.getItem('bsrCodes') ? JSON.parse(localStorage.getItem('bsrCodes')) : {}));
  const [part1Disabled, setPart1Disabled] = useState(false);
  const [part2Disabled, setPart2Disabled] = useState(false);
  const [part3Disabled, setPart3Disabled] = useState(false);
  const [part4Disabled, setPart4Disabled] = useState(false);
  const [part5Disabled, setPart5Disabled] = useState(false);
  const [raiseRequestFormTypeVal, setRaiseRequestFormTypeVal] = useState('fuv_import_form');
  const [importFUVFile, setImportFUVFile] = useState(false);
  const [showImportFUVFile, setShowImportFUVFile] = useState(false);
  const [credentialAvailable, setCredentialAvailable] = useState(false)
  const [isConnectorRunning, setIsConnectorRunning] = useState(false);
  const [port, setPort] = useState(0);
  const [verifyTraceCredentials, setVerifyTraceCredentials] = useState(false);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [jsonData, setJsonData] = useState({});



  //create new raise request
  const submitProceedBtn1 = async (values) => {
    if (credentialAvailable) {
      createRaiseRequest(values, captchaRef, port)
      return null;
    }
    setIsConnectorRunning(true);
  };



  useEffect(() => {
    if (submitBtnProceed) {
      submitUnsetBtnProceed();
      const link = document.getElementById('submitCRaiseReqForm');
      link.click();
    }
  }, [submitBtnProceed]);

  const handleBSRCodeChange = (BSRCode) => {
    async function fetchBSRCodes() {
      try {
        const result = await searchBSRCodes(BSRCode);
        const bsrCodeData = {};
        result.data.map((bsrCode) => {
          bsrCodeData[bsrCode.bsr_code] = bsrCode;
          return true;
        });
        setBSRCodes(bsrCodeData);
        localStorage.setItem('bsrCodes', JSON.stringify(bsrCodeData));
      } catch (err) {
        console.error('Error in fetching bsr codes ', err);
      }
    }
    if (BSRCode.length !== 0 && BSRCode.length % 2 === 0) {
      fetchBSRCodes();
    }
  };

  const disabledPart1 = (setFieldValue) => {
    $('#book_adjustment_container').find('input').removeAttr('disabled', 'disabled');
    $('#challan_info_container,#nil_challan_container,#nil2_pan_container').find('input').attr('disabled', 'disabled');
    setFieldValue('bsr_code', '', false);
    setFieldValue('deposit_date', '', false);
    setFieldValue('challan_no', '', false);
    setFieldValue('challan_amount', 0, false);
    setFieldValue('nil_challan', '0', false);
    setPart1Disabled(true);
    setPart3Disabled(true);
    setPart2Disabled(false);
    setFieldValue('form1input', '0', false);
    setFieldValue('form2input', '1', false);
    setFieldValue('form3input', '0', false);
    $('#datepickerDiv').find('input').val('');
  };

  const disabledPart2 = (setFieldValue) => {
    $('#challan_info_container').find('input').removeAttr('disabled', 'disabled');
    $('#book_adjustment_container,#nil_challan_container,#nil2_pan_container').find('input').attr('disabled', 'disabled');
    setFieldValue('bsr_code2', '', false);
    setFieldValue('deposit_date2', '', false);
    setFieldValue('government_deductor_ddo_number', '', false);
    setFieldValue('transfer_voucher_amount', 0, false);
    setFieldValue('nil_challan', '0', false);
    setPart2Disabled(true);
    setPart3Disabled(true);
    setPart1Disabled(false);
    setFieldValue('form1input', '1', false);
    setFieldValue('form2input', '0', false);
    setFieldValue('form3input', '0', false);
  };

  const disabledAllPart = (setFieldValue) => {
    $('#book_adjustment_container,#challan_info_container,#deductee_container').find('input').attr('disabled', 'disabled');
    $('#nil_challan_container,#nil2_pan_container').find('input').removeAttr('disabled', 'disabled');
    setFieldValue('bsr_code', '', false);
    setFieldValue('deposit_date', '', false);
    setFieldValue('challan_no', '', false);
    setFieldValue('challan_amount', 0, false);

    setFieldValue('bsr_code2', '', false);
    setFieldValue('deposit_date2', '', false);
    setFieldValue('government_deductor_ddo_number', '', false);
    setFieldValue('transfer_voucher_amount', 0, false);
    setPart2Disabled(true);
    setPart1Disabled(true);
    setPart3Disabled(false);
    setFieldValue('form1input', '0', false);
    setFieldValue('form2input', '0', false);
    setFieldValue('form3input', '1', false);
    $('#datepickerDiv').find('input').val('');
  };

  const disabled4Part = (setFieldValue) => {
    $('#deductee_container').find('input').attr('disabled', 'disabled');
    $('#nil2_pan_container').find('input').removeAttr('disabled', 'disabled');
    setFieldValue('pan_no[0]', '', false);
    setFieldValue('pan_no[1]', '', false);
    setFieldValue('pan_no[2]', '', false);
    setFieldValue('tax_deducted[0]', '', false);
    setFieldValue('tax_deducted[1]', '', false);
    setFieldValue('tax_deducted[2]', '', false);
    setPart4Disabled(true);
    setPart5Disabled(false);
    setFieldValue('form5input', '1', false);
    setFieldValue('form4input', '0', false);
  };

  const disabled5Part = (setFieldValue) => {
    $('#nil2_pan_container').find('input').attr('disabled', 'disabled');
    $('#deductee_container').find('input').removeAttr('disabled', 'disabled');
    setFieldValue('nil_transaction', '0', false);
    setPart4Disabled(false);
    setPart5Disabled(true);
    setFieldValue('form5input', '0', false);
    setFieldValue('form4input', '1', false);
  };

  const setFiledForVal = (setFieldValue, values) => {
    if (inputVal) {
      if (values.deposit_date !== raiseRequestFormInfo.deposit_date) {
        setFieldValue('deposit_date', raiseRequestFormInfo.deposit_date, false);
        $('#datepickerDiv').find('input').val(raiseRequestFormInfo.deposit_date);
      } else if ($('#datepickerDiv').find('input').val() === '' && raiseRequestFormInfo.deposit_date !== '') {
        $('#datepickerDiv').find('input').val(raiseRequestFormInfo.deposit_date);
      }
      setInputValFunc();
    }

  };

  const restoreSystemDataOnBtnClick = (setFieldValue) => {
    if (Object.keys(importFUVFileValues).length > 0) {
      if (importFUVFileValues.data && importFUVFileValues.data.prn_no) {
        setFieldValue("token", importFUVFileValues.data.prn_no);
      }
      if (importFUVFileValues.data && importFUVFileValues.data.challan_details) {
        if (importFUVFileValues.data.challan_details && importFUVFileValues.data.challan_details.challan_no) {
          setFieldValue("challan_no", importFUVFileValues.data.challan_details.challan_no);
        }
        if (importFUVFileValues.data.challan_details && importFUVFileValues.data.challan_details.bsr_code) {
          setFieldValue("bsr_code", importFUVFileValues.data.challan_details.bsr_code);
        }
        if (importFUVFileValues.data.challan_details && importFUVFileValues.data.challan_details.deposit_date) {
          setFieldValue("deposit_date", importFUVFileValues.data.challan_details.deposit_date);
          $('#datepickerDiv').find('input').val(importFUVFileValues.data.challan_details.deposit_date);
        }
        if (importFUVFileValues.data.challan_details && importFUVFileValues.data.challan_details.challan_amount) {
          setFieldValue("challan_amount", importFUVFileValues.data.challan_details.challan_amount);
        }
      }
    }
    if (importFUVFileValues.data && importFUVFileValues.data.deductee_transactions && importFUVFileValues.data.deductee_transactions.length > 0) {
      const panNoArray = [];
      const taxDeductedArray = [];
      importFUVFileValues.data.deductee_transactions.forEach((value, index) => {
        panNoArray[index] = value.pan_no;
        taxDeductedArray[index] = value.tax_deducted;
      });
      setFieldValue('pan_no[0]', panNoArray[0]);
      setFieldValue('pan_no[1]', panNoArray[1]);
      setFieldValue('pan_no[2]', panNoArray[2]);
      setFieldValue('tax_deducted[0]', taxDeductedArray[0]);
      setFieldValue('tax_deducted[1]', taxDeductedArray[1]);
      setFieldValue('tax_deducted[2]', taxDeductedArray[2]);
    }
  };

  useEffect(() => {
    setTimeout(function () {
      $('#datepickerDiv').find('input').val(raiseRequestFormInfo.deposit_date);
    }, 30);
  }, []);

  useEffect(() => {

    if (raiseRequestFormInfo.form1input === "1" && raiseRequestFormInfo.form2input === "0" && raiseRequestFormInfo.form3input === "0") {
      setPart1Disabled(false);
      setPart2Disabled(true);
      setPart3Disabled(true);
    }

    if (raiseRequestFormInfo.form2input === "1" && raiseRequestFormInfo.form1input === "0" && raiseRequestFormInfo.form3input === "0") {
      setPart1Disabled(true);
      setPart2Disabled(false);
      setPart3Disabled(true);
    }

    if (raiseRequestFormInfo.form3input === "1" && raiseRequestFormInfo.form1input === "0" && raiseRequestFormInfo.form2input === "0") {
      setPart1Disabled(true);
      setPart2Disabled(true);
      setPart3Disabled(false);
    }

    if (raiseRequestFormInfo.form4input === "1") {
      setPart4Disabled(true);
      setPart5Disabled(false);
    }

    if (raiseRequestFormInfo.form5input === "1") {
      setPart4Disabled(false);
      setPart5Disabled(true);
    }

  }, [raiseRequestFormInfo]);

  const autoFillTxtFileData = (res, setFieldValue) => {
    setShowImportFUVFile(false);

    if (res.data && res.data.challan_details) {
      if (res.data.challan_details && res.data.challan_details.challan_no) {
        setFieldValue("challan_no", res.data.challan_details.challan_no);
      }
      if (res.data.challan_details && res.data.challan_details.bsr_code) {
        setFieldValue("bsr_code", res.data.challan_details.bsr_code);
        // raiseRequestFields.bsr_code2 = res.data.challan_details.bsr_code;
      }
      if (res.data.challan_details && res.data.challan_details.deposit_date) {
        setFieldValue("deposit_date", res.data.challan_details.deposit_date);
        $('#datepickerDiv').find('input').val(res.data.challan_details.deposit_date);
        // raiseRequestFields.deposit_date2 = res.data.challan_details.deposit_date;
      }
      if (res.data.challan_details && res.data.challan_details.challan_amount) {
        setFieldValue("challan_amount", res.data.challan_details.challan_amount);
      }
    }
    if (res.data && res.data.deductee_transactions && res.data.deductee_transactions.length > 0) {
      const panNoArray = [];
      const taxDeductedArray = [];
      res.data.deductee_transactions.forEach((value, index) => {
        panNoArray[index] = value.pan_no;
        taxDeductedArray[index] = value.tax_deducted;
      });
      setFieldValue('pan_no[0]', panNoArray[0]);
      setFieldValue('pan_no[1]', panNoArray[1]);
      setFieldValue('pan_no[2]', panNoArray[2]);
      setFieldValue('tax_deducted[0]', taxDeductedArray[0]);
      setFieldValue('tax_deducted[1]', taxDeductedArray[1]);
      setFieldValue('tax_deducted[2]', taxDeductedArray[2]);
    }
    setRaiseRequestFormTypeVal("fuv_import_form")
    const challanInfoContainer = document.getElementById('challan_info_container');
    const deducteeContainer = document.getElementById('deductee_container');
    challanInfoContainer.click();
    deducteeContainer.click();
    const link = document.getElementById('submitCRaiseReqForm');
    link.click();

  }


  return (
    <>

      {isConnectorRunning
        ? <ConnectorAdapter
          show={isConnectorRunning}
          handleClose={() => {
            setIsConnectorRunning(false);
          }}
          setPort={setPort}
          handleProceed={() => {
            setVerifyTraceCredentials(true);
          }}
        />
        : null
      }
      {verifyTraceCredentials && port
        ?
        <>
          <TracesConnector
            deductorId={deductorId}
            returnId={returnId}
            handleProceed={() => {
              setIsConnectorRunning(false);
              setVerifyTraceCredentials(false);
            }}
            port={port}
            setVerifyCreds={() => {
              setCredentialAvailable(true)
              createRaiseRequest(jsonData, captchaRef, port)

            }}
            creds={null}
            setCaptchaRef={setCaptchaRef}
            callCaptchaRef={true}
          />
        </>
        : null
      }



      <Formik
        enableReinitialize
        initialValues={raiseRequestFormInfo}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={raiseRequestFormValidationSchema}
        onSubmit={(values) => {
          // same shape as initial values
          // createDeducteeTransactions(values);
        }}
      >
        {({
          errors,
          values,
          validateForm,
          setErrors,
          resetForm,
          setFieldValue,
        }) => {
          const submitForm = () => {
            validateForm().then(async (data) => {
              if (Object.keys(data).length > 0) {
                const AllPanNo = [];
                const AllTaxDeducted = [];
                let AllErrors = {};
                if (values.nil_transaction === '0') {
                  const nPattern = new RegExp(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/);

                  if ($.trim(values.pan_no['0']) === '' && $.trim(values.pan_no['1']) === '' && $.trim(values.pan_no['2']) === '') {
                    AllPanNo['0'] = 'PAN Number is required';
                  }
                  
                  if ($.trim(values.pan_no['0']) !== '' && !nPattern.test(values.pan_no['0'])) {
                    AllPanNo['0'] = 'Invalid PAN Number';
                  }

                  if ($.trim(values.pan_no['1']) !== '' && !nPattern.test(values.pan_no['1'])) {
                    AllPanNo['1'] = 'Invalid PAN Number';
                  }

                  if ($.trim(values.pan_no['2']) !== '' && !nPattern.test(values.pan_no['2'])) {
                    AllPanNo['2'] = 'Invalid PAN Numbersss';
                  }
                  if ($.trim(values.pan_no['0']) !== '' && $.trim(values.tax_deducted['0']) === '') {
                    AllTaxDeducted['0'] = 'Tax Deducted Amount is required';
                  }

                  if ($.trim(values.pan_no['1']) !== '' && $.trim(values.tax_deducted['1']) === '') {
                    AllTaxDeducted['1'] = 'Tax Deducted Amount is required';
                  }

                  if ($.trim(values.pan_no['2']) !== '' && $.trim(values.tax_deducted['2']) === '') {
                    AllTaxDeducted['2'] = 'Tax Deducted Amount is required';
                  }

                }
                AllErrors = { ...data, tax_deducted: AllTaxDeducted, pan_no: AllPanNo };
                setErrors(AllErrors);
              } else {
                const AllPanNo = [];
                const AllTaxDeducted = [];
                let AllErrors = {};
                let errorValidReturn = 0;
                if (values.nil_transaction === '0') {
                  const nPattern = new RegExp(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/);

                  if ($.trim(values.pan_no['0']) === '' && $.trim(values.pan_no['1']) === '' && $.trim(values.pan_no['2']) === '') {
                    AllPanNo['0'] = 'PAN Number is required';
                    errorValidReturn += 1;
                  }
                 
                  if ($.trim(values.pan_no['0']) !== '' && !nPattern.test(values.pan_no['0'])) {
                    AllPanNo['0'] = 'Invalid PAN Number';
                    errorValidReturn += 1;
                  }

                  if ($.trim(values.pan_no['1']) !== '' && !nPattern.test(values.pan_no['1'])) {
                    AllPanNo['1'] = 'Invalid PAN Number';
                    errorValidReturn += 1;
                  }

                  
                  if ($.trim(values.pan_no['2']) !== '' && !nPattern.test(values.pan_no['2'])) {
                    AllPanNo['2'] = 'Invalid PAN Numbersss';
                    errorValidReturn += 1;
                  }
                  if ($.trim(values.pan_no['0']) !== '' && $.trim(values.tax_deducted['0']) === '') {
                    AllTaxDeducted['0'] = 'Tax Deducted Amount is required';
                    errorValidReturn += 1;
                  }

                  if ($.trim(values.pan_no['1']) !== '' && $.trim(values.tax_deducted['1']) === '') {
                    AllTaxDeducted['1'] = 'Tax Deducted Amount is required';
                    errorValidReturn += 1;
                  }

                  if ($.trim(values.pan_no['2']) !== '' && $.trim(values.tax_deducted['2']) === '') {
                    AllTaxDeducted['2'] = 'Tax Deducted Amount is required';
                    errorValidReturn += 1;
                  }
                  if (errorValidReturn === 0) {
                    setJsonData(() => values)
                    submitProceedBtn1(values);
                  }
                } else {
                  setJsonData(() => values)
                  submitProceedBtn1(values);
                }

                AllErrors = { ...errors, tax_deducted: AllTaxDeducted, pan_no: AllPanNo };
                setErrors(AllErrors);
              }
            });
          };
          setFiledForVal(setFieldValue, values);
          return (
            <Form key={1} autoComplete="off">
              <ErrorFocus />
              {showImportFUVFile ? (
                <ImportFUVFile
                  show={showImportFUVFile}
                  handleClose={(_refresh) => {
                    setShowImportFUVFile(false);
               
                  }}
                  deductorId={props.deductorId}
                  returnId={props.returnId}
                  handleProceedImportFUVFile={(res) => { autoFillTxtFileData(res, setFieldValue) }}
                />
              ) : null}

              <div className="container">
                {/* <!--Raise a Request on TRACES-----------> */}
                <div className="row">
                  <div className="col-md-12 px-0">
                    <fieldset className="Fsetraisereqtraces">
                      <legend>
                        <div className="innerdevraisereqtraces">
                          <div className="leftsidfileraisereqtraces">
                            <h4>Raise a Request on TRACES
                            </h4>
                          </div>
                          <div className="innerhieghtdivraisereqtraces mt-1"></div>
                          {(raiseRequestFormTypeVal === 'fuv_import_form' && regularReturnAvailble === true) ? (
                            <span>
                              <div
                                className="rightsidedivraisereqtraces mr-1"
                                onClick={() => {
                                  setShowImportFUVFile(true);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <h4 className="">
                                  Instead, Import txt file
                                </h4>
                              </div>
                              <div className="rightsidedivraisereqtraces mr-1"
                                onClick={() => restoreSystemDataOnBtnClick(setFieldValue)}
                                style={{ cursor: 'pointer' }}
                              >
                                <h4 className="">
                                  Restore to System Data
                                </h4>
                              </div>
                            </span>
                          ) : null}
                          <div className="midltoborraisereqtraces"></div>
                        </div>
                      </legend>
                      {/* <!-- token no -----------> */}
                      {(regularReturnAvailble !== true) ? (
                        <div className="row pb-1">
                          <div className="col-md-12">
                            <div className="form-check-inline pr-3">
                              <label className="form-check-label" for="">
                                <span className="labeltextfs">Do you want to fill Challan and Deductee Details manually?</span>
                              </label>
                            </div>
                            
                            <div className="form-check-inline">
                              <label className="form-check-label" for="raiseRequestFormType2">
                                <input
                                  style={{ verticalAlign: 'middle' }}
                                  className="form-check-input"
                                  type="radio"
                                  name="raiseRequestFormType"
                                  id="raiseRequestFormType2"
                                  value="fuv_import_form"
                                  checked={raiseRequestFormTypeVal === 'fuv_import_form'}
                                  onChange={(_e) => {
                                    setRaiseRequestFormTypeVal('fuv_import_form');
                                    setImportFUVFile(true);
                                  }}
                                />
                                <span className="">Yes</span>
                              </label>
                            </div>

                            <div className="form-check-inline">
                              <label className="form-check-label" for="raiseRequestFormType">
                                <input
                                  style={{ verticalAlign: 'middle' }}
                                  className="form-check-input"
                                  type="radio"
                                  name="raiseRequestFormType"
                                  id="raiseRequestFormType"
                                  value="traces_form"
                                  checked={raiseRequestFormTypeVal === 'traces_form'}
                                  onChange={() => {
                                    setImportFUVFile(false);
                                    setRaiseRequestFormTypeVal('traces_form');
                                  }}
                                />
                                <span className="">No, I will use a txt file</span>
                              </label>
                            </div>
                            {raiseRequestFormTypeVal === 'traces_form' ? (
                              <div className="form-check-inline">
                                <label className="form-check-label" for="radio1">
                                  <button
                                    className="btn btn-default browsebtnTR"
                                    onClick={() => {
                                      setShowImportFUVFile(true);
                                    }}
                                  >Browse</button>
                                </label>
                              </div>
                            ) : null}
                            <div className="form-check-inline float-right">
                              <label className="form-check-label tokentextfs toppaddlable" for="">
                                <Field
                                  className="form-check-input checklebtopalignnill"
                                  type="checkbox"
                                  name="justfication_report"
                                  id="justfication_report"
                                  value="1"
                                  checked={values.justfication_report === true}
                                  onChange={(e) => {
                                    setFieldValue('justfication_report', !values.justfication_report);
                                    
                                  }}
                                  onClick={() => {
                                    disabledAllPart(setFieldValue);
                                  }}
                                  style={{ verticalAlign: 'sub' }}
                                />
                                Raise request for justification also
                              </label>
                            </div>
                          </div>


                        </div>
                      ) : null}
                      {raiseRequestFormTypeVal === 'fuv_import_form' ? (
                        <div className="row py-2">
                          <div className="col-md-12">
                            <form>
                              <div className="row">
                                <div className="col-md-6 pt-1">
                                  <span className="tokentextfs">
                                    Token Number/Provisional Receipt Number
                                    (PRN) of the return selected above
                                    <span className="red_text">*</span>
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <PRNInput
                                    type="text"
                                    className="form-control inputborborcle inputboxheight"
                                    placeholder="Enter Token No./PRN* Number"
                                    id="token"
                                    name="token"
                                    min="0"
                                    value={values.token}
                                    onChange={(e) => setFieldValue('token', e.target.value)}
                                  />

                                  {errors.token ? (
                                    <span className="error_message">
                                      {errors.token}
                                    </span>
                                  ) : null}
                                </div>

                              </div>
                            </form>


                          </div>
                        </div>
                      ) : null}
                      {raiseRequestFormTypeVal === 'fuv_import_form' ? (

                        <div>
                          {/* <!-- challan details -----------> */}
                          <div className="row py-2">
                            <div className="col-md-12">
                              <h4 className="challandetfs">Challan Details</h4>
                            </div>
                          </div>
                          <div className="row">
                            {/* <!-- card box 1 --> */}
                            <div className="col-md-4">
                              <div
                                className={part1Disabled ? 'opacityDiv' : ''}
                                onClick={() => {
                                  disabledPart2(setFieldValue);
                                }}
                                id="challan_info_container"
                              >
                                <div className="card cardborclr pb-2">
                                  <p className="optionprovidtextfs"><span className="optiontextblod">Option 1:</span> Provide any one challan information of the selected return</p>
                                  <div className="row pb-1">
                                    <div className="form-group col-md-12	 pr-3 pl-3 mb-0">
                                      <label for="">BSR Code</label>
                                      <NumericInput
                                        type="input"
                                        className="form-control"
                                        id="bsr_code"
                                        name="bsr_code"
                                        placeholder="Bsr Code"
                                        value={values.bsr_code}
                                        onChange={(e) => e.target.value.match(/^([0-9]{0,7})$/) && setFieldValue('bsr_code', e.target.value)}
                                        disabled={!!part1Disabled}
                                        onClick={() => {
                                          disabledPart2(setFieldValue);
                                        }}
                                      />
                                      {errors.bsr_code ? <span className="error_message">{errors.bsr_code}</span> : null}
                                      {bsrCodes[values.bsr_code] ? <span className="greentext" style={{ fontSize: '10px' }}>{bsrCodes[values.bsr_code].bank_name}</span> : null}
                                    </div>
                                    <div className="form-group col-md-12 pr-3 pl-3 mb-0">
                                     
                                      <div
                                        className="form-group tracesdateofdpadd"
                                        id="datepickerDiv"
                                        onClick={() => {
                                          disabledPart2(setFieldValue);
                                        }}
                                      >
                                        <label htmlFor="pwda">
                                          Date of Deposit
                                          <span className="red_text">*</span>
                                        </label>

                                        <DatePicker
                                          selectedDate={
                                            values.deposit_date
                                              ? moment(values.deposit_date).format('YYYY-MM-DD')
                                              : null
                                          }
                                          onChange={(date) => {
                                            setFieldValue('deposit_date', date);
                                          }}
                                        />
                                        {errors.deposit_date ? (
                                          <div className="error_message">
                                            {errors.deposit_date}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="form-group col-md-12	 pr-3 pl-3 mb-0">
                                      <label for="">Challan No.</label>
                                      <NumericInput
                                        type="input"
                                        className="form-control"
                                        id="challan_no"
                                        name="challan_no"
                                        placeholder="Challan No"
                                        value={values.challan_no}
                                        onChange={(e) => e.target.value.match(/^([0-9]{0,5})$/) && setFieldValue('challan_no', e.target.value)}
                                        disabled={!!part1Disabled}
                                        onClick={() => {
                                          disabledPart2(setFieldValue);
                                        }}
                                      />

                                      {errors.challan_no ? <span className="error_message">{errors.challan_no}</span> : null}
                                    </div>
                                    <div className="form-group col-md-12	 pr-3 pl-3 mb-0">
                                      <label for="">Challan Amt </label>
                                      <NumericInputWithDecimalTwo
                                        style={{ padding: '18px .75rem' }}
                                        type="input"
                                        className="form-control amount-box inputboxheight"
                                        id="challan_amount"
                                        name="challan_amount"
                                        min="0"
                                        value={values.challan_amount}
                                        onChange={(e) => e.target.value.match(/^([0-9.]{0,14})$/) && setFieldValue('challan_amount', e.target.value)}
                                        onClick={() => {
                                          disabledPart2(setFieldValue);
                                        }}
                                      />
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- end card box 1 -->
                                            <!-- card box 2 --> */}
                            <div className="col-md-4">
                              <div
                                className={part2Disabled ? 'opacityDiv' : ''}
                                onClick={() => {
                                  disabledPart1(setFieldValue);
                                }}
                                id="book_adjustment_container"
                              >
                                <div className="card cardborclr">
                                  <p className="optionprovidtextfs"><span className="optiontextblod">Option 2:</span> Provide one book adjustment information of the selected return</p>
                                  <div className="row pb-1">
                                    <div className="form-group col-md-12	 pr-3 pl-3 mb-0">
                                      <label for=""> Receipt Number</label>
                                      <NumericInput
                                        type="input"
                                        className="form-control"
                                        id="bsr_code2"
                                        name="bsr_code2"
                                        placeholder="Receipt Number"
                                        value={values.bsr_code2}
                                        onChange={(e) => e.target.value.match(/^([0-9]{0,7})$/) && setFieldValue('bsr_code2', e.target.value)}
                                        disabled={!!part1Disabled}
                                        onClick={() => {
                                          disabledPart1(setFieldValue);
                                        }}
                                      />
                                      {errors.bsr_code2 ? (
                                        <span className="error_message">
                                          {errors.bsr_code2}
                                        </span>
                                      ) : null}
                                      {bsrCodes[values.bsr_code2] ? <span className="greentext" style={{ fontSize: '10px' }}>{bsrCodes[values.bsr_code2].bank_name}</span> : null}
                                    </div>
                                    <div className="form-group col-md-12	 pr-3 pl-3 datecaliconzindex mb-0">
                                      
                                      <label>
                                        Date of Deposit
                                        <span className="red_text">*</span>
                                      </label>
                                      <DatePicker

                                        selectedDate={values.deposit_date2 ? moment(values.deposit_date2).format('YYYY-MM-DD') : null}
                                        onChange={(value) => setFieldValue('deposit_date2', value)}
                                        maxDate={new Date()}
                                        onClick={() => {
                                          disabledPart1(setFieldValue);
                                        }}

                                      />
                                      {errors.deposit_date2 ? (
                                        <div className="error_message">
                                          <br />
                                          {errors.deposit_date2}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="form-group col-md-12	 pr-3 pl-3 mb-0">
                                      <label for="">DDO Serial No.</label>

                                      <input
                                        className="form-control"
                                        type="text"
                                        name="government_deductor_ddo_number"
                                        id="government_deductor_ddo_number"
                                        placeholder="DDO Serial No"
                                        value={values.government_deductor_ddo_number}
                                        onChange={(e) => e.target.value.match(/^([0-9]{0,9})$/) && setFieldValue('government_deductor_ddo_number', e.target.value)}
                                        checked={(Number(values.government_deductor_ddo_number) === 0)}
                                        onClick={() => {
                                          setFieldValue('government_deductor_ddo_number', 0);
                                          disabledPart1(setFieldValue);
                                        }}
                                      />
                                      {errors.government_deductor_ddo_number ? (
                                        <span className="error_message">
                                          {errors.government_deductor_ddo_number}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group col-md-12	 pr-3 pl-3 mb-0">
                                      <label for="">Transfer Voucher Amt <span className="red_text">*</span></label>
                                      <NumericInput
                                        type="input"
                                        className="form-control"
                                        id="transfer_voucher_amount"
                                        name="transfer_voucher_amount"
                                        placeholder="Voucher Amount"
                                        value={values.transfer_voucher_amount}
                                        onChange={(e) => e.target.value.match(/^([0-9]{0,14})$/) && setFieldValue('transfer_voucher_amount', e.target.value)}
                                        onClick={() => {
                                          disabledPart1(setFieldValue);
                                        }}
                                      />
                                      {errors.transfer_voucher_amount ? (
                                        <span className="error_message">
                                          {errors.transfer_voucher_amount}
                                        </span>
                                      ) : null}

                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- end card box 2 -->
                                                      <!-- card box 3 --> */}
                            <div className="col-md-4">
                              <div className={part3Disabled ? 'opacityDiv' : ''} id="nil_challan_container"
                                onClick={() => {
                                  disabledAllPart(setFieldValue);
                                }}
                              >
                                <div className="card cardborclr">
                                  <p className="optionprovidtextfs"><span className="optiontextblod">Option 3:</span> Select NIL Challan in case, no challan exists, for the selected return</p>
                                  <div className="row pb-3">
                                    <div className="form-check-inline col-md-12 pl-3 pt-4">
                                      <label className="form-check-label" for="">
                                        <Field
                                          className="form-check-input checklebtopalignnill"
                                          type="checkbox"
                                          name="nil_challan"
                                          id="nil_challan"
                                          value="1"
                                          checked={values.nil_challan === '1'}
                                          onChange={(e) => {
                                            setFieldValue('nil_challan', e.target.value);
                                            
                                          }}
                                          onClick={() => {
                                            disabledAllPart(setFieldValue);
                                          }}
                                          style={{ verticalAlign: 'sub' }}
                                        />
                                        NIL Challan
                                      </label>
                                    </div>
                                    <div className="form-group col-md-12 py-3">
                                      <label for="" className="py-2"> </label>
                                    </div>
                                    <div className="form-group col-md-12 py-3">
                                      <label for="" className="py-2"> </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- end card box 3 --> */}
                          </div>
                          {/* <!-- end challan details -----------> */}

                          {/* <!-- Deductee Details -----------> */}
                          <div className="row py-3">
                            <div className="col-md-12">
                              <h4 className="challandetfs">Deductee Details</h4>
                            </div>
                          </div>
                          <div className="row">
                            {/* <!-- card box 1 --> */}
                            <div className="col-md-8">
                              <div
                                className={part4Disabled ? 'opacityDiv' : ''}
                                onClick={() => {
                                  disabled5Part(setFieldValue);
                                }}
                                id="deductee_container"
                              >
                                <div className="card cardborclr">
                                  <p className="optionprovidtextfs"><span className="optiontextblod">Option 1:</span> Provide any three Deductee Transaction's PAN and their Tax Deducted</p>
                                  <div className="row">

                                    <div className="form-group col-md-6 pr-3">
                                      <label for="">PAN</label>

                                      <Field
                                        style={{ padding: '18px .75rem' }}
                                        type="text"
                                        className="form-control inputboxheight"
                                        id="pan_no[0]"
                                        placeholder="Enter PAN"
                                        name="pan_no[0]"
                                        value={values.pan_no[0]}
                                        maxLength={10}
                                        onChange={(e) => {
                                          setFieldValue('pan_no[0]', e.target.value);
                                          disabled5Part(setFieldValue);
                                        }}
                                      />
                                      {errors && errors.pan_no && errors.pan_no[0] ? <span className="error_message">{errors.pan_no[0]}</span> : null}
                                    </div>
                                    <div className="form-group col-md-6 pr-3 pl-3">
                                      <label for="">Tax Deducted</label>
                                      <Field
                                        style={{ padding: '18px .75rem' }}
                                        type="text"
                                        className="form-control inputboxheight"
                                        placeholder="Enter Tax Deducted"
                                        id="tax_deducted[0]"
                                        name="tax_deducted[0]"
                                        value={values.tax_deducted[0]}
                                        onChange={(e) => {
                                          e.target.value.match(/^([0-9.]{0,14})$/) && (Number(e.target.value) || String(e.target.value).length === 0) && setFieldValue('tax_deducted[0]', e.target.value);
                                          disabled5Part(setFieldValue);
                                        }}
                                      />
                                      {errors && errors.tax_deducted && errors.tax_deducted[0] ? <span className="error_message">{errors.tax_deducted[0]}</span> : null}
                                    </div>

                                    <div className="form-group col-md-6 pr-3">
                                      <Field
                                        style={{ padding: '18px .75rem' }}
                                        type="text"
                                        className="form-control inputboxheight"
                                        id="pan_no[1]"
                                        placeholder="Enter PAN"
                                        name="pan_no[1]"
                                        value={values.pan_no[1]}
                                        maxLength={10}
                                        onChange={(e) => {
                                          setFieldValue('pan_no[1]', e.target.value);
                                          disabled5Part(setFieldValue);
                                        }}
                                      />
                                      {errors && errors.pan_no && errors.pan_no[1] ? <span className="error_message">{errors.pan_no[1]}</span> : null}
                                    </div>
                                    <div className="form-group col-md-6 pr-3 pl-3">
                                      <Field
                                        style={{ padding: '18px .75rem' }}
                                        type="text"
                                        className="form-control inputboxheight"
                                        placeholder="Enter Tax Deducted"
                                        id="tax_deducted[1]"
                                        name="tax_deducted[0]"
                                        value={values.tax_deducted[1]}
                                        onChange={(e) => {
                                          e.target.value.match(/^([0-9.]{0,14})$/) && (Number(e.target.value) || String(e.target.value).length === 0) && setFieldValue('tax_deducted[1]', e.target.value);
                                          disabled5Part(setFieldValue);
                                        }}
                                      />
                                      {errors && errors.tax_deducted && errors.tax_deducted[1] ? <span className="error_message">{errors.tax_deducted[1]}</span> : null}

                                    </div>

                                    <div className="form-group col-md-6 pr-3">
                                      <Field
                                        style={{ padding: '18px .75rem' }}
                                        type="text"
                                        className="form-control inputboxheight"
                                        id="pan_no[2]"
                                        placeholder="Enter PAN"
                                        name="pan_no[2]"
                                        value={values.pan_no[2]}
                                        maxLength={10}
                                        onChange={(e) => {
                                          setFieldValue('pan_no[2]', e.target.value);
                                          disabled5Part(setFieldValue);
                                        }}
                                      />
                                      {errors && errors.pan_no && errors.pan_no[2] ? <span className="error_message">{errors.pan_no[2]}</span> : null}
                                    </div>
                                    <div className="form-group col-md-6 pr-3 pl-3">
                                      <Field
                                        style={{ padding: '18px .75rem' }}
                                        type="text"
                                        className="form-control inputboxheight"
                                        placeholder="Enter Tax Deducted"
                                        id="tax_deducted[2]"
                                        name="tax_deducted[2]"
                                        value={values.tax_deducted[2]}
                                        onChange={(e) => {
                                          e.target.value.match(/^([0-9.]{0,14})$/) && (Number(e.target.value) || String(e.target.value).length === 0) && setFieldValue('tax_deducted[2]', e.target.value);
                                          disabled5Part(setFieldValue);
                                        }}
                                      />
                                      {errors && errors.tax_deducted && errors.tax_deducted[2] ? <span className="error_message">{errors.tax_deducted[2]}</span> : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- end card box 1 --> */}

                            {/* <!-- card box 2 --> */}
                            <div className="col-md-4">
                              <div
                                className={part5Disabled ? 'opacityDiv' : ''}
                                onClick={() => {
                                  disabled4Part(setFieldValue);
                                }}
                                id="nil2_pan_container"
                              >
                                <div className="card cardborclr">
                                  <p className="optionprovidtextfs"><span className="optiontextblod">Option 2:</span> Select No Valid PAN in case, no valid PAN exists, for the challan entered above for the selected return</p>
                                  <div className="row pb-3">
                                    <div className="form-check-inline col-md-12 pl-3 pt-0">
                                      <label className="form-check-label">
                                        <Field
                                          className="form-check-input checklebtopalignnill"
                                          type="checkbox"
                                          name="nil_transaction"
                                          id="nil_transaction"
                                          value="1"
                                          checked={values.nil_transaction === '1'}
                                          onChange={(e) => {
                                            setFieldValue('nil_transaction', e.target.value);
                                          }}
                                          onClick={() => {
                                            disabled4Part(setFieldValue);
                                          }}
                                          style={{ verticalAlign: 'middle' }}
                                        />
                                        NIL Transaction
                                      </label>
                                    </div>
                                    <div className="form-group col-md-12 py-3">
                                      <label for="" className="py-0"> </label>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- end card box 2 --> */}
                          </div>
                          {/* <!-- end Deductee Details ----------->
                                                                    <!--end token no -----------> */}
                        </div>
                      ) : null}
                    </fieldset>
                    {/* <!-- bottom submit btn ------> */}
                    {raiseRequestFormTypeVal === 'fuv_import_form' ? (
                      <div className="row py-3 mb-1">
                        <div className="col-md-12 text-right">
                          <span className="btn btn-default cancelbtnbgclrs mr-2" onClick={() => setRaiseRequestFormType('haveConsoFile')}>Cancel</span>
                          <span className="btn btn-primary submitbtnbgclrs" id="submitCRaiseReqForm" onClick={() => submitForm()}>
                            Submit
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {/* <!--end bottom submit btn ------> */}
                  </div>
                </div>
                {/* <!--end Raise a Request on TRACES-----------></div> */}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>

  );
};
export default CreateRaiseRequestForm;
