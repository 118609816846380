import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './UpdateTracesCredential.css';
//import Spinner from 'react-bootstrap/Spinner';
import ShowAlert from '../ShowAlert';
import { verifyInsightsLoginFromConnector } from '../../api/localConnectorAPI';
import { editDeductor, getDeductorById } from '../../api/deductorAPI';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../features/header/headerSlice';
import { getInsightsCredentialsForVerificationByConnector } from '../../api/deductorAPI';

const UpdateInsightsCredential = (props) => {
  const {
    show, handleClose, handleProceed, insightsPassword = '', insightsUser = '', deductorId, port, setInsightsCaptchaRef, setStartInsightProcess
  } = props;

  const headerDataDetails = useSelector(selectHeaderData);

  const [loading, setLoading] = useState(false);
  // const [cookies, setCookies] = useState(null);
  // const [captchaImg, setCaptchaImg] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  //const [captcha, setCaptcha] = useState('');

  const [showTracesPassword, setShowTracesPassword] = useState(false);
  /* end js traces cred.popup password section */

  const updateCredentials = () => {
    const request = {
      "insights_username": username,
      "insights_password": password,
      "deductor_id": deductorId,
      "deductor_name": headerDataDetails?.deductor_name ?? "",
      "deductor_status": 1,
      "deductor_credentials": [
        {
          "credential_type": "INSIGHT",
          "password": password,
          "username": username
        }
      ]
    }    
    async function fetchEverything() {
      try {
        await editDeductor(request);
      } catch (err) {
        console.error('error in updating deductor credentials: ', err);
      }
    }
    fetchEverything();
  };



  useEffect(() => {
    setUsername(insightsUser);
    setPassword(insightsPassword);
  }, [port, insightsUser, insightsPassword]);

  const verifyInsightsCredentials = () => {

    setError(null);
    async function fetchEverything() {
      try {

        //send Insights Credentials to server for encrption
        let extraHeader = {};
        const request = {
          'deductor_id': deductorId,
          'insights_username': username,
          'insights_password': password,
          'logout_immediately': 0
        };

        const serverApiResult = await getInsightsCredentialsForVerificationByConnector(request);
        const EncryptLoginData = serverApiResult.data
        const EncryptLoginDataHeader = serverApiResult.headers ?? null;


        const connectorRequest = {
          "data": EncryptLoginData?.data
        };

        extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = EncryptLoginDataHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

        try {

          const connectorResult = await verifyInsightsLoginFromConnector(port, connectorRequest, extraHeader);

          if (connectorResult.status === 1) {

            //Update INSIGHTS credentials in user profile
            updateCredentials();

            //console.log('After updating profile credntials, setting up insights captcha ref', connectorResult, connectorResult?.data?.captcha_ref); 
            //Set status to start next action
            setInsightsCaptchaRef(connectorResult?.data?.captcha_ref);

            handleProceed(connectorResult?.data?.captcha_ref);

            setStartInsightProcess(true);
            //handleClose();
          }

        } catch (err) {

          console.log('Connector Error', err);

          setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['insights.unknown_error']);

        }


      } catch (err) {

        console.error('error: ', err);
        setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message);

      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const getInsightsCredentials = async (deductorId) => {
    setLoading(true);

    try {
      const result = await getDeductorById(deductorId);
      if (result?.data?.deductor_credentials?.length > 0) {
        result?.data?.deductor_credentials.forEach((item) => {
          if (String(item?.credential_type ?? '').toUpperCase() === "INSIGHT") {
            setUsername(item?.username ?? "");
            setPassword(item.password ?? "");
          }
        })
      }

    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (show) {
      getInsightsCredentials(deductorId)
    }
  }, [deductorId, show]);



  const submitForm = (e) => {
    e.preventDefault();
    const fErrors = {};
    if (!username) fErrors['username'] = 'Username is required';
    if (!password) fErrors['password'] = 'Password is required';

    setFormErrors(fErrors);
    if (Object.keys(fErrors).length > 0) return;

    verifyInsightsCredentials();
  };

  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="tracescredentials"
      >
        {loading ? <LoadingIndicator /> : null}
        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title style={{ fontSize: "16px" }}>Insights Credentials</Modal.Title>
        </Modal.Header>

        <ShowAlert
          error={error}
          success={success}
          onClose={() => { setError(null); setSuccess(null); }}
        />

        <form onSubmit={submitForm}>
          <Modal.Body className="pb-0 px-0">

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <p className="mb-0 pb-0">Please provide Insights Credentials below </p>
                  <form className="form-horizontal">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="control-label" htmlFor="">UserName <span style={{ color: "red" }}>*</span></label>
                          <input
                            type="search"
                            className="form-control"
                            id="username"
                            name="username"
                            placeholder="Enter Username"
                            maxLength={10}
                            onChange={(e) => setUsername(e.target.value.toUpperCase())}
                            value={username}
                          />
                          {formErrors.username ? <span className="error_message">{formErrors.username}</span> : null}
                        </div>

                        {/*traces cred.popup password section */}


                        <div className="form-group">
                          <label htmlFor="password">
                            Password
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {showTracesPassword ? (
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="password"
                                placeholder="Enter Password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <div
                                className="input-group-prepend eyeipbox-create-deductor"
                                onClick={() => setShowTracesPassword(false)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </div>
                            </div>
                          ) : (
                            <div className="input-group mb-3">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Enter Password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                              />
                              <div
                                className="input-group-prepend eyeipbox-create-deductor"
                                onClick={() => setShowTracesPassword(true)}
                                style={{ cursor: 'pointer' }}
                              >
                              <FontAwesomeIcon icon={faEye} />
                            </div>
                            </div>
                          )}
                        {formErrors.password ? <span className="error_message">{formErrors.password}</span> : null}
                      </div>
                      {/* end traces cred.popup password section */}

                    </div>

                </div>

              </form>
              <div className="col-md-12 text-center mb-2">

                <Button type="submit" className="tracesbtnsubmit" onClick={submitForm} disabled={loading}> Submit  </Button>
              </div>
            </div>
          </div>
        </div>

      </Modal.Body>

    </form >
      </Modal >
    </>

  );
};
export default UpdateInsightsCredential;
