// import moment from 'moment';

export const raiseRequestFields = {
  raiseRequestFormType: 'traces_form',
  token: '',
  bsr_code: '',
  deposit_date: '',
  challan_no: '',
  challan_amount: 0,
  bsr_code2: '',
  deposit_date2: '',
  government_deductor_ddo_number: '',
  transfer_voucher_amount: 0,
  nil_challan: '0',
  pan_no: ['', '', ''],
  tax_deducted: ['', '', ''],
  nil_transaction: '0',
  form1input: '1',
  form2input: '1',
  form3input: '1',
  form4input: '1',
  form5input: '1',
  justfication_report: false,
};


export const raiseRequestResetFields = {
  deductor_id: '',
  request_for: [],
  token: '',
  challan_details: null,
  book_entry_details: null,
  nil_challan: null,
  captcha_ref: null,
  transaction_details: null,
  nil_transaction: null,
  return_id: null,
};
