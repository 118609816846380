import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { process } from "@progress/kendo-data-query";
import moment from 'moment';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import ShowAlert from '../../../components/ShowAlert';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { BASE_PATH, QUARTER_LIST } from '../../../app/constants';
import { downloadFile } from '../../../api/deductorAPI';
import NoticeSyncData from '../../../components/NoticeSyncData';
import TracesConnector from '../../../components/kdkConnector/TracesConnector';
import ConnectorAdapter from '../../../components/kdkConnector/ConnectorAdapter';
import { gocallApi } from '../../../api/issueCertificateAPI';
import SkeletonNoticeOrder from '../../../components/Skeleton/SkeletonNoticeOrder';
import dwnloadPdf from "../../../images/downloadpdf.png";
import { deepClone, getFinancialYears } from '../../../utils/UtilityFunctions';


const NoticeOrdersList = (props) => {

    const { deductor_Id, financialYear } = props;
    const FinancialYearList = getFinancialYears();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [deductorId, setDeductorId] = useState(false);
    const [tan, setTan] = useState(false);
    const [startConnector, setStartConnector] = useState(false);
    const [port, setPort] = useState(0);
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [captchaRef, setCaptchaRef] = useState(null);
    const [chartDataSkeleton, setChartDataSkeleton] = useState(true);
    const [cursor, setCursor] = useState({ current_page: 1, next_page: null });
    const [dataState, setDataState] = useState({});
    const [intialData, setIntialData] = useState([]);

    const skeletonColgroup = ["50", "90", "90", "130", "200", "130", "25"];
    const skeletonMainHead = [
        { colSpan: 1, },
        { colSpan: 1, },
        { colSpan: 1, },
        { colSpan: 1, },
        { colSpan: 1, },
        { colSpan: 1, },
        { colSpan: 1, },
    ];

 

    const noticeOrderExcelList = async (dataItem) => {
        setLoading(true);
        const urlPath = `/api/v1/notice-order/download-intimation?deductor_id=${dataItem?.deductor_id ?? ""}&ref_num=${dataItem?.ref_num ?? ""}&request_no=${dataItem?.request_no}`;

        try {
            await downloadFile(urlPath, true, `NoticeOrder_${dataItem?.ref_num ?? ""}.pdf`)
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);

        } finally {
            setLoading(false)
        }
    }

    const setSyncData = (data) => {
        setDeductorId(data?.deductor_id);
        setTan(data?.tan);
        setStartConnector(true);
    }

    const dataStateChange = (data_state) => {

        if (data_state.filter || (data_state.sort && data_state?.sort?.length > 0)) {
            const filter_state = deepClone(data_state);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                            if (["gte", "lte", 'lt', 'gt', "neq"].includes(itemObj?.operator)) {
                                if(itemObj.field !== "issued_at"){
                                    itemObj.value = Number(itemObj.value);
                                }
                            }
    
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                itemObj.value = itemObj.value?.[0] ?? "";
                            }
                            else if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                let array = []
                                item.logic = "or"
                                if (itemObj?.value?.length > 0) {
                                    itemObj?.value.forEach((keyValue) => {
                                        array.push({ field: itemObj.field, operator: 'eq', value: keyValue })
                                    })
                                    item.filters = array;
                                }
                            }
    
                        })
                    }
                })
            }
            const dataProcess = process(intialData, filter_state);
            setDataState({ ...data_state });
            setData(dataProcess.data ?? []);

        } else {
            const defaultData = JSON.parse(JSON.stringify(intialData));
            setData([...defaultData]);
            setDataState({});

        }

    }

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };


    const getNoticeOrderList = async (parameter) => {
        const { deductor_Id = null, cursor = {}, financialYear = null} = parameter;
        let url = `/api/v1/notice-order/list`;
        const filtersParam = [{
            "logic": "and", "filters": [
                { "field": "issued_at", "operator": "gte", "value": `${financialYear}-04-01` },
                { "field": "issued_at", "operator": "lte", "value": `${parseInt(financialYear) + 1}-03-31` }
            ]
        }];

        // if (dataState?.filter?.filters?.length > 0) {
        //     const filters = dataState?.filter?.filters;
        //     filters.forEach((item) => {
        //         if (item?.filters?.[0]?.field === "issued_at") {
        //             filtersParam[0].filters[0] = item?.filters?.[0];
        //         }
        //         if (item?.filters?.[1]?.field === "issued_at") {
        //             filtersParam[0].filters[1] = item?.filters?.[1];
        //         } else {
        //             filtersParam.push(item);
        //         }
            // })
        // }
        const payload = {
            deductor_id: deductor_Id ?? "",
            limit:100,
            // sort_by: dataState?.sort?.[0]?.dir ?? "",
            // sort_on: dataState?.sort?.[0]?.field ?? "",
            filters: filtersParam
        }
        if (cursor?.next_page) payload.page = cursor.next_page ?? 1;

        try {
            const result = await gocallApi("post", url, payload);

            if (cursor?.next_page) {

                setData((prev) => {
                    prev = prev.concat(result?.data?.data ?? []);
                    return [...prev];
                })
                setIntialData((prev) => {
                    prev = prev.concat(result?.data?.data ?? []);
                    return [...prev];
                })

            } else {
                setData(result?.data?.data ?? []);
                setIntialData(result?.data?.data ?? []);
            }
            setCursor(result?.data?.cursor ?? { current_page: 1, next_page: null });

        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setChartDataSkeleton(false);
        }
    }

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem ?? {};
        const dataIndex = props.dataIndex ?? null;
        return (
            <tr>
                <td class="text-left pl-1 ">
                    {dataItem?.financial_year ? `${dataItem.financial_year}-${dataItem.financial_year + 1}` : ""}
                </td>
                <td class="text-center " style={{paddingRight: "25px"}}>
                    {dataItem?.form_type ?? ""} {dataItem?.quarter ? ` | ${dataItem.quarter}` : ""}
                </td>
                <td class="text-center pl-0">
                    {dataItem?.issued_at ? moment(dataItem.issued_at).format('DD/MM/YYYY') : ""}
                </td>
                <td class="text-left pl-0 pr-2">
                    {dataItem?.subject ?? ""}
                </td>
                <td class="text-left px-0"  title={dataItem.ref_num ?? ""}>
                    {dataItem.ref_num}
                </td>
                <td class={`${data.length > 5 ? "text-right pr-2" : "text-center"}`} >
                    <img src={dwnloadPdf} alt="download icon" className="downloadicon_pointer planexpiredalert" title="Download Intimation" width="19" onClick={() => {
                        if (dataItem.request_no && dataItem.status === "AVAILABLE") {
                            noticeOrderExcelList(dataItem)
                        } else {
                            if (dataItem.sync_status === "IN_PROGRESS") {
                                setError("The requested file will be available soon. Please revisit shortly.");
                            } else {
                                setSyncData(dataItem, dataIndex)
                            }

                        }
                    }} />
                </td>
            </tr>
        )
    }

    useEffect(() => {
            getNoticeOrderList({ deductor_Id: deductor_Id, financialYear: financialYear });
    }, [deductor_Id, financialYear])

    return (
        <>

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {loading && <LoadingIndicator />}

            {startConnector
                ? (<ConnectorAdapter
                    show={startConnector}
                    handleClose={() => setStartConnector(false)}
                    setPort={setPort}
                    handleProceed={() => setCheckTracesCredentials(true)}
                />)
                : null
            }

            {checkTracesCredentials && port
                ? <TracesConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={() => {
                        setStartConnector(false);
                        setCheckTracesCredentials(false);
                    }}
                    tan={tan}
                    port={port}
                    setCaptchaRef={setCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                />
                : null
            }

            {captchaRef && port
                ? (
                    <NoticeSyncData
                        deductorId={deductorId}
                        port={port}
                        captchaRef={captchaRef}
                        setSuccess={setSuccess}
                        setError={setError}
                        setShowSyncVerify={() => {
                            setStartConnector(false);
                            setCheckTracesCredentials(false);
                            setCaptchaRef(null);
                        }}
                        handleProceed={(syncStatus) => {

                            data.forEach((item) => {
                                if (tan === item.tan) {
                                    item.sync_status = syncStatus?.data?.action_status ?? item.syncStatus;
                                }
                            })
                            setData([...data]);
                            setTimeout(() => {
                                getNoticeOrderList({ deductor_Id: deductor_Id, cursor: cursor, financialYear:financialYear })
                            }, 10000)
                        }}

                    />
                )
                : null
            }
            {chartDataSkeleton ? <SkeletonNoticeOrder title="Notices & Orders" colmd="col-md-7" row={5} cell={7} colgroup={skeletonColgroup}  mainHead={skeletonMainHead} /> :
                <div class="col-12 col-xl-7 col-lg-6 col-md-12 col-sm-12" >
                    <fieldset class="Deductor_dashboard_outstdemand_fs Deductor_dashboard_noticeorder_fs">
                        <legend class="Deductor_dashboard_outstdemand_leg">
                            {/* <h4>Notices & Orders</h4> */}

                            <div className="Deductor_dashboard_inndev">
                                <div className="Deductor_dashboard_lefts">
                                    <h4>Notices & Orders</h4>
                                </div>
                                <div className="Deductor_dashboard_inner"></div>
                                <div className="Deductor_dashboard_right">
                                    <Link to={`${BASE_PATH}raised-requests-list/${deductor_Id}?financial_year=${financialYear}`} className='planexpiredalert'>
                                        <button className="Deductor_dashboard_startfilingbtn planexpiredalert">
                                            View all the Raised Requests
                                        </button>
                                    </Link>
                                </div>
                                <div className="Deductor_dashboard_middleborder"></div>
                            </div>
                        </legend>
                        <div class={`table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs ${data.length > 5 ? "noticeorderlist" : "noticeorderlist"} `} >
                                <Grid
                                    className="table table-striped notices_orders_tb listofdedtb scrollvisible mb-0"
                                    data={data}
                                    {...dataState}

                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterEqOperator', operator: 'in' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    sortable
                                >
                                  
                                    <Column
                                        width="70"
                                        field="financial_year"
                                        title="FY"
                                        filter="dropdown"
                                        columnMenu={((props) => <ColumnMenu filterList={FinancialYearList} hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('financial_year') ? 'active ' : ''}
                                    />

                                    <Column
                                        width="108"
                                        field="quarter"
                                        title="Form | Qtr"
                                        filter="dropdown"
                                        columnMenu={((props) => <ColumnMenu filterList={QUARTER_LIST} hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('quarter') ? 'active ' : ''}
                                    />

                                    <Column
                                        width="80"
                                        field="issued_at"
                                        title="Date"
                                        filter="date"
                                        columnMenu={((props) => <ColumnMenu  {...props} />)}
                                        headerClassName={isColumnActive('issued_at') ? 'active ' : ''}
                                    />

                                    <Column
                                        width="210"
                                        field="subject"
                                        title="Subject"
                                        filter="text"
                                        columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={`${isColumnActive('subject') ? 'active ' : ''}`}


                                    />
                                    <Column
                                        width="180"
                                        field="ref_num"
                                        title="Reference No."
                                        headerClassName={`${isColumnActive('ref_num') ? 'active ' : ''}`}
                                        columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                    
                                    />


                                    <Column
                                        width="60"
                                        title="Actions"
                                        sortable={false}
                                        headerClassName="cursor-default"

                                    />
                                <GridNoRecords>
                                   No Intimation Order or Notices found
                                </GridNoRecords>
                                  
                                </Grid>
                        </div>
                        {cursor.next_page && <div className="text-center pt-1 showmorebox" >
                            <span className='planexpiredalert' onClick={() => {
                                getNoticeOrderList({ deductor_Id: deductor_Id, cursor: cursor, financialYear:financialYear })
                            }}>
                                showMore
                            </span>
                        </div>}
                    </fieldset>
                </div>
            }
        </>
    )
}

export default NoticeOrdersList;