import React, { useState, useEffect } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone, formatAccountsNumber } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";
import moment from "moment";


const SdPe26Q27EQReg = (props) => {

    const { apiData, formType } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);


    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    s_no: item[0],
                    cd_no: item[1],
                    dd_no: item[2],
                    deductee_ref_no: item[3],
                    pan: item[4],
                    name: item[5],
                    section: item[6],
                    pay_date: moment(item[7], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    amt_paid: item[8],
                    tds_ded: item[9],
                    rate: item[10],
                    pan_status: item[11],
                    tds_rate: item[12],
                    applicable_tax_rate_206aa: item[13],
                    applicable_tax_rate_206ab: item[14],
                    tax_rate: item[15],
                    tds_deducted: item[16],
                    short_deduction: item[17]
                })
            })
            return newData
        }
    };

    const dataStateChange = (data_state) => {
        if (data_state.filter || (data_state.sort && data_state?.sort?.length > 0)) {
            const filter_state = deepClone(data_state);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                            if (["gte", "lte", 'lt', 'gt', "neq", "eq"].includes(itemObj?.operator) && !Array.isArray(itemObj.value)) {

                                if (itemObj.field === "pay_date") {
                                    const date = moment(itemObj.value).format('DD/MM/YYYY');
                                    itemObj.value = moment(date, 'DD/MM/YYYY').format();
                                } else {
                                    itemObj.value = Number(itemObj.value);

                                }
                            }
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                if (itemObj.field !== "pay_date") {
                                    itemObj.value = itemObj.value?.[0] ?? "";
                                }

                            } else if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                let array = []
                                item.logic = "or"
                                if (itemObj?.value?.length > 0) {
                                    itemObj?.value.forEach((keyValue) => {
                                        if (keyValue === 'NA') {
                                            array.push({ field: itemObj.field, operator: 'isNull', value: null })
                                        } else {
                                            array.push({ field: itemObj.field, operator: 'eq', value: Number(keyValue) })
                                        }
                                    })
                                    item.filters = array;
                                }
                            }
                        })
                    }
                })
            }
            const dataProcess = process(inititalData, filter_state);
            setDataState({ ...data_state });
            setData(dataProcess.data ?? []);

        } else {
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData([...defaultData]);
            setDataState({});
        }

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const renderSubTextTitle = (title, subText) => {

        return (
            <div style={{ whiteSpace: 'break-spaces' }}>
                {title}
                {subText && (<div className="text-center">
                    {' '}<span className="b2btbtrthtextclr" style={{ paddingLeft: '20px' }}>{subText ?? 0}</span>
                </div>)}
            </div>
        );
    };

    const calculateTotal = (index) => {
        let total = 0
        data.length > 0 && data.forEach((item) => {
            switch (index) {
                case "16":
                    total += item?.tds_deducted;
                    break;
                case "17":
                    total += item?.short_deduction;
                    break;
                default:
                    total = 0
            }

        })
        return formatAccountsNumber(total)
    };

    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;
        const dataItem = props?.dataItem ?? {}

        return (
            <>
                <tr key={key} role="row">
                    <td className="text-center">{dataItem?.s_no}</td>
                    <td className="text-center">{dataItem?.cd_no ?? "-"}</td>
                    <td className="text-center">{dataItem?.dd_no ?? "-"}</td>
                    <td className="text-center">{dataItem?.deductee_ref_no ?? "-"}</td>
                    <td className="text-center">{dataItem?.pan ?? "-"}</td>
                    <td className="text-left">{dataItem?.name ?? "-"}</td>
                    <td className="text-center">{dataItem?.section ?? "-"}</td>
                    <td className="text-center">{moment(dataItem?.pay_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(dataItem?.pay_date).format("DD/MM/YYYY") ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.amt_paid ?? 0) ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.tds_ded ?? 0) ?? "-"}</td>
                    <td className="text-center">{dataItem?.rate ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.pan_status ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.tds_rate ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.applicable_tax_rate_206aa ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.applicable_tax_rate_206ab ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.tax_rate ?? "-"}</td>
                    <td className="text-right notices_orders_rowbgclr_grey">{formatAccountsNumber(dataItem?.tds_deducted ?? 0) ?? "-"}</td>
                    <td className="text-right notices_orders_rowbgclr_grey">{formatAccountsNumber(dataItem?.short_deduction ?? 0) ?? "-"}</td>
                </tr>

                {key + 1 === data.length &&
                    <tr className="tdsjustification_rowshadow">
                        <td className="text-left" colSpan={2}>Total</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-right">
                            {calculateTotal("16")}
                        </td>
                        <td className="text-right">
                            {calculateTotal("17")}
                        </td>
                    </tr>
                }

            </>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);


    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab09" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">
                    <div className="card">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                            <Tooltip anchorElement="pointer" position="top">
                                <Grid
                                    className="table table-striped exptds_justformtype_table mb-0"
                                    style={{ maxHeight: '530px', overflowX: 'auto' }}
                                    data={data}
                                    {...dataState}

                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        pan: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    sortable
                                >
                                    <Column>
                                        <Column>
                                            <Column
                                                field="s_no"
                                                title={renderSubTextTitle('Sr. No.')}
                                                width="50"
                                            />
                                        </Column>
                                    </Column>

                                    <Column title="Data From Statement" headerClassName={'sorting_disabled'}>
                                        <Column>
                                            <Column
                                                field="cd_no"
                                                headerClassName={isColumnActive('cd_no') ? 'active' : ''}
                                                title={renderSubTextTitle('CD Serial No.')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="dd_no"
                                                headerClassName={isColumnActive('dd_no') ? 'active' : ''}
                                                title={renderSubTextTitle('DD Serial No.')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="deductee_ref_no"
                                                headerClassName={isColumnActive('deductee_ref_no') ? 'active' : ''}
                                                title={renderSubTextTitle(`${formType === "24Q" ? "Employee" : "Deductee"} Reference Number`)}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="pan"
                                                headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                title={renderSubTextTitle(`PAN of ${formType === "24Q" ? "Employee" : "Deductee"}`)}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="pan"
                                            />
                                            <Column
                                                field="name"
                                                headerClassName={isColumnActive('name') ? 'active' : ''}
                                                title={renderSubTextTitle(`Name of ${formType === "24Q" ? "Employee" : "Deductee"}`)}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="section"
                                                headerClassName={isColumnActive('section') ? 'active' : ''}
                                                title={renderSubTextTitle('Section Code')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="pay_date"
                                                headerClassName={isColumnActive('pay_date') ? 'active' : ''}
                                                title={renderSubTextTitle('Date of Payment / Credit')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="date"
                                            />
                                            <Column
                                                field="amt_paid"
                                                headerClassName={isColumnActive('amt_paid') ? 'active' : ''}
                                                title={renderSubTextTitle('Amount Paid / Credited')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="tds_ded"
                                                headerClassName={isColumnActive('tds_ded') ? 'active' : ''}
                                                title={renderSubTextTitle('TDS Deducted')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="rate"
                                                headerClassName={isColumnActive('rate') ? 'active' : ''}
                                                title={renderSubTextTitle('Rate as per statement(%)')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                        </Column>
                                    </Column>

                                    <Column title="Processed Data" headerClassName={'sorting_disabled notices_orders_rowbgclr_yell'}>
                                        <Column headerClassName={'notices_orders_rowbgclr_yell'}>
                                            <Column
                                                field="cert_num"
                                                headerClassName={isColumnActive('cert_num') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Status of PAN ')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="tds_rate"
                                                headerClassName={isColumnActive('tds_rate') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('TDS Rates as per Income Tax Act, \n 1961')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="from_date"
                                                headerClassName={isColumnActive('from_date') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Applicable Tax \n Rate as per \n Section 206AA')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="date"
                                            />
                                            <Column
                                                field="exp_date"
                                                headerClassName={isColumnActive('exp_date') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Applicable Tax \n Rate as per \n Section 206AB')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="date"
                                            />
                                            <Column
                                                field="limit_cert"
                                                headerClassName={isColumnActive('limit_cert') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Applied \n Tax Rate')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                        </Column>

                                        <Column title="Short Deduction" headerClassName={'sorting_disabled notices_orders_rowbgclr_grey'}>
                                            <Column
                                                field="tds_deducted"
                                                headerClassName={isColumnActive('tds_deducted') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                                title={renderSubTextTitle('TDS to be Deducted(Including Surcharge and \n Edu. Cess. If applicable)')}
                                                width="160"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="short_deduction"
                                                headerClassName={isColumnActive('short_deduction') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                                title={renderSubTextTitle('Short \n Deduction')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                        </Column>

                                    </Column>

                                    <GridNoRecords>
                                        No Record Found
                                    </GridNoRecords>

                                </Grid>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default SdPe26Q27EQReg;