import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../features/header/headerSlice';
import queryString from 'query-string';
import './CorrectionDashboard.css';
import { BASE_PATH } from '../../app/constants';
import EditDeductor from '../editDeductor/EditDeductor';
import DeducteeCorrectionList from '../deducteeList/DeducteeCorrectionList';
import EmployeeSalaryList from '../deductorDashboard/employeeDashboard/employeeSalaryList/EmployeeSalaryList';
import DeducteeTransactions from '../deducteeTransactions/DeducteeTransactions';
import ChallanList from '../challanList/ChallanList';
import CorrectionTDSComputation from '../deducteeTransactionsAndChallan/CorrectionTDSComputation';
import {
    checkConso,
} from '../../api/returnAPI';
import FVUGenerationV1 from '../deducteeTransactionsAndChallan/fvuGeneration/FVUGenerationV1';

const CorrectionDashboard = ({ history, ...props }) => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const deductor_id = params.deductor_id;
    const return_id = params.return_id;
    const current_tab = params.tab ? params.tab : 'edit_deductor';
    const headerDataDetails = useSelector(selectHeaderData);
    const [consoStatus, setConsoStatus] = useState([]);



    const getConsoStatus = async (dedId, rtnId) => {
        try {
            const request = `?deductor_id=${dedId}&return_id=${rtnId}`;
            const results = await checkConso(request);
            setConsoStatus(results.data)
        } catch (err) {
        }
    };

    useEffect(() => {
        getConsoStatus(deductor_id, return_id);
    }, [deductor_id, return_id])


    return (
        <>
            <div className="container-fluid w-1300">
                <div className="row mt-2">
                    <div className="col-md-12 mt-2">
                        <div className="corrctiontimelinebox">

                            <div className="btn-group btn-group-md">
                                <Link className="backbtntextclr pt-1" to={`${BASE_PATH}correction-return-dashboard/${deductor_id}/return/${return_id}`}>&lt;  Back to Return Dashboard</Link>
                            </div>

                            <div className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "btn-group btn-group-md corrsuminnerbox01" : "btn-group btn-group-md corrsuminnerboxlong01"}>
                                <Link className="timelinetextclr pt-1"
                                    to={{
                                        pathname: `${BASE_PATH}correction`,
                                        search: `?deductor_id=${deductor_id}&return_id=${return_id}&tab=edit_deductor`,
                                        state: location.state,
                                    }}
                                >
                                    <span className={current_tab === 'edit_deductor' ? "corrsuminnercontent activetextclr" : "corrsuminnercontent"}>Deductor</span>
                                </Link>
                                <span className="timelinerightbor"></span>
                            </div>
                            <div className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "btn-group btn-group-md corrsuminnerbox02" : "btn-group btn-group-md corrsuminnerboxlong02"}>
                                <Link className="timelinetextclr pt-1"
                                    to={{
                                        pathname: `${BASE_PATH}correction`,
                                        search: `?deductor_id=${deductor_id}&return_id=${return_id}&tab=deductee`,
                                        state: location.state,
                                    }}>
                                    <span className={current_tab === 'deductee' ? "corrsuminnercontent activetextclr" : "corrsuminnercontent"}> Deductee & PAN</span>
                                </Link>
                                <span className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "timelinerightbor correctbor02" : "timelinerightbor correctborlong02"}></span>
                            </div>
                            {(headerDataDetails && headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4') ? (
                                <div className="btn-group btn-group-md corrsuminnerbox07">
                                    <Link className="timelinetextclr pt-1"
                                        to={{
                                            pathname: `${BASE_PATH}correction`,
                                            search: `?deductor_id=${deductor_id}&return_id=${return_id}&tab=salary`,
                                            state: location.state,
                                        }}>
                                        <span className={current_tab === 'salary' ? "corrsuminnercontent activetextclr" : "corrsuminnercontent"}> Salary</span>
                                    </Link>
                                    <span className="timelinerightbor correctbor07"></span>
                                </div>
                            ) : null}
                            <div className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "btn-group btn-group-md corrsuminnerbox03" : "btn-group btn-group-md corrsuminnerboxlong03"}>
                                <Link className="timelinetextclr pt-1"
                                    to={{
                                        pathname: `${BASE_PATH}correction`,
                                        search: `?deductor_id=${deductor_id}&return_id=${return_id}&tab=transactions`,
                                        state: location.state,
                                    }}>
                                    <span className={current_tab === 'transactions' ? "corrsuminnercontent activetextclr" : "corrsuminnercontent"}> Deductee Transaction</span>
                                </Link>
                                <span className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "timelinerightbor correctbor03" : "timelinerightbor correctborlong03"}></span>
                            </div>
                            <div className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "btn-group btn-group-md corrsuminnerbox04" : "btn-group btn-group-md corrsuminnerboxlong04"}>
                                <Link className="timelinetextclr pt-1"
                                    to={{
                                        pathname: `${BASE_PATH}correction`,
                                        search: `?deductor_id=${deductor_id}&return_id=${return_id}&tab=challan`,
                                        state: location.state,
                                    }}
                                >
                                    <span className={current_tab === 'challan' ? "corrsuminnercontent activetextclr" : "corrsuminnercontent"}> Challan</span>
                                </Link>
                                <span className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "timelinerightbor correctbor04" : "timelinerightbor correctborlong04"}></span>
                            </div>
                            <div className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "btn-group btn-group-md corrsuminnerbox05" : "btn-group btn-group-md corrsuminnerboxlong05"}>
                                <Link className="timelinetextclr pt-1"
                                    to={{
                                        pathname: `${BASE_PATH}correction`,
                                        search: `?deductor_id=${deductor_id}&return_id=${return_id}&tab=computation`,
                                        state: location.state,
                                    }}
                                >
                                    <span className={current_tab === 'computation' ? "corrsuminnercontent activetextclr" : "corrsuminnercontent"}> Computation</span>
                                </Link>
                                <span className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "timelinerightbor correctbor05" : "timelinerightbor correctborlong05"}></span>
                            </div>
                            <div className={headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '24Q' && headerDataDetails.quarter === 'Q4' ? "btn-group btn-group-md corrsuminnerbox06" : "btn-group btn-group-md corrsuminnerboxlong06"}>
                                <Link className="timelinetextclr pt-1"
                                    to={{
                                        pathname: `${BASE_PATH}correction`,
                                        search: `?deductor_id=${deductor_id}&return_id=${return_id}&tab=FVU`,
                                        state: location.state,
                                    }}
                                >
                                    <span className={current_tab === 'FVU' ? "corrsuminnercontent activetextclr" : "corrsuminnercontent"}>FVU</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                current_tab !== 'FVU' && current_tab !== 'computation' &&
                <div className="container-fluid">
                    <div className=" row mt-2">
                        <div className="col-md-12">
                            <p className="textclrblue mb-0">Values in Blue indicate that there has been a change in this field from the imported conso file.</p>
                        </div>
                    </div>
                </div>
            }

            <div className="mt-3">
                {current_tab === 'edit_deductor' && <EditDeductor componentImport={true} consoStatus={consoStatus} match={{ params: { deductorId: deductor_id, returnId: return_id } }} />}
                {current_tab === 'deductee' && <DeducteeCorrectionList componentImport={true} consoStatus={consoStatus} match={{ params: { deductorId: deductor_id } }} history={history} />}
                {current_tab === 'salary' && <EmployeeSalaryList componentImport={true} consoStatus={consoStatus} history={history} />}
                {current_tab === 'transactions' && <DeducteeTransactions consoStatus={consoStatus} history={history} />}
                {current_tab === 'challan' && <ChallanList consoStatus={consoStatus} match={{ params: { deductorId: deductor_id, returnId: return_id } }} history={history} />}
                {current_tab === 'computation' && <CorrectionTDSComputation componentImport={true} consoStatus={consoStatus} history={history} headerDataDetails={headerDataDetails} />}

                {current_tab === 'FVU' && <FVUGenerationV1 componentImport={true} consoStatus={consoStatus} history={history} />}

            </div>
        </>
    );
};

export default CorrectionDashboard;
