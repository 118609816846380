import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Formik, Form, Field,
} from 'formik';
import $ from 'jquery';
import Autosuggest from 'react-autosuggest';
import { challanFormValidationSchema } from './ChallanFormSchema';
import ErrorFocus from '../ErrorFocus';
import './CreateChallan.css';
import DatePicker from '../datePicker/DatePickerV2';
import { searchBSRCodes, getNatureOfPaymentCodes, challanUsingConnector } from '../../api/challanAPI';
import { selectHeaderData } from '../../features/header/headerSlice';
import NumericInput from '../NumericInput';
import ConnectorAdapter from '../kdkConnector/ConnectorAdapter';
import Select from 'react-select';


import {
  LIST_OF_BANKS, PAYMENT_MODE, PAYMENT_LIST, BANKS_LIST, MAJOR_HEAD_LIST, PAY_AT_COUNTER,
  HEADER_CONNECTOR_DIGEST_KEY, TYPE_OF_PAYMENT, MINOR_HEAD, TYPE_OF_PAYMENT_24Q
} from '../../app/constants';
import { getAssessmentYears } from '../../utils/UtilityFunctions'
import UpdateIncomeTaxCredential from '../kdkConnector/UpdateIncomeTaxCredential';
import ShowAlert from '../ShowAlert';
import { createChallanPaymentUsingConnector } from '../../api/localConnectorAPI';
import { challanFormFields } from './ChallanFormHelper';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import moment from 'moment';
import WindowWrapperFixed from '../grid/WindowWrapperFixed';
import exclamationIcon from "../../images/exclamation.png";


export default function CreateChallanForm(props) {
  const headerData = useSelector(selectHeaderData);
  // eslint-disable-next-line
  const [horizontalView, setHorizontalView] = useState(props.horizontalView ? props.horizontalView : false);
  const [bsrCodes, setBSRCodes] = useState((localStorage.getItem('bsrCodes') ? JSON.parse(localStorage.getItem('bsrCodes')) : {}));
  const [paymentCodes, setPaymentCodes] = useState({});
  const [paymentCodesArray, setPaymentCodesArray] = useState([]);
  const [assessment_years, setAssessmentYears] = useState([]);
  const [checkITCredentials, setCheckITCredentials] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [startConnector, setStartConnector] = useState(false);
  const [port, setPort] = useState(0);
  const [majorHeads, setMajorHeads] = useState(MAJOR_HEAD_LIST);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [formType, setFormType] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const [typeOfPaymentOption, setTypeOfPaymentOption] = useState(TYPE_OF_PAYMENT);

  const {
    challanInfo, ErrorChallan, createChallan, onClose, challanId, autoSaveTriggerOnce, componentImport, updateChallanInfo, challanList, currentChallan, setCurrentChallan, deductorId
  } = props;

  const nPattern = new RegExp(/^[0-9]*$/);

  // eslint-disable-next-line
  const handleMajorHeadOptions = (nature_of_payment_id = '') => {
    const company_ids = [23, 24, 41, 56];
    let majorHeadsOptions = {};
    if (nature_of_payment_id === '') {
      setMajorHeads(MAJOR_HEAD_LIST);
    } else {
      if (company_ids.includes(parseInt(nature_of_payment_id))) {
        majorHeadsOptions['OTHER_THAN_COMPANY'] = MAJOR_HEAD_LIST.OTHER_THAN_COMPANY;
      } else {
        majorHeadsOptions = MAJOR_HEAD_LIST;
      }
    }
    setMajorHeads(majorHeadsOptions);
  }

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    $('[data-toggle="tooltip"]').click(() => {
      $('[data-toggle="tooltip"]').tooltip('hide');
    });

    if (componentImport) {
      $('#bsr_code_div').find('input').attr('disabled', 'disabled').css("background-color", "#e9ecef");;
      $("#type_of_payment,#tds_amtp,#surcharge_amtp,#edu_cess_amtp,#interest_amtp,#fees_amtp,#other_amtp").attr("disabled", 'disabled');
    }

    function initResizeElement() {
      const popups = document.getElementsByClassName('challan-popup');
      let element = null;
      let startX; let startY; let startWidth; let
        startHeight;

      function doDragWidth(e) {
        element.style.width = `${startWidth - e.clientX + startX}px`;
      }

      function doDragHeight(e) {
        const cardHeight = $('.card').height() + 30;
        const newHeight = startHeight - e.clientY + startY;
        element.style.height = `${Math.min(cardHeight, newHeight)}px`;
      }

      function stopDrag() {
        document.documentElement.removeEventListener('mousemove', doDragWidth, false);
        document.documentElement.removeEventListener('mousemove', doDragHeight, false);
        document.documentElement.removeEventListener('mouseup', stopDrag, false);
      }

      function initDragWidth(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10,
        );
        document.documentElement.addEventListener('mousemove', doDragWidth, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      function initDragHeight(e) {
        element = this.parentPopup;

        startY = e.clientY;
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10,
        );
        document.documentElement.addEventListener('mousemove', doDragHeight, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      for (let i = 0; i < popups.length; i++) {
        const p = popups[i];

        const right = document.createElement('div');
        right.className = 'resizer-left';
        p.appendChild(right);
        right.addEventListener('mousedown', initDragWidth, false);
        right.parentPopup = p;

        const bottom = document.createElement('div');
        bottom.className = 'resizer-up';
        p.appendChild(bottom);
        bottom.addEventListener('mousedown', initDragHeight, false);
        bottom.parentPopup = p;
      }
    }

    $('#create-deductor').animate({
      opacity: '1',
    });

    // initDragElement();
    initResizeElement();

    async function fetchEverything() {
      async function fetchPaymentCodes() {
        const result = await getNatureOfPaymentCodes();

        const paymentCodeData = {};
        result.data.map((paymentCode) => {
          paymentCodeData[paymentCode.code] = paymentCode;
          return true;
        });
        setPaymentCodes(paymentCodeData);

        if (result?.data && result.data.length > 0) {
          const paymentCodeArray = [{ value: '', label: 'Select' }];
          result.data.forEach((item) => {
            paymentCodeArray.push({ ...item, value: item.nature_of_payment_id, label: `${item.code} - ${item.description}` })

          })
          setPaymentCodesArray(paymentCodeArray);
        }
      }
      try {
        await Promise.all([
          fetchPaymentCodes(),
        ]);
      } catch (err) {
        console.error('error: ', err);
      }
    }
    fetchEverything();

    setTimeout(function () {
      if (autoSaveTriggerOnce === true) {
        $('#challan_save_btn').trigger('click');
      }
    }, 1000);

    // Assessment Years in this case to start from 2015
    setAssessmentYears(getAssessmentYears(2015));
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [autoSaveTriggerOnce, componentImport]);


  const handleBSRCodeChange = (BSRCode) => {
    async function fetchBSRCodes() {
      try {
        const result = await searchBSRCodes(BSRCode);
        const bsrCodeData = {};
        result.data.map((bsrCode) => {
          bsrCodeData[bsrCode.bsr_code] = bsrCode;
          return true;
        });
        setBSRCodes(bsrCodeData);
        localStorage.setItem('bsrCodes', JSON.stringify(bsrCodeData));
      } catch (err) {
        console.error('Error in fetching bsr codes ', err);
      }
    }
    if ((BSRCode.length !== 0 && BSRCode.length % 2 === 0) || BSRCode.length === 7) {
      fetchBSRCodes();
    }
  };

  function printErrMsg(errID, msg) {
    if (errID && msg) {
      document.getElementById(errID).innerHTML = msg;
    }
  }

  const prevChallan = () => {
    let selectPage = 0;
    if (currentChallan > 0) {
      selectPage = currentChallan - 1;
    }
    if (currentChallan !== selectPage) {
      setCurrentChallan(selectPage);
      updateChallanInfo(challanList[selectPage], horizontalView);
    }
    else {
      onClose();
    }
  }

  const nextChallan = () => {
    const selectPage = currentChallan + 1;
    if (currentChallan !== selectPage && selectPage < challanList?.length) {
      setCurrentChallan(selectPage);
      updateChallanInfo(challanList[selectPage], horizontalView);
    }
    else {
      onClose();
    }
  }


  const makePaymentByConnector = (values) => {
    const request = {
      "deductor_id": deductorId ?? '',
      "mode_of_payment": values.mode_of_payment ?? '',
      "assessment_year": String(values.assessment_year),
      "deductee_status": values.deductee_status ?? '',
      "type_of_payment": values.type_of_payment ?? challanFormFields.type_of_payment,
      "bank_name": values.bank_name ?? '',
      "nature_of_payment_id": values.nature_of_payment_id ?? '',
      "payment_through": values.mode_of_payment_offline,
      "cheque_dd_no": values.cheque_dd_no ?? 0,
      "tds_amtp": values.tds_amtp ?? 0,
      "surcharge_amtp": values.surcharge_amtp ?? 0,
      "edu_cess_amtp": values.edu_cess_amtp ?? 0,
      "interest_amtp": values.interest_amtp ?? 0,
      "fees_amtp": values.fees_amtp ?? 0,
      "other_amtp": values.other_amtp ?? 0,
      "book_entry": values.book_entry ?? 0
    }

    async function fetchEverything() {
      try {
        setLoading(true)
        const result = await challanUsingConnector(request);

        if (result.data.status === 1) {
          setResult(result);
        }
        const responseData = result.data ?? null;
        const responseHeader = result.headers ?? null;

        //Connector request data
        const connectorRequest = {
          "data": responseData.data
        };
        let extraHeader = {};
        extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

        try {

          await createChallanPaymentUsingConnector(port, connectorRequest, extraHeader);

        } catch (err) {

          setError(err.message);
        }


      } catch (err) {
        if (err.code === 424) {
          setCheckITCredentials(true);
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }

    }
    fetchEverything();
  };

  useEffect(() => {
    setFormType(headerData?.form_type)
    if (headerData.form_type === '24Q' || headerData.form_type === '27EQ') {
      setTypeOfPaymentOption(TYPE_OF_PAYMENT_24Q);
    }

  }, [headerData?.form_type]);

  const resetPaymentDates = (minor_head, setFieldValue) => {
    setFieldValue('deposit_date', '');
    $('.cal_clear_btn').trigger('click');
    if (headerData.form_type === '26Q' && minor_head === 'TDS_TCS_ADVANCE_TAX_100' && headerData.quarter_start_date && headerData?.quarter_end_date) {
      setMinDate(new Date(headerData.quarter_start_date));
      setMaxDate(new Date(headerData.quarter_end_date));
    } else {
      setMinDate(moment('1900-01-01').toDate());
      setMaxDate(moment(new Date()).toDate());
    }
  }

  let width1 = document.body.clientWidth;
  // let top1 = document.body.clientTop;

  return (
    <>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        onClose={() => { setError(null); }}
      />
      {startConnector
        ? <ConnectorAdapter
          show={startConnector}
          handleClose={() => setStartConnector(false)}
          setPort={setPort}
          handleProceed={() => {
            $('#payment_through_connector').trigger('click')
          }}
          title={'Login at Income Tax Portal'}
          section={'EFILING'}
        />
        : null
      }

      {!result && checkITCredentials ?
        <UpdateIncomeTaxCredential
          show={checkITCredentials}
          handleClose={() =>
            setCheckITCredentials(false)
          }
          deductorId={deductorId}
          makePaymentByConnector={() => {
            makePaymentByConnector(data);
          }}
          handleProceed={() => {
            setCheckITCredentials(false);
          }}
          port={port}
        />
        : null

      }
      <Formik
        enableReinitialize={true}
        initialValues={challanInfo}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={challanFormValidationSchema}
        onSubmit={(values) => {
          // same shape as initial values   

          if (values.challanDeposited === false) {

            if (port > 0) {
              const submitValues = { ...values };

              makePaymentByConnector(submitValues)
              setData(submitValues)
            } else {
              setStartConnector(true);
            }

          } else if (values.challanDeposited === true || values.challan_no > 0) {
            const submitValues = { ...values };
            if (bsrCodes[values.bsr_code]) {
              submitValues.bsr_code_id = bsrCodes[values.bsr_code].bsr_code_id;
            } else {
              delete submitValues['bsr_code_id'];
            }
            submitValues.nature_of_payment = values.nature_of_payment_id;
            if (paymentCodes[values.nature_of_payment_id] === undefined || paymentCodes[values.nature_of_payment_id] === '') {
              submitValues.nature_of_payment_id = paymentCodes[values.nature_of_payment_id];
            } else {
              submitValues.nature_of_payment_id = paymentCodes[values.nature_of_payment_id].nature_of_payment_id;
            }
            createChallan(submitValues);
          }
          else {
            printErrMsg('errorChallanNo', 'The challan no must be at least 1.');
          }

        }}
      >
        {({
          errors, values, setFieldValue,
        }) => {

          return (
            <Form key={1} autoComplete="off">
              <ErrorFocus />
              <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ padding: '15px 0px 3px 0px' }}>

                  <div className="col-md-8 col-sm-8 col-lg-8 col-xl-8">
                    {horizontalView

                      ? (
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12" style={{ display: 'flex', flexDirection: 'row' }}>
                          <h6 className="col-md-6 col-sm-6 col-lg-6 col-xl-6" style={{ cursor: 'default' }}>
                            {challanId && <span style={currentChallan > 0 ? { cursor: 'pointer', color: '#0000ff' } : { cursor: 'default', fontSize: '14px', fontWeight: '500' }} onClick={() => { return currentChallan > 0 ? prevChallan() : null }} className="pr-1">&lt; Previous</span>}
                            {challanId ? 'Update' : 'Create'}
                            {' '}
                            Challan
                            {challanId && <span style={currentChallan < (challanList?.length - 1) ? { cursor: 'pointer', color: '#0000ff', paddingRight: '10px' } : { cursor: 'default', paddingRight: '10px', fontSize: '14px', fontWeight: '500', color: '#000' }} onClick={() => { return currentChallan < (challanList?.length - 1) ? nextChallan() : null }} className="pl-1">Next &gt;</span>}
                          </h6>
                        </div>
                      )

                      : (
                        <div className="col-md-12 col-sm-10 col-lg-11 col-xl-11">
                          <h6 style={{ cursor: "default" }}>
                            {challanId && <span className="text-right pr-4" style={currentChallan > 0 ? { cursor: 'pointer', color: '#0000ff' } : { cursor: 'default', fontSize: '14px', fontWeight: '500' }} onClick={() => { return currentChallan > 0 ? prevChallan() : null }}>&lt; Previous</span>}
                            {challanId ? 'Update' : 'Create'}
                            {' '}
                            Challan
                            {challanId && <span style={currentChallan < (challanList?.length - 1) ? { cursor: 'pointer', color: '#0000ff', paddingRight: '10px' } : { cursor: 'default', paddingRight: '10px', fontSize: '14px', fontWeight: '500', color: '#000' }} onClick={() => { return currentChallan < (challanList?.length - 1) ? nextChallan() : null }} className="pl-4">Next &gt;</span>}
                          </h6>
                        </div>

                      )}

                  </div>

                  <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3" style={{ paddingTop: '5px' }}>
                  </div>
                </kendo-dialog-titlebar>}
                onClose={() => onClose()}
                initialHeight={'auto'}
                initialWidth={700}
                initialTop={50}
                totalPopups={1}
                isModalView={true}
                initialLeft={width1 - 720}
                className={'create-challan-form'}
              >

                <div className="cdf mt-1 verpopformamt px-2">
                  <div className="row">
                    <div className={horizontalView ? 'col-lg-6 border-right' : 'col-lg-12'}>
                      <div className="row padding_style mt-1 mb-1">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="challanDeposited"
                            id="inlineRadio1"
                            checked={(values.challanDeposited === undefined) ? setFieldValue('challanDeposited', true) : values.challanDeposited}
                            onClick={() => setFieldValue('challanDeposited', true)}
                            disabled={challanId}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            I have already deposited the challan
                          </label>
                        </div>
                        {!ErrorChallan ? (
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challanDeposited"
                              id="inlineRadio2"
                              checked={!values.challanDeposited}
                              onClick={() => setFieldValue('challanDeposited', false)}
                              disabled={challanId}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio2">
                              I will deposit now
                            </label>
                          </div>
                        ) : null}

                        {headerData.is_government_deductor && (
                          <>
                            <span className="f-12 mr-4 float-right bokrigh">Book Entry</span>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="book_entry"
                                id="book_entry1"
                                checked={(Number(values.book_entry) === 1)}
                                onClick={() => { setFieldValue('book_entry', 1); setFieldValue('type_of_payment', ''); }}
                              />
                              <label className="form-check-label" htmlFor="book_entry1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="book_entry"
                                id="book_entry2"
                                checked={(Number(values.book_entry) === 0)}
                                onClick={() => setFieldValue('book_entry', 0)}
                              />
                              <label className="form-check-label" htmlFor="book_entry2">No</label>
                            </div>
                          </>
                        )}

                      </div>
                      {values.challanDeposited
                        ? (
                          <div className="pt-2">
                            {horizontalView ? <div style={{ height: '11px' }} /> : null}
                            <div className={horizontalView ? 'row' : 'row mt-1'}>
                              <div className="form-group col-md-4">
                                <label htmlFor="challan_no">
                                  {Number(values.book_entry) === 1 ? 'Transfer Voucher Number' : 'Challan Number'}
                                  <span className="red_text">*</span>
                                </label>
                                <Field
                                  autoFocus
                                  type="text"
                                  className={`form-control ${errors?.challan_no ? 'input-error1' : ''}`}
                                  id="challan_no"
                                  placeholder={Number(values.book_entry) === 1 ? 'Enter Transfer Voucher Number' : 'Enter Challan Number'}
                                  name="challan_no"
                                  maxLength={5}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(event) => {
                                    setFieldValue('challan_no', event.target.value)
                                    if (parseInt(event.target.value) > 0) {
                                      printErrMsg('errorChallanNo', '');
                                    }
                                  }}
                                  disabled={componentImport ? true : false}
                                />
                                {errors.challan_no ? <span className="error_message">
                                  <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                  {Number(values.book_entry) === 1 ?
                                    errors.challan_no.replace("Challan Number", "Transfer Voucher Number")
                                    :
                                    errors.challan_no}
                                </span> : null}
                                {!errors.challan_no ? <div id="errorChallanNo" style={{ color: 'red', fontSize: '11px' }}></div> : null}
                              </div>

                              <div className="form-group col-md-4 depdatecalepadd">
                                <label htmlFor="deposit_date">
                                  Deposit Date
                                  <span className="red_text">*</span>
                                </label>
                                <DatePicker
                                  selectedDate={values?.deposit_date ? values?.deposit_date : ''}
                                  onChange={(value) => setFieldValue('deposit_date', value)}
                                  //minDate={moment('1900-01-01').toDate()}
                                  //maxDate={moment(new Date()).toDate()}
                                  minDate={minDate ? minDate : moment('1900-01-01').toDate()}
                                  maxDate={maxDate ? maxDate : moment(new Date()).toDate()}
                                  placeholder="Deposit Date"
                                  disabled={componentImport ? true : false}
                                  errors={errors?.deposit_date ? errors.deposit_date : ""}
                                />
                                {errors.deposit_date ? (
                                  <div className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.deposit_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="cheque_dd_no">
                                  Cheque /DD No.
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="cheque_dd_no"
                                  placeholder="Enter Cheque /DD No."
                                  name="cheque_dd_no"
                                  maxLength={6}
                                  disabled={componentImport ? true : false}
                                />
                              </div>
                              <div className="form-group col-md-4 createchallan_bsrcodepadd" id="bsr_code_div">
                                <label htmlFor="bsr_code">
                                  {Number(values.book_entry) !== 1 ? 'BSR Code' : 'Form 24G Receipt Number'}
                                  <span className="red_text">*</span>
                                </label>
                                <Autosuggest
                                  suggestions={Object.keys(bsrCodes)}
                                  onSuggestionsFetchRequested={() => { }}
                                  onSuggestionsClearRequested={() => { }}
                                  getSuggestionValue={(suggestion) => suggestion}
                                  renderSuggestion={(suggestion) => <div>{suggestion}</div>}
                                  inputProps={{
                                    placeholder: (Number(values.book_entry) !== 1) ? 'Enter BSR Code' : 'Enter Form 24G Receipt Number',
                                    value: values.bsr_code,
                                    onChange: (e, { newValue }) => {
                                      if (newValue.length <= 7 && nPattern.test(newValue)) {
                                        handleBSRCodeChange(newValue);
                                        setFieldValue('bsr_code', newValue);
                                      }
                                    },
                                  }}
                                  containerProps={{
                                    className: `${errors?.bsr_code ? 'input-error1' : ''}`,
                                  }}
                                  disabled={componentImport ? true : false}
                                  className={`form-control ${errors?.bsr_code ? 'input-error1' : ''}`}
                                />
                                {errors.bsr_code ?
                                  <div className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {Number(values.book_entry) === 1 ?
                                      errors.bsr_code.replace("BSR Code", "Form 24G Receipt Number")
                                      :
                                      errors.bsr_code}
                                  </div>
                                  : null}
                                {bsrCodes[values.bsr_code] ? <span className="greentext" style={{ fontSize: '10px' }}>{bsrCodes[values.bsr_code].bank_name}</span> : null}
                              </div>

                              <div className='form-group col-md-4'>
                                <label htmlFor="type_of_payment">
                                  Minor Head / Type of Payment
                                  <span className="red_text">*</span>
                                </label>
                                <Select
                                  value={typeOfPaymentOption[typeOfPaymentOption.findIndex((item) => String(item.value) === String(values.type_of_payment))] ?? null}
                                  onChange={(selected) => {
                                    setFieldValue('type_of_payment', selected.value);
                                    resetPaymentDates(selected.value, setFieldValue);
                                  }}
                                  options={typeOfPaymentOption}
                                  placeholder="Select option"
                                  className={`react-select-container ${errors?.type_of_payment ? 'input-error1' : ''}`}
                                  classNamePrefix="react-select"
                                  openMenuOnFocus={true}
                                />
                                <span className="overflowtooltipconttdsamt">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={errors.type_of_payment}
                                    style={{ color: 'red', fontSize: '11px' }}
                                  >

                                    {errors.type_of_payment ? <div className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.type_of_payment}</div> : null}
                                  </span>

                                </span>
                              </div>

                              <div className={'form-group col-md-4'} >
                                <label htmlFor="nature_of_payment_id">
                                  Section
                                </label>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  value={paymentCodesArray[paymentCodesArray.findIndex((item) => String(item.nature_of_payment_id) === String(values.nature_of_payment_id))] ?? null}
                                  onChange={(selected) => {
                                    setFieldValue(
                                      'nature_of_payment_id',
                                      selected.nature_of_payment_id,
                                    );
                                    setFieldValue('nature_of_remittance', [9, 10, 11, 54, 71].includes(Number(selected.nature_of_payment_id)) ? 'INTEREST_PAYMENT' : values.nature_of_remittance)

                                    Number(selected.nature_of_payment_id) === 10 && values?.deduction_reason === "C" && setFieldValue('deduction_reason', "");
                                  }
                                  }
                                  options={paymentCodesArray}
                                  placeholder="Select"
                                  openMenuOnFocus={true}
                                />

                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="pt-2">
                            {horizontalView ? <div style={{ height: '11px' }} /> : null}
                            <div className={horizontalView ? 'row' : 'row mt-1'}>

                              <div className='form-group col-md-4'>
                                <label htmlFor="assessment_year"><span className="pr-2">Assessment Year</span><span className="red_text">*</span></label>
                                <br />
                                <Select
                                  value={assessment_years[assessment_years.findIndex((item) => String(item.value) === String(values.assessment_year))] ?? null}
                                  onChange={(selected) => setFieldValue('assessment_year', selected.value)}
                                  options={assessment_years}
                                  placeholder="Select option"
                                  className={`react-select-container ${errors?.assessment_year ? 'input-error1' : ''}`}
                                  classNamePrefix="react-select"
                                  openMenuOnFocus={true}
                                />
                                <span className="overflowtooltipconttdsamt">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={errors.assessment_year}
                                    style={{ color: 'red', fontSize: '11px' }}
                                  >

                                    {errors.assessment_year ? <div className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.assessment_year}</div> : null}
                                  </span>

                                </span>
                              </div>

                              <div className='form-group col-md-4'>
                                <label htmlFor="type_of_payment">
                                  Minor Head / Type of Payment
                                  <span className="red_text">*</span>
                                </label>
                                <Select
                                  value={MINOR_HEAD[MINOR_HEAD.findIndex((item) => String(item.value) === String(values.type_of_payment))] ?? null}
                                  onChange={(selected) => setFieldValue('type_of_payment', selected.value)}
                                  options={MINOR_HEAD}
                                  placeholder="Select option"
                                  className={`react-select-container ${errors?.type_of_payment ? 'input-error1' : ''}`}
                                  classNamePrefix="react-select"
                                  openMenuOnFocus={true}
                                />
                                <span className="overflowtooltipconttdsamt">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={errors.type_of_payment}
                                    style={{ color: 'red', fontSize: '11px' }}
                                  >
                                    {errors.type_of_payment ? <div className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.type_of_payment}</div> : null}
                                  </span>

                                </span>
                              </div>

                              <div className={'form-group col-md-4'} >
                                <label htmlFor="nature_of_payment_id">
                                  Section
                                  <span className="red_text">*</span>
                                </label>
                                <Select
                                  className={`react-select-container ${errors?.nature_of_payment_id ? 'input-error1' : ''}`}
                                  classNamePrefix="react-select"
                                  value={paymentCodesArray[paymentCodesArray.findIndex((item) => String(item.nature_of_payment_id) === String(values.nature_of_payment_id))] ?? null}
                                  onChange={(selected) => {
                                    setFieldValue(
                                      'nature_of_payment_id',
                                      selected.nature_of_payment_id,
                                    );
                                    setFieldValue('nature_of_remittance', [9, 10, 11, 54, 71].includes(Number(selected.nature_of_payment_id)) ? 'INTEREST_PAYMENT' : values.nature_of_remittance)

                                    Number(selected.nature_of_payment_id) === 10 && values?.deduction_reason === "C" && setFieldValue('deduction_reason', "");
                                  }
                                  }
                                  options={paymentCodesArray}
                                  placeholder="Select"
                                  openMenuOnFocus={true}
                                />

                                {errors.nature_of_payment_id ? (
                                  <span className="overflowtooltipsurcharge">
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Section is required"
                                      style={{ color: 'red', fontSize: '11px' }}
                                      className="error_message"
                                    >
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.nature_of_payment_id}
                                    </span>
                                  </span>

                                ) : null}

                              </div>

                              <div className='form-group col-md-4'>
                                <label htmlFor="deductee_status">
                                  Major Head
                                  <span className="red_text">*</span>
                                </label>
                                <Select
                                  value={majorHeads[majorHeads.findIndex((item) => String(item.value) === String(values.deductee_status))] ?? null}
                                  onChange={(selected) => setFieldValue('deductee_status', selected.value)}
                                  options={majorHeads}
                                  placeholder="Select option"
                                  className={`react-select-container ${errors?.deductee_status ? 'input-error1' : ''}`}
                                  classNamePrefix="react-select"
                                  openMenuOnFocus={true}
                                />
                                <span className="overflowtooltipconttdsamt">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={errors.deductee_status}
                                    style={{ color: 'red', fontSize: '11px' }}
                                  >

                                    {errors.deductee_status ? <div className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.deductee_status}</div> : null}
                                  </span>

                                </span>
                              </div>

                              <div className='form-group col-md-4'>
                                <label htmlFor="mode_of_payment">
                                  Mode Of Payment
                                  <span className="red_text">*</span>
                                </label>
                                <Select
                                  value={PAYMENT_MODE[PAYMENT_MODE.findIndex((item) => String(item.value) === String(values.mode_of_payment))] ?? null}
                                  onChange={(selected) => setFieldValue('mode_of_payment', selected.value)}
                                  options={PAYMENT_MODE}
                                  placeholder="Select option"
                                  className={`react-select-container ${errors?.mode_of_payment ? 'input-error1' : ''}`}
                                  classNamePrefix="react-select"
                                  openMenuOnFocus={true}
                                />
                                <span className="overflowtooltipconttdsamt">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={errors.mode_of_payment}
                                    style={{ color: 'red', fontSize: '11px' }}
                                  >

                                    {errors.mode_of_payment ? <div className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.mode_of_payment}</div> : null}
                                  </span>

                                </span>
                              </div>

                              {(values.mode_of_payment === 'PAY_AT_BANK_COUNTER')
                                ?
                                <div className='form-group col-md-4'>
                                  <label htmlFor="mode_of_payment_offline">
                                    Payment Through
                                    <span className="red_text">*</span>
                                  </label>
                                  <Select
                                    value={PAY_AT_COUNTER[PAY_AT_COUNTER.findIndex((item) => String(item.value) === String(values.mode_of_payment_offline))] ?? null}
                                    onChange={(selected) => setFieldValue('mode_of_payment_offline', selected.value)}
                                    options={PAY_AT_COUNTER}
                                    placeholder="Select option"
                                    className={`react-select-container ${errors?.mode_of_payment_offline ? 'input-error1' : ''}`}
                                    classNamePrefix="react-select"
                                    openMenuOnFocus={true}
                                  />
                                  <span className="overflowtooltipconttdsamt">
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={errors.mode_of_payment_offline}
                                      style={{ color: 'red', fontSize: '11px' }}
                                    >
                                      {errors.mode_of_payment_offline ? <div className="error_message">
                                        <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                        {errors.mode_of_payment_offline}</div> : null}
                                    </span>

                                  </span>
                                </div>
                                : null
                              }

                              {(values.mode_of_payment === 'NET_BANKING' || values.mode_of_payment === 'DEBIT_CARD' || values.mode_of_payment === 'PAYMENT_GATEWAY')
                                ?
                                <div className='form-group col-md-4'>
                                  <label htmlFor="bank_name">
                                    Bank Name
                                    <span className="red_text">*</span>
                                  </label>
                                  {values.mode_of_payment === 'DEBIT_CARD' ?
                                    <Select
                                      value={BANKS_LIST[BANKS_LIST.findIndex((item) => String(item.value) === String(values.bank_name))] ?? null}
                                      onChange={(selected) => setFieldValue('bank_name', selected.value)}
                                      options={BANKS_LIST}
                                      placeholder="Select option"
                                      className={`react-select-container ${errors?.bank_name ? 'input-error1' : ''}`}
                                      classNamePrefix="react-select"
                                      openMenuOnFocus={true}
                                    />
                                    :
                                    values.mode_of_payment === 'PAYMENT_GATEWAY' ?
                                      <Select
                                        value={PAYMENT_LIST[PAYMENT_LIST.findIndex((item) => String(item.value) === String(values.bank_name))] ?? null}
                                        onChange={(selected) => setFieldValue('bank_name', selected.value)}
                                        options={PAYMENT_LIST}
                                        placeholder="Select option"
                                        className={`react-select-container ${errors?.bank_name ? 'input-error1' : ''}`}
                                        classNamePrefix="react-select"
                                        openMenuOnFocus={true}
                                      />
                                      :
                                      <Select
                                        value={LIST_OF_BANKS[LIST_OF_BANKS.findIndex((item) => String(item.value) === String(values.bank_name))] ?? null}
                                        onChange={(selected) => setFieldValue('bank_name', selected.value)}
                                        options={LIST_OF_BANKS}
                                        placeholder="Select option"
                                        className={`react-select-container ${errors?.bank_name ? 'input-error1' : ''}`}
                                        classNamePrefix="react-select"
                                        openMenuOnFocus={true}
                                      />
                                  }
                                  <span className="overflowtooltipconttdsamt">
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={errors.bank_name}
                                      style={{ color: 'red', fontSize: '11px' }}
                                    >
                                      {errors.bank_name ? <div className="error_message">
                                        <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                        {errors.bank_name}</div> : null}
                                    </span>

                                  </span>
                                </div>
                                : null
                              }
                            </div>
                          </div>
                        )}

                    </div>
                    <div className={horizontalView ? 'col-lg-6' : 'col-lg-12'}>
                      <div className="row toppaddvview mt-1">

                        <div className="form-group col-md-12">
                          <span style={{ fontWeight: '700', fontSize: '12px' }} >
                            Total Amount
                            {/* <br /> */}
                            {' '}
                            ₹
                            {' '}
                            <span style={{ color: 'rgb(49, 113, 155)', fontWeight: '700' }}>
                              {

                                (Number(values.tds_amtp) ? Number(values.tds_amtp) : 0) + (Number(values.surcharge_amtp) ? Number(values.surcharge_amtp) : 0) + (Number(values.edu_cess_amtp) ? Number(values.edu_cess_amtp) : 0)
                                + (Number(values.interest_amtp) ? Number(values.interest_amtp) : 0) + (Number(values.fees_amtp) ? Number(values.fees_amtp) : 0) + (Number(values.other_amtp) ? Number(values.other_amtp) : 0)

                              }
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-3">
                          <label htmlFor="tds_amtp">
                            TDS/TCS
                            <span className="red_text">*</span>
                          </label>
                          <NumericInput
                            type="input"
                            className={`form-control amount-box ${errors?.tds_amtp ? 'input-error1' : ''}`}
                            id="tds_amtp"
                            placeholder="Enter TDS/TCS"
                            name="tds_amtp"
                            min="0"
                            maxLength={14}
                            value={values.tds_amtp}
                            onChange={(e) => setFieldValue('tds_amtp', e.target.value)}
                          />
                          {errors.tds_amtp ? <div className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.tds_amtp}
                          </div> : null}
                          {(componentImport && challanInfo && challanInfo.old_tds_amtp && challanInfo.old_tds_amtp > 0 && challanInfo.old_tds_amtp !== challanInfo.tds_amtp) ? (<div className="error_message" style={{ color: challanInfo.tds_amtp_color ? `#${challanInfo.tds_amtp_color}` : '#0000ff' }}>{challanInfo.old_tds_amtp}</div>) : null}
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="surcharge_amtp">
                            Surcharge

                          </label>
                          <NumericInput
                            type="input"
                            className={`form-control amount-box ${errors?.surcharge_amtp ? 'input-error1' : ''}`}
                            id="surcharge_amtp"
                            placeholder="Enter Surcharge Paid"
                            name="surcharge_amtp"
                            min="0"
                            maxLength={14}
                            value={values.surcharge_amtp}
                            onChange={(e) => setFieldValue('surcharge_amtp', e.target.value)}
                          />
                          {/*<div style={{
                                position: 'absolute', left: '19px', top: '34px', fontSize: '14px',
                              }}
                              >
                                ₹
                              </div>*/}
                          {errors.surcharge_amtp ? <div className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.surcharge_amtp}
                          </div> : null}
                          {(componentImport && challanInfo && challanInfo.old_surcharge_amtp && challanInfo.old_surcharge_amtp > 0 && challanInfo.old_surcharge_amtp !== challanInfo.surcharge_amtp) ? (<div className="error_message" style={{ color: challanInfo.surcharge_amtp_color ? `#${challanInfo.surcharge_amtp_color}` : '#0000ff' }}>{challanInfo.old_surcharge_amtp}</div>) : null}

                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="edu_cess_amtp">
                            Health & Edu. Cess
                          </label>
                          <NumericInput
                            type="input"
                            className={`form-control amount-box ${errors?.edu_cess_amtp ? 'input-error1' : ''}`}
                            id="edu_cess_amtp"
                            placeholder="Enter Health & Edu. Cess"
                            name="edu_cess_amtp"
                            min="0"
                            maxLength={14}
                            value={values.edu_cess_amtp}
                            onChange={(e) => setFieldValue('edu_cess_amtp', e.target.value)}
                          />
                          {/*<div style={{
                                position: 'absolute', left: '19px', top: '34px', fontSize: '14px',
                              }}
                              >
                                ₹
                              </div>*/}
                          {errors.edu_cess_amtp ? <div className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.edu_cess_amtp}
                          </div> : null}
                          {(componentImport && challanInfo && challanInfo.old_edu_cess_amtp && challanInfo.old_edu_cess_amtp > 0 && challanInfo.old_edu_cess_amtp !== challanInfo.edu_cess_amtp) ? (<div className="error_message" style={{ color: challanInfo.edu_cess_amtp_color ? `#${challanInfo.edu_cess_amtp_color}` : '#0000ff' }}>{challanInfo.old_edu_cess_amtp}</div>) : null}
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="interest_amtp">
                            Interest Amount
                          </label>
                          <NumericInput
                            type="input"
                            className={`form-control amount-box ${errors?.interest_amtp ? 'input-error1' : ''}`}
                            id="interest_amtp"
                            placeholder="Enter Interest Paid"
                            name="interest_amtp"
                            min="0"
                            maxLength={14}
                            value={values.interest_amtp}
                            onChange={(e) => setFieldValue('interest_amtp', e.target.value)}
                          />
                          {/*<div style={{
                                position: 'absolute', left: '19px', top: '34px', fontSize: '14px',
                              }}
                              >
                                ₹
                              </div>*/}
                          {errors.interest_amtp ? <div className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.interest_amtp}
                          </div> : null}
                          {(componentImport && challanInfo && challanInfo.old_interest_amtp && challanInfo.old_interest_amtp > 0 && challanInfo.old_interest_amtp !== challanInfo.interest_amtp) ? (<div className="error_message" style={{ color: challanInfo.interest_amtp_color ? `#${challanInfo.interest_amtp_color}` : '#0000ff' }}>{challanInfo.old_interest_amtp}</div>) : null}
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="fees_amtp">
                            Fees

                          </label>
                          <NumericInput
                            type="input"
                            className={`form-control amount-box ${errors?.fees_amtp ? 'input-error1' : ''}`}
                            id="fees_amtp"
                            placeholder="Enter Fees"
                            name="fees_amtp"
                            min="0"
                            maxLength={14}
                            value={values.fees_amtp}
                            onChange={(e) => setFieldValue('fees_amtp', e.target.value)}
                          />
                          {/* <div style={{
                                position: 'absolute', left: '19px', top: '34px', fontSize: '14px',
                              }}
                              >
                                ₹
                              </div>*/}
                          {errors.fees_amtp ? <div className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.fees_amtp}
                          </div> : null}
                          {(componentImport && challanInfo && challanInfo.old_fees_amtp && challanInfo.old_fees_amtp > 0 && challanInfo.old_fees_amtp !== challanInfo.fees_amtp) ? (<div className="error_message" style={{ color: challanInfo.fees_amtp_color ? `#${challanInfo.fees_amtp_color}` : '#0000ff' }}>{challanInfo.old_fees_amtp}</div>) : null}
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="other_amtp" style={{ width: '140px' }}>
                            {!values.challanDeposited && values.payment_mode === 'OFFLINE' ? 'Other amount and penalty' : 'Penalty / Others '}

                          </label>
                          <NumericInput
                            type="input"
                            className={`form-control amount-box ${errors?.other_amtp ? 'input-error1' : ''}`}
                            id="other_amtp"
                            placeholder="Enter Other Amount"
                            name="other_amtp"
                            min="0"
                            maxLength={14}
                            value={values.other_amtp}
                            onChange={(e) => setFieldValue('other_amtp', e.target.value)}
                          />
                          {/*} <div style={{
                                position: 'absolute', left: '19px', top: '34px', fontSize: '14px',
                              }}
                              >
                                ₹
                              </div>*/}
                          {errors.other_amtp ? <div className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.other_amtp}
                          </div> : null}
                          {(componentImport && challanInfo && challanInfo.old_other_amtp && challanInfo.old_other_amtp > 0 && challanInfo.old_other_amtp !== challanInfo.other_amtp) ? (<div className="error_message" style={{ color: challanInfo.other_amtp_color ? `#${challanInfo.other_amtp_color}` : '#0000ff' }}>{challanInfo.old_other_amtp}</div>) : null}
                        </div>

                        {/* new field added */}

                        <span style={(headerData.return_type === 'CORRECTION') ? { display: 'contents' } : { display: 'none' }}>
                          <div className="form-group col-md-3">
                            <label htmlFor="interest_allocated_amtp">
                              Interest Paid Allocated
                              <span className="red_text">*</span>
                            </label>
                            <NumericInput
                              type="input"
                              className={`form-control amount-box ${errors?.interest_allocated_amtp ? 'input-error1' : ''}`}
                              id="interest_allocated_amtp"
                              placeholder="Enter Interest Paid Allocated"
                              name="interest_allocated_amtp"
                              min="0"
                              maxLength={14}
                              value={values.interest_allocated_amtp}
                              onChange={(e) => {
                                setFieldValue('interest_allocated_amtp', e.target.value)
                              }}

                            />
                            {/*} <div style={{
                                  position: 'absolute', left: '19px', top: '34px', fontSize: '14px',
                                }}
                                >
                                  ₹
                                </div>*/}
                            {errors.interest_allocated_amtp ? <span className="error_message">
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.interest_allocated_amtp}
                            </span> : null}
                            {(componentImport && challanInfo && challanInfo.old_interest_allocated_amtp && challanInfo.old_interest_allocated_amtp > 0 && challanInfo.old_interest_allocated_amtp !== challanInfo.interest_allocated_amtp) ? (<div className="error_message" style={{ color: challanInfo.interest_allocated_amtp_color ? `#${challanInfo.interest_allocated_amtp_color}` : '#0000ff' }}>{challanInfo.old_interest_allocated_amtp}</div>) : null}
                          </div>

                          <div className="form-group col-md-3">
                            <label htmlFor="other_allocated_amtp">
                              Other Amount Allocated
                              <span className="red_text">*</span>
                            </label>
                            <NumericInput
                              type="input"
                              className={`form-control amount-box ${errors?.other_allocated_amtp ? 'input-error1' : ''}`}
                              id="other_allocated_amtp"
                              placeholder="Enter Other Amount Allocated"
                              name="other_allocated_amtp"
                              min="0"
                              maxLength={14}
                              value={values.other_allocated_amtp}
                              onChange={(e) => {
                                setFieldValue('other_allocated_amtp', e.target.value)
                              }}
                            />
                            {/*<div style={{
                                  position: 'absolute', left: '19px', top: '34px', fontSize: '14px',
                                }}
                                >
                                  ₹
                              </div> */}
                            {errors.other_allocated_amtp ? <span className="error_message">
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.other_allocated_amtp}
                            </span> : null}
                            {(componentImport && challanInfo && challanInfo.old_other_allocated_amtp && challanInfo.old_other_allocated_amtp > 0 && challanInfo.old_other_allocated_amtp !== challanInfo.other_allocated_amtp) ? (<div className="error_message" style={{ color: challanInfo.other_allocated_amtp_color ? `#${challanInfo.other_allocated_amtp_color}` : '#0000ff' }}>{challanInfo.old_other_allocated_amtp}</div>) : null}
                          </div>
                        </span>
                      </div>


                    </div>
                    <div className={horizontalView ? 'text-center mt-2 col-sm-12 sticky_bottom' : 'col-sm-12 mt-2 sticky_bottom'}>
                      {!values.challanDeposited ? (
                        <button
                          id="payment_through_connector"
                          type="submit"
                          className="light_color_bg"
                          style={{
                            display: 'inline-block',
                            backgroundColor: '#31719b',
                            color: '#fff',
                            border: 'none',
                            padding: '7px 20px',
                            fontSize: '12px',
                            marginRight: '0.5rem',
                            borderRadius: '40px',
                            fontWeight: '500'
                          }}
                          onClick={() => {
                            if (port === 0) {
                              setStartConnector(true);
                            }
                          }}
                        >
                          {
                            (values.mode_of_payment === 'PAY_AT_BANK_COUNTER')
                              ? `Generate PDF`
                              : (values.mode_of_payment === 'RTGS_NEFT')
                                ? `Download E- Mandate Form`
                                : 'Pay Now'
                          }
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="light_color_bg btn_blue"
                          id="challan_save_btn"
                          style={{
                            display: 'inline-block',
                            backgroundColor: '#31719b',
                            color: '#fff',
                            border: 'none',
                            padding: '7px 23px',
                            fontSize: '12px',
                            marginRight: '0.5rem',
                            borderRadius: '40px',
                            fontWeight: '500'

                          }}
                        >
                          {!values.challanDeposited ? 'Print' : (
                            <span>
                              {!values.challanDeposited ? 'Pay Now' : 'Save'}
                            </span>
                          )}
                        </button>
                      )}

                      <button
                        type="button"
                        className="light-btn btn_gray"
                        onClick={() => onClose()}
                        style={{
                          border: 'none',
                          padding: '7px 23px',
                          fontSize: '12px',
                          marginRight: '0.5rem',
                          borderRadius: '40px',
                          fontWeight: '500'

                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>

              </WindowWrapperFixed>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
