import { Skeleton } from '@mui/material';
import React from 'react'

const SkeletonNoticeOrder = (props) => {
    const { row, cell, title, mainHead, subHead, colgroup, skeletonMinSubHead, colmd, classtitle } = props;
    return (
        <>
            <div class={`${colmd ? colmd : "col-md-12"}`}>
                <fieldset class="Deductor_dashboard_outstdemand_fs">
                    <legend class={`${classtitle ? "w-auto" : "Deductor_dashboard_outstdemand_leg"}`}>
                        <h4>{title}</h4>
                    </legend>
                    <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <table class="table table-striped Deductor_dashboard_outstdemand_tb mb-0">
                            <colgroup>

                                {colgroup?.length > 0 && colgroup.map((item) => (
                                    <col width={item} />
                                ))}
                            </colgroup>

                            <thead>

                                {mainHead?.length > 0 && <tr class="Deductor_dashboard_outstdemand_toprow">
                                    {mainHead.map((item) => (
                                        <th colSpan={item?.colSpan ?? 1} >
                                            <Skeleton animation="wave" variant="text" width={"100%"} height={"25px"} />
                                        </th>
                                    ))}
                                </tr>}


                                {subHead?.length > 0 && <tr class="">
                                    {subHead.map((item) => {
                                        return (
                                            <th width={item?.width ?? ""} >
                                                {item.type === "skeleton" && <Skeleton animation="wave" variant="text" width={"100%"} height={"25px"} />}
                                                {item.type === "none" && null}
                                            </th>
                                        )
                                    })}
                                </tr>}
                                {skeletonMinSubHead?.length > 0 && <tr class="">
                                    {skeletonMinSubHead.map((item) => {
                                        return (
                                            <th width={item?.width ?? ""} >
                                                {item.type === "skeleton" && <Skeleton animation="wave" variant="text" width={"100%"} height={"25px"} />}
                                                {item.type === "none" && null}
                                            </th>
                                        )
                                    })}
                                </tr>}


                            </thead>
                            <tbody>
                                {[...Array(row)]?.length > 0 && [...Array(row)].map((item) => (

                                    <tr>
                                        {[...Array(cell)]?.length > 0 && [...Array(cell)].map((item) => (
                                            <td class="text-center notice_order_textclr_blue">
                                                <Skeleton animation="wave" variant="text" width={"100%"} height={"25px"} />
                                            </td>
                                        ))}
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                </fieldset>
            </div>
        </>
    )
}

export default SkeletonNoticeOrder