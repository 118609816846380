import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { uploadManualConsoFile } from '../../../api/returnAPI';
import { BASE_PATH, MAX_IMPORT_FILE_SIZE_MB } from '../../../app/constants';
import { Alert } from 'react-bootstrap';



const RaisedFileImportPopup = (props) => {
    const { show, handleClose, deductorId, returnId, financialYear } = props;

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [consoCheck, setConsoCheck] = useState(false);
    let JSZip = require("jszip");

    const {
        acceptedFiles, getRootProps, getInputProps,
    } = useDropzone({
        accept: '.tds',
    });

    const removeFile = (targetFile) => {
        const newFiles = [];
        files.map((file) => {
            if (file.path !== targetFile.path) {
                newFiles.push(file);
            }
            return true;

        });
        setFiles(newFiles);
    };

    const renderFiles = files.map((file) => (
        <li key={file.path} style={{ margin: '5px' }}>
            <span>
                {file.path}
                {' '}
                -
                {file.size}
                {' '}
                bytes
            </span>
            <Button
                variant="danger"
                style={{
                    fontSize: '12px', padding: '0px', paddingLeft: '5px', paddingRight: '5px', marginLeft: '10px',
                }}
                onClick={() => removeFile(file)}
            >
                Remove
            </Button>
        </li>
    ));

    const uploadFiles = async (return_id) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('deductor_id', deductorId);
        formData.append('return_id', returnId);
        formData.append('created_at', moment(files[0].lastModified).format('YYYY-MM-DD'));

        let ddtZip = new JSZip();
        for (let file of files) {
            let filename = file.name;
            ddtZip.file(`${filename}`, file, { binary: true });
        }

        const ddtZipBlob = await ddtZip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions: { level: 0 } })
        formData.append('file', ddtZipBlob, "CorrectionReturnFile.zip");
        if (ddtZipBlob.size / (1024 * 1024) > MAX_IMPORT_FILE_SIZE_MB) {
            setLoading(false);
            return setError(`Max size of the file that can be uploaded is ${MAX_IMPORT_FILE_SIZE_MB} MB.`);
        }

        try {
            const result = await uploadManualConsoFile(formData);
            setSuccess(result.message)
            setTimeout(function () {
                history.push(`${BASE_PATH}correction-return-dashboard/${deductorId}/return/${returnId}?financial_year=${financialYear}&conso_status=1`);
            }, 1000)
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        const newFiles = acceptedFiles;
        const uniqueFiles = [];
        const flags = [];
        const l = newFiles.length;
        let i;
        for (i = 0; i < l; i++) {
            if (flags[newFiles[i].path]) continue;
            flags[newFiles[i].path] = true;
            uniqueFiles.push(newFiles[i]);
        }
        setFiles(uniqueFiles);
    }, [acceptedFiles]);

    return (
        <>


            {loading && <LoadingIndicator />}

            <Modal className="deductordashboadscss Createnewreturn_Q2browser_popup show" backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton className="Createnewreturn_Q2browser_header" style={{ padding: "5px 15px" }}>
                    <Modal.Title className="gst_verifyyremailid_title" >Import Conso File</Modal.Title>
                </Modal.Header>

                <Modal.Body className="consofileimport_return">
                    {error
                        ? (
                            <Alert variant="danger" style={{ fontSize: "12px" }} onClose={() => setError('')} dismissible>{error}</Alert>
                        )
                        : null}
                    {success
                        ? (
                            <Alert variant="success" style={{ fontSize: "12px" }} onClose={() => setSuccess('')}>{success}</Alert>
                        )
                        : null}
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-check-inline pb-3">
                                <label class="form-check-label labletextfs pr-2" for="check1">
                                    <input type="radio" class="form-check-input" checked={consoCheck === false ? true : false} style={{ verticalAlign: "sub" }} />
                                    I already have the Consolidated File available
                                </label>
                                <label class="form-check-label labletextfs" for="check1">
                                    <input type="radio" class="form-check-input" checked={consoCheck === true ? true : false}
                                        onChange={() => {
                                            setConsoCheck(true);
                                            if (returnId) {
                                                history.push(`${BASE_PATH}return-raise-request?deductor_id=${deductorId}&return_id=${returnId}&financial_year=${financialYear}`);
                                            }
                                        }}
                                        style={{ verticalAlign: "sub" }} />
                                    I don't have the Consolidated File available
                                </label>

                            </div>
                            <section  {...getRootProps({ className: 'Q2selct_importdropzone dropzone' })}>

                                <input {...getInputProps({ accept: '.tds', multiple: false })} />
                                <p class="clickhere_fs">Click here to Browse or Drag n Drop  the Consolidate File</p>
                            </section>


                            <aside>
                                <p class="fileselectedtextfs mb-1">Files</p>
                                <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
                            </aside>
                        </div>
                        <div class="col-lg-12 text-right">
                            <button class={`file_submitbtn ${files?.length === 0 ? "disabled" : ""}`} onClick={() => {
                                if (returnId && files?.length > 0) {
                                    uploadFiles();
                                }
                            }}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RaisedFileImportPopup