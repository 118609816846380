/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import EmailPdfList from './EmailPdfList';
import ExcelExportImport from './ExcelExportImport';
import { BASE_PATH } from '../../app/constants';
import './emailpdf.css';

const DownloadTdsTcsCertificate = ({ history, ...props }) => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const { email_type, form_type, quarter, year } = params;
    const { deductorId } = props.match.params;

    const handleSucessProcees = () => {
        setTimeout(() => window.location.reload(), 1000);
    };
    return (
        <>
            <div className="downloadtcs_certificate_parent">
            <div className="container">
                <div className="row mt-2">
                    <div className="col-md-3">
                        <span className="issuescon_backbtn cursor_pointer"
                            onClick={() => {
                                history.push(`${BASE_PATH}issue-tds-tcs-certificates/${deductorId}?form_type=${form_type}&quarter=${quarter}&year=${year}`)
                            }}>
                            &lt;Back
                        </span>
                    </div>
                    <div className="col-md-7">
                        <h4 className="issuestdstcscert_titlefs">Email PDF</h4>
                        <p className="suppsubtitlefs mb-1">Form Type: <strong>{form_type}</strong> |
                            F.Y: <strong>{`${year}-${String(Number(year) + 1).substr(2, 2)}`}</strong> |
                            Quarter: <strong>{quarter}</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card py-2 px-2 mt-2">
                            <div className="row mt-2">
                            
                             
                                <EmailPdfList
                                    formType={form_type ?? ""}
                                    deductorId={deductorId ?? ""}
                                    emailType={email_type ?? ""}
                                    quarter={quarter ?? ""}
                                    year={year ?? ""}
                                />
                                <ExcelExportImport
                                    formType={form_type ?? ""}
                                    deductorId={deductorId ?? ""}
                                    emailType={email_type ?? ""}
                                    quarter={quarter ?? ""}
                                    year={year ?? ""}
                                    handleSucessProcees={handleSucessProcees} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}

export default DownloadTdsTcsCertificate;
