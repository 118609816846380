/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './ConfirmPopup.css';
function ConfirmPopup(props) {
  const {
    show, handleClose, handleProceed, confirmText, confirmButtonText, confirmButtonText2, handleProceed2, showCancel = true, changeSecondBtnText, changeBtnColor
  } = props;

  return (
    <Modal className="areusuredelmbox" show={show} onHide={handleClose}>
      <Modal.Body><p className={changeSecondBtnText || changeBtnColor ? 'text-center' : ''}>{confirmText ?? 'Are you sure that you want to delete the Challan ? Please note that the process is irreversible. Click Delete button to continue.'}</p></Modal.Body>
      <Modal.Footer className="areusuredelmboxF">
        {showCancel &&
          <Button className="btnfscof cancelbtnbgclr" variant="secondary" onClick={handleClose}>
            {changeSecondBtnText ? changeSecondBtnText : "Cancel"}
          </Button>
        }
        <Button className={`${changeBtnColor ? "cancelbtnbgclr_coral" : "btnfscof"}`} variant="danger" onClick={handleProceed}>
          {confirmButtonText ?? 'Confirm'}
        </Button>
        {confirmButtonText2 ?
          <Button className="btnfscof" variant="danger" onClick={handleProceed2}>
            {confirmButtonText2 ?? 'Confirm'}
          </Button> : null}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmPopup;
