import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getTracesProcessStatusFromConnector } from '../../../api/localConnectorAPI';


export default function ProgressBarForBulkPan(props) {

    const { bulkPanVerificationStatusData, statusKey, setReloadPanList } = props;
    const [data, setData] = useState(null);
    const timer = useRef(null);
    
    //Get port and captcha ref from local storage
    const port = parseInt(bulkPanVerificationStatusData?.port ?? 0);
    const captcha_ref = bulkPanVerificationStatusData?.captcha_ref ?? null;

    useEffect(() => {

        // Run a continuous loop every 2 seconds
        const continueCheckStatusWhenFoundInProgress = async (port, captcha_ref, statusKey) => {
            try {
                const statusResult = await getTracesProcessStatusFromConnector(port, `?captcha_ref=${captcha_ref}`);
                if (parseInt(statusResult.status) === 1) {
                    setData(statusResult?.data?.summary ?? []);
                    if ((statusResult?.data?.action_status ?? null) === 'IN_PROGRESS') {
                        timer.current = setTimeout(() => continueCheckStatusWhenFoundInProgress(port, captcha_ref, statusKey), 2000);
                        return;
                    }else if((statusResult?.data?.action_status ?? null) === 'COMPLETE'){
                        //process completed, now reload page
                        setReloadPanList(true);
                    }else{
                        localStorage.removeItem(statusKey);
                        setReloadPanList(true);       
                    }
                }
            } catch (err) {
                console.error('Bulk Pan Verificaiton Status', err)
                
            }

            // Finally, remove status key from storage
            localStorage.removeItem(statusKey);
        }

        if (port && captcha_ref) {
            continueCheckStatusWhenFoundInProgress(port, captcha_ref, statusKey);
        }

        // Clear timeout on unmount
        return () => clearTimeout(timer.current);

    }, [port, captcha_ref, statusKey, setReloadPanList]);


    const getPercentData = (data) => {
        
        const total = parseInt(data?.['total'] ?? 0);
        const unattempted = parseInt(data?.['unattempted'] ?? 0);

        if (parseInt(total) === 0) return 0;

        const percent = parseInt(((total - unattempted) / total) * 100);

        if (percent === 100) {
            //Reload deductee listing for latest pan status data
            setTimeout(() => setReloadPanList(true), 3000);
        }

        return percent;

    }

    return (
        <ProgressBar animated now={getPercentData(data)} label={'Bulk PAN Verification Status: ' + getPercentData(data) + ' %'} />
    )
}