import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { getAllActivePlans, submitPlanEnquiry, getSharedPlandataOnServer } from '../../api/profileAPI';
import PlanTable from './PlanTable';
import Billing from '../billing/Billing';
// import { GoogleAnalytics } from '../../utils/UtilityFunctions';
import { BASE_PATH } from '../../app/constants';
import PlanInquiryPopup from './PlanInquiryPopup';
import ShowAlert from '../../components/ShowAlert';
import './Plans.css';
import TentativePayment from './TentativePayment';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { useCallback } from 'react';
import TabbedPlanTable from './TabbedPlanTable';


const Plans = ({ history }) => {
    const defaultdata = { subject: '', subjectError: '', body: '', bodyError: '' };
    const defaultHeading = 'Plans';
    const location = useLocation();
    const params = queryString.parse(location.search);
    const changePlanStatus = parseInt(params?.change_plan ?? 0);
    const [heading, setHeading] = useState(defaultHeading);
    const [plans, setPlans] = useState([]);
    const [isPlansLoading, setIsPlansLoading] = useState(true);
    const [showPlanBilling, setShowPlanBilling] = useState(false);
    const [showPaymentmode, setShowPaymentmode] = useState(false)
    const [visible, setVisible] = useState(false)
    const [mailMessage, setMailmessage] = useState(defaultdata)
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const [sharenplanExpire, setSharePlanExpire] = useState(false);
    const [allowOtherPaymentmode, setAllowOtherPaymentmode] = useState('')
    const [billingDetails, setBillingDetails] = React.useState({
        tenant_address: {
            address_detail: '',
            city: '',
            company_gst_number: '',
            company_name: '',
            pincode: '',
            state_id: '',
        },
        tenant_contact_person: {
            email: '',
            full_name: '',
            is_active: false,
            mobile_no: '',
        },
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMailmessage((prev) => {
            prev[name] = value;
            if (prev[name]) {
                prev[`${name}Error`] = ""
            }
            return { ...prev };
        })

    }

    const submitPlanEnquiryOnServer = () => {
        async function mailEnquiryfunction() {
            const data = {
                subject: mailMessage.subject,
                body: mailMessage.body
            }
            try {
                const response = await submitPlanEnquiry(data);
                if (response.status === 1) {
                    setSuccess('We have received your request. Someone from our Sales Team will get in touch with you shortly.')
                }

            } catch (err) {
                setError(err)
            } finally {
                setLoading(false)
                setVisible(false)
                setMailmessage({ ...defaultdata });
            }
        }

        if (mailMessage.subject && mailMessage.body) {
            setLoading(true);
            mailEnquiryfunction();
        } else if (!mailMessage.subject || !mailMessage.body) {
            const error = JSON.parse(JSON.stringify(mailMessage));
            if (!mailMessage.subject) error.subjectError = 'Subject is required';
            if (!mailMessage.body) error.bodyError = 'Message is required'
            setMailmessage({ ...error })
        }
    }

    const getSharedPlanData = useCallback(async (spi) => {

        try {
            const result = await getSharedPlandataOnServer(spi);
            let new_billingDetails = { ...billingDetails };
            if (result.data.mobile_no) { new_billingDetails.tenant_contact_person.mobile_no = result.data.mobile_no }
            if (result.data.email) { new_billingDetails.tenant_contact_person.email = result.data.email }
            if (result.data.tenant_address) { new_billingDetails.tenant_address = result.data.tenant_address }
            if (result.data.tenant_contact_person) { new_billingDetails.tenant_contact_person = result.data.tenant_contact_person }
            setBillingDetails(new_billingDetails);

            const summary = {};
            summary['plan_name'] = result?.data?.plan_details?.plan_name ?? '';
            summary['plan_amt'] = result?.data?.plan_details?.plan_amt ?? '';
            summary['discount_amt'] = result?.data?.plan_details?.discount_amt ?? '';
            summary['tax_rate'] = result?.data?.plan_details?.tax_rate ?? '';
            summary['tax_amt'] = result?.data?.plan_details?.tax_amt ?? '';
            summary['payment_amt'] = result?.data?.plan_details?.payment_amt ?? '';
            summary['plan_id'] = result?.data?.plan_details?.plan_id ?? '';
            summary['plan_version_id'] = result?.data?.plan_details?.plan_version_id ?? ''
            setShowPlanBilling({ ...summary });
            const paymentmodekey = result?.data?.plan_details?.allow_other_payment_mode !== 0 ? result?.data?.plan_details?.allow_other_payment_mode : '';
            setAllowOtherPaymentmode(paymentmodekey);
        } catch (err) {
            setSharePlanExpire(err.message);
        } finally {
            setLoading(false);
        }
        //eslint-disable-next-line
    }, []);

    // React.useEffect(() => {
    //     GoogleAnalytics();
    // }, []);

    React.useEffect(() => {
        const getPlansData = async () => {
            setIsPlansLoading(true);
            try {
                const result = await getAllActivePlans(changePlanStatus);
                setPlans(result?.data?.list ?? []);
                setHeading(result?.data?.heading ?? defaultHeading);
            } catch (err) {
                // console.error('Error in getting plans: ', err);
            }
            setIsPlansLoading(false);
        };

        if (params.spi) {
            setLoading(true);
            getSharedPlanData(params.spi);
        } else {
            getPlansData();
        }
    }, [params.spi, changePlanStatus, getSharedPlanData]);

    return (
        <>
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />
            {loading && <LoadingIndicator />}
            <PlanInquiryPopup
                show={visible}
                handleClose={() => setVisible(false)}
                setVisible={setVisible}
                mailMessage={mailMessage}
                setMailmessage={setMailmessage}
                handleChange={handleChange}
                submitPlanEnquiryOnServer={submitPlanEnquiryOnServer}
            />
            <div className="container-fluid" style={{ maxWidth: '1280px' }}>
                {
                    !showPlanBilling && !params.spi ?
                        <>
                            <div className="row mt-2">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <Link to={`${BASE_PATH}`} className="backtoclr"> &lt;  Back to Deductor List</Link>
                                </div>
                                {plans?.[0]?.sub_product && <h2 className="text-center corportplanfs">{heading ?? "Plans"}</h2>}


                            </div>
                            {
                                plans?.[0]?.sub_product ?
                                    <TabbedPlanTable plans={plans} isPlansLoading={isPlansLoading} heading={heading} history={history} setVisible={setVisible} setShowPlanBilling={setShowPlanBilling} />
                                    : <PlanTable plans={plans} isPlansLoading={isPlansLoading} heading={heading} history={history} setVisible={setVisible} setShowPlanBilling={setShowPlanBilling} />
                            }
                        </>
                        :
                        null
                }


                {showPlanBilling && !sharenplanExpire && !showPaymentmode ?
                    <>
                        <Billing planData={showPlanBilling} allowOtherPaymentmode={allowOtherPaymentmode} setShowPaymentmode={setShowPaymentmode} setBillingDetails={setBillingDetails} billingDetails={billingDetails} sharedplanid={params.spi} history={history} setShowPlanBilling={setShowPlanBilling} />
                    </>
                    : null

                }

                {!showPlanBilling && sharenplanExpire &&
                    <div className="row mt-5">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <Link to={`${BASE_PATH}`} className="backtoclr"> &lt;  Back to Deductor List</Link>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>

                            <FontAwesomeIcon style={{ fontSize: '170px', color: 'red' }} icon={faExclamationTriangle} />

                            <div style={{ marginTop: '39px' }}>
                                <h3>{sharenplanExpire}</h3>
                            </div>
                        </div>
                    </div>
                }

                {showPaymentmode && params.spi ?
                    <>
                        <div className="row mt-5">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 backtoclr" style={{ cursor: 'pointer' }} onClick={() => [setShowPaymentmode(false)]}>
                                &lt;  Back
                            </div>

                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-12">
                                <h2 className="text-center corportplanfs">Payment Made Through Other Modes</h2>
                            </div>
                        </div>
                        <TentativePayment history={history} sharedplanid={params.spi} showPaymentmode={showPaymentmode} setSuccess={setSuccess} setError={setError} />
                    </> : null
                }




            </div>
        </>
    );
};
export default Plans;
