/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';
import { initialHeaderData } from '../../app/constants';

export const headerSlice = createSlice({
  name: 'header',
  initialState: initialHeaderData,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state[key] = value;
    },
    setHeader: (state, action) => {
      Object.keys(action.payload).map((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { setFieldValue, setHeader } = headerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectHeaderData = state => state.header;

export default headerSlice.reducer;
