import React, { useEffect, useState } from "react";
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { deepClone, formatNumber } from "../../../utils/UtilityFunctions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@progress/kendo-react-tooltip';

const saveSummaryDefault = {
    interest_liability: { total: 0, less_tds: 0, less_interest: 0, balance: 0 },
    fee_liability: { total: 0, less_tds: 0, less_fee: 0, balance: 0 },
}
const SaveAllocationDataPopup = (props) => {

    const { onClose, handleProceed, data, lateLiabilitySummary, relookButton } = props;

    const [saveSummary, setSaveSmmary] = useState(deepClone(saveSummaryDefault));

    const calculateSummary = (data) => {

        const summaryObj = deepClone(saveSummaryDefault);

        summaryObj.interest_liability.total = lateLiabilitySummary?.total_int_amtp ?? 0;
        summaryObj.fee_liability.total = lateLiabilitySummary?.total_fee_amtp ?? 0;

        if (data.length > 0) {
            data.forEach((item) => {

                //Allocated Interest
                summaryObj.interest_liability.less_interest += (Number(item?.allocated_int_amtp ?? 0) - Number(item?.reduce_unconsumed_tds_for_interest_amtp ?? 0));
                //Used TDS in Interest Allocation
                summaryObj.interest_liability.less_tds += Number(item?.reduce_unconsumed_tds_for_interest_amtp ?? 0);

                //Allocated Fee 
                //summaryObj.fee_liability.less_fee += Number(item?.allocated_fee_amtp ?? 0);
                //Used TDS in Fee Allocation
                //summaryObj.fee_liability.less_tds += Number(item?.reduce_unconsumed_tds_for_fee_amtp ?? 0);
            })
        }

        summaryObj.interest_liability.balance = Number(summaryObj.interest_liability.total) - Number(summaryObj.interest_liability.less_interest) - Number(summaryObj.interest_liability.less_tds);
        //summaryObj.fee_liability.balance = Number(summaryObj.fee_liability.total) - Number(summaryObj.fee_liability.less_fee);

        setSaveSmmary((prev) => {
            prev = summaryObj
            return { ...prev }
        })
    }

    useEffect(() => {
        calculateSummary(data);
        // eslint-disable-next-line
    }, [data])



    return (
        <>
            <WindowWrapperFixed
                title={
                    <h4 className="modal-title w-100">
                        <div className="row col-md-12">
                            Save Allocation Data
                        </div>
                    </h4>
                }
                onClose={(errorChallanId) => {
                    onClose(errorChallanId);
                }}
                totalPopups={1}
                index={0}
                isModalView={true}
                initialWidth={600}
                initialHeight={'auto'}
                initialLeft={500}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            
                                <table className="table table-striped interestall_proceed_modal_tb mb-0">

                                    <tbody>
                                        <tr className="">
                                            <td width="30" className="pr-0">

                                            </td>
                                            <td width="450" className="text-left">
                                                Total Interest Liability
                                            </td>
                                            <td width="120" className="text-right">
                                                {formatNumber(saveSummary?.interest_liability?.total ?? 0)}
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="pr-0">
                                                Less:
                                            </td>
                                            <td className="text-left">
                                                Allocated out of Interest component
                                            </td>
                                            <td className="text-right">
                                                {formatNumber(saveSummary?.interest_liability?.less_interest ?? 0)}
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="pr-0">
                                                Balance:
                                            </td>
                                            <td className="text-left">                                                
                                                <Tooltip anchorElement="pointer" parentTitle={true} position="top">
                                                Allocated out of TDS component
                                                {' '}
                                                <span data-toggle="tooltip" data-placement="top" title="Once allocated from TDS, components are permanently assigned, ensuring efficient utilization."> 
                                                <FontAwesomeIcon icon={faInfoCircle} style={{color: '#2f709a'}} />
                                                </span>
                                                </Tooltip>
                                            </td>
                                            <td className="text-right">
                                                {formatNumber(Number(saveSummary?.interest_liability?.less_tds ?? 0))}
                                                {/* {Number(saveSummary?.interest_liability?.balance ?? 0) > 0 ? formatNumber(Number(saveSummary?.interest_liability?.balance)) : '0.00'} */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>

                            {/*<div className="pt-1">
                                <div className="intallocaT_hrlinebox">
                                    <hr className="intallocaT_hrlineclr" />
                                </div>
                            </div>
                             <div className="pt-1">
                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                    <table className="table table-striped interestall_proceed_modal_tb mb-0">

                                        <tbody>
                                            <tr className="">
                                                <td width="30" className="pr-0">

                                                </td>
                                                <td width="450" className="text-left">
                                                    Total Fee Liability
                                                </td>
                                                <td width="120" className="text-right">
                                                    {formatNumber(saveSummary?.fee_liability?.total ?? 0)}
                                                </td>
                                            </tr>
                                            <tr className="">
                                                <td className="pr-0">
                                                    Less:
                                                </td>
                                                <td className="text-left">
                                                    The allocated out of Fee component
                                                </td>
                                                <td className="text-right">
                                                    {formatNumber(saveSummary?.fee_liability?.less_fee ?? 0)}
                                                </td>
                                            </tr>
                                            <tr className="">
                                                <td className="pr-0">
                                                    Balance:
                                                </td>
                                                <td className="text-left">
                                                    Allocated out of TDS component
                                                </td>
                                                <td className="text-right">
                                                {formatNumber(Number(saveSummary?.fee_liability?.less_tds ?? 0))}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> */}

                            <div className="text-center pt-2 mb-0">
                                <button className="btn btn-defualt Interest_fees_amt_proceedbtnbgclr mr-2" onClick={() => handleProceed()}>Proceed </button>
                                <button className="btn btn-defualt Interest_fees_amt_relookbtn" onClick={() => relookButton()}>
                                    I want to Relook
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            </WindowWrapperFixed>
        </>
    );
}
export default SaveAllocationDataPopup;