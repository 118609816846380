import React, { useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import "./ChallanStatusList.scss";
import { BASE_PATH } from '../../../app/constants';
import { useEffect } from 'react';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../../components/ShowAlert';
import { gocallApi } from '../../../api/issueCertificateAPI';
import { formatNumber } from '../../../utils/UtilityFunctions';
import MappedTransPopup from './MappedTransPopup';
import { getNatureOfPaymentCodes } from '../../../api/challanAPI';
import { deepClone } from '../../../utils/UtilityFunctions';
import { process } from "@progress/kendo-data-query";
import moment from 'moment';

const ChallanStatusList = () => {

    const challansTitle = {
        verified: "List of Verified Challans",
        unverified: "List of UnVerified Challans",
        unconsumed: "List of Unconsumed Challans"
    }
    const location = useLocation();
    // const searchStr = location.search;
    const params = queryString.parse(location.search);

    const PathParams = useParams();
    const ChallansStatus = PathParams.status ?? "";
    const finanicalYear = params.financial_year ? parseInt(params.financial_year) : null

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [intialData, setIntialData] = useState([]);
    const [dataState, setDataState] = useState({});
    const [mapTransList, setMapTransList] = useState(null);
    const [paymentCodes, setPaymentCodes] = useState([]);
    const [summary, setSummary] = useState({});

    const dataStateChange = (data_state) => {
        //setDataState(data_state);        
        if (data_state.filter || (data_state.sort && data_state?.sort?.length > 0)) {
            const filter_state = deepClone(data_state);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                             if (["gte", "lte", 'lt', 'gt', "neq", "eq"].includes(itemObj?.operator) && !Array.isArray(itemObj.value)) {
                                
                                if(itemObj.field === "deposit_date"){
                                    const date = moment(itemObj.value).format('DD/MM/YYYY');
                                    itemObj.value = moment(date, 'DD/MM/YYYY').format();
                                }else{
                                    itemObj.value = Number(itemObj.value);

                                }
                            }    
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                if(itemObj.field !== "deposit_date"){                           
                                    itemObj.value = itemObj.value?.[0] ?? ""; 
                                }
                                                              
                            }else  if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                let array = []
                                item.logic = "or"
                                if (itemObj?.value?.length > 0) {
                                    itemObj?.value.forEach((keyValue) => {
                                        if(keyValue === 'NA') {
                                            array.push({ field: itemObj.field, operator: 'isNull', value: null })
                                        }else{
                                            array.push({ field: itemObj.field, operator: 'eq', value: Number(keyValue) })
                                        }                                        
                                    })
                                    item.filters = array;
                                }
                            }                            
                        })
                    }
                })
            }
            const dataProcess = process(intialData, filter_state);
            setDataState({ ...data_state });
            setData(dataProcess.data ?? []);

        } else {
            const defaultData = JSON.parse(JSON.stringify(intialData));
            setData([...defaultData]);
            setDataState({});
        }     

    }

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem ?? {};
        const dataIndex = props.dataIndex ?? null;
        return (
            <tr key={dataItem.challan_id ?? dataIndex}>
                <td className="text-center" style={{ paddingRight: "31px" }}>{dataItem.challan_no ? dataItem.challan_no : "-"}</td>
                <td className="text-center" style={{ paddingRight: "31px" }}>{dataItem.bsr_code ? dataItem.bsr_code : "-"}</td>
                <td className="text-center" style={{ paddingRight: "31px" }}>
                    {dataItem?.deposit_date ? moment(dataItem.deposit_date).format('DD/MM/YYYY') : ""}
                </td>
                <td className="text-center" style={{ paddingRight: "31px" }}>{dataItem.nature_of_payment ? dataItem.nature_of_payment : "-"}</td>
                <td className={`text-right ${ChallansStatus === "verified" || ChallansStatus === "unverified" ? "activeverify_colbgclr_lyell" : ""}`}>{formatNumber(dataItem.total_amtp ?? "0")}</td>
                <td className='text-right'>{formatNumber(dataItem.traces_unconsumed_amtp ?? "0")}</td>
                <td className={`text-right ${ChallansStatus === "unconsumed" ? "activeverify_colbgclr_lyell" : ""}`}>{formatNumber(dataItem.total_remaining_amtp ?? "0")}</td>
                <td
                    className="text-center challanno_textclr_blurundline" style={{ paddingRight: "31px" }}
                    onClick={() => {
                        const obj = {
                            challan_no: dataItem.challan_no ?? "",
                            deposit_date: dataItem.deposit_date ? moment(dataItem.deposit_date).format('DD/MM/YYYY') : "",
                            bsr_code: dataItem.bsr_code ?? "",
                            challan_id: dataItem.challan_id ?? "",
                            deductor_id: params?.deductor_id ?? "",
                            total_amtp: dataItem?.total_amtp ?? ""
                        }
                        const checkKey = (array, keyName) => {
                            let isUnique = true;

                            for (let item of array) {
                                if (item.challan_no === keyName) {
                                    isUnique = false;
                                    break;
                                }
                            }
                            return isUnique;
                        };

                        if (mapTransList?.length > 0 && checkKey(mapTransList, dataItem.challan_no ?? "")) {
                            setMapTransList((prev) => {
                                if (prev?.length > 1) prev.splice(0, 1);
                                prev.push(obj);
                                return [...prev];
                            })
                        } else if (mapTransList?.length === 0 || !mapTransList) {
                            setMapTransList([obj]);
                        }

                    }}
                >{dataItem.total_mapped_transactions ?? "-"}</td>
            </tr>
        )

    }

    const columnTitle = (title, subText) => {
        return (<div>
            {title}
            <div className={`text-right challanstitlepos`} >{subText && <span>{`${formatNumber(subText ?? 0)}`}</span>}</div>
        </div>)
    };

    const columnTrans = (title, subText) => {
        return (<div>
            {title}
            <div className={`text-center challanstitle_notformat`} style={{marginLeft: `${(title.length) * 2.7}px`}} >{subText && <span>{`${subText}`}</span>}</div>
        </div>)
    }

    
    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const getChallansListOnServer = async (deductor_id, financial_year, status, data_state) => {
        const url = `/api/v1/challan/list`;
        const challanStatus = (status === "verified" ? ["MATCHED"] : status === "unverified" ? ["UNMATCHED","UNVERIFIED"] : "");
        const filtersParam = [{
            "logic": "and", "filters": [
                { "field": "deposit_date", "operator": "gte", "value": `${financial_year}-04-01` },
                { "field": "deposit_date", "operator": "lte", "value": `${parseInt(financial_year) + 1}-03-31` }
            ]
        }];

        if (data_state?.filter?.filters?.length > 0) {
            const filters = data_state?.filter?.filters;
            filters.forEach((item) => {
                if (item?.filters?.[0]?.field === "deposit_date") {
                    filtersParam[0].filters[0] = item?.filters?.[0];
                }
                if (item?.filters?.[1]?.field === "deposit_date") {
                    filtersParam[0].filters[1] = item?.filters?.[1];
                } else {
                    filtersParam.push(item);
                }
            })
        }

        const payload = {
            deductor_id: deductor_id ?? "",
            limit: 15,
            sort_by: data_state?.sort?.[0]?.dir ?? "",
            sort_on: data_state?.sort?.[0]?.field ?? "",
            filter_status: challanStatus ? challanStatus : [],
            show_all_unconsumed: status === "unconsumed" ? true : false,
            show_all_challan: status !== "unconsumed" ? true : false,
            filters: filtersParam,
            from_deposit_date: `${financial_year}-04-01`,
            to_deposit_date: `${parseInt(financial_year) + 1}-03-31`
        }


        setLoading(true);
        try {
            const result = await gocallApi("post", url, payload);
            if(result?.data?.data?.length > 0){
                result.data.data.forEach((item) => {
                  item.deposit_date = moment(item.deposit_date, "DD/MM/YYYY").format();
                })
            }
            setData(result?.data?.data ?? []);
            
            setIntialData((prev) => {
                prev = deepClone(result?.data?.data ?? []);
                return [...prev];
            })

            setSummary(result?.data?.summary ?? {});
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false);
        }
    }

    const fetchPaymentCodesFunc = (params) => {
        async function fetchEverything() {
            async function fetchPaymentCodes() {
                const result = await getNatureOfPaymentCodes(params);
                const paymentCodeData = [];
                const payDataKey = {};
                result?.data?.map((paymentCode) => {
                    paymentCodeData.push({
                        label: `${paymentCode.code} - ${paymentCode.description}`,
                        value: paymentCode.nature_of_payment_id,
                    }); 
                    payDataKey[paymentCode.code] = paymentCode;
                    return true;
                });
                paymentCodeData.push({
                    label: 'NA',
                    value: 'NA',
                })
                setPaymentCodes(paymentCodeData);
            }
            try {
                await Promise.all([
                    fetchPaymentCodes(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };

    useEffect(() => {
        if (params.deductor_id && params.financial_year) {
            getChallansListOnServer(params.deductor_id, params.financial_year, PathParams.status, dataState)
        }
        // eslint-disable-next-line
    }, [params.deductor_id, params.financial_year, PathParams.status]);

    useEffect(() => {
        fetchPaymentCodesFunc()
    }, [])


    return (
        <>
            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />           

            {loading && <LoadingIndicator />}
            {mapTransList?.length > 0 &&
                mapTransList.map((item, index) => {
                    return (<MappedTransPopup
                        show={true}
                        showData={item}
                        handleClose={() => {
                            setMapTransList((prev) => {
                                if (prev?.length === 1) return null;
                                if (prev?.length > 1) prev.splice(0, 1);
                                return [...prev];
                            })
                        }}
                        financial_year={finanicalYear}
                        paymentCodes={paymentCodes}
                        totalpopups={index + 1}
                        index={index}
                    />)
                })

            }

            <div class="chanllans_status_list container-fluid">

                <div class="row mt-2">
                    <div class="col-md-5">
                        <Link class="dash_totalverchall_backtextfs" to={`${BASE_PATH}deductor?deductor_id=${params.deductor_id}&financial_year=${finanicalYear}`} >&lt; Back to Dashboard</Link>
                    </div>
                    <div class="col-md-7">
                        <h4 class="dash_totalverchall_textfs">{challansTitle?.[ChallansStatus] ?? ""}</h4>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class={`table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs ${data.length > 20 ? "header_padenable" : ""}`}>
                                <Tooltip anchorElement="pointer" position="right">
                                    <Grid
                                        className="table table-striped blukimport_tb mb-0 scrollvisible"
                                        data={data}
                                        {...dataState}
                                        sortable
                                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                                        filterOperators={{
                                            text: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            ],
                                            dropdown: [
                                                { text: 'grid.filterEqOperator', operator: 'in' },
                                            ],
                                            numeric: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                            ],
                                            textWithEmpty: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                            ],
                                            date: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                            ],
                                        }}
                                        rowRender={rowRender}
                                    >
                                        <Column
                                            width="95"
                                            field="challan_no"
                                            title={columnTrans("Challan No", summary?.total_challan)}
                                            filter="text"
                                            columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                            headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                        />
                                        <Column
                                            width="80"
                                            field="bsr_code"
                                            title={"BSR"}
                                            filter="text"
                                            columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                            headerClassName={isColumnActive('bsr_code') ? 'active' : ''}
                                        />

                                        <Column
                                            width="90"
                                            field="deposit_date"
                                            title={"Deposit Date"}
                                            filter="date"
                                            columnMenu={dataState && ((props) => <ColumnMenu  {...props} />)}
                                            headerClassName={isColumnActive('deposit_date') ? 'active' : ''}

                                        />

                                        <Column
                                            width="80"
                                            field="nature_of_payment_id"
                                            title="Section"
                                            filter="dropdown"
                                            columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter filterList={paymentCodes} {...props} />)}
                                            headerClassName={isColumnActive('nature_of_payment_id') ? 'active' : ''}
                                        />


                                        <Column
                                            width="100"
                                            field="total_amtp"
                                            title={columnTitle("Total Challan Amt", summary?.total_challan_amtp)}
                                            filter="numeric"
                                            columnMenu={dataState && ((props) => <ColumnMenu {...props} />)}
                                            headerClassName={`${isColumnActive('total_amtp') ? 'active' : ''}${ChallansStatus === "verified" || ChallansStatus === "unverified" ? " activeverify_colbgclr_lyell" : ""}`}

                                        />

                                        <Column
                                            width="100"
                                            field="traces_unconsumed_amtp"
                                            title={columnTitle("TRACES Unconsumed", summary?.total_traces_unconsumed_amtp)}
                                            filter="numeric"
                                            columnMenu={dataState && ((props) => <ColumnMenu  {...props} />)}
                                            headerClassName={`${isColumnActive('traces_unconsumed_amtp') ? 'active' : ''}`}
                                        />
                                        <Column
                                            width="100"
                                            field="total_remaining_amtp"
                                            title={columnTitle("Remaining balance", summary?.total_remaining_bal_amtp)}
                                            filter="numeric"
                                            columnMenu={dataState && ((props) => <ColumnMenu  {...props} />)}
                                            headerClassName={`${isColumnActive('total_remaining_amtp') ? 'active' : ''}${ChallansStatus === "unconsumed" ? " activeverify_colbgclr_lyell" : ""}`}
                                        />
                                        <Column
                                            width="100"
                                            filter="numeric"
                                            title={columnTrans("Mapped Trans.", summary?.total_mapped_transactions)}
                                            field="total_mapped_transactions"
                                            columnMenu={dataState && ((props) => <ColumnMenu  {...props} />)}
                                            headerClassName={isColumnActive('total_mapped_transactions') ? 'active' : ''}
                                        />
                                        <GridNoRecords>
                                            No Record Found
                                        </GridNoRecords>
                                    </Grid>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChallanStatusList