import React, { useEffect, useState } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone, formatAccountsNumber } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";
import moment from "moment";


const IntLatePaymentTable2 = (props) => {

    const { apiData, formType } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);


    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {                
                newData.push({
                    s_no: item[0],
                    cd_no: item[1],
                    dd_no: item[2],
                    ded_ref_num: item[3],
                    pan: item[4],
                    name: item[5],
                    amt_paid: item[6],
                    tds_ded: item[7],
                    tds_dep: item[8],
                    tds_credit: item[9],
                    deduction_date: moment(item[10], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    due_date: moment(item[11], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    oltas_date: moment(item[12], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    delay_period: item[13],
                    int_late_amt: item[14],
                })
            })
            return newData
        }
    };

    const dataStateChange = (data_state) => {
        if (data_state.filter || (data_state.sort && data_state?.sort?.length > 0)) {
            const filter_state = deepClone(data_state);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                             if (["gte", "lte", 'lt', 'gt', "neq", "eq"].includes(itemObj?.operator) && !Array.isArray(itemObj.value)) {
                                
                                if(["deduction_date", "due_date", "oltas_date"].includes(itemObj.field)){
                                    const date = moment(itemObj.value).format('DD/MM/YYYY');
                                    itemObj.value = moment(date, 'DD/MM/YYYY').format();
                                }else{
                                    itemObj.value = Number(itemObj.value);

                                }
                            }    
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                if(!["deduction_date", "due_date", "oltas_date"].includes(itemObj.field)){                           
                                    itemObj.value = itemObj.value?.[0] ?? ""; 
                                }
                                                              
                            }else  if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                let array = []
                                item.logic = "or"
                                if (itemObj?.value?.length > 0) {
                                    itemObj?.value.forEach((keyValue) => {
                                        if(keyValue === 'NA') {
                                            array.push({ field: itemObj.field, operator: 'isNull', value: null })
                                        }else{
                                            array.push({ field: itemObj.field, operator: 'eq', value: Number(keyValue) })
                                        }                                        
                                    })
                                    item.filters = array;
                                }
                            }                            
                        })
                    }
                })
            }
            const dataProcess = process(inititalData, filter_state);
            setDataState({ ...data_state });
            setData(dataProcess.data ?? []);

        } else {
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData([...defaultData]);
            setDataState({});
        }     

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const renderSubTextTitle = (title, subText) => {
        
        return (
            <div style={{whiteSpace: 'break-spaces'}}>
                {title}
                {subText && (<div className="text-center">
                    {' '}<span className="b2btbtrthtextclr" style={{ paddingLeft: '20px' }}>{subText ?? 0}</span>
                </div>)}
            </div>
        );
    };

    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;

        return (
            <tr key={key} role="row">
                <td className="text-center">{props?.dataItem?.s_no}</td>
                <td className="text-center">{props?.dataItem?.cd_no ?? "-"}</td>
                <td className="text-center">{props?.dataItem?.dd_no ?? "-"}</td>
                <td className="text-center">{props?.dataItem?.ded_ref_num ?? "-"}</td>
                <td className="text-center">{props?.dataItem?.pan ?? "-"}</td>
                <td className="text-left">{props?.dataItem?.name ?? "-"}</td>
                <td className="text-right">{formatAccountsNumber(props?.dataItem?.amt_paid ?? 0) ?? "-"}</td>
                <td className="text-right">{formatAccountsNumber(props?.dataItem?.tds_ded ?? 0) ?? "-"}</td>
                <td className="text-right notices_orders_rowbgclr_yell">{formatAccountsNumber(props?.dataItem?.tds_dep ?? 0) ?? "-"}</td>
                <td className="text-right notices_orders_rowbgclr_yell">{formatAccountsNumber(props?.dataItem?.tds_credit ?? 0) ?? "-"}</td>
                <td className="text-center notices_orders_rowbgclr_grey">{moment(props?.dataItem?.deduction_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(props?.dataItem?.deduction_date).format("DD/MM/YYYY") ?? "-"}</td>
                <td className="text-center notices_orders_rowbgclr_grey">{moment(props?.dataItem?.due_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(props?.dataItem?.due_date).format("DD/MM/YYYY") ?? "-"}</td>
                <td className="text-center notices_orders_rowbgclr_grey">{moment(props?.dataItem?.oltas_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(props?.dataItem?.oltas_date).format("DD/MM/YYYY") ?? "-"}</td>
                <td className="text-center notices_orders_rowbgclr_grey">{props?.dataItem?.delay_period ?? "-"}</td>
                <td className="text-right notices_orders_rowbgclr_grey">{formatAccountsNumber(props?.dataItem?.int_late_amt ?? 0) ?? "-"}</td>                
            </tr>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);


    return (
        <fieldset className="exptds_justformtype_09fs">
            <legend className="exptds_justformtype_09leg">

            </legend>

            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                <Tooltip anchorElement="pointer" position="top">
                    <Grid
                        className="table table-striped exptds_justformtype_table mb-0"
                        style={{ maxHeight: '530px', overflowX: 'auto' }}
                        data={data}
                        {...dataState}

                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                        filterOperators={{
                            numeric: [
                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                            ],
                            date: [
                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                { text: 'grid.filterEqOperator', operator: 'eq' },
                            ],
                            textWithEmpty: [
                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                            ],
                            pan: [
                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                { text: 'grid.filterContainsOperator', operator: 'in' },
                            ],
                        }}
                        rowRender={rowRender}
                        sortable
                    >
                        <Column>
                            <Column>
                                <Column
                                    field="s_no"
                                    title={renderSubTextTitle('Sr. No.')}
                                    width="50"
                                />
                            </Column>
                        </Column>

                        <Column title="Data From Statement" headerClassName={'sorting_disabled'}>

                            <Column>
                                <Column
                                    field="cd_no"
                                    headerClassName={isColumnActive('cd_no') ? 'active' : ''}
                                    title={renderSubTextTitle('CD Serial No.')}
                                    width="130"
                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                    filter="textWithEmpty"
                                />
                                <Column
                                    field="dd_no"
                                    headerClassName={isColumnActive('dd_no') ? 'active' : ''}
                                    title={renderSubTextTitle('DD Serial No.')}
                                    width="130"
                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                    filter="textWithEmpty"
                                />
                                <Column
                                    field="ded_ref_num"
                                    headerClassName={isColumnActive('ded_ref_num') ? 'active' : ''}
                                    title={renderSubTextTitle(`${formType === "24Q" ? "Employee" : "Deductee"} Reference Number`)}
                                    width="130"
                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                    filter="textWithEmpty"
                                />
                                <Column
                                    field="pan"
                                    headerClassName={isColumnActive('pan') ? 'active' : ''}
                                    title={renderSubTextTitle(`PAN of \n ${formType === "24Q" ? "Employee" : "Deductee"}`)}
                                    width="140"
                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                    filter="pan"
                                />
                                <Column
                                    field="name"
                                    headerClassName={isColumnActive('name') ? 'active' : ''}
                                    title={renderSubTextTitle(`Name of  ${formType === "24Q" ? "Employee" : "Deductee"}`)}
                                    width="150"
                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                    filter="textWithEmpty"
                                />
                                <Column
                                    field="amt_paid"
                                    headerClassName={isColumnActive('amt_paid') ? 'active' : ''}
                                    title={renderSubTextTitle('Amount Paid / Credited')}
                                    width="140"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="numeric"
                                />
                                <Column
                                    field="tds_ded"
                                    headerClassName={isColumnActive('tds_ded') ? 'active' : ''}
                                    title={renderSubTextTitle('TDS Deducted')}
                                    width="140"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="numeric"
                                />
                            </Column>

                        </Column>

                        <Column title="Processed Data" headerClassName='sorting_disabled notices_orders_rowbgclr_yell' sortable={false}>

                            <Column headerClassName='notices_orders_rowbgclr_yell' sortable={false}>
                                <Column
                                    field="tds_dep"
                                    headerClassName={isColumnActive('tds_dep') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                    title={renderSubTextTitle('TDS Deposited')}
                                    width="140"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="numeric"
                                />
                                <Column
                                    field="tds_credit"
                                    headerClassName={isColumnActive('tds_credit') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                    title={renderSubTextTitle('TDS Credit Allowed \n to the Extent of Available Balance')}
                                    width="170"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="numeric"
                                />
                            </Column>

                            <Column title="Interest Calculation" headerClassName='sorting_disabled notices_orders_rowbgclr_grey' sortable={false}>
                                <Column
                                    field="deduction_date"
                                    headerClassName={isColumnActive('deduction_date') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                    title={renderSubTextTitle('Date of Deduction')}
                                    width="130"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="date"
                                />
                                <Column
                                    field="due_date"
                                    headerClassName={isColumnActive('due_date') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                    title={renderSubTextTitle('Due Date for TDS Deposit')}
                                    width="130"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="date"
                                />
                                <Column
                                    field="oltas_date"
                                    headerClassName={isColumnActive('oltas_date') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                    title={renderSubTextTitle('Date of Deposit \n as Per OLTAS')}
                                    width="150"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="date"
                                />
                                <Column
                                    field="delay_period"
                                    headerClassName={isColumnActive('delay_period') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                    title={renderSubTextTitle('Period of Delay \n in Months Either')}
                                    width="150"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="numeric"
                                />
                                <Column
                                    field="int_late_amt"
                                    headerClassName={isColumnActive('int_late_amt') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                    title={renderSubTextTitle('Interest on Late Payment U/S 201 (1A)')}
                                    width="150"
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    filter="numeric"
                                />
                            </Column>
                        </Column>

                        <GridNoRecords>
                            No Record Found
                        </GridNoRecords>

                    </Grid>
                </Tooltip>

            </div>
        </fieldset>
    )

};

export default IntLatePaymentTable2;