import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../components/verifyPAN/UpdateTracesCredential.css';
import ShowAlert from '../../components/ShowAlert';
import { verifyEFilingCredsFromConnector, importedChallansFromConnector } from '../../api/localConnectorAPI';
import { editDeductor, importChallanFromIncomeTaxPortal } from '../../api/deductorAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';

const UpdateEFilingCredentialForImportChallan = (props) => {
  const { show, handleClose, deductorId, tan, port, handleProceed } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [eFilingPswrd, setEFilingPswrd] = useState('');

  const updateCredentials = (eFilingPswrd) => {
    const request = {
      "traces_username": tan,
      "traces_password": eFilingPswrd,
      "deductor_id": deductorId,
      "deductor_credentials": [
        {
          "credential_type": "EFILING",
          "username": tan,
          "password": eFilingPswrd,
          "verified": true,
        }
      ]
    }
    async function fetchEverything() {
      try {
        await editDeductor(request);
      } catch (err) {
        setError('error in updating deductor credentials');
      }
    }
    fetchEverything();
  };

  const submitForm = (e) => {
    e.preventDefault();
    let fErrors = {};
    if (eFilingPswrd === '') { fErrors['eFilingPassword'] = 'EFiling Password is required'; }
    setFormErrors(fErrors);
    if (Object.keys(fErrors).length > 0) return;
    verifyLogin(eFilingPswrd);
  };

  const verifyLogin = async (eFilingPswrd) => {

    try {
      setLoading(true);
      let extraHeader = {};
      const request = {
        'itdportal_username': tan,
        'itdportal_password': eFilingPswrd
      }

      const loginResult = await verifyEFilingCredsFromConnector(port, request);

      //Update Income Tax Credentials
      updateCredentials(eFilingPswrd);

      const requestParams = {
        "deductor_id": deductorId,
        "captcha_ref": loginResult.data.captcha_ref ?? '',
      }

      try {

        const loginVerified = await importChallanFromIncomeTaxPortal(requestParams);
        const LoginVerifiedResult = loginVerified.data
        const responseHeader = loginVerified.headers ?? null;

        const connectorRequest = {
          "data": LoginVerifiedResult.data
        };

        extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

        await importedChallansFromConnector(port, connectorRequest, extraHeader);

        //everything ok
        handleProceed();

      } catch (err) {
        console.log("check err", err.message)
        const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message;
        setError(errmsg);

      }

    } catch (err) {

      if (err.message[0] === 'itefil.invalid_userid') {
        handleProceed();
      } else {
        const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message;
        setError(errmsg);
      }

    } finally {

      setLoading(false);
    }
  }


  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="tracescredentials"
      >
        {loading ? <LoadingIndicator /> : null}

        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title>Income Tax Credentials</Modal.Title>
        </Modal.Header>

        <form onSubmit={submitForm}>
          <Modal.Body className="pb-0 px-0">

            <ShowAlert
              error={error}
              success={success}
              onClose={() => { setError(null); setSuccess(null); }}
            />

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {/* <p className="mb-0 pb-0">Please provide Income Tax Credentials below </p> */}

                  <div className="row">
                    <div className="col-md-12">

                      <div className="form-group">
                        <label className="control-label" htmlFor="">EFiling UserName </label>
                        <input
                          disabled
                          type="search"
                          className="form-control"
                          value={tan}
                        />
                      </div>

                      <div className="form-group">
                        <label className="control-label" htmlFor="">EFiling Password </label>
                        <input
                          type="password"
                          className="form-control"
                          id="eFilingPassword"
                          name="eFilingPassword"
                          placeholder="Enter EFiling Password"
                          onChange={(e) => setEFilingPswrd(e.target.value)}
                          value={eFilingPswrd}
                        />
                        {formErrors.eFilingPassword ? <span className="error_message">{formErrors.eFilingPassword}</span> : null}
                      </div>
                    </div>

                  </div> <br />


                </div>

              </div>

            </div>

          </Modal.Body>
          <Modal.Footer className="tracecredfoot">
            <Button type="submit" className="tracesbtnsubmit" onClick={submitForm} disabled={loading}> Save </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>

  );
};
export default UpdateEFilingCredentialForImportChallan;
