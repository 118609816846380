import axios from "axios";
import { BASE_URL_GO } from "../app/constants";
import {
  handleErrorWithCode,
} from "./responseHandler";
import { getConfig } from "./common";

export async function downloadFVUFile(getParams) {

    const url = `${BASE_URL_GO}/api/v1/returns/download-fvu-file${getParams}`;
    try {
      const config = { ...getConfig() };
      config.responseType = 'blob';
      const result = await axios.get(url, config);
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  
  }

  export async function checkFVUFileStatus(getParams) {

    const url = `${BASE_URL_GO}/api/v1/returns/status-generate-fvu-file${getParams}`;
  
    try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function generateFVUFile(getParams) {

    const url = `${BASE_URL_GO}/api/v1/returns/start-generate-fvu-file${getParams}`;
  
    try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function getFvuRegularOltasCaptcha() {
    const url = `${BASE_URL_GO}/api/v1/oltas/captcha`;
  
    try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function getFvuRegularOltasCSI(data) {
    const url = `${BASE_URL_GO}/api/v1/oltas/download-csi`;
  
    try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function fvuRegularDownloadCSI(data) {
    const url = `${BASE_URL_GO}/api/v1/efiling/download-csi`;
  
    try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function generateRegularFVUFileWithConnector(getParams) {
    const url = `${BASE_URL_GO}/api/v1/usuc/efiling/get-fvu-file${getParams}`;  
  
    try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
      return result;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }