/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import AlphaNumericInput from './AlphaNumericInput';

const AlphaNumericInputWithDropdown = (props) => {
    const { value, onChange, data, ...rest } = props;
    return (
        <div style={{ position: 'relative' }}>
            <AlphaNumericInput
                type="input"
                className="form-control"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                {...rest}
            />
            <Dropdown style={{ position: 'absolute', top: '0px', right: '0px' }}>
                <Dropdown.Toggle
                    style={{ backgroundColor: 'transparent', color: 'black', border: 'none' }}
                />

                <Dropdown.Menu drop="left">
                    {data.map((item) => (
                        <Dropdown.Item
                            style={{ fontSize: '12px' }}
                            onSelect={() => onChange(item)}
                            key={item}
                        >
                            {item}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default AlphaNumericInputWithDropdown;
