import { getAuthToken } from "../utils/AuthToken";

export const loginAxiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getConfig = function () {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken(true)}`,
    }
  }
}

export const getConfigForDeleteHttpVerb = function (data) {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken(true)}`,
    }, data
  }
};

export const getConnectorConfig = { crossdomain: true };