import React, { useState, useEffect } from 'react';
import ShowAlert from '../ShowAlert';
import Modal from 'react-bootstrap/Modal';

const OtpVerifiyPopup = (props) => {
    const { handleClose, data, error, setError, verifiyApi, resendOtP } = props;
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(120);

    const handleChange = (e) => {
        if (isNaN(Number(e.target.value))) return;
        setOtp(e.target.value);
    }

    const verifiedOTP = () => {

        let apidata = {
            country_code: data.country_code,
            otp_verification_id: data.id,
            otp: otp,
            user_id: data?.userId ?? null
        }
        if (data.key === "mobile_no") apidata["mobile_no_or_email"] = String(data.user);
        else if (data.key === "email") apidata["mobile_no_or_email"] = String(data.user);

        verifiyApi(apidata, data.verify);
    }
    const resendVerifiedOTP = () => {
        let apidata = {
            country_code: data.country_code
        }
        if (data.key === "mobile_no") apidata["mobile_no"] = String(data.user);
        else if (data.key === "email") apidata["email"] = String(data.user);
        resendOtP(apidata, data.key, data.verify);
    }

    const startTimer = () => {
        setTimer(120);
        let newTimer = 120;
        setInterval(() => {
            if (newTimer > 0) {
                newTimer -= 1;
                setTimer(newTimer);
            }
        }, 1000);
    };


    useEffect(() => {
        startTimer();
    }, [data.id]);

    return (

        <Modal className="gst_verifyyremailid_modal" backdrop="static" show={true} onHide={handleClose}>
            <Modal.Header closeButton className="gst_verifyyremailid_header">
                <Modal.Title className="gst_verifyyremailid_title" >{`Verify Your ${data.Title}`}</Modal.Title>
            </Modal.Header>
            <ShowAlert
                error={error}
                onClose={() => { setError(null); }}
            />
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className="text-center">
                            <img alt='icon' src={data.icon} width="80" />
                        </p>

                        <p className="gst_verifyyremailid_textfs text-center">
                            {`An OTP (valid for next 2 mins.) has been sent to you on your ${data.Title} `}
                        </p>
                        <h4 className="gst_verifyyremailid_emialidfs pb-3">{data.key === 'mobile_no' ? `+91-${data.user}` : data.user}</h4>
                        <div className="form-group mb-2">
                            <input type="text" className="form-control gst_verifyyremailid_inptfs" placeholder="Enter OTP" onChange={handleChange} value={otp} />

                        </div>
                    </div>
                    <div className="col-md-12 text-center">
                        <button className={`gst_verifyyremailid_emailidbgclr ${!otp ? "emailopacity" : ""}`} disabled={!otp ? true : false} onClick={() => verifiedOTP()}  >Verify OTP</button>
                    </div>
                    <div className="col-md-12 pt-2 text-center pb-2">
                        {
                            timer > 0
                                ? <span className='circle'>{timer}</span>
                                : <button className="gst_verifyyremailid_btnresent_bgclr" onClick={() => resendVerifiedOTP()} >Resend OTP</button>
                        }
                        {/* <button className="gst_verifyyremailid_btnresent_bgclr" onClick={() => resendVerifiedOTP()} >Resend OTP</button> */}
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default OtpVerifiyPopup
