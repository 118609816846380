import React from 'react';
import WindowWrapperFixed from '../../components/grid/WindowWrapperFixed';
import "./BulkImport.css";

const ImportStatus = (props) => {

  const { onClose, data, heading, handleProceed, browseType } = props;


  const handlesuccess = (totallist) => {
    onClose();
    if (totallist) {
      handleProceed(totallist);
    }
    
  }

  return (

    <WindowWrapperFixed
      title={<kendo-dialog-titlebar>
        <h4 className='my-3' style={{ fontSize: "16px" }}>
          {browseType === 'excel' ? 'Import Status of Bulk Import via Excel' : 'Import Status'}
        </h4>
      </kendo-dialog-titlebar>}
      onClose={onClose}
      totalPopups={0}
      initialTop={50}
      index={1}
      className={`creaeclient_ImportSt`}
      initialHeight={'auto'}
      style={{ maxHeight: 'auto', overflowX: 'hidden' }}
      initialWidth={700}
      initialLeft={(((window.innerWidth) - 700) / 2)}
      isModalView={true}
    >
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
            <table class="table table-striped tds_blukimportdedcut_tb mb-0">
              <thead>
                <tr>
                  {heading.map((item, index) => {
                    return (<th width={index < 3 ? 250 : 500}>{item}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">{data?.total ?? 0}</td>
                  <td className="text-center" >{data?.created ?? 0}</td>
                  <td className="text-center" >{data?.error ?? 0}</td>
                  <td className="text-center">{(Number(data?.duplicate ?? 0) + Number(data?.already_exist ?? 0))}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-12 pt-3 text-center">
          <button className="btn btn-default blukimp_deduct_btnbgclr" onClick={() => handlesuccess(data ?? "")}>Okay</button>
        </div>
      </div>
    </WindowWrapperFixed>

  );
}

export default ImportStatus;
