/* eslint-disable require-jsdoc */
import React from 'react';
import './LoadingIndicator.css';

export default function LoadingIndicator() {
  return (
    <div id="overlay">
      <div id="background"></div>
      <div id="logocontainer" >
        <div id="pelogo">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 120.6 141.1" style={{ verticalAlign: 'baseline', margin: '0 auto', width: '21vh', position: 'absolute', left: '10%', top: '9%' }}>
            <g>
              <g>
                <path fill="#31719B" d="M5.4,6.3c-3,0-5.4,2.5-5.4,5.4v77.9c0,1.2,1.5,1.6,2.1,0.6l44.8-78c1.5-2.6-0.4-5.9-3.4-5.9L5.4,6.3L5.4,6.3z" />
                <path fill="#31719B" d="M89.4,111.1c3.2,0,5.8-2.6,5.8-5.8V59.8c0-3.2-3.7-5.1-6.2-3.2L17.2,109c-0.9,0.6-0.4,2.1,0.7,2.1H89.4z" />
                <path fill="#FC7D72" d="M101.6,6.6c0-3.7-3-6.6-6.6-6.6H66.9c-2,0-3.8,1.1-4.8,2.8L5,102.1c-1.2,2,1.3,4.3,3.2,2.9l91.1-65.9c1.4-1.1,2.3-2.7,2.3-4.5L101.6,6.6L101.6,6.6z" />
              </g>
            </g>
          </svg>
        </div>
        <div className="loader" style={{ backgroundColor: '#fc7d72', left: '2vh', top: '0', height: '2vh', width: '0', animation: 'slide1 1.3s linear forwards infinite' }}></div>
        <div className="loader" style={{ right: '0', top: '2vh', width: '2vh', height: '0', animation: ' slide2 1.3s linear forwards infinite', animationDelay: '.6s' }}></div>
        <div className="loader" style={{ backgroundColor: '#fc7d72', right: '2vh', bottom: '0', height: '2vh', width: '0', animation: 'slide3 1.3s linear forwards infinite' }}></div>
        <div className="loader" style={{ backgroundColor: '#fc7d72', left: '0', bottom: '2vh', width: '2vh', height: '0', animation: 'slide4 1.3s linear forwards infinite', animationDelay: '.7s' }}></div>
      </div>
    </div>
  );
}
