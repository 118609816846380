import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import NumericInputWithDecimalTwo from '../../../../../components/NumericInputWithDecimalTwo';
import { formatNumber } from '../../../../../utils/UtilityFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRupeeSign
} from '@fortawesome/free-solid-svg-icons';


const LetOutIncome = (props) => {
    const { show, handleClose, values, setFieldValue, totalHouseIncome } = props;

    const [letoutBreakup, setLetoutBreakup] = useState({
        'gross_amtp': Number(values?.let_out_breakup?.gross_amtp ?? 0),
        'property_tax_amtp': Number(values?.let_out_breakup?.property_tax_amtp ?? 0),
        'net_annual_amtp': Number(values?.let_out_breakup?.net_annual_amtp ?? 0),
        'standard_deduction_amtp': Number(values?.let_out_breakup?.standard_deduction_amtp ?? 0),
        'loan_interest_amtp': Number(values?.let_out_breakup?.loan_interest_amtp ?? 0),
    });

    const [letoutAmtp, setLetoutAmtp] = useState(values.let_out_amtp);
    const [dataChange, setDataChange] = useState(0);

    const closePopup = () => {
        handleClose(false);
    }

    const saveLetOut = () => {
        values.let_out_breakup = letoutBreakup;
        setFieldValue('let_out_breakup', letoutBreakup);
        setFieldValue('let_out_amtp', letoutAmtp);
        totalHouseIncome('let_out_amtp', letoutAmtp, setFieldValue, values);
        handleClose(false);
    }

    const calculate_let_out = (field, value) => {

        let new_letoutBreakup = letoutBreakup;
        new_letoutBreakup[field] = value;

        let gross_amtp = new_letoutBreakup.gross_amtp ? new_letoutBreakup.gross_amtp : 0;
        let property_tax_amtp = new_letoutBreakup.property_tax_amtp ? new_letoutBreakup.property_tax_amtp : 0;
        let loan_interest_amtp = new_letoutBreakup.loan_interest_amtp ? new_letoutBreakup.loan_interest_amtp : 0;

        let net_annual_amtp = parseFloat(gross_amtp - property_tax_amtp).toFixed(2);
        let standard_deduction_amtp = parseFloat(30 / 100 * net_annual_amtp).toFixed(2);
        let let_out_amtp = parseFloat(gross_amtp - property_tax_amtp - standard_deduction_amtp - loan_interest_amtp).toFixed(2);

        new_letoutBreakup.net_annual_amtp = net_annual_amtp;
        new_letoutBreakup.standard_deduction_amtp = standard_deduction_amtp;
        setLetoutBreakup(new_letoutBreakup);
        setLetoutAmtp(let_out_amtp);
        setDataChange(dataChange + 1);
    }

    return (<Modal
        show={show}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        size="sm"
        className="allowancedspopup"
    >
        <Modal.Header closeButton className="MonthWSdetailsH monthdetail_header">
            <Modal.Title className="pt-0 mt-0">
                <sapn className="MonthWSdetailsT"> Let Out Income From House Property</sapn>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0 px-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 pt-2">
                                <div className="card MonthWSdetailscard">
                                    <div className="table-responsive">
                                        <table className="table table-striped allowanceWSDTB">
                                            <thead>
                                                <tr>
                                                    <td>
                                                        Gross Annual Value (A)
                                                        <br />
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className="form-control mt-2"
                                                            placeholder="0.00"
                                                            name="gross_amtp"
                                                            value={letoutBreakup.gross_amtp}
                                                            onChange={(e) => {
                                                                if (e.target.value.replace('.', '').length <= 12) {
                                                                    calculate_let_out('gross_amtp', e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        Property Tax (B)
                                                        <br />
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className="form-control mt-2"
                                                            placeholder="0.00"
                                                            name="property_tax_amtp"
                                                            value={letoutBreakup.property_tax_amtp}
                                                            onChange={(e) => {
                                                                if (e.target.value.replace('.', '').length <= 12) {
                                                                    calculate_let_out('property_tax_amtp', e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="pt-2 pb-2">Net Amount Value: <FontAwesomeIcon icon={faRupeeSign} /> {formatNumber(letoutBreakup.net_annual_amtp ? letoutBreakup.net_annual_amtp : 0)}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Standard Deduction (C)
                                                        <br />
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className="form-control mt-2"
                                                            placeholder="0.00"
                                                            name="standard_deduction_amtp"
                                                            value={letoutBreakup.standard_deduction_amtp}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>Loan Interest (D)
                                                    <br />
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className="form-control mt-2"
                                                            placeholder="0.00"
                                                            name="loan_interest_amtp"
                                                            value={letoutBreakup.loan_interest_amtp}
                                                            onChange={(e) => {
                                                                if (e.target.value.replace('.', '').length <= 12) {
                                                                    calculate_let_out('loan_interest_amtp', e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="pt-2 pb-2">Let Out income from house property (E=A-B-C-D): <FontAwesomeIcon icon={faRupeeSign} /> {formatNumber(letoutAmtp)}</td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="btn-group mt-2 mb-1">
                                    <button type="button" className="btn btn-default monthwsdetilssavebtn" onClick={() => saveLetOut()}>Save & Exit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
    </Modal>
    );
};

export default LetOutIncome;