import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import addBtnRoundIcon from '../../images/icons/add_btn_round.svg';
import infoIcon from '../../images/icons/infoicon.svg'
import addBtnBulk from '../../images/bulk-import.png';
import { formatAccountsNumber, formatNumber } from '../../utils/UtilityFunctions';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Skeleton } from '@mui/material'

export class CustomCell extends React.Component {
    render() {
        return (
            <span style={this.props.style ? this.props.style : {}} className={this.props.className ? this.props.className : ""}>
                {this.props.titleIcon ? <FontAwesomeIcon icon={this.props.titleIcon} /> : null}
                {this.props.title} {this.props.icon ? <span>(<FontAwesomeIcon icon={this.props.icon} />) </span> : null}
            </span >
        );
    }
}

export class AddSalaryCell extends React.Component {

    sortChange = () => {
        let new_sort = [];
        let dir = 'asc';
        if (this.props.dataState && this.props.dataState.sort) {
            new_sort = this.props.dataState.sort.filter((sort, index) => {
                if (sort.field === this.props.field) {
                    dir = sort.dir === 'asc' ? 'desc' : '';
                }
                return sort.field !== this.props.field;
            })
        }

        if (this.props.field) {
            if (dir) {
                new_sort.push({ field: this.props.field, dir: dir });
                //console.log(new_sort);
            }
            this.props.dataState.sort = new_sort;
            this.props.dataStateChange(this.props.dataState);
        }
    }
    render() {
        return (<>
            <span onClick={this.sortChange}>Salary For</span>
            <span id="create_new_salary" onClick={this.props.handleClick}>
                <span className="create_deductor listofdticon"> &nbsp;<img src={addBtnRoundIcon} alt="" /></span>
            </span>
            {this.props.subtextData ?
                <div className="text-right">
                    <span title={this.props.title + ': ' + this.props.subtextData.subText} className="b2btbtrthtextclr" style={{ color: `#${this.props.subtextData.color}` }}>{this.props.subtextData.subText}</span>
                </div>
                : null}
        </>
        );
    }
}

export class AddDeducteeTransactionsIcon extends React.Component {

    sortChange = () => {
        let new_sort = [];
        let dir = 'asc';
        if (this.props.dataState && this.props.dataState.sort) {
            new_sort = this.props.dataState.sort.filter((sort, index) => {
                if (sort.field === this.props.field) {
                    dir = sort.dir === 'asc' ? 'desc' : '';
                }
                return sort.field !== this.props.field;
            })
        }

        if (this.props.field) {
            // if (dir) {
            //     new_sort.push({ field: this.props.field, dir: dir });
            //     //console.log(new_sort);
            // }
            new_sort = dir ? [{ field: this.props.field, dir: dir }] : false;
            this.props.dataState.sort = new_sort;
            this.props.dataStateChange(this.props.dataState);
        }
    }
    render() {
        return (<>
            <span onClick={this.sortChange} title={this.props.addTooltip}>{this.props.title}</span>
            {(this.props.showButton === undefined || this.props.showButton === true) &&
                <span title={this.props.addTooltip} onClick={this.props.handleClick}>
                    <span className="create_deductor listofdticon"> &nbsp;<img title={this.props.addTooltipPlus} src={addBtnRoundIcon} alt="" /></span>
                </span>
            }
            {this.props.subtextData ?
                <div className={`${this.props.headClass ? "text-center" : "text-right"}`}>
                    {
                        this.props.summaryColumnSkelton
                        ? <Skeleton animation="wave" variant="text" width={"80px"} height={"20px"} />
                        : <span title={this.props.headClass ? '' : this.props?.subtextData?.subTextTitle ? this.props?.subtextData?.subTextTitle + ': ' + formatNumber(this.props.subtextData.subText)   : this.props.title  + ': ' + formatNumber(this.props.subtextData.subText)} className="b2btbtrthtextclr" style={{ color: `#${this.props.subtextData.color}`, paddingLeft: (this?.props?.noLeftPadding ?? false) === true ? '0px' : '20px' }}>{this.props.headClass ? this.props.subtextData.subText : formatNumber(this.props.subtextData.subText)}</span>
                    }
                    
                </div>
                : null}
        </>
        );
    }
}

export class CustomCellForSort extends React.Component {

    sortChange = () => {
        let new_sort = [];
        let dir = 'asc';
        if (this.props.dataState && this.props.dataState.sort) {
            new_sort = this.props.dataState.sort.filter((sort, index) => {
                if (sort.field === this.props.field) {
                    dir = sort.dir === 'asc' ? 'desc' : '';
                }
                return sort.field !== this.props.field;
            })
        }

        if (this.props.field) {
            if (dir) {
                new_sort.push({ field: this.props.field, dir: dir });
                //console.log(new_sort);
            }
            this.props.dataState.sort = new_sort;
            this.props.dataStateChange(this.props.dataState);
        }
    }
    render() {
        return (<>
            <span onClick={this.sortChange}>{this.props.title}</span>
            {this.props.subtextData ?
                <div className="text-right">
                    <span title={this.props.title + ': ' + this.props.subtextData.subText} className="b2btbtrthtextclr" style={{ color: `#${this.props.subtextData.color}` }}>{this.props.subtextData.subText}</span>
                </div>
                : null}
        </>
        );
    }
}

export class CustomCellList extends React.Component {

    sortChange = () => {
        let new_state = this.props.dataState;

        let dir = 'asc';
        if (this.props.dataState && this.props.dataState.sort) {
            if (this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field) {
                dir = this.props.dataState.sort[0].dir === 'asc' ? 'desc' : '';
            }
        }
        if (this.props.field) {

            new_state.sort = dir ? [{ field: this.props.field, dir: dir }] : false;
            this.props.dataStateChange(new_state);
        }
    }
    render() {
        return (<>
            {this.props.dataState && <span onClick={this.sortChange}>{this.props.title}</span>}
            {!this.props.dataState && <span>{this.props.title}</span>}
            {this.props.addnewRow && <span  className ='planexpiredalert' title={this.props.addTooltip} onClick={this.props.handleClick}>
                <span className="create_deductor" style={{ cursor: "pointer" }}> &nbsp;<img src={addBtnBulk} alt="" width="20" /></span>
            </span>}
            {this.props.subtextData ?
                <div className={`${this.props?.subtextData?.subClass ?? "text-right"}`}>
                    <span title={this.props?.subtextData?.titleNotShow ? "" : this.props.title + ': ' + this.props.subtextData.subText} className="b2btbtrthtextclr" style={{ color: `#${this.props.subtextData.color}` }}>{this.props.subtextData.subText}</span>
                </div>
                : null}
        </>
        );
    }
}


export class AddInfoIcon extends React.Component {

    sortChange = () => {
        let new_sort = [];
        let dir = 'asc';
        if (this.props.dataState && this.props.dataState.sort) {
            new_sort = this.props.dataState.sort.filter((sort, index) => {
                if (sort.field === this.props.field) {
                    dir = sort.dir === 'asc' ? 'desc' : '';
                }
                return sort.field !== this.props.field;
            })
        }

        if (this.props.field) {
            if (dir) {
                new_sort.push({ field: this.props.field, dir: dir });
                //console.log(new_sort);
            }
            this.props.dataState.sort = new_sort;
            this.props.dataStateChange(this.props.dataState);
        }
    }
    render() {
        return (<>
            <span onClick={this.sortChange} title={this.props.heading}>{this.props.title}</span>
            <span title={this.props.addTooltip} onClick={this.props.handleClick}>
                <span className="create_deductor listofdticon"> &nbsp;<img src={infoIcon} alt="" /></span>
            </span>
            {this.props.subtextData ?
                <div className="text-right">
                    <span title={this.props.title + ': ' + (this.props.headClass ? formatNumber(this.props.subtextData.subText) : this.props.subtextData.subText)} className="b2btbtrthtextclr" style={{ color: `#${this.props.subtextData.color}` }}>{this.props.headClass ? formatNumber(this.props.subtextData.subText) : this.props.subtextData.subText}</span>
                </div>
                : null}
        </>
        );
    }
}

export class AddSyncIcon extends React.Component {

    sortChange = () => {
        let new_sort = [];
        let dir = 'asc';
        if (this.props.dataState && this.props.dataState.sort) {
            new_sort = this.props.dataState.sort.filter((sort, index) => {
                if (sort.field === this.props.field) {
                    dir = sort.dir === 'asc' ? 'desc' : '';
                }
                return sort.field !== this.props.field;
            })
        }

        if (this.props.field) {            
            new_sort = dir ? [{ field: this.props.field, dir: dir }] : false;
            this.props.dataState.sort = new_sort;
            this.props.dataStateChange(this.props.dataState);
        }
    }
    render() {
        return (<>
            <span onClick={this.sortChange} title={this.props.heading}>{this.props.title}</span>&nbsp;
            {(this.props.showButton === undefined || this.props.showButton === true) &&
                <span onClick={!this.props.iconDisabled ? this.props.handleClick : null}>
                    <FontAwesomeIcon icon={faSync} color={!this.props.iconDisabled ? "#31719b" : "#d3d3d3"} title={this.props.addTooltip} />
                </span>
            }
            {this.props.subtextData ?
                <div className="text-center">
                    <span title={this.props.title + ': ' + (this.props.headClass ? formatNumber(this.props.subtextData.subText) : this.props.subtextData.subText)} className="b2btbtrthtextclr" style={{ color: `#${this.props.subtextData.color}` }}>{this.props.subtextData.subText}</span>
                </div>
                : null
            }
        </>
        );
    }
}


export class CustomDataCell extends React.Component {

    sortChange = () => {
      let new_state = this.props.dataState;
      let dir = 'asc';
      if (this.props.dataState && this.props.dataState.sort) {
        if (this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field) {
          dir = this.props.dataState.sort[0].dir === 'asc' ? 'desc' : '';
        }
      }
      if (this.props.field && new_state) {
        new_state.sort = dir ? [{ field: this.props.field, dir: dir }] : false;
        this.props.dataStateChange(new_state);
      }
  
    }
  
    render() {
      return (<>
        <div onClick={(e) => e.stopPropagation()} style={this.props.style ? this.props.style : {}} className={this.props.className ? "title_field " + this.props.className : "title_field"} >
  
          <span className='cell-column-title' style={this.props.TitleStyle ? this.props.TitleStyle : {}} onClick={this.sortChange} title={this.props.addTooltip}>{this.props.title}</span>
          {(this.props.showButton === undefined || this.props.showButton === true) &&
            <span title={this.props.addTooltip} onClick={this.props.handleClick}>
              <span className="create_deductor listofdticon"> &nbsp;<img title={this.props.addTooltipPlus} src={addBtnRoundIcon} alt="" /></span>
            </span>
          }
  
          {this.props.subtextData ?
            <div className={`${this.props.headClass ? this.props.headClass : 'text-cetner'}`} style={{ color: `#${this.props.subtextData.subTextColor}}` }}>
              {
                this.props.summaryColumnSkelton
                  ? <Skeleton style={{ display: 'inline-block' }} animation="wave" variant="text" width={"80px"} height={"20px"} />
                  : <span onClick={(e) => e.stopPropagation()} style={{cursor: 'default'}} title={
                    this.props?.subtextData?.subTextTitle
                      ? this.props?.subtextData?.subTextTitle
                      : (this.props.numberFormating ?? false) === true
                        ? this.props.title + ': ' + formatAccountsNumber(this.props.subtextData.subText)
                        : this.props.title + ': ' + this.props.subtextData.subText
                  } className="b2btbtrthtextclr" >{(this.props.numberFormating ?? false) === true ? formatAccountsNumber(this.props.subtextData.subText) : this.props.subtextData.subText}</span>
              }
  
            </div>
            : null}
  
          {this.props.dataState && this.props.field && this.props.dataState.sort && this.props.dataState.sort[0] && this.props.dataState.sort[0].field === this.props.field ?
            <span className={`k-icon k-i-sort-${this.props.dataState.sort[0].dir}-sm active_sort`}></span>
            : null}
            
        </div>
      </>
      );
    }
  }