import React, { useState, useEffect } from 'react'
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import ConnectorAdapter from './ConnectorAdapter';
import { verfiyItloginCrediationalOnServer } from '../../api/ReturnRegister';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import {updateFilingItHistoryOnPortal } from '../../api/localConnectorAPI';
import ITConnector from './ITConnector';
import { gocallApi } from '../../api/issueCertificateAPI';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../features/header/headerSlice';
import ShowAlert from '../ShowAlert';

const InsightsGovtPortal = (props) => {

    const { syncDeductor, setCheckItPortal, tan } = props;

    const userCreditional = {
        itdportal_username: tan, 
    }
    const headerData = useSelector(selectHeaderData);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [port, setPort] = useState();
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [showValidationFailPopup, setShowValidationFailPopup] = useState(false);


    const verfiyCredApi = async (port, syncdata, setCheckItPortal) => {
        if (!port) {
            return false;
        }
        setLoading(true);


        const verigyEfilingCreditional = `?deductor_id=${syncdata}`
        try {

            const loginVerified = await verfiyItloginCrediationalOnServer(verigyEfilingCreditional);

            if (loginVerified.data.status === 1) {
                let extraHeader = {};
                const LoginVerifiedResult = loginVerified.data
                const responseHeader = loginVerified.headers ?? null;

                const connectorRequest = {
                    "data": LoginVerifiedResult.data
                };

                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                try {
                    await updateFilingItHistoryOnPortal(port, connectorRequest, extraHeader);
                    setCheckItPortal(false)
                } catch (err) {
                    const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['insights.unknown_error'];
                    setError(errmsg);
                }

            }

        } catch (err) {
            console.error('2 error: ', err);
             setShowValidationFailPopup(true);
            // if (err.message) setError(err.message);
            // else setError(err);

        } finally {
            setCheckItCredentials(false);
            setLoading(false);
        }


    }

    const updateCredentialsOnserver = async (itdportal_password) => {

        setLoading(true);
        const payload = {
            deductor_id: syncDeductor ?? "",
            deductor_name: headerData?.deductor_name ?? "",
            efilling_password: itdportal_password ?? "",
            deductor_status: 1,

        }
        const url = "/api/v1/deductor"

        try {
            const result = await gocallApi("put", url, payload)
            setShowValidationFailPopup(false);
            if (result.data) {
                setTimeout(() => verfiyCredApi(port, syncDeductor, setCheckItPortal),1000)
            }

        } catch (err) {
            console.error(err);

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (port && checkItCredentials) {
            verfiyCredApi(port, syncDeductor, setCheckItPortal);
        }
    }, [port, checkItCredentials, syncDeductor, setCheckItPortal]);

    useEffect(() => {
        if (syncDeductor) {
            setShowConnnectorCall(true);
        }
    }, [syncDeductor])

    
    return (
        <>
            {error &&
                <ShowAlert
                    error={error}
                    onClose={() => setError(null)}
                />
            }
            {loading ? <LoadingIndicator /> : null}

            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => setShowConnnectorCall(false)}
                    setPort={setPort}
                    handleProceed={() => setCheckItCredentials(true)}
                />
                : null}

            {showValidationFailPopup &&
                <ITConnector
                    syncData={userCreditional}
                    show={showValidationFailPopup}
                    handleClose={() => {
                        setShowValidationFailPopup(false);
                        setCheckItPortal(false);
                    }}
                    verfiyCredentialApi={(syncdata) => {
                        updateCredentialsOnserver(syncdata?.itdportal_password ??  "")
                    }}
                    loading={loading}

                />
            }

        </>
    )
}

export default InsightsGovtPortal