import React, { useState, useEffect } from "react";

import useContextMenu from "./useContextMenu";

import Draggable from 'react-draggable';

const menuItems = [
    {
        title: 'Salary 17(1)',
        visible: false,
        child: [
            { key: 'sal_1_amt', title: 'Basic', visible: false, child: [] },
            { key: 'sal_2_amt', title: 'DA Forming Part of Salary', visible: false, child: [] },
            { key: 'sal_3_amt', title: 'Commission', visible: false, child: [] },
            { key: 'sal_4_amt', title: 'Advance Salary', visible: false, child: [] },
            { key: 'sal_5_amt', title: 'Bonus', visible: false, child: [] },
            { key: 'sal_6_amt', title: 'Variable', visible: false, child: [] },
            { key: 'sal_6_prd', title: 'Variable Payout Period', visible: false, child: [] },
        ],
    },
    {
        title: 'Employee Details',
        visible: false,
        child: [
            { key: 'ref_no', title: 'Employee Ref. No.', visible: false, child: [] },
            { key: 'pan', title: 'PAN', visible: false, child: [] },
            { key: 'nm', title: 'Name', visible: false, child: [] },
            { key: 'dob', title: 'DOB', visible: false, child: [] },
            { key: 'age', title: 'Age', visible: false, child: [] },
            { key: 'catg', title: 'Category', visible: false, child: [] },
            { key: 'regime', title: 'Regime', visible: false, child: [] },
            { key: 'location', title: 'Location', visible: false, child: [] },
            { key: 'email', title: 'Email', visible: false, child: [] },
        ],
    },
    {
        title: 'Perquisites 17(2)',
        visible: false,
        child: [
            {
                key: 'perq_1', title: 'Rent Free Accomodation',
                visible: false,
                child: [
                    { key: 'perq_1_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_1_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_2', title: 'Perquisite of Motor Car',
                visible: false,
                child: [
                    { key: 'perq_2_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_2_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_3', title: 'LTC - Provided',
                visible: false,
                child: [
                    { key: 'perq_3_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_3_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_4', title: 'Free Concessional Supply of gas/water',
                visible: false,
                child: [
                    { key: 'perq_4_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_4_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_5', title: 'Domestic Servent',
                visible: false,
                child: [
                    { key: 'perq_5_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_5_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_6', title: 'Free education Facility',
                visible: false,
                child: [
                    { key: 'perq_6_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_6_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_7', title: 'Transfer of Movable Assests(Car)',
                visible: false,
                child: [
                    { key: 'perq_7_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_7_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_8', title: 'Lunch',
                visible: false,
                child: [
                    { key: 'perq_8_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_8_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_9', title: 'Credit Card',
                visible: false,
                child: [
                    { key: 'perq_9_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_9_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_10', title: 'Interest Free Loan',
                visible: false,
                child: [
                    { key: 'perq_10_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_10_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_11', title: 'Use of Movable assets',
                visible: false,
                child: [
                    { key: 'perq_11_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_11_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_20', title: 'Tax on Perquisite paid by employer',
                visible: false,
                child: [
                    { key: 'perq_20_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_20_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_12', title: 'Gift',
                visible: false,
                child: [
                    { key: 'perq_12_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_12_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_13', title: 'Club Facility',
                visible: false,
                child: [
                    { key: 'perq_13_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_13_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_14', title: 'Medical Facility India',
                visible: false,
                child: [
                    { key: 'perq_14_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_14_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_15', title: 'Obligation of Employee met by employer',
                visible: false,
                child: [
                    { key: 'perq_15_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_15_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_16', title: 'Any other Perquisite',
                visible: false,
                child: [
                    { key: 'perq_16_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_16_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_17', title: 'Employer\'s contribution to Superannuation Fund',
                visible: false,
                child: [
                    { key: 'perq_17_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_17_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_18', title: 'Stock options allotted or transferred by employer', //being an eligible start-up referred to in section 80-IAC
                visible: false,
                child: [
                    { key: 'perq_18_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_18_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'perq_19', title: 'Stock options (non-qualified options) other than ESOP',
                visible: false,
                child: [
                    { key: 'perq_19_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'perq_19_ex_amt', title: 'Exempted' },
                ]
            },
            
        ],
    },
    {
        title: 'Profit in Lieu of Salary 17(2)',
        visible: false,
        child: [
            {
                key: 'prft_1_amt', title: 'Compensation to Voluntary retirement',
                visible: false,
                child: [
                    //{ key: 'prft_1_pr', title: 'Provided Or Taxable' },
                    //{ key: 'prft_1_ex', title: 'Exempted' },
                ]
            },
            {
                key: 'prft_2_amt', title: 'Retrenchment Compensation',
                visible: false,
                child: [
                    //{ key: 'prft_1_pr', title: 'Provided Or Taxable' },
                    //{ key: 'prft_1_ex', title: 'Exempted' },
                ]
            },
            {
                key: 'prft_3_amt', title: 'PF Interest/PF Payment',
                visible: false,
                child: [
                    //{ key: 'prft_1_pr', title: 'Provided Or Taxable' },
                    //{ key: 'prft_1_ex', title: 'Exempted' },
                ]
            },
            {
                key: 'prft_4_amt', title: 'Any other amount received as profit in lieu of salary',
                visible: false,
                child: [
                    //{ key: 'prft_1_pr', title: 'Provided Or Taxable' },
                    //{ key: 'prft_1_ex', title: 'Exempted' },
                ]
            },
        ]
    },
    {
        title: 'Retirement Benefits',
        visible: false,
        child: [
            {
                key: 'rb_1_amt', title: 'Leave encashment - on retirement',
                visible: false,
                child: [
                    //{ key: 'rb_1_pr', title: 'Provided Or Taxable' },
                    //{ key: 'rb_1_ex', title: 'Exempted' },
                ]
            },
            {
                key: 'rb_2_amt', title: 'Gratuity',
                visible: false,
                child: [
                    //{ key: 'rb_2_pr', title: 'Provided Or Taxable' },
                    //{ key: 'rb_2_ex', title: 'Exempted' },
                ]
            },
            {
                key: 'rb_3_amt', title: 'Un-commuted pension',
                visible: false,
                child: [
                    //{ key: 'rb_3_pr', title: 'Provided Or Taxable' },
                    //{ key: 'rb_3_ex', title: 'Exempted' },
                ]
            },
            {
                key: 'rb_4_amt', title: 'Commuted pension',
                visible: false,
                child: [
                    //{ key: 'rb_4_pr', title: 'Provided Or Taxable' },
                    //{ key: 'rb_4_ex', title: 'Exempted' },
                ]
            },
        ]
    },
    {
        title: 'Chapter VIA- Deductions',
        visible: false,
        child: [
            { key: 'via_ded_80c_amt', title: '80C', visible: false, child: [] },
            { key: 'via_ded_80ccc_amt', title: '80CCC', visible: false, child: [] },
            { key: 'via_ded_80ccd_1_amt', title: '80CCD(1)', visible: false, child: [] },
            { key: 'via_ded_80ccd_2_amt', title: '80CCD(2)', visible: false, child: [] },
            { key: 'via_ded_80ccd_1b_amt', title: '80CCD(1B)', visible: false, child: [] },
            { key: 'via_ded_80d_self_amt', title: '80D(self)', visible: false, child: [] },
            { key: 'via_ded_80d_p_amt', title: '80D(Parents)', visible: false, child: [] },
            // { key: 'via_ded_80e_amt', title: '80E', visible: false, child: [] },
            { key: 'via_ded_80g_50_amt', title: '80G(50%)', visible: false, child: [] },
            { key: 'via_ded_80g_100_amt', title: '80G(100%)', visible: false, child: [] },
            { key: 'via_ded_80g_oth_50_amt', title: '80G(Others 50%)', visible: false, child: [] },
            { key: 'via_ded_80g_oth_100_amt', title: '80G(Others 100%)', visible: false, child: [] },
            { key: 'via_ded_80tta_amt', title: '80TTA', visible: false, child: [] },
            { key: 'via_ded_80oth_amt', title: 'Others', visible: false, child: [] },
        ]
    },
    {
        title: 'Allowances',
        visible: false,
        child: [
            {
                key: 'alw_1', title: 'House rent allowance',
                visible: false,
                child: [
                    { key: 'alw_1_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_1_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_2', title: 'Children education allowance',
                visible: false,
                child: [
                    { key: 'alw_2_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_2_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_3', title: 'Children hostel allowance',
                visible: false,
                child: [
                    { key: 'alw_3_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_3_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_4', title: 'Transport allowance (Residence - Office)',
                visible: false,
                child: [
                    { key: 'alw_4_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_4_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_5', title: 'Conveyance allowance',
                visible: false,
                child: [
                    { key: 'alw_5_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_5_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_6', title: 'Helper allowance',
                visible: false,
                child: [
                    { key: 'alw_6_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_6_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_7', title: 'Uniform allowance',
                visible: false,
                child: [
                    { key: 'alw_7_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_7_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_8', title: 'Compensatory field area allowance',
                visible: false,
                child: [
                    { key: 'alw_8_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_8_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_13', title: 'Daily allowance',
                visible: false,
                child: [
                    { key: 'alw_13_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_13_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_14', title: 'Border area, remote locality or disturbed area or difficult area allowance',
                visible: false,
                child: [
                    { key: 'alw_14_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_14_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_9', title: 'Transfer allowance',
                visible: false,
                child: [
                    { key: 'alw_9_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_9_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_10', title: 'Traveling allowance',
                visible: false,
                child: [
                    { key: 'alw_10_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_10_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_11', title: 'Other special allowances u/s 1(14)',
                visible: false,
                child: [
                    { key: 'alw_11_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_11_ex_amt', title: 'Exempted' },
                ]
            },
            {
                key: 'alw_12', title: 'Any other allowance',
                visible: false,
                child: [
                    { key: 'alw_12_pr_amt', title: 'Provided Or Taxable' },
                    { key: 'alw_12_ex_amt', title: 'Exempted' },
                ]
            },
            
        ]
    }
]


const Menu = ({ outerRef, ...props }) => {
    const { xPos, yPos, menu, columnIndex } = useContextMenu(outerRef);
    const { handleRightClickSelection, setSelectedColumnIndex } = props;
    const [menuItem, setMenuItem] = useState(menuItems)

    //console.log('columnIndex', columnIndex, menu);

    const onMenuClick = (e, index) => {
        //e.preventDefault();

        menuItem[index].visible = !menuItem[index].visible;

        //disable all other menus
        menuItem.forEach((item, itemIndex) => {
            if (Number(index) !== Number(itemIndex)) item.visible = false;
        })


        setMenuItem([...menuItem]);
    };

    const onSubMenuClick = (e, index, subIndex) => {
        //e.preventDefault();

        menuItem[index].child[subIndex].visible = !menuItem[index].child[subIndex].visible;

        menuItem[index].child.forEach((item, itemIndex) => {
            if (Number(subIndex) !== Number(itemIndex)) item.visible = false;
        })

        setMenuItem([...menuItem]);
    }

    const handleSelectItem = (key) => {
        menuItem.forEach((item) => {
            item.visible = false;
            if (item.child.length > 0) {
                item.child.forEach((subItem) => {
                    subItem.visible = false;
                })
            }
        });
        setMenuItem([...menuItem]);

        if (handleRightClickSelection) {
            handleRightClickSelection(key, columnIndex); //right penal column index number
        }
    }


    useEffect(() => {
        if (!menu) {
            //on menu close, set all visible status to false
            menuItem.forEach((item) => {
                item.visible = false;
                if (item.child.length > 0) {
                    item.child.forEach((subItem) => {
                        subItem.visible = false
                    })
                }
            })
            setMenuItem([...menuItem]);
            setSelectedColumnIndex(() => null);
        } else {
            setSelectedColumnIndex(columnIndex);
        }
        // eslint-disable-next-line
    }, [menu, columnIndex])


    if (menu) {
        return (
            <>
                <Draggable>
                    <div className="MenuContainer">
                        <ul className="right-menu" style={{ top: yPos, left: xPos }}>
                            {
                                menuItem.map((menu, index) => {
                                    return (
                                        <>
                                            <li> <span className="link" onClick={(e) => onMenuClick(e, index)}>{menu.title}</span>

                                                {menu.child.length > 0 && <span class="expand">{menu.visible ? '-' : '+'}</span>}

                                                {menu.visible && (

                                                    <ul className="child">

                                                        {menu.child.map((item, subIndex) => {
                                                            if (item.child.length === 0) {
                                                                return (
                                                                    <li style={{ cursor: 'pointer' }} id={item.key} onClick={(e) => { handleSelectItem(item.key) }}>
                                                                        <span>{item.title}</span>
                                                                    </li>
                                                                )
                                                            }
                                                            return (
                                                                <>
                                                                    <li>
                                                                        <span className="link" onClick={(e) => onSubMenuClick(e, index, subIndex)}>{item.title}</span>

                                                                        {item.child.length > 0 && <span class="expand">{item.visible ? '-' : '+'}</span>}

                                                                        {
                                                                            (item.visible && item.child.length > 0) && (
                                                                                <>

                                                                                    <ul className="sub_child">
                                                                                        {
                                                                                            item.child.map((subItem) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <li style={{ cursor: 'pointer' }} id={subItem.key} onClick={(e) => { handleSelectItem(subItem.key) }} >
                                                                                                            <span>{subItem.title}</span>
                                                                                                        </li>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </>

                                                                            )
                                                                        }
                                                                    </li>
                                                                </>
                                                            )
                                                        }
                                                        )}
                                                    </ul>
                                                )}
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Draggable>
            </>
        );
    }
    return <></>;
};

export default Menu;