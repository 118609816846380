import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './ReurnDashboardemoVPopup.css'
import returndash_demovideo from "../../images/ExpressTDS_return_demo.gif";

const ReurnDashboardVPopup = (props) => {

    const {show, onHide} = props;

    return (
        <>
        <Modal className='returndash_videodemo_popup' backdrop="static" show={show} onHide={onHide}>
                <Modal.Header closeButton className="returndash_videodemo_header">
                    <Modal.Title className="returndash_videodemo_title" >
                        Create Return Help
                    </Modal.Title>
                </Modal.Header>            
                <Modal.Body className='pt-2 px-2 pb-2'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <img alt="Create Return Help" src={returndash_demovideo} style={{width:'100%'}} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

};

export default ReurnDashboardVPopup;