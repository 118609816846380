import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts';
import DeducteeSummary from './DeducteeSummary';
import {
  getCorrectionNatureOfPaymentSummary
} from '../../api/returnAPI';

const SectionWiseSummary = (props) => {
  const { deductorId, returnId, headerDataDetails } = props;
  const [chartData, setChartData] = useState([]);

  const fetchSectionSummary = async (deductor_id, return_id) => {
    try {
      const result = await getCorrectionNatureOfPaymentSummary(`?deductor_id=${deductor_id}&return_id=${return_id}&limit=5`);

      if (result.data.length > 0) {
        const chartD = [['Section', 'Total Transactions']];
        result.data.map((section) => {
          chartD.push([section.section_code, section.total_deductee_transactions]);
          return true;
        });
        setChartData(chartD);
      }
    } catch (err) {
    }
  };

  useEffect(() => {
    fetchSectionSummary(deductorId, returnId);
  }, [deductorId, returnId]);
  return (
    <div>
      <div className="row mt-1 mb-2">
        <div className="col-md-5">
          <fieldset className="sectionsummwaisef">
            <legend className="sectionsummwaiseleg">
              <h4>{(headerDataDetails && headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '27EQ') ? "Collection Code Wise Summary" : "Section wise Summary"}</h4>
            </legend>
            {chartData.length > 0 ? (
              <Chart
                width="100%"
                height="250px"
                chartType="PieChart"
                loader={<div style={{ paddingTop: '25px', paddingLeft: '5px', fontSize: '12px' }}>Loading Chart...</div>}
                data={chartData}
                options={{
                  pieSliceTextStyle: { color: 'black', fontWeight: '400' },
                  tooltip: {
                    ignoreBounds: true,
                    isHtml: true,
                  },
                  pieHole: 0.4,
                  chartArea: {
                    left: 25, right: 30, bottom: 0, top: 30,
                  },
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            ) : (
              <span className="dark_color_text" style={{ marginTop: '20px', fontSize: '12px' }}>No Records Found!</span>
            )}

          </fieldset>
        </div>
        <div className="col-md-7">

          <DeducteeSummary deductorId={deductorId} returnId={returnId} />
        </div>
      </div>

    </div>

  );
};

export default SectionWiseSummary;
