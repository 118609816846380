import * as React from 'react';
import {
    GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import { CustomFilterUI } from './CustomFilterUI';
import { ComplianceFilterUI } from './ComplianceFilterUI';
import { PanFilterUI } from './PanFilterUI';
import { ChallanFilterUI } from './ChallanFilterUI';

export class ColumnMenu extends React.Component {
    render() {
        return (
            <div>
                <GridColumnMenuFilter
                    {...this.props}
                    expanded={true}
                    hideSecondFilter={this.props.hideSecondFilter ? this.props.hideSecondFilter : false}
                    filterUI={(props) => this.props.panFilter ?
                        <PanFilterUI filterList={this.props.filterList} column={this.props.column} {...props} />
                        :
                        this.props.challanFilter ?
                            <ChallanFilterUI filterList={this.props.filterList} column={this.props.column} {...props} />
                            :
                            this.props.complianceFilter 
                                ? <ComplianceFilterUI filterList={this.props.filterList} column={this.props.column} {...props} />
                                : <CustomFilterUI filterList={this.props.filterList} column={this.props.column} {...props} />}
                />
            </div>
        );
    }
}