import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '../../../app/constants';
import LateInterestPopup from './LateInterestPopup';
import Skeleton2ColumnBox from '../../../components/Skeleton/Skeleton2ColumnBox';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { getTDSComputationSummaryData } from './TDSComputationSummary';

const LateDeductionNotes = (props) => {

  const { deductorId, returnId, setError, healthSummaryData, isSkeleton  } = props;

  const [visibleSections, setVisibleSections] = useState(new Set());
  const [lateDeductionInterestData, setLateDeductionInterestData] = useState({ title: 'Late Deduction Interest', trans: 0, amt: 0 });
  const [lateDepositInterestData, setLateDepositInterestData] = useState({ title: 'Late Deposit Interest', trans: 0, amt: 0 });
  const [unmappedTransactions, setUnmappedTransactions] = useState({ title: 'Unmapped Transaction', trans: 0, amt: 0 });
  const [lateFeesData, setLateFeesData] = useState({ title: 'Late Fees', date: '', amt: 0 });

  const LATE_DEDUCTION_INTEREST = 'LATE_DEDUCTION_INTEREST';
  const LATE_DEPOSIT_INTEREST = 'LATE_DEPOSIT_INTEREST';

  const getSectionData = (index) => {
    const section = Array.from(visibleSections)[index];
    return section === LATE_DEDUCTION_INTEREST ? lateDeductionInterestData : lateDepositInterestData;
  }

  const getFilterKey = (index) => {
    const section = Array.from(visibleSections)[index];
    return section === LATE_DEDUCTION_INTEREST ? 'date_mismatch' : 'interest_mismatch';
  }

  const getSectionKey = (index) => {
    const section = Array.from(visibleSections)[index];
    return section;
  }

  let popupIndex = 0;
  let totalPopups = 0;
  Array.from(visibleSections).forEach((deducteeID) => {
    if (visibleSections.has(deducteeID)) {
      totalPopups++;
    }
  });

  useEffect(() => {
    if (healthSummaryData) {
      const summary = getTDSComputationSummaryData(healthSummaryData?.tds_computation_summary ?? {});

      //Set Late Deduction Interest Data
      const lateDeductionData = lateDeductionInterestData;
      lateDeductionData.title = 'Late Deduction Interest';
      lateDeductionData.trans = healthSummaryData?.tds_computation_summary?.late_deduction_interest_trnxs ?? 0;
      lateDeductionData.amt = healthSummaryData?.tds_computation_summary?.late_deduction_interest_amtp ?? 0;
      setLateDeductionInterestData((prev) => {
        prev = lateDeductionData
        return { ...prev }
      })

      //Set Late Deposit Interest Data
      const lateDepositData = lateDepositInterestData;
      lateDepositData.title = 'Late Deposit Interest';
      lateDepositData.trans = healthSummaryData?.tds_computation_summary?.late_payment_interest_trnxs ?? 0;
      lateDepositData.amt = healthSummaryData?.tds_computation_summary?.late_payment_interest_amtp ?? 0;
      setLateDepositInterestData((prev) => {
        prev = lateDepositData
        return { ...prev }
      })

      //Set Unmapped Tranaction Data
      const unmappedTransactionsData = unmappedTransactions;
      unmappedTransactionsData.title = 'Unmapped Transaction';
      unmappedTransactionsData.trans = (healthSummaryData?.tds_computation_summary?.total_trnxs ?? 0) - (healthSummaryData?.tds_computation_summary?.mapped_trnxs ?? 0);
      unmappedTransactionsData.amt = summary?.total?.unmapped ?? 0;
      setUnmappedTransactions((prev) => {
        prev = unmappedTransactionsData
        return { ...prev }
      })

      //Set Late Fees Data
      const lateFeesSummaryData = lateFeesData;
      lateFeesSummaryData.title = 'Late Fees';
      lateFeesSummaryData.date = healthSummaryData?.tds_computation_summary?.late_fee_due_date;
      lateFeesSummaryData.amt = healthSummaryData?.tds_computation_summary?.late_fee_amtp ?? 0;
      setLateFeesData((prev) => {
        prev = lateFeesSummaryData
        return { ...prev }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthSummaryData])

  return (
    <>
      {Array.from(visibleSections).map((section) => {
        popupIndex = popupIndex + 1;
        const parms = {
          onClose: () => {
            const newVisibleSections = new Set(visibleSections);
            newVisibleSections.delete(section);
            setVisibleSections(newVisibleSections);
          },
          index: popupIndex - 1,
          totalPopups: totalPopups,
        }

        return (

          <LateInterestPopup
            returnId={returnId}
            deductorId={deductorId}
            sectionData={getSectionData(parms.index)}
            filterKey={getFilterKey(parms.index)}
            sectionKey={getSectionKey(parms.index)}
            setError={setError}
            {...parms}
          />
        )
      })}

      {
        isSkeleton
          ? <div className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12" style={{paddingTop:'1.7rem'}}><Skeleton2ColumnBox /></div>
          : <div className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12" style={{paddingTop:'1.7rem'}}>

            <div className="row">
              {
                Number(lateDeductionInterestData?.amt ?? 0) > 0
                  ?
                  <>
                    <div className="col-6 col-md-6 mb-2" onClick={() => {
                      let newVisibleSections = new Set(visibleSections);
                      if (!newVisibleSections.has(LATE_DEDUCTION_INTEREST)) {
                        newVisibleSections.add(LATE_DEDUCTION_INTEREST);
                        setVisibleSections(newVisibleSections);
                      }
                    }}>
                      <div className="card tdscomp_unmappedchallan h-100" >

                        <h4 className="tdscomp_liabilitt_textfs">{lateDeductionInterestData?.title}</h4>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr"> Trans.:</span> <span className="tdscomp_unmapped_amtbold">{lateDeductionInterestData?.trans ?? 0}</span>

                        </p>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr">Int. Amt.:</span> <span className="tdscomp_unmapped_amtbold">{formatNumber(lateDeductionInterestData?.amt ?? 0)}</span>
                        </p>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-6 col-md-6 mb-2" style={{ height: '95px' }}>
                      <div className="card tdscomp_unmappedchallan h-100" style={{ cursor: 'default' }}>
                        <h4 className="tdscomp_liabilitt_textfs">{lateDeductionInterestData?.title}</h4>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr">Great!! you have deducted TDS on time!</span>
                        </p>
                      </div>
                    </div>
                  </>
              }

              {
                Number(lateDepositInterestData?.amt ?? 0) > 0
                  ?
                  <>
                    <div className="col-6 col-md-6 mb-2" onClick={() => {

                      let newVisibleSections = new Set(visibleSections);
                      if (!newVisibleSections.has(LATE_DEPOSIT_INTEREST)) {
                        newVisibleSections.add(LATE_DEPOSIT_INTEREST);
                        setVisibleSections(newVisibleSections);
                      }
                    }}
                    >
                      <div className="card tdscomp_unmappedchallan h-100" >

                        <h4 className="tdscomp_liabilitt_textfs">{lateDepositInterestData?.title}</h4>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr"> Trans.:</span> <span className="tdscomp_unmapped_amtbold">{lateDepositInterestData?.trans ?? 0}</span>

                        </p>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr">Int. Amt.:</span> <span className="tdscomp_unmapped_amtbold">{formatNumber(lateDepositInterestData?.amt ?? 0)}</span>
                        </p>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-6 col-md-6 mb-2" style={{ height: '95px' }} >
                      <div className="card tdscomp_unmappedchallan h-100" style={{ cursor: 'default' }}>
                        <h4 className="tdscomp_liabilitt_textfs">{lateDepositInterestData?.title}</h4>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr">Great!! you have deposited TDS on time!</span>
                        </p>
                      </div>
                    </div>
                  </>
              }

            </div>

            <div className="row pt-2">
              {
                Number(lateFeesData?.amt ?? 0) > 0
                  ?
                  <>
                    <div className="col-6 col-md-6" >
                      <div className="card tdscomp_unmappedchallan h-100" style={{ cursor: 'default' }}>
                        <h4 className="tdscomp_liabilitt_textfs">{lateFeesData?.title}</h4>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr">Due Date:</span> <span className="tdscomp_unmapped_amtbold">{lateFeesData?.date}</span>
                        </p>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr">Amt.:</span> <span className="tdscomp_unmapped_amtbold">{formatNumber(lateFeesData?.amt)}</span>
                        </p>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-6 col-md-6" style={{ height: '95px' }}>
                      <div className="card tdscomp_unmappedchallan h-100" style={{ cursor: 'default' }}>
                        <h4 className="tdscomp_liabilitt_textfs">{lateFeesData?.title}</h4>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr"> Great!! You have filed the return on time!</span>
                        </p>
                      </div>
                    </div>
                  </>
              }


              {
                Number(unmappedTransactions?.trans ?? 0) > 0
                  ?
                  <>
                    <div className="col-6 col-md-6" >
                      <Link
                        target="_blank"
                        to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&additional_filter=33`}
                        tabIndex="-1"
                      >
                        <div className="card tdscomp_unmappedchallan h-100">
                          <h4 className="tdscomp_liabilitt_textfs black_txt">{unmappedTransactions?.title}</h4>
                          <p className="tdscomp_unmappedchallan_textfs">
                            <span className="tdscomp_trans_textclr"> Trans.:</span> <span className="tdscomp_unmapped_amtbold black_txt">{unmappedTransactions?.trans ?? 0}</span>
                          </p>
                          <p className="tdscomp_unmappedchallan_textfs">
                            <span className="tdscomp_trans_textclr">Amt.:</span> <span className="tdscomp_unmapped_amtbold black_txt">{
                            formatNumber((healthSummaryData?.tds_computation_summary?.liability_tds_amtp ?? 0) + (healthSummaryData?.tds_computation_summary?.liability_cess_amtp ?? 0) +(healthSummaryData?.tds_computation_summary?.liability_surcharge_amtp ?? 0)
                            - (healthSummaryData?.tds_computation_summary?.mapped_tds_amtp ?? 0) - (healthSummaryData?.tds_computation_summary?.mapped_liability_surcharge_amtp ?? 0) -(healthSummaryData?.tds_computation_summary?.mapped_liability_cess_amtp ?? 0)
                            
                            )}</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </>
                  : <>
                    <div className="col-6 col-md-6" style={{ height: '95px' }}>
                      <div className="card tdscomp_unmappedchallan h-100" style={{ cursor: 'default' }}>
                        <h4 className="tdscomp_liabilitt_textfs black_txt">{unmappedTransactions?.title}</h4>
                        <p className="tdscomp_unmappedchallan_textfs">
                          <span className="tdscomp_trans_textclr">Great!! You have mapped all transactions!</span>
                        </p>
                      </div>
                    </div>
                  </>
              }
            </div>
          </div>
      }


    </>

  );
};

export default LateDeductionNotes;
