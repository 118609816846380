import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import exprtIcon from '../../../images/icons/export.svg';
import importIcon from '../../../images/icons/import.svg';
import editIcon from '../../../images/icons/edit.svg';
import Spinner from 'react-bootstrap/Spinner';
import ShowAlert from '../../../components/ShowAlert';
import { importDeducteesSalaryTdsCalculator, downloadDeducteesSalaryTdsCalculator } from '../../../api/salaryAPI';
import moment from 'moment';
import DownloadFile from '../../../components/DownloadFile';

const ExcelExportImport = ({ history, ...props }) => {
  const { deductorId, handleSucessProcees, financial_year } = props;
  const [loading, setLoading] = useState(false);  
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [files, setFiles] = useState([]);
  const { isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject, } = useDropzone({ accept: '.xls,.xlsx,.xlsm', });

  const [downloadExcelFile, setDownloadExcelFile] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const removeFile = (targetFile) => {
    const newFiles = [];
    files.forEach((file) => {
      if (file.path === targetFile.path) return;
      newFiles.push(file);
    });
    setFiles(newFiles);
  };

  const renderFiles = files.map((file) => (
    <li key={file.path} style={{ margin: '5px' }}>
      <span>
        {file.path}
        {' '}
        -
        {file.size}
        {' '}
        bytes
      </span>
      <Button
        variant="danger"
        style={{
          fontSize: '12px', padding: '0px', paddingLeft: '5px', paddingRight: '5px', marginLeft: '10px',
        }}
        onClick={() => removeFile(file)}
      >
        Remove
      </Button>
    </li>
  ));

  const uploadFiles = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append(`deductor_id`, deductorId);
    formData.append(`financial_year`, financial_year);
    formData.append(`form_type`, '24Q');
    formData.append(`quarter`, 'Q4');
    files.map((file) => {
      formData.append(`file`, file);
      formData.append(`created_at`, moment(file.lastModified).format('YYYY-MM-DD'));
      return true;
    });

    try {
      const result = await importDeducteesSalaryTdsCalculator(formData);
      if (result && result.status === 1) {
        setSuccess(result.message);
        handleSucessProcees(result.message);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    // const newFiles = acceptedFiles;
    const newFiles = [...files, ...acceptedFiles];
    const uniqueFiles = [];
    const flags = [];
    const l = newFiles.length;
    if (newFiles.length) {
      flags[newFiles[l - 1]?.path] = true;
      uniqueFiles.push(newFiles[l - 1]);
    }
    //let i;
    // for (i = 0; i < l; i++) {
    //   console.log('>>',newFiles[i]);
    //   if (flags[newFiles[i]?.path]) continue;
    //   flags[newFiles[i]?.path] = true;
    //   uniqueFiles.push(newFiles[i]);
    // }
    setFiles(uniqueFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);


  return (
    <>

      {downloadExcelFile &&
        <DownloadFile
          apiFunction={downloadDeducteesSalaryTdsCalculator}
          setDownloadLoading={setDownloadLoading}
          setError={setError}
          fileName={'ExpressTDS-DeducteesWithEmailDetails'}
          setDownloadExcelFile={setDownloadExcelFile}
          sessionStorageKey={`export-salary-tds-calculator-emails-ref-key-${deductorId}-${financial_year}`}
          params={`?deductor_id=${deductorId}&financial_year=${financial_year}`}
          handleSuccessProceed={() => {
            setDownloadExcelFile(false);
          }}
          handleErrorProceed={(msg) => {
            setDownloadExcelFile(false);
          }}
        />
      }

      <div className="col-xl-3 col-lg-5 col-md-5 col-sm-3 col-xs-12">
        <ShowAlert
          error={error}
          success={success}
          onClose={() => {
            setError(null);
            setSuccess(null);
          }}
          headerStyle={{
            position: 'absolute', top: 0, left: 0, width: '100%', fontSize: '13.5px',
          }}
        />
        <div className="card cardsuppsendtosupp mailshadowbox">
          <h4 className="wantuptextfs">Want to Update through Excel?</h4>
          <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
            <table className="table table-borderless updatesidetb">

              <tbody>
                <tr>
                  <td width="125"><span class="pr-1"><img src={exprtIcon} width="16" alt="" /></span> Step 1:</td>
                  <td width="210">Export Employees Comm. details in Excel Format</td>
                  <td width={downloadLoading ? '146' : ''}>
                    <button type="button" className="btn btn-default exportrightsbtn" onClick={() => setDownloadExcelFile(true)}>
                      {downloadLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {' '}
                          Loading...
                        </>
                      ) : 'Export'}

                    </button>
                  </td>
                </tr>
                <tr>
                  <td width="125"><span class="pr-1"><img src={importIcon} width="14" alt="" /></span> Step 2:</td>
                  <td width="210" colspan="2"> Add/Update <span className="textfsbold">Email Address</span> Details in the Exported Excel</td>
                </tr>
                <tr>
                  <td width="125"><span class="pr-1"><img src={editIcon} width="16" alt="" /></span> Step 3:</td>
                  <td width="210" colspan="2"> Browse and Import the Updated Excel</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12">
            <div {...getRootProps({ className: ' importdropzone dropzone dropzonestyle' })}>
              <input {...getInputProps({ accept: '.xls,.xlsx,.xlsm', multiple: false })} />
              <p className="textfs12 text-center">
                {!isDragActive && 'Click here to Browse or Drag n Drop  the Updated File'}
                {isDragActive && !isDragReject && 'Drop files here'}
                {isDragReject && 'File type not accepted, sorry!'}
              </p>
            </div>
            <p className="fileselectedtext">
              File Selected:
            </p>
            <ul style={{ fontSize: '11px' }}>{renderFiles}</ul>
          </div>
          <div className="col-md-12 text-center">
            <div onClick={uploadFiles} className="btn btn-default exportrightsbtn" disabled={loading || files.length === 0}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {' '}
                  Loading...
                </>
              ) : 'Import'}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcelExportImport;
