import React, { useState, useEffect } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Spinner from 'react-bootstrap/Spinner';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { gocallApi } from '../../../api/issueCertificateAPI';
import ShowAlert from '../../../components/ShowAlert';
import WindowWrapper from '../../../components/grid/WindowWrapper';
import { formatNumber } from '../../../utils/UtilityFunctions';
import "../returnsDashboardV2/ModalPopup.scss";

const MappedTransPopup = (props) => {
    const { show, handleClose, totalpopups, index, financial_year, showData, paymentCodes } = props;

    const [data, setData] = useState();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [dataState, setDataState] = useState({});
    const [cursor, setCursor] = useState({ current_page: 1, next_page: null });
    const [loading, setLoading] = useState(false);

    const getMappedTransitionData = async (data_state, showData, financial_year, cursor) => {
        const payload = {
            challan_id: showData?.challan_id ?? "",
            deductor_id: showData?.deductor_id ?? "",
            get_by_other_return: true,
            limit: 16,
            fin_year: financial_year,
            page: cursor?.next_page ? cursor?.next_page : 1,
            sort_by: data_state?.sort?.[0]?.dir ?? "",
            sort_on: data_state?.sort?.[0]?.field ?? "",
            filters: data_state?.filter?.filters ?? []
        }
        setLoading(true)
        const url = `/api/v2/transaction/mapped-trans`
        try {
            const result = await gocallApi("post", url, payload);
            if (cursor?.next_page > 1) {
                setData((prev) => {
                    prev = prev.concat(result?.data?.data ?? []);
                    return [...prev]
                })
            } else {
                setData(result?.data?.data ?? []);
            }
            setCursor(result?.data?.cursor ?? { current_page: 1, next_page: null });

        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false);
        }
    }

    const dataStateChange = (data_state) => {
        setDataState(data_state);
    }


    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem ?? {};
        const dataIndex = props.dataIndex ?? null;
        return (
            <tr key={dataItem.deductee_id ?? dataIndex}>
                <td className="text-center" style={{ paddingRight: "25px" }}>{dataItem.form_type ?? ""}</td>
                <td className="text-center" style={{ paddingRight: "25px" }}>{dataItem.quarter ?? ""}</td>
                <td className="text-left overflowtextsendtosupptbtd" title={dataItem.deductee_name ?? ""}>{dataItem.deductee_name ?? ""}</td>
                <td className="text-left">{dataItem.pan ?? ""}</td>
                <td className="text-left">{dataItem.display_code ?? ""}</td>
                <td className="text-center">{dataItem.tds_rate_b100 ?? ""}</td>
                <td className="text-center">{dataItem.payment_date ?? ""}</td>
                <td className="text-center">{dataItem.deduction_date ?? ""}</td>
                <td className="text-right">{formatNumber(dataItem.paid_amtp ?? "0")}</td>
                <td className="text-right">{formatNumber(dataItem.total_tds_amtp ?? "0")}</td>

            </tr>
        )

    }

    useEffect(() => {
        if (showData?.challan_id) {
            getMappedTransitionData(dataState, showData, financial_year)
        }

    }, [showData?.challan_id, dataState, showData, financial_year])


    return (
        <>
        
            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            <div className="mappedtransitionchallans">
                {show &&
                    <WindowWrapper
                        title={
                            <h4 className="modal-title">
                                <span className="challanno_textfs"> <span>Challan No: </span><span className="challan_numtextfsclr">{showData?.challan_no ?? ""}</span></span>
                                <span className="challanno_vline"> | </span>

                                <span className="challanno_textfs"><span>Deposit Date: </span> <span className="challan_numtextfsclr">{showData?.deposit_date ?? ""}</span></span>
                                <span className="challanno_vline"> | </span>

                                <span className="challanno_textfs"><span>BSR Code: </span> <span className="challan_numtextfsclr">{showData?.bsr_code ?? ""}</span>	</span>
                                <span className="challanno_vline"> | </span>
                                <span className="challanno_textfs"><span>Challan Amount: </span> <span className="challan_numtextfsclr">{formatNumber(showData?.total_amtp ?? 0, "-", false)}</span></span>
                            </h4>}

                        onClose={handleClose}
                        totalPopups={totalpopups}
                        index={index}
                        initialHeight={360}
                        className="mapped_trans_modal mappedtransitionchallans"
                        style={{ maxHeight: '360px', overflowY: 'auto' }}

                    >

                        <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <Tooltip anchorElement="pointer" position="right">
                                <Grid
                                    className="table table-striped blukimport_tb mb-0 scrollvisible"
                                    data={data}
                                    {...dataState}
                                    sortable
                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterEqOperator', operator: 'in' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                >
                                    <Column
                                        width="50"
                                        field="form_type"
                                        title={"Form"}
                                        filter="text"
                                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('form_type') ? 'active' : ''}
                                    />
                                    <Column
                                        width="50"
                                        field="quarter"
                                        title={"Quarter"}
                                        filter="text"
                                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('quarter') ? 'active' : ''}
                                    />

                                    <Column
                                        width="120"
                                        field="deductee_name"
                                        title={"Deductee Name"}
                                        filter="text"
                                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('deductee_name') ? 'active' : ''}

                                    />

                                    <Column
                                        width="65"
                                        field="pan"
                                        title="PAN"
                                        filter="text"
                                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('pan') ? 'active' : ''}
                                    />

                                    <Column
                                        width="50"
                                        field="code"
                                        title="Section"
                                        filter="dropdown"
                                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter filterList={paymentCodes} {...props} />)}
                                        headerClassName={isColumnActive('code') ? 'active' : ''}
                                    />


                                    <Column
                                        width="60"
                                        field="tds_rate_b100"
                                        title={"Rate(%)"}
                                        filter="numeric"
                                        columnMenu={dataState && ((props) => <ColumnMenu {...props} />)}
                                        headerClassName={isColumnActive('tds_rate_b100') ? 'active' : ''}

                                    />

                                    <Column
                                        width="80"
                                        field="payment_date"
                                        title={"Payment Date"}
                                        filter="date"
                                        columnMenu={dataState && ((props) => <ColumnMenu {...props} />)}
                                        headerClassName={isColumnActive('payment_date') ? 'active' : ''}
                                    />
                                    <Column
                                        width="80"
                                        field="deduction_date"
                                        title={"Deduction Date"}
                                        filter="date"
                                        columnMenu={dataState && ((props) => <ColumnMenu  {...props} />)}
                                        headerClassName={isColumnActive('deduction_date') ? 'active' : ''}
                                    />

                                    <Column
                                        width="100"
                                        field="paid_amtp"
                                        title={"Amt Paid"}
                                        filter="numeric"
                                        columnMenu={dataState && ((props) => <ColumnMenu  {...props} />)}
                                        headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                                    />

                                    <Column
                                        width="100"
                                        field="total_tds_amtp"
                                        title={"Total Tax Deducted"}
                                        filter="numeric"
                                        columnMenu={dataState && ((props) => <ColumnMenu  {...props} />)}
                                        headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                                    />


                                </Grid>
                            </Tooltip>

                            {cursor.next_page && <div className="text-center pt-1 showmorebox" >
                                <span onClick={() => {
                                    cursor.next_page = cursor.next_page + 1
                                    setCursor(cursor)
                                    getMappedTransitionData(dataState, showData, financial_year, cursor)
                                }}>
                                    {loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {' '}
                                            Loading...
                                        </>
                                    ) : 'ShowMore'}

                                </span>
                            </div>}
                        </div>

                    </WindowWrapper >}
            </div>
        </>
    )
}

export default MappedTransPopup