import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { STATES, DEDUCTOR_CATEGORIES_LIST, } from '../../../app/constants';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { Formik, Form, Field } from 'formik';
import { deductorFormValidationSchema } from './DeductorFormSchema';
import ErrorFocus from '../../../components/ErrorFocus';
import AlphaNumericInput from '../../../components/AlphaNumericInput';
import NumericInput from '../../../components/NumericInput';
import PANInput from '../../../components/PANInput';
import infoIcon from '../../../images/info.png';
import ShowAlert from '../../../components/ShowAlert';
import Button from 'react-bootstrap/Button';

import './EditDeductorForm.css';
import ConnectorAdapter from '../../../components/kdkConnector/ConnectorAdapter';
import TracesConnector from '../../../components/kdkConnector/TracesConnector';
import SinglePanVerify from '../../../components/verifyPAN/SinglePanVerify';
import exclamationIcon from "../../../images/exclamation.png";

const EditDeductorForm = (props) => {
  const { onClose, deductorData, saveDeductor, error, setError, deductorId } = props;



  const [showSinglePAN, setShowSinglePAN] = useState(false);
  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [verifyPanNumber, setVerifyPanNumber] = useState(false);
  const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
  const [verifyPANResult, setVerifyPANResult] = useState(null);
  const [deductorSectionPanVerifyStatus, setDeductorSectionPanVerifyStatus] = useState(false);
  const [responsibleSectionPanVerifyStatus, setResponsibleSectionPanVerifyStatus] = useState(false);
  const pattPAN = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    $("#save_deductor_btn").trigger("click");
  }, [])

  return (
    <>
      <WindowWrapperFixed
        title={
          <h4 className="modal-title w-100 text-center">{deductorData?.deductor_name} | {DEDUCTOR_CATEGORIES_LIST?.[deductorData?.deductor_category] ?? 'N/A'}</h4>
        }
        onClose={onClose}
        totalPopups={1}
        index={0}
        initialHeight={'auto'}
        //className={'editDeductorForm'}
        initialLeft={150}
      >

        <Formik
          initialValues={deductorData}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={deductorFormValidationSchema}
          onSubmit={(values) => {
            // same shape as initial values
            if (saveDeductor) saveDeductor(values);
          }}
        >
          {({
            errors, values, setValues, setFieldValue,
          }) => (
            <Form key={1} className="cdm" autoComplete="off">

              {showSinglePAN ?
                <ConnectorAdapter
                  show={showSinglePAN}
                  handleClose={() => setShowSinglePAN(false)}
                  setPort={setPort}
                  handleProceed={() => setCheckTracesCredentials(true)}
                />
                : null}

              {checkTracesCredentials && port
                ? <TracesConnector
                  deductorId={deductorId}
                  returnId={null}
                  handleProceed={() => {
                    setShowSinglePAN(false);
                    setCheckTracesCredentials(false);
                    setVerifyPanNumber(false);
                  }}
                  port={port}
                  setCaptchaRef={setCaptchaRef}
                  creds={{
                    "tan": values.tan,
                    "traces_username": values.traces_username,
                    "traces_password": values.traces_password
                  }}
                />
                : null
              }

              {verifyPanNumber && captchaRef && port
                ? <SinglePanVerify
                  deductorId={deductorId}
                  port={port}
                  captchaRef={captchaRef}
                  setError={() => null}
                  setVerifyPANResult={setVerifyPANResult}
                  pan={values.pan}
                  setLoading={() => null}
                  handleProceed={() => {
                    setVerifyPanNumber(false)
                  }}
                />
                : null
              }


              <ErrorFocus />
              {/* {console.log('errors', errors)} */}
              <ShowAlert
                error={error}
                onClose={() => { setError(null); }}
              />
              <div className="modal-body pt-1 pb-1 px-2">
                <div className="row">
                  <div className="col-md-6">
                    <fieldset className="deductor_details_fs">
                      <legend className="deductor_details_leg">
                        <h4>Deductor Details
                        </h4>
                      </legend>
                      <div className="form-row tds_editdeduct_form">

                        {/* <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            Deductor Name<span className="red_text">*</span>
                          </label>
                          <Field
                            type="name"
                            className={`form-control ${errors.deductor_name ? 'input-error1' : ''}`}
                            id="deductor_name"
                            placeholder="Enter Deductor Name"
                            name="deductor_name"
                            autocomplete="no"
                            maxLength={75}
                          />
                          {errors.deductor_name ? <span className="error_message">{errors.deductor_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            Category<span className="red_text">*</span>
                          </label>
                          <Field
                            as="select"
                            className={`form-control ${errors.deductor_category ? 'input-error1' : ''}`}
                            id="deductor_category"
                            name="deductor_category"
                          >
                            {<option value="" >Choose Category</option>}
                            {Object.keys(DEDUCTOR_CATEGORIES_LIST).map((categoryId) =>
                              <option key={categoryId} value={categoryId}>{DEDUCTOR_CATEGORIES_LIST[categoryId]}</option>
                            )}
                          </Field>
                          {errors.deductor_category ? <span className="error_message" style={{ display: 'block' }}>{errors.deductor_category}</span> : null}
                        </div> */}

                        {/* <div className="form-group col-md-12 colbotpaddrem"> */}
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label className="tds_editdeduct_leb">PAN</label><span className="textred">*</span>
                          <PANInput
                            value={values.pan}
                            className={`${errors.pan ? 'input-error1' : null}`}
                            onChange={(v) => {
                              setFieldValue('pan', v.trim().toUpperCase());
                            }}
                            data={['PANNOTREQD']}
                          />
                          {errors.pan ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.pan}</span> : null}

                          {
                            deductorId ? (
                              deductorSectionPanVerifyStatus && verifyPANResult ? (<span className={verifyPANResult.toLowerCase() === 'active' ? 'float-right green_text mt-1' : 'float-right red_text mt-1'} style={{ fontSize: '11px' }}>{verifyPANResult}</span>)
                                :
                                (
                                  <div>
                                    {

                                      pattPAN.test(values.responsible_person_pan) ? (
                                        <Button
                                          variant="link"
                                          className="float-right dark_color_text"
                                          style={{
                                            fontSize: '12px',
                                            paddingTop: '0px',
                                            paddingRight: '0px',
                                            cursor: 'pointer'
                                          }}
                                          disabled={!values.traces_username || !values.traces_password || !values.tan || !values.responsible_person_pan}
                                          onClick={() => {
                                            setShowSinglePAN(true);
                                            setVerifyPanNumber(true);

                                            setDeductorSectionPanVerifyStatus(true);
                                            setResponsibleSectionPanVerifyStatus(false);
                                          }}
                                        >
                                          Verify
                                        </Button>
                                      ) : null
                                    }
                                  </div>
                                )
                            ) : null

                          }


                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label className="tds_editdeduct_leb">GSTIN
                          </label>
                          <AlphaNumericInput
                            type="text"
                            className={`form-control ${errors.gst_no ? 'input-error1' : ''}`}
                            id="gst_no"
                            placeholder="Enter GSTIN"
                            name="gst_no"
                            maxLength={15}
                            value={values.gst_no}
                            onChange={(e) => setFieldValue('gst_no', e.target.value.trim().toUpperCase())}
                          />
                          {errors.gst_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.gst_no}</span> : null}
                        </div>

                        {/* </div> */}
                        <div className="form-group col-md-12 pb-2">
                          <h6 className="Edit_deductor_titlefs mb-0" style={{ padding: '20px 0px 19px 0px;' }} >Deductor Contact Information</h6>
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            Mobile Number<span className="red_text">*</span>
                          </label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors.contact_info_primary_mobile_no ? 'input-error1' : ''}`}
                            id="contact_info_primary_mobile_no"
                            placeholder="Enter Mobile Number"
                            name="contact_info_primary_mobile_no"
                            maxLength={10}
                            value={values.contact_info_primary_mobile_no}
                            onChange={(e) => setFieldValue('contact_info_primary_mobile_no', e.target.value)}
                          />
                          {errors.contact_info_primary_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_info_primary_mobile_no}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">Alternate Mobile Number</label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors.contact_info_alternate_mobile_no ? 'input-error1' : ''}`}
                            id="contact_info_alternate_mobile_no"
                            placeholder="Enter Alternate Mobile Number"
                            name="contact_info_alternate_mobile_no"
                            maxLength={10}
                            value={values.contact_info_alternate_mobile_no}
                            onChange={(e) => setFieldValue('contact_info_alternate_mobile_no', e.target.value)}
                          />
                          {errors.contact_info_alternate_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_info_alternate_mobile_no}</span> : null}

                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            Email<span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors.contact_info_primary_email ? 'input-error1' : ''}`}
                            id="contact_info_primary_email"
                            placeholder="Enter Email"
                            name="contact_info_primary_email"
                            autocomplete="no"
                          />
                          {errors.contact_info_primary_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_info_primary_email}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">Alternate Email</label>
                          <Field
                            type="text"
                            className={`form-control ${errors.contact_info_alternate_email ? 'input-error1' : ''}`}
                            id="contact_info_alternate_email"
                            placeholder="Enter Alternate Email"
                            name="contact_info_alternate_email"
                            autocomplete="no"
                          />
                          {errors.contact_info_alternate_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_info_alternate_email}</span> : null}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6">
                    <fieldset className="Responsiblepersondetails_fs">
                      <legend className="Responsiblepersondetails_leg">
                        <h4>Responsible Person Details
                        </h4>
                      </legend>
                      <div className="form-row tds_editdeduct_form">
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label className="tds_editdeduct_leb">
                            Name of Person<span className="textred">*</span>
                          </label>
                          <Field
                            type="name"
                            className={`form-control ${errors.responsible_person_contact_info_person_name ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_person_name"
                            placeholder="Enter Name"
                            name="responsible_person_contact_info_person_name"
                            autocomplete="no"
                            maxLength={75}
                          />
                          {errors?.responsible_person_contact_info_person_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_contact_info_person_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label className="tds_editdeduct_leb">PAN<span className="textred">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors.responsible_person_pan ? 'input-error1' : ''}`}
                            id="responsible_person_pan"
                            placeholder="Enter Pan"
                            name="responsible_person_pan"
                            autocomplete="no"
                            maxLength={10}
                          />
                          {errors.responsible_person_pan ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_pan}</span> : null}



                          {
                            deductorId ? (
                              responsibleSectionPanVerifyStatus && verifyPANResult ? (<span className={verifyPANResult.toLowerCase() === 'active' ? 'float-right green_text mt-1' : 'float-right red_text mt-1'} style={{ fontSize: '11px' }}>{verifyPANResult}</span>)
                                :
                                (
                                  <div>
                                    {

                                      pattPAN.test(values.responsible_person_pan) ? (
                                        <Button
                                          variant="link"
                                          className="float-right dark_color_text"
                                          style={{
                                            fontSize: '12px',
                                            paddingTop: '0px',
                                            paddingRight: '0px',
                                            cursor: 'pointer'
                                          }}
                                          disabled={!values.traces_username || !values.traces_password || !values.tan || !values.responsible_person_pan}
                                          onClick={() => {
                                            setShowSinglePAN(true);
                                            setVerifyPanNumber(true);
                                            setResponsibleSectionPanVerifyStatus(true);
                                            setDeductorSectionPanVerifyStatus(false);
                                          }}
                                        >
                                          Verify
                                        </Button>
                                      ) : null
                                    }
                                  </div>
                                )
                            ) : null

                          }

                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label className="tds_editdeduct_leb">Designation<span className="textred">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors.responsible_person_designation ? 'input-error1' : ''}`}
                            id="responsible_person_designation"
                            placeholder="Enter Designation"
                            name="responsible_person_designation"
                            autocomplete="no"
                            maxLength={20}
                          />
                          {errors.responsible_person_designation ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_designation}</span> : null}
                        </div>
                        <div className="form-group col-md-7 pt-2">
                          <h6 className="Edit_deductor_titlefs mb-0">Responsible person Contact Information</h6>
                        </div>
                        <div className="form-group col-md-5 pt-2 text-right">
                          {/* <button className="btn btn-default copyform_btnbgclr">Copy from Deductor</button> */}
                          <button
                            type="button"
                            className="btn btn-default copyform_btnbgclr"
                            onClick={() => {
                              setValues({
                                ...values,
                                responsible_person_contact_info_primary_mobile_no: values.contact_info_primary_mobile_no,
                                responsible_person_contact_info_alternate_mobile_no: values.contact_info_alternate_mobile_no,
                                responsible_person_contact_info_primary_email: values.contact_info_primary_email,
                                responsible_person_contact_info_alternate_email: values.contact_info_alternate_email,
                              });
                            }}
                          >
                            Copy from Deductor
                          </button>
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            Mobile Number<span className="red_text">*</span>
                          </label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors.responsible_person_contact_info_primary_mobile_no ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_primary_mobile_no"
                            placeholder="Enter Mobile Number"
                            name="responsible_person_contact_info_primary_mobile_no"
                            maxLength={10}
                            value={values.responsible_person_contact_info_primary_mobile_no}
                            onChange={(e) => setFieldValue('responsible_person_contact_info_primary_mobile_no', e.target.value)}
                          />
                          {errors.responsible_person_contact_info_primary_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_contact_info_primary_mobile_no}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">Alternate Mobile Number</label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors.responsible_person_contact_info_alternate_mobile_no ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_alternate_mobile_no"
                            placeholder="Enter Alternate Mobile Number"
                            name="responsible_person_contact_info_alternate_mobile_no"
                            maxLength={10}
                            value={values.responsible_person_contact_info_alternate_mobile_no}
                            onChange={(e) => setFieldValue('responsible_person_contact_info_alternate_mobile_no', e.target.value)}
                          />
                          {errors.responsible_person_contact_info_alternate_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_contact_info_alternate_mobile_no}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            Email<span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors.responsible_person_contact_info_primary_email ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_primary_email"
                            placeholder="Enter Email"
                            name="responsible_person_contact_info_primary_email"
                            autocomplete="no"
                          />
                          {errors.responsible_person_contact_info_primary_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_contact_info_primary_email}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">Alternate Email</label>
                          <Field
                            type="text"
                            className={`form-control ${errors.responsible_person_contact_info_alternate_email ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_alternate_email"
                            placeholder="Enter Alternate Email"
                            name="responsible_person_contact_info_alternate_email"
                            autocomplete="no"
                          />
                          {errors.responsible_person_contact_info_alternate_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_contact_info_alternate_email}</span> : null}
                        </div>
                        <div className="form-group col-md-12 mt-1 ">
                          <p className="verify_btn_bottom_margin createdcontp mr-2 mb-0">
                            Is contact person same as responsible person?

                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title="This information will be used to send Email and SMS to this deductor. It is not related to the eTDS filing information."
                            >
                              {' '}
                              <img src={infoIcon} width="15px" alt="" style={{ paddingLeft: '2px' }} />
                            </span>

                            <span className="form-check mr-2 ml-4">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="is_cp_same_as_rp"
                                id="gridRadios1"
                                value="yes"
                              />
                              <label className="form-check-label" htmlFor="gridRadios1">
                                Yes
                              </label>
                            </span>
                            <span className="form-check mr-2 ml-4">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="is_cp_same_as_rp"
                                id="gridRadios2"
                                value="no"
                              />
                              <label className="form-check-label" htmlFor="gridRadios2">
                                No
                              </label>
                            </span>
                          </p>
                        </div>
                      </div>
                    </fieldset>

                  </div>
                </div>

                {values.is_cp_same_as_rp === 'no' && (
                  <div className=" row">
                    <div className="col-md-12">
                      <fieldset className="editedcdfieldset_fs">
                        <legend className="editedcdfieldset_leg">
                          <h4>Contact Person Details</h4>
                        </legend>
                        <div className="contact_person mt-1 colbotpaddrem">
                          <div className="form-row tds_editdeduct_form">
                            <div className="form-group col-md-4">
                              <label for="">
                                Name of Person<span className="red_text">*</span>
                              </label>
                              <Field
                                type="name"
                                className={`form-control ${errors.contact_person_contact_info_person_name ? 'input-error1' : ''}`}
                                id="contact_person_contact_info_person_name"
                                placeholder="Enter Name"
                                name="contact_person_contact_info_person_name"
                                autocomplete="no"
                                maxLength={75}
                              />
                              {errors.contact_person_contact_info_person_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_person_contact_info_person_name}</span> : null}
                            </div>
                            <div className="form-group col-md-4">
                              <label for="">
                                Mobile Number<span className="red_text">*</span>
                              </label>
                              <NumericInput
                                type="text"
                                className={`form-control ${errors.contact_person_contact_info_primary_mobile_no ? 'input-error1' : ''}`}
                                id="contact_person_contact_info_primary_mobile_no"
                                placeholder="Enter Mobile Number"
                                name="contact_person_contact_info_primary_mobile_no"
                                maxLength={10}
                                value={values.contact_person_contact_info_primary_mobile_no === '+91' ? '' : values.contact_person_contact_info_primary_mobile_no}
                                onChange={(e) => setFieldValue('contact_person_contact_info_primary_mobile_no', e.target.value)}
                              />
                              {errors.contact_person_contact_info_primary_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_person_contact_info_primary_mobile_no}</span> : null}
                            </div>
                            <div className="form-group col-md-4">
                              <label for="">
                                Email<span className="red_text">*</span>
                              </label>
                              <Field
                                type="text"
                                className={`form-control ${errors.contact_person_contact_info_primary_email ? 'input-error1' : ''}`}
                                id="contact_person_contact_info_primary_email"
                                placeholder="Enter Email"
                                name="contact_person_contact_info_primary_email"
                                autocomplete="no"
                              />
                              {errors.contact_person_contact_info_primary_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_person_contact_info_primary_email}</span> : null}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}
                
                <div className="row">
                  <div className="col-md-6">
                    <fieldset className="deductor_add_info_fs">
                      <legend className="deductor_add_info_leg">
                        <h4>Deductor Address Information</h4>
                      </legend>
                      <div className="form-row tds_editdeduct_form">
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            Flat/Door/Block Number<span className="red_text">*</span>
                          </label>
                          <Field
                            type="uniquecode*"
                            className={`form-control ${errors.address_building_no ? 'input-error1' : ''}`}
                            id="address_building_no"
                            placeholder="Enter Flat/Door/Block Number"
                            name="address_building_no"
                            autocomplete="no"
                            maxLength={25}
                          />
                          {errors.address_building_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_building_no}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">Building Name</label>
                          <Field
                            type="text"
                            className={`form-control ${errors.address_building_name ? 'input-error1' : ''}`}
                            id="address_building_name"
                            placeholder="Enter Building Name"
                            name="address_building_name"
                            maxLength={25}
                            autocomplete="no"
                          />
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">Street/ Road Name</label>
                          <Field
                            type="text"
                            className={`form-control ${errors.address_street_name ? 'input-error1' : ''}`}
                            id="address_street_name"
                            placeholder="Enter Street/ Road Name"
                            name="address_street_name"
                            autocomplete="no"
                            maxLength={25}
                          />
                          {errors.address_street_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_street_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">Area</label>
                          <Field
                            type="text"
                            className={`form-control ${errors.address_area ? 'input-error1' : ''}`}
                            id="address_area"
                            placeholder="Enter Area"
                            name="address_area"
                            maxLength={25}
                            autocomplete="no"
                          />
                          {errors.address_area ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_area}</span> : null}

                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            City<span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors.address_city ? 'input-error1' : ''}`}
                            id="address_city"
                            placeholder="Enter City"
                            name="address_city"
                            maxLength={25}
                            autocomplete="no"
                          />
                          {errors.address_city ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_city}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="address_state_id">
                            State<span className="red_text">*</span>
                          </label>
                          <Field
                            as="select"
                            className="form-control"
                            id="address_state_id"
                            name="address_state_id"
                          >
                            {(parseInt(values.address_state_id) > 0) ? (<option value="" disabled>Select State</option>) : (<option value="" selected='selected'>Select State</option>)}
                            {Object.keys(STATES).map((stateId) => <option key={stateId} value={stateId}>{STATES[stateId]}</option>)}
                          </Field>
                          {errors.address_state_id ? <span className="error_message">{errors.address_state_id}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="">
                            Pincode<span className="red_text">*</span>
                          </label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors.address_pincode ? 'input-error1' : ''}`}
                            id="address_pincode"
                            placeholder="Enter Pincode"
                            name="address_pincode"
                            maxLength={6}
                            value={values.address_pincode}
                            onChange={(e) => setFieldValue('address_pincode', e.target.value)}
                            autocomplete="no"
                          />
                          {errors.address_pincode ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_pincode}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="branch">
                            Branch<span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors.branch ? 'input-error1' : ''}`}
                            id="branch"
                            placeholder="Enter Branch"
                            name="branch"
                            maxLength={75}
                            autocomplete="no"
                          />
                          {errors.branch ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.branch}</span> : null}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6">
                    <fieldset className="responsibleperson_fs">
                      <legend className="responsibleperson_leg">
                        <div className="responsibleperson_inndev">
                          <div className="responsibleperson_left">
                            <h4>Responsible Person Address Information</h4>
                          </div>
                          <div className="responsibleperson_inner"></div>
                          <div className="responsibleperson_right">
                            <h4 className="responsibleperson_copyfrm">
                              <span
                                type="button"
                                className="responsibleperson_copybtnbox"
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    responsible_person_address_building_no: values.address_building_no,
                                    responsible_person_address_building_name: values.address_building_name,
                                    responsible_person_address_street_name: values.address_street_name,
                                    responsible_person_address_area: values.address_area,
                                    responsible_person_address_city: values.address_city,
                                    responsible_person_address_state_id: values.address_state_id,
                                    responsible_person_address_pincode: values.address_pincode,
                                  });
                                }}
                              >
                                Copy from Deductor
                              </span>
                            </h4>
                          </div>
                          <div className="responsibleperson_middleborder"></div>
                        </div>
                      </legend>

                      <div className="form-row tds_editdeduct_form">
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="responsible_person_address_building_no">
                            Flat/Door/Block Number<span className="red_text">*</span>
                          </label>
                          <Field
                            type="uniquecode*"
                            className={`form-control ${errors.responsible_person_address_building_no ? 'input-error1' : ''}`}
                            id="responsible_person_address_building_no"
                            placeholder="Enter Flat/Door/Block Number"
                            name="responsible_person_address_building_no"
                            autocomplete="no"
                            maxLength={25}
                          />
                          {errors?.responsible_person_address_building_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_building_no}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="responsible_person_address_building_name">Building Name</label>
                          <Field
                            type="text"
                            className={`form-control ${errors.responsible_person_address_building_name ? 'input-error1' : ''}`}
                            id="responsible_person_address_building_name"
                            placeholder="Enter Building Name"
                            name="responsible_person_address_building_name"
                            maxLength={25}
                            autocomplete="no"
                          />
                          {errors.responsible_person_address_building_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_building_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="responsible_person_address_street_name">Street/ Road Name</label>
                          <Field
                            type="text"
                            className={`form-control ${errors.responsible_person_address_street_name ? 'input-error1' : ''}`}
                            id="responsible_person_address_street_name"
                            placeholder="Enter Street/ Road Name"
                            name="responsible_person_address_street_name"
                            maxLength={25}
                            autocomplete="no"
                          />
                          {errors?.responsible_person_address_street_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_street_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="responsible_person_address_area">Area</label>
                          <Field
                            type="text"
                            className={`form-control ${errors.responsible_person_address_area ? 'input-error1' : ''}`}
                            id="responsible_person_address_area"
                            placeholder="Enter Area"
                            maxLength={25}
                            name="responsible_person_address_area"
                          />
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="responsible_person_address_city">
                            City<span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors.responsible_person_address_city ? 'input-error1' : ''}`}
                            id="responsible_person_address_city"
                            placeholder="Enter City"
                            name="responsible_person_address_city"
                            maxLength={25}
                            autocomplete="no"
                          />
                          {errors?.responsible_person_address_city ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_city}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="responsible_person_address_state_id">
                            State<span className="red_text">*</span>
                          </label>
                          <Field
                            as="select"
                            className={`form-control ${errors.responsible_person_address_state_id ? 'input-error1' : ''}`}
                            id="responsible_person_address_state_id"
                            name="responsible_person_address_state_id"
                          >
                            <option value="" disabled>Select State</option>
                            {Object.keys(STATES).map((stateId) => <option key={stateId} value={stateId}>{STATES[stateId]}</option>)}
                          </Field>
                          {errors?.responsible_person_address_state_id ? <span className="error_message" style={{ display: 'block' }}><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_state_id}</span> : null}
                        </div>
                        <div className="form-group col-md-6 colbotpaddrem">
                          <label for="responsible_person_address_pincode">
                            Pincode<span className="red_text">*</span>
                          </label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors.responsible_person_address_pincode ? 'input-error1' : ''}`}
                            id="responsible_person_address_pincode"
                            placeholder="Enter Pincode"
                            name="responsible_person_address_pincode"
                            maxLength={6}
                            value={values.responsible_person_address_pincode}
                            onChange={(e) => setFieldValue('responsible_person_address_pincode', e.target.value)}
                            autocomplete="no"
                          />
                          {errors?.responsible_person_address_pincode ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_pincode}</span> : null}
                        </div>
                      </div>

                    </fieldset>
                  </div>
                </div>

                <div className="row  pt-2">
                  <div className="col-md-12 text-center">
                    <button id="save_deductor_btn" type='submit' className="btn btn-default Edit_deductor_btnsave">Save</button>
                    {/* <button className="btn btn-default Edit_deductor_btncancel">Cancel</button> */}
                  </div>
                </div>
              </div>

            </Form>
          )}
        </Formik>

      </WindowWrapperFixed>

    </>

  );
};

export default EditDeductorForm;
