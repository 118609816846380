/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from 'moment';
import './pdf_template.css';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { DEDUCTOR_CATEGORIES, STATE_LIST, DEPARTMENT_NAMES, NATURE_COLLECTIONS } from '../../../app/constants';

const PDF24Q = (props) => {
    const pdfExportComponent = React.useRef(null);

    const { PDFTemplateData, handleClose, prnData } = props;

    var end_year = PDFTemplateData.batch_header.financial_yr.substring(0, 2) + PDFTemplateData.batch_header.financial_yr.substring(4, 6);
    var start_year = PDFTemplateData.batch_header.financial_yr.substring(0, 4);
    const deductor_address_state = STATE_LIST.filter((data) => { return data.value === parseInt(PDFTemplateData.batch_header.deductor_address_state) });
    const responsible_person_state = STATE_LIST.filter((data) => { return data.value === parseInt(PDFTemplateData.batch_header.responsible_person_state) });
    const quarter_last_month = PDFTemplateData.batch_header.period === 'Q1' ? 6 : PDFTemplateData.batch_header.period === 'Q2' ? 9 : PDFTemplateData.batch_header.period === 'Q3' ? 12 : 3;
    const quarter_last_year = PDFTemplateData.batch_header.period === 'Q4' ? moment(end_year + '-01-01', 'YYYY-MM-DD').format("YYYY") : moment(start_year + '-01-01', 'YYYY-MM-DD').format("YYYY");

    useEffect(() => {

        setTimeout(function () {
            if (pdfExportComponent.current) {
                pdfExportComponent.current.save(() => {
                    handleClose();
                });
            }

        }, 500);
    }, []);


    const convertChallanNumner = (challan_no) => {
        if (challan_no) {
            return String(challan_no).padStart(5, '0');
        }
        else {
            return "-";
        }
    }

    return (
        <div id="pdf_template_area" style={{ position: 'absolute', top: '0px', left: '-100%' }}>
            <PDFExport
                ref={pdfExportComponent}
                forcePageBreak=".page-break"
                paperSize="A3"
                margin="1cm"
                landscape={true}
                title="Return Form No. 24Q"
                fileName={PDFTemplateData.file_header.tan_of_deductor + '_24Q_' + PDFTemplateData.batch_header.period + '_' + PDFTemplateData.batch_header.financial_yr + ".pdf"}
            >
                <div className="pdf_template" id="pdf_template">
                    <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                        <tr>
                            <td>

                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody>

                                        <tr>
                                            <th colSpan="5" style={{ border: 'none', textAlign: 'center', fontSize: '12px' }}>Form No. 24Q</th>
                                        </tr>
                                        <tr>
                                            <th colSpan="5" style={{ border: 'none', textAlign: 'center' }}>(See section 192 and rule 31A)</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="text-center">	Quarterly statement of deduction of tax under sub-section (3) of section 200 of the Income-tax Act in respect of salary paid to employee under section 192, or income of specified senior citizen under section 194P, forthe quarter ended<strong> {moment('1969-' + quarter_last_month + '-01').format("MMMM")} {quarter_last_year}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5"></td>
                                        </tr>


                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>1.</th>
                                            <td> <span style={{ paddingRight: '7px' }}>(a)</span> Tax Deduction and Collection A/C No. (TAN)</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left', width: '7.3rem' }}>{PDFTemplateData.file_header.tan_of_deductor}	</th>

                                            <td><span style={{ paddingRight: '7px' }}>(d)</span> Has any statement been filed earlier for this quarter (Yes/No)</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{prnData.previous_prn ? 'Yes' : 'No'}</th>
                                        </tr>
                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>&nbsp;</th>
                                            <td><span style={{ paddingRight: '7px' }}>(b)</span> Permanent Account No (PAN)</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{PDFTemplateData.batch_header.pan_deductor}</th>

                                            <td><span style={{ paddingRight: '7px' }}>(e)</span> If answer to (d) is 'Yes', then Provisional Receipt no. of original statement</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{prnData.previous_prn ? prnData.previous_prn : '-'}</th>
                                        </tr>

                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>&nbsp;</th>
                                            <td><span style={{ paddingRight: '7px' }}>(c)</span> Financial Year</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{PDFTemplateData.batch_header.financial_yr.substring(0, 4)} - {PDFTemplateData.batch_header.financial_yr.substring(4, 6)}</th>

                                            <td><span style={{ paddingRight: '7px' }}>(f)</span> Type of Deductor</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{DEDUCTOR_CATEGORIES[PDFTemplateData.batch_header.deductor_type]}</th>
                                        </tr>


                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}>2.</th>
                                            <th colSpan="4" style={{ border: 'none', textAlign: 'left', fontSize: '14px', paddingTop: '15px' }}>Particular of the Deductor [Employer]</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', width: '19.7rem' }}>(a) Name of the Employer</td>
                                            <td colSpan="3" style={{ norder: 'none', textAlign: 'left', fontSize: '12px' }}>
                                                <strong>{PDFTemplateData.batch_header.deductor_name ? PDFTemplateData.batch_header.deductor_name : '-'}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ paddingLeft: '35px', paddingBottom: '0px' }}>(b) If Central/State Government</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.ministry_name ? DEPARTMENT_NAMES[PDFTemplateData.batch_header.ministry_name] : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>AIN Code of PAO/TO/CDDO </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.account_office_identification_number ? PDFTemplateData.batch_header.account_office_identification_number : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', paddingTop: '0px' }}>(c) TAN Reg. No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>-</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ paddingLeft: '35px' }}>(d) Address</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingBottom: '0px' }}>Flat No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingBottom: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address1 ? PDFTemplateData.batch_header.deductor_address1 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name of the Premises/Building </td>
                                            <td colSpan="3" tyle={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address2 ? PDFTemplateData.batch_header.deductor_address2 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Road/Street/Lane/Area/Location</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address3 ? (PDFTemplateData.batch_header.deductor_address3 + ", ") : ''}
                                                {(PDFTemplateData.batch_header.deductor_address3 && PDFTemplateData.batch_header.deductor_address4) ? ', ' : ''}
                                                {PDFTemplateData.batch_header.deductor_address4 ? PDFTemplateData.batch_header.deductor_address4 : ''}
                                                {(PDFTemplateData.batch_header.deductor_address3 || PDFTemplateData.batch_header.deductor_address4) ? '' : '-'}
                                            </strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Town/City/District/State</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address5 ? PDFTemplateData.batch_header.deductor_address5 : ''}
                                                {(PDFTemplateData.batch_header.deductor_address5 && deductor_address_state.length) ? ', ' : ''}
                                                {deductor_address_state.length ? deductor_address_state[0].label : ''}
                                                {(PDFTemplateData.batch_header.deductor_address5 || deductor_address_state.length) ? '' : '-'}
                                            </strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>PIN Code </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address_pincode ? PDFTemplateData.batch_header.deductor_address_pincode : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Telephone No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_std_code} - {PDFTemplateData.batch_header.deductor_phone_no}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Telephone No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_std_code_alternate} - {PDFTemplateData.batch_header.deductor_phone_no_alternate}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Email </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_email ? PDFTemplateData.batch_header.deductor_email : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Email </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_email_alternate ? PDFTemplateData.batch_header.deductor_email_alternate : '-'}</strong></td>
                                        </tr>


                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>

                    <table className="page-break" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                        <tr>
                            <td>

                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '30px' }}>&nbsp;</th>
                                            <td style={{ width: '20.5rem' }}>&nbsp;</td>
                                            <th style={{ width: '10.3rem' }}>&nbsp;</th>
                                            <td>&nbsp;</td>
                                            <th>&nbsp;</th>
                                        </tr>

                                        <tr>
                                            <th colSpan="" style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}> 3</th>
                                            <th colSpan="4" style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}>Particular of the Person Responsible for Deduction of Tax</th>
                                        </tr>

                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', width: '19.7rem' }}>(a) Name</td>
                                            <td colSpan="3" style={{ norder: 'none', textAlign: 'left', fontSize: '12px' }}>
                                                <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', width: '19.7rem' }}>(b) PAN of person responsible</td>
                                            <td colSpan="3" style={{ norder: 'none', textAlign: 'left', fontSize: '12px' }}>
                                                <strong>{PDFTemplateData.batch_header.pan_responsible_person ?? "-"}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ paddingLeft: '35px' }}>(b) Address</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Flat No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address1 ? PDFTemplateData.batch_header.responsible_person_address1 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name of Premises/Building </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address2 ? PDFTemplateData.batch_header.responsible_person_address2 : '-'}</strong></td>
                                        </tr>

                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Road/Street/Lane/Area/Location</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address3 ? (PDFTemplateData.batch_header.responsible_person_address3 + ", ") : ''}
                                                {(PDFTemplateData.batch_header.responsible_person_address3 && PDFTemplateData.batch_header.responsible_person_address4) ? ', ' : ''}
                                                {PDFTemplateData.batch_header.responsible_person_address4 ? PDFTemplateData.batch_header.responsible_person_address4 : ''}
                                                {(PDFTemplateData.batch_header.responsible_person_address3 || PDFTemplateData.batch_header.responsible_person_address4) ? '' : '-'}
                                            </strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Town/City/District/State</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address5 ? PDFTemplateData.batch_header.responsible_person_address5 : ''}
                                                {(PDFTemplateData.batch_header.responsible_person_address5 && responsible_person_state.length) ? ', ' : ''}
                                                {responsible_person_state.length ? responsible_person_state[0].label : ''}
                                                {(PDFTemplateData.batch_header.responsible_person_address5 || responsible_person_state.length) ? '' : '-'}
                                            </strong></td>
                                        </tr>


                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>PIN Code </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_pin ? PDFTemplateData.batch_header.responsible_person_pin : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Telephone No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_std_code} - {PDFTemplateData.batch_header.responsible_person_phone_no}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Telephone No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_std_code_alternate} - {PDFTemplateData.batch_header.responsible_person_phone_no_alternate}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Email  </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_email ? PDFTemplateData.batch_header.responsible_person_email : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Email </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_email_alternate ? PDFTemplateData.batch_header.responsible_person_email_alternate : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Mobile No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.mobile_number ? PDFTemplateData.batch_header.mobile_number : '-'}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>4. Details of tax deducted and paid to the credit of Central Government</th>
                                        </tr>
                                    </tbody>
                                </table>

                                <table className="table27bor" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody><tr>
                                        <th>Sr. No.</th>
                                        <th>Tax</th>
                                        <th>Surcharge</th>
                                        <th>Health and Education Cess</th>
                                        <th>Interest</th>
                                        <th>Fee</th>
                                        <th>Penalty/Others</th>
                                        <th>Total amount deposited as per challan/Book Adjustment (302+303+304+305+306+307)</th>
                                        <th>Mode of Deposit through Challan (C) / Book Adjustment (B)</th>
                                        <th>BSR Code / Receipt Number of Form No. 24G</th>
                                        <th>Challan Serial No. / DDO Serial no. of Form No.24G</th>
                                        <th>Date on which amount deposited through challan/ Date of transfer voucher</th>
                                        <th>Minor Head of Challan</th>
                                    </tr>
                                        <tr>
                                            <td className="text-center">[301]</td>
                                            <td className="text-right">[302]</td>
                                            <td className="text-right">[303]</td>
                                            <td className="text-right">[304]</td>
                                            <td className="text-right">[305]</td>
                                            <td className="text-right">[306]</td>
                                            <td className="text-right">[307]</td>
                                            <td className="text-center">[308]</td>
                                            <td className="text-center">[309]</td>
                                            <td className="text-center">[310]</td>
                                            <td className="text-center">[311]</td>
                                            <td className="text-center">[312]</td>
                                            <td className="text-center">[313]</td>
                                        </tr>
                                        {PDFTemplateData.challan_details && PDFTemplateData.challan_details.map((challan_details, index) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{challan_details.challan_detail_record_number ? challan_details.challan_detail_record_number : '-'}</td>
                                                    <td className="text-right">{formatNumber(challan_details.oltas_TDS_TCS_income_tax ? challan_details.oltas_TDS_TCS_income_tax : 0)}</td>
                                                    <td className="text-right">{formatNumber(challan_details.oltas_TDS_TCS_cess ? challan_details.oltas_TDS_TCS_surcharge : 0)}</td>
                                                    <td className="text-right">{formatNumber(challan_details.oltas_TDS_TCS_cess ? challan_details.oltas_TDS_TCS_cess : 0)}</td>
                                                    <td className="text-right">{formatNumber(challan_details.oltas_TDS_TCS_interest_amount ? challan_details.oltas_TDS_TCS_interest_amount : 0)}</td>
                                                    <td className="text-right">{formatNumber(challan_details.fee ? challan_details.fee : 0)}</td>
                                                    <td className="text-right">{formatNumber(challan_details.oltas_TDS_TCS_others_amount ? challan_details.oltas_TDS_TCS_others_amount : 0)}</td>
                                                    <td className="text-right">{formatNumber(challan_details.total_deposit_amount_challan_transfer_voucher_number ? challan_details.total_deposit_amount_challan_transfer_voucher_number : 0)}</td>
                                                    <td className="text-center">{challan_details.By_book_entry_cash === 'Y' ? 'B' : 'C'}</td>
                                                    <td className="text-center">{challan_details.bank_branch_code_Form_24G_Receipt_Number ? challan_details.bank_branch_code_Form_24G_Receipt_Number : '-'}</td>
                                                    <td className="text-center">
                                                        {PDFTemplateData.batch_header.deductor_type !== 'A' ?
                                                            convertChallanNumner(challan_details.bank_challan_no) :
                                                            convertChallanNumner(challan_details.ddo_serial_number_of_form_24G)
                                                        }
                                                    </td>
                                                    <td className="text-center">{challan_details.date_of_bank_challan_transfer_voucher ? moment(challan_details.date_of_bank_challan_transfer_voucher, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                                    <td className="text-center">{challan_details.minor_head_of_challan ? challan_details.minor_head_of_challan : '-'}</td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>

                                <table className="page-break" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                                    <tr>
                                        <td>
                                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '25px', marginTop: '25px' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>5. Details of salary paid and tax deducted thereon from the employees-</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ border: 'none', textAlign: 'left', fontSize: '11px',fontWeight:"normal" }}>(i) enclose Annexure I along with each statement having details of the relevant quarter;</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ border: 'none', textAlign: 'left', fontSize: '11px',fontWeight:"normal" }}>(ii) enclose Annexure II along with the last statement, i.e., for the quarter ending 31st March having details for the whole financial year. </th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ border: 'none', textAlign: 'left', fontSize: '11px',fontWeight:"normal" }}>(iii) enclose Annexure III along with the last statement, i.e., for the quarter ending 31st March having details for the whole financial year. </th>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', paddingBottom: '10px' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ fontSize: '13px', textAlign: 'center' }}> Verification</th>
                                                    </tr>
                                                    <tr>
                                                        <td>I, <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong>, hereby certify that all the particulars furnished above are correct and complete.</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                                <tbody><tr>
                                                    <td style={{ paddingBottom: '0px' }}>Place: <strong>{PDFTemplateData.batch_header.responsible_person_address5}</strong></td>
                                                    <td style={{ paddingBottom: '0px', textAlign: 'right' }}>Signature of the Person Responsible for Deducting Tax at Source</td>
                                                    <td></td>
                                                </tr>
                                                    <tr>
                                                        <td style={{ paddingTop: '0px' }}>
                                                            Date: <strong>{moment(PDFTemplateData.file_header.file_creation_date, 'DDMMYYYY').format('DD/MM/YYYY')}</strong>
                                                        </td>
                                                        <td style={{ paddingTop: '0px', textAlign: 'right' }}>
                                                            Name and Designation of the Person Responsible for Deducting <br />
                                                            Tax at Source
                                                        </td>
                                                        <td style={{ paddingTop: '0px', width: '20%', paddingLeft: '10px' }}>
                                                            <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong> <br />
                                                            <strong>{PDFTemplateData.batch_header.designation_person_responsible_deduction ? PDFTemplateData.batch_header.designation_person_responsible_deduction : '-'}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', textAlign: 'right', paddingTop: '10px' }}>
                                                <tr>
                                                    <th className="text-right">Express TDS - A KDK Software Product</th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>

                            </td>
                        </tr>
                    </table>



                    {PDFTemplateData.challan_details && PDFTemplateData.challan_details.map((challan_details, index) => {

                        let amount_of_payment_credit = 0;
                        let TDS_TCS_income_tax = 0;
                        let TDS_TCS_surcharge = 0;
                        let TDS_TCS_cess = 0;
                        let total_income_tax_deducted_at_source_TDS_TCS_income_tax = 0;
                        let total_tax_deposited = 0;


                        return (<>
                            <table className="page-break" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                <tr>
                                    <th style={{ border: 'none', textAlign: 'center', fontSize: '13px' }}>
                                        Annexure - Employee wise break-up of TDS <br />
                                        (Please use separate Annexure for each line item in the table at S.No. 04 of main Form 24Q)</th>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Details of salary paid and tax deducted thereon from the employees</td>
                                </tr>
                            </table>
                            <table className="table04bor" style={{ width: '1010px', margin: 'auto auto', border: '0px solid #333', paddingTop: '10px', marginTop: '1rem' }}>
                                <tr>
                                    <td> Name Of Employer</td>
                                    <td>{PDFTemplateData.batch_header.deductor_name ? PDFTemplateData.batch_header.deductor_name : '-'}</td>
                                </tr>
                                <tr>
                                    <td>TAN</td>
                                    <td>{PDFTemplateData.file_header.tan_of_deductor ? PDFTemplateData.file_header.tan_of_deductor : '-'}</td>
                                </tr>
                                <tr>
                                    <td>BSR Code of the Branch Where tax is deposited/Receipt Number of Form No.24G</td>
                                    <td>{challan_details.bank_branch_code_Form_24G_Receipt_Number ? challan_details.bank_branch_code_Form_24G_Receipt_Number : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Date on which challan deposited/Transfer voucher date (dd/mm/yyyy)</td>
                                    <td>{challan_details.date_of_bank_challan_transfer_voucher ? moment(challan_details.date_of_bank_challan_transfer_voucher, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Challan Serial No./DDO Serial No. of Form No.24G</td>
                                    <td>
                                        {PDFTemplateData.batch_header.deductor_type !== 'A' ?
                                            convertChallanNumner(challan_details.bank_challan_no) :
                                            convertChallanNumner(challan_details.ddo_serial_number_of_form_24G)
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td> Amount as per Challan</td>
                                    <td>{challan_details.total_deposit_amount_challan_transfer_voucher_number ? formatNumber(challan_details.total_deposit_amount_challan_transfer_voucher_number) : '-'}</td>
                                </tr>
                                <tr>
                                    <td> Total TDS to be allocated</td>
                                    <td>{formatNumber(challan_details.total_tax_deposit_amount_deductee_annexure ? challan_details.total_tax_deposit_amount_deductee_annexure : 0)}</td>
                                </tr>
                                <tr>
                                    <td> Total Interest to be allocated among deductees mentioned below</td>
                                    <td>{formatNumber(challan_details.oltas_TDS_TCS_interest_amount ? challan_details.oltas_TDS_TCS_interest_amount : 0)}</td>
                                </tr>
                            </table>

                            <table className="table27bor" style={{ width: '1010px', margin: 'auto auto', border: '0px solid #333', paddingTop: '10px', marginTop: '1rem' }}>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>Employee Reference number provied by employer if available</th>
                                    <th>PAN Of the Employee</th>
                                    <th>Name Of the Employee</th>
                                    <th>Section code</th>
                                    <th>Date Of Payment / Credit</th>
                                    <th>Date Of Deduction</th>
                                    <th>Amount Paid or Credited</th>
                                    <th>Tax</th>
                                    <th>Surcharge</th>
                                    <th>Health and Education Cess</th>
                                    <th>Total TDS (Total of column 322, 323,324) </th>
                                    <th>Total TDS Deposited</th>
                                    <th>Date of Deposit</th>
                                    <th>Reason for Non / Lower / Higher Deduction</th>
                                    <th>Number of the certificate u/s 197 issued by the Assessing Officer for nondeduction/ lower deduction</th>
                                </tr>

                                <tr>
                                    <td className="text-center">[314]</td>
                                    <td className="text-center">[315]</td>
                                    <td className="text-center"> [316]</td>
                                    <td className="text-center">[317]</td>
                                    <td className="text-center"> [318]</td>
                                    <td className="text-center"> [319]</td>
                                    <td className="text-right"> [320]</td>
                                    <td className="text-right"> [321]</td>
                                    <td className="text-right"> [322]</td>
                                    <td className="text-right"> [323]</td>
                                    <td className="text-right"> [324]</td>
                                    <td className="text-center"> [325]</td>
                                    <td className="text-center"> [326]</td>
                                    <td className="text-center"> [327]</td>
                                    <td className="text-center"> [328]</td>
                                    <td className="text-center"> [329]</td>
                                </tr>
                                {challan_details.deductee_details && challan_details.deductee_details.map((deductee_details, index) => {

                                    amount_of_payment_credit = parseFloat(deductee_details.amount_of_payment_credit ? deductee_details.amount_of_payment_credit : 0) + parseFloat(amount_of_payment_credit);
                                    TDS_TCS_income_tax = parseFloat(deductee_details.TDS_TCS_income_tax ? deductee_details.TDS_TCS_income_tax : 0) + parseFloat(TDS_TCS_income_tax);
                                    TDS_TCS_surcharge = parseFloat(deductee_details.TDS_TCS_surcharge ? deductee_details.TDS_TCS_surcharge : 0) + parseFloat(TDS_TCS_surcharge);
                                    TDS_TCS_cess = parseFloat(deductee_details.TDS_TCS_cess ? deductee_details.TDS_TCS_cess : 0) + parseFloat(TDS_TCS_cess);
                                    total_income_tax_deducted_at_source_TDS_TCS_income_tax = parseFloat(deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax ? deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax : 0) + parseFloat(total_income_tax_deducted_at_source_TDS_TCS_income_tax);
                                    total_tax_deposited = parseFloat(deductee_details.total_tax_deposited ? deductee_details.total_tax_deposited : 0) + parseFloat(total_tax_deposited);

                                    return (<tr>
                                        <td className="text-center">{deductee_details.deductee_detail_record_no ? deductee_details.deductee_detail_record_no : '-'}</td>
                                        <td className="text-center">{deductee_details.employee_serial_no ? deductee_details.employee_serial_no : '-'}</td>
                                        <td className="text-center">{deductee_details.deductee_pan ? deductee_details.deductee_pan : '-'}</td>
                                        <td className="text-center">{deductee_details.name_of_deductee ? deductee_details.name_of_deductee : '-'}</td>
                                        <td className="text-center">{NATURE_COLLECTIONS[deductee_details.section_code_under_which_payment_made] ? NATURE_COLLECTIONS[deductee_details.section_code_under_which_payment_made] : '-'}</td>
                                        <td className="text-center">{deductee_details.date_on_which_amount_paid_credited ? moment(deductee_details.date_on_which_amount_paid_credited, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                        <td className="text-center">{deductee_details.date_on_which_tax_deducted_collected ? moment(deductee_details.date_on_which_tax_deducted_collected, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                        <td className="text-right">{formatNumber(deductee_details.amount_of_payment_credit ? deductee_details.amount_of_payment_credit : 0)}</td>
                                        <td className="text-right">{formatNumber(deductee_details.TDS_TCS_income_tax ? deductee_details.TDS_TCS_income_tax : 0)}</td>
                                        <td className="text-right">{formatNumber(deductee_details.TDS_TCS_surcharge ? deductee_details.TDS_TCS_surcharge : 0)}</td>


                                        <td className="text-right">{formatNumber(deductee_details.TDS_TCS_cess ? deductee_details.TDS_TCS_cess : 0)}</td>
                                        <td className="text-right">{formatNumber(deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax ? deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax : 0)}</td>
                                        <td className="text-right">{formatNumber(deductee_details.total_tax_deposited ? deductee_details.total_tax_deposited : 0)}</td>
                                        <td className="text-center">{deductee_details.date_of_deposit ? moment(deductee_details.date_of_deposit, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                        <td className="text-center">{deductee_details.remarks_1 ? deductee_details.remarks_1 : '-'}</td>
                                        <td className="text-center">{deductee_details.certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction ? deductee_details.certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction : '-'}</td>
                                    </tr>)
                                })}

                                <tr>
                                    <td className="text-center"><strong>Total</strong></td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-right"><strong>{formatNumber(amount_of_payment_credit)}</strong></td>
                                    <td className="text-right"><strong>{formatNumber(TDS_TCS_income_tax)}</strong></td>
                                    <td className="text-right"><strong>{formatNumber(TDS_TCS_surcharge)}</strong></td>
                                    <td className="text-right"><strong>{formatNumber(TDS_TCS_cess)}</strong></td>
                                    <td className="text-right"><strong>{formatNumber(total_income_tax_deducted_at_source_TDS_TCS_income_tax)}</strong></td>
                                    <td className="text-right"><strong>{formatNumber(total_tax_deposited)}</strong></td>
                                    <td className="text-right">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                </tr>
                            </table>

                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                                <tr>
                                    <td>

                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '10px' }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{ fontSize: '13px', textAlign: 'center' }}> Verification</th>
                                                </tr>
                                                <tr>
                                                    <td>I, <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong>, hereby certify that all the particulars furnished above are correct and complete.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '15px', marginBottom: '15px' }}>
                                            <tbody><tr>
                                                <td style={{ paddingBottom: '0px' }}>Place: <strong>{PDFTemplateData.batch_header.responsible_person_address5}</strong></td>
                                                <td style={{ paddingBottom: '0px', textAlign: 'right' }}>Signature of the Person Responsible for Deducting Tax at Source</td>
                                                <td></td>
                                            </tr>
                                                <tr>
                                                    <td style={{ paddingTop: '0px' }}>
                                                        Date: <strong>{moment(PDFTemplateData.file_header.file_creation_date, 'DDMMYYYY').format('DD/MM/YYYY')}</strong>
                                                    </td>
                                                    <td style={{ paddingTop: '0px', textAlign: 'right' }}>
                                                        Name and Designation of the Person Responsible for Deducting <br />
                                                        Tax at Source
                                                    </td>
                                                    <td style={{ paddingTop: '0px', width: '20%', textAlign: 'right' }}>
                                                        <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong> <br />
                                                        <strong>{PDFTemplateData.batch_header.designation_person_responsible_deduction ? PDFTemplateData.batch_header.designation_person_responsible_deduction : '-'}</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>

                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', textAlign: 'right', paddingTop: '10px' }}>
                                <tr>
                                    <th className="text-right">Express TDS - A KDK Software Product</th>
                                </tr>
                            </table>
                        </>)
                    })}



                    <table className="page-break" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '10px' }}>
                        <tr>
                            <th style={{ border: 'none', textAlign: 'center', fontSize: '12.5px', padding: '10px 0px' }}>
                                Annexure II <br />Details of salary paid/credited during the financial year and net tax payable
                            </th>
                        </tr>
                    </table>


                    <table className="table27bor" style={{ width: '1010px', margin: 'auto auto', border: '0px solid #333', paddingTop: '10px', marginTop: '1rem' }}>
                        <tr>
                            <th>Sr.No</th>
                            <th>PAN</th>
                            <th>Name of Employee</th>
                            <th>{parseInt(start_year) >= 2023 ? "Whether opting out for taxation u/s 115BAC(1A) ?" : "Whether opting for Taxation u/s 115BAC ?"}</th>
                            <th> Deductee Type (Senior Citizen, Super Senior Citizen, Others</th>
                            <th>Date From and date up to employed with current employer in the current financial year</th>
                            <th>Gross Salary as per provisions contained in section 17(1)</th>
                            <th>Value of perquisites under section 17(2) (as per FormNo.12BA wherever applicable)</th>
                            <th>salary under section 17(3) (as per FormNo.12BA wherever applicable)</th>
                            <th>Total amount of gross salary received from current employer (see Note 1 for definition of Salary)</th>
                        </tr>
                        <tr>
                            <td className="text-center">(330)</td>
                            <td className="text-center">(331)</td>
                            <td className="text-center">(332A)</td>
                            <td className="text-center">(332B)</td>
                            <td className="text-center">(333)</td>
                            <td className="text-center">(334)</td>
                            <td className="text-right">(335)</td>
                            <td className="text-right">(336)</td>
                            <td className="text-right">(337)</td>
                            <td className="text-right">(338)</td>
                        </tr>
                        {PDFTemplateData.salary_details && PDFTemplateData.salary_details.map((salary_details, index) => {
                            return (<tr>
                                <td className="text-center">{salary_details.salary_detail_record_number ? salary_details.salary_detail_record_number : '-'}</td>
                                <td className="text-center">{salary_details.employee_pan ? salary_details.employee_pan : '-'}</td>
                                <td className="text-center">{salary_details.name_of_employee ? salary_details.name_of_employee : '-'}</td>
                                <td className="text-center">{salary_details.whether_opting_for_taxation_us_115BAC === 'Y' ? 'Yes' : 'No'}</td>
                                <td className="text-center">{salary_details.category_of_employee ? salary_details.category_of_employee : '-'}</td>
                                <td className="text-center">{salary_details.period_of_employment_from_date ? moment(salary_details.period_of_employment_from_date, 'DDMMYYYY').format("DD/MM/YYYY") : '-'} - {salary_details.period_of_employment_to_date ? moment(salary_details.period_of_employment_to_date, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                <td className="text-right">{formatNumber(salary_details.gross_salary_as_per_provisions_contained_in_sec17 ? salary_details.gross_salary_as_per_provisions_contained_in_sec17 : 0)}</td>
                                <td className="text-right">{formatNumber(salary_details.value_of_perquisites ? salary_details.value_of_perquisites : 0)}</td>
                                <td className="text-right">{formatNumber(salary_details.profits_in_lieu_of_salary ? salary_details.profits_in_lieu_of_salary : 0)}</td>
                                <td className="text-right">{formatNumber(salary_details.taxable_amount_on_which_tax_is_deducted_by_the_current_employer ? salary_details.taxable_amount_on_which_tax_is_deducted_by_the_current_employer : 0)}</td>
                            </tr>)
                        })}

                    </table>




                    <table className="table27bor" style={{ width: '1010px', margin: 'auto auto', border: '0px solid #333', paddingTop: '10px', marginTop: '1rem' }}>
                        <tr>
                            <th>Reported total amount of salary received from other employer(s) (see Note 1 for definition of Salary)</th>
                            <th>Travel concession or assistance under section 10(5)</th>
                            <th>Death-cum-retirement gratuity under section 10(10)</th>
                            <th>Commuted value of pension under section 10(10A)</th>
                            <th>Cash equivalent of leave salary encashment under section 10 (10AA)</th>
                            <th>House rent allowance under section 10(13A)</th>
                            <th>PAN of landlord, if exemption is claimed under section 10(13A)</th>
                            {parseInt(start_year) >= 2023 ? <th>Other special allowances under section 10(14)</th> : null}
                            <th>Amount of any other exemption under section 10</th>
                            <th>Total amount of exemption claimed under section 10 (340+341+342+343 +344+346A+346B)</th>
                            <th>Total deduction under section 16(ia)</th>
                        </tr>
                        <tr>
                            <td className="text-right">(339)</td>
                            <td className="text-right">(340)</td>
                            <td className="text-right">(341)</td>
                            <td className="text-right">(342)</td>
                            <td className="text-right">(343)</td>
                            <td className="text-right">(344)</td>
                            <td className="text-center">(345)</td>
                            {parseInt(start_year) >= 2023 ? <td className="text-right">(346A)</td> : null}
                            <td className="text-right">{parseInt(start_year) >= 2023 ? "(346B)" : "(346)"}</td>
                            <td className="text-right">(347)</td>
                            <td className="text-right">(348)</td>
                        </tr>
                        {PDFTemplateData.salary_details && PDFTemplateData.salary_details.map((salary_details, index) => {
                            return (
                                <tr>
                                    <td className="text-right">{formatNumber(salary_details.reported_taxable_amount_on_which_tax_is_deducted_by_previous_employer ? salary_details.reported_taxable_amount_on_which_tax_is_deducted_by_previous_employer : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.travel_concession_or_assistance ? salary_details.travel_concession_or_assistance : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.death_cum_retirement_gratuity ? salary_details.death_cum_retirement_gratuity : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.commuted_value_of_pension ? salary_details.commuted_value_of_pension : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.cash_eqivalent_of_leave_salary_encashment ? salary_details.cash_eqivalent_of_leave_salary_encashment : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.house_rent_allowance ? salary_details.house_rent_allowance : 0)}</td>
                                    <td className="text-center">
                                        {salary_details.whether_aggregate_rent_payment_exceeds_rupees_one_lakh_during_previous_year === 'Y' ?
                                            salary_details.pan_of_landlord_1
                                            : "-"}

                                        {salary_details.pan_of_landlord_2 ?
                                            ' ' + salary_details.pan_of_landlord_2
                                            : null}

                                        {salary_details.pan_of_landlord_3 ?
                                            ' ' + salary_details.pan_of_landlord_3
                                            : null}

                                        {salary_details.pan_of_landlord_4 ?
                                            ' ' + salary_details.pan_of_landlord_4
                                            : null}
                                    </td>
                                    {parseInt(start_year) >= 2023 ? <td className="text-right">{formatNumber(salary_details.special_us_10_14_allowance ? salary_details.special_us_10_14_allowance : 0)}</td> : null}
                                    <td className="text-right">{formatNumber(salary_details.amount_of_any_other_exemption_under_section_10 ? salary_details.amount_of_any_other_exemption_under_section_10 : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.total_amount_of_exemption_claimed_under_section_10 ? salary_details.total_amount_of_exemption_claimed_under_section_10 : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.deduction16_ia ? salary_details.deduction16_ia : 0)}</td>
                                </tr>)
                        })}
                    </table>




                    <table className="table27bor" style={{ width: '1010px', margin: 'auto auto', border: '0px solid #333', paddingTop: '10px', marginTop: '1rem' }}>
                        <tr>
                            <th>Total deduction under section 16(ii)</th>
                            <th>Total deduction under section 16(iii)</th>
                            <th>Income chargeable under the head "Salaries" [338+339- (347+348+ 349+350)].</th>
                            <th>Income (or admissible loss) from house property reported by employee offered for TDS as per section 192 (2B)</th>
                            <th>PAN of lender, if interest on housing loan is claimed under section 24(b)</th>
                            <th>Income under the head other sources offered for TDS as per section 192 (2B)</th>
                            <th>Gross total income (351+352+ 354).</th>
                            <th style={{ padding: 0, border: "2px solid" }}>
                            <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}>Deduction in respect of life insurance premia, contributions to provident fund etc. under section 80C</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "50%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                    </tr>
                                </table>
                            </th>
                            <th style={{ padding: 0, border: "2px solid" }}>
                            <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}>Deduction in respect of contribution to certain pension funds under section 80CCC</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "50%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                    </tr>
                                </table>

                            </th>
                            <th style={{ padding: 0, border: "2px solid" }}>
                            <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}>Deduction in respect of contribution by taxpayer to notified pension scheme under section 80CCD(1)</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "50%", }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                    </tr>
                                </table>

                            </th>
                        </tr>
                        <tr>
                            <td className="text-left">(349)</td>
                            <td className="text-left">(350)</td>
                            <td className="text-right">(351)</td>
                            <td className="text-right">(352)</td>
                            <td className="text-center">(353)</td>
                            <td className="text-right">(354)</td>
                            <td className="text-right">(355)</td>
                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(356)</span>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(357)</span>
                            </td>
                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(358)</span>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(359)</span>
                            </td>
                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(360)</span>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(361)</span>
                            </td>
                        </tr>
                        {PDFTemplateData.salary_details && PDFTemplateData.salary_details.map((salary_details, index) => {
                            return (
                                <tr>
                                    <td className="text-left">{formatNumber(salary_details.deduction16_ii ? salary_details.deduction16_ii : 0)}</td>
                                    <td className="text-left">{formatNumber(salary_details.deduction16_iii ? salary_details.deduction16_iii : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.income_chargeable_under_the_head_salaries ? salary_details.income_chargeable_under_the_head_salaries : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.income_including_admissible_loss_from_house_property ? salary_details.income_including_admissible_loss_from_house_property : 0)}</td>
                                    <td className="text-center">
                                        {salary_details.whether_interest_paid_to_the_lender_under_the_head_income_from_house_property === 'Y' ?
                                            salary_details.pan_of_lender_1
                                            : "-"}

                                        {salary_details.pan_of_lender_2 ?
                                            ' ' + salary_details.pan_of_lender_2
                                            : null}

                                        {salary_details.pan_of_lender_3 ?
                                            ' ' + salary_details.pan_of_lender_3
                                            : null}

                                        {salary_details.pan_of_lender_4 ?
                                            ' ' + salary_details.pan_of_lender_4
                                            : null}
                                    </td>
                                    <td className="text-right">{formatNumber(salary_details.income_under_the_head_other_sources_offered_for_TDS ? salary_details.income_under_the_head_other_sources_offered_for_TDS : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.gross_total_income ? salary_details.gross_total_income : 0)}</td>
                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80C_gross_amt ? salary_details.VIA_80C_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80C_dedu_amt ? salary_details.VIA_80C_dedu_amt : 0)}
                                        </span>
                                    </td>
                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80CCC_gross_amt ? salary_details.VIA_80CCC_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80CCC_dedu_amt ? salary_details.VIA_80CCC_dedu_amt : 0)}
                                        </span>
                                    </td>
                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80CCD1_gross_amt ? salary_details.VIA_80CCD1_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80CCD1_dedu_amt ? salary_details.VIA_80CCD1_dedu_amt : 0)}
                                        </span>
                                    </td>

                                </tr>)
                        })}
                    </table>






                    <table className="table27bor" style={{ width: '1010px', margin: 'auto auto', border: '0px solid #333', paddingTop: '10px', marginTop: '1rem' }}>
                        <tr>

                            <th style={{ padding: 0, border: "2px solid" }}>
                            <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}>Deduction in respect of amount paid or deposited under notified pension scheme under section 80CCD (1B)</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "50%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                    </tr>
                                </table>
                            </th>
                            <th style={{ padding: 0, border: "2px solid" }}>
                            <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}> Deduction in respect
                                        of contribution by
                                        employer to notified
                                        pension scheme
                                        under section
                                        80CCD(2)</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "50%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                    </tr>
                                </table>
                            </th>






                            {parseInt(start_year) >= 2023 ? <>


                                <th style={{ padding: 0, border: "2px solid" }}>
                                <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                        <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}> Deduction in
                                            respect of contribution by the employee to Agnipath Scheme
                                            under section 80CCH</td></tr>
                                        <tr>
                                            <td style={{ borderLeft: "none", borderBottom: "none", width: "50%" }}>Gross amount </td>
                                            <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                        </tr>
                                    </table>
                                </th>
                                <th style={{ padding: 0, border: "2px solid" }}>
                                <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                        <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}>Deduction in
                                            respect of contribution by the Central Government to Agnipath Scheme
                                            under section 80CCH</td></tr>
                                        <tr>
                                            <td style={{ borderLeft: "none", borderBottom: "none", width: "50%" }}>Gross amount </td>
                                            <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                        </tr>
                                    </table>
                                </th>

                            </>
                                : null
                            }



                            <th style={{ padding: 0, border: "2px solid" }}>
                            <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}>Deduction on in
                                        respect of health
                                        insuranc e premia
                                        under section 80D</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "50%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                    </tr>
                                </table>
                            </th>
                            <th style={{ padding: 0, border: "2px solid" }}>
                            <table style={{tableLayout:"fixed",minHeight:"110px"}}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={2}> Deduction in respect
                                        of interest on loan
                                        taken for higher
                                        education under
                                        section 80E</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "50%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "50%" }}>Deductible amount</td>
                                    </tr>
                                </table>
                            </th>



                        </tr>
                        <tr>
                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(362)</span>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(363)</span>
                            </td>
                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(364A)</span>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(364B)</span>
                            </td>

                            {parseInt(start_year) >= 2023 ? <>
                                <td className="text-right" style={{ padding: 0 }}>
                                    <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(365A)</span>
                                    <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(365B)</span>
                                </td>
                                <td className="text-right" style={{ padding: 0 }}>
                                    <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(366A)</span>
                                    <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(366B)</span>
                                </td>
                            </>
                                : null
                            }



                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(367A)</span>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(367B)</span>
                            </td>
                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(368)</span>
                                <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(369)</span>
                            </td>

                        </tr>
                        {PDFTemplateData.salary_details && PDFTemplateData.salary_details.map((salary_details, index) => {
                            return (
                                <tr>
                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80CCD1B_gross_amt ? salary_details.VIA_80CCD1B_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80CCD1B_dedu_amt ? salary_details.VIA_80CCD1B_dedu_amt : 0)}
                                        </span>
                                    </td>
                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80CCD2_gross_amt ? salary_details.VIA_80CCD2_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80CCD2_dedu_amt ? salary_details.VIA_80CCD2_dedu_amt : 0)}
                                        </span>
                                    </td>





                                    {parseInt(start_year) >= 2023 ? <>
                                        <td className="text-right" style={{ padding: 0 }}>
                                            <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                                {formatNumber(salary_details.VIA_80CCH_gross_amt ? salary_details.VIA_80CCH_gross_amt : 0)}
                                            </span>
                                            <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                                {formatNumber(salary_details.VIA_80CCH_dedu_amt ? salary_details.VIA_80CCH_dedu_amt : 0)}
                                            </span>
                                        </td>
                                        <td className="text-right" style={{ padding: 0 }}>
                                            <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                                {formatNumber(salary_details.VIA_80CCH_1_gross_amt ? salary_details.VIA_80CCH_1_gross_amt : 0)}
                                            </span>
                                            <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                                {formatNumber(salary_details.VIA_80CCH_1_dedu_amt ? salary_details.VIA_80CCH_1_dedu_amt : 0)}
                                            </span>
                                        </td>
                                    </> : null}



                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80D_gross_amt ? salary_details.VIA_80D_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80D_dedu_amt ? salary_details.VIA_80D_dedu_amt : 0)}
                                        </span>
                                    </td>

                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80E_gross_amt ? salary_details.VIA_80E_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "50%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80E_dedu_amt ? salary_details.VIA_80E_dedu_amt : 0)}
                                        </span>
                                    </td>


                                </tr>)
                        })}
                    </table>




                    <table className="table27bor" style={{ width: '1010px', margin: 'auto auto', border: '0px solid #333', paddingTop: '10px', marginTop: '1rem' }}>
                        <tr>


                            <th style={{ padding: 0, border: "2px solid" }}>
                                <table style={{ tableLayout:"fixed",minHeight:"110px" }}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={3}>Total deduction in
                                        respect of donations
                                        to certain funds,
                                        charitable
                                        institutions, etc.
                                        under section 80G</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "33%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "33%" }}>Qualifying amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "33%" }}>Deductible amount</td>
                                    </tr>
                                </table>
                            </th>

                            <th style={{ padding: 0, border: "2px solid" }}>
                                <table style={{ tableLayout:"fixed",minHeight:"110px" }}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={3}>Deduction in
                                        respect of interest
                                        on deposits in
                                        savings account
                                        under section 80TTA</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "33%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "33%" }}>Qualifying amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "33%" }}>Deductible amount</td>
                                    </tr>
                                </table>
                            </th>
                            <th style={{ padding: 0, border: "2px solid" }}>
                                <table style={{ tableLayout:"fixed",minHeight:"110px" }}>
                                    <tr><td style={{ border: "none", padding: 0, borderBottom: "1px solid" }} colSpan={3}>Amount deductible
                                        under any other
                                        provision(s) of
                                        Chapter VI-A</td></tr>
                                    <tr>
                                        <td style={{ borderLeft: "none", borderBottom: "none", width: "33%" }}>Gross amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "33%" }}>Qualifying amount </td>
                                        <td style={{ borderRight: "none", borderBottom: "none", width: "33%" }}>Deductible amount</td>
                                    </tr>
                                </table>
                            </th>

                            <th>Total amount deductible under Chapter VI‐A [357+359+361 (limited to Rs. 1,50,000) +363 +364B + 365B +366B+ 367B +369 +372 +375 +378]</th>
                            <th>Total taxable income (355-379).</th>
                            <th>Income-tax on total income</th>

                        </tr>
                        <tr>

                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(370)</span>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(371)</span>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(372)</span>
                            </td>
                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(373)</span>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(374)</span>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(375)</span>
                            </td>
                            <td className="text-right" style={{ padding: 0 }}>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(376)</span>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(377)</span>
                                <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>(378)</span>
                            </td>
                            <td className="text-right">(379)</td>
                            <td className="text-right">(380)</td>
                            <td className="text-right">(381)</td>
                        </tr>
                        {PDFTemplateData.salary_details && PDFTemplateData.salary_details.map((salary_details, index) => {
                            return (
                                <tr>


                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80G_gross_amt ? salary_details.VIA_80G_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80G_quli_amt ? salary_details.VIA_80G_quli_amt : 0)}
                                        </span>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80G_dedu_amt ? salary_details.VIA_80G_dedu_amt : 0)}
                                        </span>
                                    </td>
                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80TTA_gross_amt ? salary_details.VIA_80TTA_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80TTA_quli_amt ? salary_details.VIA_80TTA_quli_amt : 0)}
                                        </span>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_80TTA_dedu_amt ? salary_details.VIA_80TTA_dedu_amt : 0)}
                                        </span>
                                    </td>
                                    <td className="text-right" style={{ padding: 0 }}>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderRight: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_OTHERS_gross_amt ? salary_details.VIA_OTHERS_gross_amt : 0)}
                                        </span>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_OTHERS_quli_amt ? salary_details.VIA_OTHERS_quli_amt : 0)}
                                        </span>
                                        <span style={{ width: "33%", float: "left", lineHeight: "20px", borderLeft: "1px solid", height: "100%", minHeight: "22px", paddingRight: "2px" }}>
                                            {formatNumber(salary_details.VIA_OTHERS_dedu_amt ? salary_details.VIA_OTHERS_dedu_amt : 0)}
                                        </span>
                                    </td>




                                    <td className="text-right">{formatNumber(salary_details.gross_total_of_amount_deductible_under_provisions_of_chapter_VI_A ? salary_details.gross_total_of_amount_deductible_under_provisions_of_chapter_VI_A : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.total_taxable_income ? salary_details.total_taxable_income : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.income_tax_on_total_income ? salary_details.income_tax_on_total_income : 0)}</td>
                                </tr>)
                        })}
                    </table>



                    <table className="table27bor" style={{ width: '1010px', margin: 'auto auto', border: '0px solid #333', paddingTop: '10px', marginTop: '1rem' }}>
                        <tr>
                            <th>Rebate under section
                                87A, if applicable</th>
                            <th>
                                Surcharge, wherever
                                applicable</th>
                            <th>
                                Health and Education Cess</th>
                            <th>
                                Income-tax relief under
                                section 89, when salary, etc. is paid in
                                arrear or advance</th>
                            <th>Net tax liability [381+383+384-(382+385)].</th>
                            <th>Total amount of tax
                                deducted at source by the current employer
                                for the whole year
                                [aggregate of the
                                amount in columns
                                325 of Annexure I for
                                all the quarters in
                                respect of each employee]</th>
                            <th>Reported amount of
                                tax deducted at source by other employer(s)
                                or deductor(s) (income
                                in respect of which
                                included in computing
                                total taxable income in
                                column 339)</th>
                            <th>
                                Total amount of tax
                                deducted at source for the whole year
                                (387+388)</th>
                            <th>
                                Shortfall in tax
                                deduction (+) or excess tax deduction
                                (-) (386-389)</th>
                        </tr>



                        <tr>
                            <td className="text-left">(382)</td>
                            <td className="text-left">(383)</td>
                            <td className="text-right">(384)</td>
                            <td className="text-right">(385)</td>
                            <td className="text-center">(386)</td>
                            <td className="text-right">(387)</td>
                            <td className="text-right">(388)</td>
                            <td className="text-right">(389)</td>
                            <td className="text-right">(390)</td>
                        </tr>



                        {PDFTemplateData.salary_details && PDFTemplateData.salary_details.map((salary_details, index) => {
                            return (
                                <tr>
                                    <td className="text-right">{formatNumber(salary_details.rebate_under_section_87A ? salary_details.rebate_under_section_87A : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.surcharge ? salary_details.surcharge : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.education_cess ? salary_details.education_cess : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.income_tax_relief_us_89 ? salary_details.income_tax_relief_us_89 : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.net_income_tax_payable ? salary_details.net_income_tax_payable : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.total_amount_of_tax_deducted_at_source_by_the_current_employer_for_the_whole_year ? salary_details.total_amount_of_tax_deducted_at_source_by_the_current_employer_for_the_whole_year : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.reported_amount_of_tax_deducted_at_source_by_previous_employer ? salary_details.reported_amount_of_tax_deducted_at_source_by_previous_employer : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.total_amount_of_tax_deducted_at_source_for_the_whole_year ? salary_details.total_amount_of_tax_deducted_at_source_for_the_whole_year : 0)}</td>
                                    <td className="text-right">{formatNumber(salary_details.shortfall_in_tax_deduction ? salary_details.shortfall_in_tax_deduction : 0)}</td>
                                </tr>)
                        })}
                    </table>

                    <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', textAlign: 'right', marginTop: '10px' }}>
                        <tr>
                            <th className="text-right">Express TDS - A KDK Software Product</th>
                        </tr>
                    </table>



                </div >
            </PDFExport >
        </div >
    );
};

export default PDF24Q;