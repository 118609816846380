import React from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { BASE_PATH } from '../../../app/constants';
import { Tooltip } from '@progress/kendo-react-tooltip'

const PanSummary = (props) => {

  const { returnId, deductorId, panSummaryData, skeletonLoading, formType } = props;

  let title = 'PAN Summary';
  if (formType === '26Q') {
    title = 'PAN Status as per TRACES';
  }

  if (formType === '24Q') {
    return (
      <>
        <fieldset className="pan_sumamry_fs h-100 px-1">
          <legend className="pan_sumamry_leg">
            <h4>PAN Summary</h4>
          </legend>
          {
            skeletonLoading
              ? <>
                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                  <tr>
                    <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                    <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                  </tr>
                  <tr>
                    <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                    <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                    <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                  </tr>
                </div>
              </>
              :
              <>
                <div style={{ paddingTop: '32px' }} className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                  <table className="table table-borderless pan_sumamry_table  mb-0">
                    <tbody>
                      <tr>
                        <td width="150">
                          {
                            panSummaryData?.cnt_traces_invalid_pan > 0
                              ?
                              <>
                                <Link
                                  target="_blank"
                                  to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&filters=${btoa(JSON.stringify([{ logic: "and", filters: [{ field: "pan_status", operator: "in", value: ["PAN_INVALID"] }] }]))}`}
                                  tabIndex="-1"
                                >
                                  <div className="cardbox_contentborder" data-toggle="tooltip" data-placement="top" title="Fix Now">
                                    <div className="btn-group comput_health_lowerdt_card">
                                      <span className="invlid_textbox">
                                        <span className="textnofsclr">
                                          {panSummaryData?.cnt_traces_invalid_pan ?? 0}
                                        </span>
                                        <br />
                                        <span className="invlid_textfsclr">
                                          Invalid
                                        </span>
                                      </span>
                                      <span className="comput_health_verifid_btnbox">
                                        Fix Now
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </>
                              :
                              <>
                                <div className="cardbox_contentborder_withouthoverbox" >
                                  <div className="btn-group comput_health_lowerdt_card">
                                    <span className="invlid_textbox">
                                      <span className="textnofsclr">
                                        {panSummaryData?.cnt_traces_invalid_pan ?? 0}
                                      </span>
                                      <br />
                                      <span className="invlid_textfsclr">
                                        Invalid
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </>
                          }

                        </td>

                        <td width="150">
                          {
                            panSummaryData?.cnt_unverified_pan > 0
                              ? <>
                                <Link
                                  target="_blank"
                                  to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&filters=${btoa(JSON.stringify([{ logic: "and", filters: [{ field: "pan_status", operator: "in", value: ["PAN_AVAILABLE_BUT_UNVERIFIED"] }] }]))}`}
                                  tabIndex="-1"
                                >
                                  <div className="cardbox_contentborder" data-toggle="tooltip" data-placement="top" title="Click to Verify">
                                    <div className="btn-group comput_health_lowerdt_card">
                                      <span className="invlid_textbox">
                                        <span className="textnofsclr">
                                          {panSummaryData?.cnt_unverified_pan ?? 0}
                                        </span>
                                        <br />
                                        <span className="invlid_textfsclr">
                                          Un-Verified
                                        </span>
                                      </span>
                                      <span className="comput_health_verifid_btnbox">
                                        Fix Now
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </>
                              :
                              <>
                                <div className="cardbox_contentborder_withouthoverbox">
                                  <div className="btn-group comput_health_lowerdt_card">
                                    <span className="invlid_textbox">
                                      <span className="textnofsclr">
                                        {panSummaryData?.cnt_unverified_pan ?? 0}
                                      </span>
                                      <br />
                                      <span className="invlid_textfsclr">
                                        Un-Verified
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </>
                          }

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                  <table className="table table-borderless pan_sumamry_table  mb-0">
                    <tbody>
                      <tr>
                        <td width="150">
                          <div className="cardbox_contentborder_withouthoverbox mb-1">
                            <span className="textnofsclr">
                              {panSummaryData?.cnt_valid_pan ?? 0}
                            </span>
                            <div className="invlid_textbox">
                              <span className="invlid_textfsclr">
                                Operative
                              </span>
                            </div>
                          </div>
                        </td>
                        <td width="150">
                          <div className="cardbox_contentborder_withouthoverbox mb-1">
                            <span className="textnofsclr">
                              {panSummaryData?.cnt_inactive_pan ?? 0}
                            </span>
                            <div className="invlid_textbox">
                              <span className="invlid_textfsclr">
                                InOperative
                              </span>
                            </div>
                          </div>
                        </td>
                        <td width="150">
                          <div className="cardbox_contentborder_withouthoverbox">
                            <span className="textnofsclr">
                              {panSummaryData?.cnt_not_available_pan ?? 0}
                            </span>
                            <div className="invlid_textbox">
                              <span className="invlid_textfsclr_yell">
                                PANNOTAVBL
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
          }
        </fieldset>
      </>
    )
  } else {
    return (
      <>
        <div className="col-md-5">
          <fieldset className="pan_sumamry_fs h-100">
            <legend className="pan_sumamry_leg">
              <h4>{title}</h4>
            </legend>

            {
              skeletonLoading
                ? <>
                  <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                    <tr>
                      <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                      <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                      <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                    </tr>
                    <tr>
                      <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                      <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                      <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                    </tr>
                  </div>
                </>
                :
                <>
                  <div className="pt-2 table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                    <Tooltip anchorElement="pointer" position="top">
                      <table className="table table-borderless pan_sumamry_table  mb-0">
                        <tbody>
                          <tr>
                            <td width="150">
                              {
                                panSummaryData?.cnt_traces_invalid_pan > 0
                                  ?
                                  <>
                                    <Link
                                      target="_blank"
                                      to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&filters=${btoa(JSON.stringify([{ logic: "and", filters: [{ field: "pan_status", operator: "in", value: ["PAN_INVALID"] }] }]))}`}
                                      tabIndex="-1"
                                    >
                                      <div className="cardbox_contentborder" >
                                        <div className="btn-group comput_health_lowerdt_card" >
                                          <span className="invlid_textbox" >
                                            <span className="textnofsclr" >
                                              {panSummaryData?.cnt_traces_invalid_pan ?? 0}
                                            </span>
                                            <br />
                                            <span className="invlid_textfsclr">
                                              Invalid
                                            </span>
                                          </span>
                                          <span className="comput_health_verifid_btnbox" data-toggle="tooltip" data-placement="top" title="Fix Now">
                                            Fix Now
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </>
                                  :
                                  <>
                                    <div className="cardbox_contentborder_withouthoverbox" >
                                      <div className="btn-group comput_health_lowerdt_card" >
                                        <span className="invlid_textbox" >
                                          <span className="textnofsclr" >
                                            {panSummaryData?.cnt_traces_invalid_pan ?? 0}
                                          </span>
                                          <br />
                                          <span className="invlid_textfsclr">
                                            Invalid
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </>
                              }

                            </td>

                            <td width="150">
                              {
                                panSummaryData?.cnt_unverified_pan > 0
                                  ?
                                  <>
                                    <Link
                                      target="_blank"
                                      to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&filters=${btoa(JSON.stringify([{ logic: "and", filters: [{ field: "pan_status", operator: "in", value: ["PAN_AVAILABLE_BUT_UNVERIFIED"] }] }]))}`}
                                      tabIndex="-1"
                                    >
                                      <div className="cardbox_contentborder">
                                        <div className="btn-group comput_health_lowerdt_card" >
                                          <span className="invlid_textbox" >
                                            <span className="textnofsclr" >
                                              {panSummaryData?.cnt_unverified_pan ?? 0}
                                            </span>
                                            <br />
                                            <span className="invlid_textfsclr">
                                              Un-Verified
                                            </span>
                                          </span>
                                          <span className="comput_health_verifid_btnbox" data-toggle="tooltip" data-placement="top" title="Click to Verify">
                                            Verify Now
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </>
                                  :
                                  <>
                                    <div className="cardbox_contentborder_withouthoverbox">
                                      <div className="btn-group comput_health_lowerdt_card" >
                                        <span className="invlid_textbox" >
                                          <span className="textnofsclr" >
                                            {panSummaryData?.cnt_unverified_pan ?? 0}
                                          </span>
                                          <br />
                                          <span className="invlid_textfsclr">
                                            Un-Verified
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </>
                              }

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Tooltip>
                  </div>
                  <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                    <table className="table table-borderless pan_sumamry_table  mb-0">
                      <tbody>
                        <tr>
                          <td width="150">
                            <div className="cardbox_contentborder_withouthoverbox mb-1">
                              <span className="textnofsclr">
                                {panSummaryData?.cnt_valid_pan ?? 0}
                              </span>

                              <div className="invlid_textbox">
                                <span className="operative_textfsclr">
                                  Operative
                                </span>
                              </div>
                            </div>
                          </td>
                          <td width="150">
                            <div className="cardbox_contentborder_withouthoverbox mb-1">
                              <span className="textnofsclr">
                                {panSummaryData?.cnt_inactive_pan ?? 0}
                              </span>

                              <div className="invlid_textbox">
                                <span className="invlid_textfsclr">
                                  InOperative
                                </span>
                              </div>
                            </div>
                          </td>
                          <td width="150">
                            <div className="cardbox_contentborder_withouthoverbox">
                              <span className="textnofsclr">
                                {panSummaryData?.cnt_not_available_pan ?? 0}
                              </span>

                              <div className="invlid_textbox">
                                <span className="invlid_textfsclr_yell">
                                  PANNOTAVBL
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
            }
          </fieldset>
        </div>

      </>

    );
  }

};

export default PanSummary;
