import React from 'react'
import { formatNumber } from '../../../utils/UtilityFunctions'

const ViewPrequisitesLite = (props) => {
    const { data, headingCodeList17two } = props;

    return (
        <>
            <tr className="">
                <th width="10" id="accordion" className="accordion">
                    <span className="salarytaxregime_edit_collapse " data-toggle="collapse" href="#salarytaxregime_edit_collap02" aria-expanded="true"></span>
                </th>
                <th className="text-left">
                    Prequisites 17(2)
                </th>
                <th className="text-right">
                    {formatNumber(data.perquisites_amtp ?? 0)}
                </th>

            </tr>

            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_collap02" className="in collapse show">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <thead>
                                    <tr>
                                        <th width="45"></th>
                                        <th width="550" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop">Particulars</th>
                                        <th width="150" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop">Value of Perquisite</th>
                                        </tr>
                                </thead>
                                <tbody>
                                    {data?.perquisite_breakup_amtp?.length > 0 &&
                                        data?.perquisite_breakup_amtp.map((item, index) => {
                                            return (
                                                <tr className="salarytaxregime_edit_alternetbgclr_grey">
                                                    <td width=""></td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft">
                                                        {headingCodeList17two?.get(item?.particular_code)?.description ?? ""}
                                                    </td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell text-left">
                                                        {formatNumber(item.perquisite_amtp ?? 0)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    <tr>
                                        <td></td>
                                        <td className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom"></td>
                                        <td className="salarytaxreg_innertb_bgclr_lightyell text-right salarytaxreg_innertb_borderbottom"></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewPrequisitesLite