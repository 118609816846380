import { useEffect } from 'react';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import { getPanListToVerifyAtTracesViaConnector } from '../../api/serverConnectorAPI';
import { verifyBulkPanFromConnector } from '../../api/localConnectorAPI';

const BulkPanVerify = (props) => {

  const { deductorId, returnId, port, captchaRef, setError, setSuccess, statusKey, setBulkPanVerificationStatusData, bulkPanDMScreen, setReloadPanList } = props;

  useEffect(() => {

    const bulkPanVerification = (deductorId, returnId, port, captchaRef, setError, statusKey) => {

      async function fetchEverything() {
        try {
          // //call PAN listing API from server
          const params = {
            "captcha_ref": captchaRef ?? '',
            "deductor_id": deductorId ?? '',
            "return_id": returnId ?? '',
            "get_expired_pan_only": (bulkPanDMScreen ? 1 : 0),
          }         
          const bulkPanResult = await getPanListToVerifyAtTracesViaConnector(params);
          const responseData = bulkPanResult.data ?? null;
          const responseHeader = bulkPanResult.headers ?? null;

          //Connector request data
          const connectorRequest = {
            "data": responseData.data
          };
          let extraHeader = {};
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          try {
            const connectorResponse = await verifyBulkPanFromConnector(port, connectorRequest, extraHeader);
            if (Number(connectorResponse.status) === 1) {
              //set local storage key to get PAN verify status data
              const statusData = { 'captcha_ref': captchaRef, 'port': port };
              localStorage.setItem(statusKey, JSON.stringify(statusData));
              setBulkPanVerificationStatusData(statusData);
            setSuccess("Your Bulk PAN Verification process has started successfully. You may monitor the status of the process at the bottom of the screen. Please ensure that the Connector service keeps running, and the system is not shut down.");
            }

          } catch (err) {
            setError(err.message);
          }

        } catch (err) {
          console.error('error: ', err);
          setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? err?.message ?? CONNECTOR_ERRORS_MAPPING['default']);
          if (setReloadPanList) {
            setTimeout(() => {
              setReloadPanList(true);
            }, 5000)
          }
        } finally {
        }
      }

      fetchEverything();
    };

    bulkPanVerification(deductorId, returnId, port, captchaRef, setError, statusKey);

    //eslint-disable-next-line
  }, [deductorId, returnId, port, captchaRef]);


  return (null);
};

export default BulkPanVerify;
