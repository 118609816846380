import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import queryString from 'query-string';
import {
  Formik, Form, Field,
} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash, faCircle
} from '@fortawesome/free-solid-svg-icons';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Button from 'react-bootstrap/Button';
import { deductorFormValidationSchema } from './DeductorFormSchema';
import { correctiondDeductorFormValidationSchema } from './CorrectionDeductorFormSchema';
import './CreateDeductorManually.css';
import itdIcon from '../../images/itd_icon.png';
import tracesIcon from '../../images/traces_icon.png';
import checkIcon from '../../images/icons/check.svg';
import infoIcon from '../../images/info.png';
import exclamationIcon from "../../images/exclamation.png";

import {
  BASE_PATH, STATES, DEPARTMENT_NAMES, GOVERNMENT_DEDUCTOR_TYPES,
  // DEDUCTOR_CATEGORIES, 
  DEDUCTOR_CATEGORIES_LIST,
} from '../../app/constants';
import ErrorFocus from '../ErrorFocus';
import AlphaNumericInput from '../AlphaNumericInput';
import NumericInput from '../NumericInput';
import PANInput from '../PANInput';
import AlphaNumericInputWithDropdown from '../AlphaNumericInputWithDropdown';
import { getInsightsCredentialsForVerificationByConnector } from '../../api/deductorAPI';
import ConnectorAdapter from '../kdkConnector/ConnectorAdapter';
import TracesConnector from '../kdkConnector/TracesConnector';
import SinglePanVerify from '../verifyPAN/SinglePanVerify';
import { verifyEFilingCredsFromConnector, verifyInsightsLoginFromConnector } from '../../api/localConnectorAPI';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import ShowAlert from '../ShowAlert';
import InsightPortalPopup from '../../features/editDeductor/InsightPortalPopup';
import { deductor_details } from './DeductorFormHelper';

export const stateList = [
  { label: 'ANDAMAN AND NICOBAR ISLANDS', value: 1 },
  { label: 'ANDHRA PRADESH', value: 2 },
  { label: 'ARUNACHAL PRADESH', value: 3 },
  { label: 'ASSAM', value: 4 },
  { label: 'BIHAR', value: 5 },
  { label: 'CHANDIGARH', value: 6 },
];
const newPattPAN = new RegExp(/^([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z])$/);

const pattPAN = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

const tanPattern = new RegExp('[A-Z]{4}[0-9]{5}[A-Z]{1}');
const GstinPattern = new RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/);
const emailPattern = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);

const uernameMustTenDigit = new RegExp(/[\S]{10,}$/);
const uername1stTo5thLetterAlphabet = new RegExp(/^(?:[A-Z]){5}/);
const uername4thLetterAlphabets = ["P", "C", "H", "A", "B", "G", "J", "L", "F", "T"];
const uername6thTo9thLetterNumeric = new RegExp(/[0-9]{4}/);
const uername10thLetterAlphabet = new RegExp(/[A-Z]{1}/);


export default function CreateDeductorForm(props) {
  const {
    deductorInfo, deductorId, createDeductor, returnType, returnId, initialData, componentImport, correctionDeductorInfo, consoStatus
  } = props;
  const location = useLocation();
  const params = queryString.parse(location.search);  

  const [initialTracesInfo, setInitialTracesInfo] = useState({
    traces_username: '',
    traces_password: '',
    traces_verified: false,
  });
  const [initialInsightsInfo, setInitialInsightsInfo] = useState({
    insights_username: '',
    insights_password: '',
    insights_verified: false,
  });


  const [showEFilingPassword, setShowEFilingPassword] = useState(false);
  const [showTracesPassword, setShowTracesPassword] = useState(false);
  const [showInsightsPassword, setShowInsightsPassword] = useState(false);
  const [verifyPANResult, setVerifyPANResult] = useState(null);

  const [deductorSectionPanVerifyStatus, setDeductorSectionPanVerifyStatus] = useState(false);
  const [responsibleSectionPanVerifyStatus, setResponsibleSectionPanVerifyStatus] = useState(false);
  const [tracesCredsVerifyStatus, setTracesCredsVerifyStatus] = useState(false);


  const [errMsg, setErrMsg] = useState('');
  const [errMsgTan, setErrMsgTan] = useState('');
  const [disable, setDisable] = useState(false);

  const [showSinglePAN, setShowSinglePAN] = useState(false);
  const [verifyCreds, setVerifyCreds] = useState(false);
  const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
  const [checkITCreds, setCheckITCreds] = useState(false);
  const [verifyITCreds, setVerifyITCreds] = useState(false);
  const [checkResult, setCheckResult] = useState(false)
  const [insightData,setInsightData] = useState(null)

  // const [errMsgInsightsVerigy, setErrMsgInsightsVerigy] = useState('');
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("")
  const [startConnector, setStartConnector] = useState(false);
  const [verifyInsightsCreds, setVerifyInsightsCreds] = useState(false);

  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);

  const [verifyPanNumber, setVerifyPanNumber] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showDeductorDetailsPanTooltip, setShowDeductorDetailsPanTooltip] = useState(false);
  const [showResponsiblePersonPanTooltip, setShowResponsiblePersonPanTooltip] = useState(false);
  const [showInsightPortalPopup, setShowInsightPortalPopup] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [submitDetailInsightPortal, setSubmitDetailInsightPortal] = useState(null);

  const CreateManuallyDeductor = async () => {
    const getDetuctorData = JSON.parse(localStorage.getItem("deductData"));
    localStorage.removeItem('deductData');

    // setLoading(true);
    if (getDetuctorData) {
      deductorInfo.tan = getDetuctorData?.tan;
      deductorInfo.traces_password = getDetuctorData?.data.traces_password;
      deductorInfo.traces_username = getDetuctorData?.data?.traces_username;
      deductorInfo.deductor_name = getDetuctorData?.deductor_name;
      deductorInfo.pan = getDetuctorData?.data?.pan;

      deductorInfo.address_state_id = getDetuctorData?.data?.address?.state_id;
      deductorInfo.address_area = getDetuctorData?.data?.address?.area;
      deductorInfo.address_building_name = getDetuctorData?.data?.address?.building_name;
      deductorInfo.address_building_no = getDetuctorData?.data?.address?.building_no;
      deductorInfo.address_city = getDetuctorData?.data?.address?.city;
      deductorInfo.address_pincode = getDetuctorData?.data?.address?.pincode;
      deductorInfo.address_street_name = getDetuctorData?.data?.address?.street_name;

      deductorInfo.contact_info_alternate_country_code = getDetuctorData?.data?.contact_info?.alternate_country_code;
      deductorInfo.contact_info_alternate_email = getDetuctorData?.data?.contact_info?.alternate_email;
      deductorInfo.contact_info_alternate_mobile_no = getDetuctorData?.data?.contact_info?.alternate_mobile_no;
      deductorInfo.person_name = getDetuctorData?.data?.contact_info?.person_name;
      deductorInfo.contact_info_primary_country_code = getDetuctorData?.data?.contact_info?.primary_country_code;
      deductorInfo.contact_info_primary_email = getDetuctorData?.data?.contact_info?.primary_email;
      deductorInfo.contact_info_primary_mobile_no = getDetuctorData?.data?.contact_info?.primary_mobile_no;



      deductorInfo.contact_person_contact_info_alternate_country_code = getDetuctorData?.data?.contact_person?.contact_info?.alternate_country_code;
      deductorInfo.contact_person_contact_info_alternate_email = getDetuctorData?.data?.contact_person?.contact_info?.alternate_email;
      deductorInfo.contact_person_contact_info_alternate_mobile_no = getDetuctorData?.data?.contact_person?.contact_info?.alternate_mobile_no;
      deductorInfo.contact_person_contact_info_person_name = getDetuctorData?.data?.contact_person?.contact_info?.person_name;
      deductorInfo.contact_person_contact_info_primary_country_code = getDetuctorData?.data?.contact_person?.contact_info?.primary_country_code;
      deductorInfo.contact_person_contact_info_primary_email = getDetuctorData?.data?.contact_person?.contact_info?.primary_email;
      deductorInfo.contact_person_contact_info_primary_mobile_no = getDetuctorData?.data?.contact_person?.contact_info?.primary_mobile_no;

      deductorInfo.deductor_status = getDetuctorData.data.deductor_company_status.toString();
      deductorInfo.deductor_category = getDetuctorData.data.deductor_category.toString();
      deductorInfo.branch = getDetuctorData.data.branch.toString();

      deductorInfo.responsible_person_pan = getDetuctorData?.data?.responsible_person.pan;
      deductorInfo.responsible_person_designation = getDetuctorData?.data?.responsible_person?.designation;
      deductorInfo.responsible_person_contact_info_person_name = getDetuctorData?.data?.responsible_person?.contact_info?.person_name;
      deductorInfo.responsible_person_address_area = getDetuctorData?.data?.responsible_person?.address?.area;
      deductorInfo.responsible_person_address_building_name = getDetuctorData?.data?.responsible_person?.address?.building_name;
      deductorInfo.responsible_person_address_building_no = getDetuctorData?.data?.responsible_person?.address?.building_no;
      deductorInfo.responsible_person_address_city = getDetuctorData?.data?.responsible_person?.address?.city;
      deductorInfo.responsible_person_address_pincode = getDetuctorData?.data?.responsible_person?.address?.pincode;
      deductorInfo.responsible_person_address_state_id = getDetuctorData?.data?.responsible_person?.address?.state_id;
      deductorInfo.responsible_person_address_street_name = getDetuctorData?.data?.responsible_person?.address?.street_name;

      deductorInfo.responsible_person_contact_info_alternate_country_code = getDetuctorData?.data?.responsible_person?.contact_info?.alternate_country_code;
      deductorInfo.responsible_person_contact_info_alternate_email = getDetuctorData?.data?.responsible_person?.contact_info?.alternate_email;
      deductorInfo.responsible_person_contact_info_alternate_mobile_no = getDetuctorData?.data?.responsible_person?.contact_info?.alternate_mobile_no;
      deductorInfo.responsible_person_contact_info_primary_country_code = getDetuctorData?.data?.responsible_person?.contact_info?.primary_country_code;
      deductorInfo.responsible_person_contact_info_primary_email = getDetuctorData?.data?.responsible_person?.contact_info?.primary_email;
      deductorInfo.responsible_person_contact_info_primary_mobile_no = getDetuctorData?.data?.responsible_person?.contact_info?.primary_mobile_no;
      deductorInfo.insights_reg_status = getDetuctorData.data.insights_reg_status.toString();
      deductorInfo.insights_reg_refno = getDetuctorData.data.insights_reg_refno.toString();
      deductorInfo.insights_reg_email = getDetuctorData.data.insights_reg_email.toString();
      deductorInfo.insights_login_exists = getDetuctorData.data.insights_login_exists.toString();

      setTimeout(function () {
        $("#save_deductor_btn").trigger("click");
      }, 100);      
    }
  }

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    if (params.focus) {
      $(`#${params.focus}`).focus();
    }
    setInitialTracesInfo({
      tan: deductorInfo.tan,
      traces_username: deductorInfo.traces_username,
      traces_password: deductorInfo.traces_password,
      traces_verified: deductorInfo.traces_verified,
    });
    setInitialInsightsInfo({
      tan: deductorInfo.tan,
      insights_username: deductorInfo.insights_username,
      insights_password: deductorInfo.insights_password,
      insights_verified: deductorInfo.insights_verified,
    });
    setVerifyInsightsCreds(deductorInfo?.insights_verified ?? false);

    if (deductorInfo?.traces_verified) {
      setVerifyCreds(true);
      setTracesCredsVerifyStatus(true);
    }
    if (deductorInfo?.efill_password) {
      setVerifyITCreds(true)
    }

    CreateManuallyDeductor();    
    //eslint-disable-next-line
  }, []);

  const checkPanMatche = (value) => {
    const panVal = value.toString();
    if (!newPattPAN.test(value) && panVal.length === 10) {
      setErrMsg("Invalid PAN format.");
    } else {
      setErrMsg(null);
    }
  }

  const CheckTanValidation = async (tanValue) => {
    if (tanValue !== '' && !tanPattern.test(tanValue)) {
      setDisable(true);
      setErrMsgTan('Invalid TAN Number');
    } else {
      setDisable(false);
      setErrMsgTan(null);
    }
  }

  const verifyInsightsCredentials = async (values, port) => {
    const insights_username = values.insights_username;
    const insights_password = values.insights_password;
    if (insights_username.trim() !== '' && insights_password.trim() !== '') {
      async function fetchEverything() {
        try {
          setLoading(true);
          let extraHeader = {};
          const request = {
            'deductor_id': deductorId,
            'insights_username': insights_username,
            'insights_password': insights_password,
            'logout_immediately': 1
          };

          const serverApiResult = await getInsightsCredentialsForVerificationByConnector(request);
          const EncryptLoginData = serverApiResult.data
          const EncryptLoginDataHeader = serverApiResult.headers ?? null;


          const connectorRequest = {
            "data": EncryptLoginData?.data
          };

          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = EncryptLoginDataHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          try {
            const connectorResult = await verifyInsightsLoginFromConnector(port, connectorRequest, extraHeader);

            if (connectorResult.status === 1) {
              setVerifyInsightsCreds(true);
            }

          } catch (err) {

            setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
          }

        } catch (err) {
          // console.log('Error', err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
      fetchEverything();
    }
  }


  const verifyIncomeTaxCredentials = async (values, port) => {
    try {
      setLoading(true);
      const request = {
        'itdportal_username': values.tan ?? '',
        'itdportal_password': values.efill_password ?? ''
      }

      const loginResult = await verifyEFilingCredsFromConnector(port, request);
      if (loginResult?.status === 1) {
        setCheckResult(true);
      }

    } catch(err) {
      setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
    } finally {
      setLoading(false);
    }
  }

  const removeSpeiclaChars = (string) => {
    //eslint-disable-next-line
    if (string) return string.trim().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    return string;
  }

  const valdiatePANFormat = (key, value, setFieldError) => {
    if (!newPattPAN.test(value) || value.length !== 10) {
      setFieldError(key, 'Invalid PAN format.');
    }
  }

  
  return (
    <div>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(''); setSuccess(null)}}
      />
       
      {userDetails &&
        <InsightPortalPopup
          show={userDetails}
          handleClose={() => setUserDetails(null)}
          alreadyFillData={userDetails.alreadyFillData}
          itCredentional={userDetails?.ItCredentional}
          deductorId={deductorId}
          setSubmitDetailInsightPortal={setSubmitDetailInsightPortal}
          setSuccess1={setSuccess}
          setInsightData={setInsightData}
          />
      }
       
      <Formik
        initialValues={deductorInfo}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={componentImport ? correctiondDeductorFormValidationSchema : deductorFormValidationSchema}
        onSubmit={(values) => {
          // same shape as initial values
          if (verifyCreds && tracesCredsVerifyStatus) {
            values.traces_verified = true;
          }
          if (verifyInsightsCreds) {
            values.insights_verified = true;
          }

          createDeductor(values);
        }}
        validate={values => {
          const errors = {};
          if (values.deductor_name && removeSpeiclaChars(values.deductor_name).length === 0) {
            errors.deductor_name = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.responsible_person_contact_info_person_name && removeSpeiclaChars(values.responsible_person_contact_info_person_name).length === 0) {
            errors.responsible_person_contact_info_person_name = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.contact_person_contact_info_person_name && removeSpeiclaChars(values.contact_person_contact_info_person_name).length === 0) {
            errors.contact_person_contact_info_person_name = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.responsible_person_designation && removeSpeiclaChars(values.responsible_person_designation).length === 0) {
            errors.responsible_person_designation = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.address_building_no && removeSpeiclaChars(values.address_building_no).length === 0) {
            errors.address_building_no = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.address_building_name && removeSpeiclaChars(values.address_building_name).length === 0) {
            errors.address_building_name = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.address_street_name && removeSpeiclaChars(values.address_street_name).length === 0) {
            errors.address_street_name = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.address_area && removeSpeiclaChars(values.address_area).length === 0) {
            errors.address_area = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.address_city && removeSpeiclaChars(values.address_city).length === 0) {
            errors.address_city = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.branch && removeSpeiclaChars(values.branch).length === 0) {
            errors.branch = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.responsible_person_address_building_no && removeSpeiclaChars(values.responsible_person_address_building_no).length === 0) {
            errors.responsible_person_address_building_no = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.responsible_person_address_building_name && removeSpeiclaChars(values.responsible_person_address_building_name).length === 0) {
            errors.responsible_person_address_building_name = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.responsible_person_address_building_name && removeSpeiclaChars(values.responsible_person_address_building_name).length === 0) {
            errors.responsible_person_address_building_name = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.responsible_person_address_street_name && removeSpeiclaChars(values.responsible_person_address_street_name).length === 0) {
            errors.responsible_person_address_street_name = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.responsible_person_address_area && removeSpeiclaChars(values.responsible_person_address_area).length === 0) {
            errors.responsible_person_address_area = "Only special characters, blank spaces and Dots not allowed.";
          }
          if (values.responsible_person_address_city && removeSpeiclaChars(values.responsible_person_address_city).length === 0) {
            errors.responsible_person_address_city = "Only special characters, blank spaces and Dots not allowed.";
          }

          return errors;
        }}
      >
        {({
          errors, values, setValues, setFieldValue, setFieldError
        }) => (
          <Form key={1} className="cdm" autoComplete="off">

            {
              startConnector &&
              <ConnectorAdapter
                show={startConnector}
                handleClose={() => setStartConnector(false)}
                setPort={setPort}
                handleProceed={(port) => {
                  verifyInsightsCredentials(values, port);
                }}
              />
            }


            {showSinglePAN ?
              <ConnectorAdapter
                show={showSinglePAN}
                handleClose={() => setShowSinglePAN(false)}
                setPort={setPort}
                handleProceed={() => setCheckTracesCredentials(true)}
              />
              : null}

            {checkTracesCredentials && port
              ? <TracesConnector
                deductorId={deductorId}
                returnId={null}
                handleProceed={() => {
                  setShowSinglePAN(false);
                  setCheckTracesCredentials(false);
                  setVerifyPanNumber(false);
                }}
                port={port}
                setCaptchaRef={setCaptchaRef}
                setVerifyCreds={setVerifyCreds}
                creds={{
                  "tan": values.tan,
                  "traces_username": values.traces_username,
                  "traces_password": values.traces_password
                }}
              />
              : null
            }

            {verifyPanNumber && captchaRef && port
              ? <SinglePanVerify
                deductorId={deductorId}
                port={port}
                captchaRef={captchaRef}
                setError={() => null}
                setVerifyPANResult={setVerifyPANResult}
                pan={values.pan}
                //setLoading={() => null}
                setLoading={setLoading}
                handleProceed={() => {
                  setVerifyPanNumber(false)
                }}
              />
              : null
            }

            {checkITCreds &&
              <ConnectorAdapter
                show={checkITCreds}
                handleClose={() => setCheckITCreds(false)}
                setPort={setPort}
                handleProceed={(port) => {
                  verifyIncomeTaxCredentials(values, port);
                }}
              />
            }

            {checkITCreds &&
              <ConnectorAdapter
                show={checkITCreds}
                handleClose={() => setCheckITCreds(false)}
                setPort={setPort}
                handleProceed={(port) => {
                  verifyIncomeTaxCredentials(values, port);
                }}
              />
            }

            {showInsightPortalPopup &&
              <ConnectorAdapter
                show={showInsightPortalPopup}
                handleClose={() => setShowInsightPortalPopup(false)}
                setPort={setPort}
                handleProceed={(port) => {
                  const userDetails =  deductor_details(values);
                  const userInfo = {
                    ItCredentional: values,
                    alreadyFillData: userDetails
                  }
                  setUserDetails({...userInfo});
                }}
              />
            }

            <ErrorFocus />

            {!componentImport ?
              <div className="container-fluid w-1300">
                {returnType === 'regular' ? (
                  <div className="mt-2" />
                ) : (
                  <div className="mt-0" />
                )}
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-xs-12 pl-0">
                    <div className="float-left pt-1">
                      {returnType === 'regular' ? (
                        <button type="button" className="f-12 btnblmrsm btn btn-link p-0" style={{ color: 'rgb(252, 125, 114)' }}>
                          <Link to={BASE_PATH} className="editddtorgivebackbtn"> &lt; Back to List of Deductors </Link>
                        </button>
                      ) : (
                        <button type="button" className="f-12 btnblmrsm btn btn-link p-0" style={{ color: 'rgb(252, 125, 114)' }}>
                          <Link to={`${BASE_PATH}correction-return-dashboard/${deductorId}/return/${returnId}`} className="editddtorgivebackbtn corrflopw"> &lt; Back to Return Dashboard </Link>
                        </button>
                      )}

                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-4 col-md-3 col-sm-4 col-xs-12 text-center">
                    {returnType === 'regular' ? (
                      <h4 className='tds_editdeductor_titlefs'>{deductorId ? 'Edit Deductor ' : 'Create Deductor'}</h4>
                    ) : (
                      <h4 className='tds_editdeductor_titlefs'>{deductorId ? ' ' : 'Create Deductor'}</h4>
                    )}

                  </div>
                  <div className="col-xl-4 col-lg-5 col-md-5 col-sm-4 col-xs-12 pr-0">
                    {returnType === 'regular' ? (
                      <div className="row">
                        <div className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-12 smbtnmrl switchtogglebtn">
                          {deductorId ? (
                            <BootstrapSwitchButton
                              checked={!!values.deductor_status}
                              onlabel="Active"
                              onstyle="outline-success"
                              offlabel="In-Active"
                              offstyle="outline-secondary"
                              Style="w-100 mx-3 f-14 editddswbtn"

                              onChange={(checked) => {
                                setFieldValue('deductor_status', checked ? 1 : 0);
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-8 col-sm-7">
                          <div className="float-right smtpadd">
                            <button type="submit" id="save_deductor_btn" className="light_color_bg float-right editdsavebtn" disabled={disable} style={disable ? { opacity: 0.6, marginTop: '1.5px', padding: '5px 21px' } : { marginTop: '1.5px', padding: '5px 21px' }}>Save</button>
                            <Link to={BASE_PATH}>
                              <button type="button" className="light-btn float-right mr-2 editdcanclebtn">Cancel</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (

                      <div className="float-right smtpadd">
                        <button type="submit" id="save_deductor_btn" className="light_color_bg float-right editdsavebtn" style={{ marginTop: '2px', padding: '5px 31px' }}>Save</button>

                      </div>

                    )}

                  </div>
                </div>
              </div> : null}

            {!componentImport ?
              <div className="container-fluid w-1300">
                <div className="row">
                  <div className="col-md-4">
                    <fieldset className="tdseditdeductor_itFS">
                      <legend className="tdseditdeductor_it_leg">
                        <div className="tdseditdeductor_it_inndev">
                          <div className="tdseditdeductor_it_left">
                            <h4>IT Credentials</h4>
                          </div>
                          <div className="tdseditdeductor_it_innerdiv"></div>
                          <div className="tdseditdeductor_it_right">
                            {verifyITCreds || checkResult
                              ? (
                                <span className="tdseditdeductor_it_verifytextfs_span green_text">
                                  <img width="13px" src={checkIcon} alt="" />
                                  {' '}
                                  Verified
                                </span>
                              ) : (
                                <Button
                                  variant="link"
                                  className="btn btn-default tdseditdeductor_it_verifytextfs dark_color_text"
                                  style={{
                                    fontSize: '12px',
                                    paddingTop: '0px',
                                    paddingRight: '5px',
                                  }}
                                  disabled={!values.traces_username || !values.traces_password}
                                  onClick={() => {
                                    setCheckITCreds(true);
                                  }}
                                >
                                  Verify Now
                                </Button>
                              )}
                          </div>
                          <div className="tdseditdeductor_it_middleborder"></div>
                        </div>
                      </legend>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="from from-row">
                            <div className="form-group col-md-12 mt-1 mb-2 verify_btn_bottom_margin">
                              <label htmlFor="tan">
                                <img className="label_image" src={itdIcon} alt="" />
                                {' '}
                                TAN
                                <span className="red_text">*</span>
                              </label>
                              <AlphaNumericInput
                                type="text"
                                className="form-control"
                                id="tan"
                                placeholder="Enter TAN"
                                name="tan"
                                value={values.tan}
                                onChange={(e) => {
                                  setFieldValue('tan', e.target.value.trim().toUpperCase());
                                  setVerifyITCreds(false)
                                  if (e.target.value.trim().toUpperCase() !== initialTracesInfo.tan) {
                                    setFieldValue('traces_verified', false);
                                  } else if (values.traces_username === initialTracesInfo.traces_username && values.traces_password === initialTracesInfo.traces_password) {
                                    setFieldValue('traces_verified', initialTracesInfo.traces_verified);
                                  }                                  
                                }}
                                onBlur={(e) => {
                                  CheckTanValidation(values.tan);
                                }}
                                maxLength={10}
                                disabled={deductorId}
                                autoComplete="new-password"
                              />
                              {errors.tan ? <span className="error_message">{errors.tan}</span> : null}
                              {errMsgTan ? <span className="error_message" style={{ fontSize: '11px', paddingLeft: '0px' }}>{errMsgTan}</span> : null}
                            </div>

                            <div className="form-group col-md-12 mt-1">
                              <label htmlFor="efill_password">
                                <img className="label_image" src={itdIcon} alt="" />
                                {' '}
                                Income Tax e-filing Password
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              {showEFilingPassword ? (
                                <div className="input-group">
                                  <Field
                                    type="input"
                                    className={`form-control ${errors?.efill_password ? 'input-error1' : ''}`}
                                    id="efill_password"
                                    placeholder="Enter e-filing Password"
                                    name="efill_password"
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                      setFieldValue('efill_password', e.target.value);
                                      setVerifyITCreds(false)
                                      if (errors.efill_password && e.target.value) {
                                        errors.efill_password = ""
                                      }
                                    }}
                                  />
                                  <div
                                    className="input-group-prepend eyeipbox-create-deductor"
                                    onClick={() => setShowEFilingPassword(false)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                  </div>
                                </div>
                              ) : (
                                <div className="input-group">
                                  <Field
                                    type="password"
                                    className={`form-control ${errors?.efill_password ? 'input-error1' : ''}`}
                                    id="efill_password"
                                    placeholder="Enter e-filing Password"
                                    name="efill_password"
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                      setFieldValue('efill_password', e.target.value);
                                      if (errors.efill_password && e.target.value) {
                                        errors.efill_password = ""
                                      }
                                    }}
                                  />
                                  <div
                                    className="input-group-prepend eyeipbox-create-deductor"
                                    onClick={() => setShowEFilingPassword(true)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </div>
                                </div>
                              )}
                              {errors.efill_password ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.efill_password}</span> : null}
                            </div>

                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-md-4">
                    <fieldset className="tdseditdeductor_itFS tdseditdeductor_tracsFS_color">
                      <legend className="tdseditdeductor_it_leg">
                        <div className="tdseditdeductor_it_inndev">
                          <div className="tdseditdeductor_it_left">
                            <h4>TRACES Credentials</h4>
                          </div>
                          <div className="tdseditdeductor_it_innerdiv"></div>
                          <div className="tdseditdeductor_it_right">
                            {verifyCreds && tracesCredsVerifyStatus
                              ? (
                                <span className='tdseditdeductor_it_verifytextfs_span green_text'>
                                  <img width="13px" src={checkIcon} alt="" />
                                  {' '}
                                  Verified
                                </span>
                              ) : (
                                <Button
                                  variant="link"
                                  className="btn btn-default tdseditdeductor_it_verifytextfs dark_color_text"
                                  style={{
                                    fontSize: '12px',
                                    paddingTop: '0px',
                                    paddingRight: '5px',
                                  }}
                                  disabled={!values.traces_username || !values.traces_password || !values.tan}
                                  onClick={() => {
                                    setShowSinglePAN(true);
                                    setTracesCredsVerifyStatus(true);
                                  }}
                                >
                                  Verify Now
                                </Button>
                              )}

                          </div>
                          <div className="tdseditdeductor_it_middleborder tdseditdeductor_tracsFS_middclr"></div>
                        </div>
                      </legend>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="from from-row">
                            <div className="form-group col-md-12 mt-1 mb-2 verify_btn_bottom_margin">
                              <label htmlFor="traces_username">
                                <img className="label_image" src={tracesIcon} alt="" />
                                {' '}
                                TRACES Username
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                type="name"
                                className={`form-control ${errors?.traces_username ? 'input-error1' : ''}`}
                                id="traces_username"
                                placeholder="Enter TRACES Username"
                                name="traces_username"
                                value={values.traces_username}
                                maxLength={11}
                                onChange={(e) => {
                                  setFieldValue('traces_username', e.target.value);
                                  setVerifyCreds(false);
                                  setTracesCredsVerifyStatus(false);
                                  if (e.target.value !== initialTracesInfo.traces_username) {
                                    setFieldValue('traces_verified', false);
                                  } else if (values.traces_password === initialTracesInfo.traces_password && values.tan === initialTracesInfo.tan) {
                                    setFieldValue('traces_verified', initialTracesInfo.traces_verified);
                                  }
                                  if (errors.traces_username && e.target.value) {
                                    errors.traces_username = ""
                                  }
                                }}
                                autoComplete="new-password"
                              />
                              {errors.traces_username ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.traces_username}</span> : null}
                            </div>
                            <div className="form-group col-md-12 mt-1">
                              <label htmlFor="traces_password">
                                <img className="label_image" src={tracesIcon} alt="" />
                                {' '}
                                TRACES Password
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              {showTracesPassword ? (
                                <div className="input-group">
                                  <Field
                                    type="input"
                                    className={`form-control ${errors?.traces_password ? 'input-error1' : ''}`}
                                    id="traces_password"
                                    placeholder="Enter TRACES Password"
                                    name="traces_password"
                                    value={values.traces_password}
                                    onChange={(e) => {
                                      setFieldValue('traces_password', e.target.value);
                                      setVerifyCreds(false);
                                      setTracesCredsVerifyStatus(false);
                                      if (e.target.value !== initialTracesInfo.traces_password) {
                                        setFieldValue('traces_verified', false);
                                      } else if (values.traces_username === initialTracesInfo.traces_username && values.tan === initialTracesInfo.tan) {
                                        //setFieldValue('traces_verified', initialTracesInfo.traces_verified);
                                        setFieldValue('traces_verified', verifyCreds && tracesCredsVerifyStatus);
                                      }
                                      if (errors.traces_password && e.target.value) {
                                        errors.traces_password = ""
                                      }
                                    }}
                                    autoComplete="new-password"
                                  />
                                  <div
                                    className="input-group-prepend eyeipbox-create-deductor"
                                    onClick={() => setShowTracesPassword(false)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                  </div>
                                </div>
                              ) : (
                                <div className="input-group">
                                  <Field
                                    type="password"
                                    className={`form-control ${errors?.traces_password ? 'input-error1' : ''}`}
                                    id="traces_password"
                                    placeholder="Enter TRACES Password"
                                    name="traces_password"
                                    value={values.traces_password}
                                    onChange={(e) => {
                                      setFieldValue('traces_password', e.target.value);
                                      if (e.target.value !== initialTracesInfo.traces_password) {
                                        setFieldValue('traces_verified', false);
                                      } else if (values.traces_username === initialTracesInfo.traces_username && values.tan === initialTracesInfo.tan) {
                                        setFieldValue('traces_verified', initialTracesInfo.traces_verified);
                                      }
                                      if (errors.traces_password && e.target.value) {
                                        errors.traces_password = ""
                                      }
                                    }}
                                    autoComplete="new-password"
                                  />
                                  <div
                                    className="input-group-prepend eyeipbox-create-deductor"
                                    onClick={() => setShowTracesPassword(true)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </div>
                                </div>
                              )}
                              {errors.traces_password ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.traces_password}</span> : null}
                            </div>

                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-md-4">
                    <fieldset className="tdseditdeductor_itFS">
                      <legend className="tdseditdeductor_it_leg">
                        <div className="tdseditdeductor_it_inndev">
                          <div className="tdseditdeductor_it_left">
                            <h4>Insights Credentials</h4>
                          </div>
                          <div className="tdseditdeductor_it_innerdiv"></div>
                          <div className="tdseditdeductor_it_right">
                            {insightData ? <Button
                                  variant="link"
                                  className={`btn btn-default tdseditdeductor_it_verifytextfs 
                                    dark_color_text `}
                                  style={{
                                    fontSize: '12px',
                                    paddingTop: '0px',
                                    paddingRight: '5px',
                                  }}

                                  onClick={()=>{setSuccess(`           
                                     Your registration request has been successfully submitted, and your reference number is ${insightData.ref_no}. You will receive the ITDREIN and user credentials via email at ${insightData?.email}. Please enter these credentials in ExpressTDS to complete the compliance check.
            
                                    `);

                                  }}
                                >
                                  Submitted
                                </Button> :   verifyInsightsCreds
                              ? (
                                <span className="tdseditdeductor_it_verifytextfs_span green_text">
                                  <img width="13px" src={checkIcon} alt="" />
                                  {' '}
                                  Verified
                                </span>
                              ) : ((verifyITCreds || checkResult) && !values.insights_reg_status && !submitDetailInsightPortal
                               && !values.insights_username && !values.insights_password) && values?.insights_login_exists !== 1
                               ? (
                                <Button
                                  variant="link"
                                  className="btn btn-default tdseditdeductor_it_verifytextfs dark_color_text"
                                  style={{
                                    fontSize: '12px',
                                    paddingTop: '0px',
                                    paddingRight: '5px',
                                  }}
                                  onClick={() => {
                                    setShowInsightPortalPopup(true);
                                  }}
                                >
                                  Register Now
                                </Button>
                              ) : ((submitDetailInsightPortal || values.insights_reg_status === "SUBMITTED") &&
                                    !values.insights_username && !values.insights_password
                                  ) ?  
                              <Button
                                  variant="link"
                                  className={`btn btn-default tdseditdeductor_it_verifytextfs 
                                    dark_color_text ${values?.insights_reg_status ==="SUBMITTED" ? "" :"disabled"}`}
                                  style={{
                                    fontSize: '12px',
                                    paddingTop: '0px',
                                    paddingRight: '5px',
                                  }}

                                  onClick={()=>{setSuccess(`Your registration request has been successfully submitted, and your reference number is ${values.insights_reg_refno}. You will receive the ITDREIN and user credentials via email at ${values?.insights_reg_email}. Please enter these credentials in ExpressTDS to complete the compliance check.`);

                                  }}
                                >
                                  Submitted
                                </Button>
                              :(
                                <Button
                                  variant="link"
                                  className="btn btn-default tdseditdeductor_it_verifytextfs dark_color_text"
                                  style={{
                                    fontSize: '12px',
                                    paddingTop: '0px',
                                    paddingRight: '5px',
                                  }}
                                  disabled={!values.insights_username || !values.insights_password}
                                  onClick={() => {
                                    setStartConnector(true);

                                  }}
                                >
                                  Verify Now
                                </Button>
                              )}
                          </div>
                          <div className="tdseditdeductor_it_middleborder"></div>
                        </div>
                      </legend>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="from from-row">
                            <div className="form-group col-md-12 mt-1 mb-2 verify_btn_bottom_margin">
                              <label htmlFor="insights_username">
                                <img className="label_image" src={itdIcon} alt="" />
                                {' '}
                                Insights Username
                              </label>
                              <Field
                                type="name"
                                className="form-control"
                                id="insights_username"
                                placeholder="Enter Insights Username"
                                name="insights_username"
                                value={values.insights_username}
                                onChange={(e) => {
                                  setFieldValue('insights_username', e.target.value.trim().toUpperCase());
                                  setVerifyInsightsCreds(false);
                                  if (e.target.value !== initialInsightsInfo.insights_username) {
                                    setFieldValue('insights_verified', false);
                                  } else if (values.insights_password === initialInsightsInfo.insights_password) {
                                    setFieldValue('insights_verified', initialInsightsInfo.insights_verified);
                                  }
                                }}
                                autoComplete="new-password"
                              />

                            </div>
                            <div className="form-group col-md-12 mt-1">
                              <label htmlFor="insights_password">
                                <img className="label_image" src={itdIcon} alt="" />
                                {' '}
                                Insights Password
                              </label>
                              {showInsightsPassword ? (
                                <div className="input-group">
                                  <Field
                                    type="input"
                                    className="form-control"
                                    id="insights_password"
                                    placeholder="Enter Insights Password"
                                    name="insights_password"
                                    value={values.insights_password}
                                    onChange={(e) => {
                                      setFieldValue('insights_password', e.target.value);
                                      setVerifyInsightsCreds(false);
                                      if (e.target.value !== initialInsightsInfo.insights_password) {
                                        setFieldValue('insights_verified', false);
                                      } else if (values.insights_username === initialInsightsInfo.insights_username && values.tan === initialTracesInfo.tan) {
                                        setFieldValue('insights_verified', initialInsightsInfo.insights_verified);
                                      }
                                    }}
                                    autoComplete="new-password"
                                  />
                                  <div
                                    className="input-group-prepend eyeipbox-create-deductor"
                                    onClick={() => setShowInsightsPassword(false)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                  </div>
                                </div>
                              ) : (
                                <div className="input-group">
                                  <Field
                                    type="password"
                                    className="form-control"
                                    id="insights_password"
                                    placeholder="Enter Insights Password"
                                    name="insights_password"
                                    value={values.insights_password}
                                    onChange={(e) => {
                                      setFieldValue('insights_password', e.target.value);
                                      if (e.target.value !== initialInsightsInfo.insights_password) {
                                        setFieldValue('insights_verified', false);
                                      } else if (values.traces_username === initialInsightsInfo.insights_username && values.tan === initialTracesInfo.tan) {
                                        setFieldValue('insights_verified', initialInsightsInfo.insights_verified);
                                      }
                                    }}
                                    autoComplete="new-password"
                                  />
                                  <div
                                    className="input-group-prepend eyeipbox-create-deductor"
                                    onClick={() => setShowInsightsPassword(true)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </div>
                                </div>
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>


                </div>
              </div>
              :
              null
            }


            <div className="container-fluid w-1300">
              <div className="row pb-5">
                <div className="col-md-6">
                  <fieldset className="editeddfieldset mt-0 pt-0">
                    <legend className="editeddlegent">
                      <h4>Deductor Details</h4>
                    </legend>

                    <div className="">
                      <div className="deductordetails">

                        <div className="row mt-1">
                          <div className="form-group col-md-6 colbotpaddrem">
                            <label htmlFor="deductor_name">
                              Deductor Name
                              <span className="red_text">*</span>
                            </label>
                            <Field
                              type="name"
                              className={`form-control ${errors?.deductor_name ? 'input-error1' : ''}`}
                              id="deductor_name"
                              placeholder="Enter Deductor Name"
                              name="deductor_name"
                              autocomplete="no"
                              maxLength={75}
                              onChange={(e) => {
                                setFieldValue('deductor_name', e.target.value);
                                if (errors.deductor_name && e.target.value) errors.deductor_name = "";
                              }}
                              onBlur={(e) => {
                                if (String(e.target.value).length === 0) {
                                  setFieldError('deductor_name', "This field is required");
                                } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                  setFieldError('deductor_name', "Only special characters, blank spaces and Dots not allowed.");
                                }
                              }}
                            />                            
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo.old_deductor_name) ? (<span className="error_message" style={{ color: correctionDeductorInfo.deductor_name_color ? `#${correctionDeductorInfo.deductor_name_color}` : '#0000ff' }}>{correctionDeductorInfo.old_deductor_name === '-' ? 'No previous value' : correctionDeductorInfo.old_deductor_name}</span>) : null}

                            {initialData && initialData.deductor_name_old ? (<span className="error_message" style={{ color: initialData.deductor_name_color ? `#${initialData.deductor_name_color}` : '' }}>{initialData.deductor_name_old}</span>) : null}
                            {errors.deductor_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.deductor_name}</span> : null}
                          </div>
                          <div className="form-group col-md-6 colbotpaddrem">
                            <label htmlFor="deductor_category">
                              Category
                              <span className="red_text">*</span>
                            </label>
                            
                            <div>
                              <Field
                                as="select"
                                className={`form-control deductordetails_inputbox ${errors?.deductor_category ? 'input-error1' : ''}`}
                                id="deductor_category"
                                name="deductor_category"
                                onChange={(e) => {
                                  setFieldValue('deductor_category', e.target.value);
                                  if (errors.deductor_category && e.target.value) errors.deductor_category = "";
                                }}
                              >
                                {<option value="" >Choose Category</option>}
                                {Object.keys(DEDUCTOR_CATEGORIES_LIST).map((categoryId) =>
                                  <option key={categoryId} value={categoryId}>{DEDUCTOR_CATEGORIES_LIST[categoryId]}</option>
                                )}
                              </Field>
                            </div>
                            {Object.keys(DEDUCTOR_CATEGORIES_LIST).map((categoryId) =>
                              (componentImport && correctionDeductorInfo && correctionDeductorInfo.old_deductor_category &&
                                correctionDeductorInfo.old_deductor_category === categoryId) ?
                                <span className="error_message" style={{
                                  color: correctionDeductorInfo.deductor_category_color ? `#${correctionDeductorInfo.deductor_category_color}`
                                    : '#0000ff'
                                }}>{correctionDeductorInfo.old_deductor_category === '-' ? 'No previous value' : DEDUCTOR_CATEGORIES_LIST[categoryId]}</span>
                                : null
                            )}
                            {errors.deductor_category ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.deductor_category}</span> : null}
                          </div>
                          <div className="form-group col-md-6 verify_btn_bottom_margin">
                            <label htmlFor="pan">
                              PAN
                              <span className="red_text">*</span>
                            </label>
                            <PANInput
                              className={`${errors?.pan ? 'input-error1' : ''}`}
                              value={values.pan}
                              onChange={(v) => {
                                setVerifyPANResult(null);
                                //setVerifyPANResultResponsible(null);
                                setVerifyCreds(null);
                                setFieldValue('pan', v.trim().toUpperCase());
                                if (errors.pan && v.trim()) errors.pan = "";

                              }}
                              onFocus={(e) => {
                                setShowDeductorDetailsPanTooltip(true)
                              }}
                              onBlur={(e) => {
                                setShowDeductorDetailsPanTooltip(false)
                                if (String(e.target.value).length === 0) {
                                  setFieldError('pan', 'This field is required');
                                } else {
                                  valdiatePANFormat('pan', String(e.target.value).trim().toUpperCase(), setFieldError)
                                }
                              }}
                              data={['PANNOTREQD']}
                            />
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo.old_pan) ? (<span className="error_message" style={{ color: correctionDeductorInfo.pan_color ? `#${correctionDeductorInfo.pan_color}` : '#0000ff' }}>{correctionDeductorInfo.old_pan === '-' ? 'No previous value' : correctionDeductorInfo.old_pan}</span>) : null}
                            {initialData && initialData.pan_old ? (<span className="error_message" style={{ color: initialData.pan_color ? `#${initialData.pan_color}` : '' }}>{initialData.pan_old}</span>) : null}
                            {errors.pan ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.pan}</span> : null}

                            {
                              deductorId ? (
                                deductorSectionPanVerifyStatus && verifyPANResult ? (<span className={verifyPANResult.toLowerCase() === 'active' ? 'float-right green_text mt-1' : 'float-right red_text mt-1'} style={{ fontSize: '11px' }}>{verifyPANResult}</span>)
                                  :
                                  (
                                    <div>
                                      {
                                        componentImport === undefined
                                          &&
                                          pattPAN.test(values.responsible_person_pan) ? (
                                          <Button
                                            variant="link"
                                            className="float-right dark_color_text"
                                            style={{
                                              fontSize: '12px',
                                              paddingTop: '0px',
                                              paddingRight: '0px',
                                              cursor: 'pointer'
                                            }}
                                            disabled={!values.traces_username || !values.traces_password || !values.tan || !values.responsible_person_pan}
                                            onClick={() => {
                                              setShowSinglePAN(true);
                                              setVerifyPanNumber(true);

                                              setDeductorSectionPanVerifyStatus(true);
                                              setResponsibleSectionPanVerifyStatus(false);
                                            }}
                                          >
                                            Verify
                                          </Button>
                                        ) : null
                                      }
                                    </div>
                                  )
                              ) : null

                            }

                            {showDeductorDetailsPanTooltip && (
                              <div className="usernametoolbox">
                                <div className="passlist">
                                  <p className="passmust">Username must have</p>
                                  <span>
                                    <span className="passlisticon">
                                      <FontAwesomeIcon
                                        icon={faCircle}
                                        color={uernameMustTenDigit.test(values.pan) ? 'green' : ''}
                                      />
                                    </span>
                                    Max. ten letters
                                  </span>
                                  <br />
                                  <span>
                                    <span className="passlisticon">
                                      <FontAwesomeIcon
                                        icon={faCircle}
                                        color={uername1stTo5thLetterAlphabet.test(values.pan) ? 'green' : ''}
                                      />
                                    </span>
                                    1st to 5th letters alphabet
                                  </span>
                                  <br />
                                  <span>
                                    <span className="passlisticon">
                                      <FontAwesomeIcon
                                        icon={faCircle}
                                        color={uername4thLetterAlphabets.includes(values?.pan?.[3]) ? 'green' : ''}
                                      />
                                    </span>
                                    4th letter from P,C,H,A,B,G,J,L,F,T
                                  </span>
                                  <br />
                                  <span>
                                    <span className="passlisticon">
                                      <FontAwesomeIcon
                                        icon={faCircle}
                                        color={uername6thTo9thLetterNumeric.test(values?.pan?.length > 8 ? values.pan.slice(5, 9) : "") ? 'green' : ''}
                                      />
                                    </span>
                                    6th to 9th letter numeric
                                  </span>
                                  <span>
                                    <br />
                                    <span className="passlisticon">
                                      <FontAwesomeIcon
                                        icon={faCircle}
                                        color={uername10thLetterAlphabet.test(values?.pan?.length > 9 ? values.pan.slice(8, 10) : "") ? 'green' : ''}
                                      />
                                    </span>
                                    10th letter alphabet
                                  </span>

                                </div>
                              </div>
                            )}

                          </div>
                          {
                            !componentImport &&
                            <div className="form-group col-md-6">
                              <label htmlFor="gst">GSTIN</label>
                              <AlphaNumericInput
                                type="text"
                                className={`form-control ${errors?.gst_no ? 'input-error1' : ''}`}
                                id="gst_no"
                                placeholder="Enter GSTIN"
                                name="gst_no"
                                maxLength={15}
                                value={values.gst_no}
                                onChange={(e) => {
                                  setFieldError('gst_no', "");
                                  setFieldValue('gst_no', e.target.value.trim().toUpperCase())
                                }}
                                onBlur={(e) => {
                                  if (String(e.target.value).length > 0 && !GstinPattern.test(e.target.value)) {
                                    setFieldError('gst_no', "Invalid GST Number");
                                  }
                                }}
                              />
                              {/* {(componentImport && correctionDeductorInfo && correctionDeductorInfo.old_gst_no) ? (<span className="error_message" style={{ color: correctionDeductorInfo.gst_no_color ? `#${correctionDeductorInfo.gst_no_color}` : '#0000ff' }}>{correctionDeductorInfo.old_gst_no === '-' ? 'No previous value' : correctionDeductorInfo.old_gst_no}</span>) : null} */}
                              {initialData && initialData.gst_old ? (<span className="error_message" style={{ color: initialData.gst_color ? `#${initialData.gst_color}` : '' }}>{initialData.gst_old}</span>) : null}
                              {errors.gst_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.gst_no}</span> : null}
                            </div>
                          }

                        </div>
                      </div>
                      <div className="deductorcontact mt-2 smpadcot">
                        <h6>Deductor Contact Information</h6>
                        <div className="row mt-1">
                          <div className="form-group col-md-6">
                            <label htmlFor="contact_info_primary_mobile_no">
                              Mobile Number
                              <span className="red_text">*</span>
                            </label>
                            <NumericInput
                              type="text"
                              className={`form-control ${errors?.contact_info_primary_mobile_no ? 'input-error1' : ''}`}
                              id="contact_info_primary_mobile_no"
                              placeholder="Enter Mobile Number"
                              name="contact_info_primary_mobile_no"
                              maxLength={10}
                              value={values.contact_info_primary_mobile_no}
                              onChange={(e) => {
                                setFieldValue('contact_info_primary_mobile_no', e.target.value);
                                setFieldError('contact_info_primary_mobile_no', "");
                                if (errors.contact_info_primary_mobile_no && e.target.value) errors.contact_info_primary_mobile_no = "";
                              }}
                              onBlur={(e) => {
                                if (String(e.target.value).length === 0) {
                                  setFieldError('contact_info_primary_mobile_no', "This field is required");
                                } else if (e.target.value < 6000000000 || e.target.value > 9999999999) {
                                  setFieldError('contact_info_primary_mobile_no', "Invalid Mobile Number");
                                }
                              }}
                            />
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.contact_info?.old_primary_mobile_no) ? (<span className="error_message" style={{ color: correctionDeductorInfo.contact_info.primary_mobile_no_color ? `#${correctionDeductorInfo.contact_info.primary_mobile_no_color}` : '#0000ff' }}>{correctionDeductorInfo.contact_info.old_primary_mobile_no === '-' ? 'No previous value' : correctionDeductorInfo.contact_info.old_primary_mobile_no}</span>) : null}
                            {initialData && initialData.primary_mobile_no_old ? (<span className="error_message" style={{ color: initialData.primary_mobile_no_color ? `#${initialData.primary_mobile_no_color}` : '' }}>{initialData.primary_mobile_no_old}</span>) : null}
                            {errors.contact_info_primary_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_info_primary_mobile_no}</span> : null}
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="contact_info_alternate_mobile_no">Alternate Mobile Number</label>
                            <NumericInput
                              type="text"
                              className={`form-control ${errors?.contact_info_alternate_mobile_no ? 'input-error1' : ''}`}
                              id="contact_info_alternate_mobile_no"
                              placeholder="Enter Alternate Mobile Number"
                              name="contact_info_alternate_mobile_no"
                              maxLength={10}
                              value={values.contact_info_alternate_mobile_no}
                              onChange={(e) => {
                                setFieldValue('contact_info_alternate_mobile_no', e.target.value);
                                setFieldError('contact_info_alternate_mobile_no', "");
                              }}
                              onBlur={(e) => {
                                if (String(e.target.value).length > 0 && (e.target.value < 6000000000 || e.target.value > 9999999999)) {
                                  setFieldError('contact_info_alternate_mobile_no', "Invalid Mobile Number");
                                }
                              }}
                            />
                            {errors.contact_info_alternate_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_info_alternate_mobile_no}</span> : null}

                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="contact_info_primary_email">
                              Email
                              <span className="red_text">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control ${errors?.contact_info_primary_email ? 'input-error1' : ''}`}
                              id="contact_info_primary_email"
                              placeholder="Enter Email"
                              name="contact_info_primary_email"
                              autocomplete="no"
                              onChange={(e) => {
                                setFieldValue('contact_info_primary_email', e.target.value);
                                if (errors.contact_info_primary_email && e.target.value) errors.contact_info_primary_email = "";
                              }}
                              onBlur={(e) => {
                                if (String(e.target.value).length === 0) {
                                  setFieldError('contact_info_primary_email', "This field is required");
                                } else if (String(e.target.value).length > 0) {
                                  if (removeSpeiclaChars(e.target.value).length === 0) {
                                    setFieldError('contact_info_primary_email', "Only special characters, blank spaces and Dots not allowed.");
                                  } else if (!emailPattern.test(e.target.value)) {
                                    setFieldError('contact_info_primary_email', "Invalid email");
                                  }
                                }
                              }}
                            />
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.contact_info?.old_primary_email) ? (<span className="error_message" style={{ color: correctionDeductorInfo.contact_info.primary_email_color ? `#${correctionDeductorInfo.contact_info.primary_email_color}` : '#0000ff' }}>{correctionDeductorInfo.contact_info.old_primary_email === '-' ? 'No previous value' : correctionDeductorInfo.contact_info.old_primary_email}</span>) : null}
                            {initialData && initialData.primary_email_old ? (<span className="error_message" style={{ color: initialData.primary_email_color ? `#${initialData.primary_email_color}` : '' }}>{initialData.primary_email_old}</span>) : null}
                            {errors.contact_info_primary_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_info_primary_email}</span> : null}
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="contact_info_alternate_email">Alternate Email</label>
                            <Field
                              type="text"
                              className={`form-control ${errors?.contact_info_alternate_email ? 'input-error1' : ''}`}
                              id="contact_info_alternate_email"
                              placeholder="Enter Alternate Email"
                              name="contact_info_alternate_email"
                              autocomplete="no"
                              onChange={(e) => {
                                setFieldValue('contact_info_alternate_email', e.target.value);
                                setFieldError('contact_info_alternate_email', "");
                              }}
                              onBlur={(e) => {
                                if (String(e.target.value).length > 0) {
                                  if (removeSpeiclaChars(e.target.value).length === 0) {
                                    setFieldError('contact_info_alternate_email', "Only special characters, blank spaces and Dots not allowed.");
                                  } else if (!emailPattern.test(e.target.value)) {
                                    setFieldError('contact_info_alternate_email', "Invalid email");
                                  }
                                }
                              }}
                            />
                            {errors.contact_info_alternate_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_info_alternate_email}</span> : null}

                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="col-md-6">
                  <fieldset className="editeddrpdfieldset mt-0 pt-0">
                    <legend className="editeddrpdlegent">
                      <h4>Responsible Person Details</h4>
                    </legend>

                    <div className="rp_details">

                      <div className="row mt-1">
                        <div className="form-group col-md-6 mb-0 pb-0">
                          <label htmlFor="responsible_person_contact_info_person_name">
                            Name of Person
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            type="name"
                            className={`form-control ${errors?.responsible_person_contact_info_person_name ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_person_name"
                            placeholder="Enter Name"
                            name="responsible_person_contact_info_person_name"
                            autocomplete="no"
                            maxLength={75}
                            onChange={(e) => {
                              setFieldValue('responsible_person_contact_info_person_name', e.target.value);
                              if (errors?.responsible_person_contact_info_person_name && e.target.value) errors.responsible_person_contact_info_person_name = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('responsible_person_contact_info_person_name', "This field is required");
                              } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('responsible_person_contact_info_person_name', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.contact_info?.old_person_name) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person?.contact_info?.person_name_color ? `#${correctionDeductorInfo?.responsible_person?.contact_info?.person_name_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person?.contact_info?.old_person_name === '-' ? 'No previous value' : correctionDeductorInfo?.responsible_person?.contact_info?.old_person_name}</span>) : null}
                          {initialData && initialData?.responsible_person && initialData?.responsible_person.contact_info && initialData?.responsible_person?.contact_info?.person_name_old ? (<span className="error_message" style={{ color: initialData?.responsible_person?.contact_info?.person_name_color ? `#${initialData?.responsible_person?.contact_info?.person_name_color}` : '' }}>{initialData?.responsible_person?.contact_info?.person_name_old}</span>) : null}
                          {errors?.responsible_person_contact_info_person_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_contact_info_person_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6 verify_btn_bottom_margin mb-0 pb-0">
                          <label htmlFor="responsible_person_pan">
                            PAN
                            <span className="red_text">*</span>
                          </label>
                          {/* <PANInput
                            value={values.responsible_person_pan}
                            onChange={(v) => {
                              setVerifyPANResultResponsible(null);
                              setFieldValue('responsible_person_pan', v.trim().toUpperCase());
                            }}
                            data={['PANNOTREQD']}
                          /> */}
                          <AlphaNumericInput
                            type="text"
                            className={`form-control ${errors?.responsible_person_pan ? 'input-error1' : ''}`}
                            placeholder="Enter PAN"
                            maxLength={10}
                            value={values.responsible_person_pan}
                            onChange={(v) => {
                              //setVerifyPANResultResponsible(null);
                              setFieldValue('responsible_person_pan', v.target.value.trim().toUpperCase());
                              checkPanMatche(v.target.value.trim().toUpperCase());
                              if (errors?.responsible_person_pan && v.target.value.trim()) errors.responsible_person_pan = "";

                            }}
                            onFocus={(e) => {
                              setShowResponsiblePersonPanTooltip(true)
                            }}
                            onBlur={(e) => {
                              setShowResponsiblePersonPanTooltip(false);
                              if (String(e.target.value).length === 0) {
                                setFieldError('responsible_person_pan', 'This field is required');
                              } else {
                                valdiatePANFormat('responsible_person_pan', String(e.target.value).trim().toUpperCase(), setFieldError)
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.old_pan) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person.pan_color ? `#${correctionDeductorInfo?.responsible_person.pan_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person.old_pan === '-' ? 'No previous value' : correctionDeductorInfo?.responsible_person?.old_pan}</span>) : null}

                          {initialData && initialData?.responsible_person && initialData?.responsible_person?.pan_old ? (<span className="error_message" style={{ color: initialData?.responsible_person.pan_color ? `#${initialData?.responsible_person.pan_color}` : '' }}>{initialData?.responsible_person.pan_old}</span>) : null}
                          {errMsg && !errors.responsible_person_pan ? <span className="error_message">{errMsg}</span> : null}
                          {errors.responsible_person_pan && !errMsg ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_pan}</span> : null}

                          {
                            deductorId ? (
                              responsibleSectionPanVerifyStatus && verifyPANResult ? (<span className={verifyPANResult.toLowerCase() === 'active' ? 'float-right green_text mt-1' : 'float-right red_text mt-1'} style={{ fontSize: '11px' }}>{verifyPANResult}</span>)
                                :
                                (
                                  <div>
                                    {
                                      componentImport === undefined
                                        &&
                                        pattPAN.test(values.responsible_person_pan) ? (
                                        <Button
                                          variant="link"
                                          className="float-right dark_color_text"
                                          style={{
                                            fontSize: '12px',
                                            paddingTop: '0px',
                                            paddingRight: '0px',
                                            cursor: 'pointer'
                                          }}
                                          disabled={!values.traces_username || !values.traces_password || !values.tan || !values.responsible_person_pan}
                                          onClick={() => {
                                            setShowSinglePAN(true);
                                            setVerifyPanNumber(true);
                                            setResponsibleSectionPanVerifyStatus(true);
                                            setDeductorSectionPanVerifyStatus(false);
                                          }}
                                        >
                                          Verify
                                        </Button>
                                      ) : null
                                    }
                                  </div>
                                )
                            ) : null

                          }
                          {showResponsiblePersonPanTooltip && (
                            <div className="pantoolbox">
                              <div className="passlist">
                                <p className="passmust">Username must have</p>
                                <span>
                                  <span className="passlisticon">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color={uernameMustTenDigit.test(values.responsible_person_pan) ? 'green' : ''}
                                    />
                                  </span>
                                  Max. ten letters
                                </span>
                                <br />
                                <span>
                                  <span className="passlisticon">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color={uername1stTo5thLetterAlphabet.test(values.responsible_person_pan) ? 'green' : ''}
                                    />
                                  </span>
                                  1st to 5th letters alphabet
                                </span>
                                <br />
                                <span>
                                  <span className="passlisticon">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color={uername4thLetterAlphabets.includes(values?.responsible_person_pan?.[3]) ? 'green' : ''}
                                    />
                                  </span>
                                  4th letter from P,C,H,A,B,G,J,L,F,T
                                </span>
                                <br />
                                <span>
                                  <span className="passlisticon">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color={uername6thTo9thLetterNumeric.test(values?.responsible_person_pan?.length > 8 ? values.responsible_person_pan.slice(5, 9) : "") ? 'green' : ''}
                                    />
                                  </span>
                                  6th to 9th letter numeric
                                </span>
                                <span>
                                  <br />
                                  <span className="passlisticon">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color={uername10thLetterAlphabet.test(values?.responsible_person_pan?.length > 9 ? values.responsible_person_pan.slice(8, 10) : "") ? 'green' : ''}
                                    />
                                  </span>
                                  10th letter alphabet
                                </span>

                              </div>
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_designation">
                            Designation
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors?.responsible_person_designation ? 'input-error1' : ''}`}
                            id="responsible_person_designation"
                            placeholder="Enter Designation"
                            name="responsible_person_designation"
                            autocomplete="no"
                            maxLength={20}
                            onChange={(e) => {
                              setFieldValue('responsible_person_designation', e.target.value);
                              if (errors?.responsible_person_designation && e.target.value) errors.responsible_person_designation = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('responsible_person_designation', "This field is required");
                              } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('responsible_person_designation', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.responsible_person && correctionDeductorInfo.responsible_person.old_designation) ? (<span className="error_message" style={{ color: correctionDeductorInfo.responsible_person.designation_color ? `#${correctionDeductorInfo.responsible_person.designation_color}` : '#0000ff' }}>{correctionDeductorInfo.responsible_person.old_designation === '-' ? 'No previous value' : correctionDeductorInfo.responsible_person.old_designation}</span>) : null}

                          {initialData && initialData.responsible_person && initialData.responsible_person.designation_old ? (<span className="error_message" style={{ color: initialData.responsible_person.designation_color ? `#${initialData.responsible_person.designation_color}` : '' }}>{initialData.responsible_person.designation_old}</span>) : null}
                          {errors.responsible_person_designation ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_designation}</span> : null}
                        </div>
                      </div>
                    </div>
                    <div className="rp_contact mt-2">
                      <div className="row">
                        <h6 className="col-md-8 respoh6padd">Responsible person Contact Information</h6>
                        <div className="col-md-4">
                          <button
                            type="button"
                            className="dark_color_bg2 float-right"
                            onClick={() => {
                              setValues({
                                ...values,
                                responsible_person_contact_info_primary_mobile_no: values.contact_info_primary_mobile_no,
                                responsible_person_contact_info_alternate_mobile_no: values.contact_info_alternate_mobile_no,
                                responsible_person_contact_info_primary_email: values.contact_info_primary_email,
                                responsible_person_contact_info_alternate_email: values.contact_info_alternate_email,
                              });
                            }}
                          >
                            Copy from Deductor
                          </button>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_contact_info_primary_mobile_no">
                            Mobile Number
                            <span className="red_text">*</span>
                          </label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors?.responsible_person_contact_info_primary_mobile_no ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_primary_mobile_no"
                            placeholder="Enter Mobile Number"
                            name="responsible_person_contact_info_primary_mobile_no"
                            maxLength={10}
                            value={values.responsible_person_contact_info_primary_mobile_no}
                            onChange={(e) => {
                              setFieldValue('responsible_person_contact_info_primary_mobile_no', e.target.value)
                              if (errors?.responsible_person_contact_info_primary_mobile_no && e.target.value) errors.responsible_person_contact_info_primary_mobile_no = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('responsible_person_contact_info_primary_mobile_no', "This field is required");
                              } else if (e.target.value < 6000000000 || e.target.value > 9999999999) {
                                setFieldError('responsible_person_contact_info_primary_mobile_no', "Invalid Mobile Number");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.responsible_person && correctionDeductorInfo.responsible_person.contact_info.old_primary_mobile_no) ? (<span className="error_message" style={{ color: correctionDeductorInfo.responsible_person.contact_info.primary_mobile_no_color ? `#${correctionDeductorInfo.responsible_person.contact_info.primary_mobile_no_color}` : '#0000ff' }}>{correctionDeductorInfo.responsible_person.contact_info.old_primary_mobile_no === '-' ? 'No previous value' : correctionDeductorInfo.responsible_person.contact_info.old_primary_mobile_no}</span>) : null}
                          {errors.responsible_person_contact_info_primary_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_contact_info_primary_mobile_no}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_contact_info_alternate_mobile_no">Alternate Mobile Number</label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors?.responsible_person_contact_info_alternate_mobile_no ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_alternate_mobile_no"
                            placeholder="Enter Alternate Mobile Number"
                            name="responsible_person_contact_info_alternate_mobile_no"
                            maxLength={10}
                            value={values.responsible_person_contact_info_alternate_mobile_no}
                            onChange={(e) => {
                              setFieldValue('responsible_person_contact_info_alternate_mobile_no', e.target.value);
                              setFieldError('responsible_person_contact_info_alternate_mobile_no', "");
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length > 0 && (e.target.value < 6000000000 || e.target.value > 9999999999)) {
                                setFieldError('responsible_person_contact_info_alternate_mobile_no', "Invalid Mobile Number");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.responsible_person && correctionDeductorInfo.responsible_person.contact_info.old_alternate_mobile_no) ? (<span className="error_message" style={{ color: correctionDeductorInfo.responsible_person.contact_info.alternate_mobile_no_color ? `#${correctionDeductorInfo.responsible_person.contact_info.alternate_mobile_no_color}` : '#0000ff' }}>{correctionDeductorInfo.responsible_person.contact_info.old_alternate_mobile_no === '-' ? 'No previous value' : correctionDeductorInfo.responsible_person.contact_info.old_alternate_mobile_no}</span>) : null}
                          {errors.responsible_person_contact_info_alternate_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_contact_info_alternate_mobile_no}</span> : null}

                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_contact_info_primary_email">
                            Email
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors?.responsible_person_contact_info_primary_email ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_primary_email"
                            placeholder="Enter Email"
                            name="responsible_person_contact_info_primary_email"
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('responsible_person_contact_info_primary_email', e.target.value)
                              setFieldError('responsible_person_contact_info_primary_email', "");
                              if (errors?.responsible_person_contact_info_primary_email && e.target.value) errors.responsible_person_contact_info_primary_email = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('responsible_person_contact_info_primary_email', "This field is requid");
                              } else if (String(e.target.value).length > 0) {
                                if (removeSpeiclaChars(e.target.value).length === 0) {
                                  setFieldError('responsible_person_contact_info_primary_email', "Only special characters, blank spaces and Dots not allowed.");
                                } else if (!emailPattern.test(e.target.value)) {
                                  setFieldError('responsible_person_contact_info_primary_email', "Invalid email");
                                }
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.responsible_person && correctionDeductorInfo.responsible_person.contact_info.old_primary_email) ? (<span className="error_message" style={{ color: correctionDeductorInfo.responsible_person.contact_info.primary_email_color ? `#${correctionDeductorInfo.responsible_person.contact_info.primary_email_color}` : '#0000ff' }}>{correctionDeductorInfo.responsible_person.contact_info.old_primary_email === '-' ? 'No previous value' : correctionDeductorInfo.responsible_person.contact_info.old_primary_email}</span>) : null}
                          {errors.responsible_person_contact_info_primary_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_contact_info_primary_email}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_contact_info_alternate_email">Alternate Email</label>
                          <Field
                            type="text"
                            className={`form-control ${errors?.responsible_person_contact_info_alternate_email ? 'input-error1' : ''}`}
                            id="responsible_person_contact_info_alternate_email"
                            placeholder="Enter Alternate Email"
                            name="responsible_person_contact_info_alternate_email"
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('responsible_person_contact_info_alternate_email', e.target.value)
                              setFieldError('responsible_person_contact_info_alternate_email', "");
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length > 0) {
                                if (removeSpeiclaChars(e.target.value).length === 0) {
                                  setFieldError('responsible_person_contact_info_alternate_email', "Only special characters, blank spaces and Dots not allowed.");
                                } else if (!emailPattern.test(e.target.value)) {
                                  setFieldError('responsible_person_contact_info_alternate_email', "Invalid email");
                                }
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.responsible_person && correctionDeductorInfo.responsible_person.contact_info.old_alternate_email) ? (<span className="error_message" style={{ color: correctionDeductorInfo.responsible_person.contact_info.alternate_email_color ? `#${correctionDeductorInfo.responsible_person.contact_info.alternate_email_color}` : '#0000ff' }}>{correctionDeductorInfo.responsible_person.contact_info.old_alternate_email === '-' ? 'No previous value' : correctionDeductorInfo.responsible_person.contact_info.old_alternate_email}</span>) : null}
                          {errors.responsible_person_contact_info_alternate_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.responsible_person_contact_info_alternate_email}</span> : null}

                        </div>
                      </div>
                      {!componentImport ? (
                        <div className="contact_person_detail">
                          <div className="row">
                            <div className="col-md-12">

                              <p className="verify_btn_bottom_margin createdcontp">
                                Is contact person same as responsible person?
                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="This information will be used to send Email and SMS to this deductor. It is not related to the eTDS filing information."
                                >
                                  {' '}
                                  <img src={infoIcon} width="15px" alt="" style={{ paddingLeft: '2px' }} />
                                </span>

                                <span className="form-check mr-2 ml-4">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="is_cp_same_as_rp"
                                    id="gridRadios1"
                                    value="yes"
                                  />
                                  <label className="form-check-label" htmlFor="gridRadios1">
                                    Yes
                                  </label>
                                </span>
                                <span className="form-check mr-2 ml-4">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="is_cp_same_as_rp"
                                    id="gridRadios2"
                                    value="no"
                                  />
                                  <label className="form-check-label" htmlFor="gridRadios2">
                                    No
                                  </label>
                                </span>

                              </p>

                            </div>

                          </div>
                        </div>
                      ) : null}

                    </div>
                  </fieldset>

                  {values.is_cp_same_as_rp === 'no' && (
                    <div>
                      {!componentImport ? (
                        <fieldset className="editedcdfieldset">
                          <legend className="editedcdlegent">
                            <h4>Contact Person Details</h4>
                          </legend>
                          <div className="contact_person mt-1">
                            <div className="row mt-1">
                              <div className="form-group col-md-6">
                                <label htmlFor="contact_person_contact_info_person_name">
                                  Name of Person
                                  <span className="red_text">*</span>
                                </label>
                                <Field
                                  type="name"
                                  className={`form-control ${errors?.contact_person_contact_info_person_name ? 'input-error1' : ''}`}
                                  id="contact_person_contact_info_person_name"
                                  placeholder="Enter Name"
                                  name="contact_person_contact_info_person_name"
                                  autocomplete="no"
                                  maxLength={75}
                                  onChange={(e) => {
                                    setFieldValue('contact_person_contact_info_person_name', e.target.value);
                                    setFieldError('contact_person_contact_info_person_name', "");
                                    if (errors?.contact_person_contact_info_person_name && e.target.value) errors.contact_person_contact_info_person_name = "";
                                  }}
                                  onBlur={(e) => {
                                    if (String(e.target.value).length === 0) {
                                      setFieldError('contact_person_contact_info_person_name', "This field is required");
                                    } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                      setFieldError('contact_person_contact_info_person_name', "Only special characters, blank spaces and Dots not allowed.");
                                    }
                                  }}
                                />
                                {errors.contact_person_contact_info_person_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_person_contact_info_person_name}</span> : null}
                              </div>
                              <div className="form-group col-md-6 verify_btn_bottom_margin">
                                <label htmlFor="contact_person_contact_info_primary_mobile_no">
                                  Mobile Number
                                  <span className="red_text">*</span>
                                </label>
                                <NumericInput
                                  type="text"
                                  className={`form-control ${errors?.contact_person_contact_info_primary_mobile_no ? 'input-error1' : ''}`}
                                  id="contact_person_contact_info_primary_mobile_no"
                                  placeholder="Enter Mobile Number"
                                  name="contact_person_contact_info_primary_mobile_no"
                                  maxLength={10}
                                  value={values.contact_person_contact_info_primary_mobile_no === '+91' ? '' : values.contact_person_contact_info_primary_mobile_no}
                                  onChange={(e) => {
                                    setFieldValue('contact_person_contact_info_primary_mobile_no', e.target.value)
                                    setFieldError('contact_person_contact_info_primary_mobile_no', "")
                                    if (errors?.contact_person_contact_info_primary_mobile_no && e.target.value) errors.contact_person_contact_info_primary_mobile_no = "";
                                  }}
                                  onBlur={(e) => {
                                    if (String(e.target.value).length === 0) {
                                      setFieldError('contact_person_contact_info_primary_mobile_no', "This field is required");
                                    } else if (String(e.target.value).length > 0 && (e.target.value < 6000000000 || e.target.value > 9999999999)) {
                                      setFieldError('contact_person_contact_info_primary_mobile_no', "Invalid Mobile Number");
                                    }
                                  }}
                                />
                                {errors.contact_person_contact_info_primary_mobile_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_person_contact_info_primary_mobile_no}</span> : null}
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="contact_person_contact_info_primary_email">
                                  Email
                                  <span className="red_text">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${errors?.contact_person_contact_info_primary_email ? 'input-error1' : ''}`}
                                  id="contact_person_contact_info_primary_email"
                                  placeholder="Enter Email"
                                  name="contact_person_contact_info_primary_email"
                                  autocomplete="no"
                                  onChange={(e) => {
                                    setFieldValue('contact_person_contact_info_primary_email', e.target.value);
                                    setFieldError('contact_person_contact_info_primary_email', "");
                                    if (errors?.contact_person_contact_info_primary_email && e.target.value) errors.contact_person_contact_info_primary_email = "";
                                  }}
                                  onBlur={(e) => {
                                    if (String(e.target.value).length === 0) {
                                      setFieldError('contact_person_contact_info_primary_email', "This field is required");
                                    } else if (String(e.target.value).length > 0) {
                                      if (removeSpeiclaChars(e.target.value).length === 0) {
                                        setFieldError('contact_person_contact_info_primary_email', "Only special characters, blank spaces and Dots not allowed.");
                                      } else if (!emailPattern.test(e.target.value)) {
                                        setFieldError('contact_person_contact_info_primary_email', "Invalid email");
                                      }
                                    }
                                  }}
                                />
                                {errors.contact_person_contact_info_primary_email ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.contact_person_contact_info_primary_email}</span> : null}
                              </div>
                            </div>

                          </div>
                        </fieldset>
                      ) : null}
                    </div>
                  )}

                </div>

                <div className="col-md-6 mt-4">
                  <fieldset className="editedaifieldset">
                    <legend className="editedailegent">
                      <h4>Deductor Address Information</h4>
                    </legend>
                    <div className="deductoraddress mt-1">

                      <div className="row mt-1">
                        <div className="form-group col-md-6">
                          <label htmlFor="address_building_no">
                            Flat/Door/Block Number
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            type="uniquecode*"
                            className={`form-control ${errors?.address_building_no ? 'input-error1' : ''}`}
                            id="address_building_no"
                            placeholder="Enter Flat/Door/Block Number"
                            name="address_building_no"
                            autocomplete="no"
                            maxLength={25}
                            onChange={(e) => {
                              setFieldValue('address_building_no', e.target.value);
                              setFieldError('address_building_no', "");
                              if (errors?.address_building_no && e.target.value) errors.address_building_no = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('address_building_no', "This field is required");
                              } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('address_building_no', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.address && correctionDeductorInfo.address.old_building_no) ? (<span className="error_message" style={{ color: correctionDeductorInfo.address.building_no_color ? `#${correctionDeductorInfo.address.building_no_color}` : '#000ff' }}>{correctionDeductorInfo.address.old_building_no === '-' ? 'No previous value' : correctionDeductorInfo.address.old_building_no}</span>) : null}
                          {initialData && initialData.address && initialData.address.building_no_old ? (<span className="error_message" style={{ color: initialData.address.building_no_color ? `#${initialData.address.building_no_color}` : '' }}>{initialData.address.building_no_old}</span>) : null}
                          {errors.address_building_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_building_no}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="address_building_name">Building Name</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="address_building_name"
                            placeholder="Enter Building Name"
                            name="address_building_name"
                            maxLength={25}
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('address_building_name', e.target.value);
                              setFieldError('address_building_name', "");
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length > 0 && removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('address_building_name', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.address && correctionDeductorInfo.address.old_building_name) ? (<span className="error_message" style={{ color: correctionDeductorInfo.address.building_name_color ? `#${correctionDeductorInfo.address.building_name_color}` : '#0000ff' }}>{correctionDeductorInfo.address.old_building_name === '-' ? 'No previous value' : correctionDeductorInfo.address.old_building_name}</span>) : null}
                          {errors.address_building_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_building_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="address_street_name">
                            Street/ Road Name
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="address_street_name"
                            placeholder="Enter Street/ Road Name"
                            name="address_street_name"
                            autocomplete="no"
                            maxLength={25}
                            onChange={(e) => {
                              setFieldValue('address_street_name', e.target.value);
                              setFieldError('address_street_name', "");
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length > 0 && removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('address_street_name', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.address && correctionDeductorInfo.address.old_street_name) ? (<span className="error_message" style={{ color: correctionDeductorInfo.address.street_name_color ? `#${correctionDeductorInfo.address.street_name_color}` : '#0000ff' }}>{correctionDeductorInfo.address.old_street_name === '-' ? 'No previous value' : correctionDeductorInfo.address.old_street_name}</span>) : null}
                          {initialData && initialData.address && initialData.address.street_name_old ? (<span className="error_message" style={{ color: initialData.address.street_name_color ? `#${initialData.address.street_name_color}` : '' }}>{initialData.address.street_name_old}</span>) : null}
                          {errors.address_street_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_street_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="address_area">Area</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="address_area"
                            placeholder="Enter Area"
                            name="address_area"
                            maxLength={25}
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('address_area', e.target.value);
                              setFieldError('address_area', "");
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length > 0 && removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('address_area', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.address && correctionDeductorInfo.address.old_area) ? (<span className="error_message" style={{ color: correctionDeductorInfo.address.area_color ? `#${correctionDeductorInfo.address.area_color}` : '#0000ff' }}>{correctionDeductorInfo.address.old_area === '-' ? 'No previous value' : correctionDeductorInfo.address.old_area}</span>) : null}
                          {errors.address_area ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_area}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="address_city">
                            City
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors?.address_city ? 'input-error1' : ''}`}
                            id="address_city"
                            placeholder="Enter City"
                            name="address_city"
                            maxLength={25}
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('address_city', e.target.value)
                              if (errors?.address_city && e.target.value) errors.address_city = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('address_city', "This field is required");
                              } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('address_city', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo.address && correctionDeductorInfo.address.old_city) ? (<span className="error_message" style={{ color: correctionDeductorInfo.address.city_color ? `#${correctionDeductorInfo.address.city_color}` : '#0000ff' }}>{correctionDeductorInfo.address.old_city === '-' ? 'No previous value' : correctionDeductorInfo.address.old_city}</span>) : null}
                          {initialData && initialData.address && initialData.address.city_old ? (<span className="error_message" style={{ color: initialData.address.city_color ? `#${initialData.address.city_color}` : '' }}>{initialData.address.city_old}</span>) : null}
                          {errors.address_city ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_city}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="address_state_id">
                            State
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            as="select"
                            className={`form-control deductordetails_inputbox ${errors?.address_state_id ? 'input-error1' : ''}`}
                            id="address_state_id"
                            name="address_state_id"
                          >
                            {(parseInt(values.address_state_id) > 0) ? (<option value="" disabled>Select State</option>) : (<option value="" selected='selected'>Select State</option>)}
                            {Object.keys(STATES).map((stateId) => <option key={stateId} value={stateId}>{STATES[stateId]}</option>)}
                          </Field>
                          {Object.keys(STATES).map((stateId) =>
                            (componentImport && correctionDeductorInfo && correctionDeductorInfo?.address?.old_state_id && correctionDeductorInfo?.address?.old_state_id === stateId) ? <span className="error_message" style={{ color: correctionDeductorInfo?.address?.state_id_color ? `#${correctionDeductorInfo?.address?.state_id_color}` : '#0000ff' }}>{correctionDeductorInfo?.address?.old_state_id === '-' ? 'No previous value' : STATES[stateId]}</span> : null
                          )}
                          {errors.address_state_id ? <span className="error_message" style={{ display: 'flex' }}><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_state_id}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="address_pincode">
                            Pincode
                            <span className="red_text">*</span>
                          </label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors?.address_pincode ? 'input-error1' : ''}`}
                            id="address_pincode"
                            placeholder="Enter Pincode"
                            name="address_pincode"
                            maxLength={6}
                            value={values.address_pincode}
                            onChange={(e) => {
                              setFieldValue('address_pincode', e.target.value);
                              setFieldError('address_pincode', '');
                              if (errors?.address_pincode && e.target.value) errors.address_pincode = "";
                            }}
                            onBlur={(e) => {
                              if(String(e.target.value).length === 0) {
                                setFieldError('address_pincode', 'This field is required');
                              }
                            }}
                            autocomplete="no"
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.address && correctionDeductorInfo?.address?.old_pincode) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.address?.pincode_color ? `#${correctionDeductorInfo?.address?.pincode_color}` : '#0000ff' }}>{correctionDeductorInfo?.address?.old_pincode === '-' ? 'No previous value' : correctionDeductorInfo?.address?.old_pincode}</span>) : null}
                          {initialData && initialData?.address && initialData?.address?.pincode_old ? (<span className="error_message" style={{ color: initialData?.address.pincode_color ? `#${initialData?.address?.pincode_color}` : '' }}>{initialData?.address?.pincode_old}</span>) : null}
                          {errors.address_pincode ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.address_pincode}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="branch">
                            Branch
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors?.branch ? 'input-error1' : ''}`}
                            id="branch"
                            placeholder="Enter Branch"
                            name="branch"
                            maxLength={75}
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('branch', e.target.value);
                              if (errors?.branch && e.target.value) errors.branch = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('branch', "This field is required");
                              } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('branch', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {((componentImport && correctionDeductorInfo && correctionDeductorInfo.old_branch) && correctionDeductorInfo.old_branch !== "0") ? (<span className="error_message" style={{ color: correctionDeductorInfo.branch_color ? `#${correctionDeductorInfo.branch_color}` : '#0000ff' }}>{correctionDeductorInfo.old_branch === '-' ? 'No previous value' : correctionDeductorInfo.old_branch}</span>) : null}
                          {errors.branch ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.branch}</span> : null}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="col-md-6 mt-4">
                  <fieldset className="editerpaiddfieldset">
                    <legend className="editerpaiddlegent">
                      <div className="respperaddrinndev editedai">
                        <div className="respperaddrlefts editedai">
                          <h4>Responsible Person Address Information</h4>
                        </div>
                        <div className="respperaddrinner editedai" />
                        <div className="respperaddrright editedai">
                          <h4 className="respperaddrcopyt" style={{ cursor: 'pointer' }}>
                            <span
                              type="button"
                              className="dark_color_bg2 float-right editecopyfded"
                              onClick={() => {
                                setValues({
                                  ...values,
                                  responsible_person_address_building_no: values.address_building_no,
                                  responsible_person_address_building_name: values.address_building_name,
                                  responsible_person_address_street_name: values.address_street_name,
                                  responsible_person_address_area: values.address_area,
                                  responsible_person_address_city: values.address_city,
                                  responsible_person_address_state_id: values.address_state_id,
                                  responsible_person_address_pincode: values.address_pincode,
                                });
                              }}
                            >
                              Copy from Deductor
                            </span>
                          </h4>
                        </div>
                        <div className="respperaddrbortoplg borblue" />
                      </div>
                    </legend>

                    <div className="rp_address mt-1">

                      <div className="row mt-1">
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_address_building_no">
                            Flat/Door/Block Number
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            type="uniquecode*"
                            className={`form-control ${errors?.responsible_person_address_building_no ? 'input-error1' : ''}`}
                            id="responsible_person_address_building_no"
                            placeholder="Enter Flat/Door/Block Number"
                            name="responsible_person_address_building_no"
                            autocomplete="no"
                            maxLength={25}
                            onChange={(e) => {
                              setFieldValue('responsible_person_address_building_no', e.target.value);
                              if (errors?.responsible_person_address_building_no && e.target.value) errors.responsible_person_address_building_no = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('responsible_person_address_building_no', "This field is required");
                              } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('responsible_person_address_building_no', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.address && correctionDeductorInfo?.responsible_person?.address?.old_building_no) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person?.address?.building_no_color ? `#${correctionDeductorInfo?.responsible_person?.address?.building_no_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person?.address?.old_building_no === '-' ? 'No previous value' : correctionDeductorInfo?.responsible_person.address?.old_building_no}</span>) : null}
                          {initialData && initialData?.responsible_person && initialData?.responsible_person?.address && initialData?.responsible_person?.address?.building_no_old ? (<span className="error_message" style={{ color: initialData?.responsible_person?.address?.building_no_color ? `#${initialData?.responsible_person?.address?.building_no_color}` : '' }}>{initialData?.responsible_person?.address?.building_no_old}</span>) : null}
                          {errors?.responsible_person_address_building_no ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_building_no}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_address_building_name">Building Name</label>
                          <Field
                            type="text"
                            className={`form-control ${errors?.responsible_person_address_building_name ? 'input-error1' : ''}`}
                            id="responsible_person_address_building_name"
                            placeholder="Enter Building Name"
                            name="responsible_person_address_building_name"
                            maxLength={25}
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('responsible_person_address_building_name', e.target.value);
                              setFieldError('responsible_person_address_building_name', "");
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length > 0 && removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('responsible_person_address_building_name', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.address && correctionDeductorInfo?.responsible_person?.address?.old_building_name) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person.address?.building_name_color ? `#${correctionDeductorInfo?.responsible_person?.address?.building_name_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person?.address?.old_building_name === '-' ? 'No previous value' : correctionDeductorInfo?.responsible_person?.address?.old_building_name}</span>) : null}
                          {initialData && initialData.responsible_person && initialData?.responsible_person?.address && initialData?.responsible_person?.address?.building_name_old ? (<span className="error_message" style={{ color: initialData?.responsible_person?.address?.building_name_color ? `#${initialData?.responsible_person?.address?.building_name_color}` : '' }}>{initialData?.responsible_person?.address?.building_name_old}</span>) : null}
                          {errors.responsible_person_address_building_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_building_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_address_street_name">
                            Street/ Road Name
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors?.responsible_person_address_street_name ? 'input-error1' : ''}`}
                            id="responsible_person_address_street_name"
                            placeholder="Enter Street/ Road Name"
                            name="responsible_person_address_street_name"
                            maxLength={25}
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('responsible_person_address_street_name', e.target.value);
                              setFieldError('responsible_person_address_street_name', "");
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length > 0 && removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('responsible_person_address_street_name', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.address && correctionDeductorInfo?.responsible_person?.address?.old_street_name) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person?.address?.street_name_color ? `#${correctionDeductorInfo?.responsible_person?.address?.street_name_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person?.address?.old_street_name === '-' ? 'No previous value' : correctionDeductorInfo?.responsible_person?.address?.old_street_name}</span>) : null}
                          {initialData && initialData?.responsible_person && initialData?.responsible_person?.address && initialData?.responsible_person?.address?.street_name_old ? (<span className="error_message" style={{ color: initialData?.responsible_person?.address?.street_name_color ? `#${initialData?.responsible_person?.address?.street_name_color}` : '' }}>{initialData?.responsible_person?.address?.street_name_old}</span>) : null}
                          {errors?.responsible_person_address_street_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_street_name}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_address_area">Area</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="responsible_person_address_area"
                            placeholder="Enter Area"
                            maxLength={25}
                            name="responsible_person_address_area"
                            onChange={(e) => {
                              setFieldValue('responsible_person_address_area', e.target.value);
                              setFieldError('responsible_person_address_area', "");
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length > 0 && removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('responsible_person_address_area', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.address && correctionDeductorInfo?.responsible_person?.address?.old_area) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person?.address?.area_color ? `#${correctionDeductorInfo?.responsible_person?.address?.area_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person?.address?.old_area === '-' ? 'No previous value' : correctionDeductorInfo?.responsible_person?.address?.old_area}</span>) : null}
                          {errors?.responsible_person_address_area ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_area}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_address_city">
                            City
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${errors?.responsible_person_address_city ? 'input-error1' : ''}`}
                            id="responsible_person_address_city"
                            placeholder="Enter City"
                            name="responsible_person_address_city"
                            maxLength={25}
                            autocomplete="no"
                            onChange={(e) => {
                              setFieldValue('responsible_person_address_city', e.target.value);
                              if (errors?.responsible_person_address_city && e.target.value) errors.responsible_person_address_city = "";
                            }}
                            onBlur={(e) => {
                              if (String(e.target.value).length === 0) {
                                setFieldError('responsible_person_address_city', "This field is required");
                              } else if (removeSpeiclaChars(e.target.value).length === 0) {
                                setFieldError('responsible_person_address_city', "Only special characters, blank spaces and Dots not allowed.");
                              }
                            }}
                          />
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.address && correctionDeductorInfo?.responsible_person?.address?.old_city) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person?.address?.city_color ? `#${correctionDeductorInfo?.responsible_person?.address?.city_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person?.address.old_city === '-' ? 'No previous value' : correctionDeductorInfo?.responsible_person?.address?.old_city}</span>) : null}
                          {initialData && initialData?.responsible_person && initialData?.responsible_person?.address && initialData?.responsible_person?.address?.city_old ? (<span className="error_message" style={{ color: initialData?.responsible_person?.address?.city_color ? `#${initialData?.responsible_person?.address?.city_color}` : '' }}>{initialData?.responsible_person?.address?.city_old}</span>) : null}
                          {errors?.responsible_person_address_city ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_city}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_address_state_id">
                            State
                            <span className="red_text">*</span>
                          </label>
                          <Field
                            as="select"
                            className={`form-control deductordetails_inputbox ${errors?.responsible_person_address_state_id ? 'input-error1' : ''}`}
                            id="responsible_person_address_state_id"
                            name="responsible_person_address_state_id"
                          // disabled={!responsiblePersonAddressChangedVal}
                          >
                            <option value="" disabled>Select State</option>
                            {Object.keys(STATES).map((stateId) => <option key={stateId} value={stateId}>{STATES[stateId]}</option>)}
                          </Field>
                          {Object.keys(STATES).map((stateId) =>
                            (componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.address?.old_state_id && correctionDeductorInfo?.responsible_person?.address?.old_state_id === stateId) ? <span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person?.address?.state_id_color ? `#${correctionDeductorInfo?.responsible_person?.address?.state_id_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person?.address?.old_state_id === '-' ? 'No previous value' : STATES[stateId]}</span> : null
                          )}
                          {errors?.responsible_person_address_state_id ? <span className="error_message" style={{ display: 'flex' }}><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_state_id}</span> : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="responsible_person_address_pincode">
                            Pincode
                            <span className="red_text">*</span>
                          </label>
                          <NumericInput
                            type="text"
                            className={`form-control ${errors?.responsible_person_address_pincode ? 'input-error1' : ''}`}
                            id="responsible_person_address_pincode"
                            placeholder="Enter Pincode"
                            name="responsible_person_address_pincode"
                            maxLength={6}
                            value={values.responsible_person_address_pincode}
                            onChange={(e) => {
                              setFieldValue('responsible_person_address_pincode', e.target.value);
                              setFieldError('responsible_person_address_pincode', '')
                              if (errors?.responsible_person_address_pincode && e.target.value) errors.responsible_person_address_pincode = "";
                            }}
                            onBlur={(e) => {                             
                              if(String(e.target.value).length === 0) { 
                                setFieldError('responsible_person_address_pincode', 'This field is required')
                              }
                            }}
                            autocomplete="no"
                          />                          
                          {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.responsible_person?.address && correctionDeductorInfo?.responsible_person.address?.old_pincode) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.responsible_person?.address?.pincode_color ? `#${correctionDeductorInfo?.responsible_person?.address?.pincode_color}` : '#0000ff' }}>{correctionDeductorInfo?.responsible_person?.address?.old_pincode === '-' ? 'No previous value' : correctionDeductorInfo?.responsible_person?.address?.old_pincode}</span>) : null}
                          {initialData && initialData?.responsible_person && initialData?.responsible_person.address && initialData?.responsible_person?.address?.pincode_old ? (<span className="error_message" style={{ color: initialData?.responsible_person?.address?.pincode_color ? `#${initialData?.responsible_person?.address?.pincode_color}` : '' }}>{initialData?.responsible_person?.address?.pincode_old}</span>) : null}
                          {errors?.responsible_person_address_pincode ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.responsible_person_address_pincode}</span> : null}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                {GOVERNMENT_DEDUCTOR_TYPES.includes(values.deductor_category) && (
                  <div className="col-md-12 mt-4">
                    <div className="card">
                      <div className="gov_deductordetails">
                        <h6>For Government Deductors</h6>
                        <div className="row mt-4">
                          <div className="form-group col-md-3">
                            <label htmlFor="government_deductor_pao_code">
                              PAO Code
                              {values.deductor_category === 'A' && <span className="red_text">*</span>}
                            </label>
                            <AlphaNumericInputWithDropdown
                              type="name"
                              className={`form-control ${errors?.government_deductor_pao_code ? 'input-error1' : ''}`}
                              id="government_deductor_pao_code"
                              placeholder="Enter PAO Code"
                              name="government_deductor_pao_code"
                              value={values.government_deductor_pao_code}
                              onChange={(v) => {
                                setFieldValue('government_deductor_pao_code', v.trim().toUpperCase());
                                if (errors?.government_deductor_pao_code && v.trim()) errors.government_deductor_pao_code = "";
                              }}
                              data={['PAOCDNOTAVBL']}
                              maxLength={20}
                            />
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.government_deductor && correctionDeductorInfo?.government_deductor?.old_pao_code) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.government_deductor?.pao_code_color ? `#${correctionDeductorInfo?.government_deductor?.pao_code_color}` : '#0000ff' }}>{correctionDeductorInfo?.government_deductor?.old_pao_code === '-' ? 'No previous value' : correctionDeductorInfo?.government_deductor?.old_pao_code}</span>) : null}
                            {errors?.government_deductor_pao_code ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.government_deductor_pao_code}</span> : null}
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="government_deductor_pao_number">
                              PAO Registration No.
                            </label>
                            <AlphaNumericInput
                              type="text"
                              className={`form-control ${errors?.government_deductor_pao_number ? 'input-error1' : ''}`}
                              id="government_deductor_pao_number"
                              placeholder="Enter PAO Registration No."
                              name="government_deductor_pao_number"
                              maxLength={7}
                              value={values.government_deductor_pao_number}
                              onChange={(v) => {
                                setFieldValue('government_deductor_pao_number', v.target.value.trim().toUpperCase());
                                if (errors?.government_deductor_pao_number && v.target.value.trim()) errors.government_deductor_pao_number = "";
                              }}
                            />
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.government_deductor && correctionDeductorInfo?.government_deductor?.old_pao_number) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.government_deductor?.pao_number_color ? `#${correctionDeductorInfo?.government_deductor?.pao_number_color}` : '#0000ff' }}>{correctionDeductorInfo?.government_deductor?.old_pao_number === '-' ? 'No previous value' : correctionDeductorInfo?.government_deductor?.old_pao_number}</span>) : null}
                            {errors?.government_deductor_pao_number ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.government_deductor_pao_number}</span> : null}
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="government_deductor_ddo_code">
                              DDO Code
                              {values.deductor_category === 'A' && <span className="red_text">*</span>}
                            </label>
                            <AlphaNumericInputWithDropdown
                              className={`form-control ${errors?.government_deductor_ddo_code ? 'input-error1' : ''}`}
                              id="government_deductor_ddo_code"
                              placeholder="Enter DDO Code"
                              name="government_deductor_ddo_code"
                              value={values.government_deductor_ddo_code}
                              onChange={(v) => {
                                setFieldValue('government_deductor_ddo_code', v.trim().toUpperCase());
                                if (errors?.government_deductor_ddo_code && v.trim()) errors.government_deductor_ddo_code = "";
                              }}
                              data={['DDOCDNOTAVBL']}
                              maxLength={20}
                            />
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.government_deductor && correctionDeductorInfo?.government_deductor.old_ddo_code) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.government_deductor?.ddo_code_color ? `#${correctionDeductorInfo?.government_deductor?.ddo_code_color}` : '#0000ff' }}>{correctionDeductorInfo?.government_deductor?.old_ddo_code === '-' ? 'No previous value' : correctionDeductorInfo?.government_deductor?.old_ddo_code}</span>) : null}
                            {errors?.government_deductor_ddo_code ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.government_deductor_ddo_code}</span> : null}
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="government_deductor_ddo_number">
                              DDO Registration No.
                            </label>
                            <AlphaNumericInput
                              type="text"
                              className={`form-control ${errors?.government_deductor_ddo_number ? 'input-error1' : ''}`}
                              id="government_deductor_ddo_number"
                              placeholder="Enter DDO Registration No."
                              name="government_deductor_ddo_number"
                              maxLength={10}
                              value={values.government_deductor_ddo_number}
                              onChange={(v) => {
                                setFieldValue('government_deductor_ddo_number', v.target.value.trim().toUpperCase());
                                if (errors?.government_deductor_ddo_number && v.target.value.trim()) errors.government_deductor_ddo_number = "";
                              }}
                            />
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.government_deductor && correctionDeductorInfo?.government_deductor?.old_ddo_number) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.government_deductor?.ddo_number_color ? `#${correctionDeductorInfo?.government_deductor?.ddo_number_color}` : '#0000ff' }}>{correctionDeductorInfo?.government_deductor?.old_ddo_number === '-' ? 'No previous value' : correctionDeductorInfo?.government_deductor?.old_ddo_number}</span>) : null}
                            {errors?.government_deductor_ddo_number ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.government_deductor_ddo_number}</span> : null}
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="government_deductor_dept_name">
                              Ministry/Department Name
                              {values.deductor_category === 'A' || values.deductor_category === 'D' || values.deductor_category === 'G' ?
                                <span className="red_text">*</span> : null}
                            </label>
                            <Field
                              as="select"
                              className={`form-control f-14 ${errors?.government_deductor_dept_name ? 'input-error1' : ''}`}
                              id="government_deductor_dept_name"
                              name="government_deductor_dept_name"
                            >
                              <option value="" disabled>Choose Department</option>
                              {Object.keys(DEPARTMENT_NAMES).map((depId) => <option key={depId} value={depId}>{DEPARTMENT_NAMES[depId]}</option>)}
                            </Field>
                            {errors?.government_deductor_dept_name ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.government_deductor_dept_name}</span> : null}
                          </div>
                          {Number(values.government_deductor_dept_name) === 99
                            && (
                              <div className="form-group col-md-3">
                                <label htmlFor="government_deductor_dept_name_other">
                                  Ministry/ Department Name other
                                  <span className="red_text">*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${errors?.government_deductor_dept_name_other ? 'input-error1' : ''}`}
                                  id="government_deductor_dept_name_other"
                                  placeholder="Enter Ministry/Department Name"
                                  name="government_deductor_dept_name_other"
                                  value={values?.government_deductor_dept_name_other}
                                  onChange={(v) => {
                                    setFieldValue('government_deductor_dept_name_other', v.target.value.trim());
                                    if (errors?.government_deductor_dept_name_other && v.target.value.trim()) errors.government_deductor_dept_name_other = "";
                                  }}
                                  maxLength={150}
                                />
                                {errors?.government_deductor_dept_name_other ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.government_deductor_dept_name_other}</span> : null}
                              </div>
                            )}

                          <div className="form-group col-md-3">
                            <label htmlFor="government_deductor_office_identity_number">
                              Account Office Identification No.
                              {values?.deductor_category === 'A' || values?.deductor_category === 'S' ?
                                <span className="red_text">*</span> : null}
                            </label>
                            <AlphaNumericInput
                              type="text"
                              className={`form-control ${errors?.government_deductor_office_identity_number ? 'input-error1' : ''}`}
                              id="government_deductor_office_identity_number"
                              placeholder="Enter Account Office Identification No."
                              name="government_deductor_office_identity_number"
                              maxLength={7}
                              value={values?.government_deductor_office_identity_number}
                              onChange={(v) => {
                                setFieldValue('government_deductor_office_identity_number', v.target.value.trim().toUpperCase());
                                if (errors?.government_deductor_office_identity_number && v.target.value.trim()) errors.government_deductor_office_identity_number = "";
                              }}
                              // onBlur={(e) => {
                              //   if((values?.deductor_category === 'A' || values?.deductor_category === 'S') && String(e.target.value).length === 0) {
                              //     setFieldError('government_deductor_office_identity_number', 'This field is required');
                              //   }
                              // }}
                            />
                            {(componentImport && correctionDeductorInfo && correctionDeductorInfo?.government_deductor && correctionDeductorInfo?.government_deductor?.old_office_identity_number) ? (<span className="error_message" style={{ color: correctionDeductorInfo?.government_deductor?.office_identity_number_color ? `#${correctionDeductorInfo?.government_deductor?.office_identity_number_color}` : '#0000ff' }}>{correctionDeductorInfo?.government_deductor?.old_office_identity_number === '-' ? 'No previous value' : correctionDeductorInfo?.government_deductor?.old_office_identity_number}</span>) : null}
                            {errors?.government_deductor_office_identity_number ? <span className="error_message"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors?.government_deductor_office_identity_number}</span> : null}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                )}
              </div>
            </div>
            {componentImport ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-right">
                    <div className="form-check-inline mb-2" style={{ position: 'relative', bottom: '3rem' }}>
                      <label className="form-check-label">
                        <span className="pl-1 text-warning">{consoStatus && consoStatus?.conso_file_status === 'IMPORT_IN_PROGRESS' ? 'IMPORT IN PROGRESS PLEASE WAIT....' : null}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="addcompbtnpositionfixed">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {returnType === 'regular' ? (
                      <div className="float-right">
                        <button type="submit" id="save_deductor_btn" className="light_color_bg float-right editdsavebtn" disabled={disable} style={disable ? { opacity: 0.6 } : { padding: '5px 31px' }}>Save</button>
                        <Link to={BASE_PATH}>
                          <button type="button" className="light-btn mr-2  editdcanclebtn" style={{ padding: '5px 25px' }}>Cancel</button>
                        </Link>
                      </div>
                    ) : (
                      <button type="submit" id="save_deductor_btn" className="light_color_bg float-right editdsavebtn" style={{ padding: '5px 31px' }}>Save</button>
                    )}
                  </div>

                </div>
              </div>
            </div>

          </Form>
        )}
      </Formik>

    </div>
  );
}
