// eslint-disable-next-line
import React, { useEffect } from 'react';
import { verifyPANandCheckCompliance, checkExistingComplianceRequest } from '../../../api/localConnectorAPI';
import { getPanListToVerifyAtTracesAndInsightsViaConnector } from '../../../api/serverConnectorAPI';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../../app/constants';

export default function UploadExcelComplianceCheck(props) {

  const { deductorId, port, fileData, captchaRef, insightsCaptchaRef, setSuccess, setError, setLoading,
    coplianceStatusKey, setComplianceCheckStatusData, isPanOtpStatus, requestforCheckCompliance } = props;


  //const { deductorId, port, fileData, captchaRef, insightsCaptchaRef, setShowProgressBar, setError, setLoading, setComplianceCheckStatusData } = props;

  const fetchTracesCredentials = (deductorId, captchaRef, insightsCaptchaRef, port, coplianceStatusKey) => {

    async function fetchEverything() {
      try {
        setLoading(true);

        const payload = {
          'deductor_id': deductorId,
          'action_type': isPanOtpStatus === 'Y' ? 'COMPLIANCE_CHECK_BULK_PAN' : 'INSIGHTS_ONLY',
          'traces_captcha_ref': captchaRef,
          'insights_captcha_ref': insightsCaptchaRef,
        }
        const result = await getPanListToVerifyAtTracesAndInsightsViaConnector(payload);

        const responseHeader = result?.headers ?? null;
        const response = result?.data ?? null

        try {

          const dataKey = result?.data?.data ?? '';
          const formData = new FormData();
          formData.append('pan_file', fileData[0]);
          formData.append('data', dataKey);
          let extraHeader = {};

          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          const connectorResponse = (requestforCheckCompliance === true) ? (await checkExistingComplianceRequest(port, response, extraHeader)) : (await verifyPANandCheckCompliance(port, formData, extraHeader))

          if (Number(connectorResponse.status) === 1) {
            //set local storage key to get PAN verify status data
            const statusData = { 'captcha_ref': captchaRef, 'insights_captcha_ref': insightsCaptchaRef, 'port': port };
            localStorage.setItem(coplianceStatusKey, JSON.stringify(statusData));
            setComplianceCheckStatusData(statusData);
            setSuccess("Your Check Compliance process has started successfully. You may monitor the status of the process at the bottom of the screen. Please ensure that the Connector service keeps running, and the system is not shut down.");
          }



        } catch (err) {

          const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default'];  
          setError(errmsg);
        }

      } catch (err) {

        setError(err.message);

      } finally {
        setLoading(false);
      }

    }

    fetchEverything();
  }

  useEffect(() => {
    fetchTracesCredentials(deductorId, captchaRef, insightsCaptchaRef, port, coplianceStatusKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductorId, captchaRef, insightsCaptchaRef, port, coplianceStatusKey]);

  return (null);

};
