import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import { BASE_PATH, BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import { updateFilingHistoryOnServer, verifyEFilingCredsFromConnector } from '../../api/localConnectorAPI';
import { selectHeaderData } from '../header/headerSlice';
import { useSelector } from 'react-redux';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import { LOGIN_PATHS, MISC_PATHS } from '../../App';
import Draggable from 'react-draggable';

import { gocallApi } from '../../api/issueCertificateAPI';
import { verfiyEfilingCrediationalOnServer } from '../../api/ReturnRegister';
import { clearAuthToken, getAuthToken } from '../../utils/AuthToken';
import ShowAlert from '../../components/ShowAlert';

const DeductorSyncAllClientListProgressBar = (props) => {
    const { runSync = null, display, setRunSync = null } = props;
    const headerData = useSelector(selectHeaderData);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [port, setPort] = useState();
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [clientImportProgress, setClientImportProgress] = useState(false)
    const [currentAssessObj, setCurrentAssessObj] = useState('')
    const [syncAssessData, setSyncAssessData] = useState([])
    const [error, setError] = useState(false);

    const timer = useRef(null);
    const location = useLocation();

    let shouldRunBulkClientSync = false;
    if (headerData?.mobile_number
        && !LOGIN_PATHS.includes(location.pathname)
        && !MISC_PATHS.includes(location.pathname)
        && ![`${BASE_PATH}dashboard`].includes(location.pathname)
    ) {

        shouldRunBulkClientSync = (sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`)
            && sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`) !== 'false')
            ? false
            : true;
    }

    const bulkSyncLocalDataObj = sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`) ? JSON.parse(sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`)) : null;
    const bulkSyncLocalData = bulkSyncLocalDataObj?.syncData ?? [];
    const [totalCount, setTotalCount] = useState(bulkSyncLocalDataObj ? bulkSyncLocalDataObj?.totalSyncCount : 0);
    const syncCompleted = bulkSyncLocalDataObj ? bulkSyncLocalDataObj?.syncCompleteCount : 0;

    const currWorkingPer = totalCount > 0 ? 100 * ((syncCompleted) / totalCount) : 0;
    const [workDone, setWorkDone] = useState(currWorkingPer > 0 ? currWorkingPer : 0)
    const [currentWorking, setCurrentWorking] = useState(syncCompleted > 0 ? syncCompleted : 1)

    const getActiveClientList = () => {
        if (!runSync) {
            return;
        }
        let isComplete = false;
        const userData = { apiCalled: false, data: [] };
        async function fetchEverything(page) {
            const url = `/api/v1/deductor/list-all`;
            const Payload = {
                "filter_status": 1,
                "filter_has_itd_creds": 1,
                limit: 100,
            }

            if (page) Payload.page = page;

            try {
                const result = await gocallApi("post", url, Payload);


                if (result.data.data && result.data.data.length > 0 && runSync) {

                    userData.apiCalled = true;
                    result.data.data.forEach((item) => {
                        userData.data.push(item);
                    })

                    sessionStorage.setItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`, JSON.stringify({ syncData: userData?.data, totalSyncCount: userData?.data.length, syncCompleteCount: 0 }));

                    if (!result?.data?.cursor?.next_page) isComplete = true;
                    if (result?.data?.cursor?.next_page) fetchEverything(result?.data?.cursor?.next_page);

                    if (isComplete) {
                        const response = userData;
                        const syncData = response?.data ?? []
                        if (syncData.length > 0) {
                            setSyncAssessData(syncData);
                            setShowConnnectorCall(true);
                            setTotalCount(userData?.data.length);

                        } else if (response?.apiCalled) {
                            sessionStorage.setItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`, true);
                        }
                    }
                }
            } catch (err) {
                // setError(err);
                return []
            }
        }
        fetchEverything();



    }


    const verfiyCredApi = async (port, syncAssessData, totalDeductor, currentRow) => {
        if (!port) {
            return false;
        }

        // Validate auth token - dont fail if invalid
        if (!getAuthToken(false)) {
            clearAuthToken();
            return false
        }

        const obj = syncAssessData[currentRow];

        const tempBulkSyncDataObj = sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`) ? JSON.parse(sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`)) : null;
        const tempBulkSyncData = tempBulkSyncDataObj?.syncData ?? [];

        const index = tempBulkSyncData.findIndex(x => x.tan === obj?.tan);
        if (index > -1) { tempBulkSyncData.splice(index, 1) }

        setClientImportProgress(true);
        setCurrentAssessObj(obj);

        const verifyLoginData = {
            'itdportal_username': obj?.itdportal_username ?? '',
            'itdportal_password': obj?.itdportal_password ?? ''
        }

        try {
            const loginResult = await verifyEFilingCredsFromConnector(port, verifyLoginData);

            const verigyEfilingCreditional = {
                "deductor_id": obj.deductor_id ?? "",
                "captcha_ref": loginResult.data.captcha_ref ?? "",
            }
            try {

                const loginVerified = await verfiyEfilingCrediationalOnServer(verigyEfilingCreditional);

                if (loginVerified.data.status === 1) {
                    let extraHeader = {};
                    const LoginVerifiedResult = loginVerified.data
                    const responseHeader = loginVerified.headers ?? null;

                    const connectorRequest = {
                        "data": LoginVerifiedResult.data
                    };

                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                    await updateFilingHistoryOnServer(port, connectorRequest, extraHeader);
                }

            } catch (err) {
                console.error('1 error: ', err);
                // const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message;
                // setError(errmsg);
            }
        } catch (err) {
            console.error('1 error: ', err);
            // const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message;
            // setError(errmsg);
        } finally {
            setTimeout(() => {
                setWorkDone((prev) => {
                    const curr = 100 * ((currentRow + 1 + syncCompleted) / (totalCount > 0 ? totalCount : totalDeductor));
                    if (curr > prev) return curr; return prev;
                });
                setCurrentWorking((prev) => { const curr = ((currentRow + 1 + syncCompleted)); if (curr > prev) return curr; return prev; });

            }, 2000);

            timer.current = setTimeout(() => {
                sessionStorage.setItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`, JSON.stringify(
                    { syncData: tempBulkSyncData, totalSyncCount: totalCount, syncCompleteCount: ((syncCompleted) + currentRow + 1) }
                ));

                if ((currentRow + 1) === totalDeductor) {
                    setClientImportProgress(false);
                    setWorkDone(0);
                    setCurrentAssessObj("");
                    setTotalCount(0);
                    setCurrentWorking(1)
                    sessionStorage.removeItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`);
                    sessionStorage.removeItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`);
                    if (setRunSync) setRunSync(false);
                } else {
                    verfiyCredApi(port, syncAssessData, totalDeductor, currentRow + 1);
                }
            }, 5000);
        }

    }


    //if not sync data available in localstorageisRunSync , then this function will run connector to update client data by getting from itr portal, 
    useEffect(() => {
        if (shouldRunBulkClientSync) {
            if (bulkSyncLocalData && bulkSyncLocalData?.length > 0) {
                const result = { data: bulkSyncLocalData };
                const syncData = result?.data ?? []
                if (syncData.length > 0) {
                    setSyncAssessData(syncData);
                    setShowConnnectorCall(true);
                    setTotalCount(bulkSyncLocalDataObj ? bulkSyncLocalDataObj?.totalSyncCount : 0)
                } else if (result?.apiCalled) {
                    sessionStorage.setItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`, true);
                }
            } else {
                getActiveClientList()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRunBulkClientSync, runSync])


    useEffect(() => {
        if (!(port && checkItCredentials && syncAssessData)) {
            return;
        }

        if (syncAssessData.length <= 0) {
            return;
        }

        verfiyCredApi(port, syncAssessData, syncAssessData.length, 0);

        //eslint-disable-next-line
    }, [port, checkItCredentials, syncAssessData])

    useEffect(() => {
        return () => clearTimeout(timer.current);
    }, [])


    return (
        <>

            {error &&
                <ShowAlert
                    error={error}
                    onClose={() => setError(null)}
                />
            }
            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => setShowConnnectorCall(false)}
                    setPort={setPort}
                    handleProceed={() => setCheckItCredentials(true)}
                />
                : null}

            {/* {clientImportProgress &&
                <SyncDeductor
                    deductorId={syncValidDeductor}
                    handleProceed={() => {
                        setSyncValidDeductor(null);
                    }}
                    port={port}
                />
            } */}
            {clientImportProgress && display && <Draggable><div className='processSync progress-sync-popup' style={{ cursor: 'pointer' }}>
                <div className='client-status-show' style={{ color: '#196ec9', fontSize: '12px' }}>We're syncing your records for an optimal experience. Relax and let it unfold.</div>
                <ProgressBar animated now={Math.round(workDone)} label={''} style={{ backgroundColor: "#e9ecef" }} />
                <div className='client-status-show'>Return Status Sync is in progress for: <span>{String(currentAssessObj?.deductor_name ?? '') ? currentAssessObj?.deductor_name + '-' + currentAssessObj?.tan : currentAssessObj?.tan}</span></div>
                <div className='client-status-show'>Time Remaining:<span>About {Math.max(Math.ceil((totalCount - currentWorking) * .1), 1)} {Math.max(Math.ceil((totalCount - currentWorking) * .1), 1) > 1 ? 'Minutes' : 'Minute'}  </span>   <div className='show-progress-count'>Count:<span>{currentWorking ?? 1}/{totalCount}</span></div></div>
            </div></Draggable>}

        </>
    )
}

export default DeductorSyncAllClientListProgressBar;