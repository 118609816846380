/* eslint-disable react/prop-types */
import React, { } from 'react';
import Modal from 'react-bootstrap/Modal';
import './monthwisesalarydetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Typography from '@material-ui/core/Typography';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';


const TdsComputed = (props) => {
    const { show, handleClose, tax_computed } = props;


    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="sm"
                className="MonthWSdetailspopup"
            >
                <Modal.Header closeButton className="MonthWSdetailsH">
                    <Modal.Title className="pt-0 mt-0">
                        <sapn className="MonthWSdetailsT">

                        </sapn>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-0 px-0">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 pt-2">
                                        <div className="table-responsive">
                                            <table className="table table-striped monthWSDTB">
                                                <tbody>
                                                    <tr>
                                                        <td>Income Tax</td>
                                                        <td className="text-right">
                                                            <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {tax_computed.income_tax_amtp ? parseFloat(tax_computed?.income_tax_amtp ?? 0).toFixed(2) : '0.00'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rebate u/s 87A</td>
                                                        <td className="text-right">
                                                            <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {tax_computed.rebate_us_87_a_amtp ? parseFloat(tax_computed.rebate_us_87_a_amtp ?? 0).toFixed(2) : '0.00'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Surcharge</td>
                                                        <td className="text-right">
                                                            <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {tax_computed.surcharge_amtp ? parseFloat(tax_computed.surcharge_amtp ?? 0).toFixed(2) : '0.00'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Health & Edu. Cess @ 4%</td>
                                                        <td className="text-right">
                                                            <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {tax_computed.health_edu_cess_amtp ? parseFloat(tax_computed.health_edu_cess_amtp ?? 0).toFixed(2) : '0.00'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Relief u/s 89</td>
                                                        <td className="text-right">
                                                            <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {tax_computed.relief_us_89_amtp ? parseFloat(tax_computed.relief_us_89_amtp ?? 0).toFixed(2) : '0.00'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tax payable</td>
                                                        <td className="text-right">
                                                            <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {tax_computed.tax_payable_amtp ? parseFloat(tax_computed.tax_payable_amtp ?? 0).toFixed(2) : '0.00'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>

        </>
    );
};

export default TdsComputed;
