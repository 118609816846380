import React from "react";
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import LateInterestLiability from "./LateInterestLiability";
import { formatNumber } from "../../../utils/UtilityFunctions";

const InterestMappingLibilityDataPopup = (props) => {
    const { returnId, deductorId, onClose, liabilityData } = props;
    return (
        <>
            <WindowWrapperFixed
                title={
                    <h4 className="modal-title w-100" style={{display: 'flex'}}>
                        <div class="col-md-7" style={{paddingLeft: '0px'}}>
                            Total Liability
                        </div>
                        <div class="col-md-5" style={{ textAlign: 'right', paddingRight: '0px' }}>
                            {Number(liabilityData?.total_int_amtp) > 0 ? formatNumber(liabilityData?.total_int_amtp) : ''}
                        </div>
                    </h4>
                }
                onClose={onClose}
                totalPopups={1}
                index={1}
                isModalView={true}
                initialHeight={'auto'}
                initialLeft={150}
                initialTop={50}
                resizable={true}
                className={`interestMapping`}
            >
                <div class="row card mb-2">
                    <LateInterestLiability
                        returnId={returnId}
                        deductorId={deductorId}
                        sectionTitle={'Late Deduction Interest'}
                        sectionAmount={Number(liabilityData?.late_deduction_interest_amtp ?? 0)}
                        filterKey={'date_mismatch'}
                        sectionKey={'LATE_DEDUCTION_INTEREST'}
                    />
                </div>
                <div class="row card mb-2">
                    <LateInterestLiability
                        returnId={returnId}
                        deductorId={deductorId}
                        sectionTitle={'Late Deposit Interest'}
                        sectionAmount={Number(liabilityData?.late_payment_interest_amtp ?? 0)}
                        filterKey={'interest_mismatch'}
                        sectionKey={'LATE_DEPOSIT_INTEREST'}
                    />
                </div>

            </WindowWrapperFixed>
        </>
    );
}
export default InterestMappingLibilityDataPopup;