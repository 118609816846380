import React, { useState, useEffect } from "react";
import WindowWrapper from "../../components/grid/WindowWrapper";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomCell } from "../../components/grid/CustomCell";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ColumnMenu } from "../../components/grid/ColumnMenu";
import { formatNumber } from "../../utils/UtilityFunctions";
import ShowMorePagination from "../../components/pagination/ShowMorePagination";
import ChallanListOtherTransaction from "./ChallanListOtherTransaction";
import { getConsumptionDetailsInChallan } from "../../api/challanAPI";
import './ChallanListV2.scss';
import { getFinancialYears } from "../../utils/UtilityFunctions";
import { QUARTER_LIST, FORM_TYPE_LIST } from "../../app/constants";

const ChallanListV2ActionPopup = (props) => {

    const { show, onClose, paymentCodes, params, setError, challanId, setLoading, index, totalpopups, challanNum } = props;

    const [otherTrans, setOtherTrans] = useState(false);
    const [dataState, setDataState] = useState({});
    const [mappedData, setMappedData] = useState([]);
    const [summary, setSummary] = useState({});
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [showMoreLoading, setShowMoreLoading] = useState(false);

    const FinancialYearList = getFinancialYears();


    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
    };

    const getConsumptionDetailData = (data) => {
        async function fetchEverything() {
            async function fetchChallans() {
                const result = await getConsumptionDetailsInChallan(data);
                if (result?.data?.cursor?.current_page > 1) {
                    setMappedData(mappedData.concat(result?.data?.data ?? []));
                } else {
                    setMappedData(result.data.data ?? []);
                }
                setSummary(result?.data?.summary ?? {})
                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page; setShowMoreLoading(true)
                setCursor(result?.data?.cursor);
            }
            setLoading(true)
            try {
                await Promise.all([
                    fetchChallans(),
                ]);
                setError(null);
            } catch (err) {
                setError(err?.message);
            } finally {
                setShowMoreLoading(false);
                setLoading(false);
            }
        }
        fetchEverything();
    };

    const fetchData = (filterData = dataState) => {
        let request = {
            deductor_id: params?.deductor_id ?? "",
            return_id: params?.return_id ?? "",
            challan_id: challanId,
        }

        if (filterData.sort && filterData.sort.length > 0) {
            request.sort_on = filterData.sort[0].field;
            request.sort_by = filterData.sort[0].dir;
        }

        if (filterData.filter && filterData.filter.filters) {
            request.filters = filterData.filter.filters;
        }

        if (filterData.page) {
            request.page = filterData.page;
        }

        getConsumptionDetailData(request);
    }

    const rowRender = (trElement, props,) => {

        const key = trElement._owner.index;

        return (
            <tr key={key} class="dash_totalverchall_trth2romamt" role="row">
                <td class="text-left">
                    {props?.dataItem?.financial_year ? `${props?.dataItem?.financial_year} - ${String(props?.dataItem?.financial_year + 1).slice(2)}` : '-'}
                </td>
                <td class="text-left data_padding-left">
                    {props?.dataItem?.form_type ?? ''}
                </td>
                <td class="text-left data_padding-left">
                    {props?.dataItem?.quarter ?? ''}
                </td>
                <td class="text-center data_padding_right">
                    {props?.dataItem?.nature_of_payment ?? '-'}
                </td>
                <td class="text-right" style={{ paddingRight: '20px' }}>
                    {formatNumber(props?.dataItem?.total_consumption_amtp ?? 0)}
                </td>
            </tr>
        );
    };

    const onChangeFilter = (newDataState) => {
        dataStateChange(newDataState);
        fetchData(newDataState);
    }


    const handleClose = () => {
        if (otherTrans) {
            setOtherTrans(false)
        } else {
            onClose()
        }
    }

    useEffect(() => {
        fetchData(dataState);
        // eslint-disable-next-line
    }, [params?.deductor_id, challanId]);

    return (
        <>
            <div className="mappedtransactionmodal">

                {show &&
                    <WindowWrapper
                        title={<kendo-dialog-titlebar>
                            <h6 className="modal-title">
                                <span className="challanno_textfs"> <span>Challan No.: </span><span className="challan_numtextfsclr">{challanNum}</span></span> |
                                <span className="challanno_textfs"> <span>Traces Unconsumed Balance: </span><span className="challan_numtextfsclr">{formatNumber(summary?.traces_unconsumed_amtp ?? 0)}</span></span>
                            </h6>
                        </kendo-dialog-titlebar>}
                        onClose={handleClose}
                        totalPopups={totalpopups}
                        index={index}
                        initialWidth={850}
                        initialHeight={300}
                        className="mapped_trans_modal"
                        style={{ maxHeight: '300px', overflowX: 'hidden' }}
                    >


                        {show && otherTrans &&
                            <ChallanListOtherTransaction
                                onClose={() => setOtherTrans(false)}
                                mappedData={mappedData}
                                paymentCodes={paymentCodes}
                                params={params}
                                challanId={challanId}
                            />
                        }

                        {show && !otherTrans &&

                            <>
                                <Tooltip anchorElement="pointer" position="right">
                                    <Grid
                                        className="table table-striped listofsalarydetailstb mb-0"
                                        style={{maxHeight: '155px'}}
                                        data={mappedData}
                                        {...dataState}
                                        rowRender={rowRender}
                                        onDataStateChange={(event) => onChangeFilter(event.dataState)}
                                        filterOperators={{
                                            text: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            ],
                                            numeric: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                            ],
                                            date: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                            ],
                                            textWithEmpty: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                            ],
                                            dropdown: [
                                                { text: 'grid.filterContainsOperator', operator: 'in' },
                                            ],
                                            pan: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                { text: 'grid.filterContainsOperator', operator: 'in' },
                                            ],
                                        }}
                                        sortable={{
                                            allowUnsort: true,
                                        }}
                                    >
                                        <Column
                                            width="100"
                                            field="financial_year"
                                            headerClassName={isColumnActive('financial_year') ? 'active' : ''}
                                            title={<CustomCell title="FY" field="financial_year" dataStateChange={dataStateChange} dataState={dataState} />}
                                            filter="dropdown"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} filterList={FinancialYearList} />)}
                                        />

                                        <Column
                                            field="form_type" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('form_type') ? 'active' : ''}
                                            title="Form Type"
                                            width="90"
                                            filter="dropdown"
                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={FORM_TYPE_LIST} />}
                                        />

                                        <Column
                                            field="quarter" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('quarter') ? 'active' : ''}
                                            title="Qtr."
                                            width="90"
                                            filter="dropdown"
                                            columnMenu={((props) => <ColumnMenu filterList={QUARTER_LIST} hideSecondFilter {...props} />)}
                                        />

                                        <Column
                                            field="nature_of_payment_id" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('nature_of_payment_id') ? 'active' : ''}
                                            title="Sec."
                                            width="90"
                                            filter="dropdown"
                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                                        />

                                        <Column
                                            field="total_consumption_amtp" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('total_consumption_amtp') ? 'active' : ''}
                                            title='Tax Amount'
                                            filter="numeric"
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            width="100"
                                        />

                                    </Grid>
                                </Tooltip>

                                <div className="row mt-2" style={{ width: '100%' }}>
                                    <div className="col-md-12 text-center">
                                        <ShowMorePagination
                                            cursor={cursor}
                                            fetchData={(nextPage) => {
                                                fetchData({ ...dataState, page: nextPage.page });
                                            }}
                                            postData={{}}
                                            loading={showMoreLoading}
                                        />
                                    </div>
                                </div>

                                <div className="row" style={{ backgroundColor: '#31719b', color: 'white' }}>
                                    <div className="col-md-12">
                                        <p className="text-right mappedchalal_textfs pr-2">
                                            <span className="challanno_textfs"> <span>Remaining Balance: </span><span className="challan_numtextfsclr">{formatNumber(summary?.remaining_bal_amtp ?? 0)}</span></span>
                                        </p>
                                    </div>
                                </div>
                            </>
                        }

                    </WindowWrapper>
                }
            </div >
        </>
    )

};

export default ChallanListV2ActionPopup;
