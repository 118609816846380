import React from 'react';
import $ from 'jquery'

const BlurOnChangeInput = ({ onFocus, onBlur, isApiCall = false, ...rest }) => {

    if ((rest?.["type"] ?? '') === "textarea") {
        return <>

            <input
                onFocus={(e) => {
                    e.target['valueBeforeFocus'] = e.target.value;
                    if (onFocus) { onFocus(e) }
                }}
                onBlur={(e) => {
                    if ((e.target.value !== e.target?.['valueBeforeFocus'] || isApiCall || !rest.hasOwnProperty('value')) && onBlur) {
                        onBlur(e);
                    }
                }}
                {...rest}
                className={`${rest?.className} ${String(rest?.value).length > 0 ? 'text-with-span-field ' : ''}`}
            />
            {String(rest?.value).length > 0 && <span className='focus-next-span' onClick={(e) => $(e.target).prev().focus()}>{rest?.value}</span>}
        </>
    }


    return (
        <input
            onFocus={(e) => {
                e.target['valueBeforeFocus'] = e.target.value;
                if (onFocus) { onFocus(e) }
            }}
            onBlur={(e) => {
                if ((e.target.value !== e.target?.['valueBeforeFocus'] || isApiCall || !rest.hasOwnProperty('value')) && onBlur) {
                    onBlur(e);
                }
            }}
            {...rest}
        />
    )
}

export default BlurOnChangeInput