/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

const nPattern = new RegExp(/^[0-9]*$/);

const PRNInput = (props) => {
  const { value, onChange, ...rest } = props;
  return (
    <input
      value={value}
      onChange={(e) => {
        if ((nPattern.test(e.target.value) && ((e.target.value).length) <= 15) || e.target.value === '') {
          onChange(e);
        }
      }}
      {...rest}
    />
  );
};

export default PRNInput;
