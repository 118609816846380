import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './LateFeeAmountPopup.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faRupeeSign
} from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../utils/UtilityFunctions';

export default function LateFeeAmountPopup(props) {
	const {
		show, onHide, tdsComputation, closeModal,
	} = props;


	//const [error, setError] = useState(null);

	return (
		<Modal
			show={show}
			onHide={onHide}
			animation={true}
		>
			<Modal.Header className="latefeeamtheadbgclr">
				<h5 className="modal-title" id="exampleModalLongTitle">Interest Liability (Adjusted + Unadjusted)</h5>
				<button type="button" className="close" onClick={() => closeModal()}>
					<span aria-hidden="true">&times;</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-body pt-1 pb-1">

					<div className="row mt-2">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
							<h4 className="h4bold">
								Late Fee
							</h4>
							<div className="table-responsive">
								<table className="table table-striped tilst2latedptb">
									<thead>
										<tr>
											<th>Due Date</th>
											<th>Filing Date</th>
											<th>Number of Delay Days </th>
											<th>Late Fee Amount</th>

										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{tdsComputation.fees && tdsComputation.fees.filing_due_date ? tdsComputation.fees.filing_due_date ? moment(tdsComputation.fees.filing_due_date).format('DD MMM YYYY') : '-' : '-'}</td>

											<td>{tdsComputation.fees && tdsComputation.fees.filing_date ? tdsComputation.fees.filing_date ? moment(tdsComputation.fees.filing_date).format('DD MMM YYYY') : '-' : '-'}</td>

											<td>{tdsComputation.fees ? tdsComputation.fees.days_for_late_fees : ''}		</td>
											<td>{tdsComputation.fees ? <span title={formatNumber(tdsComputation.fees.total_late_fees_amtp)}><FontAwesomeIcon icon={faRupeeSign} /> {' '} {formatNumber(tdsComputation.fees.total_late_fees_amtp)} </span> : '-'}	 </td>
										</tr>

									</tbody>
								</table>
							</div>
						</div>

					</div>
				</div>
			</Modal.Body>

		</Modal>
	);
}
