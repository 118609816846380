/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import deleteImg from '../../images/del-icon.png';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { parseDate } from '@telerik/kendo-intl';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { AddDeducteeTransactionsIcon } from '../../components/grid/CustomCell';
import { FormatNumberDecimal, formatNumber } from '../../utils/UtilityFunctions';
import { getter } from "@progress/kendo-react-common";
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import downloadIcon from '../../images/downloadicon_white.png';
import { getNatureOfPaymentCodes, getChallansListV2 } from '../../api/challanAPI';
import AddChallan from './AddChallan';
import ConfirmPopup from '../../components/ConfirmPopup';
import { selectHeaderData } from '../../features/header/headerSlice';
import ReturnFlowTimeLine from '../deducteeTransactions/ReturnFlowTimeLine';
import ImportReturnFromExcelV2 from '../returnDashboard/importReturnFromExcel/ImportReturnFromExcelV2';
import { downloadExcelTemplate, downloadFilledExcelTemplate } from '../../api/deductorAPI';
import { HEADER_FILE_NAME } from '../../app/constants';
import { downloadRegularReturnErrorFile } from '../../api/returnAPI';
import ChallanListMappedTransV2 from './ChallanListMappedTransV2';
import { gocallApi } from '../../api/issueCertificateAPI';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import './ChallanListV2.scss';
import Skeleton12ColumnGrid from '../../components/Skeleton/Skeleton12ColumnGrid';
import eyeIcon from '../../images/eyeiconcolr.png';
import EyeCloseIcon from "../../images/eye_close_icon_coralclr.png";
import infoIcon from '../../images/icons/infoicon.svg';
import ChallanListV2ActionPopup from './ChallanListV2ActionPopup';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';

const ChallanListV2 = ({ history, ...props }) => {

    let { deductorId } = props.match.params;

    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);

    if (params.deductor_id) {
        deductorId = params.deductor_id;
    }

    const DATA_ITEM_KEY = "challan_id";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);

    let nextPageUrl = '/';
    let paramsVals = '';
    let returnID = '';
    let openVal = null
    if (location.state && location.state.nextPageUrl) {
        nextPageUrl = location.state.nextPageUrl;
    }

    if (location.state) {
        if (location.state.paramsVals) {
            paramsVals = location.state.paramsVals;
        } else {
            paramsVals = location.state.paramVals;
        }
        if (paramsVals) {
            const firstReplace = paramsVals.replace('?', '');
            const firstSplit = firstReplace.split('&');
            firstSplit.map((key) => {
                const arrayValue = key.split('=');
                if (arrayValue[0] && arrayValue[0] === 'return_id') {
                    returnID = arrayValue[1];
                }
                return true;
            });
        }
    }
    if (returnID !== '') {
        let obj = {
            "return_id": returnID,
            "nextPageUrl": nextPageUrl
        }
        localStorage.setItem('values', JSON.stringify(obj));
    }

    if (params.return_id) {
        returnID = params.return_id;
    }

    const [port, setPort] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const headerDataDetails = useSelector(selectHeaderData);
    const [paymentCodes, setPaymentCodes] = useState([]);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [checkedChallanData, setCheckedChallanData] = useState({});
    const [allCheckData, setAllCheckData] = useState(false);
    const [selectedChallanCount, setSelectedChallanCount] = useState(0);
    const [selectedChallanData, setSelectedChallanData] = useState(0);
    const [returnId, setReturnId] = useState(returnID);
    const [dataState, setDataState] = useState({});
    const [challanSummary, setChallanSummary] = useState({});
    const [bulkVerifyChallanConnector, setBulkVerifyChallanConnector] = useState(false);
    const [importReturn, setImportReturn] = useState(false);
    const [mappedTransPopup, setMappedTransPopup] = useState({ '-1': false });
    const [data, setData] = useState([]);
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [keyIndex, setKeyIndex] = useState(null);
    const [skeletonList, setSkeletonList] = useState(true);
    const [fromTotalUnconsumedAmtp, setFromTotalUnconsumedAmtp] = useState(1);
    const [toTotalUnconsumedAmtp, setToTotalUnconsumedAmtp] = useState(1000);
    const [fromTotalAmtp, setFromTotalAmtp] = useState(1);
    const [toTotalAmtp, setToTotalAmtp] = useState(1000);
    const [sliderValue, setSliderValue] = useState([1, 1000]);
    const [filters, setFilters] = useState({
        filter_acc: '',
        filter_acc_to: '',
    });
    const [buttonClick, setButtonClick] = useState('');
    const [checkVerify, setCheckVerify] = useState(false);
    const [checkUnverify, setCheckUnverify] = useState(params.unverified === "1" ? true : false);
    const [minMaxVal, setMinMaxVal] = useState({
        minValue: 0,
        maxValue: 100
    });

    const [unConsumeMinMax, setUnConsumeMinMax] = useState({
        minVal: 0,
        maxVal: 100
    });

    const [buttonClass, setButtonClass] = useState(true);
    const [filterButton1, setFilterButton1] = useState('');
    const [filterButton2, setFilterButton2] = useState('');
    const [visibleSections, setVisibleSections] = useState(new Set());
    const [visibleActions, setVisibleActions] = useState(new Set());
    const [actionpopup, setActionPopup] = useState(false);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [filterPostData, setFilterPostData] = useState({});
    const [challanNum, setChallanNum] = useState(0);
    const financial_year = parseInt(headerDataDetails?.financial_year_formatted.split("-")?.[0] ?? 0);
    const [disabledTimeline, setDisabledTimeline] = useState(false);

    const STATUS_LIST = [
        { label: 'Unverified', value: 'UNVERIFIED' },
        { label: 'Verified', value: 'MATCHED' },
    ];

    const ACTIVITY_STATUSES = [
        {
            label: "Verified",
            color: "green",
            icon: faCircle,
            class: "iocnfsuppot"
        },

        {
            label: "Unverified",
            color: "red",
            icon: faCircle,
            class: "iocnfsuppot"
        },

    ];

    const onClose = (reload, msg = '', FormPrintOpen) => {
        if (FormPrintOpen !== true) {
            setShowCreateForm(false);
        }
        if (msg) {
            setSuccess(msg);
        }

        if (reload) {
            setTimeout(() => {
                history.go();
            }, 3000);
        }
    };

    const deleteSelectedChallan = () => {
        async function fetchEverything() {
            const deletedIds = Array.from(selectedIds);
            const payload = {
                "deductor_id": params?.deductor_id,
                "challan_id": deletedIds
            }
            async function fetchChallans() {

                const result = await gocallApi('delete', '/api/v1/challan', payload);
                setSuccess(result.data.message);
                setTimeout(() => {
                    history.go();
                }, 1500);
            }
            try {
                await Promise.all([
                    fetchChallans(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };

    const toggleAllSelect = (checkedD = {}) => {
        const unMatchedSelect = { ...checkedChallanData, ...checkedD };
        _.map(unMatchedSelect, (value, key) => {
            unMatchedSelect[key] = !allCheckData;
        });

        if (!allCheckData) {
            setSelectedChallanCount(Object.keys(unMatchedSelect).length);
        } else {
            setSelectedChallanCount(0);
        }

        setCheckedChallanData(unMatchedSelect);
        setAllCheckData(!allCheckData);
    };

    const handleChallanToggle = (event) => {
        if (event && event.dataItem) {
            let key = event.dataItem.challan_id;
            const newValue = !checkedChallanData[key];
            setCheckedChallanData({ ...checkedChallanData, [key]: newValue });

            let allMatched = true;

            _.map(checkedChallanData, (value, key1) => {
                if (key1 !== key && value !== newValue) {
                    allMatched = false;
                }
            });

            if (newValue) {
                setSelectedChallanCount(selectedChallanCount + 1);
            } else {
                setSelectedChallanCount(selectedChallanCount - 1);
            }

            if (allMatched && newValue) {
                setAllCheckData(true);
            } else {
                setAllCheckData(false);
            }
        }
    };

    const getChallanListDataV2 = (postData, filterStr, status, check, booleanCheck) => {
        async function fetchEverything() {
            async function fetchChallans() {
                if (filterStr) {
                    if (buttonClick === 'unconsumed' || buttonClick === '') {
                        postData.from_remaining_amtp = filterStr.from_val;
                        postData.to_remaining_amtp = filterStr.to_val;
                    } else if (buttonClick === 'all') {
                        postData.from_total_amtp = filterStr.from_val;
                        postData.to_total_amtp = filterStr.to_val;
                    }

                }

                if (check) {
                    if (status === "verified" && !checkUnverify) {
                        postData.filter_status = ["MATCHED"]
                    } else if (status === "verified" && checkUnverify) {
                        postData.filter_status = ["MATCHED", "UNVERIFIED"]
                    }
                    if (status === "unverified" && !checkVerify) {
                        postData.filter_status = ["UNVERIFIED"]
                    } else if (status === "unverified" && checkVerify) {
                        postData.filter_status = ["MATCHED", "UNVERIFIED"]
                    }
                } else if (!check) {
                    if (status === "verified" && checkUnverify) {
                        postData.filter_status = ["UNVERIFIED"]
                    }
                    if (status === "unverified" && checkVerify) {
                        postData.filter_status = ["MATCHED"]
                    }
                }

                const result = await getChallansListV2(postData);
                if (result?.data?.cursor?.current_page > 1) {
                    setData(data.concat(result?.data?.data ?? []));
                    setCursor(result?.data?.cursor);
                } else {
                    setData(result.data.data ?? []);
                }

                if (result?.data?.cursor?.next_page > 1) {
                    result.data.cursor.next_page_url = result?.data?.cursor?.next_page;
                }
                setChallanSummary(result?.data?.summary ?? {});

                // setSliderLimits(result?.data?.data ?? [])

                if (postData?.show_all_challan && !booleanCheck) {
                    calMinMaxValue("all", result?.data?.data)
                } else if ((postData?.show_all_unconsumed && !booleanCheck) || (!postData?.show_all_unconsumed && !booleanCheck)) {
                    calMinMaxValue("unconsumed", result?.data?.data)
                }

                let new_challanList = result?.data?.data ?? [];

                setData(new_challanList);

                const checkedData = {};
                const keyWiseData = {};
                const selectedData = [];

                new_challanList && new_challanList?.length > 0 && new_challanList.map((challan) => {
                    checkedData[challan.challan_id] = false;
                    keyWiseData[challan.challan_id] = challan;
                    selectedData.push(challan.challan_id);
                    return true;
                });

                setCheckedChallanData(checkedData);
            }
            try {
                await Promise.all([
                    fetchChallans(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.message);
            } finally {
                setSkeletonList(false);
                setLoading(false);
                setShowMoreLoading(false);
            }
        }
        if (postData?.page && postData?.page > 1) { setShowMoreLoading(true); } else { setLoading(true); }
        fetchEverything();
    };

    useEffect(() => {
        setReturnId(returnID);
        fetchPaymentCodesFunc();
        //eslint-disable-next-line
    }, []);


    const calMinMaxValue = (filtercheck, data) => {
        let minValue = 0;
        let maxValue = 0;
        let unConsumedArray = [];
        let allArray = [];
        let valObj = {}

        if (filtercheck === 'unconsumed') {
            if (data && data.length > 0) {
                data.forEach((item) => {
                    unConsumedArray.push(item?.total_remaining_amtp);

                })
                minValue = Math.min(...unConsumedArray);
                maxValue = Math.max(...unConsumedArray);
                setSliderValue([Number(minValue), Number(maxValue)]);

                valObj.minVal = minValue
                valObj.maxVal = maxValue

                setFromTotalUnconsumedAmtp(minValue);
                setToTotalUnconsumedAmtp(maxValue);

                if ((fromTotalUnconsumedAmtp === 1 && toTotalUnconsumedAmtp === 1000) || (fromTotalUnconsumedAmtp + toTotalUnconsumedAmtp) >= 0) {
                    setUnConsumeMinMax(valObj)
                }
            }
            if (data?.length <= 0 || data === null || data === undefined) {
                setSliderValue([Number(0), Number(100)]);

                valObj.minVal = 0
                valObj.maxVal = 100

                setFromTotalUnconsumedAmtp(0);
                setToTotalUnconsumedAmtp(100);

                if ((fromTotalUnconsumedAmtp === 1 && toTotalUnconsumedAmtp === 1000) || (fromTotalUnconsumedAmtp || toTotalUnconsumedAmtp)) {
                    setUnConsumeMinMax(valObj)
                }
            }
        }
        if (filtercheck === 'all') {
            if (data && data.length > 0) {
                data.forEach((item) => {
                    allArray.push(item?.total_amtp);
                })
                minValue = Math.min(...allArray);
                maxValue = Math.max(...allArray);
                setSliderValue([Number(minValue), Number(maxValue)]);

                valObj.minValue = minValue
                valObj.maxValue = maxValue

                setFromTotalAmtp(minValue);
                setToTotalAmtp(maxValue);

                if ((fromTotalAmtp === 1 && toTotalAmtp === 1000) || (fromTotalAmtp + toTotalAmtp) >= 0) {
                    setMinMaxVal(valObj)
                }
            }
            if (data?.length <= 0 || data === null || data === undefined) {
                setSliderValue([Number(0), Number(100)]);

                valObj.minValue = 0
                valObj.maxValue = 100

                setFromTotalAmtp(0);
                setToTotalAmtp(100);

                if (fromTotalAmtp === 1 && toTotalAmtp === 1000) {
                    setMinMaxVal(valObj)
                }
            }
        }
    }

    const getParamsHistoryUrl = (dateObj, filterBtn) => {

        const postData = {
            deductor_id: deductorId,
            sort_on: params.sort_on ? params.sort_on : '',
            sort_by: params.order_by ?? "",
        };

        if (params.return_id) {
            postData.return_id = params.return_id;
        }

        if (filterBtn) {
            postData.show_all_challan = (filterBtn === 'unconsumed' ? false : filterBtn === 'all' ? true : false);
            postData.show_all_unconsumed = (filterBtn === 'unconsumed' ? true : filterBtn === 'all' ? false : false);
            postData.from_deposit_date = dateObj?.from_date;
            postData.to_deposit_date = dateObj?.to_date;
        }

        if (params.sort) {
            dataState.sort = JSON.parse(atob(params.sort));
            postData.sort = JSON.parse(atob(params.sort));
        }
        if (params.filters) {
            if (buttonClick) {
                postData.show_all_challan = (buttonClick === 'unconsumed' ? false : buttonClick === 'all' ? true : false);
                postData.show_all_unconsumed = (buttonClick === 'unconsumed' ? true : buttonClick === 'all' ? false : false);
                postData.from_deposit_date = fetchDataByYearAndQuarter(financial_year, new Date().getFullYear())?.from_date;
                postData.to_deposit_date = fetchDataByYearAndQuarter(financial_year, new Date().getFullYear())?.to_date;
            }

            if (checkVerify) {
                postData.filter_status = ["MATCHED"]
            } else if (checkUnverify) {
                postData.filter_status = ["UNMATCHED", "UNVERIFIED"]
            }
            if (checkUnverify && checkVerify) {
                postData.filter_status = ["MATCHED", "UNMATCHED", "UNVERIFIED"]
            }

            // IN getParamsHistoryUrl
            let frs = JSON.parse(atob(params.filters));
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'deposit_date') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                }
            }

            dataState.filter = { filters: [] };
            dataState.filter.filters = frs;
            postData.filters = frs;
        }
        if (params.page) {
            postData.page = params.page;
        }

        setDataState(dataState);
        setFilterPostData(postData);
        return postData;
    };

    useEffect(() => {
        const postData = getParamsHistoryUrl(fetchDataByYearAndQuarter(financial_year, new Date().getFullYear()), buttonClick);
        if (params.unverified === "1") {
            postData.filter_status = ["UNMATCHED", "UNVERIFIED"]
            postData.show_old_challans = false
        }
        getChallanListDataV2(postData);

        // eslint-disable-next-line
    }, [searchStr]);


    const dataStateChange = (dataState) => {
        if (dataState?.filter?.filters && dataState?.filter?.filters.length > 0) {
            dataState?.filter?.filters.forEach((item) => {
                if (item?.filters[0].field === 'nature_of_payment_id' && item?.filters?.[0]?.operator === 'in' && item?.filters?.[0].value.includes(0)) {
                    item.logic = 'or';
                    let flag = true;
                    item.filters.forEach((subItem) => {
                        if (subItem.field === 'nature_of_payment_id' && subItem.operator === 'isempty') {
                            flag = false;
                        }
                    })
                    if (flag) {
                        item.filters.push({ field: "nature_of_payment_id", operator: "isempty", value: "NA" });
                    }
                }
            })
        }

        setDataState(dataState);
        createHistoryUrl(dataState, filters);
    };

    const createHistoryUrl = (dataState = [], filters = {}) => {

        let filterStr = `?return_id=${params.return_id}&deductor_id=${params.deductor_id}`;

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${dataState.sort[0].field}&order_by=${dataState.sort[0].dir}`;
        }

        history.push(filterStr);
    };

    const fetchPaymentCodesFunc = (params) => {
        async function fetchEverything() {
            async function fetchPaymentCodes() {
                const result = await getNatureOfPaymentCodes(params);
                const paymentCodeData = [];
                const payDataKey = {};
                result?.data?.map((paymentCode) => {
                    paymentCodeData.push({
                        label: `${paymentCode.code} - ${paymentCode.description}`,
                        value: paymentCode.nature_of_payment_id,
                    });
                    payDataKey[paymentCode.code] = paymentCode;
                    return true;
                });
                paymentCodeData.push({
                    label: 'NA',
                    value: 0,
                })
                setPaymentCodes(paymentCodeData);
            }
            try {
                await Promise.all([
                    fetchPaymentCodes(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };


    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter?.filters?.[0]?.field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const downloadErrorFile = async () => {
        setLoading(true);
        try {
            let payload = `?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`;
            const result = await downloadRegularReturnErrorFile(payload);
            const csiData = new Blob([result?.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS-ReturnErrorData.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            setError(err);
        }
        setLoading(false);
    };

    const renderSubTextTitle = (title, subText) => {
        let hoverTxt = ""
        if (title === "Mapped Trans") {
            hoverTxt = "Mapped Transaction"
        } else if (title === "TRACES Unconsum Amt") {
            hoverTxt = "TRACES Unconsumed Amount"
        } else if (title === "TDS") {
            hoverTxt = "Adjustable TDS Amount"
        }
        return (
            <div title={hoverTxt}>
                {title}
                {subText && (<div className={`${title === 'Mapped Trans' ? "text-center" : "text-right"}`}>
                    {' '}<span className="b2btbtrthtextclr" style={{ paddingLeft: '20px' }}>{formatNumber(subText ?? 0)}</span>
                </div>)}
            </div>
        );
    };

    const toggleSelected = (checked) => {
        const selected_ids = new Set();
        if (checked) {
            if (data && data.length > 0) {
                data.forEach((item) => {
                    selected_ids.add(item?.challan_id);
                })
            }
        } else {
            selected_ids.clear();
        }
        setSelectedIds((prev) => {
            prev = selected_ids
            return prev
        })
    };

    const singleSelectDelete = (checked, id) => {
        const selected_ids = new Set(selectedIds);
        if (checked) {
            selected_ids.add(id);
        } else {
            selected_ids.delete(id);
        }
        setSelectedIds((prev) => {
            prev = selected_ids
            return prev
        })
    }

    const downloadExcelTemp = async (template = 'blank') => {
        setLoading(true);
        try {
            const payload1 = `?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`
            const payload2 = `?form_type=${headerDataDetails.form_type}&quarter=${headerDataDetails.quarter}&return_type=REGULAR`;
            const result = await (template === 'filled' ? downloadFilledExcelTemplate(payload1) : downloadExcelTemplate(payload2));
            const resultData = template === 'filled' ? result.data : result;
            const csiData = new Blob([resultData], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            let formType = headerDataDetails.form_type;
            let download_file_name = `ExpressTDS_ ${formType}_Template.xlsx`;
            if (template === 'filled') {
                const headers = result?.headers;
                if (headers?.['k-file-name']) {
                    download_file_name = headers?.['k-file-name'];
                }
            }
            link.setAttribute('download', download_file_name);
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    function getFirstItemOfSet(set) {
        for (let item of set) {
            if (item) {
                return item;
            }
        }
        return undefined;
    }

    const getScrollbarHeight = (el) => {
        return el?.getBoundingClientRect()
    };

    const rowRender = (trElement, props,) => {

        const key = props.dataIndex;

        const challanID = props?.dataItem?.challan_id

        return (
            <tr key={key} className={`dash_totalverchall_trth2romamt positionkey${key}`} role="row" >
                <td className='text-left'>
                    <input
                        type='checkbox'
                        onChange={(e) => { singleSelectDelete(e.target.checked, challanID); handleChallanToggle(props) }}
                        checked={selectedIds.has(challanID)}
                        value={challanID} />
                </td>
                <td>
                    <span style={{ paddingLeft: '8px' }}>
                        <FontAwesomeIcon icon={faCircle}
                            color={`${data[key]?.verified_status === 'MATCHED' ? 'green' : 'red'}`}
                            aria-hidden="true" />
                    </span>
                </td>
                <td className="text-center">{props?.dataItem?.deposit_date}</td>
                <td className="text-center">{props?.dataItem?.challan_no}</td>
                <td className="text-center">{props?.dataItem?.bsr_code}</td>
                <td className="text-center">{props?.dataItem?.nature_of_payment ?? "-"}</td>
                <td className="text-right tds_returnflow_challan_hoverbox" onMouseEnter={() => {
                    const position = getScrollbarHeight(document.querySelector(`.positionkey${key}`));
                    if (position?.top && position?.top > 340 && position?.top < 750) {
                        const classlist = document.querySelector(`.dropdown-content.tds_returnflow_challan_dropcont.indexkey${key}`);
                        if (classlist) classlist?.classList?.add("positionclass")
                    }

                }}>
                    <span className="text-right"> {formatNumber(props?.dataItem?.total_amtp)}</span>
                    <div className="dropdown tds_returnflow_challan_hover_link" >
                        <span className={`dropdown-content tds_returnflow_challan_dropcont indexkey${key}`}>
                            <ul className="tds_returnflow_challan_hoverlist">
                                <li>
                                    <span className="float-left challan_hoverlist_cellwidthleftbox">TDS</span>
                                    <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.tds_amtp ?? 0)}</span>
                                </li>
                                <li>
                                    <span className="float-left challan_hoverlist_cellwidthleftbox">Surcharge</span>
                                    <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.surcharge_amtp ?? 0)}</span>
                                </li>
                                <li>
                                    <span className="float-left challan_hoverlist_cellwidthleftbox">Health & Edu. Cess</span>
                                    <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.edu_cess_amtp ?? 0)}</span>
                                </li>
                                <li>
                                    <span className=" float-left challan_hoverlist_cellwidthleftbox">Interest</span>
                                    <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.interest_amtp ?? 0)}</span>
                                </li>
                                <li>
                                    <span className="float-left challan_hoverlist_cellwidthleftbox">Fees</span>
                                    <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.fees_amtp ?? 0)}</span>
                                </li>
                                <li>
                                    <span className="float-left challan_hoverlist_cellwidthleftbox">Penalty/Others</span>
                                    <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.other_amtp ?? 0)}</span>
                                </li>
                            </ul>
                        </span>
                    </div>
                </td>
                <td className="text-right return_flow_adjust_challans_trrowbgclr_yell">{formatNumber(props?.dataItem?.remaining_tds_bal_amtp ?? 0)}</td>
                <td className="text-right return_flow_adjust_challans_trrowbgclr_yell">{formatNumber(props?.dataItem?.interest_unconsumed_amtp ?? 0)}</td>
                <td className="text-right return_flow_adjust_challans_trrowbgclr_yell">{formatNumber(props?.dataItem?.other_unconsumed_amtp ?? 0)}</td>
                <td className="text-right return_flow_adjust_challans_trrowbgclr_yell">{formatNumber(props?.dataItem?.total_remaining_amtp ?? 0)}</td>
                <td className='text-center return_flow_adjust_challans_trrowbgclr_yell pl-4'>
                    <span>
                        <span onClick={(e) => {
                            let newVisibleSections = new Set(visibleActions);
                            if (newVisibleSections.has(challanID)) {
                                newVisibleSections.delete(challanID);
                            } else {
                                newVisibleSections.add(challanID);
                                if (newVisibleSections.size === 3) {
                                    const firstItem = getFirstItemOfSet(newVisibleSections);
                                    newVisibleSections.delete(firstItem);
                                }
                            }
                            setVisibleActions(newVisibleSections);
                            setMappedTransPopup({ ...mappedTransPopup, [props.dataIndex]: true });
                            setKeyIndex(props.dataIndex);
                        }}>
                            <img src={visibleActions.has(challanID) ? EyeCloseIcon : eyeIcon} alt="eye icon" width={16} style={{ cursor: 'pointer' }} onClick={() => {
                                let newVisibleActions = new Set(visibleActions);
                                if (newVisibleActions.has(challanID)) {
                                    newVisibleActions.delete(challanID);
                                } else {
                                    newVisibleActions.add(challanID);
                                    if (newVisibleActions.size === 3) {
                                        const firstItem = getFirstItemOfSet(newVisibleActions);
                                        newVisibleActions.delete(firstItem);
                                    }
                                }
                                setVisibleActions(newVisibleActions);

                                setActionPopup(true);
                                setChallanNum(props?.dataItem?.challan_no)
                            }} />
                        </span>
                    </span>
                </td>

                <td className="text-center" width={100}>
                    <span className='challanno_textclr_blurundline' onClick={(e) => {
                        let newVisibleSections = new Set(visibleSections);
                        if (newVisibleSections.has(challanID)) {
                            newVisibleSections.delete(challanID);
                        } else {
                            newVisibleSections.add(challanID);
                            if (newVisibleSections.size === 3) {
                                const firstItem = getFirstItemOfSet(newVisibleSections);
                                newVisibleSections.delete(firstItem);
                            }
                        }
                        setVisibleSections(newVisibleSections);
                        setMappedTransPopup({ ...mappedTransPopup, [props.dataIndex]: true });
                        setKeyIndex(props.dataIndex);
                    }}>
                        {FormatNumberDecimal(props?.dataItem?.return_mapped_transactions ?? 0, ...Array(2), false)}
                    </span>
                </td>
            </tr>
        );
    };

    const handleSliderChangeProcess = (event, newValue) => {

        let new_filters = filters;

        if (buttonClick === 'unconsumed') {
            if (fromTotalUnconsumedAmtp.toString() !== newValue['0'].toString()) {
                setFromTotalUnconsumedAmtp(newValue['0']);

            } else if (toTotalUnconsumedAmtp.toString() !== newValue['1'].toString()) {
                setToTotalUnconsumedAmtp(newValue['1']);
            }
        } else {
            if (fromTotalAmtp.toString() !== newValue['0'].toString()) {
                setFromTotalAmtp(newValue['0']);

            } else if (toTotalAmtp.toString() !== newValue['1'].toString()) {
                setToTotalAmtp(newValue['1']);
            }
        }


        setFilters(new_filters);
        const checkSelect = checkVerify ? checkVerify : checkUnverify;
        const checkStatus = checkVerify ? "verified" : "unverified";

        const postData = getParamsHistoryUrl(fetchDataByYearAndQuarter(financial_year, new Date().getFullYear()), buttonClick);
        getChallanListDataV2(postData, { from_val: newValue['0'], to_val: newValue['1'] }, checkStatus, checkSelect, true)
    };

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    function valuetext(value) {
        return `${value}`;
    }

    let quarterObj = {
        "from_date": "",
        "to_date": ""
    };

    const fetchDataByYearAndQuarter = (fy, currentYear) => {

        if (fy === currentYear) {
            quarterObj = {
                "from_date": `${fy - 1}-04-01`,
                "to_date": moment(new Date()).format('YYYY-MM-DD')
            }
            return quarterObj;
        } else if (fy < currentYear) {
            if ((fy + 1) === currentYear) {
                quarterObj = {
                    "from_date": `${fy - 1}-04-01`,
                    "to_date": moment(new Date()).format('YYYY-MM-DD')
                }
                return quarterObj;
            } else if ((fy + 1) !== currentYear) {
                quarterObj = {
                    "from_date": `${fy - 1}-04-01`,
                    "to_date": `${fy + 1}-03-31`
                }
                return quarterObj;
            }
        }

        return quarterObj;
    };


    useEffect(() => {
        createHistoryUrl(dataState)
        //eslint-disable-next-line
    }, [dataState, filters])

    const totalmappedTransactions = () => {
        let total_mapped_trans = 0
        if (data && data?.length > 0) {
            data.forEach((item) => {
                if (selectedIds.has(item?.challan_id)) {
                    total_mapped_trans += item?.total_mapped_transactions
                }
            })
            return total_mapped_trans
        }
    };

    const confirmText = () => {
        let mapped_trans = totalmappedTransactions();
        if (mapped_trans > 0) {
            if (selectedIds.size > 1) {
                return `${mapped_trans} transactions are mapped in any of the Challans. Deleting this will unmap those transactions. Do you wish to proceed?`
            } else {
                return `${mapped_trans} transactions are mapped with this Challan. Deleting this will unmap those transactions. Do you wish to proceed?`
            }
        } else {
            return 'Are you sure that you want to delete the Challan ? Please note that the process is irreversible. Click Delete button to continue.'
        }
    };

    let popupIndex = 0;
    let totalpopups = 0;
    Array.from(visibleSections).forEach((challanID) => {

        if (visibleSections.has(challanID)) {
            totalpopups++;
        }

    });


    let popupIndex1 = 0;
    let totalpopups1 = 0;
    Array.from(visibleActions).forEach((challanID) => {
        if (visibleActions.has(challanID)) {
            totalpopups++;
        }
    });

    const getSelectedChallanData = (challanID) => {
        const selectedChallanRecord = data.filter((v) => v.challan_id === challanID);
        return selectedChallanRecord?.[0] ?? [];
    }

    return (
        <>
            {loading ? <LoadingIndicator /> : null}

            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            {showCreateForm
                ? (
                    <AddChallan
                        onClose={onClose}
                        deductorId={deductorId}
                    />
                )
                : null
            }

            <ImportReturnFromExcelV2
                show={importReturn}
                handleClose={() => {
                    setImportReturn(false);
                }}
                deductorId={params?.deductor_id}
                returnId={params.return_id}
                importExelError={() => { setImportReturn(false) }}
                showImportReturnFromTxt={false}
                downloadExcelTemp={downloadExcelTemp}
                fetchTransactionOverView={() => null}
                setDisabledTimeline={setDisabledTimeline}
            />


            {Array.from(visibleSections).map((challanID) => {
                popupIndex = popupIndex + 1;
                const parms = {
                    onClose: () => {
                        const newVisibleSections = new Set(visibleSections);
                        newVisibleSections.delete(challanID);
                        setVisibleSections(newVisibleSections);
                    },
                    index: popupIndex - 1,
                    totalpopups: totalpopups,
                }
                return (
                    <ChallanListMappedTransV2
                        show={mappedTransPopup}
                        paymentCodes={paymentCodes}
                        params={params}
                        mappedHeadData={getSelectedChallanData(challanID)}
                        setError={setError}
                        challanId={challanID ?? ""}
                        {...parms}
                    />
                )
            })}

            {Array.from(visibleActions).map((challanID) => {
                popupIndex1 = popupIndex1 + 1;
                const parms = {
                    onClose: () => {
                        const newVisibleSections = new Set(visibleActions);
                        newVisibleSections.delete(challanID);
                        setVisibleActions(newVisibleSections);
                    },
                    index: popupIndex1 - 1,
                    totalpopups1: totalpopups1,
                }
                return (
                    <ChallanListV2ActionPopup
                        show={actionpopup}
                        paymentCodes={paymentCodes}
                        params={params}
                        setError={setError}
                        challanId={challanID ?? ""}
                        setLoading={setLoading}
                        challanNum={challanNum}
                        {...parms}
                    />
                )
            })}

            <ConfirmPopup
                show={showConfirmPopup}
                handleClose={() => setShowConfirmPopup(false)}
                confirmText={confirmText()}
                confirmButtonText="Delete"
                handleProceed={() => {
                    setShowConfirmPopup(false);
                    deleteSelectedChallan();
                }}
            />

            <div className="container-fluid">
                <ReturnFlowTimeLine returnId={params.return_id} deductorId={params.deductor_id} currentPage={`ChallansListing`} disabledTimeline={disabledTimeline} />
                <div className="textscrolling_sectionfixed" id="textscrollsection">
                    <div className='row'>
                        <div className="col-xl-5 col-lg-8 col-md-8 col-sm-8 col-xs-6 text-left pr-0 mb-2">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-6 col-xs-12 pr-0 colxxlscreen_widthfixed">
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className='showalluncol_box'>
                                                <div className="showallunconbox">
                                                    <button className={`btn btn-default challanlist_showalluncon btntext_blue mr-2 ${buttonClass ? 'default_btnbgclr' : ''}`} onClick={() => {
                                                        const checkSelect = checkVerify ? checkVerify : checkUnverify;
                                                        const checkStatus = checkVerify ? "verified" : "unverified";
                                                        const postData = getParamsHistoryUrl();
                                                        getChallanListDataV2(postData, '', checkStatus, checkSelect);
                                                        setButtonClass(true); setFilterButton1(''); setFilterButton2(''); setButtonClick('')
                                                    }}>
                                                        Default
                                                    </button>
                                                    <button className={`btn btn-default challanlist_showalluncon btntext_blue mr-2  ${filterButton1}`} onClick={() => {
                                                        const checkSelect = checkVerify ? checkVerify : checkUnverify;
                                                        const checkStatus = checkVerify ? "verified" : "unverified";
                                                        const postData = getParamsHistoryUrl(fetchDataByYearAndQuarter(financial_year, new Date().getFullYear()), 'unconsumed'); setButtonClick('unconsumed')
                                                        getChallanListDataV2(postData, 'unconsumed', checkStatus, checkSelect);
                                                        setButtonClass(false); setFilterButton1('default_btnbgclr'); setFilterButton2('');

                                                    }}>
                                                        All Unconsumed
                                                    </button>
                                                    <button className={`btn btn-default challanlist_showalluncon btntext_blue ${filterButton2}`} onClick={() => {
                                                        const checkSelect = checkVerify ? checkVerify : checkUnverify;
                                                        const checkStatus = checkVerify ? "verified" : "unverified";
                                                        const postData = getParamsHistoryUrl(fetchDataByYearAndQuarter(financial_year, new Date().getFullYear()), 'all'); setButtonClick('all')
                                                        getChallanListDataV2(postData, 'all', checkStatus, checkSelect);
                                                        setButtonClass(false); setFilterButton2('default_btnbgclr'); setFilterButton1('');
                                                    }}>
                                                        All Challans
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-5 col-sm-6 col-xs-12 pl-0">
                                    <div className='listofchallan_rangsliderbox'>
                                        <Typography id="range_track" gutterBottom className='text-center pl-4'>
                                        </Typography>
                                        <div className="double_range_slider_box">
                                            <div className="double_range_slider challanListv2">
                                                <Slider
                                                    value={sliderValue}
                                                    onChange={handleSliderChange}
                                                    onChangeCommitted={(e, newValue) => handleSliderChangeProcess(e, newValue)}
                                                    valueLabelDisplay="on"
                                                    aria-labelledby="range-slider"
                                                    getAriaValueText={valuetext}
                                                    min={buttonClick ? (buttonClick === 'unconsumed' ? (unConsumeMinMax?.minVal) : minMaxVal?.minValue) : fromTotalUnconsumedAmtp}
                                                    max={buttonClick ? (buttonClick === 'unconsumed' ? unConsumeMinMax?.maxVal : minMaxVal?.maxValue) : toTotalUnconsumedAmtp}
                                                />
                                            </div>
                                        </div>

                                        <div className="rangslidew">
                                            <br />
                                            <div className="fl">
                                                {' '}
                                            </div>
                                            <div className="fr">
                                                {' '}
                                            </div>
                                        </div>
                                    </div>
                                    {/* } */}
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-7 col-lg-4 col-md-4 col-sm-6">

                            <div className="errorbtn_toppadd">
                                <button className="btn btn-default Challans_errorrecordsbtn" onClick={() => downloadErrorFile()} title='Download Error Report'>
                                    <span className="pr-1">
                                        <img src={downloadIcon} width="16" alt="Download Error Report" />
                                    </span>
                                    Error Records
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    skeletonList ? <Skeleton12ColumnGrid rowNo={7} />
                        :
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="card p-0">
                                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                        <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                            <Tooltip anchorElement="pointer" position="right">
                                                <Grid
                                                    className="table table-striped listofsalarydetailstb mb-0" id="tableid"
                                                    style={{ maxHeight: '490px', minHeight: '320px', overflowX: 'auto' }}
                                                    data={data?.length > 0 && data.map((item) => ({
                                                        ...item,
                                                        [SELECTED_FIELD]: checkedChallanData[idGetter(item)],
                                                    }))}
                                                    {...dataState}
                                                    rowRender={rowRender}
                                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                    dataItemKey={DATA_ITEM_KEY}
                                                    selectedField={SELECTED_FIELD}
                                                    selectable={{
                                                        enabled: true,
                                                        drag: false,
                                                        cell: false,
                                                    }}
                                                    ShowColumnSortIndexes={false}
                                                    onSelectionChange={handleChallanToggle}
                                                    onHeaderSelectionChange={() => toggleAllSelect()}
                                                    filterOperators={{
                                                        text: [
                                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                        ],
                                                        numeric: [
                                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                        ],
                                                        date: [
                                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                                        ],
                                                        textWithEmpty: [
                                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                        ],
                                                        dropdown: [
                                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                                        ],
                                                        pan: [
                                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                                        ],
                                                    }}
                                                    sortable={{
                                                        allowUnsort: true,
                                                    }}
                                                >

                                                    <Column headerClassName={'py-0'}>

                                                        <Column
                                                            field="selected"
                                                            width={40}
                                                            headerClassName={'text-left'}
                                                            headerCell={() => (
                                                                <div style={{ textAlign: 'left' }}>
                                                                    <span><input type="checkbox"
                                                                        checked={data && data?.length > 0 && data.findIndex((item) => !checkedChallanData[idGetter(item)]) === -1}
                                                                        onChange={(e) => { toggleAllSelect(); toggleSelected(e.target.checked) }}
                                                                        className="checkboxvertalaign pr-5 challan_createdeductee_delfs cursorpointer" /> </span>
                                                                    <br />
                                                                    <img alt='delete icon' src={deleteImg} width="16" className="delcionvert"
                                                                        onClick={() => {
                                                                            if (selectedIds.size > 0) {
                                                                                setShowConfirmPopup(true);
                                                                            } else {
                                                                                setError('Select atleast one challan to delete!!');
                                                                            }
                                                                        }} style={{ marginBottom: '8px', cursor: 'pointer' }} />
                                                                </div>
                                                            )}
                                                            headerSelectionValue={
                                                                data && data?.length > 0 && data.findIndex((item) => !checkedChallanData[idGetter(item)]) === -1
                                                            }
                                                        />

                                                        <Column
                                                            style={{ zIndex: '55 !important', top: '5px!important' }}
                                                            field="verified_status"
                                                            width="65"
                                                            title={
                                                                <div className="stsicon onhoverboxshow">
                                                                    <span style={{ color: '#33729c', paddingRight: '30px' }}>
                                                                        <FontAwesomeIcon icon={faExclamationCircle} /></span>
                                                                    <span className="tooltiptext">
                                                                        <ul className="stsboxlist">
                                                                            <li>Status:</li>
                                                                            {ACTIVITY_STATUSES.map((status) => {
                                                                                return (
                                                                                    <li>
                                                                                        <span className="form-check-inline">
                                                                                            <label className="form-check-label stsboxclrtext">
                                                                                                <span className={status.class}><FontAwesomeIcon icon={status.icon} color={status.color} /></span>
                                                                                                <span className="ststextfs" style={{ color: status.color }}>{status.label}</span>
                                                                                            </label>
                                                                                        </span>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    </span>
                                                                </div>
                                                            }
                                                            headerClassName={isColumnActive('verified_status') ? 'thdisplaycontent active text-center' : 'thdisplaycontent text-center'}
                                                            filter="dropdown"
                                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={STATUS_LIST} />}
                                                            sortable={false}
                                                            dataStateChange={dataStateChange}
                                                        />

                                                        <Column
                                                            width="120"
                                                            field="deposit_date"
                                                            headerClassName={isColumnActive('deposit_date') ? 'active' : ''}
                                                            title="Deposit Dt"
                                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                                            filter="date"
                                                        />

                                                        <Column
                                                            width={140}
                                                            field="challan_no"
                                                            headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                                            title={<AddDeducteeTransactionsIcon field="challan_no" dataState={dataState}
                                                                dataStateChange={dataStateChange}
                                                                handleClick={() => setShowCreateForm(true)}
                                                                title='Challan No'
                                                                addTooltip="Click to add new challan"
                                                                subtextData={{ subText: challanSummary?.total_challan ?? 0, color: '' }}
                                                                headClass={true}
                                                            />}
                                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                            sortable={false}
                                                        />

                                                        <Column
                                                            width={110}
                                                            field="bsr_code"
                                                            headerClassName={isColumnActive('bsr_code') ? 'active' : ''}
                                                            title="BSR Code"
                                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                        />

                                                        <Column
                                                            width={100}
                                                            field="nature_of_payment_id"
                                                            headerClassName={isColumnActive('nature_of_payment_id') ? 'active' : ''}
                                                            title="Section"
                                                            filter="dropdown"
                                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                                                        />

                                                        <Column
                                                            width={150}
                                                            field="total_amtp"
                                                            headerClassName={isColumnActive('total_amtp') ? 'active ' : ''}
                                                            title={<AddDeducteeTransactionsIcon field="total_amtp" dataState={dataState}
                                                                dataStateChange={dataStateChange}
                                                                title='Deposited Total'
                                                                subtextData={{ subText: challanSummary?.total_challan_amtp ?? 0 }}
                                                                showButton={false}
                                                            />}
                                                            filter="numeric"
                                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                                        />

                                                    </Column>


                                                    <Column title='Remaining Balance' width={600} headerClassName='rem_bal_main_col return_flow_adjust_challans_trrowbgclr_yell py-0'>

                                                        <Column
                                                            width={135}
                                                            field="remaining_tds_bal_amtp"
                                                            headerClassName={isColumnActive('remaining_tds_bal_amtp') ? 'active return_flow_adjust_challans_trrowbgclr_yell' : 'return_flow_adjust_challans_trrowbgclr_yell'}
                                                            title={<AddDeducteeTransactionsIcon field="remaining_tds_bal_amtp" dataState={dataState}
                                                                dataStateChange={dataStateChange}
                                                                title={<span>TDS <span style={{ paddingLeft: '2px' }} data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title={'(inc. Surcharge & Health and education Cess)'}>
                                                                    <img src={infoIcon} alt="info icon" width={14} /></span></span>}
                                                                subtextData={{ subText: challanSummary?.total_tds_remaining_amtp ?? 0, subTextTitle: 'TDS' }}
                                                                showButton={false}
                                                            />}
                                                            filter="numeric"
                                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                                        />

                                                        <Column
                                                            width={135}
                                                            field="interest_unconsumed_amtp"
                                                            headerClassName={isColumnActive('interest_unconsumed_amtp') ? 'active return_flow_adjust_challans_trrowbgclr_yell' : 'return_flow_adjust_challans_trrowbgclr_yell'}
                                                            title={<AddDeducteeTransactionsIcon field="interest_unconsumed_amtp" dataState={dataState}
                                                                dataStateChange={dataStateChange}
                                                                title='Interest'
                                                                subtextData={{ subText: challanSummary?.total_interest_remaining_amtp ?? 0 }}
                                                                showButton={false}
                                                            />}
                                                            filter="numeric"
                                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                                        />

                                                        <Column
                                                            width={140}
                                                            field="other_unconsumed_amtp"
                                                            headerClassName={isColumnActive('other_unconsumed_amtp') ? 'active return_flow_adjust_challans_trrowbgclr_yell' : 'return_flow_adjust_challans_trrowbgclr_yell'}
                                                            title={<AddDeducteeTransactionsIcon field="other_unconsumed_amtp" dataState={dataState}
                                                                dataStateChange={dataStateChange}
                                                                title='Fees/Others'
                                                                subtextData={{ subText: challanSummary?.total_others_remaining_amtp ?? 0 }}
                                                                showButton={false}
                                                            />}
                                                            filter="numeric"
                                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                                        />

                                                        <Column
                                                            width={150}
                                                            field="total_remaining_amtp"
                                                            headerClassName={isColumnActive('total_remaining_amtp') ? 'active return_flow_adjust_challans_trrowbgclr_yell' : 'return_flow_adjust_challans_trrowbgclr_yell'}
                                                            title={<AddDeducteeTransactionsIcon field="total_remaining_amtp" dataState={dataState}
                                                                dataStateChange={dataStateChange}
                                                                title='Total'
                                                                addTooltip="Total"
                                                                subtextData={{ subText: challanSummary?.total_remaining_bal_amtp ?? 0 }}
                                                                showButton={false}
                                                            />}
                                                            filter="numeric"
                                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                                        />

                                                        <Column
                                                            width={90}
                                                            field=""
                                                            headerClassName='return_flow_adjust_challans_trrowbgclr_yell'
                                                            title={renderSubTextTitle('Actions')}
                                                        />

                                                    </Column>

                                                    <Column headerClassName={'py-0'}>

                                                        <Column
                                                            width={140}
                                                            field="return_mapped_transactions"
                                                            filter="numeric"
                                                            headerClassName={isColumnActive('return_mapped_transactions') ? 'active' : ''}
                                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                                            title={<AddDeducteeTransactionsIcon field="return_mapped_transactions" dataState={dataState}
                                                                dataStateChange={dataStateChange}
                                                                title='Mapped Trans'
                                                                addTooltip="Mapped Transactions"
                                                                subtextData={{ subText: challanSummary?.total_mapped_transactions ?? 0, color: '' }}
                                                                headClass={true}
                                                                showButton={false}
                                                            />}
                                                            sortable={false}
                                                        />

                                                    </Column>


                                                </Grid>
                                            </Tooltip>
                                            <div className="row mt-2" style={{ width: '100%' }}>
                                                <div className="col-md-12 text-center">
                                                    <ShowMorePagination
                                                        cursor={cursor}
                                                        fetchData={getChallanListDataV2}
                                                        postData={filterPostData}
                                                        loading={showMoreLoading}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div >


            {/* <!-- left side import box --> */}
            <div className="challans_leftsidepopupbox" data-toggle="modal" data-target="#tdsreimport_popup" >
                <div className="challans_AnnouncementsIcon" data-toggle="modal" data-target="#updatebooks2adatam">
                    <div className="challans_Announcements">
                        <div className="challans_Announcements-wrapper challans_has-unseen-items Dropdown">
                            <div className="Dropdown-toggle Button">
                                <div className="challans_Announcements-bubble">&nbsp;</div>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bullhorn" className="svg-inline--fa fa-bullhorn fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ color: 'rgb(255, 255, 255)', fontSize: '11px', transform: 'rotate(-38deg)' }}><path fill="currentColor" d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"></path>
                                </svg>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="mb-0 btn challans_textfs" type="button" onClick={() => setImportReturn(true)}>
                    Import Return
                </div>
            </div>

        </>
    )
};

export default ChallanListV2;