import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import downArrow from '../../images/icons/down_arrow.svg';
import { updateOrganizationProfile, getOrganizationProfile } from '../../api/profileAPI';
import ShowAlert from '../ShowAlert';

const OrganizationProfile = (props) => {
  const { editable } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [organizationName, setOrganizationName] = useState('');
  const [organizationType, setOrganizationType] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const updateOrganizationName = async (e) => {
    e.preventDefault();

    const formErr = {};
    if (organizationName.trim().length === 0) {
      formErr.organizationName = 'Please enter organization name';
    }

    setFormErrors(formErr);

    if (Object.keys(formErr).length > 0) return;

    setLoading(true);
    const data = {
      organization_name: organizationName.replace(/<\/?[^>]+(>|$)/g, ""),
    };
    try {
      const result = await updateOrganizationProfile(data);
      setOrganizationName(data.organization_name);
      setSuccess(result.message);
    } catch (err) {
      setError(err.message);
      console.error('Error in updating organization name: ', err);
    }
    setLoading(false);
  };

  const getOrganizationData = async () => {
    try {
      const result = await getOrganizationProfile();
      setOrganizationName(result.data.organization_name);
      setOrganizationType(result.data.organization_type);
    } catch (err) {
      console.error('Error in getting organization profile: ', err);
    }
  };

  useEffect(() => {
    getOrganizationData();
  }, []);

  return (
    <div className="container">
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <form onSubmit={updateOrganizationName}>
        <div className="card padding_top_manage mt-3 profilecardpadd">
          <div className="row">
            <div className="grey_bg">
              <div className="row">
                <div className="col-sm-12">
                  <div

                    data-toggle="collapse"
                    href="#collapseOrganization"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseOrganization"
                  >

                    {' '}
                    <span style={{ fontSize: '0.9rem' }}>
                      Organization Information
                    </span>
                    <img
                      className="create_new_return float-right"
                      src={downArrow}
                      alt="View Organization Profile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="collapse" id="collapseOrganization">

            <div className="row mt-2">
              <div className="form-group col-sm-4">
                <label htmlFor="inputEmail4">
                  Organization Name
                  <span className="red_text">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Organization Name"
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                  disabled={!editable}
                />
                {formErrors.organizationName ? <span className="error_message">{formErrors.organizationName}</span> : null}
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="inputEmail4">
                  Organization / Firm Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={organizationType}
                  disabled
                />
              </div>
              <div className="col-sm-12 text-center">

                {editable && (
                  <div className="text-center mb-2">
                    <button className="light_color_bg profilsavebtn " type="submit" disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {' '}
                          Loading...
                        </>
                      ) : 'Save'}
                    </button>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrganizationProfile;
