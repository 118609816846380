import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import EmailPdfList from './EmailPdfList';
import ExcelExportImport from './ExcelExportImport';
import { BASE_PATH } from '../../../app/constants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './emailpdf.css';

const EmailSalaryTdsCalculationsDetails = ({ history, ...props }) => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const { deductor_id, financial_year } = params;
    const handleSucessProcees = () => {
        setTimeout(() => window.location.reload(), 1000);
    };
    return (
        <>
            <div class="container-fluid mt-3">
                <div class="row mt-2">
                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-2 col-xs-12">                        
                    <Link style={{color: '#fc7d72', fontSize: '12px'}} to={`${BASE_PATH}monthwise-salary-details?deductor_id=${deductor_id}&financial_year=${financial_year}`}>&lt; Back to Emp-Wise Monthly Tds Details</Link>
                    </div>
                    <div class="col-xl-10 col-lg-9 col-md-9 col-sm-10 col-xs-12 text-center">
                        <div class="col-md-12 text-center">
                            <span class="emailtoselettitle ">Email to Selected Employee</span>
                            <p class="suppsubtitlefs" style={{ paddingRight: "10rem" }}>
                                F.Y: <strong>{`${financial_year}-${String(Number(financial_year) + 1).substr(2, 2)}`}</strong> 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <EmailPdfList
                        deductorId={deductor_id ?? ""}
                        financial_year={financial_year ?? ""}
                    />
                    <ExcelExportImport
                        deductorId={deductor_id ?? ""}
                        financial_year={financial_year ?? ""}
                        handleSucessProcees={handleSucessProcees} />
                </div>
            </div>
        </>
    );
}

export default EmailSalaryTdsCalculationsDetails;
