/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import './CreateRaiseRequestFormPopup.css';
import DatePicker from '../datePicker/DatePicker';
import { raiseRequestFormPopupValidationSchema } from './RaiseRequestFormPopupSchema';
import ErrorFocus from '../ErrorFocus';
import { getFinancialYears } from '../../utils/UtilityFunctions';
import ImportFUVFile from '../../features/returnRaise/importFUVFile/ImportFUVFile';
import { raiseRequestPopupFields, resetForm } from './RaiseRequestFormPopupHelper';
import PRNInput from '../PRNInput';
import exclamationIcon from "../../images/exclamation.png";
import NumericInput from '../NumericInput';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import TracesConnector from '../kdkConnector/TracesConnector';



const CreateRaiseRequestFormPopup = (props) => {
  const {
    raiseRequestFormInfo,
    createRaiseRequest,
    onClose,
    returnId,
    deductorId,
    // updatePopUpSaveAlt,
    submitBtnProceed,
    submitUnsetBtnProceed,
    handleProceedImportFUVFile2,
    fetchPreRaiseRequestData,
    setInputValFunc,
    inputVal, port, preDefineRaiseData, checkJustification, checkConso 
  } = props;
  const [part1Disabled, setPart1Disabled] = useState(true);
  const [part2Disabled, setPart2Disabled] = useState(true);
  const [part3Disabled, setPart3Disabled] = useState(false);
  const [part4Disabled, setPart4Disabled] = useState(false);
  const [part5Disabled, setPart5Disabled] = useState(true);
  const [importFUVFile, setImportFUVFile] = useState(false);
  const [showImportFUVFile, setShowImportFUVFile] = useState(false);
  const [importFUVFileData, setImportFUVFileData] = useState(false);
  const [raiseRequestFormInfoVal, setRaiseRequestFormInfoVal] = useState(resetForm(raiseRequestPopupFields));
  const [verifyTraceCredentials, setVerifyTraceCredentials] = useState(false);
  const [afterReturnFill, setAfterReturnFill] = useState(false);
  const [form16Checked, setForm16Checked] = useState('');
  const [jsonData, setJsonData] = useState({});
  const [AYs, setAYs] = useState([]);
  const [captchaRef, setCaptchaRef] = useState('');
  useEffect(() => {
    if (submitBtnProceed) {
      submitUnsetBtnProceed();
      const link = document.getElementById('submitCRaiseReqForm');
      link.click();
    }
  }, [submitBtnProceed]);



  useEffect(() => {
    if (preDefineRaiseData) {
      setRaiseRequestFormInfoVal((prev) => {
        prev.ft = preDefineRaiseData?.form_type
        prev.fy = preDefineRaiseData?.financial_year
        prev.quarter = preDefineRaiseData?.quarter
        const formType = preDefineRaiseData?.form_type
        if (formType === '27Q' || formType === '26Q') {
          prev.request_type = ['Form16A']
        } else if (formType === '27EQ') {
          prev.request_type = ['Form27D']
        } else {
          prev.request_type = ['Form16']

        }
        if (checkJustification) {
          prev.request_type = ['Justification_Report']
        }
        if (checkConso) {
          prev.request_type = ['Conso']
        }
        return { ...prev }
      });
      $("#autoTriggeronissue").trigger("click");
    }
  }, [preDefineRaiseData]);

  const setFiledForValForm2 = (setFieldValue) => {
    setFieldValue('form1input', '1', false);
    setFieldValue('form2input', '0', false);
    setFieldValue('form3input', '0', false);
    setFieldValue('nil_chalan', '0', false);
    setPart1Disabled(false);
    setPart2Disabled(true);
    setPart3Disabled(true);
  };

  const enablePanForm = (setFieldValue) => {
    setFieldValue('form4input', '1', false);
    setFieldValue('form5input', '0', false);
    //setFieldValue('nil_transaction', '0', false);
    setPart4Disabled(false);
    setPart5Disabled(true);
  };

  const setFiledForVal = (setFieldValue, values) => {
    if (inputVal) {
      if (values.token !== raiseRequestFormInfo.token) {
        setFieldValue('token', raiseRequestFormInfo.token, false);
      }

      if (values.bsr_code !== raiseRequestFormInfo.bsr_code) {
        setFieldValue('bsr_code', raiseRequestFormInfo.bsr_code, false);
        setFiledForValForm2(setFieldValue);
      }

      if (values.challan_no !== raiseRequestFormInfo.challan_no) {
        setFieldValue('challan_no', raiseRequestFormInfo.challan_no, false);
        setFiledForValForm2(setFieldValue);
      }

      if (values.deposit_date !== raiseRequestFormInfo.deposit_date) {
        setFieldValue('deposit_date', raiseRequestFormInfo.deposit_date, false);
        $('#datepickerDiv').find('input').val(raiseRequestFormInfo.deposit_date);
        setFiledForValForm2(setFieldValue);
      }
      if (values.challan_amount !== Number(raiseRequestFormInfo.challan_amount)) {
        setFieldValue('challan_amount', Number(raiseRequestFormInfo.challan_amount), false);
        setFiledForValForm2(setFieldValue);
      }
      if (values.pan_no['0'] !== raiseRequestFormInfo.pan_no['0'] || values.tax_deducted['0'] !== raiseRequestFormInfo.tax_deducted['0']) {
        setFieldValue('pan_no[0]', raiseRequestFormInfo.pan_no['0'], false);
        setFieldValue('tax_deducted[0]', raiseRequestFormInfo.tax_deducted['0'], false);
        enablePanForm(setFieldValue);
      }
      if (values.pan_no['1'] !== raiseRequestFormInfo.pan_no['1'] || values.tax_deducted['1'] !== raiseRequestFormInfo.tax_deducted['1']) {
        setFieldValue('pan_no[1]', raiseRequestFormInfo.pan_no['1'], false);
        setFieldValue('tax_deducted[1]', raiseRequestFormInfo.tax_deducted['1'], false);
        enablePanForm(setFieldValue);
      }
      if (values.pan_no['2'] !== raiseRequestFormInfo.pan_no['2'] || values.tax_deducted['2'] !== raiseRequestFormInfo.tax_deducted['2']) {
        setFieldValue('pan_no[2]', raiseRequestFormInfo.pan_no['2'], false);
        setFieldValue('tax_deducted[2]', raiseRequestFormInfo.tax_deducted['2'], false);
        enablePanForm(setFieldValue);
      }
      setInputValFunc();
    }
  };

  const disabledPart1 = (setFieldValue) => {
    $('#book_adjustment_container').find('input').removeAttr('disabled', 'disabled');
    $('#challan_info_container,#nil_challan_container,#nil2_pan_container').find('input').attr('disabled', 'disabled');
    setPart1Disabled(true);
    setPart3Disabled(true);
    setPart2Disabled(false);
    setFieldValue('form1input', '0', false);
    setFieldValue('form2input', '1', false);
    setFieldValue('form3input', '0', false);

    // disabled={part1Disabled}
  };

  const disabledPart2 = (setFieldValue) => {
    $('#challan_info_container').find('input').removeAttr('disabled', 'disabled');
    $('#book_adjustment_container,#nil_challan_container,#nil2_pan_container').find('input').attr('disabled', 'disabled');
    setPart2Disabled(true);
    setPart3Disabled(true);
    setPart1Disabled(false);
    setFieldValue('form1input', '1', false);
    setFieldValue('form2input', '0', false);
    setFieldValue('form3input', '0', false);
    // disabled={part1Disabled}
  };

  const disabledDivSubmit = () => {
    setTimeout(function () {
      $('#book_adjustment_container,#nil2_pan_container').find('input').attr('disabled', 'disabled');
    }, 1000);
  }

  const disabledAllPart = (setFieldValue) => {
    $('#book_adjustment_container,#challan_info_container,#deductee_container').find('input').attr('disabled', 'disabled');
    $('#nil_challan_container,#nil2_pan_container').find('input').removeAttr('disabled', 'disabled');
    setPart2Disabled(true);
    setPart1Disabled(true);
    setPart3Disabled(false);
    setFieldValue('form1input', '0', false);
    setFieldValue('form2input', '0', false);
    setFieldValue('form3input', '1', false);
    // disabled={part1Disabled}
  };

  const disabled4Part = (setFieldValue) => {
    setPart4Disabled(true);
    setPart5Disabled(false);
    setFieldValue('form5input', '1', false);
    setFieldValue('form4input', '0', false);
    // disabled={part1Disabled}
  };

  const disabled5Part = (setFieldValue) => {
    $('#nil2_pan_container').find('input').attr('disabled', 'disabled');
    $('#deductee_container').find('input').removeAttr('disabled', 'disabled');
    setPart4Disabled(false);
    setPart5Disabled(true);
    setFieldValue('form5input', '0', false);
    setFieldValue('form4input', '1', false);
  };


  useEffect(() => {
    setAYs(getFinancialYears());
  }, []);

  const handleProceedImportFUVFile = (result) => {
    setShowImportFUVFile(false);
    handleProceedImportFUVFile2(result);
    setImportFUVFile(false);
  };

  const changeForm16 = (value, values, setFieldValue) => {

    let request_type = Array.isArray(values.request_type) ? values.request_type : [];
    let type = request_type.filter((type) => { return type === value });

    if (type.length === 0) {
      request_type.push(value);
      if (value === 'Form16') {
        let index = request_type.indexOf('Form16A');
        if (index > -1) {
          request_type.splice(index, 1);
        }
        index = request_type.indexOf('Form27D');
        if (index > -1) {
          request_type.splice(index, 1);
        }

      }

      if (value === 'Form16A') {
        let index = request_type.indexOf('Form16');
        if (index > -1) {
          request_type.splice(index, 1);
        }
        index = request_type.indexOf('Form27D');
        if (index > -1) {
          request_type.splice(index, 1);
        }
      }

      if (value === 'Form27D') {
        let index = request_type.indexOf('Form16');
        if (index > -1) {
          request_type.splice(index, 1);
        }
        index = request_type.indexOf('Form16A');
        if (index > -1) {
          request_type.splice(index, 1);
        }

      }


    }
    else {
      let index = request_type.indexOf(value);
      if (index > -1) {
        request_type.splice(index, 1);
      }
    }

    if (request_type.indexOf("Form16") >= 0) {
      setForm16Checked('Form16');
      setFieldValue('ft', '24Q');
      setFieldValue('quarter', 'Q4');
    }
    else if (request_type.indexOf("Form16A") >= 0) {
      setForm16Checked('Form16A');
      setFieldValue('ft', '');
    }
    else if (request_type.indexOf("Form27D") >= 0) {
      setForm16Checked('Form27D');
      setFieldValue('ft', '27EQ');
    }
    else {
      setForm16Checked('');
    }

    setFieldValue('request_type', request_type ? request_type : []);
  }

  return (
    <>


      {verifyTraceCredentials && port
        ?
        <TracesConnector
          deductorId={deductorId}
          returnId={null}
          handleProceed={() => {
            setVerifyTraceCredentials(false);
          }}
          port={port}
          setVerifyCreds={() => { createRaiseRequest(jsonData, captchaRef) }}
          creds={null}
          setCaptchaRef={setCaptchaRef}
          callCaptchaRef={true}
        />
        : null
      }

      <ImportFUVFile
        show={showImportFUVFile}
        // eslint-disable-next-line no-unused-vars
        handleClose={(_refresh) => {
          setShowImportFUVFile(false);
        }}
        deductorId={deductorId}
        importFUVFileData={importFUVFileData}
        returnId={returnId}
        handleProceedImportFUVFile={handleProceedImportFUVFile}
      />
      <Formik
        enableReinitialize
        initialValues={raiseRequestFormInfoVal}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={raiseRequestFormPopupValidationSchema}
        onSubmit={(values) => {
        }}
      >
        {({
          errors,
          values,
          validateForm,
          setErrors,
          setFieldValue,
        }) => {
          const submit1Form = () => {
            const AllErrors = {};
            let errorValidReturn = 0;
            if (values.request_type.length === 0) {
              AllErrors.request_type = 'This field is required';
              errorValidReturn += 1;
            }
            if (values.ft === '') {
              AllErrors.ft = 'This field is required';
              errorValidReturn += 1;
            }
            if (values.fy === '') {
              AllErrors.fy = 'This field is required';
              errorValidReturn += 1;
            }
            if (values.quarter === '') {
              AllErrors.quarter = 'This field is required';
              errorValidReturn += 1;
            }
            setErrors(AllErrors);
            if (errorValidReturn === 0) {
              fetchPreRaiseRequestData(`&form_type=${values.ft}&financial_year=${values.fy}&quarter=${values.quarter}`);
              setAfterReturnFill(true);
            }
          };



          const submitForm = () => {
            validateForm().then(async (data) => {
              if (Object.keys(data).length > 0) {
                const AllPanNo = [];
                const AllTaxDeducted = [];
                let AllErrors = {};
                if (values.form4input === '1') {

                  const nPattern = new RegExp(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/);
                  if ($.trim(values.pan_no['0']) === '' && $.trim(values.pan_no['1']) === '' && $.trim(values.pan_no['2']) === '') {
                    AllPanNo['0'] = 'This field is required';
                  }
                  if ($.trim(values.pan_no['0']) !== '' && !nPattern.test(values.pan_no['0'])) {
                    AllPanNo['0'] = 'Invalid PAN Number';
                  }
                  if ($.trim(values.pan_no['1']) !== '' && !nPattern.test(values.pan_no['1'])) {
                    AllPanNo['1'] = 'Invalid PAN Number';
                  }
                  if ($.trim(values.pan_no['2']) !== '' && !nPattern.test(values.pan_no['2'])) {
                    AllPanNo['2'] = 'Invalid PAN Numbersss';
                  }
                  if ($.trim(values.pan_no['0']) !== '' && $.trim(values.tax_deducted['0']) === '') {
                    AllTaxDeducted['0'] = 'This field is required';
                  }

                  if ($.trim(values.pan_no['1']) !== '' && $.trim(values.tax_deducted['1']) === '') {
                    AllTaxDeducted['1'] = 'This field is required';
                  }

                  if ($.trim(values.pan_no['2']) !== '' && $.trim(values.tax_deducted['2']) === '') {
                    AllTaxDeducted['2'] = 'This field is required';
                  }

                }
                AllErrors = { ...data, tax_deducted: AllTaxDeducted, pan_no: AllPanNo };
                setErrors(AllErrors);
              } else {
                const AllPanNo = [];
                const AllTaxDeducted = [];
                let AllErrors = {};
                if (values.form4input === '1') {
                  const nPattern = new RegExp(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/);
                  let errorValidReturn = 0;
                  if ($.trim(values.pan_no['0']) === '' && $.trim(values.pan_no['1']) === '' && $.trim(values.pan_no['2']) === '') {
                    AllPanNo['0'] = 'This field is required';
                    errorValidReturn += 1;
                  }
                  if ($.trim(values.pan_no['0']) !== '' && !nPattern.test(values.pan_no['0'])) {
                    AllPanNo['0'] = 'Invalid PAN Number';
                    errorValidReturn += 1;
                  }
                  if ($.trim(values.pan_no['1']) !== '' && !nPattern.test(values.pan_no['1'])) {
                    AllPanNo['1'] = 'Invalid PAN Number';
                    errorValidReturn += 1;
                  }
                  if ($.trim(values.pan_no['2']) !== '' && !nPattern.test(values.pan_no['2'])) {
                    AllPanNo['2'] = 'Invalid PAN Numbersss';
                    errorValidReturn += 1;
                  }
                  if ($.trim(values.pan_no['0']) !== '' && $.trim(values.tax_deducted['0']) === '') {
                    AllTaxDeducted['0'] = 'This field is required';
                    errorValidReturn += 1;
                  }

                  if ($.trim(values.pan_no['1']) !== '' && $.trim(values.tax_deducted['1']) === '') {
                    AllTaxDeducted['1'] = 'This field is required';
                    errorValidReturn += 1;
                  }

                  if ($.trim(values.pan_no['2']) !== '' && $.trim(values.tax_deducted['2']) === '') {
                    AllTaxDeducted['2'] = 'This field is required';
                    errorValidReturn += 1;
                  }


                  if (errorValidReturn === 0) {
                    setJsonData(values)
                    setVerifyTraceCredentials(true)
                  }
                } else {
                  setJsonData(values)
                  setVerifyTraceCredentials(true)
                }

                AllErrors = { ...errors, tax_deducted: AllTaxDeducted, pan_no: AllPanNo };
                setErrors(AllErrors);
              }
            });
          };
          const submit2Form = () => {
            const AllPanNo = [];
            const AllTaxDeducted = [];
            let AllErrors = {};
            let errorValidReturn = 0;

            validateForm().then(async (data) => {
              if (Object.keys(data).length > 0) {
                AllErrors = data;
              }
            });

            if (values.form4input === '1') {
              const nPattern = new RegExp(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/);

              if ($.trim(values.pan_no['0']) === '' && $.trim(values.pan_no['1']) === '' && $.trim(values.pan_no['2']) === '') {
                AllPanNo['0'] = 'This field is required';
                errorValidReturn += 1;
              }
              if ($.trim(values.pan_no['0']) !== '' && !nPattern.test(values.pan_no['0'])) {
                AllPanNo['0'] = 'Invalid PAN Number';
                errorValidReturn += 1;
              }
              if ($.trim(values.pan_no['1']) !== '' && !nPattern.test(values.pan_no['1'])) {
                AllPanNo['1'] = 'Invalid PAN Number';
                errorValidReturn += 1;
              }
              if ($.trim(values.pan_no['2']) !== '' && !nPattern.test(values.pan_no['2'])) {
                AllPanNo['2'] = 'Invalid PAN Numbers';
                errorValidReturn += 1;
              }
              if ($.trim(values.pan_no['0']) !== '' && $.trim(values.tax_deducted['0']) === '') {
                AllTaxDeducted['0'] = 'This field is required';
                errorValidReturn += 1;
              }

              if ($.trim(values.pan_no['1']) !== '' && $.trim(values.tax_deducted['1']) === '') {
                AllTaxDeducted['1'] = 'This field is required';
                errorValidReturn += 1;
              }

              if ($.trim(values.pan_no['2']) !== '' && $.trim(values.tax_deducted['2']) === '') {
                AllTaxDeducted['2'] = 'This field is required';
                errorValidReturn += 1;
              }


              if (errorValidReturn === 0) {
                submitForm();
              }
            } else {
              submitForm();

            }
            AllErrors = { ...errors, tax_deducted: AllTaxDeducted, pan_no: AllPanNo };
            setErrors(AllErrors);
          };
         
          setFiledForVal(setFieldValue, values);
          return (
            <Form key={1} autoComplete="off" className="raise-request-form" >
              <ErrorFocus />
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <div className="form-check-inline">
                          <label className="form-check-label" htmlFor="checkbox">
                            Request For <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="form-group mb-0">
                        <div className="form-check-inline">
                          <label className="form-check-inline" style={{ display: 'contents' }}>
                            <Field
                              type="checkbox"
                              className="form-check-input"
                              id="Conso"
                              name="request_type"
                              value="Conso"
                              checked={values.request_type.indexOf("Conso") >= 0}
                              onChange={(e) => { changeForm16(e.target.value, values, setFieldValue) }}
                              disabled={preDefineRaiseData ? true : false}

                            />
                            Consolidated File
                          </label>
                        </div>

                        <div className="form-check-inline">
                          <label className="form-check-label" style={{ display: 'contents' }}>

                            <Field
                              type="checkbox"
                              className="form-check-input"
                              id="Justification_Report"
                              name="request_type"
                              value="Justification_Report"
                              checked={values.request_type.indexOf("Justification_Report") >= 0}
                              onChange={(e) => { changeForm16(e.target.value, values, setFieldValue) }}
                              disabled={preDefineRaiseData ? true : false}

                            />
                            Justification Report
                          </label>
                        </div>

                        <div className="form-check-inline">
                          <label className="form-check-label" style={{ display: 'contents' }}>

                            <Field
                              type="checkbox"
                              className="form-check-input"
                              id="form16"
                              name="request_type"
                              value="Form16"
                              checked={values.request_type.indexOf("Form16") >= 0}
                              onChange={(e) => {
                                changeForm16(e.target.value, values, setFieldValue);
                                (values.ft !== '24Q' || values.quarter !== 'Q4') && setAfterReturnFill(false)
                              }}
                              disabled={preDefineRaiseData ? true : false}

                            />
                            Bulk Form 16
                          </label>
                        </div>

                        <div className="form-check-inline">
                          <label className="form-check-label" style={{ display: 'contents' }}>

                            <Field
                              type="checkbox"
                              className="form-check-input"
                              id="form16A"
                              name="request_type"
                              value="Form16A"
                              checked={values.request_type.indexOf("Form16A") >= 0}
                              onChange={(e) => {
                                changeForm16(e.target.value, values, setFieldValue);
                                (values.ft !== '26Q' || values.ft !== '27Q') && setAfterReturnFill(false)
                              }}
                              disabled={preDefineRaiseData ? true : false}

                            />
                            Bulk Form 16A
                          </label>
                        </div>

                        <div className="form-check-inline">
                          <label className="form-check-label" style={{ display: 'contents' }}>

                            <Field
                              type="checkbox"
                              className="form-check-input"
                              id="Form27D"
                              name="request_type"
                              value="Form27D"
                              checked={values.request_type.indexOf("Form27D") >= 0}
                              onChange={(e) => {
                                changeForm16(e.target.value, values, setFieldValue);
                                values.ft !== '27EQ' && setAfterReturnFill(false)
                              }}
                              disabled={preDefineRaiseData ? true : false}

                            />
                            Bulk Form 27D
                          </label>
                        </div>
                      </div>
                      {errors.request_type ? (
                        <span className="error_message">
                          <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                          {errors.request_type}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-check-label" htmlFor="sel3">Form Type <span style={{ color: "red" }}>*</span></label>

                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="form-group mb-0">
                        <Field
                          as="select"
                          className={`form-control ${errors?.ft ? 'input-error1' : ''}`}
                          id="ft"
                          value={values.ft}
                          name="ft"
                          onChange={(e) => {
                            setFieldValue(
                              'ft',
                              e.target.value,
                            );
                            setAfterReturnFill(false);
                          }}
                          disabled={preDefineRaiseData ? true : false}
                        >
                          <option value="">Select</option>
                          {form16Checked === 'Form16' ?
                            <option value="24Q">24Q</option>
                            : null
                          }

                          {form16Checked === 'Form16A' ?
                            <>
                              <option value="26Q">26Q</option>
                              <option value="27Q">27Q</option>
                            </>
                            : null
                          }

                          {form16Checked === 'Form27D' ?
                            <option value="27EQ">27EQ</option>
                            : null
                          }
                          {/* {form16Checked === 'Form27Q' ?
                            <option value="27Q">27Q</option>
                            : null
                          } */}

                          {form16Checked !== 'Form16' && form16Checked !== 'Form16A' && form16Checked !== 'Form27D' ?
                            <>
                              <option value="24Q">24Q</option>
                              <option value="26Q">26Q</option>
                              <option value="27EQ">27EQ</option>
                              <option value="27Q">27Q</option>
                            </>
                            : null
                          }

                        </Field>
                      </div>
                      {errors.ft ? (
                        <span className="error_message">
                          <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                          {errors.ft}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-inline pt-2" htmlFor="sel1">
                          Financial Year <span style={{ color: "red" }}>*</span>
                        </label>

                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="form-group mb-0">
                        <Field
                          as="select"
                          className={`form-control ${errors?.fy ? 'input-error1' : ''}`}
                          id="fy"
                          value={values.fy}
                          name="fy"
                          onChange={(e) => {
                            setFieldValue(
                              'fy',
                              e.target.value,
                            );
                            setAfterReturnFill(false);
                          }}
                          disabled={preDefineRaiseData ? true : false}
                        >
                          <option value="">Select</option>
                          {AYs.map((ay) => (
                            <option value={ay.value}>{ay.label}</option>
                          ))}
                        </Field>
                      </div>
                      {errors.fy ? (
                        <span className="error_message">
                          <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                          {errors.fy}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <div className="form-check-inline">
                          <label className="form-check-label" htmlFor="radio2">
                            Quarter <span style={{ color: "red" }}>*</span>
                          </label>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="form-group mb-0">
                        <div className="form-check-inline frm16_qutradioalign_issues">
                          <label className="form-check-label" style={{ display: 'contents' }}>
                            <Field
                              type="radio"
                              className="form-check-input"
                              id="quarter"
                              name="quarter"
                              checked={values.quarter === 'Q1'}
                              value="Q1"
                              onChange={(e) => {
                                setFieldValue('quarter', e.target.value);
                                setAfterReturnFill(false);
                              }}
                              disabled={(form16Checked === 'Form16' || preDefineRaiseData) ? true : false}
                            />
                            <span className='align_vertical'> Q1</span>
                          </label>
                        </div>
                        <div className="form-check-inline frm16_qutradioalign_issues">
                          <label className="form-check-label" style={{ display: 'contents' }}>
                            <Field
                              type="radio"
                              className="form-check-input"
                              id="quarter"
                              name="quarter"
                              checked={values.quarter === 'Q2'}
                              value="Q2"
                              onChange={(e) => {
                                setFieldValue('quarter', e.target.value);
                                setAfterReturnFill(false);
                              }}
                              disabled={(form16Checked === 'Form16' || preDefineRaiseData) ? true : false}
                            />
                            <span className='align_vertical'> Q2</span>
                          </label>
                        </div>
                        <div className="form-check-inline frm16_qutradioalign_issues">
                          <label className="form-check-label" style={{ display: 'contents' }}>
                            <Field
                              type="radio"
                              className="form-check-input"
                              id="quarter"
                              name="quarter"
                              checked={values.quarter === 'Q3'}
                              value="Q3"
                              onChange={(e) => {
                                setFieldValue('quarter', e.target.value);
                                setAfterReturnFill(false);
                              }}
                              disabled={(form16Checked === 'Form16' || preDefineRaiseData) ? true : false}
                            />
                            <span className='align_vertical'> Q3</span>
                          </label>
                        </div>
                        <div className="form-check-inline frm16_qutradioalign_issues">
                          <label className="form-check-label" style={{ display: 'contents' }}>
                            <Field
                              type="radio"
                              className="form-check-input"
                              id="quarter"
                              name="quarter"
                              checked={values.quarter === 'Q4'}
                              value="Q4"
                              onChange={(e) => {
                                setFieldValue('quarter', e.target.value);
                                setAfterReturnFill(false);
                              }}
                              disabled={preDefineRaiseData ? true : false}

                            />
                            <span className='align_vertical'> Q4</span>
                          </label>
                        </div>
                      </div>
                      {errors.quarter ? (
                        <span className="error_message">
                          <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                          {errors.quarter}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-1">
                <div className="col-md-12">
                  <div>
                    {' '}
                    <hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                  </div>
                </div>
                {afterReturnFill
                  ? (
                    <div className="col-md-12">
                      <div className="rediocheckboxsect">

                        <div className="pt-1 pb-1">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <Field
                                type="radio"
                                className="form-check-input"
                                id="raiseRequestFormType"
                                name="raiseRequestFormType"
                                checked={values.raiseRequestFormType === 'traces_form'}
                                value="traces_form"
                                onChange={(e) => {
                                  setFieldValue('raiseRequestFormType', e.target.value);
                                  setImportFUVFile(false);
                                }}
                              />
                              using TRACES Form
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <Field
                                type="radio"
                                className="form-check-input"
                                id="raiseRequestFormType"
                                name="raiseRequestFormType"
                                checked={values.raiseRequestFormType === 'fuv_import_form'}
                                value="fuv_import_form"
                                onChange={(e) => {
                                  setFieldValue('raiseRequestFormType', e.target.value);
                                  setImportFUVFile(true);
                                }}
                              />
                              using FVU Import
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
              </div>
              {!importFUVFile && afterReturnFill
                ? (
                  <div className="row">
                    <div className="col-md-6 pr-0 mr-0	">
                      <div className="inlinedissection">
                        <div className="form-group" style={{ marginBottom: "0" }}>
                          <label className="reqconslabelsect">
                            Token Number/Provisional Receipt Number (PRN) of the return selected
                            above
                            {' '}
                            <span className="red_text">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="inlinedissection">
                        <div>
                          <PRNInput
                            type="text"
                            className={`form-control ${errors?.token ? 'input-error1' : ''}`}
                            placeholder="Enter Token No./PRN* Number"
                            id="token"
                            name="token"
                            value={values.token}
                            onChange={(e) => setFieldValue('token', e.target.value)}
                          />
                          {errors.token ? (
                            <span className="error_message">
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.token}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>

                  </div>
                ) : null}
              <div>

                {!importFUVFile && afterReturnFill
                  ? (
                    <>
                      <fieldset className="newrretchaladefieldset">
                        <legend className="newrretchaladelegent">
                          <h4>Challan Details</h4>
                        </legend>
                        <div className="row">
                          <div
                            className={part1Disabled ? 'col-md-30 opacityDiv' : 'col-md-30'}
                            onClick={() => {
                              disabledPart2(setFieldValue);
                            }}
                            id="challan_info_container"
                          >
                            {part1Disabled && <div className="overlapedbox" onClick={() => {disabledPart2(setFieldValue); setFieldValue('nil_chalan', "0");}}></div>}
                            <p className="newrretprovidetextsize">Provide any one challan information of the selected return</p>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label htmlFor="pwdasqss">
                                    BSR Code
                                    <span className="red_text">*</span>
                                  </label>
                                  <NumericInput
                                    type="input"
                                    className={`form-control ${errors?.bsr_code ? 'input-error1' : ''}`}
                                    id="bsr_code"
                                    name="bsr_code"
                                    placeholder="Bsr Code"
                                    value={values.bsr_code}
                                    onChange={(e) => e.target.value.match(/^([0-9]{0,7})$/) && setFieldValue('bsr_code', e.target.value)}
                                    disabled={!!part1Disabled}
                                    onClick={() => {
                                      disabledPart2(setFieldValue);
                                    }}
                                  />
                                  {errors.bsr_code ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.bsr_code}
                                    </span> : null}
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div
                                  className="form-group datecal_iconalign"
                                  id="datepickerDiv"
                                  onClick={() => {
                                    disabledPart2(setFieldValue);
                                  }}
                                >
                                  <label htmlFor="pwda">
                                    Date of Deposit
                                    <span className="red_text">*</span>
                                  </label>
                                  <DatePicker
                                    selectedDate={
                                      values.deposit_date
                                        ? values.deposit_date
                                        : null
                                    }
                                    onChange={(date) => {
                                      setFieldValue('deposit_date', date);
                                    }}
                                    errors={errors.deposit_date ? errors.deposit_date : ""}
                                  />
                                  {errors.deposit_date ? (
                                    <span className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.deposit_date}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group ">
                                  <label htmlFor="pwdss">
                                    Challan Number
                                    <span className="red_text">*</span>
                                  </label>
                                  <NumericInput
                                    type="input"
                                    className={`form-control ${errors?.challan_no ? 'input-error1' : ''}`}
                                    id="challan_no"
                                    name="challan_no"
                                    placeholder="Challan No"
                                    value={values.challan_no}
                                    onChange={(e) => e.target.value.match(/^([0-9]{0,5})$/) && setFieldValue('challan_no', e.target.value)}
                                    disabled={!!part1Disabled}
                                    onClick={() => {
                                      disabledPart2(setFieldValue);
                                    }}
                                  />
                                  {errors.challan_no ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.challan_no}
                                    </span> : null}
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label htmlFor="pwdssssas">
                                    Challan Amount
                                    <span className="red_text">*</span>
                                  </label>
                                  <NumericInputWithDecimalTwo
                                    type="input"
                                    className={`form-control amount-box ${errors?.challan_amount ? 'input-error1' : ''}`}
                                    id="challan_amount"
                                    name="challan_amount"
                                    placeholder="Challan Amount"
                                    min="0"
                                    value={values.challan_amount}
                                    onChange={(e) => e.target.value.match(/^([0-9.]{0,14})$/) && setFieldValue('challan_amount', e.target.value)}
                                    onClick={() => {
                                      disabledPart2(setFieldValue);
                                    }}
                                  />
                                  {errors.challan_amount ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.challan_amount}
                                    </span> : null}

                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-md-05">
                            <div className="orsectionalign"><h4>OR</h4></div>
                          </div>
                          <div
                            className={part2Disabled ? 'col-md-30 opacityDiv' : 'col-md-30'}
                            onClick={() => {
                              disabledPart1(setFieldValue);
                            }}
                            id="book_adjustment_container"
                          >
                            {part2Disabled && <div className="overlapedbox" onClick={() => {disabledPart1(setFieldValue); setFieldValue('nil_chalan', "0")}}></div>}

                            <p className="newrretslecnilltextsect">Provide one book adjustment information of the selected return</p>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>
                                    Receipt Number
                                    <span className="red_text">*</span>
                                  </label>
                                  <NumericInput
                                    type="input"
                                    className={`form-control ${errors?.bsr_code2 ? 'input-error1' : ''}`}
                                    id="bsr_code2"
                                    name="bsr_code2"
                                    placeholder="Receipt Number"
                                    value={values.bsr_code2}
                                    onChange={(e) => e.target.value.match(/^([0-9]{0,7})$/) && setFieldValue('bsr_code2', e.target.value)}
                                    disabled={!part1Disabled}
                                    onClick={() => {
                                      disabledPart1(setFieldValue);
                                    }}
                                  />
                                  {errors.bsr_code2 ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.bsr_code2}
                                    </span> : null}
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group datecal_iconalign">
                                  <label>
                                    Date of Deposit
                                    <span className="red_text">*</span>
                                  </label>
                                  <DatePicker
                                    selectedDate={values.deposit_date2 ? values.deposit_date2 : null}
                                    onChange={(value) => setFieldValue('deposit_date2', value)}
                                    maxDate={new Date()}
                                    onClick={() => {
                                      disabledPart1(setFieldValue);
                                    }}
                                    errors={errors.deposit_date2 ? errors.deposit_date2 : ""}
                                  />
                                  {errors.deposit_date2 ? (
                                    <span className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.deposit_date2}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>
                                    DDO Serial No
                                    <span className="red_text">*</span>
                                  </label>
                                  <input
                                    className={`form-control ${errors?.government_deductor_ddo_number ? 'input-error1' : ''}`}
                                    type="text"
                                    name="government_deductor_ddo_number"
                                    id="government_deductor_ddo_number"
                                    placeholder="DDO Serial No"
                                    value={values.government_deductor_ddo_number}
                                    onChange={(e) => e.target.value.match(/^([0-9]{0,9})$/) && setFieldValue('government_deductor_ddo_number', e.target.value)}
                                    checked={(Number(values.government_deductor_ddo_number) === 0)}
                                    onClick={() => {
                                      setFieldValue('government_deductor_ddo_number', 0);
                                      disabledPart1(setFieldValue);
                                    }}
                                  />
                                  {errors.government_deductor_ddo_number ? (
                                    <span className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.government_deductor_ddo_number}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>
                                    Transfer Voucher Amount
                                    <span className="red_text">*</span>
                                  </label>
                                  <NumericInput
                                    type="input"
                                    className={`form-control ${errors?.transfer_voucher_amount ? 'input-error1' : ''}`}
                                    id="transfer_voucher_amount"
                                    name="transfer_voucher_amount"
                                    placeholder="Voucher Amount"
                                    value={values.transfer_voucher_amount}
                                    onChange={(e) => e.target.value.match(/^([0-9]{0,14})$/) && setFieldValue('transfer_voucher_amount', e.target.value)}
                                    onClick={() => {
                                      disabledPart1(setFieldValue);
                                    }}
                                  />
                                  {errors.transfer_voucher_amount ? (
                                    <span className="error_message">
                                      <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                      {errors.transfer_voucher_amount}
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="col-md-05">
                            <div className="orsectionalign"><h4>OR</h4></div>
                          </div>
                          <div className={part3Disabled ? 'col-md-30 opacityDiv' : 'col-md-30'}
                            onClick={() => {
                              disabledAllPart(setFieldValue);
                              setErrors({})
                              
                            }}
                            id="nil_challan_container"
                          >
                            {part3Disabled && <div className="overlapedbox"
                             onClick={() => {
                              disabledAllPart(setFieldValue);
                              setFieldValue('nil_chalan', "1");
                             }}></div>}

                            <p className="newrretslecnilltextsect">
                              Select NIL Challan in case, no challan exists, for the selected return
                            </p>
                            <div className="form-check-inline newrretcheckinlinenillsect">
                              <label className="form-check-label">
                                <Field
                                  className="form-check-input checklebtopalignnill"
                                  style={{ verticalAlign: 'middle' }}
                                  type="checkbox"
                                  name="nil_chalan"
                                  id="nil_chalan"
                                  value="1"
                                  checked={values.nil_chalan === '1'}
                                  onChange={(e) => {
                                    setFieldValue('nil_chalan', e.target.value);                                   
                                  }}
                                  onClick={() => {
                                    disabledAllPart(setFieldValue);
                                  }}
                                />
                                NIL Challan
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <fieldset className="newrretdeduteetransfieldset">
                        <legend className="newrretdeduteetrans">
                          <h4>Deductee Transactions</h4>
                        </legend>
                        <div className="row">
                          <div
                            className={part4Disabled ? 'col-md-6 opacityDiv' : 'col-md-6'}
                            onClick={() => {
                              disabled5Part(setFieldValue);
                            }}
                            id="deductee_container"
                          >
                            <p className="newrretprovidetextsize">
                              Provide any three Deductee Transaction's PAN and their Tax Deducted
                            </p>
                            <div className="row">
                              <div
                                className="col-md-6"
                              >
                                <div className="form-group">
                                  <label>PAN </label>

                                  <Field
                                    type="text"
                                    className={`form-control font-control-input ${errors?.pan_no?.[0] ? 'input-error1' : ''}`}
                                    id="pan_no[0]"
                                    placeholder="Enter PAN"
                                    name="pan_no[0]"
                                    value={values.pan_no[0]}
                                    maxLength={10}
                                    onChange={(e) => {
                                      e.target.value.match(/^([0-9A-Za-z]{0,10})$/) && setFieldValue('pan_no[0]', e.target.value);
                                      disabled5Part(setFieldValue);
                                    }}
                                  />
                                  {errors && errors.pan_no && errors.pan_no[0] ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.pan_no[0]}
                                    </span> : null}

                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Tax Deducted</label>
                                  <Field
                                    type="text"
                                    className={`form-control font-control-input ${errors?.tax_deducted?.[0] ? 'input-error1' : ''}`}
                                    id="tax_deducted[0]"
                                    placeholder="Enter Amount"
                                    name="tax_deducted[0]"
                                    value={values.tax_deducted[0]}
                                    onChange={(e) => {
                                      e.target.value.match(/^([0-9.]{0,14})$/) && (Number(e.target.value) || String(e.target.value).length === 0) && setFieldValue('tax_deducted[0]', e.target.value);
                                      disabled5Part(setFieldValue);
                                    }}
                                  />
                                  {errors && errors.tax_deducted && errors.tax_deducted[0] ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.tax_deducted[0]}
                                    </span> : null}
                                </div>
                              </div>

                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    className={`form-control font-control-input ${errors?.pan_no?.[1] ? 'input-error1' : ''}`}
                                    id="pan_no[1]"
                                    placeholder="Enter PAN"
                                    name="pan_no[1]"
                                    maxLength={10}
                                    value={values.pan_no[1]}
                                    onChange={(e) => {
                                      e.target.value.match(/^([0-9A-Za-z]{0,10})$/) && setFieldValue('pan_no[1]', e.target.value);
                                      disabled5Part(setFieldValue);
                                    }}
                                  />
                                  {errors && errors.pan_no && errors.pan_no[1] ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.pan_no[1]}
                                    </span> : null}

                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    className={`form-control font-control-input ${errors?.tax_deducted?.[1] ? 'input-error1' : ''}`}
                                    id="tax_deducted[1]"
                                    placeholder="Enter Amount"
                                    name="tax_deducted[1]"
                                    value={values.tax_deducted[1]}
                                    onChange={(e) => {
                                      e.target.value.match(/^([0-9.]{0,14})$/) && (Number(e.target.value) || String(e.target.value).length === 0) && setFieldValue('tax_deducted[1]', e.target.value);
                                      disabled5Part(setFieldValue);
                                    }}
                                  />
                                  {errors && errors.tax_deducted && errors.tax_deducted[1] ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.tax_deducted[1]}
                                    </span> : null}
                                </div>
                              </div>

                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    className={`form-control font-control-input ${errors?.pan_no?.[2] ? 'input-error1' : ''}`}
                                    id="pan_no[2]"
                                    placeholder="Enter PAN"
                                    name="pan_no[2]"
                                    value={values.pan_no[2]}
                                    maxLength={10}
                                    onChange={(e) => {
                                      e.target.value.match(/^([0-9A-Za-z]{0,10})$/) && setFieldValue('pan_no[2]', e.target.value);
                                      disabled5Part(setFieldValue);
                                    }}
                                  />
                                  {errors && errors.pan_no && errors.pan_no[2] ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.pan_no[2]}
                                    </span> : null}

                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    className={`form-control font-control-input ${errors?.tax_deducted?.[2] ? 'input-error1' : ''}`}
                                    id="tax_deducted[2]"
                                    placeholder="Enter Amount"
                                    name="tax_deducted[2]"
                                    value={values.tax_deducted[2]}
                                    onChange={(e) => {
                                      e.target.value.match(/^([0-9.]{0,14})$/) && (Number(e.target.value) || String(e.target.value).length === 0) && setFieldValue('tax_deducted[2]', e.target.value);
                                      disabled5Part(setFieldValue);
                                    }}
                                  />
                                  {errors && errors.tax_deducted && errors.tax_deducted[2] ? <span className="error_message">
                                    <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                    {errors.tax_deducted[2]}
                                    </span> : null}
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="newrretorscet"><h4>OR</h4></div>
                          </div>
                          <div id="nil2_pan_container" className={part5Disabled ? 'col-md-5 opacityDiv' : 'col-md-5'}
                            onClick={() => {
                              disabled4Part(setFieldValue);
                              setFieldValue('nil_transaction', "");
                            }}
                          >
                            <p className="newrretslecnilltextsect">
                              Select No Valid PAN in case, no valid PAN exists, for the
                              challan entered above for the selected return
                            </p>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-check-inline newrretcheckinlinenillsect">
                                  <label className="form-check-label">
                                    <Field
                                      className="form-check-input checklebtopalignnill"
                                      style={{ verticalAlign: 'middle' }}
                                      type="checkbox"
                                      name="nil_transaction"
                                      id="nil_transaction"
                                      value="1"
                                      checked={values.nil_transaction === '1'}
                                      onChange={(e) => {
                                        setFieldValue('nil_transaction', e.target.value);
                                        setFieldValue('form2input', '0', false);
                                      }}
                                      onClick={() => {
                                        disabled4Part(setFieldValue);
                                      }}
                                    />
                                    NIL Transaction
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </>
                  ) : null}

                {importFUVFile && afterReturnFill
                  ? (
                    <fieldset className="newrretdeduteetransfieldset">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="incasesection">
                            Click here to
                            {' '}
                            <span className="link"
                              onClick={() => {
                                setImportFUVFileData(values);
                                setShowImportFUVFile(true);
                              }}
                            >
                              {' '}
                              Browse
                            </span>
                            {' '}
                            a FUV/txt File
                          </p>
                        </div>
                      </div>
                    </fieldset>
                  )
                  : null}
                <div className="row">
                  {!afterReturnFill ? (
                    <div className="col-md-3 text-center pt-2">
                      <Button className="btn btn-default recaptchaprocbtn" id="autoTriggeronissue"
                        onClick={() => {
                          submit1Form();
                          disabledDivSubmit();
                        }}>
                        Proceed
                      </Button>
                      <Button className="btn btn-default recaptchaclsbtn" onClick={onClose}>
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <div className="col-md-3 text-center pt-2">
                      <Button className="btn btn-default recaptchaprocbtn" onClick={() => submit2Form()}>
                        Proceed
                      </Button>
                      <Button className="btn btn-default recaptchaclsbtn" onClick={onClose}>
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>

  );
};
export default CreateRaiseRequestFormPopup;
