import { useEffect } from "react";
import * as XLSX from "xlsx";
import { HEADER_CONNECTOR_DIGEST_KEY, CONNECTOR_ERRORS_MAPPING } from "../../app/constants";
import { getPanListToVerifyAtTracesAndInsightsViaConnector } from "../../api/serverConnectorAPI";
import { verifyPANandCheckCompliance } from "../../api/localConnectorAPI";


const DeducteeListV2MultiplePanVerify = (props) => {

    const { deductorId, port, captchaRef, setSuccess, setError, setLoading,
        setBulkPanVerificationStatusData, statusKey, panArray, setStartMultiPanVerification } = props;


    let wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "PAN_To_Connector",
        Subject: "PAN_for_Verification",
        Author: "Express_TDS",
    };

    wb.SheetNames.push("PAN");
    let newPanArray = [["PAN"]];
    if (panArray && panArray?.length > 0 && panArray?.forEach((item) => {
        let localArr = [];
        localArr.push(item)
        newPanArray.push(localArr)
    }));
    let ws_data = [...newPanArray];
    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["PAN"] = ws;

    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    function s2ab(s) {

        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;

    }

    const fetchTracesCredentials = (deductorId, captchaRef, port, statusKey) => {

        async function fetchEverything() {
            try {
                setLoading(true);

                const payload = {
                    'deductor_id': deductorId,
                    'action_type': 'VERIFY_BULK_PAN',
                    'traces_captcha_ref': captchaRef
                }
                const result = await getPanListToVerifyAtTracesAndInsightsViaConnector(payload);

                const responseHeader = result?.headers ?? null;

                try {
                    setLoading(true);
                    const dataKey = result?.data?.data ?? '';
                    const formData = new FormData();
                    formData.append('pan_file', new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'test.xlsx');
                    formData.append('data', dataKey);

                    let extraHeader = {};
                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                    const connectorResponse = await verifyPANandCheckCompliance(port, formData, extraHeader);

                    if (Number(connectorResponse.status) === 1) {
                        //set local storage key to get PAN verify status data
                        const statusData = { 'captcha_ref': captchaRef, 'port': port };
                        localStorage.setItem(statusKey, JSON.stringify(statusData));
                        setBulkPanVerificationStatusData(statusData);
                        setSuccess("Your Bulk PAN Verification process has started successfully. You may monitor the status of the process at the bottom of the screen. Please ensure that the Connector service keeps running, and the system is not shut down.");
                    }

                } catch (err) {

                    const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default'];
                    setError(errmsg);
                }

            } catch (err) {
                console.error('error: ', err);

            } finally {
                setLoading(false);
                setStartMultiPanVerification(false);
            }

        }

        fetchEverything();
    }

    useEffect(() => {
        fetchTracesCredentials(deductorId, captchaRef, port, statusKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deductorId, captchaRef, port]);


    return (null)

};

export default DeducteeListV2MultiplePanVerify;