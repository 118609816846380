/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
// import FileUploadPopup from './FileUploadPopup';
import ImportSalaryUsingExcel from './ImportSalaryUsingExcel';
import ImportReturnProgress from './ImportReturnProgress';
import { selectHeaderData } from '../../header/headerSlice';
import { useSelector } from 'react-redux';
import { createImportRegularReturnLocalStorageKey } from '../../../utils/UtilityFunctions';

const ImportReturnFromExcel = ({ history, ...props }) => {
  const {fetchTransactionOverView,
    show, handleClose, deductorId, returnId, importExelError, showImportReturnFromTxt, downloadExcelTemp = null
  } = props;

  const headerData = useSelector(selectHeaderData);

  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showResult, setShowResult] = useState(false);


  useEffect(() => {
    if (headerData?.mobile_number && headerData?.tan && headerData?.financial_year_formatted && headerData?.quarter && headerData?.form_type) {
      const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
      if (checkRunningImport) {
        setShowResult(true);
      } else {
        setShowFileUpload(true);

      }
    }
  }, [headerData])

  useEffect(() => {
    if (headerData?.mobile_number && headerData?.tan && headerData?.financial_year_formatted && headerData?.quarter && headerData?.form_type && show) {
      const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
      if (checkRunningImport) {
        setShowResult(true);
      } else {
        setShowFileUpload(true);

      }
    }
  }, [show, headerData]);


  if (showResult) {
    return (
      <>
        <ImportReturnProgress
          show={showResult}
          handleClose={() => {
            setShowResult(false);
            handleClose();
          }}
          deductorId={deductorId}
          returnId={returnId}
          headerData={headerData}
          fetchTransactionOverView={fetchTransactionOverView}
        />
      </>
    )

  }


  else if (show) {
    return (
      <>
        {showFileUpload && (
          <ImportSalaryUsingExcel           //File Upload popup component is not used now due to UI changes
            key="1"
            handleClose={() => {
              handleClose();
            }}
            handleProceed={() => {
              setShowFileUpload(false);
              setShowResult(true);
             
            }}
            deductorId={deductorId}
            returnId={returnId}
            importExelError={importExelError}
            showImportReturnFromTxt={showImportReturnFromTxt}
            downloadExcelTemp={downloadExcelTemp}
          />
        )}
      </>
    );
  }

  return null;
};

export default ImportReturnFromExcel;
