/* eslint-disable react/prop-types */
import React from 'react';
import FileUploadPopup from './FileUploadPopup';

const ImportDeductee = (props) => {
  const { handleClose, deductorId, handleProceed } = props;

  return (
    <>
      <FileUploadPopup
        show={true}
        deductorId={deductorId}
        handleClose={() => {
          handleClose();
        }}
        handleProceed={(res) => {
          if (handleProceed) {
            handleProceed();
          }

          handleClose(res);
        }}
      />
    </>
  );
};

export default ImportDeductee;
