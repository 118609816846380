import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import CreateChallanForm from '../../components/createChallanForm/CreateChallanForm';
import { challanFormFields } from '../../components/createChallanForm/ChallanFormHelper';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { updateChallan, getChallanDetail, getCorrectionChallanDetail, updateCorrectionChallan } from '../../api/challanAPI';

const EditChallan = (props) => {
  const {
    onClose, deductorId, challanId, returnId, componentImport, updateChallanInfo, challanList, currentChallan, setCurrentChallan, horizontalView
  } = props;

  const location = useLocation();
  const params = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [challanInfo, setChallanInfo] = useState(challanFormFields);
  const [dataLoaded, setDataLoaded] = useState(false);

  const createChallan = (data, closeForm = true) => {
    setError(null);
    setSuccess(null);

    data.deductor_id = deductorId;
    data.challan_id = challanId;

    if (params.return_id) {
      data.return_id = params.return_id;
    }

    async function callAPI() {
      try {
        const result = componentImport ? await updateCorrectionChallan(data) : await updateChallan(data);
        setSuccess(result.message);
        if (closeForm) {
          onClose(true, result.message);
        }
        return true;
      } catch (err) {
        console.error('error: ', err);
        setError(err.message);
        return false;
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    return callAPI();
  };

  useEffect(() => {
    async function callAPI() {
      try {
        const result = componentImport ? await getCorrectionChallanDetail(deductorId, challanId, params.return_id) : await getChallanDetail(deductorId, challanId, returnId);

        const challanData = componentImport ? result.data : result.data.challan;
        challanData.bsr_code = componentImport ? result.data.bsr_code : result.data.bsr_data.bsr_code;
        if (!componentImport) {
          challanData.nature_of_payment_id = result.data.nature_of_payment.code;
        }
        challanData.type_of_payment = !challanData.type_of_payment ? '' : challanData.type_of_payment;
        setChallanInfo((prev) => {
          const newObject = { ...prev, ...challanData }
          return newObject
        });
        setDataLoaded(true);
      } catch (err) {
        setError(err.message);
        console.error('error: ', err);
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    callAPI();
  }, [challanId, componentImport, deductorId, params.return_id, returnId]);

  return (

    <div>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {dataLoaded ? (
        <CreateChallanForm
          challanInfo={challanInfo}
          onClose={() => onClose()}
          createChallan={createChallan}
          deductorId={deductorId}
          challanId={challanId}
          componentImport={componentImport}
          updateChallanInfo={updateChallanInfo}
          challanList={challanList}
          currentChallan={currentChallan}
          setCurrentChallan={setCurrentChallan}
          horizontalView={horizontalView}
        />
      ) : null}

    </div>

  );
};

export default EditChallan;
