/*eslint-disable no-useless-escape */
import React from 'react';

const nPattern = new RegExp(/^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/);

const NumericInputWithDecimalFour = (props) => {
  const { value, onChange, ...rest } = props;
  return (
    <input
      value={value}
      onChange={(v) => {
        if (nPattern.test(v.target.value) || v.target.value === '') {
          onChange(v);
        }
      }}
      onKeyUp={(v) => v.target.value}
      {...rest}
    />
  );
};

export default NumericInputWithDecimalFour;
