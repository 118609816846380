import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import ReturnFlowTimeLine from '../deducteeTransactions/ReturnFlowTimeLine';
import TDSComputation from './returnHealth/TDSComputation';
import LowerDeductionCert from './returnHealth/LowerDeductionCert';
import ShortDeductionCert from './returnHealth/ShortDeductionCert';
import LateDeductionNotes from './returnHealth/LateDeductionNotes';
import PanSummary from './returnHealth/PanSummary';
import PanComplianceCheck206 from './returnHealth/PanComplianceCheck206';
import ComplianceCheckChart from './returnHealth/ComplianceCheckChart';
import ChallanWiseSummary from './returnHealth/ChallanWiseSummary';
import SectionWiseSummaryChart from './returnHealth/SectionWiseSummaryChart';
import EditDeductorDetails from './returnHealth/EditDeductorDetails';
import { exportRegularHealthReport, gocallApi } from '../../api/issueCertificateAPI';
import { PIECHART_LIST, COMPLIANCE_PIECHART_LIST } from '../../app/constants';
import ShowAlert from '../../components/ShowAlert';
import { convertNum } from '../../utils/UtilityFunctions';
import LowerDeductionCertPopup from './returnHealth/LowerDeductionCertPopup';
import { selectHeaderData } from '../header/headerSlice';
import { getCurrentFinancialYear } from '../../utils/UtilityFunctions';
import EditDeducteeTransactionsV2 from '../deducteeTransactions/EditDeducteeTransactionsV2';
import { getDeducteeTransactionDetail } from '../../api/deducteeTransactionsAPI';
import ConsumptionTablePopup from './returnHealth/ConsumptionTablePopup';
import DownloadFile from '../../components/DownloadFile';
import { Spinner } from 'react-bootstrap';
import './TDSComputation.css';
import './ReturnFilingSecondStepV2.css';


export const PAN_SUMMARY_LIST = {
  cnt_inactive_pan: 0,
  cnt_valid_pan: 0,
  cnt_traces_invalid_pan: 0,
  cnt_unverified_pan: 0,
  cnt_not_available_pan: 0,
}

export const COMPLIANCE_STATUS_LIST = {
  cnt_insights_regular_filer_operative: 0,
  cnt_insights_regular_filer_inoperative: 0,
  cnt_insights_non_filer_operative: 0,
  cnt_insights_non_filer_inoperative: 0,
  cnt_insights_pending: 0,
  cnt_traces_invalid_pan: 0,
  cnt_unverified_filer_pan: 0
}

export const COMPLIANCE_STATUS_COLOR = ['#FF0044', '#00A05C', '#5D55FF', '#00E9CC', '#FF6157', '#4E32B5'];

const ReturnFilingSecondStepV2 = (history) => {

  const challanWiseSummaryRef = useRef();
  const sectionWiseSummaryRef = useRef();
  const headerData = useSelector(selectHeaderData);

  const location = useLocation();
  //const searchStr = location.search;
  const params = queryString.parse(location.search);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [chartDataValue, setChartDataValue] = useState([]);
  const [chartDataLabel, setChartDataLabel] = useState([]);
  const [chartDataColor, setChartDataColor] = useState(COMPLIANCE_STATUS_COLOR);
  //const [panStatusData, setPanStatusData] = useState(PAN_SUMMARY_LIST);
  const [complianceStatusData, setComplianceStatusData] = useState(COMPLIANCE_STATUS_LIST);
  const [skeletonChartLoading, setSkeletonChartLoading] = useState(true);
  const [panSummaryData, setPanSummaryData] = useState({});
  const [shortDeductionSummaryData, setShortDeductionSummaryData] = useState(null);

  const [tdsSummaryData, setTdsSummaryData] = useState(null);
  const [showLowerDeductionPopup, setShowLowerDeductionPopup] = useState(false);
  const [healthSummaryData, setHealthSummaryData] = useState(null);
  const finanicalYear = headerData?.financial_year_formatted ? headerData?.financial_year_formatted?.split("-")?.[0] : getCurrentFinancialYear();
  const [ldcSummary, setLdcSummary] = useState({})
  const [ldcListData, setLdcListData] = useState([])
  const [showSkeleton, setShowSkeleton] = useState(true);

  const panStatusData = PAN_SUMMARY_LIST;
  const [formType, setFormType] = useState('');
  const [showType, setShowtype] = useState('ALL');
  const [showEditForm, setShowEditForm] = useState(false);
  const [deducteeTrnsactnInfo, setDeducteeTrnsactnInfo] = useState({});
  const [deducteeTransactionID, setDeducteeTransactionID] = useState(null);

  const [showCertList, setShowCertList] = useState(false);
  const [certData, setCertData] = useState(null);

  const [downloadExcelFile, setDownloadExcelFile] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadReturnId, setDownloadReturnId] = useState(false);

  const onConsumptionTableClose = () => {
    setCertData(null);
    setShowCertList(false);
  }

  const showConsumptionTable = (certData) => {
    setCertData(certData);
    setShowCertList(true);
  }

  const getLdcDataForReturn = async (deductor_Id, returnId) => {

    const url = `/api/v1/report/health/ldc/list?deductor_id=${deductor_Id}&return_id=${returnId}`;

    try {
      const result = await gocallApi("get", url);
      const data = result?.data ?? {};
      setLdcSummary(data?.summary)
      setLdcListData(data?.list ?? [])
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(err);
    } finally {
      setShowSkeleton(false);
    }
  }

  const getPanStatusDeducteeWiseSummaryData = async (deductor_Id, returnId) => {
    const url = `/api/v1/report/health/summary?deductor_id=${deductor_Id}&return_id=${returnId}`;

    try {
      const result = await gocallApi("get", url);

      let creatChartValue = [['Task', 'Compliance Check summary']];
      let creatChartLabel = [];

      const data = result?.data ?? {};

      const panSummary = data?.pan_summary;
      const shortDeductionSummary = data?.short_deduction_summary;
      const tdsCommputaionSummary = data?.tds_computation_summary;
      //PAN Summary Data
      if (panSummary) {
        const cnt_ntavil = convertNum(panSummary.cnt_applied_pan) +
          convertNum(panSummary.cnt_not_available_pan) +
          convertNum(panSummary.cnt_invalid_pan) +
          convertNum(panSummary.cnt_traces_applied_pan) +
          convertNum(panSummary.cnt_traces_not_available_pan);

        Object.keys(PIECHART_LIST).forEach((key) => {

          if (["cnt_applied_pan", "cnt_not_available_pan"].includes(key)) return;

          panStatusData[key] = panSummary?.[key] ?? 0;
        })

        panStatusData.cnt_not_available_pan = cnt_ntavil;


        Object.keys(COMPLIANCE_PIECHART_LIST).forEach((key) => {

          creatChartValue.push([COMPLIANCE_PIECHART_LIST[key], panSummary?.[key] ?? 0])
          creatChartLabel.push(COMPLIANCE_PIECHART_LIST[key])

          // complianceStatusData[key] = panSummary?.[key];

        })

      }

      setHealthSummaryData(data);
      setChartDataValue([...creatChartValue])
      setChartDataLabel([...creatChartLabel])
      setChartDataColor([...COMPLIANCE_STATUS_COLOR])

      setPanSummaryData((prev) => {
        prev = panStatusData
        return { ...prev }
      });

      // setComplianceStatusData((prev) => {
      //   prev = complianceStatusData
      //   return { ...prev }
      // });
      setComplianceStatusData(panSummary)

      setShortDeductionSummaryData(shortDeductionSummary);
      setTdsSummaryData(tdsCommputaionSummary);


    } catch (err) {
      if (err.message) setError(err.message);
      else setError(err);
    } finally {
      setSkeletonChartLoading(false);
    }
  }



  useEffect(() => {
    getPanStatusDeducteeWiseSummaryData(params?.deductor_id, params.return_id)
    getLdcDataForReturn(params?.deductor_id, params.return_id)
    // eslint-disable-next-line
  }, [params?.deductor_id, params.return_id])


  useEffect(() => {
    setFormType(headerData?.form_type);
  }, [headerData?.form_type])

  const onClose = () => {
    setShowLowerDeductionPopup(false);
  }

  const handleLDCPopupShow = (showType) => {
    setShowtype(showType);
    setShowLowerDeductionPopup(true);
  }

  const updateDeducteeInfos = (deductee_transaction_id) => {
    async function getDeducteeTrnsactionDetail() {
      async function getDetail() {
        const result = await getDeducteeTransactionDetail(`?deductee_transaction_id=${deductee_transaction_id}&deductor_id=${params.deductor_id}&return_id=${params.return_id}`);
        setDeducteeTrnsactnInfo((prev) => {
          prev = result
          return { ...prev }
        });
        setDeducteeTransactionID(deductee_transaction_id);
        setShowEditForm(true);
        //if (result?.is_child || result.is_parent) setConfirmPopup(true);
      }
      try {
        await Promise.all([
          getDetail(),
        ]);
      } catch (err) {
        console.error('error: ', err);
      }
    }
    getDeducteeTrnsactionDetail();
  };

  const onFormClose = () => {
    setShowEditForm(false);
    setDeducteeTransactionID(null);     
  };

  const onDTSave = () => {  
    setShowSkeleton(true);
    getLdcDataForReturn(params?.deductor_id, params.return_id);     
  }

  const exportReturn = (return_id) => {
    setDownloadReturnId(return_id)
    setDownloadExcelFile(true);
}

  return (
    <>
     
     {downloadExcelFile && downloadReturnId !== null &&
                <DownloadFile
                    apiFunction={exportRegularHealthReport}
                    setDownloadLoading={setDownloadLoading}
                    setError={setError}
                    fileName={'ExpressTDS_Computation_and_Health_report'}
                    setDownloadExcelFile={setDownloadExcelFile}
                    sessionStorageKey={`export-health-report-ref-key-${params.deductor_id}-${downloadReturnId}`}
                    params={`?deductor_id=${params.deductor_id}&return_id=${downloadReturnId}`}
                    handleSuccessProceed={() => {
                        setDownloadExcelFile(false);
                        setDownloadReturnId(null);
                    }}
                    handleErrorProceed={(msg) => {
                        setDownloadExcelFile(false);
                        setDownloadReturnId(null);
                    }}
                />
            }

      {
        showEditForm &&
        <EditDeducteeTransactionsV2
          onClose={() => onFormClose()}
          deductorId={params.deductor_id}
          returnId={params.return_id}
          deducteeTrnsactnInfo={deducteeTrnsactnInfo}
          deducteeTransactionID={deducteeTransactionID}
          headerDataDetails={headerData}                
          currentDeductee={0} 
          show={showEditForm}
          setShowEditForm={setShowEditForm}
          formType={formType}
          onDTSave={onDTSave}
          ldcListStatus={true}
        />
      }

      {
        showCertList &&
        <ConsumptionTablePopup
          returnId={params.return_id}
          deductorId={params.deductor_id}
          finanicalYear={finanicalYear}
          onClose={onConsumptionTableClose}
          certData={certData}
          setError={setError}
        />
      }

      {showLowerDeductionPopup &&
        <LowerDeductionCertPopup
          returnId={params.return_id}
          deductorId={params.deductor_id}
          onClose={onClose}
          ldcListData={ldcListData}
          getLdcDataForReturn={getLdcDataForReturn}
          showType={showType}
          handleEditTransaction={(id) => {
            updateDeducteeInfos(id);
          }}
          showConsumptionTable={(certData) => {
            showConsumptionTable(certData);
          }}
          showSkeleton={showSkeleton}
        />
      }

      <div className="step2">
        <div className="container-fluid">
          <ReturnFlowTimeLine returnId={params.return_id} deductorId={params.deductor_id} currentPage={`ComputationHealth`} />
        </div>
      </div>
      <div className="col-md-12 health_reportbtndwn" >
        {downloadLoading ?
          <button className="btn btn-default comp_health_returnsummary_paybtn health_spinner" >
            <span className="pr-2">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </span>
            Download
          </button>
          :
          <button className="btn btn-default comp_health_returnsummary_paybtn"
            onClick={() => {
              exportReturn(params.return_id);
            }}
          >
            Download
          </button>}
      </div>
      <div className='container-fluid mb-2'>

        <EditDeductorDetails returnId={params.return_id} deductorId={params.deductor_id} tdsComputationSummary={tdsSummaryData} />

        <ShowAlert
          error={error}
          success={success}
          onClose={() => { setError(null); setSuccess(null) }}
        />

        <div class="row">
          <TDSComputation returnId={params.return_id} deductorId={params.deductor_id} tdsComputationSummary={tdsSummaryData} isSkeleton={skeletonChartLoading} formType={formType} />
          <LateDeductionNotes returnId={params.return_id} deductorId={params.deductor_id} setError={setError} healthSummaryData={healthSummaryData} formType={formType} />
        </div>

        <div class="row">
          <div class="col-xl-7 col-lg-12 col-md-12 col-xs-12">
            {
              formType === '24Q'
                ? <>

                  <div class="row">
                    <div class="col-md-4 pr-2">
                      <LowerDeductionCert returnId={params.return_id} deductorId={params.deductor_id} handleLDCPopupShow={handleLDCPopupShow} ldcSummary={ldcSummary} formType={formType} showSkeleton={showSkeleton} getLdcDataForReturn={getLdcDataForReturn} />
                    </div>
                    <div class="col-md-4 px-2">
                      <PanSummary returnId={params.return_id} deductorId={params.deductor_id} panSummaryData={panSummaryData} skeletonLoading={skeletonChartLoading} formType={formType} />
                    </div>
                    <div class="col-md-4 pl-2">
                      <PanComplianceCheck206 returnId={params.return_id} deductorId={params.deductor_id} complianceStatusData={complianceStatusData} skeletonLoading={skeletonChartLoading} formType={formType} />
                    </div>
                  </div>

                </>
                :
                <>
                  <div class="row">
                    <LowerDeductionCert returnId={params.return_id} deductorId={params.deductor_id} handleLDCPopupShow={handleLDCPopupShow} ldcSummary={ldcSummary} formType={formType} showSkeleton={showSkeleton} setShowtype={setShowtype} getLdcDataForReturn={getLdcDataForReturn} />
                    <ShortDeductionCert returnId={params.return_id} deductorId={params.deductor_id} shortDeductionSummaryData={shortDeductionSummaryData} ldcSummary={ldcSummary} formType={formType} />
                  </div>
                  <div class="row">
                    <PanSummary returnId={params.return_id} deductorId={params.deductor_id} panSummaryData={panSummaryData} skeletonLoading={skeletonChartLoading} formType={formType} />
                    <PanComplianceCheck206 returnId={params.return_id} deductorId={params.deductor_id} complianceStatusData={complianceStatusData} skeletonLoading={skeletonChartLoading} formType={formType} />
                  </div>
                </>
            }
          </div>

          <div class="col-xl-5 col-lg-12 col-md-12 col-xs-12">
            <ComplianceCheckChart
              returnId={params.return_id}
              deductorId={params.deductor_id}
              chartDataValue={chartDataValue}
              chartDataLabel={chartDataLabel}
              chartDataColor={chartDataColor}
              skeletonChartLoading={skeletonChartLoading}
              finanicalYear={finanicalYear}
              formType={formType}
            />
          </div>
        </div>

        <div class="row mb-3 mt-2">
          <ChallanWiseSummary returnId={params.return_id} deductorId={params.deductor_id} challanWiseSummaryRef={challanWiseSummaryRef} formType={formType} />
          <SectionWiseSummaryChart returnId={params.return_id} deductorId={params.deductor_id} sectionWiseSummaryRef={sectionWiseSummaryRef} formType={formType} />
        </div>


      </div>

    </>

  );
};

export default ReturnFilingSecondStepV2;
