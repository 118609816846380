import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import gstplanImg from '../../images/gstplan_img.png';
import { selectHeaderData } from "../header/headerSlice";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { useSelector } from 'react-redux';
import './Plans.css';

const TabbedPlanTable = ({ history, ...props }) => {

    let { plans, setShowPlanBilling, isPlansLoading, setVisible } = props;
    const headerdata = useSelector(selectHeaderData);
    const DEFAULT_IMAGE_PURPLE = 'https://express-v2.s3.ap-south-1.amazonaws.com/icon_img/diamond_purple.gif';
    const DEFAULT_IMAGE_BLUEGREEN = 'https://express-v2.s3.ap-south-1.amazonaws.com/icon_img/diamond_bluegreen.gif';

    const [tabs, setTabs] = useState([]);
    const [selectTabPlan, setSelectTabPlan] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const plans_div_width = Math.floor(Math.max(16, Math.min(20, 100 / (selectTabPlan.length + 1))));

    useEffect(() => {
        if (plans.length > 0) {
            let newplan = plans?.[0]?.plans ?? [];
            setSelectTabPlan([...newplan]);
            setTabs([...plans]);
        }

    }, [plans])

    return (
        <div className="row">
            {isPlansLoading ? (
                <LoadingIndicator />
            ) :
                (<>
                    <div className="col-md-12">
                        {tabs?.length > 0 && <div class="col-md-12 text-center tabsPlan_tables">
                            <div class="d-flex justify-content-center plantab_bgclr">
                                <nav class="gstprofessional_plan_navtab mb-1">
                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                        {tabs.map((item, i) => {
                                            return (
                                                <a class={`nav-item nav-link gstprofessional_plan_tab ${tabIndex === i ? "active" : ""}`}
                                                    id="nav-home-tab" data-toggle="tab" onClick={() => {
                                                        setTabIndex(i);
                                                        let selectTabData = tabs[i].plans ?? [];
                                                        setSelectTabPlan([...selectTabData]);
                                                    }}
                                                    href="#gstprofessional_plan_tab01" role="tab" aria-controls="nav-home" aria-selected="true">
                                                    {item.sub_product ?? ""}
                                                </a>
                                            )
                                        })}


                                    </div>
                                </nav>
                            </div>
                        </div>}
                        <div className=" d-flex justify-content-center gstplanbox_disp">
                            {selectTabPlan.length > 0 && <div className="prof4colcolumns " style={{ width: plans_div_width + "%" }}>
                                <ul className="price">
                                    <li className="typepricecol">
                                        <img src={gstplanImg} height="157" alt="img" />
                                    </li>
                                    {selectTabPlan.length && selectTabPlan[0].params.map((param) => {
                                        return (<li className="planparam">{param.display_text}</li>)
                                    })}

                                    <li className="planpricetextclr py-3">Price</li>
                                </ul>
                            </div>}

                             {selectTabPlan?.length > 0 && selectTabPlan.map((plan, index) => {
                                return (
                                    <>
                                        <div className="prof4colcolumns" style={{ width: plans_div_width + "%" }}>
                                            <ul className="price">
                                                <li className={(parseInt(index) + 1) % 2 === 0 ? "headertopbox plantinumbox" : "headertopbox goldbox"}>
                                                    <div className="catgsilvericon">
                                                        <img src={
                                                            plan.graphics_value
                                                                ? plan.graphics_value
                                                                : (parseInt(index) + 1) % 2 === 0 ? DEFAULT_IMAGE_BLUEGREEN : DEFAULT_IMAGE_PURPLE
                                                        }
                                                            alt={plan.plan_name} width="80" />
                                                    </div>
                                                    <button className="btn btn-default pricetypgoldbtn silvertextclr">
                                                        {plan.plan_name}
                                                    </button>

                                                    <div className={(parseInt(index) + 1) % 2 === 0 ? "headerbottonborderpaltbox" : "headerbottonborderbox"}></div>
                                                </li>

                                                {plan.params.map((param, index2) => {
                                                    if (parseInt(index2 + 1) % 2 === 0) {
                                                        return (<li className="bootmbordernum">{param.value}</li>)
                                                    }
                                                    else if (index2 === 0) {
                                                        return (<li className={(parseInt(index) + 1) % 2 === 0 ? "firstparam sliver_platbgclr" : "firstparam gold_dainmbgclr"}> {param.value}</li>)
                                                    }
                                                    else {
                                                        return (<li className={(parseInt(index) + 1) % 2 === 0 ? "sliver_platbgclr" : "gold_dainmbgclr"}> {param.value}</li>)
                                                    }
                                                })}
                                                {plan.params.length === 0 && <li className={(parseInt(index) + 1) % 2 === 0 ? "sliver_platbgclr" : "gold_dainmbgclr"} style={{ marginTop: "15px" }}>-</li>

                                                }


                                                <li className="py-3">
                                                    <span className="distextclr">
                                                        <FontAwesomeIcon icon={faRupeeSign} />
                                                    </span>
                                                    <span className="pricetextfs">{plan.plan_amt}</span><sub><span className="distextclr" style={{ fontSize: '16px' }}>/{plan.plan_duration_txt}</span></sub>
                                                </li>
                                                <li><button onClick={() => { setShowPlanBilling(plan) }} className="btn btn-default Proceedbtntextclr">Select</button></li>
                                            </ul>
                                        </div>
                                    </>
                                )
                            })}

                            {headerdata?.organization_type !== "CA" && <div className="prof4colcolumns" style={{ width: plans_div_width + "%" }}>
                                <ul className="price" style={{ width: 100 + "%", boxShadow: "none", border: "none" }}>
                                    <li className="headertopbox goldbox">
                                        <div className="catgsilvericon">
                                            <img src={DEFAULT_IMAGE_PURPLE} alt="Enterprise Plan" width="80" />
                                        </div>
                                        <button className="btn btn-default pricetypgoldbtn silvertextclr" >
                                            {'ENTERPRISE PLAN'}
                                        </button>

                                        <div className={"headerbottonborderbox"}>

                                        </div>
                                    </li>


                                    <li className="headertopbox goldbox" style={{ marginTop: '1rem', height: '9rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <button onClick={() => { setVisible(true) }} className="btn btn-default Proceedbtntextclr">Talk to Us</button>
                                    </li>
                                </ul>
                            </div>}
                        </div>


                    </div>
                    <div className="col-md-12 pt-2">
                        <p className="pricecontfs mb-0">Price is exclusive of Taxes.</p>
                    </div>

                    <div className="col-md-12 text-right">
                        <p className="tremcondtextfs"><sup>*</sup> Terms and conditions applicable. </p>
                    </div>
                </>)
            }

        </div>
    );
};

export default TabbedPlanTable;