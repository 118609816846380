/* eslint-disable eqeqeq */
/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

// -- Validation Rules
export const raiseRequestFormValidationSchema = Yup.object().shape({
  token: Yup.string()
    .min(15, 'PRN is invalid.')
    .required('PRN is required'),

  bsr_code: Yup.string()
    .when('form1input', {
      is: (val) => val === '1',
      then: Yup.string()
        .required('BSR Code is required')
        .matches(/[0-9]{7}$/, 'Invalid BSR Code'),
    }),

  bsr_code2: Yup.string()
    .when('form2input', {
      is: (val) => val === '1',
      then: Yup.string()
        .required('BSR Code is required')
        .matches(/[0-9]{7}$/, 'Invalid BSR Code'),
    }),
  challan_no: Yup.number()
    .when('form1input', {
      is: (val) => val === '1',
      then: Yup.number()
        .min(1, 'Too Short!')
        .max(99999, 'Too Long!')
        .required('Challan Number is required'),
    }),

  challan_amount: Yup.number()
    .when('form1input', {
      is: (val) => val === '1',
      then: Yup.number()
        .min(0, 'Too Short!')
        .required('Challan Amount required'),
    }),

  deposit_date: Yup.string()
    .when('form1input', {
      is: (val) => val === '1',
      then: Yup.string().trim().required('Deposit date is required.'),
    }),

  deposit_date2: Yup.string()
    .when('form2input', {
      is: (val) => val === '1',
      then: Yup.string().trim().required('Deposit date is required.'),
    }),
  transfer_voucher_amount: Yup.number()
    .when('form2input', {
      is: (val) => val === '1',
      then: Yup.number()
        .required('Transfer Voucher Amount required'),
    }),

  government_deductor_ddo_number: Yup.string()
    .when('form2input', {
      is: (val) => val === '1',
      then: Yup.string().trim().required('Government Deductor DDO number is required.'),
    }),


  // pan_no: Yup.array()
  //   .of(
  //     Yup.string()
  //       .required('PAN Number is required')
  //       .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number'),
  //   ),

  // pan_no: Yup.array()
  // .of(
  //   Yup.string()
  //     .when('form4input', {
  //       is: (val) => val === '1',
  //       then: Yup.string()
  //         .required('PAN Number is required')
  //         .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number'),
  //     }),
  // ),

  // .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
  // .min(3, 'Minimum of 3 friends'),

  // tax_deducted: Yup.array()
  //   .of(
  //     Yup.string()
  //       .when('form4input', {
  //         is: (val) => val === '1',
  //         then: Yup.string().required('Tax Deducted Amount is required'),
  //       }),

  //   ),

  // tax_deducted: Yup.array()
  //   .of(
  //     Yup.string().required('Tax Deducted Amount is required'),
  //   ),
});
