import React, { useEffect, useState } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone, formatAccountsNumber } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";


const LateDeductionTable1 = (props) => {

    const { apiData } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);


    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    total: item[0],
                    total_int: item[1],
                    bal_int: item[2]
                })
            })
            return newData
        }
    };


    const dataStateChange = (dataState) => {

        if (dataState.filter || (dataState.sort && dataState?.sort?.length > 0)) {
            const filter_state = deepClone(dataState);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                            if (["gte", "lte", 'lt', 'gt', "neq"].includes(itemObj?.operator)) {
                                itemObj.value = Number(itemObj.value);
                            }
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                itemObj.value = itemObj.value?.[0] ?? "";
                            }
                        })
                    }
                })
            }
            const dataProcess = process(convertData(apiData), { ...filter_state });
            setDataState({ ...dataState });
            setData(dataProcess.data ?? []);

        } else {

            setDataState({});
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData(defaultData ?? []);
        }

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;


        return (
            <tr key={key} role="row">
                <td class="text-right">{formatAccountsNumber(props?.dataItem?.total ?? 0) ?? "-"}</td>
                <td class="text-right">{formatAccountsNumber(props?.dataItem?.total_int ?? 0) ?? "-"}</td>
                <td class="text-right">{formatAccountsNumber(props?.dataItem?.bal_int ?? 0) ?? "-"}</td>
            </tr>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);

    return (
        <fieldset className="exptds_justformtype_intcons_fs">
            <legend className="exptds_justformtype_intcons_leg">
                <h4 className="exptds_justformtype_intcons_title">
                    Interest Consumption Table Summary
                </h4>
            </legend>

            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                <Tooltip anchorElement="pointer" position="top">
                    <Grid
                        className="table table-striped exptds_justformtype_table mb-0"
                        style={{ maxHeight: '470px', overflowX: 'auto' }}
                        data={data}
                        {...dataState}

                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                        filterOperators={{
                            numeric: [
                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                            ],
                        }}
                        rowRender={rowRender}
                        sortable
                    >
                        <Column
                            field="total"
                            headerClassName={isColumnActive('total') ? 'active' : ''}
                            title={`Total Late Deduction Interest Computed
                            (For the complete statement)`}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="numeric"
                        />
                        <Column
                            field="total_int"
                            headerClassName={isColumnActive('total_int') ? 'active' : ''}
                            title={`Total Interest Consumed to the Extent Available`}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="numeric"
                        />
                        <Column
                            field="bal_int"
                            headerClassName={isColumnActive('bal_int') ? 'active' : ''}
                            title={`Balance Interest`}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="numeric"
                        />

                        <GridNoRecords>
                            No Record Found
                        </GridNoRecords>

                    </Grid>
                </Tooltip>

            </div>
        </fieldset>
    )

};


export default LateDeductionTable1;