/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Tooltip } from '@progress/kendo-react-tooltip';
import {
    CFG_TRACES_CONVERTER_FORM16_PARTA_URL,
    CFG_TRACES_CONVERTER_FORM16_PARTB_URL,
    CFG_TRACES_CONVERTER_FORM16A_URL,
} from '../../config';

const DownloadForm16 = ({ history, ...props }) => {
    const {
        show, handleClose, popupData, deductorId, downloadConso, pdfConversion
    } = props;

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="download-form-16-popup"
            >
                <Modal.Header closeButton className="mpoptextbor">
                    {popupData.request_for === 'Form16_Parta' ?
                        <Modal.Title>Download Form 16 Part A</Modal.Title>
                        :
                        popupData.request_for === 'Form16_Partb' ?
                            <Modal.Title>Download Form 16 Part B</Modal.Title>
                            :
                            <Modal.Title>Download Form 16A</Modal.Title>
                    }


                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-12 text-center pt-3 download_button">
                            <Tooltip anchorElement="target" position="top">
                                {!pdfConversion ?
                                    <p>
                                        PDF conversion in progress. Please wait for sometime. You can still download the TRACES zip file
                               </p> : null}
                                <button type="button" onClick={() => downloadConso(deductorId, false, popupData.raise_request_id, popupData)} className="btn btn-default downfr16btn mr-2">Download TRACES Zip</button>
                                <button type="button" onClick={() => downloadConso(deductorId, true, popupData.raise_request_id, popupData)} title="PDF conversion in progress. Please wait for sometime. You can still download the TRACES zip file" className={popupData.pdf_download ? "btn btn-default downfr16btn ml-2" : "btn btn-default downfr16btn disabled ml-2"}>Download converted PDF</button>
                            </Tooltip>
                        </div>


                        <div id="download_links">

                            < div className="col-md-12">
                                {popupData.request_for === 'Form16_Parta' ?
                                    <p className="clickheretext">
                                        <span>
                                            <a className="link" href={CFG_TRACES_CONVERTER_FORM16_PARTA_URL} > Click</a> here to download Form 16 Part A TRACES PDF Converter
								</span>
                                    </p>
                                    :
                                    popupData.request_for === 'Form16_Partb' ?
                                        <p className="clickheretext">
                                            <span>
                                                <a className="link" href={CFG_TRACES_CONVERTER_FORM16_PARTB_URL}>Click</a> here to download Form 16 Part B TRACES PDF Converter
								</span>
                                        </p>
                                        :
                                        <p className="clickheretext">
                                            <span>
                                                <a className="link" href={CFG_TRACES_CONVERTER_FORM16A_URL} > Click</a> here to download Form 16A TRACES PDF Converter
								</span>
                                        </p>
                                }

                            </div>

                        </div>

                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default DownloadForm16;
