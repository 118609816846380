import React from 'react'

const TaxCalculation = (props) => {
    const { data } = props
    return (

        <>
            {/* <!-- Tax Calculation --> */}
            {(data?.TaxOnTotalIncome) &&
                <div className="row pb-2"  >
                    <div className="col-md-12 pt-2">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <table className="table table-borderless expit_comp_personalinfo_salaryincome_tb mb-0 tr-add-hover">
                                <tbody>
                                    <tr className="expit_comp_personalinfo_salaryboxbgclr">
                                        <td width="750" colSpan="" className="expit_comp_personalinfo_salaryboxbgclr">Tax Calculation</td>
                                        <td width="100" className="text-right"></td>
                                        <td width="100" className="text-right"></td>
                                        <td width="100" className="text-right"></td>
                                        <td width="" className="text-right "></td>
                                    </tr>



                                    {/* {(data?.TaxOnTotalIncome?.TaxPayableUS115JB) && data?.TaxOnTotalIncome?.TaxPayableUS115JB?.Amount > 0 && <>

                                        <tr>
                                            <td colspan="" className=" expit_comp_personalinfo_textbold "> Tax Payable US 115JB</td>
                                            <td width=""></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width=""></td>
                                        </tr>


                                        {(data?.TaxOnTotalIncome?.TaxPayableUS115JB?.Tax ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  pl-4">Tax	</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableUS115JB?.Tax)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxPayableUS115JB?.Surcharge ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  pl-4">Add: Surcharge</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableUS115JB?.Surcharge)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxPayableUS115JB?.HEC ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  pl-4">Add: Health & Education Cess (HEC) @ 4.00%</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableUS115JB?.HEC)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                        {(data?.TaxOnTotalIncome?.TaxPayableUS115JB?.Amount ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  pl-4"> Total Tax Payable u/s 115JB</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableUS115JB?.Amount)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                    </>
                                    } */}

                                    {/* {(data?.TaxOnTotalIncome?.TaxPayableUS115JC) && data?.TaxOnTotalIncome?.TaxPayableUS115JC?.Amount > 0 && <>

                                        <tr>
                                            <td colspan="" className=" expit_comp_personalinfo_textbold "> Tax Payable US 115JC</td>
                                            <td width=""></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width=""></td>
                                        </tr>

                                        {(data?.TaxOnTotalIncome?.TaxPayableUS115JC?.Tax ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  pl-4">Tax	</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableUS115JC?.Tax)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxPayableUS115JC?.Surcharge ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  pl-4">Add: Surcharge</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableUS115JC?.Surcharge)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxPayableUS115JC?.HEC ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  pl-4">Add: Health & Education Cess (HEC) @ 4.00%</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableUS115JC?.HEC)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxPayableUS115JC?.Amount ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  pl-4"> Total Tax Payable u/s 115JC</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableUS115JC?.Amount)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                    </>
                                    } */}




                                    {(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions) && <>

                                        {data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates && <>
                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold "> Tax Normal Rates</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            {data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Items?.breakup && data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Items?.breakup.length > 0 &&
                                                data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Items?.breakup.map((otherItem, i) => {
                                                    return (<>

                                                        {otherItem?.income &&
                                                            <tr className="">
                                                                <td width="" className="pl-4">{`Tax @ ${otherItem?.rate} %	on Income ${(otherItem?.income)?.toLocaleString('hi-IN')}`} </td>
                                                                <td width="" className="text-right">{otherItem?.tax === 0 ? 'Nil' : (otherItem?.tax)?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                                <td width=""></td>

                                                            </tr>
                                                        }
                                                    </>)
                                                })
                                            }
                                        </>}

                                        {/* {data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates && <>
                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold "> Tax Special Rates</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            {data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Items?.breakup && data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Items?.breakup.length > 0 &&
                                                data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Items?.breakup.map((otherItem, i) => {
                                                    return (<>

                                                        {(otherItem?.income || otherItem?.tax || otherItem?.desc) &&
                                                            <tr className="">
                                                                <td width="" className="pl-4">Tax u/s {`${otherItem?.desc}`} {otherItem?.rate === 0 ? '' : ` @ ${otherItem?.rate}%`} {`on Income ₹${(otherItem?.income)?.toLocaleString('hi-IN')}`} </td>
                                                                <td width="" className="text-right">{otherItem?.tax === 0 ? 'Nil' : (otherItem?.tax)?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                    </>)
                                                })
                                            }

                                        </>} */}

                                        {/* {(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" ">Less: Rebate On Agriculture Income</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        } */}

                                        {(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxPayableOnTI ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" ">Tax Payable On Total Income</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxPayableOnTI)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                        {(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" ">Less: Rebate u/s 87A	</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                        {(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxPayableAfterRebate ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" ">Tax Payable After Rebate</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxPayableAfterRebate)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                        {data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge && <>
                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold ">Add: Surcharge</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            {data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Items?.breakup && data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Items?.breakup.length > 0 &&
                                                data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Items?.breakup.map((otherItem, i) => {
                                                    return (<>
                                                        {otherItem?.desc &&
                                                            <tr className="">
                                                                <td width="" className="pl-4">Surcharge {`${otherItem?.desc} of ₹${(otherItem?.tax)?.toLocaleString('hi-IN')}${otherItem?.mr > 0 ? ` (Marginal Relief: ₹${(otherItem?.mr)?.toLocaleString('hi-IN')})` : ''}`} </td>
                                                                <td width="" className="text-right">{otherItem?.sc === 0 ? 'Nil' : (otherItem?.sc)?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                    </>)
                                                })
                                            }

                                        </>}

                                        {(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" ">Add:-Health & Education Cess (HEC) @ 4.00%</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                        {(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className="  ">Gross Tax Liability</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                    </>}

                                    {/* {(data?.TaxOnTotalIncome?.GrossTaxPayableHigher ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold "> Gross Tax Payable </td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.GrossTaxPayableHigher)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {(data?.TaxOnTotalIncome?.TaxOnIncomeForEligibleStartUpUS80IAC ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold "> Tax On Income For Eligible Start Up u/s 80IAC</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxOnIncomeForEligibleStartUpUS80IAC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.TaxOnTotalIncome?.TaxDeferredRelatableToIncomeForEligibleStartUpUS80IAC ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold "> Tax Deferred Relatable To Income For Eligible Start Up u/s 80IAC</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxDeferredRelatableToIncomeForEligibleStartUpUS80IAC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.TaxOnTotalIncome?.CreditUS115JAAfromSchMATC ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold ">Less: Credit u/s 115JAA </td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.CreditUS115JAAfromSchMATC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.TaxOnTotalIncome?.CreditUS115JDfromSchAMTC ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold ">Less: Credit u/s 115JD</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.CreditUS115JDfromSchAMTC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {(data?.TaxOnTotalIncome?.TaxPayableAfterCreditMATorAMT ?? null) !== null && data?.TaxOnTotalIncome?.TaxPayableAfterCreditMATorAMT > 0 &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold "> Tax Payable After Credit </td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxPayableAfterCreditMATorAMT)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    } */}









                                    {(data?.TaxOnTotalIncome?.TaxReliefs) && data?.TaxOnTotalIncome?.TaxReliefs?.Amount > 0 && <>


                                        {(data?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold ">Less: Relief u/s 89(1) 	</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold ">Less: Relief u/s 90A 	</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold ">Less: Relief u/s 91 	</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                    </>
                                    }




                                    {(data?.TaxOnTotalIncome?.NetTaxLiability ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold "> Net Tax Liability</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.NetTaxLiability)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }






                                    {/* {(data?.TaxOnTotalIncome?.InterestAndFeePayable) && <>


                                        {(data?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold ">Add: Interest u/s 234 A</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold ">Add: Interest u/s 234 B</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold ">Add: Interest u/s 234 C</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold ">Add: Fee u/s 234F	</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }

                                    </>
                                    } */}





                                    {/* {(data?.TaxOnTotalIncome?.AggregateLiability ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold "> Aggregate Liability</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.AggregateLiability)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    } */}


                                    {/* {(data?.TaxOnTotalIncome?.TaxesPaid) && data?.TaxOnTotalIncome?.TaxesPaid?.Amount > 0 && <>

                                        {(data?.TaxOnTotalIncome?.TaxesPaid?.Amount ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold "> Taxes Paid</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxesPaid?.Amount)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold pl-4">Advance Tax</td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>

                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold pl-4">Total TDS on Salary </td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>

                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold pl-4">Total TDS on Income</td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>

                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold pl-4">TCS</td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxesPaid?.TCS)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>

                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                        }
                                        {(data?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? null) !== null &&

                                            <tr>
                                                <td colSpan="" className=" expit_comp_personalinfo_textbold pl-4">Self Assessment Tax</td>
                                                <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                                <td width=""></td>

                                            </tr>
                                        }

                                    </>
                                    } */}



                                    {/* {(data?.TaxOnTotalIncome?.AmountPayable ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold "> Amount Payable</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.AmountPayable)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    } */}





                                    {/* {(data?.TaxOnTotalIncome?.AmountPayableRounded288B ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_textbold ">Amount Payable Round off u/s 288B	</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.AmountPayableRounded288B)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.TaxOnTotalIncome?.SelfAssessmentPaidRounded288B ?? null) !== null &&

                                        <tr>
                                            <td width="" className="">Self Assessment Tax </td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.SelfAssessmentPaidRounded288B)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.TaxOnTotalIncome?.Refund ?? null) !== null &&

                                        <tr>
                                            <td width="" className="">Amount Refundable</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.Refund)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    } */}


                                    {/* {(data?.TaxOnTotalIncome?.RefundRounded288B ?? null) !== null &&

                                        <tr>
                                            <td colSpan="" className=" expit_comp_personalinfo_salarytotle_shadow "> Amount Refundable Rounded off u/s 288 B </td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.TaxOnTotalIncome?.RefundRounded288B)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {(data?.TaxOnTotalIncome?.Refund ?? null) === null && (data?.TaxOnTotalIncome?.AmountPayable ?? null) === null &&

                                        <tr>
                                            <td width="" className="">Amount Payable (Refundable)</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">0</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    } */}


                                </tbody>

                            </table>
                        </div>
                    </div>
                </div >
            }
        </>

    )
}

export default TaxCalculation