import React, { useEffect, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getInsightsProcessStatusFromConnector } from '../../../api/localConnectorAPI';


export default function ProgressBarForComplianceCheck(props) {

    const { complianceCheckStatusData, coplianceStatusKey, setReloadPanList, setError, setSuccess } = props;
    //const [data, setData] = useState(null);
    const timer = useRef(null);

    //Get port and captcha ref from local storage
    const port = parseInt(complianceCheckStatusData?.port ?? 0);
    const captcha_ref = complianceCheckStatusData?.captcha_ref ?? null;
    const insights_captcha_ref = complianceCheckStatusData?.insights_captcha_ref ?? null;

    useEffect(() => {


        // Run a continuous loop every 2 seconds
        const continueCheckStatusWhenFoundInProgress = async (port, captcha_ref, insights_captcha_ref, coplianceStatusKey) => {

            try {
                const statusResult = await getInsightsProcessStatusFromConnector(port, `?captcha_ref=${insights_captcha_ref}`);
                                if (parseInt(statusResult.status) === 1) {
                    //setData(statusResult?.data?.summary ?? []);
                    if ((statusResult?.data?.action_status ?? null) === 'IN_PROGRESS') {
                        timer.current = setTimeout(() => continueCheckStatusWhenFoundInProgress(port, captcha_ref, insights_captcha_ref, coplianceStatusKey), 2000);
                        // if (!showProgressBar && !popupStatus) {
                        //     setConfirmPopupForCompliance(true);
                        // }                        
                        return;
                    } else if ((statusResult?.data?.action_status ?? null) === 'COMPLETE') {
                        //process completed, now reload page
                        localStorage.removeItem(coplianceStatusKey);
                        setReloadPanList(true);
                    } else {
                        setSuccess(null)
                        setError('Compliance Check for 206AB and 206CCA process has been failed, Please try again after some time.');
                        localStorage.removeItem(coplianceStatusKey);
                        setTimeout(() => setReloadPanList(true), 5000);
                    }
                }
            } catch (err) {
                console.error('Compliance Check Status', err)
            }

            // Finally, remove status key from storage
            localStorage.removeItem(coplianceStatusKey);
            // showPopupOnlyOnce = false;
        }

        if (port && insights_captcha_ref) {
            continueCheckStatusWhenFoundInProgress(port, captcha_ref, insights_captcha_ref, coplianceStatusKey);
        }

        // Clear timeout on unmount
        return () => clearTimeout(timer.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [port, captcha_ref, insights_captcha_ref, coplianceStatusKey, setReloadPanList]);


    return (
        <>
            <ProgressBar animated now={'100'} label={'Compliance Check Status In-Progress...'} />
        </>
    )
}