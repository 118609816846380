import React, {useEffect } from 'react';

import {verifyTracesLoginFromConnector } from '../../api/localConnectorAPI';
import { CONNECTOR_ERRORS_MAPPING } from '../../app/constants';


const VerifyPanCloudV2 = ({ history, ...props }) => {
    const {
        handleClose, handleProceed, tan, port, tracesPassword = '', tracesUser = '', captcha_text, captcha_ref, getCaptchaPortal,
        setShowTraceValidationFailPopup, setCheckTraces, setVerifyCreds, setCaptchaError, setCaptchaLoading
    } = props;

    const verifyCatpcha = () => {

        const request = {
            "tan": tan,
            "traces_username": tracesUser,
            "traces_password": tracesPassword,
            "captcha_text": captcha_text,
            "captcha_ref": captcha_ref
        };

        async function fetchEverything() {
            try {
                const result = await verifyTracesLoginFromConnector(port, request);
                if (result.status === 1) {

                    handleProceed(result);
                    handleClose();
                    setVerifyCreds(result.message);

                }
            } catch (err) {
                if (err?.message?.includes('invalid_credentials') || err?.message?.includes('traces.invalid_credentials')) {
                    setCheckTraces(false);
                    setShowTraceValidationFailPopup(true);
                    setCaptchaError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
                    setCaptchaLoading(false);

                } else {
                    getCaptchaPortal();
                    setCaptchaError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default'])
                    setCaptchaLoading(false);
                }

            } 
        }
        setCaptchaLoading(true);
        fetchEverything();
    };

    useEffect(() => {
        verifyCatpcha();
      //eslint-disable-next-line
    }, []);



    return (
        <>
            
        </>

    );
};

export default VerifyPanCloudV2;
