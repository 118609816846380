import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { BASE_PATH } from '../../../app/constants';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CustomCell } from '../../../components/grid/CustomCell';
import { formatNumber } from '../../../utils/UtilityFunctions';
import ReturnTransactionsPopup from './ReturnTransactionsPopup';
import useIntersection from '../../../utils/useIntersection';
import { returnHealthGetChallanWiseMappedTransactionList } from '../../../api/challanAPI';
import Skeleton6ColumnGrid from '../../../components/Skeleton/Skeleton6ColumnGrid';
//import ShowMorePagination from '../../../components/pagination/ShowMorePagination';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import $ from 'jquery';
import Spinner from 'react-bootstrap/Spinner';

const ChallanWiseSummary = (props) => {

  const { returnId, deductorId, challanWiseSummaryRef } = props;
  const [showPopup, setShowPopup] = useState(false);
  const inViewport = useIntersection(challanWiseSummaryRef, "0px");

  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [mappedTransactionsList, setMappedTransactionsList] = useState([]);
  const [dataState, setDataState] = useState({});
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  let limit = 100;

  const [prevPage, setPrevPage] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  
  const getChallanSummaryData = async (filterData = dataState) => {

    let request = {
      deductor_id: deductorId,
      return_id: returnId,
      limit: limit,
      page: cursor?.next_page ?? 1
    };

    setPrevPage(request?.page);
    
    if (filterData.page) {
      request.page = filterData.page;
    }

    if (filterData.filter) {
      request.filters = filterData?.filter?.filters;
    }

    if (filterData?.sort && filterData.sort.length > 0) {
      request.sort_on = filterData.sort[0].field;
      request.sort_by = filterData.sort[0].dir;
    }


    try {
      
      if (prevPage !== cursor?.next_page) { setShowMoreLoading(true); }

      const result = await returnHealthGetChallanWiseMappedTransactionList(request);   
      if (result?.data?.cursor?.current_page > 1) {
        setMappedTransactionsList(mappedTransactionsList.concat(result?.data?.data ?? []));
      } else {
        setMappedTransactionsList(result?.data?.data ?? []);
      }
      
      setCursor(result?.data?.cursor);

    } catch (err) {
      console.error(err);
    } finally {
      setShowSkeleton(false)
      setShowMoreLoading(false);
      setIsScroll(false);
    }
  };

  const dataStateChange = (dataState) => {
    setDataState(dataState);
    getChallanSummaryData(dataState);    
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };


  useEffect(() => {
    if (inViewport && returnId && deductorId) {
      getChallanSummaryData()
    }
    // eslint-disable-next-line
  }, [inViewport, returnId, deductorId])


  useEffect(() => {
    if(isScroll && cursor?.next_page > 1) {
      getChallanSummaryData()
    }
    // eslint-disable-next-line
  },[isScroll])

  const onClose = () => {
    setShowPopup(false);
  }

  const rowRender = (trElement, props,) => {
    const data = props.dataItem;
    const rowCounter = trElement._owner.index;

    const filterString = [{ logic: "and", filters: [{ field: "challan_no", operator: "contains", value: `${data?.challan_no}` }] }];

    return (
      <tr data-index={rowCounter} role="row">
        <td className={'text-center'} title={`BSR Code: ${data?.bsr_code}, Deposit Date: ${data?.deposit_date}, Interest Amt: ${data?.interest_amtp}, Fees Amt: ${data?.fees_amtp}`}>{data?.challan_no ?? '-'}</td>
        <td className={`text-right`}>{formatNumber(data?.challan_amtp ?? '-')}</td>
        <td className={`text-right`}>{formatNumber(data?.mapped_amtp ?? '-')}</td>
        <td className="text-center">
          <Link
            target={'_blank'}
            className="link"
            to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&filters=${btoa(JSON.stringify(filterString))}`} tabIndex="-1">
            {data?.cnt_transactions}
          </Link>
        </td>
      </tr>
    )
  }

  const getScrollbarHeight = (el) => {
    return el.scrollHeight
  };
  $(function () {
    $(".k-grid-content").scroll(function () {
      if (getScrollbarHeight(document.querySelector('.k-grid-content')) < $(".k-grid-content").scrollTop() + $(".k-grid-content").height() + 1) {
        if (!isScroll && cursor?.next_page > 1) {
          setIsScroll(true);
        }
      }
    });
  });

//console.log(!isScroll, cursor?.next_page);

  return (
    <>

      {
        showPopup &&
        <ReturnTransactionsPopup
          returnId={returnId}
          deductorId={deductorId}
          onClose={onClose}
        />
      }


      <div className="col-md-7" ref={challanWiseSummaryRef}>
        <fieldset className="challanwise_summ_fs mb-0 h-100">
          <legend className="challanwise_summ_leg">
            <h4>Challan Wise Summary of Mapped Transactions</h4>
          </legend>

          <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
            {
              showSkeleton ? <Skeleton6ColumnGrid />
                :
                <Tooltip anchorElement="pointer" position="right">
                  <Grid
                    className="table table-striped tdscomp_tb mb-0"
                    style={{ minHeight: '305px', maxHeight: '305px', overflowY: "auto"  }}
                    data={mappedTransactionsList}
                    {...dataState}
                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                    filterOperators={{
                      text: [
                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                      ],
                      numeric: [
                        { text: 'grid.filterGteOperator', operator: 'gte' },
                        { text: 'grid.filterLteOperator', operator: 'lte' },
                        { text: 'grid.filterLtOperator', operator: 'lt' },
                        { text: 'grid.filterGtOperator', operator: 'gt' },
                        { text: 'grid.filterEqOperator', operator: 'eq' },
                        { text: 'grid.filterNotEqOperator', operator: 'neq' },
                      ],
                      date: [
                        { text: 'grid.filterGteOperator', operator: 'gte' },
                        { text: 'grid.filterLteOperator', operator: 'lte' },
                        { text: 'grid.filterLtOperator', operator: 'lt' },
                        { text: 'grid.filterGtOperator', operator: 'gt' },
                        { text: 'grid.filterEqOperator', operator: 'eq' },
                      ],
                      textWithEmpty: [
                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                      ],
                      dropdown: [
                        { text: 'grid.filterContainsOperator', operator: 'in' },
                      ],
                      pan: [
                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                        { text: 'grid.filterContainsOperator', operator: 'in' },
                      ],
                      challan: [
                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                      ],
                    }}
                    sortable={{
                      allowUnsort: true,
                    }}
                    rowRender={rowRender}
                  >

                    <Column
                      width={70}
                      field="challan_no"
                      headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                      title={<CustomCell title={'Challan No.'} field="challan_no" dataStateChange={dataStateChange} dataState={dataState} />}
                      filter="textWithEmpty"
                      columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                    />

                    <Column
                      width={150}
                      field="challan_amtp"
                      headerClassName={isColumnActive('challan_amtp') ? 'active' : ''}
                      title={<CustomCell title={'Challan Amt'} field="challan_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                      filter="numeric"
                      columnMenu={(props) => <ColumnMenu {...props} />}
                    />

                    <Column
                      width={150}
                      field="mapped_amtp"
                      headerClassName={isColumnActive('mapped_amtp') ? 'active' : ''}
                      title={<CustomCell title={'Mapped Amt'} field="mapped_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                      filter="numeric"
                      columnMenu={(props) => <ColumnMenu {...props} />}
                    />

                    <Column
                      width={150}
                      field="cnt_transactions"
                      headerClassName={isColumnActive('cnt_transactions') ? 'active' : ''}
                      title={<CustomCell title={'Transactions in Return'} field="cnt_transactions" dataStateChange={dataStateChange} dataState={dataState} />}
                      filter="numeric"
                      columnMenu={(props) => <ColumnMenu {...props} />}
                    />

                    <GridNoRecords>
                      No Record Found
                    </GridNoRecords>

                  </Grid>
                </Tooltip>
            }

            {showMoreLoading && (
              <span style={{
                position: 'absolute',
                left: '50%',
                top: '200px',
              }}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            )}

            {/* <div className="row mt-2 pt-2" style={{ width: '100%' }}>
          <div className="col-md-12 text-center">
            <ShowMorePagination
              cursor={cursor}
              fetchData={(nextPage) => {
                const filterData = { ...dataState, page: nextPage.page };
                getChallanSummaryData(filterData);
              }}
              postData={dataState}
              loading={showMoreLoading}
            />
          </div>
        </div> */}

          </div>
        </fieldset>

      </div>


    </>

  );
};

export default ChallanWiseSummary;
