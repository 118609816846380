import React from 'react'
import { Link } from 'react-router-dom';
import editIcoon from '../../../images/edite02.png';
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';

const PrevFilingDetV1 = (props) => {
    const { deductorAddressChangedVal, BASE_PATH, params, handleOptionChange, headerDataDetails, fvuGenerationFormFields, prnStaus, prnVal, setPrnVal, updateLastFilling } = props;

    const onChangeValidatePrn = (e) => {
        const re = /^[0-9\b]+$/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= '15') {
            fvuGenerationFormFields.prn = e.target.value;
            setPrnVal(e.target.value);
        } else {
            fvuGenerationFormFields.prn = prnVal;
        }
    };

    return (
        <>
            <div className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-6 px-2">
                <fieldset className="whetherreguler_fs h-100 pb-0">
                    <legend className="whetherreguler_leg mb-0">
                        <h4 className="mb-0">Previous Filing Details</h4>
                    </legend>
                    <div className="row">
                        <div className="col-md-12 px-2">
                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <table className="table table-borderless whetherreguler_tb  mb-0">
                                    <tbody>
                                    <tr>
                                            <td>
                                                <span className="whetherreguler_textleftfs">Has Deductor Address Changed since last filing?</span>
                                                {
                                                    Number(deductorAddressChangedVal) === 1 &&
                                                    <>
                                                        <br /><span className='whetherreguler_textleftfs'>(If Yes, click edit to change)
                                                            <Link
                                                                className=""
                                                                to={`${BASE_PATH}edit-deductor/${params.deductor_id}`}
                                                                tabIndex="-1"
                                                                target={'_blank'}
                                                            >
                                                                <img src={editIcoon} width="16" alt='' />
                                                            </Link>
                                                        </span>
                                                    </>
                                                }
                                            </td>
                                            <td width="" className="text-center">
                                                <span className="whetherreguler_textright">
                                                    <select className='' onChange={(e) => handleOptionChange(e, 'address_changed')}>
                                                        <option value={'1'} selected={deductorAddressChangedVal === true}>Yes</option>
                                                        <option value={'0'} selected={deductorAddressChangedVal === false}>No</option>
                                                    </select>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="320">
                                                <span className="whetherreguler_textleftfs">Whether regular statement for  {' '} {headerDataDetails.form_type} {' '} has been filed earlier?</span>
                                            </td>
                                            <td width="50" className="text-center">
                                                <span className="whetherreguler_textright">
                                                    <select className='' onChange={(e) => handleOptionChange(e, 'prncheckbox')}>
                                                        <option value={'1'} selected={parseInt(fvuGenerationFormFields?.prncheckbox) === 1}>Yes</option>
                                                        <option value={'0'} selected={parseInt(fvuGenerationFormFields?.prncheckbox) === 0}>No</option>
                                                    </select>
                                                </span>
                                            </td>
                                        </tr>                                        
                                        <tr>
                                            <td colspan="2" className="text-center">

                                                <BlurOnChangeInput
                                                    type="text"
                                                    className="form-control entertokenno_inputbox"
                                                    id="prn"
                                                    value={prnStaus ? fvuGenerationFormFields?.prn : ''}
                                                    placeholder={`Enter Token No./PRN* for the previous ${headerDataDetails?.form_type} regular return`}
                                                    name="prn"
                                                    maxLength={15}
                                                    disabled={!prnStaus}
                                                    onChange={(e) => onChangeValidatePrn(e)}
                                                    onBlur={() => updateLastFilling(fvuGenerationFormFields)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )
}

export default PrevFilingDetV1