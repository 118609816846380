import React, { useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import $ from "jquery";

const WindowWrapperFixed = (props) => {
  const {
    title,
    onClose,
    totalPopups,
    index = 0,
    initialWidth = 1250,
    initialHeight = 350,
    initialLeft = 10,
    initialTop = 0,
    isModalView = false,
    resizable = false,
    style,
    className,
  } = props;


  const [top, setTop] = useState(initialTop);
  const [left, setLeft] = useState(initialLeft);

  useEffect(() => {
    let top = initialTop;
    setTop(10 + top * index + 2 * index)

    // eslint-disable-next-line
  }, [index, totalPopups]);

  useEffect(() => {
    setTop(initialTop)
    setLeft(initialLeft)
    // eslint-disable-next-line
  }, [initialLeft, initialTop]);


  return (
    <Window
      maximizeButton={() => null}
      minimizeButton={() => null}
      title={title}
      onClose={onClose}
      initialHeight={initialHeight ? initialHeight : 350}
      initialWidth={initialWidth}
      left={left}
      top={top}
      className={`windowWrapper ${className? className : ''}`}
      modal={isModalView}
      resizable={resizable}
      doubleClickStageChange={false}
      onMove={(e) => {
        if(e.top){
          let maxHeight = window.innerHeight ?? 0;
          const propsclass = className?.split(" ")?.[0]; 
         
          if(Math.round($(`.k-widget.k-window.windowWrapper${propsclass ? `.${propsclass}` : ""}`).height())){
            maxHeight -= $(`.k-widget.k-window.windowWrapper${propsclass ? `.${propsclass}` : ""}`).height();
          }
          setTop(Math.min(maxHeight, e.top));
        }
       
        setLeft(Math.min(window.innerWidth - e.width, e.left));
      }}
      style={{ ...style }}
    >
      {props.children}
    </Window>
  );
};

export default WindowWrapperFixed;