import React, { useState, useEffect } from 'react';
import { getDeductorById, editDeductor } from '../../../api/deductorAPI';
import { getFormObject, getDeductorObject } from '../../../components/createDeductorForm/DeductorFormHelper';
import EditIcon from '../../../images/edit_blue.png';
//import EmailIcon from '../../../images/emailicon.png';
import EditDeductorForm from './EditDeductorForm';

const EditDeductorDetails = (props) => {

  const { deductorId } = props;

  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [deductorData, setDeductorData] = useState({});
  const [isDeductorInformationMissing, setIsDeductorInformationMissing] = useState(false);

  const onClose = () => {
    setShowForm(false);
  }

  const deductorDtails = async (deductorId) => {
    try {
      const result = await getDeductorById(deductorId,false);
      if (result?.data) {
        setDeductorData(getFormObject(result?.data));
        validateDeductorInformationForMissingDetails(result?.data);
      }
    } catch (err) {
      console.log(err);
      setError(err.toString());
    }
  }

  const saveDeductor = (values) => {
    var requestData = getDeductorObject(values);
    requestData['deductor_id'] = deductorId;

    async function callAPI() {
      async function editDeductor2() {
        const result = await editDeductor(requestData);
        return result;
      }
      try {
        const result = await editDeductor2();
        if (result.status === 1) {
          onClose();
          setTimeout(() => {
            window.location.reload();
          }, 1000)
        }

      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
      }
    }
    callAPI();
  }

  const validateDeductorInformationForMissingDetails = (data) => {

    const validateStatus = (data?.deductor_name ?? "") === "" ||
      (data?.deductor_category ?? "") === "" ||
      (data?.contact_info?.primary_mobile_no ?? "") === "" ||
      (data?.contact_info?.primary_email ?? "") === "" ||
      (data?.address?.building_no ?? "") === "" ||
      (data?.address?.city ?? "") === "" ||
      (data?.address?.state_id ?? "") === "" ||
      (data?.address?.pincode ?? "") === "" ||
      (data?.responsible_person?.pan ?? "") === "" ||
      (data?.responsible_person?.designation ?? "") === "" ||
      (data?.responsible_person?.contact_info?.person_name ?? "") === "" ||
      (data?.responsible_person?.contact_info?.primary_mobile_no ?? "") === "" ||
      (data?.responsible_person?.contact_info?.primary_email ?? "") === ""
      (data?.branch ?? "") === "";
      
    setIsDeductorInformationMissing(validateStatus);
  }


  useEffect(() => {
    deductorDtails(deductorId);
    // eslint-disable-next-line
  }, [deductorId])


  return (
    <>
      {
        isDeductorInformationMissing &&
        <div className="row mt-2">
          <div className="col-lg-12">
            <div className="deductorinfo_box">
              <span className="card comp_health_revistbox" style={{ width: 'auto', marginRight: '5px',cursor:"pointer" }} onClick={() => setShowForm(true)}>
                <span className="comp_health_revistbox_bgclr">
                  <i>Some deductor Information is missing, Click here to complete.</i>
                </span>
                <span className="comphealth_editicon">
                <img src={EditIcon} width="16"  alt='' />
              </span>
              </span>
             
              <span className="emailrighticon float-right">
                {/* <img src={EmailIcon} width="30" alt='' /> */}
              </span>
            </div>
          </div>
        </div>
      }

      {showForm &&
        <EditDeductorForm
          deductorId={props?.deductorId}
          returnId={props?.returnId}
          onClose={onClose}
          deductorData={deductorData}
          saveDeductor={saveDeductor}
          error={error}
          setError={setError}
        />}
    </>

  );
};

export default EditDeductorDetails;
