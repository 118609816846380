import React from 'react';
import { Skeleton } from '@mui/material';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '../../../app/constants';

const PanComplianceCheck206 = (props) => {

  const { deductorId, returnId, complianceStatusData, skeletonLoading, formType, finanicalYear } = props;

  let title = 'PAN Compliance Check u/s 206';
  if (formType === '24Q') {
    title = 'PAN Compliance check 206AB';
  } else if (formType === '26Q') {
    title = 'PAN status as per Compliance Check';
  }

  if (formType === '24Q') {
    return (
      <>
        <fieldset className="pan_compliance_fs h-100 px-1">
          <legend className="pan_sumamry_leg">
            <h4>PAN Compliance Check u/s 206</h4>
          </legend>
          <div className="pt-2 table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
            <table className="table table-borderless pan_sumamry_table  mb-0">
              <tbody>
                {
                  skeletonLoading
                    ?
                    <>
                      <tr>
                        <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                        <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                      </tr>
                      <tr>
                        <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                        <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                      </tr>
                      <tr>
                        <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                        <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                      </tr>
                    </>
                    :
                    <>
                      {/* <tr>
                        <td width="150">
                          {
                            complianceStatusData?.cnt_insights_pending > 0
                              ?
                              <>
                                <Link
                                  target="_blank"
                                  to={`${BASE_PATH}compliance-status-check?deductor_id=${deductorId}&return_id=${returnId}&auto=1`}
                                  tabIndex="-1"
                                >
                                  <div className="cardbox_contentborder" data-toggle="tooltip" data-placement="top" title="Click to Verify">
                                    <div className="btn-group comput_health_lowerdt_card">
                                      <span className="invlid_textbox">
                                        <span className="textnofsclr">
                                          {complianceStatusData?.cnt_insights_pending ?? 0}
                                        </span>
                                        <br />
                                        <span className="invlid_textfsclr">
                                          Pending
                                        </span>
                                      </span>
                                      <span className="comput_health_verifid_btnbox">
                                        Verify
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </>
                              :
                              <>
                                <div className="cardbox_contentborder_withouthoverbox" >
                                  <div className="btn-group comput_health_lowerdt_card">
                                    <span className="invlid_textbox">
                                      <span className="textnofsclr">
                                        {complianceStatusData?.cnt_insights_pending ?? 0}
                                      </span>
                                      <br />
                                      <span className="invlid_textfsclr">
                                        Pending
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </>
                          }

                        </td>
                        <td width="150">
                          <div className="cardbox_contentborder_withouthoverbox">
                            <span className="textnofsclr">
                              {complianceStatusData?.cnt_insights_regular_filer_operative ?? 0}
                            </span>
                            <div className="invlid_textbox">
                              <span className="regfiler_textfsclr">
                                Reg Filer (Operative)
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td width="150">
                          <div className="cardbox_contentborder_withouthoverbox">
                            <span className="textnofsclr">
                              {complianceStatusData?.cnt_insights_regular_filer_inoperative ?? 0}
                            </span>
                            <div className="invlid_textbox">
                              <span className="regfiler_textfsclr">
                                Reg Filer (In-Operative)
                              </span>
                            </div>
                          </div>
                        </td>
                        <td width="150">
                          <div className="cardbox_contentborder_withouthoverbox">
                            <span className="textnofsclr">
                              {complianceStatusData?.cnt_insights_non_filer_operative ?? 0}
                            </span>
                            <div className="invlid_textbox">
                              <span className="regfiler_textfsclr">
                                Non Filer (Operative)
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td width="150">
                          <div className="cardbox_contentborder_withouthoverbox">
                            <span className="textnofsclr">
                              {complianceStatusData?.cnt_insights_non_filer_inoperative ?? 0}
                            </span>
                            <div className="invlid_textbox">
                              <span className="regfiler_textfsclr">
                                Non Filer (In-Operative)
                              </span>
                            </div>
                          </div>
                        </td>
                        <td width="150">
                          <div className="cardbox_contentborder_withouthoverbox">
                            <span className="textnofsclr">
                              {complianceStatusData?.cnt_traces_invalid_pan ?? 0}
                            </span>
                            <div className="invlid_textbox">
                              <span className="regfiler_textfsclr">
                                Invalid
                              </span>
                            </div>
                          </div>
                        </td>

                      </tr> */}
                      <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <table className="table table-borderless pan_sumamry_table  mb-0">
                          <tbody>
                            <tr>
                              <td width="150">
                                {
                                  complianceStatusData?.cnt_insights_pending > 0
                                    ?
                                    <>
                                      <Link
                                        target="_blank"
                                        to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&financial_year=${finanicalYear}`}
                                        tabIndex="-1"
                                      >
                                        <div class="cardbox_contentborder" data-toggle="tooltip" >
                                          <div class="btn-group comput_health_lowerdt_card" >
                                            <span class="invlid_textbox" >
                                              <span class="textnofsclr" >
                                                {complianceStatusData?.cnt_insights_pending ?? 0}
                                              </span>
                                              <br />
                                              <span class="invlid_textfsclr" >
                                                Pending
                                              </span>
                                            </span>
                                            <span class="comput_health_verifid_btnbox" data-toggle="tooltip" data-placement="top" title="Click to Verify">
                                              Verify
                                            </span>
                                          </div>
                                        </div>
                                      </Link>
                                    </>
                                    :
                                    <>
                                      <div class="cardbox_contentborder_withouthoverbox" data-toggle="tooltip" >
                                        <div class="btn-group comput_health_lowerdt_card" >
                                          <span class="invlid_textbox" >
                                            <span class="textnofsclr" >
                                              {complianceStatusData?.cnt_insights_pending ?? 0}
                                            </span>
                                            <br />
                                            <span class="invlid_textfsclr" >
                                              Pending
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                }

                              </td>
                              <td width="150">

                                {
                                  complianceStatusData?.cnt_unverified_filer_pan > 0
                                    ?
                                    <>
                                      <Link
                                        target="_blank"
                                        to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&filters=${btoa(JSON.stringify([{ logic: "and", filters: [{ field: "compliance_status", operator: "in", value: ["UNVERIFIED"] }] }]))}`}
                                        tabIndex="-1"
                                      >
                                        <div className="cardbox_contentborder" style={{minWidth:'122px'}}>
                                          <div className="btn-group comput_health_lowerdt_card" >
                                            <span className="invlid_textbox" >
                                              <span className="textnofsclr" >
                                                {complianceStatusData?.cnt_unverified_filer_pan ?? 0}
                                              </span>
                                              <br />
                                              <span className="invlid_textfsclr">
                                                Un-Verified
                                              </span>
                                            </span>
                                            <span className="comput_health_verifid_btnbox" data-toggle="tooltip" data-placement="top" title="Click to Verify">
                                              Verify Now
                                            </span>
                                          </div>
                                        </div>
                                      </Link>
                                    </>
                                    :
                                    <>
                                      <div className="cardbox_contentborder_withouthoverbox" >
                                        <div className="btn-group comput_health_lowerdt_card" >
                                          <span className="invlid_textbox" >
                                            <span className="textnofsclr" >
                                              {complianceStatusData?.cnt_unverified_filer_pan ?? 0}
                                            </span>
                                            <br />
                                            <span className="invlid_textfsclr">
                                              Un-Verified
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                }

                              </td>
                              <td width="150">
                                <div className="cardbox_contentborder_withouthoverbox">
                                  <span className="textnofsclr">
                                    {complianceStatusData?.cnt_traces_invalid_pan ?? 0}
                                  </span>

                                  <div className="invlid_textbox">
                                    <span className="regfiler_textfsclr">
                                      Invalid
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="pt-2 table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <Tooltip anchorElement="pointer" position="top">
                          <table className="table table-borderless pan_sumamry_table  mb-0">
                            <tbody>
                              <tr>
                                <td width="150">

                                  <>
                                    <div className="cardbox_contentborder_withouthoverbox" >
                                      <div className="btn-group comput_health_lowerdt_card" >
                                        <span className="invlid_textbox" >
                                          <span className="textnofsclr" >
                                            Reg Filer
                                          </span>
                                          <br />
                                          <span className="operative_textfsclr">
                                            Operative
                                          </span>
                                          <span style={{ paddingLeft: '20px' }}>{complianceStatusData?.cnt_insights_regular_filer_operative ?? 0}</span>
                                          <br />
                                          <span className="invlid_textfsclr">
                                            Inoperative
                                          </span>
                                          <span style={{ paddingLeft: '20px' }}>{complianceStatusData?.cnt_insights_regular_filer_inoperative ?? 0} </span>
                                        </span>
                                      </div>
                                    </div>
                                  </>


                                </td>

                                <td width="150">

                                  <>
                                    <div className="cardbox_contentborder_withouthoverbox">
                                      <div className="btn-group comput_health_lowerdt_card" >
                                        <span className="invlid_textbox" >
                                          <span className="textnofsclr" >
                                            Non Filer
                                          </span>
                                          <br />
                                          <span className="operative_textfsclr">
                                            Operative
                                          </span>
                                          <span style={{ paddingLeft: '20px' }}>{complianceStatusData?.cnt_insights_non_filer_operative ?? 0}</span>
                                          <br />
                                          <span className="invlid_textfsclr">
                                            Inoperative
                                          </span>
                                          <span style={{ paddingLeft: '20px' }}>{complianceStatusData?.cnt_insights_non_filer_inoperative ?? 0} </span>
                                        </span>
                                      </div>
                                    </div>
                                  </>

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Tooltip>
                      </div>
                    </>
                }

              </tbody>
            </table>
          </div>
        </fieldset>
      </>
    )
  } else {
    return (
      <>
        <div className="col-md-7">
          <fieldset className="pan_compliance_fs h-100">
            <legend className="pan_sumamry_leg">
              <h4>{title}</h4>
            </legend>
            <div className="pt-2 table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
              <Tooltip anchorElement="pointer" position="top">
                <table className="table table-borderless pan_sumamry_table  mb-0">
                  <tbody>
                    {
                      skeletonLoading
                        ? <>
                          <tr>
                            <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                            <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                            <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                          </tr>
                          <tr>
                            <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                            <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                            <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                          </tr>
                        </>
                        : <>

                          <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <table className="table table-borderless pan_sumamry_table  mb-0">
                              <tbody>
                                <tr>
                                  <td width="150">
                                    {
                                      complianceStatusData?.cnt_insights_pending > 0
                                        ?
                                        <>
                                          <Link
                                            target="_blank"
                                            to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&financial_year=${finanicalYear}`}
                                            tabIndex="-1"
                                          >
                                            <div class="cardbox_contentborder" data-toggle="tooltip" >
                                              <div class="btn-group comput_health_lowerdt_card" >
                                                <span class="invlid_textbox" >
                                                  <span class="textnofsclr" >
                                                    {complianceStatusData?.cnt_insights_pending ?? 0}
                                                  </span>
                                                  <br />
                                                  <span class="invlid_textfsclr" >
                                                    Pending
                                                  </span>
                                                </span>
                                                <span class="comput_health_verifid_btnbox" data-toggle="tooltip" data-placement="top" title="Click to Verify">
                                                  Verify
                                                </span>
                                              </div>
                                            </div>
                                          </Link>
                                        </>
                                        :
                                        <>
                                          <div class="cardbox_contentborder_withouthoverbox" data-toggle="tooltip" >
                                            <div class="btn-group comput_health_lowerdt_card" >
                                              <span class="invlid_textbox" >
                                                <span class="textnofsclr" >
                                                  {complianceStatusData?.cnt_insights_pending ?? 0}
                                                </span>
                                                <br />
                                                <span class="invlid_textfsclr" >
                                                  Pending
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                    }

                                  </td>
                                  <td width="150">

                                    {
                                      complianceStatusData?.cnt_unverified_filer_pan > 0
                                        ?
                                        <>
                                          <Link
                                            target="_blank"
                                            to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&return_id=${returnId}&filters=${btoa(JSON.stringify([{ logic: "and", filters: [{ field: "compliance_status", operator: "in", value: ["UNVERIFIED"] }] }]))}`}
                                            tabIndex="-1"
                                          >
                                            <div className="cardbox_contentborder" >
                                              <div className="btn-group comput_health_lowerdt_card" >
                                                <span className="invlid_textbox" >
                                                  <span className="textnofsclr" >
                                                    {complianceStatusData?.cnt_unverified_filer_pan ?? 0}
                                                  </span>
                                                  <br />
                                                  <span className="invlid_textfsclr">
                                                    Un-Verified
                                                  </span>
                                                </span>
                                                <span className="comput_health_verifid_btnbox" data-toggle="tooltip" data-placement="top" title="Click to Verify">
                                                  Verify Now
                                                </span>
                                              </div>
                                            </div>
                                          </Link>
                                        </>
                                        :
                                        <>
                                          <div className="cardbox_contentborder_withouthoverbox" >
                                            <div className="btn-group comput_health_lowerdt_card" >
                                              <span className="invlid_textbox" >
                                                <span className="textnofsclr" >
                                                  {complianceStatusData?.cnt_unverified_filer_pan ?? 0}
                                                </span>
                                                <br />
                                                <span className="invlid_textfsclr">
                                                  Un-Verified
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                    }

                                  </td>
                                  <td width="150">
                                    <div className="cardbox_contentborder_withouthoverbox">
                                      <span className="textnofsclr">
                                        {complianceStatusData?.cnt_traces_invalid_pan ?? 0}
                                      </span>

                                      <div className="invlid_textbox">
                                        <span className="regfiler_textfsclr">
                                          Invalid
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="pt-2 table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <Tooltip anchorElement="pointer" position="top">
                              <table className="table table-borderless pan_sumamry_table  mb-0">
                                <tbody>
                                  <tr>
                                    <td width="150">

                                      <>
                                        <div className="cardbox_contentborder_withouthoverbox" >
                                          <div className="btn-group comput_health_lowerdt_card" >
                                            <span className="invlid_textbox" >
                                              <span className="textnofsclr" >
                                                Reg Filer
                                              </span>
                                              <br />
                                              <span className="operative_textfsclr">
                                                Operative
                                              </span>
                                              <span style={{ paddingLeft: '20px' }}>{complianceStatusData?.cnt_insights_regular_filer_operative ?? 0}</span>
                                              <br />
                                              <span className="invlid_textfsclr">
                                                Inoperative
                                              </span>
                                              <span style={{ paddingLeft: '20px' }}>{complianceStatusData?.cnt_insights_regular_filer_inoperative ?? 0} </span>
                                            </span>
                                          </div>
                                        </div>
                                      </>


                                    </td>

                                    <td width="150">

                                      <>
                                        <div className="cardbox_contentborder_withouthoverbox">
                                          <div className="btn-group comput_health_lowerdt_card" >
                                            <span className="invlid_textbox" >
                                              <span className="textnofsclr" >
                                                Non Filer
                                              </span>
                                              <br />
                                              <span className="operative_textfsclr">
                                                Operative
                                              </span>
                                              <span style={{ paddingLeft: '20px' }}>{complianceStatusData?.cnt_insights_non_filer_operative ?? 0}</span>
                                              <br />
                                              <span className="invlid_textfsclr">
                                                Inoperative
                                              </span>
                                              <span style={{ paddingLeft: '20px' }}>{complianceStatusData?.cnt_insights_non_filer_inoperative ?? 0} </span>
                                            </span>
                                          </div>
                                        </div>
                                      </>

                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Tooltip>
                          </div>

                        </>
                    }

                  </tbody>
                </table>
              </Tooltip>
            </div>
          </fieldset >
        </div >

      </>

    );
  }


};

export default PanComplianceCheck206;
