import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumber } from '../../utils/UtilityFunctions';
import { ADDON_GST_TAX_RATE } from '../../app/constants';
import './AddOnPopup.css';

const AddOnPopup = (props) => {

    const { show, handleClose, setPlanData, startBilling } = props;
    const [total, setTotal] = useState(null);
    const [planParameters, setPlanParameters] = useState([]);
    const [enableProceed, setEnableProceed] = useState(false);

    const increment = (parameter_id) => {
        if (planParameters.length > 0) {
            Object.keys(planParameters).forEach((item) => {
                if (parameter_id === planParameters[item]?.parameter_id) {
                    planParameters[item].parameter_value += parseFloat(planParameters[item]?.addon_multiples_value);
                    planParameters[item].parameter_amt = parseFloat( (planParameters[item]?.parameter_value) / (planParameters[item]?.addon_multiples_value) ) * parseFloat(planParameters[item]?.addon_unit_price);
                    if (parseFloat(planParameters[item]?.parameter_amt) >= 0) {
                        setPlanParameters((prev) => {
                            prev = planParameters
                            return [...prev]
                        });
                        calculateTotal(planParameters);
                    }
                }
            })
        }
    }

    const decrement = (parameter_id) => {
        if (planParameters.length > 0) {
            Object.keys(planParameters).forEach((item) => {
                if (parameter_id === planParameters[item]?.parameter_id) {                   
                    const addon_multiples_value = parseFloat(planParameters[item]?.parameter_value) - parseFloat(planParameters[item]?.addon_multiples_value);
                    if(addon_multiples_value >= 0){
                        planParameters[item].parameter_value -= parseFloat(planParameters[item]?.addon_multiples_value);
                        planParameters[item].parameter_amt = parseFloat( (planParameters[item]?.parameter_value) / (planParameters[item]?.addon_multiples_value) ) * parseFloat(planParameters[item]?.addon_unit_price);
                        if (parseFloat(planParameters[item]?.parameter_amt) >= 0) {
                            setPlanParameters((prev) => {
                                prev = planParameters
                                return [...prev]
                            });
                            calculateTotal(planParameters);
                        }
                    }                    
                }
            })
        }
    }


    const calculateTotal = (planParameters) => {
        if (planParameters && planParameters.length > 0) {
            let total = 0;
            Object.keys(planParameters).forEach((item) => {
                if (parseFloat(planParameters[item]?.parameter_amt) > 0) {
                    total += parseFloat(planParameters[item]?.parameter_amt);
                }
            })
            setTotal((prev) => {
                prev = total
                return prev
            })
        }
    }

    const convertData = (planData) => {
        if (planData.length > 0) {
            const dataArray = [];
            Object.keys(planData).forEach((item) => {
                if (planData[item]?.addon_multiples_value !== null && planData[item]?.addon_unit_price !== null) {
                    dataArray.push(
                        {
                            ...planData[item],
                            parameter_value: 0,
                            parameter_amt: 0,
                        })
                }
            })
            setPlanParameters((prev) => {
                prev = dataArray
                return prev

            });
            calculateTotal(dataArray);
        }
    };


    useEffect(() => {
        if (props?.apiData) {
            convertData(props?.apiData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.apiData]);

    const enableProceedButton = () => {
        let enableButton = false;
        planParameters.forEach((item) => {
            if (parseFloat(item?.parameter_amt) > 0) {
                enableButton = true;
            }
        })
        setEnableProceed((prev) => {
            prev = enableButton
            return prev;
        })
    }

    useEffect(() => {
        enableProceedButton();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planParameters])
   
    const getAddonDetails = (parametersList) => {
        let details = '';
        if(parametersList.length > 0) {            
            parametersList.forEach((item, index) => {
                if(index > 0) {
                    details += ` | ${item?.parameter_name} : ${item?.parameter_value}` 
                }else{
                    details += `${item?.parameter_name} : ${item?.parameter_value}`  
                }
            })            
        }
        return details;
    }

    const handleProceed = () => {
        const planData = {};
        if (planParameters.length > 0) {
            const parametersList = [];
            planParameters.forEach((item) => {
                if(parseFloat(item?.parameter_value) > 0) {
                    
                    parametersList.push({ 
                        parameter_id: item?.parameter_id,
                        parameter_value: parseFloat(item?.parameter_value),
                        parameter_name: item?.parameter_name,
                    });
                }                
            })
            planData.plan_amt = parseFloat(total);
            planData.parametersList = parametersList;
            planData.discount_amt = 0;
            planData.tax_rate = ADDON_GST_TAX_RATE;
            planData.tax_amt = parseFloat(total) * parseFloat(parseFloat(planData.tax_rate) / 100.0);
            planData.payment_amt = parseFloat(planData.plan_amt) + parseFloat(planData.tax_amt);
            planData.addon_details = getAddonDetails(planData.parametersList);
        }

        setPlanData(planData);
        startBilling();
        handleClose();
    }


    return (

        <>
            <Modal
                className="modal fade mysubplan_addon_modalboxpopup"
                backdrop="static"
                show={show}
                onHide={handleClose}
            >

                <Modal.Header closeButton className="mysubplan_addon_modalboxpopup_header">
                    <Modal.Title>Select Addon Details</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="modal-body pb-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mysubplan_addndecard">
                                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                        <table className="table table-striped mysubplan_addondetailstb mb-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan="2" className="mysubplan_addon_rightsidegrayborder" width="100">Feature</th>
                                                    <th colSpan="3" className="mysubplan_addon_rightsidegrayborder" width=""> Quantity</th>
                                                    <th width="190">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(planParameters).map((index) => {
                                                    return (
                                                        <tr className="mysubplan_addon_whitebgclrrow">
                                                            <td width="150">
                                                                {planParameters[index].parameter_name}
                                                            </td>
                                                            <td className="text-right mysubplan_addon_rightsidegrayborder textgrayclr" width="130">
                                                                {/* 500.00 / Invoices */}
                                                            </td>
                                                            <td className="text-center mysubplan_addon_rightsidegrayborder" width="150">
                                                                {
                                                                    parseFloat(planParameters[index]?.parameter_value)
                                                                }
                                                            </td>
                                                            <td className="text-center mysubplan_addon_rightsidegrayborder" width="30">
                                                                <span className="iconfs">
                                                                    <FontAwesomeIcon icon={faMinus} onClick={() => decrement(planParameters[index]?.parameter_id)} style={{ cursor: 'pointer' }} />
                                                                </span>
                                                            </td>
                                                            <td className="text-center mysubplan_addon_rightsidegrayborder" width="30">
                                                                <span className="iconfs">
                                                                    <FontAwesomeIcon icon={faPlus} onClick={() => increment(planParameters[index]?.parameter_id)} style={{ cursor: 'pointer' }} />
                                                                </span>
                                                            </td>
                                                            <td className="text-right">                                                                
                                                                {
                                                                    formatNumber(planParameters[index]?.parameter_amt)
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                                <tr className="mysubplan_addon_totalamtbgclr">
                                                    <td>Total Amount </td>
                                                    <td colSpan="4"></td>
                                                    <td className="text-right">{formatNumber(total)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center pt-2">
                                {
                                    enableProceed &&
                                    <button className="btn btn-dedauld mysub-plan_subplan_proccedbtnbgclr"
                                        onClick={() => handleProceed(true)}
                                    >
                                        Procced
                                    </button>
                                }

                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
};

export default AddOnPopup;