import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CustomCell } from '../../components/grid/CustomCell';
import { formatNumber } from '../../utils/UtilityFunctions';
import { getDeducteesTransactionsV2 } from '../../api/deducteeTransactionsAPI';
import WindowWrapper from '../../components/grid/WindowWrapper';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';
import { ColumnMenu } from '../../components/grid/ColumnMenu';

const DeducteeWiseTransactionInnerListing = (props) => {
    const { deductorId, returnId, deduteeId, natureOfPaymentId, onClose, totalPopups, index = 0, deducteeData } = props;

    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [deducteeTransactionsList, setDeducteeTransactionsList] = useState([]);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [dataState, setDataState] = useState({});
    let limit = 8;
    const fetchData = async (filterData = dataState) => {
        let request = {
            deductor_id: deductorId,
            return_id: returnId,
            deductee_id: deduteeId,
            nature_of_payment_id: deducteeData?.nature_of_payment_id,
            limit: limit,
            page: 1
        };
        if (filterData.page) {
            request.page = filterData.page;
        }
        
        if (filterData.filter) {
            request.filters = filterData?.filter?.filters;
        }

        if (filterData?.sort && filterData.sort.length > 0) {
            request.sort_on = filterData.sort[0].field;
            request.sort_by = filterData.sort[0].dir;
        }


        try {
            const result = await getDeducteesTransactionsV2(request);
            if (result.data.cursor.current_page > 1) {
                setDeducteeTransactionsList(deducteeTransactionsList.concat(result?.data?.data ?? []));
            } else {
                setDeducteeTransactionsList(result.data.data ?? []);
            }

            if (result?.data?.cursor?.next_page > 1) {
                result.data.cursor.next_page_url = result?.data?.cursor?.next_page;               
            }
            setCursor(result?.data?.cursor);

        } catch (err) {
            console.error(err);
        } finally {
            setShowMoreLoading(false);
            // setSkeletonList(false)
            // setLoading(false);
        }
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        fetchData(dataState);
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    useEffect(() => {
        fetchData(dataState);
        // eslint-disable-next-line
    }, [deductorId, natureOfPaymentId, deduteeId]);
    const rowRender = (trElement, props,) => {
        const data = props.dataItem;
        const rowCounter = trElement._owner.index;
        return (
            <tr data-index={rowCounter} className={rowCounter % 2 === 0 ? 'deducteewise_tb_alternetrowbgclr_grey ' : 'deducteewise_tb_alternetrowbgclr_white '} role="row">
                <td className={`${data?.tds_rate_b100 ? 'text-center' : 'text-center'}`}>{data?.tds_rate_b100 ? formatNumber(data?.tds_rate_b100) : '-'}</td>
                <td className="text-center">
                    {data?.payment_date}
                </td>
                <td className="text-center">
                    {data?.deduction_date}
                </td>
                <td className={`text-right`}>{formatNumber(data?.paid_amtp ?? '-')}</td>
                <td className={`text-right`}>{formatNumber(data?.total_tds_amtp ?? '-')}</td>
                <td className="text-center">
                    {data?.challan_no}
                </td>
            </tr>
        )
    }
    return (       
            
            <>
                <WindowWrapper
                    title={
                        <kendo-dialog-titlebar>
                            <h4 className="modal-title">
                                <>
                                    < div className="table-responsive">
                                        <table className="deducteesHeaderRow">
                                            <thead>
                                                <tr className="headerRowTable">
                                                    <td width="250">Deductee Name</td>
                                                    <td width="150">PAN</td>
                                                    <td width="150">Section</td>
                                                    <td width="150">Amt Paid</td>
                                                    <td width="150">Tax Deducted</td>
                                                </tr></thead>
                                            <tbody >

                                                <tr className="headerRowTable2">

                                                    <th width="250">{deducteeData?.deductee_name ?? "-"}</th>
                                                    <th width="150">{deducteeData?.pan ?? "-"}</th>
                                                    <th width="150">{deducteeData?.display_code ?? "-"}</th>
                                                    <th width="150">{formatNumber((deducteeData?.deductee_paid_amtp ?? '-'))}</th>
                                                    <th width="150">{formatNumber(deducteeData?.deductee_paid_amtp ?? '-')}</th>
                                                </tr>
                                            </tbody >
                                        </table>
                                    </div>
                                </>
                            </h4>
                        </kendo-dialog-titlebar>}
                    onClose={onClose}
                    totalPopups={totalPopups}
                    index={index}
                >
                    <div className="card" style={{ paddingTop: "unset" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs tablealignCenter">
                                    <Tooltip anchorElement="pointer" position="right">
                                        <Grid
                                            className="table table-striped headerColors mb-0"
                                            style={{ maxHeight: '256px' }}
                                            data={deducteeTransactionsList}
                                            {...dataState}
                                            onDataStateChange={(event) => dataStateChange(event.dataState)}
                                            filterOperators={{
                                                text: [
                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                ],
                                                numeric: [
                                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                ],
                                                date: [
                                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                                ],
                                                textWithEmpty: [
                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                ],
                                                dropdown: [
                                                    { text: 'grid.filterContainsOperator', operator: 'in' },
                                                ],
                                                pan: [
                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                    { text: 'grid.filterContainsOperator', operator: 'in' },
                                                ],
                                                challan: [
                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                    { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                ],
                                            }}
                                            sortable={{
                                                allowUnsort: true,
                                            }}
                                            rowRender={rowRender}
                                        >
                                            <Column
                                                field="tds_rate_b100"
                                                headerClassName={isColumnActive('tds_rate_b100') ? 'active' : ''}
                                                title={<CustomCell title={'Rate (%)'} field="tds_rate_b100" dataStateChange={dataStateChange} dataState={dataState} />}
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                            />
                                            <Column
                                                field="payment_date"
                                                headerClassName={isColumnActive('payment_date') ? 'active' : ''}
                                                title={<CustomCell title={'Payment Dt'} field="payment_date" dataStateChange={dataStateChange} dataState={dataState} />}
                                                filter="date"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                            />
                                            <Column
                                                field="deduction_date"
                                                headerClassName={isColumnActive('deduction_date') ? 'active' : ''}
                                                title={<CustomCell title={'Deduction Dt'} field="deduction_date" dataStateChange={dataStateChange} dataState={dataState} />}
                                                filter="date"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                            />

                                            <Column
                                                field="paid_amtp"
                                                headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                                                title={<CustomCell title={'Amt Paid'} field="paid_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                            />
                                            <Column
                                                field="total_tds_amtp"
                                                headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                                                title={<CustomCell title={'Tax Deducted'} field="total_tds_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                                                filter="numeric"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                            />

                                            <Column
                                                field="challan_no"
                                                headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                                title={<CustomCell title={'Mapped Challan'} field="challan_no" dataStateChange={dataStateChange} dataState={dataState} />}
                                                filter="textWithEmpty"
                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                            />

                                        </Grid>
                                    </Tooltip>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-2 pt-2" style={{ width: '100%' }}>
                        <div className="col-md-12 text-center">
                            <ShowMorePagination
                                cursor={cursor}
                                fetchData={(nextPage) => {
                                    const filterData = { ...dataState, page: nextPage.page };
                                    fetchData(filterData);
                                }}
                                postData={dataState}
                                loading={showMoreLoading}
                            />
                        </div>
                    </div>
                </WindowWrapper>

            </>
    )
}

export default DeducteeWiseTransactionInnerListing;