import { useEffect } from 'react';
import { HEADER_CONNECTOR_DIGEST_KEY, CONNECTOR_ERRORS_MAPPING } from '../app/constants';
import { getNoticeOrderConnectorApi } from '../api/localConnectorAPI';
import { getNoticeOrderStatement } from '../api/serverConnectorAPI';

const NoticeSyncData = (props) => {
  const { deductorId, port, captchaRef, handleProceed, setSuccess, setError, setShowSyncVerify } = props;
  
  const returnAction = () => {
    async function fetchEverything() {
      const requestParams = {
                "deductor_id": deductorId,
                "captcha_ref": captchaRef
              }
          try {
             
                const result = await getNoticeOrderStatement(requestParams);
                const responseData = result.data ?? null;
                const responseHeader = result.headers ?? null;

                //Connector request data
                const connectorRequest = {
                  "data": responseData.data,
                };
                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                if(setSuccess !== undefined) {
                  setSuccess('Data syncing from the government portal(s) has been started successfully.');                  
                }
                if(setShowSyncVerify !== undefined) {
                  setShowSyncVerify(true);                  
                }

                try {

                  const connectorResponse = await getNoticeOrderConnectorApi(port, connectorRequest, extraHeader); 
                  
                  if (Number(connectorResponse.status) === 1) {

                    if(handleProceed !== undefined) {

                      handleProceed(connectorResponse);
                      
                    }                 
                  
                  }                  

                } catch(err) {

                  if(err?.message?.[0]) {
                    setError(CONNECTOR_ERRORS_MAPPING[err.message[0]] ?? err.message[0]);
                  }else{
                    setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? 'Error occure');
                  }
                    
                }                             
                

          }catch(err) {

            setError(err.message);

          }finally {
              //  
          } 
    }
    fetchEverything()
  }

    useEffect(() => {
      returnAction();
      //eslint-disable-next-line
    },[])


  return(null);   
};

export default NoticeSyncData;
