/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash, faCircle,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import downArrow from '../../images/icons/down_arrow.svg';
import { updateUserPassword } from '../../api/profileAPI';
import ShowAlert from '../ShowAlert';
import { updateAuthToken } from '../../utils/AuthToken';
import { encryptPasswordString } from '../../utils/UtilityFunctions';

const atLeasetDigit = new RegExp(/(?=.*\d)[\S]{1,}$/);
const atLeasetUpperCase = new RegExp(/(?=.*[A-Z])[\S]{1,}$/);
const atLeasetLowerCase = new RegExp(/(?=.*[a-z])[\S]{1,}$/);
const atLeasetEight = new RegExp(/[\S]{8,}$/);
const fullPasswordRegex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/);

const PasswordUpdate = (props) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const updatePassword = async (e) => {
    e.preventDefault();

    const formErr = {};
    if (oldPassword.trim().length === 0) {
      formErr.newPassword = 'Please enter old password';
    }
    if (oldPassword === newPassword) {
      formErr.newPassword = 'Old and new password can not be same.';
    }
    if (!fullPasswordRegex.test(newPassword)) {
      formErr.newPassword = 'Invalid Password';
    }
    if (newPassword !== confirmPassword) {
      formErr.confirmPassword = 'Passwords must match';
    }

    setFormErrors(formErr);

    if (Object.keys(formErr).length > 0) return;

    setLoading(true);
    const data = {
      old_password: encryptPasswordString(oldPassword),
      password: encryptPasswordString(newPassword),
      password_confirmation: encryptPasswordString(confirmPassword),
    };
    try {
      const result = await updateUserPassword(data);
      setSuccess(result.message);
      updateAuthToken(result.data.token)
    } catch (err) {
      setError(err.message);
      console.error('Error in updating password: ', err);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <form onSubmit={updatePassword}>
        <div className="card padding_top_manage mt-3 mb-3 profilecardpadd">
          <div className="row">
            <div className="grey_bg">
              <div className="row">
                <div className="col-sm-12">
                  <div

                    data-toggle="collapse"
                    href="#collapseResetPassword"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseResetPassword"
                  >

                    {' '}
                    <span style={{ fontSize: '0.9rem' }}>
                      Reset Password
                      </span>

                    <img
                      className="create_new_return float-right"
                      src={downArrow}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="collapse" id="collapseResetPassword">

            <div className="row mt-2 ">

              <div className="form-group col-sm-4 ">
                <label htmlFor="inputEmail4">
                  Old Password
                  <span className="red_text">*</span>
                </label>
                {showOldPassword ? (
                  <div className="input-group mb-3">
                    <input
                      type="input"
                      className="form-control"
                      placeholder="Enter Old Password"
                      autoComplete="new-password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <div
                      className="input-group-prepend eyeipbox profiladduserpass"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowOldPassword(false)}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </div>
                  </div>
                ) : (
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Old Password"
                        autoComplete="new-password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                      <div
                        className="input-group-prepend eyeipbox profiladduserpass"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowOldPassword(true)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </div>
                    </div>
                  )}
                {formErrors.oldPassword ? <span className="error_message">{formErrors.oldPassword}</span> : null}
              </div>
              <div className="form-group col-sm-4 ">
                <label htmlFor="inputEmail4">
                  New Password
                  <span className="red_text">*</span>
                </label>
                {showNewPassword ? (
                  <div className="input-group mb-3">
                    <input
                      type="input"
                      className="form-control"
                      placeholder="Enter New Password"
                      autoComplete="new-password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      onFocus={() => setShowPasswordTooltip(true)}
                      onBlur={() => setShowPasswordTooltip(false)}
                    />
                    <div
                      className="input-group-prepend eyeipbox profiladduserpass"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowNewPassword(false)}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </div>
                  </div>
                ) : (
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter New Password"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        onFocus={() => setShowPasswordTooltip(true)}
                        onBlur={() => setShowPasswordTooltip(false)}
                      />
                      <div
                        className="input-group-prepend eyeipbox profiladduserpass"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowNewPassword(true)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </div>
                    </div>
                  )}
                {showPasswordTooltip && (
                  <div className="toolbox_resetpassword">
                    <div className="passlist">
                      <p className="passmust">Password Must</p>
                      <span>
                        <span className="passlisticon">
                          <FontAwesomeIcon
                            icon={faCircle}
                            color={atLeasetDigit.test(newPassword) ? 'green' : ''}
                          />
                        </span>
                      Have One Number
                    </span>
                      <br />
                      <span>
                        <span className="passlisticon">
                          <FontAwesomeIcon
                            icon={faCircle}
                            color={atLeasetUpperCase.test(newPassword) ? 'green' : ''}
                          />
                        </span>
                      Have One Uppercase Character
                    </span>
                      <br />
                      <span>
                        <span className="passlisticon">
                          <FontAwesomeIcon
                            icon={faCircle}
                            color={atLeasetLowerCase.test(newPassword) ? 'green' : ''}
                          />
                        </span>
                      Have One Lowercase Character
                    </span>
                      <span>
                        <br />
                        <span className="passlisticon">
                          <FontAwesomeIcon
                            icon={faCircle}
                            color={atLeasetEight.test(newPassword) ? 'green' : ''}
                          />
                        </span>
                      Have 8 Characters minimum
                    </span>

                    </div>
                  </div>
                )}
                {formErrors.newPassword ? <span className="error_message">{formErrors.newPassword}</span> : null}
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="inputEmail4">
                  Confrm Password
                  <span className="red_text">*</span>
                </label>

                {showConfirmPassword ? (
                  <div className="input-group mb-3">
                    <input
                      type="input"
                      className="form-control"
                      placeholder="Enter Confirm Password"
                      autoComplete="new-password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div
                      className="input-group-prepend eyeipbox profiladduserpass"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowConfirmPassword(false)}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </div>
                  </div>
                ) : (
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Confirm Password"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div
                        className="input-group-prepend eyeipbox profiladduserpass"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowConfirmPassword(true)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </div>
                    </div>
                  )}
                {formErrors.confirmPassword ? <span className="error_message">{formErrors.confirmPassword}</span> : null}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="text-center mb-2">
                <button className="light_color_bg profilsavebtn" type="submit" disabled={loading}>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {' '}
                      Loading...
                    </>
                  ) : 'Save'}
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
  );
};

export default PasswordUpdate;
