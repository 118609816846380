import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { BASE_PATH, DIFF_LAST_SYNC_DAYS } from '../../app/constants';
import ReturnSummary from './returnsDashboardV2/ReturnSummary';
import AllChallans from './returnsDashboardV2/AllChallans';
import OutstandingDemand from './returnsDashboardV2/OutstandingDemand';
import QuarterLiabilityPaid from './returnsDashboardV2/QuarterLiabilityPaid';
import NoticeOrdersList from './returnsDashboardV2/NoticeOrdersList';
import { convertNum, getCurrentFinancialYear, getDaysDifference } from '../../utils/UtilityFunctions';
import TopDeductees from './returnsDashboardV2/TopDeductees';
import "./DeductorDashboardV2.scss"
import { gocallApi } from '../../api/issueCertificateAPI';
import ShowAlert from '../../components/ShowAlert';
import DeductorSyncClient from '../createDeductor/DeductorSyncClient';
//import ConfirmAlertPopup from '../../components/ConfirmAlertPopup';
import WindowConfirmPopup from '../../components/WindowConfirmPopup';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { getDeductorEfilingCredentials } from '../../api/deductorAPI';
import { getSyncStatusWithoutSettingHeader } from '../../api/ReturnRegister';
//import ImportExcelFile from './salaryTdsCalculator/ImportExcelFile';

const DeductorDashboardV2 = () => {

    const qurtliabilityRef = useRef();
    const topDeducteesRef = useRef();
    const outStandingRef = useRef();
    const headerData = useSelector(selectHeaderData);
    const history = useHistory();

    const PAN_PIECHART_LIST = {
        cnt_inactive_pan: { key: "Inoperative", color: "#f54337", pattern: "verticalLines" },
        cnt_valid_pan: { key: "Operative", color: "#8bc34a", pattern: "horizontalLines" },
        cnt_traces_invalid_pan: { key: "Invalid", color: "#f54337", pattern: "squares" },
        cnt_unverified_pan: { key: "Unverified", color: "#f54337", pattern: "circles" },
        cnt_not_available_pan: { key: "Pannotavbl", color: "#fdd735", pattern: "slantedLines" },
    }

    const COMPLIANCE_PIECHART_LIST = {
        cnt_insights_pending: { key: "Pending", color: "#f54337", pattern: "verticalLines" },
        cnt_unverified_filer_pan: { key: "Unverified", color: "#f54337", pattern: "circles" },
        cnt_invalid_filer_pan: { key: "Invalid", color: "#f54337", pattern: "squares" },
        cnt_insights_regular_filer_operative: { key: "Reg Filer", color: "#8bc34a", pattern: "horizontalLines" }, // added (cnt_insights_regular_filer_inoperative + cnt_insights_regular_filer_operative) 
        cnt_insights_non_filer_operative: { key: "Non Filer", color: "#f54337", pattern: "slantedLines" }, // added (cnt_insights_non_filer_operative + cnt_insights_non_filer_inoperative)
    }

    const location = useLocation();
    const params = queryString.parse(location.search);
    const deductorId = params?.deductor_id ?? ""
    const financial_year = !isNaN(params?.financial_year) ? params?.financial_year : getCurrentFinancialYear();

    const [totalDeductee, setTotalDeductee] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [error, setError] = useState(false);
    const [chartDataSkeleton, setChartDataSkeleton] = useState(true);
    const [panStatusData, setPanStatusData] = useState({});
    const [success, setSuccess] = useState(false);

    const [latestSyncStatus, setLatestSyncStatus] = useState(null);
    const [useCreditional, setUserCreditional] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [returnId, setReturnId] = useState(false);
    const [startFilingPopup, setStartFilingPopup] = useState(false);

    //const [importExcel, setImportExcel] = useState(false);


    const getPieChartData = async (deductor_Id,) => {

        const url = `/api/v1/report/deductor/pan-status-wise-deductee-summary?deductor_id=${deductor_Id}`;

        try {
            const result = await gocallApi("get", url);
            let creatChart = {
                complianceCheck: {
                    statusWiseValue: [],
                    statusWiseColor: [],
                    status: [],
                    pattern: []
                },
                tracesPan: {
                    statusWiseValue: [],
                    statusWiseColor: [],
                    status: [],
                    pattern: []
                }
            };

            const data = result?.data ?? {};

            if (result.data && Object.keys(result.data).length > 0) {
                Object.keys(PAN_PIECHART_LIST).forEach((key) => {
                    if (key === "cnt_not_available_pan") {
                        const cnt_ntavil = convertNum(data.cnt_applied_pan) + convertNum(data.cnt_not_available_pan) + convertNum(data.cnt_invalid_pan)
                            + convertNum(data.cnt_traces_applied_pan) + convertNum(data.cnt_traces_not_available_pan);

                        creatChart.tracesPan.statusWiseValue.push(cnt_ntavil);
                        creatChart.tracesPan.statusWiseColor.push(PAN_PIECHART_LIST?.[key].color);
                        creatChart.tracesPan.pattern.push(PAN_PIECHART_LIST?.[key].pattern);
                        creatChart.tracesPan.status.push(PAN_PIECHART_LIST?.[key]?.key);
                    } else {
                        creatChart.tracesPan.statusWiseValue.push(data?.[key] ?? 0);
                        creatChart.tracesPan.statusWiseColor.push(PAN_PIECHART_LIST?.[key]?.color);
                        creatChart.tracesPan.pattern.push(PAN_PIECHART_LIST?.[key]?.pattern);
                        creatChart.tracesPan.status.push(PAN_PIECHART_LIST?.[key]?.key);
                    }
                })
            }

            Object.keys(COMPLIANCE_PIECHART_LIST).forEach((key) => {
                if (key === "cnt_insights_regular_filer_operative") {
                    const regFilerCnt = convertNum(data?.cnt_insights_regular_filer_inoperative) + convertNum(data?.cnt_insights_regular_filer_operative);
                    creatChart.complianceCheck.statusWiseValue.push(regFilerCnt);
                    creatChart.complianceCheck.statusWiseColor.push(COMPLIANCE_PIECHART_LIST?.[key]?.color);
                    creatChart.complianceCheck.pattern.push(COMPLIANCE_PIECHART_LIST?.[key]?.pattern);
                    creatChart.complianceCheck.status.push(COMPLIANCE_PIECHART_LIST?.[key]?.key);
                } else if (key === "cnt_insights_non_filer_operative") {
                    const nonFilerCnt = convertNum(data?.cnt_insights_non_filer_inoperative) + convertNum(data?.cnt_insights_non_filer_operative);
                    creatChart.complianceCheck.statusWiseValue.push(nonFilerCnt);
                    creatChart.complianceCheck.statusWiseColor.push(COMPLIANCE_PIECHART_LIST?.[key]?.color);
                    creatChart.complianceCheck.pattern.push(COMPLIANCE_PIECHART_LIST?.[key]?.pattern);
                    creatChart.complianceCheck.status.push(COMPLIANCE_PIECHART_LIST?.[key]?.key);
                }else if(key === "cnt_invalid_filer_pan"){
                    const invalidPan = convertNum(data.cnt_applied_pan) + convertNum(data.cnt_not_available_pan) + convertNum(data.cnt_invalid_pan)
                    + convertNum(data.cnt_invalid_filer_pan);
                    
                    creatChart.complianceCheck.statusWiseValue.push(invalidPan);
                    creatChart.complianceCheck.statusWiseColor.push(COMPLIANCE_PIECHART_LIST?.[key]?.color);
                    creatChart.complianceCheck.pattern.push(COMPLIANCE_PIECHART_LIST?.[key]?.pattern);
                    creatChart.complianceCheck.status.push(COMPLIANCE_PIECHART_LIST?.[key]?.key);
                }
                 else {
                    creatChart.complianceCheck.statusWiseValue.push(data?.[key] ?? 0);
                    creatChart.complianceCheck.statusWiseColor.push(COMPLIANCE_PIECHART_LIST?.[key]?.color);
                    creatChart.complianceCheck.pattern.push(COMPLIANCE_PIECHART_LIST?.[key]?.pattern);
                    creatChart.complianceCheck.status.push(COMPLIANCE_PIECHART_LIST?.[key]?.key);
                }
            })

            setPanStatusData(result?.data ?? {});
            setTotalDeductee(result?.data?.cnt_deductee ?? 0);
            setChartData(creatChart);

        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setChartDataSkeleton(false);
        }
    }

    const getItdCreditional = async (deductor_Id) => {
        setLoading(true);
        const creditional = {
            'itdportal_username': headerData.tan,
            'itdportal_password': "",
            "deductor_id": deductor_Id
        }
        try {
            const result = await getDeductorEfilingCredentials(`?deductor_id=${deductor_Id}`, false);

            creditional.itdportal_username = result?.data?.itdportal_username ?? ''
            creditional.itdportal_password = result?.data?.itdportal_password ?? ''

        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            setUserCreditional((prev) => {
                prev = creditional;
                return { ...prev };
            })
        }
    }


    const syncStatusOnServer = async (deductorId) => {
        try {
            const result = await getSyncStatusWithoutSettingHeader(deductorId);
            setLatestSyncStatus(result?.data ?? null);
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        }
    };

    const boldtext = (diffDays) => {
        return (
            <p>
                Challan Balances are&nbsp;
                <span style={{ fontWeight: 'bold' }}>
                    {diffDays} Days Old.
                </span>
                &nbsp;You are advised to update them. This would not hinder your work and will happen in the background.
            </p>
        )
    }


    const indiviualQuarterSync = (dataItem) => {

        const sync_date = latestSyncStatus?.sync_updated_at ? moment(latestSyncStatus?.sync_updated_at?.substr(0, 10), 'DD/MM/YYYY').format('YYYY-MM-DD') : null
        const currentDate = moment(new Date()).format('YYYY-MM-DD');

        var diffDays = sync_date ? getDaysDifference(sync_date, currentDate) : null;
        if (!latestSyncStatus.sync_updated_at || (diffDays && diffDays > DIFF_LAST_SYNC_DAYS)) {
            let obj = {};
            obj.message = diffDays ?
                boldtext(diffDays)
                : `It Appears that challan balances never got updated. You are advised to update them. This would not hinder your work and will happen in the background.`;
            setConfirmPopup(obj);
            setReturnId(dataItem?.return_id);
        } else {
            history.push(`${BASE_PATH}deductee-transactions?page=1&deductor_id=${deductorId}&return_id=${dataItem?.return_id}&financial_year=${params.financial_year ?? getCurrentFinancialYear()}`)
        }

    }

    useEffect(() => {
        getPieChartData(params.deductor_id);
        syncStatusOnServer(params.deductor_id);
        // eslint-disable-next-line 
    }, [params.deductor_id])

    return (
        <>

            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            {loading && <LoadingIndicator />}

            {useCreditional &&
                <DeductorSyncClient
                    syncDeductor={useCreditional}
                    deductorId={params?.deductor_id ?? ""}
                    financial_year={params?.financial_year ?? getCurrentFinancialYear()}
                    lastSyncStatus={() => {
                        // temprory commit localstorage function 
                        // if (syncClient[headerData.tan]) {
                        //     syncClient[headerData.tan] = false;
                        //     localStorage.setItem(`sync-update-${headerData.mobile_number}`, JSON.stringify(syncClient));
                        // }

                        history.push(`${BASE_PATH}deductee-transactions?page=1&deductor_id=${deductorId}&return_id=${returnId}&financial_year=${params.financial_year ?? getCurrentFinancialYear()}`)
                    }}
                    tracesSyncStart={true}
                    latestSyncStatus={latestSyncStatus}
                />
            }

            {confirmPopup &&
                <WindowConfirmPopup
                    show={true}
                    handleClose={() => setConfirmPopup(false)}
                    handleProceed={() => {
                        setConfirmPopup(false);
                        getItdCreditional(params.deductor_id)
                    }}
                    confirmText={confirmPopup.message}
                    handleProceed2={() => {
                        history.push(`${BASE_PATH}deductee-transactions?page=1&deductor_id=${deductorId}&return_id=${returnId}&financial_year=${params.financial_year ?? getCurrentFinancialYear()}`)
                        setConfirmPopup(false);
                    }}
                    title={"Alert"}
                    confirmButtonText={"Update My Challan Balance"}
                    confirmButtonTextStyle={{ backgroundColor: '#31719b', color: '#ffffff' }}
                    confirmButtonText2={"Skip"}
                    confirmButtonText2Style={{ backgroundColor: '#f2f2f2', color: '#636363' }}
                />
            }
           

            <div className="deductordashboadscss container-fluid">
                <ReturnSummary
                    deductorId={deductorId}
                    financialYear={parseInt(financial_year)}
                    panStatusData={panStatusData}
                    latestSyncStatus={latestSyncStatus}
                    indiviualQuarterSync={indiviualQuarterSync}
                    startFilingPopup={startFilingPopup}
                    setStartFilingPopup={setStartFilingPopup}
                />
                <div className="row pt-2 mb-3" >
                    <NoticeOrdersList
                        deductor_Id={deductorId}
                        financialYear={parseInt(financial_year)}
                    />
                    <AllChallans
                        deductorId={deductorId}
                        financialYear={financial_year}
                    />
                </div>
                <div className="row" >
                    <OutstandingDemand
                        deductor_Id={deductorId}
                        financialYear={parseInt(financial_year)}
                        outStandingRef={outStandingRef}
                    />
                </div>

                <div className="row mb-4" ref={qurtliabilityRef}>
                    <QuarterLiabilityPaid
                        deductor_Id={deductorId}
                        qurtliabilityRef={qurtliabilityRef}
                        financialYear={parseInt(financial_year)}
                        totalDeductee={totalDeductee}
                        chartData={chartData}
                        error1={error}
                        chartDataSkeleton={chartDataSkeleton}
                        setError1={setError}
                        getPieChartData={getPieChartData}
                        setStartFilingPopup={setStartFilingPopup}
                        panStatusData={panStatusData}
                    />
                </div>

                <div className="row mb-4" ref={topDeducteesRef} >
                    <TopDeductees deductor_Id={deductorId} topDeducteesRef={topDeducteesRef} financialYear={parseInt(financial_year)} setStartFilingPopup={setStartFilingPopup} />
                </div>


                {/* <div className="dash_rightside_certifbox">
                    <Link className="mb-0 btn dash_rightside_certifbox_textfs planexpiredalert" to={`${BASE_PATH}issue-tds-tcs-certificates/${deductorId}?financial_year=${parseInt(financial_year)}`}>
                        Certificate Management - Issue Form 16/16A
                    </Link>
                </div> */}

            </div>

        </>
    )
}

export default DeductorDashboardV2;