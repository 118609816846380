import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import AddRaiseRequestForm from '../../returnRaise/AddRaiseRequestForm'
import ShowAlert from '../../../components/ShowAlert';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { selectHeaderData } from '../../header/headerSlice';
import { useSelector } from 'react-redux';
import ImportFUVFile from '../../returnRaise/importFUVFile/ImportFUVFile';
import { getPreRaiseRequestData } from '../../../api/returnAPI';
import { useEffect } from 'react';
import { BASE_PATH } from '../../../app/constants';
import RaiseReqSubmited from '../../returnRaise/RaiseReqSubmited';
import { getCurrentFinancialYear } from '../../../utils/UtilityFunctions';

const RaisedRequestFormV2 = () => {

    const history = useHistory();
    const headerDataDetails = useSelector(selectHeaderData);
    const location = useLocation();
    const params = queryString.parse(location.search);
    const deductorId = params?.deductor_id ?? "";
    const returnId = params.return_id ?? "";
	const financialYear = headerDataDetails?.financial_year_formatted ? headerDataDetails?.financial_year_formatted?.split("-")?.[0] : getCurrentFinancialYear();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showImportFUVFile, setShowImportFUVFile] = useState(false);
    const [importFUVFile, setImportFUVFile] = useState(false);
    const [submitBtnProceed, setSubmitBtnProceed] = useState(false);
    const [importFUVFileValues, setImportFUVFileValues] = useState({});
    const [deductorPan] = useState('');
    const [updateRaiseReq, setUpdateRaiseReq] = useState(false);
    const [regularReturnAvailble, setRegularReturnAvailble] = useState(false);
    const [showRaiseReqSubmited, setShowRaiseReqSubmited] = useState(false);
    const [raiseReqSubmitedResult, setRaiseReqSubmitedResult] = useState({});
    const [inputVal, setInputVal] = useState(false);

    const onClose = (reload, msg = '') => {
        if (msg) {
            setSuccess(msg);
        }
    };

    const handleProceedImportFUVFile = (result) => {
       
        setShowImportFUVFile(false);
        setImportFUVFileValues(result);
        setImportFUVFile(false);
    };

    const submitUnsetBtnProceed = () => {
        setSubmitBtnProceed(false);
    };

    const handleSucessAdd = (result = {}) => {
        setShowRaiseReqSubmited(true);
        setRaiseReqSubmitedResult(result);
    };

    const setInputValFunc = async () => {
        setInputVal(false);
    };

   

   

   

    const fetchPreRaiseRequestData = async (deductor_id, return_id) => {
        setLoading(true);
		try {
			const result = await getPreRaiseRequestData(`?deductor_id=${deductor_id}&return_id=${return_id}`);
			// console.log("getPreRaiseRequestData", result)
			if (result.status === 1) {
				setRegularReturnAvailble(result?.data?.enable_challan_section ?? true);
				setImportFUVFileValues(result);
				setUpdateRaiseReq(true);
				setInputVal(true);
			} else {
				setUpdateRaiseReq(false);
			}
		} catch (err) {
			console.error('Error: ', err);
            if(err.message) setError(err.message);
            else setError(err);
		}finally{
            setLoading(false);
        }
	};

    useEffect(() => {
      fetchPreRaiseRequestData(deductorId, returnId);
    },[deductorId, returnId])

    return (
        <>
			<ShowAlert
                success={success}
				error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />            

			{loading ? <LoadingIndicator /> : null}

			{showImportFUVFile ? (
				<ImportFUVFile
					show={showImportFUVFile}
					handleClose={(_refresh) => {
						setShowImportFUVFile(false);
					}}
					deductorId={deductorId}
					returnId={returnId}
					handleProceedImportFUVFile={handleProceedImportFUVFile}
				/>
			) : null}

			{showRaiseReqSubmited ? (
				<RaiseReqSubmited
					show={showRaiseReqSubmited}
					handleClose={(_refresh) => {
						setShowRaiseReqSubmited(false);
						history.push({
							pathname: `${BASE_PATH}raised-requests-list/${deductorId}`,
						});
					}}
					deductorId={deductorId}
					returnId={returnId}
					raiseReqSubmitedResult={raiseReqSubmitedResult}
				/>
			)
				: null}
			<div className="container">
				<div className="row mt-2">
					<div className="col-md-12 mt-2">
						<span className="link backbtntext" variant="link" style={{ color: '#FC7D72' }} onClick={() => history.push(`${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${financialYear}`)}>
							{'< '}
							Back to Deductor Dashboard
						</span>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-12">

						
							<AddRaiseRequestForm
								onClose={onClose}
								deductorId={deductorId}
								headerDataDetails={headerDataDetails}
								setShowImportFUVFile={setShowImportFUVFile}
								importFUVFileValues={importFUVFileValues}
								importFUVFile={importFUVFile}
								submitBtnProceed={submitBtnProceed}
								submitUnsetBtnProceed={submitUnsetBtnProceed}
								deductorPan={deductorPan}
								returnId={returnId}
								handleSucessAdd={handleSucessAdd}
								updateRaiseReq={updateRaiseReq}
								setInputValFunc={setInputValFunc}
								inputVal={inputVal}
								regularReturnAvailble={regularReturnAvailble}
								handleProceedImportFUVFile={handleProceedImportFUVFile}
							/>
						

					</div>
				</div>
			</div>

		</>
    )
}

export default RaisedRequestFormV2