import { useEffect } from 'react';
import { HEADER_CONNECTOR_DIGEST_KEY, CONNECTOR_ERRORS_MAPPING } from '../app/constants';
import { getReturnTrailConnectorApi } from '../api/localConnectorAPI';
import { getReturnStatement } from '../api/serverConnectorAPI';

const SyncData = (props) => {
  const { deductorId, returnItem, port, captchaRef, handleProceed, setSuccess, setError, setShowSyncVerify, setSyncStart, setLoading } = props;
  const returnAction = () => {
    if(setSyncStart) setSyncStart(true);

    async function fetchEverything() {
      const requestParams = {
        "deductor_id": deductorId,
        "financial_year": returnItem?.financial_year ?? "",
        "quarter": returnItem?.quarter ?? "",
        "form_type": returnItem?.form_type ?? "",
        "captcha_ref": captchaRef
      }
      try {
        if(setLoading){setLoading(true)}
        const result = await getReturnStatement(requestParams);
        const responseData = result.data ?? null;
        const responseHeader = result.headers ?? null;

        //Connector request data
        const connectorRequest = {
          "data": responseData.data,
        };
        let extraHeader = {};
        extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

        if (setSuccess !== undefined) {
          setSuccess('Data sync process started successfully');
        }
        if (setShowSyncVerify !== undefined) {
          setShowSyncVerify(true);
        }

        try {

          const connectorResponse = await getReturnTrailConnectorApi(port, connectorRequest, extraHeader);

          if (Number(connectorResponse.status) === 1) {

            if (handleProceed !== undefined) {

              handleProceed();

            }

          }

        } catch (err) {

          if (err?.message?.[0]) {
            setError(CONNECTOR_ERRORS_MAPPING[err.message[0]] ?? err.message[0]);
          } else {
            setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? 'Error occure');
          }
          if(setSyncStart) setSyncStart(false)

        }


      } catch (err) {

        setError(err.message);
        if(setSyncStart) setSyncStart(false)
      } finally {
        if(setLoading){setLoading(false)}
      }
    }
    fetchEverything()
  }

  useEffect(() => {
    returnAction();
    //eslint-disable-next-line
  }, [])


  return (null);
};

export default SyncData;
