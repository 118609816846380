import React, { useEffect, useState } from 'react'
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';
import { VALIIDATION_SCHEMA } from '../salaryHelper';
import { getCommutedPensionExemptAmt } from '../../../components/salaryDetailsForm/allowancesCalculationScema';

const ComputedPension = (props) => {
    const { onClose, parentPopupWidth, info, mainData, updateEmployeeDetail, onlyView=false } = props;
    const index = info?.index ?? 0;
    const defaultObj = {
        whether_gov_emp: 0,
        whether_gratuity_rec: 0,
        commuted_pension_amtp: 0,
        commuted_pension_percent_b100: 100,
        exempt_amtp: 0,
    }

    const width1 = document.body.clientWidth;
    const popupWidth = 700;
    const additionalGap = 10;
    const initialLeft = width1 - (parentPopupWidth + (popupWidth + additionalGap));
    const [computedPension, setComputedPension] = useState({});
    const [formError, setFormError] = useState({});

    const calculateExemptAmt = (values) => {
        let exempt_amtp = getCommutedPensionExemptAmt(values);
        values.exempt_amtp = Number(exempt_amtp);

    }

    const handleChange = (name, value) => {
        computedPension[name] = value;
        calculateExemptAmt(computedPension)
        setComputedPension({ ...computedPension });
    }

    const saveData = () => {
        const valid = VALIIDATION_SCHEMA.commutedPensionValidationSchema(computedPension);
        if (!valid.validation) {
            setFormError(valid.errObj);
            return;
        }
        if (!mainData?.allowances?.[index]) mainData.allowances[index] = {};
        mainData.allowances[index].allowance_details = computedPension;
        updateEmployeeDetail(mainData, onClose);
    };

    useEffect(() => {
        const data = info?.poppupData ?? defaultObj;
        setComputedPension({ ...data })
        //eslint-disable-next-line
    }, [info])

    return (
        <>
            <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ padding: '8px 0px 3px 0px', width: "1000%" }}>
                    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <span className="taxregim_textfs pr-3">
                                Commuted Pension u/s 10(10A)
                            </span>
                        </div>
                    </div>
                </kendo-dialog-titlebar>}
                onClose={onClose}
                initialHeight={'auto'}
                initialWidth={popupWidth}
                initialTop={50}
                totalPopups={1}
                isModalView={true}
                initialLeft={initialLeft}
                className={'salarary_detail_popup'}
            >
                <div className="pb-2 pt-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card salary_taxreg_allowance">
                                <div className="form form-row salary_taxreg_allowance_form">
                                    <div className="form-group col-md-3">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Whether Cent Govt Employ</label>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <select name="whether_gov_emp" className="custom-select salary_taxreg_allowance_selbox" disabled={onlyView}
                                            value={computedPension?.whether_gov_emp ? 1 : 0}
                                            onChange={(e) => {
                                                handleChange("whether_gov_emp", convertNum(e.target.value));
                                            }}
                                        >
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Whether Gratuity Rec</label>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <select name="whether_gov_emp" className="custom-select salary_taxreg_allowance_selbox" disabled={onlyView}
                                            value={computedPension?.whether_gratuity_rec ? 1 : 0}
                                            onChange={(e) => {
                                                handleChange("whether_gratuity_rec", convertNum(e.target.value));
                                            }}
                                        >
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Commuted Pension Amount</label>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.commuted_pension_amtp ? "input-error" : ""}`}
                                        placeholder="" data-toggle="tooltip"
                                        data-placement="right" data-original-title={formError?.commuted_pension_amtp ? formError?.commuted_pension_amtp : ""}
                                            value={FormatNumberDecimal(computedPension.commuted_pension_amtp, ...Array(2), false)}
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                handleChange("commuted_pension_amtp", value);
                                            }}
                                            disabled={onlyView}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Commuted Pension %</label>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.commuted_pension_percent_b100 ? "input-error" : ""}`}
                                        placeholder="" data-toggle="tooltip"
                                        data-placement="right" data-original-title={formError?.commuted_pension_percent_b100 ? formError?.commuted_pension_percent_b100 : ""}
                                            value={FormatNumberDecimal(computedPension.commuted_pension_percent_b100, 3, ...Array(1), false)}
                                            onChange={(e) => {
                                                if (e.target.value === '' || parseFloat(e.target.value) <= 100) {
                                                    const value = FormatNumberDecValue1(e.target.value, 3, ...Array(3), e);
                                                    handleChange("commuted_pension_percent_b100", value);
                                                }
                                            }}
                                            disabled={onlyView}
                                        />
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Exempt Amt</label>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox" placeholder="" disabled
                                            value={FormatNumberDecimal(computedPension.exempt_amtp, ...Array(2), false)}

                                        />
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 pt-2 text-center">
                            <button className="btn btn-default salary_taxreg_allowance_savebtn" disabled={onlyView}
                                onClick={() => saveData()}
                            >Save & Exit</button>
                        </div>
                    </div>
                </div>
            </WindowWrapperFixed>
        </>
    )
}

export default ComputedPension