import React, { useState, useEffect } from 'react';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import './PanWiseTanDetails.scss';
import { getTanWiseReturnSummary } from '../../api/form3cdApi.js';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import WindowWrapperFixed from '../../components/grid/WindowWrapperFixed';
import moment from 'moment';

const TransDataSourcePopup = (props) => {
    const { handleClose, params } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [origReportData, setOrigReportData] = useState([]);
   
    // Define quarters and forms
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const forms = ['24Q', '26Q', '27Q', '27EQ']; 

    // Function to fetch tan-wise return summary
    const tanWiseReturnSummaryData = async (data) => {
        setLoading(true);
        try {
            const result = await getTanWiseReturnSummary(data.pan, data.financial_year);
            setOrigReportData(result?.data ?? []);

            const uniqueTANs = result.data?.length > 0 ? [...new Set(result.data.map(item => item.tan))] : [];
            const transformedData = [];

            uniqueTANs.forEach(tan => {
                forms.forEach(form => {
                    let row = { tan, form };
                    quarters.forEach(quarter => {
                        const item = result.data.find(d => d.tan === tan && d.form_type === form && d.quarter === quarter);
                        row[quarter] = item?.trans_data_source;
                        row["deductor_id"] = item?.deductor_id;
                    });
                    transformedData.push(row);
                });
            });

            setReportData(transformedData);
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const getParamsHistoryUrl = () => {
        const postData = {
            pan: params.pan
        };
        if (params.fy) {
            postData.financial_year = params.fy;
        }
        return postData;
    };

    useEffect(() => {
        const queryData = getParamsHistoryUrl();
        tanWiseReturnSummaryData(queryData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);



    function findDatePerQuater(tan, quarter, formType, transDataSource) {
    const match = origReportData.find(item => 
        item.tan === tan &&
        item.quarter === quarter &&
        item.form_type === formType &&
        item.trans_data_source === transDataSource
    );
    
    return match ? match.data_as_on : null;
    }

    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;
        const item = props.dataItem ?? {}; // Ensure dataItem is defined

        const boxClass1 = (item.Q1 === "CONSO" || item.Q1 === "TXT") ? ((item.Q1 === "CONSO") ? "box-conso center-div" : "box-txt center-div") : "";
        const boxClass2 = (item.Q2 === "CONSO" || item.Q2 === "TXT") ? ((item.Q2 === "CONSO") ? "box-conso center-div" : "box-txt center-div") : "";
        const boxClass3 = (item.Q3 === "CONSO" || item.Q3 === "TXT") ? ((item.Q3 === "CONSO") ? "box-conso center-div" : "box-txt center-div") : "";
        const boxClass4 = (item.Q4 === "CONSO" || item.Q4 === "TXT") ? ((item.Q4 === "CONSO") ? "box-conso center-div" : "box-txt center-div") : "";

        const getTooltip = (quarter, transDataSource, formType, tan) => {
            let getTooltip;            
            const getDate = findDatePerQuater(tan, quarter, formType, transDataSource);
            if(getDate && transDataSource === "CONSO"){
                getTooltip = `As on Date: ${moment(getDate).format('DD/MM/YYYY')}`;
            } else if(getDate && transDataSource === "TXT"){
                getTooltip = `Date of Creation: ${moment(getDate).format('DD/MM/YYYY')}`;
            }else {
                getTooltip =  null;
            }
            return getTooltip;
        };
          
        const box1Tooltip = (item.Q1 === "CONSO" || item.Q1 === "TXT") ? getTooltip( "Q1", item.Q1, item.form, item.tan): "";
        const box2Tooltip = (item.Q2 === "CONSO" || item.Q2 === "TXT") ? getTooltip( "Q2", item.Q2, item.form, item.tan): "";
        const box3Tooltip = (item.Q3 === "CONSO" || item.Q3 === "TXT") ? getTooltip( "Q3", item.Q3, item.form, item.tan): "";
        const box4Tooltip = (item.Q4 === "CONSO" || item.Q4 === "TXT") ? getTooltip( "Q4", item.Q4, item.form, item.tan): "";

        return (
            <tr style={{ fontWeight: 'normal' }}>
                {(key % 4) === 0 &&
                    <td width="250" className="text-center report34a_vertical tan-summary-tancol-bglcr-white" rowSpan={4} >
                        {item.tan}
                    </td>
                }

                <td width="150" className="text-center report34a_vertical">
                    {item.form}
                </td>
                <td width="100" className="text-center report34a_vertical">
                    {boxClass1 !== "" ? <div className={boxClass1} title={box1Tooltip}></div> : "NA"}
                </td>
                <td width="100" className="text-center report34a_vertical">
                    {boxClass2 !== "" ? <div className={boxClass2} title={box2Tooltip}></div> : "NA"}
                </td>
                <td width="100" className="text-center report34a_vertical">
                    {boxClass3 !== "" ? <div className={boxClass3} title={box3Tooltip}></div> : "NA"}
                </td>
                <td width="100" className="text-center report34a_vertical">
                    {boxClass4 !== "" ? <div className={boxClass4} title={box4Tooltip}></div> : "NA"}
                </td>
            </tr>
        );
    };

    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            <WindowWrapperFixed
                title={<div><span>Source Data</span></div>}
                onClose={handleClose}
                totalPopups={1}
                initialLeft={768 / (3)}
                initialTop={ 176}
                initialHeight="auto"
                initialWidth="50%"
                index={1}
                ValueNotUpdate={true}
                className="fillinglistwindowrapper"
                style={{ overflowX: 'hidden' }}
                isModalView={true}
            >
                <div >
                    <div className="container mt-2">
                        <div className="row">
                            <div className="col-md-12 text-center pt-2">
                                <div className="card tds_form3CDRevamp_genrepottyp_cardbox" >
                                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                                        <Grid
                                            className="table table-striped  tanwise_returnsumm_tb mb-0"
                                            data={reportData}
                                            rowRender={rowRender}
                                        >
                                            <Column
                                                width="130"
                                                field="tan"
                                                title='Deductor Name (TAN)'
                                            />
                                            <Column
                                                width="80"
                                                field="form_type"
                                                title='Form Type'
                                            />
                                            <Column
                                                width="100"
                                                field="return_filing_due_date"
                                                title='Q1'
                                                headerClassName='linkmodal_textleft'
                                            />
                                            <Column
                                                width="100"
                                                field="return_filing_date"
                                                title="Q2"
                                                headerClassName='linkmodal_textleft'
                                            />
                                            <Column
                                                width="100"
                                                field=""
                                                className='long-column-title'
                                                title="Q3"
                                                headerClassName='linkmodal_textleft'
                                            />
                                            <Column
                                                width="100"
                                                field=""
                                                title="Q4"
                                                className='long-column-title'
                                                headerClassName='linkmodal_textleft'
                                            />
                                        </Grid>
                                    </div>
                                </div>
                                <div className='text-left row pt-2'>
                                    <div className='col-md-3'>
                                        <div className='form3cd_panwise_legendcardbox'>
                                            <span className='pl-2'>NA </span><span className='pl-3'>Data Not Available</span>
                                        </div>
                                        
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form3cd_panwise_legendcardbox'>
                                            <div className='box-txt'></div> <label className='pl-2 src-lbl-txt'>TXT File</label>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form3cd_panwise_legendcardbox'>
                                            <div className='box-conso'></div> <label className='pl-2 src-lbl-txt'>CONSO File</label>
                                        </div>
                                    </div>
                                    <div className='col-md-3'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WindowWrapperFixed>
        </>
    );
};

export default TransDataSourcePopup;
