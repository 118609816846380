
/*eslint-disable no-useless-escape */
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
//import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "./DateRangePicker.scss";

const DateRangePickers = (props) => {
    const { onApply, onHide, initialSettings, disabled } = props;

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
        onApply(event.target.value);
    };

    return (
        <DateRangePicker
            initialSettings={initialSettings}
            onApply={handleApply}
            onHide={(e) => {
                onHide(e.target.value);
            }}
            
        >
            <input type="text" disabled={disabled} className="form-control" placeholder="DD/MM/YYYY - DD/MM/YYYY" onChange={(e) => { onApply(e.target.value); }} />
        </DateRangePicker>
    );
};

export default DateRangePickers;
