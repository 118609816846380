import React, { useEffect, useState } from 'react'
import ShowAlert from '../../components/ShowAlert';
import { gocallApi } from '../../api/issueCertificateAPI';
import { convertNum } from '../../utils/UtilityFunctions';
import { CONNECTOR_ERRORS_MAPPING } from '../../app/constants';
import DraggableContent from '../../components/grid/DraggableContent';

const EmailProgressBarPdf = (props) => {
  const { progressBarStart, formType, deductorId, quarter, year, emailType, SetProgessBarStart } = props;

  const [showProgessBar, SetShowProgressBar] = useState(false);
  const [progessBarData, SetProgessBarData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const checkEmailProgress = async (checkStatus) => {
    const type = 'get';
    const geturl = `/api/v1/tds-tcs-cert/mail-status?deductor_id=${deductorId}&financial_year=${year}&quarter=${quarter}&form_type=${formType}&pdf_type=${emailType}`;
    try {
      const result = await gocallApi(type, geturl);
      if (result?.data) {
        if (result.data?.email_status === "IN_PROGRESS") {
          SetProgessBarData(result.data ?? {});
          SetShowProgressBar(true);
          const cal_pb_val = (convertNum(result?.data?.email_sent_count) * 100) / result.data.email_total_count;
          result.data.pb_val = cal_pb_val;
          setTimeout(() => {
            checkEmailProgress(true);
          }, 4000);
        } else if (result.data?.email_status === "COMPLETE") {
          const cal_pb_val = (convertNum(result?.data?.email_sent_count) * 100) / result.data.email_total_count;
          result.data.pb_val = cal_pb_val;
          SetProgessBarData(result.data ?? {});
          setTimeout(() => {
            SetShowProgressBar(false);
            if (checkStatus) {
              setSuccess("Email Sent Successfully.");
              if (progressBarStart) SetProgessBarStart(false);
            }
          }, 4000);

        } else if (result.data?.email_status === "FAILURE") {
          SetShowProgressBar(false);
          if (checkStatus) {
            setSuccess(CONNECTOR_ERRORS_MAPPING['default']);
            if (progressBarStart) SetProgessBarStart(false);
          }
        }
      }
    } catch (err) {
      SetProgessBarStart(false);
    }

  }

  const emailStatus = async () => {
    const type = 'get';
    const geturl = `/api/v1/tds-tcs-cert/mail-status?deductor_id=${deductorId}&financial_year=${year}&quarter=${quarter}&form_type=${formType}&pdf_type=${emailType}`;
    try {
      const result = await gocallApi(type, geturl);
      if (result.data?.email_status === "IN_PROGRESS") {
        checkEmailProgress();
      } else {
        SetProgessBarData(result.data ?? {});
      }
    } catch (err) {
      if (err.message) {
        setError(err.message?.[0] ?? err.message);
      } else {
        setError(err);
      }
    }
  }


  useEffect(() => {
    if (progressBarStart) {
      checkEmailProgress();
    }
    //eslint-disable-next-line
  }, [progressBarStart]);

  useEffect(() => {
    emailStatus();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      {showProgessBar && <>
        <div className="k-overlay"></div>
        <DraggableContent style={{ position: 'fixed', zIndex: 100, bottom: '10%', left: '10%', maxHeight: 'fit-content' }}>

          <div className="card tds_form16_certif_progressmodal">
            <div className="card ">
              <div className="row">
                <>
                  <div className="col-md-12">
                    <span className="progress form16_certificate_progressbar ">
                      <span className="progress-bar progress-bar-striped progress-bar-animated"
                        style={{ width: `${progessBarData.pb_val ?? 0}%` }}>
                        {`${progessBarData.pb_val ?? 0}% Completed`}
                      </span>

                    </span>
                  </div>

                  <div className="col-md-6 pt-2">
                    <p className="form16_generatest_textno ">
                      {`Email processed: ${progessBarData?.email_sent_count ?? 0}/${progessBarData?.email_total_count ?? 0}`}
                    </p>
                  </div>
                  <div className="col-md-6 text-right pt-2">
                    <p className="form16_generatest_textno text_right">
                      {`Remaining Time : ${progessBarData?.email_estimated_time ? progessBarData?.email_estimated_time : 1} min`}
                    </p>
                  </div>
                </>

              </div>
            </div>
          </div>
        </DraggableContent>
      </>
      }

    </>
  )
}

export default EmailProgressBarPdf
