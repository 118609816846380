/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//import Alert from 'react-bootstrap/Alert';
import { BASE_PATH } from '../../app/constants';
import './RaiseReqSubmited.css';
// import successicon from '../../images/loginicon/successicon.png';

export default function RaiseReqSubmited(props) {
  const {
    show, handleClose, deductorId, raiseReqSubmitedResult,
  } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
        className="raiserequesdonepopup"
        onClose={handleClose}
      >
        <Modal.Header closeButton className="raiserequesdonepopuph">
          <Modal.Title className="">Raise Request </Modal.Title>
        </Modal.Header>
        <Modal.Body className="createnretb pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="aisedonesect">
                  {/* <p className="donaisefz">
                    <span className="pr-1"> <img src={successicon} alt="" style={{ width: '22px' }} /> </span>
                    Request has been successfully raised!
                  </p> */}
                  {/*
                  <p className="dedctdash">
                    <Link to={`${BASE_PATH}correction-return-dashboard/${deductorId}/return/${returnId}`}>Deductor Dashboard</Link>
                    {' '}
                    {'>>'}
                    {' '}
                    <Link to={`${BASE_PATH}raised-requests-list/${deductorId}`}>View all the Raised Requests</Link>
                  </p>*/}
                  <h6>
                    {raiseReqSubmitedResult?.message}
                  </h6>
                  {/* <h6>
                    Generated Request Number :
                    {' '}
                    {raiseReqSubmitedResult && raiseReqSubmitedResult.data && raiseReqSubmitedResult.data.request_id ? raiseReqSubmitedResult.data.request_id : '-'}
                  </h6> */}
                  {/* <p className="reqraisefinaltext">
                    Request Raised for Financial Year
                    {' '}
                    {raiseReqSubmitedResult && raiseReqSubmitedResult.header && raiseReqSubmitedResult.header.financial_year_formatted ? raiseReqSubmitedResult.header.financial_year_formatted : ' - '}
                    {' '}
                    ,
                    Quarter
                    {' '}
                    {raiseReqSubmitedResult && raiseReqSubmitedResult.header && raiseReqSubmitedResult.header.quarter ? raiseReqSubmitedResult.header.quarter : '-'}
                    {' '}
                    and Form
                    {' '}
                    {raiseReqSubmitedResult && raiseReqSubmitedResult.header && raiseReqSubmitedResult.header.form_type ? raiseReqSubmitedResult.header.form_type : '-'}
                  </p> */}
                </div>
              </div>
            </div>
            <div className=" modal-footer reqaisefoot  text-center">
              <div className="row">
                <div className="col-md-12">
                  {/* <Button type="button" className="btn btn-default raisereqdone mr-1 pb-1" onClick={() => handleClose()}>OK</Button> */}
                  <Link to={`${BASE_PATH}raised-requests-list/${deductorId}`}>
                    <Button type="button" className="btn btn-default viewallbt"> View all the Raised Requests </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>

      </Modal>
    </>
  );
}
