import * as React from 'react';
//import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import $ from 'jquery';

export class ChallanFilterUI extends React.Component {

    componentDidMount = () => {
        $(document).on('keydown', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                $("form.k-filter-menu button.k-primary").click();
            }
        });
    }

    handleFirstValueChange = (event) => {
        const { firstFilterProps } = this.props;
        let value = event.value;
        firstFilterProps.onChange({
            value,
            operator: firstFilterProps.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleFirstOperatorChange = (event) => {
        const { firstFilterProps } = this.props;
        const value = event.value;
        let emptyVal = (value.operator === 'contains') ? '' : 'empty';
        firstFilterProps.onChange({
            value: emptyVal,
            operator: value.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    render() {

        let { firstFilterProps } = this.props;

        let firstOperator = firstFilterProps.operators.filter((operator) => {
            if(operator.text === "Is empty"){
                operator.text = "UNMAPPED";
            } else if(operator.text === "Is not empty") {
                operator.text = "MAPPED";
            }
             return operator.operator === firstFilterProps.operator 
        });

        return (
            <div className="filter_ui">
                <DropDownList
                    data={firstFilterProps.operators}
                    textField="text"
                    dataItemKey="operator"
                    value={firstOperator[0]}
                    className="filter_ui_select"
                    popupSettings={{ className: 'filter_ui_option' }}
                    onChange={this.handleFirstOperatorChange}
                />

                {firstOperator[0].operator === 'contains' &&
                    <Input value={firstFilterProps.value} onChange={this.handleFirstValueChange} />
                }

            </div>
        );

    }
}