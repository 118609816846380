/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
    Formik, Form,
} from 'formik';
import { houseRentYearlyDataValidationSchema } from './validationSchema';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import { getHouseRentAnnualExemptAmt } from './allowancesCalculationScema';

const HouseRentYearForm = (props) => {

    const { houseRentData, saveData } = props;


    const calculateExemptAmt = (field, value, values, setFieldValue) => {

        let old_value = values.details[field];
        values.details[field] = value;

        let exempt_amtp = getHouseRentAnnualExemptAmt(values);

        setFieldValue('details.exempt_amtp', parseFloat(exempt_amtp).toFixed(2));
        values.details[field] = old_value;
    }

    return (<Formik
        initialValues={houseRentData}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={houseRentYearlyDataValidationSchema}
        onSubmit={(values) => {
            const submitValues = { ...values };
            saveData(submitValues);
        }}
    >
        {({
            errors, values, setFieldValue,
        }) => {
            if (Object.keys(errors).length > 0) {
            }
            return (
                <Form key={1} autoComplete="off">
                    <div className="row">
                        <div className="col-md-12 pt-1">
                            <div className="card allowance">
                                <div className="table-responsive">
                                    <table className="table table-striped allowanceWSDTB">
                                        <thead>
                                            <tr>
                                                <td>Whether Metro City</td>
                                                <td>
                                                    <select
                                                        name="whether_metro_city"
                                                        className="custom-select slecttextfs"
                                                        value={values.details.whether_metro_city}
                                                        onChange={(e) => {
                                                            setFieldValue('details.whether_metro_city', parseInt(e.target.value));
                                                            calculateExemptAmt('whether_metro_city', e.target.value, values, setFieldValue);
                                                        }}>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>H.R.A Received</td>
                                                <td>
                                                    <NumericInputWithDecimalTwo
                                                        type="text"
                                                        className={errors.details && errors.details.hra_received_amtp ? "form-control error_input" : "form-control"}
                                                        placeholder="0.00"
                                                        name="gratuity_received_amtp"
                                                        value={values.details.hra_received_amtp}
                                                        onChange={(e) => {
                                                            setFieldValue('details.hra_received_amtp', e.target.value);
                                                            calculateExemptAmt('hra_received_amtp', e.target.value, values, setFieldValue);
                                                        }}
                                                    />
                                                </td>
                                                <td>Rent Paid</td>
                                                <td>
                                                    <NumericInputWithDecimalTwo
                                                        type="text"
                                                        className={errors.details && errors.details.rent_paid_amtp ? "form-control error_input" : "form-control"}
                                                        placeholder="0.00"
                                                        name="rent_paid_amtp"
                                                        value={values.details.rent_paid_amtp}
                                                        onChange={(e) => {
                                                            setFieldValue('details.rent_paid_amtp', e.target.value);
                                                            calculateExemptAmt('rent_paid_amtp', e.target.value, values, setFieldValue);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Annual Salary</td>
                                                <td>
                                                    <NumericInputWithDecimalTwo
                                                        type="text"
                                                        className={errors.details && errors.details.annual_salary_amtp ? "form-control error_input" : "form-control"}
                                                        placeholder="0.00"
                                                        name="annual_salary_amtp"
                                                        value={values.details.annual_salary_amtp}
                                                        onChange={(e) => {
                                                            setFieldValue('details.annual_salary_amtp', e.target.value);
                                                            calculateExemptAmt('annual_salary_amtp', e.target.value, values, setFieldValue);
                                                        }}
                                                    />
                                                </td>
                                                <td>Exempt Amt</td>
                                                <td>
                                                    <NumericInputWithDecimalTwo
                                                        type="text"
                                                        className={errors.details && errors.details.exempt_amtp ? "form-control error_input" : "form-control"}
                                                        placeholder="0.00"
                                                        name="exempt_amtp"
                                                        value={values.details.exempt_amtp}
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="btn-group mb-1 mt-1">
                                <button type="submit" className="btn btn-default monthwsdetilssavebtn">Save & Exit</button>
                            </div>
                        </div>
                    </div>
                </Form>
            );
        }}
    </Formik>);
};

export default HouseRentYearForm;