import React from 'react'
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';

const Deduction = (props) => {
    const { data, handleInputChange, setWarning, handleNumberInputOnBlur } = props;
    return (
        <>
            <tr className="">
                <th width="10">

                </th>
                <th className="text-left">
                    Less : Deduction u/s 16
                </th>
                <th className="text-right">
                    <BlurOnChangeInput
                        type="text"
                        className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                        value={FormatNumberDecimal(data?.total_deduction_amtp ?? 0, ...Array(3))}
                        data-toggle="tooltip" data-placement="top" data-original-title={""}
                        placeholder=""
                    />
                </th>

            </tr>

            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                        <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">

                            <tbody>
                                <tr className="">
                                    <td width="45"></td>
                                    <td width="550" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop">
                                        Standard Deduction

                                    </td>
                                    <td width="127" className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright">
                                        <BlurOnChangeInput
                                            type="text"
                                            className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                            value={FormatNumberDecimal(data?.standard_deduction_amtp ?? "", ...Array(3))}
                                            data-toggle="tooltip" data-placement="top" data-original-title={""}
                                            placeholder=""
                                            disabled
                                        />
                                    </td>

                                </tr>

                                <tr className="">
                                    <td width=""></td>

                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft ">
                                        Ent Allowance
                                    </td>
                                    <td width="" className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright">
                                        <BlurOnChangeInput
                                            type="text"
                                            className={`form-control salarytaxregime_edit_inputbox text-right`}
                                            value={FormatNumberDecimal(data?.ent_allowance_amtp ?? "", ...Array(3))}
                                            data-toggle="tooltip" data-placement="top" data-original-title={""}
                                            placeholder=""
                                            readOnly={!data.is_government_deductor || data.tax_regime === 'NEW'}
                                            onChange={(e) => {
                                                let value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e)
                                                if(convertNum(value) > 5000){
                                                    value = 5000;
                                                    setWarning("Ent Allowance Deduction cannot exceed 5000.00");
                                                }
                                                handleInputChange("ent_allowance_amtp", value);
                                            }}
                                            onBlur={(e) => handleNumberInputOnBlur("ent_allowance_amtp", data?.ent_allowance_amtp)}
                                        />
                                    </td>


                                </tr>

                                <tr className="">
                                    <td width=""></td>

                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom">
                                        Profession Tax
                                    </td>
                                    <td width="" className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom salarytaxreg_innertb_borderright">
                                        <BlurOnChangeInput
                                            type="text"
                                            className={`form-control salarytaxregime_edit_inputbox text-right`}
                                            value={FormatNumberDecimal(data?.professional_tax_amtp ?? "", ...Array(3))}
                                            data-toggle="tooltip" data-placement="top" data-original-title={""}
                                            placeholder=""
                                            readOnly={data.tax_regime === 'NEW'}
                                            onChange={(e) => {
                                                let value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e)
                                                if(convertNum(value) > 5000){
                                                    value = 5000;
                                                    setWarning("Professional Tax Deduction cannot exceed 5000.00");
                                                }
                                                handleInputChange("professional_tax_amtp", value)
                                            }}
                                            onBlur={(e) => handleNumberInputOnBlur("professional_tax_amtp", data?.professional_tax_amtp)}
                                        />
                                    </td>

                                </tr>



                            </tbody>


                        </table>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default Deduction