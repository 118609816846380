/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import './FileUploadPopupV2.scss';
import { uploadManualConsoFile } from '../../../api/returnAPI';
import { MAX_IMPORT_FILE_SIZE_MB } from '../../../app/constants';
import importIcon from '../../../images/icons/import_icon.png';
import excelIcon from '../../../images/excelicon.png';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';


const FileUploadPopupV2 = ({ history, ...props }) => {
    const {
        handleClose, deductorId, returnId, handleProceed
    } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [files, setFiles] = useState([]);
    let JSZip = require("jszip");

    const {
        isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject,
    } = useDropzone({
        accept: '.tds',
    });

    const removeFile = (targetFile) => {
        const newFiles = [];
        files.map((file) => {
            if (file.path !== targetFile.path) {
                newFiles.push(file);
            }
            return true;

        });
        setFiles(newFiles);
    };

    const renderFiles = files.map((file) => (
        <li key={file.path} style={{ margin: '5px' }}>
            <span style={{ float: 'left' }}>
                {file.path}
                {' '}
                -
                {file.size}
                {' '}
                bytes
            </span>
            <Button
                variant="danger"
                style={{
                    fontSize: '12px', padding: '0px', paddingLeft: '5px', paddingRight: '5px', marginLeft: '10px', float: 'right'
                }}
                onClick={() => removeFile(file)}
            >
                Remove
            </Button>
        </li>
    ));

    const uploadFiles = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('deductor_id', deductorId);
        formData.append('return_id', returnId);
        formData.append('created_at', moment(files[0].lastModified).format('YYYY-MM-DD'));

        let ddtZip = new JSZip();
        for (let file of files) {
            let filename = file.name;
            ddtZip.file(`${filename}`, file, { binary: true });
        }

        const ddtZipBlob = await ddtZip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions: { level: 0 } })
        formData.append('file', ddtZipBlob, "CorrectionReturnFile.zip");
        if (ddtZipBlob.size / (1024 * 1024) > MAX_IMPORT_FILE_SIZE_MB) {
            setLoading(false);
            return setError(`Max size of the file that can be uploaded is ${MAX_IMPORT_FILE_SIZE_MB} MB.`);
        }

        try {
            const result = await uploadManualConsoFile(formData);
            handleProceed(result);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        const newFiles = acceptedFiles;
        const uniqueFiles = [];
        const flags = [];
        const l = newFiles.length;
        let i;
        for (i = 0; i < l; i++) {
            if (flags[newFiles[i].path]) continue;
            flags[newFiles[i].path] = true;
            uniqueFiles.push(newFiles[i]);
        }
        setFiles(uniqueFiles);
    }, [acceptedFiles]);

    return (
        <>

            <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ paddingTop: '8px', paddingBottom: '5px' }}>
                    Upload Conso file
                </kendo-dialog-titlebar>}
                onClose={handleClose}
                totalPopups={0}
                initialTop={50}
                index={1}
                className="Deductee_trans_importmodal"
                initialHeight={'auto'}
                style={{ maxHeight: '540px', overflowX: 'hidden' }}
                initialWidth={475}
                initialLeft={(((window.innerWidth) - 580) / 2)}
                isModalView={true}
            >
                {files?.length <= 0 &&
                    <div className="col-md-12 pt-4">
                        <span className="Deductee_trans_importmodal_iconbox pr-2">
                            <img src={importIcon} width="18" alt='import icon' />
                        </span>
                        <button className="btn btn-default balnktemplate_dragdropfilebtnbgclr" {...getRootProps({ className: 'fileuppopup_dropzone' })} style={{ paddingLeft: '20px' }}>
                            <input {...getInputProps({ accept: '.tds', multiple: false })} />
                            {!isDragActive && 'Drag \'n\' drop files here, or click to select files'}
                            {isDragActive && !isDragReject && 'Drop files here'}
                            {isDragReject && 'File type not accepted, sorry!'}
                        </button>
                    </div>
                }
                {files?.length > 0 &&
                    <div className="col-md-12 pt-3">
                        <span className="Deductee_trans_importmodal_iconbox pr-2">
                            <img src={excelIcon} width="18" alt='excel icon' />
                        </span>
                        <button className="btn btn-default balnktemplate_filenamebtn">
                            <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
                        </button>
                    </div>
                }

                <div className="col-lg-12 text-center pt-3">
                    {error
                        ? (
                            <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>
                        )
                        : null}

                    <button class="btn  btn-default Deductee_trans_importmodal_submitbnt mr-2" onClick={uploadFiles} disabled={loading || files.length === 0}>
                        {loading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {' '}
                                Loading...
                            </>
                        ) : 'Submit'}
                    </button>
                    <button className="btn  btn-default Deductee_trans_importmodal_calbtn" onClick={() => handleClose()}>Cancel</button>
                </div>

            </WindowWrapperFixed>
        </>
    );
};

export default FileUploadPopupV2;
