import React from "react";
import LateDeductionTable1 from "./LateDeductionTable1";
import IntLatePaymentTable2 from "./IntLatePaymentTable2";


const InterestLatePayment = (props) => {

    const { apiData, formType } = props;

    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab20" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">

                    <LateDeductionTable1                 //same as late deduction table 1 that's why same component used
                        apiData={apiData?.summary}
                    />



                    <IntLatePaymentTable2
                        apiData={apiData?.list}
                        formType={formType}
                    />


                </div>
            </div>
        </div>
    )

};

export default InterestLatePayment;