export function getTDSComputationSummaryData(data, interestCheckStatus = true, feeCheckStatus = true){
    const summary = {
        tds_cess_surcharse: { libility: 0, mapped: 0, unmapped: 0, challanAvl: 0, depoitChallan: 0, },
        interest: { libility: 0, mapped: 0, unmapped: 0, challanAvl: 0, depoitChallan: 0 },
        fees: { libility: 0, mapped: 0, unmapped: 0, challanAvl: 0, depoitChallan: 0 },
        total: { libility: 0, mapped: 0, unmapped: 0, challanAvl: 0, depoitChallan: 0 },
      }      

      summary.tds_cess_surcharse.libility = Number(data?.liability_tds_amtp ?? 0) + Number(data?.liability_cess_amtp ?? 0) + Number(data?.liability_surcharge_amtp ?? 0);
      summary.tds_cess_surcharse.mapped = Number(data?.mapped_tds_amtp ?? 0) + Number(data?.mapped_liability_cess_amtp ?? 0) + Number(data?.mapped_liability_surcharge_amtp ?? 0);
      summary.tds_cess_surcharse.unmapped = (Number(data?.liability_tds_amtp ?? 0) + Number(data?.liability_cess_amtp ?? 0) + Number(data?.liability_surcharge_amtp ?? 0)) - (Number(data?.mapped_tds_amtp ?? 0) + Number(data?.mapped_liability_cess_amtp ?? 0) + Number(data?.mapped_liability_surcharge_amtp ?? 0));
      summary.tds_cess_surcharse.challanAvl = Number(data?.challan_tds_amtp ?? 0) 
      
      const tds_cess_surcharse_deposit_challan = Number(
        ((Number(data?.liability_tds_amtp ?? 0) + Number(data?.liability_cess_amtp ?? 0) + Number(data?.liability_surcharge_amtp ?? 0)) - (Number(data?.mapped_tds_amtp ?? 0) + Number(data?.mapped_liability_cess_amtp ?? 0) + Number(data?.mapped_liability_surcharge_amtp ?? 0)))
        -
        (Number(summary.tds_cess_surcharse.challanAvl))
      )

      summary.tds_cess_surcharse.depoitChallan = tds_cess_surcharse_deposit_challan > 0 ? tds_cess_surcharse_deposit_challan : 0
  
      summary.interest.libility = Number(data?.late_deduction_interest_amtp ?? 0) + Number(data?.late_payment_interest_amtp ?? 0);
      summary.interest.mapped = Number(data?.mapped_interest_amtp ?? 0);
      summary.interest.unmapped = Math.max(Number(data?.late_deduction_interest_amtp ?? 0) + Number(data?.late_payment_interest_amtp ?? 0) - Number(data?.mapped_interest_amtp ?? 0), 0);
      summary.interest.challanAvl = Number(data?.challan_interest_amtp ?? 0);
      
      const interest_deposit_challan = Number(
        Number(data?.late_deduction_interest_amtp ?? 0) - Number(data?.mapped_interest_amtp ?? 0) + Number(data?.late_payment_interest_amtp ?? 0)
        -
        Number(summary.interest.challanAvl)
      )
      summary.interest.depoitChallan = interest_deposit_challan > 0 ? interest_deposit_challan : 0
  
      summary.fees.libility = Number(data?.late_fee_amtp ?? 0);
      summary.fees.mapped = Number(data?.mapped_fees_amtp ?? 0);
      summary.fees.unmapped = Math.max(Number(data?.late_fee_amtp ?? 0) - Number(data?.mapped_fees_amtp ?? 0), 0);
      summary.fees.challanAvl = Number(data?.challan_fees_amtp ?? 0);
      
      const fess_deposit_challan = Number(
        Number(data?.late_fee_amtp ?? 0) - Number(data?.mapped_fees_amtp ?? 0)
        -
        Number(summary.fees.challanAvl)
      )
      summary.fees.depoitChallan = fess_deposit_challan > 0 ? fess_deposit_challan : 0
  
      summary.total.libility = (summary.tds_cess_surcharse.libility > 0 ? summary.tds_cess_surcharse.libility : 0 ) + (summary.interest.libility > 0 ? summary.interest.libility : 0) + (summary.fees.libility > 0 ? summary.fees.libility : 0);
      summary.total.mapped = (summary.tds_cess_surcharse.mapped > 0 ? summary.tds_cess_surcharse.mapped : 0) + (summary.interest.mapped > 0 ? summary.interest.mapped : 0) + ( summary.fees.mapped > 0 ? summary.fees.mapped : 0);
      summary.total.unmapped = (summary.tds_cess_surcharse.unmapped > 0 ? summary.tds_cess_surcharse.unmapped : 0) + ( summary.interest.unmapped > 0 ? summary.interest.unmapped : 0) + ( summary.fees.unmapped > 0 ? summary.fees.unmapped : 0);
      summary.total.challanAvl = (summary.tds_cess_surcharse.challanAvl > 0 ? summary.tds_cess_surcharse.challanAvl : 0) + ( summary.interest.challanAvl > 0 ? summary.interest.challanAvl : 0) + (summary.fees.challanAvl > 0 ? summary.fees.challanAvl : 0);
      summary.total.depoitChallan = (summary.tds_cess_surcharse.depoitChallan > 0 ? summary.tds_cess_surcharse.depoitChallan : 0) + (interestCheckStatus && summary.interest.depoitChallan > 0 ? summary.interest.depoitChallan : 0) + (feeCheckStatus && summary.fees.depoitChallan> 0 ? summary.fees.depoitChallan : 0);

    return summary;
}