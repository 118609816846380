import React from 'react'

const FilingStatus = (props) => {
    const { efilingStatusIcon, efilingStatusText1, efilingStatusText2 } = props;
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <fieldset className="efilingstatus_fs">
                        <legend className="efilingstatus_leg">
                            <h4>
                                Filing Status
                            </h4>
                        </legend>
                        <div className="media">
                            <img src={efilingStatusIcon} alt="progress bar" className="mr-3 mt-3" style={{ width: "60px" }} />
                            <div className="media-body pt-2">
                                <p className="efilingstatus_textfs mb-2">
                                    {efilingStatusText1}
                                </p>
                                <p className="efilingstatus_textfs">
                                    {efilingStatusText2}
                                </p>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </>
    )
}

export default FilingStatus