/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImportReturnFromExcel from '../returnDashboard/importReturnFromExcel/ImportReturnFromExcel';
import FailImportChallanList from '../returnDashboard/importReturnFromExcel/FailImportChallanList';
import { BASE_PATH } from '../../app/constants';
// import ImportConsoFile from '../../features/returnRaise/importConsoFile/ImportConsoFile';
import ImportConsolidateFile from './importConsolidate/ImportConsolidateFile';
import AlertMessagePopup from '../../components/AlertMessagePopup';
import ShowAlert from '../../components/ShowAlert';
import ImportCorrectionReturnFromExcel from './importCorrectionReturnFromExcel/ImportCorrectionReturnFromExcel';
import { exportCorretionReturn } from '../../api/returnAPI';
import { selectHeaderData } from '../header/headerSlice';
import { getCurrentFinancialYear } from '../../utils/UtilityFunctions';
//import { downloadCorrectionFVUFile} from '../../api/interestFeesCalculatorAPI';
import DownloadFile from '../../components/DownloadFile';

const Quicklink = ({ history, ...props }) => {
  const { deductorId, returnId, componentImport, headerDataDetails, handleProceed } = props;

  const finanicalYear = headerDataDetails?.financial_year_formatted ? headerDataDetails?.financial_year_formatted?.split("-")?.[0] : getCurrentFinancialYear();


  const [showImportReturnFromExcel, setShowImportReturnFromExcel] = useState(false);
  const [showImportReturnFromExcel2, setShowImportReturnFromExcel2] = useState(false);
  const [showFailImportChallanList, setShowFailImportChallanList] = useState(false);
  const [failImportChallanListErrors, setFailImportChallanListErrors] = useState({});
  const [showImportConsoFile, setShowImportConsoFile] = useState(false);
  const [showAlertMessagePopup, setShowAlertMessagePopup] = useState(false);
  const [showImportReturnFromTxt, setShowImportReturnFromTxt] = useState('');
  const [loading2, setLoading2] = useState(false);
  const headerData = useSelector(selectHeaderData);
  const [downloadExcelFile, setDownloadExcelFile] = useState(false);

  const [error, setError] = useState(null);

  const importExelError = (data) => {
    setShowImportReturnFromExcel(false);
    setFailImportChallanListErrors(data);
    setShowFailImportChallanList(true);
  };

  const importExelError2 = (data) => {
    setShowImportReturnFromExcel2(false);
    setFailImportChallanListErrors(data);
    setShowFailImportChallanList(true);
  };

  const openImportPopUp = () => {
    setShowFailImportChallanList(false);
    setShowImportReturnFromExcel(true);
  };

  const showSuccessToaseterMsg = (result) => {
    if (result.status === '1') {
      setShowAlertMessagePopup(true);
      window.location.href = `${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${finanicalYear}`;
    }
  };

  const exportExcelTemp = () => {
    setDownloadExcelFile(true);
  }
  
  const showExcelPopup = () => {
    setShowImportReturnFromExcel2(true);
    setShowImportReturnFromTxt('');
  }

  const getImportReturnExcelLink = (form, quarter) => {

    return (
      <div className="col-md-4">
        <div className="quickslinnksect">
          <span className="link" onClick={() => showExcelPopup()}>Import Return from Excel</span>
        </div>
      </div>
    )
  }


  return (
    <div>
      <ShowAlert
        error={error}
        // success={success}
        onClose={() => { setError(null); }}
      />

      {downloadExcelFile &&
        <DownloadFile
          apiFunction={exportCorretionReturn}
          setDownloadLoading={setLoading2}
          setError={setError}
          fileName={'ExpressTDS-ReturnData'}
          setDownloadExcelFile={setDownloadExcelFile}
          sessionStorageKey={`export-return-ref-key-${deductorId}-${returnId}-${finanicalYear}`}
          params={`?deductor_id=${deductorId}&return_id=${returnId}`}
          handleSuccessProceed={() => {
            setDownloadExcelFile(false);
            setLoading2(false);
          }}
          handleErrorProceed={(msg) => {
            setDownloadExcelFile(false);
            setLoading2(false);
          }}
        />
      }

      <AlertMessagePopup
        show={showAlertMessagePopup}
        handleClose={() => {
          setShowAlertMessagePopup(false);
        }}
        bodyText="IMPORT IS UNDER PROGRESS PLEASE WAIT FOR SOME TIME...."
        headingText="ALERT"
        closeButtonText="Done"
      />
      <ImportReturnFromExcel
        show={showImportReturnFromExcel}
        handleClose={(refresh) => {
          setShowImportReturnFromExcel(false);
          // if (refresh) {
          //   history.go();
          // }
        }}
        deductorId={deductorId}
        returnId={returnId}
        importExelError={importExelError}
      />

      <FailImportChallanList
        show={showFailImportChallanList}
        handleClose={() => {
          showFailImportChallanList(false);
        }}
        deductorId={deductorId}
        returnId={returnId}
        failImportChallanListErrors={failImportChallanListErrors}
        openImportPopUp={openImportPopUp}
      />

      <ImportConsolidateFile
        show={showImportConsoFile}
        handleClose={(refresh) => {
          setShowImportConsoFile(false);
          // if (refresh) {
          //   history.go();
          // }
        }}
        handleSuccessProceed={(result) => {
          setShowImportConsoFile(false);
          showSuccessToaseterMsg(result);
          //history.push(`${BASE_PATH}deductor?deductor_id=${deductorId}`);
          //  window.location.href = `${BASE_PATH}correction-return-dashboard/${deductorId}/return/${returnId}`;
        }}
        deductorId={deductorId}
        returnId={returnId}
        headerDataDetails={headerDataDetails}
        handleProceed={handleProceed}
      />
      <ImportCorrectionReturnFromExcel
        show={showImportReturnFromExcel2}
        handleClose={(refresh) => {
          setShowImportReturnFromExcel2(false);
          // if (refresh) {
          //   history.go();
          // }
        }}
        deductorId={deductorId}
        returnId={returnId}
        importExelError={importExelError2}
        showImportReturnFromTxt={showImportReturnFromTxt}
        downloadExcelTemp={exportExcelTemp}
        downloadExcelTempLoading={loading2}
      />

      <div className="row mt-1">
        <div className="col-md-12">
          <fieldset className="quicklinkfieldsetclr">
            <legend className="quicklinklengent">
              <h4>Quick Links</h4>
            </legend>
            <div className="row">
              {/*<div className="col-md-2">
                <div className="quickslinnksect">
                  <Link onClick={() => setShowImportReturnFromExcel(true)}>Import Return</Link>
                </div>
                <div className="quickslinnksect">
                  <a href="" disabled>Export Return</a>
                </div>
              </div>*/}
              {/* <div className="col-md-4">
                <div className="quickslinnksect">
                  <a href="" disabled>Download Justifcation Report</a>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="quickslinnksect">
                  {/* <a href="" disabled>Import from Consolidated File</a> */}
                  <span
                    className="link"
                    onClick={() => {
                      setShowImportConsoFile(true);
                    }}
                  >
                    {' '}
                    Import from Consolidated File
                  </span>
                </div>
              </div>

              {getImportReturnExcelLink(headerData?.form_type, headerData?.quarter)}


              <div className="col-md-4">
                {componentImport ? (
                  <div className="quickslinnksect">
                    <Link to={`${BASE_PATH}correction?return_id=${returnId}&deductor_id=${deductorId}&tab=deductee&open=bulk-pan-verification`} target="_blank">Bulk PAN Verifcation of All Deductees</Link>
                  </div>
                ) : (
                  <div className="quickslinnksect">
                    <Link to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&open=bulk-pan-verification`} target="_blank">Bulk PAN Verifcation of All Deductees</Link>
                  </div>
                )
                }
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

  );
};

export default Quicklink;
