import React from 'react';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_PATH } from '../../../app/constants';


const ShowTopperFound = (props) => {

    const { showStoperList, deductorId, returnId, setShowForm } = props;

    return (
        <>
            <div class="col-12 col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <div class="row">
                    <div class={((showStoperList?.DeductorProfile > 0 && !showStoperList?.DeducteeTransactions) || (!showStoperList?.DeductorProfile && showStoperList?.DeducteeTransactions > 0)) ? "col-6 col-xl-6 text-center" : "col-12 col-xl-12 text-center"}>
                        <fieldset class="showstpfound_fs h-100 pb-0">
                            <legend class="showstpfound_leg text-left mb-0">
                                <h4 class="">Show Stoppers Found</h4>
                            </legend>
                            <div class="showstpfound_mediabox pt-3 pb-2" >
                                {showStoperList?.DeductorProfile > 0 &&
                                    <div class="media showstpfound_mediabox showstpfound_mediabox_rightborder ">
                                        <div class="media-body">
                                            <div class="showstpfound_textbox" onClick={() => setShowForm(true)} >
                                                <p class="likelydefault_nobox_fs challanverify_textclr_coral">
                                                    {showStoperList?.DeductorProfile ?? 0}
                                                </p>
                                                <hr class="likelydefault_bottomborder" />
                                                <h4 class="showstpfound_titlefs challanverify_textclr_coral" style={{paddingTop:'3px'}}>Missing Deductor Information</h4>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {showStoperList?.DeducteeTransactions > 0 && 
                                <div class="media showstpfound_mediabox showstpfound_mediabox_leftpadd">
                                    <Link
                                        className="link"
                                        to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&additional_filter=35`}
                                        tabIndex="-1"
                                        target={'_blank'}
                                    >
                                        <div class="showstpfound_textbox">
                                            <p class="likelydefault_nobox_fs challanverify_textclr_coral">
                                                {showStoperList?.DeducteeTransactions ?? 0}
                                            </p>
                                            <hr class="likelydefault_bottomborder" />
                                            <h4 class="showstpfound_titlefs challanverify_textclr_coral" style={{paddingTop:'3px'}}>Invalid Tax Deducted Amount</h4>
                                        </div>
                                    </Link>
                                </div>
                                }
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowTopperFound