import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import queryString from 'query-string';
import { parseDate } from '@telerik/kendo-intl';
import { useSelector } from "react-redux";
import { selectHeaderData } from "../../header/headerSlice";
import { gocallApi } from "../../../api/issueCertificateAPI";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CustomCell } from '../../../components/grid/CustomCell';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import moment from 'moment';
import Skeleton6ColumnGrid from '../../../components/Skeleton/Skeleton6ColumnGrid';
import editIcon from '../../../images/icons/edit.svg';
import LoadingIndicator from "../../../components/loadingIndicator/LoadingIndicator";
import ShowAlert from "../../../components/ShowAlert";
import { BASE_PATH } from "../../../app/constants";
import SemanticPagination from "../../../components/pagination/SemanticPagination";
import Computation from "./computation/Computation";
import dwnload4 from "../../../images/icons/export_icon.png";
import emailIcon from '../../../images/emailcoral.png';
import Spinner from 'react-bootstrap/Spinner';
import DownloadFile from "../../../components/DownloadFile";
import { downloadSalaryTdsCalculatorData } from "../../../api/salaryAPI";
import SalaryMapping from "./SalaryMapping";
import "../../deductorList/NoticeOrder.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

const LIMIT = 17;

const ImportedSalaryDetails = () => {
    const history = useHistory();
    const location = useLocation();
    const headerData = useSelector(selectHeaderData);
    const params = queryString.parse(location.search);
    const [dataState, setDataState] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cursor, setCursor] = useState({ current_page: 1, next_page: null });
    const deductorId = params?.deductor_id ?? '';
    const financial_year = params?.financial_year ?? '';
    const [salaryData, setSalaryData] = useState([]);
    const [summary, setSummary] = useState();
    const [summaryStatus, setSummaryStatus] = useState(true)
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [showComputation, setShowComputation] = useState(false);
    const [deducteeId, setDeducteeId] = useState();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadExcelFile, setDownloadExcelFile] = useState(false);
    const [showSalaryMappingPage, setShowSalaryMappingPage] = useState(false);
    const [apiFirstCall, setApiFirstCall] = useState(true)

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const createHistoryUrl = (filters) => {

        let filterStr = `?deductor_id=${deductorId}&financial_year=${financial_year}&page=1`

        if (filters.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
        }
        if (filters.sort && filters.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
        }
        history.push(filterStr);
    };

    const getParamsHistoryUrl = () => {

        if (params?.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
        }
        if (params?.filters?.length > 0) {
            dataState.filter = { filters: [] };
            let frs = JSON.parse(atob(params.filters));
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'issued_at') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                }
            }
            dataState.filter.filters = frs;
        } else {
            dataState.filter = { filters: [] };
        }

        setDataState({ ...dataState });
    };

    const getSalaryDataSummary = async () => {

        let filters = null;
        if (params?.filters?.length > 0) {
            filters = JSON.parse(atob(params.filters));
        }
        let payload = {
            deductor_id: deductorId,
            financial_year: financial_year,
            filters: params.filters ? filters : null
        }
        let url = `/api/v1/salary-tds-calculator/summary`;

        try {
            const result = await gocallApi("post", url, payload)
            setSummary(result?.data?.summary ?? 0);
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            //do nothing
        }
    }

    const dataStateChange = (data_state) => {
        createHistoryUrl(data_state);
        setDataState({ ...data_state });

        //Get Summary of Notice Order
        if (data_state?.filter?.filters?.length > 0 && data_state?.sort?.length > 0) {
            setSummaryStatus(true)
        } else if (data_state?.filter?.filters?.length === 0 && data_state?.sort?.length > 0) {
            setSummaryStatus(false)
        } else if (data_state?.filter?.filters?.length > 0 && data_state?.sort?.length === 0) {
            setSummaryStatus(true)
        } else {
            setSummaryStatus(true) //default true
        }
    };

    const rowRender = (trElement, props) => {
        const data = props?.dataItem ?? {}
        const rowCounter = trElement._owner.index;

        return (
            <tr data-index={rowCounter} role="row">
                <td className={'text-left'}>{data?.deductee_name ?? '-'}</td>
                <td className={'text-left'}>{data?.pan ?? '-'}</td>
                <td className={`text-right`}>{formatNumber(data?.salary_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.deductions_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.net_taxable_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.estimated_tds_amt ?? 0)}</td>
                <td className="text-center">{data?.tax_regime ?? '-'}</td>
                <td className="text-center link" onClick={() => handleShowComputation(data?.deductee_id)}>View</td>
            </tr>
        )
    };

    const editInfo = (props) => (
        <td className="saldtddeleditsect text-center">
            {' '}
            <span onClick={() => null} >
                <img src={editIcon} alt="" />
            </span>
        </td >
    )

    const getSalaryData = async (isloading) => {
        if (!isloading) setLoading(true);
        let filters = null;
        if (params?.filters?.length > 0) {
            filters = JSON.parse(atob(params.filters));
        }
        let payload = {
            deductor_id: deductorId,
            financial_year: financial_year,
            filters: params.filters ? filters : null,
            sort_on: params.sort_on ? params.sort_on : '',
            sort_by: params.order_by ?? "",
            page: params.page ? parseInt(params.page) : 1,
            limit: LIMIT
        }
        let url = `/api/v1/salary-tds-calculator/list`

        try {
            const result = await gocallApi("post", url, payload)
            if (result?.data?.data?.length > 0) {
                result?.data?.data.forEach((item) => {
                    if (item.issued_at) item.issued_at = moment(item.issued_at).format('DD/MM/YYYY')
                })
            } else if (apiFirstCall) {
                setShowSalaryMappingPage(true)
                setApiFirstCall(false)
            }
            if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page ?? null;
            setSalaryData(result?.data?.data ?? []);
            setCursor(result?.data?.cursor ?? { current_page: 1, next_page: null })

        } catch (err) {

            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            if (!isloading) setLoading(false);
            setShowSkeleton(false);
        }
    }

    const handleShowComputation = (id) => {
        setDeducteeId(id);
        setShowComputation(true);
    }

    useEffect(() => {
        getParamsHistoryUrl();
        getSalaryData();
        if (summaryStatus) {
            getSalaryDataSummary(); //Get Summary of Notice Order
        }

        // eslint-disable-next-line
    }, [location.search]);

    useEffect(() => {
        if (headerData?.organization_type === 'CA') {
            history.push(BASE_PATH);
        }
        // eslint-disable-next-line
    }, [headerData])

    return (
        <>

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />
            {loading && <LoadingIndicator />}
            {showSalaryMappingPage ?
                 <SalaryMapping
                    setShowSalaryMappingPage={setShowSalaryMappingPage}
                    setSuccess={setSuccess}
                    setError={setError}
                    setLoading={setLoading}
                    getSalaryData={getSalaryData}
                    getSalaryDataSummary={getSalaryDataSummary}
                />            
                : null 
            }
                <>

                    <div className="salary_left_sidepopup" >
                        <div className="challans_AnnouncementsIcon" >
                            <div className="challans_Announcements">
                                <div className="challans_Announcements-wrapper challans_has-unseen-items Dropdown">
                                    <div className="Dropdown-toggle Button">
                                        <div className="challans_Announcements-bubble">&nbsp;</div>
                                        <div>
                                            <FontAwesomeIcon icon={faBullhorn} style={{ color: '#fff', fontSize: '11px', transform: 'rotate(-38deg)' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span
                            className="mb-0 btn challans_textfs"
                            onClick={()=>setShowSalaryMappingPage(true)}
                            type="button"
                        > Import Salary Mapping </span>
                    </div>


                    {showComputation &&
                        <Computation
                            onClose={() => setShowComputation(false)}
                            deductorId={deductorId}
                            financial_year={financial_year}
                            deducteeId={deducteeId}
                        />
                    }

                    {downloadExcelFile &&
                        <DownloadFile
                            apiFunction={downloadSalaryTdsCalculatorData}
                            setDownloadLoading={setDownloadLoading}
                            setError={setError}
                            fileName={'ExpressTDS-Salary_TDSCalculator'}
                            setDownloadExcelFile={setDownloadExcelFile}
                            sessionStorageKey={`export-salary-tds-calculator-ref-key-${deductorId}-${financial_year}`}
                            params={`?deductor_id=${deductorId}&financial_year=${financial_year}`}
                            handleSuccessProceed={() => {
                                setDownloadExcelFile(false);
                            }}
                            handleErrorProceed={(msg) => {
                                setDownloadExcelFile(false);
                            }}
                        />
                    }

                    <div className='noticeOrderslistparentclass'>
                        <div className="container" style={{ maxWidth: '1450px' }}>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <Link className="notice_order_backbtn" to={`${BASE_PATH}deductor?deductor_id=${deductorId}`}>&lt; Back to Deductor Dashboard</Link>
                                </div>
                                <div className="col-md-4">
                                    <h4 className="notice_order_titlfs">Employee-Wise Salary Details</h4>
                                </div>
                                <div className="col-md-4 text-right">
                                    <Link className="link" to={`${BASE_PATH}monthwise-salary-details?deductor_id=${deductorId}&financial_year=${financial_year}`}><button className="btn btn-default monthwisebtn">Month Wise</button></Link>
                                    {'   '} {'   '}
                                    <span className='mr-2 ' title='Download Computation' onClick={() => setDownloadExcelFile(true)} style={{ cursor: 'pointer' }}>
                                        {
                                            downloadLoading
                                                ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}</>
                                                : <img src={dwnload4} alt="excel download icon " width="22" />
                                        }
                                    </span>
                                    {'   '}
                                    <Link to={`${BASE_PATH}email-salary-details?deductor_id=${deductorId}&financial_year=${financial_year}`} className="link">
                                        <img src={emailIcon} width={25} title="Send Email" alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                        {
                                            showSkeleton ? <Skeleton6ColumnGrid />
                                                :
                                                <>
                                                    <Tooltip anchorElement="pointer" position="right">
                                                        <Grid
                                                            className="table table-striped notices_orders_tb listofdedtb scrollvisible"
                                                            data={salaryData}
                                                            {...dataState}
                                                            onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                            filterOperators={{
                                                                text: [
                                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                ],
                                                                dropdown: [
                                                                    { text: 'grid.filterEqOperator', operator: 'in' },
                                                                ],
                                                                numeric: [
                                                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                                ],
                                                                textWithEmpty: [
                                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                                ],
                                                                date: [
                                                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                ],
                                                            }}
                                                            rowRender={rowRender}
                                                            sortable
                                                        >
                                                            <Column
                                                                width={200}
                                                                field="deductee_name"
                                                                headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                                                title={<CustomCell title={'Employee Name'} field="deductee_name" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                filter="textWithEmpty"
                                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                            />
                                                            <Column
                                                                width={100}
                                                                field="pan"
                                                                headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                                title={<CustomCell title={'PAN'} field="pan" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                filter="textWithEmpty"
                                                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                            />
                                                            <Column
                                                                width={150}
                                                                field="salary_amt"
                                                                headerClassName={isColumnActive('salary_amt') ? 'active' : ''}
                                                                title={<CustomCell title={'Salary'} field="salary_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                filter="numeric"
                                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                            />
                                                            <Column
                                                                width={150}
                                                                field="deductions_amt"
                                                                headerClassName={isColumnActive('deductions_amt') ? 'active' : ''}
                                                                title={<CustomCell title={'Chapter VI-A Ded.'} field="deductions_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                filter="numeric"
                                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                            />
                                                            <Column
                                                                width={150}
                                                                field="net_taxable_amt"
                                                                headerClassName={isColumnActive('net_taxable_amt') ? 'active' : ''}
                                                                title={<CustomCell title={'Net Taxable'} field="net_taxable_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                filter="numeric"
                                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                            />
                                                            <Column
                                                                width={150}
                                                                field="estimated_tds_amt"
                                                                headerClassName={isColumnActive('estimated_tds_amt') ? 'active' : ''}
                                                                title={<CustomCell title={'Estimated TDS'} field="estimated_tds_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                filter="numeric"
                                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                            />
                                                            <Column
                                                                width={150}
                                                                field="tax_regime"
                                                                headerClassName={isColumnActive('tax_regime') ? 'active' : ''}
                                                                title={<CustomCell title={'Regime'} field="tax_regime" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                filter="dropdown"
                                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={[{ label: "OLD", value: "old" }, { label: "NEW", value: "new" },]} />}
                                                            />
                                                            <Column
                                                                field="editIcon"
                                                                sortable={false}
                                                                title="Action"
                                                                cell={editInfo}
                                                                headerClassName="sorting_disabled"
                                                                width="120"
                                                            />
                                                        </Grid>
                                                    </Tooltip>
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>
                            {Number(summary?.total_cnt) > 0 &&
                                <SemanticPagination cursor={cursor} count="total_cnt" pagelimit={LIMIT} summary={summary} setSummaryStatus={setSummaryStatus} />
                            }

                            <div className="row">
                                <div className="col-md-12">
                                    <p className="empwisesalary_notetextfs">
                                        Note : It is Assumed that all the PAN's are correct and Operative while calculating
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            
        </>
    )
}

export default ImportedSalaryDetails