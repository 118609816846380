import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectHeaderData } from '../../features/header/headerSlice';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '../../app/constants';
import { getCorrectionTopDeductees } from '../../api/returnAPI';
import { formatNumber } from '../../utils/UtilityFunctions';

const DeducteeSummary = (props) => {
  const { deductorId, returnId } = props;
  const headerDataDetails = useSelector(selectHeaderData);

  const [loading, setLoading] = useState(false);
  const [deducteeList, setDeducteeList] = useState([]);

  const fetchTopDeductees = async (deductor_id, return_id) => {
    setLoading(true);
    try {
      const result = await getCorrectionTopDeductees(`?deductor_id=${deductor_id}&return_id=${return_id}&limit=5`);
      setDeducteeList(result?.data ?? []);
    } catch (err) {
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTopDeductees(deductorId, returnId);
  }, [deductorId, returnId]);
  return (
    <>
      <fieldset className="topfdedfel">
        <legend className="topfdedleg">
          <div className="topfdedinndev">
            <div className="topfdedtilefts">
              <h4>Top Five Deductees</h4>
            </div>
            <div className="topfdedinner" />
            <div className="topfdedright">
              <h4 className="">
                <span className="float-right topfdedfsize">
                  <Link to={`${BASE_PATH}correction?return_id=${returnId}&deductor_id=${deductorId}&tab=transactions`}>View Complete List of Deductee Transactions</Link>
                </span>
              </h4>
            </div>
            <div className="bortoplg topfdedbor" />
          </div>
        </legend>
        <table className="table table-striped table-responsive ovtbpanno">
          <thead className="">
            <tr>
              <th>Deductee Name </th>
              <th>{(headerDataDetails && headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '27EQ') ? "Collection Code" : "Section"}</th>
              <th>{(headerDataDetails && headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '27EQ') ? "Amt Rcvd" : "Amt Paid"}</th>
              <th>{(headerDataDetails && headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '27EQ') ? "Total Tax Collected" : "Tax Deducted"}</th>
              <th>Unmapped Amt</th>

            </tr>
          </thead>
          <tbody>
            {deducteeList.map((deductee) => (
              <tr>
                <td className="overflowtooltipcont">
                  <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={deductee.deductee_name}
                  >
                    {deductee.deductee_name}
                  </span>
                </td>
                <td>{deductee.code}</td>
                <td className="text-right">
                  <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={formatNumber(deductee.total_paid_amtp)}>
                    {' '}
                    {formatNumber(deductee.total_paid_amtp)}
                  </span>
                </td>
                <td className="text-right">
                  <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={formatNumber(deductee.sum_total_tds_amtp)}
                  >                    {' '}
                    {formatNumber(deductee.sum_total_tds_amtp)}
                  </span>
                </td>
                <td className="text-right">
                  <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={formatNumber(deductee.total_adjusted_tds_amtp)}
                  >                    {' '}
                    {formatNumber(deductee.total_adjusted_tds_amtp)}
                  </span>
                </td>
              </tr>
            ))}
            {deducteeList.length === 0 && !loading
              ? (
                <tr>
                  <td colSpan="10" style={{ textAlign: 'center' }}>
                    <span className="dark_color_text">No Records Found!</span>
                  </td>
                </tr>
              )
              : null}
          </tbody>
        </table>
      </fieldset>

    </>

  );
};

export default DeducteeSummary;
