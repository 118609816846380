import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Chart from 'react-google-charts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import './ReturnDashboard.css';
import { getCorrectionChallanUtilizationBreakup } from '../../api/returnAPI';
import EditChallan from '../challanList/EditChallan';
import ShowAlert from '../../components/ShowAlert';
import { formatNumber } from '../../utils/UtilityFunctions';
import SkeletonGraphRectangle from '../../components/Skeleton/SkeletonGraphRectangle';
import { Skeleton } from '@mui/material';


const ChallanSummary = (props) => {
  const { deductorId, returnId } = props;
  const [chartData, setChartData] = useState([]);
  const [skeletonChartLoading, setSkeletonChartLoading] = useState(true);
  const [chartColors, setChartColors] = useState(['#ff0000', '#008000', '#90ee90', '#ffcccb']);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [challanList, setChallanList] = useState([]);
  const [totalAvailableChallanAmt, setTotalAvailableChallanAmt] = useState(0);

  const [showChallanBreakup, setShowChallanBreakup] = useState(true);

  const [showEditForm, setShowEditForm] = useState(false);
  const [editChallanInfo, setEditChallanInfo] = useState({});

  const fetchChallanBreakup = async (deductor_id, return_id) => {
    try {
      const result = await getCorrectionChallanUtilizationBreakup(`?deductor_id=${deductor_id}&return_id=${return_id}`);
      setChallanList(result.data.challans);
      if (result.data.summary) {
        setTotalAvailableChallanAmt(result.data.summary.total_available_challan_amtp);
        setChartColors([
          `#ff0000`,
          `#008000`,
          `#90ee90`,
          `#ffcccb`,
        ]);
        const chartD = [[
          'Challan Number',
          'Utilized Amount, Not Verified',
          'Utilized Amount, Verified',
          'Unutilized Amount, Verified',
          'Unutilized Amount, Not Verified',
        ]];
        result.data.challans.map((challan) => {
          chartD.push([
            String(challan.challan_no),
            Number(challan.utilized_unverified_challan_amtp),
            Number(challan.utilized_verified_challan_amtp),
            Number(challan.unutilized_verified_challan_amtp),
            Number(challan.unutilized_unverified_challan_amtp),
          ]);
          return true;
        });
        setChartData(chartD);
      }
    } catch (err) {
      // console.error('Error in fetching transaction overview data: ', err);
    } finally {
        setSkeletonChartLoading(false);
    }
  };

  const onClose = (reload, msg = '') => {
    setShowEditForm(false);
    if (msg) {
      setSuccess(msg);
    }

    if (reload) {
      fetchChallanBreakup(deductorId, returnId);
    }
  };

  const getChartColor = (challan) => {
    if (challan.unutilized_challan_amtp === 0) {
      return 'rgb(0, 128, 0)';
    }

    if (challan.unutilized_challan_amtp > 0 && challan.unutilized_challan_amtp < challan.total_amtp) {
      return 'rgb(256, 128, 0)';
    }

    return 'rgb(255, 0, 0)';
  };

  const updateChallanInfo = (challanInfo) => {
    setEditChallanInfo(challanInfo);
    setShowEditForm(true);
  };

  useEffect(() => {
    fetchChallanBreakup(deductorId, returnId);
  }, [deductorId, returnId]);

  return (
    <fieldset className="fieldchallanamt">
      <legend className="lengchallanamt">
        <h4 className="challanamttitle">Challan Amount</h4>
      </legend>
      {showEditForm
        ? (
          <EditChallan
            onClose={onClose}
            deductorId={deductorId}
            challanId={editChallanInfo.challan_id}
          />
        )
        : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <div className="row">
        <div className="col-sm-12">
          <div className="row" style={{ position: 'relative' }}>
            <div className="col-md-8">
              {!showChallanBreakup && (
                <span
                  style={{
                    position: 'absolute', left: '5px', fontSize: '16px', top: '45%', cursor: 'pointer', zIndex: 2,
                  }}
                  onClick={() => setShowChallanBreakup(true)}
                >
                  <FontAwesomeIcon
                    icon={faArrowAltCircleLeft}
                    size="lg"
                  />
                </span>
              )}

              {skeletonChartLoading ? <SkeletonGraphRectangle />
                :
                <>
                  {showChallanBreakup ? (
                    <div className="card" style={{ border: 'none' }}>

                      {chartData.length > 0 ? (
                        <Chart
                          height="300px"
                          chartType="ColumnChart"
                          loader={<div>Loading Chart</div>}
                          data={chartData}
                          options={{
                            chartArea: {
                              width: '50%', left: 100, top: 50, bottom: 50,
                            },
                            isStacked: true,
                            hAxis: {
                              title: 'Challan Number',
                              minValue: 0,
                            },
                            vAxis: {
                              title: 'Challan Amount',
                            },
                            colors: chartColors,
                            bar: { groupWidth: '75%' },
                          }}
                          rootProps={{ 'data-testid': '3' }}
                        />
                      ) : (
                        <span className="dark_color_text" style={{ fontSize: '12px' }}>No Records Found!</span>
                      )}

                    </div>
                  ) : (
                    <div className="card" style={{ border: 'none', paddingLeft: '20px' }}>
                      <table className="table table-striped tbdedutr1">
                        <thead>
                          <tr>
                            <th>Challan No.</th>
                            <th>Deposit Date</th>
                            <th>Total Amount</th>
                            <th>Unutilized Amount</th>
                            <th className="centerAlign">Verified?</th>
                          </tr>
                        </thead>
                        <tbody>
                          {challanList?.length > 0 ? challanList?.map((challan) => (
                            <tr>
                              <td>
                                <Button
                                  variant="link"
                                  onClick={() => updateChallanInfo(challan)}
                                  style={{ padding: '0px', fontSize: '12px' }}
                                >
                                  {challan.challan_no}
                                </Button>
                              </td>
                              <td>
                                {challan.deposit_date}
                              </td>
                              <td className="centerAlign">
                                {challan.total_amtp ? formatNumber(challan.total_amtp) : null}
                              </td>
                              <td style={{ color: getChartColor(challan) }}>
                                {challan.unutilized_challan_amtp ? formatNumber(challan.unutilized_challan_amtp) : null}
                              </td>
                              <td className="centerAlign">
                                <span style={{ color: `#008000` }}>
                                  Yes
                                </span>
                              </td>
                            </tr>
                          )) : <tr><td colspan="5" style={{ textAlign: "center" }}><span className="dark_color_text">No Records Found!</span></td></tr>}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              }

            </div>
            <div className="col-md-4" >
              {skeletonChartLoading ?  
               <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Skeleton animation="wave" width={"150px"} /> 
                   </span>
                :
                <span
                  className="row"
                  style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}
                >
                  <span className=" col-md-6 totalamttextrd" style={{marginTop: '30px'}}>
                    Total amount available to be linked
                  </span>
                  <span className="col-md-6 totalamtrd" style={{marginTop: '30px'}}>
                    {/* ₹ */}

                    {' '}
                    {formatNumber(totalAvailableChallanAmt)}
                  </span>
                </span>
              }

              {showChallanBreakup && (
                <span
                  style={{
                    position: 'absolute', right: '5px', fontSize: '16px', top: '45%', cursor: 'pointer',
                  }}
                  onClick={() => setShowChallanBreakup(false)}
                >
                  <FontAwesomeIcon
                    icon={faArrowAltCircleRight}
                    size="lg"
                  />
                </span>
              )}
            </div>

          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default ChallanSummary;
