import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { gocallApi } from '../../api/issueCertificateAPI';
import { getTracesCaptchaFromConnector } from '../../api/localConnectorAPI';
import { addDeductorFromPortal, verifyItCrendital } from '../../api/deductorAPI';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import "../deductorList/DeductorIncompleteList.scss";
import searchIcon from "../../images/search.png";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { AddDeducteeTransactionsIcon } from '../../components/grid/CustomCell';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import SyncData from '../../components/SyncData';
import BlurOnChangeInput from '../../components/BlurOnChangeInput';
import DeductorSyncClient from '../createDeductor/DeductorSyncClient';
import Form3CDTimeLine from './Form3CDTimeLine';
import { FORM_3CD_START_YEAR } from '../../app/constants';
import './PanWiseTanDetails.scss';
import { getCurrentFinancialYear } from '../../utils/UtilityFunctions';
import SemanticPagination from '../../components/pagination/SemanticPagination';

const ListAllDeductors = () => {
    const location = useLocation();
    const searchStr = location.search;
    const history = useHistory();
    const params = queryString.parse(location.search);
    const [port, setPort] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null })
    const [isCaptchaCall, setIsCaptchaCall] = useState(false);
    const [userIndex, setUserIndex] = useState("");
    const [syncValidDeductor, setSyncValidDeductor] = useState(null);
    const [incompDedSummary, setIncompDedSummary] = useState({ "completed_tan_count": 0, "pending_tan_count": 0 });
    const [panInput, setPanInput] = useState(params.pan); // State to hold the PAN input value
    const fy = params?.finacial_year ? params?.finacial_year : FORM_3CD_START_YEAR;
    const [startConnector, setStartConnector] = useState(false);
    const [deductorId, setDeductorId] = useState(false);
    const [tan, setTan] = useState(false);
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [captchaRef, setCaptchaRef] = useState(null);
    const [filterYear, setFilterYear] = useState(false);

    const handleChange = (value, index, name) => {
        data[index][name] = value;
        if (data?.[index]?.errObj?.[name]) data[index].errObj[name] = "";

        setData([...data]);
    };

    const validateCredential = (validateData, index) => {
        let validation = true;
        const tanPattern = new RegExp('[A-Z]{4}[0-9]{5}[A-Z]{1}');

        let errorObj = {}

        // if(!validateData?.deductor_name?.trim()) errorObj.deductor_name = "This field is required"
        if (!validateData?.tan?.trim()) errorObj.tan = "This field is required";
        if (validateData?.tan?.trim() && !tanPattern.test(validateData?.tan?.trim())) errorObj.tan = "Invalid TAN Number";
        if (!validateData?.itdportal_password?.trim()) errorObj.itdportal_password = "This field is required";

        if (validateData?.traces_username?.trim() && validateData?.traces_password?.trim() && !validateData?.captcha_text?.trim()) errorObj.captcha_text = "Captcha is required";

        if (Object.keys(errorObj)?.length > 0) {
            validation = false;
            data[index].errObj = errorObj;
            setData([...data]);
        }
        return validation;
    }

    const saveCredentialOnserver = (data, index) => {
        const validation = validateCredential(data, index);
        if (validation) {
            setShowConnnectorCall(true);
        }

    }

    const setSyncData = (data) => {
        setDeductorId(data?.deductor_id);
        setTan(data?.tan);
        setStartConnector(true);
        setFilterYear(2023)
    }

    const getParamsHistoryUrl = () => {
        dataState.pan = params?.pan ?? "";

        if (params?.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
        }
        if (params?.filters?.length > 0) {
            dataState.filter = { filters: [] };
            dataState.filter.filters = JSON.parse(atob(params.filters));
        } else {
            dataState.filter = { filters: [] };
        }

        setDataState({ ...dataState });
    };

    const getIncompletDeductorList = async (parameter) => {
        setLoading(true);
        let filters = null;
        if (parameter?.filters?.length > 0) {
            filters = JSON.parse(atob(parameter.filters));
        }
        const url = "/api/v1/form3cd-annexures/deductor-list";
        const payload = {
            filters: parameter.filters ? filters : null,
            sort_on: parameter.sort_on ? parameter.sort_on : '',
            sort_by: parameter.order_by ?? "",
            page: parseInt(parameter.page),
            limit: 100,
            pan: parameter.pan ? parameter.pan : '',
        }
        try {
            const result = await gocallApi("post", url, payload);
            if (result?.data?.data?.length > 0) {
                setData(result?.data?.data);

                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result.data.cursor.next_page;
                setCursor(result?.data?.cursor ?? { current_page: 1, next_page_url: null });
                setIncompDedSummary(result?.data?.summary);
            } else {
                setData([]);
            }

        } catch (err) {
            if (err?.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false);
        }
    }

    const getCaptcha = async (index, port) => {
        setLoading(true);

        async function fetchEverything() {           
            try {
                const result = await getTracesCaptchaFromConnector(port);
                data[index]["captcha"] = result.data ?? {};
                data[index]["captcha_text"] = "";
                setData([...data]);
            } catch (err) {
                setError("Not able to fetch captcha, please try again after sometime. If the problem persists, then try with different internet");
                console.error(err);
                // if (err.message) setError(err.message);
                // else setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };

    const rowValidation = (rowData, index) => {
        let errObj = {};
        let validation = true;
        const tanPattern = new RegExp('[A-Z]{4}[0-9]{5}[A-Z]{1}');
        // if(!rowData?.deductor_name?.trim()) errObj.deductor_name = "This field is required"
        if (!rowData?.tan?.trim()) errObj.tan = "This field is required";
        if (rowData?.tan?.trim() && !tanPattern.test(rowData?.tan?.trim())) errObj.tan = "Invalid TAN Number";
        if (!rowData?.itdportal_password?.trim()) errObj.itdportal_password = "This field is required";

        if (Object.keys(errObj).length > 0) {
            validation = false;
            setData((prev) => {
                prev[index].errObj = errObj;
                return [...prev];
            })
        }
        return validation;
    }

    const updateRowData = (rowData, index, prevTan) => {
        const validation = rowValidation(rowData, index)

        async function fetchEverything() {
            const payload = {
                name: rowData.deductor_name ?? "",
                itdportal_password: rowData?.itdportal_password ?? "",
                tan: rowData?.tan ?? "",
                tentative_deductor_id: rowData?.tentative_deductor_id ?? "",
                traces_password: rowData?.traces_password ?? "",
                traces_username: rowData?.traces_username ?? "",
            }
            try {
                await gocallApi("put", "/api/v1/tentative-deductor", payload);

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
                rowData.tan = prevTan
            }
        }
        if (validation) {
            fetchEverything();
        }
    }

    const sendPortalFormRequest = async (datavalue, extraHeader) => {
        const payload = {
            data: datavalue,
        };
        try {
            const result = await addDeductorFromPortal(payload, extraHeader);
            setSuccess(result.message);
            if (result.data) {
                const obj = {
                    itdportal_username: userInfo.tan,
                    itdportal_password: userInfo.itdportal_password,
                    deductor_id: result?.data?.deductor_id
                }
                setTimeout(() => { setSyncValidDeductor(obj) }, 2000)
                // setTimeout(() => { setSyncValidDeductor(null) } ,4000)
            }
            if (data[userInfo.dataIndex]) {
                data.splice(userInfo.dataIndex, 1)
                setData([...data]);
                setUserInfo({});
            }
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false);
        }
    }


    const verfiyCredApi = () => {
        setError(null);
        setLoading(true);

        const request = {
            name: userInfo.deductor_name ?? "",
            itdportal_username: userInfo.tan ?? "",
            itdportal_password: userInfo.itdportal_password ?? "",
        };

        if (userInfo.traces_username && userInfo.traces_password) {
            request.traces_username = userInfo.traces_username ?? "";
            request.traces_password = userInfo.traces_password ?? "";
            request.captcha_text = userInfo.captcha_text ?? "";
            request.captcha_ref = userInfo?.captcha?.captcha_ref ?? "";
        }

        async function fetchEverything() {
            try {
                const result = await verifyItCrendital(port, request);
                if (result.data.status === 1) {
                    const responseHeader = result.headers ?? null;
                    let extraHeader = {};
                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                    sendPortalFormRequest(result.data.data, extraHeader)
                }
            } catch (err) {
                console.error('error: ', err);
                setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
                setLoading(false);
                getCaptcha(userInfo.dataIndex ?? "", port)
            } finally {
                setCheckItCredentials(false);
            }
        }
        fetchEverything();
    };

    const createHistoryUrl = (filters) => {

        let filterStr = `?page=1`
        filterStr += `&pan=${params.pan}`;
        if (filters.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
        }

        if (filters.sort && filters.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
        }

        history.push(filterStr);
    };

    const dataStateChange = (data_state) => {
        createHistoryUrl(data_state);
        setDataState(data_state);
    }

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };


    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem;
        const index = props.dataIndex;

        return (
            <tr>
                <td className="text-center">{dataItem.tan ?? ""}</td>
                <td className='text-left'>{dataItem.deductor_name ?? ""}</td>

                <td className="text-center form-groupposition">
                    {dataItem?.[`itd${dataItem.tentative_deductor_id}`] ?
                        <div className="input-group">
                            <BlurOnChangeInput type="text"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.itdportal_password ? "input-error" : ""}`}
                                placeholder="Enter ITD Password"
                                value={dataItem.itdportal_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.itdportal_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "itdportal_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`itd${dataItem.tentative_deductor_id}`] = false;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEyeSlash} />
                            </div>

                        </div> :
                        <div className="input-group">
                            <BlurOnChangeInput type="password"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.itdportal_password ? "input-error" : ""}`}
                                placeholder="Enter ITD Password"
                                value={dataItem.itdportal_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.itdportal_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "itdportal_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`itd${dataItem.tentative_deductor_id}`] = true;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </div>

                        </div>
                    }
                </td>

                <td className="text-left">
                    <BlurOnChangeInput type='text'
                        className={`form-control incompded_inputheight ${dataItem?.errObj?.traces_username ? "input-error" : ""}`}
                        value={dataItem.traces_username ?? ""}
                        maxLength={11}
                        data-toggle="tooltip"
                        data-placement="right" data-original-title={dataItem?.errObj?.traces_username ?? ""}
                        onChange={(e) => handleChange(e.target.value, index, "traces_username", dataItem?.tentative_deductor_id ?? "")}
                        onBlur={() => { updateRowData(dataItem, index) }}
                        placeholder="Enter Traces Username"
                    />
                </td>

                <td className="text-center form-groupposition">
                    {dataItem?.[`traces${dataItem.tentative_deductor_id}`] ?
                        <div className="input-group">
                            <BlurOnChangeInput type="text"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.traces_password ? "input-error" : ""}`}
                                placeholder="Enter Traces Password"
                                value={dataItem.traces_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.traces_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "traces_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}
                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`traces${dataItem.tentative_deductor_id}`] = false;
                                        return [...prev]
                                    })
                                }}
                                style={{ cursor: "pointer" }}>
                                <FontAwesomeIcon icon={faEyeSlash} />
                            </div>

                        </div> :
                        <div className="input-group">
                            <BlurOnChangeInput type="password"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.traces_password ? "input-error" : ""}`}
                                placeholder="Enter Traces Password"
                                value={dataItem.traces_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.traces_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "traces_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`traces${dataItem.tentative_deductor_id}`] = true;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </div>

                        </div>}
                </td>


                {(dataItem.completed_deductor === 1 && dataItem.deductor_id && dataItem.itdportal_password && dataItem.tan) ?
                    <>
                        <td className="text-center" >
                            <span className="clintmwloadingimg cursor-pointer" onClick={() => { if (dataItem?.sync_status !== "IN_PROGRESS") setSyncData(dataItem) }} >
                                <FontAwesomeIcon icon={faSync} width="16" className={dataItem?.sync_status === "IN_PROGRESS" ? 'rotate_icon' : ''} />
                            </span>
                        </td>

                        <td className="text-left pl-5 ml-5" colSpan={4}></td>
                    </>
                    :
                    <>
                        <td className="text-center">
                            <button className="btn btn-default blukimp_captchbtn " onClick={() => {
                                if (dataItem.traces_password && dataItem.traces_username) {
                                    setIsCaptchaCall(true);
                                    setUserIndex(index);
                                } else {
                                    validateCaptcha(dataItem, index);
                                }
                            }}>
                                Get Captcha
                            </button>

                        </td>
                        <td className="text-center">
                            <img
                                src={`data:image/png;base64, ${dataItem?.captcha?.captcha_image ?? ""}`}
                                width="100" alt="" />
                        </td>

                        <td className="text-center">
                            <input type="text"
                                className={`form-control incompded_inputheight mypc_credinputtextfs ${dataItem?.errObj?.captcha_text ? "input-error" : ""}`}
                                placeholder="Captcha"
                                value={dataItem.captcha_text ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.captcha_text ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "captcha_text", dataItem?.tentative_deductor_id ?? "")}
                            />
                        </td>
                        <td className="text-center  ">
                            <button className="btn btn-default blukimp_captchbtn create_deductor"
                                onClick={() => {
                                    dataItem.dataIndex = index
                                    setUserInfo(dataItem);
                                    saveCredentialOnserver(dataItem, index);
                                }} >
                                Create Deductor
                            </button>
                        </td>
                        <td></td>
                    </>
                }
            </tr>)
    }



    const validateCaptcha = (dataItem, index) => {
        if (!dataItem.errObj) dataItem.errObj = {};
        if (!dataItem.traces_password) dataItem.errObj.traces_password = "Traces Password is required"
        if (!dataItem.traces_username) dataItem.errObj.traces_username = "Traces UserName is required"
        data[index] = dataItem;
        setData([...data]);
    }

    useEffect(() => {
        if (port && checkItCredentials) {
            verfiyCredApi();
        }
        //eslint-disable-next-line
    }, [port, checkItCredentials])

    useEffect(() => {
        if (params.pan) {
            const param = queryString.parse(searchStr)
            getIncompletDeductorList(param)
        }
    }, [params.pan, searchStr])

    useEffect(() => {
        getParamsHistoryUrl()
        // eslint-disable-next-line
    }, [location.search]);

    $(function () {
        $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    })

    const renderMandatoryTitle = (title) => {
        return (
            < >
                <span>{title}</span>{<span style={{ color: 'red' }}>*</span>}
            </>
        );
    }

    const handlePanChange = (event) => {
        setPanInput(event.target.value.toUpperCase()); // Update PAN input state
    };

    const handleToGetPanDetails = (event) => {
        if (panInput !== params.pan) {
            history.push(`?pan=${panInput}&fy=${fy}`);
        }
    };


    const handleKeyPress = (event) => {
        if (event.key === 'Enter' || event.type === 'click' || event.type === 'Tab') {
            validatePan(event.target.value);
        }
    };

    const handleBlur = (event) => {
        validatePan(event.target.value);
    };

    const validatePan = (pan) => {

        if (!pan) {
            return;
        }
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (panRegex.test(pan.toUpperCase())) {
            handleToGetPanDetails();
        } else {
            setError('Invalid PAN number. Please enter correct PAN number');
        }
    };



    const setShowSyncVerifyStatus = (TAN) => {
        const listData = JSON.parse(JSON.stringify(data));
        for (const item of listData) {
            if (item?.tan === TAN) {
                item.sync_status = "IN_PROGRESS";
            }
        }
        setData((prev) => {
            prev = listData
            return prev
        });
    }

    return (
        <>
            {loading && <LoadingIndicator />}

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {startConnector
                ? (<ConnectorAdapter
                    show={startConnector}
                    handleClose={() => setStartConnector(false)}
                    setPort={setPort}
                    handleProceed={() => setCheckTracesCredentials(true)}
                />)
                : null
            }

            {isCaptchaCall && <ConnectorAdapter
                show={true}
                handleClose={() => {
                    setIsCaptchaCall(false);
                }}
                setPort={setPort}
                handleProceed={(getPort) => {
                    getCaptcha(userIndex,getPort);
                }}
            />}

            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => {
                        setShowConnnectorCall(false);
                    }}
                    setPort={setPort}
                    handleProceed={() => {
                        setCheckItCredentials(true);
                    }}
                />
                : null
            }



            {checkTracesCredentials && port
                ? <TracesConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={() => {
                        setStartConnector(false);
                        setCheckTracesCredentials(false);
                    }}
                    tan={tan}
                    port={port}
                    setCaptchaRef={setCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                />
                : null
            }

            {captchaRef && port
                ? (
                    <SyncData
                        deductorId={deductorId}
                        returnItem={{
                            'financial_year': filterYear?.value ?? getCurrentFinancialYear(),
                        }}
                        port={port}
                        captchaRef={captchaRef}
                        setSuccess={setSuccess}
                        setError={setError}
                        setShowSyncVerify={() => {
                            setShowSyncVerifyStatus(tan);
                            setStartConnector(false);
                            setCheckTracesCredentials(false);
                            setCaptchaRef(null);
                        }}
                    />
                )
                : null}





            {syncValidDeductor &&
                <DeductorSyncClient
                    syncDeductor={syncValidDeductor}
                />
            }

           
                <Form3CDTimeLine currentPage="ListAllDeductors" getPan={params.pan} />

                <div className="container-fluid mt-2 deductormasterlist incomplete_deductorlis_css">
                <div className="row">

                    <div className="col-md-12 text-center">
                        <div className="tds_form3CDRevamp_yr_dropdownbox d-flex justify-content-center">
                            {/* <div className="form-inline pan-input" autoComplete="off">
                                <input
                                    className="form-control panwise-tan-input pr-3"
                                    type="search"
                                    placeholder="Enter PAN to generate report"
                                    aria-label="Search"
                                    value={panInput}
                                    maxLength={10}
                                    onChange={handlePanChange}
                                    autoFocus={panInput ? false : true}
                                    onKeyDown={(event) => {
                                        handleKeyPress(event);
                                    }}
                                    onBlur={handleBlur}
                                />
                                <img className="search_text_icon doflistsiconboxmrgin cursor-pointer" src={searchIcon} alt="search icon" onClick={(e) => handleKeyPress(e)} />
                            </div> */}

                            <div className="input-group-append exptds_panwise_searchlist pan-input" autoComplete="off">
                                <input
                                    className="form-control panwisetan_inputsearch panwise-tan-input pr-3"
                                    type="search"
                                    placeholder="Enter PAN to generate report"
                                    aria-label="Search"
                                    value={panInput}
                                    maxLength={10}
                                    onChange={handlePanChange}
                                    autoFocus={panInput ? false : true}
                                    onKeyDown={(event) => {
                                        handleKeyPress(event);
                                    }}
                                    onBlur={handleBlur}
                                />
                                <span className='input-group-text exptds_panwise_searchiconbox'>
                                    <img className="exptds_panwise_searchicon cursor-pointer" src={searchIcon} alt="search icon" width={16} onClick={(e) => handleKeyPress(e)} />
                                </span>
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 text-center pt-2">
                        <div className="card tds_form3CDRevamp_genrepottyp_cardbox">
                            <p className="tds_form3CDRevamp_wecloud_textfs mb-2">
                                We could find <span className='textBlue'>{incompDedSummary.completed_tan_count}</span> TAN in software, it is advised to complete creation process for <span className='textBlue'>{incompDedSummary.pending_tan_count}</span> and get the complete Report,
                                you may proceed to generate report for deductors created in the software.
                            </p>
                            <p className="tds_form3CDRevamp_wecloud_textfs mb-2">
                                Password for <span className='textBlue'>{incompDedSummary?.password_count ?? 0}</span> TAN is
                                not entered / expired and Oldest sync for <span className='textBlue'>{incompDedSummary?.oldest_sync_tan ?? ""}</span> was done before
                                <span className='textBlue'> {incompDedSummary?.oldest_sync_days ?? 0}</span> days. Suggest to run the sync using the
                                sync (icon) button against each TAN</p>

                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                                <Grid
                                    className="table table-striped blukimport_tb mb-0 scrollvisible incvisibleover"
                                    data={data}
                                    {...dataState}
                                    sortable
                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterEqOperator', operator: 'in' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                >

                                    <Column
                                        width="105"
                                        field="tan"
                                        // title={renderMandatoryTitle("TAN")}
                                        title={<AddDeducteeTransactionsIcon field="tan" dataState={dataState}
                                            dataStateChange={dataStateChange}
                                            title={renderMandatoryTitle("TAN")}
                                            subtextData={{ subText: `(${(incompDedSummary.completed_tan_count + incompDedSummary.pending_tan_count)})` ?? 0, color: '' }}
                                            showButton={false}
                                            headClass={true}
                                        />}



                                        filter="text"
                                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('tan') ? 'active cursor-pointer' : 'cursor-pointer'}
                                    />

                                    <Column
                                        width="230"
                                        field="deductor_name"
                                        title={"Deductor Name"}
                                        filter="text"
                                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('deductor_name') ? 'active cursor-pointer' : 'cursor-pointer'}
                                    />



                                    <Column
                                        width="135"
                                        title={renderMandatoryTitle("ITD  Password")}
                                        sortable={false}

                                    />

                                    <Column
                                        width="105"
                                        field="traces_username"
                                        title={<span title='Traces Username' >{"Traces Username"}</span>}
                                        sortable={false}
                                    // filter="text"
                                    // columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                    // headerClassName={isColumnActive('traces_username') ? 'active' : ''}
                                    />


                                    <Column
                                        width="137"
                                        title={<span title='Traces Password'>{"Traces Password"}</span>}
                                        sortable={false}

                                    />

                                    <Column
                                        width="108"
                                        sortable={false}
                                    />

                                    <Column
                                        width="80"
                                        sortable={false}
                                    />
                                    <Column
                                        width="60"
                                        sortable={false}
                                    />
                                    <Column
                                        width="130"
                                        sortable={false}
                                    />
                                    <Column
                                        width="20"
                                        sortable={false}
                                    />
                                </Grid>

                            </div>
                        </div>
                    </div>
                    <SemanticPagination cursor={cursor} count="password_count" pagelimit={12} summary={incompDedSummary} />
                </div>
            </div>
        </>
    )
}

export default ListAllDeductors;

