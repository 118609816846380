import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { deepClone } from "../../../utils/UtilityFunctions";
import ShowAlert from "../../../components/ShowAlert";
import $ from 'jquery';
import Menu from "./Menu";
import './ExcelToTdsMapping.scss';

const REQUIRED_MAPPED_KEYS = ['pan', 'nm', 'regime', 'catg']

const SALARY_OBJECT = {
    'employee_dt': [
        { key: 'ref_no', label: 'Employee Ref. No.', },
        { key: 'pan', label: 'PAN', },
        { key: 'nm', label: 'Name', },
        { key: 'dob', label: 'DOB', },
        { key: 'age', label: 'Age', },
        { key: 'catg', label: 'Category', },
        { key: 'regime', label: 'Regime', },
        { key: 'location', label: 'Location', },
        { key: 'email', label: 'Email', },
    ],
    'salary17_1': [
        { key: 'sal_1_amt', label: 'Basic', },
        { key: 'sal_2_amt', label: 'DA Forming Part of Salary' },
        { key: 'sal_3_amt', label: 'Commission' },
        { key: 'sal_4_amt', label: 'Advance Salary' },
        { key: 'sal_5_amt', label: 'Bonus' },
        { key: 'sal_6_amt', label: 'Variable' },
        { key: 'sal_6_prd', label: 'Variable Payout Period' },
    ],
    'perquisites17_2': {
        'perq_1': [
            { key: 'perq_1', label: 'Rent Free Accomodation' },
            { key: 'perq_1_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_1_ex_amt', label: 'Exempted' },
        ],
        'perq_2': [
            { key: 'perq_2', label: 'Perquisite of Motor Car' },
            { key: 'perq_2_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_2_ex_amt', label: 'Exempted' },
        ],
        'perq_3': [
            { key: 'perq_3', label: 'LTC' },
            { key: 'perq_3_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_3_ex_amt', label: 'Exempted' },
        ],
        'perq_4': [
            { key: 'perq_4', label: 'Free Concessional Supply of gas/water' },
            { key: 'perq_4_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_4_ex_amt', label: 'Exempted' },
        ],
        'perq_5': [
            { key: 'perq_5', label: 'Domestic Servent' },
            { key: 'perq_5_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_5_ex_amt', label: 'Exempted' },
        ],
        'perq_6': [
            { key: 'perq_6', label: 'Free education Facility' },
            { key: 'perq_6_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_6_ex_amt', label: 'Exempted' },
        ],
        'perq_7': [
            { key: 'perq_7', label: 'Transfer of Movable Assests(Car)' },
            { key: 'perq_7_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_7_ex_amt', label: 'Exempted' },
        ],
        'perq_8': [
            { key: 'perq_8', label: 'Lunch' },
            { key: 'perq_8_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_8_ex_amt', label: 'Exempted' },
        ],
        'perq_9': [
            { key: 'perq_9', label: 'Credit Card' },
            { key: 'perq_9_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_9_ex_amt', label: 'Exempted' },
        ],
        'perq_10': [
            { key: 'perq_10', label: 'Interest Free Loan' },
            { key: 'perq_10_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_10_ex_amt', label: 'Exempted' },
        ],
        'perq_11': [
            { key: 'perq_11', label: 'Use of Movable assets' },
            { key: 'perq_11_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_11_ex_amt', label: 'Exempted' },
        ],
        'perq_20': [
            { key: 'perq_20', label: 'Tax on Perquisite paid by employer' },
            { key: 'perq_20_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_20_ex_amt', label: 'Exempted' },
        ],
        'perq_12': [
            { key: 'perq_12', label: 'Gift' },
            { key: 'perq_12_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_12_ex_amt', label: 'Exempted' },
        ],
        'perq_13': [
            { key: 'perq_13', label: 'Club Facility' },
            { key: 'perq_13_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_13_ex_amt', label: 'Exempted' },
        ],
        'perq_14': [
            { key: 'perq_14', label: 'Medical Facility India' },
            { key: 'perq_14_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_14_ex_amt', label: 'Exempted' },
        ],
        'perq_15': [
            { key: 'perq_15', label: 'Obligation of Employee met by employer' },
            { key: 'perq_15_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_15_ex_amt', label: 'Exempted' },
        ],
        'perq_16': [
            { key: 'perq_16', label: 'Any other Perquisite' },
            { key: 'perq_16_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_16_ex_amt', label: 'Exempted' },
        ],
        'perq_17': [
            { key: 'perq_17', label: 'Employer\'s contribution to Superannuation Fund' },
            { key: 'perq_17_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_17_ex_amt', label: 'Exempted' },
        ],
        'perq_18': [
            { key: 'perq_18', label: 'Stock options allotted or transferred' },
            { key: 'perq_18_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_18_ex_amt', label: 'Exempted' },
        ],
        'perq_19': [
            { key: 'perq_19', label: 'Stock options (non-qualified options) other than ESOP' },
            { key: 'perq_19_pr_amt', label: 'Provided Or Taxable' },
            { key: 'perq_19_ex_amt', label: 'Exempted' },
        ],
    },
    // 'prft': {
    //     'prft_1': [
    //         { key: 'prft_1', label: 'Compensation to Voluntary retirement' },
    //         { key: 'prft_1_pr', label: 'Provided Or Taxable' },
    //         { key: 'prft_1_ex', label: 'Exempted' },
    //     ],
    //     'prft_2': [
    //         { key: 'prft_2', label: 'Retrenchment Compensation' },
    //         { key: 'prft_2_pr', label: 'Provided Or Taxable' },
    //         { key: 'prft_2_ex', label: 'Exempted' },
    //     ],
    //     'prft_3': [
    //         { key: 'prft_3', label: 'PF Interest/PF Payment' },
    //         { key: 'prft_3_pr', label: 'Provided Or Taxable' },
    //         { key: 'prft_3_ex', label: 'Exempted' },
    //     ],
    //     'prft_4': [
    //         { key: 'prft_4', label: 'Any other amount received as profit in lieu of salary' },
    //         { key: 'prft_4_pr', label: 'Provided Or Taxable' },
    //         { key: 'prft_4_ex', label: 'Exempted' },
    //     ],
    // },
    'prft': [
        { key: 'prft_1_amt', label: 'Compensation to Voluntary retirement' },
        { key: 'prft_2_amt', label: 'Retrenchment Compensation' },
        { key: 'prft_3_amt', label: 'PF Interest/PF Payment' },
        { key: 'prft_4_amt', label: 'Any other amount received as profit in lieu of salary' },
    ],

    // 'rb': {
    //     'rb_1': [
    //         { key: 'rb_1', label: 'Leave encashment - on retirement' },
    //         { key: 'rb_1_pr', label: 'Provided Or Taxable' },
    //         { key: 'rb_1_ex', label: 'Exempted' },
    //     ],
    //     'rb_2': [
    //         { key: 'rb_2', label: 'Gratuity' },
    //         { key: 'rb_2_pr', label: 'Provided Or Taxable' },
    //         { key: 'rb_2_ex', label: 'Exempted' },
    //     ],
    //     'rb_3': [
    //         { key: 'rb_3', label: 'Un-commuted pension' },
    //         { key: 'rb_3_pr', label: 'Provided Or Taxable' },
    //         { key: 'rb_3_ex', label: 'Exempted' },
    //     ],
    //     'rb_4': [
    //         { key: 'rb_4', label: 'Commuted pension' },
    //         { key: 'rb_4_pr', label: 'Provided Or Taxable' },
    //         { key: 'rb_4_ex', label: 'Exempted' },
    //     ],
    // },
    'rb': [
        { key: 'rb_1_amt', label: 'Leave encashment - on retirement' },
        { key: 'rb_2_amt', label: 'Gratuity' },
        { key: 'rb_3_amt', label: 'Un-commuted pension' },
        { key: 'rb_4_amt', label: 'Commuted pension' },
    ],
    'via_ded': [
        { key: 'via_ded_80c_amt', label: '80C' },
        { key: 'via_ded_80ccc_amt', label: '80CCC' },
        { key: 'via_ded_80ccd_1_amt', label: '80CCD(1)' },
        { key: 'via_ded_80ccd_2_amt', label: '80CCD(2)' },
        { key: 'via_ded_80ccd_1b_amt', label: '80CCD(1B)' },
        { key: 'via_ded_80d_self_amt', label: '80D(self)' },
        { key: 'via_ded_80d_p_amt', label: '80D(Parents)' },
        // { key: 'via_ded_80e_amt', label: '80E' },
        { key: 'via_ded_80g_50_amt', label: '80G(50%)' },
        { key: 'via_ded_80g_100_amt', label: '80G(100%)' },
        { key: 'via_ded_80g_oth_50_amt', label: '80G(Others 50%)' },
        { key: 'via_ded_80g_oth_100_amt', label: '80G(Others 100%)' },
        { key: 'via_ded_80tta_amt', label: '80TTA' },
        { key: 'via_ded_80oth_amt', label: 'Others' },
    ],
    'alw': {
        'alw_1': [
            { key: 'alw_1', label: 'House rent allowance' },
            { key: 'alw_1_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_1_ex_amt', label: 'Exempted' },
        ],
        'alw_2': [
            { key: 'alw_2', label: 'Children education allowance' },
            { key: 'alw_2_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_2_ex_amt', label: 'Exempted' },
        ],
        'alw_3': [
            { key: 'alw_3', label: 'Children hostel allowance' },
            { key: 'alw_3_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_3_ex_amt', label: 'Exempted' },
        ],
        'alw_4': [
            { key: 'alw_4', label: 'Transport allowance (Residence - Office)' },
            { key: 'alw_4_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_4_ex_amt', label: 'Exempted' },
        ],
        'alw_5': [
            { key: 'alw_5', label: 'Conveyance allowance' },
            { key: 'alw_5_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_5_ex_amt', label: 'Exempted' },
        ],
        'alw_6': [
            { key: 'alw_6', label: 'Helper allowance' },
            { key: 'alw_6_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_6_ex_amt', label: 'Exempted' },
        ],
        'alw_7': [
            { key: 'alw_7', label: 'Uniform allowance' },
            { key: 'alw_7_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_7_ex_amt', label: 'Exempted' },
        ],
        'alw_8': [
            { key: 'alw_8', label: 'Compensatory field area allowance' },
            { key: 'alw_8_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_8_ex_amt', label: 'Exempted' },
        ],
        'alw_13': [
            { key: 'alw_13', label: 'Daily allowance' },
            { key: 'alw_13_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_13_ex_amt', label: 'Exempted' },
        ],
        'alw_14': [
            { key: 'alw_14', label: 'Border area, remote locality or disturbed area or difficult area allowance' },
            { key: 'alw_14_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_14_ex_amt', label: 'Exempted' },
        ],
        'alw_9': [
            { key: 'alw_9', label: 'Transfer allowance' },
            { key: 'alw_9_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_9_ex_amt', label: 'Exempted' },
        ],
        'alw_10': [
            { key: 'alw_10', label: 'Traveling allowance' },
            { key: 'alw_10_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_10_ex_amt', label: 'Exempted' },
        ],
        'alw_11': [
            { key: 'alw_11', label: 'Other special allowances u/s 1(14)' },
            { key: 'alw_11_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_11_ex_amt', label: 'Exempted' },
        ],
        'alw_12': [
            { key: 'alw_12', label: 'Any other allowance' },
            { key: 'alw_12_pr_amt', label: 'Provided Or Taxable' },
            { key: 'alw_12_ex_amt', label: 'Exempted' },
        ],
    }
};


const ExcelToTdsMapping = (props) => {
    const { handleProceed, mappingColumn, columnList, setColumnList, setTemplateName, templateName, templateId, isDefaultProceed, handleFileUploadPopupClose } = props;
    const dragItem = useRef();
    // eslint-disable-next-line
    const [salaryMappingObj, setSalaryMappingObj] = useState(deepClone(SALARY_OBJECT));
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [mapping, setMapping] = useState({});
    const [labels, setLabels] = useState({});
    const [colorClass, setColorClass] = useState({});
    const [showProceed, setShowProceed] = useState(false);
    const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
    const [templateNameError, setTemplateNameError] = useState(null)


    const outerRef = useRef(null);
    const dragStart = (e, id) => {
        dragItem.current = id;
        const elem = document.createElement("div");
        elem.id = 'drag-element-tooltip';
        let str = "Drag & Drop Salary component <br />"
        elem.innerHTML = `${str} <span class='drogged_element'>${mappingColumn[id]}</span>`;
        $('#drag-element-tooltip-main').html(elem);
        e.dataTransfer.setDragImage(elem, 0, 0);
        e.dataTransfer.setData("text/plain", id);
    };


    const checkAndEnableProccedBtn = () => {
        let isError = false;
        for (let i = 0; i < REQUIRED_MAPPED_KEYS.length; i++) {
            if (columnList.includes(REQUIRED_MAPPED_KEYS[i]) === false) {
                isError = true;
                break;
            }
        }
        if (!isError) {
            setShowProceed(true);
        }else{
            setShowProceed(false)
        }

    }


    const handleRightClickSelection = (key, index) => {

        if (key !== '' && index !== '') {


            checkAndEnableProccedBtn()

            //check for exiting same mapping
            if (columnList?.[index] === key) {
                setError('Already mapped with this heading');
                return true;
            }

            const mappingObj = deepClone(mapping);

            //remove old mapping for key if any
            Object.keys(mappingObj).forEach((item) => {
                if (Number(mappingObj[item]) === Number(index)) {
                    mappingObj[item] = '';
                }
            })

            mappingObj[key] = String(index);
            setMapping((prev) => {
                prev = mappingObj
                return { ...prev }
            })
            columnList[index] = key;
            setColumnList((prev) => {
                prev = columnList
                return [...prev]
            })
        }
    }



    const removeKey = (key) => {
        setMapping((prev) => {
            prev[key] = ""
            return { ...prev }
        })
        const index = columnList.findIndex((item) => String(item) === String(key))
        if (index >= 0) {
            setColumnList((prev) => {
                prev[index] = ''
                return [...prev]
            })
        }

        checkAndEnableProccedBtn()
    }

    const addDragData = (key, index) => {

        if (key === '' || index === '') {

            setError("Oops, we missed it! Can you try again, please?");

        } else {

            //check for exiting same mapping
            if (columnList?.[index] === key) {
                setError('Already mapped with this heading');
                return true;
            }

            checkAndEnableProccedBtn()
            const mappingObj = deepClone(mapping);

            //remove old mapping for key if any
            Object.keys(mappingObj).forEach((item) => {
                if (mappingObj[item] === index) {
                    mappingObj[item] = '';
                }
            })

            mappingObj[key] = index;
            setMapping((prev) => {
                prev = mappingObj
                return { ...prev }
            })
            columnList[index] = key;
            setColumnList((prev) => {
                prev = columnList
                return [...prev]
            })

        }
    }

    const resetMappingKeyList = (salaryMappingObj, columnList) => {

        const mappingKeys = {};
        const labelKeys = {};
        const colorClass = {}

        Object.keys(salaryMappingObj).forEach((item) => {
            if (['employee_dt', 'salary17_1', 'via_ded', 'prft', 'rb',].includes(item)) {
                salaryMappingObj[item].forEach((subItem) => {
                    mappingKeys[subItem.key] = '';
                    colorClass[subItem.key] = item;
                    if (item === 'employee_dt') {
                        labelKeys[subItem.key] = `Employee - ${subItem.label}`;
                    } else if (item === 'salary17_1') {
                        labelKeys[subItem.key] = `Salary 17(1) - ${subItem.label}`;
                    } else {
                        labelKeys[subItem.key] = `Deductions - ${subItem.label}`;
                    }
                });
            } else if (['perquisites17_2', 'alw'].includes(item)) {
                Object.keys(salaryMappingObj[item]).forEach((subItem) => {
                    if (salaryMappingObj?.[item]?.[subItem]?.[1]) {
                        mappingKeys[salaryMappingObj?.[item]?.[subItem]?.[1]?.key] = '';
                        labelKeys[salaryMappingObj?.[item]?.[subItem]?.[1]?.key] = `${salaryMappingObj?.[item]?.[subItem]?.[0]?.label} - ${salaryMappingObj?.[item]?.[subItem]?.[1]?.label}`;
                        colorClass[salaryMappingObj?.[item]?.[subItem]?.[1]?.key] = item;
                    }
                    if (salaryMappingObj?.[item]?.[subItem]?.[2]) {
                        mappingKeys[salaryMappingObj?.[item]?.[subItem]?.[2]?.key] = '';
                        labelKeys[salaryMappingObj?.[item]?.[subItem]?.[2]?.key] = `${salaryMappingObj?.[item]?.[subItem]?.[0]?.label} - ${salaryMappingObj?.[item]?.[subItem]?.[2]?.label}`;
                        colorClass[salaryMappingObj?.[item]?.[subItem]?.[2]?.key] = item;
                    }
                })
            }

        });


        columnList.forEach((element, index) => {
            if (element in mappingKeys) {
                mappingKeys[element] = String(index)
            }
        });


        setMapping((prev) => {
            prev = mappingKeys
            return { ...prev }
        })

        setLabels((prev) => {
            prev = labelKeys
            return { ...prev }
        })
        setColorClass((prev) => {
            prev = colorClass
            return { ...prev }
        })
    }

    const unMappAll = () => {
        setShowProceed(false);
        const mappingKeys = {};
        Object.keys(mapping).forEach((key) => {
            mappingKeys[key] = "";
        });
        setMapping((prev) => {
            prev = mappingKeys
            return { ...prev }
        })

        const list = Array.apply(null, Array(mappingColumn.length)).map(function () { return null })
        setColumnList((prev) => {
            prev = list
            return [...prev]
        })
    }

    useEffect(() => {
        //create mapping keys list        
        resetMappingKeyList(salaryMappingObj, columnList);
        // eslint-disable-next-line
    }, [mappingColumn, columnList])

    useEffect(() => {


        let isError = false;
        for (let i = 0; i < REQUIRED_MAPPED_KEYS.length; i++) {
            if (columnList.includes(REQUIRED_MAPPED_KEYS[i]) === false) {
                isError = true;
                break;
            }
        }
        if (!isError) {
            setShowProceed(true);
        }

    }, [columnList])

    const validateProceed = () => {
        if (templateName === "") {
            setTemplateNameError("Template Name cant be blank")
            return
        } else {
            setTemplateNameError(null)

        }


        let isError = false;
        for (let i = 0; i < REQUIRED_MAPPED_KEYS.length; i++) {
            if (columnList.includes(REQUIRED_MAPPED_KEYS[i]) === false) {
                isError = true;
                break;
            }
        }
        if (isError) {
            setError('Employee Name, PAN, Regime and Category must be mapped.');
        } else {
            handleProceed(columnList, templateId, templateName, isDefaultProceed);
        }
    }

    const getMappedTitle = (item) => {
        const labels = [];
        for (let i = 0; i < columnList.length; i++) {
            if (columnList[i] === item.key) {
                labels.push(mappingColumn[i]);
            }
        }
        if (labels.length > 1) {
            return labels.join(', ');
        }
        return item.label;
    }

    // const removeColumnMapping = (index) => {
    //     for(let i = 0; i<columnList.length; i++){
    //         if(Number(i) === Number(index)){
    //             columnList[i] = '';
    //         }
    //     }
    //     setColumnList((prev) => {
    //         prev = columnList
    //         return [...prev]
    //     })
    // }



    return (
        <>

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />







            <div className="userexcel_tdsmapping_modal container-fluid mt-2">


                <div className="row mb-3" style={{ height: '60px' }}>
                    <div className="col-md-4" style={{ display: "flex", alignItems: "center" }}>

                        <div className="notice_order_backbtn" style={{ color: "#fc7d72", fontSize: "12px", cursor: "pointer" }}
                            onClick={() => handleFileUploadPopupClose()}>&lt; Back to Employeewise Salary details</div>

                    </div>
                    <div className="col-md-4 text-center" >
                        <h4 className="userexcel_tdsmapping_titlefs">
                            Start by Mapping Left to Right
                        </h4>
                    </div>
                    <div className="col-md-4 text-right user_mapping_proceed">
                        <span >
                            Remember Mapping as
                        </span>
                        <div className="userexcel_typenameunputbox" style={{ position: "relative" }}>
                            <input type="text" class={`form-control userexcel_typenameunputbox ${isDefaultProceed ? "disabled" : ""}`}
                                placeholder="Type Name (You can save upto 5  mappings only)"
                                value={templateName} onChange={(e) => setTemplateName(e.target.value)} maxLength={100} />

                            {templateNameError && <span style={{ position: "absolute", "left": "10px" }}>{templateNameError}</span>}

                        </div>

                        <div className="userexcel_tdsmapping_rightbtnbox" style={{ display: 'flex' }}>
                            {
                                showProceed
                                    ? <button className="modal-header btn btn-default unlockmapping_btnbgclr mr-2" onClick={() => validateProceed()}>
                                        Proceed
                                    </button>
                                    :
                                    <button className="modal-header btn btn-default unlockmapping_btnbgclr mr-2 disabled" >
                                        Proceed
                                    </button>
                            }

                            <button className={`modal-header btn btn-default unlockmapping_btnbgclr ${(isDefaultProceed || columnList.join("") === "") ? 'disabled' : ''}`} onClick={() => unMappAll()}>
                                Unmap All
                            </button>
                        </div>

                    </div>
                </div>


                <Tooltip anchorElement="target" position="top">

                    <div className="row">
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12 userexcel_tdsmapping_innercolbox_01">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="borderright_sidegrey">
                                        <div className="userexcel_tdsmapping_leftbox_rightborder">
                                            <div className="card userexcel_tdsmapping_leftheadercard userexcel_tdsmapping_headercard_overflowtext">
                                                <span className="pl-0" data-toggle="tooltip" data-placement="top" title="Columns">  Columns</span>

                                            </div>
                                        </div>

                                        <div className="row pt-2 section_scrollbar" ref={outerRef}>
                                            <Menu outerRef={outerRef} handleRightClickSelection={handleRightClickSelection} setSelectedColumnIndex={setSelectedColumnIndex} />
                                            {
                                                mappingColumn.map((item, index) => {

                                                    return (
                                                        <>
                                                            <div className={Number(index) === 0 ? 'col-md-12' : 'col-md-12 pt-2'}>
                                                                <div className="userexcel_tdsmapping_leftbox_innerbtnbox">
                                                                    <button
                                                                        id={`${index}`}
                                                                        draggable={isDefaultProceed ? false : true}
                                                                        onDragStart={(e) => dragStart(e, index)}
                                                                        className={`btn btn-default leftbox_abcbtn userexcel_tdsmapping_leftbtn_overflowtext ${selectedColumnIndex && Number(selectedColumnIndex) === Number(index) ? 'selected' : ''}`}
                                                                        style={{ cursor: 'default' }}
                                                                    // onClick={e => {
                                                                    //     if(e.detail === 2){
                                                                    //         removeColumnMapping(index);
                                                                    //     }
                                                                    // }}
                                                                    >
                                                                        <span className="sixdot_iconclrpadd" id={`${index}`}>
                                                                            <FontAwesomeIcon icon={faGripVertical} style={{ paddingBottom: "2px" }} />
                                                                        </span>
                                                                        <span id={`${index}`} className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={item}>{item}</span>

                                                                    </button>
                                                                    {
                                                                        columnList[index] !== null &&
                                                                        <>
                                                                            <button className={`btn btn-default userexcel_tdsmapping_leftbtnSM_overflowtext ${colorClass?.[columnList?.[index]] ?? ''}`}>
                                                                                <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={labels?.[columnList?.[index]] ?? ''}>
                                                                                    {labels?.[columnList?.[index]] ?? ''}
                                                                                </span>
                                                                                {isDefaultProceed ? null : <span className="mapping_unmap_btn" onClick={() => { removeKey(columnList[index]) }}>&#x2715;</span>}
                                                                            </button>
                                                                        </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </>




                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-5 section_scrollbar">
                                    <div className="card userexcel_tdsmapping_right_rightheadercard">
                                        Employee Details
                                    </div>
                                    <div className="row mb-2">
                                        {
                                            salaryMappingObj?.employee_dt.map((item) => {
                                                return (
                                                    <>
                                                        <div className="col-md-12 pt-2" style={{ marginLeft: '10%' }}>
                                                            <div className="userexcel_tdsmapping_leftbox_rightbtnbox"
                                                                onDragOver={(e) => { e.preventDefault(); }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    var dataIndex = e.dataTransfer.getData("text");
                                                                    addDragData(item.key, dataIndex);
                                                                }}>
                                                                <button className={columnList.includes(item?.key) ? 'btn btn-default rightbox_basicbtn rightbox_basicbtn_greybgclr' : 'btn btn-default rightbox_basicbtn'}>
                                                                    <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(item)}>
                                                                        {["pan", "nm", "catg", "regime"].includes(item?.key) ? <span style={{ "color": "red" }}> *</span> : null}
                                                                        {item.label}</span>
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>


                                    <div className="card userexcel_tdsmapping_salalry_headercard">
                                        Salary 17(1)
                                    </div>

                                    <div className="row mb-2">
                                        {
                                            salaryMappingObj?.salary17_1.map((item) => {
                                                return (
                                                    <>
                                                        <div className="col-md-12 pt-2" style={{ marginLeft: '10%' }}>
                                                            <div className="userexcel_tdsmapping_leftbox_rightbtnbox"
                                                                onDragOver={(e) => { e.preventDefault(); }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    var dataIndex = e.dataTransfer.getData("text");
                                                                    addDragData(item.key, dataIndex);
                                                                }}
                                                            >
                                                                <button className={columnList.includes(item?.key) ? 'btn btn-default rightbox_basicbtn rightbox_basicbtn_greybgclr' : 'btn btn-default rightbox_basicbtn'}>
                                                                    <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(item)}>  {item.label}</span>
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            })
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-5 col-lg-8 col-md-12 col-sm-12 userexcel_tdsmapping_innercolbox_02">
                            <div className="row">
                                <div className="col-md-6 section_scrollbar">
                                    <div className="card userexcel_tdsmapping_middle_leftheadercard">
                                        Perquisites 17(2)
                                    </div>

                                    <div className="row">
                                        {
                                            Object.keys(salaryMappingObj?.perquisites17_2).map((item) => {
                                                const itemObj = salaryMappingObj?.perquisites17_2?.[item] ?? [];
                                                return (
                                                    <>
                                                        <div className="col-md-12 pt-2">
                                                            <div className="userexcel_tdsmapping_middle_inner_leftbtnbox">
                                                                <span className="middle_innerbox_leftside_btn">
                                                                    <button className={(columnList.includes(itemObj?.[1]?.['key']) || columnList.includes(itemObj?.[2]?.['key'])) ? 'btn btn-default midboxleft_btnbox midboxleftbtn_activebgclr' : 'btn btn-default midboxleft_btnbox'}>
                                                                        <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={itemObj?.[0]?.['label']}>  {itemObj?.[0]?.['label']} </span>
                                                                    </button>
                                                                    <span className="midboxleft_borderbott_right"></span>
                                                                    <span className="midboxleft_borderbott_left"></span>
                                                                </span>
                                                                <span className="middle_innerbox_rightside_btn">
                                                                    <button
                                                                        onDragOver={(e) => { e.preventDefault(); }}
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            var dataIndex = e.dataTransfer.getData("text");
                                                                            addDragData(itemObj?.[1]?.['key'], dataIndex);
                                                                        }}
                                                                        className={columnList.includes(itemObj?.[1]?.['key']) ? 'btn btn-default middle_innerbox_rightbtn midboxleft_btnbox midboxleftbtn_activebgclr' : ' btn btn-default middle_innerbox_rightbtn'}>
                                                                        <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(itemObj?.[1])}>  {itemObj?.[1]?.['label']}</span>
                                                                    </button>

                                                                    <button
                                                                        onDragOver={(e) => { e.preventDefault(); }}
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            var dataIndex = e.dataTransfer.getData("text");
                                                                            addDragData(itemObj?.[2]?.['key'], dataIndex);
                                                                        }}
                                                                        className={columnList.includes(itemObj?.[2]?.['key']) ? 'btn btn-default middle_innerbox_rightbtn midboxleft_btnbox midboxleftbtn_activebgclr' : ' btn btn-default middle_innerbox_rightbtn'}>
                                                                        <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(itemObj?.[2])}>  {itemObj?.[2]?.['label']}</span>
                                                                    </button>

                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 section_scrollbar">
                                    <div className="card userexcel_tdsmapping_middle_rightheadercard">
                                        Profits in Lieu of salary 17(3)
                                    </div>

                                    <div className="row">
                                        {
                                            Object.keys(salaryMappingObj?.prft).map((item) => {
                                                const itemObj = salaryMappingObj?.prft?.[item] ?? [];
                                                return (
                                                    <>
                                                        <div className="col-md-12 pt-2 mb-1" style={{ marginLeft: '25%' }}>
                                                            <div className="userexcel_tdsmapping_leftbox_rightbtnbox"
                                                                onDragOver={(e) => { e.preventDefault(); }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    var dataIndex = e.dataTransfer.getData("text");
                                                                    addDragData(itemObj?.key, dataIndex);
                                                                }}>
                                                                <button className={columnList.includes(itemObj?.key) ? 'btn btn-default rightbox_basicbtn rightbox_basicbtn_greybgclr' : 'btn btn-default rightbox_basicbtn'}>
                                                                    <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(itemObj)}>  {itemObj?.label}</span>
                                                                </button>


                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }


                                        <div className="col-md-12">
                                            <button className="btn btn-default profitsalalry_04_btnbgclr_yell">
                                                <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title="Retirements Benefits"> Retirements Benefits </span>
                                            </button>
                                        </div>
                                        {
                                            Object.keys(salaryMappingObj?.rb).map((item) => {
                                                const itemObj = salaryMappingObj?.rb?.[item] ?? [];
                                                return (
                                                    <>
                                                        <div className="col-md-12 pt-2 mb-1" style={{ marginLeft: '25%' }}>
                                                            <div className="userexcel_tdsmapping_leftbox_rightbtnbox"
                                                                onDragOver={(e) => { e.preventDefault(); }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    var dataIndex = e.dataTransfer.getData("text");
                                                                    addDragData(itemObj?.key, dataIndex);
                                                                }}>
                                                                <button className={columnList.includes(itemObj?.key) ? 'btn btn-default rightbox_basicbtn rightbox_basicbtn_greybgclr' : 'btn btn-default rightbox_basicbtn'}>
                                                                    <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(itemObj)}>  {itemObj?.label}</span>
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }

                                        <div className="col-md-12">
                                            <button className="btn btn-default profitsalalry_04_btnbgclr_draggrey">
                                                <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title="Chapter VIA Dedcutions"> Chapter VIA Dedcutions</span>
                                            </button>
                                        </div>

                                        {
                                            Object.keys(salaryMappingObj?.via_ded).map((item) => {
                                                const itemObj = salaryMappingObj?.via_ded?.[item] ?? {};
                                                return (
                                                    <>
                                                        <div className="col-md-12 pt-2" style={{ marginLeft: '25%' }}>
                                                            <div className="userexcel_tdsmapping_leftbox_rightbtnbox"
                                                                onDragOver={(e) => { e.preventDefault(); }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    var dataIndex = e.dataTransfer.getData("text");
                                                                    addDragData(itemObj?.key, dataIndex);
                                                                }}>
                                                                <button className={columnList.includes(itemObj?.key) ? 'btn btn-default rightbox_basicbtn rightbox_basicbtn_greybgclr' : 'btn btn-default rightbox_basicbtn'}>
                                                                    <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(itemObj)}>  {itemObj?.label}</span>
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 userexcel_tdsmapping_innercolbox_03">
                            <div className="row">
                                <div className="col-md-12 section_scrollbar">
                                    <div className="card userexcel_tdsmapping_right_rightheadercard">
                                        Allowances
                                    </div>

                                    <div className="row">
                                        {
                                            Object.keys(salaryMappingObj?.alw).map((item) => {
                                                const itemObj = salaryMappingObj?.alw?.[item] ?? [];
                                                return (




                                                    <>
                                                        <div className="col-md-12 pt-2">
                                                            <div className="userexcel_tdsmapping_middle_inner_leftbtnbox">
                                                                <span className="middle_innerbox_leftside_btn">
                                                                    <button className={(columnList.includes(itemObj?.[1]?.['key']) || columnList.includes(itemObj?.[2]?.['key'])) ? 'btn btn-default midboxleft_btnbox midboxleftbtn_activebgclr' : 'btn btn-default midboxleft_btnbox'}>
                                                                        <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={itemObj?.[0]?.label}> {itemObj?.[0]?.label}</span>
                                                                    </button>
                                                                    <span className="midboxleft_borderbott_right"></span>
                                                                    <span className="midboxleft_borderbott_left"></span>
                                                                </span>
                                                                <span className="middle_innerbox_rightside_btn">
                                                                    <button
                                                                        onDragOver={(e) => { e.preventDefault(); }}
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            var dataIndex = e.dataTransfer.getData("text");
                                                                            addDragData(itemObj?.[1]?.['key'], dataIndex);
                                                                        }}
                                                                        className={columnList.includes(itemObj?.[1]?.['key']) ? 'btn btn-default middle_innerbox_rightbtn midboxleft_btnbox midboxleftbtn_activebgclr' : ' btn btn-default middle_innerbox_rightbtn'}>
                                                                        <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(itemObj?.[1])}>  {itemObj?.[1]?.label} </span>
                                                                    </button>

                                                                    <button
                                                                        onDragOver={(e) => { e.preventDefault(); }}
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            var dataIndex = e.dataTransfer.getData("text");
                                                                            addDragData(itemObj?.[2]?.['key'], dataIndex);
                                                                        }}
                                                                        className={columnList.includes(itemObj?.[2]?.['key']) ? 'btn btn-default middle_innerbox_rightbtn midboxleft_btnbox midboxleftbtn_activebgclr' : ' btn btn-default middle_innerbox_rightbtn'}>
                                                                        <span className="ced_innerpadd" data-toggle="tooltip" data-placement="top" title={getMappedTitle(itemObj?.[2])}>  {itemObj?.[2]?.label}</span>
                                                                    </button>

                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>





                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Tooltip>
                <div id="drag-element-tooltip-main" />
            </div>

        </>
    )

};

export default ExcelToTdsMapping;