/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import './FileUploadPopup.css';
import { importDeductees, excelDownloadTemplateV2 } from '../../../api/deducteeAPI';
import { formatBytes } from '../../../utils/UtilityFunctions';

const FileUploadPopup = ({ history, ...props }) => {
  const {
    handleClose, handleProceed, deductorId
  } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ accept: '.xlsx,.xls' });

  const removeFile = (targetFile) => {
    const newFiles = [];
    files.map((file) => {
      if (file.path !== targetFile.path) {
        newFiles.push(file);
      }
      return true;
    });
    setFiles(newFiles);
  };

  const renderFiles = files.map((file) => (
    <li key={file.path} style={{ margin: '5px' }}>
      <span>
        {file.path}
        {' '}
        -
        {formatBytes(file.size)}
        {' '}
        bytes
      </span>
      <Button
        variant="danger"
        style={{
          fontSize: '12px', padding: '0px', paddingLeft: '5px', paddingRight: '5px', marginLeft: '10px',
        }}
        onClick={() => removeFile(file)}
      >
        Remove
      </Button>
    </li>
  ));

  const uploadFiles = async () => {
    setLoading(true);
    const formData = new FormData();
    files.map((file) => {
      formData.append(`deductor_id`, deductorId);
      formData.append(`file`, file);
      formData.append(`created_at`, moment(file.lastModified).format('YYYY-MM-DD'));
      return true;
    });

    try {
      if (files.length <= 10) {
        await importDeductees(formData);
        handleProceed();
      } else {
        setError("Maximum 10 files can be imported at a time.");
      }
    } catch (err) {
      // excelDownloadTemplateV2();
      setError(err.message);
    }
    setLoading(false);
  };

  const downloadExcelTemp = async () => {
    setLoading(true);
    try {
      const result = await excelDownloadTemplateV2();
      const csiData = new Blob([result], { type: 'application/xls' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(csiData);
      link.setAttribute('download', 'ExpressTDS_Deductee_Template.xlsx');
      document.body.appendChild(link);
      link.click();

    } catch (err) {
      console.error('Error in export: ', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    const newFiles = acceptedFiles;
    const uniqueFiles = [];
    const flags = [];
    const l = newFiles.length;
    let i;
    for (i = 0; i < l; i++) {
      if (flags[newFiles[i].path]) continue;
      flags[newFiles[i].path] = true;
      uniqueFiles.push(newFiles[i]);
    }
    setFiles(uniqueFiles);
  }, [acceptedFiles]);

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="mkdmdbox"
      >
        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title>Import deductee </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps({ accept: '.xlsx,.xls', multiple: false })} />
              <p>
                {!isDragActive && 'Drag \'n\' drop files here, or click to select files'}
                {isDragActive && !isDragReject && 'Drop files here'}
                {isDragReject && 'File type not accepted, sorry!'}
              </p>
            </div>
            <aside>
              <h6>Files</h6>
              <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
            </aside>
          </section>

        </Modal.Body>
        <Modal.Footer className="ftpaddmk">

          <div className="download_link">
            <span className="downloadtextclr" onClick={() => downloadExcelTemp()}> Download Excel Template</span>
          </div>
          {error
            ? (
              <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>
            )
            : null}

          <Button className="mboxsubmbtn" variant="primary" onClick={uploadFiles} disabled={loading || files.length === 0}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {' '}
                Loading...
              </>
            ) : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileUploadPopup;