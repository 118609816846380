import { convertNum } from "../../utils/UtilityFunctions";

export const deductorFormFields = {
  efill_username: '',
  efill_password: '',
  efill_verified: false,
  traces_username: '',
  traces_password: '',
  traces_verified: false,
  insights_username: '',
  insights_password: '',
  insights_verified: false,
  deductor_name: '',
  tan: '',
  pan: '',
  gst: '',
  deductor_status: 1,
  deductor_category: '',
  branch: '',
  address_building_no: '',
  address_building_name: '',
  address_street_name: '',
  address_area: '',
  address_city: '',
  address_state_id: '',
  address_pincode: '',
  contact_info_primary_country_code: '+91',
  contact_info_primary_mobile_no: '',
  contact_info_alternate_country_code: '+91',
  contact_info_alternate_mobile_no: '',
  contact_info_primary_email: '',
  contact_info_alternate_email: '',
  responsible_person_pan: '',
  responsible_person_designation: '',
  responsible_person_address_building_no: '',
  responsible_person_address_building_name: '',
  responsible_person_address_street_name: '',
  responsible_person_address_area: '',
  responsible_person_address_city: '',
  responsible_person_address_state_id: '',
  responsible_person_address_pincode: '',
  responsible_person_contact_info_person_name: '',
  responsible_person_contact_info_primary_country_code: '+91',
  responsible_person_contact_info_primary_mobile_no: '',
  responsible_person_contact_info_alternate_country_code: '+91',
  responsible_person_contact_info_alternate_mobile_no: '',
  responsible_person_contact_info_primary_email: '',
  responsible_person_contact_info_alternate_email: '',
  contact_person_contact_info_person_name: '',
  contact_person_contact_info_primary_country_code: '+91',
  contact_person_contact_info_primary_mobile_no: '',
  contact_person_contact_info_alternate_country_code: '+91',
  contact_person_contact_info_alternate_mobile_no: '',
  contact_person_contact_info_primary_email: '',
  contact_person_contact_info_alternate_email: '',
  government_deductor_ddo_number: '',
  government_deductor_pao_code: '',
  government_deductor_pao_number: '',
  government_deductor_ddo_code: '',
  government_deductor_dept_name: '',
  government_deductor_dept_name_other: '',
  government_deductor_office_identity_number: '',
  is_cp_same_as_rp: 'yes',
};

export const getDeductorObject = (data) => {

  // console.log("data values in helper", data)
  const deductorData = {
    deductor_name: data.deductor_name.trim(),
    tan: data.tan,
    pan: data.pan,
    gst_no: data.gst_no,
    deductor_status: data.deductor_status,
    deductor_category: data.deductor_category,
    efilling_password: data.efill_password,
    traces_username: data.traces_username,
    traces_password: data.traces_password,
    insights_username: data.insights_username,
    insights_password: data.insights_password,
    insights_verified: data.insights_verified,
    branch: data.branch,
    address: {
      building_no: data.address_building_no.trim(),
      building_name: data.address_building_name.trim(),
      street_name: data.address_street_name.trim(),
      area: data.address_area.trim(),
      city: data.address_city.trim(),
      state_id: data.address_state_id,
      pincode: data.address_pincode,
    },
    contact_info: {
      primary_country_code: '+91',
      primary_mobile_no: data.contact_info_primary_mobile_no,
      alternate_country_code: '+91',
      alternate_mobile_no: data.contact_info_alternate_mobile_no,
      primary_email: data.contact_info_primary_email.trim(),
      alternate_email: data.contact_info_alternate_email.trim(),
    },
    responsible_person: {
      pan: data.responsible_person_pan,
      designation: data.responsible_person_designation.trim(),
      address: {
        building_no: data.responsible_person_address_building_no.trim(),
        building_name: data.responsible_person_address_building_name.trim(),
        street_name: data.responsible_person_address_street_name.trim(),
        area: data.responsible_person_address_area.trim(),
        city: data.responsible_person_address_city.trim(),
        state_id: data.responsible_person_address_state_id,
        pincode: data.responsible_person_address_pincode,
      },
      contact_info: {
        person_name: data.responsible_person_contact_info_person_name,
        primary_country_code: '+91',
        primary_mobile_no: data.responsible_person_contact_info_primary_mobile_no,
        alternate_country_code: '+91',
        alternate_mobile_no: data.responsible_person_contact_info_alternate_mobile_no,
        primary_email: data.responsible_person_contact_info_primary_email.trim(),
        alternate_email: data.responsible_person_contact_info_alternate_email.trim(),
      },
    },
    contact_person: {
      contact_info: {
        person_name: data.contact_person_contact_info_person_name,
        primary_country_code: '+91',
        primary_mobile_no: data.contact_person_contact_info_primary_mobile_no,
        alternate_country_code: '+91',
        alternate_mobile_no: data.contact_person_contact_info_alternate_mobile_no,
        primary_email: data.contact_person_contact_info_primary_email ? data.contact_person_contact_info_primary_email.trim() : data.contact_person_contact_info_primary_email,
        alternate_email: data.contact_person_contact_info_alternate_email ? data.contact_person_contact_info_alternate_email.trim() : data.contact_person_contact_info_alternate_email,
      },
    },
    government_deductor: {
      ddo_number: data.government_deductor_ddo_number,
      pao_code: data.government_deductor_pao_code,
      pao_number: data.government_deductor_pao_number,
      ddo_code: data.government_deductor_ddo_code,
      dept_name: data.government_deductor_dept_name,
      dept_name_other: data.government_deductor_dept_name_other,
      office_identity_number: data.government_deductor_office_identity_number,
    },
  };

  if (data.is_cp_same_as_rp === 'yes') {
    const contactPerson = {
      person_name: deductorData.responsible_person.person_name,
      contact_info: deductorData.responsible_person.contact_info,
    };
    deductorData.contact_person = contactPerson;
  }

  const deductorCredentials = [];

  const eFilling = {
    username: data.tan,
    password: data.efill_password,
    credential_type: 'EFILING',
    verified: data.efill_verified,
  };
  if (eFilling.username && eFilling.password) deductorCredentials.push(eFilling);

  const traces = {
    username: data.traces_username,
    password: data.traces_password,
    credential_type: 'TRACES',
    verified: data.traces_verified,
  };
  if (traces.username && traces.password) deductorCredentials.push(traces);

  const inSights = {
    username: data.insights_username,
    password: data.insights_password,
    credential_type: 'INSIGHT',
    verified: data.insights_verified,
  };
  if (inSights.username && inSights.password) deductorCredentials.push(inSights);

  deductorData.deductor_credentials = deductorCredentials;
  return deductorData;
};

export const getFormObject = (data) => {
  const deductorInfo = { ...deductorFormFields };
  // console.log("deductorInfo", deductorInfo)
  deductorInfo.deductor_name = data?.deductor_name ?? '';
  deductorInfo.tan = data?.tan ?? '';
  deductorInfo.pan = data?.pan ?? '';
  deductorInfo.gst_no = data?.gst_no ?? '';
  deductorInfo.deductor_status = data?.deductor_status ?? '';
  deductorInfo.deductor_category = data?.deductor_category;
  deductorInfo.branch = data.branch ?? '';
  deductorInfo.address_building_no = data?.address?.building_no ?? '';
  deductorInfo.address_building_name = data?.address?.building_name ?? '';
  deductorInfo.address_street_name = data?.address?.street_name ?? '';
  deductorInfo.address_area = data?.address?.area ?? '';
  deductorInfo.address_city = data?.address?.city ?? '';
  deductorInfo.address_state_id = data?.address?.state_id ?? '';
  deductorInfo.address_pincode = data?.address?.pincode ?? '';

  deductorInfo.contact_info_primary_mobile_no = data?.contact_info?.primary_mobile_no ?? '';
  deductorInfo.contact_info_alternate_mobile_no = data?.contact_info?.alternate_mobile_no ?? '';
  deductorInfo.contact_info_primary_email = data?.contact_info?.primary_email ?? '';
  deductorInfo.contact_info_alternate_email = data?.contact_info?.alternate_email ?? '';

  deductorInfo.responsible_person_pan = data?.responsible_person?.pan ?? '';
  deductorInfo.responsible_person_designation = data?.responsible_person?.designation ?? '';
  deductorInfo.responsible_person_address_building_no = data?.responsible_person?.address?.building_no ?? '';
  deductorInfo.responsible_person_address_building_name = data?.responsible_person?.address?.building_name ?? '';
  deductorInfo.responsible_person_address_street_name = data?.responsible_person?.address?.street_name ?? '';
  deductorInfo.responsible_person_address_area = data?.responsible_person?.address?.area ?? '';
  deductorInfo.responsible_person_address_city = data?.responsible_person?.address?.city ?? '';
  deductorInfo.responsible_person_address_state_id = data?.responsible_person?.address?.state_id ?? '';
  deductorInfo.responsible_person_address_pincode = data?.responsible_person?.address?.pincode ?? '';

  deductorInfo.responsible_person_contact_info_person_name = data?.responsible_person?.contact_info?.person_name ?? '';
  deductorInfo.responsible_person_contact_info_primary_mobile_no = data?.responsible_person?.contact_info?.primary_mobile_no ?? '';
  deductorInfo.responsible_person_contact_info_alternate_mobile_no = data?.responsible_person?.contact_info?.alternate_mobile_no ?? '';
  deductorInfo.responsible_person_contact_info_primary_email = data?.responsible_person?.contact_info?.primary_email ?? '';
  deductorInfo.responsible_person_contact_info_alternate_email = data?.responsible_person?.contact_info?.alternate_email ?? '';

  deductorInfo.contact_person_contact_info_person_name = data?.contact_person?.contact_info?.person_name ?? '';
  deductorInfo.contact_person_contact_info_primary_mobile_no = data?.contact_person?.contact_info?.primary_mobile_no ?? '';
  deductorInfo.contact_person_contact_info_alternate_mobile_no = data?.contact_person?.contact_info?.alternate_mobile_no ?? '';
  deductorInfo.contact_person_contact_info_primary_email = data?.contact_person?.contact_info?.primary_email ?? '';
  deductorInfo.contact_person_contact_info_alternate_email = data?.contact_person?.contact_info?.alternate_email ?? '';

  deductorInfo.government_deductor_ddo_number = data?.government_deductor?.ddo_number ?? '';
  deductorInfo.government_deductor_pao_code = data?.government_deductor?.pao_code ?? '';
  deductorInfo.government_deductor_pao_number = data?.government_deductor?.pao_number ?? '';
  deductorInfo.government_deductor_ddo_code = data?.government_deductor?.ddo_code ?? '';
  deductorInfo.government_deductor_dept_name = data?.government_deductor?.dept_name ?? '';
  deductorInfo.government_deductor_dept_name_other = data?.government_deductor?.dept_name_other ?? '';
  deductorInfo.government_deductor_office_identity_number = data?.government_deductor?.office_identity_number ?? '';
  deductorInfo.insights_reg_status = data?.insights_reg_status ?? '';
  deductorInfo.insights_reg_refno = data?.insights_reg_refno ?? '';
  deductorInfo.insights_reg_email = data?.insights_reg_email ?? '';
  deductorInfo.insights_login_exists = data?.insights_login_exists ?? '';
  
  if(data.deductor_credentials) {
  data.deductor_credentials.map((cred) => {
    if (String(cred?.credential_type ?? '').toUpperCase() === 'EFILING') {
      deductorInfo.efill_username = cred?.username;
      deductorInfo.efill_password = cred?.password;
      deductorInfo.efill_verified = cred?.verified;
    }

    if (String(cred?.credential_type ?? '').toUpperCase() === 'TRACES') {
      deductorInfo.traces_username = cred?.username;
      deductorInfo.traces_password = cred?.password;
      deductorInfo.traces_verified = cred?.verified;
    }
    
    if (String(cred?.credential_type ?? '').toUpperCase() === 'INSIGHT') {
      deductorInfo.insights_username = cred?.username;
      deductorInfo.insights_password = cred?.password;
      deductorInfo.insights_verified = cred?.verified;
    }

    return null;
  });
}

  return deductorInfo;
};

export const deductor_details = ((data) => {
 const nameParts = (data?.responsible_person_contact_info_person_name ?? "").split(/\s+/); // Split on one or more spaces
 let firstName = '';
 if (nameParts.length > 0) {
  firstName = nameParts[0];
 }
 let middleName = '';
 if (nameParts.length > 2) {
   // Extract middle name if there are more than 2 parts
   middleName = nameParts.slice(1, nameParts.length - 1).join(' ');
 }
 let lastName = '';
 if (nameParts.length > 1) {
   lastName = nameParts[nameParts.length - 1];
 }
    
 const intialTemplate = { 
  name: data?.deductor_name ?? "",
  pan: data?.pan ?? "",
  building: (`${data?.address_building_no ?? ""} ${data?.address_building_name ?? ""}`).trim(),
  street: data?.address_street_name ?? "",
  area: data?.address_area ?? "",
  city: data?.address_city ?? "",
  pincode: data?.address_pincode ?? "",
  state_id: data?.address_state_id ?? "",
  country_id: convertNum(data?.address_state_id) !== 99 ? 91 : "",
    principal_officer_details: {
      person_type: "Principal Officer",
      pan: data?.responsible_person_pan ?? "",
      first_name: firstName ?? "",
      middle_name: middleName ?? "",
      last_name: lastName ?? "",
      designation: data?.responsible_person_designation ?? "",
      access_type: 0,
      dob: "",
      mobile_no: data?.responsible_person_contact_info_primary_mobile_no ?? "",
      email: data?.responsible_person_contact_info_primary_email ?? "",
      alternate_email: data?.responsible_person_contact_info_alternate_email ?? "",
      building: (`${data.responsible_person_address_building_no ?? ""} ${data?.responsible_person_address_building_name ?? ""}`).trim(),
      street: data?.responsible_person_address_street_name ?? "",
      area: data?.responsible_person_address_area ?? "",
      city: data?.responsible_person_address_city ?? "",
      pincode: data?.responsible_person_address_pincode ?? "",
      state_id: data?.responsible_person_address_state_id ?? "",
      country_id: convertNum(data?.responsible_person_address_state_id) !== 99 ? 91 : ""
  }
}
return intialTemplate;
})
