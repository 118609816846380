import React, { useState } from 'react';
import ShowAlert from '../ShowAlert';
import Modal from 'react-bootstrap/Modal';

const EditUserInfoPopup = (props) => {
    const { handleClose, data, api,error,setError} = props;
    const [userInfo, setUserInfo] = useState("");
    const [formError, setFormError] = useState("");

    const handleChange = (e) => {
        if (e.target.name === "mobile_no" && isNaN(Number(e.target.value))) return;
        setUserInfo(e.target.value);
        setFormError("");
    }

    const sendOtpMobilEmail = () => {
        
        if(data.value === userInfo) return setFormError(`This ${data.Title} is already associated with the logged in profile. Please enter a different ${data.Title}, to proceed further.`)

        if (data.key === "mobile_no") {
            if((!userInfo || userInfo.length < 10)) return setFormError("Please enter a valid ten-digit Indian mobile number!")
            let apidata = {
                country_code: data.country_code,
                mobile_no: String(userInfo)
            }
            api(apidata, data.key)
        } else if (data.key === "email") {
          
            function ValidateEmail(inputText) {
                var mailformat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                if (!inputText.match(mailformat)) {
                  setFormError("This is not a valid email address")
                  return false;
                }
                return true;
            }
            if(!userInfo) return setFormError("Please fill a valid email address");
            let valid =  ValidateEmail(userInfo)
            if(valid){
                let apidata = {
                    country_code: data.country_code,
                    email: String(userInfo)
                }
                api(apidata, data.key)
            }
        }
    }


        return (
            <Modal className="gst_editmobileno_modal " backdrop="static" show={true} onHide={handleClose}>
                <Modal.Header closeButton className="gst_editmobileno_header">
                    <Modal.Title className="gst_editmobileno_title">{`Verify Your ${data.Title}`}</Modal.Title>
                </Modal.Header>
                <ShowAlert
                error={error}
                onClose={() => { setError(null);}}
            />
                <Modal.Body>

                    <div className="row">
                        <div className="col-md-12">
                            <p className="gst_editmobileno_textfs">
                                {`An OTP will be send on the new ${data.Title} provided below:`}
                            </p>
                            <div className="form-inline">
                                <label htmlFor="" className="gst_editmobileno_textfs mr-sm-2">{`Please provide the new ${data.Title}:`}</label>
                                <input type="text" maxLength={data?.length ?? 125} name={data.key} className="form-control mb-2 mr-sm-0 gst_editmobileno_inptfs" placeholder="" onChange={handleChange} value={userInfo} />
                                {formError && <span className='formerr_validation' >{formError}</span>}
                            </div>
                        </div>
                        <div className="col-md-12 text-center pt-3">
                            <button className="gst_editmobileno_btndone_bgclr" onClick={() => sendOtpMobilEmail()}>Done</button>
                        </div>
                    </div>


                </Modal.Body>

            </Modal>
        )
    }

    export default EditUserInfoPopup
