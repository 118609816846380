import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonBarGraph = () => {
  return (
    <table className='m-auto'>
      <tbody>
        <tr>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
          <td className="align-bottom"><Skeleton animation="wave" variant="rectangular" width={"50px"} height={"200px"} /></td>
        </tr>
      </tbody>
    </table>

  )
}

export default SkeletonBarGraph