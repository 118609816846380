import React, {useState, useEffect} from 'react';
import './WindowConfirmPopup.scss';
import { Window } from "@progress/kendo-react-dialogs";

const SCREEN_WIDTH = document.body.clientWidth;

const WindowConfirmPopup = (props) => {
    const {
        handleClose,
        handleProceed,
        confirmText,
        confirmButtonText,
        confirmButtonTextStyle = { backgroundColor: '#f2f2f2', color: '#636363' },
        confirmButtonText2,
        confirmButtonText2Style = {},
        handleProceed2,
        title,
        style,
        initialTop = 50,
        initialLeft = 250,  
        initialWidth = 500,
        initialHeight = 'auto'     
    } = props;

    const [top, setTop] = useState(initialTop);
    const [left, setLeft] = useState((SCREEN_WIDTH / 2) - initialLeft);

    useEffect(() => {
        setTop(top);
        // eslint-disable-next-line
    }, []);
   
    return (
        <Window
            maximizeButton={() => null}
            minimizeButton={() => null}
            title={title}
            onClose={handleClose}
            initialHeight={initialHeight}
            initialWidth={initialWidth}
            left={left}
            top={top}
            className={`windowConfirmWrapper`}
            modal={true}
            resizable={false}
            doubleClickStageChange={false}
            onMove={(e) => {
                if (e.top) {
                    setTop(e.top);
                }
                setLeft(e.left);
            }}
            style={{ ...style }}
        >
            <div class="row">
                <div class="col-lg-12">
                    <p class="alreadyfiled_textfs">
                        {confirmText ?? ""}
                    </p>
                </div>
                <div class="col-lg-12 text-center">
                    <button style={{...confirmButtonTextStyle}} class="btn btn-default yes_btnbgclr_fs mr-2" onClick={() => handleProceed()}>{confirmButtonText ?? "Yes"}</button>
                    <button style={{...confirmButtonText2Style}} class="btn btn-default yes_btnbgclr_fs" onClick={() => handleProceed2()}>{confirmButtonText2 ?? "No"}</button>
                </div>
            </div>
        </Window>
    );
   
}

export default WindowConfirmPopup