import React, { useState, useEffect ,useCallback} from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import addBtnRoundIcon from '../../images/icons/add_btn_round.svg';
import editIcon from '../../images/icons/edit.svg';
import mail from "../../images/email_icon.png"
import { getUsersOfOrganization, updateUserProfile, sendOtpToVerifyUserMobileEmail, updateUserVerifiedMobileEmail } from '../../api/profileAPI';
import { verifyOTP } from '../../api/authenticationAPI';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { BASE_PATH } from '../../app/constants';
import InviteUser from './InviteUser';
import OtpVerifiyPopup from '../../components/profile/OtpVerifiyPopup';
import mobile from "../../images/loginicon/mobile.svg";
import UnVerifyEmailPopup from './UnVerifyEmailPopup';
import './UserList.css';
import "../../components/profile/Profile.css"

const UserList = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [userList, setUserList] = useState([]);
  const [headerInfo, setHeaderInfo] = useState({})
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [otpVerifiyPopup, setOtpVerifiyPopup] = useState(false);
  const [unVerifiedEmailPopup, setUnVerifiedEmailPopup] = useState(false);


  const getUsers = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getUsersOfOrganization();
      setUserList(result.data);
      setHeaderInfo(result.header ?? {})
      if (result.header.user_role.toUpperCase() !== 'OWNER') {
        history.push(BASE_PATH);
      }
    } catch (err) {
      // console.error('Error in getting organization users: ', err);
    }
    setLoading(false);
  }, [history]);

  const invitedUserStatusUpdate = (e, user) => {
    if (!user.user_organization_status) {
      let data = {
        user_id: user?.user_id,
        country_code: user.country_code,
        mobile_no: user.mobile_no
      }
      updateMobileEmail(data)
    } else {
      toggleUserStatus(e, user)
    }
  }

  const toggleUserStatus = async (e, user) => {
    e && e.preventDefault();
    setLoading(true);
    const data = {
      user_id: user.user_id,
    };
    if (!user.user_organization_status) {
      data["otp_verification_id"] = user.otp_verification_id;
    }
    data["user_organization_status"] = !user.user_organization_status;
    try {
      const result = await updateUserProfile(data);
      setSuccess(result.message);
      setTimeout(() => getUsers(), 500);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }

  };

  const updateMobileEmail = async (data) => {
    setLoading(true);
    try {
      const result = await sendOtpToVerifyUserMobileEmail(data);
      if (data.email) {
        let otpData = {
          Title: "Email Address",
          userId: data?.user_id,
          user: data?.email,
          country_code: data?.country_code,
          icon: mail,
          id: result?.data?.otp_verification_id ?? "",
          key: "email",
          verify: true
        }
        setOtpVerifiyPopup(otpData)
      } else if (data.mobile_no) {
        let otpData = {
          Title: "Mobile Number",
          userId: data?.user_id,
          user: data?.mobile_no ?? "",
          country_code: data?.country_code ?? "",
          icon: mobile,
          id: result?.data?.otp_verification_id ?? "",
          key: "mobile_no",
          verify: "activate_user"
        }
        setOtpVerifiyPopup(otpData)
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const OtpVerification = async (data, verify) => {

    setLoading(true);
    try {
      const otpResult = await verifyOTP(data);

      setSuccess(otpResult.message);
      setOtpVerifiyPopup(false);
      if (verify === "activate_user") {
        //let user = userList.filter((item) => Number(item.mobile_no) === Number(data.mobile_no_or_email))
        let user = userList.filter((item) => item?.user_id === data?.user_id)
        let payload = {
          user_id: user?.[0]?.user_id ?? "",
          user_organization_status: user?.[0]?.user_organization_status ?? "",
          otp_verification_id: otpResult?.data?.otp_verification_id ?? ""

        }

        toggleUserStatus("", payload)

      } else {
        setTimeout(() => {
          updateEmailOtpVerification(data);
        }, 1000)
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const updateEmailOtpVerification = async (data) => {
    setLoading(true)
    let apiData = {}
    apiData["country_code"] = data?.country_code ?? "";
    apiData["otp_verification_id"] = data?.otp_verification_id ?? "";
    apiData["otp"] = data?.otp ?? ""
    if (isNaN(Number(data.mobile_no_or_email))) apiData["email"] = data.mobile_no_or_email ?? ""
    else {
      apiData["mobile_no"] = data?.mobile_no_or_email ?? ""
    }
    try {
      await updateUserVerifiedMobileEmail(apiData);
      setTimeout(() => {
        setShowInviteUser(true);
      }, 250)
    } catch (err) {
      // setError(err.message);
    } finally {
      setLoading(false)
    }
  }

  const userProfileVerify = () => {
    const userEmail = headerInfo?.email;
    if (userList?.length > 0) {
      userList.forEach((item) => {
        if (item.email === userEmail) {

          if (item?.email_verified) {
            setShowInviteUser(true);
          } else if (!item?.email_verified) {
            let data = {
              country_code: item.country_code,
              email: item.email
            }
            setUnVerifiedEmailPopup(data);

          }
        }
      })
    }
  }

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      {showInviteUser && (
        <InviteUser show={showInviteUser} handleClose={() => setShowInviteUser(false)} />
      )}

      {unVerifiedEmailPopup && <UnVerifyEmailPopup
        unVerifiedEmailPopup={unVerifiedEmailPopup}
        handleClose={() => {
          setUnVerifiedEmailPopup(false);
        }}
        verifiedEmail={(data) => {
          setUnVerifiedEmailPopup(false);
          setTimeout(() => updateMobileEmail(data), 300);
        }}
      />}

      {otpVerifiyPopup && <OtpVerifiyPopup
        data={otpVerifiyPopup}
        handleClose={() => {
          setOtpVerifiyPopup(false);
        }}
        verifiyApi={OtpVerification}
        resendOtP={updateMobileEmail}
        error={error}
        setError={setError}
      />}
      <div className="container mt-2">
        <div className="row">
          <div className="col-sm-12">
            <div>
              <span className="">
                <Button className="f-12 px-0" variant="link" style={{ color: '#FC7D72' }}>
                  <Link className="backtoclr" to={BASE_PATH}>
                    {'< '}
                    Back to Deductor List
                  </Link>
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-2">
        <fieldset className="fieldcretchall usermanagepro">
          <legend className="lengentcretchall usermanagepro">
            <div className="fieldcretchallinndev usermanagepro">
              <div className="challanlefts usermanagepro">
                <h4>User Management</h4>
              </div>
              <div className="challaninner usermanagepro" />
              <div className="newcchallanright usermanagepro">
                <h4 style={{ cursor: 'pointer' }} onClick={() => userProfileVerify()}>
                  <img className="create_new_return" src={addBtnRoundIcon} width="15px" alt="Invite User" />
                  Invite User
                </h4>
              </div>
              <div className="bortoplg bgcorlclrbrch usermanagepro"> </div>
            </div>
          </legend>
          <div className="row">
            <div className="col-md-12">
              <div className="kdk_data_table" style={{ minHeight: '200px' }}>
                <div className="table-responsive table-responsive-xs table-responsive-sm table-responsive-md">
                  <table className="table table-striped usermanagetb">
                    <thead>
                      <tr>
                        <th className="">   Name </th>
                        <th>   Email Address </th>
                        <th>   	Mobile number </th>
                        <th> 	Action   </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((user) => (
                        <tr key={user.user_id}>
                          <td style={{ width: '250px' }}>{user.full_name}</td>
                          <td className="text-center">{user.email}</td>
                          <td className="text-center">
                            <span style={{ verticalAlign: 'revert' }}>
                              <img className="flag_icon" src={Indiaflagexp} alt="mobile" />
                            </span>
                            {user.mobile_no}
                          </td>
                          {user.user_role.toUpperCase() !== 'OWNER' ? (
                            <td className="text-center">
                              <Link to={`${BASE_PATH}user-profile?user_id=${user.user_id}`}>
                                <span><img src={editIcon} alt="Edit User Profile" /></span>
                              </Link>
                                <input type="checkbox" style={{cursor: "pointer"}} checked={user.user_organization_status} onChange={(e) => invitedUserStatusUpdate(e, user)} />
                            </td>
                          ) : (
                            <td className="text-center">
                              <Link to={`${BASE_PATH}profile`}>
                                <span><img src={editIcon} alt="Edit User Profile" /></span>
                              </Link>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

    </div>
  );
};

export default UserList;
