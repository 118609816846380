/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import NumericInput from '../../components/NumericInput';
import ShowAlert from '../../components/ShowAlert';
import loginleftside from '../../images/loginicon/login_left_side.webp';
import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import './LoginScreen.css';
import { loginUser } from '../../api/authenticationAPI';
import {
  BASE_PATH,
  COBRAND_UTM_SOURCE,
  IS_COBRAND,
  SIGNUP_AVAILABLE,
} from '../../app/constants';
import { selectHeaderData } from '../header/headerSlice';
import { getRedirectUrl, removeRedirectUrl } from '../../utils/UtilityFunctions';
import { salesiq_chatoff, initializeSalesIq } from './visitor_info.js';
import exclamationIcon from "../../images/exclamation.png";
import { encryptPasswordString } from '../../utils/UtilityFunctions';
import { updateAuthToken } from '../../utils/AuthToken.js';

const LoginScreen = ({ history }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const headerData = useSelector(selectHeaderData);

  // Generate Sign Up Page URL
  let signupUrl = `${BASE_PATH}signup`;
  // If we are not in a Cobrand and utm_source is passed via Query params, then use that
  if (!COBRAND_UTM_SOURCE && params.utm_source) {
    signupUrl += `?utm_source=${params.utm_source}`;
  }

  const [mobileNo, setMobileNo] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(headerData.header_fail ? headerData.header_fail : null);
  const [formErrors, setFormErrors] = useState({});

  const handleBlur = (val, field) => {
    if(field === 'mobileNo') {
      if(val.trim().length === 0){ 
        setFormErrors({...formErrors, mobileNo: 'This field is required'})
      }else if(Number(val) < 5999999999 || Number(val) > 9999999999) {
        setFormErrors({...formErrors, mobileNo: 'Invalid Mobile Number'})
      }else{
        setFormErrors({...formErrors, mobileNo: ''})
      }
    }
    if(field === 'password') {
      if(val.trim().length === 0){ 
        setFormErrors({...formErrors, password: 'This field is required'})      
      }else{
        setFormErrors({...formErrors, password: ''})
      }
    }    
  }
 
  const login = async (e) => {
    e.preventDefault();
    const formErr = {};

    if (mobileNo.trim().length === 0) {
      formErr.mobileNo = 'This field is required';
    } else if (mobileNo < 5999999999 || mobileNo > 9999999999) {
      formErr.mobileNo = 'Invalid Mobile Number';
    }

    if (password.trim().length === 0) {
      formErr.password = 'This field is required';
    }

    setFormErrors(formErr);
    if (Object.keys(formErr).length > 0) return;

    setLoading(true);

    const data = {
      country_code: '+91',
      mobile_no: mobileNo,
      password: encryptPasswordString(password),
    };

    try {
      const result = await loginUser(data);
      salesiq_chatoff();
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['identifyUser', mobileNo]);
      updateAuthToken(result.data.token)
      localStorage.removeItem('pincode_available'); //remove old value if exist
      if (result?.data?.pincode_available === false) {
        localStorage.setItem('pincode_available', result.data.pincode_available);
      }
      let redirect_url = getRedirectUrl();
      if (redirect_url) {
        removeRedirectUrl();
        history.push(redirect_url);
      }
      else {
        history.push(`${BASE_PATH}`);
      }

    } catch (err) {
      setError(err.message);
      console.error('Error in send otp: ', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    initializeSalesIq();
  }, []);


  return (
    <div>
      <ShowAlert
        error={error}
        onClose={() => { setError(null);}}
      />

      <div className="container mt-2">

        {/* <div className="col-2 col-sm-2 col-sm-2 login-empty-container" /> */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-lg-push-2 mt-4 mx-auto ">
          <div className="row">
            <div className="col-12 col-md-5 m-0 p-0  mt-sm-2">
              <p className="">
                <span className="fileetdssm col-12">File TDS hassle-free from anywhere</span>
              </p>
            </div>

            {!IS_COBRAND ? (
              <div className="d-none d-sm-block col-12 col-md-6 mt-2 pr-0">
                <span className="btn btn-default float-right backhbtnls">
                  <a href="https://www.expresstds.com">Back to Home</a>
                </span>
              </div>
            ) : null}

          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-5  login-img-caontainer" style={{ backgroundImage: `url(${loginleftside})` }}></div>

            <div className="x_login-form-container col-12 col-sm-12 col-md-6 bg-white formBorder">

              <div className="row">
                <div className="col-12 mt-5"><h2 className="loginHeader ng-binding ml-0 text-center">Welcome to Express TDS</h2></div>
              </div>
              <div className="row">
                <div className="col-12 mt-3"><h5 className="font-16 ng-binding subtitle ml-0 text-center">Login with your Mobile Number</h5></div>
              </div>

              <form className="form-horizontal logforms" onSubmit={(e) => login(e)}>
                <div className="form-group row mb-4">
                  <div className="col-12 col-sm-12 col-md-10 mx-auto">

                    <div className="input-group mb-2">
                      <div className="input-group-prepend tdslogin_iconboxpadd">
                        {/* <i className="far fa-flag-india"> </i> */}
                        <img className={`input-group-text flag_icon bg-white ${formErrors?.mobileNo && formErrors.mobileNo !== '' ? 'input-error1' : ''}`} src={Indiaflagexp} alt="India" />
                      </div>
                      <NumericInput
                        type="tel"
                        className={`form-control form-control-lg ${formErrors?.mobileNo && formErrors.mobileNo !== '' ? 'input-error1' : ''}`}
                        name="Mobile"
                        placeholder="Enter Mobile Number"
                        required=""
                        value={mobileNo}
                        maxLength={10}
                        onChange={(e) => setMobileNo(e.target.value)}
                        onBlur={(e) => handleBlur(e.target.value, 'mobileNo')}
                      />
                    </div>
                    {formErrors.mobileNo ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{formErrors.mobileNo}</span> : null}
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <div className="col-12 col-sm-12 col-md-10 mx-auto">
                    {showPassword ? (
                      <div className="input-group mb-2">
                        <input
                          type="input"
                          className={`form-control form-control-lg ${formErrors?.password && formErrors.password !== '' ? 'input-error1' : ''}`}
                          name="password"
                          placeholder="Enter Password"
                          required=""
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={(e) => handleBlur(e.target.value, 'password')}
                          maxLength={100}
                        />
                        <div className="input-group-prepend eyeipbox" onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </div>
                      </div>
                    ) : (
                      <div className="input-group mb-2">
                        <input
                          type="password"
                          className={`form-control form-control-lg ${formErrors?.password && formErrors.password !== '' ? 'input-error1' : ''}`}
                          name="password"
                          placeholder="Enter Password"
                          required=""
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete="new-password"
                          onBlur={(e) => handleBlur(e.target.value, 'password')}
                          maxLength={100}
                        />
                        <div className={`input-group-prepend eyeipbox ${formErrors?.password && formErrors.password !== '' ? 'input-error1' : ''}`} onClick={() => setShowPassword(true)} style={{ cursor: 'pointer', padding: '13px 4px 2px 3px' }}>
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                      </div>
                    )}                    
                    {formErrors.password ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{formErrors.password}</span> : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-10 col-md-10 mt-2  mx-auto   ">

                    <Button
                      type="submit"
                      bsPrefix="form-control form-control-lg btn red-btn semi-bold btnFont"
                      name="Login"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {' '}
                          Loading...
                        </>
                      ) : 'LOGIN'}
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-4 mx-auto text-center">
                    <p className="ng-binding alsintextsiz">
                      <span className="">
                        <Link className="regtext" to={`${BASE_PATH}forgetpass?mobile_no=${mobileNo}`}>Forgot Password?</Link>
                      </span>

                    </p>
                  </div>

                  {SIGNUP_AVAILABLE ?
                    (<div className="col-12 col-md-offset-2 text-center ">
                      <p className="ng-binding alsintextsiz">
                        <span className="pr-2">Don't have an account?</span>
                        <Link className="regtext" to={signupUrl}>Sign Up</Link>
                      </p>
                    </div>)
                    : null}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-sm-2 login-empty-container" />

      </div>
    </div>

  );
};

export default LoginScreen;
