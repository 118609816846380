import React from "react";
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import moment from "moment";

const LateFeeLibilityDataPopup = (props) => {
    const { onClose, liabilityData } = props;
    const today = moment().format('DD/MM/YYYY');
    return (
        <>
            <WindowWrapperFixed
                title={
                    <h4 className="modal-title w-100" style={{ display: 'flex' }}>
                        <div className="col-md-7" style={{ paddingLeft: '0px' }}>
                            Late Fee till ({today})
                        </div>
                        <div className="col-md-5" style={{ textAlign: 'right', paddingRight: '0px' }}>

                        </div>
                    </h4>
                }
                onClose={onClose}
                totalPopups={1}
                index={0}
                isModalView={true}
                initialHeight={'auto'}
                initialLeft={400}
                initialWidth={800}
                resizable={true}
                className={`interestMapping`}
            >
                <>
                    <div class="row" style={{ marginRight: '0px' }}>
                        <div className="col-md-12 mb-3">
                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <table className="table table-striped Deducteetrans_deducteewise_tb mb-0">
                                    <thead>
                                        <tr>
                                            <th width="120">Due Date</th>
                                            {/* <th width="120">Filing Date</th> */}
                                            <th width="120">Delay in Days</th>
                                            <th width="120">Calculation</th>
                                            <th width="120">Late Fee Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Number(liabilityData?.total_fee_amtp ?? 0) > 0
                                                ? <tr className="">
                                                    <td className="text-center">
                                                        {liabilityData?.due_date ?? '-'}
                                                    </td>
                                                    <td className="text-center">
                                                        {liabilityData?.delay_in_days ?? '-'}
                                                    </td>
                                                    <td className="text-center">
                                                        {`${liabilityData?.delay_in_days ?? 0}*${liabilityData?.late_fee_per_day_amtp ?? 0} per day`}
                                                    </td>
                                                    <td className="text-right">
                                                        {liabilityData?.late_fee_amtp ?? '-'}
                                                    </td>
                                                </tr>
                                                :
                                                <tr className="">
                                                    <td className="text-center" colSpan={4}>
                                                        No Records!!
                                                    </td>                                                   
                                                </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>

            </WindowWrapperFixed >
        </>
    );
}
export default LateFeeLibilityDataPopup;