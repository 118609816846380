import React, { useEffect, useState } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";


const PanError = (props) => {

    const { apiData, formType } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);


    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    s_no: item[0],
                    cd_no: item[1],
                    dd_no: item[2],
                    deductee_ref_no: item[3],
                    pan: item[4],
                    name: item[5],
                })
            })
            return newData
        }
    };


    const dataStateChange = (dataState) => {

        if (dataState.filter || (dataState.sort && dataState?.sort?.length > 0)) {
            const filter_state = deepClone(dataState);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                            if (["gte", "lte", 'lt', 'gt', "neq"].includes(itemObj?.operator)) {
                                itemObj.value = Number(itemObj.value);
                            }
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                itemObj.value = itemObj.value?.[0] ?? "";
                            }
                        })
                    }
                })
            }
            const dataProcess = process(convertData(apiData), { ...filter_state });
            setDataState({ ...dataState });
            setData(dataProcess.data ?? []);

        } else {

            setDataState({});
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData(defaultData ?? []);
        }

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;
        const dataItem = props?.dataItem ?? {}

        return (
            <tr key={key} role="row">
                <td class="text-center">{dataItem?.s_no}</td>
                <td class="text-center">{dataItem?.cd_no ?? "-"}</td>
                <td class="text-center">{dataItem?.dd_no ?? "-"}</td>
                <td class="text-center">{dataItem?.deductee_ref_no ?? "-"}</td>
                <td class="text-center">{dataItem?.pan ?? "-"}</td>
                <td class="text-left">{dataItem?.name ?? "-"}</td>

            </tr>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);

    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab18" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">
                    <div className="card">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                            <Tooltip anchorElement="pointer" position="top">
                                <Grid
                                    className="table table-striped exptds_justformtype_table mb-0"
                                    style={{ maxHeight: '470px', overflowX: 'auto' }}
                                    data={data}
                                    {...dataState}

                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        pan: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    sortable
                                >
                                    <Column
                                        field="s_no"
                                        title={`Sr. No.`}
                                        width="50"
                                    />
                                    <Column
                                        field="cd_no"
                                        headerClassName={isColumnActive('cd_no') ? 'active' : ''}
                                        title={`CD Serial No.`}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                        filter="textWithEmpty"
                                    />
                                    <Column
                                        field="dd_no"
                                        headerClassName={isColumnActive('dd_no') ? 'active' : ''}
                                        title={`DD Serial No.`}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                        filter="textWithEmpty"
                                    />
                                    <Column
                                        field="deductee_ref_no"
                                        headerClassName={isColumnActive('deductee_ref_no') ? 'active' : ''}
                                        title={`${formType === "24Q" ? "Employee" : "Deductee"} Reference Number`}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                        filter="textWithEmpty"
                                    />
                                    <Column
                                        field="pan"
                                        headerClassName={isColumnActive('pan') ? 'active' : ''}
                                        title={`PAN of ${formType === "24Q" ? "Employee" : "Deductee"}`}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                        filter="pan"
                                    />
                                    <Column
                                        field="name"
                                        headerClassName={isColumnActive('name') ? 'active' : ''}
                                        title={`Name of ${formType === "24Q" ? "Employee" : "Deductee"}`}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                        filter="textWithEmpty"
                                    />

                                    <GridNoRecords>
                                        No Record Found
                                    </GridNoRecords>

                                </Grid>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default PanError;