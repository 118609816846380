import { useEffect } from "react";
import { HEADER_FILE_NAME } from "../app/constants";
import { downloadFileByRefKey } from "../api/downloadFile";

const DownloadFile = (props) => {

    const { apiFunction, setError, setDownloadLoading, fileName, sessionStorageKey, params, handleSuccessProceed, handleErrorProceed } = props;

    const downloadExcelFileFunction = async () => {
        if(setDownloadLoading)setDownloadLoading(true);
        try {

            const response = await apiFunction(params);
            if (response?.data) {
                setTimeout(() => {
                    const dataKey = response?.data;   
                    sessionStorage.setItem(sessionStorageKey, dataKey)
                    downloadExcelFileFunctionWithRefKey(dataKey)
                }, 2000);
            };            

        } catch (err) {
            setError(err?.message);
            if (handleErrorProceed) handleErrorProceed(err.message);
        } 
    };

    const downloadExcelFileFunctionWithRefKey = async (dataKey) => {
        if(setDownloadLoading)setDownloadLoading(true);
        try {

            const result = await downloadFileByRefKey(dataKey);
            sessionStorage.removeItem(sessionStorageKey);
            const xlsxData = new Blob([result?.data]);
            const file_name = result.headers[HEADER_FILE_NAME] ?? fileName;
            const link = document.createElement('a');
            link.href = URL.createObjectURL(xlsxData);
            link.setAttribute('download', `${file_name}`);
            document.body.appendChild(link);
            link.click();
            if (handleSuccessProceed) handleSuccessProceed();
            if(setDownloadLoading)setDownloadLoading(false);
        } catch (err) {
            if (err?.status === 404) {
                setTimeout(() => {
                    downloadExcelFileFunctionWithRefKey(dataKey)
                }, 5000);
            } else {
                setError(err?.message);
                if (handleErrorProceed) handleErrorProceed(err.message);
                if(setDownloadLoading)setDownloadLoading(false);
            }
        }
    };

    useEffect(() => {

        const checkExistingRefKey = sessionStorage.getItem(sessionStorageKey)
        if (checkExistingRefKey) {
            downloadExcelFileFunctionWithRefKey(checkExistingRefKey);
        } else {
            downloadExcelFileFunction()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (null);

};

export default DownloadFile;