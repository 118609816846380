import React from 'react';
import checkIcon from "../../../images/checkmark.png";
import closedIcon from "../../../images/closedicon.png";

const ReturnProgressBar = (props) => {

    const {status} = props;
    return (
        <>
            <div className="row mt-1">
                <div className="col-md-12">
                    <fieldset className="efiling_flow_returngentst_fs">
                        <legend className="efiling_flow_returngentst_leg">
                            <h4>Return Generation Status</h4>
                        </legend>

                        <div className="progress efiling_flow_progressbar">
                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${status.pb_val}%` }} ></div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 text-center">
                                <button className="btn btn-default efiling_flow_fetchingCSI_btn">
                                    Fetching CSI

                                    <span className="pl-1 float-right">
                                        <img src={status.csi ? checkIcon : closedIcon} alt="check mark" width="18" />
                                    </span>
                                </button>
                            </div>

                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 text-center">
                                <button className="btn btn-default efiling_flow_fetchingCSI_btn">
                                    Preparing TXT
                                    <span className="pl-1 float-right">
                                        <img src={status.txt ? checkIcon : closedIcon} alt="check mark" width="18" />
                                    </span>
                                </button>
                            </div>



                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 text-center">
                                <button className="btn btn-default efiling_flow_fetchingCSI_btn" id="validtimeline_show">
                                    Validating TXT
                                    <span className="pl-1 float-right">
                                        <img src={status.fvu ? checkIcon : closedIcon} alt="closed icon" width="18" />
                                    </span>
                                </button>
                            </div>

                        </div>


                    </fieldset>
                </div>
            </div>
        </>
    )
}

export default ReturnProgressBar