import React, { useEffect, useState } from 'react';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { formatNumber, getFirstAndLastDateForYearAndMonth } from '../../../utils/UtilityFunctions';
import $ from "jquery";
import "../../deductorDashboard/returnsDashboardV2/ModalPopup.scss";
import { gocallApi } from '../../../api/issueCertificateAPI';
import Spinner from 'react-bootstrap/Spinner';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { Skeleton } from '@mui/material';


const DeducteeTransPopup = (props) => {
    const { handleClose, month, params, headerData,
        naturePaymentId, setError, totalPopups, index, ids } = props;

    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState({});
    const [showSkelton, setShowSkelton] = useState(true);
    const [transPopupList, setTransPopupList] = useState([]);
    const [dataState, setDataState] = useState({});
    const [scrollPagnation, setScrollPagnation] = useState(false);
        const { firstDay, lastDay } = getFirstAndLastDateForYearAndMonth(headerData?.financial_year_formatted, month);

    const filter = [
        {
            "logic": "and",
            "filters": [
                {
                    "field": "code",
                    "operator": "in",
                    "value": [
                        naturePaymentId
                    ]
                }
            ]
        },
        {
            "logic": "and",
            "filters": [
                {
                    "field": "payment_date",
                    "operator": "gte",
                    "value": firstDay
                },
                {
                    "field": "payment_date",
                    "operator": "lte",
                    "value": lastDay
                }
            ]
        }
    ];



    const dataStateChange = (data_state) => {
        setDataState({ ...data_state });
    }


    const getTdsMappingMonthWiseChildwise = async (payload, isPagination) => {

        const url = `/api/v2/transaction/list`;
        if (isPagination) setLoading(true);
        else setShowMoreLoading(true);

        try {
            const result = await gocallApi("post", url, payload)
            if (payload?.page > 1) {
                setTransPopupList((prev) => {
                    prev = prev.concat(result?.data?.data ?? []);
                    return [...prev];
                })
            } else {
                setTransPopupList(result?.data?.data ?? []);
            }
            setCursor(result?.data?.cursor ?? {});
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setShowSkelton(false);
            if (isPagination) setLoading(false);
            else setShowMoreLoading(false);
            setScrollPagnation(false);
        }

    }



    const rowRender = (trElement, props) => {
        const dataItem = props?.dataItem ?? {};

        return (
            <tr>
                <td class="text-left">{dataItem?.deductee_name ?? ""}</td>
                <td class="text-center">{dataItem?.pan ?? ""}</td>
                <td class="text-right">{formatNumber(dataItem?.paid_amtp ?? "0")}</td>
                <td class="text-right">{formatNumber(dataItem?.total_tds_amtp ?? "0")}</td>
                <td class="text-center">{dataItem?.challan_no ?? ""}</td>
            </tr>
        )
    }

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };
    const getScrollbarHeight = (el) => {
        return el.scrollHeight
    };
    $(function () {
        $(".deducteetranspopup .k-grid-content").scroll(function () {

            if (getScrollbarHeight(document.querySelector('.k-grid-content')) < $(".k-grid-content").scrollTop() + $(".k-grid-content").height() + 3) {
                if (!scrollPagnation && cursor?.next_page > 1) {
                    setScrollPagnation(true);

                }
            }
        });
    });

    useEffect(() => {
        const payload = {
            "deductor_id": params?.deductor_id ?? "",
            "return_id": params?.return_id,
            "sort_on": dataState?.sort?.[0]?.field ?? "",
            "sort_by": dataState?.sort?.[0]?.dir ?? "",
            "filters": dataState?.filter?.filters ? [...dataState?.filter?.filters, ...filter] : filter,
            "limit": 16,
            "month": month,
            "nature_of_payment_id": naturePaymentId,
            "page": 1
        }
        getTdsMappingMonthWiseChildwise(payload);
        // eslint-disable-next-line
    }, [ids, dataState]);

    useEffect(() => {

        if (cursor.next_page > 1 && scrollPagnation) {
            const payload = {
                "deductor_id": params?.deductor_id ?? "",
                "return_id": params?.return_id,
                "sort_on": dataState?.sort?.[0]?.field ?? "",
                "sort_by": dataState?.sort?.[0]?.dir ?? "",
                "filters": dataState?.filter?.filters ? [...dataState?.filter?.filters, ...filter] : filter,
                "limit": 16,
                "month": month,
                "nature_of_payment_id": naturePaymentId,
                "page": cursor.next_page ? cursor.next_page : 1
            }
            getTdsMappingMonthWiseChildwise(payload, scrollPagnation);
        }
        // eslint-disable-next-line
    }, [scrollPagnation]);


    return (
        <>
            {showMoreLoading && Object.keys(dataState)?.length > 0 && <LoadingIndicator />}

            <div className="monthwisedeductee transpopup">
                <WindowWrapperFixed
                    title="Deductee Transactions"
                    onClose={() => handleClose(index)}
                    initialHeight={230}
                    style={{ maxHeight: '240px', overflowX: 'hidden' }}
                    totalPopups={totalPopups}
                    index={index}
                    initialTop={230}
                    initialWidth={1048}
                >
                    {
                      //  (!transPopupList?.length > 0) ?
                      showSkelton ? 
                        <div style={{ display: "flex" }}>
                            {[...Array(6)].map((items) =>
                                <div className='col-md-2'>
                                    {[...Array(4)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
                                </div>
                            )}
                        </div>
                        :
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs deducteetranspopup">
                            <Tooltip anchorElement="pointer" position="right">

                                <Grid
                                    className="table table-striped blukimport_tb mb-0 scrollvisible"
                                    data={transPopupList}
                                    {...dataState}
                                    sortable
                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterEqOperator', operator: 'in' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                >
                                    <Column
                                        width="100"
                                        field="deductee_name"
                                        title="Ded Name"
                                        filter="text"
                                        columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                    />

                                    <Column
                                        width="100"
                                        field="pan"
                                        title="PAN"
                                        filter="text"
                                        columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('pan') ? 'active' : ''}
                                    />

                                    <Column
                                        width="100"
                                        field="paid_amtp"
                                        title="Amt Paid"
                                        filter="numeric"
                                        columnMenu={((props) => <ColumnMenu  {...props} />)}
                                        headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                                    />

                                    <Column
                                        width="100"
                                        field="total_tds_amtp"
                                        title="TDS"
                                        filter="numeric"
                                        columnMenu={((props) => <ColumnMenu {...props} />)}
                                        headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                                    />
                                    <Column
                                        width="100"
                                        field="challan_no"
                                        title="Challan"
                                        filter="text"
                                        columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                    />

                                </Grid>
                            </Tooltip>
                            {loading && (
                                <span style={{
                                    position: 'absolute',
                                    left: '50%',
                                    bottom: "2px",
                                    color: "gray"
                                }}>

                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                            )}
                        </div>}

                </WindowWrapperFixed>
            </div>
        </>
    )
}

export default DeducteeTransPopup