import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { parseDate } from '@telerik/kendo-intl';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { BASE_PATH } from '../../app/constants';
import { getCurrentFinancialYear } from '../../utils/UtilityFunctions';
import { gocallApi } from '../../api/issueCertificateAPI';
import ShowAlert from '../../components/ShowAlert';
import BulkPanImport from './BulkPanImport';
import "./BulkPanVerificationV2.scss";
import ShowMorePagination from '../../components/pagination/ShowMorePagination';
import moment from 'moment';


const BulkPANVerificationV2 = () => {

    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);
    const deductorId = params.deductor_id ?? ""
    const history = useHistory();

    const PAN_STATUS = {
        PAN_NOT_AVAILABLE: "Not Available",
        PAN_INVALID: "Invalid",
        PAN_APPLIED: "Applied",
        PAN_AVAILABLE_BUT_INACTIVE: "Inoperative",
        PAN_AVAILABLE_BUT_UNVERIFIED: "Unverified",
        PAN_AVAILABLE_AND_VERIFIED: "Operative "
    }


    const DropdownComplianceStatus = [
        {label: "Regular-filer", value: 0},
        {label: "Non-filer", value: 1},
        {label: "Unverified", value: null},
    ]

    const ComplianceStatus = (status) => {

        switch (status) {
            case 0: return "Regular-filer";
            case 1: return "Non-filer"
            case null:
            default: return "Unverified"
        }


    }

    const [bulkPanList, setBulkPanList] = useState([]);
    const [dataState, setDataState] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cursor, setCursor] = useState({ current_page: 1, next_page: null });
    const [reloadPanList, setReloadPanList] = useState(false);

    const dataStateChange = (data_state) => {
        const financial_year = params?.financial_year ?? getCurrentFinancialYear()
        createHistoryUrl({ filter_year: financial_year, filters: data_state })
        // setDataState({ ...data_state })
    }

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const createHistoryUrl = ((parameter) => {
        const { filter_year = null, filters = [] } = parameter;

        let filterStr = `?deductor_id=${deductorId}&financial_year=${filter_year ?? ""}`;
        if (filters.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
        }

        if (filters.sort && filters.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
        }

        history.push(filterStr);
    });

    const getParamsHistoryUrl = (parameter) => {


        const requestData = {
            deductor_id: parameter?.deductor_id ?? "",
            page: 1,
            limit: 17
        };
        const filterState = {};
        filterState.sort = [];
        if (parameter.sort_on) {
            filterState.sort = [{ field: parameter.sort_on, dir: parameter.order_by ?? 'asc' }];
            requestData.sort_on = parameter.sort_on;
            requestData.sort_by = parameter.order_by;
        }
        if (parameter.filters) {
            filterState.filter = { filters: [] };
            const frs = JSON.parse(atob(parameter.filters));
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'verified_on') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                    if (frs[i].filters[j].field === 'pan') {
                        frs[i].filters[j].value = frs[i].filters[j].value.toUpperCase();

                    }
                }
            }
            filterState.filter.filters = frs
            requestData.filters = frs

        } else {
            filterState.filter = { filters: [] };
        }

        if (parameter.page) requestData.page = parseInt(parameter.page);

        setDataState(filterState);
        return requestData;
    };

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem ?? {};

        return (
            <tr>
                <td className="text-center blukpan_textclr_blue">{dataItem.pan ?? "-"}</td>
                <td className="">{dataItem.traces_name ?? ""}</td>
                <td className="text-center">{PAN_STATUS[dataItem.traces_status] ? PAN_STATUS[dataItem.traces_status] : "Pending"}</td>
                <td className="text-center">{ComplianceStatus(dataItem.insight_status)}</td>
                <td className="text-center"><span style={{ marginLeft: "2.2rem" }}>{dataItem.verified_on ? moment(dataItem.verified_on).format("DD/MM/YYYY") : "-"}</span></td>
            </tr>
        )
    }

    const getBulkPlanListOnServer = (parameter) => {

        async function fetchEverything() {
            const url = `/api/v1/report/deductor/pan-verification-list`;
            setLoading(true);
            try {
                const result = await gocallApi("post", url, parameter);
                if (parameter?.page > 1) {
                    setBulkPanList((prev) => {
                        prev = prev.concat(result?.data?.data ?? []);
                        return [...prev];
                    })
                } else {
                    setBulkPanList(result?.data?.data ?? []);
                }

                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page
                setCursor(result?.data?.cursor ?? { current_page: 1, next_page_url: null })

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything()

    }

    useEffect(() => {
        const params = queryString.parse(searchStr);
        const requestData = getParamsHistoryUrl(params);
        getBulkPlanListOnServer(requestData)
    }, [searchStr, reloadPanList])


    return (
        <>

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />            

            <div className="bulkpanverificationscss container-fluid">
                <div className="row mt-2">
                    <div className="col-md-2">
                        <Link className="bulkpanverif_backtextfs" to={`${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${params?.financial_year}`}>&lt; Back to Dashboard</Link>
                    </div>
                    <div className="col-md-7 text-center" >
                        <h4 className="bulkpanverif_textfs">BULK PAN Verification Status</h4>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-9 col-lg-7 col-md-7 col-sm-8 col-xs-12">
                        <div className="card">
                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" >
                                <Tooltip anchorElement="pointer" position="right">
                                    <Grid
                                        className={`table table-striped dash_blukpanstatus_tb mb-1 ${bulkPanList?.length > 17 ? "gridmaxheight" : ""} `}
                                        data={bulkPanList}
                                        {...dataState}

                                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                                        filterOperators={{
                                            text: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            ],
                                            dropdown: [
                                                { text: 'grid.filterEqOperator', operator: 'in' },
                                            ],
                                            numeric: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                            ],
                                            textWithEmpty: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                            ],
                                            date: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                            ],
                                        }}
                                        rowRender={rowRender}
                                        sortable
                                    >

                                        <Column
                                            width="100"
                                            field="pan"
                                            title="PAN"
                                            filter="text"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                            headerClassName={isColumnActive('pan') ? 'active' : ''}
                                        />

                                        <Column
                                            width="200"
                                            field="traces_name"
                                            title="Name as per TRACES"
                                            filter="text"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter  {...props} />)}
                                            headerClassName={isColumnActive('traces_name') ? 'active' : ''}
                                        />

                                        <Column
                                            width="190"
                                            field="traces_status"
                                            title="Status as per TRACES"
                                            filter="text"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                            headerClassName={isColumnActive('traces_status') ? 'active' : ''}
                                        />

                                        <Column
                                            width="155"
                                            field="insight_status"
                                            title="Status as per Insights"
                                            filter="dropdown"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter filterList={DropdownComplianceStatus} {...props} />)}
                                            headerClassName={isColumnActive('insight_status') ? 'active' : ''}
                                        />

                                        <Column
                                            width="105"
                                            title="Verified on"
                                            filter='date'
                                            field="verified_on"
                                            // headerClassName='sortablefalsecolumn'
                                            columnMenu={((props) => <ColumnMenu  {...props} />)}
                                            headerClassName={isColumnActive('verified_on') ? 'active' : ''}

                                        />
                                    </Grid>
                                </Tooltip>
                            </div>
                            <ShowMorePagination
                                cursor={cursor}
                                postData={{}}
                                loading={loading}
                                fetchData={(pages) => {
                                    let searchStr = '';
                                    Object.keys(params).map((key) => {
                                        if (key && key !== 'page') {
                                            searchStr = `${searchStr}&${key}=${params[key]}`;
                                        }
                                        return null;
                                    });
                                    history.push(`${location.pathname}?page=${pages?.page ?? 1}${searchStr}`)
                                }}
                            />
                        </div>
                    </div>
                    <BulkPanImport deductorId={deductorId} setReloadPanList={setReloadPanList} />
                </div>
            </div>
        </>
    )
}

export default BulkPANVerificationV2