import React, { Component } from 'react';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import CreateDeductorForm from '../../components/createDeductorForm/CreateDeductorForm';
import ShowAlert from '../../components/ShowAlert';
import { editDeductor, getDeductorById, editCorrectionDeductor, getCorrectionDeductorById } from '../../api/deductorAPI';
import { getDeductorObject, getFormObject, deductorFormFields } from '../../components/createDeductorForm/DeductorFormHelper';
import { BASE_PATH } from '../../app/constants';

export default class EditDeductor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deductorInfo: { ...deductorFormFields, is_cp_same_as_rp: 'no' },
      initialData: {},
      deductorId: 0,
      returnId: 0,
      error: '',
      success: '',
      loading: false,
      dataLoaded: false,
      correctionDeductorInfo: {},
    };
  }

  componentDidMount() {
    var deductorId = this.props?.match?.params?.deductorId;
    if (this.props?.match?.params?.returnId) {
      var returnId = this.props?.match?.params?.returnId;
      var componentImport = this.props?.componentImport
    }
    var self = this;
    async function getDeductor2() {
      self.setState({ loading: true });
      try {
        const result = componentImport ? await getCorrectionDeductorById(deductorId, returnId) : await getDeductorById(deductorId);
        var deductorInfo = getFormObject(result?.data);
        deductorInfo['deductor_id'] = deductorId;
        if (self.props?.componentImport) {
          deductorInfo['return_id'] = self.props?.match?.params?.returnId;
        }
        deductorInfo['is_cp_same_as_rp'] = 'no';

        if (deductorInfo?.deductor_category) deductorInfo.deductor_category = deductorInfo.deductor_category === "Z" ? "" : deductorInfo.deductor_category;
        if (!deductorInfo?.branch) deductorInfo.branch = "NA";

        self.setState({ deductorId: result?.data?.deductor_id, returnId: result?.data?.return_id, deductorInfo: deductorInfo, initialData: result?.data, dataLoaded: true, correctionDeductorInfo: result?.data });
      } catch (err) {
        if (err?.message) {
          self.setState({ error: err.message, dataLoaded: true });
        } else {
          self.setState({ error: err, dataLoaded: true });
        }
      } finally {
        self.setState({ loading: false });
      }
    }
    getDeductor2();

  }

  createDeductor = (values) => {
    var self = this;
    self.setState({ error: '', success: '' });
    var requestData = getDeductorObject(values);

    if (requestData?.hasOwnProperty('ref_code')) delete requestData['ref_code'];



    requestData['deductor_id'] = this.state?.deductorId;

    if (self.props?.match?.params?.returnId) {
      requestData['return_id'] = this.state?.returnId;
      var componentImport = self.props?.componentImport
    }

    async function callAPI() {
      async function editDeductor2() {
        const result = componentImport ? await editCorrectionDeductor(requestData) : await editDeductor(requestData);
        return result;
      }
      try {
        const result = await editDeductor2();
        self.setState({ error: '', success: result?.message });
        if (!self.props?.componentImport) {
          setTimeout(() => self.props?.history?.push(BASE_PATH), 1000);
        } else {
          //window.location.href = `${BASE_PATH}correction?deductor_id=${self.props.match.params.deductorId}&return_id=${self.props.match.params.returnId}&tab=deductee`;
          // self.props.history.push(`${BASE_PATH}correction?deductor_id=${self.props.match.params.deductorId}&return_id=${self.props.match.params.returnId}&tab=deductee`);
        }
      } catch (err) {
        console.error('error: ', err);
        self.setState({ error: err.toString(), success: '' });
      } finally {
        self.setState({ loading: false });
      }
    }
    self.setState({ loading: true });
    callAPI();
  };

  render() {
    var self = this;
    const { deductorInfo, deductorId, success, error, loading, dataLoaded, correctionDeductorInfo } = this.state;
    return (
      <div>
        {loading ? <LoadingIndicator /> : null}
        <ShowAlert
          success={success}
          error={error}
          onClose={() => {
            self.setState({ success: null, error: null });
          }}
        />

        {dataLoaded ? <CreateDeductorForm
          deductorInfo={deductorInfo}
          deductorId={deductorId}
          createDeductor={this.createDeductor}
          componentImport={this.props?.componentImport}
          consoStatus={this.props?.consoStatus}
          correctionDeductorInfo={correctionDeductorInfo}
          returnType="regular"
          returnId=''
        /> : null}
      </div>
    );
  }
}
