/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable linebreak-style */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import CreateDeducteeForm from '../../components/createDeducteeForm/CreateDeducteeForm';
import CreateCorrectionDeducteeForm from '../../components/createDeducteeForm/CreateCorrectionDeducteeForm';
import { getFormObject, getDeducteeObject } from '../../components/createDeducteeForm/DeducteeFormHelper';
import { getCorrectionFormObject, correctionGetDeducteeObject } from '../../components/createDeducteeForm/CorrectionDeducteeFormHelper';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { updateDeductee, getDeducteeDetails, updateCorrectionDeductee, getCorrectionDeducteeDetails } from '../../api/deducteeAPI';

const EditDeductee = (props) => {
  const { onClose, deductorId, deducteeInfo, deducteeId, headerNotUpdate, componentImport, updateDeducteeInfo,
     deducteeList, currentDeductee, setCurrentDeductee, horizontalView, left } = props;

  const location = useLocation();
  const params = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deducteeEditInfo, setDeducteeEditInfo] = useState(false);
  const [editDatainfo, setEditDatainfo] = useState({});

  const unMaskingData = (data) => {
    if (data?.mobile_no) {
      data.mobile_no = data?.mobile_no.replace(/\D+/g, '')
    }
    return data
  }

  async function createDeductee(data, closeForm = true) {
    setError(null);
    setSuccess(null);
    const deducteeData = componentImport ? correctionGetDeducteeObject(data) : getDeducteeObject(unMaskingData(data));
    deducteeData.deductor_id = deductorId;
    deducteeData.deductee_id = deducteeId ? deducteeId : deducteeInfo.deductee_id;;
    // deducteeData.deductee_id = deducteeInfo.deductee_id;
    if (params.return_id) {
      deducteeData.return_id = params.return_id;
    }
    async function callAPI() {
      async function createDeductee2() {
        deducteeData.user_salary_category = deducteeData.salary_category;
        const result = componentImport ? await updateCorrectionDeductee(deducteeData) : await updateDeductee(deducteeData);
        return result;
      }
      try {
        const result = await createDeductee2();
        setSuccess(result.message);
        if (closeForm) {
          onClose(true, result.message);
        }
      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    callAPI();
  }

  const maskingData = (data) => {    
    if (data?.data?.deductee?.mobile_no) {
      let mobile_no = String(data?.data?.deductee?.mobile_no)
      if (mobile_no.length > 5 && mobile_no.length < 11) {
        data.data.deductee.mobile_no = (mobile_no.slice(0, 5) + "-" + mobile_no.slice(5));
      } else if (mobile_no.length > 10 && mobile_no.length !== 14) {
        data.data.deductee.mobile_no = (mobile_no.slice(0, 5) + "-" + mobile_no.slice(5, 10) + "-" + mobile_no.slice(10));
      } else {
        data.data.deductee.mobile_no = (mobile_no);
      }
      // data.data.deductee.mobile_no = `${mobile_no.slice(0, 5)}${mobile_no?.length > 6 ? '-' : ''}${mobile_no.slice(5, 10)}${mobile_no?.length > 11 ? '-' : ''}${mobile_no.slice(10, 12)}`
    }
    return data
  }

  async function callGetDeducteeAPI(deducteeId) {
    async function getDeducteeDetaisData() {
      const result = componentImport ? await getCorrectionDeducteeDetails(`?deductee_id=${deducteeId}&return_id=${params.return_id}&deductor_id=${deductorId}`, !headerNotUpdate) : await getDeducteeDetails(`?deductee_id=${deducteeId}&deductor_id=${deductorId}`, !headerNotUpdate);
      // if (!componentImport) {
        // return maskingData(result);
      // } else {
        return result;
      // }      
    }
    try {
      const result = await getDeducteeDetaisData();
      if (result.data) {
        componentImport ? setDeducteeEditInfo(result?.data) : setDeducteeEditInfo(result?.data?.deductee);
        if (componentImport) {
          setEditDatainfo(result?.data);
        }
      }
    } catch (err) {
      setError(err.toString());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (deducteeInfo?.deductee_id) {
      setLoading(true);
      callGetDeducteeAPI(deducteeInfo?.deductee_id);
    }
  }, [deducteeInfo]);


  useEffect(() => {
    if (deducteeId) {
      setLoading(true);
      callGetDeducteeAPI(deducteeId);
    }
  }, [deducteeId]);


  return (

    <div>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {deducteeEditInfo ? componentImport ? (
        <CreateCorrectionDeducteeForm
          deducteeInfo={getCorrectionFormObject(deducteeEditInfo)}
          onClose={() => onClose()}
          createDeductee={createDeductee}
          deducteeId={deducteeEditInfo?.deductee_id}
          deductorId={deductorId}
          return_id={params.return_id}
          headerNotUpdate={headerNotUpdate}
          updateDeducteeInfo={updateDeducteeInfo}
          deducteeList={deducteeList}
          currentDeductee={currentDeductee}
          setCurrentDeductee={setCurrentDeductee}
          horizontalView={horizontalView}
          editDatainfo={editDatainfo}
        />
      ) : (
        <CreateDeducteeForm
          deducteeInfo={getFormObject(deducteeEditInfo)}
          onClose={() => onClose()}
          createDeductee={createDeductee}
          deducteeId={deducteeEditInfo?.deductee_id}
          deductorId={deductorId}
          updateDeducteeInfo={updateDeducteeInfo}
          deducteeList={deducteeList}
          currentDeductee={currentDeductee}
          setCurrentDeductee={setCurrentDeductee}
          horizontalView={horizontalView}
          left={left}
        />
      )
        : null}

    </div>

  );
};

export default EditDeductee;
