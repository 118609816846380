// @flow
/**
 * This component is designed to be a multi-selct component which supports
 * the selection of several items in a picklist.  It was meant to mimic the
 * style of react-select but the multi-select behavior didn't work for our
 * our needs.
 *
 * Arguments:
 * - options: The {value, label}[] options to be displayed
 * - values: The currently selected values []
 * - onSelectedChanged: An event to notify the caller of new values
 * - valueRenderer: A fn to support overriding the message in the component
 */
import React, { Component } from 'react';
import './MultiSelect.css';
import Dropdown from './dropdown.js';
import SelectPanel from './select-panel.js';
import getString from './get-string.js';
import SelectItem from './select-item.js';

class MultiSelect extends Component {
    getSelectedText() {
        const { options, selected } = this.props;

        const selectedOptions = selected
            .map(s => options.find(o => o.value === s));

        const selectedLabels = selectedOptions.map(s => s ? s.label : "");

        return selectedLabels.join(", ");
    }

    renderHeader() {
        const {
            options,
            selected,
            valueRenderer,
            overrideStrings,
        } = this.props;

        const noneSelected = selected.length === 0;
        const allSelected = selected.length === options.length;

        const customText = valueRenderer && valueRenderer(selected, options);

        if (noneSelected) {
            return <span style={styles.noneSelected}>
                {customText || getString("selectSomeItems", overrideStrings)}
            </span>;
        }

        if (customText) {
            return <span>{customText}</span>;
        }

        return <span>
            {allSelected
                ? getString("allItemsAreSelected", overrideStrings)
                : this.getSelectedText()
            }
        </span>;
    }

    handleSelectedChanged = (selected) => {
        const { onSelectedChanged } = this.props;

        if (onSelectedChanged) {
            onSelectedChanged(selected);
        }
    }

    render() {
        const {
            ItemRenderer,
            options,
            selected,
            selectAllLabel,
            isLoading,
            disableSearch,
            filterOptions,
            overrideStrings,
            labelledBy,
            hasSelectAll,
            onClose,
        } = this.props;

        return <div className="multi-select">
            <Dropdown
                isLoading={isLoading}
                contentComponent={SelectPanel}
                shouldToggleOnHover={false}
                contentProps={{
                    ItemRenderer,
                    options,
                    selected,
                    hasSelectAll,
                    selectAllLabel,
                    onSelectedChanged: this.handleSelectedChanged,
                    disableSearch,
                    filterOptions,
                    overrideStrings,
                }}
                labelledBy={labelledBy}
                onClose={onClose}
            >
                {this.renderHeader()}
            </Dropdown>
        </div>;
    }
}

const styles = {
    noneSelected: {
        color: "#aaa",
    },
};

export default MultiSelect;
export { Dropdown, SelectPanel, SelectItem };