// eslint-disable-next-line
import React, { useEffect } from 'react';
import { getPanListToVerifyAtTracesAndInsightsViaConnector } from '../../api/serverConnectorAPI';
import { verifyPANandCheckCompliance, checkExistingComplianceRequest } from '../../api/localConnectorAPI';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';

export default function UploadPanListComplianceCheck(props) {
  // eslint-disable-next-line
  const { deductorId, returnId, port, insightsCaptchaRef, setSuccess, setError,
    setLoading, coplianceStatusKey, setComplianceCheckStatusData, requestforCheckCompliance, showToast = true,setReloadPanList } = props;


  const fetchTracesCredentials = (deductorId, insightsCaptchaRef, port, coplianceStatusKey) => {

    async function fetchEverything() {
      try {
        setLoading(true);

        const payload = {
          'deductor_id': deductorId,
          'return_id': returnId,
          'action_type': 'RECHECK_INSIGHTS_PANS',
          'insights_captcha_ref': insightsCaptchaRef,
        }
        const result = await getPanListToVerifyAtTracesAndInsightsViaConnector(payload);
        const responseHeader = result.headers ?? null;
        const response = result?.data ?? null

        try {

          const dataKey = result?.data?.data ?? '';
          const formData = new FormData();
          formData.append('data', dataKey);
          let extraHeader = {};

          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          const connectorResponse = (requestforCheckCompliance === true) ? (await checkExistingComplianceRequest(port, response, extraHeader)) : (await verifyPANandCheckCompliance(port, formData, extraHeader))

          if (Number(connectorResponse.status) === 1) {
            if (showToast) {
              //set local storage key to get PAN verify status data
              const statusData = { 'insights_captcha_ref': insightsCaptchaRef, 'port': port };
              localStorage.setItem(coplianceStatusKey, JSON.stringify(statusData));
              setComplianceCheckStatusData(statusData);
              setSuccess("Your ReCheck Compliance process has started successfully. You may monitor the status of the process at the bottom of the screen. Please ensure that the Connector service keeps running, and the system is not shut down.");
            } else {
              if(requestforCheckCompliance) {
                setSuccess('It may take a while to update the status of all records. Kindly Check after sometime.');
                setTimeout(() => setReloadPanList(true), 4000)
              } else {
                setSuccess('Your request for verification has been uploaded to the Insights portal. The portal generates results after some time, so kindly check back after a while to view the Status'); 
                setTimeout(() => setReloadPanList(true), 4000)
              }              
            }
          }


        } catch (err) {
          const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default'];
          if (showToast) {
            setError(errmsg);
          } else {
            setError(errmsg);
          }
        }

      } catch (err) {

        console.error('2 error: ', err);
        setError(err.message);

      } finally {
        setLoading(false);
      }

    }

    fetchEverything();
  }

  useEffect(() => {
    fetchTracesCredentials(deductorId, insightsCaptchaRef, port, coplianceStatusKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductorId, insightsCaptchaRef, port, coplianceStatusKey]);

  return (null);

};
