/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';
import './SingleSelect.css';

export default function SingleSelect(props) {
  const { options, value, onChange, allowNumeric = true, ...rest } = props;

  const onKeyDown = (e) => {
    if (!allowNumeric) {
      if (/[0-9]/i.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <Select
      defaultValue={value?.value ? value : null}
      value={value?.value ? value : null}
      onChange={onChange}
      options={options}
      onKeyDown={onKeyDown}
      className="single-select-d"
      {...rest}
    />
  );
}
