/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable require-jsdoc */
/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import $ from 'jquery';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { deducteeFormValidationSchema } from './DeducteeFormSchema';
import { deducteeFormFields } from './DeducteeFormHelper';
import { COUNTRTY_LIST, STATES, STATE_LIST, DEDUCTEE_TYPE_REGULAR_RETURN } from '../../app/constants';
import ErrorFocus from '../ErrorFocus';
import './CreateDeductee.css';
import DatePicker from '../datePicker/DatePicker';
import AlphaNumericInput from '../AlphaNumericInput';
import PANInput from '../PANInput';
import SingleSelect from '../singleSelect/SingleSelect';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import ConnectorAdapter from '../kdkConnector/ConnectorAdapter';
import TracesConnector from '../kdkConnector/TracesConnector';
import SinglePanVerify from '../verifyPAN/SinglePanVerify';
import ShowAlert from '../../components/ShowAlert';
import WindowWrapperFixed from '../grid/WindowWrapperFixed';
import exclamationIcon from "../../images/exclamation.png";

const pattPAN = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

export default function CreateDeducteeForm(props) {
  const [verifyPANResult, setVerifyPANResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSinglePAN, setShowSinglePAN] = useState(false);
  const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
  const [error, setError] = useState(null);
  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [deducteeTypes, setDeducteeTypes] = useState(DEDUCTEE_TYPE_REGULAR_RETURN);

  const {
    deducteeInfo, createDeductee, onClose, deducteeId, deductorId, validation_error, updateDeducteeInfo, deducteeList, currentDeductee, setCurrentDeductee, left = null
  } = props;

  useEffect(() => {

    if ((deducteeInfo?.pan ?? '') !== '') {
      resetDeducteeTypeOptions(deducteeInfo?.pan);
    }

    deducteeInfo.deductee_type = deducteeInfo.deductee_type ?? ""


    function initResizeElement() {
      const popups = document.getElementsByClassName('deductee-popup');
      let element = null;
      let startX; let startY; let startWidth; let
        startHeight;

      function doDragWidth(e) {
        element.style.width = `${startWidth - e.clientX + startX}px`;
      }

      function doDragHeight(e) {
        const cardHeight = $('.card').height() + 30;
        const newHeight = startHeight - e.clientY + startY;
        element.style.height = `${Math.min(cardHeight, newHeight)}px`;
      }

      function stopDrag() {
        document.documentElement.removeEventListener('mousemove', doDragWidth, false);
        document.documentElement.removeEventListener('mousemove', doDragHeight, false);
        document.documentElement.removeEventListener('mouseup', stopDrag, false);
      }

      function initDragWidth(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10,
        );
        document.documentElement.addEventListener('mousemove', doDragWidth, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      function initDragHeight(e) {
        element = this.parentPopup;

        startY = e.clientY;
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10,
        );
        document.documentElement.addEventListener('mousemove', doDragHeight, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      for (let i = 0; i < popups.length; i++) {
        const p = popups[i];

        const right = document.createElement('div');
        right.className = 'resizer-left';
        p.appendChild(right);
        right.addEventListener('mousedown', initDragWidth, false);
        right.parentPopup = p;

        const bottom = document.createElement('div');
        bottom.className = 'resizer-up';
        p.appendChild(bottom);
        bottom.addEventListener('mousedown', initDragHeight, false);
        bottom.parentPopup = p;
      }
    }

    $('#create-deductor').animate({
      opacity: '1',
    });

    // initDragElement();
    initResizeElement();

    $('#deductee_name').focus();

  }, []);


  const verifyTraces = async () => {
    setShowSinglePAN(true);
  }

  const prevDeductee = () => {
    let selectPage = 0;
    if (currentDeductee > 0) {
      selectPage = currentDeductee - 1;
    }
    if (currentDeductee !== selectPage) {
      setCurrentDeductee(selectPage);
      updateDeducteeInfo(deducteeList[selectPage], false);
    }
  }

  const nextDeductee = () => {
    const selectPage = currentDeductee + 1;
    if (currentDeductee !== selectPage && selectPage < deducteeList.length) {
      setCurrentDeductee(selectPage);
      updateDeducteeInfo(deducteeList[selectPage], false);
    }
  }

  const applyColour = (type) => {
    if (type === "next") {
      const selectPage = currentDeductee + 1;
      if (currentDeductee !== selectPage && selectPage < deducteeList?.length) {
        return 'rgb(0, 0, 255)';
      } else {
        return 'grey';
      }
    } else {
      let selectPage = 0;
      if (currentDeductee > 0) {
        selectPage = currentDeductee - 1;
      }
      if (currentDeductee !== selectPage) {
        return 'rgb(0, 0, 255)';
      }
      else {
        return 'grey';
      }

    }

  };


  const getCountryIndex = (value) => {
    const index = COUNTRTY_LIST.findIndex((item) => String(item.value) === String(value))
    if (index >= 0) return index;
    return null;
  }


  const resetDeducteeTypeOptions = (InputPan, setFieldValue) => {
    let deducteeTypeOptions = [];
    let pan = InputPan.toUpperCase()
    let regexPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (['PANAPPLIED', 'PANINVALID', 'PANNOTAVBL'].includes(pan)) {
      deducteeTypeOptions = [{ label: 'Others', value: 'OTH_10' }];
    }
    else if (!regexPan.test(pan)) {
      deducteeTypeOptions = DEDUCTEE_TYPE_REGULAR_RETURN
    }

    else {
      const chr = pan.charAt(3);
      switch (chr) {
        case 'C':
          deducteeTypeOptions = [{ label: 'Company, other than domestic company', value: 'COMPANY_01' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'COMPANY_01');
          break;
        case 'P':
          deducteeTypeOptions = [{ label: 'Individual', value: 'INDIVIDUAL_02' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'INDIVIDUAL_02');
          break;
        case 'H':
          deducteeTypeOptions = [{ label: 'Hindu Undivided Family', value: 'HUF_03' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'HUF_03');
          break;
        case 'F':
          deducteeTypeOptions = [{ label: 'Firm', value: 'FIRM_07' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'FIRM_07');
          break;
        case 'B':
          deducteeTypeOptions = [{ label: 'Body of individuals', value: 'BOI_08' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'BOI_08');
          break;
        case 'J':
          deducteeTypeOptions = [{ label: 'Artificial juridical person-AJP', value: 'AJP_09' }];
          if (setFieldValue !== undefined) setFieldValue('deductee_type', 'AJP_09');
          break;
        case 'A':
          deducteeTypeOptions = [
            { label: "Association of Persons (AOP)-No Company Member", value: "AOP_04" },
            { label: "Association of Persons (AOP)-Only Company Members", value: "AOP_05" },
            { label: "Co-operative Society", value: "COOP_06" },
          ];
          break;
        default:
          deducteeTypeOptions = DEDUCTEE_TYPE_REGULAR_RETURN;
          if (setFieldValue !== undefined) setFieldValue('deductee_type', '');
      }
    }
    setDeducteeTypes((prev) => {
      prev = deducteeTypeOptions
      return [...prev]
    });
  }

  const panStatusMapping = (pan_status) => {
    const status = pan_status.toLowerCase();
    if (status.includes('valid') && status.includes('inoperat')) {
      return 'Inoperative';
    } else if (status.includes('valid') && status.includes('operat')) {
      return 'Operative';
    } else if (status.includes('invalid')) {
      return 'Invalid';
    } else if (status.includes('not found')) {
      return 'PANNOTAVBL';
    } else {
      return 'Unverified';
    }
  };


  let width1 = document.body.clientWidth;

  const keyPress = useCallback(
    (e) => {
      /* enter */
      if (e.keyCode === 13) {
        e.preventDefault();
        $("#save-deductee").trigger('click');
      }
      /* alt+s */
      if (e.altKey && !e.shiftKey && e.keyCode === 83) {
        e.preventDefault();
        $('#save-deductee').trigger('click');
        $('#save-deductee').attr('disabled', true);
      }
      /* alt+shift+s */
      if (e.altKey && e.shiftKey && e.keyCode === 83) {
        e.preventDefault();
        $('#save-deductee-new').trigger('click');
        $('#save-deductee-new').attr('disabled', true);
      }
      /* Esc */
      if (e.keyCode === 27) {
        $('#close-deductee-new').trigger('click');
      }
    },
    []
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);


  return (
    <>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        onClose={() => { setError(null); }}
      />
      <Formik
        initialValues={deducteeInfo}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={deducteeFormValidationSchema}
        onSubmit={(values) => {
          // same shape as initial values
          createDeductee(values);
          $('#save-deductee, #save-deductee-new').attr('disabled', false);
        }}
      >
        {({
          errors, values, setFieldValue, validateForm, setErrors, resetForm,
        }) => {
          const submitAndResetForm = () => {
            validateForm().then(async (data) => {
              if (Object.keys(data).length > 0) {
                setErrors(data);
              } else {
                const result = await createDeductee(values, false);
                if (result) {
                  resetForm(deducteeFormFields);
                  $(".MyDatePicker input").val('');
                }
              }
              $('#save-deductee, #save-deductee-new').attr('disabled', false);
            });
          };

          if (Object.keys(errors).length > 0) {
            $('#save-deductee, #save-deductee-new').attr('disabled', false);
          }

          return (
            <Form key={1} autoComplete="off" className="createdeductee_eductmaster_modalbox">

              {showSinglePAN ?
                <ConnectorAdapter
                  show={showSinglePAN}
                  handleClose={() => setShowSinglePAN(false)}
                  setPort={setPort}
                  handleProceed={() => setCheckTracesCredentials(true)}
                />
                : null}

              {checkTracesCredentials && port
                ? <TracesConnector
                  deductorId={deductorId}
                  returnId={null}
                  handleProceed={() => {
                    setShowSinglePAN(false);
                    setCheckTracesCredentials(false);
                  }}
                  port={port}
                  setCaptchaRef={setCaptchaRef}
                  setVerifyCreds={() => null}
                  creds={null}
                />
                : null
              }

              {captchaRef && port
                ? <SinglePanVerify
                  deductorId={deductorId}
                  port={port}
                  captchaRef={captchaRef}
                  setError={setError}
                  setVerifyPANResult={setVerifyPANResult}
                  pan={values.pan}
                  setLoading={setLoading}
                  handleProceed={() => null}
                />
                : null
              }

              <ErrorFocus />
              <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ padding: '11px 0px 8px 0px', width: '100%' }}>

                  <div style={{ display: 'flex' }}>
                    <div className="col-md-8" style={{ padding: '2px 0px 0px 0px' }}>

                      <h6 style={{ cursor: 'default', fontSize: '1rem', fontWeight: '500', position: 'relative', top: `${deducteeId ? '3px' : '1px'}`, textAlign: `${deducteeId ? 'center' : 'left'}`, color: deducteeId && applyColour() }} className="createDempfs">
                        {deducteeList && <span style={{ cursor: 'pointer', padding: '1px', fontWeight: '500', fontSize: '14px', position: 'relative', top: '2px', float: 'left' }} onClick={() => prevDeductee()} className="pr-1">&lt; Previous</span>}
                        <span className='' style={{ color: 'black' }}>
                          {deducteeId ? 'Update' : 'Create'}
                          {' '}
                          Deductee/Employee
                        </span>

                      </h6>
                    </div>

                    <div className="col-md-4 text-right">
                      <span className='' style={{ position: 'relative', top: '-2px', cursor: 'pointer', color: deducteeId && applyColour("next") }}>
                        {deducteeList && <span style={{ cursor: 'pointer', fontWeight: '500', fontSize: '14' }} onClick={() => nextDeductee()} className="pl-2">Next &gt;</span>}
                      </span>
                      <span className='updatededuct_togglebtnbox' style={{ paddingLeft: '9px' }}>
                        {deducteeId ?
                          (
                            <>
                              <span className='updatededuct_deactivetextclr'>Deactive</span>
                              <BootstrapSwitchButton
                                checked={!!values.deductee_status}
                                onlabel="&nbsp;"
                                onstyle="outline-success swactinacbtnlistdoutlin"
                                offlabel=" &nbsp;"
                                offstyle="outline-secondary swactinacbtnlistdoutlin"
                                style="swactinacbtnlistd"
                                onChange={() => {
                                  setFieldValue('deductee_status', !values.deductee_status);
                                }}

                              />
                              <span className='updatededuct_activetextclr'>Active</span>
                            </>
                          ) : null}
                      </span>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* </div/> */}
                  {/* </div> */}
                </kendo-dialog-titlebar>}
                onClose={() => onClose()}
                initialHeight={'auto'}
                initialWidth={700}
                initialTop={50}
                totalPopups={1}
                isModalView={true}
                className={'create-deductee-form'}
                // style={{zIndex: 10005}} // z-index use default
                initialLeft={left ?? (width1 - 720)}
              // initialTop={top1 - 20}
              >

                <div className="cdf mt-2">
                  <div className="row">
                    <div className={'col-lg-12'}>
                      <div className="">

                        <div className={'row mt-2'}>
                          <div className="form-group col-md-6 pr-2">
                            <label htmlFor="deductee_name">
                              Deductee/Employee Name
                              <span className="red_text">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control ${errors?.deductee_name ? 'input-error1' : ''}`}
                              id="deductee_name"
                              placeholder="Enter Deductee/Employee Name"
                              name="deductee_name"
                              maxLength={75}
                            />
                            {errors.deductee_name ? <span className="error_message txt-ovrflow" title={errors.deductee_name}><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.deductee_name}</span> :
                              validation_error && validation_error.deductee_name && validation_error.deductee_name.length > 0 ?
                                <span className="error_message txt-ovrflow" title={validation_error.deductee_name}>{validation_error.deductee_name}</span>
                                : null}
                            {values?.name_on_pan && values?.name_on_pan !== "Not Available" ? <span>Name as per PAN : <span style={{ color: 'red' }}>{values?.name_on_pan}</span></span> : null}
                          </div>
                          <div className="form-group col-md-3 pl-2 zero_margin_bottom smmboxml">
                            <label htmlFor="pan">
                              PAN
                              <span className="red_text">*</span>
                            </label>
                            <PANInput
                              value={String(values.pan ?? '').toUpperCase()}
                              onChange={(v) => {
                                setVerifyPANResult(null);
                                setShowSinglePAN(null);
                                setPort(0);
                                setCaptchaRef(null);
                                setFieldValue('pan', v.trim().toUpperCase());
                                resetDeducteeTypeOptions(v, setFieldValue);
                              }}
                              data={['PANAPPLIED', 'PANINVALID', 'PANNOTAVBL']}
                              className={`${errors.pan ? 'input-error1' : ''}`}
                            />
                            {errors.pan ? <span className="error_message txt-ovrflow" title={errors.pan}><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.pan}</span> :
                              validation_error && validation_error.pan && validation_error.pan.length > 0 ?
                                <span className="error_message txt-ovrflow" title={validation_error.pan}>{validation_error.pan}</span>
                                : null}

                            {verifyPANResult
                              ? (
                                <span className="float-right" style={{ fontSize: '11px', color: `#${panStatusMapping(verifyPANResult) === 'Operative' ? '8bc34a' : 'f54337'}` }}>
                                  {panStatusMapping(verifyPANResult)}
                                </span>)
                              : (
                                <div>
                                  {pattPAN.test(values.pan) ? (
                                    <small
                                      className="float-right dark_color_text mt-1 mb-2"
                                      style={{ cursor: 'pointer', fontSize: '11px' }}
                                      onClick={() => verifyTraces()}
                                    >
                                      Verify from TRACES
                                    </small>
                                  ) : null}
                                </div>
                              )}

                          </div>
                          {(values.pan === 'PANAPPLIED' || values.pan === 'PANINVALID' || values.pan === 'PANNOTAVBL') ? (
                            <div className="form-group col-md-3 pl-0">
                              <label htmlFor="ref_number">
                                Ref No.
                              </label>
                              <AlphaNumericInput
                                type="text"
                                className={`form-control ${errors.ref_number ? 'input-error1' : ''}`}
                                id="ref_number"
                                placeholder="Enter Ref No."
                                name="ref_number"
                                value={values.ref_number}
                                maxLength={10}
                                onChange={(e) => {
                                  setFieldValue('ref_number', e.target.value.trim().toUpperCase());
                                }}
                              />
                              {errors.ref_number ? <span className="error_message txt-ovrflow" title={errors.ref_number}><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.ref_number}</span> :
                                validation_error && validation_error.ref_number && validation_error.ref_number.length > 0 ?
                                  <span className="error_message txt-ovrflow" title={validation_error.ref_number}>{validation_error.ref_number}</span>
                                  : null}
                            </div>
                          ) : null}
                        </div>



                        <div className={'row mt-2'}>
                          <div className="form-group col-md-6 smmboxml">
                            <label htmlFor="deductee_type">
                              Deductee Type
                              <span className="red_text">*</span>
                            </label>
                            <Field
                              as="select"
                              className={`form-control f-14 ${errors.deductee_type ? 'input-error1' : ''}`}
                              id="deductee_type"
                              name="deductee_type"
                            >
                              <option value="" disabled>Choose Type</option>
                              {
                                deducteeTypes.map((item) => {
                                  return (
                                    <option value={item?.value}>{item?.label}</option>
                                  )
                                })
                              }
                            </Field>
                            {errors.deductee_type ? <span className="error_message txt-ovrflow" title={errors.deductee_type}><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.deductee_type}</span> :
                              validation_error && validation_error.deductee_type && validation_error.deductee_type.length > 0 ?
                                <span className="error_message txt-ovrflow" title={validation_error.deductee_type}>{validation_error.deductee_type}</span>
                                : null}
                          </div>
                        
                          <div className="form-group col-md-6">
                            <label htmlFor="tax_regime">Tax Regime<span className="red_text">*</span></label>
                            <Field
                              as="select"
                              className={`form-control f-14 ${errors.tax_regime ? 'input-error1' : ''}`}
                              name="tax_regime"
                            >
                              <option value="">Select</option>
                              <option value="OLD">Old</option>
                              <option value="NEW">New</option>
                            </Field>
                            {errors.tax_regime ? <span className="error_message txt-ovrflow" title={errors.tax_regime}><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.tax_regime}</span> :
                              validation_error && validation_error.tax_regime && validation_error.tax_regime.length > 0 ?
                                <span className="error_message txt-ovrflow" title={validation_error.tax_regime}>{validation_error.tax_regime}</span>
                                : null}
                          </div>


                        </div>
                      </div>
                    </div>
                    <div className={'col-lg-12'}>
                      <div className="row pl-3">

                        <div className={'form-group col-md-6 pl-0 smmboxml veticalview'}>
                          <label htmlFor="employee_id">
                            Employee/Deductee ID
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Enter Employee/Deductee ID"
                            name="employee_id"
                            maxLength={10}
                            onChange={(e) => {
                              setFieldValue('employee_id', e.target.value.replace(/([^0-9A-Za-z])$/, ''))
                            }}
                          />
                          {errors.employee_id ? <span className="error_message txt-ovrflow" title={errors.employee_id}>{errors.employee_id}</span> :
                            validation_error && validation_error.employee_id && validation_error.employee_id.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.employee_id}>{validation_error.employee_id}</span>
                              : null}
                        </div>

                        <div className={'form-group col-md-6 pl-0'}>
                          <label htmlFor="dob">
                            Date of Birth
                          </label>
                          <DatePicker
                            selectedDate={values.dob ? values.dob : null}
                            onChange={(value) => {
                              setFieldValue('dob', value);
                              if (value) {
                                setFieldValue('salary_category', '');
                              }
                            }
                            }
                            maxDate={new Date()}
                            placeHolder="Enter DOB"
                          />
                          {errors.dob ?
                            <span className="error_message txt-ovrflow" title={errors.dob}>
                              {errors.dob}
                            </span> :


                            validation_error && validation_error.dob && validation_error.dob.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.dob}>{validation_error.dob}</span>
                              : null}
                        </div>

                        <div className='form-group col-md-6 pl-0'>
                          <label htmlFor="mobile_no">
                            Mobile No.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile_no"
                            placeholder="Enter Mobile No"
                            name="mobile_no"
                            maxLength={15}
                            value={values.mobile_no}
                            onChange={(e) => {
                              setFieldValue('mobile_no', e.target.value.replace(/([^0-9])$/, ''))
                            }}
                          />
                          {errors.mobile_no ? <span className="error_message txt-ovrflow" title={errors.mobile_no}>{errors.mobile_no}</span> :
                            validation_error && validation_error.mobile_no && validation_error.mobile_no.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.mobile_no}>{validation_error.mobile_no}</span>
                              : null}
                        </div>
                        <div className={'form-group col-md-6 pl-0'}>
                          <label htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Enter Email"
                            name="email"
                          />
                          {errors.email ? <span className="error_message txt-ovrflow" title={errors.email}>{errors.email}</span> :
                            validation_error && validation_error.email && validation_error.email.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.email}>{validation_error.email}</span>
                              : null}
                        </div>

                        <div className='form-group col-md-6 pl-0'>
                          <div>
                            <label htmlFor="non_resident">Is Non-Resident?</label>
                            <Field
                              as="select"
                              className="form-control f-14"
                              name="non_resident"
                              value={!!values.non_resident}
                              onChange={() => {
                                setFieldValue('non_resident', !values.non_resident);
                              }}
                            >
                              <option value={false}>No</option>
                              <option value={true}>Yes</option>
                            </Field>
                            {errors.non_resident ? <span className="error_message txt-ovrflow" title={errors.non_resident}>{errors.non_resident}</span> :
                              validation_error && validation_error.non_resident && validation_error.non_resident.length > 0 ?
                                <span className="error_message txt-ovrflow" title={validation_error.non_resident}>{validation_error.non_resident}</span>
                                : null}
                          </div>
                        </div>

                        <div className='form-group col-md-6 pl-0'>
                          <div>
                            <label htmlFor="permanent_establishment">Permanent Establishment in India?</label>
                            <Field
                              as="select"
                              className="form-control f-14"
                              name="permanent_establishment"
                              value={!!values.permanent_establishment}
                              onChange={() => {
                                setFieldValue('permanent_establishment', !values.permanent_establishment);
                              }}
                            >
                              <option value={false}>No</option>
                              <option value={true}>Yes</option>
                            </Field>
                            {errors.permanent_establishment ? <span className="error_message txt-ovrflow" title={errors.permanent_establishment}>{errors.permanent_establishment}</span> :
                              validation_error && validation_error.permanent_establishment && validation_error.permanent_establishment.length > 0 ?
                                <span className="error_message txt-ovrflow" title={validation_error.permanent_establishment}>{validation_error.permanent_establishment}</span>
                                : null}
                          </div>
                        </div>

                        <div className={'form-group col-md-6 pl-0'}>
                          <label htmlFor="salary_category">Category</label>
                          <Field
                            as="select"
                            className="form-control f-14"
                            name="salary_category"
                            disabled={values.dob ? true : false}
                          >
                            <option value="">Choose Category</option>
                            <option value="W">Woman</option>
                            <option value="G">Other</option>
                            <option value="S">Senior Citizen</option>
                            <option value="O"> Super Senior Citizen</option>
                          </Field>
                          {errors.salary_category ? <span className="error_message txt-ovrflow" title={errors.salary_category}>{errors.salary_category}</span> :
                            validation_error && validation_error.salary_category && validation_error.salary_category.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.salary_category}>{validation_error.salary_category}</span>
                              : null}

                        </div>
                        <div className={'form-group col-md-6 pl-0'}>
                          <label htmlFor="address">
                            Address
                          </label>
                          <Field
                            type="uniquecode*"
                            className="form-control"
                            id="address"
                            placeholder="Enter Address"
                            name="address"
                            maxLength={150}
                          />
                          {errors.address ? <span className="error_message txt-ovrflow" title={errors.address}>{errors.address}</span> :
                            validation_error && validation_error.address && validation_error.address.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.address}>{validation_error.address}</span>
                              : null}
                        </div>
                        <div className={'form-group col-md-6 pl-0'}>
                          <label htmlFor="city">City</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="city"
                            placeholder="Enter City"
                            name="city"
                            onKeyDown={(e) => {
                              if (/[0-9]/i.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            maxLength={60}
                          />
                          {errors.city ? <span className="error_message txt-ovrflow" title={errors.city}>{errors.city}</span> :
                            validation_error && validation_error.city && validation_error.city.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.city}>{validation_error.city}</span>
                              : null}
                        </div>

                        <div className={'form-group col-md-6 pl-0'}>
                          <label htmlFor="state_id">
                            State
                          </label>
                          <SingleSelect
                            value={{ label: STATES[values.state_id], value: values.state_id }}
                            onChange={(selected) => {
                              setFieldValue('state_id', selected.value);
                              selected.value && selected.value !== 99 && setFieldValue('country_id', 113);
                              selected.value && selected.value === 99 && setFieldValue('country_id', null);

                            }}
                            allowNumeric={false}
                            options={STATE_LIST}
                            placeholder="Select State"
                          />
                          {errors.state_id ? <span className="error_message txt-ovrflow" title={errors.state_id}>{errors.state_id}</span> :
                            validation_error && validation_error.state_id && validation_error.state_id.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.state_id}>{validation_error.state_id}</span>
                              : null}
                        </div>
                        <div className={'form-group col-md-6 pl-0'}>
                          <label htmlFor="country_id">
                            Country
                          </label>
                          <SingleSelect
                            name="country_id"
                            value={values?.country_id ? COUNTRTY_LIST[getCountryIndex(values.country_id)] : ''}
                            onChange={(selected) => {
                              setFieldValue('country_id', selected.value)
                              selected.value && selected.value !== 113 && setFieldValue('state_id', 99);
                            }}
                            allowNumeric={false}
                            options={values.state_id === 99 || values.state_id === '' ? COUNTRTY_LIST : [COUNTRTY_LIST[111]]}
                            placeholder="Select Country"
                            isDisabled={values?.state_id && values?.state_id !== 99}
                          />
                          {errors.country_id ? <span className="error_message txt-ovrflow" title={errors.country_id}>{errors.country_id}</span> :
                            validation_error && validation_error.country_id && validation_error.country_id.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.country_id}>{validation_error.country_id}</span>
                              : null}
                        </div>

                        <div className={'form-group col-md-6 pl-0'}>
                          <label htmlFor="tax_identification_no">Tax identification Number</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="tax_identification_no"
                            placeholder="Enter Tax Identification No."
                            name="tax_identification_no"
                            maxLength={25}
                            onChange={(e) => {
                              e.target.value.match(/^[a-zA-Z1-9]*$/) && setFieldValue('tax_identification_no', e.target.value)
                            }}
                          />
                          {errors.tax_identification_no ? <span className="error_message txt-ovrflow" title={errors.tax_identification_no}>{errors.tax_identification_no}</span> :
                            validation_error && validation_error.tax_identification_no && validation_error.tax_identification_no.length > 0 ?
                              <span className="error_message txt-ovrflow" title={validation_error.tax_identification_no}>{validation_error.tax_identification_no}</span>
                              : null}
                        </div>
                      </div>
                    </div>
                    <div className={'col-sm-12 sticky_bottom_create_deductee mt-1'}>
                      <button
                        type="submit"
                        className="light_color_bg listdupdeempbtn"
                        style={{ marginRight: '5px' }}
                        id="save-deductee"
                      >
                        Save
                      </button>
                      {!deducteeId ? (
                        <button
                          type="button"
                          className="dark_color_bg listdupdeempbtnblu"
                          style={{ marginRight: '5px', padding: '5px 15px' }}
                          onClick={() => submitAndResetForm()}
                          id="save-deductee-new"
                        >
                          Save and New
                        </button>
                      ) : null}
                      <button
                        type="button"
                        className="light-btn listdupdeempbtngry"
                        onClick={() => onClose()}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="row col-sm-12 text-left" style={{ padding: '0 15px' }}>
                        <div style={{ width: '100%', textAlign: 'left', paddingLeft: '15px' }}>
                          {!deducteeId ? (
                            <small className="hviewhide">
                              Use Alt + S to Save
                              {' '}
                              <br />
                              Use Alt + Shift + S for Save & New
                              <br />
                              Esc to Cancel
                            </small>
                          ) : (
                            <small className="hviewhide">
                              Use Alt + S to Save
                              {' '}
                              <br />
                              Esc to Cancel
                            </small>
                          )}
                        </div>
                      </div>
                  </div>

                </div>
              </WindowWrapperFixed >
            </Form>
          );
        }}
      </Formik>

    </>
  );
}
