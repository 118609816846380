import React, { useState, useEffect } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone, formatAccountsNumber } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";
import moment from "moment";


const LateFiling = (props) => {

    const { apiData } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);

    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    s_no: item[0],
                    due_date: moment(item[1], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    filing_date: moment(item[2], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    no_days: item[3],
                    fee_day: item[4],
                    tot_fee: item[5],
                    sum_tds: item[6],
                    fee_pay: item[7],
                    fee_paid: item[8],
                    bal_fee: item[9],
                })
            })
            return newData
        }
    };

    const dataStateChange = (data_state) => {
        if (data_state.filter || (data_state.sort && data_state?.sort?.length > 0)) {
            const filter_state = deepClone(data_state);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                             if (["gte", "lte", 'lt', 'gt', "neq", "eq"].includes(itemObj?.operator) && !Array.isArray(itemObj.value)) {
                                
                                if(["due_date", "filing_date"].includes(itemObj.field)){
                                    const date = moment(itemObj.value).format('DD/MM/YYYY');
                                    itemObj.value = moment(date, 'DD/MM/YYYY').format();
                                }else{
                                    itemObj.value = Number(itemObj.value);

                                }
                            }    
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                if(!["due_date", "filing_date"].includes(itemObj.field)){                           
                                    itemObj.value = itemObj.value?.[0] ?? ""; 
                                }
                                                              
                            }else  if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                let array = []
                                item.logic = "or"
                                if (itemObj?.value?.length > 0) {
                                    itemObj?.value.forEach((keyValue) => {
                                        if(keyValue === 'NA') {
                                            array.push({ field: itemObj.field, operator: 'isNull', value: null })
                                        }else{
                                            array.push({ field: itemObj.field, operator: 'eq', value: Number(keyValue) })
                                        }                                        
                                    })
                                    item.filters = array;
                                }
                            }                            
                        })
                    }
                })
            }
            const dataProcess = process(inititalData, filter_state);
            setDataState({ ...data_state });
            setData(dataProcess.data ?? []);

        } else {
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData([...defaultData]);
            setDataState({});
        }     

    };



    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const calculateTotal = (index) => {
        let total = 0
        data.length > 0 && data.forEach((item) => {
            switch (index) {
                case "8":
                    total += item?.fee_paid;
                    break;
                case "9":
                    total += item?.bal_fee;
                    break;                
                default:
                    total = 0
            }

        })
        return formatAccountsNumber(total)
    };

    const renderSubTextTitle = (title, subText) => {

        return (
            <div style={{whiteSpace: 'break-spaces'}}>
                {title}
                {subText && (<div className="text-center">
                    {' '}<span className="b2btbtrthtextclr" style={{ paddingLeft: '20px' }}>{subText ?? 0}</span>
                </div>)}
            </div>
        );
    };


    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;
        const dataItem = props?.dataItem ?? {}

        return (
            <>
                <tr key={key} role="row">
                    <td className="text-center ">{dataItem?.s_no}</td>
                    <td className="text-center">{moment(dataItem?.due_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(dataItem?.due_date).format("DD/MM/YYYY") ?? "-"}</td>
                    <td className="text-center">{moment(dataItem?.filing_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(dataItem?.filing_date).format("DD/MM/YYYY") ?? "-"}</td>
                    <td className="text-center">{dataItem?.no_days ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.fee_day ?? 0) ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.tot_fee ?? 0) ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.sum_tds ?? 0) ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.fee_pay ?? 0) ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.fee_paid ?? 0) ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.bal_fee ?? 0) ?? "-"}</td>
                </tr>

                {
                    key + 1 === data.length &&
                    <tr className="tdsjustification_rowshadow">
                        <td className="text-left">Total</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-right">
                            {calculateTotal("8")}
                        </td>
                        <td className="text-right">
                            {calculateTotal("9")}
                        </td>
                    </tr>
                }
            </>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);

    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab22" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">
                    <div className="card">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                            <Tooltip anchorElement="pointer" position="top">
                                <Grid
                                    className="table table-striped exptds_justformtype_table mb-0"
                                    style={{ maxHeight: '530px', overflowX: 'auto' }}
                                    data={data}
                                    {...dataState}

                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    sortable
                                >
                                    <Column
                                        field="s_no"
                                        title={`Sr. No.`}
                                        width="50"
                                    />
                                    <Column
                                        field="due_date"
                                        headerClassName={isColumnActive('due_date') ? 'active' : ''}
                                        title={renderSubTextTitle('Due Date of Filing')}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="date"
                                    />
                                    <Column
                                        field="filing_date"
                                        headerClassName={isColumnActive('filing_date') ? 'active' : ''}
                                        title={`Date of Filing`}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="date"
                                    />
                                    <Column
                                        field="no_days"
                                        headerClassName={isColumnActive('no_days') ? 'active' : ''}
                                        title={renderSubTextTitle('Delay in Filing (No. of Days)')}
                                        width="140"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        field="fee_day"
                                        headerClassName={isColumnActive('fee_day') ? 'active' : ''}
                                        title={`Fee per Day`}
                                        width="120"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        field="tot_fee"
                                        headerClassName={isColumnActive('tot_fee') ? 'active' : ''}
                                        title={`Total Fee`}
                                        width="120"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        field="sum_tds"
                                        headerClassName={isColumnActive('sum_tds') ? 'active' : ''}
                                        title={renderSubTextTitle('Total TDS Deducted as per Statement + Required Tax to be Deducted')}
                                        width="160"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        field="fee_pay"
                                        headerClassName={isColumnActive('fee_pay') ? 'active' : ''}
                                        title={`Fee Payable`}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        field="fee_paid"
                                        headerClassName={isColumnActive('fee_paid') ? 'active' : ''}
                                        title={`Fee Paid`}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />
                                    <Column
                                        field="bal_fee"
                                        headerClassName={isColumnActive('bal_fee') ? 'active' : ''}
                                        title={renderSubTextTitle('Balance Fee to be Paid')}
                                        width="130"
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                        filter="numeric"
                                    />

                                    <GridNoRecords>
                                        No Record Found
                                    </GridNoRecords>

                                </Grid>
                            </Tooltip>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

};

export default LateFiling;