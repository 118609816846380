import React, { useEffect, useState } from 'react'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import ShowMorePagination from '../../../components/pagination/ShowMorePagination'
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../../components/ShowAlert';
import EmailPdfRow from './EmailPdfRow';
import { sendSalaryTdsCalculatorEmailtoDeductee } from '../../../api/salaryAPI';
import { getDeducteeListToSendMail, getDeducteeSummaryToSendMail } from '../../../api/salaryAPI';
import { Spinner } from 'react-bootstrap';
import { AddDeducteeTransactionsIcon } from '../../../components/grid/CustomCell.jsx';
import './emailpdf.css';

const EmailPdfList = (props) => {
    const { deductorId, financial_year = '' } = props
    const location = useLocation();
    const history = useHistory();
    const params = queryString.parse(location.search);
    const [error, setError] = useState(null);
    const [dataState, setDataState] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterPostData, setFilterPostData] = useState({});
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [sendEmailList, setSendEmailList] = useState([]);
    const [port, setPort] = useState(0);
    const [success, setSuccess] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [summary, setSummary] = useState(null);
    const [selectDeducteeList, setSelectDeducteeList] = useState([])
    const [loadingMail, setLoadingMail] = useState(false);

    const fetchEmailDeducteeList = async (postData) => {
        const data = postData
        data.deductor_id = deductorId;
        data.financial_year = financial_year;

        setLoading(true);
        try {
            const result = await getDeducteeListToSendMail(data);
            if (result?.data) {
                if (result.data.cursor.current_page > 1) {
                    setSendEmailList(sendEmailList.concat(result?.data?.data ?? []));
                } else {
                    setSendEmailList(result?.data?.data ?? []);

                }
            }
            setCursor(result.data.cursor);
            setError(null);
        } catch (err) {
            console.error('error: ', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }


    const fetchSummaryTotal = async (postData) => {
        const data = postData
        data.deductor_id = deductorId;
        data.financial_year = financial_year;

        try {
            const result = await getDeducteeSummaryToSendMail(data);
            if (result?.data) {
                setSummary(result?.data ?? {});
            }
            setError(null);
        } catch (err) {
            console.error('error: ', err);
            setError(err.message);
        } finally {
        }
    }

    const getParamsHistoryUrl = () => {
        const requestData = {};
        if (params.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.sort_by ?? 'asc' }];
            requestData.sort_on = params.sort_on;
            requestData.sort_by = params.sort_by;
        }
        dataState.filter = { filters: [] };
        if (params.filters) {
            let frs = JSON.parse(atob(params.filters));
            dataState.filter.filters = frs;
            requestData.filters = JSON.parse(atob(params.filters));
        }
        if (params.page) {
            requestData.page = Number(params.page);
        }
        requestData.limit = params.limit ?? 15;
        setDataState(dataState);
        setFilterPostData(requestData);
        return requestData;
    };

    const createHistoryUrl = (dataState = []) => {
        let filterStr = dataState.page ? `?page=${dataState.page}` : `?page=1`;
        if (params.deductor_id) {
            filterStr += `&deductor_id=${params.deductor_id}`;
        }

        if (params.financial_year) {
            filterStr += `&financial_year=${params.financial_year}`;
        }

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr += `&sort_on=${dataState.sort?.[0]?.field ?? ''}`;
            filterStr += `&sort_by=${dataState.sort?.[0]?.dir ?? ''}`;
        }
        history.push(filterStr);
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        createHistoryUrl(dataState);
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const rowRender = (trElement, props) => {
        return <EmailPdfRow
            trElement={trElement}
            rowProps={props}
            port={port}
            setPort={setPort}
            getParamsHistoryUrl={getParamsHistoryUrl}
            fetchEmailDeducteeList={fetchEmailDeducteeList}
            deductorId={deductorId}
            setSelectDeducteeList={setSelectDeducteeList}
            selectDeducteeList={selectDeducteeList}
            allSelected={allSelected}
        />
    }

    const selectAllfunc = () => {
        if (allSelected) {
            setSelectDeducteeList([])
        }
        setAllSelected((prev) => !prev)
    }

    const sendEmailDeductee = async (deducteeList) => {
        const data = {}
        data.deductor_id = deductorId;
        data.financial_year = financial_year;
        data.deductee_id = deducteeList
        if (deducteeList.length === 0) {
            setError('Please select atleast 1 employee to send email.');
        } else if (deducteeList && deducteeList.length > 0) {
            setLoadingMail(true);
            try {
                let result = await sendSalaryTdsCalculatorEmailtoDeductee(data);
                if (result?.status === 1) {
                    setSelectDeducteeList([])
                    setAllSelected(false)
                    setSuccess(result?.message)
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoadingMail(false);
            }
        }
    }

    useEffect(() => {
        const postData = getParamsHistoryUrl();
        fetchEmailDeducteeList(postData)
        if (!summary) {
            fetchSummaryTotal(postData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])


    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            <div class="col-xl-9 col-lg-7 col-md-7 col-sm-9 col-xs-12">
                <div class="card pt-2 pb-1 px-0">
                    <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <Grid
                            data={sendEmailList}
                            className={`table table-striped`}
                            style={{ height: '400px', overflow: 'auto' }}
                            {...dataState}
                            onDataStateChange={(event) => dataStateChange(event.dataState)}
                            filterOperators={{
                                text: [
                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                ],
                                dropdown: [
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                ],
                                numeric: [
                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                ],
                                date: [
                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                ],
                                textWithEmpty: [
                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                ],
                            }}
                            scrollable
                            editField="inEdit"
                            rowRender={rowRender}
                            sortable
                        >
                            <Column
                                width="65"
                                filter="text"
                                title=""
                                headerCell={(props) => {
                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                            <input style={{ height: '13px !important' }} type="checkbox" checked={allSelected} onChange={() => selectAllfunc()} />
                                        </div>
                                    );
                                }}
                                headerClassName="text-center"
                            />

                            <Column
                                width="250"
                                field="deductee_name"
                                filter="text"
                                headerClassName={isColumnActive('deductee_name') ? 'text-center active' : 'text-center'}
                                title="Employee Name"
                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}

                            />

                            {/* <Column
                                width="150"
                                field="deductee_pan"
                                filter="text"
                                title={<CustomCell title={renderSubTextTitle('Pan', summary?.deductee_pan_count)} field="deductee_pan" dataStateChange={dataStateChange} dataState={dataState} />}
                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                headerClassName={isColumnActive('deductee_pan') ? ' active' : ''}

                            /> */}
                             <Column
                                width={"150"}
                                field={'deductee_pan'}
                                headerClassName={isColumnActive('deductee_pan') ? 'active' : ''}
                                title={<AddDeducteeTransactionsIcon 
                                    field="deductee_pan" 
                                    dataState={dataState}
                                    dataStateChange={dataStateChange}
                                    title='Pan'
                                    subtextData={{ subText: summary?.deductee_pan_count ?? 0, color: summary?.deductee_pan_count_color }}
                                    showButton={false}
                                    headClass={true}
                                    noLeftPadding={true}
                                />}
                                filter="deductee_pan"
                                columnMenu={(props) => <ColumnMenu challanFilter={true} {...props} />}
                                sortable={false}
                            />

                            <Column
                                width={"250"}
                                field={'email'}
                                headerClassName={isColumnActive('email') ? 'active' : ''}
                                title={<AddDeducteeTransactionsIcon field="email" dataState={dataState}
                                    dataStateChange={dataStateChange}
                                    title='Email Address'
                                    subtextData={{ subText: summary?.email_count ?? 0, color: summary?.email_count_color }}
                                    showButton={false}
                                    headClass={true}
                                    //summaryColumnSkelton={summaryColumnSkelton}
                                />}
                                filter="email"
                                columnMenu={(props) => <ColumnMenu challanFilter={true} {...props} />}
                                sortable={false}
                            />



                            {/* <Column
                                    width="200"
                                    field="mobile_no"
                                    filter="numeric"
                                    title={<CustomCell title={renderSubTextTitle('Mobile No.', summary?.mobile_no_count)} field="email" dataStateChange={dataStateChange} dataState={dataState} />}
                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                    headerClassName={isColumnActive('mobile_no') ? ' active' : ''}

                                /> */}

                        </Grid>


                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="btn btn-defualt sendmailpdf_btn ml-2" onClick={() => sendEmailDeductee(selectDeducteeList)} disabled={false}>
                                {loadingMail ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Sending...
                                    </>
                                ) : 'Send Email'}

                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            {cursor.next_page && (
                                <tr className="showmoretrbgclr" >
                                    <td colSpan="4" className="text-center">
                                        <ShowMorePagination
                                            cursor={cursor}
                                            fetchData={fetchEmailDeducteeList}
                                            postData={filterPostData}
                                            loading={false}
                                        />
                                    </td>
                                </tr>
                            )}
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default EmailPdfList