import React from 'react'
import { formatNumber } from '../../../utils/UtilityFunctions';
import ViewAllowancesDetails from './ViewAllowancesDetails';
import ViewAllowancesLite from './ViewAllowancesLite';
import ViewRetireBenfitDetails from './ViewRetireBenfitDetails';
import ViewRetireBenfitLite from './ViewRetireBenfitLite';

const ViewSalary17one = (props) => {
    const { data, headingCodeLIst17one, USER_TYPE, taxRegime, headingCodeListAllowance, calcIcon,
        headingCodeListRetirbenfit } = props;

    return (
        <>
            <tr className="">
                <th width="10" id="accordion" className="accordion">
                    <span className="salarytaxregime_edit_collapse " data-toggle="collapse" href="#salarytaxregime_edit_collap01" aria-expanded="true"></span>
                </th>
                <th width="550" className="text-left">
                    Salary 17(1)
                </th>
                <th width="150" className="text-right">
                    {formatNumber(data.salary_amtp ?? 0)}
                </th>

            </tr>

            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_collap01" className="in collapse show">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>
                                    <tr>
                                        <td width="46"></td>
                                        <td width="550" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop"></td>
                                        <td width="150" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_bordertop"></td>

                                    </tr>
                                    {data?.salary_breakup_amtp?.details?.length > 0 &&
                                        data?.salary_breakup_amtp?.details.map((item, index) => {
                                            return (
                                                <tr className="">
                                                    <td width="45"></td>
                                                    <td width="550" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft">
                                                        {headingCodeLIst17one?.get(item?.particular_code)?.description ?? ""}
                                                    </td>
                                                    <td width="150" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright text-left">
                                                        {formatNumber(item.amount_amtp ?? 0)}
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    {USER_TYPE === "Details" &&
                                        <ViewAllowancesDetails data={data} headingCodeListAllowance={headingCodeListAllowance} calcIcon={calcIcon} />
                                    }

                                    {USER_TYPE === "Lite" && taxRegime === "OLD" &&
                                        <ViewAllowancesLite data={data} headingCodeListAllowance={headingCodeListAllowance} calcIcon={calcIcon} />
                                    }

                                    {USER_TYPE === "Details" &&
                                        <ViewRetireBenfitDetails data={data} headingCodeListRetirbenfit={headingCodeListRetirbenfit} calcIcon={calcIcon} />
                                    }

                                    {USER_TYPE === "Lite" &&
                                        <ViewRetireBenfitLite data={data} headingCodeListRetirbenfit={headingCodeListRetirbenfit} calcIcon={calcIcon} />
                                    }
                                    <tr>
                                        <td width="45"></td>
                                        <td width="550" className="  salarytaxreg_innertb_bordertop"></td>
                                        <td width="150" className="  salarytaxreg_innertb_bordertop"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewSalary17one