/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
    Formik, Form,
} from 'formik';
import { MONTH_LIST } from '../../app/constants';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import { houseRentMonthlyDataValidationSchema } from './validationSchema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRupeeSign
} from '@fortawesome/free-solid-svg-icons';
import { getHouseRentMonthExemptAmt } from './allowancesCalculationScema';

const HouseRentMonthForm = (props) => {

    const { houseRentData, saveData, totalMonthly, setTotalMonthly } = props;


    useEffect(() => {

        let total_monthly = 0;
        Object.keys(houseRentData.details).map((index) => {
            total_monthly = parseFloat(total_monthly) + parseFloat(houseRentData.details[index].exempt_amtp);
            return true;
        });
        setTotalMonthly(total_monthly.toFixed(2));

    }, [houseRentData]);

    const calculateExemptAmt = (field, value, key, values, setFieldValue) => {

        let old_value = values.details[key][field];
        values.details[key][field] = value;

        let exempt_amtp = getHouseRentMonthExemptAmt(values, key);

        setFieldValue(`details[${key}].exempt_amtp`, exempt_amtp);
        values.details[key][field] = old_value;

        let total_monthly = exempt_amtp;
        Object.keys(values.details).map((index) => {
            if (key !== index) {
                total_monthly = parseFloat(total_monthly) + parseFloat(values.details[index].exempt_amtp);
            }
            return true;
        });
        setTotalMonthly(total_monthly.toFixed(2));
    }


    return (<Formik
        initialValues={houseRentData}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={houseRentMonthlyDataValidationSchema}
        onSubmit={(values) => {
            const submitValues = { ...values };
            saveData(submitValues);
        }}
    >
        {({
            errors, values, setFieldValue,
        }) => {
            if (Object.keys(errors).length > 0) {
            }
            return (
                <Form key={1} autoComplete="off">
                    <div className="row">
                        <div className="col-md-12 pt-1">
                            <div className="card allowance">
                                <div className="table-responsive">
                                    <table className="table table-striped monthWSDTB houserenttbinputheight">
                                        <thead>
                                            <tr>
                                                <th>Month</th>
                                                <th>Salary</th>
                                                <th>H.R.A Received</th>
                                                <th>Rent Paid</th>
                                                <th>Metro City</th>
                                                <th>H.R.A Exempt</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {MONTH_LIST.map((month, index) => {
                                                let month_id = month.value;
                                                return (
                                                    < tr >
                                                        <td>{month.label}</td>
                                                        <td>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                className={errors.details && errors.details[month.value] && errors.details[month.value].salary_amtp ? "form-control error_input" : "form-control"}
                                                                style={{ width: '80px' }}
                                                                placeholder="0.00"
                                                                value={values.details[`${month.value}`].salary_amtp}
                                                                onChange={(e) => {
                                                                    setFieldValue(`details[${month_id}].salary_amtp`, e.target.value);
                                                                    calculateExemptAmt('salary_amtp', e.target.value, month_id, values, setFieldValue);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                className={errors.details && errors.details[month.value] && errors.details[month.value].hra_received_amtp ? "form-control error_input" : "form-control"}
                                                                style={{ width: '80px' }}
                                                                placeholder="0.00"
                                                                value={values.details[`${month.value}`].hra_received_amtp}
                                                                onChange={(e) => {
                                                                    setFieldValue(`details[${month_id}].hra_received_amtp`, e.target.value);
                                                                    calculateExemptAmt('hra_received_amtp', e.target.value, month_id, values, setFieldValue);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                className={errors.details && errors.details[month.value] && errors.details[month.value].rent_paid_amtp ? "form-control error_input" : "form-control"}
                                                                style={{ width: '80px' }}
                                                                placeholder="0.00"
                                                                value={values.details[`${month.value}`].rent_paid_amtp}
                                                                onChange={(e) => {
                                                                    setFieldValue(`details[${month_id}].rent_paid_amtp`, e.target.value);
                                                                    calculateExemptAmt('rent_paid_amtp', e.target.value, month_id, values, setFieldValue);
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                type="checkbox"
                                                                value="0"
                                                                name="whether_metro_city"
                                                                checked={parseInt(values.details[`${month.value}`].whether_metro_city) ? true : false}
                                                                onChange={(e) => {
                                                                    let whether_metro_city = parseInt(values.details[month_id].whether_metro_city) ? 0 : 1
                                                                    setFieldValue(`details[${month_id}].whether_metro_city`, whether_metro_city);
                                                                    calculateExemptAmt('whether_metro_city', whether_metro_city, month_id, values, setFieldValue);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                style={{ width: '80px' }}
                                                                className={errors.details && errors.details[month.value] && errors.details[month.value].exempt_amtp ? "form-control error_input" : "form-control"}
                                                                placeholder="0.00"
                                                                value={values.details[`${month.value}`].exempt_amtp}
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="float-right">
                                        <span style={{ fontSize: '13px', margin: '10px' }}>TOTAL</span>
                                        <span className="pr-1  fsizeiconrup"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                        {totalMonthly}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="btn-group mb-1 mt-1">
                                <button type="submit" className="btn btn-default monthwsdetilssavebtn">Save & Exit</button>
                            </div>
                        </div>
                    </div>
                </Form>
            );
        }}
    </Formik>);
};

export default HouseRentMonthForm;