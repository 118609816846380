import React, { useEffect, useState } from 'react'
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';

const SelfOccupied = (props) => {
    const { onClose, parentPopupWidth, info, mainData, updateEmployeeDetail, onlyView=false } = props;
    const defaultData = {
        'construction_amtp': 0,
        'repair_renewal_extension_amtp': 0,
    }
    const ConstructionLimit = 200000;
    const RepairssLimit = 30000;

    const width1 = document.body.clientWidth;
    const popupWidth = 500;
    const additionalGap = 10;
    const initialLeft = width1 - (parentPopupWidth + (popupWidth + additionalGap));

    const [selfOccupied, setSelfOccupied] = useState({});
    const [formError, setFormError] = useState({});

    const calculate_self_occupied = (field, value) => {

        let new_selfOccupiedBreakup = selfOccupied;
        new_selfOccupiedBreakup[field] = value;

        // let self_occupied_amtp = (parseFloat(new_selfOccupiedBreakup.construction_amtp ? new_selfOccupiedBreakup.construction_amtp : 0) + parseFloat(new_selfOccupiedBreakup.repair_renewal_extension_amtp ? new_selfOccupiedBreakup.repair_renewal_extension_amtp : 0)).toFixed(2);
        setSelfOccupied({...new_selfOccupiedBreakup});
    }
    const saveSelfOccpied = () => {
        let validation = true;
        const err = {};
        if (selfOccupied.construction_amtp > ConstructionLimit) {
            err.construction_amtp = "Interest on Borrowed Capital for Construction cannot be more than Rs. 2,00,000.00";
            validation = false;
        }

        if (selfOccupied.repair_renewal_extension_amtp > RepairssLimit) {
            err.repair_renewal_extension_amtp = "Interest on Borrowed Capital for Repair/Renewal/Extension cannot be more than Rs. 30,000";
            validation = false;
        }
        if (validation) {
            mainData.self_occupied_breakup = selfOccupied;
            updateEmployeeDetail(mainData, onClose)
        } else {
            setFormError({ ...err });
        }

    }

    useEffect(() => {
        const data = info.poppupData ?? defaultData;
        setSelfOccupied({ ...data });
        //eslint-disable-next-line
    }, [info])

    return (
        <>
            <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ padding: '8px 0px 3px 0px', width: "1000%" }}>
                    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <span className="taxregim_textfs pr-3">
                                Self Occupied
                            </span>
                        </div>
                    </div>
                </kendo-dialog-titlebar>}
                onClose={onClose}
                initialHeight={'auto'}
                initialWidth={popupWidth}
                initialTop={50}
                totalPopups={1}
                isModalView={true}
                initialLeft={initialLeft}
                className={'salarary_detail_popup'}
            >
                <div className="modal-body pb-2 pt-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card salary_taxreg_allowance">
                                <div className="form form-row salary_taxreg_allowance_form">
                                    <div className="form-group col-md-8">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Interest on Borrowed Capital for Construction</label>
                                    </div>

                                    <div className="form-group col-md-4">
                                        <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.construction_amtp ? "input-error" : ""}`}
                                            placeholder="" data-toggle="tooltip"
                                            data-placement="right" data-original-title={formError?.construction_amtp ? formError?.construction_amtp : ""}
                                            value={FormatNumberDecimal(selfOccupied.construction_amtp, ...Array(2), false)}
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                calculate_self_occupied("construction_amtp", value);
                                            }}
                                            disabled={onlyView}
                                        />
                                    </div>

                                    <div className="form-group col-md-8">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Interest on Borrowed Capital for Repairs/renewals</label>
                                    </div>

                                    <div className="form-group col-md-4">
                                        <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.repair_renewal_extension_amtp ? "input-error" : ""}`}
                                            placeholder="" data-toggle="tooltip"
                                            data-placement="right" data-original-title={formError?.repair_renewal_extension_amtp ? formError?.repair_renewal_extension_amtp : ""}
                                            value={FormatNumberDecimal(selfOccupied.repair_renewal_extension_amtp, ...Array(2), false)}
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                calculate_self_occupied("repair_renewal_extension_amtp", value);
                                            }}
                                            disabled={onlyView}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 pt-2 text-center">
                            <button className="btn btn-default salary_taxreg_allowance_savebtn" disabled={onlyView}
                                onClick={() => saveSelfOccpied()}
                            >Save & Exit</button>
                        </div>
                    </div>
                </div>
            </WindowWrapperFixed>
        </>
    )
}

export default SelfOccupied