import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDeductorInsightsCredentialsForConnector } from '../../api/deductorAPI';
import './KDKConnector.css';
import { selectHeaderData } from '../../features/header/headerSlice';
import ShowAlert from '../ShowAlert';
import UpdateInsightsCredential from '../verifyPAN/UpdateInsightsCredential';
import { HEADER_CONNECTOR_DIGEST_KEY, CONNECTOR_ERRORS_MAPPING } from '../../app/constants';
import { verifyInsightsLoginFromConnector } from '../../api/localConnectorAPI';

export default function InsightsConnector({ history, ...props }) {

  const { handleProceed,
    deductorId,
    returnId,
    port,
    setInsightsCaptchaRef,
    creds = null,
    setVerifyCreds,
    tan = null,
    setLoading,
    handleClose,
  } = props;


  const headerDataDetails = useSelector(selectHeaderData);
  const [error, setError] = useState(false);
  const [showInsightsValidationFailPopup, setShowInsightsValidationFailPopup] = useState(false);
  const insightTan = '';
  const insightsUser = '';
  const insightsPassword = '';

  useEffect(() => {
    const fetchTracesCredentials = (deductorId) => {
      async function fetchEverything() {
        try {
          // Dont update Header, as it is already updated by rest of the API. This component is supposed 
          // to work for both Regular and Correction return, and hence header update will mess display for Correction.
          const serverApiResult = await getDeductorInsightsCredentialsForConnector(`?deductor_id=${deductorId}`, false);
          setLoading(true);

          if (parseInt(serverApiResult?.status ?? 0) === 200) {
            const EncryptLoginData = serverApiResult?.data
            const EncryptLoginDataHeader = serverApiResult?.headers ?? null;
            const connectorRequest = {
              "data": EncryptLoginData?.data
            };
            let extraHeader = {};
            extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = EncryptLoginDataHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

            try {

              const connectorResult = await verifyInsightsLoginFromConnector(port, connectorRequest, extraHeader);
              setInsightsCaptchaRef(connectorResult?.data?.captcha_ref);
              handleProceed(connectorResult?.data?.captcha_ref);

            } catch (err) {
              console.log('Connector Error', err.message);
              const errorList = err.message;
              if (errorList.includes('insights.invalid_credentials')) {
                setShowInsightsValidationFailPopup(true);
              } else {
                if(err?.message?.[0] !== undefined) {
                  setError(CONNECTOR_ERRORS_MAPPING[err?.message[0]] ?? CONNECTOR_ERRORS_MAPPING['insights.unknown_error']);
                }else{
                  setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['insights.unknown_error']);
                }                
              }

              if(handleClose !== undefined) {
                handleClose();
              }
            }

          }

        } catch (err) {
          console.error('error: ', err);
          if (err.code === 404) {
            setShowInsightsValidationFailPopup(true);
          } else {
            setError(err.message);
          }
        } finally {
          //handleClose();
          setLoading(false);
        }
      }

      fetchEverything();
    };

    fetchTracesCredentials(deductorId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductorId, port, creds, headerDataDetails, tan]);

  return (
    <>
      {error &&
        <ShowAlert
          error={error}
          onClose={() => setError(null)}
        />
      }

      {showInsightsValidationFailPopup &&
        <UpdateInsightsCredential
          show={showInsightsValidationFailPopup}
          handleClose={() => {
            setError(null);
            setShowInsightsValidationFailPopup(false);
            handleProceed();
          }}
          deductorId={deductorId}
          returnId={returnId}
          tan={insightTan}
          insightsUser={insightsUser}
          insightsPassword={insightsPassword}
          handleProceed={handleProceed}
          showKDKConnector={() => { }}
          port={port}
          setInsightsCaptchaRef={setInsightsCaptchaRef}
          setVerifyCreds={setVerifyCreds}
        />
      }

    </>

  )
}
