import React, { useEffect, useState } from 'react'
import { checkRaiseRequestFromConnector, gocallApi } from '../../api/issueCertificateAPI'
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter'
import TracesConnector from '../../components/kdkConnector/TracesConnector'
import { HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator'
import ShowAlert from '../../components/ShowAlert'
import moment from 'moment/moment'
const PendingStatusTableRow = React.memo((props) => {

    const { trElement, rowProps, port, setPort, getParamsHistoryUrl, fetchPendingRequestData } = props
    const [localState, setLocalState] = useState({})
    const [verifyTraceCredentials, setVerifyTraceCredentials] = useState(false)
    const [captchaRef, setCaptchaRef] = useState(null);
    const [credentialAvailable, setCredentialAvailable] = useState(false)
    const [isConnectorRunning, setIsConnectorRunning] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    const rowArray = [
        { key: "deductor_name" },
        { key: "tan" },
        { key: "deductor_type" },
        { key: "request_raised_on" },
        { key: "update_button" },
    ]

    const callStatusCheck = async () => {
        setLoading(true)
        try {
            const url = `/api/v1/usuc/traces/pending-raise-request`
            const result = await gocallApi("post", 
            url, 
            { deductor_id: localState?.deductor_id, captcha_ref: captchaRef, },
            true);
            const responseData = result?.data ?? null;
            const responseHeader = result?.headers ?? null;
            const connectorRequest = {
                "data": responseData?.data
            };
            let extraHeader = {};
            extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

            try {
                const connectorResponse = await checkRaiseRequestFromConnector(port, connectorRequest, extraHeader);
                if (Number(connectorResponse.status) === 1) {
                    setTimeout(async () => {
                        setSuccess(String(connectorResponse?.message).length > 0 ? connectorResponse?.message : "Status has been updated successfully. Those Requests which are available can be downloaded from the particular deductor");
                        const postData = getParamsHistoryUrl();
                        await fetchPendingRequestData(postData);
                        setLoading(false);
                    }, 10000);

                }
            } catch (err) {
                // setError(err.message);
                console.error(err)
            } finally {
                setLoading(true);
            }

        } catch (err) {
            setError(err);
        } finally {
            // setLoading(false);
        }
    };

    //check  raise request status
    const checkStatus = async () => {
        if (credentialAvailable) {
            setVerifyTraceCredentials(true)
            return null;
        }
        setIsConnectorRunning(true);
    };



    useEffect(() => {
        setLocalState(() => {
            return { ...rowProps.dataItem }
        })
    }, [rowProps.dataItem])



    return (

        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            {isConnectorRunning
                ? <ConnectorAdapter
                    show={isConnectorRunning}
                    handleClose={() => {
                        setIsConnectorRunning(false);
                    }}
                    setPort={setPort}
                    handleProceed={() => {
                        setVerifyTraceCredentials(true);
                    }}
                />
                : null
            }

            {verifyTraceCredentials && port
                ?
                <>
                    <TracesConnector
                        deductorId={localState?.deductor_id}
                        returnId={null}
                        handleProceed={() => {
                            setIsConnectorRunning(false);
                            setVerifyTraceCredentials(false);
                        }}
                        port={port}
                        setVerifyCreds={() => {
                            setCredentialAvailable(true)
                            callStatusCheck();
                        }}
                        creds={null}
                        setCaptchaRef={setCaptchaRef}
                        callCaptchaRef={true}
                        tan={localState?.tan}

                    />
                </>
                : null
            }

            <tr className={` ${trElement?.props['aria-rowindex'] % 2 === 0 ? 'white_background' : ''}`}>
                {rowArray.map((item, index) => {
                    if (item?.key === 'deductor_name') {
                        return (
                            <td class="tds_listofdeducted_tb_overflowtext" width="250">
                                <span data-toggle="tooltip" data-placement="top" title={localState?.deductor_name}>{localState?.deductor_name}</span>
                            </td>)
                    } else if (item?.key === 'tan') {
                        return <td className="text-center" width="150">{localState?.tan}</td>
                    } else if (item?.key === 'deductor_type') {
                        return (
                            <td class="tds_listofdeducted_tb_overflowtext" width="150">
                                <span data-toggle="tooltip" data-placement="top" title={localState?.deductor_type}>
                                    {localState?.deductor_type}
                                </span>
                            </td>)
                    } else if (item?.key === 'request_raised_on') {
                        return <td className="text-center" width="150">{moment(localState?.request_raised_on).format('DD/MM/YYYY, h:mm a')}</td>
                    } else if (item?.key === 'update_button') {
                        return (
                            <td class="text-center" width="200">
                                <button class="btn btn-default pending_updatest_btncglr" onClick={() => checkStatus()}>Update Status</button>
                            </td>
                        )
                    }
                    return <></>
                })
                }

            </tr>

        </>


    )
})

export default PendingStatusTableRow