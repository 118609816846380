import jwt from 'jsonwebtoken';

const authTokenLocalStorageKey = "authToken";

export function getAuthToken(failIfInvalidToken) {
    let token = ""
    try {
        token = localStorage.getItem(authTokenLocalStorageKey)
        if (token === null || token === undefined || String(token).trim() === "") {
            token = ""
        } else {
            // Now, check if the token is well-formed (it does not check for validity though)
            const decodedToken = jwt.decode(token, { complete: true });
            if (!decodedToken || !decodedToken.payload) {
                token = ""
            }
        }
    } catch {
        token = ""
    }

    if (!token && failIfInvalidToken) {
        // Simulate unauthorized response from server
        throw new Error("Unauthorized access! Please login with your credentials.")
    }

    return token;
}

export function updateAuthToken(token) {
    localStorage.setItem(authTokenLocalStorageKey, String(token))
}

export function clearAuthToken() {
    localStorage.removeItem(authTokenLocalStorageKey)
}