import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getTracesCaptchaFromConnector, verifyTracesLoginFromConnector } from '../../api/localConnectorAPI';
import { BASE_PATH, CONNECTOR_ERRORS_MAPPING, DASHBOARD_PATH } from '../../app/constants';
//import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../features/header/headerSlice';
import { useLocation, useHistory } from 'react-router-dom';


const VerifyPANCloud = ({ history, ...props }) => {
  const {
    show, handleClose, handleProceed, tan, port, tracesPassword = '', tracesUser = '',
    setShowTraceValidationFailPopup, setShowTracesCaptcha, setCaptchaRef, setVerifyCreds, dontLogin, callCaptchaRef, latestSyncStatus
  } = props;

  const headerDataDetails = useSelector(selectHeaderData);
  const location = useLocation();
  const historic = useHistory();
  const pathName = location.pathname;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captchaImg, setCaptchaImg] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [cookies, setCookies] = useState(null);
  const [formErrors, setFormErrors] = useState(null);

  const getCaptcha = (port) => {
    async function fetchEverything() {
      try {
        const result = await getTracesCaptchaFromConnector(port);
        setCaptchaImg(result.data.captcha_image);
        setCookies(result.data.captcha_ref);
        if (callCaptchaRef) setCaptchaRef(result.data.captcha_ref);

        //setError(null);
      } catch (err) {
        console.error('error: ', err);
        if (err?.message?.[0] !== undefined) {
          setError(CONNECTOR_ERRORS_MAPPING[err?.message[0]] ?? CONNECTOR_ERRORS_MAPPING['traces.unknown_error']);
        } else {
          setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['traces.unknown_error']);
        }
      } finally {
        setLoading(false);
        setCaptcha('');
      }
    }
    setLoading(true);
    fetchEverything();
  };


  const verifyCatpcha = () => {
    if (captcha.length === 0) {
      setFormErrors("Captcha is required")
      return;
    }

    setError(null);

    const request = {
      "tan": tan,
      "traces_username": tracesUser,
      "traces_password": tracesPassword,
      "captcha_text": captcha,
      "captcha_ref": cookies
    };

    async function fetchEverything() {
      try {
        const result = await verifyTracesLoginFromConnector(port, request);
        if (result.status === 1) {

          if (setCaptchaRef) setCaptchaRef(cookies);
          if (handleProceed) handleProceed(result);
          if (handleClose) handleClose();
          if (setVerifyCreds) setVerifyCreds(result.message);

        }
      } catch (err) {
        setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
        getCaptcha(port)
        if (err?.message?.includes('invalid_credentials') || err?.message?.includes('traces.invalid_credentials')) {
          setShowTracesCaptcha(false);
          setShowTraceValidationFailPopup(true);
        }

      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const closeModel = () => {
    handleClose();

    if (headerDataDetails?.tan && headerDataDetails?.mobile_number && pathName === DASHBOARD_PATH) {
      // let InsightsGuide = JSON.parse(localStorage.getItem('Insights-guide'));
      // const userMobile = headerDataDetails?.mobile_number;
      // const userTan = headerDataDetails?.tan;      
      //if (!InsightsGuide || !InsightsGuide[userMobile] || !InsightsGuide[userMobile].includes(userTan)) {   
      if (latestSyncStatus?.sync_status === null) {
        //Deductor not exist in insights guide
        historic.push(`${BASE_PATH}list/recent`);
      }
    }
  }

  useEffect(() => {
    if (dontLogin && String(tracesPassword).length >= 0 && String(tracesUser).length >= 0) {
      setVerifyCreds()
    } else {
      getCaptcha(port);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [port, dontLogin, tracesPassword, tracesUser]);


  return (
    <>
      {(!dontLogin || String(tracesPassword).length === 0 || String(tracesUser).length === 0) &&
        <Modal
          show={show}
          onHide={closeModel}
          backdrop="static"
          keyboard={false}
          className="tracescredentials"
        >
          {loading ? <LoadingIndicator /> : null}
          <Modal.Header closeButton className="mpoptextbor">
            <Modal.Title className="panvermpopuhtit">Login At Traces </Modal.Title>
          </Modal.Header>

          {/* <ShowAlert error={error} onClose={() => setError(null)} /> */}

          <Modal.Body className="panvermpopubody">
            <div className='form-group col-md-12'>
              {error
                ?
                <span style={{ color: 'red', fontSize: '12px' }}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  {' '}
                  {error}
                </span>
                : null
              }
            </div>
            <div className="container mt-2">

              <div className="card" style={{ border: "0px" }}>
                <div className="row ">

                  <div className="form-group col-md-12 mb-0">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4 mb-0" style={{ marginTop: "5px", marginBottom: "0px" }}>
                          Captcha
                        </label>
                        <div className="captcha_img verifypantraces" style={{ marginTop: "4px" }}><img src={`data:image/png;base64, ${captchaImg}`} alt="" /></div>
                      </div>
                      <div className="form-group col-md-6" style={{ marginTop: '5px' }}>
                        <label htmlFor="captcha mb-1" >
                          Enter Captcha
                          <span className="red_text">*</span>
                          <input
                            type="text"
                            className="form-control"
                            id="captcha"
                            placeholder="Enter Captcha"
                            value={captcha}
                            onChange={(event) => {
                              setCaptcha(event.target.value)
                              setFormErrors(null)
                            }}
                            style={{ marginTop: '4px' }}
                          />
                          {formErrors ? <span className="error_message">{formErrors}</span> : null}
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-2">
                      <p style={{ marginBottom: "0rem", fontSize: "12px" }}>
                        <small>
                          Can't read the captcha?
                          <Button variant="link" onClick={() => getCaptcha(port)} style={{ margin: '-9px', fontSize: 12 }}>Click here</Button>
                          to refresh
                        </small>
                      </p>
                    </div>



                  </div>
                </div>
              </div>
              <div className="col-md-12 panvermpopufoot verirypancloudbtn">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={verifyCatpcha} disabled={loading}>
                  Proceed
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }

    </>


  );
};

export default VerifyPANCloud;
