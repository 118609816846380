import React, { useEffect, useState } from 'react'
import ShowAlert from '../../../components/ShowAlert'
import { updateDeductee } from '../../../api/deducteeAPI'
import $ from 'jquery';

const EmailPdfRow = React.memo((props) => {
    const { trElement, rowProps, deductorId, setSelectDeducteeList, selectDeducteeList, allSelected } = props
    const [localState, setLocalState] = useState({})
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [emailError, setEmailError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const rowArray = [
        { key: "checkbox" },
        { key: "deductee_name" },
        { key: "deductee_pan" },
        { key: "email" },
        //{ key: "mobile_no" },
    ]

    const selectRow = (deducteeId) => {
        const index = selectDeducteeList.findIndex((item) => item === deducteeId)
        if (index > -1) {
            selectDeducteeList.splice(index, 1)
        } else selectDeducteeList.push(deducteeId)
        setSelectDeducteeList(() =>
            [...selectDeducteeList]
        )

    }
    const updateDeducteeFun = async (name, value) => {
        const dataPost = {};
        dataPost.deductee_id = localState.deductee_id;
        dataPost.deductor_id = deductorId;
        dataPost[name] = value;
        if (String(value).length === 0 || localState[name] === value) {
            return null
        }
        if (name === "email") {
            if (value !== '' && !/^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
                setEmailError(true)
                return 'Invalid Email Address!';
            } setEmailError(false)
        }
        else if (name === "mobile_no") {
            if (value !== '' && !/^[6-9]\d{9}$/.test(value)) {
                setMobileError(true)
                return 'Invalid Mobile Number!';
            } setMobileError(false)

        }

        async function callUpdatePanNameAPI() {
            try {
                const result = await updateDeductee(dataPost);
                if (result?.data) {
                    setLocalState((prev) => {
                        prev[name] = value
                        return { ...prev }
                    })
                    setSuccess("Data has Been updated successfully")
                    setTimeout(() => {
                        window.location.reload();
                    },1000)
                }
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err);
            }
        }
        callUpdatePanNameAPI();
    }


    useEffect(() => {
        setLocalState(() => {
            return { ...rowProps.dataItem }
        })
    }, [rowProps.dataItem])

    useEffect(() => {
        if (allSelected && localState?.email) {
            setSelectDeducteeList((prev) => {
                if (!prev.includes(localState?.deductee_id)) {
                    prev.push(localState?.deductee_id)
                }
                return [...prev]
            })
        }
    }, [allSelected, localState, setSelectDeducteeList])

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })


    return (

        <>
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />
            <tr className={` ${trElement?.props['aria-rowindex'] % 2 === 0 ? 'white_background' : ''}`}>
                {rowArray.map((item, index) => {
                    if (item?.key === 'checkbox') {
                        return (
                            <td class="tds_listofdeducted_tb_overflowtext" width="20">
                                <input type="checkbox" disabled={(localState?.email && !emailError) ? false : true} tabIndex={-1}
                                    checked={(localState.deductee_id && selectDeducteeList.includes(localState.deductee_id)) ? true : false}
                                    onChange={() => selectRow(localState.deductee_id)}
                                />  </td>)
                    }
                    else if (item?.key === 'deductee_name') {
                        return (
                            <td class="tds_listofdeducted_tb_overflowtext" width="250">
                                <span data-toggle="tooltip" data-placement="top" title={localState?.deductee_name}>{localState?.deductee_name}</span>
                            </td>)
                    } else if (item?.key === 'deductee_pan') {
                        return <td className="tds_listofdeducted_tb_overflowtext text-center" width="150">{localState?.deductee_pan}</td>
                    } else if (item?.key === 'email') {
                        return (
                            <td class="tds_listofdeducted_tb_overflowtext " width="250" data-toggle='tooltip' data-original-title={emailError ? "Email Address is not Valid" : ""} >
                                <input minLength={1} maxLength={100} defaultValue={localState?.email} className={`input_has_value ${emailError ? "input-error" : ""} `} name="email" onBlur={(e) => updateDeducteeFun(e.target.name, e.target.value)}
                                />
                            </td>)
                    } else if (item?.key === 'mobile_no') {
                        return <td className="tds_listofdeducted_tb_overflowtext text-center" width="200" data-toggle='tooltip' data-original-title={mobileError ? "Mobile No is not Valid" : ""} >
                            <input minLength={5} maxLength={15} defaultValue={localState?.mobile_no} className={`text-center input_has_value ${mobileError ? "input-error" : ""} `}  name="mobile_no" onBlur={(e) => updateDeducteeFun(e.target.name, e.target.value)} />
                        </td>
                    }
                    return <></>
                })
                }

            </tr>
        </>


    )
})

export default EmailPdfRow