/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CreateDeducteeForm from '../../components/createDeducteeForm/CreateDeducteeForm';
import CreateCorrectionDeducteeForm from '../../components/createDeducteeForm/CreateCorrectionDeducteeForm';
import { deducteeFormFields, getDeducteeObject } from '../../components/createDeducteeForm/DeducteeFormHelper';
import { correctionDeducteeFormFields, correctionGetDeducteeObject } from '../../components/createDeducteeForm/CorrectionDeducteeFormHelper';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { addDeductee, addCorrectionDeductee } from '../../api/deducteeAPI';
import queryString from 'query-string';

const AddDeductee = (props) => {
  const { onClose, deductorId, componentImport, left } = props;

  const location = useLocation();
  const params = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const unMaskingData = (data) => {
    if (data?.mobile_no) {
      data.mobile_no = data?.mobile_no.replace(/\D+/g, '')
    }
    return data
  }

  const createDeductee = (data, closeForm = true) => {
    setError(null);
    setSuccess(null);
    let setAllValue = [data];

    const deducteeData = componentImport ? correctionGetDeducteeObject(data) : getDeducteeObject(unMaskingData(data));
    deducteeData.deductor_id = deductorId;
    deducteeData.forms = '24Q,26Q,27EQ';
    if (params.return_id) {
      deducteeData.return_id = params.return_id;
    }

    async function callAPI() {
      async function createDeductee2() {
        deducteeData.user_salary_category = deducteeData.salary_category;
        const result = componentImport ? await addCorrectionDeductee(deducteeData) : await addDeductee(deducteeData);
        return result;
      }
      try {
        const result = await createDeductee2();
        setAllValue.push(result.data);
        localStorage.setItem("getNewAddedDeductee", JSON.stringify(setAllValue));
        setSuccess(result.message);
        if (closeForm) {
          onClose(true, result.message);
        }
        return true;
      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
        return false;
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    return callAPI();
  };

  return (

    <div>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {componentImport ? (
        <CreateCorrectionDeducteeForm
          deducteeInfo={correctionDeducteeFormFields}
          onClose={() => onClose()}
          createDeductee={createDeductee}
          deductorId={deductorId}
        />
      ) : (
        <CreateDeducteeForm
          deducteeInfo={deducteeFormFields}
          onClose={() => onClose()}
          createDeductee={createDeductee}
          deductorId={deductorId}
          left={left}
        />
      )}



    </div>

  );
};

export default AddDeductee;
