import React, { useState, useEffect, useRef, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import '../returnDashboard/importReturnFromExcel/ImportReturnResult.css';
import '../returnDashboard/importReturnFromExcel/ImportReturnProgress.css';
import './DeducteeListV2.scss';
import { HEADER_FILE_NAME } from '../../app/constants';
import { createImportRegularDeducteeReturnLocalStorageKey } from '../../utils/UtilityFunctions';
import { getImportDeducteeStatus, downloadDeducteeImportErrorFile } from '../../api/deducteeAPI';

const DeducteeListV2ImportProgress = (props) => {

    const { show, handleClose, deductorId, headerData } = props;

    const checkRunningImport = localStorage.getItem(createImportRegularDeducteeReturnLocalStorageKey(headerData))
    const [importStats, setImportStats] = useState(checkRunningImport ? JSON.parse(checkRunningImport)?.import_stats : {})
    const [hasError, setHasError] = useState(checkRunningImport ? JSON.parse(checkRunningImport)?.has_errored_file : false)
    const [importStatus, setImportStatus] = useState(checkRunningImport ? JSON.parse(checkRunningImport)?.import_status : '');
    const [loading, setLoading] = useState(false);
    const timer = useRef(null);


    const callImportStatus = useCallback(async (deductor_id) => {
        try {
            const result = await getImportDeducteeStatus(deductor_id);
            if (result?.data) {
                setImportStats(() => { return { ...result?.data?.import_stats } })
                setImportStatus(result?.data?.import_status)
                localStorage.setItem(createImportRegularDeducteeReturnLocalStorageKey(headerData), JSON.stringify(result?.data));
                if (result?.data?.has_errored_file) {
                    setHasError(true)
                }
                if (result?.data?.import_status !== "SUCCESS" && result?.data?.import_status !== "FAILURE") {
                    timer.current = setTimeout(() => callImportStatus(deductor_id), 3000);
                }
                if (result?.data?.import_status === "SUCCESS") {
                    timer.current = setTimeout(() => window.location.reload(), 2000)
                }
            }
        } catch (err) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deductorId]);


    const downloadErrorFile = async () => {
        setLoading(true);
        try {
            let params = `?deductor_id=${deductorId}`;
            const result = await downloadDeducteeImportErrorFile(params);
            const csiData = new Blob([result?.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS_Deductee_Error_File.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            console.error('Error in file: ', err);
        }
        setLoading(false);
    };

    useEffect(() => {
        const checkRunningImport = localStorage.getItem(createImportRegularDeducteeReturnLocalStorageKey(headerData))
        if (!checkRunningImport || (JSON.parse(checkRunningImport)?.import_status !== 'SUCCESS' && JSON.parse(checkRunningImport)?.import_status !== 'FAILURE')) {
            callImportStatus(deductorId)
        }
        return () => clearTimeout(timer.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onHide = () => {
        handleClose();
        const checkRunningImport = localStorage.getItem(createImportRegularDeducteeReturnLocalStorageKey(headerData))
        if (checkRunningImport && (JSON.parse(checkRunningImport)?.import_status === 'SUCCESS' || JSON.parse(checkRunningImport)?.import_status === 'FAILURE')) {
            localStorage.removeItem(createImportRegularDeducteeReturnLocalStorageKey(headerData));
        }
    }


    return (
        <>

            {loading ? <LoadingIndicator /> : null}

            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
                className="tdsimport_inprogress"
            >
                <Modal.Header closeButton className="tdsimport_inprogress_header">
                    <h4 class="tdsimport_inprogress_title tdsimport_inprogress_disinline ">
                        {importStatus === 'SUCCESS' ? <span style={{ color: 'green' }}>Import Completed</span> : importStatus === 'FAILURE ' ? <span style={{ color: 'red' }}>Import Failed</span> : <>
                            <span style={{ color: 'blue' }}>Import in Progress</span>
                            <div style={{ width: "60%", float: "right", marginTop: "3px" }}><ProgressBar animated now={100} label={''} /></div>
                        </>}
                    </h4>
                </Modal.Header>
                <Modal.Body className="pt-2 pb-2 px-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                    <table class="table table-striped tdsimport_inprogress_tb mb-1">
                                        <thead>
                                            <tr>
                                                <th width="250"></th>
                                                <th width="100">Total</th>
                                                <th width="100">Imported</th>
                                                <th width="100">Errored</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {importStats ?
                                                <tr>
                                                    <td>Deductee Records</td>
                                                    <td class="text-right">{importStats?.TotalCnt ?? 0}</td>
                                                    <td class="text-right">{(importStats?.TotalCnt ?? 0) - (importStats?.ErrorCnt ?? 0)}</td>
                                                    <td class="text-right">{importStats?.ErrorCnt ?? 0}</td>
                                                </tr>
                                                :
                                                <tr colSpan={4}>
                                                    No Data Found!!
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                    {(downloadErrorFile && hasError && (importStatus === 'SUCCESS' || importStatus === 'FAILED')) &&
                                        <span style={{ paddingTop: '10px', paddingLeft: '8rem' }}>
                                            <button className="btn btn-defulat deduct_master_compliance_checkbtnbox text-center" onClick={() => downloadErrorFile()}>
                                                Download Error Report
                                            </button>

                                        </span>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>


            </Modal>


        </>
    );
};

export default DeducteeListV2ImportProgress;
