import React, { useState } from "react";
import { BASE_PATH } from "../../app/constants";
import moment from 'moment';
import { submitTentativePayment } from "../../api/profileAPI";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {FormatNumberDecValue } from "../../utils/UtilityFunctions";

const TentativePayment = (props) => {
    const { setSuccess, setError, sharedplanid, history, showPaymentmode } = props;
    const defaultdata = { company_name: '', trxn_date: '', trxn_ref: '', trxn_amt: '' }
    const [currentTab, setCurrentTab] = useState('CASH');
    const [calendarShow, setCalendarShow] = useState(false);
    const [headingError, setHeadingError] = useState({})
    const [data, setData] = useState(defaultdata);
    const heading = {
        CASH: [{ company_name: 'Company Name', error: 'Company Name is required' }, { trxn_date: 'Cash Deposit Date', error: 'Cash Deposit Date is required' }, { trxn_ref: 'Cash Submission Branch & City Name', error: 'Cash Submission Branch & City Name is required' }, { trxn_amt: 'Amount', error: 'Amount is required' }],
        NEFT_RTGS: [{ company_name: 'Company Name', error: 'Company Name is required' }, { trxn_date: 'Trxn Date', error: 'Trxn Date is required' }, { trxn_ref: 'UTR No.', error: 'UTR No. is required' }, { trxn_amt: 'Amount', error: 'Amount is required' }],
        IMPS: [{ company_name: 'Company Name', error: 'Company Name is required' }, { trxn_date: 'Trxn Date', error: 'Trxn Date is required' }, { trxn_ref: 'Txn Ref. No', error: 'Txn Ref. No is required' }, { trxn_amt: 'Amount', error: 'Amount is required' }],
        CHEQUE: [{ company_name: 'Company Name', error: 'Company Name is required' }, { trxn_date: 'Cheque Submission Date', error: 'Cheque Submission Date is required' }, { trxn_ref: 'Cheque No.', error: 'Cheque No. is required' }, { trxn_amt: 'Amount', error: 'Amount is required' }]
    };

    const handleChange = (name, value) => {
        
        setData((prev) => {
            prev[name] = value;
            return { ...prev };
        })
        if (Object.keys(headingError).length > 0) {
            setHeadingError((prev) => {
                prev[name] = '';
                return { ...prev }
            })
        }
    }

    const validateData = (data) => {

        let shouldApiCall = true;
        let error = {}
        const newData = JSON.parse(JSON.stringify(data));

        Object.keys(newData).forEach((item, i) => {
            if (newData[item]) {
                if (item === 'trxn_date') {
                    newData[item] = moment(data.trxn_date).format('DD/MM/YYYY');

                }
            } else if (!newData[item]) {
                error[item] = heading[currentTab][i].error
                shouldApiCall = false;
            }
        })
        newData['spi'] = sharedplanid;
        newData['tentative_payment_mode'] = currentTab;
        const apiPayload =  Object.assign(newData, showPaymentmode);
        if (!shouldApiCall) {
            setHeadingError({ ...error })
            return shouldApiCall;
        } else if (shouldApiCall) {
            setHeadingError({ ...error })
            return apiPayload;
        }

    }

    const submitTentativePaymentOnServer = async () => {
        const responsedata = validateData(data)
        if (responsedata) {
            try {
                const response = await submitTentativePayment(responsedata)
                setSuccess(response.message);
                setTimeout(() => {
                    history.push(`${BASE_PATH}`);
                }, 1500)

            } catch (err) {
                setError(err.message)
            }
        }

    }


    return (
        <>
            <div>
                <div className="row mt-5">
                    <div className="text-left ml-3">
                        <button className={`btn btn-default paymentmode_button  ${currentTab === 'CASH' ? 'payment_active_Button' : ''}`} onClick={() => [setData(defaultdata), setHeadingError({}), setCurrentTab('CASH')]}>Cash</button>
                        <button className={`btn btn-default paymentmode_button ${currentTab === 'CHEQUE' ? 'payment_active_Button' : ''}`} onClick={() => [setData(defaultdata), setHeadingError({}), setCurrentTab('CHEQUE')]}>Cheque</button>
                        <button className={`btn btn-default paymentmode_button ${currentTab === 'IMPS' ? 'payment_active_Button' : ''}`} onClick={() => [setData(defaultdata), setHeadingError({}), setCurrentTab('IMPS')]}>IMPS</button>
                        <button className={`btn btn-default paymentmode_button ${currentTab === 'NEFT_RTGS' ? 'payment_active_Button' : ''}`} onClick={() => [setData(defaultdata), setHeadingError({}), setCurrentTab('NEFT_RTGS')]}>NEFT / RTGS</button>
                    </div>
                </div>
                <div className="row mt-5 mb-4">
                    <div className="col-md-12">
                        <div className="card p-0" style={{ border: '0px' }}>
                            <div className="expencesscrollhide_1025"
                                style={{ display: "flex", flexWrap: "wrap" }}>
                                <div className="paymentmode_heading" style={{ width: '30%' }}>
                                    <span className="heading">{heading[currentTab][0].company_name}
                                        <span style={{ color: 'red' }}>*</span>
                                    </span>
                                    <span class="full-width">
                                        <input type="text" style={{ fontSize: "12px", border: "1px solid lightgray", width: "90%" }} name='company_name' maxLength={255} onChange={(e) => handleChange(e.target.name, e.target.value)} value={data.company_name ?? ''} />
                                    </span><br />
                                    <span style={{ fontSize: "12px", color: "red" }}>{headingError.company_name ?? ''}</span>
                                </div>
                                <div className="paymentmode_heading" style={{ width: "20%" }}>
                                    <span className="heading">{heading[currentTab][1].trxn_date}<span style={{ color: "red" }}>*</span></span>
                                    <span class="full-width">
                                        <DatePicker
                                            style={{ fontSize: "12px", border: "1px solid lightgray" }}
                                            value={data.trxn_date || null}
                                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            name="trxn_date"
                                            format={"dd/MM/yyyy"}
                                            id={"datepicker"}
                                            max={new Date()}
                                            formatPlaceholder={{ year: "YYYY", month: "MM", day: "DD" }}
                                            show={calendarShow}
                                            onFocus={() => {
                                                setCalendarShow(true)
                                                document.getElementById("datepicker").focus();
                                            }}
                                            onBlur={() => setCalendarShow(false)}
                                        />
                                    </span><br />
                                    <span style={{ fontSize: "12px", color: "red" }}>{headingError.trxn_date ?? ''}</span>
                                </div>
                                <div className="paymentmode_heading" style={{ width: "30%" }}>
                                    <span className="heading">{heading[currentTab][2].trxn_ref}
                                        <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span class="full-width">
                                        <input style={{ fontSize: "12px", textAlign:"right", border: "1px solid lightgray", width: "90%" }} type="text" name="trxn_ref" maxLength={255} onChange={(e) => handleChange(e.target.name, e.target.value)} value={data.trxn_ref ?? ''} />
                                    </span><br />
                                    <span style={{ fontSize: "12px", color: "red" }}>{headingError.trxn_ref ?? ''}</span>
                                </div>
                                <div className="paymentmode_heading" style={{ width: "20%" }}>
                                    <span className="heading">{heading[currentTab][3].trxn_amt}
                                        <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span class="full-width">
                                        <input style={{ fontSize: "12px", border: "1px solid lightgray" }} type="text" name="trxn_amt" onChange={(e) => handleChange(e.target.name, FormatNumberDecValue(e.target.value,14,2,false,true))} value={data.trxn_amt ?? ''} />
                                    </span><br />
                                    <span style={{ fontSize: "12px", color: "red" }}>{headingError.trxn_amt ?? ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-5">
                    <button className="btn btn-default makepaybtn" onClick={() => submitTentativePaymentOnServer()}>Submit Payment Details</button>
                </div>
            </div>
        </>
    )
}

export default TentativePayment;
