// @flow
/**
 * This component represents an unadorned list of SelectItem (s).
 */
import React, { Component } from 'react';
import $ from 'jquery';

import SelectItem from './select-item.js';

class SelectList extends Component {
    componentDidMount() {
        try {
            /* $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
             $('[data-toggle="tooltip"]').click(() => {
                 $('[data-toggle="tooltip"]').tooltip('hide');
             });*/
        } catch (e) {

        }
    }

    containsAll = (needles, haystack) => {
        for (var i = 0; i < needles.length; i++) {
            if ($.inArray(needles[i], haystack) === -1) return false;
        }
        return true;
    }

    containsNotAnyItem = (needles, haystack) => {
        for (var i = 0; i < needles.length; i++) {
            if ($.inArray(needles[i], haystack) !== -1) return true;
        }
        return false;
    }

    parentActionRequired = (checked, option, selected) => {
        const { onSelectedChanged } = this.props;
        if (checked) {
            const status = this.containsAll(option.allChild, selected);
            if (status && !selected.includes(option.parent)) {
                onSelectedChanged([...selected, option.parent])
            }
        } else {
            const status = this.containsNotAnyItem(option.allChild, selected);
            if (!status) {
                const index = selected.indexOf(option.parent);
                if (index > -1) { // only splice array when item is found
                    selected.splice(index, 1); // 2nd parameter means remove one item only
                }
                onSelectedChanged([...selected])
            }
        }
    }

    handleSelectionChanged = (option, checked, child) => {
        const { selected, onSelectedChanged } = this.props;

        let childs = [];

        if (option.child && option.child.length > 0) {
            childs = option.child;
        }

        if (checked) {

            let allSelected  = [...selected, ...childs, option.value];
            
            allSelected = allSelected.filter((item, index) => allSelected.indexOf(item) === index);

            onSelectedChanged(allSelected);

            if (option.parent) {
                this.parentActionRequired(checked, option, [...selected, ...childs, option.value]);
            }

        } else {
            const index = selected.indexOf(option.value);
            let removed = [
                ...selected.slice(0, index),
                ...selected.slice(index + 1),
            ];

            removed = removed.filter(x => !childs.includes(x));

            if (option.parent) {
                this.parentActionRequired(checked, option, removed);
            }

            onSelectedChanged(removed);
        }
    }


    renderItemChilds() {
        const {
            ItemRenderer,
            options,
            selected,
            focusIndex,
            onClick,
            disabled,
        } = this.props;

        return(null);
    }


    renderItems() {
        const {
            ItemRenderer,
            options,
            selected,
            focusIndex,
            onClick,
            disabled,
        } = this.props;


        return options.map((o, i) => {

            if (o.tooltip) {
                return (
                    <li
                        style={styles.listItem}
                        key={o.value}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={o.tooltip}
                    >
                        <SelectItem
                            focused={focusIndex === i}
                            option={o}
                            onSelectionChanged={c => this.handleSelectionChanged(o, c)}
                            checked={selected.includes(o.value)}
                            onClick={e => onClick(e, i)}
                            ItemRenderer={ItemRenderer}
                            disabled={o.disabled || disabled}
                        />
                    </li>
                );
            }

            if(!o.parent){
                return (            
                    <li style={styles.listItem} key={o.hasOwnProperty("key") ? o.key : i} >
                        <SelectItem
                            focused={focusIndex === i}
                            option={o}
                            onSelectionChanged={c => this.handleSelectionChanged(o, c, o.child)}
                            checked={selected.includes(o.value)}
                            onClick={e => onClick(e, i)}
                            ItemRenderer={ItemRenderer}
                            disabled={o.disabled || disabled}
                        />
                    </li>
                );
            }else{
                return (     
                    <ul style={{ marginLeft: '20px' }}>    
                        <li style={styles.listItem} key={o.hasOwnProperty("key") ? o.key : i} >
                            <SelectItem
                                focused={focusIndex === i}
                                option={o}
                                onSelectionChanged={c => this.handleSelectionChanged(o, c, o.child)}
                                checked={selected.includes(o.value)}
                                onClick={e => onClick(e, i)}
                                ItemRenderer={ItemRenderer}
                                disabled={o.disabled || disabled}
                            />
                        </li>
                    </ul>   
                );
            }
            
        });
    }

    render() {
        return <ul
            className="select-list"
            style={styles.list}
        >
            {this.renderItems()}
        </ul>;
    }
}

const styles = {
    list: {
        margin: 0,
        paddingLeft: 0,
    },
    listItem: {
        listStyle: 'none',
    },
};

export default SelectList;
