/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './ViewComputation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRupeeSign, faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import { PDFExport } from "@progress/kendo-react-pdf";
import { formatNumber } from '../../../../../utils/UtilityFunctions';


class ViewComputation extends React.Component {

    pdfExportComponent;

    render() {

        let { show, handleClose, computationData, allowanceList, otherIncomeParticulars, deductionVIAParticulars, headerDataDetails, financialYear } = this.props;
        let AggregateDeductibleAmountSum = 0;
        let TotalGrossAmountSum = 0;
        let TotalDeductibleAmountSum = 0;
        var TotalQualifyingAmountSum = 0;

        return (
            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    className="MonthWSdetailspopup"
                >
                    <Modal.Header closeButton className="MonthWSdetailsH computation_header">
                        <Modal.Title className="pt-0 mt-0">
                            <sapn className="MonthWSdetailsT">Computation</sapn>

                            <span style={{ position: 'absolute', right: '60px', cursor: 'pointer' }}
                                onClick={() => {
                                    document.getElementById('pdfTitle').style.display = "block";
                                    this.pdfExportComponent.save();
                                    document.getElementById('pdfTitle').style.display = "none";
                                }}> <FontAwesomeIcon icon={faFilePdf} /></span>

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-0 px-0">
                        <div>
                            <div className="container mt-1">
                                <div className="card py-0 px-0 mb-2 cardheightfixed">
                                    <PDFExport
                                        ref={(component) => (this.pdfExportComponent = component)}
                                        multiPage={true}
                                        margin={"1cm"}
                                        title="Income Tax Computation"
                                        fileName={computationData.deductee_name + "-Salary-" + financialYear + "-" + ((parseInt(financialYear) + 1).toString().substr(2, 2)) + "-Computation.pdf"}
                                    >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div id="pdfTitle" style={{ display: 'none' }}>
                                                    <div className="col-md-12 text-center">Income Tax Computation</div>
                                                    <div className="row pl-2" style={{ fontSize: '12px' }}>
                                                        <div className="col-md-4 pt-4">Deductor Name</div> <div className="col-md-8 pt-4">{headerDataDetails.deductor_name + " - " + headerDataDetails.tan}</div>
                                                        <div className="col-md-4 pt-2">Financial Year</div> <div className="col-md-8 pt-2">{headerDataDetails.financial_year_formatted}</div>
                                                        <div className="col-md-4 pt-2">Quarter</div> <div className="col-md-8 pt-2">{headerDataDetails.quarter}</div>
                                                        <div className="col-md-4 pt-2">Form Type</div> <div className="col-md-8 pt-2">{headerDataDetails.form_type}</div>
                                                        <div className="col-md-4 pt-2">Return Type</div> <div className="col-md-8 pt-2">{headerDataDetails.return_type}</div>
                                                        <div className="col-md-4 pt-2">Deductee/Employee Name</div> <div className="col-md-8 pt-2">{computationData.deductee_name}</div>
                                                        <div className="col-md-4 pt-2">Employment Period</div> <div className="col-md-8 pt-2">{computationData.employment_period_from + " - " + computationData.employment_period_to}</div>
                                                    </div>
                                                </div>

                                                <div className="row pl-2 pt-4" style={{ fontSize: '13px' }}>
                                                    <div className="col-md-10">{parseInt(headerDataDetails.financial_year_formatted) >= 2023 ? "A. Whether opting out for taxation u/s 115BAC(1A) ?" : "A. Whether opting for Taxation u/s 115BAC ?"}
</div>
                                                    <div className="col-md-1 ml-4">{parseInt(headerDataDetails.financial_year_formatted) >= 2023 ? (computationData.tax_regime === 'NEW' ? '[NO]' : '[YES]') : (computationData.tax_regime === 'OLD' ? '[NO]' : '[YES]')}</div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-striped viewcomputattb">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '27rem' }}>Details of Income</th>
                                                                <th>Amt</th>
                                                                <th>Amt</th>
                                                                <th>Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* <tr className="innertrthcol">
                                                        <th>A. Whether opting for taxation u/s 115BAC ?</th>
                                                        <th colspan="3" className="text-right">{computationData.tax_regime === 'OLD' ? '[NO]' : '[YES]'}</th>
                                                    </tr> */}
                                                            <tr className="innertrthcol">
                                                                <th >1. GROSS SALARY</th>
                                                                <th colspan="2" className="text-right"></th>
                                                                <th className="text-right">
                                                                    {/* <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {(computationData && computationData.total_gross_salary_amtp) ? computationData.total_gross_salary_amtp : 0} */}
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(a). Salary u/s 17(1)</span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.salary_amtp) ? formatNumber(computationData.salary_amtp) : 0}
                                                                </td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(b). Value of Perquisites u/s 17(2)</span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.perquisites_amtp) ? formatNumber(computationData.perquisites_amtp) : 0}
                                                                </td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(c). Profits in Lieu of salary u/s 17(3)</span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.profit_in_lieu_amtp) ? formatNumber(computationData.profit_in_lieu_amtp) : 0}
                                                                </td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(d). Total</span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_gross_salary_amtp) ? formatNumber(computationData.total_gross_salary_amtp) : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pl-3">(e) Reported total amount of salary received from other employer(s)</td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"><span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.previous_employer_amount_received_amtp) ? formatNumber(computationData.previous_employer_amount_received_amtp) : 0}</td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th>2. Less: ALLOWANCES EXEMPT U/S 10 </th>
                                                                <th colspan="2" className="text-right"></th>
                                                                <th className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_allowance_exempt_amtp) ? formatNumber(computationData.total_allowance_exempt_amtp) : 0}
                                                                </th>
                                                            </tr>
                                                            {computationData.allowances.map((data, index) => {
                                                                let allowanceData = allowanceList[computationData.tax_regime].filter((allowance, index2) => { return parseInt(allowance.allowance_type_id) === parseInt(data.allowance_type_id) })
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <span className="pl-2">{(allowanceData.length) ? allowanceData[0].allowance_type_name : '-'}</span>
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                            {(data) ? formatNumber(data.exempt_amtp) : 0}
                                                                        </td>
                                                                        <td className="text-right"></td>
                                                                        <td className="text-right"></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            <tr>
                                                                <td><span className="pl-2">Total Deduction</span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_allowance_exempt_amtp) ? formatNumber(computationData.total_allowance_exempt_amtp) : 0}
                                                                </td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th>3. Less: DEDUCTION U/S 16 </th>
                                                                <th colspan="2" className="text-right"></th>
                                                                <th className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_deduction_amtp) ? formatNumber(computationData.total_deduction_amtp) : 0}
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(i). Standard Deduction u/s 16(ia)</span></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.standard_deduction_amtp) ? formatNumber(computationData.standard_deduction_amtp) : 0}
                                                                </td>
                                                                <td className="text-right">

                                                                </td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(ii). Entertainment Allowance u/s 16(ii)</span></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.ent_allowance_amtp) ? formatNumber(computationData.ent_allowance_amtp) : 0}
                                                                </td>
                                                                <td className="text-right">

                                                                </td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(iii). Professional Tax u/s 16(iii)</span></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.professional_tax_amtp) ? formatNumber(computationData.professional_tax_amtp) : 0}
                                                                </td>
                                                                <td className="text-right">

                                                                </td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(iv). Total amount of deductions under section 16</span></td>
                                                                <td className="text-right">
                                                                </td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_deduction_amtp) ? formatNumber(computationData.total_deduction_amtp) : 0}
                                                                </td>
                                                                <td className="text-right">

                                                                </td>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th>4. NET SALARY FROM CURRENT EMPLOYER (1-2-3) </th>
                                                                <th colspan="2" className="text-right"></th>
                                                                <th className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_salary_amtp) ? formatNumber(parseFloat(computationData.net_salary_amtp)) : 0}
                                                                </th>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th>5. INCOME CHARGEABLE UNDER SALARY </th>

                                                                <th className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData) ? formatNumber(parseFloat(computationData.net_salary_amtp)) : 0}
                                                                </th>
                                                                <th className="text-right" colspan="2"></th>
                                                            </tr>

                                                            <tr className="innertrthcol">
                                                                <th colspan="4"> 6. ANY OTHER INCOME REPORTED BY THE EMPLOYEE UNDER AS PER SECTION 192 (2B)</th>
                                                            </tr>

                                                            <tr>
                                                                <td>A. Income From House Property</td>
                                                                <td colspan="2" className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_house_property_income_amtp) ? formatNumber(computationData.total_house_property_income_amtp) : 0}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td><span className="pl-2">(i). Let Out Property</span></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.let_out_amtp) ? formatNumber(computationData.let_out_amtp) : 0}
                                                                </td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">(ii). Self Occupied Property</span></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.self_occupied_amtp) ? formatNumber(computationData.self_occupied_amtp) : 0}
                                                                </td>
                                                                <td className="text-right">

                                                                </td>
                                                                <td className="text-right">

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="pl-2">Total</span></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_house_property_income_amtp) ? formatNumber(computationData.total_house_property_income_amtp) : 0}
                                                                </td>
                                                                <td className="text-right">

                                                                </td>
                                                                <td className="text-right">

                                                                </td>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th>Maximum Allowed Interset in no case exceed (<span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span> -2,00,000.00). All negative values to be written in bracket </th>
                                                                <th colspan="2" className="text-right"></th>
                                                                <th className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData) ? formatNumber(computationData.total_house_property_income_amtp) : 0}
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>B. Income From Other Sources Offered For TDS U/S 192(2B)</td>
                                                                <td colspan="3" className="text-right"></td>
                                                            </tr>
                                                            {computationData.other_income_offered_details.map((data, index) => {
                                                                let particularsData = otherIncomeParticulars.filter((particulars, index2) => { return particulars.particular_code === data.particular_code })
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <span className="pl-2">{(particularsData.length) ? particularsData[0].particular_description : '-'}</span>
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                            {(data) ? formatNumber(data.amount_amtp) : 0}
                                                                        </td>
                                                                        <td className="text-right"></td>
                                                                        <td className="text-right"></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            <tr>
                                                                <td><span className="pl-2">Total</span></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_other_income_offered_amtp) ? formatNumber(computationData.total_other_income_offered_amtp) : 0}
                                                                </td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th>7. GROSS TOTAL INCOME (5 + 6) </th>
                                                                <th className="text-right"></th>
                                                                <th className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_gross_income_amtp) ? formatNumber(computationData.total_gross_income_amtp) : 0}
                                                                </th>
                                                                <th className="text-right">
                                                                </th>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th>8. DEDUCTION UNDER CHAPTER VI-A </th>
                                                                <th className="text-right"></th>
                                                                <th className="text-right">
                                                                </th>
                                                                <th className="text-right">
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>A. Aggregate of 80C, 80CCC, 80CCD(1)</td>
                                                                <td className="text-right">Gross Amt</td>
                                                                <td className="text-right">Deductible Amt	</td>
                                                                <td className="text-right"></td>
                                                            </tr>
                                                            {computationData.deduction_vi_a_details.map((data, index) => {
                                                                if (data.code === '80C' || data.code === '80CCC' || data.code === '80CCD_1') {
                                                                    let particularsData = deductionVIAParticulars[computationData.tax_regime].filter((particulars, index2) => { return particulars.code === data.code })
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                <span className="pl-2">{(particularsData.length) ? particularsData[0].display_code : '-'}</span>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                                {(data) ? formatNumber(data.gross_amount_amtp) : 0}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                                {(data) ? formatNumber(data.deductible_amount_amtp) : 0}
                                                                            </td>
                                                                            <td className="text-right">
                                                                            </td>
                                                                            <td style={{ display: 'none' }}>
                                                                                <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                                {formatNumber(AggregateDeductibleAmountSum += parseFloat(data.deductible_amount_amtp))}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                else {
                                                                    return false
                                                                }
                                                            })}
                                                            <tr>
                                                                <td>
                                                                    Aggregate deductible Amount of 80C, 80CCC, 80CCD(1) in no case exceed.
                                                        </td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {formatNumber(AggregateDeductibleAmountSum.toFixed(2))}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>B. Other Sections</td>
                                                                <td className="text-right">Gross Amt</td>
                                                                <td className="text-right">Deductible Amt	</td>
                                                                <td className="text-right">Qualifying Amt</td>
                                                            </tr>
                                                            {computationData.deduction_vi_a_details.map((data, index) => {
                                                                if (data.code !== '80C' && data.code !== '80CCC' && data.code !== '80CCD_1') {
                                                                    let particularsData = deductionVIAParticulars[computationData.tax_regime].filter((particulars, index2) => { return particulars.code === data.code })
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                <span className="pl-2">{(particularsData.length) ? particularsData[0].display_code : '-'}</span>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                                {(data) ? formatNumber(data.gross_amount_amtp) : 0}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                                {(data) ? formatNumber(data.deductible_amount_amtp) : 0}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                                {(data) ? formatNumber(data.qualifying_amtp) : 0}
                                                                            </td>
                                                                            <td style={{ display: 'none' }}>
                                                                                <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                                {TotalGrossAmountSum += parseFloat(data.gross_amount_amtp)}
                                                                                {TotalDeductibleAmountSum += parseFloat(data.deductible_amount_amtp)}
                                                                                {(data.qualifying_amtp) ? TotalQualifyingAmountSum += parseFloat(data.qualifying_amtp) : TotalQualifyingAmountSum += 0}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                else {
                                                                    return false
                                                                }
                                                            })}
                                                            <tr>
                                                                <td><span className=""> Total</span></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {formatNumber(TotalGrossAmountSum.toFixed(2))}
                                                                </td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {formatNumber(TotalDeductibleAmountSum.toFixed(2))}
                                                                </td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {formatNumber(TotalQualifyingAmountSum)}
                                                                </td>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th><span className="">9. AGGREGATE OF DEDUCTION UNDER CHAPTER VI-A (10A+10B) </span></th>
                                                                <th colspan="2" className="text-right"></th>
                                                                <th className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.total_deduction_vi_a_amtp) ? formatNumber(computationData.total_deduction_vi_a_amtp) : 0}
                                                                </th>
                                                            </tr>
                                                            <tr className="innertrthcol">
                                                                <th><span className="">10. NET TAXABLE INCOME (9-11) </span></th>
                                                                <th colspan="2" className="text-right"></th>
                                                                <th className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_taxable_income && computationData.net_taxable_income.total_net_taxable_income_amtp) ? formatNumber(computationData.net_taxable_income.total_net_taxable_income_amtp) : 0}
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="">11. TAX ON TOTAL INCOME</span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_taxable_income.tax_computed.income_tax_amtp) ? formatNumber(computationData.net_taxable_income.tax_computed.income_tax_amtp) : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="">12. LESS:- REBATE U/S 87A</span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_taxable_income.tax_computed.rebate_us_87_a_amtp) ? formatNumber(computationData.net_taxable_income.tax_computed.rebate_us_87_a_amtp) : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="">13. ADD: SURCHARGE (If Applicable) </span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_taxable_income.tax_computed.surcharge_amtp) ? formatNumber(computationData.net_taxable_income.tax_computed.surcharge_amtp) : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="">14. ADD: HEALTH & EDUCATION CESS @ 4% (13-14+15) </span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_taxable_income.tax_computed.health_edu_cess_amtp) ? formatNumber(computationData.net_taxable_income.tax_computed.health_edu_cess_amtp) : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="">15. TAX PAYABLE (13-14+15+16) </span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_taxable_income.tax_computed.tax_payable_amtp) ? formatNumber(computationData.net_taxable_income.tax_computed.tax_payable_amtp) : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="">16. LESS: RELIEF U/S 89 </span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_taxable_income.tax_computed.relief_us_89_amtp) ? formatNumber(computationData.net_taxable_income.tax_computed.relief_us_89_amtp) : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="">17. NET TAX PAYABLE (17-18) </span></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right"></td>
                                                                <td className="text-right">
                                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                                    {(computationData && computationData.net_taxable_income && computationData.net_taxable_income.total_tax_computed_amtp) ? formatNumber(computationData.net_taxable_income.total_tax_computed_amtp) : 0}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </PDFExport>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
};

export default ViewComputation;
