import React from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';

const DeductionVIA = (props) => {
    const { data, headingCodeListdedVI_A, unUsedDropdownList, drowdownList, taxRegime, setData, handleArrayNumberInputOnBlur,
        updateEmployeeDetail, handleArrayTypeInputChange, deleteIcon, addBtnRoundIcon, dedVI_A_RowObj
    } = props;
    return (
        <>
            <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse collapsed" data-toggle="collapse" href="#salarytaxregime_edit_mastercollap03" aria-expanded="false"></span>

                </th>
                <th width="550" className="text-left">
                    Chapter VI-A Dedcutions
                </th>
                <th width="120" className="text-right">
                    {FormatNumberDecimal(data?.total_deduction_vi_a_amtp ?? "", ...Array(2), false)}
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_mastercollap03" className="in collapse">

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <thead>
                                    <tr>
                                        <th width="37"></th>
                                        <th width="150" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop ">Particulars</th>
                                        <th width="100" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop ">Gross Amount</th>
                                        <th width="100" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop ">Deductible</th>
                                        <th width="140" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderright salarytaxreg_innertb_bordertop ">Qualifying Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.deduction_vi_a_details?.length > 0 &&
                                        data?.deduction_vi_a_details.map((item, index) => {
                                            item.display_code = headingCodeListdedVI_A?.get(item.code)?.display_code ?? "";
                                            return (
                                                <tr className="" key={index}>
                                                    <td width=""></td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft ">
                                                        <span className="">
                                                            <DropDownList
                                                                data={unUsedDropdownList(drowdownList?.deductionVI_A?.[taxRegime], data.deduction_vi_a_details, item.code)}
                                                                className="header_select headerselect w-100 drpdwnlist_changedesign clrblkmth"
                                                                popupSettings={{ className: 'headerselectoption' }}
                                                                textField="display_code"
                                                                value={item}
                                                                onChange={(e) => {
                                                                    data.deduction_vi_a_details[index] = e.target.value;
                                                                    setData({ ...data })
                                                                }}
                                                                onBlur={(e) => updateEmployeeDetail(data)}
                                                                placeholder="Add additional detail"
                                                                dataItemKey="code"
                                                                id="yearDropDownPopup"
                                                            />
                                                        </span>
                                                    </td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell ">
                                                        <BlurOnChangeInput
                                                            type="text"
                                                            className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                            value={FormatNumberDecimal(item.gross_amount_amtp ?? 0, ...Array(2), false)}
                                                            data-toggle="tooltip" data-placement="top" title={""}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                                handleArrayTypeInputChange("deduction_vi_a_details", index, "gross_amount_amtp", value);
                                                            }}
                                                            onBlur={(e) => handleArrayNumberInputOnBlur("deduction_vi_a_details", index, "gross_amount_amtp", item.gross_amount_amtp)}
                                                        />
                                                    </td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell ">
                                                        <BlurOnChangeInput
                                                            type="text"
                                                            className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                            value={FormatNumberDecimal(item.deductible_amount_amtp ?? 0, ...Array(2), false)}
                                                            data-toggle="tooltip" data-placement="top" title={""}
                                                            placeholder=""
                                                            disabled

                                                        />

                                                    </td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright">
                                                        <span className="d-flex">
                                                            <BlurOnChangeInput
                                                                type="text"
                                                                className={`form-control salarytaxregime_edit_inputbox  text-right`}
                                                                value={FormatNumberDecimal(item.qualifying_amtp ?? 0, ...Array(2), false)}
                                                                data-toggle="tooltip" data-placement="top" title={""}
                                                                placeholder=""
                                                                disabled
                                                            />

                                                            <span className="deleicon_pos" style={{ width: "30%" }}>
                                                                {index > 0 &&
                                                                    <img src={deleteIcon} alt="addicon" width="16"
                                                                        onClick={() => {
                                                                            data.deduction_vi_a_details.splice(index, 1);
                                                                            setData({ ...data });
                                                                        }}
                                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                    />
                                                                }
                                                                
                                                                {(index + 2) !== drowdownList?.deductionVI_A?.[taxRegime]?.length && // add index + 2 because default select option always added in dropdown
                                                                    (index + 1) === data?.deduction_vi_a_details?.length &&
                                                                    <img src={addBtnRoundIcon} alt="addicon" width="16"
                                                                        onClick={() => {
                                                                            data?.deduction_vi_a_details.push(dedVI_A_RowObj);
                                                                            setData({ ...data });
                                                                        }}
                                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                    />
                                                                }
                                                            </span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>


                    </div>
                </td>
            </tr>
        </>
    )
}

export default DeductionVIA