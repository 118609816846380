import React, { useEffect, useState } from 'react';
import { BASE_PATH } from '../../../app/constants';
import SkeletonGraphCircle from '../../../components/Skeleton/SkeletonGraphCircle';
import { Skeleton } from '@mui/material';
import SkeletonQrtliability from '../../../components/Skeleton/SkeletonQrtliability';
import ShowAlert from '../../../components/ShowAlert';
import { gocallApi } from '../../../api/issueCertificateAPI';
import useIntersection from '../../../utils/useIntersection';
import AddDeductee from '../../deducteeList/AddDeductee';
import NoDataImg from '../../../images/docu_icons.png'
import AddButton from '../../../images/icons/add_btn_round.svg';
import LastFiveQtrsData from './LastFiveQtrsData';

import ReactApexChart from 'react-apexcharts';


const QuarterLiabilityPaid = (props) => {

    const { deductor_Id, qurtliabilityRef, financialYear, totalDeductee, chartData,
         getPieChartData, error1, setError1, chartDataSkeleton, setStartFilingPopup } = props;

    const inViewport = useIntersection(qurtliabilityRef, "0px");

    const defaultOptionPan = {
        series: chartData?.tracesPan?.statusWiseValue ?? [],
        options: {
            chart: {
                width: 380,
                type: 'donut',
                dropShadow: {enabled: true,color: '#111',top: -1,left: 3,blur: 3,opacity: 0.2},
                events: {
                    
                    mounted:(chart, options) => {
                        const labels = options?.config.labels ?? [];
                        const index = labels.findIndex((key) => key === "Unverified");
                        // not showing label value in donut chart in center and label default selected
                        // Call toggleDataPointSelection 3 times, it works !
                        if(index >= 0){
                            setTimeout(() => {
                                chart.toggleDataPointSelection(index);
                                chart.toggleDataPointSelection(index);
                                chart.toggleDataPointSelection(index);
                            }, 400)
                            
                        }
                        
                    },
                 
                },
            },
            stroke: {width: 0},
            plotOptions: {pie: {donut: {labels: {show: true, name: {show: true, fontSize: "14px"}, value:{show: true,  fontSize: "14px"}, total: {showAlways: false,show: false}}}}},
            colors: chartData?.tracesPan?.statusWiseColor ?? [],
            labels: chartData?.tracesPan?.status ?? [],
            dataLabels: {dropShadow: {blur: 3,opacity: 0.8}},
            fill: {type: 'pattern',opacity: 1,
                pattern: {
                    enabled: true, style: chartData?.tracesPan?.pattern ?? [],
                },
            },
            states: {hover: {filter: 'none'}},
            theme: {palette: 'palette2'},
            responsive: [{breakpoint: 480,options: {chart: {width: 200},legend: {position: 'bottom'}}}]
        },
    };

    const defaultOptionCompliance = {
        series: chartData?.complianceCheck?.statusWiseValue ?? [],
        
        options: {
            chart: {
                width: 380,
                type: 'donut',
                dropShadow: {enabled: true,color: '#111',top: -1,left: 3,blur: 3,opacity: 0.2},
                events: {
                    
                    mounted:(chart, options) => {
                        const labels = options?.config.labels ?? [];
                        const index = labels.findIndex((key) => key === "Unverified");
                        // not showing label value in donut chart in center and label default selected
                        // Call toggleDataPointSelection 3 times, it works !
                        if(index >= 0){
                            setTimeout(() => {
                                chart.toggleDataPointSelection(index);
                                chart.toggleDataPointSelection(index);
                                chart.toggleDataPointSelection(index);
                            }, 400)
                        }
                        
                    },
                 
                },
            },
            stroke: {width: 0},
            colors: chartData?.complianceCheck?.statusWiseColor ?? [],
            plotOptions: {
                pie: {

                donut: {labels: {show: true, name: {show: true, fontSize: "14px"}, value:{show: true,  fontSize: "14px"}, total: {showAlways: false,show: false}}}
            }},
            labels: chartData?.complianceCheck?.status ?? [],
            dataLabels: {dropShadow: {blur: 3,opacity: 0.8}},
            fill: {type: 'pattern',opacity: 1,
                pattern: {
                    enabled: true, style: chartData?.complianceCheck?.pattern ?? [],
                },
            },
            states: {hover: {filter: 'none'}},
            theme: {palette: 'palette2'},
            responsive: [{breakpoint: 480,options: {chart: {width: 200},legend: {position: 'bottom'}}}]
        },
    };

    const [qurtLiabSkeleton, setQurtLiabSkeleton] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [barChartData, setBarChartData] = useState([]);
    const [quarterLabel, setQuarterLabel] = useState([])


    const getMonthIndex = (qtrNo, year, bar) => {
        const last5QtrLabel = bar;
        let selectedIndex = 0;

        for (let i = 0; i < last5QtrLabel.length; i++) {
            if (qtrNo === last5QtrLabel[i].substring(0, 2) || year === last5QtrLabel[i].substring(5, 9)) {
                selectedIndex = i;
                break;
            }
        }
        return selectedIndex;
    }

    const setSctionWiseData = (data, bar) => {

        if (data.length === 0) return [];
        const list = {};
        data.forEach((item) => {

            if (!list?.[item.financial_year]) {
                list[`${item.financial_year}-${item.quarter}`] = item.quarter;
            }
        })

        const newList = [];
        Object.keys(list).forEach((key, index) => {
            const obj = { name: `${key}`, data: [0, 0, 0, 0, 0] }
            data.forEach((subItem) => {
                if (Number(key) === String(subItem.financial_year).substring(0, 4) || list[key] === subItem.quarter) {
                    if (obj.data[getMonthIndex(subItem.quarter, subItem.financial_year, bar)] !== undefined) {
                        obj.data[getMonthIndex(subItem.quarter, subItem.financial_year, bar)] = subItem.challan_paid_amtp;
                    }
                }
            })
            newList[key] = obj
        })

        const sectionWiseData = [];
        Object.keys(newList).forEach((item) => {
            sectionWiseData.push(newList[item]);
        })

        return sectionWiseData;
    }

    const getBarChartData = async (deductor_Id, financialYear) => {

        const url = `/api/v1/report/deductor/quarter-wise-challan-paid?deductor_id=${deductor_Id}&quarters=${5}&financial_year=${financialYear}`;

        try {
            const result = await gocallApi("get", url);
            let isBarChart = false;
            let bar = []

            if (result?.data?.length > 0) {



                result.data.forEach((item, index) => {
                    const currFY = item.financial_year ?? 0;
                    const nextFY = item.financial_year ? String(item.financial_year).substr(2, 2) : 0;
                    bar.push(`${item.quarter ?? ""} | ${currFY}-${Number(nextFY) + 1}`)
                    if (item.challan_paid_amtp) isBarChart = true;
                })
            }

            if (isBarChart) {
                setBarChartData(setSctionWiseData(result?.data, bar));
                setQuarterLabel(bar)
            }
            else {
                setBarChartData([]);
            }

        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setQurtLiabSkeleton(false);
        }
    }

    const viewdeatailreport = () => {
        window.open(`${BASE_PATH}deductee-list?deductor_id=${deductor_Id}&financial_year=${financialYear}`, "_blank")
    }

    const onClose = (reload, msg = '') => {
        setShowCreateForm(false);
        if (msg) {
            setSuccess(msg);
        }

        if (reload) {
            setTimeout(() => {
                getPieChartData(deductor_Id);
            }, 300);
        }
    };


    useEffect(() => {
        if (inViewport) {
            getBarChartData(deductor_Id, financialYear)
        }
        //eslint-disable-next-line
    }, [inViewport, deductor_Id, financialYear])

    return (
        <>

            <ShowAlert
                success={success}
                error={error || error1}
                onClose={() => { setSuccess(null); setError(null); if (error1) setError1(false) }}
            />


            {showCreateForm &&
                <AddDeductee
                    onClose={onClose}
                    deductorId={deductor_Id}
                />
            }

            <div className="col-md-6 mt-3">
                <fieldset className="dash_quarterliability_fs" style={{ height: "350px" }}>
                    <legend className="dash_quarterliability_lg">
                        <h4 className="dash_quarterliability_tital">
                            Liability of Last Five Quarters
                        </h4>
                    </legend>
                    {qurtLiabSkeleton ? <SkeletonQrtliability /> :
                        barChartData.length > 0 ?
                            <div className="quarter_chartbox text-center">
                                <LastFiveQtrsData data={barChartData} quarterLabel={quarterLabel} setStartFilingPopup={setStartFilingPopup} />
                            </div> :
                            <div className="container mt-2">
                                <div className="row">
                                    <div className='col-md-4'>
                                        <div className="browserbutton_box browser_liability_chartbgimg">
                                        </div>
                                        
                                    </div>
                                    <div className='col-md-8 text-center'>
                                        <div className="browserbutton_box pt-3">
                                            <div className="clickaablebtn_box pt-4">
                                                <span className="clickablebtn">
                                                    <img src={NoDataImg} alt="No data" width="100" onClick={() => setStartFilingPopup(true)} />
                                                </span>
                                                <p className="No_Records_to_Display_textfs mb-1 pt-0">
                                                    No Records to Display
                                                </p>
                                                <p className="No_Records_to_Display_textfs" onClick={() => setStartFilingPopup(true)}>
                                                    To Create/Import Return use <img src={AddButton} alt="" width={'16px'} style={{ cursor: 'pointer' }} /> button
                                                </p>
                                            </div>
                                        </div>
                                        
                                    </div>


                                </div>

                            </div>
                    }
                </fieldset>
            </div>

            <div className="col-md-6 mt-3">
                <fieldset className="dash_createdeductee_fs" style={{ height: "350px" }}>
                    <legend className="dash_createdeductee_lg">
                        <div className="Deductor_dashboard_inndev">
                            <div className="Deductor_dashboard_lefts">
                                {chartDataSkeleton
                                    ? <Skeleton animation="wave" variant="text" width={"20%"} height={"30px"} />
                                    : <h4> Total Deductees ({`${totalDeductee}`})</h4>
                                }
                            </div>
                            <div className="Deductor_dashboard_inner"></div>
                            <div className="Deductor_dashboard_right">
                                <button className="btn btn-default dash_createdeductee_btnbgclr planexpiredalert" onClick={() => viewdeatailreport()}>
                                    View All
                                </button>
                            </div>
                            <div className="Deductor_dashboard_middleborder"></div>
                        </div>
                    </legend>

                    {chartDataSkeleton ? <SkeletonGraphCircle /> :
                        <div className="text-center">
                            {totalDeductee > 0 ?
                               
                                <div className='row mt-1'>
                                    <div className='col-md-6'>
                                        <ReactApexChart options={defaultOptionCompliance.options} series={defaultOptionCompliance.series} type="donut" width={350} />

                                        <div className="row pt-1">
                                            <div className="col-md-12 text-center">
                                                <div className="quterliabilitypaid_cardtext quterliabilitypaid_toppadd ">
                                                    <span className="quterliabilitypaid_cardtitlefs">
                                                        Compliance Check
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <ReactApexChart options={defaultOptionPan.options} series={defaultOptionPan.series} type="donut" width={350} />

                                        <div className="row pt-3">
                                            <div className="col-md-12 text-center">
                                                <div className="quterliabilitypaid_cardtext">
                                                    <span className="quterliabilitypaid_cardtitlefs quterliabilitypaid_rightpadd">
                                                       Pan Verification Status
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                                :
                               
                                <div className="container mt-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="browserbutton_box browser_totaldedcutee_chartbgimg">

                                            </div>
                                            
                                        </div>
                                        <div className="col-md-8 text-center pt-2">
                                            <div className="browserbutton_box">
                                                <div className="clickaablebtn_box totaldeductee_ceontectbox pt-4">
                                                    <span className="clickablebtn" onClick={() => setStartFilingPopup(true)}>
                                                        <img src={NoDataImg} alt="No data" width="100" />
                                                    </span>
                                                    <p className="No_Records_to_Display_textfs mb-2 pt-2">
                                                        No Records to Display
                                                    </p>
                                                    <p className="No_Records_to_Display_textfs">
                                                        To Create/Import Deductees use   <img src={AddButton} alt="" width={'16px'} style={{ cursor: 'pointer' }} onClick={() => setStartFilingPopup(true)} /> button
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            }
                        </div>}

                </fieldset>

            </div>
        </>
    )
}

export default QuarterLiabilityPaid