import React from 'react';
import Modal from 'react-bootstrap/Modal';

const PlanInquiryPopup = (props) => {
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName="yourtrialexpirpoup"
                keyboard={false}
                className="yourtrialexpirpoup talkpopup"
            >
                <Modal.Header closeButton className="yourtrialexpirhead">
                    <Modal.Title className="trialexpttle">Talk to Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <label className="youtrialexptextfs" for="subject">Subject<span style={{ color: 'red' }}>*</span></label>
                                <input className="plantalksubjectInput" id="subject" type="text" name="subject" maxLength={75} onChange={props.handleChange} value={props.mailMessage.subject} />
                                <span style={{ fontSize: '12px', color: 'red' }}>{props.mailMessage.subjectError}</span>
                            </div>
                            <div>
                                <label className="youtrialexptextfs" for="body">Body<span style={{ color: 'red' }}>*</span></label>
                                <textArea className="plantalk_body_textarea" id="body" name='body' maxLength={300} onChange={props.handleChange}>{props.mailMessage.body}</textArea>
                                <span style={{ fontSize: '12px', color: 'red' }}>{props.mailMessage.bodyError}</span>
                            </div>
                        </div>
                        <div className="col-md-12 text-center pt-2 pb-1">

                            <button type="button" style={{ marginRight: "9px" }} onClick={() => props.submitPlanEnquiryOnServer()} className="btn btn-default Proceedbtntextclr">Submit</button>
                            <button type="button" onClick={() => {
                                props.setVisible(false)
                                props.setMailmessage({ subject: '', subjectError:'', body: '', bodyError:'' })
                            }}
                                className="btn btn-default Proceedbtntextclr">
                                Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PlanInquiryPopup