import React from "react";
import Fixed220Interest from "./Fixed220Interest";
import Interest220TillDate from "./Interest220TillDate";
import BalanceInterest220 from "./BalanceInterest220";


const Interest220 = (props) => {

    const {apiData} = props;

    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab21" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">

                    <Fixed220Interest
                        apiData={apiData?.list_fixed}
                    />

                    <Interest220TillDate
                        apiData={apiData?.list_dop}
                    />

                    <BalanceInterest220
                        apiData={apiData?.summary_bal}
                    />

                </div>
            </div>
        </div>
    )

};

export default Interest220;