import React from 'react';
import './RaiseRequestList.css';
import RaiseRequest from './raiseRequest/RaiseRequest';

const RaiseRequestList = (props) => {
  const { deductorId } = props.match.params;

  return (
    <div className="bgclrbody">
      <div className="container">
        <RaiseRequest deductorId={deductorId} history={props.history} />
      </div>
    </div>

  );
};

export default RaiseRequestList;
