import * as React from 'react';
//import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import $ from 'jquery';
import { setTimeout } from 'timers';

export class PanFilterUI extends React.Component {

    componentDidMount = () => {

        let self = this;

        if (this.props.secondFilterProps && !this.props.secondFilterProps.value) {

            this.props.secondFilterProps.onChange({
                value: this.props.secondFilterProps.value,
                operator: 'in'
            });

            setTimeout(function () {
                let event = {
                    target: { value: { text: "Or", operator: "or" } }
                };
                self.props.onLogicChange(event);
            }, 300);
        }

        $(document).on('keydown', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                $("form.k-filter-menu button.k-primary").click();
            }
        });

    }

    handleFirstValueChange = (event) => {
        let value = event.value.value ? event.value.value : event.value;
        if (Array.isArray(event.value)) {
            value = [];
            event.value.map((event_val, index) => {
                value.push(event_val.value);
                return true;
            })
            value = value.length > 0 ? value : '';
        }
        const { firstFilterProps } = this.props;
        firstFilterProps.onChange({
            value,
            operator: firstFilterProps.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleSecondValueChange = (event) => {
        let value = event.value.value ? event.value.value : event.value;
        if (Array.isArray(event.value)) {
            value = [];
            event.value.map((event_val, index) => {
                value.push(event_val.value);
                return true;
            })
        }
        const { secondFilterProps } = this.props;
        secondFilterProps.onChange({
            value,
            operator: secondFilterProps.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleFirstOperatorChange = (event) => {
        const value = event.value
        const { firstFilterProps } = this.props;
        firstFilterProps.onChange({
            value: value.operator === 'in' ? [] : '',
            operator: value.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleSecondOperatorChange = (event) => {
        const value = event.value
        const { secondFilterProps } = this.props;
        secondFilterProps.onChange({
            value: value.operator === 'in' ? [] : '',
            operator: value.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    render() {

        let { filterList, firstFilterProps, secondFilterProps, logicData, logicValue, onLogicChange } = this.props;

        firstFilterProps.operators.map((operator, index) => { if (operator.operator === 'in') { firstFilterProps.operators[index].text = 'In'; } return true; });

        secondFilterProps.operators.map((operator, index) => { if (operator.operator === 'in') { secondFilterProps.operators[index].text = 'In'; } return true; });

        let firstOperator = firstFilterProps.operators.filter((operator) => { return operator.operator === firstFilterProps.operator });

        let secondOperator = secondFilterProps.operators.filter((operator) => { return operator.operator === secondFilterProps.operator });

        let firstDropdownProps = [];
        let secondDropdownProps = [];
        if (firstOperator[0].operator === 'in' && firstFilterProps.value) {
            firstDropdownProps = filterList.filter((data, index) => { return firstFilterProps.value.includes(data.value); });
        }

        if (secondOperator[0].operator === 'in' && secondFilterProps.value) {
            secondDropdownProps = filterList.filter((data, index) => { return secondFilterProps.value.includes(data.value); });
        }

        return (
            <div className="filter_ui">
                <DropDownList
                    data={firstFilterProps.operators}
                    textField="text"
                    dataItemKey="operator"
                    value={firstOperator[0]}
                    className="filter_ui_select"
                    popupSettings={{ className: 'filter_ui_option' }}
                    onChange={this.handleFirstOperatorChange}
                />

                {firstOperator[0].operator === 'in' ?
                    <MultiSelect data={filterList} textField="label" value={firstDropdownProps} dataItemKey="value" onChange={this.handleFirstValueChange} className="filter_ui_select" popupSettings={{ className: 'filter_ui_option' }} />
                    :
                    <Input value={firstFilterProps.value} onChange={this.handleFirstValueChange} />
                }

                <DropDownList
                    data={logicData}
                    textField="text"
                    dataItemKey="operator"
                    value={logicValue}
                    onChange={(e) => { onLogicChange(e) }}
                    className="filter_ui_select"
                    popupSettings={{ className: 'filter_ui_option' }}
                    style={{ width: '70px' }}
                />


                <DropDownList
                    data={secondFilterProps.operators}
                    textField="text"
                    dataItemKey="operator"
                    value={secondOperator[0]}
                    className="filter_ui_select"
                    popupSettings={{ className: 'filter_ui_option' }}
                    onChange={this.handleSecondOperatorChange}
                />

                {secondOperator[0].operator === 'in' ?
                    <MultiSelect data={filterList} textField="label" value={secondDropdownProps} dataItemKey="value" onChange={this.handleSecondValueChange} className="filter_ui_select" popupSettings={{ className: 'filter_ui_option' }} />
                    :
                    <Input value={secondFilterProps.value} onChange={this.handleSecondValueChange} />
                }



            </div>
        );

    }
}