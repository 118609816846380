/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import './InviteUser.css';
import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import ShowAlert from '../../components/ShowAlert';
import { inviteUser } from '../../api/profileAPI';
import NumericInput from '../../components/NumericInput';

const InviteUser = (props) => {
  const { show, handleClose } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    full_name: '',
    country_code: '+91',
    mobile_no: '',
    email: '',
    employee_no: null,
    designation: null,
    department: null,
  });
  const [formErrors, setFormErrors] = useState({});


  const addUser = async (e) => {
    e.preventDefault();

    const formErr = {};
    if (userData.full_name.trim().length === 0) {
      formErr.full_name = 'Name is required';
    }

    if (userData.mobile_no.trim().length !== 10 || Number(userData.mobile_no) < 6000000000) {
      formErr.mobile_no = 'Invalid Mobile No';
    }


    if (userData.email.trim().length === 0) {
      formErr.email = 'Email is required';
    }

    setFormErrors(formErr);

    if (Object.keys(formErr).length > 0) return;

    setLoading(true);
    const data = {
      ...userData,
    };
    try {
      const result = await inviteUser(data);
      setSuccess(result.message);
      setTimeout(() => {
        handleClose()
      }, 2000);
    } catch (err) {
      setError(err.message);
      console.error('Error in inviting user: ', err);
    }
    setLoading(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className=""
    >
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <Modal.Header closeButton className="headerinvite">
        <Modal.Title className=" text-center addpusecent justify-content-center">Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="container">
          <form onSubmit={(e) => addUser(e)} autoComplete="off">
            <div className="row">
              <div className="col-sm-12">
                <div className="row mt-2">

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">
                          Name
                          <span className="red_text">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          value={userData.full_name}
                          onChange={(e) => setUserData({ ...userData, full_name: e.target.value })}
                        />
                        {formErrors.full_name ? <span className="error_message">{formErrors.full_name}</span> : null}
                      </div>
                      <div className="form-group col-sm-4">
                        <label htmlFor="exampleFormControlSelect1">Mobile Number</label>
                        <span className="red_text">*</span>
                        <div className="input-group ">
                          <div className="input-group-prepend inpgpbx profileuseraddmob">
                            <img className="flag_icon" src={Indiaflagexp} alt="mobile" />
                          </div>
                          <NumericInput
                            type="tel"
                            className="form-control"
                            name="Mobile"
                            placeholder="Enter Mobile Number"
                            maxLength="10"
                            value={userData.mobile_no}
                            onChange={(e) => setUserData({ ...userData, mobile_no: e.target.value })}
                          />
                        </div>
                        {formErrors.mobile_no ? <span className="error_message">{formErrors.mobile_no}</span> : null}
                      </div>
                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">
                          Email Address
                          <span className="red_text">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Address"
                          autoComplete="new-password"
                          value={userData.email}
                          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        />
                        {formErrors.email ? <span className="error_message">{formErrors.email}</span> : null}
                      </div>

                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">Employee No.</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="Enter Employee No."
                          value={userData.employee_no}
                          onChange={(e) => setUserData({ ...userData, employee_no: e.target.value })}
                        />
                      </div>
                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">Designation</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Designation"
                          value={userData.designation}
                          onChange={(e) => setUserData({ ...userData, designation: e.target.value })}
                        />
                      </div>
                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">Department</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Department"
                          value={userData.department}
                          onChange={(e) => setUserData({ ...userData, department: e.target.value })}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="text-center mb-2">
                        <button className="light_color_bg inviteuser_btnbgclr" type="submit" disabled={loading}>
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              {' '}
                              Loading...
                            </>
                          ) : 'Save'}
                        </button>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>
          </form>
        </div>

      </Modal.Body>

    </Modal>
  );
};

export default InviteUser;
