import React from 'react';
import WindowWrapperFixed from '../../components/grid/WindowWrapperFixed';
import "./BulkImport.css";
import { useHistory } from 'react-router-dom';
import { BASE_PATH } from '../../app/constants';

const AutoScanImportStats = (props) => {

    const { onClose, data } = props;

    const history = useHistory();

    const handlesuccess = () => {
        onClose();
        history.push(`${BASE_PATH}list/incomplete`)
    }

    return (

        <WindowWrapperFixed
            title={<kendo-dialog-titlebar>
                <h4 className='my-3' style={{ fontSize: "16px" }}>
                    Import Status
                </h4>
            </kendo-dialog-titlebar>}
            onClose={onClose}
            totalPopups={0}
            initialTop={50}
            index={1}
            className={`creaeclient_ImportSt`}
            initialHeight={'auto'}
            style={{ maxHeight: 'auto', overflowX: 'hidden' }}
            initialWidth={700}
            initialLeft={(((window.innerWidth) - 700) / 2)}
            isModalView={true}
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <table className="table table-striped tds_blukimportdedcut_tb mb-0">
                            <thead>
                                <tr>
                                    <td className="text-center">Total Files</td>
                                    <td className="text-center">Files Imported</td>
                                    <td className="text-center">Files not Imported due to Invalid Format</td>
                                    <td className="text-center">Duplicate TAN (not Imported)</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">{Number(data?.total_file_count ?? 0)}</td>
                                    <td className="text-center">{Number(data?.valid_file_count ?? 0)}</td>
                                    <td className="text-center">{Number(data?.total_file_count ?? 0) - Number(data?.valid_file_count ?? 0)}</td>
                                    <td className="text-center">{Number(data?.valid_file_count ?? 0) - Number(data?.unq_data_count ?? 0)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-12 pt-3 text-center">
                    <button className="btn btn-default blukimp_deduct_btnbgclr" onClick={() => handlesuccess()}>Okay</button>
                </div>
            </div>
        </WindowWrapperFixed>

    );
}

export default AutoScanImportStats;
