import React, { useState } from 'react';
import { Skeleton } from '@mui/material'
import { Tooltip } from '@progress/kendo-react-tooltip';
import eyeIcon from '../../../images/eyeiconcolr.png';
import ConnectorAdapter from '../../../components/kdkConnector/ConnectorAdapter';
import TracesConnector from '../../../components/kdkConnector/TracesConnector';
import VerifyLdcCertificiate from './VerifyLdcCertificiate';
import ShowAlert from '../../../components/ShowAlert';


const LowerDeductionCert = (props) => {

  const { returnId, deductorId, showSkeleton, handleLDCPopupShow, ldcSummary, formType, getLdcDataForReturn } = props;

  const [startReVerifyLdc, setStartReVerifyLdc] = useState(false);
  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [startLdcVerifyProcess, setStartLdcVerifyProcess] = useState(false);
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const getStatusWiseContent = (ldcSummary) => {

    if (Number(ldcSummary?.total ?? 0) === 0) {

      return (
        <p className="comphealth_LDCbox_textfs textclr_coral pt-5 text-center">
          You have no LDC cases in this return.
        </p>
      )

    } else {

      return (
        <>
          {
            Number(ldcSummary?.total ?? 0) > 0 && Number(ldcSummary?.total ?? 0) === Number(ldcSummary?.verified ?? 0)
              ?
              <>
                <p className="comphealth_LDCbox_textfs textclr_coral pt-2 text-center">
                  <span className='black_color_text'>Certificate found for total</span> <span className='coral_link' onClick={() => handleLDCPopupShow('ALL')}>{`${ldcSummary?.total}`}</span>  <span className='black_color_text'>dedcutee transaction/s.</span>
                </p>
                <p className="comphealth_LDCbox_textfs textclr_coral pt-2 text-center">
                  <span className='black_color_text'>All of them are Verified</span> <span className='coral_link' onClick={() => handleLDCPopupShow('ALL')}><img src={eyeIcon} width={18} alt="" /></span>
                </p>
              </>
              : <p className="comphealth_LDCbox_textfs textclr_coral pt-3 text-center">
                <span className='black_color_text'>You have</span> <span className='coral_link' onClick={() => handleLDCPopupShow('ALL')}>{`${ldcSummary?.total}`}</span> <span className='black_color_text'>transactions in this return.</span>
              </p>
          }

          {
            Number(ldcSummary?.verified ?? 0) > 0 && Number(ldcSummary?.verified ?? 0) < Number(ldcSummary?.total ?? 0) &&
            <p className="comphealth_LDCbox_textfs textclr_coral pt-1 text-center">
              <span className='coral_link' onClick={() => handleLDCPopupShow('VALID')}>{Number(ldcSummary?.verified)}</span> <span className='black_color_text'>out of</span> <span className='coral_link' onClick={() => handleLDCPopupShow('ALL')}> {Number(ldcSummary?.total)}</span> <span className='black_color_text'>Trans. are Verified.</span>
            </p>
          }


          {
            Number(ldcSummary?.total ?? 0) > 0 && Number(ldcSummary?.total ?? 0) === Number(ldcSummary?.unverified ?? 0) &&
            <p className="comphealth_LDCbox_textfs textclr_coral pt-1 text-center">
              No Transactions are verified
            </p>
          }

          {
            Number(ldcSummary?.relooked) > 0 &&
            <p className="comphealth_LDCbox_textfs textclr_coral pt-1 text-center">
              <span className='coral_link' onClick={() => handleLDCPopupShow('RELOOKED')}>{Number(ldcSummary?.relooked) + Number(ldcSummary?.unverified)} </span> <span className='black_color_text'>Transactions need to be relooked.</span>
            </p>
          }

        </>
      )
    }

  }

  const renderView = () => {
    return (
      <fieldset className="comphealth_LDCbox_fs h-100 px-2 mt-1">
        <legend className="comphealth_LDCbox_leg">
          <Tooltip anchorElement="pointer" position="top">
            <div className="comphealth_LDCbox_inndev">
              <div className="comphealth_LDCbox_leftside">
                <h4>Lower Deduction Cert</h4>
              </div>
              <div className="comphealth_LDCbox_innerhieghtdiv"></div>
              <div className="comphealth_LDCbox_rightsidediv">
                {
                  Number(ldcSummary?.total ?? 0) === 0
                    ? <button className="btn btn-default comphealth_LDCbox_verybtn disabled">Verify</button>
                    : <button className="btn btn-default comphealth_LDCbox_verybtn" onClick={() => setStartReVerifyLdc(true)} data-toggle="tooltip" data-placement="top" title="Click to Verify">Verify</button>
                }

              </div>
              <div className="comphealth_LDCbox_middborder"></div>
            </div>
          </Tooltip>
        </legend>
        <div className="comphealth_LDCbox_innercontentbox">
          {
            showSkeleton
              ? <>
                <p><Skeleton animation="wave" variant="rounded" width={"100%"} height={"25px"} /></p>
                <p><Skeleton animation="wave" variant="rounded" width={"100%"} height={"25px"} /></p>
                <p><Skeleton animation="wave" variant="rounded" width={"100%"} height={"25px"} /></p>
              </>
              : getStatusWiseContent(ldcSummary)}
        </div>
      </fieldset>
    )
  }

  return (
    <>
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {
        startReVerifyLdc
          ? <ConnectorAdapter
            show={startReVerifyLdc}
            handleClose={() => setStartReVerifyLdc(false)}
            setPort={setPort}
            handleProceed={() => setStartLdcVerifyProcess(true)}
            title={'Login at Income Tax Portal'}
            section={'EFILING'}
          />
          : null
      }
      {startLdcVerifyProcess && port
        ?
        <TracesConnector
          deductorId={deductorId}
          returnId={returnId}
          handleProceed={() => {
            setStartReVerifyLdc(false);
            setStartLdcVerifyProcess(false);
          }}
          port={port}
          setCaptchaRef={setCaptchaRef}
          setVerifyCreds={() => null}
          creds={null}
        />
        : null
      }

      {captchaRef && port ? (
        <VerifyLdcCertificiate
          deductorId={deductorId}
          returnId={returnId}
          port={port}
          captchaRef={captchaRef}
          setError={setError}
          setSuccess={(msg) => {
            setSuccess(msg);
            setTimeout(() => {
              window.location.reload();
            }, 5000)
          }}
          getLdcDataForReturn={getLdcDataForReturn}
        />
      ) : null
      }
      {
        formType === '24Q'
          ? renderView()
          : <div className="col-md-5 pt-1">{renderView()}</div>
      }
    </>
  )

};

export default LowerDeductionCert;