/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './ConfirmPopup.css';

function AlertMessagePopup(props) {
  const {
    show, handleClose, bodyText, headingText, closeButtonText,
  } = props;

  return (
    <Modal className="areusuredelmbox" show={show} onHide={handleClose}>
      {headingText ? (
        <Modal.Header>
          <Modal.Title>{headingText}</Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body><p>{bodyText ?? ''}</p></Modal.Body>
      <Modal.Footer className="areusuredelmboxF">
        <Button variant="secondary" onClick={handleClose}>
          {closeButtonText ?? 'Done'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlertMessagePopup;
