import React from 'react';
import Modal from 'react-bootstrap/Modal';

const UnVerifyEmailPopup = (props) => {
      const {unVerifiedEmailPopup, handleClose,verifiedEmail} = props;

    return (
        <>
            <Modal className="unverify_email_address_modal " backdrop="static"  show={true} onHide={handleClose}>
                <Modal.Header closeButton className="unverify_email_address_header">
                    <Modal.Title className="unverify_email_address_title" >Unverified Email Address</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="unverify_email_address_textfs text-center">
                                The Email Address corresponding to the logged in account is not yet verified. Please verify your Email Address to proceed further.
                            </p>
                        </div>
                        <div className="col-md-12 text-center">
                            <button className="unverify_email_address_emailidbgclr mr-1 mb-1" onClick={() => verifiedEmail(unVerifiedEmailPopup)}  >I'll do it now</button>
                            <button className="unverify_email_address_emailidbgclr" onClick={() => handleClose()} >Not now</button>
                        </div>

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default UnVerifyEmailPopup
