import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../verifyPAN/UpdateTracesCredential.css';
import ShowAlert from '../ShowAlert';
import { editDeductor } from '../../api/deductorAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../features/header/headerSlice';
import { verifyEFilingCredsFromConnector } from '../../api/localConnectorAPI';
import {importChallanFromIncomeTaxPortal} from '../../api/deductorAPI';
import {CONNECTOR_ERRORS_MAPPING} from '../../app/constants';

const UpdateIncomeTaxCredential = (props) => {
  const { show, handleClose, handleProceed, deductorId, makePaymentByConnector, setPswrd, port} = props;

  const headerDataDetails = useSelector(selectHeaderData);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [password, setPassword] = useState('');


  const [showTracesPassword, setShowTracesPassword] = useState(false);
  /* end js traces cred.popup password section */

  const updateCredentials = () => {
    const request = {      
      efilling_password: password,
      deductor_id: deductorId,
      deductor_credentials: [
        {
          username: headerDataDetails.tan,
          password: password,
          credential_type: "EFILING",
          verified: true
        },
      ],
    }
    async function fetchEverything() {
      try {
        const result = await editDeductor(request);
        if (result.status === 1) {          
          // handleProceed();
          makePaymentByConnector();
          setPswrd(password)
        }

      } catch (err) {
        console.error('error in updating credentials: ', err);
      }
    }
    fetchEverything();
  };

  useEffect(() => {
    setPassword(password);
  }, [password]);



  const verifyLogin = () => {

    const requestParams = {
      'itdportal_username': headerDataDetails.tan,
      'itdportal_password': password
    }

    async function fetchEverything() {
      try {
        const result = await verifyEFilingCredsFromConnector(port, requestParams);
        
        const importChallanRequestData = {
          "deductor_id": deductorId,
          "captcha_ref": result.data.captcha_ref ?? '',              
        }
        
        const verifyResult = await importChallanFromIncomeTaxPortal(importChallanRequestData);

        if (verifyResult.data.status === 1) {
          updateCredentials();
          handleProceed();
        }
        


      }catch(err) {
        const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message;
        setError(errmsg);     
        
      }
    }
    fetchEverything();
    }

  
  const submitForm = (e) => {
    e.preventDefault();
    const fErrors = {};
    if (!password) fErrors['password'] = 'Password is required';

    setFormErrors(fErrors);
    if (Object.keys(fErrors).length > 0) return;

    //updateCredentials();
    verifyLogin();
    
  };

  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="tracescredentials"
      >
        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title>Income Tax Credentials</Modal.Title>
        </Modal.Header>

        <ShowAlert
            error={error}
            success={success}
            onClose={() => { setError(null); setSuccess(null); }}
          />       
        
        <form onSubmit={submitForm}>
          <Modal.Body className="pb-0 px-0">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <p className="mb-0 pb-0">Please provide Income Tax Password below </p>
                  <form className="form-horizontal">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <div className="form-group">
                          <label className="control-label" htmlFor="">Username </label>
                          <input
                            type="search"
                            className="form-control"
                            id="username"
                            name="username"
                            placeholder="Enter Username"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                          />
                          {formErrors.username ? <span className="error_message">{formErrors.username}</span> : null}
                        </div> */}

                        {/*traces cred.popup password section */}
                        <div className="form-group">
                          <label htmlFor="password">
                            Password
                          </label>
                          {showTracesPassword ? (
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="password"
                                placeholder="Enter Password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <div
                                className="input-group-prepend eyeipbox-create-deductor"
                                onClick={() => setShowTracesPassword(false)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </div>
                            </div>
                          ) : (
                            <div className="input-group mb-3">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Enter Password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                              />
                              <div
                                className="input-group-prepend eyeipbox-create-deductor"
                                onClick={() => setShowTracesPassword(true)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </div>
                            </div>
                          )}
                          {formErrors.password ? <span className="error_message">{formErrors.password}</span> : null}
                        </div>
                        {/* end traces cred.popup password section */}                    
                      </div>

                    </div>

                  </form>
                </div>

              </div>

            </div>
          </Modal.Body>
          <Modal.Footer className="tracecredfoot">
            <Button type="submit" className="tracesbtnsubmit" onClick={submitForm}> Submit  </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>

  );
};
export default UpdateIncomeTaxCredential;
