import { salaryDetailsInitializeData, otherIncomeInitializeData, otherInitializeData } from './initializeData';

export const resetInitialData = (data) => {
    if (data.allowances && data.allowances.length) {
        data.allowances.map((allowances, index) => {
            if (!allowances.allowance_type_id) {
                data.allowances[index] = salaryDetailsInitializeData.allowances[0];
            }
            return true;
        });
    }
    else {
        data.allowances = salaryDetailsInitializeData.allowances;
    }

    if (data.deduction_vi_a_details && data.deduction_vi_a_details.length) {
        data.deduction_vi_a_details.map((deduction_vi_a_details, index) => {
            if (!deduction_vi_a_details.code) {
                data.deduction_vi_a_details[index] = otherIncomeInitializeData.deduction_vi_a_details[0];
            }
            return true;
        });
    }
    else {
        data.deduction_vi_a_details = otherIncomeInitializeData.deduction_vi_a_details;
    }

    if (data.other_income_offered_details && data.other_income_offered_details.length) {
        data.other_income_offered_details.map((other_income_offered_details, index) => {
            if (!other_income_offered_details.particular_code) {
                data.other_income_offered_details[index] = otherIncomeInitializeData.other_income_offered_details[0];
            }
            return true;
        });
    }
    else {
        data.other_income_offered_details = otherIncomeInitializeData.other_income_offered_details;
    }


    if (data.other_details.lender_details && data.other_details.lender_details.length) {
        data.other_details.lender_details.map((lender_details, index) => {
            if (!lender_details.name_of_lender) {
                data.other_details.lender_details[index] = otherInitializeData.lender_details[0];
            }
            return true;
        });
    }
    else {
        data.other_details.lender_details = otherInitializeData.lender_details;
    }


    if (data.other_details.landlord_details && data.other_details.landlord_details.length) {
        data.other_details.landlord_details.map((landlord_details, index) => {
            if (!landlord_details.name_of_landlord) {
                data.other_details.landlord_details[index] = otherInitializeData.landlord_details[0];
            }
            return true;
        });
    }
    else {
        data.other_details.landlord_details = otherInitializeData.landlord_details;
    }
    return data;
}



export const trimData = (data) => {
    let result = data;
    let save_type = result.save_type;
    if (result.allowances && result.allowances.length) {
        result.allowances = result.allowances.filter((allowances, index) => {
            return parseInt(allowances.allowance_type_id) > 0;
        });
    }
    if (!result.salary_id) {
        delete result.salary_id;
    }
    delete result.save_type;
    delete result.deductee_name;
    delete result.pan;
    delete result.salary_category;
    delete result.is_government_deductor;
    delete result.total_gross_salary_amtp;
    delete result.total_allowance_exempt_amtp;
    delete result.total_deduction_amtp;
    delete result.net_salary_amtp;
    delete result.total_house_property_income_amtp;
    delete result.total_other_income_offered_amtp;
    delete result.total_gross_income_amtp;
    delete result.total_deduction_vi_a_amtp;
    return [result, save_type];
}


export const getInitializeData = (deductor_id, is_government_deductor, financial_year) => {

    salaryDetailsInitializeData.salary_breakup_amtp = {
        "4": 0,
        "5": 0,
        "6": 0,
        "7": 0,
        "8": 0,
        "9": 0,
        "10": 0,
        "11": 0,
        "12": 0,
        "1": 0,
        "2": 0,
        "3": 0
    };
    salaryDetailsInitializeData.allowances = [{
        allowance_type_id: 0,
        exempt_amtp: 0,
        allowance_details: null,
    }];
    otherIncomeInitializeData.deduction_vi_a_details = [{
        code: "",
        gross_amount_amtp: 0,
        deductible_amount_amtp: 0
    }];
    otherIncomeInitializeData.other_income_offered_details = [{
        particular_code: "",
        amount_amtp: 0
    }];
    otherInitializeData.lender_details = [{
        name_of_lender: '',
        pan_of_lender: '',
        interest_amount_amtp: 0
    }];
    otherInitializeData.landlord_details = [{
        name_of_landlord: '',
        pan_of_landlord: '',
        rent_amount_amtp: 0
    }];
    let salary_data = { ...salaryDetailsInitializeData, ...{ deductor_id: deductor_id, is_government_deductor: is_government_deductor, financial_year: financial_year } }
    //Reset Tax Regime default value for FY >= 2023   
    if(Number(financial_year) >= 2023) {
        salary_data.tax_regime = 'NEW'
    }
    salary_data.employment_period_from = `01/04/${financial_year}`;
    salary_data.employment_period_to = `31/03/${parseInt(financial_year, 10) + 1}`;

    let result = { ...salary_data, ...otherIncomeInitializeData, ...{ other_details: otherInitializeData } };
    return result;
}