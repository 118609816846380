import React, { useState, useEffect } from 'react';
import Skeleton6ColumnGrid from '../../../components/Skeleton/Skeleton6ColumnGrid';
import AddChallan from '../../challanList/AddChallan';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { getTDSComputationSummaryData } from './TDSComputationSummary';
import { Tooltip } from '@progress/kendo-react-tooltip';
import ChallanFeeAllocationPopup from './ChallanFeeAllocationPopup';
import FeeAllocationIcon from '../../../images/unlinkicon_final.gif';

const TDSComputation = (props) => {

  const { returnId, deductorId, tdsComputationSummary, isSkeleton, formType } = props;
  const [interestCheckStatus, setInterestCheckStatus] = useState(true);
  const [feeCheckStatus, setFeeCheckStatus] = useState(true);
  const [showPayButton, setShowPayButton] = useState(true);
  const [summaryData, setSummaryData] = useState({});
  const [challanFormData, setChallanFormData] = useState(null);

  const [showChallanForm, setShowChallanForm] = useState(false);
  const [showChallanFeeAllocationPopup, setShowChallanFeeAllocationPopup] = useState(false);

  const handleCheckStatus = (key, status) => {
    if (key === 'Fees') {
      setFeeCheckStatus(() => status)
    } else if (key === 'Interest') {
      setInterestCheckStatus(() => status)
    }
  }

  const onClose = () => {
    setShowChallanForm(false);
  }

  useEffect(() => {
    setShowPayButton(true);

    if (tdsComputationSummary) {
      const summary = getTDSComputationSummaryData(tdsComputationSummary, interestCheckStatus, feeCheckStatus);
      const challanData = {
        challanDeposited: false,
        tds_amtp: Math.ceil(Number(summary?.tds_cess_surcharse?.depoitChallan) > 0 ? Number(summary?.tds_cess_surcharse?.depoitChallan) : 0),
        interest_amtp: interestCheckStatus ? Math.ceil(Number(summary?.interest?.depoitChallan ?? 0) > 0 ? Number(summary?.interest?.depoitChallan ?? 0) : 0) : 0,
        fees_amtp: feeCheckStatus ? Math.ceil(Number(summary?.fees?.depoitChallan ?? 0) > 0 ? Number(summary?.fees?.depoitChallan ?? 0) : 0) : 0,
      }

      setChallanFormData((prev) => {
        prev = challanData
        return { ...prev }
      })
      setSummaryData((prev) => {
        prev = summary
        return { ...prev }
      })

      //Set Pay button visibility
      if (Object.keys(summary).length === 0) {
        setShowPayButton(false);
      } else if (Number(summary?.tds_cess_surcharse?.depoitChallan) === 0 && Number(summary?.fees?.depoitChallan) === 0 && Number(summary?.interest?.depoitChallan) === 0) {
        setShowPayButton(false);
      }
      if (!interestCheckStatus || !feeCheckStatus) {
        const tds = Number(challanData?.tds_amtp)
        const interest = Number(interestCheckStatus ? challanData?.interest_amtp : 0);
        const fees = Number(feeCheckStatus ? challanData?.fees_amtp : 0);

        if (tds === 0 && interest === 0 && fees === 0) {
          setShowPayButton(false);
        } else {
          setShowPayButton(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [tdsComputationSummary, interestCheckStatus, feeCheckStatus])



  return (
    <>

      {showChallanForm
        ? (
          <AddChallan
            onClose={onClose}
            deductorId={deductorId}
            returnHealthSummary={challanFormData}
          />
        )
        : null
      }

      {
        showChallanFeeAllocationPopup &&
        <ChallanFeeAllocationPopup
          onClose={() => { setShowChallanFeeAllocationPopup(false); }}
          deductorId={deductorId}
          returnId={returnId}
          feeLiability={summaryData?.fees?.libility ?? 0}
          remainingLiability={summaryData?.fees?.unmapped ?? 0}
        />
      }

      <div className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
        <fieldset className="comp_health_returnsummary_fs">
          <legend className="comp_health_returnsummary_lg">
            <div className="comp_health_returnsummary_inndev">
              <div className="comp_health_returnsummary_leftside">
                <h4>
                  {formType === '27EQ' ? 'TCS' : 'TDS'} Computation
                </h4>
              </div>
              <div className="comp_health_returnsummary_innerhieghtdiv"></div>
              <div className="comp_health_returnsummary_rightsidediv">
                <Tooltip anchorElement="pointer" position="right">
                  <div title='Deposit challan' >
                    {
                      showPayButton
                        ? <button data-toggle="tooltip" data-placement="top" className="btn btn-default comp_health_returnsummary_paybtn" id="comp_health_pay_btn" title='Deposit challan' onClick={() => setShowChallanForm(true)}>Pay</button>
                        : <button className="btn btn-default comp_health_returnsummary_paybtn disabled" title='Deposit challan' >Pay</button>
                    }
                  </div>
                </Tooltip>
              </div>
              <div className="comp_health_returnsummary_topbormiddle"></div>
            </div>
          </legend>

          {
            isSkeleton
              ? <Skeleton6ColumnGrid />
              : <>

                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                  <table className="table table-striped tdscomp_tb">
                    <thead>
                      <tr className="tdscomp_tbtoprow">
                        <th width="290">Particulars</th>
                        <th width="150">Liability</th>
                        <th width="130">Mapped</th>
                        <th width="130">Unmapped</th>
                        <th width="130">Challan Avbl.</th>
                        <th width="150">Deposit Challan</th>
                        <th width="10"></th>
                      </tr>
                      <tr className="tdscomp_tb2row">
                        <th></th>
                        <th>(A)</th>
                        <th>(B)</th>
                        <th>(C=A-B)</th>
                        <th>(D)</th>
                        <th>(E=C-D)</th>
                        <th width="10"></th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{formType === '27EQ' ? 'TCS' : 'TDS'}, Cess and Surcharge</td>

                        <td className="text-right">{formatNumber(summaryData?.tds_cess_surcharse?.libility ?? 0)}</td>
                        <td className="text-right">{formatNumber(summaryData?.tds_cess_surcharse?.mapped ?? 0)}</td>
                        <td className="text-right comphealth_textclr_coral">{formatNumber(summaryData?.tds_cess_surcharse?.unmapped ?? 0)}</td>
                        <td className="text-right ">{formatNumber(summaryData?.tds_cess_surcharse?.challanAvl ?? 0)}</td>
                        <td className="text-right">{formatNumber(Number(summaryData?.tds_cess_surcharse?.depoitChallan ?? 0) > 0 ? Number(summaryData?.tds_cess_surcharse?.depoitChallan ?? 0) : 0)}</td>
                        <td className="pl-0 textclr_starred"></td>
                      </tr>
                      <tr>
                        <td>Interest</td>                        
                        <td className={`text-right`}>{formatNumber(summaryData?.interest?.libility ?? 0)}</td>
                        <td className="text-right">{formatNumber(summaryData?.interest?.mapped ?? 0)}</td>
                        <td className="text-right comphealth_textclr_coral">{formatNumber(summaryData?.interest?.unmapped ?? 0)}</td>
                        <td className="text-right">{formatNumber(summaryData?.interest?.challanAvl ?? 0)}</td>
                        <td className={`text-right ${Number(summaryData?.total?.libility ?? 0) > 0 ? 'pr-1' : ''} ${interestCheckStatus ? 'row-strick-1' : 'row-strick-2'}`}>{formatNumber(Number(summaryData?.interest?.depoitChallan ?? 0) > 0 ? Number(summaryData?.interest?.depoitChallan ?? 0) : 0)}
                          {
                            Number(summaryData?.total?.libility ?? 0) > 0 &&
                            <>
                              {'  '}
                              <input type="checkbox" style={{ verticalAlign: 'middle' }} checked={interestCheckStatus} onChange={(e) => handleCheckStatus('Interest', e.target.checked)} />
                            </>
                          }
                        </td>
                        <td className="pl-0 textclr_starred">{Number(summaryData?.total?.libility ?? 0) > 0 && <>*</>}</td>
                      </tr>
                      <tr>
                        <td>Fees / Others</td>
                        <td className={`text-right`}>{formatNumber(summaryData?.fees?.libility ?? 0)}</td>
                        <td className="text-right">{formatNumber(summaryData?.fees?.mapped ?? 0)}</td>
                        <td className="text-right comphealth_textclr_coral">
                        
                          <span class="comphealth_linkicongifbox"> 

                           {
                            Number(summaryData?.fees?.libility ?? 0) > 0 && 
                            <span className='comphealth_textblink'>
                            <img src={FeeAllocationIcon} alt='' width="17" style={{cursor: 'pointer', paddingRight: '2px'}} onClick={() => setShowChallanFeeAllocationPopup(true)} />
                            </span>
                           }                             
                            {formatNumber(summaryData?.fees?.unmapped ?? 0)}
                          </span>
                        </td>
                        <td className="text-right">{formatNumber(summaryData?.fees?.challanAvl ?? 0)}</td>
                        <td className={`text-right ${Number(summaryData?.total?.libility ?? 0) > 0 ? 'pr-1' : ''} ${feeCheckStatus ? 'row-strick-1' : 'row-strick-2'}`}>{formatNumber(Number(summaryData?.fees?.depoitChallan ?? 0) > 0 ? Number(summaryData?.fees?.depoitChallan ?? 0) : 0)}
                          {
                            Number(summaryData?.total?.libility ?? 0) > 0 &&
                            <>
                              {'  '}
                              <input type="checkbox" style={{ verticalAlign: 'middle' }} checked={feeCheckStatus} onChange={(e) => handleCheckStatus('Fees', e.target.checked)} />
                            </>
                          }
                        </td>
                        <td className="pl-0 textclr_starred">{Number(summaryData?.total?.libility ?? 0) > 0 && <>*</>}</td>
                      </tr>

                      <tr className="tdscomp_rowshadow">
                        <td>Total</td>
                        <td className="text-right">{formatNumber(summaryData?.total?.libility ?? 0)}</td>
                        <td className="text-right">{formatNumber(summaryData?.total?.mapped ?? 0)}</td>
                        <td className="text-right">{formatNumber(summaryData?.total?.unmapped ?? 0)}</td>
                        <td className="text-right">{formatNumber(summaryData?.total?.challanAvl ?? 0)}</td>
                        <td className="text-right">{formatNumber(Number(summaryData?.total?.depoitChallan ?? 0) > 0 ? Number(summaryData?.total?.depoitChallan ?? 0) : 0)}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row pt-2">
                  <div className="col-md-12">
                    <p className="starsave_amttextfsclr mb-2"></p>
                  </div>

                </div>
              </>
          }

        </fieldset>
      </div>

    </>

  );
};

export default TDSComputation;
