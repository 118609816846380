import moment from 'moment';

var today = new Date();

export const challanFormFields = {
  challanDeposited: true,
  challan_no: '',
  bsr_code: '',
  cheque_dd_no: '',
  deposit_date: '',
  book_entry: 0,
  tds_amtp: 0,
  surcharge_amtp: 0,
  edu_cess_amtp: 0,
  interest_amtp: 0,
  fees_amtp: 0,
  other_amtp: 0,
  other_allocated_amtp: 0,
  interest_allocated_amtp: 0,
  nature_of_payment_id: '',
  type_of_payment: 'TDS_TCS_PAYABLE_BY_TAXPAYER_200',
  payment_mode: '',
  mode_of_payment_online: '',
  mode_of_payment_offline: '',
  bank_name: '',
  cheque_no: '',
  cheque_deposit_date: moment(today).format('MM/DD/YYYY'),
  date_as_on_cheque: '',
  deductee_status: '',
};
