/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import { CENTER_GOVERNMENT_DEDUCTOR_TYPES } from '../../app/constants';

// -- Validation Rules
export const correctiondDeductorFormValidationSchema = Yup.object().shape({

  // tan: Yup.string()
  // .required('TAN Number is required')
  // .matches(/^[A-Z]{4}[0-9]{5}[A-Z]{1}$/, 'Invalid TAN Number'),

  deductor_name: Yup.string()
    .trim()
    .min(1, 'Too Short!')
    .max(75, 'Too Long!')
    .required('Deductor name is required'),

  contact_info_primary_email: Yup.string()
    .email('Invalid email')
    .required('Primary Email is required'),

  deductor_category: Yup.string().required('Category is required'),

  pan: Yup.string()
    .required('PAN Number is required')
    .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number'),

  // gst_no: Yup.string()
  //   .matches(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/, { message: 'Invalid GST Number', excludeEmptyString: true }),

  contact_info_primary_mobile_no: Yup.number()
    .required('Mobile number is required.')
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  address_building_no: Yup.string()
    .trim()
    .required('Building number is required'),

  address_state_id: Yup.string()
    .trim()
    .required('State is required'),

  address_city: Yup.string()
    .trim()
    .required('City required'),

  address_pincode: Yup.number()
    .required('Pincode is required')
    .min(110001, 'Invalid Pincode')
    .max(999999, 'Invalid Pincode'),

  branch: Yup.string().trim().required('Branch is required'),

  responsible_person_contact_info_person_name: Yup.string()
    .trim()
    .required('Person name is required'),

  responsible_person_pan: Yup.string()
    .required('PAN number is required')
    .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number'),

  responsible_person_designation: Yup.string()
    .trim()
    .required('Designation is required'),

  responsible_person_contact_info_primary_mobile_no: Yup.number()
    .required('Mobile number is required.')
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  responsible_person_contact_info_primary_email: Yup.string()
    .email('Invalid email')
    .required('Primary Email is required'),

  responsible_person_address_building_no: Yup.string()
    .trim()
    .required('Building number is required'),

  responsible_person_address_city: Yup.string()
    .trim()
    .required('City required'),

  responsible_person_address_state_id: Yup.number()
    .required('State is required'),

  responsible_person_address_pincode: Yup.number()
    .required('Pincode is required')
    .min(110001, 'Invalid Pincode')
    .max(999999, 'Invalid Pincode'),

  government_deductor_pao_code: Yup.string().nullable()
    .when('deductor_category', {
      is: 'A',
      then: Yup.string().trim().required('PAO Code is required').max(20)
        .matches(/[A-Za-z0-9]+$/, 'Only alpha-numeric values allowed'),
    }),

  government_deductor_ddo_code: Yup.string().nullable()
    .when('deductor_category', {
      is: 'A',
      then: Yup.string().trim().required('DDO Code is required'),
    }),

  government_deductor_dept_name: Yup.string().nullable()
    .when('deductor_category', {
      is: (val) => CENTER_GOVERNMENT_DEDUCTOR_TYPES.includes(val),
      then: Yup.string().trim().required('Department Name is required'),
    }),

  government_deductor_office_identity_number: Yup.string().nullable()
    .when('deductor_category', {
      is: (val) => ['A', 'S'].includes(val),
      then: Yup.string().trim().required('Account Office Identification Number is required'),
    }),

  government_deductor_dept_name_other: Yup.string().nullable()
    .when('government_deductor_dept_name', {
      is: '99',
      then: Yup.string().trim().required('Other Department Name is required'),
    }),
});
