import React, { useEffect, useState } from 'react'
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';


const LetOutProperty = (props) => {
    const { onClose, parentPopupWidth, info, mainData, updateEmployeeDetail, onlyView=false } = props;
    const defaultObj = {
        'gross_amtp': 0,
        'property_tax_amtp': 0,
        'net_annual_amtp': 0,
        'standard_deduction_amtp': 0,
        'loan_interest_amtp': 0,
    }
    const width1 = document.body.clientWidth;
    const popupWidth = 500;
    const additionalGap = 10;
    const initialLeft = width1 - (parentPopupWidth + (popupWidth + additionalGap));

    const [letOutProperty, setLetOutProperty] = useState({});
    const [formError] = useState({});


    const calculate_let_out = (field, value) => {

        let new_letoutBreakup = letOutProperty;
        new_letoutBreakup[field] = value;

        let gross_amtp = new_letoutBreakup.gross_amtp ? new_letoutBreakup.gross_amtp : 0;
        let property_tax_amtp = new_letoutBreakup.property_tax_amtp ? new_letoutBreakup.property_tax_amtp : 0;
        // let loan_interest_amtp = new_letoutBreakup.loan_interest_amtp ? new_letoutBreakup.loan_interest_amtp : 0;

        let net_annual_amtp = parseFloat(gross_amtp - property_tax_amtp).toFixed(2);
        let standard_deduction_amtp = parseFloat(30 / 100 * net_annual_amtp).toFixed(2);
        // let let_out_amtp = parseFloat(gross_amtp - property_tax_amtp - standard_deduction_amtp - loan_interest_amtp).toFixed(2);

        new_letoutBreakup.net_annual_amtp = net_annual_amtp;
        new_letoutBreakup.standard_deduction_amtp = Number(standard_deduction_amtp);
        setLetOutProperty({...new_letoutBreakup});
        
    }

    const saveData = () => {
        mainData.let_out_breakup = letOutProperty;
        updateEmployeeDetail(mainData, onClose)
    }

    useEffect(() => {
      const data = info?.poppupData ?? defaultObj;
      setLetOutProperty({...data});
      //eslint-disable-next-line
    }, [info])

    return (
        <>
            <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ padding: '8px 0px 3px 0px', width: "1000%" }}>
                    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <span className="taxregim_textfs pr-3">
                                Let Out Property
                            </span>
                        </div>
                    </div>
                </kendo-dialog-titlebar>}
                onClose={onClose}
                initialHeight={'auto'}
                initialWidth={popupWidth}
                initialTop={50}
                totalPopups={1}
                isModalView={true}
                initialLeft={initialLeft}
                className={'salarary_detail_popup'}
            >
                <div className="pb-2 pt-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card salary_taxreg_allowance">
                                <div className="form form-row salary_taxreg_allowance_form">
                                    <div className="form-group col-md-8">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Gross Annual Value</label>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox"
                                        placeholder="" data-toggle="tooltip"
                                        data-placement="right" data-original-title={formError?.gross_amtp ? formError?.gross_amtp : ""}
                                            value={FormatNumberDecimal(letOutProperty.gross_amtp, ...Array(2), false)}
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                calculate_let_out("gross_amtp", value);
                                            }}
                                            disabled={onlyView}
                                        />
                                    </div>

                                    <div className="form-group col-md-8">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Property Tax</label>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox"
                                        placeholder="" data-toggle="tooltip"
                                        data-placement="right" data-original-title={formError?.property_tax_amtp ? formError?.property_tax_amtp : ""}
                                            value={FormatNumberDecimal(letOutProperty.property_tax_amtp, ...Array(2), false)}
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                calculate_let_out("property_tax_amtp", value);
                                            }}
                                            disabled={onlyView}
                                        />
                                    </div>

                                    <div className="form-group col-md-8">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Standard Deduction U/s 24</label>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox"
                                        placeholder="" data-toggle="tooltip"
                                        data-placement="right" data-original-title={formError?.standard_deduction_amtp ? formError?.standard_deduction_amtp : ""}
                                            value={FormatNumberDecimal(letOutProperty.standard_deduction_amtp, ...Array(2), false)}
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                calculate_let_out("standard_deduction_amtp", value);
                                            }}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group col-md-8">
                                        <label className="salary_taxreg_allowance_labelfs" for="">Interest Paid against Loan</label>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox"
                                        placeholder="" data-toggle="tooltip"
                                        data-placement="right" data-original-title={formError?.loan_interest_amtp ? formError?.loan_interest_amtp : ""}
                                            value={FormatNumberDecimal(letOutProperty.loan_interest_amtp, ...Array(2), false)}
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                calculate_let_out("loan_interest_amtp", value);
                                            }}
                                            disabled={onlyView}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 pt-2 text-center">
                            <button
                                className="btn btn-default salary_taxreg_allowance_savebtn" disabled={onlyView}
                                onClick={() => {
                                    saveData();
                                }}>
                                Save & Exit
                            </button>
                        </div>
                    </div>
                </div>
            </WindowWrapperFixed>
        </>
    )
}

export default LetOutProperty