
export const MaxTravelConcessionAmount = 36000;
export const Gratuity_SCENARIO1_LIMIT = 2000000;
export const Gratuity_SCENARIO2_LIMIT = 2000000;
export const MaxLeaveSalaryEncashment = 300000;


// Travel concesssion Exempt Amount
export const getTravelExemptAmt = (values) => {
    let exempt_amtp = 0;
    let actual_exp_paid_amtp = parseInt(values.actual_exp_paid_amtp) > 0 ? values.actual_exp_paid_amtp : 0;
    let family_person = parseInt(values.family_person) > 0 ? values.family_person : 0;
    let max_paid_amtp = parseInt(family_person) * MaxTravelConcessionAmount;
    let route_amtp = Math.max(parseFloat(values.air_route_amtp), parseFloat(values.rail_connect_route_amtp), parseFloat(values.other_route_amtp));
    route_amtp = parseInt(route_amtp) > 0 ? route_amtp : 0;

    // Scenario 1 - Cash Allowance - Yes + Cent Govt Employee - No
    if (parseInt(values.whether_cent_gov_emp) === 0 && parseInt(values.whether_cash_allowance) === 1) {
        exempt_amtp = Math.min(parseFloat(route_amtp), parseFloat(actual_exp_paid_amtp), parseFloat(max_paid_amtp));
    }
    else {
        exempt_amtp = Math.min(parseFloat(route_amtp), parseFloat(actual_exp_paid_amtp));
    }

    return parseFloat(exempt_amtp).toFixed(2);
}



// Gratuity Exempt Amount
export const getGratuityExemptAmt = (values) => {
    let exempt_amtp = 0;
    let gratuity_received_amtp = parseInt(values.gratuity_received_amtp) > 0 ? values.gratuity_received_amtp : 0;
    let last_month_salary_with_DA_amtp = parseInt(values.last_month_salary_with_DA_amtp) > 0 ? values.last_month_salary_with_DA_amtp : 0;
    let avg_salary_amtp = parseInt(values.avg_salary_amtp) > 0 ? values.avg_salary_amtp : 0;
    let no_of_years = parseInt(values.service_period_year) > 0 ? values.service_period_year : 0;
    if (values.service_period_month && parseInt(values.service_period_month) >= 6) {
        no_of_years = parseInt(no_of_years) + 1;
    }

    // Scenario - 1 :- If Whether Govt Employee = Yes then Exempt Amount = Gratuity Received
    if (parseInt(values.whether_gov_emp) === 1) {
        return values.gratuity_received_amtp;
    }

    // Scenario - 4: Gratuity Received After Death, then Exempt Amount = Gratuity Received
    if (parseInt(values.whether_received_after_death) === 1) {
        return values.gratuity_received_amtp;
    }

    // Scenario - 2: Employees Covered Under the Payment of Gratuity Act
    if (parseInt(values.whether_gratuity_act_app) === 1) {
        exempt_amtp = parseFloat(last_month_salary_with_DA_amtp) * parseInt(no_of_years) * (15 / 26);
        exempt_amtp = parseInt(exempt_amtp) > 0 ? exempt_amtp : 0;
        let min_amtp = Math.min(parseFloat(gratuity_received_amtp), parseFloat(exempt_amtp), Gratuity_SCENARIO1_LIMIT);
        return min_amtp.toFixed(2);
    }

    // Scenario - 3: Employees Not Covered Under the Payment of Gratuity Act
    exempt_amtp = parseFloat(avg_salary_amtp) * parseInt(no_of_years) * (1 / 2);
    exempt_amtp = parseInt(exempt_amtp) > 0 ? exempt_amtp : 0;
    let min_amtp = Math.min(parseFloat(gratuity_received_amtp), parseFloat(exempt_amtp), Gratuity_SCENARIO1_LIMIT);
    return parseFloat(min_amtp).toFixed(2);
}




// Commuted Pension Exempt Amount
export const getCommutedPensionExemptAmt = (values) => {

    let exempt_amtp = 0;
    let commuted_pension_amtp = parseInt(values.commuted_pension_amtp) ? parseFloat(values.commuted_pension_amtp) : 0;
    let commuted_pension_percent_b100 = parseInt(values.commuted_pension_percent_b100) ? parseFloat(values.commuted_pension_percent_b100) : 0;

    // Scenario 1 : Govt Employee
    if (parseInt(values.whether_gov_emp) === 1) {
        return values.commuted_pension_amtp;
    }

    let overall_pension = 100 * (parseFloat(commuted_pension_amtp) / parseFloat(commuted_pension_percent_b100));

    // Scenario 2 : Non-govt Employee, and has received Gratuity
    if (parseInt(values.whether_gratuity_rec) === 1) {
        exempt_amtp = (1 / 3) * overall_pension;
        let min_amtp = Math.min(parseFloat(commuted_pension_amtp), parseFloat(exempt_amtp));
        return min_amtp.toFixed(2);
    }

    // Scenario 3 : Non-govt Employee and has not received Gratuity
    exempt_amtp = (1 / 2) * overall_pension;
    let min_amtp = Math.min(parseFloat(values.commuted_pension_amtp), parseFloat(exempt_amtp));
    return parseFloat(min_amtp).toFixed(2);
}


// Leave Salary Encashment Exempt Amount
export const getLeaveSalaryEncashmentExemptAmt = (values) => {

    // Scenario 1 : Govt Employee
    if (parseInt(values.whether_gov_emp) === 1) {
        return values.leaves_encas_rec_amtp;
    }
    /*
    * Scenario 2 : Non - Govt Employee
    * Exempt Amount =
    * Lower of Leave Encashment Received or
    * 10 Months Average Salary or
    * SCENARIO2_MAX_LIMIT or
    * (Average 10 month Salary / 10) * (Unavailed Leave / 30)
    */
    let leaves_encas_rec_amtp = parseInt(values.leaves_encas_rec_amtp) > 0 ? values.leaves_encas_rec_amtp : 0;
    let avg_10_month_salary_amtp = parseInt(values.avg_10_month_salary_amtp) > 0 ? values.avg_10_month_salary_amtp : 0;
    let exempt_amtp = (parseFloat(values.avg_10_month_salary_amtp) / 10) * (parseFloat(values.unavailed_leaves) / 30);
    exempt_amtp = parseInt(exempt_amtp) > 0 ? exempt_amtp : 0;

    let min_amtp = Math.min(MaxLeaveSalaryEncashment, parseFloat(leaves_encas_rec_amtp), parseFloat(avg_10_month_salary_amtp), parseFloat(exempt_amtp));
    return parseFloat(min_amtp).toFixed(2);
}


// House Rent Annual Exempt Amount
export const getHouseRentAnnualExemptAmt = (values) => {

    let rent_paid_amtp = 0;
    let annual_salary_amtp = 0;
    let hra_received_amtp = values.details.hra_received_amtp;

    /*
    * The deduction available is the least of the following amounts:
    * a. Actual HRA received;
    * b. 50% of [basic salary + DA] for those living in metro cities (40% for non-metros); or
    * c. Actual rent paid less 10% of basic salary + DA
    */
    // Metro
    if (parseInt(values.details.whether_metro_city) === 1) {
        rent_paid_amtp = parseFloat(values.details.rent_paid_amtp) - (10 / 100 * parseFloat(values.details.annual_salary_amtp));
        annual_salary_amtp = (50 / 100 * parseFloat(values.details.annual_salary_amtp));
    }
    else {
        // Non-Metro
        rent_paid_amtp = parseFloat(values.details.rent_paid_amtp) - (10 / 100 * parseFloat(values.details.annual_salary_amtp));
        annual_salary_amtp = (40 / 100 * parseFloat(values.details.annual_salary_amtp));
    }

    rent_paid_amtp = parseInt(rent_paid_amtp) > 0 ? rent_paid_amtp : 0;
    annual_salary_amtp = parseInt(annual_salary_amtp) > 0 ? annual_salary_amtp : 0;
    hra_received_amtp = parseInt(hra_received_amtp) > 0 ? hra_received_amtp : 0;

    let exempt_amtp = Math.min(parseFloat(rent_paid_amtp), parseFloat(annual_salary_amtp), parseFloat(hra_received_amtp));

    return parseFloat(exempt_amtp).toFixed(2);

}


// House Rent Month Exempt Amount
export const getHouseRentMonthExemptAmt = (values, key) => {

    let rent_paid_amtp = 0;
    let salary_amtp = 0;
    let hra_received_amtp = 0;
    /*
     * The deduction available is the least of the following amounts:
     * a. Actual HRA received;
     * b. 50% of [basic salary + DA] for those living in metro cities (40% for non-metros); or
     * c. Actual rent paid less 10% of basic salary + DA
     */
    // Metro
    if (parseInt(values.details[key].whether_metro_city) === 1) {
        rent_paid_amtp = parseFloat(values.details[key].rent_paid_amtp) - (10 / 100 * parseFloat(values.details[key].salary_amtp));
        salary_amtp = (50 / 100 * parseFloat(values.details[key].salary_amtp));
    }
    else {
        // Non-Metro
        rent_paid_amtp = parseFloat(values.details[key].rent_paid_amtp) - (10 / 100 * parseFloat(values.details[key].salary_amtp));
        salary_amtp = (40 / 100 * parseFloat(values.details[key].salary_amtp));
    }

    rent_paid_amtp = parseInt(rent_paid_amtp) > 0 ? rent_paid_amtp : 0;
    salary_amtp = parseInt(salary_amtp) > 0 ? salary_amtp : 0;
    hra_received_amtp = parseInt(values.details[key].hra_received_amtp) > 0 ? values.details[key].hra_received_amtp : 0;

    let exempt_amtp = Math.min(parseFloat(rent_paid_amtp), parseFloat(salary_amtp), parseFloat(hra_received_amtp));

    return parseFloat(exempt_amtp).toFixed(2);

}