/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import { MAX_ENT_ALLOWANCE_DEDUCTION, MAX_PROFESSIONAL_ALLOWANCE_DEDUCTION } from '../../../../app/constants';
// -- Validation Rules
export const salaryDetailsvalidationSchema = Yup.object().shape({
    deductee_name: Yup.string()
        .trim()
        .min(1, 'Too Short!')
        .max(75, 'Too Long!')
        .required('Deductee name is required'),

    deductee_id: Yup.string().required('Please select Deductee Name'),

    employment_period_from: Yup.string()
        .required('Date of period is required'),

    employment_period_to: Yup.string()
        .required('Date of period is required'),

    tax_regime: Yup.string()
        .required('Tax Regime is required'),

    allowances: Yup.array().of(
        Yup.object().shape({
            allowance_type_id: Yup.number()
                .when('exempt_amtp', {
                    is: (value) => (value > 0),
                    then: Yup.number().min(1, 'Allowance type is required').required('Allowance type is required'),
                }),
        })
    ).required('Allowance type is required'),

    ent_allowance_amtp: Yup.number().max(parseInt(MAX_ENT_ALLOWANCE_DEDUCTION), 'Ent. Allowance Deduction cannot exceed ' + MAX_ENT_ALLOWANCE_DEDUCTION),
    professional_tax_amtp: Yup.number().max(parseInt(MAX_PROFESSIONAL_ALLOWANCE_DEDUCTION), 'Professional Tax Deduction cannot exceed ' + MAX_PROFESSIONAL_ALLOWANCE_DEDUCTION),

    /*previous_employer_amount_received_amtp: Yup.number()
        .when('previous_employer', {
            is: 1,
            then: Yup.number()
                .required('Amount Received required')
        }),

    previous_employer_tds_amtp: Yup.number()
        .when('previous_employer', {
            is: 1,
            then: Yup.number()
                .required('Tds Amount required')
        }),*/

    net_salary_amtp: Yup.number().min(0, 'Amount should be greater Than or Equal to 0').required('Net salary required'),
});


export const otherIncomevalidationSchema = Yup.object().shape({

    /*self_occupied_amtp: Yup.number()
        .when('tax_regime', {
            is: 'NEW',
            then: Yup.number()
                .min(0, 'Interest on Self Occupied not allowed in New Tax Regime')
        }),*/

    total_house_property_income_amtp: Yup.number()
        .min(-200000, 'As per Finance Act, loss from house property is to be restricted to -2,00,000.00'),


    other_income_offered_details: Yup.array().of(
        Yup.object().shape({
            particular_code: Yup.string()
                .when('amount_amtp', {
                    is: (value) => (value > 0),
                    then: Yup.string().trim().required('Particular type is required'),
                }),
        })
    ),

    total_gross_income_amtp: Yup.number()
        .min(0, 'Amount should be greater Than or Equal to 0')
        .required('Gross income is required'),

    total_deduction_vi_a_amtp: Yup.number()
        .min(0, 'Amount should be greater Than or Equal to 0')
        .required('Deduction under chapter VI-A is required'),


    deduction_vi_a_details: Yup.array().of(
        Yup.object().shape({
            code: Yup.string()
                .when('gross_amount_amtp', {
                    is: (value) => (value > 0),
                    then: Yup.string().trim().required('Particular type is required'),
                }),
        })
    ),

    net_taxable_income: Yup.object().shape({
        total_net_taxable_income_amtp: Yup.number().min(0, 'Amount should be greater Than or Equal to 0').required('Net taxable income is required'),
        total_tax_computed_amtp: Yup.number().min(0, 'Amount should be greater Than or Equal to 0').required('Total Computed is required'),
        relief_u_s_89_amtp: Yup.number()
            .max(Yup.ref('total_net_taxable_income_amtp'), 'Amount should be less Than or Equal to Net Taxable Income'),
    })

});


export const otherDetailsvalidationSchema = Yup.object().shape({

    landlord_details: Yup.array()
        .when('whether_total_rent_greater_onelakh', {
            is: 1,
            then: Yup.array().of(
                Yup.object().shape({
                    name_of_landlord: Yup.string()
                        .trim()
                        .min(1, 'Too Short!')
                        .max(75, 'Too Long!')
                        .required('Name is required.'),
                    pan_of_landlord: Yup.string()
                        .required('PAN is required')
                        .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(GOVERNMENT)|(NONRESIDENT)|(OTHERVALUE)$/, 'Invalid PAN'),
                    rent_amount_amtp: Yup.number().min(0, 'Amount should be Positive').required('Amount should be Positive')
                })
            )
                .required('Must have landlord data'),
        }),

    lender_details: Yup.array()
        .when('whether_interest_paid_to_lender', {
            is: 1,
            then: Yup.array().of(
                Yup.object().shape({
                    name_of_lender: Yup.string()
                        .trim()
                        .min(1, 'Too Short!')
                        .max(75, 'Too Long!')
                        .required('Name is required.'),
                    pan_of_lender: Yup.string()
                        .required('PAN is required')
                        .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(GOVERNMENT)|(NONRESIDENT)|(OTHERVALUE)$/, 'Invalid PAN'),
                    interest_amount_amtp: Yup.number().min(0, 'Amount should be Positive').required('Amount should be Positive')
                })
            )
                .required('Must have landlord data'),
        }),

    fund_name: Yup.string()
        .when('whether_amtrec_from_superannuation_fund', {
            is: 1,
            then: Yup.string()
                .trim()
                .min(1, 'Too Short!')
                .max(75, 'Too Long!')
                .required('Name of fund is required.'),
        }),

    fund_from: Yup.string()
        .when('whether_amtrec_from_superannuation_fund', {
            is: 1,
            then: Yup.string()
                .required('Date of period is required'),
        }),

    fund_to: Yup.string()
        .when('whether_amtrec_from_superannuation_fund', {
            is: 1,
            then: Yup.string()
                .required('Date of period is required'),
        }),
    tax_rate_b100: Yup.number().max(100, 'Amount should be less Than or Equal to 100'),

});