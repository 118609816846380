/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './monthwisesalarydetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRupeeSign
} from '@fortawesome/free-solid-svg-icons';
import NumericInputWithDecimalTwo from '../../../../../components/NumericInputWithDecimalTwo';
import { MONTH_LIST } from '../../../../../app/constants'
import { formatNumber } from '../../../../../utils/UtilityFunctions';


const Monthwisesalarydetails = (props) => {
    const { show, handleClose, values, setFieldValue, totalGrossSalary } = props;
    const [total, setTotal] = useState(0);


    const getTotal = () => {
        let salary_amtp = 0;
        MONTH_LIST.map((month, index) => {
            let month_value = document.getElementById(`month_breakup_${month.value}`).value;
            month_value = parseInt(month_value) > 0 ? month_value : 0;
            salary_amtp = parseFloat(salary_amtp) + parseFloat(month_value);
            return true;
        });
        setTotal(parseFloat(salary_amtp).toFixed(2));
    }

    const saveBreakup = () => {
        let salary_amtp = 0;
        MONTH_LIST.map((month, index) => {
            let month_value = document.getElementById(`month_breakup_${month.value}`).value;
            month_value = parseInt(month_value) > 0 ? month_value : 0;
            values.salary_breakup_amtp[`${month.value}`] = month_value;
            salary_amtp = parseFloat(salary_amtp) + parseFloat(month_value);
            return true;
        });

        setFieldValue('salary_breakup_amtp', values.salary_breakup_amtp);
        setFieldValue('salary_amtp', salary_amtp.toFixed(2));
        totalGrossSalary('salary_amtp', salary_amtp.toFixed(2), setFieldValue, values);
        handleClose(false);
    }

    const closePopup = () => {
        handleClose(false);
    }

    useEffect(() => {
        let salary_amtp = 0;
        MONTH_LIST.map((month, index) => {
            let month_value = parseInt(values.salary_breakup_amtp[`${month.value}`]) >= 0 ? values.salary_breakup_amtp[`${month.value}`] : 0;
            document.getElementById(`month_breakup_${month.value}`).value = month_value;
            salary_amtp = parseFloat(salary_amtp) + parseFloat(month_value);
            return true;
        });
        setTotal(parseFloat(salary_amtp).toFixed(2));

    }, [values.salary_breakup_amtp]);


    return (<Modal
        show={show}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        size="sm"
        className="MonthWSdetailspopup"
    >
        <Modal.Header closeButton className="MonthWSdetailsH monthdetail_header">
            <Modal.Title className="pt-0 mt-0">
                <sapn className="MonthWSdetailsT"> Month-wise Salary Details</sapn>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0 px-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 pt-2">
                                <div className="card MonthWSdetailscard">
                                    <div className="table-responsive">
                                        <table className="table table-striped monthWSDTB monthwisemodal_inputwidthfixed mb-0">
                                            <thead>
                                                <tr>
                                                    <th width="150">Month</th>
                                                    <th width="160">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {MONTH_LIST.map((month, index) => {
                                                    return (
                                                        < tr >
                                                            <td>{month.label}</td>
                                                            <td className="text-right pr-1">
                                                            

                                                            <div className="input-group">
                                                                <div class="input-group-prepend monthwise_salarydetails_rupsign">
                                                                    <FontAwesomeIcon icon={faRupeeSign} />
                                                                </div>
                                                                <NumericInputWithDecimalTwo
                                                                    type="text"
                                                                    className="form-control monthwise_inputborderrightnon"
                                                                    placeholder="0.00"
                                                                    name={month.value}
                                                                    id={`month_breakup_${month.value}`}
                                                                    maxLength={18}
                                                                    onChange={(e) => { getTotal() }}
                                                                />
                                                           
                                                           
                                                        </div>


                                                                
                                                               
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td><span style={{ fontSize: '1rem' }}>TOTAL</span></td>
                                                    <td className="text-left pr-1">
                                                        <span className="pl-2 pr-2"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                        <spa class="float-right"> {formatNumber(total)}</spa>
                                                        
                                                       
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="btn-group mt-2 mb-1">
                                    <button type="button" className="btn btn-default monthwsdetilssavebtn" onClick={saveBreakup}>Save & Exit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
    </Modal >
    );
}

export default Monthwisesalarydetails;
