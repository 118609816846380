import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ColumnMenu } from '../../components/grid/ColumnMenu.jsx';
import queryString from 'query-string';
import searchIcon from "../../images/search.png";
//import exceldwnload from "../../images/exceldownload2.png";
import sys_btn from "../../images/icons/sys_btn_icon.svg";
import { BASE_PATH } from '../../app/constants';
import { getCurrentFinancialYearByQuarter, getFinancialYearsList } from '../../utils/UtilityFunctions';
import { gocallApi } from '../../api/issueCertificateAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { downloadExcelReturnRegister } from '../../api/ReturnRegister.js';
import DeductorSyncAllClientListProgressBar from './DeductorSyncAllClientListProgressBar.jsx';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter.jsx';
import TracesConnector from '../../components/kdkConnector/TracesConnector.jsx';
import SyncData from '../../components/SyncData.jsx';
import SemanticPagination from '../../components/pagination/SemanticPagination';
import exceldwnload from '../../images/icons/export_icon.png';
import "./ReturnRegisterList.scss";

const ClientWiseList = () => {


    const location = useLocation();
    const history = useHistory();
    const searchStr = location.search;
    const current_year = getCurrentFinancialYearByQuarter();
    const params = queryString.parse(searchStr);

    const [searchTerm, setSearchTerm] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [filterYear, setFilterYear] = useState(false);
    const [quarterWiseData, setQuarterWiseData] = useState({});
    const [isQrtListShow, setIsQrtListShow] = useState("");
    const [runSync, setRunSync] = useState(false)
    const [startConnector, setStartConnector] = useState(false);
    const [port, setPort] = useState(0);
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [deductorId, setDeductorId] = useState(false);
    const [tan, setTan] = useState(false);
    //const [sync_status, setShowSyncVerify] = useState(false);
    const [captchaRef, setCaptchaRef] = useState(null);
    const [selectedQuarter, setSelectedQuarter] = useState(null);
    const [selectedFormType, setSelectedFormType] = useState(null);
    const [dataState, setDataState] = useState({});
    const [quarterListData, setQuarterListData] = useState(null)
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [dedutorName, setDeductorName] = useState("");
    const [clientWiseSummary, setClientWiseSummary] = useState(null)
    const [summaryStatus, setSummaryStatus] = useState(true)

    const handleOnChange = (value) => {
        setSearchTerm(value);
        if (value === '') {
            let searchStr = `?financial_year=${params.financial_year ?? getCurrentFinancialYearByQuarter()}`;
            params && Object.keys(params).forEach((key) => {
                if (key && !['search_text', 'financial_year'].includes(key)) {
                    searchStr = `${searchStr}&${key}=${params[key]}`;
                }
            });
            history.push(searchStr);
        }
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const changeYearFilter = (value) => {
        setFilterYear(value);
        const parameter = {
            filters: dataState,
            search: searchTerm,
            filter_year: value.value,
        }
        createHistoryUrl(parameter)
        setIsQrtListShow("");
    };

    const handleSubmit = (event) => {
        event && event.preventDefault();
        let newSearchItem = searchTerm.trim();
        if (searchTerm.length > 0 && !newSearchItem) {
            return setSearchTerm("");

        }

        if (newSearchItem.length > 75) {
            setError('The filter deductor name can not be greater than 75 characters!');
            return;
        }
        if (newSearchItem) {
            const parameter = {
                filters: dataState,
                search: newSearchItem,
                filter_year: filterYear.value ?? ""
            }
            createHistoryUrl(parameter)
        }
    };

    const getClientWiseListOnServer = (parameter) => {

        async function fetchEverything() {
            const url = `/api/v1/return-register/client-wise`;
            parameter.financial_year = parameter.financial_year ? parseInt(parameter.financial_year) : "";
            setLoading(true);
            try {
                const result = await gocallApi("post", url, parameter);
                setData(result?.data?.data ?? []);

                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page;
                setCursor(result?.data?.cursor ?? { current_page: 1, next_page_url: null });


            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything()

    }

    const getClientWiseSummary = (parameter) => {

        async function fetchEverything() {
            const url = `/api/v1/return-register/client-wise-summary`;
            parameter.financial_year = parameter.financial_year ? parseInt(parameter.financial_year) : "";
            setLoading(true);
            try {
                const result = await gocallApi("post", url, parameter);
                setClientWiseSummary(result?.data ?? []);

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything()

    }

    const dwnloadReturnRegister = () => {
        async function fetchEverything() {
            setLoading(true);
            const payload = `?financial_year=${filterYear?.value ?? ""}`
            try {
                await downloadExcelReturnRegister(payload);
            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    }

    const createHistoryUrl = ((parameter) => {
        const { filter_year = null, filters = [], search = "" } = parameter;

        let filterStr = `?financial_year=${filter_year ?? ""}`;
        if (search) filterStr += `&search_text=${encodeURIComponent(search)}`;

        if (filters.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
        }

        if (filters.sort && filters.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
        }

        history.push(filterStr);
    });

    const getParamsHistoryUrl = (parameter) => {

        const year = parameter.financial_year ?? filterYear.value;

        const requestData = {
            financial_year: year,
            page: 1
        };
        const filterState = {};
        filterState.sort = [];
        if (parameter.sort_on) {
            filterState.sort = [{ field: parameter.sort_on, dir: parameter.order_by ?? 'asc' }];
            requestData.sort_on = parameter.sort_on;
            requestData.sort_by = parameter.order_by;
        }
        if (parameter.filters) {
            filterState.filter = { filters: [] };
            filterState.filter.filters = JSON.parse(atob(parameter.filters));
            requestData.filters = JSON.parse(atob(parameter.filters));
        } else {
            filterState.filter = { filters: [] };
        }

        if (parameter?.search_text) requestData.search_text = parameter.search_text ?? "";
        if (parameter.page) requestData.page = parseInt(parameter.page);

        setDataState(filterState);
        return requestData;
    };

    const dataStateChange = (data_state) => {
        const parameter = {
            filters: data_state,
            search: searchTerm,
            filter_year: filterYear.value ?? ""
        }
        createHistoryUrl(parameter)

        //Get Summary of Notice Order
        if (data_state?.filter?.filters?.length > 0 && data_state?.sort?.length > 0) {
            setSummaryStatus(true)
        } else if (data_state?.filter?.filters?.length === 0 && data_state?.sort?.length > 0) {
            setSummaryStatus(false)
        } else if (data_state?.filter?.filters?.length > 0 && data_state?.sort?.length === 0) {
            setSummaryStatus(true)
        } else {
            setSummaryStatus(true) //default true
        }
    };

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem ?? {};
        const index = props.dataIndex ?? 0
        return (
            <tr className={`${isQrtListShow === index ? "rowclick_showtb" : ""} cursor-pointer`} id={`client${index}`} key={data.deductor_id ?? ""}
                onClick={() => {
                    if (isQrtListShow !== index) quarterWiseReturnlist(dataItem, index)
                    setTimeout(() => {
                        const rowpos = $(`#client${index}`).position();
                        const topPosition = rowpos?.top ? rowpos.top : 0;
                        $('.table-responsive .clientwise .k-grid-content.k-virtual-content').scrollTop(topPosition);
                    }, 1000);
                }}>
                <td className="Return_register_overflowtext textclr_blue">
                    <Link to={`${BASE_PATH}deductor?deductor_id=${dataItem.deductor_id}`} target="_blank" >
                        <span className="tr-cursor-pointer"
                            title={dataItem.deductor_name ?? ""}>

                            {dataItem.deductor_name ?? ""}
                        </span>
                    </Link>
                </td>

                <td className="text-left">{dataItem.tan ?? ""}</td>
                <td className={`text-left ${!dataItem?.primary_mobile_no ? 'text-center' : ''}`}>{dataItem.primary_mobile_no ?? "-"}</td>
                <td className={`text-left Return_register_overflowtext ${!dataItem?.primary_email ? 'text-center' : ''}`}>
                    <span
                        title={dataItem.primary_email ?? ""}>
                        {dataItem.primary_email ?? "-"}
                    </span>
                </td>
                <td className="text-center ">
                    <div>
                        {dataItem?.filing_status?.length ?? "-"}
                    </div>
                </td>
            </tr>
        )
    };


    const quarterWiseReturnlist = (dataItem, index) => {
        if (dataItem.filing_status.length > 0) {
            const formType_key = [];
            const qrtlist = {};

            dataItem.filing_status.forEach((item) => {
                if (!formType_key.includes(item.form_type)) {
                    formType_key.push(item.form_type);
                    qrtlist[item.form_type] = {
                        [item.quarter]: {
                            filing_date: item.filing_date ?? "",
                            quarter: item.quarter ?? "",
                            return_type: item.return_type ?? "",
                            status: item.status ?? ""
                        }
                    }
                }
                else if (formType_key.includes(item.form_type)) {
                    if (qrtlist[item.form_type]) {
                        qrtlist[item.form_type][item.quarter] = {
                            filing_date: item.filing_date ?? "",
                            quarter: item.quarter ?? "",
                            return_type: item.return_type ?? "",
                            status: item.status ?? ""
                        }
                    }
                }

            })
            setQuarterWiseData(qrtlist);

        } else {
            setQuarterWiseData({});
        }
        setIsQrtListShow(index)
        setQuarterListData(dataItem)
        setDeductorName(dataItem.deductor_name ?? "");

    }

    const setShowSyncVerifyStatus = (TAN) => {
        const listData = JSON.parse(JSON.stringify(data));
        for (const item of listData) {
            if (item?.tan === TAN) {
                item.sync_status = "IN_PROGRESS";
            }
        }
        setData((prev) => {
            prev = listData
            return prev
        });
    }

    const setSyncData = (data) => {
        setDeductorId(data?.deductor_id);
        setTan(data?.tan);
        setStartConnector(true);
        setSelectedFormType(data?.form_type);
        setSelectedQuarter(data?.quarter);
    }

    useEffect(() => {

        const parameter = queryString.parse(searchStr);
        let param_year = parameter.financial_year ? parseInt(parameter.financial_year) : getCurrentFinancialYearByQuarter();
        // set year and month dropdown
        const year_list = getFinancialYearsList(param_year);
        if (parameter?.search_text) setSearchTerm(parameter.search_text);

        setYearList(year_list);
        if (param_year) {
            const selected_year = year_list.filter((year) => parseInt(year.value) === param_year);
            setFilterYear(selected_year[0]);
        } else {
            setFilterYear(getCurrentFinancialYearByQuarter());
        }

        parameter.financial_year = filterYear.value ? filterYear.value : param_year;

        const postData = getParamsHistoryUrl(parameter);
        getClientWiseListOnServer(postData);
        if (summaryStatus) {
            getClientWiseSummary(postData) // get summary
        }

        //eslint-disable-next-line
    }, [searchStr]);

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })


    return (
        <>
            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {startConnector
                ? (<ConnectorAdapter
                    show={startConnector}
                    handleClose={() => setStartConnector(false)}
                    setPort={setPort}
                    handleProceed={() => setCheckTracesCredentials(true)}
                />)
                : null
            }

            {checkTracesCredentials && port
                ? <TracesConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={() => {
                        setStartConnector(false);
                        setCheckTracesCredentials(false);
                    }}
                    tan={tan}
                    port={port}
                    setCaptchaRef={setCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                />
                : null
            }

            {captchaRef && port
                ? (
                    <SyncData
                        deductorId={deductorId}
                        returnItem={{
                            'financial_year': filterYear?.value ?? getCurrentFinancialYearByQuarter(),
                            'quarter': selectedQuarter,
                            'form_type': selectedFormType
                        }}
                        port={port}
                        captchaRef={captchaRef}
                        handleProceed={() => { }}
                        setSuccess={setSuccess}
                        setError={setError}
                        setShowSyncVerify={() => {
                            setShowSyncVerifyStatus(tan);
                            setStartConnector(false);
                            setCheckTracesCredentials(false);
                            setCaptchaRef(null);
                        }}
                    />
                )
                : null}
            <DeductorSyncAllClientListProgressBar runSync={runSync} setRunSync={setRunSync} display={true} />

            {loading && <LoadingIndicator />}
            <div className="Return_Registercss container containermaxwidth">
                <div className="row mt-2">
                    <div className="col-12 col-xl-2 col-lg-3 col-md-3 col-sm-3">
                        <Link href="" className="btn btn-default Return_register_backbtn" to={BASE_PATH}> &lt; Back to Deductor Master</Link>
                    </div>
                    <div className="col-12 col-xl-8 col-lg-6 col-md-6 col-sm-6  d-flex  justify-content-center">
                        <nav className="Return_register_nav">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">

                                <Link className="nav-item nav-link Return_register_tab Return_register_tabretborderrightnon searchbox_show_less"
                                    to={`${BASE_PATH}return-register/return-wise?financial_year=${filterYear?.value ?? current_year}`}>
                                    Return Wise
                                </Link>
                                <Link className="nav-item nav-link Return_register_tab actives searchbox_show_details"
                                    to={`${BASE_PATH}return-register/client-wise?financial_year=${filterYear?.value ?? current_year}`}>
                                    Deductor Wise
                                </Link>

                            </div>
                        </nav>
                    </div>
                    <div className="col-12 col-xl-2 col-lg-3 col-md-3 col-sm-3 text-right">
                        <DropDownList
                            data={yearList}
                            className="header_select headerselect drpdwn_width"
                            popupSettings={{ className: 'headerselectoption' }}
                            textField="text"
                            value={filterYear}
                            onChange={(e) => { changeYearFilter(e.target.value); }}
                            dataItemKey="value"
                            id="yearDropDownPopup"
                        />

                    </div>

                </div>
                <div className="row pt-2">
                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="form-inline Return_register_searchbox">

                            <input className="form-control"
                                type="search"
                                placeholder="Search By Deductor Name / Mobile / Email / TAN"
                                aria-label="Search"
                                incremental="true"
                                value={searchTerm}
                                onChange={(e) => handleOnChange(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        handleSubmit(event)
                                    }
                                }}
                            />
                            <img className="Return_register_search_text_icon Return_register_doflistsiconbox" src={searchIcon} onClick={(event) => handleSubmit(event)}
                                alt="Search Icon" />
                        </div>
                    </div>

                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 text-right">


                        <img src={exceldwnload} width="28" style={{ cursor: "pointer" }} onClick={() => dwnloadReturnRegister()} className="mr-2 planexpiredalert" title='
                    Download Return Register' alt="" />

                        <button className={`btn btn-default Return_register_btnbgclr mr-1`}
                            onClick={() => { setRunSync(true) }}>
                            <span className="returnreg_imgbox">
                                <img src={sys_btn} alt='sys_btn' style={{ paddingRight: "3px", marginTop: "-2px" }} />
                                <span className="returnreg_textfs">Sync Now</span>
                            </span>

                        </button>
                        {/* <button className="btn btn-default Return_register_btnbgclr" onClick={() => dwnloadReturnRegister()}> */}
                        {/* Return Register */}
                        {/* </button> */}

                    </div>

                </div>

                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="tab-content">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className={`card`}>
                                            <div
                                                className="table-responsive clientwise table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <Tooltip anchorElement="pointer" position="right">
                                                    <Grid
                                                        data={data}
                                                        className={`table table-striped clientwise mb-0`}
                                                        {...dataState}
                                                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                        sortable
                                                        filterOperators={{
                                                            text: [
                                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                            ],
                                                            dropdown: [
                                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                            ],
                                                            numeric: [
                                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                            ],
                                                            textWithEmpty: [
                                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                            ],
                                                        }}
                                                        style={{ maxHeight: `${String(isQrtListShow) ? "255px" : ""}`, overflowX: 'auto' }}
                                                        rowRender={rowRender}
                                                        scrollable

                                                    >
                                                        <Column
                                                            width="180"
                                                            field='deductor_name'
                                                            filter='text'
                                                            headerClassName={isColumnActive('deductor_name') ? 'active' : ''}
                                                            title='Deductor Name'
                                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}

                                                        />
                                                        <Column
                                                            width="100"
                                                            field='tan'
                                                            filter='text'
                                                            headerClassName={isColumnActive('tan') ? 'active' : ''}
                                                            title='TAN'
                                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                        />
                                                        <Column
                                                            width="100"
                                                            field='primary_mobile_no'
                                                            filter='text'
                                                            headerClassName={isColumnActive('primary_mobile_no') ? 'active' : ''}
                                                            title='Mobile No'
                                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                        />
                                                        <Column
                                                            width="150"
                                                            field='primary_email'
                                                            filter='text'
                                                            headerClassName={isColumnActive('primary_email') ? 'active' : ''}
                                                            title='Email'
                                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                        />

                                                        <Column
                                                            width="150"
                                                            title='No of Filed Return'
                                                            sortable={false}
                                                        />

                                                    </Grid>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Pagination cursor={cursor}  handleProcess={() => setIsQrtListShow("")}/> */}
                                    {clientWiseSummary?.summary_count > 0 &&
                                        <SemanticPagination cursor={cursor} count="summary_count" pagelimit="17" summary={clientWiseSummary} handleProcess={() => setIsQrtListShow("")} setSummaryStatus={setSummaryStatus} />
                                    }

                                </div>

                                {String(isQrtListShow) && <div className="row mt-2" id="refreshtabrow_01">
                                    <div className="col-md-12">
                                        <div className="card cardbox_clientwise">
                                            <div className="synccontentboxbgclr">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <span className="synccontent_comfs">{dedutorName}</span>
                                                        <span className="btn btn-default synccontent_btnbgclr ml-2" onClick={() => { if (data?.[isQrtListShow]?.sync_status !== "IN_PROGRESS" && quarterListData) setSyncData(quarterListData) }}
                                                        >
                                                            <span class="clientstatusimgreturn">
                                                                <FontAwesomeIcon icon={faSync} width="16" className={data?.[isQrtListShow]?.sync_status === "IN_PROGRESS" ? 'rotate_icon' : ''} />
                                                            </span>
                                                            <span className="returnreg_textfs">Sync Now</span>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div
                                                className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <table className="table table-striped Return_register_rewisetb mb-0">
                                                    <thead>
                                                        <tr className="">
                                                            <th width="70">Form Type</th>
                                                            <th width="150">
                                                                Quarter 1(Apr- Jun)
                                                            </th>
                                                            <th width="150">
                                                                Quarter 2(Jul- Sept)
                                                            </th>
                                                            <th width="150">
                                                                Quarter 3(Oct- Dec)
                                                            </th>
                                                            <th width="150">
                                                                Quarter 4(Jan- Mar)
                                                            </th>
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(quarterWiseData)?.length > 0 && Object.keys(quarterWiseData).map((key) => {
                                                            const quarter = quarterWiseData?.[key] ?? null;
                                                            return (
                                                                <tr className="tr-cursor-pointer">
                                                                    <td className="text-center">{key}</td>
                                                                    <td>
                                                                        <span className="textclr_blue"
                                                                            data-toggle="tooltip" data-placement="right"
                                                                            data-original-title={`${quarter?.Q1?.filing_date} ${quarter?.Q2?.return_type ? `(${quarter?.Q2?.return_type?.toLowerCase()})` : ""}`}
                                                                        >{quarter?.Q1?.status ?? ""}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="textclr_blue"
                                                                            data-toggle="tooltip" data-placement="right"
                                                                            data-original-title={`${quarter?.Q2?.filing_date ?? ""} ${quarter?.Q2?.return_type ? `(${quarter?.Q2?.return_type?.toLowerCase()})` : ""}`}
                                                                        >{quarter?.Q2?.status ?? ""}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="textclr_blue">
                                                                            <span data-toggle="tooltip" data-placement="right"
                                                                                data-original-title={`${quarter?.Q3?.filing_date} ${quarter?.Q3?.return_type ? `(${quarter?.Q3?.return_type?.toLowerCase()})` : ""}`}
                                                                            >
                                                                                {quarter?.Q3?.status ?? ""}
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="textclr_blue"
                                                                            data-toggle="tooltip" data-placement="right"
                                                                            data-original-title={`${quarter?.Q4?.filing_date ?? ""} ${quarter?.Q4?.return_type ? `(${quarter?.Q4?.return_type?.toLowerCase()})` : ""}`}
                                                                        >{quarter?.Q4?.status ?? ""} </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        {Object.keys(quarterWiseData).length === 0 && <tr><td colspan="5" className='text-center'>No Record Found</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientWiseList