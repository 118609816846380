import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import $ from "jquery"
import moment from "moment";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import ShowAlert from "../../components/ShowAlert";
import WindowWrapperFixed from "../../components/grid/WindowWrapperFixed";
import EmployeeSalaryDetail from "./EmployeeSalaryDetail";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { getDeducteesV2 } from "../../api/deducteeAPI";
import {
  addEmployeeSalaryDetailV2,
  getDeductionVIAParticulars,
  updateEmployeeSalaryDetailV2,
} from "../../api/EmployeeApi";
import addBtnRoundIcon from "../../images/icons/add_btn_round.svg";
import ComputedPension from "./popupComponent/ComputedPension";
import Gratuity from "./popupComponent/Gratuity";
import HouseRentAllowance from "./popupComponent/HouseRentAllowance";
import LeaveSalaryEncashment from "./popupComponent/LeaveSalaryEncashment";
import TravelConcession from "./popupComponent/TravelConcession";
import SelfOccupied from "./popupComponent/SelfOccupied";
import LetOutProperty from "./popupComponent/LetOutProperty";
import { selectHeaderData } from "../header/headerSlice";
import { ALLOWANCEEXEMPT_DROPDOWN, defaultdata, OTHERINCOMEOFFERED_DROPDOWN, perquisites_17_2DROPDOWN, profit_17_3DROPDOWN, RETIRMENTBENFIT_DROPDOWN, SALARY_17_1DROPDOWN, transformApiData, transformDropdown, updateUIData, validateOtherDetailSchema } from "./salaryHelper";
import { convertNum, deepClone } from "../../utils/UtilityFunctions";
import { getCurrentFinancialYear } from "../form3CD/Form3CDUtilityFunctions";
import AddDeductee from "../deducteeList/AddDeductee";
import "./SalaryDetail.scss";

const AddSalaryDetail = (props) => {
  const width1 = document.body.clientWidth;
  const { onClose, deductorId, parentGetListApi, returnId } = props;
  const popupWidth = 910;
  const headerDataDetails = useSelector(selectHeaderData);
  let financialYear = getCurrentFinancialYear();
  if (headerDataDetails.financial_year_formatted) {
    const getYearIndex = headerDataDetails.financial_year_formatted.split("-");
    if (getYearIndex[0]) financialYear = parseInt(getYearIndex[0]);
  }

  const USER_TYPE = headerDataDetails?.organization_type === "CORPORATE" ? "Details" : "Lite";

  defaultdata.deductor_id = deductorId;
  defaultdata.financial_year = financialYear;
  defaultdata.employment_period_from = `01/04/${financialYear}`;
  defaultdata.employment_period_to = `31/03/${financialYear + 1}`;

  const [data, setData] = useState(defaultdata);
  const [deducteeData, setDeducteeData] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [warning, setWarning] = useState(null);
  const [formError, setFormError] = useState({});
  const [otherDetailError, setOtherDetailError] = useState({});
  const [isUserEdit, setIsUserEdit] = useState(false);
  const [relief89usErr, setRelief89usErr] = useState("");
  const [drowdownList, setDropdownList] = useState({
    salary17one: [],
    perquisites_17_2: [],
    profit_17_3: [],
    othinc_off_det: [],
    deductionVI_A: {},
    allowanceExempt: [],
    retirebenfits: [],
  });
  const [showCalcPopups, setShowCalcPopups] = useState({
    computedPensionPopup: false,
    gratuityPopup: false,
    houseRentAllowPopup: false,
    leaveSalaryEncashPopup: false,
    travelConcessionPopup: false,
    letOutPropertyPopup: false,
    SelfOccupiedPopup: false,
    createDeducteePopup: false
  });
  const [deducteeDet, setDeducteeDet] = useState({
    deducteeName: "",
    dateRange: {
      start: moment(`01/04/${financialYear}`, "DD/MM/YYYY").toDate(),
      end: moment(`31/03/${financialYear + 1}`, "DD/MM/YYYY").toDate()
    },
    supAnnDateRange: { start: null, end: null }
  });

  const validateMandatoryDetail = (data) => {

    const err_obj = {};
    const minDate = moment(`01/04/${financialYear}`, "DD/MM/YYYY").unix();
    const maxDate = moment(`31/03/${financialYear + 1}`, "DD/MM/YYYY").unix();
    const from_date = data.employment_period_from ? moment(data.employment_period_from, "DD/MM/YYYY").unix() : null;
    const to_date = data.employment_period_to ? moment(data.employment_period_to, "DD/MM/YYYY").unix() : null;

    if (!data.deductee_id) {
      err_obj.deductee_name = "Invalid deductee name";
    }

    if (!from_date) {
      err_obj.from_date = "Invalid from date";
    } else if (from_date && from_date < minDate) {
      err_obj.from_date = `The employment period to date must be greater than or equal to 01/04/${financialYear}`;
    } else if (from_date && from_date > maxDate) {
      err_obj.from_date = `The employment period to date must be less than or equal to 31/03/${financialYear + 1}`;
    } else if (to_date && from_date && from_date > to_date) {
      err_obj.to_date = `The employment period to date must be less than or equal to ${data.employment_period_to}`;
    }

    if (!to_date) {
      err_obj.to_date = "Invalid to date";
    } else if (to_date && to_date > maxDate) {
      err_obj.to_date = `The employment period to date must be less than or equal to 31/03/${financialYear + 1}`
    } else if (to_date && to_date < minDate) {
      err_obj.to_date = `The employment period to date must be greater than or equal to 01/04/${financialYear}`;
    } else if (to_date && from_date && to_date < from_date) {
      err_obj.to_date = `The employment period to date must be greater than or equal to ${data.employment_period_from}`
    }

    setFormError({ ...err_obj });
    if (Object.keys(err_obj).length > 0) {
      setWarning("Please correct the errors in the form before saving.");
      return false;
    }
    
    if(data?.net_taxable_income?.relief_u_s_89_amtp && 
      (data?.net_taxable_income?.total_net_taxable_income_amtp ?? 0) < data?.net_taxable_income?.relief_u_s_89_amtp){
        setWarning('Amount should be less Than or Equal to Net Taxable Income');
        setRelief89usErr('Amount should be less Than or Equal to Net Taxable Income');
        return;
      }

    // other Detail validation 
    const result = validateOtherDetailSchema(otherDetailError, data, financialYear);
    setOtherDetailError({ ...result?.updatedErrors ?? {}});
    setWarning(result?.warningMess ?? "");

    if(!result.validation) return false;

    return true;
  };

  const handleDeducteeNameChange = (event) => {
    const nameStr = event.target.value;
    async function fetchDeducteeName() {
      const result = await getDeducteesV2(
        {
          deductor_id: deductorId,
          search_text: nameStr,
          limit: 17,
          filter_status: 1,
          deductee_type: "INDIVIDUAL_02,OTH_10",
        },
        false
      );
      const DeducteeDataName = [];
      const resultData = result?.data?.data ?? [];
      resultData.map((deductee) => {
        const panDetail = deductee.pan;
        let deductee_name = deductee.deductee_name;
        if (deductee_name.length > 25) {
          deductee_name = deductee_name.substring(0, 25) + "...";
        }
        DeducteeDataName.push({
          id: deductee.deductee_id,
          salary_category: deductee.salary_category,
          ded_name: deductee.deductee_name,
          text: `${deductee.deductee_name} - ${panDetail}`,
          pan: panDetail,
        });
        return true;
      });
      setDeducteeData(DeducteeDataName);
    }
    if (nameStr.length !== 0 && nameStr.length > 0) {
      fetchDeducteeName();
    } else {
      setDeducteeData([]);
    }
  };

  const selectedDeducteeName = async (value) => {
    let filterDeducteeData = deducteeData.filter((data, index) => {
      return data.text === value;
    });
    if (filterDeducteeData.length > 0) {
      data.deductee_id = filterDeducteeData[0].id;
      data.salary_category = filterDeducteeData[0].salary_category;
      data.deductee_name = filterDeducteeData[0].ded_name;
      data.pan = filterDeducteeData[0].pan;
      deducteeDet.deducteeName = value;
      setDeducteeDet({ ...deducteeDet });
      setData({ ...data });
    }
  };

  const HandleClose = () => {
    let NewAddedDeductee = JSON.parse(localStorage.getItem('getNewAddedDeductee'));
    if (NewAddedDeductee) {
        let getDeducteeAllData = NewAddedDeductee[0];
        let getDeducteeID = NewAddedDeductee[1];

        data.deductee_id = getDeducteeID.deductee_id;
        data.salary_category = getDeducteeAllData.salary_category;
        data.deductee_name = getDeducteeAllData.deductee_name;

        deducteeDet.deducteeName = `${getDeducteeAllData.deductee_name} - ${getDeducteeAllData.pan}`;
        setDeducteeDet({ ...deducteeDet });
        setData({ ...data });
    }

    showCalcPopups.createDeducteePopup = false;
    setShowCalcPopups({ ...showCalcPopups });
    localStorage.removeItem('getNewAddedDeductee');
    setTimeout(function () {
        $('#deductee_name').focus();
    });
}

  const listNoDataRender = (element, setFieldValue, values) => {
    const noData = (
      <span>
        <h4
          style={{ fontSize: "1em" }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <span
            className="k-icon k-i-warning"
            style={{ fontSize: "1.6em", top: "0px", paddingBottom: "3px" }}
          />
          <div className="nodata">No data found</div>
          <hr style={{ margin: "4px 0px  0px 0px" }} />
          <span
            onClick={() => {
              showform();
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="btn btn-default"
            style={{
              padding: "0px",
              color: "#31719b",
              backgroundColor: "none",
              border: "none !important",
              textTransform: "capitalize",
              height: "20px",
            }}
          >
            <span className="addnewded_icon">
              <img src={addBtnRoundIcon} width={16} alt="" />
              <span className="addnewded_textfs pl-2">Add new deductee</span>
            </span>
          </span>
        </h4>
      </span>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  const itemRender = (li, itemProps) => {
    const index = itemProps.index;
    const itemChildren = (
      <span>
        <span style={{ color: "#00F" }}>{li.props.children}</span>
        <br />
        <br />
        {index + 1 === deducteeData.length && (
          <>
            <li
              className="k-button addinnerbtnbgclr"
              onClick={() => {
                showform();
              }}
              style={{
                background: "#31719b",
                height: "30px",
                width: "150px",
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              <span className="addnewded_icon pr-2">
                <img src={addBtnRoundIcon} width={16} alt="" />
              </span>
              <span className="addnewded_textfs">Add new deductee</span>
            </li>
          </>
        )}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const showform = () => {
      showCalcPopups.createDeducteePopup = true;
      setShowCalcPopups({ ...showCalcPopups });
  };

  const updateEmployeeDetail = async (data, onClose, warningMesstaxRegime) => {
    const isValid = validateMandatoryDetail(data);
    if (!isValid) return;
    const payload = data;
    payload.return_id = returnId;

    try {
      const result = await updateEmployeeSalaryDetailV2(payload);
      const apiData = updateUIData(result?.data ?? {}, deepClone(data));
      setData({ ...apiData });
      if (onClose) onClosePopup();
      if (warningMesstaxRegime) setWarning(warningMesstaxRegime)

    } catch (err) {
      if (err.message) {
        setError(err?.message);
      } else {
        setError(err);
      }
    }
  };

  const addNewEmployee = async (data) => {
    const isValid = validateMandatoryDetail(data);
    if (!isValid) return;

    const payload = data;
    payload.return_id = returnId;

    try {
      const result = await addEmployeeSalaryDetailV2(payload);

      let apiData = result.data ?? {};
      const usertype = result?.header?.organization_type === "CORPORATE" ? "Details" : "Lite";
      let deductee_name = `${apiData.deductee_name} - ${apiData.pan}`;
      const daterange = {
        start: apiData.employment_period_from ? moment(apiData.employment_period_from, "DD/MM/YYYY").toDate() : null,
        end: apiData.employment_period_to ? moment(apiData.employment_period_to, "DD/MM/YYYY").toDate() : null,
      }

      apiData.is_government_deductor = headerDataDetails.is_government_deductor;
      apiData = transformApiData(apiData, usertype);
      // transform dropdown list
      drowdownList.salary17one = transformDropdown(apiData?.salary_breakup_amtp?.details ?? [], SALARY_17_1DROPDOWN, "particular_code");
      drowdownList.perquisites_17_2 = transformDropdown(apiData?.perquisite_breakup_amtp ?? [], perquisites_17_2DROPDOWN, "particular_code");
      drowdownList.profit_17_3 = transformDropdown(apiData?.profit_in_lieu_breakup_amtp ?? [], profit_17_3DROPDOWN, "particular_code");
      drowdownList.othinc_off_det = transformDropdown(apiData?.other_income_offered_details ?? [], OTHERINCOMEOFFERED_DROPDOWN, "particular_code");
      drowdownList.retirebenfits = transformDropdown(apiData?.allowances ?? [], RETIRMENTBENFIT_DROPDOWN, "allowance_type_id");
      drowdownList.allowanceExempt = transformDropdown(apiData?.allowances ?? [], ALLOWANCEEXEMPT_DROPDOWN?.[usertype] ?? [], "allowance_type_id");

      if (!isUserEdit) setIsUserEdit(true);

      setDropdownList({ ...drowdownList });
      deducteeDet.deducteeName = deductee_name;
      deducteeDet.dateRange = daterange;
      setDeducteeDet({ ...deducteeDet });
      setData({ ...apiData });
      parentGetListApi();
    } catch (err) {
      if (err.message) {
        setError(err?.message);
      } else {
        setError(err);
      }
    }
  };

  const getDeductionVIAParticularsList = () => {
    async function fetchEverything() {
      async function fetchDeductionVIAParticulars() {
        const result = await getDeductionVIAParticulars(
          financialYear,
          headerDataDetails?.deductor_category
        );
        drowdownList.deductionVI_A = result?.data ?? {};
        setDropdownList({ ...drowdownList });
      }
      try {
        await Promise.all([fetchDeductionVIAParticulars()]);
      } catch (err) { }
    }
    fetchEverything();
  };

  const onClosePopup = () => {
    setShowCalcPopups({});
  };

  const addOverlayOnParent = () => {
    let show = false;
    const calc = showCalcPopups;
    if (
      (calc?.computedPensionPopup ?? false) !== false ||
      (calc?.gratuityPopup ?? false) !== false ||
      (calc?.SelfOccupiedPopup ?? false) !== false ||
      (calc.houseRentAllowPopup ?? false) !== false ||
      (calc?.createDeducteePopup ?? false) !== false ||
      (calc?.leaveSalaryEncashPopup ?? false) !== false ||
      (calc?.letOutPropertyPopup ?? false) !== false ||
      (calc?.travelConcessionPopup ?? false) !== false
    ) {
      show = true;
      const zIndex = $(".k-widget.k-window.windowWrapper.salarary_detail_popup.overlaynew").css("z-index");
      setTimeout(() => {
        $(".k-overlay.addintionaloverlaymult").css('z-index', `${convertNum(zIndex)}`);
        $(".k-widget.k-window.windowWrapper.salarary_detail_popup.overlaynew").css("z-index", `${convertNum(zIndex) - 1}`);
      }, 100);
    };
    return show;
  }

  useEffect(() => {
    getDeductionVIAParticularsList();
    //eslint-disable-next-line
  }, [deductorId]);

  // When the user presses the enter key, focus on the next input.
  $("input,select").bind("keydown", function (e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      $('input, select, textarea')
      [$('input,select,textarea').index(this) + 1]?.focus();
    }
  });

  $(document).ready(function () {
    // Initialize tooltips
    $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });

    // Adjust z-index after tooltip is shown
    $('[data-toggle="tooltip"]').on('shown.bs.tooltip', function () {
      $('.tooltip').css('z-index', '100990'); // Adjust z-index dynamically here
    });
  });

  return (
    <>

      <ShowAlert
        error={error}
        warning={warning}
        success={success}
        onClose={() => {
          setError(null);
          setSuccess(null);
          setWarning(null)
        }}
      />

      {showCalcPopups?.createDeducteePopup ? (
        <AddDeductee
          onClose={() => {
            HandleClose();
          }}
          deductorId={deductorId}
          showDeducteeFormLeft={true}
          left={width1 - 1850}
        />
      ) : null}

      {showCalcPopups?.computedPensionPopup && (
        <ComputedPension
          parentPopupWidth={popupWidth}
          info={showCalcPopups?.computedPensionPopup}
          updateEmployeeDetail={updateEmployeeDetail}
          mainData={deepClone(data)}
          onClose={onClosePopup}
        />
      )}
      {showCalcPopups?.gratuityPopup && (
        <Gratuity
          parentPopupWidth={popupWidth}
          info={showCalcPopups?.gratuityPopup}
          updateEmployeeDetail={updateEmployeeDetail}
          mainData={deepClone(data)}
          onClose={onClosePopup}
        />
      )}
      {showCalcPopups?.houseRentAllowPopup && (
        <HouseRentAllowance
          parentPopupWidth={popupWidth}
          info={showCalcPopups?.houseRentAllowPopup}
          updateEmployeeDetail={updateEmployeeDetail}
          mainData={deepClone(data)}
          onClose={onClosePopup}
        />
      )}

      {showCalcPopups?.leaveSalaryEncashPopup && (
        <LeaveSalaryEncashment
          parentPopupWidth={popupWidth}
          info={showCalcPopups?.leaveSalaryEncashPopup}
          updateEmployeeDetail={updateEmployeeDetail}
          mainData={deepClone(data)}
          onClose={onClosePopup}
        />
      )}

      {showCalcPopups?.letOutPropertyPopup && (
        <LetOutProperty
          parentPopupWidth={popupWidth}
          info={showCalcPopups?.letOutPropertyPopup}
          updateEmployeeDetail={updateEmployeeDetail}
          mainData={deepClone(data)}
          onClose={onClosePopup}
        />
      )}

      {showCalcPopups?.SelfOccupiedPopup && (
        <SelfOccupied
          parentPopupWidth={popupWidth}
          info={showCalcPopups?.SelfOccupiedPopup}
          updateEmployeeDetail={updateEmployeeDetail}
          mainData={deepClone(data)}
          onClose={onClosePopup}
        />
      )}

      {showCalcPopups?.travelConcessionPopup && (
        <TravelConcession
          parentPopupWidth={popupWidth}
          info={showCalcPopups?.travelConcessionPopup}
          updateEmployeeDetail={updateEmployeeDetail}
          mainData={deepClone(data)}
          onClose={onClosePopup}
          headerData={headerDataDetails}
        />
      )}

      {addOverlayOnParent() && <div className='k-overlay addintionaloverlaymult'></div>}

      <WindowWrapperFixed
        title={
          <kendo-dialog-titlebar
            style={{ padding: "8px 0px 3px 0px", width: "1000%" }}
          >
            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="input-group Salary_Tax_Regime_from_editbox"
                    data-toggle="tooltip" data-placement="left" data-original-title={`${formError.deductee_name ? formError.deductee_name : ""}`}
                  >
                    <AutoComplete
                      data={deducteeData}
                      id="deductee_name"
                      textField="text"
                      dataItemKey="id"
                      value={deducteeDet?.deducteeName ?? ""}
                      className={`form-control Salary_Tax_Regime_from_inputbox ${formError.deductee_name ? "input-error" : ""
                        }`}
                      popupSettings={{ className: "form-control-option" }}
                      onChange={(e) => {
                        handleDeducteeNameChange(e);
                        deducteeDet.deducteeName = e.target.value;
                        setDeducteeDet({ ...deducteeDet });
                        if (e.target.value.length < 2) {
                          data.deductee_id = "";
                          data.salary_category = "";
                          data.pan = "";
                          setData({ ...data });
                        }
                      }}
                      onClose={(e) => {
                        selectedDeducteeName(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (data?.salary_id) {
                          updateEmployeeDetail(data);
                        } else {
                          addNewEmployee(data);
                        }
                      }}
                      placeholder="Enter Employee Name"
                      name="deductee_name"
                      listNoDataRender={(element) => {
                        return listNoDataRender(element);
                      }}
                      itemRender={(li, itemProps) => {
                        return itemRender(li, itemProps);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <span className="taxregim_textfs pr-3">Tax Regime</span>
                  <span className="taxregim_new_textfs pr-2">New</span>
                  <label className="switch Salary_taxregim_SW">
                    <input
                      type="checkbox"
                      checked={data.tax_regime === "OLD" ? true : false}
                      onChange={(e) => {
                        const val = e.target.checked ? "OLD" : "NEW";
                        const showMessage = `You have changed the Tax Regime for ${deducteeDet?.deducteeName ?? ""} from ${data.tax_regime ?? ""} to ${val}.`
                        const newdata = deepClone(data);
                        newdata.tax_regime = val;
                        if (newdata?.salary_id) {
                          updateEmployeeDetail(newdata, false, showMessage);
                        } else {
                          setData({ ...newdata });
                          addNewEmployee(newdata);
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>

                  <span className="taxregim_old_textfs">Old</span>
                </div>
                <div className="col-md-5">
                  <span className="input-group salarytaxreg_daterangegrp">
                    <span className="salarytaxreg_daterangegrp_textfs pr-1">
                      Employment Period<span className="textred">*</span> :{" "}
                    </span>
                    <div>
                        <DateRangePicker
                          value={{
                            start: deducteeDet?.dateRange?.start ?? null,
                            end: deducteeDet?.dateRange?.end ?? null
                          }}
                          onChange={(e) => {
                            // show date different state(deducteedet)
                            deducteeDet.dateRange.start = e.target.value?.start;
                            deducteeDet.dateRange.end = e.target.value?.end;
                            data.employment_period_from = e.target.value?.start ? moment(e.target.value?.start).format("DD/MM/YYYY") : null;
                            data.employment_period_to = e.target.value?.end ? moment(e.target.value?.end).format("DD/MM/YYYY") : null;
                            setData({ ...data });
                            setDeducteeDet({ ...deducteeDet });
                          }}
                          onBlur={(e) => {
                            if (data?.salary_id) {
                              updateEmployeeDetail(data);
                            } else {
                              addNewEmployee(data);
                            }
                          }}
                          className={`${formError.from_date ? "frminperr" : ""} ${formError.to_date ? "toinperr" : ""}`}
                          min={new Date(`${moment(`01/04/${financialYear}`, "DD/MM/YYYY")}`)}
                          max={new Date(`${moment(`31/03/${financialYear + 1}`, "DD/MM/YYYY")}`)}
                          endDateInputSettings={{
                            title: `${formError.to_date ? formError.to_date : ""}`,
                            className: `toerrorclass`,
                            format: "dd/MM/yyyy",
                            formatPlaceholder: {
                              year: "YYYY",
                              month: "MM",
                              day: "DD"
                            }
                          }}
                          startDateInputSettings={{
                            title: `${formError.from_date ? formError.from_date : ""}`,
                            className: `fromerrorclass`,
                            format: "dd/MM/yyyy",
                            formatPlaceholder: {
                              year: "YYYY",
                              month: "MM",
                              day: "DD"
                            }
                          }}
                        />
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="col-md-3 col-sm-3 col-lg-3 col-xl-3"
              style={{ paddingTop: "5px" }}
            ></div>
          </kendo-dialog-titlebar>
        }
        onClose={() => onClose(isUserEdit)}
        initialHeight={"auto"}
        initialWidth={900}
        initialTop={50}
        totalPopups={1}
        isModalView={true}
        initialLeft={width1 - popupWidth}
        className={"salarary_detail_popup overlaynew"}
      >

          <EmployeeSalaryDetail
            data={data}
            setData={setData}
            USER_TYPE={USER_TYPE}
            drowdownList={drowdownList}
            setDropdownList={setDropdownList}
            updateEmployeeDetail={updateEmployeeDetail}
            financialYear={financialYear}
            deducteeDet={deducteeDet}
            setDeducteeDet={setDeducteeDet}
            otherDetailError={otherDetailError}
            setOtherDetailError={setOtherDetailError}
            setWarning={setWarning}
            relief89usErr={relief89usErr}
            showCalcHousePrtyPopupFunc={(popupName, poppupData) => {
              const obj = { [popupName]: { poppupData } };
              setShowCalcPopups({ ...obj });
            }}
            showCalcPopupsfunc={(
              popupName,
              index,
              parentKey,
              updateArrayKey,
              poppupData = null
            ) => {
              const obj = {
                [popupName]: { index, parentKey, updateArrayKey, poppupData },
              };
              setShowCalcPopups({ ...obj });
            }}
          />
      </WindowWrapperFixed>
    </>
  );
};

export default AddSalaryDetail;
