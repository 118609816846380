import React, { useEffect, useState } from 'react';
import ReturnFlowTimeLine from '../../deducteeTransactions/ReturnFlowTimeLine';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ShowAlert from '../../../components/ShowAlert';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy } from "@progress/kendo-data-query";
import { ColumnMenu } from '../../../components/grid/ColumnMenuFrontEnd';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Skeleton12ColumnGrid from '../../../components/Skeleton/Skeleton12ColumnGrid';
import {
  getChallansInterestAllocationList,
  getInterestMappingLateLiabilitySmmary,
  saveInterestAllocationData
} from '../../../api/challanAPI';
import Pagination from '../../../components/pagination/Pagination';
import { deepClone, formatNumber, getColumnTotalFromArrayOfObject } from '../../../utils/UtilityFunctions';
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { FormatNumberDecimal, FormatNumberDecValue } from '../../../utils/UtilityFunctions';
import InterestMappingLibilityDataPopup from './InterestMappingLibilityDataPopup';
import LateFeeLibilityDataPopup from './LateFeeLibilityDataPopup';
import SaveAllocationDataPopup from './SaveAllocationDataPopup';
import AddChallan from '../../challanList/AddChallan';
import { AddDeducteeTransactionsIcon } from '../../../components/grid/CustomCell';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../header/headerSlice';
import "./ReturnFilingInterestMapping.scss";

const initialFilter = {
  logic: "and",
  filters: [],
};

const SUMMARY = {
  total_challan_count: 0,
  total_amtp: 0,
  total_unconsumed_amtp: 0,
  unconsumed_int_amtp: 0,
  unconsumed_fees_or_other_amtp: 0,
  allocated_int_amtp: 0,
}

const ReturnFilingInterestMapping = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const deductorId = params?.deductor_id;
  const returnId = params?.return_id;
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [interestMappingList, setInterestMappingList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(true);
  const [dataState, setDataState] = useState({});
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const limit = 250;
  const [lateLiabilitySummary, setLateLiabilitySummary] = useState(null);
  const [showLibilityPopup, setShowLibilityPopup] = useState(false);
  const [saveAllocationPopup, setSaveAllocationPopup] = useState(false);
  const [showLateFeePopup, setShowLateFeePopup] = useState(false);
  const [showAutoAllocateButton, setShowAutoAllocateButton] = useState(false);
  const [autoAllocationUsed, setAutoAllocationUsed] = useState(true);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showPayButton, setShowPayButton] = useState(false);
  const [showChallanForm, setShowChallanForm] = useState(false);
  const [filter, setFilter] = React.useState(initialFilter);
  const [summary, setSummary] = useState(SUMMARY);
  const headerData = useSelector(selectHeaderData);
  const [formType, setFormType] = useState('');

  // const triggerFeeAllocationChange = (challans) => {
  //   let enableSaveButton = false;
  //   challans.forEach((item) => {
  //     if (Number(item?.is_mapped ?? 0) === 1 && Number(item?.allocated_fee_amtp ?? 0) === 0 && Number(item?.fees_amtp ?? 0) > 0) {
  //       item.allocated_fee_amtp = item?.fees_amtp;
  //       item.isFeeAllocationChanged = true;
  //       enableSaveButton = true;
  //     }
  //   })
  //   if (enableSaveButton) {
  //     setShowSaveButton(true);
  //     setInterestMappingList((prev) => {
  //       prev = challans;
  //       return [...prev];
  //     });
  //   }
  // }

  const getLateLiabilitySummaryData = async (responseData) => {
    try {
      const request = `?deductor_id=${deductorId}&return_id=${returnId}`;
      const result = await getInterestMappingLateLiabilitySmmary(request);
      const liabitlitySummary = result?.data ?? {};
      liabitlitySummary.allocated_fee_amtp = 0;
      liabitlitySummary.allocated_int_amtp = 0;

      if (responseData?.length > 0) {
        responseData.forEach((item) => {
          liabitlitySummary.allocated_fee_amtp += item.allocated_fee_amtp ?? 0;
          liabitlitySummary.allocated_int_amtp += item.allocated_int_amtp ?? 0;
        })
      }
      liabitlitySummary.remaining_int_amtp = liabitlitySummary.total_int_amtp - liabitlitySummary.allocated_int_amtp;
      liabitlitySummary.remaining_fee_amtp = liabitlitySummary.total_fee_amtp - liabitlitySummary.allocated_fee_amtp;;


      setLateLiabilitySummary(liabitlitySummary);

    } catch (err) {
      console.error('error: ', err);
      setError(err.message);
    } finally {
      //setShowSkelton(false);
    }
  }
  const resetDate = (date) => {
    const [d, m, y] = date.split('/');
    return new Date(y, m - 1, d);
  }
  const converter = (challans) => {
    if (challans.length > 0) {
      challans.forEach((item, index) => {
        const date = item?.deposit_date ? resetDate(item?.deposit_date) : '';
        challans[index] = {
          ...item,

          display_code: (item?.display_code === null || item?.display_code === '') ? 'NA' : item.display_code,
          deposit_date_filter: date,
          reduce_unconsumed_tds_for_interest_amtp: 0,
          reduce_unconsumed_tds_for_fee_amtp: 0,
          isInterestAllocationChanged: false,
          isTdsAmountUpdated: false,
          isFeeAllocationChanged: false,
          org_total_unconsumed_amtp: item?.total_unconsumed_amtp ?? 0,
          org_unconsumed_fee_amtp: item?.fees_amtp ?? 0,
          org_allocated_fee_amtp: item?.allocated_fee_amtp ?? 0,
        }
      })
    }
    return challans;
  }

  const getInterestMappingData = () => {
    async function fetchEverything() {
      async function fetchChallans() {
        const postData = {
          deductor_id: deductorId,
          return_id: returnId,
          limit: limit
        };
        const result = await getChallansInterestAllocationList(postData);
        const challans = converter(result?.data?.data ?? []);
        setInterestMappingList((prev) => {
          prev = challans;
          return [...prev];
        });
        getLateLiabilitySummaryData(result?.data?.data ?? []);

        if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page;
        setCursor(result.data.cursor);
      }
      try {
        await Promise.all([fetchChallans()]);
        setError(null);
      } catch (err) {
        console.error('error: ', err);
        setError(err.message);
      } finally {
        setShowSkelton(false);
      }
    }

    fetchEverything();
  }

  const dataStateChange = (dataState) => {
    setDataState(dataState);
    setFilter(dataState?.filter);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };

  const getDate = (date) => {
    if (date) {
      const dd = date.split('T');
      return dd[0]
    }
  }

  const autoAllocateInterestAmount = () => {
    //interest auto allocation
    let remainingInterestAmount = Math.ceil(Number(lateLiabilitySummary?.remaining_int_amtp ?? 0));
    let liabilityAllocated = Math.ceil(Number(lateLiabilitySummary?.allocated_int_amtp ?? 0));

    interestMappingList.forEach((challan, keyIndex) => {

      if (Number(remainingInterestAmount) <= 0) return;

      let challanUnconsumedInterestAmtp = Math.ceil(Number(challan.unconsumed_int_amtp ?? 0));

      if (challanUnconsumedInterestAmtp > 0) {

        //calculate allocated interest amount
        const interestAmountToAllocate = Math.min(Number(remainingInterestAmount), Number(challanUnconsumedInterestAmtp));

        //update liability allocated summary data
        remainingInterestAmount = Math.ceil(Number(remainingInterestAmount) - Number(interestAmountToAllocate));
        liabilityAllocated = Math.ceil(Number(liabilityAllocated) + Number(interestAmountToAllocate));

        //update challan data
        challan.allocated_int_amtp = Math.ceil(Number(challan.allocated_int_amtp) + Number(interestAmountToAllocate));
        challanUnconsumedInterestAmtp = Math.ceil(Number(challanUnconsumedInterestAmtp) - Number(interestAmountToAllocate));
        challan.unconsumed_int_amtp = Math.ceil(challanUnconsumedInterestAmtp);
        challan.isInterestAllocationChanged = true;

        //update list with updated challan details
        interestMappingList[keyIndex] = challan;
      }

    })

    interestMappingList.forEach((challan, keyIndex) => {

      if (Number(remainingInterestAmount) <= 0) return;

      let challanUnconsumedTdsAmtp = Math.ceil(Number(challan.total_unconsumed_amtp ?? 0));

      if (challanUnconsumedTdsAmtp > 0) {

        //calculate allocated TDS amount
        const tdsAmountToAllocate = Math.min(Number(remainingInterestAmount), Number(challanUnconsumedTdsAmtp));

        //update liability allocated summary data
        remainingInterestAmount = Math.ceil(Number(remainingInterestAmount) - Number(tdsAmountToAllocate));
        liabilityAllocated = Math.ceil(Number(liabilityAllocated) + Number(tdsAmountToAllocate));

        //update challan data
        challan.allocated_int_amtp = Math.ceil(Number(challan.allocated_int_amtp) + Number(tdsAmountToAllocate));
        challanUnconsumedTdsAmtp = Math.ceil(Number(challanUnconsumedTdsAmtp) - Number(tdsAmountToAllocate));
        challan.total_unconsumed_amtp = Math.ceil(challanUnconsumedTdsAmtp);
        challan.isInterestAllocationChanged = true;
        challan.isTdsAmountUpdated = true;
        challan.reduce_unconsumed_tds_for_interest_amtp = tdsAmountToAllocate;

        //update list with updated challan details
        interestMappingList[keyIndex] = challan;
      }

    })

    setShowSaveButton(true);

    //Update state list object
    setInterestMappingList((prev) => {
      prev = interestMappingList;
      return [...prev];
    });


    //Update liability summary object
    lateLiabilitySummary.remaining_int_amtp = remainingInterestAmount;
    lateLiabilitySummary.allocated_int_amtp = liabilityAllocated;

    // //Update state liability object
    setLateLiabilitySummary((prev) => {
      prev = lateLiabilitySummary;
      return { ...prev };
    });

  }

  // const autoAllocateFeeAmount = () => {
  //   //interest auto allocation
  //   let remainingFeeAmount = Math.ceil(Number(lateLiabilitySummary?.remaining_fee_amtp ?? 0));
  //   let liabilityAllocated = Math.ceil(Number(lateLiabilitySummary?.allocated_fee_amtp ?? 0));

  //   interestMappingList.forEach((challan, keyIndex) => {

  //     if (Number(remainingFeeAmount) <= 0) return;

  //     let challanUnconsumedFeeAmtp = Math.ceil(Number(challan.fees_amtp ?? 0));

  //     if (challanUnconsumedFeeAmtp > 0) {

  //       //calculate allocated interest amount
  //       const feeAmountToAllocate = Math.min(Number(remainingFeeAmount), Number(challanUnconsumedFeeAmtp));

  //       //update liability allocated summary data
  //       remainingFeeAmount = Math.ceil(Number(remainingFeeAmount) - Number(feeAmountToAllocate));
  //       liabilityAllocated = Math.ceil(Number(liabilityAllocated) + Number(feeAmountToAllocate));

  //       //update challan data
  //       challan.allocated_fee_amtp = Math.ceil(Number(challan.allocated_fee_amtp) + Number(feeAmountToAllocate));
  //       challanUnconsumedFeeAmtp = Math.ceil(Number(challanUnconsumedFeeAmtp) - Number(feeAmountToAllocate));
  //       challan.fees_amtp = Math.ceil(challanUnconsumedFeeAmtp);
  //       challan.isFeeAllocationChanged = true;

  //       //update list with updated challan details
  //       interestMappingList[keyIndex] = challan;
  //     }

  //   })

  //   setShowSaveButton(true);

  //   //Update state list object
  //   setInterestMappingList((prev) => {
  //     prev = interestMappingList;
  //     return [...prev];
  //   });


  //   //Update liability summary object
  //   lateLiabilitySummary.remaining_fee_amtp = remainingFeeAmount;
  //   lateLiabilitySummary.allocated_fee_amtp = liabilityAllocated;

  //   // //Update state liability object
  //   setLateLiabilitySummary((prev) => {
  //     prev = lateLiabilitySummary;
  //     return { ...prev };
  //   });

  // }

  const updateStateData = (mappingList, liabilitySummary) => {

    setInterestMappingList((prev) => {
      prev = mappingList
      return [...prev]
    })

    //update remaining allocation state data
    setLateLiabilitySummary((prev) => {
      prev = liabilitySummary
      return { ...prev }
    })
  }

  const removeFormatting = (amount) => {
    if (amount !== '' && amount !== undefined && amount !== null) {
      return amount.replace(/,/g, '');
    }
    return amount;
  }

  const resetLiabilityAllocation = (mappingList) => {

    const summaryObj = deepClone(lateLiabilitySummary);
    if (!summaryObj) return;
    let interestAlllocation = 0;
    let otherAllocation = 0;
    let feeAllocation = 0;
    mappingList.forEach((item) => {
      interestAlllocation += Number(item?.allocated_int_amtp ?? 0);
      otherAllocation += Number(item?.allocated_other_amtp ?? 0);
      feeAllocation += Number(item?.allocated_fee_amtp ?? 0);
    })

    summaryObj.allocated_int_amtp = Number(interestAlllocation);
    summaryObj.allocated_other_amtp = Number(otherAllocation);
    summaryObj.allocated_fee_amtp = Number(feeAllocation);
    summaryObj.remaining_int_amtp = Number(summaryObj?.total_int_amtp ?? 0) - Number(interestAlllocation);
    summaryObj.remaining_other_amtp = Number(summaryObj?.total_other_amtp ?? 0) - Number(otherAllocation);
    summaryObj.remaining_fee_amtp = Number(summaryObj?.total_fee_amtp ?? 0) - Number(feeAllocation);

    updateStateData(mappingList, summaryObj);

  }

  // const handleManuallyFeeAllocatedAmountChange = (e, challan_id) => {
  //   //manual allocation
  //   const index = getChallanIndexById(challan_id);

  //   //get current challan from state list
  //   const mappingList = deepClone(interestMappingList);
  //   const liabilitySummary = deepClone(lateLiabilitySummary);

  //   const selectedChallan = mappingList?.[index] ?? [];

  //   selectedChallan['isFeeAllocationChanged'] = true;


  //   const oldValue = removeFormatting(e.target['valueBeforeFocus'] !== '' ? e.target['valueBeforeFocus'] : 0);
  //   const newValue = removeFormatting(e.target.value !== '' ? e.target.value : 0);

  //   //calculate delta fee amount
  //   let deltaFee = Number(newValue) - Number(oldValue);

  //   // No change done by user - Ignore and return back
  //   if (deltaFee === 0) {
  //     return true;
  //   }

  //   //Error if total fee liability = 0, not allowed to enter any value in fee allocation
  //   if (Number(lateLiabilitySummary?.total_fee_amtp ?? 0) <= 0 && Number(deltaFee) > 0) {
  //     setError('Liability calculated is less then adjusted Fee, so entering value in "Fees" column will add the particular Challan to the TDS statement');
  //   }

  //   //Error if user allocate fee < unconsumed fee amount
  //   if (Number(deltaFee) > 0 && Number(newValue) < (Number(selectedChallan?.org_unconsumed_fee_amtp ?? 0))) {
  //     selectedChallan.allocated_fee_amtp = Math.round(oldValue);//reset for old value
  //     setError('Amount cannot be less than Fee deposited in challan');
  //     mappingList[index] = selectedChallan;
  //     updateStateData(mappingList, liabilitySummary);
  //     resetLiabilityAllocation(mappingList);
  //     setShowSaveButton(false);
  //     return true;
  //   }

  //   // Error if user is overallocating against balance of unconsumed fee + unconsumed TDS
  //   //if (Number(deltaFee) > (Number(selectedChallan?.org_unconsumed_fee_amtp ?? 0) + Number(selectedChallan?.org_total_unconsumed_amtp ?? 0))) {
  //   if (Number(deltaFee) > 0 && Number(newValue) > (Number(selectedChallan?.org_unconsumed_fee_amtp ?? 0) + Number(selectedChallan?.org_total_unconsumed_amtp ?? 0) - Math.round(Number(selectedChallan?.reduce_unconsumed_tds_for_interest_amtp ?? 0)))) {
  //     //selectedChallan.allocated_fee_amtp = Math.round(oldValue);
  //     selectedChallan.allocated_fee_amtp = Math.round(Number(selectedChallan?.org_unconsumed_fee_amtp ?? 0));
  //     selectedChallan.total_unconsumed_amtp = Math.round(Number(selectedChallan?.org_total_unconsumed_amtp ?? 0)) - Math.round(Number(selectedChallan?.reduce_unconsumed_tds_for_interest_amtp ?? 0));
  //     setError('Enter amount equals to or less than Unconsumed TDS');
  //     mappingList[index] = selectedChallan;
  //     updateStateData(mappingList, liabilitySummary);
  //     resetLiabilityAllocation(mappingList);
  //     setShowSaveButton(false);
  //     return true;
  //   }

  //   // if (Number(newValue) === (Number(selectedChallan?.org_unconsumed_fee_amtp ?? 0))) {
  //   //   selectedChallan.allocated_fee_amtp = Math.round(Number(selectedChallan?.org_unconsumed_fee_amtp ?? 0));
  //   //   selectedChallan.total_unconsumed_amtp = Math.round(Number(selectedChallan?.total_unconsumed_amtp ?? 0)) + Math.round(Number(selectedChallan?.reduce_unconsumed_tds_for_fee_amtp ?? 0));
  //   //   selectedChallan.reduce_unconsumed_tds_for_fee_amtp = 0;
  //   //   mappingList[index] = selectedChallan;
  //   //   updateStateData(mappingList, liabilitySummary);
  //   //   resetLiabilityAllocation(mappingList);
  //   //   setShowSaveButton(true);
  //   //   return true;
  //   // }

  //   if (Number(selectedChallan?.fees_amtp ?? 0) > 0 && Number(selectedChallan?.org_allocated_fee_amtp ?? 0) === 0 && Number(newValue) > 0) {
  //     if (Number(deltaFee) < 0) {

  //       selectedChallan.total_unconsumed_amtp = Number(selectedChallan.total_unconsumed_amtp) + Math.abs(Number(deltaFee));
  //       selectedChallan.reduce_unconsumed_tds_for_fee_amtp = Number(selectedChallan?.reduce_unconsumed_tds_for_fee_amtp ?? 0) - Math.abs(Number(deltaFee))

  //     } else {

  //       let usableUnconsumedFeeTdsAmount = Math.round(Number(newValue) - Number(selectedChallan?.fees_amtp ?? 0));
  //       selectedChallan.reduce_unconsumed_tds_for_fee_amtp = usableUnconsumedFeeTdsAmount;
  //       selectedChallan.total_unconsumed_amtp = Number(selectedChallan.total_unconsumed_amtp) - usableUnconsumedFeeTdsAmount;
  //     }
  //     selectedChallan.isTdsAmountUpdated = true;
  //     mappingList[index] = selectedChallan;
  //     updateStateData(mappingList, liabilitySummary);
  //     resetLiabilityAllocation(mappingList);
  //     setShowSaveButton(true);
  //     return true;
  //   }

  //   // User has reduced the allocated fee amount
  //   if (deltaFee < 0) {
  //     if (Number(newValue) < Number(selectedChallan?.org_unconsumed_fee_amtp ?? 0)) {
  //       //selectedChallan.allocated_fee_amtp = Math.round(oldValue);
  //       selectedChallan.allocated_fee_amtp = Math.round(Number(selectedChallan?.org_unconsumed_fee_amtp ?? 0));
  //       selectedChallan.total_unconsumed_amtp = selectedChallan.org_total_unconsumed_amtp;
  //       selectedChallan.reduce_unconsumed_tds_for_fee_amtp = 0;
  //       setError('Amount cannot be less than Fee deposited in challan');
  //       mappingList[index] = selectedChallan;
  //       updateStateData(mappingList, liabilitySummary);
  //       resetLiabilityAllocation(mappingList);
  //       setShowSaveButton(false);
  //       return true;
  //     }

  //     selectedChallan.total_unconsumed_amtp = Number(selectedChallan.total_unconsumed_amtp) + Math.abs(Number(deltaFee));

  //     selectedChallan.reduce_unconsumed_tds_for_fee_amtp = Number(selectedChallan?.reduce_unconsumed_tds_for_fee_amtp ?? 0) - Math.abs(Number(deltaFee))

  //     //selectedChallan.fees_amtp = Math.ceil(Number(selectedChallan?.fees_amtp ?? 0) + Math.abs(Number(deltaFee)));

  //     //update challan data in list
  //     mappingList[index] = selectedChallan;

  //     updateStateData(mappingList, liabilitySummary);

  //     resetLiabilityAllocation(mappingList);

  //     setShowSaveButton(true);

  //     return true;

  //   } else {


  //     //User has increased the allocated fee amount
  //     let usableUnconsumedFeeAmount = Math.min(Number(selectedChallan.total_unconsumed_amtp), Number(deltaFee));

  //     //update challan unconsumed tds amount with usable unconsumed fee amount
  //     selectedChallan.total_unconsumed_amtp = Math.ceil(Number(selectedChallan.total_unconsumed_amtp) - Number(usableUnconsumedFeeAmount));

  //     selectedChallan.reduce_unconsumed_tds_for_fee_amtp += Number(usableUnconsumedFeeAmount);
  //     selectedChallan.isTdsAmountUpdated = true;
  //     //update delta fee amount with usable unconsumed fee amount 
  //     deltaFee = Math.ceil(Number(deltaFee) - Number(usableUnconsumedFeeAmount));

  //     //Scenario 1: No more fee amount left to allocate
  //     if (deltaFee <= 0) {

  //       //update challan data in list
  //       //selectedChallan.reduce_unconsumed_tds_for_fee_amtp -= Number(usableUnconsumedFeeAmount);

  //       mappingList[index] = selectedChallan;

  //       updateStateData(mappingList, liabilitySummary);

  //       resetLiabilityAllocation(mappingList);

  //       setShowSaveButton(true);

  //       return true;

  //     }

  //     // Scenario 2: There is no Unconsumed TDS amt left to balance against the allocated fee amt. Show error
  //     if (Number(selectedChallan?.total_unconsumed_amtp ?? 0) === 0) {

  //       //set error status
  //       setError('Amount must be equals to or less than Unconsumed TDS');

  //       selectedChallan.allocated_fee_amtp = Math.round(selectedChallan.fees_amtp);

  //       selectedChallan.reduce_unconsumed_tds_for_fee_amtp -= Number(usableUnconsumedFeeAmount);
  //       selectedChallan.isTdsAmountUpdated = true;

  //       //update challan data in list
  //       mappingList[index] = selectedChallan;

  //       updateStateData(mappingList, liabilitySummary);

  //       resetLiabilityAllocation(mappingList);

  //       setShowSaveButton(false);

  //       return true;
  //     }

  //     // Scenario 3: There is some Unconsumed TDS amt left to balance against the allocated fee amt, but it is insufficient. Show error
  //     if (Number(selectedChallan?.total_unconsumed_amtp ?? 0) > 0 && Number(selectedChallan?.total_unconsumed_amtp ?? 0) < deltaFee) {

  //       //update challan data and tds updated status
  //       selectedChallan.reduce_unconsumed_tds_for_fee_amtp = selectedChallan.total_unconsumed_amtp;
  //       selectedChallan.total_unconsumed_amtp = 0;
  //       selectedChallan.isTdsAmountUpdated = true;
  //       setError('Amount must be equals to or less than Unconsumed TDS');

  //       //update challan data in list
  //       mappingList[index] = selectedChallan;

  //       updateStateData(mappingList, liabilitySummary);

  //       resetLiabilityAllocation(mappingList);

  //       setShowSaveButton(false);

  //       return true;

  //     } else {

  //       // Scenario 4: There is sufficient Unconsumed TDS amt left to balance against the allocated fee amt.

  //       //update challan data and tds updated status
  //       selectedChallan.total_unconsumed_amtp = Math.ceil(Number(selectedChallan.total_unconsumed_amtp) - Number(deltaFee));
  //       selectedChallan.reduce_unconsumed_tds_for_fee_amtp = Number(deltaFee);
  //       selectedChallan.isTdsAmountUpdated = true;

  //       //update challan data in list
  //       mappingList[index] = selectedChallan;

  //       updateStateData(mappingList, liabilitySummary);

  //       resetLiabilityAllocation(mappingList);

  //       setShowSaveButton(true);

  //       return true;
  //     }

  //   }

  // }

  const handleManuallyInterestAllocatedAmountChange = (e, challan_id) => {
    //manual allocation
    const index = getChallanIndexById(challan_id);

    const oldValue = removeFormatting(e.target['valueBeforeFocus'] !== '' ? e.target['valueBeforeFocus'] : 0);
    const newValue = removeFormatting(e.target.value !== '' ? e.target.value : 0);

    //calculate delta interest amount
    let deltaInterest = Number(newValue) - Number(oldValue);

    // No change done by user - Ignore and return back
    if (deltaInterest === 0) {
      return true;
    }

    //get current challan from state list
    const mappingList = deepClone(interestMappingList);
    const liabilitySummary = deepClone(lateLiabilitySummary);

    const selectedChallan = mappingList?.[index] ?? [];

    selectedChallan['isInterestAllocationChanged'] = true;

    //Error if total interest liability = 0, not allowed to enter any value in interest allocation
    const total_interest_liability = Number(lateLiabilitySummary?.total_int_amtp ?? 0);
    if (total_interest_liability <= 0) {
      setError('Liability calculated is less then adjusted Interest, so entering Value in "Allocate Interest" column will Add the particular Challan to the TDS statement');
    }

    // Error if user is overallocating against balance of unconumsed Interest + TDS
    if (Number(deltaInterest) > (Number(selectedChallan?.unconsumed_int_amtp ?? 0) + Number(selectedChallan?.total_unconsumed_amtp ?? 0))) {
      selectedChallan.allocated_int_amtp = Math.round(oldValue);
      setError('Amount must be equals to or less than Unconsumed TDS');
      updateStateData(mappingList, liabilitySummary);
      resetLiabilityAllocation(mappingList);
      setShowSaveButton(false);
      return true;
    }

    // User has reduced the allocated interest amount
    if (deltaInterest < 0) {

      // First check if allocated interest was reduced from TDS component
      if (Number(selectedChallan?.reduce_unconsumed_tds_for_interest_amtp ?? 0) > 0) {
        let addBackTdsReduced = Math.min(Number(selectedChallan.reduce_unconsumed_tds_for_interest_amtp), Math.abs(Number(deltaInterest)));
        
        //Decrease challan reduced tds amount with delta interest amount to the extent reducted from tds
        selectedChallan.reduce_unconsumed_tds_for_interest_amtp -= Number(addBackTdsReduced);
        
        //Increase challan unconsumed amount with delta interest amount to the extent reducted from tds
        selectedChallan.total_unconsumed_amtp = Number(selectedChallan?.total_unconsumed_amtp ?? 0) + Number(addBackTdsReduced);
        
        // Update deltaInterest with remaining balance to be adjusted against Unconsumed Interest amount
        deltaInterest += Number(addBackTdsReduced);
      }

      //Update challan unconsumed interest amount with delta interest amount
      selectedChallan.unconsumed_int_amtp = Math.ceil(Number(selectedChallan?.unconsumed_int_amtp ?? 0) + Math.abs(Number(deltaInterest)));

      //update challan data in list
      mappingList[index] = selectedChallan;

      updateStateData(mappingList, liabilitySummary);

      resetLiabilityAllocation(mappingList);

      setShowSaveButton(true);

      return true;

    } else {

      //User has increased the allocated interest amount
      let usableUnconsumedInterestAmount = Math.min(Number(selectedChallan.unconsumed_int_amtp), Number(deltaInterest));

      //update challan unconsumed interet amount with usable unconsumed interest amount
      selectedChallan.unconsumed_int_amtp = Math.ceil(Number(selectedChallan.unconsumed_int_amtp) - Number(usableUnconsumedInterestAmount));

      //update delta interest amount with usable unconsumed interest amount 
      deltaInterest = Math.ceil(Number(deltaInterest) - Number(usableUnconsumedInterestAmount));

      //Scenario 1: No more interest amount left to allocate
      if (deltaInterest <= 0) {

        //update challan data in list
        mappingList[index] = selectedChallan;

        updateStateData(mappingList, liabilitySummary);

        resetLiabilityAllocation(mappingList);

        setShowSaveButton(true);

        return true;

      }

      // Scenario 2: There is no Unconsumed TDS amt left to balance against the allocated interest amt. Show error
      if (Number(selectedChallan?.total_unconsumed_amtp ?? 0) === 0) {

        //set error status
        setError('Amount must be equals to or less than Unconsumed TDS');

        //update challan data in list
        mappingList[index] = selectedChallan;

        updateStateData(mappingList, liabilitySummary);

        resetLiabilityAllocation(mappingList);

        setShowSaveButton(false);

        return true;
      }

      // Scenario 3: There is some Unconsumed TDS amt left to balance against the allocated interest amt, but it is insufficient. Show error
      if (Number(selectedChallan?.total_unconsumed_amtp ?? 0) > 0 && Number(selectedChallan?.total_unconsumed_amtp ?? 0) < deltaInterest) {

        //update challan data and tds updated status
        selectedChallan.reduce_unconsumed_tds_for_interest_amtp = selectedChallan.total_unconsumed_amtp;
        selectedChallan.total_unconsumed_amtp = 0;
        selectedChallan.isTdsAmountUpdated = true;
        setError('Amount must be equals to or less than Unconsumed TDS');

        //update challan data in list
        mappingList[index] = selectedChallan;

        updateStateData(mappingList, liabilitySummary);

        resetLiabilityAllocation(mappingList);

        setShowSaveButton(false);

        return true;

      } else {

        // Scenario 4: There is sufficient Unconsumed TDS amt left to balance against the allocated interest amt.

        //update challan data and tds updated status
        selectedChallan.total_unconsumed_amtp = Math.ceil(Number(selectedChallan.total_unconsumed_amtp) - Number(deltaInterest));
        //selectedChallan.reduce_unconsumed_tds_for_interest_amtp = Number(deltaInterest);
        selectedChallan.reduce_unconsumed_tds_for_interest_amtp += Number(deltaInterest);
        selectedChallan.isTdsAmountUpdated = true;

        //update challan data in list
        mappingList[index] = selectedChallan;

        updateStateData(mappingList, liabilitySummary);

        resetLiabilityAllocation(mappingList);

        setShowSaveButton(true);

        return true;
      }
    }

  }

  const getChallanIndexById = (challan_id) => {
    for (let i = 0; i < interestMappingList.length; i++) {
      if (interestMappingList[i].challan_id === challan_id) {
        return i;
      }
    }
  }

  const handleAllocatedAmountChange = (challan_id, field, value) => {
    const index = getChallanIndexById(challan_id);
    const list = interestMappingList;
    list[index][field] = value;
    //setShowSaveButton(true);
    setInterestMappingList((prev) => {
      prev = list
      return [...prev]
    })
  }

  const getScrollbarHeight = (el) => {
    return el?.getBoundingClientRect()
  };

  const rowRender = (trElement, props) => {
    const data = props.dataItem;
    const key = trElement._owner.index;
    return (
      <tr key={key} role='row' className={`positionkey${key}`}>
        <td class="text-center" style={{ verticalAlign: 'middle' }}>
          <span className={(data?.is_new ?? 0) === 1 ? 'superscript-pd' : ''}>{data?.challan_no ?? '-'}</span>
          {(data?.is_new ?? 0) === 1 && <span className='superscript'> New</span>}
        </td>
        <td class="text-center" style={{ verticalAlign: 'middle' }}>{data?.deposit_date ? getDate(data?.deposit_date) : '-'}</td>
        <td class="text-center" style={{ verticalAlign: 'middle' }}>{data?.display_code ? data?.display_code : '-'}</td>
        <td class="text-right tds_returnflow_challan_hoverbox" style={{ verticalAlign: 'middle' }} onMouseEnter={() => {
          const position = getScrollbarHeight(document.querySelector(`.positionkey${key}`));
          if (position?.top && position?.top > 290 && position?.top < 760) {
            const classlist = document.querySelector(`.dropdown-content.tds_returnflow_challan_dropcont.indexkey${key}`);
            if (classlist) classlist?.classList?.add("positionclass")
          }
        }}>
          <span className="text-right">{formatNumber(data?.total_amtp ?? 0)}</span>
          <div className="dropdown tds_returnflow_challan_hover_link" >
            <span className={`dropdown-content tds_returnflow_challan_dropcont indexkey${key}`}>
              <ul className="tds_returnflow_challan_hoverlist">
                <li>
                  <span className="float-left challan_hoverlist_cellwidthleftbox">TDS</span>
                  <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.tds_amtp ?? 0)}</span>
                </li>
                <li>
                  <span className="float-left challan_hoverlist_cellwidthleftbox">Surcharge</span>
                  <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.surcharge_amtp ?? 0)}</span>
                </li>
                <li>
                  <span className="float-left challan_hoverlist_cellwidthleftbox">Health & Edu. Cess</span>
                  <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.edu_cess_amtp ?? 0)}</span>
                </li>
                <li>
                  <span className=" float-left challan_hoverlist_cellwidthleftbox">Interest</span>
                  <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.interest_amtp ?? 0)}</span>
                </li>
                <li>
                  <span className="float-left challan_hoverlist_cellwidthleftbox">Fees</span>
                  <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.fees_amtp ?? 0)}</span>
                </li>
                <li>
                  <span className="float-left challan_hoverlist_cellwidthleftbox">Penalty/Others</span>
                  <span className="text-right challan_hoverlist_cellwidthrightbox">{formatNumber(props?.dataItem?.other_amtp ?? 0)}</span>
                </li>
              </ul>
            </span>
          </div>
        </td>
        <td class="text-right" style={{ verticalAlign: 'middle' }} >
          {formatNumber(data?.total_unconsumed_amtp ?? 0)}
        </td>
        <td class="text-right" style={{ verticalAlign: 'middle' }}>{formatNumber(data?.unconsumed_int_amtp ?? 0)}</td>
        <td class="text-right" style={{ verticalAlign: 'middle' }}>{formatNumber(data?.unconsumed_fees_or_other_amtp ?? 0)}</td>
        {/* <td class="text-right Interest_fees_rightborder" style={{verticalAlign: 'middle'}}>{formatNumber(data?.unconsumed_other_amtp ?? 0)}</td> */}
        <td class="text-right Interest_allocated_celbgclr_lightyell ">
          <BlurOnChangeInput
            id={data.challan_id}
            type="text"
            className={`form-control-interests-mapping text-right`}
            //className={`form-control-interests-mapping text-right`}
            //title={Number(data?.allocated_int_amtp) > (Number(data?.tds_amtp ?? 0) + Number(data?.unconsumed_int_amtp ?? 0)) ? 'Invalid Amount' : 'Interest Allocated'}
            style={{ height: 'calc(1.42857em + 8px)', fontSize: '11px', fontWeight: '500' }}
            value={FormatNumberDecimal(data?.allocated_int_amtp ?? 0, ...Array(2), false)}
            maxLength={20}
            placeholder="Enter interest allocation amount"
            onChange={(e) => {
              if (String(e.target.value).trim() === '') handleAllocatedAmountChange(data.challan_id, 'allocated_int_amtp', FormatNumberDecValue(0, 20, null, false, false));
              else handleAllocatedAmountChange(data.challan_id, 'allocated_int_amtp', FormatNumberDecValue(e.target.value, 20, null, false, false));
            }}
            //disabled={(data?.unconsumed_int_amtp ?? 0) === 0}
            onBlur={(e) => {
              handleManuallyInterestAllocatedAmountChange(e, data.challan_id);
            }}
          />
        </td>
        {/* <td class="text-right Interest_allocated_celbgclr_liggtgrey ">
          <BlurOnChangeInput
            id={data.challan_id}
            type="text"
            className={`form-control-interests-mapping text-right`}
            style={{ height: 'calc(1.42857em + 8px)', fontSize: '11px', fontWeight: '500' }}
            value={FormatNumberDecimal(data?.allocated_fee_amtp ?? 0, ...Array(2), false)}
            maxLength={20}
            placeholder="Enter interest allocation amount"
            onChange={(e) => {
              if (String(e.target.value).trim() === '') handleAllocatedAmountChange(data.challan_id, 'allocated_fee_amtp', FormatNumberDecValue(0, 20, null, false, false));
              else handleAllocatedAmountChange(data.challan_id, 'allocated_fee_amtp', FormatNumberDecValue(e.target.value, 20, null, false, false));
            }}
            //disabled={(data?.unconsumed_other_amtp ?? 0) === 0}
            onBlur={(e) => {
              handleManuallyFeeAllocatedAmountChange(e, data.challan_id);
            }}
          />
        </td> */}
      </tr>
    )
  }

  const saveAutoAllocationInterestAmount = async () => {
    //call api for auto interest allocation   
    const reduceTotalTDS = [];
    const interestAllocationsToUpdate = [];
    const feeAllocationsToUpdate = [];
    //const otherAllocationsToUpdate = [];
    interestMappingList.forEach((item) => {
      if ((item?.isTdsAmountUpdated ?? false) === true) {
        reduceTotalTDS.push({
          challan_id: item.challan_id,
          interest_amtp: item.reduce_unconsumed_tds_for_interest_amtp,
          //fees_amtp: item.reduce_unconsumed_tds_for_fee_amtp,
        });
      }
      if ((item?.isInterestAllocationChanged ?? false) === true) {
        interestAllocationsToUpdate.push({
          challan_id: item.challan_id,
          interest_amtp: item.allocated_int_amtp,
        });
      }
      if ((item?.isFeeAllocationChanged ?? false) === true) {
        feeAllocationsToUpdate.push({
          challan_id: item.challan_id,
          fees_amtp: item.allocated_fee_amtp,
        });
      }
    })

    try {
      setShowSaveButton(false);
      const payload = {
        deductor_id: deductorId,
        return_id: returnId,
        reduce_total_tds: reduceTotalTDS,
        interest_allocations_to_update: interestAllocationsToUpdate,
        //fee_allocations_to_update: feeAllocationsToUpdate,
        //other_allocations_to_update: otherAllocationsToUpdate
      }
      const result = await saveInterestAllocationData(payload);
      setSuccess(result.message);
      reload(3000);
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      //
    }

  }

  const reload = (time) => {
    setTimeout(() => {
      window.location.reload();
    }, time);
  }


  useEffect(() => {
    getInterestMappingData();
    if(headerData?.form_type){
      setFormType(headerData?.form_type);
    }
    // eslint-disable-next-line
  }, [headerData]);

  useEffect(() => {
    setShowAutoAllocateButton(false);
    setShowPayButton(false);

    const summaryData = {
      total_challan_count: interestMappingList ? interestMappingList.length : 0,
      total_amtp: getColumnTotalFromArrayOfObject(interestMappingList, 'total_amtp'),
      total_unconsumed_amtp: getColumnTotalFromArrayOfObject(interestMappingList, 'total_unconsumed_amtp'),
      unconsumed_int_amtp: getColumnTotalFromArrayOfObject(interestMappingList, 'unconsumed_int_amtp'),
      unconsumed_fees_or_other_amtp: getColumnTotalFromArrayOfObject(interestMappingList, 'unconsumed_fees_or_other_amtp'),
      allocated_int_amtp: getColumnTotalFromArrayOfObject(interestMappingList, 'allocated_int_amtp'),
    }

    setSummary((prev) => {
      prev = summaryData
      return { ...prev }
    })

    const interet_liability_remaining_amt = Number(lateLiabilitySummary?.remaining_int_amtp ?? 0);

    if ((interet_liability_remaining_amt > 0) && autoAllocationUsed) {

      const grandTotalUnconsumedIntAmtp = getColumnTotalFromArrayOfObject(interestMappingList, 'unconsumed_int_amtp');
      const grandTotalTotalUnconsumedAmtp = getColumnTotalFromArrayOfObject(interestMappingList, 'total_unconsumed_amtp');
      //Unconsumed TDS or Unconsumed Interest column amount must be > 0
      if (Number(grandTotalTotalUnconsumedAmtp) > 0 || Number(grandTotalUnconsumedIntAmtp) > 0) {
        setShowAutoAllocateButton(true);
      }
    }


    if ((lateLiabilitySummary?.remaining_int_amtp ?? 0) > 0) {
      setShowPayButton(true);
    }

    // if (interestMappingList.length > 0) {
    //   //auto trigger onchange event for some fee allocation
    //   triggerFeeAllocationChange(interestMappingList);
    // }

    // eslint-disable-next-line
  }, [interestMappingList, lateLiabilitySummary])

  const closeChallanPopup = (reload, msg = '') => {
    setShowChallanForm(false);
    if (msg) { setSuccess(msg); }
    if (reload) {
      window.location.reload();
    }
  }

  const getFormWiseLabel = (formType) => {
    switch(formType){
      case '27EQ': return 'TCS';
      case '24Q': 
      case '26Q': 
      case '27Q':       
      default: return 'TDS';
    }
  }


  return (
    <>
      {/* {loading ? <LoadingIndicator /> : null} */}

      <ShowAlert error={error} success={success} onClose={() => { setSuccess(null); setError(null); }} />

      <ReturnFlowTimeLine returnId={params.return_id} deductorId={params.deductor_id} currentPage={`InterestMappings`} />

      {showChallanForm
        ? (
          <AddChallan
            onClose={() => closeChallanPopup(true)}
            deductorId={deductorId}
            returnHealthSummary={
              {
                challanDeposited: false,
                tds_amtp: 0,
                interest_amtp: Number(Math.round(lateLiabilitySummary?.remaining_int_amtp ?? 0)),
                fees_amtp: 0, //(lateLiabilitySummary?.remaining_fee_amtp ?? 0) > 0 ? Number(Math.round(lateLiabilitySummary?.remaining_fee_amtp ?? 0)) : 0,
              }
            }
          />
        )
        : null
      }

      {
        showLibilityPopup &&
        <InterestMappingLibilityDataPopup
          returnId={returnId}
          deductorId={deductorId}
          liabilityData={
            {
              total_int_amtp: lateLiabilitySummary?.total_int_amtp ?? 0,
              late_deduction_interest_amtp: lateLiabilitySummary?.late_deduction_interest_amtp ?? 0,
              late_payment_interest_amtp: lateLiabilitySummary?.late_payment_interest_amtp ?? 0,
            }
          }
          setError={setError}
          onClose={() => {
            setShowLibilityPopup(false);
          }}
        />
      }

      {
        showLateFeePopup &&
        <LateFeeLibilityDataPopup
          returnId={returnId}
          deductorId={deductorId}
          liabilityData={lateLiabilitySummary}
          setError={setError}
          onClose={() => {
            setShowLateFeePopup(false);
          }}
        />
      }

      {
        saveAllocationPopup &&
        <SaveAllocationDataPopup
          returnId={returnId}
          deductorId={deductorId}
          data={interestMappingList}
          lateLiabilitySummary={lateLiabilitySummary}
          setError={setError}
          onClose={() => { setSaveAllocationPopup(false); }}
          handleProceed={() => {
            saveAutoAllocationInterestAmount();
            setSaveAllocationPopup(false);
          }}
          relookButton={() => {
            setSaveAllocationPopup(false);
            reload(500)
          }}
        />
      }

      <div className="container-fluid pt-1">
        <div className="Interest_allocation_maindiv">

          <div className='row mt-2'>
            <div className='col-xl-9 col-lg-8 col-md-6 col-sm-12 text-center'>
              <div className='intressallocationboxleft'>
                <p className='unconsumed_textclr_box'>
                  <span class="unconsumed_textclr_blue">List of challans that can be utilized for Interest allocation,  after {getFormWiseLabel(formType)} Mapping.</span>
                  <br />
                  <span className='unconsumed_textclr_fs'>
                    For the values entered in the last column, the application will offset from {getFormWiseLabel(formType)}s component if interest is not sufficient enough to meet the same.
                  </span>
                  <br />
                  <span className='pl-3 autoall_btnbox_paddtop d-flex justify-content-center'>
                    {
                      showAutoAllocateButton
                        ? <button class="btn btn-defualt Interest_fees_amt_autoall mr-2 mb-1" onClick={() => {
                          autoAllocateInterestAmount();
                          //autoAllocateFeeAmount();
                          setAutoAllocationUsed(false)
                        }}>Auto Allocate</button>
                        : <button class="btn btn-defualt Interest_fees_amt_autoall mr-2 mb-1 disabled">Auto Allocate</button>
                    }
                    {
                      showSaveButton
                        ? <button class="btn btn-defualt Interest_fees_amt_savebtn mr-2 mb-1" onClick={() => setSaveAllocationPopup(true)}>Save</button>
                        : <button class="btn btn-defualt Interest_fees_amt_savebtn mr-2 mb-1 disabled">Save</button>
                    }
                    {
                      showPayButton
                        ? <button class="btn btn-defualt Interest_fees_amt_paybtn mb-1" onClick={() => setShowChallanForm(true)}>Pay</button>
                        : <button class="btn btn-defualt Interest_fees_amt_paybtn mb-1 disabled">Pay</button>
                    }
                  </span>
                </p>
              </div>

            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 pl-1'>
              <div className='interstallo_striknotebox'>
                <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                  <table className='table table-borderless interstall_innertable_width mb-0'>
                    <tbody>
                      <tr>
                        <td className='' width="120">Particulars</td>
                        <td className=' text-center' width="120" >Interest</td>
                        {/* <td className=' text-center' width="120">Fee</td> */}
                      </tr>
                      <tr>

                        <td width={120}>Total Liability</td>
                        <td width={120} class="text-right  textclr_bluelink pr-5" onClick={() => setShowLibilityPopup(true)}>{formatNumber(Number(lateLiabilitySummary?.total_int_amtp ?? 0))}</td>
                        {/* <td width={120} class="text-right  textclr_bluelink" onClick={() => setShowLateFeePopup(true)} >{formatNumber(Number(lateLiabilitySummary?.total_fee_amtp ?? 0))}</td> */}
                      </tr>
                      <tr>
                        <td width={120}>Liability Adjusted</td>
                        <td width={120} class="text-right pr-5">{formatNumber(Number(lateLiabilitySummary?.allocated_int_amtp ?? 0))}</td>
                        {/* <td width={120} class="text-right ">{formatNumber(Number(lateLiabilitySummary?.allocated_fee_amtp ?? 0))}</td> */}
                      </tr>
                      <tr>
                        <td width={120} class="Interest_fees_textclr_red ">Remaining for adjustment</td>
                        <td width={120} class="text-right   Interest_fees_textclr_red pr-5">{Number(lateLiabilitySummary?.remaining_int_amtp ?? 0) > 0 ? formatNumber(Number(lateLiabilitySummary.remaining_int_amtp)) : '0.00'}</td>
                        {/* <td width={120} class="text-right   Interest_fees_textclr_red ">{Number(lateLiabilitySummary?.remaining_fee_amtp ?? 0) > 0 ? formatNumber(Number(lateLiabilitySummary?.remaining_fee_amtp ?? 0)) : '0.00'}</td> */}
                      </tr>
                      <div class="interstall_innertable_striknote_pin">
                        <div class="interstall_innertable_striknote_shadow"></div>
                        <div class="interstall_innertable_striknote_metal"></div>
                        <div class="interstall_innertable_striknote_bottom_circle"></div>
                      </div>
                    </tbody>

                  </table>
                </div>
              </div>

            </div>
          </div>

          <div className="row mt-2">
            <div class="col-lg-12">
              <div class="card" style={{ padding: '0' }}>

                <div class="row">

                  <div class="col-md-12">


                    <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                      {
                        showSkelton ? <Skeleton12ColumnGrid />
                          : <>
                            <Tooltip anchorElement="pointer" position="right">
                              <Grid
                                className={`table table-striped interestsmappings mb-0`}
                                {...dataState}
                                onDataStateChange={(event) => dataStateChange(event.dataState)}
                                data={filterBy(interestMappingList, filter)}
                                filter={filter}
                                filterOperators={{
                                  text: [
                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                  ],
                                  numeric: [
                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                  ],
                                  date: [
                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                  ],
                                }}
                                rowRender={rowRender}
                                sortable={false}
                                //style={{ minHeight: '300px', maxHeight: '500px', overflowX: 'auto' }}
                                style={{ maxHeight: '500px', minHeight: '320px', overflowX: 'auto' }}
                              >
                                <Column
                                  width="150"
                                  field="challan_no"
                                  title={<AddDeducteeTransactionsIcon 
                                    title={'Challan No.'}
                                    subtextData={{ subText: summary?.total_challan_count ?? 0}}
                                    showButton={false}
                                    headClass={true}
                                  />}
                                  filter="text"
                                  columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                  headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                />
                                <Column
                                  width="150"
                                  field="deposit_date_filter"
                                  title="Deposit Dt"
                                  filter="date"
                                  columnMenu={(props) => <ColumnMenu {...props} />}
                                  headerClassName={isColumnActive('deposit_date_filter') ? 'active' : ''}
                                />
                                <Column
                                  width="120"
                                  field="display_code"
                                  title="Section"
                                  filter="text"
                                  columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                  headerClassName={isColumnActive('display_code') ? 'active' : ''}
                                />
                                <Column
                                  width="120"
                                  field="total_amtp"
                                  title={<AddDeducteeTransactionsIcon 
                                    title={'Deposited Total'}
                                    subtextData={{ subText: summary?.total_amtp ?? 0}}
                                    showButton={false}
                                  />}
                                  filter="numeric"
                                  columnMenu={(props) => <ColumnMenu  {...props} />}
                                  headerClassName={isColumnActive('total_amtp') ? 'active' : ''}
                                />
                                <Column
                                  width="170"
                                  field="total_unconsumed_amtp"
                                  title={<AddDeducteeTransactionsIcon 
                                    title={'Rem. Bal. TDS Amount'}
                                    subtextData={{ subText: summary?.total_unconsumed_amtp ?? 0}}
                                    showButton={false}
                                  />}
                                  filter="numeric"
                                  columnMenu={(props) => <ColumnMenu  {...props} />}
                                  headerClassName={isColumnActive('total_unconsumed_amtp') ? 'active' : ''}
                                />
                                <Column
                                  width="170"
                                  field="unconsumed_int_amtp"
                                  title={<AddDeducteeTransactionsIcon 
                                    title={'Rem. Bal. Interest'}
                                    subtextData={{ subText: summary?.unconsumed_int_amtp ?? 0}}
                                    showButton={false}
                                  />}
                                  filter="numeric"
                                  columnMenu={(props) => <ColumnMenu  {...props} />}
                                  headerClassName={isColumnActive('unconsumed_int_amtp') ? 'active' : ''}
                                />
                                <Column
                                  width="170"
                                  field="unconsumed_fees_or_other_amtp"
                                  title={<AddDeducteeTransactionsIcon 
                                    title={'Rem. Bal. Fees/Others'}
                                    subtextData={{ subText: summary?.unconsumed_fees_or_other_amtp ?? 0}}
                                    showButton={false}
                                  />}
                                  filter="numeric"
                                  columnMenu={(props) => <ColumnMenu  {...props} />}
                                  headerClassName={isColumnActive('unconsumed_fees_or_other_amtp') ? 'active' : ''}
                                />
                                <Column
                                  width="160"
                                  field="allocated_int_amtp"
                                  title={<AddDeducteeTransactionsIcon 
                                    title={'Allocate Interest'}
                                    subtextData={{ subText: summary?.allocated_int_amtp ?? 0}}
                                    showButton={false}
                                  />}
                                  filter="numeric"
                                />
                                {/* <Column
                                    width="160"
                                    field="allocated_fee_amtp"
                                    title="Fee"
                                    filter="numeric"
                                  /> */}
                              </Grid>
                            </Tooltip>
                            <Pagination cursor={cursor} />
                          </>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReturnFilingInterestMapping