import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import './UtilizationSummary.scss'
import { downloadRegularReturnErrorFile, getImportReturnStatus, getOverValuesChallan } from '../../../api/returnAPI';
import { gocallApi } from '../../../api/issueCertificateAPI';
import { createImportRegularReturnLocalStorageKey } from '../../../utils/UtilityFunctions';
import { HEADER_FILE_NAME } from '../../../app/constants';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../../components/ShowAlert';
import ConfirmPopup from '../../../components/ConfirmPopup';


const UtilizationSummary = (props) => {
    const { deductorId, returnId, setShowFromUtlization, headerData, setShowResult, setLoading1, setDisabledTimeline ,disableUnmap} = props;
    const [overValueChallan, setOverValueChallan] = useState([])
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [overMappedtotal, setOverMappedtotal] = useState(0)
    const [hasError, setHasError] = useState(false)
    const getOverValueChallan = async (deductor_id, returnId) => {
        try {
            const result = await getOverValuesChallan(deductor_id, returnId);
            setOverValueChallan(result?.data ?? [])
            if (result?.data?.length > 0) {
                setDisabledTimeline(true)
                let overMapped = 0
                result?.data.forEach(item => {
                    overMapped = overMapped + (item?.total_overutilized_amtp ?? 0)
                });
                setOverMappedtotal(overMapped)
            } else {
                setDisabledTimeline(false)
            }

        } catch (err) {
        } finally {
            setLoading1(false)
        }

    }
    const upmappAllChallan = async (overValueChallan) => {
        setLoading(true)
        if (overValueChallan && overValueChallan?.length > 0) {
            for (let i = 0; i < overValueChallan?.length; i++) {
                try {
                    await gocallApi('post', '/api/v1/return/unmap-all-challan-transactions', {
                        deductor_id: deductorId,
                        return_id: returnId, challan_id: overValueChallan[i]?.challan_id
                    })
                } catch (err) {
                    setError("Something Went Wrong")
                    return
                }
            }
            getOverValueChallan(deductorId, returnId)
            setSuccess("Unmapped Successfully")
            setTimeout(() => window.location.reload(), 2000)
        }
        setLoading(false);

    };
    const downloadErrorFile = async () => {
        setLoading(true);
        try {
            let params = `?deductor_id=${deductorId}&return_id=${returnId}`;
            const result = await downloadRegularReturnErrorFile(params);
            const csiData = new Blob([result?.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            let formType = headerData.form_type;
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS_${formType}_Error_File.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    const callImportStatus = async (deductor_id, returnId) => {
        try {
            const result = await getImportReturnStatus(deductor_id, returnId);
            if (result?.data?.has_errored_file) {
                setHasError(true)
            }
        } catch (err) {
        }
    }


    useEffect(() => {
        if (headerData?.form_type !== "") {
            getOverValueChallan(deductorId, returnId)
            const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
            if (checkRunningImport) {
                setHasError(JSON.parse(checkRunningImport)?.has_errored_file ?? false)
            } else {
                callImportStatus(deductorId, returnId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deductorId, returnId, headerData])

    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null) }}
            />
            {(overValueChallan && overValueChallan?.length) ?

                <>

                    <ConfirmPopup
                        show={confirmPopup}
                        handleProceed={() => {
                            upmappAllChallan(overValueChallan)
                        }}
                        confirmText="This action will un-map all transactions from Over-Mapped challans. 
                        If required, you can remap them later. 
                        In case there are any error records, you can download them from Deductee Transactions page to review."
                        confirmButtonText="Proceed"
                        handleClose={() => {
                            setConfirmPopup(false)
                        }}
                    />

                    <Modal
                        show={true}
                        backdrop="static"
                        keyboard={false}
                        className="modal Deductee_trans_utilization_summ_modalbox show"
                    >
                        <Modal.Header className="modal-header Deductee_trans_utilization_summ_header">
                            <h4 class="modal-title">
                                Utilization Summary
                            </h4>
                        </Modal.Header>
                        <Modal.Body className="pt-2 pb-2 px-3">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="Deductee_trans_utilization_summ_tbscrolling table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                        <table class="table table-borderless Deductee_trans_utilization_summ_table mb-0">
                                            <thead>
                                                <tr>
                                                    <th width="100">
                                                        Challan No <br></br><span style={{color:"#00f",fontSize:"9px"}}>{overValueChallan?.length ?? 0}</span>
                                                    </th>
                                                    <th width="150" style={{verticalAlign:"baseline"}}>
                                                        Challan Total
                                                    </th>
                                                    <th width="150"  style={{verticalAlign:"baseline"}}>
                                                        Mapped amount
                                                    </th>
                                                    <th width="100" class="overmapped_textclr">
                                                        Over Mapped  <br></br><span style={{color:"#00f",fontSize:"9px"}}>{overMappedtotal?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</span> 
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {overValueChallan.map((item) => (
                                                    <tr className="k-master-row">
                                                        <td class="text-center">{item?.challan_no}</td>
                                                        <td class="text-right">{(item?.total_challan_amtp)?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                                                        <td class="text-right">{(item?.total_tds_amtp)?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                                                        <td class="text-right overmapped_textclr">({(item?.total_overutilized_amtp)?.toLocaleString('en-IN', { minimumFractionDigits: 2 })})</td>
                                                    </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2" style={{ justifyContent: "center" }}>
                                <div class="col-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 text-right">
                                    <button class={`btn btn-default Deductee_trans_unmapbtn ${disableUnmap ? "disabled" :""}`} onClick={() => { setConfirmPopup(true) }}>
                                        Unmap All
                                    </button>

                                </div>
                                {hasError ? <div class="col-5 col-xl-4 col-lg-4 col-md-4 col-sm-4 text-center">
                                    <button class="btn btn-default Deductee_trans_unmapbtn" onClick={() => { downloadErrorFile() }}>
                                        Download Error Report
                                    </button>
                                </div> : null}
                                <div class="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">

                                    <button class="btn btn-default Deductee_trans_unmapbtn" onClick={() => {
                                        if (setShowFromUtlization) {
                                            setShowFromUtlization(true);
                                            const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
                                            if (checkRunningImport && (JSON.parse(checkRunningImport)?.import_status === 'SUCCESS' || JSON.parse(checkRunningImport)?.import_status === 'FAILURE')) {
                                                localStorage.removeItem(createImportRegularReturnLocalStorageKey(headerData));
                                            }

                                            setShowResult(false)
                                        }
                                    }} >
                                        Re-Import
                                    </button>
                                </div>

                                <div class="col-md-12 pt-2">
                                    {/* <p class="Deductee_trans_utilization_summ_textfsclr">
                                        Download Error Report will also have overutilization details in the challan sheet, You can rectify and import again
                                    </p> */}
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal> </> : null}
        </>

    )
}

export default UtilizationSummary