import React from "react";
import LateDeductionTable1 from "./LateDeductionTable1";
import LateDeductionTable2 from "./LateDeductionTable2";


const IntOnLateDeduction = (props) => {

    const { apiData, formType } = props;

    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab23" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">

                    <LateDeductionTable1
                        apiData={apiData?.summary}
                    />


                    <LateDeductionTable2
                        apiData={apiData?.list}
                        formType={formType}
                    />


                </div>
            </div>
        </div>
    )

};

export default IntOnLateDeduction;