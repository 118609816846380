/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React from 'react';
import './Pagination.css';

function SpanButton({ text, onClick }) {
  return (
    <span
      className="page-link"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      tabIndex="-1"
    >
      {text}
    </span>
  );
}

export default function PostPagination(props) {
  const { cursor, fetchData } = props;

  if (cursor.current_page === 1 && !cursor.next_page_url) {
    return null;
  }

  return (
    <div className="col-sm-12 mt-3">
      <div className="align-center mb-2">
        <nav aria-label="...">
          <ul className="pagination justify-content-center">
            {cursor.current_page === 1 ? (
              <li className="page-item disabled">
                <SpanButton
                  text="First"
                  onClick={() => fetchData(1)}
                />
              </li>
            ) : (
              <li className="page-item">
                <SpanButton
                  text="First"
                  onClick={() => fetchData(1)}
                />
              </li>
            )}

            {cursor.current_page > 1
              ? (
                <li className="page-item">
                  <SpanButton
                    text={cursor.current_page - 1}
                    onClick={() => fetchData(cursor.current_page - 1)}
                  />
                </li>
              )
              : null}

            <li className="page-item active">
              <SpanButton
                text={cursor.current_page}
                onClick={() => fetchData(cursor.current_page)}
              />
            </li>

            {cursor.next_page_url ? (
              <li className="page-item">
                <SpanButton
                  text={cursor.current_page + 1}
                  onClick={() => fetchData(cursor.current_page + 1)}
                />
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </div>
  );
}
