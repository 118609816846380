import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import {
  getDeductorInsightsCredentialsForConnector,
  getPanToVerifyAtInsights
} from '../../api/deductorAPI';
import {
  verifyInsightsLoginAndReturnCaptcha,
  verifyInsightsLoginForSinglePan,
  refreshInsightsCaptcha
} from '../../api/localConnectorAPI';
import './KDKConnector.css';
import { selectHeaderData } from '../../features/header/headerSlice';
import UpdateInsightsCredential from '../verifyPAN/UpdateInsightsCredential';
import { HEADER_CONNECTOR_DIGEST_KEY, CONNECTOR_ERRORS_MAPPING } from '../../app/constants';

import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';

export default function InsightsConnectorWithCaptcha({ history, ...props }) {

  const {
    deductorId,
    returnId,
    port,
    pan,
    handleProceed,
    handleClose,
    setInsightsCaptchaRef,
    creds = null,
    setParentLoading,
    setStartInsightProcess,
    setParentError,
    relaodAfterError
  } = props;


  const headerDataDetails = useSelector(selectHeaderData);
  const [error, setError


  ] = useState(false);
  const [showInsightsValidationFailPopup, setShowInsightsValidationFailPopup] = useState(false);
  const [insightLogin, setInsightLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaImg, setCaptchaImg] = useState(null);
  const [captcha, setCaptcha] = useState('');
  const [cookies, setCookies] = useState(null);

  const insightTan = '';
  const insightsUser = '';
  const insightsPassword = '';

  const fetchTracesCredentials = (deductorId) => {
    async function fetchEverything() {
      try {
        // Dont update Header, as it is already updated by rest of the API. This component is supposed 
        // to work for both Regular and Correction return, and hence header update will mess display for Correction.
        const serverApiResult = await getDeductorInsightsCredentialsForConnector(`?deductor_id=${deductorId}`, false);
        setLoading(true);
        setParentLoading(true);

        if (parseInt(serverApiResult?.status ?? 0) === 200) {
          const EncryptLoginData = serverApiResult?.data
          const EncryptLoginDataHeader = serverApiResult?.headers ?? null;
          const connectorRequest = {
            "data": EncryptLoginData?.data
          };
          let extraHeader = {};
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = EncryptLoginDataHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          try {

            const connectorResult = await verifyInsightsLoginAndReturnCaptcha(port, connectorRequest, extraHeader);
            if (setInsightsCaptchaRef) {
              setInsightsCaptchaRef(connectorResult?.data?.captcha_ref);
            }
            setCaptchaImg(connectorResult?.data?.captcha_image);
            setCookies(connectorResult?.data?.captcha_ref);
            setShowCaptcha(true);

            //handleProceed(connectorResult?.data?.captcha_ref);

          } catch (err) {
            const errorList = err.message;
            if(setStartInsightProcess) setStartInsightProcess(false)
            if (errorList.includes('insights.invalid_credentials')) {
              setShowInsightsValidationFailPopup(true);
            } else {
              setError(CONNECTOR_ERRORS_MAPPING[err?.message?.[0]] ?? CONNECTOR_ERRORS_MAPPING['default']);
              if(setParentError) setParentError(CONNECTOR_ERRORS_MAPPING[err?.message?.[0]] ?? CONNECTOR_ERRORS_MAPPING['default']);
            }

          }

        }

      } catch (err) {
        console.error('error: ', err);
        if (err.code === 404) {
          setShowInsightsValidationFailPopup(true);
        } else {
          setError(err.message);
        }
      } finally {
        //handleClose();
        setLoading(false);
        setParentLoading(false);
        setInsightLogin(false);
      }
    }

    fetchEverything();
  };



  useEffect(() => {

    if (insightLogin) {
      fetchTracesCredentials(deductorId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductorId, port, creds, headerDataDetails, pan, insightLogin]);


  const handleCloseAction = () => {
    setShowCaptcha(false)
    handleClose();
  }

  const verifyCatpcha = () => {
    async function fetchEverything() {
      try {
        const request = {
          'captcha_ref': cookies,
          'captcha_text': captcha,
          'deductor_id': deductorId,
          'pan': pan,
        };
        const serverApiResult = await getPanToVerifyAtInsights(request);
        setLoading(true);
        setParentLoading(true);

        const EncryptLoginData = serverApiResult?.data
        const EncryptLoginDataHeader = serverApiResult?.headers ?? null;
        const connectorRequest = {
          "data": EncryptLoginData?.data
        };
        let extraHeader = {};
        extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = EncryptLoginDataHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

        try {

          const connectorResult = await verifyInsightsLoginForSinglePan(port, connectorRequest, extraHeader);
          handleProceed(connectorResult?.data);
          handleCloseAction();

        } catch (err) {
          const errorList = err.message;
          if (setParentError) {
            setParentError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['insights.unknown_error']);
          } else {
            setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['insights.unknown_error']);
          }

          if (errorList.includes('insights.captcha_failure')) {
            //handle invalid captcha string, refresh captcha
            refreshCaptcha();
          }
          if (relaodAfterError && !errorList.includes('insights.captcha_failure')) {
            setTimeout(() => relaodAfterError(true), 3000)
          }
        }

      } catch (err) {
        if(err.message){
          setError(err.message?.[0] ?? err.message)
        }else{
          setError(err);
        }

      } finally {

        setLoading(false);
        setParentLoading(false);
        setInsightLogin(false);
      }
    }

    fetchEverything();
  }

  const refreshCaptcha = () => {

    async function fetchEverything() {
      try {
        setLoading(true);
        const connectorResult = await refreshInsightsCaptcha(port, `?captcha_ref=${cookies}`);
        if (setInsightsCaptchaRef) {
          setInsightsCaptchaRef(connectorResult?.data?.captcha_ref);
        }
        setCaptchaImg(connectorResult?.data?.captcha_image);
        setCookies(connectorResult?.data?.captcha_ref);
        setShowCaptcha(true);
      } catch (err) {
        setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
      } finally {
        setLoading(false);
      }
    }

    fetchEverything();
  }

  return (
    <>

      {showInsightsValidationFailPopup &&
        <UpdateInsightsCredential
          show={showInsightsValidationFailPopup}
          handleClose={() => {
            setError(null);
            setShowInsightsValidationFailPopup(false);
            setInsightLogin(true);
            handleCloseAction();
          }}
          deductorId={deductorId}
          returnId={returnId}
          tan={insightTan}
          insightsUser={insightsUser}
          insightsPassword={insightsPassword}
          handleProceed={(res) => {
            handleProceed()
            if (setInsightsCaptchaRef) {
              setInsightsCaptchaRef(res);
            }
            if (insightLogin) { //reset login captcha status if already set,  to call useEffect action
              setInsightLogin(false);
            }
            setInsightLogin(true);
          }}
          port={port}
          setInsightsCaptchaRef={(res) => {

            if (setInsightsCaptchaRef) {
              setInsightsCaptchaRef(res);
            }
            setInsightLogin(true);
          }}
          setStartInsightProcess={setStartInsightProcess}
        />
      }


      {showCaptcha &&

        <Modal
          show={showCaptcha}
          onHide={handleCloseAction}
          backdrop="static"
          keyboard={false}
          className="insightsconnwidthcaptcha_modal"
        >


          <Modal.Header closeButton className="insightsconnwidthcaptcha_header">
            <Modal.Title className="insightsconnwidthcaptcha_title">Login At Insights</Modal.Title>
          </Modal.Header>

          <ShowAlert error={error} onClose={() => setError(null)} />

          {loading ? <LoadingIndicator /> : null}

          <Modal.Body className="insightsconnwidthcaptcha_body">
            <div className="row mt-3">
              <div className="form-group col-md-12">
                <div className="">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputEmail4">
                        Captcha
                      </label>
                      <div className="captcha_img"><img src={`data:image/png;base64, ${captchaImg}`} alt="" /></div>
                    </div>
                    <div className="form-group col-md-6" style={{ marginTop: '5px' }}>
                      <label htmlFor="captcha">
                        Enter Captcha
                        <span className="red_text">*</span>
                        <input
                          type="text"
                          className="form-control"
                          id="captcha"
                          placeholder="Enter Captcha"
                          value={captcha}
                          onChange={(event) => setCaptcha(event.target.value)}
                          style={{ marginTop: '12px' }}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <p>
                      <small>
                        Can't read the captcha?
                        <Button variant="link" className='logininghts_clickhere_textfs' onClick={() => refreshCaptcha(cookies)} style={{ margin: '-9px', fontSize: 12 }}>Click here</Button>
                        to refresh
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="panvermpopufoot">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              verifyCatpcha();
              if (relaodAfterError) {
                setShowCaptcha(false)
              }
            }} disabled={loading}>
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>


      }


    </>

  )
}
