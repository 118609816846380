import React from 'react';
import { useState } from 'react';
import CreateManuallyPopup from './CreateManuallyPopup';
import { addReturn, checkConso } from '../../../api/returnAPI';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import RaisedFileImportPopup from './RaisedFileImportPopup';
import CorrectionConfirmationPopup from './CorrectionConfirmationPopup';
import { Alert } from 'react-bootstrap';
import { deepClone } from '../../../utils/UtilityFunctions';
import moment from 'moment';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';


const FillingListPopup = (props) => {

    const { handleClose, financialYear, data, deductorId, allReturnData, successProced, updateFilingData, indiviualQuarterSync, startFilingPopup } = props;
    
    const width = document.body.clientWidth;

    const [createReturnPopup, setCreateReturnPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [importFilePopup, setImportFilePopup] = useState(false);
    const [consoFileAlready, setConsoFileAlready] = useState(false);
    const [newReturnId, setNewReturnId] = useState("");
    const [returnPayload, setReturnPayload] = useState({});

    const clickCorrectionWorkOnThis = async (returnItem) => {
        setLoading(true);
        let returnID = returnItem.return_id;

        try {
            const request = `?deductor_id=${deductorId}&return_id=${returnID}`;
            const results = await checkConso(request);
            openConsoFileFoundInSystem(results);

        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(''), 1500);
        } finally {
            setLoading(false);
        }
    };

    const openConsoFileFoundInSystem = (results = {}) => {
        if (['CONSO_IMPORTED', 'REQUEST_SUBMITTED', 'IMPORT_IN_PROGRESS'].includes(results?.data?.conso_file_status) && results?.data?.file_found) {
            setConsoFileAlready(true);
        } else {
            setImportFilePopup(true);
        }

    };

    const quartReturnType = (dataItem, formType, qtr) => {
        const returntype = dataItem?.[qtr]?.return_type.toLowerCase();

        return (
            dataItem && dataItem?.[qtr]?.return_type && dataItem?.[qtr]?.return_type === 'CORRECTION' ? (
                <span
                    className="link"
                    onClick={() => {
                        const Payload = {
                            deductor_id: deductorId,
                            form_type: formType ?? "",
                            quarter: qtr ?? "",
                            return_type: "CORRECTION",
                            year: financialYear,
                            efiling_status: dataItem?.efiling_status ?? "",
                            ack_no: dataItem?.ack_no ?? "",
                            filing_date: dataItem.filing_date ?? ""
                        }
                        setReturnPayload(Payload);
                        setNewReturnId(dataItem?.[qtr]?.return_id ?? "");

                        if (dataItem?.[qtr]?.return_id) {
                            clickCorrectionWorkOnThis(dataItem?.[qtr]);
                        } else if (!dataItem?.[qtr]?.return_id) {
                            CreateReturnOnServer(Payload, true)
                        }
                    }}
                    style={{ textTransform: "capitalize " }}
                >
                    {returntype}
                </span>
            )
                : dataItem && dataItem?.[qtr]?.return_type && dataItem?.[qtr]?.return_type === 'REGULAR' && dataItem?.[qtr]?.return_id ? (
                    <span style={{color: "#31719b", textTransform: "capitalize", cursor: "pointer"}}
                     onClick={() => {
                        handleClose();
                        indiviualQuarterSync(dataItem?.[qtr])
                    }}
                    >{returntype}</span>
                ) : <span
                    className="link"
                    onClick={() => {
                        const returnPayload = {
                            deductor_id: deductorId,
                            form_type: formType ?? "",
                            quarter: qtr ?? "",
                            return_type: "REGULAR",
                            year: financialYear
                        }
                        CreateReturnOnServer(returnPayload);
                    }}
                >Regular</span>
        )

    }

    const CreateReturnOnServer = async (returnPayload, correctionType) => {
        setLoading(true);
        const payload = {
            deductor_id: returnPayload.deductor_id,
            form_type: returnPayload?.form_type ?? "",
            quarter: returnPayload?.quarter ?? "",
            return_type: returnPayload?.return_type ?? "",
            year: returnPayload?.year ?? ""

        }

        try {
            const result = await addReturn(payload);

            if (!correctionType && result.data.return_id) {
                handleClose();
                indiviualQuarterSync({return_id: result.data.return_id});
                successProced()
                
            } else if (correctionType) {
                setImportFilePopup(true);
                setNewReturnId(result?.data?.return_id ?? "");
                updateFilingData();
            }
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);

        } finally {
            setLoading(false);
        }
    }



    return (
        <>

            {loading && <LoadingIndicator />}

            {importFilePopup &&
                <RaisedFileImportPopup
                    show={importFilePopup}
                    handleClose={() => setImportFilePopup(false)}
                    deductorId={deductorId}
                    returnId={newReturnId}
                    returnPayload={returnPayload}
                    financialYear={financialYear}

                />}

            {consoFileAlready &&
                <CorrectionConfirmationPopup
                    show={consoFileAlready}
                    handleClose={() => setConsoFileAlready(false)}
                    createNewReturn={() => {
                        setImportFilePopup(true);
                    }}
                    allReturnData={allReturnData}
                    returnPayload={returnPayload}
                    successProced={() => {
                        handleClose();
                        successProced()
                    }}
                    deductorId={deductorId}
                    returnId={newReturnId}
                    financialYear={financialYear}
                />
            }

            {createReturnPopup &&
                <CreateManuallyPopup
                    show={createReturnPopup}
                    handleClose={() => setCreateReturnPopup(false)}
                    financialYear={financialYear}
                    deductorId={deductorId}
                    setNewReturnId={setNewReturnId}
                    setImportFilePopup={setImportFilePopup}
                    updateFilingData={updateFilingData}
                    alreadyCreatedReturn={(returnType) => {

                        const dataItem = deepClone(data?.[returnType.form_type]?.[returnType.quarter] ?? {});

                        const Payload = {
                            deductor_id: deductorId, form_type: returnType.form_type ?? "", quarter: returnType.quarter ?? "",
                            return_type: "CORRECTION", year: financialYear, efiling_status: dataItem?.efiling_status ?? "",
                            ack_no: dataItem?.ack_no ?? "", filing_date: dataItem.filing_date ?? ""
                        }
                        setReturnPayload(Payload);
                        setNewReturnId(dataItem?.return_id ?? "");
                        clickCorrectionWorkOnThis(dataItem);
                        setCreateReturnPopup(false);

                    }}
                    indiviualQuarterSync={indiviualQuarterSync}
                    successProceed={() => {
                        handleClose();
                        successProced()
                    }}
                />
            }
            <WindowWrapperFixed
                title={<kendo-dialog-titlebar>
                    <div style={{width: "600px"}}>
                        <span></span>
                        <span class="StartFiling_title">
                            <button class="btn btn-default StartFiling_btn zps_dd_btn_start_manually" onClick={() => setCreateReturnPopup(true)}>Start Manually
                            </button>
                        </span >
                    </div>
                </kendo-dialog-titlebar>}
                onClose={handleClose}
                totalPopups={1}
                initialLeft={width / (3)}
                initialTop={startFilingPopup ? 1200 : 40}
                initialWidth={650}
                initialHeight={265}
                index={1}
                ValueNotUpdate={true}
                className="fillinglistwindowrapper"
                style={{ maxHeight: '340px', overflowX: 'hidden' }}
                isModalView={true}
            >
                <div style={{width: "640px"}}>
                    {error
                        ? (
                            <Alert variant="danger" style={{ fontSize: "12px" }} onClose={() => setError('')} dismissible>{error}</Alert>
                        )
                        : null}
                    {success
                        ? (
                            <Alert variant="success" style={{ fontSize: "12px" }} onClose={() => setSuccess('')}>{success}</Alert>
                        )
                        : null}

                    <div class="row mx-0">
                        <div class="col-md-12">
                            <p class="selectcell_fs mt-1 mb-1">
                                Select the Cell (combination of Quarter and Form) to start filing new return
                            </p>
                            <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <table class="table table-striped stratfiling_tb mb-0">
                                    <thead>
                                        <tr>
                                            <th width="150">Form</th>
                                            <th width="150">Q1</th>
                                            <th width="150">Q2</th>
                                            <th width="150">Q3</th>
                                            <th width="150">Q4</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && Object.keys(data)?.length > 0 ? Object.keys(data).map((key) => {
                                            return (
                                                <tr>
                                                    <td class="text-center">
                                                        {key ?? ""}
                                                    </td>
                                                    <td class="text-center stratfiling_textclr_coral">
                                                        {quartReturnType(data[key], key, "Q1")}
                                                        <span className='startfiling_subtextfs'>
                                                           {data?.[key]?.["Q1"]?.return_type === 'CORRECTION' && data?.[key]?.["Q1"]?.regular_date ?
                                                            `(Regular on ${moment(data?.[key]?.["Q1"]?.regular_date).format("DD/MM/YYYY")})`
                                                             : ``} 
                                                        </span>
                                                    </td>
                                                    <td class="text-center stratfiling_textclr_blue">
                                                        {quartReturnType(data[key], key, "Q2")}
                                                        <span className='startfiling_subtextfs'>
                                                           {data?.[key]?.["Q2"]?.return_type === 'CORRECTION' && data?.[key]?.["Q2"]?.regular_date ? 
                                                           `(Regular on ${moment(data?.[key]?.["Q2"]?.regular_date).format("DD/MM/YYYY")})` 
                                                           : ``} 
                                                        </span>
                                                    </td>
                                                    <td class="text-center stratfiling_textclr_coral">
                                                        {quartReturnType(data[key], key, "Q3")}
                                                        <span className='startfiling_subtextfs'>
                                                           {data?.[key]?.["Q3"]?.return_type === 'CORRECTION' && data?.[key]?.["Q3"]?.regular_date ? 
                                                           `(Regular on ${moment(data?.[key]?.["Q3"]?.regular_date).format("DD/MM/YYYY")})` 
                                                           : ``} 
                                                        </span>
                                                    </td>
                                                    <td class="text-center stratfiling_textclr_blue">
                                                        {quartReturnType(data[key], key, "Q4")}
                                                        <span className='startfiling_subtextfs'>
                                                           {data?.[key]?.["Q4"]?.return_type === 'CORRECTION' && data?.[key]?.["Q4"]?.regular_date ?
                                                            `(Regular on ${moment(data?.[key]?.["Q4"]?.regular_date).format("DD/MM/YYYY")})` 
                                                            : ``} 
                                                        </span>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                            : <tr><td colSpan={5} className="text-center">No Record Found</td></tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                    </WindowWrapperFixed>
        </>
    )
}

export default FillingListPopup