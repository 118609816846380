export const fileHeaderRecordColumns = {
    'keys24Q': [
        'sr_no',
        'record_type',
        'file_type',
        'upload_type',
        'file_creation_date',
        'file_sequence_no',
        'uploader_type',
        'tan_of_deductor',
        'total_batches',
        'return_preparation_utility',
        'record_hash',
        'fvu_versionss',
        'file_hash',
        'sam_version',
        'sam_hash',
        'scm_version',
        'scm_hash',
        'consolidated_file_hash'
    ],
    'keys26Q': [
        'sr_no',
        'record_type',
        'file_type',
        'upload_type',
        'file_creation_date',
        'file_sequence_no',
        'uploader_type',
        'tan_of_deductor',
        'total_batches',
        'return_preparation_utility',
        'record_hash',
        'fvu_versionss',
        'file_hash',
        'sam_version',
        'sam_hash',
        'scm_version',
        'scm_hash',
        'consolidated_file_hash'
    ],
    'keys27Q': [
        'sr_no',
        'record_type',
        'file_type',
        'upload_type',
        'file_creation_date',
        'file_sequence_no',
        'uploader_type',
        'tan_of_deductor',
        'total_batches',
        'return_preparation_utility',
        'record_hash',
        'fvu_versionss',
        'file_hash',
        'sam_version',
        'sam_hash',
        'scm_version',
        'scm_hash',
        'consolidated_file_hash'
    ],
    'keys27EQ': [
        'sr_no',
        'record_type',
        'file_type',
        'upload_type',
        'file_creation_date',
        'file_sequence_no',
        'uploader_type',
        'tan_of_deductor',
        'total_batches',
        'return_preparation_utility',
        'record_hash',
        'fvu_versionss',
        'file_hash',
        'sam_version',
        'sam_hash',
        'scm_version',
        'scm_hash',
        'consolidated_file_hash'
    ]
};

export const batchHeaderRecordColumns = {
    'keys24Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_count_transfer_voucher',
        'form_number',
        'transaction_type',
        'batch_updation_indicator',
        'original_token_number',
        'token_number_previous_regular_statement',
        'token_number_statement_submitted',
        'token_number_date',
        'last_tan_deductor_collector',
        'tan_deductor ',
        'receipt_number',
        'pan_deductor',
        'assessment_yr',
        'financial_yr',
        'period',
        'deductor_name',
        'deductor_branch',
        'deductor_address1',
        'deductor_address2',
        'deductor_address3',
        'deductor_address4',
        'deductor_address5',
        'deductor_address_state',
        'deductor_address_pincode',
        'deductor_email',
        'deductor_std_code',
        'deductor_phone_no',
        'change_address_deductor_since_last_return',
        'deductor_type',
        'name_person_responsible_for_deduction',
        'designation_person_responsible_deduction',
        'responsible_person_address1',
        'responsible_person_address2',
        'responsible_person_address3',
        'responsible_person_address4',
        'responsible_person_address5',
        'responsible_person_state',
        'responsible_person_pin',
        'responsible_person_email',
        'mobile_number',
        'responsible_person_std_code',
        'responsible_person_phone_no',
        'change_address_responsible_person_since_last_return',
        'total_deposit_amount_as_per_challan',
        'unmatched_challan_count',
        'count_salary_details_records',
        'gross_total_income_as_per_salary_detail',
        'ao_approval',
        'whether_regular_statement_for_form_26Q_filed_for_earlier_period',
        'last_deductor_type',
        'state_name',
        'pao_code',
        'ddo_code',
        'ministry_name',
        'ministry_name_other',
        'pan_responsible_person',
        'pao_registration_no',
        'ddo_registration_no',
        'deductor_std_code_alternate',
        'deductor_phone_no_alternate',
        'deductor_email_alternate',
        'responsible_person_std_code_alternate',
        'responsible_person_phone_no_alternate',
        'responsible_person_email_alternate',
        'account_office_identification_number',
        'GSTN',
        'record_hash',
    ],
    'keys26Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_count_transfer_voucher',
        'form_number',
        'transaction_type',
        'batch_updation_indicator',
        'original_token_number',
        'token_number_previous_regular_statement',
        'token_number_statement_submitted',
        'token_number_date',
        'last_tan_deductor_collector',
        'tan_deductor ',
        'receipt_number',
        'pan_deductor',
        'assessment_yr',
        'financial_yr',
        'period',
        'deductor_name',
        'deductor_branch',
        'deductor_address1',
        'deductor_address2',
        'deductor_address3',
        'deductor_address4',
        'deductor_address5',
        'deductor_address_state',
        'deductor_address_pincode',
        'deductor_email',
        'deductor_std_code',
        'deductor_phone_no',
        'change_address_deductor_since_last_return',
        'deductor_type',
        'name_person_responsible_for_deduction',
        'designation_person_responsible_deduction',
        'responsible_person_address1',
        'responsible_person_address2',
        'responsible_person_address3',
        'responsible_person_address4',
        'responsible_person_address5',
        'responsible_person_state',
        'responsible_person_pin',
        'responsible_person_email',
        'mobile_number',
        'responsible_person_std_code',
        'responsible_person_phone_no',
        'change_address_responsible_person_since_last_return',
        'total_deposit_amount_as_per_challan',
        'unmatched_challan_count',
        'count_salary_details_records',
        'gross_total_income_as_per_salary_detail',
        'ao_approval',
        'whether_regular_statement_for_form_26Q_filed_for_earlier_period',
        'last_deductor_type',
        'state_name',
        'pao_code',
        'ddo_code',
        'ministry_name',
        'ministry_name_other',
        'pan_responsible_person',
        'pao_registration_no',
        'ddo_registration_no',
        'deductor_std_code_alternate',
        'deductor_phone_no_alternate',
        'deductor_email_alternate',
        'responsible_person_std_code_alternate',
        'responsible_person_phone_no_alternate',
        'responsible_person_email_alternate',
        'account_office_identification_number',
        'GSTN',
        'record_hash',
    ],
    'keys27Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_count_transfer_voucher',
        'form_number',
        'transaction_type',
        'batch_updation_indicator',
        'original_token_number',
        'token_number_previous_regular_statement',
        'token_number_statement_submitted',
        'token_number_date',
        'last_tan_deductor_collector',
        'tan_deductor ',
        'receipt_number',
        'pan_deductor',
        'assessment_yr',
        'financial_yr',
        'period',
        'deductor_name',
        'deductor_branch',
        'deductor_address1',
        'deductor_address2',
        'deductor_address3',
        'deductor_address4',
        'deductor_address5',
        'deductor_address_state',
        'deductor_address_pincode',
        'deductor_email',
        'deductor_std_code',
        'deductor_phone_no',
        'change_address_deductor_since_last_return',
        'deductor_type',
        'name_person_responsible_for_deduction',
        'designation_person_responsible_deduction',
        'responsible_person_address1',
        'responsible_person_address2',
        'responsible_person_address3',
        'responsible_person_address4',
        'responsible_person_address5',
        'responsible_person_state',
        'responsible_person_pin',
        'responsible_person_email',
        'mobile_number',
        'responsible_person_std_code',
        'responsible_person_phone_no',
        'change_address_responsible_person_since_last_return',
        'total_deposit_amount_as_per_challan',
        'unmatched_challan_count',
        'count_salary_details_records',
        'gross_total_income_as_per_salary_detail',
        'ao_approval',
        'whether_regular_statement_for_form_26Q_filed_for_earlier_period',
        'last_deductor_type',
        'state_name',
        'pao_code',
        'ddo_code',
        'ministry_name',
        'ministry_name_other',
        'pan_responsible_person',
        'pao_registration_no',
        'ddo_registration_no',
        'deductor_std_code_alternate',
        'deductor_phone_no_alternate',
        'deductor_email_alternate',
        'responsible_person_std_code_alternate',
        'responsible_person_phone_no_alternate',
        'responsible_person_email_alternate',
        'account_office_identification_number',
        'GSTN',
        'record_hash',
    ],
    'keys27EQ': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_count_transfer_voucher',
        'form_number',
        'transaction_type',
        'batch_updation_indicator',
        'original_token_number',
        'token_number_previous_regular_statement',
        'token_number_statement_submitted',
        'token_number_date',
        'last_tan_deductor_collector',
        'tan_of_collector  ',
        'receipt_number',
        'pan_deductor',
        'assessment_yr',
        'financial_yr',
        'period',
        'deductor_name',
        'collector_branch',
        'collector_address1',
        'collector_address2',
        'collector_address3',
        'collector_address4',
        'collector_address5',
        'collector_address_state',
        'collector_address_pincode',
        'collector_email',
        'collector_std_code',
        'collector__phone_no',
        'change_address_collector_since_last_return',
        'collector_type',
        'name_person_responsible_for_collection',
        'designation_person_responsible_collection ',
        'responsible_person_address1',
        'responsible_person_address2',
        'responsible_person_address3',
        'responsible_person_address4',
        'responsible_person_address5',
        'responsible_person_state',
        'responsible_person_pin',
        'responsible_person_email',
        'mobile_number',
        'responsible_person_std_code',
        'responsible_person_phone_no',
        'change_address_responsible_person_since_last_return',
        'total_deposit_amount_as_per_challan',
        'unmatched_challan_count',
        'count_salary_details_records',
        'gross_total_income_as_per_salary_detail',
        'ao_approval',
        'whether_regular_statement_for_form_26Q_filed_for_earlier_period',
        'last_collector_type',
        'state_name',
        'pao_code',
        'ddo_code',
        'ministry_name',
        'ministry_name_other',
        'pan_responsible_person',
        'pao_registration_no',
        'ddo_registration_no',
        'collector_std_code_alternate',
        'collector_phone_no_alternate',
        'collector_email_alternate',
        'responsible_person_std_code_alternate',
        'responsible_person_phone_no_alternate',
        'responsible_person_email_alternate',
        'account_office_identification_number',
        'GSTN',
        'record_hash',
    ]
};

export const challanDetailsColumns = {
    'keys24Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_detail_record_number',
        'count_of_deductee',
        'nil_challan_indicator',
        'challan_updation_indicator',
        'filler_2',
        'filler_3',
        'filler_4',
        'last_challan_serial_no',
        'bank_challan_no',
        'last_transfer_voucher_no',
        'ddo_serial_number_of_form_24G',
        'last_bank_branch_code_form_24G_receipt_number',
        'bank_branch_code_Form_24G_Receipt_Number',
        'last_date_of_bank_challan_no_transfer_voucher_no',
        'date_of_bank_challan_transfer_voucher',
        'filler_5',
        'filler_6',
        'section',
        'oltas_TDS_TCS_income_tax',
        'oltas_TDS_TCS_surcharge',
        'oltas_TDS_TCS_cess',
        'oltas_TDS_TCS_interest_amount',
        'oltas_TDS_TCS_others_amount',
        'total_deposit_amount_challan_transfer_voucher_number',
        'last_total_deposit_amount_challan',
        'total_tax_deposit_amount_deductee_annexure',
        'TDS_TCS_income_tax',
        'TDS_TCS_surcharge',
        'TDS_TCS_cess',
        'total_income_tax_deducted_source',
        'TDS_TCS_interest_amount',
        'TDS_TCS_others_amount',
        'cheque_dd_no',
        'By_book_entry_cash',
        'remarks',
        'fee',
        'minor_head_of_challan',
    ],
    'keys26Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_detail_record_number',
        'count_of_deductee',
        'nil_challan_indicator',
        'challan_updation_indicator',
        'filler_3',
        'filler_4',
        'filler_5',
        'last_bank_challan_no',
        'bank_challan_no',
        'last_transfer_voucher_no',
        'ddo_serial_number_of_form_24G',
        'last_bank_branch_code_form_24G_receipt_number',
        'bank_branch_code_Form_24G_Receipt_Number',
        'last_date_of_bank_challan_no_transfer_voucher_no',
        'date_of_bank_challan_transfer_voucher',
        'filler_6',
        'filler_7',
        'section',
        'oltas_TDS_TCS_income_tax',
        'oltas_TDS_TCS_surcharge',
        'oltas_TDS_TCS_cess',
        'oltas_TDS_TCS_interest_amount',
        'oltas_TDS_TCS_others_amount',
        'total_deposit_amount_challan_transfer_voucher_number',
        'last_total_deposit_amount_challan',
        'total_tax_deposit_amount_deductee_annexure',
        'TDS_TCS_income_tax',
        'TDS_TCS_surcharge',
        'TDS_TCS_cess',
        'total_income_tax_deducted_source',
        'TDS_TCS_interest_amount',
        'TDS_TCS_others_amount',
        'cheque_dd_no',
        'By_book_entry_cash',
        'remarks',
        'fee',
        'minor_head_of_challan',
    ],
    'keys27Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_detail_record_number',
        'count_of_deductee',
        'nil_challan_indicator',
        'challan_updation_indicator',
        'filler_3',
        'filler_4',
        'filler_5',
        'last_bank_challan_no',
        'bank_challan_no',
        'last_transfer_voucher_no',
        'ddo_serial_number_of_form_24G',
        'last_bank_branch_code_form_24G_receipt_number',
        'bank_branch_code_Form_24G_Receipt_Number',
        'last_date_of_bank_challan_no_transfer_voucher_no',
        'date_of_bank_challan_transfer_voucher',
        'filler_6',
        'filler_7',
        'section',
        'oltas_TDS_TCS_income_tax',
        'oltas_TDS_TCS_surcharge',
        'oltas_TDS_TCS_cess',
        'oltas_TDS_TCS_interest_amount',
        'oltas_TDS_TCS_others_amount',
        'total_deposit_amount_challan_transfer_voucher_number',
        'last_total_deposit_amount_challan',
        'total_tax_deposit_amount_deductee_annexure',
        'TDS_TCS_income_tax',
        'TDS_TCS_surcharge',
        'TDS_TCS_cess',
        'total_income_tax_deducted_source',
        'TDS_TCS_interest_amount',
        'TDS_TCS_others_amount',
        'cheque_dd_no',
        'By_book_entry_cash',
        'remarks',
        'fee',
        'minor_head_of_challan',
    ],
    'keys27EQ': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_detail_record_number',
        'count_of_party_records',
        'nil_challan_indicator',
        'challan_updation_indicator',
        'filler_3',
        'filler_4',
        'filler_5',
        'last_bank_challan_no',
        'bank_challan_no',
        'last_transfer_voucher_no',
        'ddo_serial_number_of_form_24G',
        'last_bank_branch_code_form_24G_receipt_number',
        'bank_branch_code_Form_24G_Receipt_Number',
        'last_date_of_bank_challan_no_transfer_voucher_no',
        'date_of_bank_challan_transfer_voucher',
        'filler_6',
        'filler_7',
        'section',
        'oltas_TDS_TCS_income_tax',
        'oltas_TDS_TCS_surcharge',
        'oltas_TDS_TCS_cess',
        'oltas_TDS_TCS_interest_amount',
        'oltas_TDS_TCS_others_amount',
        'total_deposit_amount_challan_transfer_voucher_number',
        'last_total_deposit_amount_challan',
        'total_tax_deposit_amount_deductee_annexure',
        'TDS_TCS_income_tax',
        'TDS_TCS_surcharge',
        'TDS_TCS_cess',
        'total_income_tax_deducted_source',
        'TDS_TCS_interest_amount',
        'TDS_TCS_others_amount',
        'cheque_dd_no',
        'By_book_entry_cash',
        'remarks',
        'fee',
        'minor_head_of_challan',
    ]
};

export const deducteeDetailsColumns = {
    'keys24Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_detail_record_number',
        'deductee_detail_record_no',
        'mode',
        'employee_serial_no',
        'deductee_code',
        'last_employee_party_pan',
        'deductee_pan',
        'last_deductee_ref_no',
        'deductee_ref_no',
        'name_of_deductee',
        'TDS_TCS_income_tax',
        'TDS_TCS_surcharge',
        'TDS_TCS_cess',
        'total_income_tax_deducted_at_source_TDS_TCS_income_tax',
        'last_total_income_tax_deducted_at_source',
        'total_tax_deposited',
        'last_total_tax_deposited',
        'total_value_of_purchase',
        'amount_of_payment_credit',
        'date_on_which_amount_paid_credited',
        'date_on_which_tax_deducted_collected',
        'date_of_deposit',
        'rate_at_which_tax_deducted_collected',
        'grossing_up_indicator',
        'book_entry_cash_indicator',
        'date_of_furnishing_tax_deduction_certificate',
        'remarks_1',
        'remarks_2',
        'remarks_3',
        'section_code_under_which_payment_made',
        'certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction',
        'filler_1',
        'filler_2',
        'filler_3',
        'filler_4',
        'filler_5',
        'filler_6',
        'filler_7',
        'filler_8',
        'filler_9',
        'filler_10',
        'filler_11',
        'filler_12',
        'filler_13',
        'record_hash',
    ],
    'keys26Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_detail_record_number',
        'deductee_detail_record_no',
        'mode',
        'employee_serial_no',
        'deductee_code',
        'last_employee_party_pan',
        'deductee_pan',
        'last_deductee_ref_no',
        'deductee_ref_no',
        'name_of_deductee',
        'TDS_TCS_income_tax',
        'TDS_TCS_surcharge',
        'TDS_TCS_cess',
        'total_income_tax_deducted_at_source_TDS_TCS_income_tax',
        'last_total_income_tax_deducted_at_source',
        'total_tax_deposited',
        'last_total_tax_deposited',
        'total_value_of_purchase',
        'amount_of_payment_credit',
        'date_on_which_amount_paid_credited',
        'date_on_which_tax_deducted_collected',
        'date_of_deposit',
        'rate_at_which_tax_deducted_collected',
        'grossing_up_indicator',
        'book_entry_cash_indicator',
        'date_of_furnishing_tax_deduction_certificate',
        'remarks_1',
        'remarks_2',
        'remarks_3',
        'section_code_under_which_payment_made',
        'certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction',
        'filler_2',
        'filler_2',
        'filler_3',
        'filler_4',
        'filler_5',
        'filler_6',
        'filler_7',
        'filler_8',
        'cash_withdrawal_in_excess_of_1_crore_referreds_section_194N',
        'cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N',
        'cash_withdrawal_in_excess_1_crore_cases_covered_section_194N',
        'cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N',
        'cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N',
        'cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N',
        'filler_11',
        'filler_12',
        'filler_13',
        'filler_14',
        'filler_15',
        'record_hash',
    ],
    'keys27Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_detail_record_number',
        'deductee_detail_record_no',
        'mode',
        'employee_serial_no',
        'deductee_code',
        'last_employee_party_pan',
        'deductee_pan',
        'last_deductee_ref_no',
        'deductee_ref_no',
        'name_of_deductee',
        'TDS_TCS_income_tax',//14
        'TDS_TCS_surcharge',
        'TDS_TCS_cess',
        'total_income_tax_deducted_at_source_TDS_TCS_income_tax',
        'last_total_income_tax_deducted_at_source',
        'total_tax_deposited',
        'last_total_tax_deposited',
        'total_value_of_purchase',
        'amount_of_payment_credit',
        'date_on_which_amount_paid_credited',
        'date_on_which_tax_deducted_collected',
        'date_of_deposit',
        'rate_at_which_tax_deducted_collected',
        'grossing_up_indicator',
        'book_entry_cash_indicator',
        'date_of_furnishing_tax_deduction_certificate',
        'remarks_1',
        'remarks_2',
        'remarks_3',
        'section_code_under_which_payment_made',
        'certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction',
        'wheather_tds_rate_and_dtaa', 
        'name_of_remittance',
        'unique_ack_form_15ca',
        'country_of_residence_deductee',
        'email_id_deductee',
        'contact_number_deductee',
        'address_of_deductee',
        'tax_id_number', 
        'cash_withdrawal_in_excess_of_1_crore_referreds_section_194N',
        'cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N',
        'cash_withdrawal_in_excess_1_crore_cases_covered_section_194N',
        'cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N',
        'cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N',
        'cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N',
        'wheater_deductee_opting_out_115bac',//702
        'filler_12',
        'filler_13',
        'filler_14',
        'filler_15',
        'record_hash',
    ],
    'keys27EQ': [
        'sr_no',
        'record_type',
        'batch_number',
        'challan_detail_record_no',
        'party_detail_record_no',
        'mode',
        'employee_serial_no',
        'party_code',
        'last_employee_party_pan',
        'party_pan',
        'last_deductee_ref_no',
        'deductee_ref_no',
        'name_of_party',
        'TDS_TCS_income_tax',
        'TDS_TCS_surcharge',
        'TDS_TCS_cess',
        'total_income_tax_deducted_at_source_TDS_TCS_income_tax',
        'last_total_income_tax_deducted_at_source',
        'total_tax_deposited',
        'last_total_tax_deposited',
        'total_value_of_purchase',
        'amount_of_receipt_debited',
        'date_on_which_amount_received_debited',
        'date_on_which_tax_collected',
        'date_of_deposit',
        'rate_at_which_tax_deducted_collected',
        'grossing_up_indicator',
        'book_entry_cash_indicator ',
        'date_of_furnishing_tax_deduction_certificate',
        'remarks_1',
        'deductee_is_non_resident ',
        'deductee_is_having_permanent_establishment_in_india',
        'section_code_under_which_payment_made',
        'certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction.',
        'whether_the_payment_by_collectee_is_liable_to_TDS_as_per_clause',
        'challan_number',
        'date_of_payment_of_TDS_to_central_government',
        'filler_4',
        'filler_5',
        'filler_6',
        'filler_7',
        'filler_8',
        'filler_9',
        'filler_10',
        'filler_11',
        'filler_12',
        'filler_13',
        'filler_14',
        'whether_collectee_opting_out_115bac',
        'filler_16',
        'filler_17',
        'filler_18',
        'filler_19',
        'record_hash',
    ]
};



export const salaryDetailsColumns = {
    'keys24Q': [
        'sr_no',
        'record_type',
        'batch_number',
        'salary_detail_record_number',
        'mode',
        'filler_7',
        'employee_pan',
        'pan_ref_no',
        'name_of_employee',
        'category_of_employee',
        'period_of_employment_from_date',
        'period_of_employment_to_date',
        'total_amount_of_salary',
        'filler_8',
        'count_of_salary_details_section_16_detail',
        'gross_total_of_total_deduction_under_section_16',
        'income_chargeable_under_the_head_salaries',
        'income_including_admissible_loss_from_house_property',
        'gross_total_income',
        'last_gross_total_income',
        'count_of_salary_details_chapter_VI_A_detail ',
        'gross_total_of_amount_deductible_under_provisions_of_chapter_VI_A',
        'total_taxable_income',
        'income_tax_on_total_income',
        'surcharge',
        'education_cess',
        'income_tax_relief_us_89',
        'net_income_tax_payable',
        'total_amount_of_tax_deducted_at_source_for_the_whole_year',
        'shortfall_in_tax_deduction',
        'aggregate_amount_of_deductions_admissible_under_section_80C_80CCC_80CCD',
        'remarks1',
        'remarks2',
        'taxable_amount_on_which_tax_is_deducted_by_the_current_employer',
        'reported_taxable_amount_on_which_tax_is_deducted_by_previous_employer',
        'total_amount_of_tax_deducted_at_source_by_the_current_employer_for_the_whole_year',
        'reported_amount_of_tax_deducted_at_source_by_previous_employer',
        'whether_tax_deducted_at_higher_rate_due_to_non_furnishing_of_pan_by_deductee',
        'whether_aggregate_rent_payment_exceeds_rupees_one_lakh_during_previous_year',
        'count_of_pan_of_the_landlord',
        'pan_of_landlord_1',
        'name_of_landlord_1',
        'pan_of_landlord_2',
        'name_of_landlord_2',
        'pan_of_landlord_3',
        'name_of_landlord_3',
        'pan_of_landlord_4',
        'name_of_landlord_4',
        'whether_interest_paid_to_the_lender_under_the_head_income_from_house_property',
        'count_of_pan_of_the_lender',
        'pan_of_lender_1',
        'name_of_lender_1',
        'pan_of_lender_2',
        'name_of_lender_2',
        'pan_of_lender_3',
        'name_of_lender_3',
        'pan_of_lender_4',
        'name_of_lender_4',
        'whether_contributions_paid_by_the_trustees_of_an_approved_superannuation_fund',
        'name_of_the_superannuation_fund',
        'date_from_which_the_employee_has_contributed_to_the_superannuation_fund',
        'date_to_which_the_employee_has_contributed_to_the_superannuation_fund',
        'the_amount_of_contribution_repaid_on_account_of_principal_and_interest_from_superannuation_fund',
        'the_average_rate_of_deduction_of_tax_during_the_preceding_three_years',
        'the_amount_of_tax_deducted_on_repayment_of_superannuation_fund',
        'gross_total_income_including_contribution_repaid_on_account_of_principal_and_interest_from_superannuation_fund',
        'gross_salary_as_per_provisions_contained_in_sec17',
        'value_of_perquisites',
        'profits_in_lieu_of_salary',
        'travel_concession_or_assistance',
        'death_cum_retirement_gratuity',
        'commuted_value_of_pension',
        'cash_eqivalent_of_leave_salary_encashment',
        'house_rent_allowance',
        'amount_of_any_other_exemption_under_section_10',
        'total_amount_of_exemption_claimed_under_section_10',
        'income_under_the_head_other_sources_offered_for_TDS',
        'rebate_under_section_87A',
        'whether_opting_for_taxation_us_115BAC',
        'special_us_10_14_allowance',
        'record_hash'
    ]
};