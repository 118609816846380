import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ColumnMenu } from "../../components/grid/ColumnMenu";
import { formatNumber } from "../../utils/UtilityFunctions";
import { getMappedTransactionListV2 } from "../../api/challanAPI";
import ShowMorePagination from "../../components/pagination/ShowMorePagination";
import { Link } from 'react-router-dom';
import { BASE_PATH } from "../../app/constants";


const ChallanListOtherTransaction = (props) => {

    const { onClose, mappedData, paymentCodes, params, challanId } = props;

    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [showMoreLoading, setShowMoreLoading] = useState(false);

    const quarters = [
        { label: "Q1", value: "Q1" },
        { label: "Q2", value: "Q2" },
        { label: "Q3", value: "Q3" },
        { label: "Q4", value: "Q4" },
    ];

    const formTypes = [
        { label: "24Q", value: "24Q" },
        { label: "26Q", value: "26Q" },
        { label: "27Q", value: "27Q" },
        { label: "27EQ", value: "27EQ" },
    ];

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };


    const dataStateChange = (dataState) => {
        setDataState(dataState);
    };

    const getOtherTransactionDataV2 = (data) => {
        async function fetchEverything() {
            async function fetchChallans() {
                const result = await getMappedTransactionListV2(data);
                if (result.data.cursor.current_page > 1) {
                    setData(mappedData.concat(result?.data?.data ?? []));
                } else {
                    setData(result?.data?.data ?? []);
                }
                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page; setShowMoreLoading(true);
                setCursor(result?.data?.cursor);


            }
            try {
                await Promise.all([
                    fetchChallans(),
                ]);
            } catch (err) {
                console.error('error: ', err);
            } finally {
                setShowMoreLoading(false);
            }
        }
        fetchEverything();
    };

    const fetchData = (filterData = dataState) => {
        let request = {
            deductor_id: params?.deductor_id ?? "",
            return_id: params?.return_id ?? "",
            get_by_other_return: true,
            challan_id: challanId,
            limit: 16
        };

        if (filterData.sort && filterData.sort.length > 0) {
            request.sort_on = filterData.sort[0].field;
            request.sort_by = filterData.sort[0].dir;
        }

        if (filterData.filter && filterData.filter.filters) {
            request.filters = filterData.filter.filters;
        }

        if (filterData.page) {
            request.page = filterData.page;
        }

        getOtherTransactionDataV2(request);
    };

    const onChangeFilter = (newDataState) => {
        dataStateChange(newDataState);
        fetchData(newDataState);
    }

    useEffect(() => {
        fetchData(dataState);
        //eslint-disable-next-line
    }, [params?.deductor_id, params?.return_id]);

    const rowRender = (trElement, props,) => {

        const key = trElement._owner.index;

        return (
            <tr key={key} class="dash_totalverchall_trth2romamt" role="row">
                <td class="text-center data_padding_right challanno_textclr_blurundline">
                    <Link to={`${BASE_PATH}challans?deductor_id=${params?.deductor_id}&return_id=${props?.dataItem?.return_id}`} target="_blank">{props?.dataItem?.form_type ?? ''}</Link>
                </td>
                <td class="text-center data_padding_right challanno_textclr_blurundline">
                    <Link to={`${BASE_PATH}challans?deductor_id=${params?.deductor_id}&return_id=${props?.dataItem?.return_id}`} target="_blank">{props?.dataItem?.quarter ?? ''}</Link>
                </td>
                <td class="text-left">
                    {props?.dataItem?.deductee_name ?? ''}
                </td>
                <td class="text-left data_padding-left">
                    {props?.dataItem?.pan ?? ''}
                </td>
                <td class="text-left data_padding-left">
                    {props?.dataItem?.display_code ?? ''}
                </td>
                <td class="text-center data_padding_right">
                    {props?.dataItem?.tds_rate_b100 ?? '-'}
                </td>
                <td class="text-center data_padding_right">
                    {props?.dataItem?.payment_date ?? 0}
                </td>
                <td class="text-center data_padding_right">
                    {props?.dataItem?.deduction_date ?? 0}
                </td>
                <td class="text-right" style={{paddingRight: '20px'}}>
                    {formatNumber(props?.dataItem?.paid_amtp ?? 0)}
                </td>
                <td class="text-right" style={{paddingRight: '20px'}}>
                    {formatNumber(props?.dataItem?.total_tds_amtp ?? 0)}
                </td>
            </tr>
        );
    }

    return (
        <>

            <Tooltip anchorElement="pointer" position="right">
                <Grid
                    className="table table-striped listofsalarydetailstb"
                    // style={{ height: '400px', overflowX: 'hidden' }}
                    data={data}
                    {...dataState}
                    onDataStateChange={(event) => onChangeFilter(event.dataState)}
                    filterOperators={{
                        text: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                        ],
                        numeric: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                        ],
                        date: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                        ],
                        textWithEmpty: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                        ],
                        dropdown: [
                            { text: 'grid.filterContainsOperator', operator: 'in' },
                        ],
                        pan: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterContainsOperator', operator: 'in' },
                        ],
                    }}
                    sortable={{
                        allowUnsort: true,
                    }}
                    rowRender={rowRender}
                >
                    <Column
                        field="form_type" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('form_type') ? 'active' : ''}
                        title='Form'
                        width="100"
                        filter="dropdown"
                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={formTypes} />}
                    />

                    <Column
                        field="quarter" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('quarter') ? 'active' : ''}
                        title="Quarter"
                        width="100"
                        filter="dropdown"
                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={quarters} />}
                    />

                    <Column
                        field="deductee_name" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                        title='Deductee Name'
                        width="180"
                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                        filter="textWithEmpty"
                    />

                    <Column
                        field="pan" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('pan') ? 'active' : ''}
                        title="PAN"
                        width="120"
                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                        filter="pan"
                    />

                    <Column
                        field="code" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('code') ? 'active' : ''}
                        title="Section"
                        width="100"
                        filter="dropdown"
                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                    />

                    <Column
                        field="tds_rate_b100" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('tds_rate_b100') ? 'active' : ''}
                        title="Rate (%)"
                        width="120"
                        filter="numeric"
                        columnMenu={(props) => <ColumnMenu {...props} />}
                    />

                    <Column
                        field="payment_date" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('payment_date') ? 'active' : ''}
                        // title="Unconsumed Amt"
                        title='Payment Date'
                        width="120"
                        filter="date"
                        columnMenu={(props) => <ColumnMenu {...props} />}
                    />

                    <Column
                        field="deduction_date" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('deduction_date') ? 'active' : ''}
                        // title="Transactions"
                        title='Deduction Date'
                        filter="date"
                        columnMenu={(props) => <ColumnMenu {...props} />}
                        width="120"
                    />

                    <Column
                        field="paid_amtp" dataStateChange={dataStateChange}
                        headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                        // title="Transactions"
                        title='Amt Paid'
                        filter="numeric"
                        columnMenu={(props) => <ColumnMenu {...props} />}
                        width="120"
                    />

                    <Column
                        field="total_tds_amtp" dataStateChange={dataStateChange}
                        filter="numeric"
                        headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                        title='Total Tax Deducted'
                        columnMenu={(props) => <ColumnMenu {...props} />}
                        width="120"
                    />

                </Grid>
            </Tooltip>

            <div className="row mt-2" style={{ width: '100%' }}>
                <div className="col-md-12 text-center">
                    <ShowMorePagination
                        cursor={cursor}
                        fetchData={(nextPage) => {
                            fetchData({ ...dataState, page: nextPage.page });
                        }}
                        postData={{}}
                        loading={showMoreLoading}
                    />
                </div>
            </div>

            <div className="col-md-12 text-center">
                <button className="btn btn-default 5othermappedchallbtn" onClick={() => onClose()}>Back</button>
            </div>




        </>

    )

};

export default ChallanListOtherTransaction;