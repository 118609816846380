/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import $ from 'jquery';
import moment from 'moment';
import { NATURE_OF_REMITTANCE_LIST_NEW } from '../../app/constants';
import ErrorFocus from '../ErrorFocus';
import '@progress/kendo-theme-default/dist/all.css';
import './CreateDeducteeTransactionsForm.css';
import { deducteeTransactionsFormFields } from './DeducteeTransactionsFormHelper';
import { correctionDeducteeTransactionsFormValidationSchema } from './DeducteeTransactionsFormSchema';
import DatePicker from '../datePicker/DatePicker';
import {
  getNatureOfPaymentCodes,
  getCorrectionChallans,
} from '../../api/challanAPI';
import { getCorrectionDeducteesAutocomplete } from '../../api/deducteeAPI';
import AlphaNumericInput from '../AlphaNumericInput';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import TdsRateInput from '../TdsRateInput';
import NumericInput from '../NumericInput';
import { calculation26QTaxRate, calculation27EQTaxRate, calculation27QTaxRate } from './calculationTaxRates'
import { getReasonList, getReasonListV2 } from './reasonList';
import AddDeductee from '../../features/deducteeList/AddDeductee';
import Select from 'react-select';
import ConnectorAdapter from '../kdkConnector/ConnectorAdapter';
import TracesConnector from '../kdkConnector/TracesConnector';
import SinglePanVerify from '../verifyPAN/SinglePanVerify';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import InsightsConnectorWithCaptcha from '../kdkConnector/InsightsConnectorWithCaptcha';
import Addbtnround from '../../images/icons/add_btn_round.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons';

export default function CreateDeducteeTransactionsForm(props) {
  const horizontalView = (props.horizontalView ? props.horizontalView : false);
  const {
    deducteeTransactionFormInfo,
    createDeducteeTransactions,
    onClose,
    deducteeTransactionID,
    returnId,
    deductorId,
    updatePopUpSaveAlt,
    headerDataDetails,
    autoSaveTriggerOnce,
    correctionDeducteeTrnsactnInfo,
    updateDeducteeInfos,
    deducteeTransactionsList,
    currentDeductee,
    setCurrentDeductee,
  } = props;
  const [mapChallan, setMapChallan] = useState(true);
  const [paymentDate, setPaymentDate] = useState('');
  const [, setDeductionDate] = useState(paymentDate);
  const [tdsRateB100, setTdsRateB100] = useState(
    deducteeTransactionFormInfo.tds_rate_b100,
  );
  const [paidAmount, setPaidAmount] = useState(
    deducteeTransactionFormInfo.paid_amtp,
  );
  const [tdsAmount, setTdsAmount] = useState(
    deducteeTransactionFormInfo.tds_amtp,
  );
  const [surchargePaidAmtp, setSurchargePaidAmtp] = useState(
    deducteeTransactionFormInfo.surcharge_paid_amtp,
  );
  const [educationCessAmtp, setEducationCessAmtp] = useState(
    deducteeTransactionFormInfo.education_cess_amtp,
  );
  const firstTotalTdsValue = Number(deducteeTransactionFormInfo.tds_amtp)
    + Number(deducteeTransactionFormInfo.surcharge_paid_amtp)
    + Number(deducteeTransactionFormInfo.education_cess_amtp);
  const [totalTdsAmount, setTotalTdsAmount] = useState(firstTotalTdsValue);
  const [paymentCodes, setPaymentCodes] = useState({});
  const [paymentCodesArray, setPaymentCodesArray] = useState([]);
  const [, setChallanList] = useState([]);
  const [, setCursor] = useState({
    current_page: 1,
    next_page_url: null,
  });
  const [checkedChallanData, setCheckedChallanData] = useState({});

  const [challanInfo, setChallanInfo] = useState({});

  const [challanIdValue, setChallanIdValue] = useState(0);
  const [challanUnutilizedValue, setChallanUnutilizedValue] = useState(0);

  const [deducteeData, setDeducteeData] = useState([]);

  const [, setChallanListStrVal] = useState('');
  const [natureOfPaymentIDVal, setNatureOfPaymentIDVal] = useState('');
  const [challanVals, setChallanVals] = useState('');
  const [mapChallanValChecked, setMapChallanValChecked] = useState(0);

  const [, setPaymentDateCheck] = useState(paymentDate);

  const challanIDFor24QArray = ['192A', '192B', '192C', '194P'];

  const [errors, setErrors] = useState({
    paymentDate: '',
    deductionDate: '',
  });
  const [submitDisable, setSubmitDisable] = useState(false);
  const [submitEnableFor194NF, setSubmitEnableFor194NF] = useState(true);
  const [deducteeTrnsactnInfo, setDeducteeTrnsactnInfo] = useState({});
  const [show27EQExtraFields, setShow27EQExtraFields] = useState(false);
  const [liableFieldDisable, setLiableFieldDisable] = useState(true);
  const [certificateEnable, setCertificateEnable] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [mapChallanDisable, setMapChallanDisable] = useState(false);
  const [sectionErrorTitleMsg, setSectionErrorTitleMsg] = useState(false);

  const [autoChangeCall, setAutoChangeCall] = useState(0);
  const [verifySinglePan, setVerifySinglePan] = useState(false);
  const [complianceCheck, setComplianceCheck] = useState(null);
  const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
  const [pan, setPan] = useState(null);
  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [section, setSection] = useState(null);
  const [startConnector, setStartConnector] = useState(false);
  const [, setVerifyPANResult] = useState(null);
  const [loading, setLoading] = useState(false);
  //const [,setTracesPanData] = useState(null);
  const [panStatus, setPanStatus] = useState(null);
  const [panStatusColor, setPanStatusColor] = useState(null);
  const [panComplianceStatus, setPanComplianceStatus] = useState(null);
  const [panAsOnDate, setPanAsOnDate] = useState(null);
  const [, setInsightsCaptchaRef] = useState(null);

  const formikRef = useRef();
  const [customError, setCustomError] = useState(null);

  const [toggleAllOption, setToggleAllOption] = useState(true)
  const [open, setOpen] = useState(false)

  const updateDeducteeInfo = () => {
    async function getDeducteeTrnsactionDetail() {
      async function getDetail() {
        if (headerDataDetails.form_type === "24Q") {
          deducteeTransactionFormInfo.tds_rate_b100 = 0;
        }
        if (deducteeTransactionFormInfo) {
          const tds_rate_b100 = deducteeTransactionFormInfo.tds_rate_b100;
          setDeducteeTrnsactnInfo(deducteeTransactionFormInfo);
          setTdsRateB100(tds_rate_b100);
          setTdsAmount(deducteeTransactionFormInfo.tds_amtp);

          setPanStatus(deducteeTransactionFormInfo?.pan_info_status?.text);
          setPanStatusColor(deducteeTransactionFormInfo?.pan_info_status?.color);
          setPanComplianceStatus(deducteeTransactionFormInfo?.compliance_status);
          setPanAsOnDate(deducteeTransactionFormInfo?.as_on_date);
        }
      }
      try {
        await Promise.all([
          getDetail(),
        ]);
      } catch (err) {
        console.error('error: ', err);
      }
    }
    getDeducteeTrnsactionDetail();
  };

  useEffect(() => {
    updateDeducteeInfo();


    function initResizeElement() {
      const popups = document.getElementsByClassName('popup');
      let element = null;
      let startX;
      let startY;
      let startWidth;
      let startHeight;

      function doDragWidth(e) {
        element.style.width = `${startWidth - e.clientX + startX}px`;
      }

      function doDragHeight(e) {
        const cardHeight = $('.card').height() + 30;
        const newHeight = startHeight - e.clientY + startY;
        element.style.height = `${Math.min(cardHeight, newHeight)}px`;
      }

      function stopDrag() {
        document.documentElement.removeEventListener(
          'mousemove',
          doDragWidth,
          false,
        );
        document.documentElement.removeEventListener(
          'mousemove',
          doDragHeight,
          false,
        );
        document.documentElement.removeEventListener(
          'mouseup',
          stopDrag,
          false,
        );
      }

      function initDragWidth(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10,
        );
        document.documentElement.addEventListener(
          'mousemove',
          doDragWidth,
          false,
        );
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      function initDragHeight(e) {
        element = this.parentPopup;

        startY = e.clientY;
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10,
        );
        document.documentElement.addEventListener(
          'mousemove',
          doDragHeight,
          false,
        );
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      for (let i = 0; i < popups.length; i++) {
        const p = popups[i];

        const right = document.createElement('div');
        right.className = 'resizer-left';
        p.appendChild(right);
        right.addEventListener('mousedown', initDragWidth, false);
        right.parentPopup = p;

        const bottom = document.createElement('div');
        bottom.className = 'resizer-up';
        p.appendChild(bottom);
        bottom.addEventListener('mousedown', initDragHeight, false);
        bottom.parentPopup = p;
      }
    }

    $('#create-deductor').animate({
      opacity: '1',
    });

    const convertDateFormat = (date) => {
      const [day, month, year] = date.split('/');
      if (year !== undefined && month !== undefined) {
        return String(`${year}-${month}-${day}`);
      }
      return date;
    }

    async function fetchEverything() {
      async function fetchPaymentCodes() {
        let query_params = `?form_type=${headerDataDetails.form_type}`;
        query_params = query_params + `&quarter=${headerDataDetails.quarter}&financial_year=${headerDataDetails.financial_year_formatted}`;
        const result = await getNatureOfPaymentCodes(query_params);
        const paymentCodeData = {};
        result.data.forEach((paymentCode) => {
          if (parseInt(deducteeTransactionFormInfo?.nature_of_payment_id) === parseInt(paymentCode.nature_of_payment_id)) {
            setNatureOfPaymentIDVal(paymentCode.nature_of_payment_id);
          }
          if (
            headerDataDetails.form_type === '24Q'
            && challanIDFor24QArray.indexOf(paymentCode.code) >= 0
          ) {
            paymentCodeData[paymentCode.nature_of_payment_id] = paymentCode;
          } else if (headerDataDetails.form_type !== '24Q') {
            paymentCodeData[paymentCode.nature_of_payment_id] = paymentCode;
          }
        });
        setPaymentCodes(paymentCodeData);
        if (result?.data && result.data.length > 0) {
          const paymentCodeArray = [];
          result.data.forEach((item) => {
            paymentCodeArray.push({ ...item, value: item.nature_of_payment_id, label: `${item.code} - ${item.description}` })
          })
          setPaymentCodesArray(paymentCodeArray);
        }
      }
      try {
        await Promise.all([fetchPaymentCodes()]);
      } catch (err) {
        console.error('error: ', err);
      }

      async function fetchChallans() {
        const postData = {
          deductor_id: deductorId,
          return_id: returnId,
          "filters": [
            {
              "logic": "and",
              "filters": [
                {
                  "field": "total_unconsumed_amtp",
                  "operator": "gte",
                  "value": "1"
                }
              ]
            }],
          'limit': '50'
        };

        const result = await getCorrectionChallans(postData);

        let challan_list = [];
        let edit_challan_available = false;
        challan_list = result.data.data.map((challandata, index) => {
          if (deducteeTransactionFormInfo && parseInt(deducteeTransactionFormInfo.challan_no) === challandata.challan_no && deducteeTransactionFormInfo.deposit_date === challandata.deposit_date) {
            edit_challan_available = true;
          }
          return challandata;
        })

        if (!edit_challan_available && deducteeTransactionFormInfo && deducteeTransactionFormInfo.challan_id) {
          challan_list.push({
            'challan_no': deducteeTransactionFormInfo.challan_no,
            'challan_id': deducteeTransactionFormInfo.challan_id,
            'total_amtp': deducteeTransactionFormInfo.challan_total_amtp,
            'deposit_date': convertDateFormat(deducteeTransactionFormInfo.deposit_date),
            'total_unconsumed_amtp': 0,
          })
        }

        setChallanList(challan_list);
        setCursor(result.data.cursor);
        const checkedData = {};
        let challanListStr = '';
        challan_list.forEach((challan) => {
          if (
            deducteeTransactionFormInfo
            && parseInt(deducteeTransactionFormInfo.challan_no) === challan.challan_no
          ) {
            setChallanVals(challan.challan_id);
            setChallanUnutilizedValue(challan.total_unconsumed_amtp);
          }
          checkedData[challan.challan_id] = challan;
          if (challanListStr !== '') {
            challanListStr = `${challanListStr},${challan.challan_id}`;
          } else {
            challanListStr = String(challan.challan_id);
          }
        });
        setCheckedChallanData(checkedData);
        setChallanListStrVal(challanListStr);
      }
      try {
        await Promise.all([fetchChallans()]);
      } catch (err) {
        console.error('error: ', err);
      }
    }
    fetchEverything();
    initResizeElement();
    setMapChallanValChecked(deducteeTransactionFormInfo.mapChallanVal);
    setTimeout(function () {
      if (autoSaveTriggerOnce === true) {
        $('#save-deductee-trn').trigger('click');
      }
    }, 1000);

  }, []);

  const checkUnconsumedValue = (amtVals) => {
    setSubmitDisable(false);
    if (parseInt(challanIdValue) === 0) { }
    else if (
      challanUnutilizedValue < amtVals
      && amtVals > 0
      && deducteeTransactionID
    ) { }
    else if (
      challanUnutilizedValue < amtVals
      && amtVals > 0
      && parseInt(mapChallanValChecked) === 1
    ) {
      setSubmitDisable(true);
    } else {
      setSubmitDisable(false);
    }
  };

  const handleOnChange = (key, value) => {
    const startDate = new Date('2020-07-01');
    const endDate = new Date('2020-09-30');
    if (key === 'paymentDate') {
      if (value > endDate) {
        setPaymentDate('');
        setTimeout(() => {
          $('#paymentDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          paymentDate: 'Payment Date Not grater than quarter end date',
        };
        setErrors(newErrors);
      } else if (value < startDate) {
        setPaymentDate('');
        setTimeout(() => {
          $('#paymentDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          paymentDate: 'Payment Date Not less than quarter start date',
        };
        setErrors(newErrors);
      } else if (value <= endDate) {
        setPaymentDate(moment(value).format('YYYY-MM-DD'));
        const newErrors = { ...errors, paymentDate: '', deductionDate: '' };
        setErrors(newErrors);
      }
    } else if (key === 'deductionDate') {
      if (String(paymentDate) === '') {
        setDeductionDate('');
        setTimeout(() => {
          $('#deductionDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          deductionDate: 'Payment should not empty',
        };
        setErrors(newErrors);
      } else if (value < startDate) {
        setDeductionDate('');
        setTimeout(() => {
          $('#deductionDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          deductionDate: 'Deduction Not less than quarter start date',
        };
        setErrors(newErrors);
      } else if (value > endDate) {
        setDeductionDate('');
        setTimeout(() => {
          $('#deductionDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          deductionDate: 'Deduction Not grater than quarter end date',
        };
        setErrors(newErrors);
      } else if (value >= startDate) {
        setDeductionDate(moment(value).format('YYYY-MM-DD'));
        const newErrors = { ...errors, deductionDate: '' };
        setErrors(newErrors);
      }
    } else if (key === 'tdsRateB100') {
      setTdsRateB100(value);
      let tdsAmt = (value / 100) * paidAmount;
      if (tdsAmt !== 0) {
        tdsAmt = tdsAmt.toFixed(2);
      }
      setTdsAmount(tdsAmt);
      const calculationOfTotalTds = Number(tdsAmt) + Number(surchargePaidAmtp) + Number(educationCessAmtp);
      setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
      // setTotalTdsAmount setSurchargePaidAmtp setEducationCessAmtp
      checkUnconsumedValue(calculationOfTotalTds);
    } else if (key === 'paidAmount') {
      setPaidAmount(value);
      if (deducteeTransactionFormInfo.tds_rate_b100 !== 0) {
        let tdsAmt = (tdsRateB100 / 100) * value;
        if (tdsAmt !== 0) {
          tdsAmt = tdsAmt.toFixed(2);
        }
        setTdsAmount(tdsAmt);
        const calculationOfTotalTds = Number(tdsAmt) + Number(surchargePaidAmtp) + Number(educationCessAmtp);
        setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
        checkUnconsumedValue(calculationOfTotalTds);
      } else {
        setTdsAmount(deducteeTransactionFormInfo.tds_amtp);
      }
    } else if (key === 'tdsAmount') {
      setTdsAmount(value);
      const calculationOfTotalTds = Number(value) + Number(surchargePaidAmtp) + Number(educationCessAmtp);
      setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
      checkUnconsumedValue(calculationOfTotalTds);
    } else if (key === 'surchargePaidAmtp') {
      setSurchargePaidAmtp(value);
      const calculationOfTotalTds = Number(tdsAmount) + Number(value) + Number(educationCessAmtp);
      setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
      checkUnconsumedValue(calculationOfTotalTds);
    } else if (key === 'educationCessAmtp') {
      setEducationCessAmtp(value);
      const calculationOfTotalTds = Number(tdsAmount) + Number(surchargePaidAmtp) + Number(value);
      setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
      checkUnconsumedValue(calculationOfTotalTds);
    }


  };

  const handleChalanValueChange = (value) => {
    if (value !== '') {
      if (Object.keys(checkedChallanData).length > 0) {
        if (checkedChallanData[value]) {
          setChallanUnutilizedValue(
            checkedChallanData[value].total_unconsumed_amtp,
          );
        }
      } else {
        setChallanUnutilizedValue(0);
      }
      setChallanInfo(checkedChallanData[value]);
    } else {
      setChallanInfo({});
      setChallanUnutilizedValue(0);
    }
  };

  const handleDeducteeNameChange = (event) => {
    const nameStr = event.target.value;
    async function fetchDeducteeName() {
      const result = await getCorrectionDeducteesAutocomplete(`?deductor_id=${deductorId}&return_id=${returnId}&filter_text=${nameStr}`);
      const DeducteeDataName = [];
      result.data.forEach((deductee) => {
        const panDetail = deductee.pan && deductee.pan !== 'PANAPPLIED' && deductee.pan !== 'PANINVALID' && deductee.pan !== 'PANNOTAVBL' ? deductee.pan : '';
        let deductee_name = deductee.deductee_name;
        if (deductee_name.length > 25) {
          deductee_name = deductee_name.substring(0, 25) + '...';
        }
        DeducteeDataName.push({ id: deductee.deductee_id, text: `${deductee_name} - ${deductee.pan}`, pan: panDetail, as_on_date: deductee.as_on_date, compliance_status: deductee.compliance_status, pan_status: deductee.pan_status });
      });
      setDeducteeData(DeducteeDataName);
      localStorage.setItem('deducteeList', JSON.stringify(DeducteeDataName));
    }

    let filterDeducteeData = deducteeData.filter((data, index) => { return data.text === nameStr; });
    if (nameStr.length !== 0 && nameStr.length > 0 && filterDeducteeData.length === 0) {
      fetchDeducteeName();
    } else {
      const DeducteeDataName = [];
      setDeducteeData(DeducteeDataName);
    }
  };


  $('#deductee_name').change(() => {
    $.each($('#deductee_name_list option'), (index, value) => {
      if (value.value === $('#deductee_name').val()) {
        $('#deductee_id').val(value.dataset.value);
        $('#deductee_id').trigger('click');
      }
    });
  });

  const change24QTaxDeducted = (setFieldValue, paid_amtp, deductee_name, field, tdsAmt) => {
    let filterDeducteeData = deducteeData.filter((data, index) => { return data.text === deductee_name; });
    if (filterDeducteeData.length > 0) {
      if (filterDeducteeData[0].pan === 'PANAPPLIED' || filterDeducteeData[0].pan === 'PANNOTAVBL' || filterDeducteeData[0].pan === 'PANINVALID') {

        setFieldValue('deduction_reason', "C", false);
        onChangeDeductionReason("C", setFieldValue);

        if (headerDataDetails.form_type === '24Q') {
          let newTdsAmt = (20 / 100) * paid_amtp;
          if (field !== 'tds_amtp') {
            setFieldValue('tds_amtp', newTdsAmt, false);
            setTdsAmount(newTdsAmt);
          }
          if (field === 'tds_amtp' && tdsAmt < newTdsAmt) {
            printErrMsg('errorMsgTds', 'TDS amount should be atleast 20% of the Amount Paid');
          }
        }

      }
    }
  }

  const formValueChange = (e, setFieldValue, values, field) => {
    if (field === 'tds_amtp') {
      if (e.target.id === 'TdsRateB100') {
        let tdsAmt = (e.target.value / 100) * paidAmount;
        if (tdsAmt !== 0) {
          tdsAmt = tdsAmt.toFixed(2);
        }
        setFieldValue(field, tdsAmt);
        setTdsAmount(tdsAmt);
        //= ==deductee date=====//

        // deductionDateDiv deduction_date
        if (e.target.value <= 0) {
          setFieldValue('deduction_date', '', false);
          $('#deductionDateDiv input').val('');
        }
      } else if (e.target.id === 'paid_amtp') {

        let tdsAmt = (tdsRateB100 / 100) * e.target.value;
        if (tdsAmt !== 0) {
          tdsAmt = tdsAmt.toFixed(2);
        }
        setFieldValue(field, tdsAmt);
        setTdsAmount(tdsAmt);
        change24QTaxDeducted(setFieldValue, e.target.value, values.deductee_name, 'paid_amtp');
      }
    }
  };

  const formValueChange2 = (values, setFieldValue, field) => {
    if (field === 'tds_amtp') {
      let tdsAmt = (values / 100) * paidAmount;
      if (parseInt(tdsAmt) !== 0) {
        tdsAmt = tdsAmt.toFixed(2);
      }
      setFieldValue(field, tdsAmt, false);
    }
  };

  const mapChallanValChange = (e, setFieldValue) => {
    if (e.target.value === '0') {
      setFieldValue('challan_id', '', false);
      setChallanVals('');
      setChallanUnutilizedValue('0');
      setMapChallanValChecked('0');
      setSubmitDisable(false);
    } else {
      setMapChallanValChecked('1');
    }
    setFieldValue('mapChallanVal', e.target.value, false);
  };

  useEffect(() => {

    if (headerDataDetails.form_type === '27EQ') {
      setShow27EQExtraFields(true);
    }

    const calculationOfTotalTds = Number(tdsAmount) + Number(surchargePaidAmtp) + Number(educationCessAmtp);
    setTotalTdsAmount(calculationOfTotalTds);
    checkUnconsumedValue(calculationOfTotalTds);

    $(window).keydown((e) => {
      if (e.altKey && !e.shiftKey && parseInt(e.keyCode) === 83) {
        /* alt+s */
        e.preventDefault();
        $('#save-deductee-trn').trigger('click');
        $('#save-deductee-trn').attr('disabled', true);
      }

      if (e.altKey && e.shiftKey && parseInt(e.keyCode) === 83) {
        /* alt+s */
        e.preventDefault();
        $('#save-deductee-trn-new').trigger('click');
        $('#save-deductee-trn-new').attr('disabled', true);
      }
    });

    setMapChallanDisable(true);
    setMapChallan(true);
    deducteeTransactionFormInfo.mapChallanVal = '1';

    let deduction_reason = deducteeTransactionFormInfo.deduction_reason;
    if (deduction_reason === 'F' || deduction_reason === 'G') {
      setLiableFieldDisable(false);
    } else {
      setLiableFieldDisable(true);
    }
  }, [challanInfo]);

  useEffect(() => {
    let reason_id = deducteeTransactionFormInfo.deduction_reason;
    let selected_reason = getReasonList(headerDataDetails).filter((reason, index) => { return reason.id === reason_id; });
    if (selected_reason.length > 0) {
      setCertificateEnable(selected_reason[0].certificate_applicable);
    } else if (deducteeTransactionFormInfo.lower_deduction_certificate_no !== '') {
      setCertificateEnable(true);
    } else {
      setCertificateEnable(false);
    }
  }, [])

  const onChangeDeductionReasonForSpecific = (reason_id, nature_of_payment_id) => {
    const reasonPErrMsg = 'Reason P is applicable only for Section 194 and 195.'
    const reasonQErrMsg = 'Reason Q is applicable only for Section 194A.'
    const reasonUErrMsg = 'Reason U is not applicable for the selected Section.'
    if (reason_id === 'P' && nature_of_payment_id !== 2 && nature_of_payment_id !== 71) {
      printErrMsg('errorMsgSection', reasonPErrMsg);
      setSectionErrorTitleMsg(reasonPErrMsg);
    } else if (reason_id === 'Q' && nature_of_payment_id !== 25) {
      printErrMsg('errorMsgSection', reasonQErrMsg);
      setSectionErrorTitleMsg(reasonQErrMsg);
    } else if (reason_id === 'U' && (nature_of_payment_id === 5 || nature_of_payment_id === 26 || nature_of_payment_id === 36 || nature_of_payment_id === 41 || nature_of_payment_id === 46 || nature_of_payment_id === 53)) {
      printErrMsg('errorMsgSection', reasonUErrMsg);
      setSectionErrorTitleMsg(reasonUErrMsg);
    } else {
      printErrMsg('errorMsgSection', '');
      setSectionErrorTitleMsg('');
    }
  }


  const onChangeDeductionReason = (reason_id, setFieldValue) => {

    let selected_reason = getReasonList(headerDataDetails).filter((reason, index) => { return reason.id === reason_id; });
    if (selected_reason.length > 0) {
      setCertificateEnable(selected_reason[0].certificate_applicable);
      if (selected_reason[0].certificate_applicable === false) {
        setFieldValue('lower_deduction_certificate_no', '');
      }
    }
    else {
      setCertificateEnable(false);
      setFieldValue('lower_deduction_certificate_no', '');
    }

    let financial_year_formatted = headerDataDetails.financial_year_formatted;
    var getFYFromHeader = financial_year_formatted.split('-');

    if (headerDataDetails.form_type === '27EQ' &&
      (reason_id === 'F' || reason_id === 'G') &&
      ((parseInt(getFYFromHeader[0]) === 2020 && (headerDataDetails.quarter === 'Q3' || headerDataDetails.quarter === 'Q4')) ||
        parseInt(getFYFromHeader[0]) >= 2021)) {
      setLiableFieldDisable(false);
      setFieldValue('tds_liable', '0');
    } else {
      setLiableFieldDisable(true);
      setFieldValue('tds_liable', '');
      liableHandleChange('0', setFieldValue);
    }

    if (headerDataDetails.form_type === '27EQ' && reason_id === 'Y') {
      setTdsAmount(() => 0);
    }

  }

  const selectedDeducteeName = (value, setFieldValue, values) => {
    let filterDeducteeData = deducteeData.filter((data, index) => { return data.text === value; });
    if (filterDeducteeData.length > 0) {
      setFieldValue('deductee_id', filterDeducteeData[0].id, false);
      setFieldValue('deductee_pan', filterDeducteeData[0].pan, false);
      setFieldValue('deductee_name', value, false);
      values.deductee_pan = filterDeducteeData[0].pan;
      tdsRateCalculation(values, value, 'deductee_name', setFieldValue);
      //setDeducteeData([]);
      change24QTaxDeducted(setFieldValue, values.paid_amtp, value, 'deductee_name');

    }
  };

  function tdsRateCalculation(values, value, field, setFieldValue, compliance_status = null) {
    //const panCategory = panDetailCat; 
    values[field] = value;
    let nature_of_payment_id = values.nature_of_payment_id;
    let paymentDate = values.payment_date;
    let deductionDate = values.deduction_date;
    let payment_date = paymentDate.split('-').reverse().join('-');
    let deduction_date = deductionDate.split('-').reverse().join('-');
    let deductee_name = values.deductee_name;
    let deductee_pan = values.deductee_pan;
    let deduction_reason = values.deduction_reason;
    let applicable_tds_rate = values.applicable_tds_rate;

    try {
      if (parseInt(nature_of_payment_id) === 53 || parseInt(nature_of_payment_id) === 82) {
        setSubmitEnableFor194NF(false);
        // setSubmitDisable(true);
      } else {
        setSubmitEnableFor194NF(true);
        setFieldValue('cash_withdrawal_option', '');
        // setSubmitDisable(false);
      }

      if (parseInt(nature_of_payment_id) !== 36 && parseInt(nature_of_payment_id) !== 53 && parseInt(nature_of_payment_id) !== 82 && parseInt(nature_of_payment_id) !== 82) {
        setFieldValue('cash_withdrawal_amtp', '0');
        values.cash_withdrawal_amtp = 0;
      }
    } catch (e) {
      console.warn("warning in checking nature id");
    }

    if (Object.keys(paymentCodes).length > 0) {
      let tax_rates = setTDSRateAuto(nature_of_payment_id, payment_date, deduction_date, setFieldValue, deductee_name, deductee_pan, deduction_reason, applicable_tds_rate, compliance_status);
      if (parseInt(nature_of_payment_id) === 36 || parseInt(nature_of_payment_id) === 53) {
        excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', tax_rates);
      }
    }
  }

  function getDeducteeCategory(val) {
    const panCheck = val.charAt(3);
    if (panCheck === 'C') {
      return 'K';
    } else if (panCheck === 'P') {
      return 'Q';
    } else if (panCheck === 'H') {
      return 'Q';
    } else if (panCheck === 'F') {
      return 'F';
    } else if (panCheck === 'A') {
      return 'P';
    } else if (panCheck === 'T') {
      return 'T';
    } else if (panCheck === 'B') {
      return 'B';
    } else if (panCheck === 'L') {
      return 'L';
    } else if (panCheck === 'J') {
      return 'J';
    } else if (panCheck === 'G') {
      return 'A';
    } else {
      return null;
    }
  }

  function setTDSRateAuto(nature_of_payment_id, payment_date, deduction_date, setFieldValue, deductee_name, deductee_pan, deduction_reason, applicable_tds_rate, compliance_status) {





    let regexPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    deductee_pan = regexPan.test(deductee_pan) ? deductee_pan : ""

    if ((headerDataDetails && headerDataDetails.form_type !== '27EQ')) {
      printErrMsg('errorMsgSurcharge', '');
      printErrMsg('errorMsgCess', '');
      printErrMsg('errorMsgTds', '');
      printErrMsg('cashWithdrawErr', '');
      printErrMsg('cashWithdrawErrs', '');
    }

    payment_date = parseInt(moment(payment_date, 'DD/MM/YYYY', true).unix());
    deduction_date = parseInt(moment(deduction_date, 'DD/MM/YYYY', true).unix());
    const startDate = parseInt(moment('2020-05-14').unix());
    const endDate = parseInt(moment('2021-03-31').unix());
    //let paymentCode = '';

    setPaymentDateCheck(payment_date);

    if (!nature_of_payment_id) {
      setRateValue(setFieldValue, 0, 0);
      return;
    }

    /*Object.keys(paymentCodes).map((key, index) => {
      if (parseInt(paymentCodes[key].nature_of_payment_id) === parseInt(nature_of_payment_id)) {
        paymentCode = paymentCodes[key].code;
      }
    });*/

    if (headerDataDetails.form_type === '24Q') {
      return;
    }

    if (headerDataDetails.form_type === '26Q') {
      const deductee_category = deductee_pan ? getDeducteeCategory(deductee_pan) : null;
      let tax_rates = calculation26QTaxRate(deductee_pan, deductee_category, payment_date, deduction_date, startDate, endDate, nature_of_payment_id, deduction_reason, compliance_status, headerDataDetails)
      setRateValue(setFieldValue, tax_rates, nature_of_payment_id);
      return tax_rates;
    }

    if (headerDataDetails.form_type === '27EQ') {
      let tax_rates = calculation27EQTaxRate(deductee_pan, payment_date, deduction_date, startDate, endDate, nature_of_payment_id, deduction_reason, compliance_status, headerDataDetails)
      setRateValue(setFieldValue, tax_rates, nature_of_payment_id);
      return tax_rates;
    }
    if (headerDataDetails.form_type === '27Q') {
      const deductee_category = deductee_pan ? getDeducteeCategory(deductee_pan) : null;
      let tax_rates = calculation27QTaxRate(deductee_pan, deductee_category, payment_date, deduction_date, startDate, endDate, nature_of_payment_id, deduction_reason, applicable_tds_rate)
      if (deduction_reason === 'Y') {
        tax_rates = 0;
      }
      setRateValue(setFieldValue, tax_rates, nature_of_payment_id);
      return tax_rates;
    }


  }


  function setRateValue(setFieldValue, tdsRateSet, nature_of_payment_id) {
    try {
      if (tdsRateSet === undefined) {
        tdsRateSet = 0;
        setNatureOfPaymentIDVal('');
      }
      setFieldValue('tds_rate_b100', tdsRateSet);
      handleOnChange('tdsRateB100', tdsRateSet);
      formValueChange2(tdsRateSet, setFieldValue, 'tds_amtp');
      if (parseInt(nature_of_payment_id) !== 53) {
        setFieldValue('cash_withdrawal_option', '');
      }
      // setFieldValue("cash_withdrawal_option_two", "");
    } catch (e) {
      console.warn('');
    }
  }

  function validateReasonSection(values, setFieldValue, fromErr) {
    const paidAmtp = parseInt(values.paid_amtp);
    const tdsAmtp = parseInt(values.tds_amtp);
    const { nature_of_payment_id } = values;
    const reason = document.getElementById('deduction_reason').value;
    const msg = 'For Section (194D, 194DA, 194I(a), 194I(b), 192A, 194, 194A, 194EE, 194K) and Reason of Deduction (B), TDS Rate, TDS Amount, Surcharge Amount & Health and Education Cess Amount cannot be greater than 0.';

    if (
      reason === 'B'
      && (parseInt(nature_of_payment_id) === 1
        || parseInt(nature_of_payment_id) === 2
        || parseInt(nature_of_payment_id) === 6
        || parseInt(nature_of_payment_id) === 7
        || parseInt(nature_of_payment_id) === 23
        || parseInt(nature_of_payment_id) === 25
        || parseInt(nature_of_payment_id) === 28
        || parseInt(nature_of_payment_id) === 33
        || parseInt(nature_of_payment_id) === 35
        || parseInt(nature_of_payment_id) === 48)
    ) {
      setFieldValue('tds_amtp', '0');
      handleOnChange('tdsAmount', '0');
      setFieldValue('education_cess_amtp', '0');
      handleOnChange('educationCessAmtp', '0');
      setFieldValue('surcharge_paid_amtp', '0');
      handleOnChange('surchargePaidAmtp', '0');
      switch (fromErr) {
        case 'errorMsgTds': {
          printErrMsg('errorMsgTds', msg);
          printErrMsg('errorMsgSurcharge', '');
          printErrMsg('errorMsgCess', '');
          return;
        }
        case 'errorMsgSurcharge': {
          printErrMsg('errorMsgSurcharge', msg);
          printErrMsg('errorMsgTds', '');
          printErrMsg('errorMsgCess', '');
          return;
        }
        case 'errorMsgCess': {
          printErrMsg('errorMsgCess', msg);
          printErrMsg('errorMsgTds', '');
          printErrMsg('errorMsgSurcharge', '');
          return;
        }
        default: {
          return;
        }
      }
    }

    if (tdsAmtp > paidAmtp && nature_of_payment_id !== '36' && nature_of_payment_id !== '53') {
      if (paidAmtp === 0 && tdsAmtp > 0) {
        return false
      } else {
        printErrMsg('errorMsgTds', 'TDS Amount cannot be greater than Amount Paid');
      }
    } else {
      printErrMsg('errorMsgTds', '');
    }
  }

  function printErrMsg(errID, msg) {
    if (errID && document.getElementById(errID)) {
      document.getElementById(errID).setAttribute("title", msg);
      document.getElementById(errID).innerHTML = msg;
    }
  }

  function excessWithdrawTdsCalculation(values, setFieldValue, field, value) {
    //const paidAmtp = parseInt(values.paid_amtp);
    values[field] = value;
    const { nature_of_payment_id, cash_withdrawal_amtp, surcharge_paid_amtp, education_cess_amtp } = values;
    const tdsRate = values.tds_rate_b100;

    if ([36, 53, 81, 82].includes(parseInt(nature_of_payment_id))) {
      const tdsAmount = ((tdsRate / 100) * cash_withdrawal_amtp);
      setTdsAmount(parseFloat(tdsAmount).toFixed(2));
      setFieldValue('tds_amtp', parseFloat(tdsAmount).toFixed(2));
      setTdsAmount(parseFloat(tdsAmount).toFixed(2));

      const calculationOfTotalTds = Number(tdsAmount)
        + Number(surcharge_paid_amtp)
        + Number(education_cess_amtp);
      setTotalTdsAmount(parseFloat(calculationOfTotalTds).toFixed(2));
      checkUnconsumedValue(parseFloat(calculationOfTotalTds).toFixed(2));
    }
  }

  function setExcessTdsRate(values, setFieldValue) {
    const cash_withdrawal_option = document.getElementById('cash_withdrawal_option').value;
    if (cash_withdrawal_option === '20L-1CR') {
      setSubmitEnableFor194NF(false);
      setSubmitDisable(true);
      setFieldValue('tds_rate_b100', '2');
      setFieldValue('tds_amtp', '0');
      setTdsRateB100("2");
      excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', 2);
    } else if (cash_withdrawal_option === '1CR-ABOVE') {
      setSubmitEnableFor194NF(false);
      setSubmitDisable(true);
      setFieldValue('tds_rate_b100', '5');
      setFieldValue('tds_amtp', '0');
      setTdsRateB100("5");
      excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', 5);
    } else if (cash_withdrawal_option === '20L-3CR-COOP') {
      setSubmitEnableFor194NF(false);
      setSubmitDisable(true);
      setFieldValue('tds_rate_b100', '2');
      setFieldValue('tds_amtp', '0');
      setTdsRateB100("2");
      excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', 2);
    } else if (cash_withdrawal_option === '3CR-ABOVE-COOP') {
      setSubmitEnableFor194NF(false);
      setSubmitDisable(true);
      setFieldValue('tds_rate_b100', '5');
      setFieldValue('tds_amtp', '0');
      setTdsRateB100("5");
      excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', 5);
    }
  }

  function dateValidation(values, payment_date) {
    const { nature_of_payment_id } = values;
    const { cash_withdrawal_amtp } = values;
    const { cash_withdrawal_option } = values;
    if (parseInt(nature_of_payment_id) === 53) {
      if (cash_withdrawal_amtp <= 0 || cash_withdrawal_option === '') {
        printErrMsg('cashWithdrawErr', 'Excess of cash is required');
        printErrMsg('cashWithdrawErrs', 'Excess of cash is required');
        return false;
      } else {
        printErrMsg('errorExcess', '');
        printErrMsg('cashWithdrawErr', '');
        printErrMsg('cashWithdrawErrs', '');
        return true;
      }
    } else if (parseInt(nature_of_payment_id) === 36) {
      if (cash_withdrawal_amtp <= 0) {
        return false;
      } else {
        printErrMsg('errorExcess', '');
        return true;
      }
    }

    // if (cash_withdrawal_option === '') {
    //   printErrMsg('cashWithdrawErr', 'Excess of cash is required');
    //   printErrMsg('cashWithdrawErrs', 'Excess of cash is required');
    //   return false;
    // }
    // printErrMsg('cashWithdrawErr', '');
    // printErrMsg('cashWithdrawErrs', '');

    return true;
  }

  const autoPopulateDeductionDate = (date, setFieldValue, values) => {
    if (moment(date, 'DD/MM/YYYY', true).isValid() && autoChangeCall) {
      let quarter_start_date = moment(headerDataDetails.quarter_start_date).unix();
      let payment_date = moment(date, "DD/MM/YYYY").unix();
      if (payment_date >= quarter_start_date) {
        setFieldValue('deduction_date', date);
        values.deduction_date = date;
        $('#deductionDateDiv').find('input').val(date);
      }
    }
    tdsRateCalculation(values, date, 'payment_date', setFieldValue);
  };

  const liableHandleChange = (value, setFieldValue) => {
    if (value === '0' || value === '') {
      setFieldValue('tds_challan_no', '');
      setFieldValue('tds_payment_date', '');
      $('#tdsPaymentDate').find('input').val('');
    }
  };

  const listNoDataRender = (element, setFieldValue, values) => {
    const noData = (
      <span>
        <h4 style={{ fontSize: '1em' }} onMouseDown={(e) => e.stopPropagation()}>
          <span className="k-icon k-i-warning" style={{ fontSize: '1.6em', top: '0px', paddingBottom: '3px' }} />
          <div className=''>No data found</div>
          <hr style={{ margin: '4px 0px  0px 0px' }} />
          <span
            onClick={() => { showform(setFieldValue, values) }}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            className="btn btn-default" style={{ padding: '0px', color: '#31719b', backgroundColor: 'none', border: 'none !important', textTransform: 'capitalize' }}>
            <span className='addnewded_icon'>
              <img src={Addbtnround} width={16} alt='' />
              <span className='addnewded_textfs pl-2'>Add new deductee</span>
            </span>

          </span>
        </h4>
      </span>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  }

  const itemRender = (li, itemProps, setFieldValue, values) => {
    const index = itemProps.index;

    const itemChildren = (
      <span>
        <span style={{ color: "#00F" }}>{li.props.children}</span><br /><br /> <br />
        {index + 1 === deducteeData.length && (
          <>
            <li className="k-button addinnerbtnbgclr" onClick={() => { showform(setFieldValue, values); }}
              style={{ textAlign: 'center' }}>
              <span className='addnewded_icon pr-2'>
                <img src={Addbtnround} width={16} alt='' />
              </span>
              <span className='addnewded_textfs'>Add new deductee</span>
            </li>
          </>
        )
        }
      </span>
    )

    return React.cloneElement(li, li.props, itemChildren);

  }

  const showform = (setFieldValue, values) => {
    setTimeout(function () {
      values.deductee_name = '';
      values.deductee_id = '';
      setFieldValue('deductee_name', '');
      setFieldValue('deductee_id', '');
      setShowCreateForm(true);
    });
  }

  const HandleClose = (setFieldValue, values) => {
    let NewAddedDeductee = JSON.parse(localStorage.getItem('getNewAddedDeductee'));
    if (NewAddedDeductee) {
      let getDeducteeAllData = NewAddedDeductee[0];
      let getDeducteeID = NewAddedDeductee[1];
      values.deductee_id = getDeducteeID.deductee_id;
      values.deductee_name = `${getDeducteeAllData.deductee_name} - ${getDeducteeAllData.pan}`;
      setFieldValue('deductee_id', getDeducteeID.deductee_id);
      setFieldValue('deductee_name', `${getDeducteeAllData.deductee_name} - ${getDeducteeAllData.pan}`);
    }

    setShowCreateForm(false);
    localStorage.removeItem('getNewAddedDeductee');
    setTimeout(function () {
      $('#deductee_name').focus();
    });
  }


  const prevDeductee = () => {
    let selectPage = 0;
    if (currentDeductee > 0) {
      selectPage = currentDeductee - 1;
    }
    if (currentDeductee !== selectPage) {
      setCurrentDeductee(selectPage);
      updateDeducteeInfos(deducteeTransactionsList[selectPage], horizontalView);
    }
    else {
      onClose();
    }
  }

  const nextDeductee = () => {
    const selectPage = currentDeductee + 1;
    if (currentDeductee !== selectPage && selectPage < deducteeTransactionsList.length) {
      setCurrentDeductee(selectPage);
      updateDeducteeInfos(deducteeTransactionsList[selectPage], horizontalView);
    }
    else {
      onClose();
    }
  }


  const handleMarkNill = (setFieldValue) => {
    setFieldValue('tds_amtp', 0);
    setFieldValue('paid_amtp', 0);
    setFieldValue('tds_rate_b100', 0);
    setFieldValue('deduction_date', '');
    setFieldValue('surcharge_paid_amtp', 0);
    setFieldValue('education_cess_amtp', 0);
    setFieldValue('purchase_amtp', 0);
    setFieldValue('cash_withdrawal_amtp', 0);
    setFieldValue('cash_withdrawal_option', '');
    setFieldValue('mark_nil', true);
    setTdsRateB100(0);
    setPaidAmount(0);
    setTdsAmount(0);
    $('#deductionDateDiv').find('input').val('');
  }







  const getComplianceStatusForPan = (pan) => {
    setPan((prev) => {
      prev = pan
      return prev
    })
    setSection((prev) => {
      prev = 'COMPLIANCE_CHECK'
      return prev
    })
    setStartConnector(true);
  }

  const panStatuscolorMapping = (status) => {
    if (status === null || status === '' || status === undefined) return;
    if (status.toLowerCase().includes('active')) {
      setPanStatusColor('22c91d');
    } else {
      setPanStatusColor('ff0000');
    }
  }

  const getPanStatus = (values, value, form_type, setFieldValue) => {
    const panData = value.split("-");
    const selectedPan = (panData?.[panData.length - 1] ?? '').trim();
    //eslint-disable-next-line
    let compliance_status = '';
    if (selectedPan !== '') {
      if (deducteeData.length > 0) {
        let selectedItem = null;
        deducteeData.forEach((item) => {
          if (item.pan === selectedPan) {
            selectedItem = item;
            setPanStatus((prev) => {
              prev = item?.pan_status?.text
              return prev
            });
            setPanStatusColor((prev) => {
              prev = panStatuscolorMapping(item?.pan_status?.text)
              return prev
            })
            compliance_status = item?.compliance_status;
            setPanComplianceStatus((prev) => {
              prev = item?.compliance_status
              return prev
            })
            setPanAsOnDate((prev) => {
              prev = item?.as_on_date
              return prev
            })
          }
        })

        if (form_type === '27EQ' && selectedItem?.compliance_status === 'NON FILER') {
          setTimeout(() => {
            setFieldValue('deduction_reason', 'I');
            tdsRateCalculation(values, 'I', 'deduction_reason', setFieldValue, selectedItem?.compliance_status);
          }, 100)
        }
        if (form_type === '26Q' && selectedItem?.compliance_status === 'NON FILER') {
          setTimeout(() => {
            setFieldValue('deduction_reason', 'U');
            tdsRateCalculation(values, 'U', 'deduction_reason', setFieldValue, selectedItem?.compliance_status);
          }, 100)

        }
      }
    }
  }


  const getVerifyStatusForPan = (pan) => {
    setPan((prev) => {
      prev = pan
      return prev
    })
    setSection((prev) => {
      prev = 'VERIFY_PAN'
      return prev
    })
    setStartConnector(true);
  }


  const getDefaultLinksHtml = (values) => {
    if (values.deductee_pan === '' || values.deductee_pan === null) return (null);
    return (
      <>
        <div className={'row'} style={{ margin: '35px 0px 10px 0px', textAlign: 'left' }}>
          <div class="updatedeductee_trans_compst_bgclr">
            <div class="row">
              <div class="col-md-12 text-right">
                <span class="reverifypan-textclr" style={{ cursor: 'pointer' }} onClick={() => {
                  getComplianceStatusForPan(values.deductee_pan)
                }}>
                  Get Compliance Status
                </span>
                <span class="px-2 lineclr_grey">|</span>
                <span class="reverifypan-textclr" style={{ cursor: 'pointer' }} onClick={(e) => {
                  getVerifyStatusForPan(values.deductee_pan)
                }}>
                  Verify PAN from TRACES
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  const getHtml1 = (form_type, values, panStatus, panComplianceStatus) => {
    //if(form_type === '24Q' || panComplianceStatus === null) return(null);
    if (form_type === '24Q') return (null);
    return (
      <>
        <div className={'row'} style={{ margin: '3px 0px 0px 0px', textAlign: 'left', height: '36px' }}>
          <div class="updatedeductee_trans_compst_bgclr">
            <div class="row">
              <div class="col-md-12 text-right">
                {
                  (panComplianceStatus !== null && panComplianceStatus !== undefined)
                    ?
                    <>
                      <div className={'row'} style={{ margin: '0px 0px 0px 0px', textAlign: 'left', height: '36px' }}>
                        <div class="updatedeductee_trans_compst_bgclr">
                          <div class="row">
                            <div class="col-md-12">
                              <span class="status-textfs">
                                Status:
                              </span>
                              <span class="nonflier-textclr pl-2 pr-1" style={{ color: panComplianceStatus.includes('REGULAR') ? '#22c91d' : '' }}>
                                {panComplianceStatus} {panAsOnDate !== '' ? `(as on ${panAsOnDate})` : null}
                              </span>
                              <span class="getstatus-textclr" style={{ cursor: 'pointer' }} onClick={() => {
                                getComplianceStatusForPan(values.deductee_pan)
                              }}>
                                Get Updated Status
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <div className={'row'} style={{ margin: '0px 0px 0px 0px', textAlign: 'left', height: '36px' }}>
                      <div class="updatedeductee_trans_compst_bgclr">
                        <div class="row">
                          <div class="col-md-12">
                            <span class="getstatus-textclr" style={{ cursor: 'pointer' }} onClick={() => {
                              getComplianceStatusForPan(values.deductee_pan)
                            }}>
                              Get Compliance Status
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  const getHtml2 = (form_type, values, panStatus, panComplianceStatus) => {
    //if(form_type === '24Q' || panStatus === undefined || panStatus === null || panStatus === '') return;
    if (form_type === '24Q') return;
    if (panComplianceStatus === null && (panStatus === null || panStatus === undefined || panStatus === '')) {
      return getDefaultLinksHtml(values);
    }
    panStatuscolorMapping(panStatus);
    return (
      <>
        <div className={'row'} style={{ margin: '35px 0px 10px 0px', textAlign: 'left' }}>
          <div class="updatedeductee_trans_compst_bgclr">
            <div class="row">
              <div class="col-md-12">
                <span class="status-textfs pr-2">Status:</span>
                <span class="pr-2" style={{ color: `#${panStatusColor}` }}>
                  {panStatus}
                </span>
                <span class="reverifypan-textclr" style={{ cursor: 'pointer' }} onClick={(e) => {
                  getVerifyStatusForPan(values.deductee_pan)
                }}>
                  Verify PAN from TRACES
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }


  const getNatureOfPaymentOptionLabelById = (nature_of_payment_id) => {
    if (nature_of_payment_id === '') return null;
    let natureOfPaymentText = null;
    paymentCodesArray.forEach((item) => {
      if (parseInt(item.nature_of_payment_id) === parseInt(nature_of_payment_id) && item.description.length > 80) {
        natureOfPaymentText = item.description
      }
    })
    return natureOfPaymentText;
  }

  return (
    <>
      {loading ? <LoadingIndicator /> : null}

      {startConnector
        ? (<ConnectorAdapter
          show={startConnector}
          handleClose={() => setStartConnector(false)}
          setPort={setPort}
          handleProceed={() => {
            if (section === 'VERIFY_PAN') {
              setCheckTracesCredentials(true)
            } else if (section === 'COMPLIANCE_CHECK') {
              setComplianceCheck(true)
            }
          }}
        />)
        : null}

      {checkTracesCredentials
        ? <TracesConnector
          deductorId={deductorId}
          returnId={null}
          setCaptchaRef={setCaptchaRef}
          handleProceed={(res) => {
            setCheckTracesCredentials(false);
            setVerifySinglePan(true);
          }}
          port={port}
          setVerifyCreds={() => null}
          creds={null}
        />
        : null
      }
      {verifySinglePan && captchaRef && port
        ? <SinglePanVerify
          deductorId={deductorId}
          port={port}
          captchaRef={captchaRef}
          setError={setErrors}
          setVerifyPANResult={setVerifyPANResult}
          pan={pan}
          setLoading={setLoading}
          setTracesPanData={(res) => {
            const status = res.status;
            setPanStatus(status);
            if (status && status.toLowerCase() === 'active') {
              setPanStatusColor('22c91d');
            } else if (status && (status.toLowerCase() === 'invalid' || status.toLowerCase() === 'not found')) {
              setPanStatusColor('ff0000');
            }
          }}
          handleProceed={() => null}
        />
        : null
      }

      {
        complianceCheck && port
          ?
          <InsightsConnectorWithCaptcha
            deductorId={deductorId}
            returnId={null}
            port={port}
            pan={pan}
            handleProceed={(res) => {
              if (res?.data && res.data.length > 0) {
                const statusData = res.data;
                statusData.forEach(item => {
                  if (item.panAadhaarLinkStatus !== '') {
                    const status = item.aplicableFlag;
                    setPanComplianceStatus((prev) => {
                      prev = (status === 'N') ? 'REGULAR FILER' : ' NON FILER'
                      return prev
                    })
                  }
                })
              }
              setPanAsOnDate((prev) => {
                prev = res.lastUpdateDate
                return prev
              })
              setComplianceCheck(false)
            }}
            setInsightsCaptchaRef={setInsightsCaptchaRef}
            setVerifyCreds={() => null}
            creds={null}
            setParentLoading={setLoading}
            captchaVerify={true}
            handleClose={() => {
              //reset all values to start again
              setStartConnector(false);
              setCheckTracesCredentials(false);
              setVerifySinglePan(false);
              setComplianceCheck(false);

            }}
          />
          : null
      }

      <div className="deductee-overlay" />
      <div
        className={
          horizontalView
            ? 'popup popup-horizontal deductee-trans-popup'
            : 'popup popup-vertical vpopuphight deductee-trans-popup'
        }
        id="create-deductor"
      >
        <div
          className="col-md-12 col-sm-12 col-lg-12 col-xl-12 deductee-form"
          style={{ padding: '0px' }}
        >
          <div className="float_card" style={{ borderBottom: '0px' }}>
            <Formik
              innerRef={formikRef}
              initialValues={deducteeTransactionFormInfo}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={correctionDeducteeTransactionsFormValidationSchema}
              onSubmit={(values) => {
                // same shape as initial values
                // console.log(values);
                // createDeducteeTransactions(values);
              }}
            >
              {({
                errors,
                values,
                validateForm,
                setErrors,
                resetForm,
                setFieldValue,
              }) => {
                const submitAndResetForm = () => {

                  setCustomError(() => null);
                  if (
                    [76, 77, 78, 80].includes(Number(values?.nature_of_payment_id))
                    &&
                    (
                      String(values?.deductee_name).indexOf('PANNOTAVBL') !== -1 ||
                      String(values?.deductee_name).indexOf('PANINVALID') !== -1 ||
                      String(values?.deductee_name).indexOf('PANAPPLIED') !== -1
                    )
                  ) {
                    setCustomError((prev) => {
                      prev = 'Deductee should have a valid PAN. Default PAN values, such as, PANNOTAVBL, PANAPPLIED, PANINVALID are not allowed.'
                      return prev
                    });
                    return false;
                  }

                  validateForm().then(async (data) => {
                    // console.log('validation result: ', data);
                    if (Object.keys(data).length > 0) {
                      setErrors(data);
                    } else {
                      let payment_date = values.payment_date;
                      let deduction_date = values.deduction_date;
                      const result = await createDeducteeTransactions(
                        values,
                        false,
                      );
                      if (result) {
                        resetForm(deducteeTransactionsFormFields);
                        setFieldValue('payment_date', '', false);
                        setFieldValue('tds_rate_b100', '0', false);
                        setFieldValue('payment_date', payment_date, false);
                        setFieldValue('deduction_date', deduction_date, false);
                        setFieldValue('challan_id ', '', false);
                        setFieldValue('tds_amtp ', 0, false);
                        setTdsRateB100(0);
                        setTdsAmount(0);
                        setChallanVals('');
                        setNatureOfPaymentIDVal('');
                        //$('#paymentDateDiv').find('input').val('');
                        // $('#deductionDateDiv').find('input').val('');
                        setTotalTdsAmount('0');
                        setChallanUnutilizedValue('0');
                        updatePopUpSaveAlt();
                        setMapChallan(true);
                        setTimeout(function () {
                          $('#nature_of_payment_id').focus();
                          $('.deductee-form').scrollTop(0);
                        });
                      }
                    }
                    $('#save-deductee-trn-new').attr('disabled', false);
                    $('#save-deductee-trn').attr('disabled', false);
                  });
                };
                const submitForm = (values, payment_date) => {
                  if ((tdsAmount && tdsAmount !== undefined) || (tdsRateB100 && tdsRateB100 !== undefined)) {
                    values.tds_amtp = tdsAmount;
                    values.tds_rate_b100 = tdsRateB100;
                  }
                  values.challan_id = challanVals;
                  const dateVal = dateValidation(values, payment_date);

                  setCustomError(() => null);
                  if (
                    [76, 77, 78, 80].includes(Number(values?.nature_of_payment_id))
                    &&
                    (
                      String(values?.deductee_name).indexOf('PANNOTAVBL') !== -1 ||
                      String(values?.deductee_name).indexOf('PANINVALID') !== -1 ||
                      String(values?.deductee_name).indexOf('PANAPPLIED') !== -1
                    )
                  ) {
                    setCustomError((prev) => {
                      prev = 'Deductee should have a valid PAN. Default PAN values, such as, PANNOTAVBL, PANAPPLIED, PANINVALID are not allowed.'
                      return prev
                    });
                    return false;
                  }

                  if (dateVal) {
                    validateForm().then(async (data) => {

                      if (Object.keys(data).length > 0) {
                        setErrors(data);
                      } else {
                        await createDeducteeTransactions(
                          values,
                          true,
                        );
                      }
                      $('#save-deductee-trn-new').attr('disabled', false);
                      $('#save-deductee-trn').attr('disabled', false);

                    });
                  }
                };
                return (<>
                  {showCreateForm
                    ? (
                      <AddDeductee
                        onClose={() => HandleClose(setFieldValue, values)}
                        deductorId={deductorId}
                        showDeducteeFormLeft={true}
                        componentImport={true}
                      />
                    )
                    : null}
                  <Form key={1} autoComplete="off">
                    <ErrorFocus />
                    <div className="sticky_top">
                      <div className="popup-header">
                        <div className="row">
                          <div className="col-md-8 col-sm-8 col-lg-8 col-xl-8">
                            {' '}
                            {horizontalView ? (
                              <div
                                className="col-md-12 col-sm-12 col-lg-12 col-xl-12"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <h6 style={{ cursor: 'default' }}>
                                  {deducteeTransactionID && <span style={{ cursor: 'pointer', color: '#0000ff' }} onClick={() => prevDeductee()} className="pr-1">&lt;</span>}
                                  {deducteeTransactionID ? 'Update' : 'Create'}
                                  {' '}
                                  Deductee Transaction
                                  {deducteeTransactionID && <span style={{ cursor: 'pointer', color: '#0000ff' }} onClick={() => nextDeductee()} className="pl-1">&gt;</span>}
                                </h6>
                              </div>
                            ) : (
                              <h6 style={{ fontSize: '13px', cursor: 'default' }}>
                                {deducteeTransactionID && <span style={{ cursor: 'pointer', color: '#0000ff' }} onClick={() => prevDeductee()} className="pr-1">&lt;</span>}
                                {deducteeTransactionID ? 'Update' : 'Create'}
                                {' '}
                                Deductee Transaction
                                {deducteeTransactionID && <span style={{ cursor: 'pointer', color: '#0000ff' }} onClick={() => nextDeductee()} className="pl-1">&gt;</span>}
                              </h6>
                            )}
                            {' '}
                          </div>
                          <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3 unconsumedAmtDiv">
                            {' '}
                            <span>
                              Unconsumed Amount
                              {' '}
                              <br />
                              <abbr className="unconsumedAmtValue ruppboxicon">
                                {' '}
                                ₹
                                {' '}
                                {challanUnutilizedValue}
                              </abbr>
                            </span>
                            {' '}
                          </div>
                          {/* <div
                            className="col-md-3 col-sm-3 col-lg-3 col-xl-3 view_btn"
                            style={{ paddingTop: '5px' }}
                          >
                            <Button
                              variant="link"
                              style={{
                                margin: '-8px',
                                padding: '5px 0px',
                                fontSize: '12px',
                                float: 'right',
                              }}
                              onClick={() => toggleHorizotalView()}
                              className="view_btn dark_color_text"
                            >
                              {' '}
                              <img
                                src={
                                  horizontalView ? verticalIcon : horizontalIcon
                                }
                                alt=""
                              />
                              {' '}
                              Switch View
                              {' '}
                            </Button>
                          </div> */}
                          <div
                            className="col-md-1 col-sm-1 col-lg-1 col-xl-1"
                            onClick={() => onClose()}
                            style={{ cursor: 'pointer' }}
                          >
                            {' '}
                            <span style={{ float: 'right' }}>
                              <FontAwesomeIcon icon={faTimes} className='closeicon_clr' />
                            </span>
                            {' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cdf mt-1">
                      <div className="deductordetails">
                        <div className="row">
                          <div
                            className={
                              horizontalView ? 'col-sm-12' : 'col-sm-12'
                            }
                          >
                            <div className="row padding_style">
                              <div
                                className={
                                  horizontalView
                                    ? 'col-sm-3 pt-1'
                                    : 'col-sm-6 pt-4'
                                }
                              >
                                {' '}
                                <span className="f-14 mr-4">Map Challan</span>
                                <div className="form-check form-check-inline">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="mapChallanVal"
                                    id="inlineRadio1"
                                    value="1"
                                    checked={values.mapChallanVal === '1'}
                                    onChange={(e) => {
                                      setMapChallan(true);
                                      mapChallanValChange(e, setFieldValue);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  >
                                    Now
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <Field
                                    className="form-check-input"
                                    disabled={mapChallanDisable}
                                    type="radio"
                                    name="mapChallanVal"
                                    id="inlineRadio2"
                                    value="0"
                                    checked={values.mapChallanVal === '0'}
                                    onChange={(e) => {
                                      setMapChallan(false);
                                      mapChallanValChange(e, setFieldValue);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio2"
                                  >
                                    Later
                                  </label>
                                </div>
                              </div>
                              <div
                                className={
                                  horizontalView
                                    ? 'col-sm-3 vortiviewbox'
                                    : 'col-sm-6 '
                                }
                              >
                                <div
                                  className={
                                    mapChallan && parseInt(values.mapChallanVal) === 1
                                      ? 'slideUp'
                                      : 'row mt-0 slideUp closed'
                                  }
                                >
                                  <div className="form-group ">
                                    <label htmlFor="inputEmail4">
                                      Challan No.
                                    </label>
                                    <div>
                                      <Field
                                        as="select"
                                        className="custom-select selecbox"
                                        disabled={deducteeTransactionFormInfo.challan_id ? true : false}
                                        id="inputGroupSelect01"
                                        value={challanVals}
                                        name="challan_id"
                                        onChange={(e) => {
                                          handleChalanValueChange(
                                            e.target.value,
                                          );
                                          setChallanIdValue(e.target.value);
                                          setFieldValue(
                                            'challan_id',
                                            e.target.value,
                                          );
                                          setChallanVals(e.target.value);
                                        }}
                                      >
                                        <option value="">Select</option>
                                        {Object.keys(checkedChallanData).map(
                                          (challan) => (
                                            <option
                                              key={
                                                checkedChallanData[challan]?.challan_id
                                              }
                                              value={
                                                checkedChallanData[challan]?.challan_id
                                              }
                                              data-placement="top"
                                            >
                                              {
                                                checkedChallanData[challan]?.challan_no
                                              }
                                              {' '}
                                              {' - '}
                                              {' '}
                                              ₹
                                              {' '}
                                              {
                                                checkedChallanData[challan]?.total_amtp
                                              }
                                              {' '}
                                              {' - '}
                                              {' '}
                                              {checkedChallanData[challan]?.deposit_date}
                                            </option>
                                          ),
                                        )}
                                      </Field>
                                    </div>
                                    {errors.challan_id ? (
                                      <span className="error_message">
                                        {errors.challan_id}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div
                                className={
                                  horizontalView
                                    ? 'col-sm-5'
                                    : 'col-sm-6 horiviewbox'
                                }
                              >
                                <div
                                  className={
                                    mapChallan && parseInt(values.mapChallanVal) === 1
                                      ? 'slideUp'
                                      : 'row mt-2 slideUp closed'
                                  }
                                >
                                  <div className="form-group">
                                    <label
                                      htmlFor="inputEmail4"
                                      className="labchsect"
                                    >
                                      Challan No.
                                      {' '}
                                    </label>
                                    <div>
                                      <Field
                                        as="select"
                                        className="custom-select slecwidth"
                                        id="inputGroupSelect01"
                                        value={challanVals}
                                        name="challan_id"
                                        onChange={(e) => {
                                          handleChalanValueChange(
                                            e.target.value,
                                          );
                                          setChallanIdValue(e.target.value);
                                          setFieldValue(
                                            'challan_id',
                                            e.target.value,
                                          );
                                          setChallanVals(e.target.value);
                                        }}
                                      >
                                        <option value="">Select</option>
                                        {Object.keys(checkedChallanData).map(
                                          (challan) => (
                                            <option
                                              key={
                                                checkedChallanData[challan]
                                                  .challan_id
                                              }
                                              value={
                                                checkedChallanData[challan]
                                                  .challan_id
                                              }
                                              data-placement="top"
                                            >
                                              {
                                                checkedChallanData[challan]
                                                  .challan_no
                                              }
                                              {' '}
                                              {' - '}
                                              {' '}
                                              ₹
                                              {' '}
                                              {
                                                checkedChallanData[challan]
                                                  .total_amtp
                                              }
                                              {' '}
                                              {' - '}
                                              {' '}
                                              {moment(
                                                checkedChallanData[challan]
                                                  .deposit_date,
                                                'DD-MM-YYYY',
                                              ).format('YYYY-MM-DD')}
                                            </option>
                                          ),
                                        )}
                                      </Field>
                                    </div>
                                    {errors.challan_id ? (
                                      <span

                                        style={{ color: 'red', fontSize: '11px' }}
                                        className="error_message"
                                      >
                                        {errors.challan_id}
                                      </span>

                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  horizontalView ? 'col-md-4 pt-1' : 'col-md-0'
                                }
                              >
                                <p className="mt-3 totalforts pt-3">
                                  {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Total Tax Collected' : 'Total Tax Deducted'}: ₹
                                  {' '}
                                  {Number(totalTdsAmount)}
                                  {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_total_tds_amtp) ? (<div className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.total_tds_amtp_color ? `#${correctionDeducteeTrnsactnInfo.total_tds_amtp_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_total_tds_amtp === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_total_tds_amtp}</div>) : null}
                                </p>

                              </div>
                            </div>
                            <div className="row mt-0 mb-0 pb-0" style={{ marginBottom: '-4px !important' }}>
                              <div
                                className={
                                  horizontalView ? 'col-sm-2' : 'col-sm-6'
                                }
                              >
                                <div className="form-group">
                                  <label
                                    htmlFor="inputEmail4"
                                    className="labpb4"
                                  >
                                    {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Code' : 'Section'}
                                    <span className="red_text">*</span>
                                  </label>
                                  <div className="sltWidt">
                                    <Select
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      title={getNatureOfPaymentOptionLabelById(natureOfPaymentIDVal)}
                                      value={paymentCodesArray[paymentCodesArray.findIndex((item) => String(item.nature_of_payment_id) === String(values.nature_of_payment_id))] ?? null}
                                      onChange={(selected) => {
                                        setNatureOfPaymentIDVal(selected.nature_of_payment_id);
                                        onChangeDeductionReasonForSpecific(values.deduction_reason, parseInt(selected.nature_of_payment_id));
                                        setFieldValue(
                                          'nature_of_payment_id',
                                          selected.nature_of_payment_id,
                                        );
                                        setFieldValue('nature_of_remittance', [9, 10, 11, 54, 71].includes(Number(selected.nature_of_payment_id)) ? 'INTEREST_PAYMENT' : values.nature_of_remittance)
                                        tdsRateCalculation(
                                          values,
                                          selected.nature_of_payment_id,
                                          'nature_of_payment_id',
                                          setFieldValue,
                                          panComplianceStatus
                                        );
                                        Number(selected.nature_of_payment_id) === 10 && values?.deduction_reason === "C" && setFieldValue('deduction_reason', "");
                                        setToggleAllOption(true);
                                      }
                                      }
                                      options={paymentCodesArray}
                                      placeholder="Select"
                                    />
                                    {/* <Field
                                      autoFocus
                                      as="select"
                                      title={getNatureOfPaymentOptionLabelById(natureOfPaymentIDVal)}
                                      className="custom-select sltWidt1"
                                      id="nature_of_payment_id"
                                      name="nature_of_payment_id"
                                      value={natureOfPaymentIDVal}
                                      onChange={(e) => {
                                        setNatureOfPaymentIDVal(e.target.value);
                                        onChangeDeductionReasonForSpecific(values.deduction_reason, parseInt(e.target.value));
                                        setFieldValue(
                                          'nature_of_payment_id',
                                          e.target.value,
                                        );
                                        setFieldValue('nature_of_remittance', [9, 10, 11, 54, 71].includes(Number(e.target.value)) ? 'INTEREST_PAYMENT' : values.nature_of_remittance)
                                        // setTDSAMTMapping(
                                        //   e.target.value,
                                        //   setFieldValue,
                                        // );
                                        tdsRateCalculation(
                                          values,
                                          e.target.value,
                                          'nature_of_payment_id',
                                          setFieldValue,
                                          panComplianceStatus
                                        );
                                        Number(e.target.value) === 10 && values?.deduction_reason === "C" && setFieldValue('deduction_reason', "");

                                      }}
                                    >
                                      <option value="" disabled>
                                        Select
                                      </option>
                                      {paymentCodesArray.map(
                                        (item) => (
                                          <option
                                            title={item.description.length > 80 ? item.description : null}
                                            key={item.nature_of_payment_id}
                                            value={item.nature_of_payment_id}
                                            data-placement="top"
                                          >
                                            {item.code}
                                            {' '}
                                            {' - '}
                                            {' '}
                                            {item.description && item.description.length > 0
                                              ? item.description.substring(0, 80) : ''}
                                            {item.description.length > 80
                                              ? '...'
                                              : ''}
                                          </option>
                                        ),
                                      )}
                                    </Field> */}
                                  </div>
                                  {errors.nature_of_payment_id ? (
                                    <span className="overflowtooltipsurcharge">
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Section is required"
                                        style={{ color: 'red', fontSize: '11px' }}
                                        className="error_message"
                                      >
                                        {errors.nature_of_payment_id}
                                      </span>
                                    </span>

                                  ) : null}

                                  {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_code && correctionDeducteeTrnsactnInfo.old_description) ? (<div className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.code_color ? `#${correctionDeducteeTrnsactnInfo.code_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_code === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_code + ' ' + correctionDeducteeTrnsactnInfo.old_description}</div>) : null}

                                  {/* <!-- new status heml --> */}
                                  {getHtml1(headerDataDetails.form_type, values, panStatus, panComplianceStatus)}
                                  {/* <!-- new status heml --> */}

                                </div>
                              </div>

                              <div
                                className={
                                  horizontalView ? 'col-md-2' : 'col-md-6'
                                }
                              >
                                <label htmlFor="inputEmail4" className="labpb4">
                                  {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collectee Name' : 'Deductee Name'}
                                  {' '}
                                  <span className="red_text">*</span>
                                </label>
                                <div>
                                  <div className="autoCompDTF dninpboxsm">
                                    <AutoComplete
                                      data={deducteeData}
                                      id="deductee_name"
                                      textField="text"
                                      dataItemKey="id"
                                      value={values.deductee_name}
                                      className="form-control"
                                      popupSettings={{ className: 'autoCompDTF downslewidth', animate: { closeDuration: 500 } }}
                                      onChange={(e) => {
                                        handleDeducteeNameChange(e);
                                        setFieldValue(
                                          'deductee_name',
                                          e.target.value,
                                        );
                                        //checkPanCategory(e.target.value);
                                        if (e.target.value.length < 1) {
                                          setFieldValue('deductee_id', '');
                                          setFieldValue('deductee_pan', '');
                                          tdsRateCalculation(values, e.target.value, 'deductee_name', setFieldValue);
                                          setPanStatus(null)
                                          setPanStatusColor(null);
                                          setPanComplianceStatus(null);
                                          setPanAsOnDate(null);
                                        }
                                        if (headerDataDetails.form_type !== '24Q') {
                                          getPanStatus(values, e.target.value, headerDataDetails.form_type, setFieldValue)
                                        }
                                      }}
                                      onClose={(e) => {
                                        selectedDeducteeName(e.target.value, setFieldValue, values);
                                        //autoSuggestPanValue(e.target.value, setFieldValue);
                                      }}
                                      listNoDataRender={(element) => { return listNoDataRender(element, setFieldValue, values); }}
                                      itemRender={(li, itemProps) => { return itemRender(li, itemProps, setFieldValue, values); }}
                                      placeholder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Enter Collectee Name' : 'Enter Deductee Name'}
                                    />
                                    <input
                                      type="hidden"
                                      name="deductee_name"
                                      id="deductee_name"
                                      value={values.deductee_name}
                                    />
                                    <input
                                      type="hidden"
                                      name="deductee_id"
                                      id="deductee_id"
                                      value={values.deductee_id}
                                      onClick={(e) => {
                                        setFieldValue(
                                          'deductee_id',
                                          e.target.value,
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_deductee_name && correctionDeducteeTrnsactnInfo.old_pan) ? (<span className="error_message clrbtnfrmDeducteetrans errorDTName" style={{ color: correctionDeducteeTrnsactnInfo.deductee_name_color ? `#${correctionDeducteeTrnsactnInfo.deductee_name_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_deductee_name === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_deductee_name + '-' + correctionDeducteeTrnsactnInfo.old_pan}</span>) : null}

                                {
                                  customError
                                    ?
                                    <>
                                      <span className="error_message clrbtnfrmDeducteetrans errorDTName">
                                        {customError}
                                      </span>
                                    </>
                                    :
                                    <>
                                      {errors.deductee_name ? (

                                        <span className="error_message clrbtnfrmDeducteetrans errorDTName">

                                          {errors.deductee_name}
                                        </span>
                                      ) : null}
                                      {!errors.deductee_name && errors.deductee_id ? (
                                        <span className="error_message clrbtnfrmDeducteetrans errorDTName">
                                          {errors.deductee_id}
                                        </span>
                                      ) : null}
                                    </>
                                }

                                {/* <!-- new status heml --> */}
                                {getHtml2(headerDataDetails.form_type, values, panStatus, panComplianceStatus)}
                                {/* <!-- new status heml --> */}

                              </div>
                              {/* only vertical view */}
                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-2'
                                    : 'form-group col-md-6'
                                }
                              >
                                <label
                                  htmlFor="deduction_reason"
                                  className="labpb4"
                                >
                                  {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Reason for Non/Lower Collection' : 'Reason of Deduction'}
                                </label>
                                <div>

                                  <Select
                                    value={getReasonListV2(headerDataDetails, values.nature_of_payment_id, toggleAllOption)[getReasonListV2(headerDataDetails, values.nature_of_payment_id, toggleAllOption).findIndex((item) => String(item?.value) === String(values.deduction_reason))] ?? null}
                                    id="deduction_reason"
                                    name="deduction_reason"
                                    onChange={(e) => {
                                      if (e?.value === "showmore" || e?.value === "showless") {
                                        setToggleAllOption((prev) => !prev)
                                        setOpen(true)
                                      } else {
                                        //setAbc(e.value);
                                        setOpen(false)

                                        setFieldValue('deduction_reason', e?.value);
                                        onChangeDeductionReason(e?.value, setFieldValue);
                                        onChangeDeductionReasonForSpecific(e?.value, parseInt(values.nature_of_payment_id));
                                        tdsRateCalculation(
                                          values,
                                          e?.value,
                                          'deduction_reason',
                                          setFieldValue,
                                        );
                                      }
                                    }
                                    }
                                    options={getReasonListV2(headerDataDetails, values.nature_of_payment_id, toggleAllOption)}
                                    placeholder="Select"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    onMenuOpen={() => setOpen(true)}
                                    openOnFocus={true}
                                    menuIsOpen={open}
                                    onMenuClose={() => setOpen(false)}
                                  />
                                  {/*  <Field
                                    as="select"
                                    className="custom-select"
                                    id="deduction_reason"
                                    name="deduction_reason"
                                    title={getReasonOfDeductionOptionLabelById(values?.deduction_reason)}
                                    onChange={(e) => {
                                      setFieldValue('deduction_reason', e.target.value);
                                      setDeductionReason(() => e.target.value);
                                      onChangeDeductionReason(e.target.value, setFieldValue);
                                      onChangeDeductionReasonForSpecific(e.target.value, parseInt(values.nature_of_payment_id));
                                      tdsRateCalculation(
                                        values,
                                        e.target.value,
                                        'deduction_reason',
                                        setFieldValue,
                                      );
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {(headerDataDetails) ?
                                      getReasonList(headerDataDetails).map((reason, index) => {
                                        if (values.nature_of_payment_id === '10' && headerDataDetails.form_type === '27Q' && reason.id === 'C') {
                                          return null
                                        }
                                        if (['46', '41',].includes(values.nature_of_payment_id) && headerDataDetails.form_type === '27Q' && reason.id === 'J') {
                                          return null
                                        }
                                        if (!['76', '80'].includes(values.nature_of_payment_id) && headerDataDetails.form_type === '27Q' && reason.id === 'Y') {
                                          return null
                                        }
                                        return (
                                          <option value={reason.id} title={reason.value.length > 100 ? reason.value : null}>
                                     //       {/* {reason.value} */} {/* 
                                            {reason.value && reason.value.length > 0
                                              ? reason.value.substring(0, 100) : ''}
                                            {reason.value.length > 100
                                              ? '...'
                                              : ''}
                                          </option>
                                        )
                                      }) : null
                                    }
                                  </Field>*/}
                                </div>
                                <span className="overflowtooltipreason">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={sectionErrorTitleMsg ? sectionErrorTitleMsg : errors.nature_of_payment_id}
                                    id="errorMsgSection"
                                    style={{ color: 'red', fontSize: '11px' }}
                                  />
                                </span>
                                {errors.deduction_reason ? (
                                  <span className="overflowtooltipreason">
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Deduction Reason is required"
                                      style={{ color: 'red', fontSize: '11px' }}
                                      className="error_message"
                                    >
                                      {errors.deduction_reason}
                                    </span>
                                  </span>

                                ) : null}
                                {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_deduction_reason) ? (<div className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.deduction_reason_color ? `#${correctionDeducteeTrnsactnInfo.deduction_reason_color}` : '#0000ff' }}>
                                  {correctionDeducteeTrnsactnInfo.old_deduction_reason === '-' ? 'No previous value' : null}
                                  {getReasonList(headerDataDetails).forEach((reason, index) => {
                                    if (correctionDeducteeTrnsactnInfo.old_deduction_reason === reason.id) {
                                      return (
                                        <span>{reason.value}</span>
                                      )
                                    }
                                  })}
                                </div>) : null}
                              </div>
                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-2'
                                    : 'form-group col-md-6'
                                }
                              >
                                <label
                                  htmlFor="lower_deduction_certificate_no"
                                  className="labpb4"
                                >
                                  {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Lower Collection Certificate No' : 'Lower Deduction Certificate No'}

                                </label>
                                <AlphaNumericInput
                                  type="text"
                                  disabled={(headerDataDetails && headerDataDetails.form_type === '27Q') ? !(certificateEnable
                                    && [3, 66, 67, 68, 69, 70, 71, 72, 73].includes(parseInt(values.nature_of_payment_id))) : !certificateEnable}
                                  value={((headerDataDetails && headerDataDetails.form_type === '27Q') ? !(certificateEnable
                                    && [3, 66, 67, 68, 69, 70, 71, 72, 73].includes(parseInt(values.nature_of_payment_id))) : !certificateEnable) ? "" : values.lower_deduction_certificate_no} id="lower_deduction_certificate_no"
                                  name="lower_deduction_certificate_no"
                                  className="form-control"
                                  placeholder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? "Enter Lower Collection Certificate No" : "Enter Lower Deduction Certificate No"}
                                  style={values.lower_deduction_certificate_no ? { textTransform: 'uppercase' } : { textTransform: '' }}
                                  maxLength={10}
                                  onChange={(e) => {
                                    handleOnChange(
                                      'lowerDeductionCertificateNo',
                                      e.target.value,
                                    );
                                    setFieldValue(
                                      'lower_deduction_certificate_no',
                                      e.target.value,
                                    );
                                  }}
                                />
                                {' '}
                                {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_lower_deduction_certificate_no) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.lower_deduction_certificate_no_color ? `#${correctionDeducteeTrnsactnInfo.lower_deduction_certificate_no_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_lower_deduction_certificate_no === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_lower_deduction_certificate_no}</span>) : null}
                                <span id="err_msg" style={{ color: 'red', fontSize: '11px' }} />
                                {errors.lower_deduction_certificate_no ? (

                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Certificate no is required for reason A/B"
                                    style={{ color: 'red', fontSize: '11px' }}
                                    className="error_message"

                                  >
                                    {errors.lower_deduction_certificate_no}

                                  </span>
                                ) : null}
                              </div>

                              {/* {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Code' : 'Section'}  */}
                              {/* These fields show when form type is 27EQ otherwise it will be hide */}
                              {show27EQExtraFields ? (
                                <React.Fragment>
                                  <div
                                    className={
                                      horizontalView
                                        ? 'form-group col-md-2'
                                        : 'form-group col-md-4'
                                    }
                                  >
                                    <label htmlFor="tds_liable" className="labpb4">
                                      Collectee Is Liable To TDS?
                                      <span className="red_text">*</span>
                                    </label>
                                    <Field
                                      as="select"
                                      className="form-control f-14"
                                      name="tds_liable"
                                      value={values.tds_liable}
                                      disabled={liableFieldDisable}
                                      onChange={(e) => {
                                        setFieldValue('tds_liable', e.target.value);
                                        liableHandleChange(e.target.value, setFieldValue);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="0">No</option>
                                      <option value="1">Yes</option>
                                    </Field>
                                    {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_tds_liable) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.tds_liable_color ? `#${correctionDeducteeTrnsactnInfo.tds_liable_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_tds_liable === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_tds_liable}</span>) : null}
                                    {errors.tds_liable ? (
                                      <span className="error_message">
                                        {errors.tds_liable}
                                      </span>
                                    ) : null}
                                    {' '}
                                  </div>
                                  <div
                                    className={
                                      horizontalView
                                        ? 'form-group col-md-2'
                                        : 'form-group col-md-4'
                                    }
                                  >
                                    <label htmlFor="tds_challan_no " className="labpb4">
                                      Challan No.
                                      <span className="red_text">*</span>
                                    </label>
                                    <NumericInput
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter TDS Challan No"
                                      id="tds_challan_no "
                                      name="tds_challan_no "
                                      value={values.tds_challan_no}
                                      disabled={(Number(values.tds_liable) === 1) ? false : true}
                                      maxLength={5}
                                      // minLength={1}
                                      onChange={(e) => {
                                        setFieldValue('tds_challan_no', e.target.value)
                                      }}
                                    />
                                    {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_tds_challan_no) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.tds_challan_no_color ? `#${correctionDeducteeTrnsactnInfo.tds_challan_no_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_tds_challan_no === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_tds_challan_no}</span>) : null}
                                    {errors.tds_challan_no ? (
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Please select tds challan Number is required"
                                        style={{ color: 'red', fontSize: '11px' }}
                                        className="error_message"

                                      >
                                        {errors.tds_challan_no}
                                      </span>
                                    ) : null}
                                    {' '}
                                  </div>

                                  <div
                                    className={
                                      horizontalView
                                        ? 'form-group col-md-2'
                                        : 'form-group col-md-4'
                                    }
                                    id="tdsPaymentDate"
                                  >
                                    <label htmlFor="tds_amtp" className="labpb4">
                                      Payment Date of TDS
                                      <span className="red_text">*</span>
                                    </label>
                                    <DatePicker
                                      selectedDate={
                                        values.tds_payment_date
                                          ? values.tds_payment_date
                                          : null
                                      }
                                      maxDate={headerDataDetails.quarter_end_date ? new Date(headerDataDetails.quarter_end_date) : new Date()}
                                      placeHolder="Enter Payment Date of TDS"
                                      onChange={(date) => {
                                        setFieldValue('tds_payment_date', date);
                                      }}
                                      disabled={(Number(values.tds_liable) === 1) ? false : true}
                                      name="tds_payment_date"
                                    />
                                    {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_tds_payment_date) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.tds_payment_date_color ? `#${correctionDeducteeTrnsactnInfo.tds_payment_date_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_tds_payment_date === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_tds_payment_date}</span>) : null}
                                    {errors.tds_payment_date ? (
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Please select tds payment date is required"
                                        style={{ color: 'red', fontSize: '11px', display: 'inline-block' }}
                                        className="error_message"
                                      >
                                        {errors.tds_payment_date}
                                      </span>
                                    ) : null}
                                    {' '}
                                  </div>
                                </React.Fragment>
                              ) : null}

                              {/* end only vertical view */}

                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-2'
                                    : 'form-group col-md-4 frDt'
                                }
                                id="paymentDateDiv"
                              >
                                <label htmlFor="inputEmail4" className="labpb4">
                                  {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Amt Received Date' : 'Payment Date'}
                                  {' '}
                                  <span className="red_text">*</span>
                                  {' '}
                                  {/* <span id="errMsg" style={{ color: 'red', fontSize: '11px' }} /> */}
                                </label>
                                <DatePicker
                                  selectedDate={
                                    values.payment_date
                                      ? values.payment_date
                                      : null
                                  }
                                  maxDate={
                                    headerDataDetails.quarter_end_date ? new Date(headerDataDetails.quarter_end_date) : new Date()
                                  }
                                  placeHolder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Enter Amt Received Date' : 'Enter Payment Date'}
                                  onChange={(date) => {
                                    setFieldValue('payment_date', date);
                                    autoPopulateDeductionDate(date, setFieldValue, values);
                                    setAutoChangeCall(1);
                                  }}
                                />
                                {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_payment_date) ? (<div className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.payment_date_color ? `#${correctionDeducteeTrnsactnInfo.payment_date_color}` : '' }}>{correctionDeducteeTrnsactnInfo.old_payment_date === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_payment_date}</div>) : null}
                                {errors.payment_date ? (
                                  <span className="overflowtooltipsurcharge">
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Date of Payment is required"
                                      style={{ color: 'red', fontSize: '11px' }}
                                      className="error_message clrbtnfrmDeducteetrans"

                                    >
                                      {errors.payment_date}
                                    </span>
                                  </span>

                                ) : null}
                                {errors.paymentDate ? (
                                  <span className="error_message">

                                    {errors.paymentDate}
                                  </span>
                                ) : null}
                              </div>
                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-2 msnd'
                                    : 'form-group col-md-4 flDt msnd'
                                }
                                id="deductionDateDiv"
                              >
                                <div
                                  className={
                                    values.tds_rate_b100 <= 0
                                      && values.tds_amtp <= 0
                                      ? 'hideDiv1'
                                      : ''
                                  }
                                >
                                  <label
                                    htmlFor="inputEmail4"
                                    className="labpb4 "
                                  >
                                    {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Date' : 'Deduction Date'}
                                  </label>
                                  <DatePicker
                                    selectedDate={
                                      values.deduction_date
                                        ? values.deduction_date
                                        : null
                                    }
                                    onChange={(date) => {
                                      setFieldValue('deduction_date', date);
                                      tdsRateCalculation(values, date, 'deduction_date', setFieldValue);
                                    }}
                                    minDate={
                                      headerDataDetails.quarter_start_date
                                        ? new Date(
                                          headerDataDetails.quarter_start_date,
                                        )
                                        : new Date()
                                    }
                                    placeHolder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Enter Collection Date' : 'Enter Deduction Date'}
                                  />
                                  {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_deduction_date) ? (<div className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.deduction_date_color ? `#${correctionDeducteeTrnsactnInfo.deduction_date_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_deduction_date === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_deduction_date}</div>) : null}
                                  {errors.deduction_date ? (
                                    <span className="overflowtooltipsurcharge">
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Date of Deduction is required"
                                        style={{ color: 'red', fontSize: '11px' }}
                                        className="error_message clrbtnfrmDeducteetrans"
                                      >
                                        {errors.deduction_date}
                                      </span>
                                    </span>

                                  ) : null}
                                  {errors.deductionDate ? (
                                    <span className="error_message">
                                      <br />
                                      {errors.deductionDate}
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              {headerDataDetails.form_type === '27Q' &&
                                <div className={horizontalView ? 'form-group col-md-2 ' : 'form-group col-md-4  grcolmb0'}                                  >
                                  <label htmlFor="educationCesss" className="labpb4"> Applicability of TDS Rate</label>
                                  <Field as="select" className="custom-select" id="applicable_tds_rate" name="applicable_tds_rate"
                                    onChange={(e) => {
                                      setFieldValue(
                                        'applicable_tds_rate',
                                        e.target.value,
                                      );
                                      setExcessTdsRate(values, setFieldValue);
                                    }}
                                  >
                                    <option value="" disabled>Select</option>
                                    <option value="A"> Rate as per Income Tax</option>
                                    <option value="B">Rate as per DTAA</option>                             \
                                  </Field>
                                </div>
                              }

                              {headerDataDetails.form_type !== '24Q' && headerDataDetails.form_type !== '27Q' ?
                                <div
                                  className={
                                    horizontalView
                                      ? 'form-group col-md-2 tdsrateboxdd'
                                      : 'form-group col-md-4 tdsratebox tdsrateboxdd'
                                  }
                                >
                                  <label htmlFor="inputEmail4" className="labpb4">
                                    Rate
                                    <span className="red_text">*</span>
                                  </label>
                                  {headerDataDetails.form_type === '24Q' ? (
                                    <select
                                      className="custom-select"
                                      id="TdsRateB100"
                                      value={values.tds_rate_b100}
                                      disabled={true}
                                    >
                                      <option value="0">NA</option>
                                    </select>
                                  ) : (
                                    <TdsRateInput
                                      value={tdsRateB100}
                                      onChange={(v) => {
                                        setFieldValue('tds_rate_b100', v.trim());
                                        handleOnChange('tdsRateB100', v.trim());
                                        if (parseInt(values.nature_of_payment_id) === 36 || parseInt(values.nature_of_payment_id) === 53) {
                                          excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', v.trim());
                                        }
                                        else {
                                          formValueChange2(v.trim(), setFieldValue, 'tds_amtp');
                                        }
                                      }}
                                      data={
                                        (headerDataDetails && headerDataDetails.form_type === '27EQ') ?
                                          [
                                            '0',
                                            '0.005',
                                            '0.075',
                                            '0.1',
                                            '0.75',
                                            '1',
                                            '1.5',
                                            '1.875',
                                            '2',
                                            '2.5',
                                            '3.75',
                                            '5',
                                            '10',
                                          ]
                                          :
                                          [
                                            '0',
                                            '0.1',
                                            '0.75',
                                            '1',
                                            '1.5',
                                            '2',
                                            '3.75',
                                            '5',
                                            '7.5',
                                            '10',
                                            '15',
                                            '18.75',
                                            '20',
                                            '22.5',
                                            '25',
                                            '30',
                                            '40',
                                          ]
                                      }
                                    />
                                  )}
                                  {deducteeTrnsactnInfo.tds_rate_b100_old ? (
                                    <span style={{ color: `#${deducteeTrnsactnInfo.tds_rate_b100_color}`, float: 'right', fontSize: '10px' }}>
                                      OLD RATE
                                      {deducteeTrnsactnInfo.tds_rate_b100_old}
                                    </span>
                                  ) : null}
                                  {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_tds_rate_b100) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.tds_rate_b100_color ? `#${correctionDeducteeTrnsactnInfo.tds_rate_b100_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_tds_rate_b100 === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_tds_rate_b100}</span>) : null}
                                  {' '}
                                  {errors.tds_rate_b100 ? (
                                    <span className="error_message">
                                      {errors.tds_rate_b100}
                                    </span>
                                  ) : null}
                                </div>
                                : null}
                              {/* only vertical view section */}
                              {!show27EQExtraFields && headerDataDetails.form_type !== '24Q' ? (
                                <React.Fragment>
                                  <div
                                    className={
                                      horizontalView
                                        ? 'form-group col-md-2 displaynonh'
                                        : 'form-group col-md-4 displaynonh grcolmb0'
                                    }
                                  >
                                    <label
                                      htmlFor="educationCesss"
                                      className="labpb4"
                                    >
                                      Cash Withdrawal in excess of
                                    </label>

                                    <Field
                                      as="select"
                                      className="custom-select"
                                      id="cash_withdrawal_option"
                                      name="cash_withdrawal_option"
                                      disabled={submitEnableFor194NF}
                                      onChange={(e) => {
                                        setFieldValue(
                                          'cash_withdrawal_option',
                                          e.target.value,
                                        );
                                        /*tdsRateCalculation(
                                          values,
                                          e.target.value,
                                          'cash_withdrawal_option',
                                          setFieldValue
                                        );*/
                                        setExcessTdsRate(values, setFieldValue);
                                      }}
                                    >
                                      <option value="" >Select</option>
                                      {
                                        parseInt(values?.nature_of_payment_id) === 53 || parseInt(values?.nature_of_payment_id) === 81
                                          ?
                                          <>
                                            <option value="20L-1CR" >20 Lakh to 1 Crore</option>
                                            <option value="1CR-ABOVE">Excess of 1 Crore</option>
                                          </>
                                          :
                                          <>
                                            <option value="20L-3CR-COOP">20 Lakh to 3 Crore-Co-operative Society</option>
                                            <option value="3CR-ABOVE-COOP">Excess of 3 Crore-Co-operative Society</option>
                                          </>
                                      }
                                    </Field>
                                    {errors.cash_withdrawal_option ? (
                                      <span className="error_message">

                                        {errors.cash_withdrawal_option}
                                      </span>
                                    ) : null}

                                  </div>

                                  <div
                                    className={
                                      horizontalView
                                        ? `form-group col-md-2 ${headerDataDetails.form_type === '27Q' ? '' : 'displaynonh'} `
                                        : 'form-group col-md-4 displaynonh grcolmb0'
                                    }
                                  >
                                    <label
                                      htmlFor="educationCesss"
                                      className="labpb4"
                                    >
                                      {parseInt(values.nature_of_payment_id) === 36 ? 'Withdrawal Amount' : 'Excess Withdrawal Amount'}
                                      {' '}
                                    </label>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        left: '19px',
                                        top: '26px',
                                        fontSize: '14px',
                                      }}
                                    >
                                      ₹
                                    </div>

                                    <NumericInputWithDecimalTwo
                                      type="text"
                                      className="form-control text-right"
                                      id="cash_withdrawal_amtp"
                                      name="cash_withdrawal_amtp"
                                      value={values?.cash_withdrawal_amtp}
                                      disabled={(parseInt(values.nature_of_payment_id) === 36 || parseInt(values.nature_of_payment_id) === 81) ? submitDisable : submitEnableFor194NF}
                                      placeholder="Amount of cash"
                                      onChange={(e) => {
                                        setFieldValue('cash_withdrawal_amtp', e.target.value);
                                        excessWithdrawTdsCalculation(values, setFieldValue, 'cash_withdrawal_amtp', e.target.value);
                                      }}
                                    />
                                    {errors.cash_withdrawal_amtp ? (
                                      <span className="error_message">

                                        {errors.cash_withdrawal_amtp}
                                      </span>
                                    ) : null}
                                    <span id="errorExcess" className='error_message' />



                                  </div></React.Fragment>
                              ) : null}

                              {headerDataDetails.form_type === '27Q' &&
                                <div
                                  className={
                                    horizontalView
                                      ? 'form-group col-md-2 tdsrateboxdd'
                                      : 'form-group col-md-4 tdsratebox tdsrateboxdd'
                                  }
                                >
                                  <label htmlFor="inputEmail4" className="labpb4">
                                    Rate
                                    <span className="red_text">*</span>
                                  </label>
                                  {values.applicable_tds_rate && values.applicable_tds_rate === 'B' ? (
                                    <NumericInputWithDecimalTwo
                                      type="text"
                                      className="form-control text-right"
                                      id="tds_rate_b100"
                                      value={values.tds_rate_b100}
                                      placeholder="Enter TDS Rate"
                                      onChange={(e) => {
                                        handleOnChange(
                                          'tdsRateB100',
                                          e.target.value,
                                        );
                                        setFieldValue(
                                          'tds_rate_b100',
                                          e.target.value.trim(),
                                        );
                                        formValueChange2(e.target.value.trim(), setFieldValue, 'tds_amtp');

                                      }}

                                    />
                                  ) : (
                                    <TdsRateInput
                                      value={tdsRateB100}
                                      onChange={(v) => {
                                        setFieldValue('tds_rate_b100', v.trim());
                                        handleOnChange('tdsRateB100', v.trim());
                                        formValueChange2(v.trim(), setFieldValue, 'tds_amtp');
                                      }}
                                      data={
                                        ['0',
                                          '5',
                                          '7.5',
                                          '10',
                                          '18.75',
                                          '20',
                                          '22.5',
                                          '30',
                                          '40',
                                          '60',
                                        ]

                                      }
                                    />
                                  )}
                                  {errors.tds_rate_b100 ? (
                                    <span className="error_message">
                                      {errors.tds_rate_b100}
                                    </span>
                                  ) : null}
                                </div>
                              }

                              {show27EQExtraFields && headerDataDetails.form_type !== '24Q' ? (
                                <div
                                  className={
                                    horizontalView
                                      ? 'form-group col-md-2'
                                      : 'form-group col-md-4'
                                  }
                                >
                                  <label htmlFor="purchase_amtp" className="labpb4">
                                    Amt of Purchase
                                    {' '}
                                    <span className="red_text">*</span>
                                  </label>
                                  <NumericInputWithDecimalTwo
                                    type="text"
                                    className="form-control text-right"
                                    id="purchase_amtp"
                                    value={values.purchase_amtp}
                                    placeholder="Enter Amount Purches"
                                    onChange={(e) => {
                                      // handleOnChange(
                                      //   'purchaseAmtp',
                                      //   e.target.value,
                                      // );
                                      setFieldValue('purchase_amtp', e.target.value);
                                    }}
                                  />

                                  <div
                                    style={{
                                      position: 'absolute',
                                      left: '19px',
                                      top: '26px',
                                      fontSize: '14px',
                                    }}
                                  >
                                    ₹
                                  </div>

                                  {' '}

                                  {errors.purchase_amtp ? (
                                    <span className="error_message">
                                      {errors.purchase_amtp}
                                    </span>
                                  ) : null}
                                  {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_purchase_amtp) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.purchase_amtp_color ? `#${correctionDeducteeTrnsactnInfo.purchase_amtp_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_purchase_amtp === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_purchase_amtp}</span>) : null}


                                </div>

                              ) : null}
                              < div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-2'
                                    : 'form-group col-md-4'
                                }
                              >
                                <label htmlFor="paid_amtp" className="labpb4">
                                  {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Amt Received' : 'Amount Paid'}
                                  {' '}
                                  <span className="red_text">*</span>
                                </label>
                                <NumericInputWithDecimalTwo
                                  type="text"
                                  className="form-control text-right"
                                  id="paid_amtp"
                                  value={values.paid_amtp}
                                  placeholder="Enter Amount Paid"
                                  // disabled={submitDisable}
                                  onChange={(e) => {
                                    handleOnChange(
                                      'paidAmount',
                                      e.target.value,
                                    );
                                    setFieldValue('paid_amtp', e.target.value);
                                    setPaidAmount(e.target.value);
                                    if ([36, 53, 81, 82].includes(parseInt(values.nature_of_payment_id))) {
                                      excessWithdrawTdsCalculation(values, setFieldValue, 'paid_amtp', e.target.value);
                                    }
                                    else {
                                      formValueChange(e, setFieldValue, values, 'tds_amtp');
                                    }
                                  }
                                  }
                                  onKeyUp={() => {
                                    validateReasonSection(
                                      values,
                                      setFieldValue,
                                      '',
                                    );
                                  }}
                                />

                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '19px',
                                    top: '26px',
                                    fontSize: '14px',
                                  }}
                                >
                                  ₹
                                </div>

                                {' '}

                                {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_paid_amtp) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.paid_amtp_color ? `#${correctionDeducteeTrnsactnInfo.paid_amtp_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_paid_amtp === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_paid_amtp}</span>) : null}

                                {errors.paid_amtp ? (
                                  <span className="overflowtooltipsurcharge">
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Amount paid should be greater than 0"
                                      style={{ color: 'red', fontSize: '11px' }}
                                      className="error_message"
                                    >
                                      {errors.paid_amtp}
                                    </span>
                                  </span>
                                ) : null}

                              </div>
                              {/* end  only vertical view section */}

                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-2'
                                    : 'form-group col-md-4 grcolmb0'
                                }
                              >
                                <label htmlFor="tds_amtp" className="labpb4">
                                  {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Tax collected' : 'Tax Deducted'}
                                  <span className="red_text">*</span>
                                </label>
                                <NumericInputWithDecimalTwo
                                  type="text"
                                  className="form-control text-right"
                                  id="tds_amtp"
                                  //value={(values.paid_amtp > 0) ? tdsAmount : 0}
                                  value={tdsAmount}
                                  placeholder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Enter Tax collected' : 'Enter Tax Deducted'}
                                  onChange={(e) => {
                                    handleOnChange('tdsAmount', e.target.value);
                                    setFieldValue('tds_amtp', e.target.value);
                                  }}
                                  onKeyUp={(e) => {
                                    validateReasonSection(
                                      values,
                                      setFieldValue,
                                      'errorMsgTds',
                                    );
                                    change24QTaxDeducted(setFieldValue, values.paid_amtp, values.deductee_name, 'tds_amtp', e.target.value);
                                  }}
                                />
                                <span className="overflowtooltipsurcharge">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={errors.tds_amtp}
                                    id="errorMsgTds"
                                    style={{ color: 'red', fontSize: '11px' }}
                                  />
                                </span>
                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '19px',
                                    top: '26px',
                                    fontSize: '14px',
                                  }}
                                >
                                  ₹
                                </div>
                                {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_tds_amtp) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.tds_amtp_color ? `#${correctionDeducteeTrnsactnInfo.tds_amtp_color}` : '' }}>{correctionDeducteeTrnsactnInfo.old_tds_amtp === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_tds_amtp}</span>) : null}
                                {errors.tds_amtp ? (
                                  <span className="error_message">
                                    {errors.tds_amtp}
                                  </span>
                                ) : null}
                                {' '}
                              </div>
                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-2'
                                    : 'form-group col-md-4 grcolmb0'
                                }
                              >
                                <label
                                  htmlFor="surchargePaid"
                                  className="labpb4"
                                >
                                  Surcharge
                                  <span className="red_text">*</span>
                                </label>
                                <NumericInputWithDecimalTwo
                                  type="text"
                                  className="form-control text-right"
                                  id="surcharge_paid_amtp"
                                  value={values.surcharge_paid_amtp}
                                  placeholder="Enter Surcharge Paid"
                                  onChange={(e) => {
                                    handleOnChange(
                                      'surchargePaidAmtp',
                                      e.target.value,
                                    );
                                    setFieldValue(
                                      'surcharge_paid_amtp',
                                      e.target.value,
                                    );
                                    excessWithdrawTdsCalculation(values, setFieldValue, 'surcharge_paid_amtp', e.target.value);
                                    /*tdsRateCalculation(
                                      values,
                                      e.target.value,
                                      'surcharge_paid_amtp',
                                      setFieldValue
                                    );*/
                                  }}
                                  onKeyUp={(e) => {
                                    validateReasonSection(
                                      values,
                                      setFieldValue,
                                      'errorMsgSurcharge',
                                    );
                                  }}
                                />

                                <span className="overflowtooltipconttdsamt">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="For Section (194D, 194DA, 194I(a), 194I(b), 192A, 194, 194A, 194EE, 194K) and Reason of Deduction (B), TDS Rate, TDS Amount, Surcharge Amount & Health and Education Cess Amount cannot be greater than 0."
                                    id="errorMsgSurcharge"
                                    style={{ color: 'red', fontSize: '11px' }}
                                  />
                                </span>

                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '19px',
                                    top: '26px',
                                    fontSize: '14px',
                                  }}
                                >
                                  ₹
                                </div>
                                {' '}
                                {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_surcharge_paid_amtp) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.surcharge_paid_amtp_color ? `#${correctionDeducteeTrnsactnInfo.surcharge_paid_amtp_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_surcharge_paid_amtp === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_surcharge_paid_amtp}</span>) : null}
                                {errors.surcharge_paid_amtp ? (
                                  <span className="error_message">
                                    {errors.surcharge_paid_amtp}
                                  </span>
                                ) : null}
                              </div>

                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-2'
                                    : 'form-group col-md-4 grcolmb0'
                                }
                              >
                                <label
                                  htmlFor="educationCess"
                                  className="labpb4"
                                >
                                  Health and Education Cess
                                  {' '}
                                  <span className="red_text">*</span>
                                </label>
                                <NumericInputWithDecimalTwo
                                  type="text"
                                  className="form-control text-right"
                                  id="education_cess_amtp"
                                  value={values.education_cess_amtp}
                                  placeholder="Enter Health and Education Cess"
                                  onChange={(e) => {
                                    handleOnChange(
                                      'educationCessAmtp',
                                      e.target.value,
                                    );
                                    setFieldValue(
                                      'education_cess_amtp',
                                      e.target.value,
                                    );
                                    excessWithdrawTdsCalculation(values, setFieldValue, 'education_cess_amtp', e.target.value);
                                    /*tdsRateCalculation(
                                      values,
                                      e.target.value,
                                      'education_cess_amtp',
                                      setFieldValue
                                    );*/
                                  }}
                                  onKeyUp={(e) => {
                                    validateReasonSection(
                                      values,
                                      setFieldValue,
                                      'errorMsgCess',
                                    );
                                  }}
                                />
                                <span className="overflowtooltipconttdsamt">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="For Section (194D, 194DA, 194I(a), 194I(b), 192A, 194, 194A, 194EE, 194K) and Reason of Deduction (B), TDS Rate, TDS Amount, Surcharge Amount & Health and Education Cess Amount cannot be greater than 0."
                                    id="errorMsgCess"
                                    style={{ color: 'red', fontSize: '11px' }}
                                  />
                                </span>

                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '19px',
                                    top: '26px',
                                    fontSize: '14px',
                                  }}
                                >
                                  ₹
                                </div>
                                {' '}
                                {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_education_cess_amtp) ? (<span className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.education_cess_amtp_color ? `#${correctionDeducteeTrnsactnInfo.education_cess_amtp_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_education_cess_amtp === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_education_cess_amtp}</span>) : null}
                                {errors.education_cess_amtp ? (
                                  <span className="error_message">
                                    {errors.education_cess_amtp}
                                  </span>
                                ) : null}
                              </div>
                              {/* only hv */}
                              {!show27EQExtraFields && headerDataDetails.form_type !== '24Q' ? (
                                <div
                                  className={
                                    horizontalView
                                      ? 'form-group col-md-2'
                                      : 'form-group col-md-4 displaynonv'
                                  }
                                >
                                  <label
                                    htmlFor="educationCesss"
                                    className="labpb4"
                                  >
                                    Cash Withdrawal in excess of
                                  </label>

                                  <Field
                                    as="select"
                                    className="custom-select"
                                    id="cash_withdrawal_option"
                                    name="cash_withdrawal_option"
                                    disabled={submitEnableFor194NF}
                                    onChange={(e) => {
                                      setFieldValue(
                                        'cash_withdrawal_option',
                                        e.target.value,
                                      );
                                      /*tdsRateCalculation(
                                        values,
                                        e.target.value,
                                        'cash_withdrawal_option',
                                        setFieldValue
                                      );*/
                                      setExcessTdsRate(values, setFieldValue);
                                    }}
                                  >
                                    <option value="" disabled>Select</option>
                                    {
                                      Number(values?.nature_of_payment_id) === 53 || Number(values?.nature_of_payment_id) === 81
                                        ?
                                        <>
                                          <option value="20L-1CR" >20 Lakh to 1 Crore</option>
                                          <option value="1CR-ABOVE">Excess of 1 Crore</option>
                                        </>
                                        :
                                        <>
                                          <option value="20L-3CR-COOP">20 Lakh to 3 Crore-Co-operative Society</option>
                                          <option value="3CR-ABOVE-COOP">Excess of 3 Crore-Co-operative Society</option>
                                        </>
                                    }
                                  </Field>
                                  <span id="cashWithdrawErr" className='error_message' />
                                </div>
                              ) : null}
                              {/* end only hv */}


                              {headerDataDetails.form_type === '27Q' ? (
                                <>
                                  <div
                                    className={
                                      horizontalView
                                        ? 'form-group col-md-2 '
                                        : 'form-group col-md-4  grcolmb0'
                                    }
                                  >
                                    <label
                                      htmlFor="educationCesss"
                                      className="labpb4"
                                    >
                                      Nature of Remittance
                                      <span className="red_text">*</span>
                                    </label>
                                    <Select
                                      value={NATURE_OF_REMITTANCE_LIST_NEW[NATURE_OF_REMITTANCE_LIST_NEW.findIndex((item) => String(item.value) === String(values.nature_of_remittance))] ?? null}
                                      onChange={(selected) => setFieldValue('nature_of_remittance', selected.value)}
                                      options={NATURE_OF_REMITTANCE_LIST_NEW}
                                      placeholder="Select option"
                                      className="single-select-d"

                                    />
                                    <span className="overflowtooltipconttdsamt">
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={errors.nature_of_remittance}
                                        id="cashWithdrawErrs"
                                        style={{ color: 'red', fontSize: '11px' }}
                                      >
                                        {errors.nature_of_remittance}

                                      </span>

                                    </span>
                                  </div>

                                  <div className={horizontalView ? 'form-group col-md-2 ' : 'form-group col-md-4  grcolmb0 '}                                  >
                                    <label htmlFor="educationCesss" className="labpb4">  15CA Acknowledgment No   </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      id="acknowledgment_no_15ca"
                                      placeholder=" 15CA Acknowledgment No"
                                      name="acknowledgment_no_15ca"
                                      maxLength={15}
                                      onChange={(e) => {
                                        e.target.value.match(/^[a-zA-Z0-9]*$/) && setFieldValue('acknowledgment_no_15ca', e.target.value)
                                      }}

                                    />
                                    <span className="overflowtooltipconttdsamt">
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Enter 15CA Acknowledgment Number "
                                        id="tool"
                                        style={{ color: 'red', fontSize: '11px' }}
                                        className="error_message"
                                      >
                                        {errors.acknowledgment_no_15ca}
                                      </span>
                                    </span>

                                  </div>

                                  <div className={horizontalView ? 'form-group col-md-2 ' : 'form-group col-md-4  '}                                  >
                                    <label htmlFor="educationCesss" className="labpb4">  Grossing Up Indicator  </label>
                                    <Field as="select" value={values.grossing_up_indicator ?? ""} className="custom-select" id="grossing_up_indicator" name="grossing_up_indicator"
                                      onChange={(e) => {
                                        setFieldValue(
                                          'grossing_up_indicator',
                                          e.target.value,
                                        );
                                        setExcessTdsRate(values, setFieldValue);
                                      }}
                                    >
                                      <option value="" disabled>Select</option>
                                      <option value="Y">Yes</option>
                                      <option value="N">N0</option>                             \
                                    </Field>
                                  </div>




                                </>
                              ) : null}




                              {/* }
                              <div className={horizontalView ? 'form-group col-md-2' : 'form-group col-md-4 desplaynonv'}>
                                <label htmlFor="educationCesss" className="labpb3">
                                  Amount of cash withdrawal in excess of 1 crore
                                  {' '}
                                  {' '}

                                </label>
                                <div style={{
                                  position: 'absolute', left: '19px', top: '43px', fontSize: '14px',
                                }}
                                >
                                  ₹
                                </div>
                                <input type="number" step="any" min="0" className="form-control text-right" id="educationCesss" value="" placeholder="Amount of cash" />
                                {' '}
                              </div>

                              <div className={horizontalView ? 'form-group col-md-2' : 'form-group col-md-4 desplaynonv'}>

                                <label htmlFor="deduction_reason" className="labpb4">Reason of Deduction</label>
                                <div>
                                  <Field
                                    as="select"
                                    className="custom-select"
                                    id="deduction_reason"
                                    name="deduction_reason"
                                  >
                                    <option value="">Select</option>
                                    {/* <option value="TBA">TBA</option> *
                                    <option value="A">A - Lower deduction/No deduction u/s 197</option>
                                    <option value="B">B - No deduction u/s 197 A</option>
                                    <option value="C">C - Higher rate (Valid PAN not available)</option>
                                    <option value="T">T - Transporter</option>
                                    <option value="Y">Y - Threshold limit</option>
                                    <option value="S">S - Software Providers</option>
                                    <option value="Z">Z - No deduction u/s 197A(1F)</option>
                                    <option value="R">R - Deduction on interest income-Senior citizens)</option>
                                    <option value="N">N - No deduction -clause (iii,iv,v)-section 194 N</option>
                                  </Field>

                                </div>
                                {errors.deduction_reason ? <span className="error_message">{errors.deduction_reason}</span> : null}
                              </div>
                              <div className={horizontalView ? 'form-group col-md-2' : 'form-group col-md-4 desplaynonv'}>

                                <label htmlFor="lower_deduction_certificate_no" className="labpb4">
                                  Lower Deduction Certificate No

                                </label>
                                <AlphaNumericInput
                                  type="text"
                                  id="lower_deduction_certificate_no"
                                  name="lower_deduction_certificate_no"
                                  className="form-control"
                                  id="lower_deduction_certificate_no"
                                  placeholder="Enter Lower Deduction Certificate No"
                                  maxLength={10}
                                  onChange={(e) => {
                                    handleOnChange('lowerDeductionCertificateNo', e.target.value);
                                    setFieldValue('lower_deduction_certificate_no', e.target.value);
                                  }}
                                />
                                {' '}
                                {errors.lower_deduction_certificate_no ? <span className="error_message">{errors.lower_deduction_certificate_no}</span> : null}
                              </div>

                                  */}
                              <div
                                className={
                                  horizontalView
                                    ? 'col-md-3'
                                    : 'col-md-6 mt-1 hpopuphightbtn'
                                }
                              >
                                <div className="text-left">
                                  {submitDisable ? (
                                    // <small
                                    //   className="hviewhide"
                                    //   style={{ color: 'red' }}
                                    // >
                                    //   Challan Unconsumed Amount is less than Tds
                                    //   Amount
                                    // </small>
                                    null
                                  ) : (
                                    <div>
                                      {!deducteeTransactionID ? (
                                        <small className="hviewhide pt-1">
                                          Use Alt + S to Save
                                          {' '}
                                          <br />
                                          Use Alt + Shift + S for Save & New
                                        </small>
                                      ) : (
                                        <small className="hviewhide">
                                          Use Alt + S to Save
                                          {' '}
                                        </small>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className={
                                  horizontalView
                                    ? 'col-md-7 mt-2'
                                    : 'col-md-6 mt-1 hpopuphightbtn'
                                }
                              >
                                <div className="text-left ml-5 pl-5">
                                  <button
                                    // disabled={submitDisable}
                                    className="dark_color_bg btnbgpadd1 mr-2 mb-1"
                                    type="submit"
                                    onClick={() => submitForm(values, values.payment_date)}
                                  >
                                    Save
                                  </button>
                                  {!deducteeTransactionID ? (
                                    <button
                                      disabled={submitDisable}
                                      className="light_color_bg btnbgpadd2 mr-2 mb-1"
                                      onClick={() => submitAndResetForm()}
                                    >
                                      Save and New
                                    </button>
                                  ) : null}
                                  <button
                                    type="button"
                                    className="light-btn cdedtrscalbtn"
                                    onClick={() => onClose()}
                                    style={{ padding: '5px 23px', fontWeight: '500' }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                              {!show27EQExtraFields && headerDataDetails.form_type !== '27Q' ? (
                                <div
                                  className={
                                    horizontalView
                                      ? 'form-group col-md-2'
                                      : 'form-group col-md-4 displaynonv'
                                  }
                                >
                                  <label
                                    htmlFor="educationCesss"
                                    className="labpb3"
                                  >
                                    Excess Withdrawal Amount
                                    {' '}
                                  </label>
                                  <div
                                    style={{
                                      position: 'absolute',
                                      left: '19px',
                                      top: '26px',
                                      fontSize: '14px',
                                    }}
                                  >
                                    ₹
                                  </div>
                                  <NumericInputWithDecimalTwo
                                    type="text"
                                    className="form-control text-right"
                                    id="cash_withdrawal_amtp"
                                    name="cash_withdrawal_amtp"
                                    value={values.cash_withdrawal_amtp}
                                    //disabled={submitEnableFor194NF}
                                    disabled={(parseInt(values.nature_of_payment_id) === 36 || parseInt(values.nature_of_payment_id) === 81) ? submitDisable : submitEnableFor194NF}
                                    placeholder="Amount of cash"
                                    onChange={(e) => {
                                      setFieldValue(
                                        'cash_withdrawal_amtp',
                                        e.target.value,
                                      );
                                      excessWithdrawTdsCalculation(values, setFieldValue, 'cash_withdrawal_amtp', e.target.value);
                                    }}
                                  />
                                  {' '}
                                  <span
                                    id="errorExcess"
                                  />

                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-12 ">
                            <div className="row mb-0">
                              <div
                                className={
                                  horizontalView
                                    ? 'col-md-12 text-center vpopuphightbtn'
                                    : 'col-md-8 mt-0'
                                }
                              >
                                <button
                                  // disabled={submitDisable}
                                  className="dark_color_bg btnbgpadd1 mr-2 mb-1"
                                  type="submit"
                                  onClick={() => { submitForm(values, values.payment_date); }}
                                  id="save-deductee-trn"
                                >
                                  Save
                                </button>
                                {!deducteeTransactionID ? (
                                  <button
                                    disabled={submitDisable}
                                    className="light_color_bg btnbgpadd2 mr-2 mb-1"
                                    id="save-deductee-trn-new"
                                    onClick={() => submitAndResetForm()}
                                  >
                                    Save and New
                                  </button>
                                ) : null}
                                <button
                                  type="button"
                                  className="light-btn cdedtrscalbtn"
                                  onClick={() => onClose()}
                                  style={{ padding: '5px 23px', fontWeight: '500' }}
                                >
                                  Cancel
                                </button>

                                {deducteeTransactionID &&
                                  <button
                                    type="button"
                                    className="light_color_bg btnbgpadd2 ml-2"
                                    onClick={() => handleMarkNill(setFieldValue)}
                                    style={{ padding: '5px 23px', borderRadius: '40px' }}
                                  >
                                    Mark NIL
                                  </button>}
                              </div>
                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-3'
                                    : 'form-group col-md-4'
                                }
                              >
                                <p className="totalsectdisplaynoneh">
                                  {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Total Tax Collected' : 'Total Tax Deducted'}:
                                  {' '}
                                  <span className="float-right">
                                    ₹
                                    {' '}
                                    {Number(totalTdsAmount)}
                                    {(correctionDeducteeTrnsactnInfo && correctionDeducteeTrnsactnInfo.old_total_tds_amtp) ? (<div className="error_message" style={{ color: correctionDeducteeTrnsactnInfo.total_tds_amtp_color ? `#${correctionDeducteeTrnsactnInfo.total_tds_amtp_color}` : '#0000ff' }}>{correctionDeducteeTrnsactnInfo.old_total_tds_amtp === '-' ? 'No previous value' : correctionDeducteeTrnsactnInfo.old_total_tds_amtp}</div>) : null}
                                  </span>
                                </p>

                              </div>
                              <div
                                className={
                                  horizontalView
                                    ? 'form-group col-md-4 hviewhide'
                                    : 'form-group col-md-12'
                                }
                              >
                                {submitDisable ? (
                                  // <small
                                  //   className="hviewhide"
                                  //   style={{ color: 'red' }}
                                  // >
                                  //   Challan Unconsumed Amount is less than Tds
                                  //   Amount
                                  // </small>
                                  null
                                ) : (
                                  <div>
                                    {!deducteeTransactionID ? (
                                      <small className="hviewhide">
                                        Use Alt + S to Save
                                        {' '}
                                        <br />
                                        Use Alt + Shift + S for Save & New
                                      </small>
                                    ) : (
                                      <small className="hviewhide">
                                        Use Alt + S to Save
                                        {' '}
                                      </small>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div >
    </>
  );
}