import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import alertIcon from "../../../images/alerticon.png";
import Slider from '@material-ui/core/Slider';
import calendarIcon from "../../../images/calender_img.png"
import unlinkIcon from "../../../images/icons/unlink_img.svg"
import { getChallansListV2 } from '../../../api/challanAPI';
import ShowAlert from '../../../components/ShowAlert';
import { deepClone, formatNumber, getCurrentFinancialYear } from '../../../utils/UtilityFunctions';
import searchIcon from "../../../images/icons/searchIcon.svg"
import { gocallApi } from '../../../api/issueCertificateAPI';
//import ConfirmAlertPopup from '../../../components/ConfirmAlertPopup';
import WindowConfirmPopup from '../../../components/WindowConfirmPopup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../header/headerSlice';
import { Skeleton } from '@mui/material';
import AddChallan from '../../challanList/AddChallan';


const TdsComputationSummary = (props) => {
    const { dragArray, setDragArray, challanftr, setChallanFtr, setShowMoreLoading } = props;


    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);
    const headerDataDetails = useSelector(selectHeaderData);
    const financial_year = headerDataDetails?.financial_year_formatted ? parseInt(headerDataDetails?.financial_year_formatted.split("-")?.[0] ?? 0) : getCurrentFinancialYear();
    const [consumedAmt, setConsumedAmt] = useState(0)
    const constantKeys = {
        minslider: "from_amt",
        maxSlider: "to_amt",
        section: "nature_of_payment",
        remaining_tds_bal_amtp: "remaining_tds_bal_amtp",
        challan_no: "challan_no",
        searchInp: "searchStr",
        slideCompvalue: "CompareAmt"
    }

    const sortQrtWiseDate = {
        "Q1": { start_date: `01/05/${financial_year}`, end_date: `15/07/${financial_year}` },
        "Q2": { start_date: `01/08/${financial_year}`, end_date: `15/10/${financial_year}` },
        "Q3": { start_date: `01/11/${financial_year}`, end_date: `15/01/${financial_year + 1}` },
        "Q4": { start_date: `01/02/${financial_year + 1}`, end_date: `15/04/${financial_year + 1}` },
    }
    const inputSearch = [constantKeys.remaining_tds_bal_amtp, constantKeys.section, constantKeys.challan_no];


    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [challanId, setChallanId] = useState(null);
    const [minMaxValue, setMinMaxValue] = useState(null);
    const [challansList, setChallansList] = useState({})
    const [defaultChallansList, setDefaultChallansList] = useState([])
    const [dataState, setDataState] = useState(null);
    const [sliderValue, setSliderValue] = useState([1, 1000]);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [unConsumedAmt, setUnConsumedAmt] = useState(0);
    const [showAddChallan, setShowAddChallan] = useState(false);

    const getTopSummary = async (deductorId, returnId) => {
            let url = `/api/v2/transaction/summary-count?deductor_id=${deductorId}&return_id=${returnId}`;
            try {
                const result = await gocallApi("get", url);
                setConsumedAmt(result?.data?.unmapped_liability_amtp ?? 0)

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
            }
        
    }


    function valuetext(value) {
        return `${value}`;
    }

    const getChallanList = async (data) => {
        getTopSummary(data?.deductor_id,data?.return_id)


        let payload = {
            deductor_id: data?.deductor_id ?? "",
            return_id: data?.return_id ?? "",
            limit: 1000,
            "from_deposit_date": `${financial_year - 1}-04-01`,
            "to_deposit_date": moment(new Date()).format('YYYY-MM-DD'),
            show_all_challan: true,
            sort_by: "asc",
            sort_on: "deposit_date"
        }
        try {
            const result = await getChallansListV2(payload);
            const quarter = result?.header?.quarter ?? ""
            let consumed = [];
            let unConsumed = [];
            let minvalue = 0;
            let maxvalue = 0;
            let convertData = [];

            if (result?.data?.data?.length > 0) {
                const array1 = [];
                const array2 = [];
                result.data.data.forEach((item) => {

                    if (item.deposit_date && moment(item.deposit_date, "DD/MM/YYYY").unix() >= moment(sortQrtWiseDate?.[quarter]?.start_date, "DD/MM/YYYY").unix()
                        && moment(item.deposit_date, "DD/MM/YYYY").unix() <= moment(sortQrtWiseDate?.[quarter]?.end_date, "DD/MM/YYYY").unix()
                    ) {
                        array1.push(item)
                    } else {
                        array2.push(item);
                    }
                });
                if (array2.length > 0) {
                    array2.sort((firstObj, secondObj) => {
                        return firstObj?.remaining_tds_bal_amtp < secondObj?.remaining_tds_bal_amtp ? 1 : -1
                    })
                }
                convertData = [...array1, ...array2];
            }


            if (convertData.length > 0) {
                convertData.forEach((item) => {
                    if (minvalue > item.remaining_tds_bal_amtp) minvalue = item.remaining_tds_bal_amtp ?? 0;

                    if (maxvalue < item.remaining_tds_bal_amtp) maxvalue = item.remaining_tds_bal_amtp ?? 0;

                    if (item.remaining_tds_bal_amtp) {
                        item.highlighted = false;
                        unConsumed.push(item);
                    } else {
                        consumed.push(item);
                    }

                    setUnConsumedAmt((prev) => {
                        prev = prev + (item?.remaining_tds_bal_amtp ?? 0)
                        return prev
                    })

                })
            }

            setMinMaxValue((prev) => {
                if (!prev) prev = {};
                prev.min = minvalue;
                prev.max = maxvalue;
                return { ...prev }
            })
            setSliderValue([minvalue, maxvalue])
            setChallansList((prev) => {
                if (!prev) prev = {};
                prev.unConsumed = unConsumed;
                prev.consumed = consumed;
                return { ...prev }
            });

            setDefaultChallansList(convertData ?? []);


        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        }

    }

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    const handleSliderChangeProcess = (event, newValue) => {

        const comparvalue = {};
        if (newValue?.[0] !== minMaxValue.min) comparvalue[constantKeys.minslider] = newValue?.[0] ?? 0;
        if (newValue?.[1] !== minMaxValue.max) comparvalue[constantKeys.maxSlider] = newValue?.[1] ?? 0;

        const obj = {};
        if (dataState?.[constantKeys.searchInp]) obj[constantKeys.searchInp] = dataState?.[constantKeys.searchInp] ?? "";

        if (Object.keys(comparvalue)?.length > 0) {
            obj[constantKeys.slideCompvalue] = comparvalue;
        }
        setDataState({ ...obj });
        filterChallansList(obj);
    };

    const consumedChallanftrUi = (list, data_state, keys, inputSearch, unConsumed, consumed) => {
        list.forEach((item) => {
            let valueItem = null;
            Object.keys(data_state).forEach((key) => {

                if (key === keys.searchInp) {
                    for (let childkey of inputSearch) {
                        const value = deepClone(String(item?.[childkey] ?? "")?.toLowerCase());

                        if (value && value?.indexOf(String(data_state[key])?.toLowerCase()) !== -1) {
                            valueItem = item;
                            break;
                        }
                    }
                }
                if (key === keys.slideCompvalue) {

                    if (valueItem) {
                        valueItem = comparefunction(data_state, key, keys.minslider, keys.maxSlider, valueItem, "remaining_tds_bal_amtp");
                    } else if (!valueItem && !data_state?.[keys?.searchInp]) {
                        valueItem = comparefunction(data_state, key, keys.minslider, keys.maxSlider, item, "remaining_tds_bal_amtp");
                    }

                }

            })

            if (valueItem) {
                if (valueItem.remaining_tds_bal_amtp && unConsumed) unConsumed.push(valueItem);
                else consumed.push(valueItem);
            }


        });

        return { consumed: consumed, unConsumed: unConsumed }
    }

    const filterChallansList = (data_state) => {
        const list = defaultChallansList;
        const keys = constantKeys;
        let consumed = [];
        let unConsumed = [];
        if (data_state && Object.keys(data_state).length > 0 && list?.length > 0) {
            const filterData = consumedChallanftrUi(list, data_state, keys, inputSearch, unConsumed, consumed);
            consumed = filterData.consumed;
            unConsumed = filterData.unConsumed;
        } else {
            if (list.length > 0) {
                list.forEach((item) => {
                    if (item.remaining_tds_bal_amtp) {
                        unConsumed.push(item);
                    } else {
                        consumed.push(item);
                    }
                })
            }
        }
        setChallansList((prev) => {
            if (!prev) prev = {};
            prev.unConsumed = unConsumed;
            prev.consumed = consumed;
            return { ...prev }
        });

    }

    const comparefunction = (data_state, key, firstKey, secondKey, valueItem, parentKey) => {

        if (data_state?.[key]?.[firstKey] && data_state?.[key]?.[secondKey]) {
            return valueItem[parentKey] >= data_state?.[key]?.[firstKey] && valueItem[parentKey] <= data_state?.[key]?.[secondKey] ? valueItem : null;
        } else if (data_state?.[key]?.[firstKey]) {
            return valueItem[parentKey] >= data_state?.[key]?.[firstKey] ? valueItem : null;
        } else if (data_state?.[key]?.[secondKey]) {
            return valueItem[parentKey] <= data_state?.[key]?.[secondKey] ? valueItem : null
        }

    }

    const handleChangeSearchInput = (event, name) => {
        event.preventDefault();
        const obj = {};
        if (event.target.value) obj[name] = event.target.value.trim();
        if (dataState?.[constantKeys.slideCompvalue]) {
            obj[constantKeys.slideCompvalue] = dataState[constantKeys.slideCompvalue];
        }
        setDataState({ ...obj });
        if (event.target.value === "") {
            filterChallansList(obj);
        }
    }

    const unMappedTransitionChallan = async (challan_id) => {
        const url = `/api/v1/return/unmap-all-challan-transactions`
        const payload = {
            "deductor_id": params?.deductor_id ?? "",
            "return_id": params?.return_id ?? "",
            "challan_id": challan_id
        }
        try {
            const result = await gocallApi("post", url, payload);
            setConfirmPopup(false);
            if (result.data) {
                setSuccess(result?.message ?? "");
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }

        } catch (err) {

            if (err.message) setError(err.message);
            else setError(err);

        }
    }


    const mappedTransitionChallan = async (challan_id) => {

        const url = `/api/v1/return/map-transactions`
        const deductee_ids = Object.keys(dragArray).map((key) => dragArray?.[key]?.deductee_transaction_id);
        let payload = {
            "deductor_id": params.deductor_id ?? "",
            "return_id": params.return_id ?? "",
            "challan_id": challan_id,
            "remap_flag": true,
            "deductee_transaction_id": deductee_ids
        }

        try {
            const result = await gocallApi("post", url, payload);
            if (result.data) {
                setDragArray({});
                setSuccess(result?.message ?? "");

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                setError("Oops, we missed it! Can you try again, please?")
            }

        } catch (err) {

            if (err.message) setError(err.message);
            else setError(err);

        }
    }

    const addDragData = (dataItem) => {
        if (!dataItem?.challan_id) {
            setError("Oops, we missed it! Can you try again, please?")
        }
        if (dragArray && Object.keys(dragArray)?.length > 0) {
            mappedTransitionChallan(dataItem?.challan_id ?? '');
        } else {
            setError("Oops, we missed it! Can you try again, please?")
        }

    }
    const titlefun =
        (<div className="row w-100">
            <div className="col-md-12 text-center">
                <span className="text-center float-left">
                    <img src={alertIcon} alt="alertIcon" width="25" />
                </span>
                <span className="text-center">
                    Alert For Un-Mapping
                </span>
            </div>

        </div>)



    useEffect(() => {
        const params = queryString.parse(searchStr);
        getChallanList(params);
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {confirmPopup &&
                <WindowConfirmPopup
                    //show={confirmPopup}
                    handleClose={() => setConfirmPopup(false,)}
                    handleProceed={() => {
                        unMappedTransitionChallan(challanId);
                    }}
                    confirmText={`Do You want to Unmap the transactions from this challan?`}
                    confirmButtonText={`Yes`}
                    confirmButtonText2={`No`}
                    handleProceed2={() => { setConfirmPopup(false) }}
                    title={titlefun}

                />}

            {showAddChallan &&
                <AddChallan
                    onClose={() => setShowAddChallan(false)}
                    deductorId={params?.deductor_id ?? ""}
                />
            }

            <div className="col-xl-4 col-lg-7 col-md-12 dedcuteetrans_mapping_right_colwidth pl-1">

                <fieldset className='tdsmapping_challanbox_fs'>
                    <legend className="tdsmapping_challanbox_leg">
                        <div className="tdsmapping_challanbox_inndev">
                            <div className="tdsmapping_challanbox_lefts">
                                <h4 className="tdsmapping_challanbox_title">
                                    Challans
                                </h4>
                            </div>
                            <div className="tdsmapping_challanbox_inner"></div>
                            <div className="tdsmapping_challanbox_right">
                                <div className="tdsmapping_double_range_slider_box">
                                    <div className="double_range_slider challanListv2">
                                        <Slider
                                            value={sliderValue}
                                            onChange={handleSliderChange}
                                            onChangeCommitted={(e, newValue) => handleSliderChangeProcess(e, newValue)}
                                            valueLabelDisplay="on"
                                            aria-labelledby="range-slider"
                                            getAriaValueText={valuetext}
                                            min={minMaxValue?.min ? minMaxValue?.min : 0}
                                            max={minMaxValue?.max ? minMaxValue?.max : 0}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="tdsmapping_challanbox_middleborder"></div>
                        </div>
                    </legend>
                    <div className='row'>
                        <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="card mb-2 p-0 tdsmapping_totalstriknotebox">

                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                    <table className="table table-borderless tdsmapping_totalstriknote_tb mb-0">
                                        <tbody>
                                            <tr>
                                                <th width="150" className="mapping_totle_cellbox_bgclr_blue">TDS Challan Available</th>
                                                <th width="100" className="mapping_totle_cellbox_bgclr_white text-right">{formatNumber(unConsumedAmt ?? "0.00")}</th>
                                            </tr>
                                            <tr>
                                                <th width="" className="mapping_totle_cellbox_bgclr_blue"> Unmapped Liability</th>
                                                <th width="" className="mapping_totle_cellbox_bgclr_white text-right">{formatNumber(consumedAmt ?? "0.00")}</th>
                                            </tr>
                                            <tr>
                                                <th width="" className="mapping_totle_cellbox_bgclr_blue"> Challan Available</th>
                                                <th width="" className={`mapping_totle_cellbox_bgclr_white ${consumedAmt > unConsumedAmt ? 'textclr_red' : 'textclr_green'} text-right`}>
                                                    {formatNumber((unConsumedAmt - consumedAmt) ?? "0.00")}
                                                </th>
                                            </tr>

                                            <div className="tdsmapping_totalstriknote_pin">
                                                <div className="tdsmapping_totalstriknote_shadow">
                                                </div>
                                                <div className="tdsmapping_totalstriknote_metal"></div>
                                                <div className="tdsmapping_totalstriknote_bottom_circle"></div>
                                            </div>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-5 pl-1'>
                            <div className='tdsmapping_rightsearchboxtop'>
                                <div className="input-group tdsmapping_searchbox d-flex justify-content-start">
                                    <div className="form-inline" autocomplete="off">
                                        <input className="form-control tdsmapping_searchtext" type='search' title=""
                                            placeholder="Search Challan No/Amt/Sec" aria-label="Search"
                                            incremental="true" value={dataState?.[constantKeys.searchInp] ?? ""}
                                            onChange={(e) => handleChangeSearchInput(e, constantKeys.searchInp)}
                                            onKeyDown={(event) => {
                                                if (event.keyCode === 13) {
                                                    filterChallansList(dataState);
                                                }
                                            }}
                                        /></div>
                                    <span className="input-group-text tdsmapping_search_icon">
                                        <img src={searchIcon} alt="search" width={16}
                                            onClick={() => {
                                                filterChallansList(dataState);
                                            }}
                                        />
                                    </span>
                                </div>
                                {consumedAmt > unConsumedAmt &&
                                    <div className='tdsmapping_paynowbtn'>
                                        <button className='btn btn-default tdsmapping_challanbox_paynowbtn' onClick={() => setShowAddChallan(true)}>
                                            Pay Now
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>

                            <div className="mapping_unconsum_boxfixed pb-2" >
                                <div className='row'>

                                    {Object.keys(challansList)?.length === 0 ? <div >
                                        {[...Array(4)].map((items) =>
                                            <span className='col-md-12' style={{ display: "flex" }} >

                                                {[...Array(2)].map((items, index) => <Skeleton animation="wave" className={`${index === 1 ? "sumskelton" : ""}`} variant="text" width={"265px"} height={"120px"} />)}

                                            </span>
                                        )}

                                    </div> :
                                        <>

                                            {

                                                challansList?.unConsumed?.length > 0 && challansList.unConsumed.map((item, index) => {

                                                    return (
                                                        <>

                                                            <div className={`col-md-6  pr-1 pb-2    ${(index + 1) % 2 === 0 ? "float-left" : ""}`}

                                                            >
                                                                <div className={`${item.challan_id} ${challanftr && challanftr?.challan_id === item.challan_id ? "tdsmapping_cardbox_modeview_disabledcard" : "tdsmapping_cardbox_modeview_active"}`}>
                                                                    <div className={`card mapping_uncons_card ${item.highlighted ? "activehighlight" : ""}`}
                                                                        onDragOver={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        onClick={() => {
                                                                            if (params.filtertable === "sectionwise" || !params.filtertable) {
                                                                                if (challanftr && challanftr?.challan_id === item.challan_id) {
                                                                                    setChallanFtr(null);
                                                                                } else {
                                                                                    setChallanFtr(item);
                                                                                }
                                                                                setShowMoreLoading(true);
                                                                            }
                                                                        }}
                                                                        onDrop={(e) => {
                                                                            addDragData(item ?? "");
                                                                            setChallanId(item.challan_id);
                                                                        }}
                                                                    >
                                                                        <div className="card-header mapping_uncons_cardheader">
                                                                            <span className="mapping_uncons_leftside_text float-left">
                                                                                Rem. Bal. :
                                                                                <span className="pl-1">
                                                                                    {formatNumber(item.remaining_tds_bal_amtp ?? "0")}
                                                                                </span>

                                                                            </span>
                                                                            <span className="mapping_uncons_rightamt float-right">
                                                                                <span className="mapsectionno_fs pr-2">
                                                                                    {item.nature_of_payment ?? ""}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="card-body mapping_uncons_body">
                                                                            <p className="mapping_uncons_innertextfs mb-1 text-left">
                                                                                <span className="leftside_textfs pr-1">
                                                                                    Challan No :
                                                                                </span>

                                                                                <span className="rightside_textbold">
                                                                                    {item.challan_no ? `${item.challan_no ?? ""}` : ""}
                                                                                </span>

                                                                            </p>

                                                                            <p className="mapping_uncons_innertextfs mb-1 text-left">
                                                                                <span className="leftside_textfs pr-1">
                                                                                    <img src={calendarIcon} alt="calendar Icon" width={16} style={{ verticalAlign: "unset", marginRight: "3px", position: 'relative', top: '3px' }} />
                                                                                    <span className="rightside_textbold">
                                                                                        {item.deposit_date ?? ""}
                                                                                    </span>
                                                                                </span>
                                                                                <span className="pl-2 text-right">
                                                                                    <span className={`tds_unlinked_img ${item.return_mapped_transactions ? "cursor-pointer" : "cursor-default"} pr-2`}>
                                                                                        <img alt="unlink" src={unlinkIcon} width="14" onClick={(event) => {

                                                                                            event.stopPropagation()
                                                                                            if (item.return_mapped_transactions) {
                                                                                                setConfirmPopup(true);
                                                                                                setChallanId(item.challan_id);
                                                                                            }


                                                                                        }} />

                                                                                    </span>
                                                                                    <span className="">
                                                                                        <span className="rightside_textbold">
                                                                                            {`${item.return_mapped_transactions ?? "0"} Trans.`}
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </p>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(index + 1) % 2 === 0 && <div className="row pt-2"></div>}
                                                            {challansList?.unConsumed?.length === index + 1 && (index + 1) % 2 !== 0 &&
                                                                <div className={`col-md-6 pr-1 float-left`}>
                                                                    <div className="">
                                                                        <div className="card mapping_uncons_card" style={{ width: "276px", height: "82px", border: "0px",paddingBottom: '8px' }}>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            {dataState && Object.keys(dataState).length > 0
                                                && challansList?.unConsumed?.length === 0
                                                && challansList?.consumed?.length === 0 ?
                                                <div className='pl-3' style={{ fontSize: "12px", textAlign: "center" }}>
                                                    {"No result found for the search Criteria"}
                                                </div>
                                                :
                                                challansList?.unConsumed?.length === 0 ?
                                                    <div className='pl-3' style={{ fontSize: "12px", textAlign: "center" }}>
                                                        {"No Unconsumed Challan Available for Mapping"}
                                                    </div>
                                                    : null
                                            }
                                            {
                                                challansList?.consumed?.length > 0 &&

                                                <div className="row col-md-12 pr-0 mr-0 ml-0 pl-0">
                                                    <div className="col-md-12">
                                                        <div className="spborder_aftertenrow"></div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                challansList?.consumed?.length > 0 && challansList.consumed.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className={`col-md-6 pr-1 pb-2   ${(index + 1) % 2 === 0 ? "float-left" : ""}`}>
                                                                <div className={`${item.challan_id} ${challanftr && challanftr?.challan_id === item.challan_id ? "tdsmapping_cardbox_modeview_disabledcard" : ""}`}>

                                                                    <div className="card mapping_uncons_card"
                                                                        onClick={() => {
                                                                            if (params.filtertable === "sectionwise" || !params.filtertable) {
                                                                                if (challanftr && challanftr?.challan_id === item.challan_id) {
                                                                                    setChallanFtr(null);
                                                                                } else {
                                                                                    setChallanFtr(item);
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className="card-header mapping_uncons_cardheader position-relative" >
                                                                            <div className="cardview_denidedview"></div>
                                                                            <span className="mapping_uncons_leftside_text float-left">
                                                                                Rem. Bal. :
                                                                                <span className="pl-1">
                                                                                    {formatNumber(item.remaining_tds_bal_amtp ?? "0")}
                                                                                </span>

                                                                            </span>
                                                                            <span className="mapping_uncons_rightamt float-right">
                                                                                <span className="mapsectionno_fs pr-2">
                                                                                    {item.nature_of_payment ?? ""}
                                                                                </span>
                                                                                <span className="editiconbgclr" data-toggle="modal" data-target="#adjustable_amt_against_popup">
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="card-body mapping_uncons_body" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                                                                            <p className="mapping_uncons_innertextfs mb-1 text-left">
                                                                                <span className="leftside_textfs pr-1">
                                                                                    Challan No :
                                                                                </span>

                                                                                <span className="rightside_textbold">
                                                                                    {item.challan_no ? ` ${item.challan_no ?? ""}` : ""}
                                                                                </span>

                                                                            </p>

                                                                            <p className="mapping_uncons_innertextfs mb-1 text-left">
                                                                                <span className="leftside_textfs pr-1">
                                                                                    <img src={calendarIcon} alt="calendar Icon" width={9} style={{ verticalAlign: "unset", marginRight: "3px", position: 'relative', top: '3px' }} />
                                                                                    <span className="rightside_textbold">
                                                                                        {item.deposit_date ?? "0"}
                                                                                    </span>
                                                                                </span>
                                                                                <span className="pl-2 text-right">
                                                                                    <span className={`tds_unlinked_img ${item.return_mapped_transactions ? "cursor-pointer" : "cursor-default"}`}>
                                                                                        <img alt="unlink" src={unlinkIcon} width="14"
                                                                                            onClick={(event) => {
                                                                                                event.stopPropagation()
                                                                                                if (item.return_mapped_transactions) {
                                                                                                    setConfirmPopup(true);
                                                                                                    setChallanId(item.challan_id);
                                                                                                }

                                                                                            }}
                                                                                        />

                                                                                    </span>
                                                                                    <span className="">
                                                                                        <span className="rightside_textbold">
                                                                                            {`${item.return_mapped_transactions ?? "0"} Trans.`}
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                            <div className="ribbon"><span>Consumed</span></div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(index + 1) % 2 === 0 && <div className="row pt-2"></div>}
                                                            {challansList?.consumed?.length === index + 1 && (index + 1) % 2 !== 0 &&
                                                                <div className={`col-md-6 pr-1 float-left`}>
                                                                    <div className="">
                                                                        <div className="card mapping_uncons_card" style={{ width: "276px", height: "82px", border: "0px" }}>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>




            </div>
        </>
    )
}

export default TdsComputationSummary
