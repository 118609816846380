import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getTracesProcessStatusFromConnector } from '../../api/localConnectorAPI';

export default function ProgressBarForPAN(props) {
  const { bulkPanVerificationStatusData, statusKey, setPort, setBulkPanVerification, setReloadPanList, setDisableBulkPanVerificationButton } = props
  const [data, setData] = useState(null);
  const timer = useRef(null);
  //Get port and captcha ref from local storage
  const port = parseInt(bulkPanVerificationStatusData?.port ?? 0);
  const captcha_ref = bulkPanVerificationStatusData?.captcha_ref ?? null;

  useEffect(() => {

    // Run a continuous loop every 2 seconds
    const continueCheckStatusWhenFoundInProgress = async (port, captcha_ref, statusKey, setPort, setBulkPanVerification) => {
      try {
        const statusResult = await getTracesProcessStatusFromConnector(port, `?captcha_ref=${captcha_ref}`);
        if (parseInt(statusResult.status) === 1) {
          setData(statusResult?.data?.summary ?? []);
          if ((statusResult?.data?.action_status ?? null) === 'IN_PROGRESS') {
            timer.current = setTimeout(() => continueCheckStatusWhenFoundInProgress(port, captcha_ref, statusKey, setPort, setBulkPanVerification), 2000);
            return;
          }
        }
      } catch (err) {
        console.error('Bulk Pan Verificaiton Status', err)
      }

      // Finally
      localStorage.removeItem(statusKey);
      setPort(0);
      setBulkPanVerification(false);

    }

    if (port && captcha_ref) {
      continueCheckStatusWhenFoundInProgress(port, captcha_ref, statusKey, setPort, setBulkPanVerification);
    }

    // Clear timeout on unmount
    return () => clearTimeout(timer.current);

  }, [port, captcha_ref, statusKey, setPort, setBulkPanVerification])



  const getPercentData = (data) => {

    //const server_failure = data?.['server_failure'] ?? 0;
    //const server_success = data?.['server_success'] ?? 0;
    const total = parseInt(data?.['total'] ?? 0);
    //const traces_failure = data?.['traces_failure'] ?? 0;
    //const traces_success = data?.['traces_success'] ?? 0; 
    const unattempted = parseInt(data?.['unattempted'] ?? 0);

    if (parseInt(total) === 0) return 0;

    const percent = parseInt(((total - unattempted) / total) * 100);

    setDisableBulkPanVerificationButton(true);

    if (percent === 100) {
      //Reload deductee listing for latest pan status data
      setTimeout(() => setReloadPanList(true), 3000);
    }

    return percent;

  }

  return (
    captcha_ref &&
    <ProgressBar animated now={getPercentData(data)} label={'Bulk PAN Verification Status: ' + getPercentData(data) + ' %'} />
  )


}

