import React, { useEffect, useState } from 'react'
import ShowAlert from '../../components/ShowAlert'
import { updateDeductee } from '../../api/deducteeAPI'
import $ from 'jquery';
import BlurOnChangeInput from '../../components/BlurOnChangeInput';
import { deepClone } from '../../utils/UtilityFunctions';

const EmailPdfRow = React.memo((props) => {
    const { trElement, dataItem, deductorId, setSelectDeducteeList, selectDeducteeList, allSelected, sumCntUpdated, updateParent, index } = props;

    const [localState, setLocalState] = useState({})
    const [defaultState, setDefaultState] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const rowArray = [
        { key: "checkbox" },
        { key: "deductee_name" },
        { key: "deductee_pan" },
        { key: "email" },
        { key: "mobile_no" },
    ];

    const selectRow = (deducteeId) => {
        const index = selectDeducteeList.findIndex((item) => item === deducteeId)
        if (index > -1) {
            selectDeducteeList.splice(index, 1)
        } else selectDeducteeList.push(deducteeId)
        setSelectDeducteeList(() =>
            [...selectDeducteeList]
        )

    }
    const updateDeducteeFun = async (name, value) => {
        const dataPost = {};
        dataPost.deductee_id = localState.deductee_id;
        dataPost.deductor_id = deductorId;
        dataPost[name] = value;
       
        if (name === "email") {
            if (value !== '' && !/^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
                setEmailError(true)
                return 'Invalid Email Address!';
            } setEmailError(false)
        }

        if (defaultState[name] === value) {
            return null
        }

        async function callUpdatePanNameAPI() {
            try {
                const result = await updateDeductee(dataPost);
                if (result?.data) {
                    
                    sumCntUpdated(defaultState[name], value);
                    updateParent(localState, index)
                    setDefaultState(deepClone(localState ?? {}));
                    setSuccess("Data has Been updated successfully")
                }
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.message);
            }
        }
        callUpdatePanNameAPI();
    }

    useEffect(() => {
        const item = JSON.parse(dataItem)
        setLocalState({ ...item });
        setDefaultState({...item});
    }, [dataItem])

    useEffect(() => {
        if (allSelected && localState?.email) {
            setSelectDeducteeList((prev) => {
                if (!prev.includes(localState?.deductee_id)) {
                    prev.push(localState?.deductee_id)
                }
                return [...prev]
            })
        } else if (!localState.email && selectDeducteeList.includes(localState.deductee_id)) {
            const index = selectDeducteeList.findIndex((item) => item === localState.deductee_id)
            if (index > -1) {
                selectDeducteeList.splice(index, 1)
                setSelectDeducteeList(() =>
                    [...selectDeducteeList]
                )
            }
        }
      //eslint-disable-next-line
    }, [allSelected, localState, setSelectDeducteeList]);

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
    return (

        <>
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />
            <tr className={` ${trElement?.props['aria-rowindex'] % 2 === 0 ? 'white_background' : ''}`}>
                {rowArray.map((item, index) => {
                    if (item?.key === 'checkbox') {
                        return (
                            <td class="tds_listofdeducted_tb_overflowtext" width="20">
                                <input type="checkbox" disabled={(localState?.email && !emailError) ? false : true} tabIndex={-1}
                                    checked={(localState.deductee_id && selectDeducteeList.includes(localState.deductee_id)) ? true : false}
                                    onChange={() => selectRow(localState.deductee_id)}
                                />  </td>)
                    }
                    else if (item?.key === 'deductee_name') {
                        return (
                            <td class="tds_listofdeducted_tb_overflowtext" width="250">
                                <span data-toggle="tooltip" data-placement="top" title={localState?.deductee_name}>{localState?.deductee_name}</span>
                            </td>)
                    } else if (item?.key === 'deductee_pan') {
                        return <td className="tds_listofdeducted_tb_overflowtext text-center" width="150">{localState?.deductee_pan}</td>
                    } else if (item?.key === 'email') {
                        return (
                            <td class="tds_listofdeducted_tb_overflowtext " width="250" data-toggle='tooltip' data-original-title={emailError ? "Email Address is not Valid" : ""} >
                                <BlurOnChangeInput
                                    minLength={1}
                                    maxLength={100}
                                    value={localState?.email}
                                    onChange={(e) => {
                                        localState["email"] = e.target.value;
                                        setLocalState({ ...localState });
                                    }}
                                    placeholder="Enter Email id"
                                    className={`${localState?.email ? "input_has_value" : "without_input_value"} ${emailError ? "input-error" : ""} `}
                                    name="email"
                                    onBlur={(e) => updateDeducteeFun(e.target.name, e.target.value)}
                                />
                            </td>)
                    }
                    return <></>
                })
                }

            </tr>
        </>


    )
})

export default EmailPdfRow