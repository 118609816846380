import axios from 'axios';
import { BASE_URL_GO } from '../app/constants';
import { handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';



export async function getPanListToVerifyAtTracesViaConnector(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/traces/bulk-pan-verify-list`;  

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function verifySinglePanToConnector(getParams) {
  const url = `${BASE_URL_GO}/api/v1/usuc/traces/pan-verify${getParams}`;  

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getReturnStatement(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/traces/returns-to-fetch-statement`;  

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getUnconsumedChallanInfoToConnector(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/traces/unconsumed-challan-info`;  

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getPanListToVerifyAtTracesAndInsightsViaConnector(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/traces-insights/bulk-pan-verify-list`;  

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getNoticeOrderStatement(data) {
  const url = `${BASE_URL_GO}/api/v1/notice-order/get-pending-history`;  
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getLdcDataForTransaction(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/traces/return-ldc-verify-list`;  
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
