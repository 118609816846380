import React, { useEffect, useState } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone, formatAccountsNumber } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";
import moment from "moment";


const Fixed220Interest = (props) => {


    const { apiData } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);


    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    s_no: item[0],
                    type: item[1],
                    def_amt: item[2],
                    order_date: moment(item[3], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    due_date: moment(item[4], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    int_cons: item[5],
                    date_upto: moment(item[6], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    int_sec: item[7]
                })
            })
            return newData
        }
    };  


    const dataStateChange = (data_state) => {
        if (data_state.filter || (data_state.sort && data_state?.sort?.length > 0)) {
            const filter_state = deepClone(data_state);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                             if (["gte", "lte", 'lt', 'gt', "neq", "eq"].includes(itemObj?.operator) && !Array.isArray(itemObj.value)) {
                                
                                if(["order_date", "due_date", "date_upto"].includes(itemObj.field)){
                                    const date = moment(itemObj.value).format('DD/MM/YYYY');
                                    itemObj.value = moment(date, 'DD/MM/YYYY').format();
                                }else{
                                    itemObj.value = Number(itemObj.value);

                                }
                            }    
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                if(!["order_date", "due_date", "date_upto"].includes(itemObj.field)){                           
                                    itemObj.value = itemObj.value?.[0] ?? ""; 
                                }
                                                              
                            }else  if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                let array = []
                                item.logic = "or"
                                if (itemObj?.value?.length > 0) {
                                    itemObj?.value.forEach((keyValue) => {
                                        if(keyValue === 'NA') {
                                            array.push({ field: itemObj.field, operator: 'isNull', value: null })
                                        }else{
                                            array.push({ field: itemObj.field, operator: 'eq', value: Number(keyValue) })
                                        }                                        
                                    })
                                    item.filters = array;
                                }
                            }                            
                        })
                    }
                })
            }
            const dataProcess = process(inititalData, filter_state);
            setDataState({ ...data_state });
            setData(dataProcess.data ?? []);

        } else {
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData([...defaultData]);
            setDataState({});
        }     

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const calculateTotal = (index) => {
        let total = 0
        data.length > 0 && data.forEach((item) => {
            switch (index) {
                case "7":
                    total += item?.int_sec;
                    break;
                default:
                    total = 0
            }

        })
        return formatAccountsNumber(total)
    };

    const renderSubTextTitle = (title, subText) => {

        return (
            <div style={{ whiteSpace: 'break-spaces' }}>
                {title}
                {subText && (<div className="text-center">
                    {' '}<span className="b2btbtrthtextclr" style={{ paddingLeft: '20px' }}>{subText ?? 0}</span>
                </div>)}
            </div>
        );
    };

    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;
        const dataItem = props?.dataItem ?? {}

        return (
            <>
                <tr key={key} role="row">
                    <td className="text-center ">{dataItem?.s_no}</td>
                    <td className="text-left">{dataItem?.type ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.def_amt ?? 0) ?? "-"}</td>
                    <td className="text-center">{moment(dataItem?.order_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(dataItem?.order_date).format("DD/MM/YYYY") ?? "-"}</td>
                    <td className="text-center">{moment(dataItem?.due_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(dataItem?.due_date).format("DD/MM/YYYY") ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.int_cons ?? 0) ?? "-"}</td>
                    <td className="text-center">{moment(dataItem?.date_upto).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(dataItem?.date_upto).format("DD/MM/YYYY") ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.int_sec ?? 0) ?? "-"}</td>
                </tr>

                {key + 1 === data.length &&
                    <tr className="tdsjustification_rowshadow">
                        <td className="text-left">Total</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-right">
                            {calculateTotal("7")}
                        </td>
                    </tr>
                }
            </>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);


    return (
        <fieldset className="exptds_justformtype_intcons_fs">
            <legend className="exptds_justformtype_intcons_leg">
                <h4 className="exptds_justformtype_intcons_title">
                    Fixed 220(2) Interest
                </h4>
            </legend>

            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                <Tooltip anchorElement="pointer" position="top">
                    <Grid
                        className="table table-striped exptds_justformtype_table mb-0"
                        style={{ maxHeight: '530px', overflowX: 'auto' }}
                        data={data}
                        {...dataState}

                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                        filterOperators={{
                            text: [
                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                            ],
                            date: [
                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                { text: 'grid.filterEqOperator', operator: 'eq' },
                            ],
                            numeric: [
                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                            ],
                            textWithEmpty: [
                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                            ],
                        }}
                        rowRender={rowRender}
                        sortable
                    >
                        <Column
                            field="s_no"
                            title={`Sr. No.`}
                            width="40"
                        />
                        <Column
                            field="type"
                            headerClassName={isColumnActive('type') ? 'active' : ''}
                            title={`Default Type`}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                            filter="textWithEmpty"
                        />
                        <Column
                            field="def_amt"
                            headerClassName={isColumnActive('def_amt') ? 'active' : ''}
                            title={`Default Amount`}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="numeric"
                        />
                        <Column
                            field="order_date"
                            headerClassName={isColumnActive('order_date') ? 'active' : ''}
                            title={renderSubTextTitle('Date on which Order is Passed')}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="date"
                        />
                        <Column
                            field="due_date"
                            headerClassName={isColumnActive('due_date') ? 'active' : ''}
                            title={`Due Date of Payment`}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="date"
                        />
                        <Column
                            field="int_cons"
                            headerClassName={isColumnActive('int_cons') ? 'active' : ''}
                            title={`Interest Consumed`}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="numeric"
                        />
                        <Column
                            field="date_upto"
                            headerClassName={isColumnActive('date_upto') ? 'active' : ''}
                            title={renderSubTextTitle('Date upto which interest 220(2) is computed')}
                            width="140"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="date"
                        />
                        <Column
                            field="int_sec"
                            headerClassName={isColumnActive('int_sec') ? 'active' : ''}
                            title={renderSubTextTitle('Interest under section 220(2)')}
                            width="130"
                            columnMenu={(props) => <ColumnMenu {...props} />}
                            filter="numeric"
                        />

                        <GridNoRecords>
                            No Record Found
                        </GridNoRecords>

                    </Grid>
                </Tooltip>

            </div>
        </fieldset>
    )

};


export default Fixed220Interest;