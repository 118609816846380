import React, { useEffect, useState } from 'react';
import downloadIcon from "../../images/downloadiconblue.png";
import downloadRedIcon from "../../images/icons/export_icon.png";
import editIconBlue from "../../images/editiconblue.png";
import checkinIonBlue from '../../images/bluecheckiocn.png';
import pdfIcon from "../../images/pdficonblue.png";
import { MAX_IMPORT_FILE_SIZE_MB } from '../../app/constants';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import ExcelBulkPanVerification from '../deductorDashboard/returnsDashboard/ExcelBulkPanVerification';
import ShowAlert from '../../components/ShowAlert';
import { selectHeaderData } from '../header/headerSlice';
import { useSelector } from 'react-redux';
import ProgressBarForBulkPan from '../deductorDashboard/returnsDashboard/ProgressBarForBulkPAN';
import InsightsConnector from '../../components/kdkConnector/InsightsConnector';
import UploadExcelComplianceCheck from '../deductorDashboard/returnsDashboard/UploadExcelComplianceCheck';
import ProgressBarForComplianceCheck from '../deductorDashboard/returnsDashboard/ProgressBarForComplianceCheck';
import { downloadPANTemplate } from '../../api/deducteeAPI';
import { getDeductorInsightsCredentialsForConnector, getInProgressPanVerificationTask } from '../../api/deductorAPI';
import ConfirmPopup from '../../components/ConfirmPopup';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';


const BulkPanImport = (props) => {

    const { deductorId, setReloadPanList } = props;
    const headerDataDetails = useSelector(selectHeaderData);

    //status keys
    const sliderUniqueKey = `${headerDataDetails['mobile_number']}_${headerDataDetails['tan']}`;

    const statusKey = `${sliderUniqueKey}_BULK_PAN_VERIFICATION`;
    const coplianceStatusKey = `${sliderUniqueKey}_COMPLIANCE_CHECK`;

    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [port, setPort] = useState(0);
    const [startConnector, setStartConnector] = useState(false);
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [captchaRef, setCaptchaRef] = useState(null);
    const [startBulkPanVeification, setStartBulkPanVeification] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [bulkPanVerificationStatusData, setBulkPanVerificationStatusData] = useState(null);
    const [complianceCheckStatusData, setComplianceCheckStatusData] = useState(null);
    const [checkInsightsCredentials, setCheckInsightsCredentials] = useState(false);
    const [startComplianceCeckProcess, setStartComplianceCeckProcess] = useState("");
    const [insightsCaptchaRef, setInsightsCaptchaRef] = useState(null);
    const [requestforCheckCompliance, setRequestForCheckCompliance] = useState(false);
    const [verifyStatus, setVerifyStatus] = useState({ panStatus: true });
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [message, setMessage] = useState('');
    const [formErrors, setFormErrors] = useState(null);




    // const [isError, setIsError] = useState(false)
    const {
        acceptedFiles, getRootProps, getInputProps
    } = useDropzone({
        accept: '.xls,.xlsx,.xlsm,'
    });
    const onlyOneFileAllowedExtensions = /(\.xlsx|\.xlsm|\.xls)$/i;

    const removeFile = (targetFile) => {
        const newFiles = [];
        files.forEach((file) => {
            if (file.path === targetFile.path) return;
            newFiles.push(file);
        });
        setFormErrors(null)
        setFiles(newFiles);
    };
    const renderFiles = files.map((file) => {
        return (<li key={file.path} style={{ margin: '5px' }}>
            <span>
                {file.path}
                {' '}
                -
                {file.size}
                {' '}
                bytes
            </span>
            <Button
                variant="danger"
                style={{
                    fontSize: '12px', padding: '0px 5px 2px 5px', marginLeft: '10px',
                }}
                onClick={() => removeFile(file)}
            >
                Remove
            </Button>
        </li>)
    });

    const isAnyBulkPanVerificationProcessRunning = () => {
        if (JSON.parse(localStorage.getItem(statusKey)) !== null) {
            return true;
        }
        return false;
    }

    const isAnyComplianceCheckProcessRunning = () => {
        if (JSON.parse(localStorage.getItem(coplianceStatusKey)) !== null) {
            return true;
        }
        return false;
    }

    const downloadExcelTemp = async () => {
        setLoading(true);
        try {
            const result = await downloadPANTemplate();
            const csiData = new Blob([result], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            link.setAttribute('download', 'PAN_Upload_Template.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    const handleStartCompliance = async () => {
        const fetchRnuungComplianceRequestId = (deductorId) => {
            async function fetchEverything() {
                try {
                    setLoading(true);
                    const result = await getInProgressPanVerificationTask(`?deductor_id=${deductorId}`, false);
                    if (result?.data) {
                        setShowConfirmPopup(true);
                        setMessage(result?.message);
                    }
                } catch (err) {
                    if (err.code === 404) {
                        setStartConnector(true);
                    }
                } finally {
                    setLoading(false);
                }
            }
            fetchEverything();
        };
        fetchRnuungComplianceRequestId(deductorId)
    }

    const getInstghtCreditionals = async (deductorId) => {

        try {
            await getDeductorInsightsCredentialsForConnector(`?deductor_id=${deductorId}`, false);
            setVerifyStatus((prev) => {
                prev.compliance = true;
                return { ...prev };
            })

        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        const newFiles = acceptedFiles;
        const uniqueFiles = [];
        const flags = [];
        let xlsmxfile = [];
        let fileSize = 0;
        const l = newFiles.length;
        let i;
        for (i = 0; i < l; i++) {
            if (flags[newFiles[i].path]) continue;
            flags[newFiles[i].path] = true;
            uniqueFiles.push(newFiles[i]);
            xlsmxfile = [...files, ...uniqueFiles];
        }
        let checkfile = [];
        xlsmxfile.forEach((file) => {
            fileSize += file.size;
            if (onlyOneFileAllowedExtensions.exec(file.name)) {
                checkfile = [...checkfile, file.name];
            }
        });
        if (checkfile.length > 1) {
            return setFormErrors('Only one Excel file can be uploaded at a time.');
        } else if (fileSize / (1024 * 1024) > MAX_IMPORT_FILE_SIZE_MB) {
            fileSize = 0;
            return setFormErrors(`Maximum size of the files that can be uploaded is ${MAX_IMPORT_FILE_SIZE_MB} MB.`);
        }

        if (files.length) {
            setFiles([...files, ...uniqueFiles]);
        } else {
            setFiles([...uniqueFiles]);
        }

        //eslint-disable-next-line
    }, [acceptedFiles]);

    // We should get Localstorage captcha ref only once as statusKey is generated
    useEffect(() => {
        if (!statusKey) return;

        setBulkPanVerificationStatusData(JSON.parse(localStorage.getItem(statusKey)) ?? null);
    }, [statusKey]);

    useEffect(() => {
        if (!coplianceStatusKey) return;

        setComplianceCheckStatusData(JSON.parse(localStorage.getItem(coplianceStatusKey)) ?? null);
    }, [coplianceStatusKey]);

    useEffect(() => {
        getInstghtCreditionals(deductorId)
    }, [deductorId]);

    return (
        <>
            {showConfirmPopup &&
                <ConfirmPopup
                    show={showConfirmPopup}
                    handleClose={() => setShowConfirmPopup(false)}
                    confirmButtonText="Okay"
                    handleProceed={() => {
                        setShowConfirmPopup(false);
                        setStartConnector(true);
                    }}
                    confirmText={message}
                />
            }

            {startConnector
                ? (<ConnectorAdapter
                    show={startConnector}
                    handleClose={() => setStartConnector(false)}
                    setPort={setPort}
                    handleProceed={() => {
                        if(!verifyStatus.panStatus && verifyStatus.compliance){
                            setCheckInsightsCredentials(true);
                        }else{
                            setCheckTracesCredentials(true);
                        }
                        

                    }}
                />)
                : null}

            {checkTracesCredentials && port
                ? <TracesConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={() => null}
                    setVerifyCreds={() => {
                        setCheckTracesCredentials(false);
                        if ((verifyStatus.panStatus && !verifyStatus.compliance) || (!verifyStatus.panStatus && !verifyStatus.compliance)){
                             setStartBulkPanVeification(true);
                        }
                        else if (verifyStatus.panStatus && verifyStatus.compliance) {
                            setCheckInsightsCredentials(true);
                        }
                    }}
                    port={port}
                    setCaptchaRef={setCaptchaRef}
                    creds={null}
                    tan={headerDataDetails.tan ?? ""}
                />
                : null
            }

            {startBulkPanVeification &&
                <ExcelBulkPanVerification
                    deductorId={deductorId}
                    port={port}
                    fileData={files}
                    captchaRef={captchaRef}
                    setError={setError}
                    setLoading={setLoading}
                    setSuccess={(msg) => {
                        setSuccess(msg);
                    }}
                    statusKey={statusKey}
                    setBulkPanVerificationStatusData={setBulkPanVerificationStatusData}
                />
            }

            {checkInsightsCredentials && port
                ?
                <InsightsConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={(res) => {
                        setRequestForCheckCompliance(true);
                        setCheckInsightsCredentials(false);
                        setStartComplianceCeckProcess(true);

                    }}
                    port={port}
                    setInsightsCaptchaRef={setInsightsCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                    setLoading={setLoading}
                />
                : null
            }

            {insightsCaptchaRef && startComplianceCeckProcess &&
                <UploadExcelComplianceCheck
                    deductorId={deductorId}
                    port={port}
                    fileData={files}
                    captchaRef={captchaRef}
                    insightsCaptchaRef={insightsCaptchaRef}
                    setError={setError}
                    setSuccess={setSuccess}
                    setLoading={setLoading}
                    coplianceStatusKey={coplianceStatusKey}
                    setComplianceCheckStatusData={setComplianceCheckStatusData}
                    requestforCheckCompliance={requestforCheckCompliance}
                    isPanOtpStatus={verifyStatus.panStatus && verifyStatus.compliance ? "Y" : "N"}
                />
            }

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />            

            {loading && <LoadingIndicator />}

            <div className="col-xl-3 col-lg-5 col-md-5 col-sm-4 col-xs-12">
                <div className="card bulkpanstatus_cardboxshadow">
                    <h4 className="bulkpanstatus_wantuptextfs">Upload through Excel</h4>
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <table className="table table-borderless bulkpanstatus_updatethroexcel_tb mb-0">
                            <tbody>
                                <tr>
                                    <td width="90" className="pr-1">
                                        <span className="pr-1">
                                            <img src={downloadIcon} alt="download" width="19" style={{ cursor: "pointer" }} />
                                        </span>
                                        Step 1:
                                    </td>
                                    <td width="220" className="pl-0">Download the Excel</td>
                                    <td>
                                        <img src={downloadRedIcon} alt="download" style={{ cursor: "pointer" }} onClick={() => downloadExcelTemp()} width="19" />
                                    </td>
                                </tr>
                                <tr>
                                    <td width="90" className="pr-1">
                                        <span className="pr-1">
                                            <img src={editIconBlue} alt="editicon" width="19" style={{ cursor: "pointer" }} />
                                        </span>
                                        Step 2:
                                    </td>
                                    <td width="220" colspan="2" className="pl-0">Fill only PAN in the template</td>
                                </tr>
                                <tr>
                                    <td width="90" className="pr-1">
                                        <span className="pr-1">
                                            <img src={pdfIcon} alt="pdficon" width="19" style={{ cursor: "pointer" }} />
                                        </span>
                                        Step 3:
                                    </td>
                                    <td width="220" className="pl-0" colspan="2">Browse and Import the Filled template</td>
                                </tr>

                                <tr>
                                    <td colspan="3">
                                        <section {...getRootProps({ className: 'blukpanst_importdropzone dropzone dropzonestyle' })} >
                                            <input
                                                type="file" name="book_file" id="book_file"
                                                {...getInputProps({ accept: '.xls,.xlsx,.xlsm', multiple: false })} autocomplete="off"
                                                tabindex="-1" style={{ display: "none" }} />
                                            <p className="font11Cent mb-0">Click here to Browse or Drag n Drop  the Updated File </p>
                                            <p className="font11Cent">Import Format Supported: .xlsx, .xls, .xlsm</p>
                                        </section>
                                    </td>

                                </tr>

                                <tr>
                                    <td width="90" className="pr-1" rowspan="2">
                                        <span className="pr-1">
                                            <img src={checkinIonBlue} width="16" alt="" style={{ cursor: "pointer" }} />
                                        </span>
                                        Step 4:
                                    </td>
                                    <td className="pl-0" colspan="2">
                                        <div className="form-check-inline">
                                            <label className="form-check-inline" for="">
                                                <input type="checkbox" className="form-check-input"
                                                    checked={verifyStatus.panStatus ? true : false}
                                                    onChange={(e) => {
                                                        setVerifyStatus((prev) => {
                                                            prev.panStatus = e.target.checked;
                                                            return { ...prev }
                                                        })
                                                    }}
                                                /> Verify PAN status
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>

                                    <td className="pl-0" colspan="2">
                                        <div className="form-check-inline">
                                            <label className="form-check-inline" for="">
                                                <input type="checkbox" className="form-check-input"
                                                    checked={verifyStatus.compliance ? true : false}
                                                    onChange={(e) => {
                                                        setVerifyStatus((prev) => {
                                                            prev.compliance = e.target.checked;
                                                            return { ...prev }
                                                        })
                                                    }}
                                                /> Verify Compliance 206CCA/AB
                                            </label>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12">
                        <p className="bulkpanstatus_fileselectedtext mb-1">File Selected:</p>
                        <ul className="mb-0" style={{ fontSize: '12px' }}>{renderFiles}</ul>
                        {formErrors && <span style={{ color: "red", fontSize: "11px" }}>{formErrors}</span>}
                    </div>

                    <div className="col-md-12 text-center">
                        {(!isAnyBulkPanVerificationProcessRunning() && !isAnyComplianceCheckProcessRunning() && files.length > 0) ? <button className="blukpanstatus_stimport"
                            onClick={() => {

                                if (verifyStatus.compliance) {
                                    handleStartCompliance();
                                } else setStartConnector(true);

                            }}>
                            Start Verification
                        </button>
                            :
                            <button className="blukpanstatus_stimport disabled" >
                                Start Verification
                            </button>}
                    </div>
                </div>
                
            </div>
            <div className="col-md-9 mt-4 mr-4" >
                {bulkPanVerificationStatusData?.captcha_ref
                    ?
                    <ProgressBarForBulkPan
                        bulkPanVerificationStatusData={bulkPanVerificationStatusData}
                        statusKey={statusKey}
                        setReloadPanList={setReloadPanList}
                        setStartBulkPanVeification={setStartBulkPanVeification}
                        setBulkPanVerificationStatusData={setBulkPanVerificationStatusData}
                    />
                    : null}

                {complianceCheckStatusData?.insights_captcha_ref
                    ?
                    <ProgressBarForComplianceCheck
                        complianceCheckStatusData={complianceCheckStatusData}
                        coplianceStatusKey={coplianceStatusKey}
                        setReloadPanList={setReloadPanList}
                        setError={setError}
                    />
                    : null}
            </div>

        </>
    )
}

export default BulkPanImport;