import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './ConfirmAlertPopup.scss';

const ConfirmAlertPopup = (props) => {
    const {
        show, handleClose, handleProceed, confirmText, confirmButtonText, confirmButtonText2, handleProceed2, title, posclass,
        confirmButtonTextStyle, confirmButtonText2Style
    } = props;

    return (
        <Modal className={`alertbox_popup ${posclass ?? ""}`} show={show} onHide={handleClose}>
            {title && <Modal.Header className="alertbox_popup_header" closeButton>{title ?? ""}</Modal.Header>}
            <Modal.Body style={{ padding: "1rem" }}>
                <div class="row">
                    <div class="col-lg-12">
                        <p class="alreadyfiled_textfs">
                            {confirmText ?? ""}
                        </p>
                    </div>

                    <div class="col-lg-12 text-center">
                        {
                            confirmButtonTextStyle && confirmButtonText2Style
                                ?
                                <>
                                    <button style={{ ...confirmButtonTextStyle }} class="btn btn-default yes_btnbgclr_fs mr-2" onClick={() => handleProceed()}>{confirmButtonText ?? "Yes"}</button>
                                    <button style={{ ...confirmButtonText2Style }} class="btn btn-default yes_btnbgclr_fs" onClick={() => handleProceed2()}>{confirmButtonText2 ?? "No"}</button>
                                </>
                                :
                                <>
                                    <button style={{ backgroundColor: '#f2f2f2', color: '#636363' }} class="btn btn-default yes_btnbgclr_fs mr-2" onClick={() => handleProceed()}>{confirmButtonText ?? "Yes"}</button>
                                    <button class="btn btn-default yes_btnbgclr_fs" onClick={() => handleProceed2()}>{confirmButtonText2 ?? "No"}</button>
                                </>

                        }

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmAlertPopup