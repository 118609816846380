/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Pagination.css';


export default function ShowMorePagination(props) {
    const { cursor, postData, loading, fetchData } = props;

    if(cursor?.next_page) cursor.next_page_url = cursor.next_page;

    const showMore = () => {
        let data = postData;
        data.page = cursor.current_page + 1;
        fetchData(data);
    };

    if (!cursor.next_page_url) {
        return null;
    }

    return (
        <div className="col-md-12 text-center">
            <div className="showmorebox">
                {loading ? (
                    <span>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        {' '}
                    Loading...
                    </span>
                ) : <span onClick={showMore} style={{ cursor: 'pointer' }}> Show More</span>}
            </div>
        </div>
    );
}
