import moment from 'moment';

export function getFinancialYearsList(year = null) {
  const startYear = year ? year : 2020;
  const years = [];
  let endYear = parseInt(getCurrentFinancialYear());
  while (endYear >= startYear) {
    let next_year = endYear + 1;
    years.push({ text: `${endYear}-${next_year.toString().substr(2, 2)}`, value: `${endYear}` });
    endYear = endYear - 1;
  }
  return years;
}

export function getCurrentFinancialYear() {
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('M');
  if (currentMonth > 3) return parseInt(currentYear, 10);

  return parseInt(currentYear, 10) - 1;
}

