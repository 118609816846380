import React, { useState } from 'react'
import $ from "jquery";
import { convertNum, formatNumber } from '../../utils/UtilityFunctions'
import { ALLOWANCEEXEMPT_DROPDOWN, converArrayObj_to_map, OTHERINCOMEOFFERED_DROPDOWN, perquisites_17_2DROPDOWN, profit_17_3DROPDOWN, RETIRMENTBENFIT_DROPDOWN, SALARY_17_1DROPDOWN } from './salaryHelper';
import calcIcon from "../../images/calculator.png";
import ViewOtherDetails from './ViewSalary/ViewOtherDetails';
import ViewFixedInput from './ViewSalary/ViewFixedInput';
import ViewIncOthSrc from './ViewSalary/ViewIncOthSrc';
import ViewIncHouseProperty from './ViewSalary/ViewIncHouseProperty';
import ViewPrevEmployer from './ViewSalary/ViewPrevEmployer';
import ViewDeduction from './ViewSalary/ViewDeduction';
import ViewProfitSalary from './ViewSalary/ViewProfitSalary';
import ViewPrequisitesDetails from './ViewSalary/ViewPrequisitesDetails';
import ViewSalary17one from './ViewSalary/ViewSalary17one';
import ViewPrequisitesLite from './ViewSalary/ViewPrequisitesLite';
import salaryanngledown from "../../images/allcol_blue_down.png";
import salaryannudangle from '../../images/allcol_blue_up.png';


const ViewSalaryDetail = (props) => {
    const { data, drowdownList, USER_TYPE } = props;

    const taxRegime = data.tax_regime ?? "";
    const headingCodeLIst17one = converArrayObj_to_map(SALARY_17_1DROPDOWN, "particular_code");
    const headingCodeListAllowance = converArrayObj_to_map(ALLOWANCEEXEMPT_DROPDOWN?.[USER_TYPE] ?? [], "allowance_type_id");
    const headingCodeListRetirbenfit = converArrayObj_to_map(RETIRMENTBENFIT_DROPDOWN ?? [], "allowance_type_id");
    const headingCodeList17three = converArrayObj_to_map(profit_17_3DROPDOWN, "particular_code");
    const headingCodeListothincoff = converArrayObj_to_map(OTHERINCOMEOFFERED_DROPDOWN, "particular_code");
    const headingCodeListdedVI_A = converArrayObj_to_map(drowdownList?.deductionVI_A?.[taxRegime] ?? [], "code");
    const headingCodeList17two = converArrayObj_to_map(perquisites_17_2DROPDOWN, "particular_code");

    const [showAll, setShowAll] = useState(true);

    const toggleAll = () => {
        if (showAll) {
            $('.salayannexureII .collapse').collapse('hide');
        } else {

            $('.salayannexureII .collapse').collapse('show');
        }
        setShowAll(!showAll);
    }

    const calculateTotal = (calcData, key) => {
        let total = 0;
        if (calcData?.length > 0) {

            total = calcData.reduce((prev, curr) => {
                return prev += convertNum(curr?.[key] ?? 0);
            }, 0);

        }

        return formatNumber(total);
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <fieldset className="salaryannux_details_fs">
                        <legend className="salaryannux_details_leg mb-0 pb-0">
                            <span className="salaryannux_details_allcall cursor_pointer">
                                <img src={showAll ? salaryannudangle : salaryanngledown} width={11} alt='' onClick={() => toggleAll()} />
                            </span>
                        </legend>
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs salayannexureII">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">

                                <tbody>
                                    <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                                        <th width="10" id="accordion" className="text-left accordion">
                                            <span className="salarytaxregime_edit_mastercollapse " data-toggle="collapse" href="#salarytaxregime_edit_mastercollap01" aria-expanded="true"></span>

                                        </th>
                                        <th width="550" className="text-left">
                                            Income from Salaries
                                        </th>
                                        <th width="150" className="text-right">
                                            {formatNumber(data.net_salary_amtp ?? 0)}
                                        </th>
                                    </tr>
                                    <tr className="hide-table-padding">
                                        <td colspan="15" className="p-0">
                                            <div id="salarytaxregime_edit_mastercollap01" className="in collapse show">

                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                                                    <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                                        <tbody>
                                                            <ViewSalary17one
                                                                data={data} headingCodeLIst17one={headingCodeLIst17one} USER_TYPE={USER_TYPE} taxRegime={taxRegime}
                                                                headingCodeListAllowance={headingCodeListAllowance} calcIcon={calcIcon}
                                                                headingCodeListRetirbenfit={headingCodeListRetirbenfit}
                                                            />
                                                            {USER_TYPE === "Details" ?
                                                                <ViewPrequisitesDetails data={data} headingCodeList17two={headingCodeList17two} />
                                                                : <ViewPrequisitesLite data={data} headingCodeList17two={headingCodeList17two} />}

                                                            <ViewProfitSalary data={data} headingCodeList17three={headingCodeList17three} />

                                                            <ViewDeduction data={data} />

                                                            <ViewPrevEmployer data={data} />

                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div>
                                        </td>
                                    </tr>

                                    <ViewIncHouseProperty data={data} calcIcon={calcIcon} />

                                    <ViewIncOthSrc data={data} headingCodeListothincoff={headingCodeListothincoff} />

                                    <ViewOtherDetails data={data} headingCodeListdedVI_A={headingCodeListdedVI_A} calculateTotal={calculateTotal} />

                                    <ViewFixedInput data={data} />

                                </tbody>

                            </table>
                        </div>
                    </fieldset>

                </div>
            </div>
        </>
    )
}

export default ViewSalaryDetail