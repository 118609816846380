import { Skeleton } from '@mui/material'
import React from 'react'

const Skeleton6ColumnGrid = (props) => {

  let rowNo = props?.rowNo ?? 5


  return (
    <div className=" row ">
      <div className='col-md-2'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-2'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-2'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-2'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-2'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
      <div className='col-md-2'>
        {[...Array(rowNo)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
      </div>
    </div>

  )
}

export default Skeleton6ColumnGrid