/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { AddDeducteeTransactionsIcon, AddSyncIcon } from '../../components/grid/CustomCell';
import { getter } from "@progress/kendo-react-common";
import { Tooltip } from '@progress/kendo-react-tooltip';
import queryString from 'query-string';
// import './DeducteeList.css';
import './DeducteeListV2.scss';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import editIcon from '../../images/icons/edit.svg';
import AddDeductee from './AddDeductee';
import EditDeductee from './EditDeductee';
import {
    getDeducteesV2, updateDeductee, deducteeMasterSummary
} from '../../api/deducteeAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { BASE_PATH } from '../../app/constants';
import { selectHeaderData } from '../../features/header/headerSlice';
import ImportDeductee from './importDeductee/ImportDeductee';
import { exportDeductee } from '../../api/deducteeAPI';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import ProgressBarForPAN from '../../components/verifyPAN/ProgressBarForPAN';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import BulkPanVerify from '../../components/verifyPAN/BulkPanVerify';
import { getCurrentFinancialYear } from '../../utils/UtilityFunctions';
import ImportIcon from '../../images/icons/import_icon.png';
import ExportIcon from '../../images/icons/export_icon.png';
import searchIcon from '../../images/search.png';
import BlurOnChangeInput from '../../components/BlurOnChangeInput';
import { getInProgressPanVerificationTask } from '../../api/deductorAPI';
import ConfirmPopup from '../../components/ConfirmPopup';
import InsightsConnector from '../../components/kdkConnector/InsightsConnector';
import UploadPanListComplianceCheck from '../deducteeTransactionsAndChallan/UploadPanListComplianceCheck';
import CustomMultiSelect from '../../components/multiSelect/MultiSelect';
import Skeleton12ColumnGrid from '../../components/Skeleton/Skeleton12ColumnGrid';
import Spinner from 'react-bootstrap/Spinner';
import InsightsConnectorWithCaptcha from '../../components/kdkConnector/InsightsConnectorWithCaptcha';
import DeducteeListV2ImportProgress from './DeducteeListV2ImportProgress';
import { createImportRegularDeducteeReturnLocalStorageKey } from '../../utils/UtilityFunctions';
import { getTracesCaptchaFromConnector } from '../../api/localConnectorAPI';
import SinglePanVerify from '../../components/verifyPAN/SinglePanVerify';
import DeducteeListV2MultiplePanVerify from './DeducteeListV2MultiplePanVerify';
import DeducteeListV2ComplianceCheck from './DeducteeListV2ComplianceCheck';


const DeducteeListV2 = ({ history, ...props }) => {

    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);

    const deductorId = params?.deductor_id;

    const DATA_ITEM_KEY = "deductee_id";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const { componentImport } = props;

    const headerDataDetails = useSelector(selectHeaderData);
    const finanicalYear = headerDataDetails?.financial_year_formatted ? headerDataDetails?.financial_year_formatted?.split("-")?.[0] : getCurrentFinancialYear();

    //status keys
    const sliderUniqueKey = `${headerDataDetails['mobile_number']}_${headerDataDetails['tan']}`;

    const coplianceStatusKey = `${sliderUniqueKey}_COMPLIANCE_CHECK`;

    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editDeductee, setEditDeductee] = useState({});
    const [loading, setLoading] = useState(false);
    const [deducteeList, setDeducteeList] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [currentDeductee, setCurrentDeductee] = useState(0);
    const [horizontalView, setHorizontalView] = useState(false);
    const [showBulkPAN, setShowBulkPAN] = useState(false);
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [dataState, setDataState] = useState({});
    const [checkedDeducteeData, setCheckedDeducteeData] = useState({});
    const [allCheckData, setAllCheckData] = useState(false);
    const [selectedDeducteeCount, setSelectedDeducteeCount] = useState(0);
    const [panArray, setPanArray] = useState([]);
    const [filterPostData, setFilterPostData] = useState(false);
    const [deducteeListKeyWise, setDeducteeListKeyWise] = useState([]);
    const [showImportDeductee, setShowImportDeductee] = useState(false);
    // const [showErrorImport, setShowErrorImport] = useState(false);
    const [deducteeSummary, setDeducteeSummary] = useState(null);

    const [port, setPort] = useState(0);
    const [captchaRef, setCaptchaRef] = useState(null);
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [bulkPanVerificationStatusData, setBulkPanVerificationStatusData] = useState(null);
    const [reloadPanList, setReloadPanList] = useState(false);
    const [disableBulkPanVerificationButton, setDisableBulkPanVerificationButton] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [showConfirmPopupForPan, setShowConfirmPopupForPan] = useState(false);
    const [confirmPopupForCompliance, setConfirmPopupForCompliance] = useState(false);
    const [startConfirmPopupConnector, setStartConfirmPopupConnector] = useState(false);
    const [requestforCheckCompliance, setRequestForCheckCompliance] = useState(false);
    const [startConnector, setStartConnector] = useState(false);
    const [insightCredsForPopup, setInsightCredsForPopup] = useState(false);
    const [recheckCompliance, setRecheckCompliance] = useState(false);
    const [insightsCaptchaRef, setInsightsCaptchaRef] = useState(null);
    const [complianceCheckStatusData, setComplianceCheckStatusData] = useState(null);
    const [checkInsightsCredentials, setCheckInsightsCredentials] = useState(false);
    const [startComplianceCeckProcess, setStartComplianceCheckProcess] = useState("");
    const [verifyButton, setVerifyButton] = useState("");
    const [selectedDeducteeType, setSelectedDeducteeType] = useState([]);
    const [errorClass, setErrorClass] = useState("");
    const [showMsg, setShowMsg] = useState(false);
    const [scrollPagnation, setScrollPagnation] = useState(false);
    const [skeletonList, setSkeletonList] = useState(true);
    const [startConnectorForSinglePAN, setStartConnectorForSinglePAN] = useState(false);
    const [singlePan, setSinglePAN] = useState(null);
    const [section, setSection] = useState(null);
    const [complianceCheck, setComplianceCheck] = useState(null);
    const [showProgressResult, setShowProgressResult] = useState(false);
    const [startConnectorForSinglePANVerify, setStartConnectorForSinglePANVerify] = useState(false);
    const [verifyPan, setVerifyPan] = useState(false);
    const [type, setType] = useState("");
    const [startMultiPanVerification, setStartMultiPanVerification] = useState(false);
    const [iconDisabled, setIconDisabled] = useState(false);
    const [result, setResult] = useState(false);

    const limit = 17;

    const statusKey = `${headerDataDetails['mobile_number']}_${headerDataDetails['tan']}_${headerDataDetails['financial_year_formatted']}_${headerDataDetails['form_type']}_${headerDataDetails['quarter']}`;

    // We should get Localstorage captcha ref only once as statusKey is generated
    useEffect(() => {
        if (!statusKey) return;

        setBulkPanVerificationStatusData(JSON.parse(localStorage.getItem(statusKey)) ?? null);
    }, [statusKey]);


    const PAN_LIST = [
        { label: 'PAN APPLIED', value: 'PANAPPLIED' },
        { label: 'PAN INVALID', value: 'PANINVALID' },
        { label: 'PAN NOT AVAILABLE', value: 'PANNOTAVBL' },
    ];

    const STATUS_LIST = [
        { label: 'Operative', value: 'PAN_AVAILABLE_AND_VERIFIED' },
        { label: 'Inoperative', value: 'PAN_AVAILABLE_BUT_INACTIVE' },
        { label: 'Unverified', value: 'PAN_AVAILABLE_BUT_UNVERIFIED ' },
        { label: 'Invalid', value: 'PAN_INVALID' },
        { label: 'PANNOTAVBL', value: 'PAN_NOT_AVAILABLE' },
    ];

    const STATUS_AGE = [
        { label: '< 3 Months', value: 'LTQTR' },
        { label: '> 3 Months', value: 'GTQTR' },
        { label: '< 1 Year', value: 'LT1YR' },
        { label: '> 1 Year', value: 'GT1YR' },
    ];

    const COMPLIANCE_STATUS = [
        { label: 'Regular Filer', value: 'REGULAR FILER' },
        { label: 'Non Filer', value: 'NON FILER' },
        { label: 'Invalid', value: 'INVALID' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Unverified', value: 'UNVERIFIED' },
    ]

    const DEDUCTEE_CATEGORY = [
        { label: 'Individual', value: 'INDIVIDUAL_02', val: '02' },
        { label: 'Hindu Undivided Family', value: 'HUF_03', val: '03' },
        { label: 'Firm', value: 'FIRM_07', val: '07' },
        { label: 'Company-Other than domestic company', value: 'COMPANY_01', val: '01' },
        { label: 'Association of Persons (AOP)-No Company Member', value: 'AOP_04', val: '04' },
        { label: 'Association of Persons (AOP)-Only Company Members', value: 'AOP_05', val: '05' },
        { label: 'Co-operative Society', value: 'COOP_06', val: '06' },
        { label: 'Body of Individuals', value: 'BOI_08', val: '08' },
        { label: 'Artificial Juridical Person-AJP', value: 'AJP_09', val: '09' },
        { label: 'Others', value: 'OTH_10', val: '10' },
    ];

    const ACTIVITY_STATUSES = [
        {
            label: "Operative",
            color: "#8bc34a",
            icon: faCircle,
            class: "iocnfsuppot"
        },
        {
            label: "Unverified",
            color: "#f54337",
            icon: faCircle,
            class: "iocnfsuppot"
        },
        {
            label: "Inoperative",
            color: "#f54337",
            icon: faCircle,
            class: "iocnfsuppot"
        },
        {
            label: "Invalid",
            color: "#f54337",
            icon: faCircle,
            class: "iocnfsuppot"
        },
        {
            label: "PANNOTAVBL",
            color: "#fdd735",
            icon: faCircle,
            class: "iocnfsuppot"
        },

    ];


    const DEDUCTEE_TYPE_ARRAY = ['COMPANY_01', 'INDIVIDUAL_02', 'HUF_03', 'AOP_04', 'AOP_05', 'COOP_06', 'FIRM_07', 'BOI_08', 'AJP_09', 'OTH_10'];

    const d = new Date();
    let year = d.getFullYear();

    const getFYsForDropDown = function (val) {
        const FYs = [];
        let startYear = 2020;
        const currentYear = year;
        let endYear = parseInt(currentYear, 10);
        while (startYear <= endYear) {
            FYs.push({
                label: `${startYear}-${(startYear + 1).toString().slice(2, 4)}`,
                value: startYear,
            });
            startYear += 1;
        }

        FYs.sort((a, b) => (a.key < b.key ? 1 : -1));

        return FYs;
    }

    const yearDropdown = getFYsForDropDown();

    $("input,select").bind("keydown", function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13 && e?.target?.id !== "searchStr") {
            e.preventDefault();
            $('input, select, textarea')
            [$('input,select,textarea').index(this) + 1]?.focus();
        }
    });


    const addErrorObject = (data) => {
        data && data.length > 0 && data.forEach((item) => {
            if (item.errorObj === undefined) {
                item.errorObj = { deductee_name: null, pan: null, email: null }
            }
        })
        return data
    }

    const getDeducteeLists = (data, page) => {
        async function fetchEverything() {
            data.page = page ?? '1'
            async function fetchDeductees() {
                const result = await getDeducteesV2(data);
                let new_deducteeList = result?.data?.data ?? [];
                if (result.data.cursor.current_page > 1) {
                    setDeducteeList(addErrorObject(deducteeList.concat(result?.data?.data ?? [])));
                } else {
                    setDeducteeList(addErrorObject(result?.data?.data ?? []));
                }
                setCursor(result?.data?.cursor);

                const checkedData = checkedDeducteeData;
                const keyWiseData = {};

                new_deducteeList.map((deductee) => {
                    checkedData[deductee.deductee_id] = false;
                    keyWiseData[deductee.deductee_id] = deductee;
                    return true;
                });
                setCheckedDeducteeData(checkedData);
                setDeducteeListKeyWise(keyWiseData);
            }
            try {
                await Promise.all([
                    fetchDeductees(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
                setShowMoreLoading(false);
                setScrollPagnation(false);
                setSkeletonList(false);
            }
        }

        data.page && data.page > 1 && setShowMoreLoading(true);
        fetchEverything();
    };


    const getDeducteeMasterSummary = (data) => {

        async function fetchSummary() {
            try {
                const result = await deducteeMasterSummary(data);
                setDeducteeSummary(result?.data ?? {})
            } catch (err) {
                setError(err)
            }
        }
        fetchSummary()
    };

    const handleChange = (key, keyName, value) => {
        deducteeList[key][keyName] = value;
        setDeducteeList((prev) => {
            prev = deducteeList
            return [...prev]
        })
    };

    const isValidPan = (InputPan) => {
        let pan = InputPan.toUpperCase()
        let regexPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        return regexPan.test(pan);
    };

    const isValidEmail = (InputEmail) => {
        let regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regexEmail.test(InputEmail);
    }

    const saveData = (deducteeInfo, keyName, e, key) => {

        e.preventDefault();
        let isValidate = true;
        let regexName = /^[a-zA-Z].*/;

        if (keyName === "deductee_name") {
            if (e.target.value.trim().length === 0) {
                deducteeInfo.errorObj.deductee_name = 'This field is required';
                isValidate = false;
            } else if (e.target.value.trim().length > 0 && !regexName.test(String(e.target.value).trim())) {
                deducteeInfo.errorObj.deductee_name = 'Invalid Deductee Name';
                isValidate = false;
            }
            setErrorClass("input-error");
            setShowMsg(true);
        }

        if (keyName === "pan") {
            if (parseInt(e.target.value.trim().length) === 0) {
                deducteeInfo.errorObj.pan = 'This field is required';
                isValidate = false;
            } else if (((e.target.value.trim().length > 0 && e.target.value.trim().length !== 10) || !isValidPan(e.target.value))) {
                deducteeInfo.errorObj.pan = 'Invalid PAN';
                isValidate = false;
            }

            setErrorClass("input-error");
            setShowMsg(true);
        }

        if (keyName === "email" && (e.target.value.trim().length === 0 || !isValidEmail(e.target.value))) {
            deducteeInfo.errorObj.email = 'Invalid Email';
            isValidate = false;
            setErrorClass("input-error");
            setShowMsg(true);
        }

        handleChange(key, keyName, e.target.value);

        async function fetchEverything() {
            const payload = {
                deductee_id: deducteeInfo.deductee_id,
                deductor_id: deductorId,
                [keyName]: e.target.value.trim()
            }

            try {
                if (e.target.value !== '' && isValidate) {
                    const result = await updateDeductee(payload);
                    if (result?.data) {
                        setSuccess(result.message);
                        setErrorClass('');
                        setShowMsg(false);
                        deducteeInfo.errorObj.deductee_name = null;
                        deducteeInfo.errorObj.pan = null;
                        deducteeInfo.errorObj.email = null;
                    }
                }
            }
            catch (err) {
                if (keyName === "pan") {
                    handleChange(key, keyName, e.target["valueBeforeFocus"]);
                }
                setError(err)
            }
        }
        fetchEverything();
    };

    useEffect(() => {
        const postData = getParamsHistoryUrl();
        getDeducteeLists(postData);
        // checkErrorImport();
        getDeducteeMasterSummary(postData);
        setSearchTerm(params.filter_deductor_name ?? '');

    }, [searchStr]);

    const getParamsHistoryUrl = () => {

        const postData = {
            search_text: params.filter_deductor_name,
            deductor_id: deductorId,
            sort_on: params.sort_on ? params.sort_on : '',
            sort_by: params.order_by ?? "",
        };

        if (params.return_id) {
            postData.return_id = params.return_id;
        }

        if (params.deductee_type) {
            postData.deductee_type = params.deductee_type;
            const deductee_types = params.deductee_type.split(',');

            const deducteeType = [];


            if (deductee_types.length > 0) {
                deductee_types.forEach((item) => {
                    if (DEDUCTEE_TYPE_ARRAY.includes(item)) {
                        deducteeType.push(item);
                    }
                })
            }
            setSelectedDeducteeType(deducteeType);
        }

        if (params.sort) {
            dataState.sort = JSON.parse(atob(params.sort));
            postData.sort = JSON.parse(atob(params.sort));
        }
        if (params.filters) {
            dataState.filter = { filters: [] };
            dataState.filter.filters = JSON.parse(atob(params.filters));
            postData.filters = JSON.parse(atob(params.filters));
        }
        if (params.page) {
            postData.page = params.page;
        }
        if (params.action) {
            postData.action = params.action.split(",");
        }
        postData.limit = params.limit ?? limit;
        setDataState(dataState);
        setFilterPostData(postData);
        return postData;
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        let deductee_type = [];
        let action = '';
        if (params.deductee_type) {
            deductee_type = params.deductee_type.split(",");
        }
        if (params.action) {
            action = params.action;
        }
        createHistoryUrl(dataState, action, deductee_type);
    };

    const handleOnChange = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value === '') {
            let searchStr = `?filter_deductor_name=${encodeURIComponent(event.target.value)}`;
            params && Object.keys(params).forEach((key) => {
                if (key && !['filter_deductor_name'].includes(key)) {
                    searchStr = `${searchStr}&${key}=${params[key]}`;
                }
            });

            history.push(searchStr);
        }
    };

    const createHistoryUrl = (dataState = [], action = '', deductee_type = []) => {

        let filterStr = `?deductor_id=${deductorId}`;

        if (params.return_id) {
            filterStr += `&return_id=${params.return_id}`;
        }
        if (params.tab) {
            filterStr += `&tab=${params.tab}`;
        }
        if (searchTerm) {
            filterStr += `&filter_deductor_name=${encodeURIComponent(searchTerm)}`;
        }

        if (deductee_type.length > 0) {
            filterStr += `&deductee_type=${deductee_type.join(",")}`;
        }

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${dataState.sort[0].field}&order_by=${dataState.sort[0].dir}`;
        }
        history.push(filterStr);
    };


    const changeFilter = (value) => {

        let selectedFilters = [];

        setSelectedDeducteeType((prev) => {
            prev = value
            return prev
        });

        selectedFilters = [...value];

        createHistoryUrl(dataState, '', selectedFilters);
    };

    const handleSubmit = (event) => {
        event && event.preventDefault();

        let newSearchItem = searchTerm.trim();
        if (searchTerm.length > 0 && !newSearchItem) {
            return setSearchTerm("");

        }

        if (newSearchItem.length > 75) {
            setError('The filter deductor name can not be greater than 75 characters!');
            return;
        }

        let searchStr = `?filter_deductor_name=${encodeURIComponent(newSearchItem)}`;
        params && Object.keys(params).forEach((key) => {
            if (key && !['filter_deductor_name'].includes(key)) {
                searchStr = `${searchStr}&${key}=${params[key]}`;
            }
        });
        history.push(searchStr);

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };


    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
        $('[data-toggle="tooltip"]').click(() => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        });

        if (params.page) delete params.page;
        if (params.open && params.open === 'create-deductee') {
            setShowCreateForm(true);
            delete params.open;
        }
        if (params.open && params.open === 'bulk-pan-verification') {
            setShowBulkPAN(true);
            delete params.open;
            history.replace(params);
        }
    }, [searchStr, deductorId]);


    const updateDeducteeInfo = (deducteeInfo, view = false) => {
        setLoading(true);
        setShowEditForm(false);
        setEditDeductee(deducteeInfo);
        setHorizontalView(view);
        setTimeout(function () { setShowEditForm(true); setLoading(false); }, 1);
    };


    const updateDeducteeStatus = (deducteeInfo, deducteeStatus) => {
        setError(null);
        setSuccess(null);
        const deducteeData = {
            deductee_id: deducteeInfo.deductee_id,
            deductor_id: deductorId,
            deductee_status: deducteeStatus ? 1 : 0,
        };

        if (params.return_id) {
            deducteeData.return_id = params.return_id;
        }

        deducteeInfo.deductee_status = deducteeStatus;

        const index = _.findIndex(deducteeList, { deductee_id: deducteeInfo.deductee_id });

        // Replace item at index using native splice
        deducteeList.splice(index, 1, deducteeInfo);
        setDeducteeList(deducteeList);

        async function callAPI() {
            async function createDeductee2() {
                const result = await updateDeductee(deducteeData);
                return result;
            }
            try {
                const result = await createDeductee2();
                setSuccess(result.message);
                setTimeout(() => {
                    setSuccess(null);
                }, 3000);
            } catch (err) {
                console.error('error: ', err);
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        callAPI();
    };


    const toggleAllSelect = (checkedD = {}) => {
        const unMatchedSelect = { ...checkedDeducteeData, ...checkedD };

        _.map(unMatchedSelect, (value, key) => {
            unMatchedSelect[key] = !allCheckData;
        });

        if (!allCheckData) {
            setSelectedDeducteeCount(Object.keys(unMatchedSelect).length);
        } else {
            setSelectedDeducteeCount(0);
        }

        setCheckedDeducteeData(unMatchedSelect);
        setAllCheckData(!allCheckData);
    };

    const handleDeducteeToggle = (event) => {
        let key = event.dataItem.deductee_id;
        const newValue = !checkedDeducteeData[key];
        setCheckedDeducteeData({ ...checkedDeducteeData, [key]: newValue });
        let allMatched = true;

        _.map(checkedDeducteeData, (value, key1) => {
            if (key1 !== key && value !== newValue) {
                allMatched = false;
            }
        });

        if (newValue) {
            setSelectedDeducteeCount(selectedDeducteeCount + 1);
            panArray.push(event.dataItem.pan)
            setSinglePAN(event.dataItem.pan)
        } else {
            setSelectedDeducteeCount(selectedDeducteeCount - 1);
            panArray.pop(event.dataItem.pan)
            // setPanArray(panArray.pop(event.dataItem.pan));
        }

        if (allMatched && newValue) {
            setAllCheckData(true);
        } else {
            setAllCheckData(false);
        }
    };

    const onClose = (reload, msg = '') => {
        setShowCreateForm(false);
        setShowEditForm(false);
        if (msg) {
            setSuccess(msg);
        }

        if (reload) {
            setTimeout(() => {
                history.go();
            }, 300);
        }
    };

    const getStatuColorCode = (pan_status) => {
        switch (pan_status) {
            case 'PAN_AVAILABLE_AND_VERIFIED': return { label: 'Operative', color: '#8bc34a' };
            case null:
            case 'PAN_AVAILABLE_BUT_UNVERIFIED': return { label: 'Unverified', color: '#f54337' };
            case 'PAN_AVAILABLE_BUT_INACTIVE': return { label: 'Inoperative', color: '#f54337' };
            case 'PAN_INVALID': return { label: 'Invalid', color: '#f54337' };
            case 'PAN_NOT_AVAILABLE': return { label: 'PANNOTAVBL', color: '#fdd735' };
            default: return { label: '-', color: '' };
        }
    };

    const StatusCell = (dataItem) => {
        const pan_status = getStatuColorCode(dataItem.pan_status);
        return (
            <div className="Stsonhoverboxshow">
                <span className="stserrorboxclr">
                    <span className="iocnfsuppot">
                        <FontAwesomeIcon icon={faCircle} style={{ color: pan_status?.color }} />
                    </span>
                </span>
                <span className="Ststooltiptext">
                    <ul className="Stsstsboxlist">
                        <li>
                            <span className="form-check-inline">
                                <label className="form-check-label stserrorboxclr" htmlFor="">
                                    <span className="iocnfsuppot">
                                        <FontAwesomeIcon icon={faCircle} style={{ color: pan_status?.color }} />
                                    </span>
                                    <span className="ststextfs" style={{ color: pan_status?.color }}>{pan_status?.label}</span>
                                </label>
                            </span>
                        </li>
                    </ul>
                </span>
            </div>
        );
    };

    const getStatusAge = (status_age) => {
        let statusAge = ""
        switch (status_age) {
            case 'LTQTR':
                statusAge = '< 3 Months';
                break;
            case 'GTQTR':
                statusAge = '> 3 Months';
                break;
            case 'GT1YR':
                statusAge = '> 1 Year';
                break;
            case 'LT1YR':
                statusAge = '< 1 Year';
                break;
            default:
                statusAge = '-'
        }
        return statusAge;
    };


    const getDeducteeName = (dataItem, key) => {

        return (
            <>
                <div data-toggle="tooltip" data-placement="top" title={dataItem.deductee_name} style={{
                    width: `${((dataItem.name_on_traces && dataItem.deductee_name !== dataItem.name_on_traces) &&
                        (dataItem.name_on_traces !== 'Not Available')) ? '50%' : '100%'}`
                }}>
                    <BlurOnChangeInput
                        type="textarea"
                        className={`form-control-deductee text-left ${dataItem?.deductee_name ? 'input_has_value' : ''} ${dataItem?.errorObj?.deductee_name ? `${errorClass}` : ''}`}
                        value={dataItem?.deductee_name}
                        data-toggle="tooltip" data-placement="top" title={dataItem.deductee_name}
                        placeholder="Enter Name"
                        onChange={(e) => {
                            handleChange(key, 'deductee_name', e.target.value);
                        }}
                        onBlur={(e) => saveData(dataItem, 'deductee_name', e, key)}
                        disabled={!dataItem.deductee_status ? true : false}
                        maxLength={75}
                    />
                    {dataItem?.errorObj?.deductee_name && showMsg ? <span className="error_message">{dataItem?.errorObj?.deductee_name}</span> : null}

                </div>
                {(dataItem.name_on_traces && dataItem.deductee_name !== dataItem.name_on_traces) &&
                    (dataItem.name_on_traces !== 'Not Available') &&
                    <div style={{ width: '50%' }} data-toggle="tooltip" data-placement="top" title={dataItem.name_on_traces}>
                        {
                            (dataItem.name_on_traces && dataItem.deductee_name !== dataItem.name_on_traces) &&
                                (dataItem.name_on_traces !== 'Not Available')
                                ? (
                                    <span style={{ color: `#ff0000`, textAlign: 'right', width: '60%' }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={dataItem.name_on_traces}>
                                        {` / ${dataItem.name_on_traces}`}
                                    </span>
                                ) : null}
                    </div>
                }
            </>
        )
    }

    const rowRender = (trElement, props) => {

        //const key = props.dataIndex;        
        const key = trElement._owner.index;

        // const complianceStatus = props?.dataItem?.compliance_status ? props?.dataItem?.compliance_status.charAt(0) + props?.dataItem?.compliance_status.slice(1).toLowerCase() : 'Unverified'
        let compliance_status_color_code = '';
        if (props?.dataItem?.compliance_status === 'Regular Filer') {
            compliance_status_color_code = '#008000';
        } else if (props?.dataItem?.compliance_status === 'Non Filer') {
            compliance_status_color_code = '#ff0000';
        }

        return (
            <tr key={key} className="deduct_master_tbtoprow" role="row" style={{ opacity: `${!props.dataItem.deductee_status ? '0.5' : ''}` }}>
                <td className="text-center">
                    <input
                        type="checkbox"
                        id="exampleCheck1"
                        checked={checkedDeducteeData[props.dataItem?.deductee_id]}
                        onChange={() => handleDeducteeToggle(props)}
                        disabled={!props.dataItem.deductee_status ? true : false}
                    />
                </td>
                <td className="text-center">{StatusCell(props?.dataItem)}</td>
                <td style={{ display: 'flex' }}>
                    {getDeducteeName(props?.dataItem, key)}
                </td>
                <td>
                    <BlurOnChangeInput
                        type="text"
                        className={`form-control-deductee text-center ${props?.dataItem?.pan ? 'input_has_value' : ''} ${props?.dataItem?.errorObj?.pan ? `${errorClass}` : ''}`}
                        style={{ height: 'calc(1.42857em + 8px)', fontSize: '11px', fontWeight: '500' }}
                        value={deducteeList[key]?.pan.toUpperCase()}
                        maxLength={10}
                        placeholder="Enter PAN"
                        onChange={(e) => {
                            e.target.value.match(/^[0-9a-zA-Z]{0,10}$/) &&
                                handleChange(key, 'pan', e.target.value.toUpperCase());
                        }}
                        onBlur={(e) => saveData(props?.dataItem, 'pan', e, key)}
                        disabled={!props.dataItem.deductee_status ? true : false}
                    />
                    {props?.dataItem?.errorObj?.pan && showMsg ? <span className="error_message">{props?.dataItem?.errorObj?.pan}</span> : null}
                </td>
                <td className='text-left'
                    style={{ color: `${['Invalid', 'Pending', 'Non Filer', 'Unverified'].includes(props?.dataItem?.compliance_status) ? 'red' : props?.dataItem?.compliance_status?.includes('Regular') ? 'green' : 'black'}` }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={props?.dataItem?.processing_date ?? ""}
                >
                    {props?.dataItem?.compliance_status ?? ''}
                </td>
                <td className="text-center">
                    {props?.dataItem?.compliance_financial_year ? `${props?.dataItem?.compliance_financial_year} - ${String(props?.dataItem?.compliance_financial_year + 1).slice(2)}` : '-'}
                </td>
                <td className='text-left'
                    data-toggle="tooltip"
                    data-placement="top"
                    title={props?.dataItem?.verification_date ?? ""}
                    style={{ color: `${['PAN_INVALID', 'PAN_AVAILABLE_BUT_INACTIVE', 'PAN_AVAILABLE_BUT_UNVERIFIED', 'PAN_AVAILABLE_AND_VERIFIED'].includes(props?.dataItem?.pan_status) ? getStatuColorCode(props?.dataItem?.pan_status).color : ''}`, paddingLeft: '17px' }}
                >
                    {getStatuColorCode(props?.dataItem?.pan_status).label}
                </td>
                <td className="text-left" style={{ color: `${props?.dataItem?.status_age ? props?.dataItem?.status_age?.includes('L') ? 'green' : 'red' : 'black'}`, paddingLeft: '19px' }}>
                    {getStatusAge(props?.dataItem?.status_age)}
                </td>
                {!params?.return_id &&
                    <>
                        <td className='text-left' data-toggle="tooltip" data-placement="top" title={props?.dataItem?.email ?? ""}>
                            <BlurOnChangeInput
                                type="text"
                                className={`form-control-deductee ${props?.dataItem?.email ? 'input_has_value' : ''} ${props?.dataItem?.errorObj?.email ? `${errorClass}` : ''}`}
                                value={props?.dataItem?.email}
                                data-toggle="tooltip" data-placement="top" title={props?.dataItem?.email ?? ""}
                                placeholder="Enter Email"
                                onChange={(e) => {
                                    handleChange(key, 'email', e.target.value);
                                }}
                                onBlur={(e) => saveData(props?.dataItem, 'email', e, key)}
                                disabled={!props.dataItem.deductee_status ? true : false}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        $('input').blur();
                                    }
                                }}
                            />
                            {props?.dataItem?.errorObj?.email && showMsg ? <span className="error_message">{props?.dataItem?.errorObj?.email}</span> : null}
                        </td>
                        <td className='saldtddeleditsect text-center'>
                            {' '}
                            <span className='pr-1'
                                onClick={() => { setCurrentDeductee(props.dataIndex); updateDeducteeInfo(props.dataItem) }}
                            >
                                <img src={editIcon} alt="" width="13" />
                            </span>
                            <span className="border_left mx-1"></span>
                            <span className="pl-1" data-toggle="modal" data-target=".bd-example-modal-lg">
                                <label className="switch listofdempswitch">
                                    <input type="checkbox" checked={!props.dataItem.deductee_status} onChange={() => updateDeducteeStatus(props.dataItem, !props.dataItem.deductee_status)} />
                                    <span className="slider round listofdempswround" />
                                </label>
                            </span>
                        </td>
                    </>
                }
            </tr>
        )
    };


    const downloadDeducteeExcel = async () => {
        setLoading(true);
        try {
            const result = await exportDeductee(deductorId);
            const xlsxData = new Blob([result]);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(xlsxData);
            link.setAttribute('download', 'Deductees with PAN Details.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (err) {
            setError(err);
        }
        setLoading(false);
    };

    const toggleSelected = (checked) => {
        setAllCheckData(checked);
        if (Object.keys(checkedDeducteeData).length > 0) {
            Object.keys(checkedDeducteeData).forEach((item) => {
                checkedDeducteeData[item] = checked;
            })
            setSelectedDeducteeCount(Object.keys(checkedDeducteeData).length);
            setCheckedDeducteeData((prev) => {
                prev = checkedDeducteeData
                return { ...prev }
            });
        }
        if (!checked) { setSelectedDeducteeCount(0) }

        if (deducteeList && deducteeList?.length > 0) {
            deducteeList.forEach((item) => {
                panArray.push(item?.pan)
            })
        }
    };

    const handleStartCompliance = async (type) => {
        const fetchRnuungComplianceRequestId = (deductorId) => {
            async function fetchEverything() {
                try {
                    setLoading(true);
                    const result = await getInProgressPanVerificationTask(`?deductor_id=${deductorId}`, false);
                    setResult(true);

                    if (result?.data) {
                        if (type === "bulkPan") {
                            setShowConfirmPopupForPan(true);
                        } else if (type === "new") {
                            setConfirmPopup(true);
                        } else {
                            setStartConfirmPopupConnector(true);
                            setRequestForCheckCompliance(true);
                        }
                    }
                } catch (err) {
                    if (err.code === 404) {
                        if (type === "new" || type === "bulkPan") {
                            setStartConnector(true);
                        }
                        setResult(false);
                    }

                } finally {
                    setLoading(false);
                }
            }
            fetchEverything();
        };
        fetchRnuungComplianceRequestId(deductorId)
    };


    $(function () {
        $(".deduct_master_tb .k-grid-content").scroll(function () {
            if (getScrollbarHeight(document.querySelector('.deduct_master_tb .k-grid-content')) < $(".deduct_master_tb .k-grid-content").scrollTop() + $(".deduct_master_tb .k-grid-content").height() + 3) {
                if (!scrollPagnation && cursor?.next_page > 1) {
                    setScrollPagnation(true);
                }
            }
        });
    });

    const getScrollbarHeight = (el) => {
        return el.scrollHeight
    };


    useEffect(() => {
        if (cursor?.next_page > 1) {
            const postData = getParamsHistoryUrl();
            getDeducteeLists(postData, cursor.next_page);
            setShowMoreLoading(true);
        }
        // eslint-disable-next-line
    }, [scrollPagnation]);


    const getComplianceStatusForPan = (pan) => {
        setSinglePAN((prev) => {
            prev = pan
            return prev
        })
        setSection((prev) => {
            prev = 'COMPLIANCE_CHECK'
            return prev
        })
        setStartConnectorForSinglePAN(true);
    };


    useEffect(() => {
        if (reloadPanList) {
            window.location.reload();
        }
    }, [reloadPanList]);


    useEffect(() => {
        if (headerDataDetails?.mobile_number && headerDataDetails?.tan) {
            const checkRunningImport = localStorage.getItem(createImportRegularDeducteeReturnLocalStorageKey(headerDataDetails));
            if (checkRunningImport) {
                setShowProgressResult(true);
            }
        }
    }, [headerDataDetails]);

    useEffect(() => {
        const handleStartCompliance = async (type) => {
            const fetchRnuungComplianceRequestId = (deductorId) => {
                async function fetchEverything() {
                    try {
                        setLoading(true);
                        await getInProgressPanVerificationTask(`?deductor_id=${deductorId}`, false);

                    } catch (err) {
                        if (err.code === 404) {
                            setIconDisabled(true);
                        }

                    } finally {
                        setLoading(false);
                    }
                }
                fetchEverything();
            };
            fetchRnuungComplianceRequestId(deductorId)
        };
        handleStartCompliance("existing")
    }, [])

    const getCaptcha = (port, isLoading, DATA) => {
        async function fetchEverything() {
            try {
                const result = await getTracesCaptchaFromConnector(port);
                setCaptchaRef(result?.data?.captcha_ref ?? "");
            } catch (err) {
                setError("Please ensure that your Internet connection is stable and that the TRACES website is accessible.");

            } finally {
                if (isLoading) setLoading(false);
            }
        }
        if (isLoading) setLoading(true);
        fetchEverything();
    };

    const getGoBackLink = (id) => {
        if (id) {
            history.push(`${BASE_PATH}return-filing-step2?return_id=${id}&deductor_id=${deductorId}`)
        } else {
            history.push(`${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${finanicalYear}`)
        }
    };


    return (
        <div>
            {loading ? <LoadingIndicator /> : null}
            {showCreateForm
                ? (
                    <AddDeductee
                        onClose={onClose}
                        deductorId={deductorId}
                        componentImport={componentImport}
                    />
                )
                : null}
            {showEditForm
                ? (
                    <EditDeductee
                        onClose={onClose}
                        deductorId={deductorId}
                        deducteeInfo={editDeductee}
                        componentImport={componentImport}
                        updateDeducteeInfo={updateDeducteeInfo}
                        deducteeList={deducteeList}
                        currentDeductee={currentDeductee}
                        setCurrentDeductee={setCurrentDeductee}
                        horizontalView={horizontalView}
                    />
                )
                : null}
            <ShowAlert
                //autoHideTime={10000}
                success={success}
                error={error}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            {showBulkPAN
                ? <ConnectorAdapter
                    show={showBulkPAN}
                    handleClose={() => {
                        setShowBulkPAN(false);
                    }}
                    setPort={setPort}
                    handleProceed={() => setCheckTracesCredentials(true)}
                />
                : null
            }

            {startConnector
                ? (<ConnectorAdapter
                    show={startConnector}
                    handleClose={() => setStartConnector(false)}
                    setPort={setPort}
                    handleProceed={() => {
                        // setCheckTracesCredentialsPan(true);
                        setCheckInsightsCredentials(true);
                    }}
                />)
                : null}

            {startConnectorForSinglePAN
                ? (<ConnectorAdapter
                    show={startConnectorForSinglePAN}
                    handleClose={() => setStartConnectorForSinglePAN(false)}
                    setPort={setPort}
                    handleProceed={() => {
                        if (section === 'COMPLIANCE_CHECK') {
                            setComplianceCheck(true)
                        }
                    }}
                />)
                : null}

            {
                complianceCheck && port
                    ?
                    <InsightsConnectorWithCaptcha
                        deductorId={deductorId}
                        returnId={null}
                        port={port}
                        pan={singlePan}
                        handleProceed={() => {
                            setComplianceCheck(false);
                            setReloadPanList(true);
                        }}
                        setInsightsCaptchaRef={setInsightsCaptchaRef}
                        setVerifyCreds={() => null}
                        creds={null}
                        setParentLoading={setLoading}
                        captchaVerify={true}
                        handleClose={() => {
                            //reset all values to start again
                            setStartConnectorForSinglePAN(false);
                            setCheckTracesCredentials(false);
                            setComplianceCheck(false);

                        }}
                        relaodAfterError={setReloadPanList}
                        setParentError={setError}
                    />
                    : null
            }


            {checkTracesCredentials && port
                ? <TracesConnector
                    deductorId={deductorId}
                    returnId={params?.return_id}
                    handleProceed={() => {
                        setShowBulkPAN(false);
                        setCheckTracesCredentials(false);
                    }}
                    port={port}
                    setCaptchaRef={setCaptchaRef}
                    setVerifyCreds={() => {
                        if (type === "multiPan") {
                            setStartMultiPanVerification(true);
                        } else if (type === "singlePan") {
                            setVerifyPan(true);
                        }
                    }}
                    creds={null}
                />
                : null
            }

            {startMultiPanVerification && type === "multiPan" &&
                <DeducteeListV2MultiplePanVerify
                    deductorId={deductorId}
                    port={port}
                    captchaRef={captchaRef}
                    setSuccess={setSuccess}
                    setError={setError}
                    setLoading={setLoading}
                    setBulkPanVerificationStatusData={setBulkPanVerificationStatusData}
                    statusKey={statusKey}
                    panArray={panArray}
                    setStartMultiPanVerification={setStartMultiPanVerification}
                />
            }

            {verifyPan && captchaRef && port
                ? <SinglePanVerify
                    deductorId={deductorId}
                    port={port}
                    captchaRef={captchaRef}
                    setError={setError}
                    setVerifyPANResult={() => null}
                    pan={singlePan}
                    setLoading={setLoading}
                    handleProceed={() => null}
                    setReloadPanList={setReloadPanList}
                    errorPopup={true}
                    setSuccess={setSuccess}
                />
                : null
            }

            {checkInsightsCredentials && port
                ?
                <InsightsConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={(res) => {
                        setCheckInsightsCredentials(false);
                        setStartComplianceCheckProcess(true);
                        setRequestForCheckCompliance(true);

                    }}
                    port={port}
                    setInsightsCaptchaRef={setInsightsCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                    setLoading={setLoading}
                />
                : null
            }

            {captchaRef && port && ["syncBulkPan", "bulkPan"].includes(verifyButton)
                ? <BulkPanVerify
                    deductorId={deductorId}
                    returnId={params?.return_id}
                    port={port}
                    captchaRef={captchaRef}
                    setError={setError}
                    setSuccess={setSuccess}
                    statusKey={statusKey}
                    setBulkPanVerificationStatusData={setBulkPanVerificationStatusData}
                    bulkPanDMScreen={verifyButton === "syncBulkPan" ? "DM" : null}        //flag used only for deductee master
                    setReloadPanList={setReloadPanList}
                />
                : null
            }

            {startConfirmPopupConnector
                ? (<ConnectorAdapter
                    show={startConfirmPopupConnector}
                    handleClose={() => setStartConfirmPopupConnector(false)}
                    setPort={setPort}
                    handleProceed={() => {
                        setInsightCredsForPopup(true);
                    }}
                />)
                : null
            }

            {showConfirmPopupForPan &&
                <ConfirmPopup
                    show={showConfirmPopupForPan}
                    handleClose={() => setShowConfirmPopupForPan(false)}
                    confirmButtonText="Okay"
                    handleProceed={() => {
                        setShowConfirmPopupForPan(false);
                        if (!result) {
                            setStartConnector(true);
                        }
                    }}
                    confirmText={'Insights Portal does not accept new request until the previous Request is updated first. Click on the Sync button given in the column "Compliance Status" to update.'}
                    showCancel={false}
                />
            }

            {confirmPopup &&
                <ConfirmPopup
                    show={confirmPopup}
                    handleClose={() => setConfirmPopup(false)}
                    confirmButtonText="Okay"
                    handleProceed={() => setConfirmPopup(false)}
                    confirmText={'Insights Portal does not accept new request until the previous Request is updated first. Click on the Sync button given in the column "Compliance Status" to update.'}
                    showCancel={false}
                />
            }

            {confirmPopupForCompliance &&
                <ConfirmPopup
                    show={confirmPopupForCompliance}
                    handleClose={() => setConfirmPopupForCompliance(false)}
                    confirmButtonText="Okay"
                    handleProceed={() => setConfirmPopupForCompliance(false)}
                    confirmText={'Your request for verification has been uploaded to the Insights portal. The portal generates results after some time, so kindly check back after a while to view the Status.'}
                    showCancel={false}
                />
            }


            {insightCredsForPopup && port
                ? <InsightsConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={(res) => {
                        setInsightCredsForPopup(false);
                        setRecheckCompliance(true);
                    }}
                    port={port}
                    setInsightsCaptchaRef={setInsightsCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                    setLoading={setLoading}
                />
                : null
            }

            {startConnectorForSinglePANVerify &&
                <ConnectorAdapter
                    show={startConnectorForSinglePANVerify}
                    handleClose={() => setStartConnectorForSinglePANVerify(false)}
                    setPort={setPort}
                    handleProceed={(connectorport) => {
                        getCaptcha(connectorport);
                    }}
                />
            }

            {recheckCompliance && insightsCaptchaRef &&
                <UploadPanListComplianceCheck
                    deductorId={deductorId}
                    port={port}
                    insightsCaptchaRef={insightsCaptchaRef}
                    setSuccess={setSuccess}
                    setError={setError}
                    setLoading={setLoading}
                    coplianceStatusKey={coplianceStatusKey}
                    setComplianceCheckStatusData={setComplianceCheckStatusData}
                    requestforCheckCompliance={requestforCheckCompliance}
                    showToast={false}
                    setReloadPanList={setReloadPanList}
                />
            }

            {insightsCaptchaRef && startComplianceCeckProcess &&
                <DeducteeListV2ComplianceCheck
                    setLoading={setLoading}
                    deductorId={deductorId}
                    setComplianceCheckStatusData={setComplianceCheckStatusData}
                    setError={setError}
                    insightsCaptchaRef={insightsCaptchaRef}
                    port={port}
                    coplianceStatusKey={coplianceStatusKey}
                    captchaRef={captchaRef}
                    panArray={panArray}
                    setSuccess={setSuccess}
                    setReloadPanList={setReloadPanList}
                    type={type}
                />

            }

            {showImportDeductee ?
                <ImportDeductee
                    show={showImportDeductee}
                    handleClose={(response) => {
                        setShowImportDeductee(false);
                        if (response && response.message) {
                            setSuccess(response.message);
                            getDeducteeLists(filterPostData);
                            // checkErrorImport();
                        }
                    }}
                    deductorId={deductorId}
                    handleProceed={() => setShowProgressResult(true)}
                />
                : null
            }

            {showProgressResult &&
                <DeducteeListV2ImportProgress
                    show={showProgressResult}
                    handleClose={() => setShowProgressResult(false)}
                    deductorId={deductorId}
                    headerData={headerDataDetails}
                />
            }

            <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col-xl-5 col-lg-7 col-md-12 col-sm-12 col-12 pr-1">
                        <div className='deducteemaster_backsearchbox' style={{ marginLeft: '-11px' }}>
                            <span className="text-left">
                                <Button className="f-12" style={props.componentImport ? { visibility: 'hidden' } : { color: '#FC7D72', fontSize: '12px', fontWeight: '500', padding:'0px' }} variant="link"
                                    onClick={() => getGoBackLink(params?.return_id)}>
                                    {'< '}
                                    {!params?.return_id ? 'Back to Deductor Dashboard' : 'Back to Computation'}
                                </Button>
                            </span>
                            <div className="input-group deductee_master_searchbox ml-2">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search by Name/ PAN/ Email"
                                    id="searchStr"
                                    value={searchTerm}
                                    onChange={handleOnChange}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 13) {
                                            handleSubmit(event);

                                        }
                                    }}
                                />
                                <div className='input-group-prepend'>
                                    <img className='dedmaster_search_text_icon' src={searchIcon} alt="search icon" width={16} onClick={(e) => handleSubmit(e)} />
                                </div>
                            </div>
                            <div className='dedcuteemaster_custmultibox listoftransactionsboxgp pl-2' style={{ maxHeight: '376px', zIndex: '0' }}>
                                <Tooltip anchorElement="pointer" position="top">
                                    <CustomMultiSelect
                                        className="dedcuteemaster_custmultisel"
                                        options={DEDUCTEE_CATEGORY}
                                        selected={selectedDeducteeType}
                                        disableSearch
                                        onSelectedChanged={(selectedValue) => {
                                            changeFilter(selectedValue);
                                        }}
                                        valueRenderer={(selectedItems) => (selectedItems.length > 0 ? `${selectedItems.length}  selected` : 'Deductee Type')}
                                    />
                                </Tooltip>
                            </div>

                        </div>
                    </div>
                    <div className={`${!params?.return_id ? 'col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12' : 'col-xl-5 col-lg-5 col-md-8 col-sm-7 col-12'}`}
                        style={{ padding: '0rem 0rem 0rem 0rem' }}>
                        <h4 className="deductee_master_titlefs">{!params?.return_id ? 'Deductee Master' : 'Compliance Status u/s 206AB & 206CCA'}</h4>
                    </div>

                    <div className={`${!params?.return_id ? 'col-xl-4 col-lg-12 col-md-7 col-sm-7 col-12 text-right' : 'col-xl-2 col-lg-12 col-md-4 col-sm-5 col-12 text-right'}`}>
                        {!params?.return_id &&
                            <>
                                <span className="exiocn_padd pr-2" style={{ cursor: 'pointer' }}>
                                    <span data-toggle="tooltip" data-placement="top" title="Import Excel">
                                        <img src={ImportIcon} width="25" alt="import" onClick={() => setShowImportDeductee(true)} />
                                    </span>
                                </span>
                                <span className="exiocn_padd pr-2" style={{ cursor: 'pointer' }}>
                                    <span data-toggle="tooltip" data-placement="top" title="Export Excel">
                                        <img src={ExportIcon} width="25" alt="export" onClick={() => downloadDeducteeExcel()} />
                                    </span>
                                </span>
                            </>
                        }
                        <button className="btn btn-defulat deduct_master_compliance_checkbtnbox mr-2"
                            onClick={() => {
                                if (selectedDeducteeCount > 0) {
                                    if (selectedDeducteeCount === 1) {
                                        getComplianceStatusForPan(singlePan)   //single PAN CC
                                    } else {
                                        handleStartCompliance("bulkPan");   //excel flow (multiple pan CC (checkbox selected by user))
                                        setType("multiPanByUser");
                                    }

                                } else {
                                    handleStartCompliance("new");   //recheck flow (all PANs CC)
                                }
                            }}
                        >
                            {!params?.return_id ? 'Compliance Check' : 'Start Compliance Check'}
                        </button>

                        {!params?.return_id &&
                            <button className="btn btn-defulat deduct_master_compliance_panverificatbtnbox"
                                onClick={() => {
                                    if (selectedDeducteeCount > 0) {
                                        if (selectedDeducteeCount === 1) {
                                            setShowBulkPAN(true);  //single PAN verification        
                                            setType("singlePan");
                                        } else {
                                            setShowBulkPAN(true);  //multiple PAN(multiple check box selected)
                                            setType("multiPan");
                                        }
                                    } else {
                                        setShowBulkPAN(true); setVerifyButton("bulkPan"); //bulk PAN (all PANs)
                                    }

                                }}
                                disabled={disableBulkPanVerificationButton}
                                style={disableBulkPanVerificationButton === true ? { opacity: '0.5' } : { opacity: '1' }}>
                                PAN Verification
                            </button>
                        }

                    </div>
                </div>
            </div>


            {
                skeletonList ? <Skeleton12ColumnGrid rowNo={7} />
                    :
                    <div className='container-fluid'>
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div className="card p-0">
                                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" >
                                        <Tooltip anchorElement="pointer" position="right">
                                            <Grid
                                                className="table table-striped deduct_master_tb mb-0"
                                                // style={{ maxHeight: '500', overflowX: 'auto' }}
                                                rowRender={rowRender}

                                                data={deducteeList.map((item) => ({
                                                    ...item,
                                                    [SELECTED_FIELD]: checkedDeducteeData[idGetter(item)],
                                                }))}
                                                {...dataState}
                                                onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                dataItemKey={DATA_ITEM_KEY}
                                                selectedField={SELECTED_FIELD}
                                                selectable={{
                                                    enabled: true,
                                                    drag: false,
                                                    cell: false,
                                                }}
                                                onSelectionChange={handleDeducteeToggle}
                                                onHeaderSelectionChange={() => toggleAllSelect()}
                                                filterOperators={{
                                                    text: [
                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                    ],
                                                    numeric: [
                                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                                        { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                    ],
                                                    textWithEmpty: [
                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                    ],
                                                    dropdown: [
                                                        { text: 'grid.filterContainsOperator', operator: 'in' },
                                                    ],
                                                    pan: [
                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                        { text: 'grid.filterContainsOperator', operator: 'in' },
                                                    ],
                                                }}
                                                sortable={{
                                                    allowUnsort: true,
                                                }}
                                                fixedScroll={true}
                                            >
                                                <Column
                                                    field="selected"
                                                    width="40px"
                                                    headerClassName={'text-center'}
                                                    headerCell={() => (
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                checked={allCheckData}
                                                                onChange={(e) => toggleSelected(e.target.checked)}
                                                                className="checkboxvertalaign"
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                <Column
                                                    style={{ zIndex: '55 !important', top: '5px!important' }}
                                                    //field="pan_status"
                                                    width="50"
                                                    title={
                                                        <div className="stsicon onhoverboxshow">
                                                            <span style={{ color: '#33729c' }}>
                                                                <FontAwesomeIcon icon={faExclamationCircle} /></span>
                                                            <span className="tooltiptext">
                                                                <ul className="stsboxlist">
                                                                    <li>Status:</li>
                                                                    {ACTIVITY_STATUSES.map((status) => {
                                                                        return (
                                                                            <li>
                                                                                <span className="form-check-inline">
                                                                                    <label className="form-check-label stsboxclrtext">
                                                                                        <span className={status.class}><FontAwesomeIcon icon={status.icon} color={status.color} /></span>
                                                                                        <span className="ststextfs" style={{ color: status.color }}>{status.label}</span>
                                                                                    </label>
                                                                                </span>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </span>
                                                        </div>
                                                    }
                                                    filter="dropdown"
                                                    sortable={false}

                                                />
                                                <Column
                                                    width="340"
                                                    field="deductee_name"
                                                    headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                                    title={
                                                        <AddDeducteeTransactionsIcon field="deductee_name" dataState={dataState}
                                                            dataStateChange={dataStateChange}
                                                            handleClick={() => setShowCreateForm(true)}
                                                            title="Deductee Name / Name as per PAN"
                                                            subtextData={{ subText: deducteeSummary?.total_name ?? 0, color: '' }}
                                                            addTooltipPlus="Click to add new deductee"
                                                            headClass={true}
                                                            showButton={!params?.return_id ? true : false}
                                                        />
                                                    }
                                                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                    sortable={false}
                                                    filter='pan'
                                                />

                                                <Column
                                                    field="pan"
                                                    headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                    title={`PAN`}
                                                    width="120"
                                                    columnMenu={(props) => <ColumnMenu {...props} panFilter={true} filterList={PAN_LIST} />}
                                                    filter="pan"
                                                />
                                                <Column
                                                    field="compliance_status"
                                                    headerClassName={isColumnActive('compliance_status') ? 'active' : ''}
                                                    title={
                                                        <AddSyncIcon
                                                            field="compliance_status"
                                                            dataState={dataState}
                                                            dataStateChange={dataStateChange}
                                                            handleClick={() => handleStartCompliance("existing")}
                                                            title="Compliance Status"
                                                            addTooltip={iconDisabled ? " No request ID to sync!" : "Update Status of Request uploaded"}
                                                            showButton={!params?.return_id ? true : false}
                                                            iconDisabled={iconDisabled}
                                                        />
                                                    }
                                                    width="160"
                                                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} filterList={COMPLIANCE_STATUS} />}
                                                    filter='dropdown'
                                                    sortable={false}
                                                />
                                                <Column
                                                    field="compliance_financial_year"
                                                    headerClassName={isColumnActive('compliance_financial_year') ? 'active' : ''}
                                                    title="FY (Compliance Check)"
                                                    width="170"
                                                    columnMenu={(props) => <ColumnMenu  {...props} hideSecondFilter filterList={yearDropdown} />}
                                                    filter='dropdown'
                                                />
                                                <Column
                                                    field="pan_status"
                                                    headerClassName={isColumnActive('pan_status') ? 'active' : ''}
                                                    title={
                                                        <AddSyncIcon
                                                            field="pan_status"
                                                            dataState={dataState}
                                                            dataStateChange={dataStateChange}
                                                            handleClick={() => { setShowBulkPAN(true); setVerifyButton("syncBulkPan"); }}
                                                            title="PAN Status"
                                                            addTooltip="Check PAN for expired status"
                                                            showButton={!params?.return_id ? true : false}
                                                        />
                                                    }
                                                    sortable={false}
                                                    width="150"
                                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={STATUS_LIST} />}
                                                    filter="dropdown"
                                                />
                                                <Column
                                                    field="status_age"
                                                    headerClassName={isColumnActive('status_age') ? 'active' : ''}
                                                    title="Status Age"
                                                    width="120"
                                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={STATUS_AGE} />}
                                                    filter="dropdown"
                                                />

                                                {!params?.return_id &&
                                                    <Column
                                                        field="email"
                                                        title='Email Address'
                                                        headerClassName={isColumnActive('email') ? 'active' : ''}
                                                        dataStateChange={dataStateChange}
                                                        width="170"
                                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                        filter="textWithEmpty"
                                                    />
                                                }

                                                {!params?.return_id &&
                                                    <Column
                                                        field="editIcon" sortable={false}
                                                        title="Actions"
                                                        width="90"
                                                    />
                                                }

                                                <GridNoRecords>
                                                    No Record Found
                                                </GridNoRecords>
                                            </Grid>
                                        </Tooltip>

                                        <div style={{ marginTop: '5px' }}>
                                            {
                                                bulkPanVerificationStatusData?.captcha_ref
                                                    ? <ProgressBarForPAN
                                                        bulkPanVerificationStatusData={bulkPanVerificationStatusData}
                                                        statusKey={statusKey}
                                                        setPort={setPort}
                                                        setBulkPanVerification={setCheckTracesCredentials}
                                                        setReloadPanList={setReloadPanList}
                                                        setDisableBulkPanVerificationButton={setDisableBulkPanVerificationButton}
                                                    />
                                                    : null
                                            }
                                        </div>

                                        <div className="col-sm-12 pb-1 text-center">
                                            {showMoreLoading
                                                &&
                                                (
                                                    <span style={{ color: '#31719b' }}>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        {' '}
                                                        Loading...
                                                    </span>
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div >

                        </div>
                    </div>
            }

        </div >
    );
};

export default DeducteeListV2;