import axios from "axios";
import { BASE_URL_GO } from "../app/constants";
import { handleErrorWithCode } from "./responseHandler";
import { getConfig } from "./common";

export async function downloadFileByRefKey(dataKey) {
  const url = `${BASE_URL_GO}/api/v1/files/download-file?file_ref_key=${dataKey}`;

  try {
    const config = { ...getConfig() };
    config.responseType = "blob";
    const result = await axios.get(url, config);
    return result;
  } catch (err) {
    if (err?.response?.status === 404) {
      throw err.response;
    } else {
      throw handleErrorWithCode(err);
    }
  }
};

