import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { MAX_IMPORT_FILE_SIZE_MB } from '../../app/constants';
import "./BulkImport.css";
import { importForm3CDTXTFile } from '../../api/form3cdApi.js';
import WindowWrapperFixed from '../../components/grid/WindowWrapperFixed';
import importIcon from '../../images/icons/import_icon.png';
import excelIcon from '../../images/excelicon.png';

const Form3CDBulkImport = ({ history, ...props }) => {
  const {
    handleClose, ifBulkImport, setError, setSuccess, setShowProgressBar, reportData, financialYear, uniqueTANs
  } = props;
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const {
    isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject,
  } = useDropzone({
    accept: '.txt,.tds'
  });

  const removeFile = (targetFile) => {
    const newFiles = [];
    files.forEach((file) => {
      if (file.path === targetFile.path) return;
      newFiles.push(file);
    });
    setFiles(newFiles);
  };
  const renderFiles = files.map((file) => {
    return (<li key={file.path} style={{ margin: '5px' }}>
      <span>
        {file.path}
        {' '}
        -
        {file.size}
        {' '}
        bytes
      </span>
      <Button
        variant="danger"
        style={{
          fontSize: '12px', padding: '0px 5px 2px 5px', marginLeft: '10px',
        }}
        onClick={() => removeFile(file)}
      >
        Remove
      </Button>
    </li>)
  });


  // Function to generate  return id
  const uploadTxtOnServer = async (formData) => {

    let isUploadSuccess = false;
    try {
      if (setShowProgressBar) setShowProgressBar(true);

      const result = await importForm3CDTXTFile(formData);
      if (result && result?.status === 1) {
        isUploadSuccess = true
      } else {
        setError(result.message);
        isUploadSuccess = false;
      }
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(err);
      isUploadSuccess = false;
    } finally {
      if (setShowProgressBar) setShowProgressBar(false);
    }
    return isUploadSuccess
  }


  const uploadFiles = async () => {
    setLoading(true);

    try {

      let fileCounterFail = 0;
      let fileCounterSuccess = 0;
      
      for (let file of files) {
        const formData = new FormData();
        const getItem = await getRetrunDataFromTxtFile(file);
        if (!getItem) {
          fileCounterFail++;
          continue; // Skip to the next iteration if no valid file found
        }

        formData.append('deductor_id', getItem.deductor_id);
        formData.append('financial_year', getItem.financial_year);
        formData.append('quarter', getItem.quarter);
        formData.append('form_type', getItem.form_type);
        formData.append('file', file);

        const getStatus = await uploadTxtOnServer(formData);
        if(getStatus){
          fileCounterSuccess++;
        }
      }
      
      if( fileCounterFail > 0 ){
        setError(`${fileCounterFail} Invalid file(s) found.`);
      }
      if( fileCounterSuccess > 0 ){
        setSuccess(`${fileCounterSuccess} TXT file(s) imported successfully`);
      }

      setLoading(false);
      setFiles([]);
      handleClose();

    } catch (error) {
      console.error("Error processing file:", error);
      setError("Error processing file");
      handleClose();
      setLoading(false);
    }
  };


  useEffect(() => {
    const newFiles = acceptedFiles;
    const uniqueFiles = [];
    const flags = [];
    let xlsmxfile = [];
    let fileSize = 0;
    const l = newFiles.length;
    let i;
    for (i = 0; i < l; i++) {
      if (flags[newFiles[i].path]) continue;
      flags[newFiles[i].path] = true;
      uniqueFiles.push(newFiles[i]);
      xlsmxfile = [...files, ...uniqueFiles];
    }

    xlsmxfile.forEach((file) => {
      fileSize += file.size;

    });
    if (fileSize / (1024 * 1024) > MAX_IMPORT_FILE_SIZE_MB) {
      fileSize = 0;
      return setError(`Maximum size of the files that can be uploaded is ${MAX_IMPORT_FILE_SIZE_MB} MB.`);
    }

    if (files.length) {
      setFiles([...files, ...uniqueFiles]);
    } else {
      setFiles([...uniqueFiles]);
    }

    //eslint-disable-next-line
  }, [acceptedFiles]);


  const getRetrunDataFromTxtFile = async (file) => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const forms = ['24Q', '26Q', '27Q', '27EQ'];

    // Read file
    const text = await file.text();
    const lines = text.split('\n');

    if (lines.length >= 2) {
      let col_data = lines[1].split("^");

      // Handle batch header data
      if (col_data.length > 17 && col_data[1] === 'BH') {
        // Check conditions

        if (col_data[16].substr(0, 4) === financialYear.value &&
          forms.includes(col_data[4]) &&
          quarters.includes(col_data[17]) &&
          uniqueTANs.includes(col_data[12])) {

          const currentColData = col_data;
          // Perform additional checks or operations if needed  

          let foundItem = reportData.find(item => (
            item.tan === currentColData[12] &&
            item.form === currentColData[4]
          ));
          if (foundItem && (foundItem[currentColData[17]] ?? '') === "CONSO") {
            return null;
          }
          if (!foundItem) {
            foundItem = reportData.find(item => (
              item.tan === currentColData[12]
            ));
          }
          if (foundItem) {
            return {
              "deductor_id": foundItem.deductor_id,
              "financial_year": financialYear.value,
              "quarter": currentColData[17],
              "form_type": currentColData[4],
            };
          }
        }
      }
    }

    return null;

  }

  return (
    <>
      <WindowWrapperFixed
        title={<kendo-dialog-titlebar>
          <h4 className='my-3' style={{ fontSize: "16px" }}>
            {ifBulkImport ? "Import TXT" : ""}
          </h4>
        </kendo-dialog-titlebar>}
        onClose={handleClose}
        totalPopups={0}
        initialTop={((window.innerHeight) / 2)}
        index={1}
        className={`bookVerify ${ifBulkImport ? "bulk_importcss" : ""}`}
        initialHeight={'auto'}
        style={{ maxHeight: 'auto', overflowX: 'hidden' }}
        initialWidth={700}
        initialLeft={(((window.innerWidth) - 700) / 2)}
        isModalView={true}
      >
        {ifBulkImport && <div className="modal-body pt-1 pb-0">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                {files?.length <= 0 &&
                  <div className="col-md-12 pt-4">
                    <span className="Deductee_trans_importmodal_iconbox pr-2">
                      <img src={importIcon} width="18" alt='import icon' />
                    </span>
                    <button className="btn btn-default balnktemplate_dragdropfilebtnbgclr" {...getRootProps({ className: 'fileuppopup_dropzone' })} style={{ paddingLeft: '20px' }}>
                      <input {...getInputProps({ accept: '.txt,.tds', multiple: true })} />
                      {!isDragActive && 'Drag \'n\' drop Files/Folders here, or click to select Files/Folders'}
                      {isDragActive && !isDragReject && 'Drop files here'}
                      {isDragReject && 'File type not accepted, sorry!'}
                    </button>
                  </div>
                }
                {files?.length > 0 &&
                  <div className="col-md-12 pt-3">
                    <span className="Deductee_trans_importmodal_iconbox pr-2">
                      <img src={excelIcon} width="18" alt='excel icon' />
                    </span>
                    <button className="btn btn-default balnktemplate_filenamebtn">
                      <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
                    </button>
                  </div>
                }

                {/* <section className="">
                  <div {...getRootProps({ className: 'dropzone dropzonestyle' })}>
                    <input id="book_file" {...getInputProps({ accept: '.txt,.tds', multiple: true })} />


                    <p className="font11Center">
                      {!isDragActive && 'Drag \'n\' drop Files/Folders here, or click to select Files/Folders'}
                      {isDragActive && !isDragReject && 'Drop files here'}
                      {isDragReject && 'File type not accepted, sorry!'}
                    </p>


                  </div>
                </section> */}
              </div>
              <div className="row mt-3"></div>
              {/* <aside className='pt-2'>
                <div style={{ maxHeight: '152px', overflow: 'auto', }}>
                  <h6 style={{ fontSize: '12px' }}>{'Files'}</h6>
                  <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
                </div>

              </aside> */}
              <div className="text-center pb-1 ">
                <Button className="btn btn-default gstrfFbtnimport" variant="primary" onClick={() => uploadFiles()} disabled={loading || files.length === 0}>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {' '}
                      Loading...
                    </>
                  ) : 'Import'}
                </Button>

              </div>
            </div>
          </div>
        </div>}
      </WindowWrapperFixed>
    </>
  );
};

export default Form3CDBulkImport;
