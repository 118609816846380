import React from 'react'
import { convertNum, formatNumber } from '../../../utils/UtilityFunctions';

const ViewRetireBenfitLite = (props) => {
    const { data, headingCodeListRetirbenfit, calcIcon } = props;

     // Calculate the total of the amt key in an array of objects.
     const calctotalArray = (calcData, drpdwnObj) => {

        if (calcData?.length > 0) {
            const total = calcData.reduce((prev, curr) => {
                if (drpdwnObj?.has(curr?.allowance_type_id)) {
                    prev += convertNum(curr.received_amtp) - convertNum(curr.exempt_amtp);
                }
                return prev;
            }, 0);
            return formatNumber(total);
        } else {
            return 0;
        }

    };
    
    return (
        <>
            <tr className="">
                <td width=""></td>

                <td id="accordion" className="accordion text-left salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom">
                    <span className="salarytaxregime_edit_collapse  mr-1" data-toggle="collapse" href="#salarytaxregime_edit_retbenefite_collap02" aria-expanded="true"></span>
                    Retirement Benefits
                </td>
                <td className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom text-right">
                    {calctotalArray(data.allowances ?? [], headingCodeListRetirbenfit)}
                </td>

            </tr>
            {data?.allowances?.length > 0 &&
                <tr className="hide-table-padding">
                    <td colspan="15" className="p-0">
                        <div id="salarytaxregime_edit_retbenefite_collap02" className="in collapse show">
                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                    <thead>
                                        <tr>
                                            <th width="44"></th>
                                            <th width="350" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop">Particulars</th>
                                            <th width="200" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop"></th>
                                            <th width="120" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop">Exempted</th>
                                           </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data?.allowances.map((item, index) => {

                                                if (!headingCodeListRetirbenfit?.has(item?.allowance_type_id)) return null;

                                                return (
                                                    <tr className="salarytaxregime_edit_alternetbgclr_grey">
                                                        <td width=""></td>
                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft">
                                                            {headingCodeListRetirbenfit?.get(item?.allowance_type_id)?.description ?? ""}
                                                        </td>
                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell ">
                                                            {headingCodeListRetirbenfit?.get(item?.allowance_type_id)?.calc_id &&
                                                                <img src={calcIcon} alt="calculator icon" width="16" className="" />}                                                                                                                        </td>
                                                        
                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell text-left ">
                                                            {formatNumber(item.exempt_amtp ?? 0)}
                                                        </td>
                                                        
                                                    </tr>
                                                )
                                            })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>}
        </>
    )
}

export default ViewRetireBenfitLite