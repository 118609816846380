import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import NumericInputWithDecimalTwo from '../../../../../components/NumericInputWithDecimalTwo';
import { formatNumber } from '../../../../../utils/UtilityFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRupeeSign
} from '@fortawesome/free-solid-svg-icons';


const SelfOccupied = (props) => {
    const { show, handleClose, values, setFieldValue, totalHouseIncome } = props;

    const ConstructionLimit = 200000;
    const RepairssLimit = 30000;

    const [selfOccupiedBreakup, setSelfOccupiedBreakup] = useState({
        'construction_amtp': Number(values?.self_occupied_breakup?.construction_amtp ?? 0),
        'repair_renewal_extension_amtp': Number(values?.self_occupied_breakup?.repair_renewal_extension_amtp ?? 0),
    });

    const [error, setError] = useState({
        'construction_amtp': false,
        'repair_renewal_extension_amtp': false,
    });

    const [dataChange, setDataChange] = useState(0);

    const [selfOccupiedAmtp, setSelfOccupiedAmtp] = useState(values.self_occupied_amtp);


    const closePopup = () => {
        handleClose(false);
    }

    const saveLetOut = () => {

        if (selfOccupiedBreakup.construction_amtp > ConstructionLimit) {
            return false
        }

        if (selfOccupiedBreakup.repair_renewal_extension_amtp > RepairssLimit) {
            return false
        }

        values.self_occupied_breakup = selfOccupiedBreakup;
        setFieldValue('self_occupied_breakup', selfOccupiedBreakup);
        setFieldValue('self_occupied_amtp', selfOccupiedAmtp);
        totalHouseIncome('self_occupied_amtp', selfOccupiedAmtp, setFieldValue, values);
        handleClose(false);
    }

    const calculate_self_occupied = (field, value) => {

        let new_selfOccupiedBreakup = selfOccupiedBreakup;
        new_selfOccupiedBreakup[field] = value;


        let self_occupied_amtp = (parseFloat(new_selfOccupiedBreakup.construction_amtp ? new_selfOccupiedBreakup.construction_amtp : 0) + parseFloat(new_selfOccupiedBreakup.repair_renewal_extension_amtp ? new_selfOccupiedBreakup.repair_renewal_extension_amtp : 0)).toFixed(2);
        setSelfOccupiedBreakup(new_selfOccupiedBreakup);
        setSelfOccupiedAmtp(self_occupied_amtp);
        setDataChange(dataChange + 1);
    }

    return (<Modal
        show={show}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        size="sm"
        className="allowancedspopup"
    >
        <Modal.Header closeButton className="MonthWSdetailsH monthdetail_header">
            <Modal.Title className="pt-0 mt-0">
                <sapn className="MonthWSdetailsT"> Self Occupied Income From House Property</sapn>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0 px-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 pt-2">
                                <div className="card MonthWSdetailscard">
                                    <div className="table-responsive">
                                        <table className="table table-striped allowanceWSDTB">
                                            <thead>
                                                <tr>
                                                    <td colSpan="2">
                                                        Interest on Borrowed Capital (A)
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '50%' }}>
                                                        For Construction (B)
                                                        <br />
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={error.construction_amtp ? "form-control mt-2 error_input" : "form-control mt-2"}
                                                            placeholder="0.00"
                                                            name="construction_amtp"
                                                            value={selfOccupiedBreakup.construction_amtp}
                                                            onChange={(e) => {
                                                                if (e.target.value.replace('.', '').length <= 12) {
                                                                    if (parseFloat(e.target.value) > ConstructionLimit) {
                                                                        setError({ construction_amtp: true, repair_renewal_extension_amtp: error.repair_renewal_extension_amtp });
                                                                    }
                                                                    else {
                                                                        setError({ construction_amtp: false, repair_renewal_extension_amtp: error.repair_renewal_extension_amtp });
                                                                    }

                                                                    calculate_self_occupied('construction_amtp', e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ width: '50%' }}>
                                                        For Repair/Renewal/Extensions (C)
                                                        <br />
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={error.repair_renewal_extension_amtp ? "form-control mt-2 error_input" : "form-control mt-2"}
                                                            placeholder="0.00"
                                                            name="repair_renewal_extension_amtp"
                                                            value={selfOccupiedBreakup.repair_renewal_extension_amtp}
                                                            onChange={(e) => {
                                                                if (e.target.value.replace('.', '').length <= 12) {
                                                                    if (parseFloat(e.target.value) > RepairssLimit) {
                                                                        setError({ repair_renewal_extension_amtp: true, construction_amtp: error.construction_amtp });
                                                                    }
                                                                    else {
                                                                        setError({ repair_renewal_extension_amtp: false, construction_amtp: error.construction_amtp });
                                                                    }
                                                                    calculate_self_occupied('repair_renewal_extension_amtp', e.target.value);

                                                                }
                                                            }
                                                            }
                                                        />
                                                    </td>
                                                </tr>

                                                {error.construction_amtp || error.repair_renewal_extension_amtp ?
                                                    <tr>
                                                        <td>
                                                            {error.construction_amtp ?
                                                                <div className="error_div">
                                                                    Interest on Borrowed Capital for Construction cannot be more than Rs. 2,00,000.00
                                                                </div>
                                                                : null}
                                                        </td>
                                                        <td>
                                                            {error.repair_renewal_extension_amtp ?
                                                                <div className="error_div">
                                                                    Interest on Borrowed Capital for Repair/Renewal/Extension cannot be more than Rs. 30,000
                                                                </div>
                                                                : null}
                                                        </td>
                                                    </tr>
                                                    : null}

                                                <tr>
                                                    <td colSpan="2" className="pt-2 pb-2">Self Occupied Income from House Property (D = A+B+C): <FontAwesomeIcon icon={faRupeeSign} /> {formatNumber(selfOccupiedAmtp)}</td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="btn-group mt-2 mb-1">
                                    <button type="button" className="btn btn-default monthwsdetilssavebtn" onClick={() => saveLetOut()}>Save & Exit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
    </Modal>
    );
};

export default SelfOccupied;