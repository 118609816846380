import React from 'react'
import { formatNumber } from '../../../utils/UtilityFunctions';

const ViewOtherDetails = (props) => {
    const { data, headingCodeListdedVI_A, calculateTotal } = props;
    return (
        <>
            <tr className="trrow_bgclr_purple">
                <th width="10">

                </th>
                <th width="" className="text-left">
                    Gross Taxable Income
                </th>
                <th width="" className="text-right">
                    {formatNumber(data?.total_gross_income_amtp ?? 0)}
                </th>
            </tr>

            <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse " data-toggle="collapse" href="#salarytaxregime_edit_mastercollap03" aria-expanded="true"></span>

                </th>
                <th width="550" className="text-left">
                    Chapter VI-A Dedcutions
                </th>
                <th width="150" className="text-right">
                    {formatNumber(data?.total_deduction_vi_a_amtp ?? 0)}
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_mastercollap03" className="in collapse show">

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <thead>
                                    <tr>
                                        <th width="40"></th>
                                        <th width="200" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop ">Particulars</th>
                                        <th width="150" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop ">Gross Amount</th>
                                        <th width="150" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop ">Deductible</th>
                                        <th width="150" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderright salarytaxreg_innertb_bordertop ">Qualifying Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.deduction_vi_a_details?.length > 0 &&
                                        data?.deduction_vi_a_details.map((item, index) => {
                                            item.display_code = headingCodeListdedVI_A?.get(item.code)?.display_code ?? "";
                                            return (
                                                <tr className="">
                                                    <td width=""></td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft ">
                                                        {item.display_code ?? ""}
                                                    </td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell text-right">
                                                        {formatNumber(item.gross_amount_amtp ?? 0)}
                                                    </td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell text-right">
                                                        {formatNumber(item.deductible_amount_amtp ?? 0)}
                                                    </td>
                                                    <td width="" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderright text-right">
                                                        {formatNumber(item.qualifying_amtp ?? 0)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    <tr>
                                        <td></td>
                                        <td className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom"></td>
                                        <td className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom"></td>
                                        <td className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom"></td>
                                        <td className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </td>
            </tr>


            <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse " data-toggle="collapse" href="#salarytaxregime_edit_otherdetailscollap01" aria-expanded="true"></span>

                </th>
                <th width="550" className="text-left">
                    Other Details
                </th>
                <th width="150" className="text-right">
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_otherdetailscollap01" className="in collapse show">

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>
                                    <tr className="">
                                        <th width="10" id="accordion" className="accordion">
                                            <span className="salarytaxregime_edit_collapse " data-toggle="collapse" href="#salarytax_edit_otherdetails_innercollap01" aria-expanded="true"></span>
                                        </th>
                                        <th width="550" className="text-left">
                                            Whether Total Rent Paid 1 Lakh?
                                            <span className="salarytax_otherdetails_Yesbtnbox pl-2">
                                                <button
                                                    className={`btn btn-default cursor_default box_shadow_disab ${data?.other_details?.whether_total_rent_greater_onelakh ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"} mr-2`}

                                                >Yes</button>
                                                <button
                                                    className={`btn btn-default cursor_default box_shadow_disab ${!data?.other_details?.whether_total_rent_greater_onelakh ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"}`}

                                                >No</button>
                                            </span>
                                        </th>
                                        <th width="120" className="text-right">
                                            {calculateTotal(data?.other_details?.landlord_details ?? [], "rent_amount_amtp")}
                                        </th>

                                    </tr>

                                    <tr className="hide-table-padding">
                                        <td colspan="15" className="p-0">
                                            <div id="salarytax_edit_otherdetails_innercollap01" className="in collapse show" >
                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="20"></th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop  ">Name of Landlord</th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop ">PAN of Landlord</th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright">Rent Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.other_details?.landlord_details?.length > 0 &&
                                                                data?.other_details?.landlord_details.map((item, index) => {

                                                                    return (
                                                                        <tr className="">
                                                                            <td width=""></td>
                                                                            <td width="" className={`salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft `}>
                                                                                {item?.name_of_landlord ?? ""}
                                                                            </td>
                                                                            <td width="" className={`salarytaxreg_innertb_bgclr_lightyell text-center`}>
                                                                                {item?.pan_of_landlord ?? ""}
                                                                            </td>

                                                                            <td width="" className={`salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_borderright text-right`}>
                                                                                {formatNumber(item?.rent_amount_amtp ?? 0)}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            <tr>
                                                                <td></td>
                                                                <td className="text-right salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom"></td>
                                                                <td className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom"></td>
                                                                <td className="text-right salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>



                                    <tr className="">
                                        <th width="10" id="accordion" className="accordion">
                                            <span className="salarytaxregime_edit_collapse " data-toggle="collapse" href="#salarytax_edit_otherdetails_innercollap02" aria-expanded="true"></span>
                                        </th>
                                        <th className="text-left">
                                            Whether Interest Paid to Lender
                                            <span className="salarytax_otherdetails_Yesbtnbox pl-2">
                                                <button
                                                    className={`btn btn-default cursor_default box_shadow_disab ${data?.other_details?.whether_interest_paid_to_lender ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"} mr-2`}
                                                >Yes</button>
                                                <button
                                                    className={`btn btn-default cursor_default box_shadow_disab ${!data?.other_details?.whether_interest_paid_to_lender ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"}`}

                                                >No</button>
                                            </span>
                                        </th>
                                        <th className="text-right">
                                            {calculateTotal(data?.other_details?.lender_details ?? [], "interest_amount_amtp")}
                                        </th>

                                    </tr>

                                    <tr className="hide-table-padding">
                                        <td colspan="15" className="p-0">
                                            <div id="salarytax_edit_otherdetails_innercollap02" className="in collapse show">
                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="20"></th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop  ">Name of Lender</th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop ">PAN of Lender</th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright">Interest Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.other_details?.lender_details?.length > 0 &&
                                                                data?.other_details?.lender_details.map((item) => {

                                                                    return (
                                                                        <tr className="">
                                                                            <td width=""></td>
                                                                            <td width="" className={`salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft text-left`}>
                                                                                {item?.name_of_lender ?? ""}
                                                                            </td>
                                                                            <td width="" className={`salarytaxreg_innertb_bgclr_lightyell text-center`}>
                                                                                {item?.pan_of_lender ?? ""}
                                                                            </td>

                                                                            <td width="" className={`salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright text-right`}>
                                                                                {formatNumber(item?.interest_amount_amtp ?? 0)}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            <tr>
                                                                <td></td>
                                                                <td className="text-right salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom"></td>
                                                                <td className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom"></td>
                                                                <td className="text-right salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <th width="10" id="accordion" className="accordion">
                                            <span className="salarytaxregime_edit_collapse " data-toggle="collapse" href="#salarytax_edit_otherdetails_innercollap03" aria-expanded="true"></span>
                                        </th>
                                        <th className="text-left">
                                            Whether Amt Rec. from Superannuation Fund?
                                            <span className="salarytax_otherdetails_Yesbtnbox pl-2">
                                                <button
                                                    className={`btn btn-default cursor_default box_shadow_disab ${data?.other_details?.whether_amtrec_from_superannuation_fund ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"} mr-2`}

                                                >Yes</button>
                                                <button
                                                    className={`btn btn-default cursor_default box_shadow_disab ${!data?.other_details?.whether_amtrec_from_superannuation_fund ? "salarytax_otherdetails_Yes" : "salarytax_otherdetails_no"}`}

                                                >No</button>
                                            </span>
                                        </th>
                                        <th className="text-right">
                                            {formatNumber(data?.other_details?.fund_received_amtp ?? 0.00)}
                                        </th>

                                    </tr>

                                    <tr className="hide-table-padding">
                                        <td colspan="15" className="p-0">
                                            <div id="salarytax_edit_otherdetails_innercollap03" className="in collapse show">
                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="20"></th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop  ">Name of Fund</th>
                                                                <th colspan="2" width="120" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright">From - To</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="">
                                                                <td width=""></td>
                                                                <td width="" className="text-left salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft ">
                                                                    {data?.other_details?.fund_name ?? ""}
                                                                </td>
                                                                <td colspan="2" width="" className="text-center salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright">
                                                                    {data?.other_details?.fund_from ?? ""} - {data?.other_details?.fund_to ?? ""}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th width="20"></th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell   salarytaxreg_innertb_borderleft  ">Amt Received</th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell  ">Tax Rate</th>
                                                                <th width="120" className="salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_borderright">TDS Deducted</th>
                                                            </tr>


                                                            <tr className="">
                                                                <td width=""></td>
                                                                <td width="" className="text-right salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom">
                                                                    {formatNumber(data?.other_details?.fund_received_amtp ?? 0.00)}
                                                                </td>
                                                                <td width="" className="text-right salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderbottom">
                                                                    {formatNumber(data?.other_details?.tax_rate_b100 ?? 0.00)}
                                                                </td>

                                                                <td width="" className="text-right salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom">
                                                                    {formatNumber(data?.other_details?.tds_deducted_amtp ?? 0.00)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>


                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewOtherDetails