/* eslint-disable import/prefer-default-export */
export const fvuGenerationFormFields = {
  prn:'',
  lastfilling:'0',
  deductor_address_changed:'0',
  responsible_person_address_changed:'0',
  prncheckbox:'0',
  address_building_no: '',
  address_building_name: '',
  address_street_name: '',
  address_area: '',
  address_city: '',
  address_state_id: '',
  address_pincode: '',
  responsible_person_address_building_no: '',
  responsible_person_address_building_name: '',
  responsible_person_address_street_name: '',
  responsible_person_address_area: '',
  responsible_person_address_city: '',
  responsible_person_address_state_id: '',
  responsible_person_address_pincode: '',
};
