/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import '../../returnDashboard/importReturnFromExcel/FileUploadPopup.css';
import { importCorrectionReturn } from '../../../api/returnAPI';
import ConfirmPopup from '../../../components/ConfirmPopup';
import { MAX_IMPORT_FILE_SIZE_MB } from '../../../app/constants';
import { formatBytes } from '../../../utils/UtilityFunctions';
import exportIcon from '../../../images/icons/export_icon.png';
import importIcon from '../../../images/icons/import_icon.png';
import excelIcon from '../../../images/excelicon.png';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import './FileUploadPopupV2.scss';

const FileUploadPopupV2 = ({ history, ...props }) => {
    const {
        handleClose, handleProceed, deductorId, returnId, importExelError, downloadExcelTempLoading, downloadExcelTemp = null
    } = props;
    // const headerData = useSelector(selectHeaderData);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [files, setFiles] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const {
        isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject,
    } = useDropzone({
        accept: '.xls,.xlsx,.xlsm',
        multiple: false,
    });

    const removeFile = (targetFile) => {
        const newFiles = [];
        files.map((file) => {
            if (file.path !== targetFile.path) {
                newFiles.push(file);
            }
            return true;

        });
        setFiles(newFiles);
    };

    const renderFiles = files.map((file) => (
        <li key={file.path} style={{ margin: '5px' }}>
            <span style={{ float: 'left' }}>
                {file.path}
                {' '}
                -
                {formatBytes(file.size)}
            </span>
            <Button
                variant="danger"
                style={{
                    fontSize: '12px', padding: '0px', paddingLeft: '5px', paddingRight: '5px', marginLeft: '10px', float: 'right'
                }}
                onClick={() => removeFile(file)}
            >
                Remove
            </Button>
        </li>
    ));

    const uploadFiles = async () => {
        setShowConfirm(false);
        setLoading(true);
        const formData = new FormData();
        files.map((file) => {
            formData.append('file', file);
            formData.append('deductor_id', deductorId);
            formData.append('return_id', returnId);
            // formData.append('created_at', moment(file.lastModified).format('YYYY-MM-DD'));
            return true;
        });

        try {
            const result = await importCorrectionReturn(formData);
            handleProceed(result);
        } catch (err) {
            if (err.status === '0' && err.errors && Object.keys(err.errors).length > 0) {
                importExelError(err.errors);
            } else if (err.errors && Object.keys(err.errors).length > 0) {
                setError(Object.values(err.errors));
            } else {
                setError(err.message);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        const newFiles = acceptedFiles;
        const uniqueFiles = [];
        const flags = [];
        const l = newFiles.length;
        let i;
        for (i = 0; i < l; i++) {
            if (flags[newFiles[i].path]) continue;
            flags[newFiles[i].path] = true;
            uniqueFiles.push(newFiles[i]);

        }



        setFiles(uniqueFiles);
    }, [acceptedFiles]);

    const showConfirmPopup = () => {
        if (files[0].size / (1024 * 1024) > MAX_IMPORT_FILE_SIZE_MB) {
            return setError(`Max size of the file that can be uploaded is ${MAX_IMPORT_FILE_SIZE_MB} MB.`);
        }
        setShowConfirm(true);
    }

    return (
        <>
            <ConfirmPopup
                show={showConfirm}
                handleProceed={() => {
                    uploadFiles()
                }}
                confirmText={"Please click Proceed button to continue importing the file."}
                confirmButtonText="Proceed"
                handleClose={() => {
                    setShowConfirm(false);
                }}
                changeBtnColor={true}
            />

            <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ paddingTop: '8px', paddingBottom: '5px' }}>
                    {'Import Return From Excel'}
                </kendo-dialog-titlebar>}
                onClose={handleClose}
                totalPopups={0}
                initialTop={50}
                index={1}
                className="Deductee_trans_importmodal"
                initialHeight={'auto'}
                style={{ maxHeight: '540px', overflowX: 'hidden' }}
                initialWidth={475}
                initialLeft={(((window.innerWidth) - 580) / 2)}
                isModalView={true}
            >
                <div className="modal-body">
                    <div className="row">
                        <span className="Deductee_trans_importmodal_iconbox pr-2 pl-3">
                            <img src={exportIcon} width="18" alt='export icon' />
                        </span>
                        {downloadExcelTemp && <p style={{ color: "#1a1adb", cursor: "pointer", float: 'right', margin: "-4px 0 0 0px" }}
                            onClick={() => downloadExcelTemp()}>
                            {downloadExcelTempLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {' '}
                                    Loading...
                                </>
                            ) :
                                <Button className="btn btn-default balnktemplate_btnbgclr" variant="primary" >Export Data</Button>
                            }
                        </p>}
                        {files?.length <= 0 &&
                            <div className="col-md-12 pt-4">
                                <span className="Deductee_trans_importmodal_iconbox pr-2">
                                    <img src={importIcon} width="18" alt='import icon' />
                                </span>
                                <button className="btn btn-default balnktemplate_dragdropfilebtnbgclr" {...getRootProps({ className: 'fileuppopup_dropzone' })} style={{ paddingLeft: '20px' }}>
                                    <input {...getInputProps({ accept: '.xls,.xlsx,.xlsm', multiple: false })} />
                                    {!isDragActive && 'Drag \'n\' drop files here, or click to select files'}
                                    {isDragActive && !isDragReject && 'Drop files here'}
                                    {isDragReject && 'File type not accepted, sorry!'}
                                </button>
                            </div>
                        }
                        {files?.length > 0 &&
                            <div className="col-md-12 pt-3">
                                <span className="Deductee_trans_importmodal_iconbox pr-2">
                                    <img src={excelIcon} width="18" alt='excel icon' />
                                </span>
                                <button className="btn btn-default balnktemplate_filenamebtn">
                                    <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
                                </button>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-12 pt-3">
                            <p className="importexcel_textfs_style mb-2">
                                <span style={{ color: '#fc7d72' }}> Note: </span> Please click on Export Data to obtain complete data in Excel (if it is not already available). Update the Excel file to make changes to existing records or to add new records in bulk, and then re-import the data.
                            </p>
                        </div>
                        <div className="col-md-1">

                        </div>


                        <div className="col-lg-12 text-center pt-3">
                            {error
                                ? (
                                    <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>
                                )
                                : null}

                            <button class="btn  btn-default Deductee_trans_importmodal_submitbnt mr-2" onClick={showConfirmPopup} disabled={loading || files.length === 0}>
                                {loading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Loading...
                                    </>
                                ) : 'Submit'}
                            </button>
                            <button className="btn  btn-default Deductee_trans_importmodal_calbtn" onClick={() => handleClose()}>Cancel</button>
                        </div>
                    </div>


                </div>

            </WindowWrapperFixed>
        </>
    );
};

export default FileUploadPopupV2;
