import React from 'react';
import { IntlProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { CustomCell } from '../../components/grid/CustomCell';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';
import { formatNumber } from '../../utils/UtilityFunctions';

export default function DateMismatch(props) {
    const { 
            dateWiseConflictList, 
            dataState, 
            onChangeFilter, 
            isColumnActive, 
            loading, 
            cursor, 
            dataStateChange, 
            fetchData, 
            showMoreLoading,
            PAN_LIST, 
            paymentCodes 
        } = props;

    const rowRender = (trElement, props,) => {
        const key = trElement._owner.index;
        return (
            <tr key={key} class="reco-k-master-row" role="row">
                <td class=" textclr_blue">{props?.dataItem?.deductee_name ?? '-'}</td>
                <td class="text-left mist_redtext">{props?.dataItem?.pan ?? '-'}</td>
                <td class="text-center">{props?.dataItem?.display_code ?? '-'}</td>
                <td class="text-center">{props?.dataItem?.payment_date ?? '-'}</td>
                <td class="text-center">{props?.dataItem?.deduction_date ?? '-'}</td>
                <td class="text-center">{props?.dataItem?.delay_in_days ?? '-'}</td>
                <td class="text-right">{formatNumber(props?.dataItem?.paid_amtp ?? 0)}</td>
                <td class="text-right">{formatNumber(props?.dataItem?.total_tds_amtp ?? 0)}</td>
                <td class="text-right">{formatNumber(props?.dataItem?.late_tds_interest_amtp ?? 0)}</td>
            </tr>
        );
    }

    return (
        <div className="table table-striped invoicefilctstb mb-0">
            <IntlProvider locale="en">
                <Tooltip anchorElement="pointer" position="top">
                    <Grid
                        data={dateWiseConflictList}
                        className="table-responsive manualpopuptb"
                        {...dataState}
                        onDataStateChange={(event) => onChangeFilter(event.dataState)}
                        filterOperators={{
                            text: [
                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                            ],
                            dropdown: [
                                { text: 'grid.filterEqOperator', operator: 'eq' },
                            ],
                            numeric: [
                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                            ],
                            date: [
                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                { text: 'grid.filterEqOperator', operator: 'eq' },
                            ],
                            pan: [
                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                { text: 'grid.filterContainsOperator', operator: 'in' },
                            ],
                            textWithEmpty: [
                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                            ],
                        }}
                        style={{ maxHeight: '520px', overflowX: 'auto' }}
                        rowRender={rowRender}
                        sortable
                    >
                        <Column
                            width='230'
                            field="deductee_name"
                            className="text-center"
                            filter="textWithEmpty"
                            title={<CustomCell title="Deductee Name" field="deductee_name" dataStateChange={dataStateChange} dataState={dataState} />}
                            headerClassName={isColumnActive('deductee_name', dataState) ? 'active' : ''}
                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                            
                        />
                        <Column
                            field="pan"
                            className="text-center"
                            filter="pan"
                            title={<CustomCell title="PAN" field="pan" dataStateChange={dataStateChange} dataState={dataState} />}
                            headerClassName={isColumnActive('pan', dataState) ? 'active' : ''}
                            columnMenu={(props) => <ColumnMenu {...props} panFilter={true} filterList={PAN_LIST} />}
                            width={100}
                        />
                        <Column
                            field="code"
                            filter="dropdown"
                            title={<CustomCell title="Section" field="code" dataStateChange={dataStateChange} dataState={dataState} />}
                            headerClassName={isColumnActive('code', dataState) ? 'active' : ''}
                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                            width={80}
                        />
                        <Column
                            field="payment_date"
                            filter="date"
                            title={<CustomCell title="Payment Dt" field="payment_date" dataStateChange={dataStateChange} dataState={dataState} />}
                            headerClassName={isColumnActive('payment_date', dataState) ? 'active' : ''}
                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                            width={80}
                        />
                        <Column
                            field="deduction_date"
                            filter="date"
                            headerClassName={isColumnActive('deduction_date', dataState) ? 'active' : ''}
                            title={<CustomCell title="Deduction Dt" field="deduction_date" dataStateChange={dataStateChange} dataState={dataState} />}
                            columnMenu={ColumnMenu}
                            width={100}
                        />
                        <Column
                            field="delay_in_days"
                            filter="numeric"
                            headerClassName={isColumnActive('delay_in_days', dataState) ? 'active' : ''}
                            title={<CustomCell title="Delay in Days" field="delay_in_days" dataStateChange={dataStateChange} dataState={dataState} />}
                            columnMenu={ColumnMenu}
                            width={100}
                        />
                        <Column
                            field="paid_amtp"
                            filter="numeric"
                            headerClassName={isColumnActive('paid_amtp', dataState) ? 'active' : ''}
                            title={<CustomCell title="Amt Paid" field="paid_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                            columnMenu={ColumnMenu}
                            width={90}
                        />
                        <Column
                            field="total_tds_amtp"
                            filter="numeric"
                            headerClassName={isColumnActive('total_tds_amtp', dataState) ? 'active' : ''}
                            title={<CustomCell title="Tax Deducted" field="total_tds_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                            columnMenu={ColumnMenu}
                            width={100}
                        />
                        <Column
                            field="late_tds_interest_amtp"
                            filter="numeric"
                            headerClassName={isColumnActive('late_tds_interest_amtp', dataState) ? 'active' : ''}
                            title={<CustomCell title="Late Deduction Interest" field="late_tds_interest_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                            columnMenu={ColumnMenu}
                            width={150}
                        />
                        {(dateWiseConflictList.length === 0 && !loading) && (
                            <GridNoRecords className="no_records">
                                Yay !! There are no mismatches in the data.
                            </GridNoRecords>
                        )}
                    </Grid>
                </Tooltip>
            </IntlProvider>
            <div className="row mt-2" style={{ width: '100%' }}>
                <div className="col-md-12 text-center">
                    <ShowMorePagination
                        cursor={cursor}
                        fetchData={(nextPage) => {
                            fetchData({ ...dataState, page: nextPage.page });
                        }}
                        postData={{}}
                        loading={showMoreLoading}
                    />
                </div>
            </div>
        </div>

    );
}
