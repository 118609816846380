/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Formik, Form,
} from 'formik';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import NumericInput from '../NumericInput';
import { leaveSalaryEncashmentValidationSchema } from './validationSchema';
import { getLeaveSalaryEncashmentExemptAmt } from './allowancesCalculationScema';


const LeaveSalaryEncashmentForm = (props) => {

    const { fieldValue, setFieldValue, allowance_index, handleClose, getTotalAllowanceAmtp } = props;
    const [leaveSalaryEncashmentData, setLeaveSalaryEncashmentData] = useState(false);

    useEffect(() => {
        if (fieldValue.allowances[allowance_index].allowance_details) {
            setLeaveSalaryEncashmentData(fieldValue.allowances[allowance_index].allowance_details);
        }
        else {
            setLeaveSalaryEncashmentData({
                whether_gov_emp: 0,
                leaves_availed: 0,
                leaves_encas_rec_amtp: 0,
                service_period_year: 0,
                service_period_month: 0,
                unavailed_leaves: 0,
                avg_10_month_salary_amtp: 0,
                exempt_amtp: 0,
            });
        }
    }, [fieldValue]);

    const saveData = (values) => {
        setLeaveSalaryEncashmentData(values);
        let allowances = fieldValue.allowances;
        allowances[allowance_index].allowance_details = values;
        allowances[allowance_index].exempt_amtp = values.exempt_amtp;
        setFieldValue('allowances', allowances);
        getTotalAllowanceAmtp(fieldValue, allowances, setFieldValue);
        handleClose(-1);

    }


    const calculateUnavailedLeaves = (field, value, values, setFieldValue) => {
        let old_value = values[field];
        values[field] = value;

        let no_of_years = values.service_period_year;
        let unavailed_leaves = (parseInt(no_of_years) * 30) - parseFloat(values.leaves_availed);
        unavailed_leaves = parseInt(unavailed_leaves) > 0 ? unavailed_leaves : 0;
        setFieldValue('unavailed_leaves', unavailed_leaves);
        calculateExemptAmt('unavailed_leaves', unavailed_leaves, values, setFieldValue);
        values[field] = old_value;
    }


    const calculateExemptAmt = (field, value, values, setFieldValue) => {
        let old_value = values[field];
        values[field] = value;

        let exempt_amtp = getLeaveSalaryEncashmentExemptAmt(values);
        setFieldValue('exempt_amtp', exempt_amtp);

        values[field] = old_value;

    }

    if (leaveSalaryEncashmentData) {
        return (<Formik
            initialValues={leaveSalaryEncashmentData}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={leaveSalaryEncashmentValidationSchema}
            onSubmit={(values) => {
                const submitValues = { ...values };
                saveData(submitValues);
            }}
        >
            {({
                errors, values, setFieldValue,
            }) => {
                if (Object.keys(errors).length > 0) {
                    console.log('Form Errors: ', errors);
                }
                return (
                    <Form key={1} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12 pt-2">
                                <div className="card allowance">
                                    <div className="table-responsive">
                                        <table className="table table-striped allowanceWSDTB">
                                            <thead>
                                                <tr>
                                                    <td style={{ width: '177px' }}>Whether Cent Govt Employ</td>
                                                    <td>
                                                        <select
                                                            name="whether_gov_emp"
                                                            className="custom-select slecttextfs"
                                                            value={values.whether_gov_emp}
                                                            onChange={(e) => {
                                                                setFieldValue('whether_gov_emp', parseInt(e.target.value));
                                                                calculateExemptAmt('whether_gov_emp', e.target.value, values, setFieldValue);
                                                            }}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </td>
                                                    <td style={{ width: '158px' }}>Leaves Availed</td>
                                                    <td>
                                                        <NumericInput
                                                            type="text"
                                                            className={errors.leaves_availed ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="leaves_availed"
                                                            value={values.leaves_availed}
                                                            onChange={(e) => {
                                                                setFieldValue('leaves_availed', e.target.value);
                                                                calculateUnavailedLeaves('leaves_availed', e.target.value, values, setFieldValue);
                                                                //calculateExemptAmt('leaves_availed', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Leave Encashment Rec</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.leaves_encas_rec_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="leaves_encas_rec_amtp"
                                                            value={values.leaves_encas_rec_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('leaves_encas_rec_amtp', e.target.value);
                                                                calculateExemptAmt('leaves_encas_rec_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>Service Period</td>
                                                    <td>
                                                        <div className="year_input">
                                                            <label>Year</label>
                                                            <NumericInput
                                                                type="text"
                                                                className={errors.service_period_year ? "form-control error_input" : "form-control"}
                                                                placeholder="0"
                                                                name="service_period_year"
                                                                value={values.service_period_year}
                                                                onChange={(e) => {
                                                                    setFieldValue('service_period_year', e.target.value);
                                                                    calculateUnavailedLeaves('service_period_year', e.target.value, values, setFieldValue);
                                                                    //calculateExemptAmt('service_period_year', e.target.value, values, setFieldValue);
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="month_input">
                                                            <label>Month</label>
                                                            <NumericInput
                                                                type="text"
                                                                className={errors.service_period_month ? "form-control error_input" : "form-control"}
                                                                placeholder="0"
                                                                name="service_period_month"
                                                                value={values.service_period_month}
                                                                onChange={(e) => {
                                                                    if (e.target.value === '' || parseFloat(e.target.value) <= 11) {
                                                                        setFieldValue('service_period_month', e.target.value);
                                                                        calculateUnavailedLeaves('service_period_month', e.target.value, values, setFieldValue);
                                                                        //calculateExemptAmt('service_period_month', e.target.value, values, setFieldValue);
                                                                    }
                                                                }
                                                                }
                                                            />
                                                        </div>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Un-availed Leaves</td>
                                                    <td>
                                                        <NumericInput
                                                            type="text"
                                                            className={errors.unavailed_leaves ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="unavailed_leaves"
                                                            value={values.unavailed_leaves}
                                                            onChange={(e) => {
                                                                setFieldValue('unavailed_leaves', e.target.value);
                                                                calculateExemptAmt('unavailed_leaves', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>Avg. of 10 Months salary</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.avg_10_month_salary_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="avg_10_month_salary_amtp"
                                                            value={values.avg_10_month_salary_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('avg_10_month_salary_amtp', e.target.value)
                                                                calculateExemptAmt('avg_10_month_salary_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Exempt Amt</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0.00"
                                                            name="exempt_amtp"
                                                            value={values.exempt_amtp}
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="btn-group mt-2 mb-1">
                                    <button type="submit" className="btn btn-default monthwsdetilssavebtn">Save & Exit</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
        );
    }
    else {
        return false;
    }
};

export default LeaveSalaryEncashmentForm;