import React, { useEffect, useState } from "react";
import { formatAccountsNumber } from "../../../utils/UtilityFunctions";



const DefaultSummary = (props) => {

    const { apiData } = props;

    const [data, setData] = useState([]);


    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {            
            data.forEach((item) => {
                newData.push({
                    short_pay_def_amt: item[0],
                    short_pay_amt: item[1],
                    short_pay_payable: item[2],
                    short_ded_def_amt: item[3],
                    short_ded_amt: item[4],
                    short_ded_payable: item[5],
                    int_short_def_amt: item[6],
                    int_short_amt: item[7],
                    int_short_payable: item[8],
                    int_late_def_amt: item[9],
                    int_late_amt: item[10],
                    int_late_payable: item[11],
                    int_short_ded_def_amt: item[12],
                    int_short_ded_amt: item[13],
                    int_short_ded_payable: item[14],
                    int_late_ded_def_amt: item[15],
                    int_late_ded_amt: item[16],
                    int_late_ded_payable: item[17],
                    late_def_amt: item[18],
                    late_amt: item[19],
                    late_payable: item[20],
                    payable: item[21],
                    rounding_off: item[22],
                    net_payable: item[23]
                })
            })
            return newData
        }
    }; 


    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);


    return (
        <>
        <div className="tab-pane fade" id="exptds_justformtype_tab03" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">
                    <div className="card">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                            <table class="table table-striped exptds_justformtype_table mb-0">


                                <thead>
                                    <tr class="">
                                        <th width="10">
                                            Sr. No.
                                        </th>
                                        <th width="450">
                                            Type of Defaults
                                        </th>
                                        <th width="150">
                                            Default Amount
                                        </th>
                                        <th width="250">
                                            Amount reported as ‘Interest/ Others’ claimed in the statement
                                        </th>
                                        <th width="100">
                                            Payable
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">1</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Short Payment ">
                                                Short Payment

                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_pay_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">2</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title=" Short Deduction">
                                                Short Deduction

                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_ded_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_ded_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.short_ded_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">3</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title=" Interest on payments default u/s 201(1A)">
                                                Interest on payments default u/s 201(1A)

                                            </span>
                                        </td>
                                        <td class="text-right">

                                        </td>
                                        <td class="text-right">

                                        </td>
                                        <td class="text-right">

                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(a)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title=" Interest on short payment">
                                                Interest on short payment
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center pl-5">(b)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Interest on late payment">
                                                Interest on late payment
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">4</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="  Interest on deduction default u/s 201(1A) ">
                                                Interest on deduction default u/s 201(1A)
                                            </span>
                                        </td>
                                        <td class="text-right">

                                        </td>
                                        <td class="text-right">

                                        </td>
                                        <td class="text-right">

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center pl-5">(a)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Interest on short deduction">
                                                Interest on short deduction
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_ded_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_ded_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_short_ded_payable) ?? 0}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center pl-5">(b)</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title=" Interest on late deduction ">
                                                Interest on late deduction
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_ded_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_ded_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.int_late_ded_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-center">5</td>
                                        <td class="text-left exptds_justformtype_overflowtext">
                                            <span data-toggle="tooltip" data-placement="top" title="Late Filing u/s 234E">
                                                Late Filing u/s 234E
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.late_def_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.late_amt) ?? 0}
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.late_payable) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="4" class="text-right">
                                            Payable
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.payable) ?? 0}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right">
                                            Rounding-Off(to the nearest multiple of ten rupees)
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.rounding_off) ?? 0}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="4" class="text-right">
                                            Net Payable
                                        </td>
                                        <td class="text-right">
                                            {formatAccountsNumber(data[0]?.net_payable) ?? 0}
                                        </td>
                                    </tr>


                                </tbody>
                            </table>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

};

export default DefaultSummary;