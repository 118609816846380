/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CreateChallanForm from '../../components/createChallanForm/CreateChallanForm';
import { challanFormFields } from '../../components/createChallanForm/ChallanFormHelper';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { addChallan } from '../../api/challanAPI';
import { selectHeaderData } from '../../features/header/headerSlice';

const AddChallan = (props) => {
  const headerData = useSelector(selectHeaderData);
  const { onClose, deductorId, tdsComputation, returnHealthSummary } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const createChallan = (data, closeForm = true) => {
    setError(null);
    setSuccess(null);

    data.deductor_id = deductorId;

    async function callAPI() {
      try {
        const result = await addChallan(data);
        setSuccess(result.message);
        if (closeForm) {
          onClose(true, result.message);
        }
        return true;
      } catch (err) {
        // console.error('error: ', err);
        setError(err.message);
        return false;
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    return callAPI();
  };

  useEffect(() => {
    if (headerData.is_government_deductor === true) {
      challanFormFields.book_entry = 1;
      challanFormFields.type_of_payment = "";
    } else {
      challanFormFields.book_entry = 0;
      challanFormFields.type_of_payment = 'TDS_TCS_PAYABLE_BY_TAXPAYER_200';
    }
    if (tdsComputation && tdsComputation.challan_required) {
      challanFormFields.challanDeposited = false;
      challanFormFields.payment_mode = 'OFFLINE';
      const d = new Date();
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      const depositDateStr = `${ye}-${mo}-${da}`;
      challanFormFields.cheque_deposit_date = depositDateStr;
      tdsComputation.challan_required.data.map((tdsComputationChallanRequiredDetail) => {
        if (tdsComputationChallanRequiredDetail.title === 'Tax Amount (A-D)') {
          challanFormFields.tds_amtp = tdsComputationChallanRequiredDetail.value_amtp;
        } else if (tdsComputationChallanRequiredDetail.title === 'Interest (B-E)') {
          challanFormFields.interest_amtp = tdsComputationChallanRequiredDetail.value_amtp;
        } else if (tdsComputationChallanRequiredDetail.title === 'Fees (C-F)') {
          challanFormFields.fees_amtp = tdsComputationChallanRequiredDetail.value_amtp;
        }
        return true;
      });
    }
  }, [tdsComputation]);

  useEffect(() => {
    if (returnHealthSummary !== undefined) {
      challanFormFields.challanDeposited = returnHealthSummary?.challanDeposited ?? true;
      challanFormFields.fees_amtp = returnHealthSummary?.fees_amtp ?? 0;
      challanFormFields.interest_amtp = returnHealthSummary?.interest_amtp ?? 0;
      challanFormFields.tds_amtp = returnHealthSummary?.tds_amtp ?? 0;
      challanFormFields.assessment_year = Number(headerData.financial_year_formatted.split("-")[0]) + 1
      challanFormFields.deductee_status = headerData?.deductor_category === "K" ? "COMPANY" : "OTHER_THAN_COMPANY"

    }
  }, [returnHealthSummary])


  return (

    <div>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      <CreateChallanForm
        challanInfo={challanFormFields}
        onClose={() => onClose()}
        createChallan={createChallan}
        deductorId={deductorId}
      />
    </div>

  );
};

export default AddChallan;
