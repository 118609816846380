import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { parseDate } from '@telerik/kendo-intl';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import Pagination from '../../components/pagination/Pagination';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { formatNumber, capitalizeEachWordFirstLetter } from '../../utils/UtilityFunctions';
import { CustomCell, AddDeducteeTransactionsIcon } from '../../components/grid/CustomCell';
import { BASE_PATH, HEADER_FILE_NAME } from '../../app/constants';
import ShowAlert from '../../components/ShowAlert';
import { getDeducteesTransactionsV3, getDeducteesSectionV3Summary, sendTransactionEmail } from '../../api/deducteeTransactionsAPI';
import { getNatureOfPaymentCodes } from '../../api/challanAPI';
import { exportRegularReturn, downloadRegularReturnErrorFile } from '../../api/returnAPI';
import { selectHeaderData } from '../header/headerSlice';
import EditDeductee from '../deducteeList/EditDeductee';
import Skeleton12ColumnGrid from '../../components/Skeleton/Skeleton12ColumnGrid';
import CM_gif from '../../images/CM_gif.gif'
import ConflictPopup from './ConflictPopup';
import { downloadExcelTemplate } from '../../api/deductorAPI';
import ImportReturnFromExcelV2 from '../returnDashboard/importReturnFromExcel/ImportReturnFromExcelV2';
import emailIcon from '../../images/emailcoral.png';
import exportReturnIcon from '../../images/exceldownload4.png';
import downloadIcon from '../../images/downloadicon_white.png';
import EyeIcon from '../../images/eyeiconcolr.png';
import EyeCloseIcon from "../../images/eye_close_icon_coralclr.png";
import { faBullhorn, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { v4 as uuid } from 'uuid';
import ReturnFlowTimeLine from './ReturnFlowTimeLine';
import '../../components/tdsFixStyle.css'
import './DeducteeTransactionsV2.scss';
import './ImportReturnFile.scss';
import DeducteeWiseTransactionInnerListing from './DeducteeWiseTransactionInnerListing';
import { gocallApi } from '../../api/issueCertificateAPI';
import DownloadFile from '../../components/DownloadFile';
import Spinner from 'react-bootstrap/Spinner';


const DeducteeWiseTransactionListing = ({ history, ...props }) => {

    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);
    let nextPageUrl = '/';
    let paramsVals = '';
    if (location.state && location.state.nextPageUrl) {
        nextPageUrl = location.state.nextPageUrl;
    }
    if (location.state && location.state.paramsVals) {
        paramsVals = location.state.paramsVals;
    }
    if (nextPageUrl !== '/') {
        let obj = {
            "nextPageUrl": nextPageUrl,
            "paramsVals": paramsVals,
        }
        localStorage.setItem('values', JSON.stringify(obj));
    }
    const PAN_LIST = [
        { label: 'PAN APPLIED', value: 'PANAPPLIED' },
        { label: 'PAN INVALID', value: 'PANINVALID' },
        { label: 'PAN NOT AVAILABLE', value: 'PANNOTAVBL' },
    ];

    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deducteeTransactionsList, setDeducteeTransactionsList] = useState([]);
    const [error, setError] = useState(null);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [showDedcuteeEditForm, setShowDedcuteeEditForm] = useState(false);
    const [deducteeId, setDeducteeId] = useState(false);
    const limit = 16;
    const [deducteeSummary, setDeducteeSummary] = useState(null);
    const [paymentCodes, setPaymentCodes] = useState([]);
    const [dataState, setDataState] = useState({});
    const headerDataDetails = useSelector(selectHeaderData);
    const [skeletonList, setSkeletonList] = useState(true);
    const [showConflictPopup, setShowConflictPopup] = useState(false);
    const [importReturn, setImportReturn] = useState(false);
    const [topSummary, setTopSummary] = useState(null)
    const [visibleSections, setVisibleSections] = useState(new Set());
    const [downloadExcelFile, setDownloadExcelFile] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [formType, setFormType] = useState('');
    const [summaryColumnSkelton, setSummaryColumnSkelton] = useState(true);
    const [disabledTimeline, setDisabledTimeline] = useState(false);

    const downloadExcelTemp = async () => {
        setLoading(true);
        try {
            let params = `?form_type=${headerDataDetails.form_type}&quarter=${headerDataDetails.quarter}&return_type=REGULAR`;
            const result = await downloadExcelTemplate(params);
            const csiData = new Blob([result], { type: 'application/xls' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            let formType = headerDataDetails.form_type;
            link.setAttribute('download', 'ExpressTDS_' + formType + '_Template.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    const getSummary = async (data) => {
        try {
            if (data.summary_action === true) {
                setSummaryColumnSkelton(true);
                const resultSummary = await getDeducteesSectionV3Summary(data);
                setDeducteeSummary(resultSummary.data ? resultSummary.data : {
                    sum_paid_amtp: '',
                    sum_total_tds_amtp: '',
                    mapped_count: '',
                    deductee_count: '',
                    mapped: '',
                    section_count: '',
                    trans_count: '',
                    unmapped: '',
                });
            }
        } catch (err) {
            console.error('error: ', err);
            setError(err.message);
        } finally {
            setSummaryColumnSkelton(false);
        }
    }
    const getDeducteeWiseTransactionsList = (data) => {
        setLoading(true);
        async function fetchEverything() {
            async function fetchDeducteesTransactions() {
                const result = await getDeducteesTransactionsV3(data);
                setDeducteeTransactionsList(result?.data?.data ?? []);
                if ((result?.status ?? 0) === 1) {
                    getSummary(data);
                }
                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page;
                setCursor(result.data.cursor);
            }
            try {
                await Promise.all([
                    fetchDeducteesTransactions(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.message);
            } finally {
                setLoading(false);
                setSkeletonList(false);
            }
        }

        setLoading(true);
        fetchEverything();
    };

    const fetchPaymentCodesFunc = (params) => {
        async function fetchEverything() {
            async function fetchPaymentCodes() {
                const result = await getNatureOfPaymentCodes(params);
                const paymentCodeData = [];
                const payDataKey = {};
                result.data.forEach((paymentCode) => {
                    paymentCodeData.push({
                        label: `${paymentCode.code} - ${paymentCode.description}`,
                        value: paymentCode.nature_of_payment_id,
                    });
                    payDataKey[paymentCode.code] = paymentCode;
                });
                setPaymentCodes(paymentCodeData);
            }
            try {
                await Promise.all([
                    fetchPaymentCodes(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    const downloadErrorFile = async () => {
        setLoading(true);
        try {
            let payload = `?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`;
            const result = await downloadRegularReturnErrorFile(payload);
            const csiData = new Blob([result?.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS-ReturnErrorData.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            setError(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPaymentCodesFunc();
    }, []);

    useEffect(() => {
        const postData = getParamsHistoryUrl();
        getDeducteeWiseTransactionsList(postData);
        //getSummary(postData);
        // eslint-disable-next-line
    }, [searchStr]);

    const getParamsHistoryUrl = () => {

        const postData = {
            deductor_id: params.deductor_id,
            return_id: params.return_id,
            sort_on: params.sort_on ? params.sort_on : '',
            sort_by: params.order_by ?? "",
            summary_action: true,
        };

        if (params?.filter_deductee_id) {
            postData.filter_deductee_id = params?.filter_deductee_id;
        }

        if (params.sort) {
            dataState.sort = JSON.parse(atob(params.sort));
            postData.sort = JSON.parse(atob(params.sort));
        }

        if (postData.sort_on !== '') {
            postData.summary_action = false;
        }

        if (params.page) {
            if (postData.page > 1) {
                postData.summary_action = false;
            }
        }

        if (params.filters) {
            postData.summary_action = true;
            // IN getParamsHistoryUrl
            let frs = JSON.parse(atob(params.filters));
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'payment_date' || frs[i].filters[j].field === 'deduction_date') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                }
            }

            dataState.filter = { filters: [] };
            dataState.filter.filters = frs;
            postData.filters = frs;
        }



        if (params.page) {
            postData.page = parseInt(params.page);
        }
        if (params.action) {
            postData.action = params.action.split(",");
        }

        postData.limit = params.limit ?? limit;
        setDataState(dataState);
        return postData;
    };

    const onClose = (reload, msg = '') => {
        setShowDedcuteeEditForm(false)
        if (msg) {
            setSuccess(msg);
        }

        if (reload) {
            setTimeout(() => {
                history.go();
            }, 300);
        }
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        let additional_filter = [];
        let action = '';
        if (params.additional_filter) {
            additional_filter = params.additional_filter.split(",");
        }
        if (params.action) {
            action = params.action;
        }
        createHistoryUrl(dataState, action, additional_filter);
    };

    const createHistoryUrl = (dataState = [], action = '', additional_filter = []) => {
        let filterStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;

        if (params?.filter_deductee_id) {
            filterStr += `&filter_deductee_id=${params.filter_deductee_id}`;
        }

        if (params.tab) {
            filterStr += `&tab=${params.tab}`;
        }

        if (params.action) {
            let all_status = params.action.split(",");
            let array_index = all_status.indexOf(action);
            if (array_index !== -1) {
                all_status.splice(array_index, 1);
            }
            else {
                all_status.push(action);
            }
            filterStr += all_status.length > 0 ? `&action=${all_status.join(",")}` : '';
        }
        // else {
        //     filterStr += `&action=${action}`;
        // }

        if (additional_filter.length > 0) {
            filterStr += `&additional_filter=${additional_filter.join(",")}`;
        }

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }

        // if (dataState.sort && dataState.sort.length > 0) {
        //     filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
        // }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${dataState.sort[0].field}&order_by=${dataState.sort[0].dir}`;
        }

        history.push(filterStr);
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };


    // const challanCells = (dataItem) => (
    //     <td className={statusColor(dataItem, "saldtddeleditsect text-right")}>
    //         {' '}
    //         <span>
    //             {dataItem.challan_no ? dataItem.challan_no : '-'}
    //         </span>
    //     </td>

    // );
    const statusColor = (dataItem, className) => {
        return className;
    };

    // const renderSubTextTitle = (title, subText, color) => {
    //     return (
    //         <div>
    //             {title} {' '}
    //             {subText !== undefined && (<div className="text-right">
    //                 <span title={title + ': ' + subText} className="b2btbtrthtextclr" style={{ color: `#${color}` }}>{subText}</span>
    //             </div>)}
    //         </div>
    //     );
    // }

    function getFirstItemOfSet(set) {
        for (let item of set) {
            if (item) {
                return item;
            }
        }
        return undefined;
    }

    const rowRender = (trElement, props,) => {
        const data = props.dataItem;
        const rowCounter = trElement._owner.index;
        const deducteeID = data.deductee_id;



        const sendEmail = async (deductee_id, nature_of_payment_id) => {
            setLoading(true);
            try {
                let payload = {
                    deductor_id: params?.deductor_id,
                    return_id: params?.return_id,
                    nature_of_payment_id: nature_of_payment_id,
                    deductee_id: deductee_id
                };
                const result = await sendTransactionEmail(payload);
                if (result?.data?.status) {
                    setSuccess(result?.data?.message);
                } else {
                    setSuccess(result?.data?.message);
                }
            } catch (err) {
                console.error('Error in export: ', err);
                setError(err);
            } finally {
                setLoading(false);
            }
        }




        return (
            <>  <tr data-index={rowCounter} className={rowCounter % 2 === 0 ? 'deducteewise_tb_alternetrowbgclr_grey ' : 'deducteewise_tb_alternetrowbgclr_white '} role="row">

                <td className="text-left"  >
                    <span data-toggle="tooltip" data-placement="top" title={data?.deductee_name ?? ''} style={{ color: '#31719B' }}>
                        {data?.deductee_name ?? ''}
                    </span>
                </td>
                <td className="text-left" >{data?.pan ?? ''}	</td>
                <td className="text-center" >{data?.display_code ?? ''}</td>
                {/* <td className="text-center" width='150'>{data?.rate ?? ''}</td> */}
                <td className="text-right" >{formatNumber(data?.deductee_paid_amtp ?? 0)}</td>
                <td className="text-right" >{formatNumber(data?.deductee_total_tds_amtp ?? 0)}</td>
                {/* <td className="text-right" width='150'>{data?.mapped_challan ?? ''}</td> */}
                <td className="text-center" >
                    {
                        headerDataDetails.organization_type === 'CORPORATE'
                            ? <>
                                <img src={emailIcon} onClick={() => sendEmail(data?.deductee_id, data?.nature_of_payment_id)} style={{ cursor: 'pointer' }} width={16} className="emailcursor" alt="" />
                                {'  '}</>
                            : null
                    }

                    <img
                        onClick={() => {

                            let newVisibleSections = new Set(visibleSections);
                            if (newVisibleSections.has(deducteeID)) {
                                newVisibleSections.delete(deducteeID);
                            } else {
                                newVisibleSections.add(deducteeID);
                                if (newVisibleSections.size === 3) {
                                    const firstItem = getFirstItemOfSet(newVisibleSections);
                                    newVisibleSections.delete(firstItem);
                                }
                            }
                            setVisibleSections(newVisibleSections);

                        }}
                        src={visibleSections.has(deducteeID) ? EyeCloseIcon : EyeIcon}
                        width="16"
                        alt="sysicon"
                        style={{ cursor: 'pointer' }}
                    />

                </td>
            </tr>

            </>
        );
    }


    let popupIndex = 0;
    let totalPopups = 0;
    Array.from(visibleSections).forEach((deducteeID) => {
        if (visibleSections.has(deducteeID)) {
            totalPopups++;
        }
    });

    const getSelectedDeducteeData = (deducteeID) => {
        const selectedDeducteeRecord = deducteeTransactionsList.filter((v) => v.deductee_id === deducteeID);
        return selectedDeducteeRecord?.[0] ?? [];

    }


    const getTopSummary = async (deductorId, returnId) => {
        if (topSummary === null) {
            let url = `/api/v2/transaction/summary-count?deductor_id=${deductorId}&return_id=${returnId}`
            try {
                const result = await gocallApi("get", url);
                setTopSummary(result?.data ?? {})

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
            }
        }
    }
    useEffect(() => {
        getTopSummary(params?.deductor_id, params?.return_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.deductor_id, params?.return_id])

    useEffect(() => {
        setFormType(headerDataDetails?.form_type);
    }, [headerDataDetails?.form_type])


    const getDeducteeLabel = (formType) => {
        switch (formType) {
            case '24Q': return 'Employee';
            case '27EQ': return 'Collectee';
            default: return 'Deductee';
        }
    }


    return (
        <>
            {downloadExcelFile &&
                <DownloadFile
                    apiFunction={exportRegularReturn}
                    setDownloadLoading={setDownloadLoading}
                    setError={setError}
                    fileName={'ExpressTDS-ReturnData'}
                    setDownloadExcelFile={setDownloadExcelFile}
                    sessionStorageKey={`export-return-ref-key-${params?.deductor_id}-${params?.return_id}-${params?.financial_year}`}
                    params={`?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`}
                    handleSuccessProceed={() => {
                        setDownloadExcelFile(false);
                    }}
                    handleErrorProceed={(msg) => {
                        setDownloadExcelFile(false);
                    }}
                />
            }

            {Array.from(visibleSections).map((deducteeID) => {
                popupIndex = popupIndex + 1;
                const parms = {
                    onClose: () => {
                        const newVisibleSections = new Set(visibleSections);
                        newVisibleSections.delete(deducteeID);
                        setVisibleSections(newVisibleSections);
                    },
                    index: popupIndex - 1,
                    totalPopups: totalPopups,
                }
                return (
                    <DeducteeWiseTransactionInnerListing
                        deductorId={params?.deductor_id}
                        returnId={params?.return_id}
                        deduteeId={deducteeID}
                        //natureOfPaymentId={getSelectedDeducteeData(deducteeID)}
                        deducteeData={getSelectedDeducteeData(deducteeID)}
                        setError={setError}
                        {...parms}
                    />
                )
            })}


            {loading ? <LoadingIndicator /> : null}

            <ImportReturnFromExcelV2
                show={importReturn}
                handleClose={(refresh) => {
                    setImportReturn(false);
                }}
                deductorId={params?.deductor_id}
                returnId={params.return_id}
                importExelError={() => { setImportReturn(false) }}
                showImportReturnFromTxt={false}
                downloadExcelTemp={downloadExcelTemp}
                fetchTransactionOverView={() => null}
                setDisabledTimeline={setDisabledTimeline}
            />

            {showDedcuteeEditForm
                ? (
                    <EditDeductee
                        onClose={onClose}
                        deductorId={params.deductor_id}
                        deducteeId={deducteeId}
                        deducteeInfo={false}
                        headerNotUpdate={true}
                        componentImport={false}
                    />
                )
                : null}

            {showConflictPopup ? (
                <ConflictPopup
                    show={showConflictPopup}
                    closeModal={() => { setShowConflictPopup(false); }}
                    onHide={() => {
                        setShowConflictPopup(false);
                    }}
                    deductorId={params.deductor_id}
                    returnId={params.return_id}
                    PAN_LIST={PAN_LIST}
                    paymentCodes={paymentCodes}
                />

            ) : null}

            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />
            <div className="container-fluid">
                <ReturnFlowTimeLine returnId={params.return_id} deductorId={params.deductor_id} currentPage={`TransactionListing`} disabledTimeline={disabledTimeline} />
            </div>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12" > </div>
                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <nav className="Deducteetrans_nav d-flex justify-content-center">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise showbox"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    Transaction Wise  <br />
                                    <span className="Deducteetrans_dedwise_count_text">({topSummary?.trans_count ?? 0})</span>
                                </Link>
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise active Deducteetrans_defaulttab showbox"
                                    to={`${BASE_PATH}deductee-wise-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    {getDeducteeLabel(formType)} Wise <br />
                                    <div className="Deducteetrans_dedwise_count_text">({topSummary?.deductee_count ?? 0})</div>
                                </Link>
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise Deducteetrans_defaulttab showbox"
                                    to={`${BASE_PATH}section-wise-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    {`${formType === '27EQ' ? 'Collectee Code Wise' : 'Section Wise'}`}<br />
                                    <span className="Deducteetrans_dedwise_count_text">({topSummary?.section_count ?? 0})</span>
                                </Link>
                            </div>
                        </nav>
                    </div>
                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 text-right">
                        <button className="btn btn-default errorrecodsbtn mr-1" onClick={() => setDownloadExcelFile(true)} title='Export Report'>
                            <span className="pr-1">
                                {
                                    downloadLoading
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}</>
                                        : <img src={exportReturnIcon} width="16" alt="Export Return" />
                                }
                            </span>
                            Return
                        </button>

                        <button className="btn btn-default errorrecodsbtn" onClick={() => downloadErrorFile()} title='Download Error Report'>
                            <span className="pr-1">
                                <img src={downloadIcon} width="16" alt="Download Error Report" />
                            </span>
                            Error Records
                        </button>
                    </div>
                </div>
                <div className="row  mt-2 ">
                    <div className="col-md-12">
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show" id="Deducteetrans_dedwise_tab02" role="tabpanel" aria-labelledby="nav-home-tab" style={{ display: 'block' }}>
                                <div className="card p-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                skeletonList ? <Skeleton12ColumnGrid rowNo={7} />
                                                    :
                                                    <div id="accordian_dwt" className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                        <Tooltip anchorElement="pointer" position="right">
                                                            <Grid
                                                                className="table table-striped listofsalarydetailstb1  deducteewisetrns_heightfixed mb-0"
                                                                style={{ maxHeight: '480px' }}

                                                                data={deducteeTransactionsList}
                                                                {...dataState}
                                                                onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                                filterOperators={{
                                                                    text: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                    ],
                                                                    numeric: [
                                                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                        { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                                    ],
                                                                    date: [
                                                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                    ],
                                                                    textWithEmpty: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                                    ],
                                                                    dropdown: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'in' },
                                                                    ],
                                                                    pan: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterContainsOperator', operator: 'in' },
                                                                    ],
                                                                    challan: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                                                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                                    ],
                                                                }}
                                                                sortable={{
                                                                    allowUnsort: true,
                                                                }}
                                                                rowRender={rowRender}
                                                            >


                                                                <Column


                                                                    field="deductee_name"
                                                                    className="text-center"
                                                                    filter="textWithEmpty"
                                                                    title={<CustomCell title={`${getDeducteeLabel(formType)} Name`} field="" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                    headerClassName={isColumnActive('deductee_name', dataState) ? 'active' : ''}
                                                                    cell={(event) => (
                                                                        <td title={capitalizeEachWordFirstLetter(event.dataItem.deductee_name)} className={statusColor(event.dataItem, "deductee_link")} onClick={() => { setDeducteeId(event.dataItem.deductee_id); setShowDedcuteeEditForm(true); }}>
                                                                            {' '}
                                                                            <span>
                                                                                {capitalizeEachWordFirstLetter(event.dataItem.deductee_name)}
                                                                            </span>
                                                                        </td>
                                                                    )}
                                                                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                                    sortable={true}

                                                                />
                                                                {/* <Column
                                                                    field="pan"
                                                                    className="text-center"
                                                                    filter="dropdown"
                                                                    title={<CustomCell title={'PAN'} field="pan" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                    headerClassName={isColumnActive('pan', dataState) ? 'active' : ''}
                                                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                                                /> */}
                                                                <Column
                                                                    field="pan"
                                                                    headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                                    title="PAN"

                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "text-left")} style={{ width: '10%', color: '#' + event.dataItem.pan_color }}>
                                                                            {' '}
                                                                            {event.dataItem.pan}
                                                                        </td>
                                                                    )}
                                                                    columnMenu={(props) => <ColumnMenu {...props} panFilter={true} filterList={PAN_LIST} />}
                                                                    filter="pan"
                                                                />
                                                                <Column

                                                                    field="code"
                                                                    headerClassName={isColumnActive('code') ? 'active' : ''}
                                                                    title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Code' : 'Section'}
                                                                    filter="dropdown"
                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "")}>
                                                                            {event.dataItem.display_code}
                                                                        </td>
                                                                    )}
                                                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                                                                />
                                                                {/* <Column
                                                                    width='150'
                                                                    field={''}
                                                                    headerClassName={'sorting_disabled'}
                                                                    title={`Rate(%)`}
                                                                /> */}
                                                                <Column
                                                                    field="deductee_paid_amtp"
                                                                    headerClassName={isColumnActive('deductee_paid_amtp') ? 'active' : ''}
                                                                    title={<AddDeducteeTransactionsIcon field="deductee_paid_amtp" dataState={dataState}
                                                                        dataStateChange={dataStateChange}
                                                                        title={headerDataDetails && headerDataDetails.form_type === '27EQ' ? 'Amt Rcvd' : 'Amt Paid'}
                                                                        subtextData={{ subText: deducteeSummary?.sum_paid_amtp ?? 0, color: deducteeSummary?.sum_paid_amtp_color }}
                                                                        showButton={false}
                                                                        summaryColumnSkelton={summaryColumnSkelton}
                                                                    />}
                                                                    filter="numeric"
                                                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                                                    sortable={false}
                                                                />
                                                                <Column
                                                                    field="deductee_total_tds_amtp"
                                                                    headerClassName={isColumnActive('deductee_total_tds_amtp', dataState) ? 'active' : ''}
                                                                    title={<AddDeducteeTransactionsIcon field="deductee_total_tds_amtp" dataState={dataState}
                                                                        dataStateChange={dataStateChange}
                                                                        title="Tax Deducted"
                                                                        subtextData={{ subText: deducteeSummary?.sum_total_tds_amtp ?? 0, color: deducteeSummary?.sum_paid_amtp_color }}
                                                                        showButton={false}
                                                                        summaryColumnSkelton={summaryColumnSkelton}
                                                                    />}
                                                                    filter="numeric"
                                                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                                                    sortable={false}
                                                                />
                                                                {/* <Column
                                                                    width='150'
                                                                    field={'challan_no' ? 'challan_no' : '-'}
                                                                    headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                                                    //title="Mapped Challan"
                                                                    title={renderSubTextTitle('Mapped Challan', deducteeSummary?.mapped_challan ?? '0', deducteeSummary?.mapped_challan_color)}
                                                                    filter="challan"
                                                                    cell={(event) => challanCells(event.dataItem)}
                                                                    columnMenu={(props) => <ColumnMenu challanFilter={true} {...props} />}
                                                                /> */}
                                                                <Column
                                                                    field="editIcon"
                                                                    sortable={false}
                                                                    //locked={true}
                                                                    title="Action"
                                                                    headerClassName={'sorting_disabled'}
                                                                //width={(headerDataDetails && headerDataDetails.form_type === '24Q') ? '165' : '70'}
                                                                />
                                                            </Grid>

                                                        </Tooltip>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <Pagination cursor={cursor} />
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="challans_leftsidepopupbox" data-toggle="modal" data-target="#tdsreimport_popup">
                <div class="challans_AnnouncementsIcon" data-toggle="modal" data-target="#updatebooks2adatam">
                    <div class="challans_Announcements">
                        <div class="challans_Announcements-wrapper challans_has-unseen-items Dropdown">
                            <div class="Dropdown-toggle Button">
                                <div class="challans_Announcements-bubble">&nbsp;</div>
                                <div>
                                    <FontAwesomeIcon icon={faBullhorn} style={{ color: '#fff', fontSize: '11px', transform: 'rotate(-38deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span
                    onClick={() => { setImportReturn(true); }}
                    class="mb-0 btn challans_textfs"
                    type="button"
                > Import Return </span>
            </div>

            <div class="return_flow_gifimgmainbox" onClick={() => setShowConflictPopup(true)}>
                <span class="bottomrightsidegifimgbox">
                    <img src={CM_gif} alt="Deducted " width="105" />
                </span>
            </div>
        </>
    );
}

export default DeducteeWiseTransactionListing;