import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { BASE_PATH } from '../../../app/constants';


const LikelyDefault = (props) => {


    const {
        deductorId,
        returnId,
        verifiedChallanCountInfo,
        lateFeeCountInfo,
        verifiedPanCountInfo,
        verifiedFilerCountInfo,
        setShowChallanForm,
        setShowLowerDeductionPopup,
        ldcSummary
    } = props;

    const [showLateFeePopup, setShowLateFeePopup] = useState(false);

    const [data, setData] = useState([]);

    const today = moment().format('DD/MM/YYYY');

    const sort = function (prop, arr) {
        prop = prop.split('.');
        var len = prop.length;

        arr.sort(function (a, b) {
            var i = 0;
            while (i < len) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a > b) {
                return -1;
            } else if (a < b) {
                return 1;
            } else {
                return 0;
            }
        });
        return arr;
    };

    useEffect(() => {

        const data = [
            {
                'title': 'CERTIFICATE_BOX',
                'value': ldcSummary?.unverified ?? 0,
            },
            {
                'title': 'PAN_BOX',
                'value': verifiedPanCountInfo?.cnt_unverified_pan ?? 0,
            },
            {
                'title': 'CHALLAN_BOX',
                'value': verifiedChallanCountInfo?.total_unverified_challan ?? 0,
            },
            {
                'title': 'COMPLIANCE_BOX',
                'value': verifiedFilerCountInfo?.cnt_insights_pending ?? 0,
            },
            {
                'title': 'LATE_FEE_BOX',
                'value': lateFeeCountInfo?.late_fees_days ?? 0,
            }
        ];

        const LikelySorted = sort('value', data);

        setData(LikelySorted);


    }, [verifiedPanCountInfo, verifiedChallanCountInfo, verifiedFilerCountInfo, lateFeeCountInfo, ldcSummary])

    return (
        <>
            <div className="row mt-2">
                <div className="col-12 col-xl-12">
                    <fieldset className="likeydefault_found_fs">
                        <legend className="likeydefault_found_leg mb-0 pb-0">
                            <h4 className="mb-0">Likely Defaults</h4>
                        </legend>
                        <div className="challanverify_contentbox likelydefaultboxinline">
                            {Object.keys(data).map((item, index) => (
                                <>
                                    {
                                        data[item].title === 'CERTIFICATE_BOX' &&
                                        <div className={`media media challanverify_mediabox ${index < 4 ? "likelydefaults_mediabox_rightborder" : ''} pl-2`}>

                                            <div className="media-body">
                                                <div className="likelydefault_textbox">
                                                    {data[item].value === 0 ?

                                                        <p className="likelydefault_nobox_fs challanverify_textclr_yellow">
                                                            0
                                                        </p>
                                                        :
                                                        <p className="likelydefault_nobox_fs challanverify_textclr_yellow cursor_pointer"
                                                            onClick={() => setShowLowerDeductionPopup(true)}
                                                        >
                                                            {data[item].value ?? 0}
                                                        </p>}
                                                    <hr className="likelydefault_bottomborder" />
                                                    <h4 className="likely_titlefs challanverify_textclr_yellow">LDC Unverified</h4>
                                                    <p className="likely_textfs challanverify_textclr_yellow mb-2">
                                                        {data[item].value === 0 ? "All certificates are verified" :
                                                            `Verify lower deduction certificates`
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {data[item].title === 'CHALLAN_BOX' &&

                                        <div className={`media media challanverify_mediabox ${index < 4 ? "likelydefaults_mediabox_rightborder" : ''} pl-2`}>

                                            <div className="media-body">
                                                <div className="likelydefault_textbox">
                                                    {data[item].value === 0 ?

                                                        <p className="likelydefault_nobox_fs challanverify_textclr_coral">
                                                            0
                                                        </p>
                                                        :
                                                        <Link
                                                            target="_blank"
                                                            to={`${BASE_PATH}challans?deductor_id=${deductorId}&return_id=${returnId}&&filters=${btoa(JSON.stringify([{ logic: "and", filters: [{ field: "verified_status", operator: "in", value: ["UNVERIFIED"] }] }]))}`}
                                                            tabIndex="-1"
                                                        >
                                                            <p className="likelydefault_nobox_fs challanverify_textclr_coral cursor_pointer">
                                                                {data[item].value ?? 0}
                                                            </p>
                                                        </Link>}
                                                    <hr className="likelydefault_bottomborder" />
                                                    <h4 className="likely_titlefs challanverify_textclr_coral">Challan Unverified</h4>
                                                    <p className="likely_textfs challanverify_textclr_coral mb-2">
                                                        {data[item].value === 0 ? "All the Challans are verified" :
                                                            `Verify challans to prevent mismatches while processing and to avoid Notices or Filing Correction return`
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {data[item].title === 'LATE_FEE_BOX' &&

                                        <div className={`media media challanverify_mediabox ${index < 4 ? "likelydefaults_mediabox_rightborder" : ''} pl-2`}>

                                            <div className="media-body">
                                                <div className="likelydefault_textbox">
                                                    <div className="likelydefault_textbox">
                                                        {data[item].value === 0 ?

                                                            <p className="likelydefault_nobox_fs challanverify_textclr_blue">
                                                                0
                                                            </p>
                                                            :
                                                            <p className="likelydefault_nobox_fs challanverify_textclr_blue cursor_pointer"
                                                                onClick={() => { setShowLateFeePopup(true) }}

                                                            >
                                                                {data[item].value ?? 0}
                                                            </p>}
                                                        <hr className="likelydefault_bottomborder" />
                                                        <h4 className="likely_titlefs challanverify_textclr_blue">Late Fees 234E</h4>
                                                        <p className="likely_textfs challanverify_textclr_blue mb-2">
                                                            {data[item].value === 0 ?
                                                                "You are well in time filing the return so No fees applicable as of now" :
                                                                `Due to a delay of ${data[item].value ?? 0} days, it is necessary to pay the late fee u/s 234E before filing the return to prevent the possibility of receiving any demand notices later`
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    {
                                        data[item].title === 'PAN_BOX' &&
                                        <div className={`media media challanverify_mediabox ${index < 4 ? "likelydefaults_mediabox_rightborder" : ''} pl-2`}>

                                            <div className="media-body">
                                                <div className="likelydefault_textbox">
                                                    {data[item].value === 0 ?

                                                        <p className="likelydefault_nobox_fs challanverify_textclr_red">
                                                            0
                                                        </p>
                                                        :
                                                        <Link
                                                            target="_blank"
                                                            to={`${BASE_PATH}deductee-list?deductor_id=${deductorId}&filters=${btoa(JSON.stringify([{ logic: "and", filters: [{ field: "pan_status", operator: "in", value: ["PAN_AVAILABLE_BUT_UNVERIFIED"] }] }]))}`}
                                                            tabIndex="-1"
                                                        >
                                                            <p className="likelydefault_nobox_fs challanverify_textclr_red cursor_pointer">
                                                                {data[item].value ?? 0}
                                                            </p>
                                                        </Link>}
                                                    <hr className="likelydefault_bottomborder" />
                                                    <h4 className="likely_titlefs challanverify_textclr_red">PAN Unverified</h4>
                                                    <p className="likely_textfs challanverify_textclr_red mb-2">
                                                        {data[item].value === 0 ? "All PAN's in the return are verified" :
                                                            `If a PAN remains unverified there can be the possibility of receiving notices regarding short deductions`
                                                        }
                                                    </p>

                                                </div>
                                            </div>
                                        </div>}
                                    {data[item].title === 'COMPLIANCE_BOX' &&
                                        <div className={`media media challanverify_mediabox ${index < 4 ? "likelydefaults_mediabox_rightborder" : ''} pl-2`}>
                                            <div className="media-body">
                                                <div className="likelydefault_textbox">
                                                    {data[item].value === 0 ?

                                                        <p className="likelydefault_nobox_fs challanverify_textclr_yellow">
                                                            0
                                                        </p>
                                                        :
                                                        <Link
                                                            target="_blank"
                                                            to={`${BASE_PATH}compliance-status-check?deductor_id=${deductorId}&return_id=${returnId}&auto=1`}
                                                            tabIndex="-1"
                                                        >
                                                            <p className="likelydefault_nobox_fs challanverify_textclr_yellow cursor_pointer">
                                                                {data[item].value ?? 0}
                                                            </p>
                                                        </Link>}
                                                    <hr className="likelydefault_bottomborder" />
                                                    <h4 className="likely_titlefs challanverify_textclr_yellow">Compliance Check Pending</h4>
                                                    <p className="likely_textfs challanverify_textclr_yellow mb-2">
                                                        {data[item].value === 0 ? "Compliance Check is done for all PAN's" :
                                                            `To avoid possible complications like TDS short deduction notice resulting from PAN of specified person, it is recommended to complete the pending compliance check`
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>}
                                </>))}
                        </div>
                    </fieldset>
                </div>
            </div>
            {showLateFeePopup &&
                <WindowWrapperFixed
                    onClose={() => setShowLateFeePopup(false)}
                    totalPopups={1}
                    index={0}
                    initialHeight={'auto'}
                    className="mapped_trans_modal mappedtransitionchallans"
                    style={{ maxHeight: '250px', overflowX: 'hidden', position: "fixed" }}
                    totalpopups={1}
                    initialWidth={800}
                    title={
                        <h4 class="modal-title latedeductint_titlefs" style={{ fontSize: "1rem", fontWeight: "500", padding: "4px 0px 4px 0px", width: "100%" }} >
                            <div class="row">
                                <div class="col-md-7">
                                    Late Fees till ({today})
                                </div>
                                <div class="col-md-5">
                                    <span class="latededuct_amtfs" style={{
                                        float: "right",
                                        color: "black",
                                        marginRight: "10px"
                                    }}>
                                        {/* {Math.min(((lateFeeCountInfo?.late_fees_days ?? 0) * 200), (lateFeeCountInfo?.late_fees_amtp ?? 0)).toLocaleString()} */}
                                    </span>
                                </div>
                            </div>
                        </h4>}

                >



                    <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <table class="table table-striped Deducteetrans_deducteewise_tb mb-0">
                            <thead>
                                <tr>
                                    <th width="120">Due Date</th>
                                    <th width="120">Delay in Days</th>
                                    <th width="120">Calculation</th>
                                    <th width="120">Late Fee Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="">

                                    <td class="text-center">
                                        {moment(lateFeeCountInfo?.due_date).format('D-MM-YYYY')}
                                    </td>
                                    <td class="text-center">
                                        {lateFeeCountInfo?.late_fees_days}
                                    </td>
                                    <td class="text-center">
                                        {lateFeeCountInfo?.late_fees_days}*200 per day
                                    </td>

                                    <td class="text-right">
                                        {Math.min(((lateFeeCountInfo?.late_fees_days ?? 0) * 200), (lateFeeCountInfo?.late_fees_amtp ?? 0)).toLocaleString()}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <div className="text-center mt-3 mb-2">

                            <div className=" btn btn-default validup_generatefvu_btn validup_downFVUbtn" style={{ borderRadius: "20px" }} onClick={() => {

                                setShowChallanForm(true)
                                setShowLateFeePopup(false)

                            }}

                            >
                                Pay
                            </div>

                        </div>
                    </div>

                </WindowWrapperFixed>}
        </>
    )
}

export default LikelyDefault