import React from 'react';
import WindowWrapper from '../../../components/grid/WindowWrapper';


const ReturnTransactionsPopup = (props) => {
  const { onClose } = props;


  return (
    <>
      <WindowWrapper
        title={
          <h4 className="modal-title w-100">
            <div class="row col-md-12">
              <div class="col-md-7">
                  Late Deduction Interest     
              </div>
              <div class="col-md-5">
                  <span class="latededuct_amtfs">
                      23,00,00,00,000
                  </span>
              </div>
          </div>             
          </h4>
        }
        onClose={onClose}
        totalPopups={1}
        index={1}
        //initialHeight={'auto'}
        //className={'editDeductorForm'}
        initialLeft={150}
        initialTop={350}
      >

        <div className="row">
          <div className="col-md-12">

            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
              <table className="table table-striped Deducteetrans_deducteewise_tb mb-0">
                <thead>
                  <tr>
                    <th width="200">Deductee Name</th>
                    <th width="120">TDS Deducted</th>
                    <th width="120">Payment Date</th>
                    <th width="120">Deduction Date</th>
                    <th width="120">Default Months</th>
                    <th width="120">Interest Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td>
                      Virat Kohli
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                    <td className="text-center">
                      30/12/2022
                    </td>
                    <td className="text-center">
                      02/05/2023
                    </td>
                    <td className="text-center">
                      5
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                  </tr>
                  <tr className="">
                    <td>
                      Virat Kohli
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                    <td className="text-center">
                      30/12/2022
                    </td>
                    <td className="text-center">
                      02/05/2023
                    </td>
                    <td className="text-center">
                      5
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                  </tr>
                  <tr className="">
                    <td>
                      Virat Kohli
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                    <td className="text-center">
                      30/12/2022
                    </td>
                    <td className="text-center">
                      02/05/2023
                    </td>
                    <td className="text-center">
                      5
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                  </tr>
                  <tr className="">
                    <td>
                      Virat Kohli
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                    <td className="text-center">
                      30/12/2022
                    </td>
                    <td className="text-center">
                      02/05/2023
                    </td>
                    <td className="text-center">
                      5
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                  </tr>
                  <tr className="">
                    <td>
                      Virat Kohli
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                    <td className="text-center">
                      30/12/2022
                    </td>
                    <td className="text-center">
                      02/05/2023
                    </td>
                    <td className="text-center">
                      5
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                  </tr>
                  <tr className="">
                    <td>
                      Virat Kohli
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                    <td className="text-center">
                      30/12/2022
                    </td>
                    <td className="text-center">
                      02/05/2023
                    </td>
                    <td className="text-center">
                      5
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                  </tr>
                  <tr className="">
                    <td>
                      Virat Kohli
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                    <td className="text-center">
                      30/12/2022
                    </td>
                    <td className="text-center">
                      02/05/2023
                    </td>
                    <td className="text-center">
                      5
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                  </tr>
                  <tr className="">
                    <td>
                      Virat Kohli
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                    <td className="text-center">
                      30/12/2022
                    </td>
                    <td className="text-center">
                      02/05/2023
                    </td>
                    <td className="text-center">
                      5
                    </td>
                    <td className="text-right">
                      10,00,00,00,000
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </WindowWrapper>

    </>

  );
};

export default ReturnTransactionsPopup;
