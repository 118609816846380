import React, { useEffect, useState } from 'react'
import { gocallApi } from '../../../api/issueCertificateAPI'
import useIntersection from '../../../utils/useIntersection';
import { deepClone, formatNumber } from '../../../utils/UtilityFunctions';
import SkeletonNoticeOrder from '../../../components/Skeleton/SkeletonNoticeOrder';
import StackedBarChart from './StackedBarChart';
import SkeletonBarGraph from '../../../components/Skeleton/SkeletonBarGraph';
import moment from 'moment';
import AddButton from '../../../images/icons/add_btn_round.svg';
import NoDataImg from '../../../images/docu_icons.png'

//import { getFinancialMonths, getCurrentFinancialYear } from '../../../utils/UtilityFunctions';

export default function TopDeductees({ deductor_Id, financialYear, topDeducteesRef, setStartFilingPopup }) {
    const skeletonColgroup = ["100", "100", "100"];
    const skeletonMainHead = [
        { colSpan: 1, },
        { colSpan: 1, },
        { colSpan: 1, },
    ];
    const [loading, setLoading] = useState(false);
    const [topDeducteesList, setTopDeducteesList] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [orgTopDeducteeList, setOrgTopDeducteeList] = useState([]);

    const inViewport = useIntersection(topDeducteesRef, "0px");
    //const [filterFormType, setFilterFormType] = useState(new Set(['24Q', '26Q', '27Q', '27EQ']));
    const [filterFormType, setFilterFormType] = useState('24Q');
    const [monthLiabilityGraphData, setMonthLiabilityGraphData] = useState([]);
    const [monthLabel, setMonthLabel] = useState([])
    const current_year = moment().format('YYYY');

    const getLast12Month = () => {
        var monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var d = new Date();
        const monthList = [];
        d.setDate(1);
        for (let i = 0; i <= 11; i++) {
            const obj = { month: d.getMonth() + 1, label: monthName[d.getMonth()] + '-' + String(d.getFullYear()).substring(2, 4) }
            monthList.push(obj);
            d.setMonth(d.getMonth() - 1);
        }
        return monthList;
    }

    const getTopDeducteesDataList = (deductor_Id, financialYear) => {
        async function fetchEverything() {
            const url = `/api/v1/report/deductor/fy-deductee-list?deductor_id=${deductor_Id}&financial_year=${financialYear}`
            try {
                const result = await gocallApi("get", url);
                setOrgTopDeducteeList(deepClone(result?.data ?? []));
                //setTopDeducteesList(result?.data ?? []);
                resetDeducteeList('24Q', result?.data ?? []);

            } catch (err) {
                console.error('error: ', err);

            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    const getSectionWiseMonthlyLiabilityGraphData = (deductor_id, financial_year) => {
        async function getData(deductor_id, financial_year) {
            const url = `/api/v1/report/deductor/deductee-list-past12-months?deductor_id=${deductor_id}&financial_year=${financial_year}`;
            try {
                const result = await gocallApi("get", url);
                const sectionData = result?.data ?? [];
                if (sectionData.length > 0) {
                    setMonthLiabilityGraphData(setSctionWiseData(result?.data));
                }
                // else {
                //     setAnnotationData(annotationSetting);
                // }
            } catch (err) {
                console.error('error: ', err);
            } finally {
                setShowSkeleton(false);
            }
        }
        getData(deductor_id, financial_year);
    }

    const getMonthIndex = (monthNo) => {
        const last12MonthLabel = getLast12Month();
        let selectedMonthIndex = 0;
        for (let i = 0; i < last12MonthLabel.length; i++) {
            if (Number(monthNo) === Number(last12MonthLabel[i].month)) {
                selectedMonthIndex = i;
                break;
            }
        }
        return selectedMonthIndex;
    }

    const setSctionWiseData = (data) => {

        if (data.length === 0) return [];
        const list = [];
        data.forEach((item) => {
            if (!list.includes(item.section)) {
                list.push(item.section)
            }
        })

        const newList = [];
        list.forEach((item) => {
            const obj = { name: item, data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }
            data.forEach((subItem) => {
                if (item === subItem.section) {
                    if (obj.data[getMonthIndex(subItem.month)] !== undefined) {
                        obj.data[getMonthIndex(subItem.month)] = subItem.deductee_paid_amtp;
                    }
                }
            })
            newList[item] = obj
        })

        const sectionWiseData = [];
        Object.keys(newList).forEach((item) => {
            sectionWiseData.push(newList[item]);
        })

        return sectionWiseData;
    }

    const resetMonthLabels = () => {
        const last12Months = getLast12Month();
        const last12MonthLables = [];
        last12Months.forEach((item) => {
            last12MonthLables.push(item.label);
        })
        setMonthLabel(last12MonthLables);
    }

    useEffect(() => {
        if (inViewport) {
            getTopDeducteesDataList(deductor_Id, financialYear);
            getSectionWiseMonthlyLiabilityGraphData(deductor_Id, current_year);
            resetMonthLabels();
        }
        //eslint-disable-next-line
    }, [deductor_Id, financialYear, inViewport])

    const filterChange = (value) => {
        // const formTypes = new Set(filterFormType);
        // if (formTypes.has(value)) {
        //     formTypes.delete(value);
        // } else {
        //     formTypes.add(value)
        // }

        setFilterFormType((prev) => {
            prev = value
            return prev
        })

        resetDeducteeList(value);
    }

    const resetDeducteeList = (formTypes, apiData) => {
        const data = deepClone(apiData ? apiData : orgTopDeducteeList);
        const list = [];
        if (data.length > 0) {
            data.forEach((item) => {
                if (formTypes === item?.form_type) {
                    list.push(item)
                }
            })
        }
        setTopDeducteesList((prev) => {
            prev = list
            return prev
        });
    }


    const listData = (data) => {
        return (
            <>
                <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 topdeductheight">
                    <fieldset class="dash_quarterliability_fs Deductor_dashboard_fs todedcutee_borderclr_coral h-100">
                        <legend className="dash_quarterliability_lg Deductor_dashboard_leg pb-0 mb-0">
                            <div className="Deductor_dashboard_inndev">
                                <div className="Deductor_dashboard_lefts">
                                    <h4> {` Top Deductees for FY ${financialYear}-${String(financialYear + 1).substring(2, 4)} `}</h4>
                                </div>
                            </div>
                            <div className="Deductor_dashboard_inner"></div>
                            <div className="Deductor_dashboard_middleborder">
                                <span className="Deductor_dashboard_qbox" style={{ top: '1px' }}>
                                    <span style={{ cursor: 'pointer' }} className={`${filterFormType === '24Q' ? 'Quarterbox_border_coralclr' : 'Quarterbox_border_greyclr'}`} onClick={() => filterChange('24Q')}>24Q</span>
                                    <span style={{ cursor: 'pointer' }} className={`${filterFormType === '26Q' ? 'Quarterbox_border_coralclr' : 'Quarterbox_border_greyclr'} mx-1`} onClick={() => filterChange('26Q')}>26Q</span>
                                    <span style={{ cursor: 'pointer' }} className={`${filterFormType === '27Q' ? 'Quarterbox_border_coralclr' : 'Quarterbox_border_greyclr'} `} onClick={() => filterChange('27Q')}>27Q</span>
                                    <span style={{ cursor: 'pointer' }} className={`${filterFormType === '27EQ' ? 'Quarterbox_border_coralclr' : 'Quarterbox_border_greyclr'} mx-1`} onClick={() => filterChange('27EQ')}>27EQ</span>
                                </span>
                            </div>
                        </legend>
                        <div className="quarter_chartbox text-center">
                            <div className={`table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs `} style={{ maxHeight: '300px', overflow: 'auto' }} >
                                <table className="table table-striped Deductor_dashboard_outstdemand_tb dashboard_scrollbar mb-0">
                                    {data && data.length > 0 &&
                                        <thead>
                                            <tr className="Deductor_dashboard_outstdemand_toprow">
                                                <th rowspan="" className='text-center col-md-4'>Name</th>
                                                <th rowspan="" className='text-center col-md-4'>Section</th>
                                                <th rowspan="" className='text-center col-md-4'>Tax Deducted</th>
                                            </tr>
                                        </thead>
                                    }
                                    <tbody>
                                        {
                                            data && data.length > 0
                                                ?
                                                data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-left ">
                                                            {item?.deductee_name ?? "-"}
                                                        </td>
                                                        <td className="text-center">
                                                            {item?.section ?? "-"}
                                                        </td>
                                                        <td className="text-right ">
                                                            {formatNumber(item?.deductee_paid_amtp ?? 0) ?? "-"}
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                // <tr>
                                                //     <td colspan="3" className="text-center">
                                                //         <span className='link' onClick={() => setStartFilingPopup(true)}>Create/Import Return to View this &nbsp; <img src={AddButton} alt="" width={'16px'} /></span>
                                                //     </td>
                                                // </tr>
                                                <div className="container mt-2">
                                                    <div className="d-flex justify-content-center">
                                                        <div className="browserbutton_box  mt-4">
                                                            <div className="clickaablebtn_box pt-3">
                                                                <span className="clickablebtn">
                                                                    <img src={NoDataImg} alt="No data" width="100" onClick={() => setStartFilingPopup(true)} />
                                                                </span>
                                                                <p className="No_Records_to_Display_textfs mb-2 pt-3">
                                                                    No Records to Display
                                                                </p>
                                                                <p className="No_Records_to_Display_textfs" onClick={() => setStartFilingPopup(true)}>
                                                                    To Create/Import Return use <img src={AddButton} alt="" width={'16px'} style={{cursor: 'pointer'}} /> button
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </fieldset >
                </div >
            </>
        )
    }    

    return (
        <>
            {showSkeleton
                ? <SkeletonBarGraph />
                :
                <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 topdeductheight">
                    <fieldset className="dash_quarterliability_fs h-100">
                        <StackedBarChart data={monthLiabilityGraphData} monthLabel={monthLabel} setStartFilingPopup={setStartFilingPopup} />
                    </fieldset>
                </div>
            }

            {loading ?
                <SkeletonNoticeOrder title={` Top Deductees for FY ${financialYear}-${String(financialYear + 1).substring(2, 4)}`} colmd="col-md-6" classtitle="dash_quarterliability_lg" row={5} cell={3} colgroup={skeletonColgroup} mainHead={skeletonMainHead} />
                : listData(topDeducteesList)
            }

        </>

    )
}
