/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import { CENTER_GOVERNMENT_DEDUCTOR_TYPES } from '../../app/constants';

// -- Validation Rules
export const deductorFormValidationSchema = Yup.object().shape({
  tan: Yup.string()
    .required('TAN Number is required')
    .matches(/^[A-Z]{4}[0-9]{5}[A-Z]{1}$/, 'Invalid TAN Number'),

  efill_password: Yup.string().required('This field is required'),
  traces_username: Yup.string().required('This field is required'),
  traces_password: Yup.string().required('This field is required'),

  deductor_name: Yup.string()
    .trim()
    .min(1, 'Too Short!')
    .max(75, 'Too Long!')
    .required('This field is required'),

  contact_info_primary_email: Yup.string()
    .required('This field is required')
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),


    contact_info_alternate_email: Yup.string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

  deductor_category: Yup.string().required('This field is required'),

  pan: Yup.string()
    .required('This field is required')
    .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN format'),

  gst_no: Yup.string()
    .matches(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/, { message: 'Invalid GST Number', excludeEmptyString: true }),

  contact_info_primary_mobile_no: Yup.number()
    .required('This field is required.')
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

    contact_info_alternate_mobile_no: Yup.number()
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  address_building_no: Yup.string()
    .trim()
    .required('This field is required'),

  address_state_id: Yup.string()
    .trim()
    .required('This field is required'),

  address_city: Yup.string()
    .trim()
    .required('This field is required'),

  address_pincode: Yup.number()
    .required('This field is required')
    .min(110001, 'Invalid Pincode')
    .max(999999, 'Invalid Pincode'),

  branch: Yup.string().trim().required('This field is required'),

  responsible_person_contact_info_person_name: Yup.string()
    .trim()
    .required('This field is required'),

  responsible_person_pan: Yup.string()
    .required('This field is required')
    .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN format'),

  responsible_person_designation: Yup.string()
    .trim()
    .required('This field is required'),

  responsible_person_contact_info_primary_mobile_no: Yup.number()
    .required('This field is required.')
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

    responsible_person_contact_info_alternate_mobile_no: Yup.number()
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  responsible_person_contact_info_primary_email: Yup.string()
    .required('This field is required')
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

    responsible_person_contact_info_alternate_email: Yup.string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

  responsible_person_address_building_no: Yup.string()
    .trim()
    .required('This field is required'),

  responsible_person_address_city: Yup.string()
    .trim()
    .required('This field is required'),

  responsible_person_address_state_id: Yup.number()
    .required('This field is required'),

  responsible_person_address_pincode: Yup.number()
    .required('This field is required')
    .min(110001, 'Invalid Pincode')
    .max(999999, 'Invalid Pincode'),

  contact_person_contact_info_person_name: Yup.string()
    .when('is_cp_same_as_rp', {
      is: 'no', // alternatively: (val) => val == true
      then: Yup.string().trim().required('This field is required'),
    }),

  contact_person_contact_info_primary_mobile_no: Yup.number()
    .when('is_cp_same_as_rp', {
      is: 'no', // alternatively: (val) => val == true
      then: Yup.number()
        .required('This field is required')
        .min(6000000000, 'Invalid Mobile Number')
        .max(9999999999, 'Invalid Mobile Number'),
    }),

  contact_person_contact_info_primary_email: Yup.string()
    .when('is_cp_same_as_rp', {
      is: 'no', // alternatively: (val) => val == true
      then: Yup.string().required('This field is required')
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),
    }),

  government_deductor_pao_code: Yup.string()
    .when('deductor_category', {
      is: 'A',
      then: Yup.string().trim().required('This field is required').max(20)
        .matches(/[A-Za-z0-9]+$/, 'Only alpha-numeric values allowed'),
    }),

  /*government_deductor_pao_number: Yup.number()
    .when('deductor_category', {
      is: 'A',
      then: Yup.number().required('PAO Number is required'),
    }),*/

  government_deductor_ddo_code: Yup.string()
    .when('deductor_category', {
      is: 'A',
      then: Yup.string().trim().required('This field is required'),
    }),

  /*government_deductor_ddo_number: Yup.string()
    .when('deductor_category', {
      is: 'A',
      then: Yup.string().trim().required('DDO Number is required'),
    }),*/

  government_deductor_dept_name: Yup.string()
    .when('deductor_category', {
      is: (val) => CENTER_GOVERNMENT_DEDUCTOR_TYPES.includes(val),
      then: Yup.string().trim().required('This field is required'),
    }),

  government_deductor_office_identity_number: Yup.string()
    .when('deductor_category', {
      is: (val) => ['A', 'S'].includes(val),
      then: Yup.string().trim().required('This field is required'),
    }),

  government_deductor_dept_name_other: Yup.string()
    .when('government_deductor_dept_name', {
      is: '99',
      then: Yup.string().trim().required('This field is required'),
    }),
});
