import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import queryString from 'query-string';
import { parseDate } from '@telerik/kendo-intl';
import { useSelector } from "react-redux";
import { selectHeaderData } from "../../header/headerSlice";
import { gocallApi } from "../../../api/issueCertificateAPI";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CustomCell } from '../../../components/grid/CustomCell';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import moment from 'moment';
import Skeleton6ColumnGrid from '../../../components/Skeleton/Skeleton6ColumnGrid';
import LoadingIndicator from "../../../components/loadingIndicator/LoadingIndicator";
import ShowAlert from "../../../components/ShowAlert";
import { BASE_PATH } from "../../../app/constants";
import SemanticPagination from "../../../components/pagination/SemanticPagination";
import DownloadFile from "../../../components/DownloadFile";
import { downloadSalaryTdsCalculatorData } from "../../../api/salaryAPI";
import Spinner from 'react-bootstrap/Spinner';
import "../../deductorList/NoticeOrder.scss"
import './SalaryDetails.scss';
import dwnload4 from "../../../images/icons/export_icon.png";
import emailIcon from '../../../images/emailcoral.png';


const LIMIT = 17;

const EmployeesWiseMonthlyTds = () => {

    const history = useHistory();
    const location = useLocation();
    const headerData = useSelector(selectHeaderData);
    const params = queryString.parse(location.search);
    const deductorId = params?.deductor_id ?? '';
    const financial_year = params?.financial_year ?? '';
    const [dataState, setDataState] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cursor, setCursor] = useState({ current_page: 1, next_page: null });
    const [salaryData, setSalaryData] = useState([]);
    const [summary, setSummary] = useState();
    const [summaryStatus, setSummaryStatus] = useState(true)
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [downloadExcelFile, setDownloadExcelFile] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const createHistoryUrl = (filters) => {

        let filterStr = `?deductor_id=${deductorId}&financial_year=${financial_year}&page=1`

        if (filters.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
        }
        if (filters.sort && filters.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
        }


        history.push(filterStr);
    };

    const getParamsHistoryUrl = () => {

        if (params?.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
        }
        if (params?.filters?.length > 0) {
            dataState.filter = { filters: [] };
            let frs = JSON.parse(atob(params.filters));
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'issued_at') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                }
            }
            dataState.filter.filters = frs;
        } else {
            dataState.filter = { filters: [] };
        }

        setDataState({ ...dataState });
    };

    const getSalaryDataSummary = async () => {

        let filters = null;
        if (params?.filters?.length > 0) {
            filters = JSON.parse(atob(params.filters));
        }
        let payload = {
            deductor_id: deductorId,
            financial_year: financial_year,
            filters: params.filters ? filters : null
        }
        let url = `/api/v1/salary-tds-calculator/summary`;

        try {
            const result = await gocallApi("post", url, payload)
            setSummary(result?.data?.summary ?? 0);
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            //do nothing
        }
    }

    const dataStateChange = (data_state) => {
        createHistoryUrl(data_state);
        setDataState({ ...data_state });

        //Get Summary of Notice Order
        if (data_state?.filter?.filters?.length > 0 && data_state?.sort?.length > 0) {
            setSummaryStatus(true)
        } else if (data_state?.filter?.filters?.length === 0 && data_state?.sort?.length > 0) {
            setSummaryStatus(false)
        } else if (data_state?.filter?.filters?.length > 0 && data_state?.sort?.length === 0) {
            setSummaryStatus(true)
        } else {
            setSummaryStatus(true) //default true
        }
    };

    const rowRender = (trElement, props) => {
        const data = props?.dataItem ?? {}
        const rowCounter = trElement._owner.index;

        return (
            <tr data-index={rowCounter} role="row">
                <td className={'text-left Return_register_overflowtext'} title={data?.deductee_name ?? ''}>{data?.deductee_name ?? '-'}</td>
                <td className={'text-center'} title={data?.pan ?? ''}>{data?.pan ?? '-'}</td>
                <td className={`text-right`} title={formatNumber(data?.salary_amt ?? 0)}>{formatNumber(data?.salary_amt ?? 0)}</td>
                <td className={'text-center'}>{data?.tax_regime ?? '-'}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_apr_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_may_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_jun_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_jul_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_aug_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_sep_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_oct_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_nov_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_dec_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_jan_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_feb_amt ?? 0)}</td>
                <td className={`text-right`}>{formatNumber(data?.tds_mar_amt ?? 0)}</td>
            </tr>
        )
    };

    const getSalaryData = async (isloading) => {
        if (!isloading) setLoading(true);
        let filters = null;
        if (params?.filters?.length > 0) {
            filters = JSON.parse(atob(params.filters));
        }
        let payload = {
            deductor_id: deductorId,
            financial_year: financial_year,
            filters: params.filters ? filters : null,
            sort_on: params.sort_on ? params.sort_on : '',
            sort_by: params.order_by ?? "",
            page: params.page ? parseInt(params.page) : 1,
            limit: LIMIT
        }
        let url = `/api/v1/salary-tds-calculator/monthwise-tds-list`

        try {
            const result = await gocallApi("post", url, payload)
            if (result?.data?.data?.length > 0) {
                result?.data?.data.forEach((item) => {
                    if (item.issued_at) item.issued_at = moment(item.issued_at).format('DD/MM/YYYY')
                })
            }
            if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page ?? null;
            setSalaryData(result?.data?.data ?? []);
            setCursor(result?.data?.cursor ?? { current_page: 1, next_page: null })

        } catch (err) {

            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            if (!isloading) setLoading(false);
            setShowSkeleton(false);
        }
    }

    useEffect(() => {
        getParamsHistoryUrl();
        getSalaryData();
        if (summaryStatus) {
            getSalaryDataSummary(); //Get Summary of Notice Order
        }

        // eslint-disable-next-line
    }, [location.search]);

    useEffect(() => {
        if (headerData?.organization_type !== 'CORPORATE') {
            history.push(BASE_PATH);
        }
        // eslint-disable-next-line
    }, [headerData])


    return (
        <>
            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {loading && <LoadingIndicator />}

            {downloadExcelFile &&
                <DownloadFile
                    apiFunction={downloadSalaryTdsCalculatorData}
                    setDownloadLoading={setDownloadLoading}
                    setError={setError}
                    fileName={'ExpressTDS-Salary_TDSCalculator'}
                    setDownloadExcelFile={setDownloadExcelFile}
                    sessionStorageKey={`export-salary-tds-calculator-ref-key-${deductorId}-${financial_year}`}
                    params={`?deductor_id=${deductorId}&financial_year=${financial_year}`}
                    handleSuccessProceed={() => {
                        setDownloadExcelFile(false);
                    }}
                    handleErrorProceed={(msg) => {
                        setDownloadExcelFile(false);
                    }}
                />
            }

            <div className='noticeOrderslistparentclass'>
                <div class="container" style={{ maxWidth: '1600px' }}>

                    <div class="row mt-2">
                        <div class="col-md-4">
                            <Link class="notice_order_backbtn" to={`${BASE_PATH}imported-salary-details?deductor_id=${deductorId}&financial_year=${financial_year}`}>&lt; Back to Employee-Wise Salary Details</Link>
                        </div>
                        <div class="col-md-4">
                            <h4 class="notice_order_titlfs">Employee-Wise Monthly TDS Details</h4>
                        </div>
                        <div class="col-md-4 text-right">
                            <span className='mr-2 ' title='Download Computation' onClick={() => setDownloadExcelFile(true)} style={{ cursor: 'pointer' }}>
                                {
                                    downloadLoading
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}</>
                                        : <img src={dwnload4} alt="excel download icon " width="22" />
                                }
                            </span>
                            {'   '}
                            <Link to={`${BASE_PATH}email-salary-details?deductor_id=${deductorId}&financial_year=${financial_year}`} className="link">
                                <img src={emailIcon} width={25} title="Send Email" alt="" />
                            </Link>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-12">
                            <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                {
                                    showSkeleton ? <Skeleton6ColumnGrid />
                                        :
                                        <>
                                            <Tooltip anchorElement="pointer" position="right">
                                                <Grid
                                                    className="table table-striped notices_orders_tb listofdedtb scrollvisible"
                                                    data={salaryData}
                                                    {...dataState}
                                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                    filterOperators={{
                                                        text: [
                                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                        ],
                                                        dropdown: [
                                                            { text: 'grid.filterEqOperator', operator: 'in' },
                                                        ],
                                                        numeric: [
                                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                        ],
                                                        textWithEmpty: [
                                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                        ],
                                                        date: [
                                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                                        ],
                                                    }}
                                                    rowRender={rowRender}
                                                    sortable
                                                >
                                                    <Column
                                                        width={100}
                                                        field="deductee_name"
                                                        headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                                        title={<CustomCell title={'Name'} field="deductee_name" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="textWithEmpty"
                                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                    />
                                                    <Column
                                                        width={100}
                                                        field="pan"
                                                        headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                        title={<CustomCell title={'PAN'} field="pan" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="textWithEmpty"
                                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="salary_amt"
                                                        headerClassName={isColumnActive('salary_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Salary'} field="salary_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tax_regime"
                                                        headerClassName={isColumnActive('tax_regime') ? 'active' : ''}
                                                        title={<CustomCell title={'Regime'} field="tax_regime" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="dropdown"
                                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={[{ label: "OLD", value: "old" }, { label: "NEW", value: "new" },]} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_apr_amt"
                                                        headerClassName={isColumnActive('tds_apr_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'April'} field="tds_apr_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_may_amt"
                                                        headerClassName={isColumnActive('tds_may_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'May'} field="tds_may_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_jun_amt"
                                                        headerClassName={isColumnActive('tds_jun_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'June'} field="tds_jun_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_jul_amt"
                                                        headerClassName={isColumnActive('tds_jul_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'July'} field="tds_jul_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_aug_amt"
                                                        headerClassName={isColumnActive('tds_aug_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Aug'} field="tds_aug_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_sep_amt"
                                                        headerClassName={isColumnActive('tds_sep_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Sept'} field="tds_sep_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_oct_amt"
                                                        headerClassName={isColumnActive('tds_oct_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Oct'} field="tds_oct_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_nov_amt"
                                                        headerClassName={isColumnActive('tds_nov_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Nov'} field="tds_nov_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_dec_amt"
                                                        headerClassName={isColumnActive('tds_dec_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Dec'} field="tds_dec_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_jan_amt"
                                                        headerClassName={isColumnActive('tds_jan_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Jan'} field="tds_jan_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_feb_amt"
                                                        headerClassName={isColumnActive('tds_feb_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Feb'} field="tds_feb_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                    <Column
                                                        width={80}
                                                        field="tds_mar_amt"
                                                        headerClassName={isColumnActive('tds_mar_amt') ? 'active' : ''}
                                                        title={<CustomCell title={'Mar'} field="tds_mar_amt" dataStateChange={dataStateChange} dataState={dataState} />}
                                                        filter="numeric"
                                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                                    />
                                                </Grid>
                                            </Tooltip>
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                    {Number(summary?.total_cnt) > 0 &&
                        <SemanticPagination cursor={cursor} count="total_cnt" pagelimit={LIMIT} summary={summary} setSummaryStatus={setSummaryStatus} />
                    }
                </div>
            </div>

        </>
    )
}

export default EmployeesWiseMonthlyTds