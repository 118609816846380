// import moment from 'moment';

export const raiseRequestPopupFields = {
  request_type: [],
  ft: '',
  fy: '',
  quarter: '',
  raiseRequestFormType: 'traces_form',
  token: '',
  bsr_code: '',
  deposit_date: '',
  challan_no: '',
  challan_amount: 0,
  bsr_code2: '',
  deposit_date2: '',
  government_deductor_ddo_number: '',
  transfer_voucher_amount: 0,
  nil_chalan: '1',
  pan_no: ['', '', ''],
  tax_deducted: ['', '', ''],
  nil_transaction: '1',
  form1input: '0',
  form2input: '0',
  form3input: '1',
  form4input: '0',
  form5input: '1',
  captcha: '',
  captcha_ref: '',
};


export const resetForm = (data) => {
  data.request_type = [];
  data.nil_chalan = 1;
  data.nil_transaction = 1;
  data.bsr_code = '';
  data.bsr_code2 = '';
  data.deposit_date = '';
  data.deposit_date2 = '';
  data.challan_no = '';
  data.challan_amount = '';
  data.government_deductor_ddo_number = '';
  data.transfer_voucher_amount = '';
  data.pan_no = ['', '', ''];
  data.tax_deducted = ['', '', ''];
  data.form1input = '0';
  data.form2input = '0';
  data.form3input = '1';
  data.form4input = '0';
  data.form5input = '1';
  return data;
}


export const raiseRequestResetFields = {
  deductor_id:'',
  financial_year:'',
  quarter: '',
  form_type: '',
  request_for: [],
  token: '',
  challan_details:null,
  book_entry_details:null,
  nil_challan:null,
  captcha_ref:null,
  transaction_details:null,
  nil_transaction:null,
};
