import React, { useEffect, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import dwnload4 from "../../images/icons/export_icon.png";
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import delIcon from "../../images/del-icon.png"
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { gocallApi } from '../../api/issueCertificateAPI';
import { getTracesCaptchaFromConnector } from '../../api/localConnectorAPI';
import { addDeductorFromPortal, downloadExcelDeductorlist, verifyItCrendital } from '../../api/deductorAPI';
import { BASE_PATH, CONNECTOR_ERRORS_MAPPING, GLOBAL_DV_CODE, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import "../deductorList/DeductorIncompleteList.scss";
import infoiconalertbox from '../../images/icons/info.svg';
import BlurOnChangeInput from '../../components/BlurOnChangeInput';
import DeductorSyncClient from '../createDeductor/DeductorSyncClient';
import { useSelector } from 'react-redux';
import returnRegIcon from "../../images/return-register.png";
import { selectHeaderData } from '../header/headerSlice';
import clickhandexpire from '../../images/icons/clickhand_expire.gif';
import { getCurrentFinancialYearByQuarter } from '../../utils/UtilityFunctions';
import SemanticPagination from '../../components/pagination/SemanticPagination';
import letsGetStartIcon from '../../images/lets_get_started.gif';
import ExpressRecoadCloud from '../../images/Express_Reco_ad_cloud.png'

const DeductorIncompleteList = () => {
    const location = useLocation();
    const searchStr = location.search;
    const current_year = getCurrentFinancialYearByQuarter();
    const headerData = useSelector(selectHeaderData);
    const history = useHistory();
    const params = queryString.parse(location.search);
    const [isRunsync, setIsRunsyns] = useState(false);
    const [port, setPort] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [dataState, setDataState] = useState({});
    const [data, setData] = useState(null);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null })
    const [isCaptchaCall, setIsCaptchaCall] = useState(false);
    const [userIndex, setUserIndex] = useState("");
    const [deducteeSummary, setDeducteeSummary] = useState({});
    const [syncValidDeductor, setSyncValidDeductor] = useState(null);

    const handleChange = (value, index, name) => {
        data[index][name] = value;
        if (data?.[index]?.errObj?.[name]) data[index].errObj[name] = "";

        setData([...data]);
    };

    const validateCredential = (validateData, index) => {
        let validation = true;
        const tanPattern = new RegExp('[A-Z]{4}[0-9]{5}[A-Z]{1}');

        let errorObj = {}

        // if(!validateData?.deductor_name?.trim()) errorObj.deductor_name = "This field is required"
        if (!validateData?.tan?.trim()) errorObj.tan = "This field is required";
        if (validateData?.tan?.trim() && !tanPattern.test(validateData?.tan?.trim())) errorObj.tan = "Invalid TAN Number";
        if (!validateData?.itdportal_password?.trim()) errorObj.itdportal_password = "This field is required";

        if (validateData?.traces_username?.trim() && validateData?.traces_password?.trim() && !validateData?.captcha_text?.trim()) errorObj.captcha_text = "Captcha is required";

        if (Object.keys(errorObj)?.length > 0) {
            validation = false;
            data[index].errObj = errorObj;
            setData([...data]);
        }
        return validation;
    }

    const saveCredentialOnserver = (data, index) => {
        const validation = validateCredential(data, index);
        if (validation) {
            setShowConnnectorCall(true);
        }

    }

    const getParamsHistoryUrl = () => {

        if (params?.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
        }
        if (params?.filters?.length > 0) {
            dataState.filter = { filters: [] };
            dataState.filter.filters = JSON.parse(atob(params.filters));
        } else {
            dataState.filter = { filters: [] };
        }

        setDataState({ ...dataState });
    };

    const getIncompletDeductorList = async (parameter) => {
        setLoading(true);
        let filters = null;
        if (parameter?.filters?.length > 0) {
            filters = JSON.parse(atob(parameter.filters));
        }
        const url = "/api/v1/tentative-deductor/list-all";
        const payload = {
            filters: parameter.filters ? filters : null,
            sort_on: parameter.sort_on ? parameter.sort_on : '',
            sort_by: parameter.order_by ?? "",
            page: parseInt(parameter.page),
            limit: 12
        }
        try {
            const result = await gocallApi("post", url, payload);
            setData(result?.data?.data ?? []);
            if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result.data.cursor.next_page;
            setCursor(result?.data?.cursor ?? { current_page: 1, next_page_url: null });
            setDeducteeSummary(result?.data?.summary ?? {});

        } catch (err) {
            if (err?.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false);
        }
    }

    const getCaptcha = async (index) => {
        setLoading(true);

        async function fetchEverything() {
            try {
                const result = await getTracesCaptchaFromConnector(port);
                data[index]["captcha"] = result.data ?? {};
                data[index]["captcha_text"] = "";
                setData([...data]);
            } catch (err) {
                setError("Not able to fetch captcha, please try again after sometime. If the problem persists, then try with different internet");
                // if (err.message) setError(err.message);
                // else setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };

    const deleteRowOnserver = async (index, id) => {
        setLoading(true);

        async function fetchEverything() {
            const url = `/api/v1/tentative-deductor`;
            const payload = {
                tentative_deductor_id: id
            };
            try {
                const result = await gocallApi("delete", url, payload);
                setSuccess(result?.data?.message ?? "");
                data.splice(index, 1);
                deducteeSummary.tentative_count -= 1;
                setDeducteeSummary({ ...deducteeSummary });
                setData([...data]);
                if (data.length === 0) {
                    const param = queryString.parse(searchStr)
                    param.page = cursor?.previous_page ?? 1;

                    getIncompletDeductorList(param)

                }

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);

            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };

    const rowValidation = (rowData, index) => {
        let errObj = {};
        let validation = true;
        const tanPattern = new RegExp('[A-Z]{4}[0-9]{5}[A-Z]{1}');
        // if(!rowData?.deductor_name?.trim()) errObj.deductor_name = "This field is required"
        if (!rowData?.tan?.trim()) errObj.tan = "This field is required";
        if (rowData?.tan?.trim() && !tanPattern.test(rowData?.tan?.trim())) errObj.tan = "Invalid TAN Number";
        if (!rowData?.itdportal_password?.trim()) errObj.itdportal_password = "This field is required";

        if (Object.keys(errObj).length > 0) {
            validation = false;
            setData((prev) => {
                prev[index].errObj = errObj;
                return [...prev];
            })
        }
        return validation;
    }

    const updateRowData = (rowData, index, prevTan) => {
        const validation = rowValidation(rowData, index)

        async function fetchEverything() {
            const payload = {
                deductor_name: rowData.deductor_name ?? "",
                itdportal_password: rowData?.itdportal_password ?? "",
                tan: rowData?.tan ?? "",
                tentative_deductor_id: rowData?.tentative_deductor_id ?? "",
                traces_password: rowData?.traces_password ?? "",
                traces_username: rowData?.traces_username ?? "",
            }
            try {
                await gocallApi("put", "/api/v1/tentative-deductor", payload);

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
                rowData.tan = prevTan
            }
        }
        if (validation) {
            fetchEverything();
        }
    }

    const sendPortalFormRequest = async (datavalue, extraHeader) => {
        const payload = {
            data: datavalue,
        };
        try {
            const result = await addDeductorFromPortal(payload, extraHeader);
            setSuccess(result.message);
            if (result.data) {
                const obj = {
                    itdportal_username: userInfo.tan,
                    itdportal_password: userInfo.itdportal_password,
                    deductor_id: result?.data?.deductor_id
                }
                setTimeout(() => { setSyncValidDeductor(obj) }, 2000)
                // setTimeout(() => { setSyncValidDeductor(null) } ,4000)
            }
            if (data[userInfo.dataIndex]) {
                data.splice(userInfo.dataIndex, 1)
                setData([...data]);
                setUserInfo({});
            }
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false);
        }
    }

    const verfiyCredApi = () => {
        setError(null);
        setLoading(true);
        const request = {
            deductor_name: userInfo.deductor_name ?? "",
            itdportal_username: userInfo.tan ?? "",
            itdportal_password: userInfo.itdportal_password ?? "",
        };

        if (userInfo.traces_username && userInfo.traces_password) {
            request.traces_username = userInfo.traces_username ?? "";
            request.traces_password = userInfo.traces_password ?? "";
            request.captcha_text = userInfo.captcha_text ?? "";
            request.captcha_ref = userInfo?.captcha?.captcha_ref ?? "";
        }

        async function fetchEverything() {
            try {
                const result = await verifyItCrendital(port, request);
                if (result.data.status === 1) {
                    const responseHeader = result.headers ?? null;
                    let extraHeader = {};
                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                    sendPortalFormRequest(result.data.data, extraHeader)
                }
            } catch (err) {
                console.error('error: ', err);
                setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
                setLoading(false);
                getCaptcha(userInfo.dataIndex ?? "")
            } finally {
                setCheckItCredentials(false);
            }
        }
        fetchEverything();
    };

    const createHistoryUrl = (filters) => {

        let filterStr = `?page=1`
        if (filters.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
        }



        if (filters.sort && filters.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
        }


        history.push(filterStr);
    };

    const dataStateChange = (data_state) => {
        createHistoryUrl(data_state);
        setDataState(data_state);
    }

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };


    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem;
        const index = props.dataIndex;

        return (
            <tr>
                <td className=''>
                    <BlurOnChangeInput type='text'
                        className={`form-control incompded_inputheight ${dataItem?.errObj?.deductor_name ? "input-error" : ""}`}
                        value={dataItem.deductor_name ?? ""}
                        data-toggle="tooltip"
                        data-placement="right" data-original-title={dataItem.deductor_name ? dataItem.deductor_name : ""}
                        onChange={(e) => handleChange(e.target.value, index, "deductor_name", dataItem?.tentative_deductor_id ?? "")}
                        onBlur={() => { updateRowData(dataItem, index) }}
                        maxLength={75}
                        placeholder="Enter Deductor Name"
                    />
                </td>

                <td className="tetxt-center">
                    <BlurOnChangeInput type='text'
                        className={`form-control incompded_inputheight ${dataItem?.errObj?.tan ? "input-error" : ""}`}
                        value={dataItem.tan ?? ""}
                        data-toggle="tooltip"
                        data-placement="right" data-original-title={dataItem?.errObj?.tan ?? ""}
                        onChange={(e) => {
                            e.target['BeforeCursorFocus'] = e.target.selectionStart;
                            handleChange(e.target?.value?.toUpperCase(), index, "tan", dataItem?.tentative_deductor_id ?? "");
                            cursorfocusFunc(e);
                        }}
                        onBlur={(e) => { updateRowData(dataItem, index, e.target?.['valueBeforeFocus']); }}
                        maxLength={10}
                        placeholder="Enter TAN"
                    />

                </td>

                <td className="text-center form-groupposition">
                    {dataItem?.[`itd${dataItem.tentative_deductor_id}`] ?
                        <div className="input-group">
                            <BlurOnChangeInput type="text"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.itdportal_password ? "input-error" : ""}`}
                                placeholder="Enter Password"
                                value={dataItem.itdportal_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.itdportal_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "itdportal_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`itd${dataItem.tentative_deductor_id}`] = false;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEyeSlash} />
                            </div>

                        </div> :
                        <div className="input-group">
                            <BlurOnChangeInput type="password"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.itdportal_password ? "input-error" : ""}`}
                                placeholder="Enter Password"
                                value={dataItem.itdportal_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.itdportal_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "itdportal_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`itd${dataItem.tentative_deductor_id}`] = true;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </div>

                        </div>}
                </td>

                <td className="text-left">
                    <BlurOnChangeInput type='text'
                        className={`form-control incompded_inputheight ${dataItem?.errObj?.traces_username ? "input-error" : ""}`}
                        value={dataItem.traces_username ?? ""}
                        maxLength={11}
                        data-toggle="tooltip"
                        data-placement="right" data-original-title={dataItem?.errObj?.traces_username ?? ""}
                        onChange={(e) => handleChange(e.target.value, index, "traces_username", dataItem?.tentative_deductor_id ?? "")}
                        onBlur={() => { updateRowData(dataItem, index) }}
                        placeholder="Enter Username"
                    />

                </td>

                <td className="text-center form-groupposition">
                    {dataItem?.[`traces${dataItem.tentative_deductor_id}`] ?
                        <div className="input-group">
                            <BlurOnChangeInput type="text"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.traces_password ? "input-error" : ""}`}
                                placeholder="Enter Password"
                                value={dataItem.traces_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.traces_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "traces_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}


                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`traces${dataItem.tentative_deductor_id}`] = false;
                                        return [...prev]
                                    })
                                }}
                                style={{ cursor: "pointer" }}>
                                <FontAwesomeIcon icon={faEyeSlash} />
                            </div>

                        </div> :
                        <div className="input-group">
                            <BlurOnChangeInput type="password"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.traces_password ? "input-error" : ""}`}
                                placeholder="Enter Password"
                                value={dataItem.traces_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.traces_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "traces_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`traces${dataItem.tentative_deductor_id}`] = true;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </div>

                        </div>}
                </td>


                <td className="text-center">
                    <button className="btn btn-default blukimp_captchbtn " onClick={() => {
                        if (dataItem.traces_password && dataItem.traces_username) {
                            setIsCaptchaCall(true);
                            setUserIndex(index);
                        } else {
                            // setError("Enter Valid Traces credentials to get captcha");
                            validateCaptcha(dataItem, index);
                        }
                    }}>
                        Get Captcha
                    </button>

                </td>
                <td className="text-center">
                    <img
                        src={`data:image/png;base64, ${dataItem?.captcha?.captcha_image ?? ""}`}
                        width="100" alt="" />
                </td>

                <td className="text-center">
                    <input type="text"
                        className={`form-control incompded_inputheight mypc_credinputtextfs ${dataItem?.errObj?.captcha_text ? "input-error" : ""}`}
                        placeholder="Captcha"
                        value={dataItem.captcha_text ?? ""}
                        data-toggle="tooltip"
                        data-placement="right" data-original-title={dataItem?.errObj?.captcha_text ?? ""}
                        onChange={(e) => handleChange(e.target.value.trim(), index, "captcha_text", dataItem?.tentative_deductor_id ?? "")}
                    />


                </td>
                <td className="text-center  ">
                    <button className="btn btn-default blukimp_captchbtn create_deductor"
                        onClick={() => {
                            dataItem.dataIndex = index
                            setUserInfo(dataItem);
                            saveCredentialOnserver(dataItem, index);
                        }} >
                        Create Deductor
                    </button>
                </td>
                <td className="text-center p-0">
                    <img src={delIcon} alt='delete icon' onClick={() => {
                        deleteRowOnserver(index, dataItem?.tentative_deductor_id ?? "");
                    }} width="16" style={{ cursor: "pointer" }} />
                </td>
            </tr>)
    }

    const cursorfocusFunc = (e) => {
        if (e) {
            let start = e.target.selectionStart;
            start = (e.target?.["BeforeCursorFocus"]);
            if (start < 0) start = 0;
            setTimeout(() => {
                e.target.setSelectionRange(start, start);
            }, 0);
        }
    }

    const validateCaptcha = (dataItem, index) => {
        if (!dataItem.errObj) dataItem.errObj = {};
        if (!dataItem.traces_password) dataItem.errObj.traces_password = "Traces Password is required"
        if (!dataItem.traces_username) dataItem.errObj.traces_username = "Traces UserName is required"
        data[index] = dataItem;
        setData([...data]);
    }

    useEffect(() => {
        if (port && checkItCredentials) {
            verfiyCredApi()
        }
        //eslint-disable-next-line
    }, [port, checkItCredentials])

    useEffect(() => {
        const param = queryString.parse(searchStr)
        getIncompletDeductorList(param)
    }, [searchStr])
    useEffect(() => {
        getParamsHistoryUrl()
        // eslint-disable-next-line
    }, [location.search]);

    $(function () {
        $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    })

    const renderMandatoryTitle = (title) => {
        return (
            < >
                <span>{title}</span>{<span style={{ color: 'red' }}>*</span>}
            </>
        );
    }

    const excelDeductorList = async () => {
        setLoading(true);
        try {
            await downloadExcelDeductorlist()
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {loading && <LoadingIndicator />}

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => {
                        setShowConnnectorCall(false);
                    }}
                    setPort={setPort}
                    handleProceed={() => {
                        setCheckItCredentials(true);
                    }}
                />
                : null
            }

            {!isRunsync ?
                <ConnectorAdapter
                    show={true}
                    handleClose={() => { setIsRunsyns(true); }}
                    closeicon={"clsnone"}
                    setPort={setPort}
                    handleProceed={() => {
                        setIsRunsyns(true);
                    }}
                /> : null
            }

            {isCaptchaCall && <ConnectorAdapter
                show={true}
                handleClose={() => {
                    setIsCaptchaCall(false);
                }}
                setPort={setPort}
                handleProceed={() => {
                    getCaptcha(userIndex);
                }}
            />}

            {syncValidDeductor &&
                <DeductorSyncClient
                    syncDeductor={syncValidDeductor}
                />
            }
            <div className="container mt-0 deductormasterlist incomplete_deductorlis_css">
                {/* <div className="row">
                <div className="col-sm-12 text-center">
                    
                </div>
            </div> */}
                <div className="row pt-2">
                    <div className="col-6 col-xl-4 col-lg-4 col-md-1 col-sm-12"></div>
                    <div className="col-6 col-xl-3 col-lg-3 col-md-5 col-sm-12 text-center pr-0" >
                        <h4 className="createdeductor_titlefs ">
                            Deductor Master
                            <span className='ml-1 ' title='Download Deductor Master' onClick={(e) => {
                                excelDeductorList();
                            }}>
                                <img src={dwnload4} alt="excel download icon " width="22" />
                            </span>
                        </h4>
                    </div>

                    <div className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-12 text-right pr-0" style={{ paddingTop: "9px" }}>
                        <Link className="btn btn-default tds_creatdeduct_pendbtnbgclr mr-2 planexpiredalert" to={`${BASE_PATH}notice-orders`}>Notice & Orders</Link>
                        <Link className="btn btn-default tds_creatdeduct_pendbtnbgclr mr-2 planexpiredalert" to={`${BASE_PATH}pending-requests`}>Check Status of Raised Requests</Link>


                    </div>

                </div>

                <div className="row borderdeductor mt-2">
                    <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-12 pl-0">
                        <nav className="tds_listof_deduct_nav">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">

                                <Link className="nav-item  tds_listof_deduct_tab  tds_listof_deduct_tabretborderrightnon searchbox_show_less" id="nav-home-tab" to={`${BASE_PATH}list/recent`}>
                                    Recent <span className="tds_listof_deduct_count_text">({`${deducteeSummary?.recent_count ?? 0}`})</span>
                                </Link>
                                <Link className="nav-item tds_listof_deduct_tab searchbox_show_details" id="nav-home-tab" to={`${BASE_PATH}list/all`}>
                                    All <span className="tds_listof_deduct_count_text">({`${deducteeSummary?.all_count ?? 0}`})</span>
                                </Link>
                                <Link className="nav-item tds_listof_deduct_tab searchbox_show_details active" id="nav-home-tab" to={`${BASE_PATH}incomplete-deductor`}>
                                    Incomplete Deductors <span className="tds_listof_deduct_count_text">({`${deducteeSummary?.tentative_count ?? 0}`})</span>
                                </Link>
                            </div>
                        </nav>
                    </div>

                    <div className="col-12 col-xl-6 col-lg-6 col-md-4 col-sm-12 text-right pr-0">

                        <Link className="btn btn-default tds_creatdeduct_pendingbtnbgclr mx-2 planexpiredalert" to={`${BASE_PATH}return-register/return-wise?financial_year=${current_year}`}>
                            <img width="14" alt="Return Register" src={returnRegIcon} style={{ paddingBottom: "2px", paddingRight: "2px" }} />
                            Return Register
                        </Link>

                    </div>
                    {
                        (
                            (dataState?.filter && dataState?.filter?.filters && dataState?.filter?.filters.length === 0)
                            &&
                            (data && deducteeSummary?.all_count === 0 && data.length === 0)
                        )
                            ?
                            <>
                                <div className="col-md-12 px-0 text-center">
                                    <Link title={`Create New Client`} className="" to={`${BASE_PATH}create-deductor`}>
                                        <img src={letsGetStartIcon} alt='' width="960" height="432" />
                                    </Link>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-md-12">
                                    <p className="blukimp_suntitlefs  mb-1">
                                        The Deductor(s) are present in the software, but they can't be fully created because of missing info., such as TAN/Username/Password, which you can enter now to create Deductor. You can opt to skip now and do it later anytime.
                                    </p>
                                </div>
                                <div className="col-md-12 px-0">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade active show" id="tds_listof_deduct_tab01" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                        <Grid
                                                            className="table table-striped blukimport_tb mb-0 scrollvisible incvisibleover"
                                                            data={data}
                                                            {...dataState}
                                                            sortable
                                                            onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                            filterOperators={{
                                                                text: [
                                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                ],
                                                                dropdown: [
                                                                    { text: 'grid.filterEqOperator', operator: 'in' },
                                                                ],
                                                                numeric: [
                                                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                                ],
                                                                textWithEmpty: [
                                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                                ],
                                                            }}
                                                            rowRender={rowRender}
                                                        >
                                                            <Column
                                                                width="230"
                                                                field="deductor_name"
                                                                title={"Deductor Name"}
                                                                filter="text"
                                                                columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                                                headerClassName={isColumnActive('deductor_name') ? 'active' : ''}
                                                            />

                                                            <Column
                                                                width="105"
                                                                field="tan"
                                                                title={renderMandatoryTitle("TAN")}
                                                                filter="text"
                                                                columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                                                headerClassName={isColumnActive('tan') ? 'active' : ''}
                                                            />

                                                            <Column
                                                                width="135"
                                                                title={renderMandatoryTitle("ITD  Password")}
                                                                sortable={false}

                                                            />

                                                            <Column
                                                                width="105"
                                                                field="traces_username"
                                                                title={<span title='Traces Username'>{"Username"}</span>}
                                                                filter="text"
                                                                columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                                                headerClassName={isColumnActive('traces_username') ? 'active' : ''}
                                                            />


                                                            <Column
                                                                width="137"
                                                                title={<span title='Traces Password'>{"Password"}</span>}
                                                                sortable={false}

                                                            />

                                                            <Column
                                                                width="108"
                                                                sortable={false}
                                                            />

                                                            <Column
                                                                width="80"
                                                                sortable={false}
                                                            />
                                                            <Column
                                                                width="60"
                                                                sortable={false}
                                                            />
                                                            <Column
                                                                width="130"
                                                                sortable={false}
                                                            />
                                                            <Column
                                                                width="20"
                                                                sortable={false}
                                                            />
                                                        </Grid>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }


                </div>
                <SemanticPagination cursor={cursor} count="tentative_count" pagelimit={12} summary={deducteeSummary} />
            </div>
            {(
                headerData.plan_valid === false
                || ((headerData.plan_valid_until ?? '') !== '')
            ) &&

                < div className="deductormasterlist container-fluid mt-2">

                    <div className="row mt-2">
                        <div className="card uexpirepaddtopbrem">
                            <div className="userexpirebox text-center">
                                <img className="info_imgalertbox" src={infoiconalertbox} alt="" />

                                {headerData.plan_valid === false ?
                                    (
                                        <> <span className="pl-2 userexpiretextfs">
                                            Your subscription has expired. Please subscribe to a paid plan to enjoy uninterrupted services.
                                        </span>
                                            <span className="text-center pl-3">
                                                <button className="btn btn-default paynowbtn expirebtnboxfs" onClick={() => history.push(`${BASE_PATH}plans`)}>{parseInt(headerData?.is_next_sub_renewal ?? 0) === 1 ? " Renew now" : "Subscribe Now"}</button>
                                            </span></>

                                    ) :
                                    (
                                        <>
                                            <span className="pl-2 userexpiretextfs">
                                                Your subscription is going to Expire in
                                                <span className="expalerttext ml-1">{headerData.plan_valid_until}</span>
                                                . Please subscribe to a paid plan & enjoy uninterrupted services.
                                            </span>
                                            <span className="text-center pl-3">
                                                <button className="btn btn-default paynowbtn expirebtnboxfs" onClick={() => history.push(`${BASE_PATH}plans`)}>{parseInt(headerData?.is_next_sub_renewal ?? 0) === 1 ? " Renew now" : "Subscribe Now"}</button>
                                            </span>
                                        </>
                                    )
                                }

                                {GLOBAL_DV_CODE &&
                                    <a href={`${BASE_PATH}plans`}>
                                        <span className="text-center pl-3">
                                            <span className='exprit_expriebox_btnbox'>
                                                <span className=" ml-1"> Apply Voucher Code  <br></br>
                                                    <span className='expalerttext' style={{ fontSize: '16px' }}> {GLOBAL_DV_CODE} </span> <br></br> to Avail Discount</span>
                                                <span className='clickimg_icon'>
                                                    <img className="" src={clickhandexpire} alt="Click here to avail discount" width="40" />
                                                </span>
                                            </span>
                                        </span>
                                    </a>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className='container-fluid'>
                <div className="clientmaster_leftbottom_bannerbox">
                    <a target='_blank' rel='noreferrer' href='https://www.expressreco.com/?utm_source=disp_ExpressTDS_app&utm_medium=cloud-software&utm_campaign=ExpressRECO2024 '>
                        <div className='clientmaster_leftbottom_banner_innercontent'>
                            <img width="100%" src={ExpressRecoadCloud} alt="" />

                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}

export default DeductorIncompleteList;
