import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './DeductorCorrectionDashboard.css';
import OverviewSummary from './OverviewSummary';
import Quicklink from './Quicklink';
import ChallanSummary from '../returnDashboard/ChallanSummary';
import SectionWiseSummary from './SectionWiseSummary';
import { BASE_PATH } from '../../app/constants';
import { selectHeaderData } from '../../features/header/headerSlice';
import { getCorrectionTransactionOverview } from '../../api/returnAPI';
import AlertMessagePopup from '../../components/AlertMessagePopup';
import Alert from 'react-bootstrap/Alert';
import { createImportCorrectionReturnConosoLocalStorageKey, getCurrentFinancialYear } from '../../utils/UtilityFunctions';
import ImportReturnProgressCorrectionConso from '../returnDashboard/importReturnFromExcel/ImportReturnProgressCorrectionConso';
import queryString from 'query-string';

const DeductorCorrectionDashboard = (props) => {
  const history = useHistory();
  const { deductorId, returnId } = props.match.params;
  const headerDataDetails = useSelector(selectHeaderData);
  const finanicalYear = headerDataDetails?.financial_year_formatted ? headerDataDetails?.financial_year_formatted?.split("-")?.[0] : getCurrentFinancialYear();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const checkConsoStatus = params?.conso_status ? true : false

  const [error, setError] = useState('');
  const [showAlertMessagePopup, setShowAlertMessagePopup] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const [transactionData, setTransactionData] = useState({
    total_deductee_transactions: '',
    total_challans: '',
    total_tax_deducted_amtp: '',
    total_tax_adjusted_amtp: '',
    total_tax_remaining_to_be_adjusted_amtp: '',
  });


  const fetchTransactionOverView = async (deductor_id, return_id) => {
    try {
      const result = await getCorrectionTransactionOverview(`?deductor_id=${deductor_id}&return_id=${return_id}`);
      setTransactionData(result.data);
    } catch (err) {
      // console.error('Error in fetching transaction overview data: ', err);
    }
  };

  useEffect(() => {
    fetchTransactionOverView(deductorId, returnId);
  }, [deductorId, returnId]);

  useEffect(() => {
    if (headerDataDetails?.mobile_number && headerDataDetails?.tan && headerDataDetails?.financial_year_formatted && headerDataDetails?.quarter && headerDataDetails?.form_type) {
      const checkRunningImport = localStorage.getItem(createImportCorrectionReturnConosoLocalStorageKey(headerDataDetails))
      if (checkRunningImport || checkConsoStatus) {
        setShowResult(true);
      }
    }
  }, [headerDataDetails, checkConsoStatus]);

  return (
    <div>
      {showResult &&
        <ImportReturnProgressCorrectionConso
          show={showResult}
          handleClose={() => {
            setShowResult(false);
          }}
          deductorId={deductorId}
          returnId={returnId}
          headerData={headerDataDetails}
        />
      }

      <div className="container">
        {error
          ? (
            <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>
          )
          : null}
        
        <div className="row mt-2">
          <div className="col-md-12 mt-2">
            <AlertMessagePopup
              show={showAlertMessagePopup}
              handleClose={() => {
                setShowAlertMessagePopup(false);
              }}
              bodyText="This return is marked done, data is not available."
              headingText="ALERT"
              closeButtonText="Close"
            />
            <Link className="backbtntextclr" to={`${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${finanicalYear}`}>
              &lt;  Back to Deductor Dashboard
            </Link>

            <span className="btn btn-default startcorrbtnbgclr"
              onClick={() => history.push({
                pathname: `${BASE_PATH}correction`,
                search: `?deductor_id=${deductorId}&return_id=${returnId}&tab=edit_deductor`,
                state: { nextPageUrl: 'challans', paramsVals: `?deductor_id=${deductorId}&return_id=${returnId}` }
              })}
            >
              Start Correction
            </span>

          </div>
        </div>


        <OverviewSummary deductorId={deductorId} returnId={returnId} headerDataDetails={headerDataDetails} componentImport={true} transactionData={transactionData} />
        <Quicklink deductorId={deductorId} returnId={returnId} componentImport={true} headerDataDetails={headerDataDetails} history={history} handleProceed={() => {
          setShowResult(true);
        }} />
        <ChallanSummary deductorId={deductorId} returnId={returnId} headerDataDetails={headerDataDetails} />

        <SectionWiseSummary deductorId={deductorId} returnId={returnId} headerDataDetails={headerDataDetails} />

      </div>
    </div>

  );
};

export default DeductorCorrectionDashboard;
