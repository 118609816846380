/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getImportReturnStatus } from '../../../api/returnAPI';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './ImportReturnResult.css';
import './ImportReturnProgress.css'
import { createImportCorrectionReturnConosoLocalStorageKey } from '../../../utils/UtilityFunctions';

const ImportReturnProgressCorrectionConso = (props) => {
    const {
        show, handleClose, deductorId, returnId, headerData
    } = props;
    const checkRunningImport = localStorage.getItem(createImportCorrectionReturnConosoLocalStorageKey(headerData))
    const [importStats, setImportStats] = useState(checkRunningImport ? JSON.parse(checkRunningImport)?.import_stats : {})
    const [importStatus, setImportStatus] = useState(checkRunningImport ? JSON.parse(checkRunningImport)?.import_status : '')
    const timer = useRef(null);

    const callImportStatus = useCallback(async (deductor_id, returnId) => {
        try {
            const result = await getImportReturnStatus(deductor_id, returnId);
            if (result?.data) {
                setImportStats(() => { return { ...result?.data?.import_stats } })
                setImportStatus(result?.data?.import_status)
                localStorage.setItem(createImportCorrectionReturnConosoLocalStorageKey(headerData), JSON.stringify(result?.data));
                if (result?.data?.import_status !== "SUCCESS" && result?.data?.import_status !== "FAILURE") {
                    timer.current = setTimeout(() => callImportStatus(deductor_id, returnId), 3000);
                }
                if (result?.data?.import_status === "SUCCESS") {
                    window.location.reload();
                }
            }
        } catch (err) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deductorId, returnId]);


    useEffect(() => {
        const checkRunningImport = localStorage.getItem(createImportCorrectionReturnConosoLocalStorageKey(headerData))
        if (!checkRunningImport || (JSON.parse(checkRunningImport)?.import_status !== 'SUCCESS' && JSON.parse(checkRunningImport)?.import_status !== 'FAILURE')) {
            callImportStatus(deductorId, returnId)
        }
        return () => clearTimeout(timer.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onHide = () => {
        handleClose();
        const checkRunningImport = localStorage.getItem(createImportCorrectionReturnConosoLocalStorageKey(headerData))
        if (checkRunningImport && (JSON.parse(checkRunningImport)?.import_status === 'SUCCESS' || JSON.parse(checkRunningImport)?.import_status === 'FAILURE')) {
            localStorage.removeItem(createImportCorrectionReturnConosoLocalStorageKey(headerData));
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
                className="tdsimport_inprogress"
            >
                <Modal.Header closeButton className="tdsimport_inprogress_header">
                    <h4 class="tdsimport_inprogress_title tdsimport_inprogress_disinline ">
                        {importStatus === 'SUCCESS' ? <span style={{ color: 'green' }}>Import Completed</span> : importStatus === 'FAILURE' ? <span style={{ color: 'red' }}>Import Failed</span> : <>
                            <span style={{ color: 'blue' }}>Import in Progress</span>
                            <div style={{ width: "60%", float: "right", marginTop: "3px" }}><ProgressBar animated now={100} label={''} /></div>
                        </>}
                    </h4>
                </Modal.Header>
                <Modal.Body className="pt-2 pb-2 px-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                    <table class="table table-striped tdsimport_inprogress_tb mb-1">
                                        <thead>
                                            <tr>
                                                <th width="250"></th>
                                                <th width="100">Total</th>
                                                <th width="100">Imported</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {importStats?.challan &&
                                                <tr>
                                                    <td>Challan Details</td>
                                                    <td class="text-right">{importStats?.challan?.TotalCnt}</td>
                                                    <td class="text-right">{(importStats?.challan?.TotalCnt ?? 0) - (importStats?.challan?.ErrorCnt ?? 0)}</td>
                                                </tr>
                                            }
                                            {importStats?.transaction &&
                                                <tr>
                                                    <td>Deductee Transactions</td>
                                                    <td class="text-right">{importStats?.transaction?.TotalCnt}</td>
                                                    <td class="text-right">{(importStats?.transaction?.TotalCnt ?? 0) - (importStats?.transaction?.ErrorCnt ?? 0)}</td>
                                                </tr>
                                            }
                                            {importStats?.salary &&
                                                <tr>
                                                    <td>Salary Details</td>
                                                    <td class="text-right">{importStats?.salary?.TotalCnt}</td>
                                                    <td class="text-right">{(importStats?.salary?.TotalCnt ?? 0) - (importStats?.salary?.ErrorCnt ?? 0)}</td>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>

            </Modal>

        </>
    );
};

export default ImportReturnProgressCorrectionConso;
