/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import './CorrectionHeader.css';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import {
  faFileSignature, faRupeeSign, faInfo, faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { BASE_PATH } from '../../app/constants';

const CorrectionHeader = (props) => {
  const { deductorId, returnId, pageName } = props;
  const history = useHistory();
  return (
    <div>
      <div className="container-fluid w-98 mt-2">
        <div className="row">
          <div className="col-md-12">
            <div className="topbtnbox">
              <div className={pageName === 'EditCorrectionDeductor' || pageName === 'CorrectionTDSComputation' || pageName === 'CorrectionFVUGeneration' ? 'btn-group btn-group-md editdedcutortimelinebox01 correctionflowactive' : 'btn-group btn-group-md editdedcutortimelinebox01'}>
                <span className="iocnb0x hedFontAws editdedtimelineiconact">
                  <FontAwesomeIcon icon={faFileSignature} />
                </span>
                <Button
                  type="button"
                  className="btn btn-default bgbtnclr editdedtimelinebgclractive"
                  onClick={() => history.push({
                    pathname: `${BASE_PATH}deductor/${deductorId}/correction-return-edit-deductor/${returnId}`,
                  })}
                >
                  Edit Deductor
                </Button>
                <div className={pageName === 'CorrectionTDSComputation' || pageName === 'CorrectionFVUGeneration' ? 'brbline editdedtimelineborb01 activeLine' : 'brbline editdedtimelineborb01'} />
              </div>
              <div className={pageName === 'CorrectionTDSComputation' || pageName === 'CorrectionFVUGeneration' ? 'btn-group btn-group-md box2 editdedcutortimelinebox02 correctionflowactive' : 'btn-group btn-group-md box2 editdedcutortimelinebox02'}>
                <span className="iocnb0x boxbg2 hedFontAws editdedtimelineicon">
                  <FontAwesomeIcon icon={faRupeeSign} />
                </span>
                <Button
                  type="button"
                  className="btn btn-default bgbtnclr boxbg2 timelineboxunactclr"
                  onClick={() => history.push({
                    pathname: `${BASE_PATH}correction-deductee-transactions`,
                    search: `?page=1&return_id=${returnId}&deductor_id=${deductorId}`,
                  })}
                >
                  Edit Transaction
                </Button>
                <div className={pageName === 'CorrectionTDSComputation' || pageName === 'CorrectionFVUGeneration' ? 'brbline2 editdedtimelineborb02 activeLine' : 'brbline2 editdedtimelineborb02'} />
              </div>
              <div className={pageName === 'CorrectionTDSComputation' || pageName === 'CorrectionFVUGeneration' ? 'btn-group btn-group-md box3 editdedcutorbox03 correctionflowactive' : 'btn-group btn-group-md box3 editdedcutorbox03'}>
                <span className="iocnb0x boxbg2 hedFontAws editdedtimelineicon">
                  <FontAwesomeIcon icon={faInfo} />

                </span>
                <Button
                  type="button"
                  className="btn btn-default bgbtnclr boxbg2 timelineboxunactclr"
                  onClick={() => history.push({
                    pathname: `${BASE_PATH}deductor/${deductorId}/correction-edit-challan/return/${returnId}`, state: { nextPageUrl: 'deducteeTransaction', paramsVals: `?deductor_id=${deductorId}&return_id=${returnId}` },
                  })}
                >
                  Edit Challan
                </Button>

                <div className={pageName === 'CorrectionTDSComputation' || pageName === 'CorrectionFVUGeneration' ? 'brbline3 editdedtimelineborb03 activeLine' : 'brbline3 editdedtimelineborb03'} />
              </div>
              <div className={pageName === 'CorrectionTDSComputation' || pageName === 'CorrectionFVUGeneration' ? 'btn-group btn-group-md box4 editdedcutorbox04 correctionflowactive' : 'btn-group btn-group-md box4 editdedcutorbox04'}>
                <span className="iocnb0x boxbg2 hedFontAws editdedtimelineicon">
                  <FontAwesomeIcon icon={faFileAlt} />
                </span>
                <Button
                  type="button"
                  className="btn btn-default bgbtnclr boxbg2 timelineboxunactclr"
                  onClick={() => history.push({
                    pathname: `${BASE_PATH}correction-tds-computation`,
                    search: `?return_id=${returnId}&deductor_id=${deductorId}`,
                  })}
                >
                  TDS Computation
                </Button>
                <div className={pageName === 'CorrectionFVUGeneration' ? 'brbline4 editdedtimelineborb04 activeLine' : 'brbline4 editdedtimelineborb04'} />
              </div>
              <div className={pageName === 'CorrectionFVUGeneration' ? 'btn-group btn-group-md box4 editdedcutorbox05 correctionflowactive' : 'btn-group btn-group-md box4 editdedcutorbox05'}>
                <span
                  className="iocnb0x boxbg2 hedFontAws editdedtimelineicon"
                >
                  <FontAwesomeIcon icon={faFileAlt} />
                </span>
                <Button
                  type="button"
                  className="btn btn-default bgbtnclr boxbg2 timelineboxunactclr"
                  onClick={() => history.push({
                    pathname: `${BASE_PATH}correction-fvu-generation`,
                    search: `?return_id=${returnId}&deductor_id=${deductorId}`,
                  })}
                >
                  FVU Generation
                </Button>
              </div>
            </div>
          </div>

          {pageName !== 'CorrectionFVUGeneration' && pageName !== 'CorrectionTDSComputation'
            ? (
              <div className="col-md-12 pt-1">
                <p className="mb-0">
                  <span className="editvaluesclr">
                    Values in Blue indicate that their has been a
                    change in this field from the imported conso file.
                  </span>
                </p>
              </div>
            ) : null}

        </div>
      </div>
    </div>

  );
};

export default CorrectionHeader;
