/* eslint-disable eqeqeq */
export const deducteeTransactionsFormFields = {
  challan_id: '',
  mapChallanVal: '0',
  nature_of_payment_id: '',
  code: '',
  return_id: '',
  deductee_id: '',
  deductee_pan: '',
  deductee_name: '',
  deductee_category: '',
  tds_rate_b100: '0',
  payment_date: '',
  deduction_date: '',
  paid_amtp: '0',
  tds_amtp: '0',
  surcharge_paid_amtp: '0',
  education_cess_amtp: '0',
  deduction_reason: '',
  lower_deduction_certificate_no: '',
  challan_no: '',
  deposit_date: '',
  cash_withdrawal_option: '',
  cash_withdrawal_amtp: '0',
  tds_liable: '',
  tds_challan_no: '',
  tds_payment_date: '',
  purchase_amtp: '0',
  grossing_up_indicator:'',
  applicable_tds_rate:'',
  acknowledgment_no_15ca:'',
  nature_of_remittance:'',
  is_child: "",
  is_parent: ""
  
};

export const getDeducteeTransactionsObject = (data) => {
  const deducteeTransactionsData = {
    challan_id: data?.challan_id ?? '',
    //mapChallanVal: data.mapChallanVal,
    //code: data.code,
    nature_of_payment_id: data?.nature_of_payment_id ?? '',
    deductee_id: data?.deductee_id ?? '',
    deductee_pan: data?.pan ?? '',
    //deductee_name: data.deductee_name,
    tds_rate_b100: data?.tds_rate_b100 ?? '',
    payment_date: data?.payment_date ?? '',
    deduction_date: data?.deduction_date ?? '',
    cash_withdrawal_option: data?.cash_withdrawal_option ?? '',
    cash_withdrawal_amtp: data?.cash_withdrawal_amtp ? data?.cash_withdrawal_amtp : 0,
    paid_amtp: data.paid_amtp ?? 0,
    tds_amtp: data?.tds_amtp ?? 0,
    surcharge_paid_amtp: data?.surcharge_paid_amtp ?? 0,
    education_cess_amtp: data?.education_cess_amtp ?? 0,
    deduction_reason: data?.deduction_reason ?? '',
    lower_deduction_certificate_no: data?.lower_deduction_certificate_no ?? '',
    tds_liable: data?.tds_liable ?? '',
    tds_challan_no: data?.tds_challan_no ?? '',
    tds_payment_date: data?.tds_payment_date ?? '',
    purchase_amtp: data?.purchase_amtp ?? 0,
    nature_of_remittance: data?.nature_of_remittance ?? '',
    acknowledgment_no_15ca: data?.acknowledgment_no_15ca ?? '',
    applicable_tds_rate: data?.applicable_tds_rate ?? '',
    grossing_up_indicator: data?.grossing_up_indicator ?? '',
    //as_on_date: data?.as_on_date,
    //compliance_status: data?.compliance_status,
    pan_info_status: data?.pan_status ?? ''
  };

  return deducteeTransactionsData;
};

export const getFormObject = (data, form_type='') => {
  //let tdsPaymentDate = '';
  //let paymentDateStr = '';
  //let deductionDateStr = '';
  let mapChallanVals = '0';
  let deducteeName = data.deductee_name;

  /*if (data.tds_payment_date) {
    const d = new Date(data.tds_payment_date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

    tdsPaymentDate = `${ye}-${mo}-${da}`;
  }

  if (data.payment_date) {
    const d = new Date(data.payment_date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

    paymentDateStr = `${ye}-${mo}-${da}`;
  }

  if (data.deduction_date) {
    const d = new Date(data.deduction_date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

    deductionDateStr = `${ye}-${mo}-${da}`;
  }*/

  if (data.challan_no != 0 && data.challan_no != '' && data.challan_no != null && data.challan_no != undefined) {
    mapChallanVals = '1';
  } else {
    mapChallanVals = '0';
  }

  if (data.pan && data.deductee_name) {
    deducteeName = `${deducteeName} - ${data.pan}`;
  }
  
  const deducteeTransactionsInfo = { ...deducteeTransactionsFormFields };
  deducteeTransactionsInfo.challan_id = data.challan_id ? data.challan_id : '';
  deducteeTransactionsInfo.cin = data.cin ? data.cin : '';
  deducteeTransactionsInfo.challan_no = data.challan_no;
  deducteeTransactionsInfo.deposit_date = data.deposit_date;
  deducteeTransactionsInfo.mapChallanVal = mapChallanVals;
  deducteeTransactionsInfo.code = data.code;
  deducteeTransactionsInfo.nature_of_payment_id = data.nature_of_payment_id;
  deducteeTransactionsInfo.deductee_id = data.deductee_id;
  deducteeTransactionsInfo.deductee_pan = data.pan;
  deducteeTransactionsInfo.deductee_name = deducteeName;
  deducteeTransactionsInfo.tds_rate_b100 = data.tds_rate_b100 ? data.tds_rate_b100 : 0;
  deducteeTransactionsInfo.payment_date = data.payment_date;
  deducteeTransactionsInfo.deduction_date = data.deduction_date;
  deducteeTransactionsInfo.cash_withdrawal_option = data.cash_withdrawal_option;
  deducteeTransactionsInfo.cash_withdrawal_amtp = data.cash_withdrawal_amtp;
  deducteeTransactionsInfo.paid_amtp = data.paid_amtp;
  deducteeTransactionsInfo.tds_amtp = data.tds_amtp;
  deducteeTransactionsInfo.surcharge_paid_amtp = data.surcharge_paid_amtp;
  deducteeTransactionsInfo.education_cess_amtp = data.education_cess_amtp;
  deducteeTransactionsInfo.deduction_reason = data.deduction_reason;
  deducteeTransactionsInfo.lower_deduction_certificate_no = data.lower_deduction_certificate_no;
  deducteeTransactionsInfo.tds_liable = data.tds_liable ? 1 : 0;
  deducteeTransactionsInfo.tds_challan_no = data.tds_challan_no ? data.tds_challan_no : '';
  deducteeTransactionsInfo.tds_payment_date = data.tds_payment_date;
  deducteeTransactionsInfo.purchase_amtp = data.purchase_amtp;
  deducteeTransactionsInfo.nature_of_remittance= data.nature_of_remittance;
  deducteeTransactionsInfo.acknowledgment_no_15ca= data.acknowledgment_no_15ca;
  deducteeTransactionsInfo.applicable_tds_rate= data.applicable_tds_rate;
  deducteeTransactionsInfo.grossing_up_indicator= data.grossing_up_indicator;
  deducteeTransactionsInfo.as_on_date = data?.as_on_date;
  deducteeTransactionsInfo.compliance_status =  data?.compliance_status ?? 'Pending';
  deducteeTransactionsInfo.pan_info_status = data?.pan_status;
  deducteeTransactionsInfo.challan_total_amtp = data?.challan_total_amtp;
  deducteeTransactionsInfo.mark_nil = data?.mark_nil ?? false;  
  deducteeTransactionsInfo.is_child = data?.is_child ?? false;  
  deducteeTransactionsInfo.is_parent = data?.is_parent ?? false;  
  deducteeTransactionsInfo.deduction_reason = data?.deduction_reason ?? "";
  // if(form_type === '26Q' && (data?.compliance_status ?? '') === 'NON FILER') {
  //   deducteeTransactionsInfo.deduction_reason = 'U';
  // }else if(form_type === '27EQ' && (data?.compliance_status ?? '') === 'NON FILER') {
  //   deducteeTransactionsInfo.deduction_reason = 'I';
  // }

  return deducteeTransactionsInfo;
};
