import React, { useEffect, useRef, useState } from 'react'
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
//import Spinner from 'react-bootstrap/Spinner';
import { Tooltip } from '@progress/kendo-react-tooltip';
import queryString from 'query-string';
import { parseDate } from '@telerik/kendo-intl';
import { convertNum, deepClone, formatNumber } from '../../../utils/UtilityFunctions';
import { gocallApi } from '../../../api/issueCertificateAPI';
import $ from 'jquery'
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';



const TdsMappingSectionWise = (props) => {

    const { params, searchStr, setError, loading1, setLoading, createHistoryUrl, dragArray, setDragArray, naturePaymentList, challanftr, setShowMoreLoading, formType, setConsumedAmt } = props;

    const dragItem = useRef();


    const [dataState, setDataState] = useState({});
    const [sectionWiseList, setSectionWiseList] = useState([]);
    const [checkedItem, setCheckedItem] = useState({});
    const [sectionSummary, setSectionSummary] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [cursor, setCursor] = useState({});
    const [scrollPagnation, setScrollPagnation] = useState(false);
    const [allTransLength, setAlltransLenght] = useState([]);


    const dataStateChange = (data_state) => {
        if (data_state?.sort?.[0]?.field === "nature_of_payment_id") {
            data_state.sort[0].field = "display_code";
        }
        setShowMoreLoading(true);
        createHistoryUrl(data_state);
    }


    const getParamsHistoryUrl = (params) => {
        const parameter = deepClone(params);
        const data_state = {}

        const postData = {
            deductor_id: parameter.deductor_id ?? "",
        };

        if (parameter.return_id) {
            postData.return_id = parameter.return_id;
        }
        if (parameter.interestFilter) postData.interestFilter = parameter.interestFilter;

        if (parameter.sectionwisefilter) {
            const sectionWise = JSON.parse(atob(parameter.sectionwisefilter));

            parameter.sort = sectionWise?.sort ?? [];
            parameter.filters = sectionWise?.filter?.filters ?? null;
        }

        if (parameter.sort) {
            if (parameter?.sort?.[0]?.field === "display_code") {
                data_state.sort = deepClone(parameter.sort);
                data_state.sort[0].field = "nature_of_payment_id";

            } else {
                data_state.sort = parameter.sort;
            }
            postData.sort = parameter.sort;
        }

        if (parameter?.filtertable) {
            postData.filtertable = parameter?.filtertable ?? ""
        }


        if (parameter.filters) {
            // IN getParamsHistoryUrl
            let frs = parameter.filters;
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'deduction_date') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                }
            }

            data_state.filter = { filters: [] };
            data_state.filter.filters = frs;
            postData.filters = frs;
        }

        setDataState(data_state);
        return postData;
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const resetChildTrans = (sectionWiseList) => {

        const getNewChildTranaction = (dataItem) => {
            let newChaildTrans = {
                challan_id: dataItem?.challan_id,
                challan_no: dataItem?.challan_no,
                deductee_transaction_id: dataItem?.deductee_transaction_id,
                paid_amtp: dataItem?.paid_amtp,
                total_tds_amtp: dataItem?.total_tds_amtp,
                late_payment_interest_amtp: dataItem.late_payment_interest_amtp
            }
            if (!dataItem.child_trans) dataItem.child_trans = [];
            dataItem.child_trans.push(newChaildTrans);

            return dataItem;
        }

        if (sectionWiseList.length > 0) {
            sectionWiseList.forEach((dataItem) => {
                const isChildTrans = (dataItem?.child_trans && dataItem?.child_trans.length > 0) ? true : false;

                /**
                 * If Child Trans exist then need to check some scenarios for futher handling
                 * 
                 */
                if (isChildTrans === true || convertNum(dataItem.child_trans_cnt) > 0) {
                    //Add new child trans with parent data
                    if(!dataItem?.excl_trans) {
                        dataItem = getNewChildTranaction(dataItem);
                    }

                    // Update totals in Parent including child totals
                    dataItem.paid_amtp += dataItem.child_paid_amtp
                    dataItem.total_tds_amtp += dataItem.child_total_tds_amtp
                    if (dataItem.late_payment_interest_amtp) dataItem.late_payment_interest_amtp += dataItem.child_late_payment_interest_amtp

                }

            })
        }

        return sectionWiseList;
    }

    const dragStart = (e, position) => {
        dragItem.current = position;
        const elem = document.createElement("div");
        elem.id = 'drag-element-tooltip';
        let str = "Drag & Drop Transactions of TDS"
        let total = 0;
 
        if (Object.keys(dragArray)?.length > 0) {
            Object.keys(dragArray).forEach((key, index) => {
                // str += `${index > 0 ? ", " : ""} ${dragArray[key]?.deductee_name ?? ""}`
                total += dragArray[key]?.total_tds_amtp

            })
        }
        elem.innerHTML = `${str} ${formatNumber(total)} to Card`;
        $('#drag-element-tooltip-main').html(elem);
        e.dataTransfer.setDragImage(elem, 0, 0);
    };


    function sortChange(field) {
        let new_sort = [];
        let dir = 'asc';
        if (dataState && dataState.sort) {
            new_sort = dataState.sort.filter((sort, index) => {
                if (sort.field === field) {
                    dir = sort.dir === 'asc' ? 'desc' : '';
                }
                return sort.field !== field;
            })
        }

        if (field) {
            new_sort = dir ? [{ field: field, dir: dir }] : false;
            dataState.sort = new_sort;
            dataStateChange(dataState);
        }
    }

    const columnTitle = (title, key, field) => {

        const formatField = ["total_tds_amt", "total_paid_amt", "sum_paid_amtp", "sum_total_tds_amtp"];

        return (<div className="pb-2">
            <span onClick={() => sortChange(field)}>{title} </span>
            <div className={`challanstitlepos total_mapped_transactions`}
                style={key === "mapped_challan" ? { right: title === "Challan" ? "9px" : "0", width: "100%", textAlign: "center" } : {}}
            >
{
                    field !== 'challan_no' &&
                <span title={title !== "Challan" ? title + ": " + formatNumber(sectionSummary?.[key] ?? "0") : ""}>
                    {formatField.includes(key) ? formatNumber(sectionSummary?.[key] ?? "0") : sectionSummary?.[key] ?? "0"}
                </span>
}

            </div>
        </div>)
    }

    const handleCheckbox = (e, deductee_transaction_id, dataItem) => {

        const checkDrag = e.target?.checked;
        if (checkDrag) {
            dragArray[deductee_transaction_id] = dataItem;
            checkedItem[deductee_transaction_id] = checkDrag
        } else {
            delete dragArray[deductee_transaction_id]
            delete checkedItem[deductee_transaction_id]

        }
        setDragArray({ ...dragArray });

        setCheckedItem({ ...checkedItem });
        if (Object.keys(checkedItem)?.length === allTransLength?.length) setSelectAll(true);
        else setSelectAll(false);

    }

    const getScrollbarHeight = (el) => {
        return el.scrollHeight
    };

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem ?? {};
        const dataIndex = props.dataIndex ?? 0;

        return (
            <>
                <tr class={`${!dataItem?.is_unmapped ? "mapping_deducttrans_rowgradienteffect" : dataIndex % 2 ? "mapping_deducttrans_row_bgclr_grey  " : "mapping_deducttrans_row_bgclr_grey"}  ${checkedItem?.[dataItem.deductee_transaction_id] ? "selected-drag-row" : ""}`}
                    // style={{ borderBottom: "1px solid #e8e4e4" }}
                    draggable={checkedItem?.[dataItem.deductee_transaction_id] ? true : false}
                    onDragStart={(e) => dragStart(e, dataIndex)}
                    title={`${params.interestFilter === "secIntfilter" && dataItem.late_payment_interest_amtp > 0 ? dataItem?.late_payment_interest_amtp : ''}`}
                >
                    <td class={`text-center ${dataItem?.child_trans ? "paddpos" : "parentranswidth"}`} style={{ position: "relative" }}>
                        <span className={``}>
                            {!dataItem?.child_trans && <span
                                className={`${checkedItem?.[dataItem.deductee_transaction_id] ? "faGripVerticaltds faGripVerticalColor" : "faGripVerticaltds"}`}
                            >
                                <FontAwesomeIcon icon={faGripVertical} style={{ paddingBottom: "2px" }} />
                                {'  '}
                            </span>}

                            {(!dataItem?.child_trans || dataItem?.child_trans?.length === 0) && <input type="checkbox"
                                checked={checkedItem?.[dataItem.deductee_transaction_id] ? true : false}
                                onChange={(e) => handleCheckbox(e, dataItem.deductee_transaction_id, dataItem)}
                            />}

                            {dataItem?.child_trans?.length > 0 ? <span class={`collapsed expand-button mapping_dt_inner2_collapseicon pr-1 pl-1 parent_${dataItem.deductee_transaction_id} all-parent-item`}
                                onClick={(e) => {
                                    $(".child_" + dataItem.deductee_transaction_id).toggle()
                                    $(".parent_" + dataItem.deductee_transaction_id).toggleClass("collapsed");
                                    if (sectionWiseList?.length <= 16) {
                                        const height = getScrollbarHeight(document.querySelector('.k-grid-content'));
                                        if (height > 400) {
                                            $(".childsectrans").removeClass("scrollbar");
                                            $(".childsectrans.challans").addClass("pl-4");

                                        } else if (height < 400) {
                                            $(".childsectrans").addClass("scrollbar");
                                            $(".childsectrans.challans").removeClass("pl-4");
                                        }

                                    }

                                }}

                            >

                            </span> : <span class="accordion-toggle expand-button pr-1 pl-1 " style={{ width: "8px" }}></span>}
                        </span>
                    </td>

                    <td class="mapping_deducttrans_textoverflow">
                        <span title={dataItem?.deductee_name ?? ""} >{dataItem?.deductee_name ?? ""}</span>
                    </td>
                    <td class={`text-center childsectrans ${sectionWiseList.length <= 16 ? "scrollbar" : ""}`}>{dataItem?.pan ?? ""}</td>
                    <td class={`text-center childsectrans ${sectionWiseList.length <= 16 ? "scrollbar" : ""}`}>{dataItem?.display_code ?? ""}</td>
                    <td class={`text-center childsectrans ${sectionWiseList.length <= 16 ? "scrollbar" : ""}`}>{dataItem?.deduction_date ?? ""}</td>
                    <td class="text-right">
                        {formatNumber(dataItem?.paid_amtp ?? "")}
                    </td>
                    <td class="text-right">
                        {formatNumber(dataItem?.total_tds_amtp ?? "")}
                    </td>
                    <td class={`text-center childsectrans challans ${sectionWiseList.length <= 16 ? "scrollbar" : "pl-4"}`}>
                        {`${convertNum(dataItem?.child_trans_cnt) > 0 ? convertNum(dataItem?.challan_cnt) === 0 ? "" : dataItem?.challan_cnt : dataItem?.challan_no ?? ""}`}
                    </td>
                </tr>

                {dataItem?.child_trans?.length > 0 && dataItem.child_trans.map((childitem, index) =>
                    <tr class={`${index % 2 ? "mapping_deducttrans_row_bgclr_grey" : "mapping_deducttrans_row_bgclr_white"} child_${dataItem.deductee_transaction_id} all-child-item`}
                        title={`${params?.interestFilter === "secIntfilter" && childitem?.late_payment_interest_amtp > 0 ? childitem?.late_payment_interest_amtp : ''}`}
                        style={{ display: "none" }}
                    >
                        <td colspan="8" class="p-0">
                            <div className={`sectiontoggletrans childtrans${dataIndex}`} >

                                <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" style={{ overflowX: "visible", maxHeight: "129px" }}>
                                    <table class="table table-striped mapping_dt_innertb mb-0">

                                        <tbody>
                                            <tr
                                                draggable={checkedItem?.[childitem.deductee_transaction_id] ? true : false}
                                                onDragStart={(e) => dragStart(e, index)}
                                                className={`${index % 2 ? "mapping_deducttrans_row_bgclr_innerlightgrey" : "mapping_deducttrans_row_bgclr_innerlightgrey"}`}
                                            >
                                                <td width="50"
                                                    className="text-center paddpos mapping_deducttrans_row_bgclr_innerlightgrey mapping_bottom_borderW"
                                                >
                                                    <span
                                                        className={`${checkedItem?.[dataItem.deductee_transaction_id] ? "faGripVerticaltds faGripVerticalColor" : "faGripVerticaltds"}`}
                                                    >
                                                        <FontAwesomeIcon icon={faGripVertical} style={{ paddingBottom: "2px" }} />
                                                        {'  '}
                                                    </span>
                                                    <input type="checkbox"
                                                        checked={checkedItem?.[childitem.deductee_transaction_id] ? true : false}
                                                        onChange={(e) => handleCheckbox(e, childitem.deductee_transaction_id, childitem)}
                                                    />
                                                </td>
                                                <td colspan="4" width="400" className="mapping_deducttrans_row_bgclr_innerlightgrey mapping_bottom_borderW">
                                                </td>
                                                <td width="100" style={{ paddingRight: "0.65rem" }}
                                                    className={`text-right textclr_lightblue total_tds_amtpmapping_rowbgclr_innertb_whitebg borderleftside ${index % 2 ? "mapping_deducttrans_row_bgclr_white" : "mapping_deducttrans_row_bgclr_white"}`} >
                                                    {formatNumber(childitem?.paid_amtp ?? "")}
                                                </td>
                                                <td width="100" style={{ paddingRight: "1.2rem" }}
                                                    className={`text-right textclr_lightblue total_tds_amtpmapping_rowbgclr_innertb_whitebg  ${index % 2 ? "mapping_deducttrans_row_bgclr_white" : "mapping_deducttrans_row_bgclr_white"}`} >
                                                    {formatNumber(childitem?.total_tds_amtp ?? "")}
                                                </td>
                                                <td width="70" style={{ paddingRight: "0.3rem" }}
                                                    className={`text-center textclr_lightblue total_tds_amtpmapping_rowbgclr_innertb_whitebg ${index % 2 ? "mapping_deducttrans_row_bgclr_white" : "mapping_deducttrans_row_bgclr_white"}`} >
                                                    {childitem?.challan_no ?? ""}
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                )}
            </>
        )
    }

    const handleSelectAll = (e) => {
        setSelectAll(e?.target?.checked);

        if (e?.target?.checked) {
            const checkIds = {};
            const dragdropArray = {};
            sectionWiseList.forEach((item) => {
                if (item?.child_trans?.length > 0) {
                    item.child_trans.forEach((childkey) => {
                        checkIds[childkey.deductee_transaction_id] = true;
                        dragdropArray[childkey.deductee_transaction_id] = childkey;
                    })
                } else {
                    checkIds[item.deductee_transaction_id] = true;
                    dragdropArray[item.deductee_transaction_id] = item;
                }

            })
            setCheckedItem({ ...checkIds });
            setDragArray({ ...dragdropArray })
        } else if (!e?.target?.checked) {
            setCheckedItem({});
            setDragArray({});
        }
    }

    const getTdsMappingSectionWise = async (params = {}, chlnftr, isPagination, page) => {

        const url = `/api/v2/transaction/list-for-mapping`;

        const payload = {
            "deductor_id": params?.deductor_id ?? "",
            "return_id": params?.return_id,
            "sort_on": params?.sort?.[0]?.field ?? "",
            "sort_by": params?.sort?.[0]?.dir ?? "",
            "filters": params?.filters ?? "",
            "limit": 17,
            "page": page ?? 1

        }

        if (params.interestFilter) payload.int_app_flag = true;
        if (chlnftr) payload.challan_id = chlnftr.challan_id;
        if(isPagination) setLoading(true);
        

        try {
            const result = await gocallApi("post", url, payload);
            const responseReset = resetChildTrans(result?.data?.data ?? []);
            let alltrans = [];
            if (responseReset.length > 0) {
                responseReset?.forEach((item) => {
                    if (item?.child_trans) {
                        Object.keys(item?.child_trans).forEach((childtrns) => {
                            alltrans.push(item?.child_trans?.[childtrns]);
                        })
                    } else {
                        alltrans.push(item);
                    }

                    if (item?.is_unmapped === 1) {                       

                        setConsumedAmt((prev) => {
                            prev = prev + (item?.total_tds_amtp ?? 0)
                            return prev
                        })                   
                    }
                    

                })
            }

            if (page > 1) {
                setSectionWiseList((prev) => {
                    prev = prev.concat(responseReset);
                    return [...prev];
                });
                setAlltransLenght((prev) => {
                    prev = prev.concat(alltrans);
                    return [...prev];
                })
                if (!sectionSummary) {
                    setSectionSummary(result?.data?.summary ?? {});
                }

            } else {
                setSectionWiseList(responseReset);
                setAlltransLenght(alltrans);
                setSectionSummary(result?.data?.summary ?? {});
            }
            if(!page) $(".sectionwisetrans .k-grid-content").scrollTop(0);


            $(".all-parent-item").addClass("collapsed");
            $(".all-child-item").hide();

            setSelectAll(false);
            setCursor(result?.data?.cursor ?? {});
            setDragArray({})
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setScrollPagnation(false);
            if(isPagination) setLoading(false);
            if(!page)  setShowMoreLoading(false);
        }

    }



    $(function () {
        $(".sectionwisetrans .k-grid-content").scroll(function () {
            if (getScrollbarHeight(document.querySelector('.sectionwisetrans .k-grid-content')) < $(".sectionwisetrans .k-grid-content").scrollTop() + $(".sectionwisetrans .k-grid-content").height() + 3) {
                if (!scrollPagnation && cursor?.next_page > 1) {
                    setScrollPagnation(true);
                    
                }
            }
        });
    });

    useEffect(() => {
        const params = queryString.parse(searchStr);
        if (params.filtertable === "sectionwise") {
            const postData = getParamsHistoryUrl(params);
            getTdsMappingSectionWise(postData, challanftr);
        } else if (!params.filtertable) {
            const postData = { deductor_id: params?.deductor_id ?? "", return_id: params?.return_id ?? "" };
            getTdsMappingSectionWise(postData, challanftr);
        }
        // eslint-disable-next-line
    }, [searchStr, challanftr]);

    useEffect(() => {
        if (cursor?.next_page > 1 && scrollPagnation) {
            const postData = getParamsHistoryUrl(params);
            getTdsMappingSectionWise(postData, null, scrollPagnation, cursor.next_page)
        }
        // eslint-disable-next-line
    }, [scrollPagnation]);




    return (
        <>
            {(!sectionSummary) ?
                <div style={{ display: "flex" }}>
                    {[...Array(6)].map((items) =>
                        <div className='col-md-2'>
                            {[...Array(8)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
                        </div>
                    )}

                </div>
                :
                <>
                    <Tooltip anchorElement="pointer" position="right">
                        <Grid
                            className={`table table-striped dash_blukpanstatus_tb mb-1 gridmaxheight sectionwisetrans`}
                            data={sectionWiseList}
                            {...dataState}
                            fixedScroll={true}
                            onDataStateChange={(event) => dataStateChange(event.dataState)}
                            filterOperators={{
                                text: [
                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                ],
                                dropdown: [
                                    { text: 'grid.filterEqOperator', operator: 'in' },
                                ],
                                numeric: [
                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                ],
                                textWithEmpty: [
                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                ],
                                date: [
                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                ],
                                challan: [
                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                    { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                ],
                            }}
                            rowRender={rowRender}
                            sortable
                        >
                            <Column
                                width="50"
                                headerCell={() => {
                                    return (
                                        <div style={{ textAlign: "center", marginRight: "6px" }}>
                                            <span>
                                                <input
                                                    type='checkbox' checked={selectAll}
                                                    onChange={(e) => handleSelectAll(e)}
                                                />
                                                <span class={` collapsed expand-button mapping_dt_inner2_collapseicon pr-1 pl-1 main-parent-item`}
                                                    onClick={(e) => {
                                                        $(".main-parent-item").toggleClass("collapsed");
                                                        if ($(e.target).hasClass("collapsed")) {
                                                            $(".all-parent-item").addClass("collapsed")
                                                            $(".all-child-item").hide()
                                                        } else {
                                                            $(".all-parent-item").removeClass("collapsed")
                                                            $(".all-child-item").show()
                                                        }
                                                    }}
                                                >
                                                </span>
                                            </span>
                                        </div>
                                    )
                                }}
                            />

                            <Column
                                width="200"
                                field="deductee_name"
                                title={formType === '24Q' ? 'Employee Name' : formType === '27EQ' ? 'Collectee Name' : 'Deductee Name'}
                                filter="text"
                                columnMenu={params.filtertable !== "monthwise" && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                            />

                            <Column
                                width="90"
                                field="pan"
                                title=" PAN "
                                filter="text"
                                columnMenu={params.filtertable !== "monthwise" && ((props) => <ColumnMenu hideSecondFilter  {...props} />)}
                                headerClassName={isColumnActive('pan') ? 'active' : ''}
                            />

                            <Column
                                width="70"
                                field="nature_of_payment_id"
                                title={formType === '27EQ' ? 'Collection Code' : 'Section'}
                                filter="dropdown"
                                columnMenu={params.filtertable !== "monthwise" && ((props) => <ColumnMenu hideSecondFilter filterList={naturePaymentList} {...props} />)}
                                headerClassName={isColumnActive('nature_of_payment_id') ? 'active' : ''}
                            />

                            <Column
                                width="80"
                                field="deduction_date"
                                title={formType === '27EQ' ? 'Collection Dt' : 'Ded Date'}
                                filter="date"
                                columnMenu={params.filtertable !== "monthwise" && ((props) => <ColumnMenu {...props} />)}
                                headerClassName={isColumnActive('deduction_date') ? 'active' : ''}
                            />

                            <Column
                                width="100"
                                field="paid_amtp"
                                title={columnTitle(formType === '27EQ' ? 'Amt Rcvd' : 'Amt Paid', "sum_paid_amtp", "paid_amtp")}
                                filter="numeric"
                                columnMenu={params.filtertable !== "monthwise" && ((props) => <ColumnMenu  {...props} />)}
                                headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                                sortable={false}
                            />


                            <Column
                                width="100"
                                field="total_tds_amtp"
                                title={columnTitle(formType === '27EQ' ? 'TCS' : 'TDS', "sum_total_tds_amtp", "total_tds_amtp")}
                                filter="numeric"
                                columnMenu={params.filtertable !== "monthwise" && ((props) => <ColumnMenu  {...props} />)}
                                headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                                sortable={false}

                            />

                            <Column
                                width="80"
                                field="challan_no"
                                title={columnTitle('Challan', "mapped_challan", "challan_no")}
                                filter="challan"
                                columnMenu={params.filtertable !== "monthwise" && ((props) => <ColumnMenu challanFilter={true} {...props} />)}
                                headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                sortable={false}

                            />

                            {(sectionWiseList?.length === 0 && !loading1) && (
                                <GridNoRecords className="no_records">
                                    {params.interestFilter === "secIntfilter" 
                                        ? "No such transactions attracting due to late deposit of challan." 
                                        : formType === '27EQ' ? 'No Collectee Records to Show.' : formType === '24Q' ? 'No Transactions to Show.' : 'No Deductee Transactions to Show.'
                                    }
                                </GridNoRecords>
                            )}


                        </Grid>
                    </Tooltip>

                    {/* {loading && (
                        <span style={{
                            position: 'absolute',
                            left: '50%',
                            bottom: "-18px",
                            color: "gray"
                        }}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </span>
                    )} */}
                </>}
        </>
    )
}

export default TdsMappingSectionWise