import React from 'react'

const Salary = (props) => {

    const { data } = props

    return (

        <>

            {/* <!-- INCOME FROM SALARY --> */}
            {(data?.IncomeFromSalary) &&
                <div className="row" >
                    <div className="col-md-12">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <table className="table table-borderless expit_comp_personalinfo_salaryincome_tb mb-0 tr-add-hover">
                                <tbody>

                                    <tr className="expit_comp_personalinfo_salaryboxbgclr">
                                        <td colSpan="3" className="expit_comp_personalinfo_salaryboxbgclr">INCOME FROM SALARY</td>
                                        <td width="100" className="text-right"></td>
                                        <td width="150" className="text-right">{(data?.IncomeFromSalary?.Amount)?.toLocaleString('hi-IN')}</td>
                                    </tr>

                                    <tr>
                                        <td colSpan="5" className="py-1"></td>
                                    </tr>
                                    {data?.IncomeFromSalary?.EmployerWiseDetails && data?.IncomeFromSalary?.EmployerWiseDetails?.length > 0 &&
                                        data?.IncomeFromSalary?.EmployerWiseDetails.map((salaryItem, i) => {
                                            const items = salaryItem?.SalaryUs17ss1?.Items;
                                            const items2 = salaryItem?.SalaryUs17ss2?.Items;
                                            const items3 = salaryItem?.SalaryUs17ss3?.Items;
                                            return (<>

                                                <tr className="expit_comp_personalinfo_tataconsultboxbgclr">
                                                    <td colSpan="3">{i + 1}. <span className=" expit_comp_personalinfo_textunderline">{salaryItem?.Name}</span></td>
                                                    <td width="" className="text-right">{salaryItem?.Amount?.toLocaleString('hi-IN')}</td>
                                                    <td width="" className="text-right"></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="">Employer Status: {salaryItem?.Category}</td>
                                                </tr>

                                                {salaryItem?.SalaryUs17ss1 && <>
                                                    <tr className="expit_comp_personalinfo_salarytotle_shadow">
                                                        <td width="" className="pl-4">Gross Salary as per 17(1)</td>
                                                        <td width=""></td>
                                                        <td width="" className="text-right">{(salaryItem?.SalaryUs17ss1?.Amount)?.toLocaleString('hi-IN')}</td>
                                                        <td width=""></td>
                                                        <td width=""></td>
                                                    </tr>

                                                    {(items?.basic_amt ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Basic Salary </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.basic_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.hra_amt ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">House Rent Allowance </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.hra_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.lta_amt ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Leave Travel Allowance </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.lta_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.da_amt ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Dearness Allowance </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.da_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.ca_amt ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Conveyance Allowance </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.ca_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.le_amt ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Leave Encashment on Retirement  </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.le_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.cea_amt ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Children Education Allowance  </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.cea_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.hea_amt ?? null) &&
                                                        <tr>
                                                            <td width="" className="pl-4">Hostel Education Allowance </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.hea_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.arrear_amt ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Arrear Received  </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.arrear_amt)?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['1_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Entertainment Allowance  </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['1_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['2_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Fees/Commission</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['2_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['3_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Employer Contribution towards Pension </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['3_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['4_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Advance of Salary </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['4_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['5_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Annuity/ Commuted Pension </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['5_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['6_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Gratuity Received </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['6_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['7_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Voluntary Retirement Scheme  </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['7_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['9_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Superannuation  </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['9_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['8_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Retrenchment Compensation</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['8_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['11_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Interest Credited to RPF (in excess of specified limit)</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['11_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['10_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Bonus</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['10_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['12_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Employer contribution to RPF (in excess of specified limit)</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['12_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['13_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Leave Encashment during Service </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['13_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {(items?.['14_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Un-commuted Pension</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['14_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }
                                                    {items?.others_det && items?.others_det.length > 0 &&
                                                        items?.others_det.map((otherItem, i) => {
                                                            return (<>

                                                                {otherItem?.oth_amt &&
                                                                    <tr className="">
                                                                        <td width="" className="pl-4">{(otherItem?.part)} </td>
                                                                        <td width=""></td>
                                                                        <td width="" className="text-right">{(otherItem?.oth_amt)?.toLocaleString('hi-IN')}</td>
                                                                        <td width=""></td>
                                                                        <td width=""></td>
                                                                    </tr>
                                                                }



                                                            </>)
                                                        })
                                                    }


                                                    {(items?.['a1_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Transport Allowance granted to physically disabled employees for commuting between place of duty and residence</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a1_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }



                                                    {(items?.['a2_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Any allowance granted to an employee working in any transport system to meet his expenditure during duty performed during running of such transport from one place to another</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a2_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a3_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Special Compensatory Allowance for Hilly Areas or High Altitude Allowance or Climate Allowance</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a3_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a4_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Border Area Allowance or Remote Area Allowance or a Difficult Area Allowance or Disturbed Area Allowance'</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a4_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a5_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Tribal Area/ Scheduled Area/ Agency Area Allowance available in MP, Assam, UP, Karnataka, West Bengal, Bihar, Orissa, Tamil Nadu, Tripura</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a5_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a6_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Compensatory field area allowance available in various areas of AP, Manipur, Sikkim, Nagaland, HP, UP & J&K</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a6_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a7_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Compensatory modified field area allowance available in specified areas of Punjab, Rajasthan, Haryana, UP, J&K, HP, West Bengal and North East</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a7_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a8_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Counter insurgency allowance to members of Armed Forces</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a8_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a9_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="">Underground Allowance granted to an employee working in underground mines</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a9_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a10_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Special Allowance in the nature of High Altitude allowance granted to the members of Armed Forces</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a10_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }


                                                    {(items?.['a11_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Any special allowance granted to members of the armed forces in the nature of special compensatory highly active field area allowance</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a11_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }

                                                    {(items?.['a12_amt'] ?? null) !== null &&
                                                        <tr>
                                                            <td width="" className="pl-4">Special Allowance granted to members of armed forces in the nature of Island Duty Allowance (in Andaman & Nicobar and Lakshadweep Group of Islands)</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items?.['a12_amt'])?.toLocaleString('hi-IN')} </td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>
                                                    }


                                                </>}

                                                    {salaryItem?.SalaryUs17ss2 && <>
                                                        <tr className="expit_comp_personalinfo_salarytotle_shadow">
                                                            <td width="" className="pl-4">Value of perquisites as per 17(2)	</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(salaryItem?.SalaryUs17ss2?.Amount)?.toLocaleString('hi-IN')}</td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>

                                                        {(items2?.rfa_amt ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Rent-free Accommodation </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['rfa_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }

                                                        {(items2?.cars_amt ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Cars </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['cars_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.helpers_amt ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Sweeper/ Gardner/ Watchman/ PA</td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['helpers_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.ifl_amt ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Interest Free Loans</td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['ifl_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['1_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Holiday Expenses </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['1_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['2_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Free Meals  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['2_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['1_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Free Education  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['3_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['4_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Contribution of Employer to Fund & Scheme Taxable u/s 17(2) </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['4_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['5_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Other Amenities  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['5_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['6_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Gifts  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['6_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['7_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Credit Card Expenses  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['7_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['8_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Club Expenses  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['8_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['9_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Use of Moveable Assets </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['9_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['10_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Transfer of Assets  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['10_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['11_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Stock Options  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['11_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['12_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Stock Options other than ESOP  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['12_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['13_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Annual Accretion by way of Interest Dividend  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['13_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['14_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Gas Electricity and Water  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['14_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {(items2?.['15_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Tax Paid by Employer on Non-Monetary Prerequisite 192 (1A)  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items2?.['15_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                        {items2?.others_det && items2?.others_det.length > 0 &&
                                                            items2?.others_det.map((otherItem, i) => {
                                                                return (<>

                                                                    {otherItem?.oth_amt > 0 &&
                                                                        <tr className="">
                                                                            <td width="" className="pl-4">{(otherItem?.part)} </td>
                                                                            <td width=""></td>
                                                                            <td width="" className="text-right">{(otherItem?.oth_amt)?.toLocaleString('hi-IN')}</td>
                                                                            <td width=""></td>
                                                                            <td width=""></td>
                                                                        </tr>
                                                                    }
                                                                </>)
                                                            })
                                                        }

                                                    </>

                                                    }

                                                    {salaryItem?.SalaryUs17ss3 && <>
                                                        <tr className="expit_comp_personalinfo_salarytotle_shadow">
                                                            <td width="" className="pl-4">Profit in lieu of salary as per 17(3)		</td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{salaryItem?.SalaryUs17ss3?.Amount}</td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>


                                                        {(items3?.['1_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Compensation received from Employer due to termination of his employment</td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items3?.['1_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }

                                                        {(items3?.['2_amt'] ?? null) !== null &&
                                                            <tr>
                                                                <td width="" className="pl-4">Payment Received from employer under Key-man Insurance Policies  </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(items3?.['2_amt'])?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>}
                                                        {(items3?.['3_amt'] ?? null) !== null && <tr>
                                                            <td width="" className="pl-4">Any Amount received before joining or after cessation of employment  </td>
                                                            <td width=""></td>
                                                            <td width="" className="text-right">{(items3?.['3_amt'])?.toLocaleString('hi-IN')}</td>
                                                            <td width=""></td>
                                                            <td width=""></td>
                                                        </tr>}

                                                        {items3?.others_det && items3?.others_det.length > 0 &&
                                                            items3?.others_det.map((otherItem, i) => {
                                                                return (<>

                                                                    {otherItem?.oth_amt > 0 &&
                                                                        <tr className="">
                                                                            <td width="" className="pl-4">{(otherItem?.part)} </td>
                                                                            <td width=""></td>
                                                                            <td width="" className="text-right">{(otherItem?.oth_amt)?.toLocaleString('hi-IN')}</td>
                                                                            <td width=""></td>
                                                                            <td width=""></td>
                                                                        </tr>
                                                                    }
                                                                </>)
                                                            })
                                                        }

                                                </>}
                                            </>)


                                        })}


                                    {data?.IncomeFromSalary?.ExemptionsUs10 &&
                                        <>
                                            <tr className="expit_comp_personalinfo_salarytotle_shadow">
                                                <td width="" className="pl-4">Less: Exemptions u/s 10	</td>
                                                <td width=""></td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Amount)?.toLocaleString('hi-IN')} </td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.hra_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">House Rent Allowance </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.hra_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.cea_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Children Education Allowance </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.cea_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.hea_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Hostel Expenditure Allowance</td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.hea_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }




                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.lta_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Leave Travel Concession  </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.lta_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.ca_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Conveyance Allowance  </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.ca_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.le_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Leave Encashment</td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.le_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.gra_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Gratuity  </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.gra_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.cp_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Commuted Pension</td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.cp_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.vrs_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Voluntary Retirement Scheme  </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.vrs_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.rc_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Retrenchment Compensation </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.rc_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.eoi_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Allowance Paid To Employees Outside India </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.eoi_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['1_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Transport Allowance granted to physically disabled employees for commuting between place of duty and residence (Rule 2BB (2))</td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['1_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['2_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Any allowance granted to an employee working in any transport system to meet his expenditure during duty performed during running of such transport from one place to another </td>
                                                <td width="" className="text-right expit_comp_personalinfo_2linevertalign">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['2_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['4_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Border Area Allowance or Remote Area Allowance or a Difficult Area Allowance or Disturbed Area Allowance </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['4_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['3_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Special Compensatory Allowance for Hilly Areas or High Altitude Allowance or Climate Allowance </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['3_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['5_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Tribal Area/ Scheduled Area/ Agency Area Allowance available in MP, Assam, UP, Karnataka, West Bengal, Bihar, Orissa, Tamil Nadu, Tripura </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['5_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['6_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Compensatory field area allowance available in various areas of AP, Manipur, Sikkim, Nagaland, HP, UP & J&K </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['6_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['7_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Compensatory modified field area allowance available in specified areas of Punjab, Rajasthan, Haryana, UP, J&K, HP, West Bengal and North East</td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['7_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }

                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['8_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Counter insurgency allowance to members of Armed Forces</td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['8_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['9_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Underground Allowance granted to an employee working in underground mines</td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['9_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['10_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Special Allowance in the nature of High Altitude allowance granted to the members of Armed Forces</td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['10_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }




                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['11_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4 ">Any special allowance granted to members of the armed forces in the nature of special compensatory highly active field area allowance </td>
                                                <td width="" className="text-right ">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['11_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['12_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Special Allowance granted to members of armed forces in the nature of Island Duty Allowance (in Andaman & Nicobar and Lakshadweep Group of Islands) </td>
                                                <td width="" className="text-right ">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.['12_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }

                                            {(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.tpnmp_amt ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Tax Paid by Employer on Non-Monetary Prerequisite 192 (1A) </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.ExemptionsUs10?.Items?.tpnmp_amt)?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {data?.IncomeFromSalary?.ExemptionsUs10?.Items?.others_det && data?.IncomeFromSalary?.ExemptionsUs10?.Items?.others_det.length > 0 &&
                                                data?.IncomeFromSalary?.ExemptionsUs10?.Items?.others_det.map((otherItem, i) => {
                                                    return (<>

                                                        {otherItem?.oth_amt &&
                                                            <tr className="">
                                                                <td width="" className="pl-4">{(otherItem?.part)} </td>
                                                                <td width="" className="text-right">{(otherItem?.oth_amt)?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                    </>)
                                                })
                                            }

                                        </>
                                    }

                                    {data?.IncomeFromSalary?.DeductionsUs16 &&
                                        <>
                                            <tr className="expit_comp_personalinfo_salarytotle_shadow">
                                                <td width="" className="pl-4">Less: Deductions u/s 16</td>
                                                <td width=""></td>
                                                <td width="" className="text-right">(-){(data?.IncomeFromSalary?.DeductionsUs16?.Amount)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            {(data?.IncomeFromSalary?.DeductionsUs16?.Items?.['sd_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Standard Deduction  </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.DeductionsUs16?.Items?.['sd_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }
                                            {(data?.IncomeFromSalary?.DeductionsUs16?.Items?.['ea_amt'] ?? null) !== null &&
                                                <tr>
                                                    <td width="" className="pl-4">Entertainment Allowance    </td>
                                                    <td width="" className="text-right">{(data?.IncomeFromSalary?.DeductionsUs16?.Items?.['ea_amt'])?.toLocaleString('hi-IN')}</td>
                                                    <td width="" className="text-right"></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.IncomeFromSalary?.DeductionsUs16?.Items?.['pt_amt'] ?? null) !== null && <tr>
                                                <td width="" className="pl-4">Professional Tax   </td>
                                                <td width="" className="text-right">{(data?.IncomeFromSalary?.DeductionsUs16?.Items?.['pt_amt'])?.toLocaleString('hi-IN')}</td>
                                                <td width="" className="text-right"></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            }

                                        </>
                                    }


                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            }
            {/* <!-- INCOME FROM SALARY --> */}


        </>



    )
}

export default Salary