import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonGraphRectangle = () => {
    return (
        <div>
            <div style={{ "display": "flex", "flexDirection": "row-reverse", "justifyContent": "space-between" }}>
                <div style={{ width: "50%" }}>
                    <Skeleton animation="wave" variant='text' sx={{ width: "65%", }} />
                     <Skeleton animation="wave" variant='text' sx={{ width: "65%", }} /> 
                     <Skeleton animation="wave" variant='text' sx={{ width: "65%", }} />

                </div>
                <div>
                <div>
                 <Skeleton animation="wave"  sx={{"width":"26%","margin":"-22px 0px 0px 168px","rotate":"90deg","position":"relative","right":"104px","top":"55px" }} />
                 <Skeleton animation="wave" sx={{ textAlign: 'center',margin: "0 9px 0px 88px" }} variant='rounded' width={"362px"} height={"200px"} />
                </div>
                <br/>
                 <Skeleton animation="wave"  sx={{"width":"33%","margin":"-15px 13px 0px 185px" }} />
</div>

            </div>

        </div>

    )
}

export default SkeletonGraphRectangle