import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { BASE_PATH } from '../../../app/constants';
import ConfirmAlertPopup from '../../../components/ConfirmAlertPopup';
import AlreadyFileListPopup from './AlreadyFileListPopup';
import { Alert } from 'react-bootstrap';
import moment from 'moment';


const CorrectionConfirmationPopup = (props) => {
    const { show, handleClose, deductorId, returnId, createNewReturn, allReturnData, returnPayload, successProced, financialYear } = props;

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [showAlreadyFileList, setShowAlreadyFileList] = useState(null);
    const getFilingList = () => {
        let array = [];
        if (allReturnData.length > 0) {
            allReturnData.sort((firstObj, secondObj) => (moment(firstObj?.filing_date ?? 0).unix() > moment(secondObj?.filing_date ?? 0).unix() ? -1 : 1))
            allReturnData.forEach((item) => {
                if (returnPayload.form_type === item.form_type && returnPayload.quarter === item.quarter && returnPayload.return_type === item.return_type) {
                    if(item.ack_no && item.filing_date && item.efiling_status){
                        array.push(item);
                    }
                }
            })
        }
        setShowAlreadyFileList(array);
    }


    return (
        <>



            {confirmPopup &&
                <ConfirmAlertPopup
                    show={confirmPopup}
                    handleClose={() => setConfirmPopup(false)}
                    confirmText="Are you sure you want to discard your already prepared return and start new?"
                    confirmButtonText="Yes"
                    confirmButtonText2="No"
                    title="Alert"
                    handleProceed2={() => {
                        setConfirmPopup(false);
                    }}
                    handleProceed={() => {
                        createNewReturn();
                    }}
                />
            }
            {showAlreadyFileList &&
                <AlreadyFileListPopup
                    data={showAlreadyFileList}
                    handleClose={() => setShowAlreadyFileList(null)}
                    returnPayload={returnPayload}
                    successProced={() => {
                        handleClose();
                        successProced();
                        setShowAlreadyFileList(null);
                    }}
                    returnId={returnId}
                />
            }


            <Modal className="deductordashboadscss Createnewreturn_ContinueonSameReturn_modal show" backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton className="Createnewreturn_ContinueonSameReturn_header">
                    <Modal.Title className="gst_verifyyremailid_title" >Correction Return</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "1rem" }}>
                    {error
                        ? (
                            <Alert variant="danger" style={{ fontSize: "12px" }} onClose={() => setError('')} dismissible>{error}</Alert>
                        )
                        : null}
                    {success
                        ? (
                            <Alert variant="success" style={{ fontSize: "12px" }} onClose={() => setSuccess('')}>{success}</Alert>
                        )
                        : null}
                    <div className="row">
                        <div className="col-md-12">
                            <p className="contonsame_textfs">
                                {`Correction return for ${returnPayload.form_type} | ${returnPayload.quarter} is already in progress. Do you want to continue working on same return or discard and prepare a new one?`}
                            </p>
                        </div>

                        <div className="col-lg-12 text-center pt-3">
                            <Link to={`${BASE_PATH}correction-return-dashboard/${deductorId}/return/${returnId}?financial_year=${financialYear}`}>
                                <button className="btn btn-default ContinueonSameReturn_btnbgclr mr-2" >Continue on Same Return</button>
                            </Link>
                            <button className="btn btn-default ContinueonSameReturn_btnbgclr"
                                onClick={() => {
                                    setConfirmPopup(true);
                                }}
                            >Discard & Prepare New</button>
                        </div>
                        <div className="col-md-12 pt-3 " >
                            <span className="ifalready_textfs" onClick={() => getFilingList()}>
                                If already <b>Filed</b>, map your return to update Return Register and create new Return
                            </span>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default CorrectionConfirmationPopup