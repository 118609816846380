/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './HouseRentAllowanceForm.css';
import HouseRentYearForm from './HouseRentYearForm';
import HouseRentMonthForm from './HouseRentMonthForm';


const HouseRentAllowanceForm = (props) => {

    const { fieldValueProps, setFieldValueProps, allowance_index, handleClose, getTotalAllowanceAmtp } = props;
    const [houseRendType, setHouseRendType] = useState("YEAR");
    const [houseRentData, setHouseRentData] = useState(false);
    const [totalMonthly, setTotalMonthly] = useState(0.00);

    useEffect(() => {
        if (fieldValueProps.allowances[allowance_index].allowance_details) {
            setHouseRendType(fieldValueProps.allowances[allowance_index].allowance_details.type);
            setHouseRentData(fieldValueProps.allowances[allowance_index].allowance_details);
        }
        else {
            setHouseRentData({
                type: "YEAR",
                details: {
                    whether_metro_city: 0,
                    hra_received_amtp: 0,
                    rent_paid_amtp: 0,
                    annual_salary_amtp: fieldValueProps.salary_amtp,
                    exempt_amtp: 0
                }
            });
        }
    }, [fieldValueProps]);


    const changeHouseRentType = (type) => {

        if (fieldValueProps.allowances[allowance_index].allowance_details &&
            type === fieldValueProps.allowances[allowance_index].allowance_details.type) {
            setHouseRendType(fieldValueProps.allowances[allowance_index].allowance_details.type);
            setHouseRentData(fieldValueProps.allowances[allowance_index].allowance_details);
            return true;
        }

        if (type === 'YEAR') {
            setHouseRentData({
                type: "YEAR",
                details: {
                    whether_metro_city: 0,
                    hra_received_amtp: 0,
                    rent_paid_amtp: 0,
                    annual_salary_amtp: fieldValueProps.salary_amtp,
                    exempt_amtp: 0
                }
            });
        }
        else {
            setHouseRentData({
                type: "MONTH",
                details: {
                    1: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    2: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    3: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    4: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    5: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    6: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    7: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    8: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    9: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    10: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    11: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                    12: {
                        whether_metro_city: 0,
                        hra_received_amtp: 0,
                        rent_paid_amtp: 0,
                        salary_amtp: 0,
                        exempt_amtp: 0
                    },
                }
            });
        }
        setHouseRendType(type);
    }


    const saveData = (values) => {
        setHouseRentData(values);
        let allowances = fieldValueProps.allowances;
        //values = resetField(values);
        allowances[allowance_index].allowance_details = values;
        if (values.type === 'YEAR') {
            allowances[allowance_index].exempt_amtp = values.details.exempt_amtp;
        }
        else {
            allowances[allowance_index].exempt_amtp = totalMonthly;
        }
        //console.log(allowances);
        setFieldValueProps('allowances', allowances);
        getTotalAllowanceAmtp(fieldValueProps, allowances, setFieldValueProps);
        handleClose(-1);
    }


    return (
        <div className="row">
            <div className="col-md-12 pt-1">
                <div id="myDIV" className="houserentallpopup">
                    <button
                        type="button"
                        className={houseRendType === 'YEAR' ? 'btn btnStyle hrallyearlybtn  activeBtn' : 'btn btnStyle hrallmonthbtn'}
                        onClick={() => {
                            changeHouseRentType('YEAR');
                        }}>Yearly</button>

                    <button type="button" className={houseRendType === 'MONTH' ? 'btn btnStyle hrallyearlybtn activeBtn' : 'btn btnStyle hrallmonthbtn'}
                        onClick={() => {
                            changeHouseRentType('MONTH');
                        }}>Monthly</button>
                </div>
            </div>

            {houseRentData && houseRendType === 'YEAR' ?
                <HouseRentYearForm
                    houseRentData={houseRentData}
                    saveData={saveData}
                />
                : null}


            {houseRentData && houseRendType === 'MONTH' ?
                <HouseRentMonthForm
                    houseRentData={houseRentData}
                    saveData={saveData}
                    totalMonthly={totalMonthly}
                    setTotalMonthly={setTotalMonthly}
                /> : null}

        </div>);
};

export default HouseRentAllowanceForm;