import React, { useEffect, useState } from 'react'
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { Tooltip } from '@progress/kendo-react-tooltip';
import eyeIcon from "../../../images/eyeiconcolr.png";
import EyeCloseIcon from "../../../images/eye_close_icon_coralclr.png";
import { deepClone, formatNumber } from '../../../utils/UtilityFunctions';
import { gocallApi } from '../../../api/issueCertificateAPI';
import { MONTH_NUMBER_TO_FULL_TEXT, MONTH_LIST } from '../../../app/constants';
import { Skeleton } from '@mui/material';
import { selectHeaderData } from '../../header/headerSlice';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import "../../deductorDashboard/returnsDashboardV2/ModalPopup.scss";
import DeducteeTransPopup from './DeducteeTransPopup';
import ShowAlert from '../../../components/ShowAlert';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';


const TdsMappingMonthWisePopup = (props) => {

    const { params, loading1,
        naturePaymentList,
        handleClose, show } = props;
    const headerData = useSelector(selectHeaderData);

    const [monthWiseList, setMonthWiseList] = useState([]);

    const [monthSummary, setMonthSummary] = useState(null);
    const [cursor, setCursor] = useState({});
    const [scrollPagnation, setScrollPagnation] = useState(false);
    const [dataState, setDataState] = useState({});

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [visibleSections, setVisibleSections] = useState(new Set());

    const dataStateChange = (data_state) => {
        setDataState(data_state);
    }

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem ?? {};
        const dataIndex = props.dataIndex ?? {};

        return (
            <tr class={`${dataIndex % 2 === 0 ? "mapping_deducttrans_row_bgclr_grey" : "mapping_deducttrans_row_bgclr_white"}`}
            >
                <td class="text-left">{MONTH_NUMBER_TO_FULL_TEXT[dataItem?.month ?? ""] ?? ""}</td>
                <td class="text-center">{dataItem?.display_code ?? ""}</td>
                <td class="mapping_deducttrans_textoverflow text-center">
                    <span >{dataItem?.dt_count ?? ""} </span>
                </td>
                <td class="text-center" style={{ paddingRight: '2rem' }}>{dataItem?.challan_count ?? ""}</td>
                <td class="text-right">
                    {formatNumber(dataItem?.total_paid_amtp ?? "")}
                </td>
                <td class="text-right">
                    {formatNumber(dataItem?.total_tds_amtp ?? "")}
                </td>
                <td class="text-center eyeicon_poit">
                    <img src={visibleSections.has(dataIndex) ? EyeCloseIcon : eyeIcon} width="16"
                        alt="sysicon" onClick={() => {
                            let newVisibleSections = new Set(visibleSections);
                                if (newVisibleSections.has(dataIndex)) {
                                    newVisibleSections.delete(dataIndex);
                                } else {
                                    newVisibleSections.add(dataIndex);
                                    if (newVisibleSections.size === 3) {
                                        const [firstItem] = newVisibleSections
                                        if(firstItem !== undefined) newVisibleSections.delete(firstItem);
                                    }
                                }
                                setVisibleSections(newVisibleSections)

                        }} />
                </td>
            </tr>
        )
    }

    

    const getTdsMappingMonthWise = async (params, data_state, isPagination, page) => {
        const paramDataState = deepClone(data_state);

        const url = `/api/v2/transaction/month-section-wise`;

        if (paramDataState?.sort?.[0]?.field === "nature_of_payment_id") {
            paramDataState.sort[0].field = "display_code";
        }

        const payload = {
            "deductor_id": params?.deductor_id ?? "",
            "return_id": params?.return_id ?? "",
            "sort_on": paramDataState?.sort?.[0]?.field ?? "",
            "sort_by": paramDataState?.sort?.[0]?.dir ?? "",
            "filters": paramDataState?.filter?.filters ?? "",
            "limit": 17,
            "page": page ?? 1,
        }

        if (isPagination) setShowMoreLoading(true);
        else setLoading(true);

        try {
            const result = await gocallApi("post", url, payload);
            // if (result?.data?.data?.length > 0) {
            //     result?.data?.data.forEach((item) => {
            //         item.trans_id = genRandNum(8);
            //     })
            // }
            setCursor((prev) =>     {
                prev = result?.data?.cursor ?? {};
                return { ...prev };
            });

            if (page > 1) {
                setMonthWiseList((prev) => {
                    prev = prev.concat(result?.data?.data ?? []);
                    return [...prev];
                })
                if (!monthSummary) {
                    setMonthSummary(result?.data?.summary ?? {});
                }
            } else {
                setMonthWiseList(result?.data?.data ?? []);
                setMonthSummary(result?.data?.summary ?? {});
            }

        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            if (isPagination) {
                setScrollPagnation(false);
                setShowMoreLoading(false);
            } else {
                setLoading(false);
            }

        }

    }

    function sortChange(field) {
        let new_sort = [];
        let dir = 'asc';
        if (dataState && dataState.sort) {
            new_sort = dataState.sort.filter((sort, index) => {
                if (sort.field === field) {
                    dir = sort.dir === 'asc' ? 'desc' : '';
                }
                return sort.field !== field;
            })
        }

        if (field) {
            new_sort = dir ? [{ field: field, dir: dir }] : false;
            dataState.sort = new_sort;

            dataStateChange({ ...dataState });
        }
    }

    const columnTitle = (title, key, field) => {
        const formatField = ["total_tds_amt", "total_paid_amt", "sum_paid_amtp", "sum_total_tds_amtp"];
        return (<div className="pb-2">
            <span onClick={() => sortChange(field)}>{title}</span>
            <div className={`challanstitlepos total_mapped_transactions`}
                style={key === "mapped_challan" ? { right: title === "Challan" ? "9px" : "0", width: "100%", textAlign: "center" } : {}}

            >
                <span title={title !== "Challan" ? title + ": " + formatNumber(monthSummary?.[key] ?? "0") : ""}>
                    {formatField.includes(key) ? formatNumber(monthSummary?.[key] ?? 0) : monthSummary?.[key]}
                </span>
            </div>
        </div>)
    }

    const getScrollbarHeight = (el) => {
        return el.scrollHeight
    };

    let cursor_temp = cursor;

    $(function () {
        $(".monthwisetrans .k-grid-content").scroll(function () {
            if (getScrollbarHeight(document.querySelector('.monthwisetrans .k-grid-content')) < $(".monthwisetrans .k-grid-content").scrollTop() + $(".monthwisetrans .k-grid-content").height() + 3) {

                if (!scrollPagnation && cursor_temp?.next_page && cursor_temp.next_page > 1) {
                    setScrollPagnation(true);
                }
            }
        });
    });

    useEffect(() => {
        getTdsMappingMonthWise(params, dataState);
        // eslint-disable-next-line
    }, [show, dataState]);

    useEffect(() => {
        if (cursor?.next_page > 1 && scrollPagnation) {
            getTdsMappingMonthWise(params, dataState, scrollPagnation, cursor.next_page);
        }
        // eslint-disable-next-line
    }, [scrollPagnation]);

    return (
        <>


            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />            

            {loading && Object.keys(dataState).length > 0 && <LoadingIndicator />}

            {Array.from(visibleSections)?.length > 0 &&
                Array.from(visibleSections).map((key, index) => {
                    const value = index + 1;
                    return (
                        <DeducteeTransPopup
                            handleClose={(popupIndex) => {
                                const newVisibleSections = new Set(visibleSections);
                                newVisibleSections.delete(key);
                                setVisibleSections(newVisibleSections);
                                
                            }}
                            ids={key}
                            month={monthWiseList?.[key]?.month}
                            params={params}
                            dataState={dataState}
                            setDataState={setDataState}
                            naturePaymentId={monthWiseList?.[key]?.nature_of_payment_id}
                            setScrollPagnation={setScrollPagnation}
                            scrollPagnation={scrollPagnation}
                            setError={setError}
                            totalPopups={index + 2}
                            index={value}
                            headerData={headerData}
                        />)
                })}

            <div className="monthwisedeductee transpopup">

                <WindowWrapperFixed
                    title="Month-Section Wise"
                    onClose={handleClose}
                    totalPopups={1}
                    index={0}
                    initialHeight={230}
                    initialWidth={1048}
                    style={{ maxHeight: '240px', overflowX: 'hidden' }}
                    className="returnfilingTdsMappingscss"

                >
                    {(!monthSummary) ?
                        <div style={{ display: "flex" }}>
                            {[...Array(6)].map((items) =>
                                <div className='col-md-2'>
                                    {[...Array(4)].map((items) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40px"} />)}
                                </div>
                            )}

                        </div>
                        :
                        <>
                            <div className="returnfilingTdsMappingscss">
                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                                    <Tooltip anchorElement="pointer" position="right">
                                        <Grid
                                            className={`table table-striped dash_blukpanstatus_tb mb-1 monthwisetrans`}
                                            data={monthWiseList}
                                            {...dataState}
                                            fixedScroll={true}
                                            onDataStateChange={(event) => dataStateChange(event.dataState)}
                                            filterOperators={{
                                                text: [
                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                ],
                                                dropdown: [
                                                    { text: 'grid.filterEqOperator', operator: 'in' },
                                                ],
                                                numeric: [
                                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                ],
                                                textWithEmpty: [
                                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                ],
                                                date: [
                                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                                ],
                                            }}
                                            rowRender={rowRender}
                                            sortable
                                        >

                                            <Column
                                                width="70"
                                                field="month"
                                                title="Month"
                                                filter="dropdown"
                                                columnMenu={((props) => <ColumnMenu hideSecondFilter filterList={MONTH_LIST} {...props} />)}
                                                headerClassName={isColumnActive('month') ? 'active' : ''}
                                            />

                                            <Column
                                                width="100"
                                                field="nature_of_payment_id"
                                                title="Sec"
                                                filter="dropdown"
                                                columnMenu={((props) => <ColumnMenu hideSecondFilter filterList={naturePaymentList} {...props} />)}
                                                headerClassName={isColumnActive('nature_of_payment_id') ? 'active' : ''}
                                            />

                                            <Column
                                                width="100"
                                                field="dt_count"
                                                title="Ded Trans."
                                                filter="numeric"
                                                columnMenu={((props) => <ColumnMenu  {...props} />)}
                                                headerClassName={isColumnActive('dt_count') ? 'active' : ''}
                                            />

                                            <Column
                                                width="70"
                                                field="challan_count"
                                                title={columnTitle("Challan", "mapped_challan", "challan_count")}
                                                filter="numeric"
                                                columnMenu={((props) => <ColumnMenu {...props} />)}
                                                headerClassName={isColumnActive('challan_count') ? 'active' : ''}
                                                sortable={false}

                                            />

                                            <Column
                                                width="100"
                                                field="total_paid_amtp"
                                                title={columnTitle("Amt Paid", "sum_paid_amtp", "total_paid_amtp")}
                                                filter="numeric"
                                                columnMenu={((props) => <ColumnMenu  {...props} />)}
                                                headerClassName={isColumnActive('total_paid_amtp') ? 'active' : ''}
                                                sortable={false}

                                            />

                                            <Column
                                                width="100"
                                                field="total_tds_amtp"
                                                title={columnTitle("TDS", "sum_total_tds_amtp", "total_tds_amtp")}
                                                filter="numeric"
                                                columnMenu={((props) => <ColumnMenu {...props} />)}
                                                headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                                                sortable={false}
                                            />

                                            <Column
                                                width="40"
                                                sortable={false}
                                                headerClassName="disablefiltercrsr"
                                            />

                                            {(monthWiseList?.length === 0 && !loading1) && (
                                                <GridNoRecords className="no_records">
                                                    No result found for the search Criteria.
                                                </GridNoRecords>
                                            )}

                                        </Grid>
                                    </Tooltip>

                                    {showMoreLoading && (
                                        <span style={{
                                            position: 'absolute',
                                            left: '50%',
                                            bottom: "2px",
                                            color: "gray"
                                        }}>

                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    )}
                                </div>
                            </div>
                        </>}
                </WindowWrapperFixed>
            </div>

        </>
    )
}

export default TdsMappingMonthWisePopup