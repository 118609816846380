/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Formik, Form,
} from 'formik';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import NumericInput from '../NumericInput';
import { gratuityValidationSchema } from './validationSchema';
import { getGratuityExemptAmt } from './allowancesCalculationScema';


const GratuityForm = (props) => {

    const { fieldValue, setFieldValue, allowance_index, handleClose, getTotalAllowanceAmtp } = props;
    const [gratuityData, setGratuityData] = useState(false);

    useEffect(() => {
        if (fieldValue.allowances[allowance_index].allowance_details) {
            setGratuityData(fieldValue.allowances[allowance_index].allowance_details);
        }
        else {
            setGratuityData({
                whether_gov_emp: 0,
                whether_gratuity_act_app: 0,
                gratuity_received_amtp: 0,
                service_period_year: 0,
                service_period_month: 0,
                last_month_salary_with_DA_amtp: 0,
                avg_salary_amtp: 0,
                whether_received_after_death: 0,
                exempt_amtp: 0,
            });
        }
    }, [fieldValue]);

    const saveData = (values) => {
        setGratuityData(values);
        let allowances = fieldValue.allowances;
        allowances[allowance_index].allowance_details = values;
        allowances[allowance_index].exempt_amtp = values.exempt_amtp;
        setFieldValue('allowances', allowances);
        getTotalAllowanceAmtp(fieldValue, allowances, setFieldValue);
        handleClose(-1);

    }

    const calculateExemptAmt = (field, value, values, setFieldValue) => {

        let old_value = values[field];
        values[field] = value;
        let exempt_amtp = getGratuityExemptAmt(values);
        setFieldValue('exempt_amtp', exempt_amtp);
        values[field] = old_value;

    }

    if (gratuityData) {
        return (<Formik
            initialValues={gratuityData}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={gratuityValidationSchema}
            onSubmit={(values) => {
                const submitValues = { ...values };
                saveData(submitValues);
            }}
        >
            {({
                errors, values, setFieldValue,
            }) => {
                if (Object.keys(errors).length > 0) {
                    console.log('Form Errors: ', errors);
                }
                return (
                    <Form key={1} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12 pt-2">
                                <div className="card allowance">
                                    <div className="table-responsive">
                                        <table className="table table-striped allowanceWSDTB">
                                            <thead>
                                                <tr>
                                                    <td style={{ width: '177px' }}>Whether Cent Govt Employ</td>
                                                    <td>
                                                        <select
                                                            name="whether_gov_emp"
                                                            className="custom-select slecttextfs"
                                                            value={values.whether_gov_emp}
                                                            onChange={(e) => {
                                                                setFieldValue('whether_gov_emp', parseInt(e.target.value));
                                                                calculateExemptAmt('whether_gov_emp', e.target.value, values, setFieldValue);
                                                            }}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </td>
                                                    <td style={{ width: '168px' }}>Whether Gratuity Act App</td>
                                                    <td>
                                                        <select
                                                            name="whether_gratuity_act_app"
                                                            className="custom-select slecttextfs"
                                                            value={values.whether_gratuity_act_app}
                                                            onChange={(e) => {
                                                                setFieldValue('whether_gratuity_act_app', parseInt(e.target.value));
                                                                calculateExemptAmt('whether_gratuity_act_app', e.target.value, values, setFieldValue);
                                                            }}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Gratuity Received</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.gratuity_received_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="gratuity_received_amtp"
                                                            value={values.gratuity_received_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('gratuity_received_amtp', e.target.value);
                                                                calculateExemptAmt('gratuity_received_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>Service Period</td>
                                                    <td>
                                                        <div className="year_input">
                                                            <label>Year</label>
                                                            <NumericInput
                                                                type="text"
                                                                className={errors.service_period_year ? "form-control error_input" : "form-control"}
                                                                placeholder="0"
                                                                name="service_period_year"
                                                                value={values.service_period_year}
                                                                onChange={(e) => {
                                                                    setFieldValue('service_period_year', e.target.value);
                                                                    calculateExemptAmt('service_period_year', e.target.value, values, setFieldValue);
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="month_input">
                                                            <label>Month</label>
                                                            <NumericInput
                                                                type="text"
                                                                className={errors.service_period_month ? "form-control error_input" : "form-control"}
                                                                placeholder="0"
                                                                name="service_period_month"
                                                                value={values.service_period_month}
                                                                onChange={(e) => {
                                                                    if (e.target.value === '' || parseFloat(e.target.value) <= 11) {
                                                                        setFieldValue('service_period_month', e.target.value);
                                                                        calculateExemptAmt('service_period_month', e.target.value, values, setFieldValue);
                                                                    }
                                                                }
                                                                }
                                                            />
                                                        </div>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Last Month Salary + DA</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.last_month_salary_with_DA_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="last_month_salary_with_DA_amtp"
                                                            value={values.last_month_salary_with_DA_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('last_month_salary_with_DA_amtp', e.target.value);
                                                                calculateExemptAmt('last_month_salary_with_DA_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>Avg. of 10 Months salary</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.avg_salary_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="avg_salary_amtp"
                                                            value={values.avg_salary_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('avg_salary_amtp', e.target.value)
                                                                calculateExemptAmt('avg_salary_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4">
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input
                                                                    name="whether_received_after_death"
                                                                    value="1"
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={parseInt(values.whether_received_after_death) === 1 ? true : false}
                                                                    onChange={() => {
                                                                        setFieldValue('whether_received_after_death', parseInt(values.whether_received_after_death) > 0 ? 0 : 1);
                                                                        calculateExemptAmt('whether_received_after_death', parseInt(values.whether_received_after_death) > 0 ? 0 : 1, values, setFieldValue);
                                                                    }
                                                                    }
                                                                /> <span className="WhetamttextVetalig">Whether Amount Received after death of employee</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Exempt Amt</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0.00"
                                                            name="exempt_amtp"
                                                            value={values?.exempt_amtp ? parseFloat(values.exempt_amtp).toFixed(2) : 0.00}
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="btn-group mt-2 mb-1">
                                    <button type="submit" className="btn btn-default monthwsdetilssavebtn">Save & Exit</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
        );
    }
    else {
        return false;
    }
};

export default GratuityForm;