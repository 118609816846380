import React, { Component } from "react";
import ReactApexChart from 'react-apexcharts';
import NODataImg from '../../../images/docu_icons.png';
import AddButton from '../../../images/icons/add_btn_round.svg';

class StackedBarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: props.data,
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false, // Set to false for vertical bars                        
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: props.monthLabel,
                },
                annotations: props.annotation,
                yaxis: {
                    title: {
                        text: 'Total Liability'
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val; // + "K"
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    offsetX: 0
                }
            },


        };
    }
    render() {
        return (
            <>
                <legend className="dash_quarterliability_lg">
                    <h4 className="dash_quarterliability_tital">
                        Section Wise Monthly Liability
                    </h4>
                </legend>
                <div id="chart" style={{ top: 0 }}>
                    {this.props.data && this.props.data.length > 0 ?
                        <ReactApexChart
                            height={"310px"}
                            width={"700px"}
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                        />
                        :
                        <div className="container mt-2">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="browserbutton_box browser_sectionwisemonth_chartbgimg">
                                        
                                    </div>
                                </div>
                                <div className="col-md-8 text-center">
                                    <div className="browserbutton_box mt-4">
                                        <div className="clickaablebtn_box pt-3">
                                            <span className="clickablebtn" style={{display:'ruby-text'}}>
                                                <img src={NODataImg} alt="No data" width="100" onClick={() => this.props.setStartFilingPopup(true)} />
                                            </span>
                                            <p className="No_Records_to_Display_textfs noredords_datainner mb-2" style={{display:'flex', position:'relative', paddingLeft:'8rem', width:'100%'}}>
                                                No Records to Display
                                            </p>
                                            <p className="No_Records_to_Display_textfs noredords_databottom" onClick={() => this.props.setStartFilingPopup(true)}  style={{display:'flex', position:'relative', paddingLeft:'4.4rem', width:'100%'}}>
                                            To Create/Import Return use <span className="px-1"><img src={AddButton} alt="" width={'16px'} style={{cursor: 'pointer'}} /></span> button
                                            </p>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                        </div>
                    }
                </div>
            </>

        );
    }
}

export default StackedBarChart;