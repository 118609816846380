import axios from 'axios';
import { BASE_URL_GO } from '../app/constants';
import { handleSuccess, handleError, handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';

export async function importForSalaryCalculator(data) {
    const url = `${BASE_URL_GO}/api/v1/salary-tds-calculator/import`;
  
    try {
      const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
      const result = await axios.post(url, data, config);
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function downloadDefaultSalaryTemplate() {
    const url = `${BASE_URL_GO}/api/v1/salary-tds-calculator/download-template`;
  
    try {
      const config = { ...getConfig() };
      config.responseType = "blob";
      const result = await axios.get(url, config);
      return result;
    } catch (err) {
      throw handleError(err);
    }
  }

  export async function downloadSalaryTdsCalculatorData(params) {
    const url = `${BASE_URL_GO}/api/v1/salary-tds-calculator/export${params}`;
    try {
      const config = { ...getConfig() };
      const result = await axios.get(url, config);
      return result.data;
    } catch (err) {
      throw handleError(err);
    }
  }

  export async function getDeducteeListToSendMail(data) {
    const url = `${BASE_URL_GO}/api/v1/salary-tds-calculator/email/list`;
    try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
      return handleSuccess(result.data);
    } catch (err) {
      throw handleError(err);
    }
  
  }

  export async function getDeducteeSummaryToSendMail(data) {
    const url = `${BASE_URL_GO}/api/v1/salary-tds-calculator/email/summary`;
    try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
      return handleSuccess(result.data);
    } catch (err) {
      throw handleError(err);
    }
  
  }
  
  export async function importDeducteesSalaryTdsCalculator(data) {
    const url = `${BASE_URL_GO}/api/v1/salary-tds-calculator/contact/import`;
  
    try {
      const config = { ...getConfig(), "Content-Type": "multipart/form-data" };
      const result = await axios.post(url, data, config);
      if (Number(result.data.status) !== 1) {
        throw result.data.message;
      }
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function downloadDeducteesSalaryTdsCalculator(params) {
    const url = `${BASE_URL_GO}/api/v1/salary-tds-calculator/contact/export${params}`;
    try {
      const config = { ...getConfig() };
      const result = await axios.get(url, config);
      return result.data;
    } catch (err) {
      throw handleError(err);
    }
  }

  export async function sendSalaryTdsCalculatorEmailtoDeductee(data) {
    const url = `${BASE_URL_GO}/api/v1/salary-tds-calculator/email`;
  
    try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return handleSuccess(result.data);
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }