import React from 'react';
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { FormatNumberDecimal, FormatNumberDecValue1, tdsReturnValidNumber } from '../../../utils/UtilityFunctions';


const FixedInput = (props) => {
    const { data, handleObjInputChange, handleObjNumberInputOnBlur, relief89usErr } = props;
    return (
        <>
            <tr className="trrow_bgclr_purple">
                <th width="10" className="text-left accordion">

                </th>
                <th width="550" className="text-left">
                    Net Taxable Income
                </th>
                <th width="120" className="text-right">
                    {FormatNumberDecimal(data?.net_taxable_income?.total_net_taxable_income_amtp ?? 0, ...Array(3))}
                </th>
            </tr>
            <tr className="trrow_bgclr_purple">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse collapsed" data-toggle="collapse" href="#salarytaxregime_edit_nettaxcollap02" aria-expanded="false"></span>

                </th>
                <th width="550" className="text-left">
                    Total tax
                </th>
                <th width="120" className="text-right">
                    {FormatNumberDecimal(data?.net_taxable_income?.total_tax_computed_amtp ?? 0, ...Array(3))}
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_nettaxcollap02" className="in collapse">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>
                                    <tr className="">
                                        <td width="10"></td>
                                        <td width="">
                                            Income Tax
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={FormatNumberDecimal(data?.net_taxable_income?.tax_computed?.income_tax_amtp ?? 0, ...Array(3))} data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                disabled
                                            />
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="">
                                            Rebate U/s 87
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={FormatNumberDecimal(data?.net_taxable_income?.tax_computed?.rebate_us_87_a_amtp ?? 0, ...Array(3))}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                disabled

                                            />
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="">
                                            Surcharge
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={FormatNumberDecimal(data?.net_taxable_income?.tax_computed?.surcharge_amtp ?? 0, ...Array(3))}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                disabled

                                            />
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Health & Education Cess
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={FormatNumberDecimal(data?.net_taxable_income?.tax_computed?.health_edu_cess_amtp ?? 0, ...Array(3))}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                disabled
                                            />
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Less:  Relief 89
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox text-right ${relief89usErr ? "input-error" : ""}`}
                                                value={FormatNumberDecimal(data?.net_taxable_income?.relief_u_s_89_amtp ?? 0, ...Array(3))}
                                                data-toggle="tooltip" data-placement="left" data-original-title={relief89usErr ? relief89usErr : ""}
                                                placeholder=""
                                                onChange={(e) => {
                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                    if (!tdsReturnValidNumber(value)) return;

                                                    handleObjInputChange("net_taxable_income", "relief_u_s_89_amtp", value)
                                                }}
                                                onBlur={(e) => {
                                                    if (!tdsReturnValidNumber(data?.net_taxable_income?.relief_u_s_89_amtp)) return;

                                                    handleObjNumberInputOnBlur("net_taxable_income", "relief_u_s_89_amtp", data?.net_taxable_income?.relief_u_s_89_amtp);
                                                }}
                                            />
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Less:  TDS deducted by Previous Employer
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={FormatNumberDecimal(data?.previous_employer_tds_amtp ?? 0, ...Array(3))}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                disabled
                                            />
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Total Tax
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                value={FormatNumberDecimal(data?.net_taxable_income?.total_tax_computed_amtp ?? 0, ...Array(3))}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Less:  TDS deducted by Current Employer
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                value={FormatNumberDecimal(data?.net_taxable_income?.tds_ded_by_current_emp_amtp ?? 0, ...Array(3))}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                onChange={(e) => {
                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                    handleObjInputChange("net_taxable_income", "tds_ded_by_current_emp_amtp", value)
                                                }}
                                                onBlur={(e) => handleObjNumberInputOnBlur("net_taxable_income", "tds_ded_by_current_emp_amtp", data?.net_taxable_income?.tds_ded_by_current_emp_amtp)}
                                            />
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            {data?.net_taxable_income?.excess_short_tds_amtp < 0 && <span className="staxtextcoral">Shortfall</span>}
                                            {data?.net_taxable_income?.excess_short_tds_amtp >= 0 && <span className="staxtextblue">Excess</span>}
                                        </td>
                                        <td colspan="" className="text-right">
                                            <BlurOnChangeInput
                                                type="text"
                                                className={`form-control salarytaxregime_edit_inputbox text-right ${data?.net_taxable_income?.excess_short_tds_amtp < 0 ? "staxtextcoral" : "staxtextblue"}`}
                                                value={FormatNumberDecimal(data?.net_taxable_income?.excess_short_tds_amtp ?? 0, ...Array(3))}
                                                data-toggle="tooltip" data-placement="top" title={""}
                                                placeholder=""
                                                onChange={(e) => {
                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                    handleObjInputChange("net_taxable_income", "excess_short_tds_amtp", value)
                                                }}
                                                onBlur={(e) => handleObjNumberInputOnBlur("net_taxable_income", "excess_short_tds_amtp", data?.net_taxable_income?.excess_short_tds_amtp)}

                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default FixedInput