import React from 'react'

const Deduction = (props) => {
    const { data } = props
    return (

        <>


            <div className="row pb-2"  >
                <div className="col-md-12 pt-2">
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <table className="table table-borderless expit_comp_personalinfo_salaryincome_tb mb-0 tr-add-hover">
                            <tbody>
                                {/* {(data?.TotalOfHeadWiseIncomes ?? null) !== null &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Total Of Head Wise Incomes</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.TotalOfHeadWiseIncomes)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.CurrentYearLossesAdjustment ?? null) !== null &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Current Year Losses Adjustment</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.CurrentYearLossesAdjustment)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.BalanceAfterCYLA ?? null) !== null &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Balance After CYLA</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.BalanceAfterCYLA)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.BroughtForwardLossesAdjustment ?? null) !== null &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Brought Forward Loss Set-off	</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.BroughtForwardLossesAdjustment)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                } */}
                                {(data?.GrossTotalIncome ?? null) !== null &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Gross Total Income</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.GrossTotalIncome)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* <!-- DEDUCTIONS UNDER (CHAPTER VI-A) --> */}
            {(data?.DeductionsUnderChapterVIA) &&

                <div className="row" >
                    <div className="col-md-12 pt-2">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <table className="table table-borderless expit_comp_personalinfo_salaryincome_tb mb-0 tr-add-hover">
                                <tbody>
                                    <tr className="expit_comp_personalinfo_salaryboxbgclr">
                                        <td width="475" colSpan="" className="expit_comp_personalinfo_salaryboxbgclr"> DEDUCTIONS UNDER CHAPTER VI-A </td>
                                        <td width="100" className="text-right"></td>
                                        <td width="100" className="text-right"></td>
                                        <td width="100" className="text-right"></td>
                                        <td width="150" className="text-right">{(data?.DeductionsUnderChapterVIA?.Amount)?.toLocaleString('hi-IN')}</td>
                                    </tr>


                                    {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C ?? null) !== null &&
                                        <>

                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.lic_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">Life insurance premium paid </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.lic_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.ctpf_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">Contribution toward provident fund / PPF </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.ctpf_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.ppf_amt ?? null) > 0 &&

                                                <tr>
                                                    <td width="" className="pl-4">Public provident fund (PPF) </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.ppf_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.inv_nsc_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">Investment in NSC (VIII issue) + Interest </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.inv_nsc_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.tfp_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">Tuition fees paid for children </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.tfp_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.rhl_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">Repayment of housing loan (principle component) </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.rhl_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.ssy_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">Sukanya Samridhi Amount</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.ssy_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.ctnpf_mf_uti_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">Contribution toward notified pension fund by MF/UTI </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.ctnpf_mf_uti_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.stamp_duty_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">Stamp duty </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.stamp_duty_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.fd_5y_amt ?? null) > 0 &&
                                                <tr>
                                                    <td width="" className="pl-4">5 Years fixed deposit with PO or Schedule Bank</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.fd_5y_amt)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }

                                            {data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.oth_det && data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.oth_det.length > 0 &&
                                                data?.DeductionsUnderChapterVIA?.Items?.DetailsUs80C?.oth_det.map((otherItem, i) => {
                                                    return (<>

                                                        {otherItem?.p_amt &&
                                                            <tr className="">
                                                                <td width="" className="pl-4">{(otherItem?.part)} </td>
                                                                <td width=""></td>
                                                                <td width="" className="text-right">{(otherItem?.p_amt)?.toLocaleString('hi-IN')}</td>
                                                                <td width=""></td>
                                                                <td width=""></td>
                                                            </tr>
                                                        }
                                                    </>)
                                                })
                                            }

                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td className="">Total 80C Deductions</td>
                                                <td width=""></td>
                                                <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80C)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td className="">Eligible Deductions 80C</td>
                                                <td width=""></td><td width=""></td>
                                                <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80C ?? 0)?.toLocaleString('hi-IN')}</td>

                                                <td width=""></td>
                                            </tr>



                                        </>}



                                    {(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCC ?? null) !== null &&
                                        <tr>
                                            <td width="" className="pl-4">80CCC </td>
                                            <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCC ?? null) !== null &&
                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions 80Ccc </td>
                                            <td width=""></td> <td width=""></td>
                                            <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCC)?.toLocaleString('hi-IN')}</td>

                                            <td width=""></td>
                                        </tr>
                                    }


                                    {(data?.DeductionsUnderChapterVIA?.Items?.EmpAmtUs80CCD_1 ?? null) !== null && (data?.DeductionsUnderChapterVIA?.Items?.SelfAmtUs80CCD_1 ?? null) !== null &&
                                        <tr>
                                            <td width="" className="pl-4">80CCD(1) </td>
                                            <td width="" className="text-right ">{((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCD_1 ?? 0))?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.DeductionsUnderChapterVIA?.Items?.EmpAmtUs80CCD_1 ?? null) !== null &&

                                        <tr>
                                            <td width="" className="pl-4">Employee's contribution towards NPS	 </td>
                                            <td width="" className="text-right ">{((data?.DeductionsUnderChapterVIA?.Items?.EmpAmtUs80CCD_1 ?? 0))?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.DeductionsUnderChapterVIA?.Items?.SelfAmtUs80CCD_1 ?? null) !== null &&

                                        <tr>
                                            <td width="" className="pl-4">Self contribution towards NPS		 </td>
                                            <td width="" className="text-right ">{((data?.DeductionsUnderChapterVIA?.Items?.SelfAmtUs80CCD_1 ?? 0))?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCD_1 ?? null) !== null &&


                                        <tr className="expit_comp_personalinfo_textbold">
                                            <td className="">Eligible Deductions 80CCD1</td>
                                            <td width=""></td><td width=""></td>
                                            <td width="" className="text-right ">{((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCD_1 ?? 0))?.toLocaleString('hi-IN')}</td>

                                            <td width=""></td>
                                        </tr>
                                    }



                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCD_1B ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80CCD(1B)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCD_1B)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCD_1B ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80CCD(1B)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCD_1B)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCD_2 ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80CCD(2)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCD_2)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCD_2 ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80CCD(2)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCD_2)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCH ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s  80CCH (Agnipath Scheme)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCH)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCH ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s  80CCH (Agnipath Scheme)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCH)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.ParentYesDetailsUs80D ?? null) !== null || (data?.DeductionsUnderChapterVIA?.Items?.ParentNoDetailsUs80D ?? null) !== null || (data?.DeductionsUnderChapterVIA?.Items?.SelfYesDetailsUs80D ?? null) !== null || (data?.DeductionsUnderChapterVIA?.Items?.SelfNoDetailsUs80D ?? null) !== null) && <>

                                        {((data?.DeductionsUnderChapterVIA?.Items?.SelfNoDetailsUs80D ?? null) !== null) && <>

                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td className="">Medical Insurance Premium u/s 80D (Self & Family)</td>
                                                <td width="" className="text-right ">{((data?.DeductionsUnderChapterVIA?.Items?.SelfNoGrossAmtUs80D ?? 0))?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            {((data?.DeductionsUnderChapterVIA?.Items?.SelfNoDetailsUs80D.HI ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Health Insurance </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.SelfNoDetailsUs80D.HI)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }

                                            {((data?.DeductionsUnderChapterVIA?.Items?.SelfNoDetailsUs80D.PHC ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Preventive Health Checkup</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.SelfNoDetailsUs80D.PHC)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }


                                        </>}


                                        {((data?.DeductionsUnderChapterVIA?.Items?.SelfYesDetailsUs80D ?? null) !== null) && <>

                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td className="">Medical Insurance Premium u/s 80D (Including Senior Citizen)</td>
                                                <td width="" className="text-right ">{((data?.DeductionsUnderChapterVIA?.Items?.SelfYesGrossAmtUs80D ?? 0))?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            {((data?.DeductionsUnderChapterVIA?.Items?.SelfYesDetailsUs80D.HI ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Health Insurance </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.SelfYesDetailsUs80D.HI)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }

                                            {((data?.DeductionsUnderChapterVIA?.Items?.SelfYesDetailsUs80D.PHC ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Preventive Health Checkup</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.SelfYesDetailsUs80D.PHC)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.SelfYesDetailsUs80D.ME ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Medical Expenditure</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.SelfYesDetailsUs80D.ME)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }

                                        </>}


                                        {((data?.DeductionsUnderChapterVIA?.Items?.ParentNoDetailsUs80D ?? null) !== null) && <>

                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td className="">Medical Insurance Premium u/s 80D (Parents)</td>
                                                <td width="" className="text-right ">{((data?.DeductionsUnderChapterVIA?.Items?.ParentNoGrossAmtUs80D ?? 0))?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            {((data?.DeductionsUnderChapterVIA?.Items?.ParentNoDetailsUs80D.HI ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Health Insurance </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.ParentNoDetailsUs80D.HI)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }

                                            {((data?.DeductionsUnderChapterVIA?.Items?.ParentNoDetailsUs80D.PHC ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Preventive Health Checkup</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.ParentNoDetailsUs80D.PHC)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }


                                        </>}




                                        {((data?.DeductionsUnderChapterVIA?.Items?.ParentYesDetailsUs80D ?? null) !== null) && <>

                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td className="">Medical Insurance Premium u/s 80D (Parents Including Senior Citizen)</td>
                                                <td width="" className="text-right ">{((data?.DeductionsUnderChapterVIA?.Items?.ParentYesGrossAmtUs80D ?? 0))?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>

                                            {((data?.DeductionsUnderChapterVIA?.Items?.ParentYesDetailsUs80D.HI ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Health Insurance </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.ParentYesDetailsUs80D.HI)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }

                                            {((data?.DeductionsUnderChapterVIA?.Items?.ParentYesDetailsUs80D.PHC ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Preventive Health Checkup</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.ParentYesDetailsUs80D.PHC)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.ParentYesDetailsUs80D.ME ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Medical Expenditure</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.ParentYesDetailsUs80D.ME)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }

                                        </>}

                                        <tr className="expit_comp_personalinfo_textbold">
                                            <td className="">Total Deductions u/s 80D	</td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80D)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>

                                        <tr className="expit_comp_personalinfo_textbold">
                                            <td className="">Eligible Deductions u/s 80D (Self &amp; Family)</td>
                                            <td width=""></td><td width=""></td>
                                            <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80D)?.toLocaleString('hi-IN')}</td>

                                            <td width=""></td>
                                        </tr>
                                    </>}

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80DD ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80DD (Medical Treatment Handicap Dependents)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80DD)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80DD ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80DD</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80DD)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80DDB ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80DDB (Medical treatment of specified disease)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80DDB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80DDB ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80DDB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80DDB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80E ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80E (Interest on loan taken for education)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80E)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80E ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80E</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80E)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80EE ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80EE (Deduction in respect of interest on loan taken for residential house property)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80EE)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80EE ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80EE</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80EE)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80EEA ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80EEA (interest on loan taken for residential house property</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80EEA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80EEA ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80EEA</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80EEA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80EEB ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80EEB (interest on loan taken for purchase of electric vehicle</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80EEB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80EEB ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80EEB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80EEB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80G && <>
                                        <tr className="expit_comp_personalinfo_textbold">
                                            <td width="" >Deductions u/s 80G	 </td>
                                            <td width="" className="text-right "></td>
                                            <td width=""></td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>

                                        {(((data?.DeductionsUnderChapterVIA?.Items?.DEF100PDWOQL_CashGrossAmtUs80G ?? null) !== null) || ((data?.DeductionsUnderChapterVIA?.Items?.DEF100PDWOQL_OthGrossAmtUs80G ?? null) !== null)) && <>
                                            <tr className="">
                                                <td width="" className="text-left" colSpan={5}>Donations entitled for 100% deduction without qualifying limit</td>
                                            </tr>

                                            {((data?.DeductionsUnderChapterVIA?.Items?.DEF100PDWOQL_CashGrossAmtUs80G ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Donation in Cash</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DEF100PDWOQL_CashGrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.DEF100PDWOQL_OthGrossAmtUs80G ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Donation in Other Mode</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DEF100PDWOQL_OthGrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                        </>
                                        }


                                        {(((data?.DeductionsUnderChapterVIA?.Items?.DEF50PDWOQL_CashGrossAmtUs80G ?? null) !== null) || ((data?.DeductionsUnderChapterVIA?.Items?.DEF50PDWOQL_OthGrossAmtUs80G ?? null) !== null)) && <>
                                            <tr className="">
                                                <td width="" className="text-left" colSpan={5}>Donations entitled for 50% deduction without qualifying limit"</td>
                                            </tr>

                                            {((data?.DeductionsUnderChapterVIA?.Items?.DEF50PDWOQL_CashGrossAmtUs80G ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Donation in Cash</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DEF50PDWOQL_CashGrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.DEF50PDWOQL_OthGrossAmtUs80G ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Donation in Other Mode</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DEF50PDWOQL_OthGrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                        </>
                                        }

                                        {(((data?.DeductionsUnderChapterVIA?.Items?.DEF100PDSTQL_CashGrossAmtUs80G ?? null) !== null) || ((data?.DeductionsUnderChapterVIA?.Items?.DEF100PDSTQL_OthGrossAmtUs80G ?? null) !== null)) && <>
                                            <tr className="">
                                                <td width="" className="text-left" colSpan={5}>Donations entitled for 100% deduction subject to qualifying limit</td>
                                            </tr>

                                            {((data?.DeductionsUnderChapterVIA?.Items?.DEF100PDSTQL_CashGrossAmtUs80G ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Donation in Cash</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DEF100PDSTQL_CashGrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.DEF100PDSTQL_OthGrossAmtUs80G ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Donation in Other Mode</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DEF100PDSTQL_OthGrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                        </>
                                        }

                                        {(((data?.DeductionsUnderChapterVIA?.Items?.DEF50PDSTQL_CashGrossAmtUs80G ?? null) !== null) || ((data?.DeductionsUnderChapterVIA?.Items?.DEF50PDSTQL_OthGrossAmtUs80G ?? null) !== null)) && <>
                                            <tr className="">
                                                <td width="" className="text-left" colSpan={5}>Donations entitled for 50% deduction subject to qualifying limit</td>
                                            </tr>

                                            {((data?.DeductionsUnderChapterVIA?.Items?.DEF50PDSTQL_CashGrossAmtUs80G ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Donation in Cash</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DEF50PDSTQL_CashGrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.DEF50PDSTQL_OthGrossAmtUs80G ?? null) !== null) &&
                                                <tr >
                                                    <td width="" className="pl-4">Donation in Other Mode</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.DEF50PDSTQL_OthGrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                        </>
                                        }

                                        <tr className="expit_comp_personalinfo_textbold">
                                            <td className="">Total Deductions u/s 80G   </td>
                                            <td width=""></td>
                                            <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80G)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                            <td width=""></td>
                                        </tr>

                                        <tr className="expit_comp_personalinfo_textbold">
                                            <td className="">Eligible Deductions u/s 80G </td>
                                            <td width=""></td> <td width=""></td>
                                            <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80G)?.toLocaleString('hi-IN')}</td>

                                            <td width=""></td>
                                        </tr>



                                    </>}





                                    {(data?.DeductionsUnderChapterVIA?.Items?.RendPaidUs80GG ?? null) !== null &&
                                        <>
                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td width="" >Deductions u/s 80GG	 </td>
                                                <td width="" className="text-right "></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            {((data?.DeductionsUnderChapterVIA?.Items?.MonthsUs80GG ?? null) !== null) &&

                                                <tr >
                                                    <td width="" >No of Months </td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.MonthsUs80GG)}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.RendPaidUs80GG ?? null) !== null) &&

                                                <tr >
                                                    <td width="" >Rent Paid</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.RendPaidUs80GG)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                </tr>
                                            }

                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td className="">Eligible Deductions u/s 80GG  </td>
                                                <td width=""></td>                                                <td width="" className="text-right"></td>

                                                <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80GG)?.toLocaleString('hi-IN')}</td>

                                                <td width=""></td>
                                            </tr>
                                        </>
                                    }


                                    {(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80GGA ?? null) !== null &&
                                        <>
                                            <tr className="expit_comp_personalinfo_textbold">
                                                <td width="" >Deductions u/s 80GGA	 </td>
                                                <td width="" className="text-right "></td>
                                                <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80GGA)?.toLocaleString('hi-IN')}</td>
                                                <td width=""></td>
                                                <td width=""></td>
                                            </tr>
                                            {((data?.DeductionsUnderChapterVIA?.Items?.CashGrossAmtUs80GGA ?? null) !== null) &&

                                                <tr className="">
                                                    <td width="" className="pl-4">Donation in Cash</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.CashGrossAmtUs80GGA)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>

                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.OthGrossAmtUs80GGA ?? null) !== null) &&

                                                <tr className="">
                                                    <td width="" className="pl-4">Donation in Other Mode</td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.OthGrossAmtUs80GGA)?.toLocaleString('hi-IN')}</td>
                                                    <td width=""></td>
                                                    <td width=""></td>
                                                    <td width=""></td>

                                                </tr>
                                            }
                                            {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80GGA ?? null) !== null) &&
                                                <tr className="expit_comp_personalinfo_textbold">
                                                    <td className="">Eligible Deductions u/s 80GGA </td>
                                                    <td width=""></td><td width=""></td>
                                                    <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80GGA)?.toLocaleString('hi-IN')}</td>

                                                    <td width=""></td>
                                                </tr>

                                            }

                                        </>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80GGB ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80GGB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80GGB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80GGB ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80GGB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80GGB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80GGC ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80GGC (Donation to Political Party)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80GGC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80GGC ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80GGC</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80GGC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IA ?? null) > 0) &&
                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80-IA </td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IA ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80-IA</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right ">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IAB ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80IAB (Profit from development of SEZ)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IAB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IAB ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80IAB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IAB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IAC ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80IAC (Deduction of profits for start ups.)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IAC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IAC ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80IAC</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IAC)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IB ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80-IB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IB ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80-IB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IBA ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80IBA (Profit derived from the business of developing and building housing projects)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IBA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IBA ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80IBA</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IBA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IC_IE ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80-IC or 80-IE</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80IC_IE)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IC_IE ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80-IC or 80-IE</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80IC_IE)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80JJA ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80JJA (Profits from business of bio-degradable waste)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80JJA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80JJA ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80JJA</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80JJA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80JJAA ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80JJAA (Employment of new workmen)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80JJAA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80JJAA ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80JJAA</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80JJAA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80LA_1 ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80LA(1) (Certain income of Offshore Banking Units)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80LA_1)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80LA_1 ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80LA(1)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80LA_1)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80LA_1A ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80LA(1A) (Certain income of International Financial Services Centre)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80LA_1A)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80LA_1A ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80LA(1A)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80LA_1A)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80M ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80M (Deduction in respect of certain inter-corporate dividends)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80M)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80M ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80M</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80M)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Deduction for Co-operative Societies u/s 80P</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }




                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AI ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(a)(i) Banking/Credit Facilities to its members</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AI)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AII ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(a)(ii) Cottage Industry</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AII)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AIII ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(a)(iii) Marketing of Agricultural produce grown by its members"</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AIII)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AIV ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(a)(iv) Purchase of Agricultural Implements, seeds, live-stocks or other articles intended for agriculture for the purpose of supplying to its members.</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AIV)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AV ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(a)(v) Processing , without the aid of power, of the agricultural Produce of its members.</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AV)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AVI ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(a)(vi) Collective disposal of Labour of its members</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AVI)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AVII ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(a)(vii) Fishing or allied activities for the purpose of supplying to its members</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2AVII)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2B ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(b)Primary cooperative society engaged in supplying Milk, oilseeds, fruits or vegetables raised or
                                                grown by its members to Federal cooperative society engaged in supplying Milk, oilseeds, fruits or vegetables/Government or
                                                local authority/Government Company / corporation established by or under a Central, State or Provincial Act</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2B)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2CI ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(c)(i)Consumer Cooperative Society Other than specified in 80P(2a) or 80P(2b)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2CI)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2CII ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(c)(ii)Other Cooperative Society engaged in activities Other than specified in 80P(2a) or 80P(2b)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2CII)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2D ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(d)Interest/Dividend from Investment in other co-operative society</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2D)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2E ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(e)Income from Letting of godowns/ warehouses for storage, processing / facilitating the marketing of commodities</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2E)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }



                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2F ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Sec.80P(2)(f)Others</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80P_2F)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80P ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80P</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80P)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80PA ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80PA (Deduction in respect of certain income of producer companies)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80PA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80PA ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80PA</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80PA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }

                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80QQB ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80QQB (Royalty income etc of authors)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80QQB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80QQB ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80QQB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80QQB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80RRB ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80RRB (Royalty on patents)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80RRB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80RRB ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80RRB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80RRB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }


                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80TTA ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80TTA (interest on deposit in saving account)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80TTA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80TTA ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80TTA</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80TTA)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80TTB ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80TTB (interest on deposit in saving account)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80TTB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80TTB ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80TTB</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80TTB)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80U ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions u/s 80U (Permanent physical disability)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80U)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80U ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions u/s 80U</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80U)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80OTH ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deductions Under Chapter VI-A (Others)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80OTH)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80OTH ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deductions Under Chapter VI-A (Others)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80OTH)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCE ?? null) > 0) &&

                                        <tr>
                                            <td width="" className="pl-4">Total Deduction u/s 80C/80CCC/80CCD(1)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.GrossAmtUs80CCE)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                    {((data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCE ?? null) !== null) &&

                                        <tr>
                                            <td width="" className="pl-4">Eligible Deduction u/s 80C/80CCC/80CCD(1)</td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right"></td>
                                            <td width="" className="text-right">{(data?.DeductionsUnderChapterVIA?.Items?.EligibleAmtUs80CCE)?.toLocaleString('hi-IN')}</td>
                                            <td width=""></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {/* <!-- end DEDUCTIONS UNDER (CHAPTER VI-A) --> */}

            <div className="row pb-2"  >
                <div className="col-md-12 pt-2">
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                        <table className="table table-borderless expit_comp_personalinfo_salaryincome_tb mb-0 tr-add-hover">
                            <tbody>
                                {(data?.DeductionUS10AA ?? null) !== null && data?.DeductionUS10AA > 0 &&
                                    <tr>
                                        <td width="" className=" expit_comp_personalinfo_textbold">Deduction US 10AA</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.DeductionUS10AA)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.TotalIncome ?? null) !== null &&
                                    <tr>
                                        <td width="" className=" expit_comp_personalinfo_textbold">Total Income</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.TotalIncome)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.TotalIncomeRounded288A ?? null) !== null &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Total Income Rounded 288A</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.TotalIncomeRounded288A)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.AgriculturalIncomeForRatePurpose ?? null) !== null && data?.AgriculturalIncomeForRatePurpose > 0 &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Agricultural Income For Rate Purpose</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.AgriculturalIncomeForRatePurpose)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.CurrentYearLossesCF ?? null) !== null && data?.CurrentYearLossesCF > 0 &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Current Year Losses CF</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.CurrentYearLossesCF)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.DeemedIncomeUS115JB ?? null) !== null && data?.DeemedIncomeUS115JB > 0 &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Deemed Income US 115JB</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.DeemedIncomeUS115JB)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                                {(data?.DeemedIncomeUS115JC ?? null) !== null && data?.DeemedIncomeUS115JC > 0 &&
                                    <tr>
                                        <td width="" className="expit_comp_personalinfo_textbold">Deemed Income US 115JC</td>
                                        <td width=""></td>
                                        <td width="" className="text-right ">{(data?.DeemedIncomeUS115JC)?.toLocaleString('hi-IN')}</td>
                                        <td width=""></td>
                                        <td width=""></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>




        </>

    )
}

export default Deduction