/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import NumericInputWithHundredDecimalTwo from './NumericInputWithHundredDecimalTwo';

const TdsRateInput = (props) => {
  const { value, onChange, placeholder, data, errors=null, onBlur } = props;
  return (
    <div style={{ position: 'relative' }}>
      <NumericInputWithHundredDecimalTwo
        type="input"
        className={`form-control ${errors ? 'input-error1' : ''}`}
        placeholder={placeholder ?? 'Enter TDS Rate'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => {
          if(onBlur) onBlur(e.target.value)
        }}
      />
      <Dropdown style={{ position: 'absolute', top: '0px', right: '0px' }}>
        <Dropdown.Toggle tabIndex={-1}
          style={{ backgroundColor: 'transparent', color: 'black', border: 'none' }}
        />

        <Dropdown.Menu drop="left" style={{marginTop:'-6px'}}>
          {data.map((item) => (
            <Dropdown.Item
              style={{ fontSize: '12px', paddingLeft: '8px' }}
              onSelect={(e) => onChange(item)}
              key={item}
            >
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TdsRateInput;
