import { useEffect, useCallback, useState } from "react";

const useContextMenu = outerRef => {
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [menu, showMenu] = useState(false);
  const [columnIndex, setColumnIndex] = useState(false);

  //const [menu1, setMenu1] = useState(null);

  const handleContextMenu = useCallback(
    event => {
      event.preventDefault();
      
      //const pane = outerRef.current.getBoundingClientRect();

      if (outerRef && outerRef.current.contains(event.target) && event.target.id !== '') {
        
        // if(event.pageY > pane.height){
        //   setYPos(`${event.pageY - 200}px`);
        // }else{
        //   setYPos(`${event.pageY - 100}px`);
        // }

        setYPos(`${event.pageY - 120}px`);
        
        //setXPos(`${180}px`);
        //setYPos(`${120}px`);
        
        //setYPos(`${event.pageY}px`);
        setXPos(`${event.pageX}px`);
        setColumnIndex(event.target.id);
        showMenu(true);
      } else {
        showMenu(false);
      }
    },
    [showMenu, outerRef, setXPos, setYPos]
  );

  const handleClick = useCallback((e) => {
    if(e.target.id !== '' || (outerRef && !outerRef?.current?.contains(e.target))){
        showMenu(false);
    }    
  }, [showMenu, outerRef]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
    // eslint-disable-next-line
  }, []);

  return { xPos, yPos, menu, columnIndex };
};

export default useContextMenu;