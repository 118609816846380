/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

const anPattern = new RegExp(/^[a-z0-9]*$/i);

const AlphaNumericInput = (props) => {
  const { value, onChange, ...rest } = props;
  return (
    <input
      value={value}
      onChange={(e) => {
        if (anPattern.test(e.target.value)) {
          onChange(e);
        }
      }}
      {...rest}
    />
  );
};

export default AlphaNumericInput;
