import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './UpdateTracesCredential.css';
import Spinner from 'react-bootstrap/Spinner';
import ShowAlert from '../../components/ShowAlert';
import { getTracesCaptchaFromConnector, verifyTracesLoginFromConnector } from '../../api/localConnectorAPI';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash,faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { BASE_PATH, CONNECTOR_ERRORS_MAPPING, DASHBOARD_PATH } from '../../app/constants';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../features/header/headerSlice';
import { gocallApi } from '../../api/issueCertificateAPI';


const UpdateTracesCredential = (props) => {
  const {
    show, handleClose, handleProceed, tracesPassword = '', tracesUser = '', tan, deductorId, port, setCaptchaRef, setVerifyCreds = null, latestSyncStatus
  } = props;

  const headerDataDetails = useSelector(selectHeaderData);

  const [loading, setLoading] = useState(false);
  const [cookies, setCookies] = useState(null);
  const [captchaImg, setCaptchaImg] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');

  const [showTracesPassword, setShowTracesPassword] = useState(false);
  /* end js traces cred.popup password section */

  const location = useLocation();
  const history = useHistory();
  const pathName = location.pathname;


  const fetchCaptcha = (port) => {
    async function fetchEverything() {
      try {
        const result = await getTracesCaptchaFromConnector(port);
        setCaptchaImg(result.data.captcha_image);
        setCookies(result.data.captcha_ref);
      } catch (err) {
        console.error('error: ', err);
        setError("Not able to fetch captcha, please try again after sometime. If the problem persists, then try with different internet");

      } finally {
        setLoading(false);
        setCaptcha('');
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const updateCredentials = (cookies) => {
    const request = {
      "deductor_name": headerDataDetails?.deductor_name ?? "",
      "traces_username": username,
      "traces_password": password,
      "deductor_id": deductorId,
      "deductor_status": 1,
      "deductor_credentials": [
        {
          "credential_type": "TRACES",
          "password": password,
          "username": username
        }
      ]
    }
    const url = "/api/v1/deductor"

    async function fetchEverything() {
      try {
        await gocallApi("put", url, request);
        setTimeout(() => {
          setCaptchaRef(cookies)
          if (setVerifyCreds) setVerifyCreds();
        }, 200)

      } catch (err) {
        console.error('error in updating deductor credentials: ', err);
      }
    }
    fetchEverything();
  };

  useEffect(() => {
    fetchCaptcha(port);
    setUsername(tracesUser);
    setPassword(tracesPassword);
  }, [port, tracesUser, tracesPassword]);

  const verifyCatpcha = () => {

    setError(null);

    const request = {
      "tan": (tan && tan !== '') ? tan : headerDataDetails.tan,
      "traces_username": username,
      "traces_password": password,
      "captcha_text": captcha,
      "captcha_ref": cookies,

    };

    async function fetchEverything() {
      try {
        const result = await verifyTracesLoginFromConnector(port, request);
        if (result.status === 1) {

          //Update TRACES credentials in user profile
          updateCredentials(cookies);
          //Set status to start next action
          handleProceed(result);
          handleClose();

        }
      } catch (err) {
        console.error('error: ', err);
        setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message ?? CONNECTOR_ERRORS_MAPPING['default']);
        fetchCaptcha(port);

      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };


  const submitForm = (e) => {
    e.preventDefault();
    const fErrors = {};
    if (!username) fErrors['username'] = 'Username is required';
    if (!password) fErrors['password'] = 'Password is required';
    if (!captcha) fErrors['captcha'] = 'Captcha is required';

    setFormErrors(fErrors);
    if (Object.keys(fErrors).length > 0) return;

    verifyCatpcha();
  };


  const closeModel = () => {
    handleClose();

    if (headerDataDetails?.tan && headerDataDetails?.mobile_number && pathName === DASHBOARD_PATH) {
      // let InsightsGuide = JSON.parse(localStorage.getItem('Insights-guide'));
      // const userMobile = headerDataDetails?.mobile_number;
      // const userTan = headerDataDetails?.tan;      
      //if (!InsightsGuide || !InsightsGuide[userMobile] || !InsightsGuide[userMobile].includes(userTan)) {   
      if (latestSyncStatus?.sync_status === null) {
        //Deductor not exist in insights guide
        history.push(`${BASE_PATH}list/recent`);
      }
    }
  }


  return (
    <>

      <Modal
        show={show}
        onHide={closeModel}
        backdrop="static"
        keyboard={false}
        className="tracescredentials tracescrednewcss"
      >
        {loading ? <LoadingIndicator /> : null}
        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title>TRACES Credentials</Modal.Title>
        </Modal.Header>



        <form onSubmit={submitForm}>
          <Modal.Body className="pb-0 px-0">
            {/* <ShowAlert
              error={error}
              onClose={() => { setError(null); }}
            /> */}
            <ShowAlert
              success={success}
              onClose={() => { setError(null); setSuccess(null); }}
            />

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {error
                    ? (
                      <div style={{ color: 'red', fontSize: '11px', marginBottom: '5px' }}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        {' '}
                        {error}
                      </div>
                    )
                    : null}
                  {/* <p className="mb-0 pb-0 ptextcreditial">Please provide Traces Credentials below </p> */}
                  <form className="form-horizontal">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-1">
                          <label className="control-label" htmlFor="">Username <span style={{ color: "red" }}>*</span></label>
                          <input
                            type="search"
                            className="form-control"
                            id="username"
                            name="username"
                            placeholder="Enter Username"
                            maxLength={11}
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                          />
                          {formErrors.username ? <span className="error_message">{formErrors.username}</span> : null}
                        </div>

                        {/*traces cred.popup password section */}


                        <div className="form-group">
                          <label htmlFor="password">
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                          {showTracesPassword ? (
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                id="password"
                                placeholder="Enter Password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <div
                                className="input-group-prepend eyeipbox-create-deductor"
                                onClick={() => setShowTracesPassword(false)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </div>
                            </div>
                          ) : (
                            <div className="input-group">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Enter Password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                              />
                              <div
                                className="input-group-prepend eyeipbox-create-deductor"
                                onClick={() => setShowTracesPassword(true)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </div>
                            </div>
                          )}
                          {formErrors.password ? <span className="error_message">{formErrors.password}</span> : null}
                        </div>
                        {/* end traces cred.popup password section */}

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="control-label" htmlFor="">Captcha </label>
                              <div className="captcha_img">
                                {' '}
                                {loading ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    {' '}
                                    Loading...
                                  </>
                                ) : (<img src={`data:image/png;base64, ${captchaImg}`} alt="" />)}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="control-label" htmlFor="">
                                Enter Captcha
                                <span className="red_text">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="captcha"
                                name="captcha"
                                value={captcha}
                                onChange={(e) => setCaptcha(e.target.value)}
                              />
                              {formErrors.captcha ? <span className="error_message">{formErrors.captcha}</span> : null}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <small>
                                Can't read the captcha?
                                <Button
                                  type="button"
                                  onClick={() => {
                                    setCaptcha('')
                                    fetchCaptcha(port);
                                  }}
                                  className="btn btn-link clickbtnbgclrnone"
                                >
                                  Click here
                                </Button>
                                to refresh
                              </small>
                            </p>
                          </div>

                        </div>
                      </div>

                    </div>

                  </form>
                  <div className="col-md-12 mb-2 text-center">
                    <Button type="submit" className="tracesbtnsubmit" onClick={submitForm} disabled={loading}> Save  </Button>

                  </div>

                </div>
              </div>
            </div>

          </Modal.Body>

        </form>
      </Modal>
    </>

  );
};
export default UpdateTracesCredential;
