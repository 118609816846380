/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Formik, Form,
} from 'formik';
import { Tooltip } from '@progress/kendo-react-tooltip';
import deleteIcon from '../../../../images/icons/delete.svg';
import filedoticon from '../../../../images/loginicon/filedoticon.png';
import addBtnRoundIcon from '../../../../images/icons/add_btn_round.svg';
import texcopicon from '../../../../images/loginicon/texcopicon.PNG';
import './SalaryDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign, faSync } from '@fortawesome/free-solid-svg-icons';
import { manageSalaryData, tdsReturnValidNumber } from '../../../../utils/UtilityFunctions';
import { otherIncomevalidationSchema } from './validationSchema';
import { calculateTotalDeductionUnderChapter6Aa, calculateTaxComputed } from './calculationScema';
import NumericInputWithDecimalTwo from '../../../../components/NumericInputWithDecimalTwo';
import NegativeNumericInputWithDecimalTwo from '../../../../components/NegativeNumericInputWithDecimalTwo';
import TdsComputed from './popup/TdsComputed';
import LetOutIncome from './popup/LetOutIncome';
import SelfOccupied from './popup/SelfOccupied';
import { getInitializeData, resetInitialData } from './salaryHelper';
import { formatNumber, roundUpValue } from '../../../../utils/UtilityFunctions';


const OtherIncome = (props) => {
    const { otherIncomeData, setSalaryData, showSalaryPopup, setShowSalaryPopup, addSalary, viewComputation, otherIncomeParticulars, deductionVIAParticulars, getDeducteeTDSAmount, headerDataDetails, componentImport, actionStatus } = props;
    const [showTdsComputedPopup, setShowTdsComputedPopup] = useState(false);
    const [showLetOutIncomePopup, setShowLetOutIncomePopup] = useState(false);
    const [showSelfOccupiedPopup, setShowSelfOccupiedPopup] = useState(false);


    useEffect(() => {
        document.getElementById('let_out_amtp').focus();
    }, [showSalaryPopup]);


    const totalHouseIncome = (field, value, setFieldValue, values) => {
        let total_house_property_income_amtp = 0;
        values[field] = value;

        values.self_occupied_amtp = parseFloat(values.self_occupied_amtp) ? values.self_occupied_amtp : 0;
        values.let_out_amtp = parseFloat(values.let_out_amtp) ? values.let_out_amtp : 0;

        total_house_property_income_amtp = parseFloat(values.let_out_amtp) - parseFloat(values.self_occupied_amtp);
        setFieldValue('total_house_property_income_amtp', total_house_property_income_amtp.toFixed(2));
        values.total_house_property_income_amtp = total_house_property_income_amtp.toFixed(2);
        totalGrossIncome(values, setFieldValue);
    }

    const totalOtherIncomeOfferedForTds = (values, other_income_offered_details, setFieldValue) => {
        let total_other_income_offered_amtp = 0;
        other_income_offered_details.map((data, index) => {
            let amount_amtp = parseInt(data.amount_amtp) ? parseFloat(data.amount_amtp) : 0;
            total_other_income_offered_amtp = parseFloat(total_other_income_offered_amtp) + amount_amtp;
            return false;
        });
        total_other_income_offered_amtp = total_other_income_offered_amtp.toFixed(2);
        setFieldValue('total_other_income_offered_amtp', total_other_income_offered_amtp);
        values.total_other_income_offered_amtp = total_other_income_offered_amtp;
        totalGrossIncome(values, setFieldValue);
    }

    const totalNetTaxableIncome = (values, setFieldValue) => {
        let total_net_taxable_income_amtp = 0;
        total_net_taxable_income_amtp = (parseFloat(values.total_gross_income_amtp) - parseFloat(values.total_deduction_vi_a_amtp)).toFixed(2);

        let net_taxable_income = values.net_taxable_income;
        net_taxable_income.total_net_taxable_income_amtp = total_net_taxable_income_amtp;
        net_taxable_income = calculateTaxComputed(net_taxable_income, values, parseInt(headerDataDetails?.financial_year_formatted.split("-")?.[0] ?? 0));
        setFieldValue('net_taxable_income', net_taxable_income);
    }

    const totalGrossIncome = (values, setFieldValue) => {
        let total_gross_income_amtp = 0;
        total_gross_income_amtp = (parseFloat(values.net_salary_amtp) + parseFloat(values.total_house_property_income_amtp) + parseFloat(values.total_other_income_offered_amtp)).toFixed(2);
        setFieldValue('total_gross_income_amtp', total_gross_income_amtp);
        values.total_gross_income_amtp = total_gross_income_amtp;
        totalDeductionUnderChapter6Aa(values, values.deduction_vi_a_details, setFieldValue);
    }

    const totalDeductionUnderChapter6Aa = (values, deduction_vi_a_details, setFieldValue) => {
        let result = calculateTotalDeductionUnderChapter6Aa(deduction_vi_a_details, values.salary_category, values.total_gross_income_amtp);
        setFieldValue('deduction_vi_a_details', result[0]);
        setFieldValue('total_deduction_vi_a_amtp', result[1].toFixed(2));
        values.total_deduction_vi_a_amtp = result[1].toFixed(2);
        totalNetTaxableIncome(values, setFieldValue);
    }

    const changeOtherIncomeOfferedTds = (e, field, index, setFieldValue, values, isRoundUpValue=false) => {
        let OtherIncomeOfferedTds = values.other_income_offered_details;
        OtherIncomeOfferedTds[index][field] = isRoundUpValue ? roundUpValue(e.target.value) : e.target.value;
        setFieldValue('other_income_offered_details', OtherIncomeOfferedTds);
        totalOtherIncomeOfferedForTds(values, OtherIncomeOfferedTds, setFieldValue);
    }

    const addOtherIncomeOfferedTds = (setFieldValue, values) => {
        let OtherIncomeOfferedTds = values.other_income_offered_details;
        OtherIncomeOfferedTds.push({
            particular_code: '', amount_amtp: 0
        });
        setFieldValue('other_income_offered_details', OtherIncomeOfferedTds);
        totalOtherIncomeOfferedForTds(values, OtherIncomeOfferedTds, setFieldValue);
    }

    const removeOtherIncomeOfferedTds = (raw, setFieldValue, values) => {
        let OtherIncomeOfferedTds = values.other_income_offered_details;
        OtherIncomeOfferedTds = OtherIncomeOfferedTds.filter((data, index) => { return raw !== index; });
        setFieldValue('other_income_offered_details', OtherIncomeOfferedTds);
        totalOtherIncomeOfferedForTds(values, OtherIncomeOfferedTds, setFieldValue);
    }

    const changeDeductionUnderChapter6A = (value, field, index, setFieldValue, values) => {
        let underChapter6A = values.deduction_vi_a_details;
        underChapter6A[index][field] = value;
        setFieldValue('deduction_vi_a_details', underChapter6A);
        totalDeductionUnderChapter6Aa(values, underChapter6A, setFieldValue);
    }

    const AddDeductionUnderChapter6A = (setFieldValue, values) => {
        let underChapter6A = values.deduction_vi_a_details;
        underChapter6A.push({
            code: '', gross_amount_amtp: 0, deductible_amount_amtp: 0
        });
        setFieldValue('deduction_vi_a_details', underChapter6A);
        totalDeductionUnderChapter6Aa(values, underChapter6A, setFieldValue);
    }

    const removeDeductionUnderChapter6A = (raw, setFieldValue, values) => {
        let underChapter6A = values.deduction_vi_a_details;
        underChapter6A = underChapter6A.filter((data, index) => { return raw !== index; });
        setFieldValue('deduction_vi_a_details', underChapter6A);
        totalDeductionUnderChapter6Aa(values, underChapter6A, setFieldValue);
    }

    const netTaxableIncome = (value, field, setFieldValue, values) => {
        let taxableIncome = values.net_taxable_income;
        taxableIncome[field] = value;
        setFieldValue('net_taxable_income', taxableIncome);
        totalNetTaxableIncome(values, setFieldValue);
    }

    const refereshDeducteeTDSAmount = async (values, setFieldValue) => {
        let result = await getDeducteeTDSAmount(values.deductor_id, values.deductee_id, values.financial_year);
        if (result.data) {
            netTaxableIncome(result.data.total_tds_amtp, 'tds_ded_by_current_emp_amtp', setFieldValue, values)
        }
    }

    const isParticularsUse = (code, particulars_code, particulars) => {
        let show = true;
        particulars.map((checkparticulars) => {
            if (checkparticulars.code === code && particulars_code !== code) {
                show = false;
            }
            return true;
        })
        return show;
    }

    const disable_field = componentImport && actionStatus !== 'ADDED' && otherIncomeData.salary_id ? true : false;

    return (
        <div className="container">
            <Formik
                initialValues={otherIncomeData}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={otherIncomevalidationSchema}
                onSubmit={async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
                    const submitValues = { ...values };
                    if (values.save_type !== 'new' && values.save_type !== 'save') {
                        setSalaryData(submitValues);
                        let popup = document.getElementById('other_income_submit').getAttribute('data-popup');
                        setShowSalaryPopup(popup);
                    }
                    else {
                        let save_success = await addSalary(submitValues);
                        if (save_success) {
                            resetForm(getInitializeData(values.deductor_id, values.is_government_deductor, values.financial_year));
                        }
                        else {
                            resetInitialData(otherIncomeData);
                        }
                    }
                }}
            >
                {({
                    errors, values, setFieldValue,
                }) => {

                    if (!values.salary_id) {
                        setTimeout(function () {
                            manageSalaryData('save', headerDataDetails.tan, values.financial_year, values);
                        }, 1000);
                    }

                    if (Object.keys(errors).length > 0) {
                        document.getElementById('save-other-income').disabled = false;
                        document.getElementById('save-new-other-income').disabled = false;
                    }
                    
                    return (
                        <Form key={1} autoComplete="off" className="other_income_form">
                            <Tooltip anchorElement="pointer" position="left">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card grosssalaryph3card">
                                                    <div className="card-header">
                                                        House Property Income/Loss
                                                        <span className="float-right" title="Total House Property Income / Loss = Let Out Amount - Self Occupied Amount">
                                                            Total = <span className=" fsizeicon"> <FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {''} {formatNumber(values.total_house_property_income_amtp)}
                                                        </span>

                                                        {errors.total_house_property_income_amtp ? (
                                                            <>
                                                                <br />
                                                                <span className="error_message">
                                                                    {errors.total_house_property_income_amtp}
                                                                </span>
                                                            </>
                                                        ) : null}
                                                        {(componentImport && otherIncomeData && otherIncomeData.old_total_house_property_income_amtp && otherIncomeData.old_total_house_property_income_amtp > 0) ? (<div className="error_message text-right" style={{ color: otherIncomeData.total_house_property_income_amtp_color ? `#${otherIncomeData.total_house_property_income_amtp_color}` : '#0000ff' }}>{formatNumber(otherIncomeData.old_total_house_property_income_amtp)}</div>) : null}

                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="" className="">Let-Out Amt</label>
                                                                <img className="grosssalaryicon" onClick={() => setShowLetOutIncomePopup(true)} title="" src={filedoticon} alt="" />
                                                                <NegativeNumericInputWithDecimalTwo
                                                                    type="text"
                                                                    disabled={disable_field}
                                                                    className="form-control"
                                                                    name="let_out_amtp"
                                                                    id="let_out_amtp"
                                                                    value={values.let_out_amtp}
                                                                    placeholder="0.00"
                                                                    onChange={(e) => {
                                                                        if (!tdsReturnValidNumber(e.target.value, true)) return;


                                                                        setFieldValue(
                                                                            'let_out_amtp',
                                                                            e.target.value,
                                                                        );
                                                                        setFieldValue('let_out_breakup', null);

                                                                        totalHouseIncome('let_out_amtp', e.target.value, setFieldValue, values);
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        if (!tdsReturnValidNumber(e.target.value, true)) return;
                                                                        setFieldValue('let_out_amtp', roundUpValue(e.target.value));
                                                                        totalHouseIncome('let_out_amtp', roundUpValue(e.target.value), setFieldValue, values);
                                                                    }}
                                                                />
                                                                {(componentImport && otherIncomeData && otherIncomeData.old_let_out_amtp && otherIncomeData.old_let_out_amtp > 0) ? (<div className="error_message" style={{ color: otherIncomeData.let_out_amtp_color ? `#${otherIncomeData.let_out_amtp_color}` : '#0000ff' }}>{otherIncomeData.old_let_out_amtp}</div>) : null}
                                                                {showLetOutIncomePopup &&
                                                                    <LetOutIncome
                                                                        show={showLetOutIncomePopup}
                                                                        handleClose={() => setShowLetOutIncomePopup(!showLetOutIncomePopup)}
                                                                        values={values}
                                                                        setFieldValue={setFieldValue}
                                                                        totalHouseIncome={totalHouseIncome}
                                                                    />}
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label className="">Self Occupied Amt</label>
                                                                <img className="grosssalaryicon" onClick={() => setShowSelfOccupiedPopup(true)} title="" src={filedoticon} alt="" />
                                                                <NumericInputWithDecimalTwo
                                                                    type="text"
                                                                    disabled={disable_field}
                                                                    className="form-control"
                                                                    name="self_occupied_amtp"
                                                                    id="self_occupied_amtp"
                                                                    value={values.self_occupied_amtp}
                                                                    placeholder="0.00"
                                                                    onChange={(e) => {
                                                                        if (!tdsReturnValidNumber(e.target.value)) return;
                                                                        setFieldValue(
                                                                            'self_occupied_amtp',
                                                                            e.target.value,
                                                                        );
                                                                        setFieldValue('self_occupied_breakup', null);
                                                                        totalHouseIncome('self_occupied_amtp', e.target.value, setFieldValue, values);
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        if (!tdsReturnValidNumber(e.target.value, true)) return;
                                                                        setFieldValue('self_occupied_amtp', roundUpValue(e.target.value));
                                                                        totalHouseIncome('self_occupied_amtp', roundUpValue(e.target.value), setFieldValue, values);
                                                                    }}
                                                                />
                                                                {errors.self_occupied_amtp ? (
                                                                    <span className="error_message">
                                                                        {errors.self_occupied_amtp}
                                                                    </span>
                                                                ) : null}

                                                                {(componentImport && otherIncomeData && otherIncomeData.old_self_occupied_amtp && otherIncomeData.old_self_occupied_amtp > 0) ? (<div className="error_message" style={{ color: otherIncomeData.self_occupied_amtp_color ? `#${otherIncomeData.self_occupied_amtp_color}` : '#0000ff' }}>{otherIncomeData.old_self_occupied_amtp}</div>) : null}

                                                                {showSelfOccupiedPopup &&
                                                                    <SelfOccupied
                                                                        show={showSelfOccupiedPopup}
                                                                        handleClose={() => setShowSelfOccupiedPopup(!showSelfOccupiedPopup)}
                                                                        values={values}
                                                                        setFieldValue={setFieldValue}
                                                                        totalHouseIncome={totalHouseIncome}
                                                                    />}

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-12 pt-2">
                                                <div className="card grosssalaryph3card mb-3">
                                                    <div className="card-header">
                                                        Other Income Offered for TDS as 192 (2B)
                                                        <span className="float-right"> Total =
                                                            {''} <span className=" fsizeicon"> <FontAwesomeIcon icon={faRupeeSign} /> </span>
                                                            {formatNumber(values.total_other_income_offered_amtp)}
                                                        </span>
                                                        {(componentImport && otherIncomeData && otherIncomeData.old_total_other_income_offered_amtp && otherIncomeData.old_total_other_income_offered_amtp > 0) ? (<div className="error_message text-right" style={{ color: otherIncomeData.total_other_income_offered_amtp_color ? `#${otherIncomeData.total_other_income_offered_amtp_color}` : '#0000ff' }}>{formatNumber(otherIncomeData.old_total_other_income_offered_amtp)}</div>) : null}
                                                    </div>
                                                    {values.other_income_offered_details.map((otherIncomeData, otherIncomeIndex) => {
                                                        return (
                                                            <div className="card-body" key={otherIncomeIndex}>
                                                                <div className="row">
                                                                    <div className="form-group col-md-6">
                                                                        <label htmlFor="" className="">Particulars</label>
                                                                        <select name="particular_code" className="custom-select" disabled={disable_field} onChange={(e) => { changeOtherIncomeOfferedTds(e, 'particular_code', otherIncomeIndex, setFieldValue, values) }} value={otherIncomeData.particular_code}>
                                                                            <option value="">Select</option>
                                                                            {otherIncomeParticulars && otherIncomeParticulars.map((data, index) => {
                                                                                let show = true;
                                                                                values.other_income_offered_details.map((checkIncome) => {
                                                                                    if (checkIncome.particular_code === data.particular_code && otherIncomeData.particular_code !== data.particular_code) {
                                                                                        show = false;
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                                if (show) {
                                                                                    return (<option key={index} value={data.particular_code}>{data.particular_description}</option>)
                                                                                }
                                                                                return false;
                                                                            })}
                                                                        </select>
                                                                        {errors.other_income_offered_details && errors.other_income_offered_details[otherIncomeIndex] && errors.other_income_offered_details[otherIncomeIndex].particular_code ? (
                                                                            <span className="error_message">
                                                                                {errors.other_income_offered_details[otherIncomeIndex].particular_code}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="form-group col-md-4 pr-0">
                                                                        <label htmlFor="" className="">Amt</label>
                                                                        <NumericInputWithDecimalTwo
                                                                            type="text"
                                                                            disabled={disable_field}
                                                                            className="form-control"
                                                                            name="amount_amtp"
                                                                            value={otherIncomeData.amount_amtp}
                                                                            placeholder="0.00"
                                                                            onChange={(e) => {
                                                                                if (!tdsReturnValidNumber(e.target.value)) return;
                                                                                changeOtherIncomeOfferedTds(e, 'amount_amtp', otherIncomeIndex, setFieldValue, values)
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (!tdsReturnValidNumber(e.target.value)) return;
                                                                                changeOtherIncomeOfferedTds(e, 'amount_amtp', otherIncomeIndex, setFieldValue, values, true)
                                                                            }}
                                                                        />
                                                                        {errors.other_income_offered_details && errors.other_income_offered_details[otherIncomeIndex] && errors.other_income_offered_details[otherIncomeIndex].amount_amtp ? (
                                                                            <span className="error_message">
                                                                                {errors.other_income_offered_details[otherIncomeIndex].amount_amtp}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="form-group col-md-2 pl-0">
                                                                        <label htmlFor="" className="text-right">
                                                                            &nbsp;
                                                                        </label>
                                                                        <span className="allowaicon">
                                                                            {otherIncomeIndex > 0 && <img src={deleteIcon} alt="" onClick={() => { removeOtherIncomeOfferedTds(otherIncomeIndex, setFieldValue, values) }} />}
                                                                            {otherIncomeIndex === parseInt(values.other_income_offered_details.length) - 1 && otherIncomeParticulars && values.other_income_offered_details.length < otherIncomeParticulars.length && <img src={addBtnRoundIcon} alt="" onClick={() => { addOtherIncomeOfferedTds(setFieldValue, values) }} />}
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="gorsstotlincome pt-2">
                                                    <span>GROSS TOTAL INCOME</span>
                                                    <span className="float-right" style={{ textAlign: 'right' }}>
                                                        Total = <span className=" fsizeicon"><FontAwesomeIcon icon={faRupeeSign} /> </span>
                                                        {formatNumber(values.total_gross_income_amtp)}
                                                        {errors.total_gross_income_amtp ? (
                                                            <>
                                                                <br />
                                                                <span className="error_message" style={{ fontSize: '11px' }}>
                                                                    {errors.total_gross_income_amtp}
                                                                </span>
                                                            </>
                                                        ) : null}
                                                        {(componentImport && otherIncomeData && otherIncomeData.old_total_gross_income_amtp && otherIncomeData.old_total_gross_income_amtp > 0) ? (<div className="error_message text-right" style={{ color: otherIncomeData.total_gross_income_amtp_color ? `#${otherIncomeData.total_gross_income_amtp_color}` : '#0000ff' }}>{formatNumber(otherIncomeData.old_total_gross_income_amtp)}</div>) : null}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-12 pt-2">
                                                <div className="card grosssalaryph3card mb-3">
                                                    <div className="card-header">
                                                        Deduction Chapter VI-A
                                                        <span className="float-right">
                                                            Total = <span className=" fsizeicon"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                            {''} {formatNumber(values.total_deduction_vi_a_amtp)}
                                                        </span>
                                                        {(componentImport && otherIncomeData && otherIncomeData.old_total_deduction_vi_a_amtp && otherIncomeData.old_total_deduction_vi_a_amtp > 0) ? (<div className="error_message text-right" style={{ color: otherIncomeData.total_deduction_vi_a_amtp_color ? `#${otherIncomeData.total_deduction_vi_a_amtp_color}` : '#0000ff' }}>{formatNumber(otherIncomeData.old_total_deduction_vi_a_amtp)}</div>) : null}
                                                    </div>
                                                    {values.deduction_vi_a_details.map((DeductionUnderChapter6AData, DeductionUnderChapter6AIndex) => {
                                                        return (
                                                            <div className="card-body" key={DeductionUnderChapter6AIndex}>
                                                                <div className="row">
                                                                    <div className="form-group col-md-3" style={{ paddingRight: '5px' }}>
                                                                        <label htmlFor="" className="">Particulars</label>
                                                                        <select name="code" className="custom-select" disabled={disable_field} onChange={(e) => { changeDeductionUnderChapter6A(e.target.value, 'code', DeductionUnderChapter6AIndex, setFieldValue, values) }} value={DeductionUnderChapter6AData.code}>
                                                                            <option value="">Select</option>
                                                                            {deductionVIAParticulars && deductionVIAParticulars[values.tax_regime].map((data, index) => {
                                                                                if (isParticularsUse(data.code, DeductionUnderChapter6AData.code, values.deduction_vi_a_details)) {
                                                                                    return (<option key={index} value={data.code}>{data.display_code}</option>)
                                                                                }
                                                                                else {
                                                                                    return false;
                                                                                }
                                                                            })}
                                                                        </select>
                                                                        {errors.deduction_vi_a_details && errors.deduction_vi_a_details[DeductionUnderChapter6AIndex] && errors.deduction_vi_a_details[DeductionUnderChapter6AIndex].code ? (
                                                                            <span className="error_message">
                                                                                {errors.deduction_vi_a_details[DeductionUnderChapter6AIndex].code}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>

                                                                    <div className="form-group col-md-3" style={{ paddingRight: '5px', paddingLeft: '5px', maxWidth: '20%' }}>
                                                                        <label for="" className="">Gross Amt</label>
                                                                        <NumericInputWithDecimalTwo
                                                                            type="text"
                                                                            disabled={disable_field}
                                                                            className="form-control"
                                                                            name="gross_amount_amtp"
                                                                            value={DeductionUnderChapter6AData.gross_amount_amtp}
                                                                            placeholder="0.00"
                                                                            onChange={(e) => {
                                                                                if (!tdsReturnValidNumber(e.target.value)) return;
                                                                                changeDeductionUnderChapter6A(e.target.value, 'gross_amount_amtp', DeductionUnderChapter6AIndex, setFieldValue, values)
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (!tdsReturnValidNumber(e.target.value)) return;
                                                                                changeDeductionUnderChapter6A(roundUpValue(e.target.value), 'gross_amount_amtp', DeductionUnderChapter6AIndex, setFieldValue, values)
                                                                            }}
                                                                        />
                                                                        {errors.deduction_vi_a_details && errors.deduction_vi_a_details[DeductionUnderChapter6AIndex] && errors.deduction_vi_a_details[DeductionUnderChapter6AIndex].gross_amount_amtp ? (
                                                                            <span className="error_message">
                                                                                {errors.deduction_vi_a_details[DeductionUnderChapter6AIndex].gross_amount_amtp}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>

                                                                    <div className="form-group col-md-2" style={{ paddingRight: '5px', paddingLeft: '5px', maxWidth: '20%' }}>
                                                                        <label htmlFor="" className="">Deductible</label>
                                                                        <NumericInputWithDecimalTwo
                                                                            type="text"
                                                                            disabled={disable_field}
                                                                            className="form-control"
                                                                            name="deductible_amount_amtp"
                                                                            value={DeductionUnderChapter6AData.deductible_amount_amtp}
                                                                            placeholder="0.00"
                                                                            readOnly={true}
                                                                            style={{width: '125px'}}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-md-2" style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                                                                        {(DeductionUnderChapter6AData.code === '80G_100' ||
                                                                            DeductionUnderChapter6AData.code === '80G_50' ||
                                                                            DeductionUnderChapter6AData.code === '80G_OTHER_100' ||
                                                                            DeductionUnderChapter6AData.code === '80G_OTHER_50' ||
                                                                            DeductionUnderChapter6AData.code === '80TTA' ||
                                                                            DeductionUnderChapter6AData.code === 'OTHERS') ?
                                                                            (<>
                                                                                <label htmlFor="" className="">Qualifying</label>
                                                                                <NumericInputWithDecimalTwo
                                                                                    type="text"
                                                                                    disabled={disable_field}
                                                                                    className="form-control"
                                                                                    name="qualifying_amtp"
                                                                                    value={DeductionUnderChapter6AData.qualifying_amtp}
                                                                                    placeholder="0.00"
                                                                                    readOnly={true}
                                                                                    style={{width: '125px'}}
                                                                                />
                                                                            </>) : null}

                                                                        {(DeductionUnderChapter6AData.code === '80D_FOR_PARENTS') ?
                                                                            (<span>
                                                                                <input
                                                                                    name="is_senior_citizen"
                                                                                    style={{ top: '2rem', position: 'relative' }}
                                                                                    value="1"
                                                                                    type="checkbox"
                                                                                    disabled={disable_field}
                                                                                    checked={DeductionUnderChapter6AData.is_senior_citizen ? true : false}
                                                                                    onClick={(e) => {
                                                                                        changeDeductionUnderChapter6A(DeductionUnderChapter6AData.is_senior_citizen ? 0 : 1, 'is_senior_citizen', DeductionUnderChapter6AIndex, setFieldValue, values);
                                                                                    }}
                                                                                />
                                                                                <label style={{ top: '1.8rem', left: '5px', width: '80px', position: 'relative' }}
                                                                                    className="form-check-label" htmlFor="is_senior_citizen">Senior Citizen</label>
                                                                            </span>) : null}
                                                                    </div>


                                                                    <div className="form-group col-md-2" style={{ textAlign: 'left' }}>
                                                                        <label htmlFor="" className="">&nbsp;</label>
                                                                        <span className="ductchapsectionicon topcolicon">
                                                                            {DeductionUnderChapter6AIndex > 0 && <img src={deleteIcon} alt="" onClick={() => { removeDeductionUnderChapter6A(DeductionUnderChapter6AIndex, setFieldValue, values) }} />}
                                                                            {DeductionUnderChapter6AIndex === parseInt(values.deduction_vi_a_details.length) - 1 && deductionVIAParticulars && values.deduction_vi_a_details.length < deductionVIAParticulars[values.tax_regime].length && <img src={addBtnRoundIcon} alt="" onClick={() => { AddDeductionUnderChapter6A(setFieldValue, values) }} />}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="gorsstotlincome pt-2">
                                                    <span>NET TAXABLE INCOME</span>
                                                    <span className="float-right" style={{ textAlign: 'right' }}>
                                                        Total = <span className=" fsizeicon"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                                        {' '}
                                                        {formatNumber(values.net_taxable_income.total_net_taxable_income_amtp)}
                                                        {errors.net_taxable_income && errors.net_taxable_income && errors.net_taxable_income.total_net_taxable_income_amtp ? (
                                                            <>
                                                                <br />
                                                                <span className="error_message" style={{ fontSize: '11px' }}>
                                                                    {errors.net_taxable_income.total_net_taxable_income_amtp}
                                                                </span>
                                                            </>
                                                        ) : null}
                                                        {(componentImport && otherIncomeData && otherIncomeData.net_taxable_income && otherIncomeData.net_taxable_income.old_total_net_taxable_income_amtp && otherIncomeData.net_taxable_income.old_total_net_taxable_income_amtp > 0) ? (<div className="error_message text-right" style={{ color: '#0000ff' }}>{formatNumber(otherIncomeData.net_taxable_income.old_total_net_taxable_income_amtp)}</div>) : null}
                                                    </span>
                                                </div>

                                            </div>


                                            <div className="col-md-12 pt-2 pb-5">
                                                <div className="card grosssalaryph3card">

                                                    <div className="card-header">
                                                        Tax Computed:

                                                        <span className="taxcopinnertext">
                                                            <span className="fsizeicon"><FontAwesomeIcon icon={faRupeeSign} /> {formatNumber(values.net_taxable_income.total_tax_computed_amtp)}</span>
                                                            <span className="pl-1" onClick={() => setShowTdsComputedPopup(true)}><img src={texcopicon} alt="" /></span>
                                                        </span>
                                                        {(componentImport && otherIncomeData && otherIncomeData.net_taxable_income && otherIncomeData.net_taxable_income.old_total_tax_computed_amtp && otherIncomeData.net_taxable_income.old_total_tax_computed_amtp > 0) ? (<span className="error_message" style={{ color: '#0000ff' }}>{formatNumber(otherIncomeData.net_taxable_income.old_total_tax_computed_amtp)}</span>) : null}
                                                        {showTdsComputedPopup &&
                                                            <TdsComputed
                                                                show={showTdsComputedPopup}
                                                                handleClose={() => setShowTdsComputedPopup(!showTdsComputedPopup)}
                                                                tax_computed={values.net_taxable_income.tax_computed}
                                                            />
                                                        }

                                                        {values.net_taxable_income.excess_short_tds_amtp >= 0 ?
                                                            <span className="float-right" style={{ color: '#31719b' }}>
                                                                Excess : <span className="fsizeicon"><FontAwesomeIcon icon={faRupeeSign} /></span> {" "}
                                                                {formatNumber(Math.abs(values.net_taxable_income.excess_short_tds_amtp))}
                                                            </span>
                                                            :
                                                            <span className="float-right" style={{ color: '#fc7d72' }}>
                                                                Shortfall : <span className="fsizeicon"><FontAwesomeIcon icon={faRupeeSign} /></span> {" "}
                                                                {formatNumber(Math.abs(values.net_taxable_income.excess_short_tds_amtp))}
                                                            </span>
                                                        }

                                                        {errors.net_taxable_income && errors.net_taxable_income && errors.net_taxable_income.total_tax_computed_amtp ? (<>
                                                            <br />
                                                            <span className="error_message">
                                                                {errors.net_taxable_income.total_tax_computed_amtp}
                                                            </span>
                                                        </>) : null}
                                                        {(componentImport && otherIncomeData && otherIncomeData.net_taxable_income && otherIncomeData.net_taxable_income.old_excess_short_tds_amtp && otherIncomeData.net_taxable_income.old_excess_short_tds_amtp > 0) ? (<div className="error_message text-right" style={{ color: '#0000ff' }}>{formatNumber(otherIncomeData.net_taxable_income.old_excess_short_tds_amtp)}</div>) : null}

                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="form-group col-md-4 ">
                                                                <label htmlFor="" className="">
                                                                    Relief u/s 89
                                                                </label>
                                                                <NumericInputWithDecimalTwo
                                                                    type="text"
                                                                    disabled={disable_field}
                                                                    className="form-control"
                                                                    name="relief_u_s_89_amtp"
                                                                    value={values.net_taxable_income.relief_u_s_89_amtp}
                                                                    placeholder="0.00"
                                                                    onChange={(e) => {
                                                                        if (!tdsReturnValidNumber(e.target.value)) return;
                                                                        netTaxableIncome(e.target.value, 'relief_u_s_89_amtp', setFieldValue, values)
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        if (!tdsReturnValidNumber(e.target.value)) return;
                                                                        netTaxableIncome(roundUpValue(e.target.value), 'relief_u_s_89_amtp', setFieldValue, values)
                                                                    }}
                                                                />
                                                                {errors.net_taxable_income && errors.net_taxable_income && errors.net_taxable_income.relief_u_s_89_amtp ? (
                                                                    <span className="error_message">
                                                                        {errors.net_taxable_income.relief_u_s_89_amtp}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="" className="">
                                                                    TDS Ded. by Current Emp.
                                                                </label>
                                                                <div className="tds_amount_field">
                                                                    <NumericInputWithDecimalTwo
                                                                        type="text"
                                                                        disabled={disable_field}
                                                                        className="form-control"
                                                                        name="tds_ded_by_current_emp_amtp"
                                                                        value={values.net_taxable_income.tds_ded_by_current_emp_amtp}
                                                                        placeholder="0.00"
                                                                        onChange={(e) => {
                                                                            if (!tdsReturnValidNumber(e.target.value)) return;
                                                                            netTaxableIncome(e.target.value, 'tds_ded_by_current_emp_amtp', setFieldValue, values)
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (!tdsReturnValidNumber(e.target.value)) return;
                                                                            netTaxableIncome(roundUpValue(e.target.value), 'tds_ded_by_current_emp_amtp', setFieldValue, values)
                                                                        }}
                                                                    />
                                                                    {!componentImport ? (
                                                                        <FontAwesomeIcon icon={faSync} className="refrsh_sync" onClick={() => refereshDeducteeTDSAmount(values, setFieldValue)} />
                                                                    ) : null
                                                                    }
                                                                </div>
                                                                {errors.net_taxable_income && errors.net_taxable_income && errors.net_taxable_income.tds_ded_by_current_emp_amtp ? (
                                                                    <span className="error_message">
                                                                        {errors.net_taxable_income.tds_ded_by_current_emp_amtp}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>


                                            <div className="col-md-12 text-center">
                                                {/* <div className="savebtnxsdisblock"> */}
                                                    <button type="submit" id="other_income_submit" data-popup="other_details" onClick={() => setFieldValue('save_type', 'other_details')} className="btn btn-default grossph3btnblueclr mr-2 ml-2 mb-1">NEXT</button>
                                                    <button type="submit" id="save-new-other-income" onClick={() => setFieldValue('save_type', 'new')} className="btn btn-default grossph3btnblueclr mb-1">SAVE & NEW </button>
                                                    <button type="submit" id="save-other-income" onClick={() => setFieldValue('save_type', 'save')} className="btn btn-default grossph3btnblueclr mr-2 ml-2 mb-1">SAVE</button>
                                                    <button type="button" className="btn btn-default grossph3btnblueclr mb-1" onClick={() => viewComputation(values)}>VIEW COMPUTATION</button>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default OtherIncome;