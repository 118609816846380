import React, { useState, useEffect } from 'react';
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import ShowAlert from '../../../components/ShowAlert';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Skeleton12ColumnGrid from '../../../components/Skeleton/Skeleton12ColumnGrid';
import { gocallApi } from '../../../api/issueCertificateAPI';
import unlinkingIcon from '../../../images/unlinking.gif';
import ConfirmAlertPopup from '../../../components/ConfirmAlertPopup';
import { FormatNumberDecimal, formatNumber, FormatNumberDecValue } from '../../../utils/UtilityFunctions';
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import Spinner from 'react-bootstrap/Spinner';

const CONFIRM_MSG_1 = 'Are you sure that you want to Remove this Challan from Allocation ?';
const CONFIRM_MSG_2 = 'Are you sure that you want to Unmap this Challan from Return ?';

const ChallanFeeAllocationPopup = (props) => {
  const { onClose, returnId, deductorId, feeLiability, remainingLiability } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [dataState, setDataState] = useState({});
  const [data, setData] = useState([]);
  const [totalAdjustedAmount, setTotalAdjustedAmount] = useState(0);
  const [totalRemainingLiability, setTotalRemainingLiability] = useState(0);
   // eslint-disable-next-line
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [deleteChallanId, setDeleteChallanId] = useState(null);
  const [isFeeAllocationChanged, setIsFeeAllocationChanged] = useState(false);
  const [confirmsg, setConfirmsg] = useState(CONFIRM_MSG_1);


  const convert = (challans) => {
    if (challans.length > 0) {
      challans.forEach((item, index) => {
        challans[index] = {
          ...item,
          //fees_allocated_amtp: formatNumber(item?.fees_allocated_amtp ?? 0),
          org_fees_allocated_amtp: item?.fees_allocated_amtp ?? 0,
        }
      })
    }
    return challans;
  }
  const getUnMappedFeeChallans = async (returnId, deductorId) => {

    const url = `/api/v1/challan/list-for-fee-allocation`;
    const payload = { deductor_id: deductorId, return_id: returnId }
    try {
      const result = await gocallApi('post', url, payload);
      setData(convert(result?.data ?? []));
    } catch (err) {
      console.err('error', err);
    } finally {
      setShowSkeleton(false);
    }
  }

  const dataStateChange = (dataState) => {
    setDataState(dataState);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };


  const getChallanIndexById = (challan_id) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].challan_id === challan_id) {
        return i;
      }
    }
  }

  const handleAllocatedAmountChange = (challan_id, value) => {
    setIsFeeAllocationChanged(true);
    const index = getChallanIndexById(challan_id);
    const list = data;
    // eslint-disable-next-line
    list[index]['fees_allocated_amtp'] = String(value).replace(/\,/g, '');
    setData((prev) => {
      prev = list
      return [...prev]
    })
  }

  const deleteChallan = () => {
    if (deleteChallanId) {
      const list = [];
      data.forEach((item) => {
        if (item?.challan_id !== deleteChallanId) {
          list.push(item);
        }
      })
      setData((prev) => {
        prev = list
        return [...prev]
      })

      resetDeletedChallanFeeAllocation(deleteChallanId);
    }
  }

  const resetDeletedChallanFeeAllocation = async (challan_id) => {

    const challans = [];
    if (data.length > 0) {
      data.forEach((item) => {
        if (item.challan_id === challan_id && Number(item.fees_allocated_amtp) > 0) {
          challans.push({
            challan_id: item.challan_id,
            fees_amtp: 0,
            free_fees_to_map_amtp: Number(item?.other_unconsumed_amtp ?? 0) + Number(item?.org_fees_allocated_amtp ?? 0)
          })
        }
      })
    }

    if (challans.length > 0) {
      try {
        const url = '/api/v1/challan/fee-allocation';
        const payload = {
          deductor_id: deductorId,
          return_id: returnId,
          fee_allocations_to_update: challans,
        }
        await gocallApi('post', url, payload);
      } catch (err) {
        setError(err);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }

  const handleDelete = (challan_id) => {
    const challan = data.filter((v) => v.challan_id === challan_id);

    if (Number(challan?.[0]?.fees_allocated_amtp ?? 0) === 0) {
      setConfirmsg(CONFIRM_MSG_1);
    } else {
      setConfirmsg(CONFIRM_MSG_2);
    }

    setConfirmPopup(true);
    setDeleteChallanId(challan_id);

  }

  const rowRender = (trElement, props) => {
    const data = props.dataItem;

    const key = trElement._owner.index;
    return (
      <tr key={key} role='row' className={`positionkey${key}`}>
        <td class="text-center">{data?.challan_no ?? '-'}</td>
        <td class="text-center" style={{ verticalAlign: 'middle' }}>{data?.deposit_date ? data?.deposit_date : '-'}</td>
        <td class="text-center" style={{ verticalAlign: 'middle' }}>{data?.bsr_code ? data?.bsr_code : '-'}</td>
        <td class="text-right tds_returnflow_challan_hoverbox" style={{ verticalAlign: 'middle' }} >
          <span className="text-right">{formatNumber(data?.total_amtp ?? 0)}</span>
        </td>
        <td class="text-right" style={{ verticalAlign: 'middle' }} >
          {formatNumber(data?.other_unconsumed_amtp ?? 0)}
        </td>
        <td class="text-right ">
          {/* <BlurOnChangeInput
            id={data.challan_id}
            type="text"
            className={`form-control text-right`}
            style={{ height: 'calc(1.42857em + 8px)', fontSize: '11px', fontWeight: '500' }}
            value={FormatNumberDecimal(data?.fees_allocated_amtp ?? 0)}
            // maxLength={20}
            placeholder="Enter interest allocation amount"
            onFocus={(e) => e.target.value = FormatNumberDecimal(e.target.value)}
            onChange={(e) => {
              if (String(e.target.value).trim() === '')
                handleAllocatedAmountChange(data.challan_id, FormatNumberDecValue1(0, ...Array(3), false, e));
              else handleAllocatedAmountChange(data.challan_id, FormatNumberDecValue1(e.target.value, ...Array(3), false, e));
            }}
          onBlur={(e) => {
            handleAllocatedAmountChange(data.challan_id, formatNumber(data?.fees_allocated_amtp ?? 0));
          }}
          /> */}
          <BlurOnChangeInput
            id={data.challan_id}
            type="text"
            className={`form-control text-right`}
            style={{ height: 'calc(1.42857em + 8px)', fontSize: '11px', fontWeight: '500' }}
            value={FormatNumberDecimal(data?.fees_allocated_amtp ?? 0, ...Array(2), false)}
            maxLength={20}
            placeholder="Enter interest allocation amount"
            onChange={(e) => {
              if (String(e.target.value).trim() === '')
                handleAllocatedAmountChange(data.challan_id, FormatNumberDecValue(0, 20, null, false, false));
              else handleAllocatedAmountChange(data.challan_id, FormatNumberDecValue(e.target.value, 20, null, false, false));
            }}
          />
        </td>
        <td><img src={unlinkingIcon} alt="" width={17} style={{ cursor: 'pointer' }} onClick={() => { handleDelete(data.challan_id) }} /> </td>
      </tr>
    )
  }


  const saveAllocation = async () => {
    const challans = [];
    if (data.length > 0) {
      data.forEach((item) => {
        if (Number(item?.fees_allocated_amtp ?? 0) > 0) {
          challans.push({
            challan_id: item.challan_id,
            fees_amtp: Number(item.fees_allocated_amtp),
            free_fees_to_map_amtp: Number(item?.other_unconsumed_amtp ?? 0) + Number(item?.org_fees_allocated_amtp ?? 0)
          })
        }
      })
    }

    try {
      setLoading(true);
      const url = '/api/v1/challan/fee-allocation';
      const payload = {
        deductor_id: deductorId,
        return_id: returnId,
        fee_allocations_to_update: challans,
      }
      const result = await gocallApi('post', url, payload);
      if (result.data) {
        setSuccess(result.message);
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    getUnMappedFeeChallans(returnId, deductorId);
    setTotalRemainingLiability(Number(remainingLiability));
    //eslint-disable-next-line
  }, [returnId, deductorId, remainingLiability])

  useEffect(() => {
    //setShowSaveButton(false);
    let total_adjusted_amount = 0;

    if (data.length > 0) {
      data.forEach((item) => {
        // eslint-disable-next-line
        const fees_allocated_amtp = String(item?.fees_allocated_amtp ?? 0).replace(/\,/g, '');
        total_adjusted_amount += Number(fees_allocated_amtp)
      })
      setTotalAdjustedAmount(formatNumber(total_adjusted_amount));
      if (total_adjusted_amount > 0 && isFeeAllocationChanged) {
        //setShowSaveButton(true);
      }
    }


    if (props?.feeLiability) {
      const remainingLiability = Math.max(Number(props?.feeLiability) - Number(total_adjusted_amount), 0);
      setTotalRemainingLiability(remainingLiability);
    }

    //eslint-disable-next-line
  }, [data, props?.feeLiability])


  return (
    <>
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {confirmPopup &&
        <ConfirmAlertPopup
          show={true}
          handleClose={() => {
            setConfirmPopup(false);
            setDeleteChallanId(null);
          }}
          handleProceed={() => {
            setConfirmPopup(false);
            setDeleteChallanId(null);
          }}
          //confirmText={`Are you sure that you want to remove this Challan for allocation ? Please note that the process is irreversible. Click Proceed button to continue.`}
          confirmText={confirmsg}
          handleProceed2={() => {
            setConfirmPopup(false);
            deleteChallan();
          }}
          confirmButtonText={`Cancel`}
          confirmButtonText2={`Proceed`}
        />
      }

      <WindowWrapperFixed
        title={
          <h4 className="modal-title comphealth_tdscomp_allocatefees_title w-100">
            <div class="row">
              <div class="col-md-6">
                <span class="comphealth_tdscomp_allocatefees_textfs">
                  Allocation of Fees
                </span>
              </div>
              <div class="col-md-6 text-right">
                <span class="comphealth_tdscomp_allocatefees_totalefees">
                  Total Fees Liability =
                  <span class="comphealth_tdscomp_allocatefees_textblue">
                    {' '}{formatNumber(feeLiability)}
                  </span>
                </span>
              </div>
            </div>
          </h4>
        }
        onClose={onClose}
        totalPopups={1}
        index={1}
        initialHeight={'auto'}
        initialLeft={400}
        isModalView={true}
        initialWidth={850}
      >

        <div className="row pt-1 pb-0">
          <div className="col-md-12">
            <div className='card p-0'>
              <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                {
                  showSkeleton ? <Skeleton12ColumnGrid />
                    : <>
                      <Tooltip anchorElement="pointer" position="right">
                        <Grid
                          className={`table table-striped fee-allocations mb-0`}
                          {...dataState}
                          onDataStateChange={(event) => dataStateChange(event.dataState)}
                          data={data}
                          filterOperators={{
                            text: [
                              { text: 'grid.filterContainsOperator', operator: 'contains' },
                            ],
                            numeric: [
                              { text: 'grid.filterGteOperator', operator: 'gte' },
                              { text: 'grid.filterLteOperator', operator: 'lte' },
                              { text: 'grid.filterLtOperator', operator: 'lt' },
                              { text: 'grid.filterGtOperator', operator: 'gt' },
                              { text: 'grid.filterEqOperator', operator: 'eq' },
                              { text: 'grid.filterNotEqOperator', operator: 'neq' },
                            ],
                            date: [
                              { text: 'grid.filterGteOperator', operator: 'gte' },
                              { text: 'grid.filterLteOperator', operator: 'lte' },
                              { text: 'grid.filterLtOperator', operator: 'lt' },
                              { text: 'grid.filterGtOperator', operator: 'gt' },
                              { text: 'grid.filterEqOperator', operator: 'eq' },
                            ],
                          }}
                          rowRender={rowRender}
                          sortable={false}
                          style={{ maxHeight: '250px', overflowX: 'auto' }}
                        >
                          <Column
                            width="100"
                            field="challan_no"
                            title="Challan"
                            filter="text"
                            headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                          />
                          <Column
                            width="100"
                            field="deposit_date"
                            title="Deposit Date"
                            filter="date"
                            headerClassName={isColumnActive('deposit_date') ? 'active' : ''}
                          />
                          <Column
                            width="100"
                            field="bsr_code"
                            title="BSR Code"
                            filter="text"
                            headerClassName={isColumnActive('bsr_code') ? 'active' : ''}
                          />
                          <Column
                            width="150"
                            field="total_amtp"
                            title="Deposited Total"
                            filter="numeric"
                            headerClassName={isColumnActive('total_amtp') ? 'active' : ''}
                          />
                          <Column
                            width="150"
                            field="fees_amtp"
                            title="Rem. Fees"
                            filter="numeric"
                            headerClassName={isColumnActive('fees_amtp') ? 'active' : ''}
                          />
                          <Column
                            width="160"
                            field="allocated_fee_amtp"
                            title="Allocate Fees"
                            filter="numeric"
                          />
                          <Column
                            width="50"
                            headerClassName={`text-center`}
                            sortable={false}
                            title={null}
                          />
                        </Grid>
                      </Tooltip>
                    </>
                }

              </div>
              {
                data.length > 0 &&
                <div>
                  <table className="table table-striped comphealth_tdscomp_allocatefees_tb mb-0">
                    <tr>
                      <td width="600" className="text-right totaladjust_textclrblod" style={{ borderTop: '0px' }}>Mapped</td>
                      <td width="160" className="text-right totaladjust_textclrblodred" style={{ borderTop: '0px' }}>
                        {totalAdjustedAmount}
                        {/* <input value={totalAdjustedAmount} readOnly style={{ height: 'calc(1.42857em + 8px)', fontSize: '11px', fontWeight: '500' }} type="text" className="form-control text-right" width={100} /> */}
                      </td>
                      <td width="50" style={{ borderTop: '0px' }}></td>
                    </tr>
                    <tr>
                      <td width="500" className={`text-right totaladjust_textclrblodred ${Number(totalRemainingLiability) > 0 ? 'red_text' : ''}`} style={{ borderTop: '0px' }}>Unmapped</td>
                      <td width="100" className={`text-right totaladjust_textclrblodred ${Number(totalRemainingLiability) > 0 ? 'red_text' : ''}`} style={{ borderTop: '0px' }}>{formatNumber(totalRemainingLiability)} </td>
                      <td width="50" style={{ borderTop: '0px' }}></td>
                    </tr>
                  </table>
                </div>
              }

            </div>
          </div>
          {
            data.length > 0 &&
            <div className="col-md-12 text-left pb-1">
              <div class="row pb-1">
                <div class="col-md-12 ">
                  <p class="comphealth_tdscomp_allocatefees_remember_textfs" style={{ color: 'black', fontWeight: 'bold' }}>
                    Hint : Edit the Last column to allocate Fees and Click Save!
                  </p>
                </div>
                <div class="col-md-12 text-center">
                  {
                    showSaveButton
                      ?
                      <button class={`btn comphealth_tdscomp_allocatefees_savebtn ml-2 disabled={loading}`} onClick={() => saveAllocation()}>
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            {' '}
                            Loading...
                          </>
                        ) : 'Save'}
                      </button>
                      : <button class={`btn comphealth_tdscomp_allocatefees_savebtn ml-2 disabled`}> Save </button>
                  }
                </div>
              </div>
            </div>
          }

        </div>
      </WindowWrapperFixed>

    </>

  );
};

export default ChallanFeeAllocationPopup;
