export const deducteeFormFields = {
  deductee_name: '',
  pan: '',
  ref_number: '',
  firm_name: '',
  deductee_type: 'OTHER_THAN_COMPANY',
  gender: '',
  dob: null,
  employee_id: '',
  deductee_status: 1,
  address: '',
  city: '',
  state_id: '',
  pincode: '',
  mobile_no: '',
  email: '',
  non_resident: false,
  permanent_establishment: true,
  salary_category: ''
};

export const getDeducteeObject = (data) => {
  
  const deducteeData = {
    deductee_name: data.deductee_name,
    pan: data.pan,
    ref_number: data.ref_number,
    firm_name: data.firm_name,
    deductee_type: data.deductee_type,
    deductee_status: (data.deductee_status ? 1 : 0),
    gender: data.gender,
    dob: data.dob,
    employee_id: data.employee_id,
    address: data.address,
    city: data.city,
    state_id: data.state_id,
    tax_identification_no: data.tax_identification_no,
    country_id: data.country_id,
    non_resident: (data.non_resident === true ? true : false),
    permanent_establishment: (data.permanent_establishment === true ? true : false),
    salary_category: data.salary_category,
    mobile_no: data.mobile_no,
    email: data.email,
    tax_regime:(data.tax_regime ==="OLD" || data.tax_regime ==="NEW") ? data.tax_regime :null

  };

  return deducteeData;
};

export const getFormObject = (data) => {
  //let dobStr = '';

  /*if (data.dob) {
    const d = new Date(data.dob);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

    dobStr = `${ye}-${mo}-${da}`;
  }*/
  const deducteeInfo = { ...deducteeFormFields };
  deducteeInfo.deductee_name = data?.deductee_name;
  deducteeInfo.pan = data?.pan;
  deducteeInfo.ref_number = data?.ref_number;
  deducteeInfo.firm_name = data?.firm_name;
  deducteeInfo.deductee_type = data?.deductee_type_code ?? "";
  deducteeInfo.gender = data?.gender ?? "";
  deducteeInfo.dob = data?.dob;
  deducteeInfo.employee_id = data?.employee_id;
  deducteeInfo.deductee_status = data?.deductee_status;
  deducteeInfo.address = data?.address;
  deducteeInfo.city = data?.city;
  deducteeInfo.state_id = data?.state_id ?? '';
  deducteeInfo.tax_identification_no = data?.tax_identification_no;
  deducteeInfo.country_id = data?.country_id ?? '';
  deducteeInfo.mobile_no = data?.mobile_no ?? '';
  deducteeInfo.email = data?.email;
  deducteeInfo.non_resident = data?.non_resident;
  deducteeInfo.permanent_establishment = data?.permanent_establishment;
  deducteeInfo.salary_category = data?.user_salary_category ? data?.user_salary_category : data?.salary_category;
  deducteeInfo.tax_regime =(data?.tax_regime ==="OLD" || data?.tax_regime ==="NEW") ? data?.tax_regime :null;
  deducteeInfo.name_on_pan = data?.name_on_pan ?? ""
  return deducteeInfo;
};
