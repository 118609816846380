/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CreateRaiseRequestFormPopup from '../../components/createRaiseRequestFormPopup/CreateRaiseRequestFormPopup';
import { raiseRequestPopupFields } from '../../components/createRaiseRequestFormPopup/RaiseRequestFormPopupHelper';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { raiseRequestViaConnector } from '../../api/returnAPI';
import { raiseRequestResetFields } from '../../components/createRaiseRequestFormPopup/RaiseRequestFormPopupHelper';
import { HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import { raiseRequestFromConnector } from '../../api/localConnectorAPI';

const AddRaiseRequestFormPopup = (props) => {
  const {
    onClose,
    deductorId,
    returnId,
    importFUVFileValues,
    importFUVFile,
    submitBtnProceed,
    submitUnsetBtnProceed,
    handleProceedImportFUVFile2,
    fetchPreRaiseRequestData,
    setInputValFunc,
    inputVal, captchaRef, port, setSuccess, preDefineRaiseData = null, handleCallOnSuccess = null, checkJustification, checkConso 
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successPopup, setSuccessPopup] = useState(null);
  const [requestFormFieldInfo, setRequestFormFieldInfo] = useState(raiseRequestPopupFields);


  async function createRaiseRequest(data, captchaRef) {
    setError(null);
    setSuccessPopup(null);
    const raiseRequestData = raiseRequestResetFields;
    raiseRequestData.deductor_id = deductorId;
    raiseRequestData.captcha_ref = captchaRef ?? null;
    raiseRequestData.financial_year = data?.fy ?? null;
    raiseRequestData.quarter = data?.quarter ?? null;
    raiseRequestData.form_type = data?.ft ?? null;
    raiseRequestData.token = data?.token ?? null;
    raiseRequestData.request_for = data?.request_type.length > 0 ? data.request_type.map(function (item) { return item.toUpperCase() }) : [];
    if (data?.form1input === '1') {
      const challan_details = {}
      challan_details.bsr_code = data?.bsr_code ?? null;
      challan_details.deposit_date = data?.deposit_date ?? null;
      challan_details.challan_no = data?.challan_no ?? null;
      challan_details.challan_amtp = data?.challan_amount ?? null;
      raiseRequestData.challan_details = challan_details;
      raiseRequestData.nil_challan = null;
      raiseRequestData.book_entry_details = null;

    }
    if (data?.form2input === '1') {
      const book_entry_details = {}
      book_entry_details.bsr_code = data?.bsr_code2 ?? null;
      book_entry_details.deposit_date = data?.deposit_date2 ?? null;
      book_entry_details.ddo_no = data?.government_deductor_ddo_number ?? null;
      book_entry_details.transfer_amtp = data?.transfer_voucher_amount ?? null;
      raiseRequestData.book_entry_details = book_entry_details;
      raiseRequestData.challan_details = null;
      raiseRequestData.nil_challan = null;

    }
    if (data?.form3input === '1') {
      raiseRequestData.nil_challan = 1;
      raiseRequestData.book_entry_details = null;
      raiseRequestData.challan_details = null;
    }
    if (data?.form4input === '1') {
      let transaction_details = []
      transaction_details[0] = { pan: data?.pan_no[0] ?? '', tax_amtp: data?.tax_deducted[0] ?? 0 }
      transaction_details[1] = { pan: data?.pan_no[1] ?? '', tax_amtp: data?.tax_deducted[1] ?? 0 }
      transaction_details[2] = { pan: data?.pan_no[2] ?? '', tax_amtp: data?.tax_deducted[2] ?? 0 }

      transaction_details = transaction_details.filter((item) => {
        if (item.pan.length > 0) return true;
        return false
      })
      raiseRequestData.transaction_details = transaction_details;
      raiseRequestData.nil_transaction = 0;

    }
    if (data?.form5input === '1') {
      raiseRequestData.nil_transaction = 1;
      raiseRequestData.transaction_details = null;
    }

    async function convertData() {
      setLoading(true);
      try {
        const result = await raiseRequestViaConnector(raiseRequestData)
        const responseData = result.data ?? null;
        const responseHeader = result.headers ?? null;
        //Connector request data
        const connectorRequest = {
          "data": responseData.data
        };
        let extraHeader = {};
        extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

        try {
          const connectorResponse = await raiseRequestFromConnector(port, connectorRequest, extraHeader);
          if (Number(connectorResponse.status) === 1) {
            setSuccess(connectorResponse?.message);
            onClose();
            if (preDefineRaiseData) handleCallOnSuccess();
          }
        } catch (err) {
          setError(err.message[0]);
        } finally {
          setLoading(true);
        }

      } catch (err) {
        setError(err.message);
      }
      finally {
        setLoading(false);
      }
    }
    convertData();
  }

  useEffect(() => {
    if (Object.keys(importFUVFileValues).length > 0) {
      if (importFUVFileValues.data && importFUVFileValues.data.prn_no) {
        raiseRequestPopupFields.token = importFUVFileValues.data.prn_no;
      }
      if (importFUVFileValues?.data && importFUVFileValues.data?.challan_details) {
        raiseRequestPopupFields.challan_no = importFUVFileValues.data.challan_details?.challan_no ?? null;
        raiseRequestPopupFields.bsr_code = importFUVFileValues.data.challan_details?.bsr_code ?? null;;
        raiseRequestPopupFields.bsr_code2 = importFUVFileValues.data.challan_details?.bsr_code ?? null;
        raiseRequestPopupFields.deposit_date = importFUVFileValues.data.challan_details?.deposit_date ?? null;;
        raiseRequestPopupFields.deposit_date2 = importFUVFileValues.data.challan_details?.deposit_date ?? null;;
        raiseRequestPopupFields.challan_amount = importFUVFileValues.data.challan_details?.challan_amount ?? null;
      }
    }
    if (importFUVFileValues.data && importFUVFileValues.data.deductee_transactions && importFUVFileValues.data.deductee_transactions.length > 0) {
      const panNoArray = [];
      const taxDeductedArray = [];
      importFUVFileValues.data.deductee_transactions.forEach((value, index) => {
        panNoArray[index] = value.pan_no;
        taxDeductedArray[index] = value.tax_deducted;
      });
      raiseRequestPopupFields.pan_no = panNoArray;
      raiseRequestPopupFields.tax_deducted = taxDeductedArray;
    }
    setRequestFormFieldInfo({ ...requestFormFieldInfo, ...raiseRequestPopupFields });
  }, [importFUVFileValues]);


  return (

    <div>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={successPopup}
        onClose={() => { setError(null); setSuccessPopup(null); }}
      />

      <CreateRaiseRequestFormPopup
        onClose={() => onClose()}
        createRaiseRequest={createRaiseRequest}
        returnId={returnId}
        deductorId={deductorId}
        raiseRequestFormInfo={requestFormFieldInfo}
        importFUVFile={importFUVFile}
        submitBtnProceed={submitBtnProceed}
        submitUnsetBtnProceed={submitUnsetBtnProceed}
        handleProceedImportFUVFile2={handleProceedImportFUVFile2}
        fetchPreRaiseRequestData={fetchPreRaiseRequestData}
        setInputValFunc={setInputValFunc}
        inputVal={inputVal}
        captchaRef={captchaRef}
        port={port}
        preDefineRaiseData={preDefineRaiseData}
        checkJustification={checkJustification}
        checkConso = {checkConso}
      />

    </div>

  );
};

export default AddRaiseRequestFormPopup;
