import React from 'react'
import { formatNumber } from '../../../utils/UtilityFunctions'

const ViewIncOthSrc = (props) => {
    const { data, headingCodeListothincoff } = props;

    return (
        <>
            <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse " data-toggle="collapse" href="#salarytaxregime_edit_mastercollap02" aria-expanded="true"></span>

                </th>
                <th width="550" className="text-left">
                    Income From Other Sources 192(2B)
                </th>
                <th width="150" className="text-right">
                    {formatNumber(data.total_other_income_offered_amtp ?? 0)}
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_mastercollap02" className="in collapse show">

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>
                                    {data?.other_income_offered_details?.length > 0 &&
                                        data.other_income_offered_details.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td width="10">

                                                    </td>
                                                    <td width="380" className="text-left">
                                                        {headingCodeListothincoff?.get(item.particular_code)?.description ?? ""}
                                                    </td>
                                                    <td width="150" className="text-right">
                                                        {formatNumber(item.amount_amtp ?? 0)}
                                                    </td>
                                                </tr>
                                            )
                                        })}



                                </tbody>
                            </table>
                        </div>


                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewIncOthSrc