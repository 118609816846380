import React from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';
import { perquisites_17_2DROPDOWN } from '../salaryHelper';

const Prequisites17Two = (props) => {
    const { data, headingCodeList17two, handleArrayTypeInputChange, handleArrayNumberInputOnBlur,
        fixedkey17two, deleteIcon, handleArrayObjDel, drowdownList, handleDrpdwn17two, USER_TYPE
    } = props;
    return (
        <>
            <tr className="">
                <th width="10" id="accordion" className="accordion">
                    <span className="salarytaxregime_edit_collapse collapsed" data-toggle="collapse" href="#salarytaxregime_edit_collap02" aria-expanded="false"></span>
                </th>
                <th className="text-left">
                    Prequisites 17(2)
                </th>
                <th className="text-right">
                    <BlurOnChangeInput
                        type="text"
                        className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                        value={FormatNumberDecimal(data.perquisites_amtp ?? 0, ...Array(3))}
                        data-toggle="tooltip" data-placement="top" title={""}
                        placeholder=""
                        disabled

                    />
                </th>

            </tr>

            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_collap02" className="in collapse">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            {USER_TYPE === "Details" ?
                                <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                    <thead>
                                        <tr>
                                            <th width="30"></th>
                                            <th width="200" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop">Particulars</th>
                                            <th width="80" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop">Value of Perquisite</th>
                                            <th width="80" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop">Amt, if any recovered from  employee</th>
                                            <th width="80" className="salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_bordertop  salarytaxreg_innertb_borderright">Taxable</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.perquisite_breakup_amtp?.length > 0 &&
                                            data?.perquisite_breakup_amtp.map((item, index) => {
                                                return (
                                                    <tr className="salarytaxregime_edit_alternetbgclr_grey" key={index}>
                                                        <td width=""></td>
                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft">
                                                            {headingCodeList17two?.get(item?.particular_code)?.description ?? ""}
                                                        </td>
                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell">
                                                            <BlurOnChangeInput
                                                                type="text"
                                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                                value={FormatNumberDecimal(item.perquisite_amtp, ...Array(3))}
                                                                data-toggle="tooltip" data-placement="top" title={""}
                                                                placeholder=""
                                                                onChange={(e) => {
                                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                                    handleArrayTypeInputChange("perquisite_breakup_amtp", index, "perquisite_amtp", value)
                                                                }}
                                                                onBlur={(e) => handleArrayNumberInputOnBlur("perquisite_breakup_amtp", index, "perquisite_amtp", item.perquisite_amtp)}
                                                            />
                                                        </td>
                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell">
                                                            <BlurOnChangeInput
                                                                type="text"
                                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                                value={FormatNumberDecimal(item.recovered_amtp, ...Array(3))}
                                                                data-toggle="tooltip" data-placement="top" title={""}
                                                                placeholder=""
                                                                onChange={(e) => {
                                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                                    handleArrayTypeInputChange("perquisite_breakup_amtp", index, "recovered_amtp", value)
                                                                }}
                                                                onBlur={(e) => handleArrayNumberInputOnBlur("perquisite_breakup_amtp", index, "recovered_amtp", item.recovered_amtp)}
                                                            />
                                                        </td>
                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_borderright">
                                                            <span className="d-flex">

                                                                <BlurOnChangeInput
                                                                    type="text"
                                                                    className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                                                                    value={convertNum(item.perquisite_amtp) - convertNum(item.recovered_amtp)}
                                                                    data-toggle="tooltip" data-placement="top" title={""}
                                                                    placeholder=""
                                                                    disabled
                                                                />
                                                                 <span className="deleicon_pos">
                                                                    {!fixedkey17two?.[item.particular_code] &&
                                                                        <img src={deleteIcon} alt="addicon"
                                                                            width="16"
                                                                            onClick={() => {
                                                                                handleArrayObjDel("perquisite_breakup_amtp", index, "perquisites_17_2", perquisites_17_2DROPDOWN, "particular_code");
                                                                            }}
                                                                            style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                        />
                                                                    }
                                                                </span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        {drowdownList?.perquisites_17_2?.length > 0 && USER_TYPE === "Details" ?
                                            <tr className="salarytaxregime_edit_alternetbgclr_white">
                                                <td width=""></td>
                                                <td width="" colSpan={3} className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom align-bottom">
                                                    <span className="" style={{ display: "flex" }}>
                                                        <DropDownList
                                                            data={drowdownList?.perquisites_17_2 ?? []}
                                                            className="header_select headerselect w-100 drpdwnlist_changedesign"
                                                            popupSettings={{ className: 'headerselectoption' }}
                                                            textField="description"
                                                            value={{description: "Add additional detail", particular_code: ""}}
                                                            onChange={(e) => {
                                                                handleDrpdwn17two(e.target.value);
                                                            }}
                                                            placeholder="Add additional detail"
                                                            dataItemKey="particular_code"
                                                            id="yearDropDownPopup"
                                                        />
                                                    </span>
                                                </td>

                                                <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom">
                                                    <BlurOnChangeInput
                                                        type="text"
                                                        className={`form-control salarytaxregime_edit_inputbox text-right disabled`}
                                                        data-toggle="tooltip" data-placement="top" title={""}
                                                        placeholder=""
                                                        disabled
                                                    />
                                                </td>


                                            </tr>
                                            :
                                            <tr className="salarytaxregime_edit_alternetbgclr_white">
                                                <td></td>
                                                <td colSpan={4} className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom"></td>
                                            </tr>
                                        }


                                    </tbody>
                                </table>
                                :
                                <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                    <thead>
                                        <tr>
                                            <th width="50"></th>
                                            <th width="550" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop">Particulars</th>
                                            <th width="120" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_bordertop salarytaxreg_innertb_borderright">Value of Perquisite</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.perquisite_breakup_amtp?.length > 0 &&
                                            data?.perquisite_breakup_amtp.map((item, index) => {
                                                return (
                                                    <tr className="salarytaxregime_edit_alternetbgclr_grey" key={index}>
                                                        <td width=""></td>
                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell    salarytaxreg_innertb_borderleft ">
                                                            {headingCodeList17two?.get(item?.particular_code)?.description ?? ""}
                                                        </td>

                                                        <td width="" className="salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_borderright">
                                                            <span className="d-flex">

                                                                <BlurOnChangeInput
                                                                    type="text"
                                                                    className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                                    value={FormatNumberDecimal(item.perquisite_amtp, ...Array(3))}
                                                                    data-toggle="tooltip" data-placement="top" title={""}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                                        handleArrayTypeInputChange("perquisite_breakup_amtp", index, "perquisite_amtp", value)
                                                                    }}
                                                                    onBlur={(e) => handleArrayNumberInputOnBlur("perquisite_breakup_amtp", index, "perquisite_amtp", item.perquisite_amtp)}
                                                                />
                                                                {USER_TYPE === "Details" && <span className="deleicon_pos">
                                                                    {!fixedkey17two?.[item.particular_code] &&
                                                                        <img src={deleteIcon} alt="addicon"
                                                                            width="16"
                                                                            onClick={() => {
                                                                                handleArrayObjDel("perquisite_breakup_amtp", index, "perquisites_17_2", perquisites_17_2DROPDOWN, "particular_code");
                                                                            }}
                                                                            style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                        />
                                                                    }
                                                                </span>}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        <tr className="salarytaxregime_edit_alternetbgclr_white">
                                            <td></td>
                                            <td colSpan={4} className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom"></td>
                                        </tr>
                                    </tbody>
                                </table>}
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default Prequisites17Two