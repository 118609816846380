import React from 'react';
import editIcoon from '../../../images/edite02.png';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '../../../app/constants';

const AuthorizedSignatoryV1 = (props) => {
    const { deductorDetail, params } = props

    return (
        <>
            <div className="col-12 col-xl-5 col-lg-5 col-md-6 col-sm-6">
                <fieldset className="authsing_fs h-100 pb-0">
                    <legend className="authsing_leg mb-0">
                        <h4 className="mb-0 pt-0">
                            Authorized Signatory

                            <span className="pl-1">
                                <Link
                                    className=""
                                    to={`${BASE_PATH}edit-deductor/${params.deductor_id}`}
                                    tabIndex="-1"
                                    target={'_blank'}
                                >
                                    <img src={editIcoon} alt="edit icon" width="16" />
                                </Link>
                            </span>
                        </h4>
                    </legend>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-check authsing_radiobox">
                                <label className="form-check-label">

                                    {deductorDetail?.responsible_person?.contact_info?.person_name ?? ""}
                                    <br />
                                    <span className="efilingflow_textclr_coral"> {deductorDetail?.responsible_person?.pan ? (deductorDetail?.responsible_person?.pan ?? "") : ""}</span>  
                                    <br />
                                    <span>{deductorDetail?.responsible_person?.contact_info?.primary_mobile_no ?? ""}</span>
                                    <br />
                                    <span className="efilingflow_textclr_coral">
                                        <span>{deductorDetail?.responsible_person?.contact_info?.primary_email}</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )
}

export default AuthorizedSignatoryV1