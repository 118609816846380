/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import $ from 'jquery';

const WindowWrapper = (props) => {
  const { title, onClose, totalPopups, index, initialWidth = 1250, initialHeight = 350, initialLeft = 10, initialTop = 0, isModalView = false, style } = props;
  const [width, setWidth] = useState(initialWidth);
  const [top, setTop] = useState(initialTop);
  const [left, setLeft] = useState(initialLeft);
  useEffect(() => {
    let top = totalPopups === 1 ? initialTop : initialHeight;
    setTop(10 + top * index + 2 * index)

  }, [index, totalPopups]);

 
  return (
    <Window
      title={title}
      onClose={onClose}
      initialHeight={initialHeight ? initialHeight : 350}
      initialWidth={width}
      left={left}
      top={top}
      width={width}
      className={`windowWrapper`}
      modal={isModalView}
      onStageChange={(event) => {
        if (event.state === 'MINIMIZED') {

          setTop($(window).height() - 50);

          let wdth = totalPopups === 1 ? initialWidth : 700;
          setWidth(wdth);
          setLeft(10 + wdth * index + 2 * index);

        } else if (event.state === 'DEFAULT') {

          let top = totalPopups === 1 ? 0 : 350;
          setTop(10 + top * index + 2 * index)

          setWidth(initialWidth);
        }

      }}
      onResize={(e) => {
        setWidth(e.width);
      }}
      onMove={(e) => {
        setLeft(Math.min( window.innerWidth - e.width, e.left))
        setTop(Math.min( window.innerHeight - e.height, e.top))
      }}
      style={{ zIndex: 100, ...style }}
    >
      {props.children}
    </Window>
  );
};

export default WindowWrapper;