import React, { useEffect, useState } from 'react';
import ReturnFlowTimeLine from '../../deducteeTransactions/ReturnFlowTimeLine';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import TdsMappingSectionWise from './TdsMappingSectionWise';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//     faFilter
// } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import "./ReturnFilingTdsMapping.scss";
import TdsComputationSummary from './TdsComputationSummary';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ShowAlert from '../../../components/ShowAlert';
import { getNatureOfPaymentCodes } from '../../../api/challanAPI';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import TdsMappingMonthWisePopup from './TdsMappingMonthWisePopup';
import { selectHeaderData } from '../../header/headerSlice';
import MappFiltericonBlue from "../../../images/filtericon_blue.png";
import MappFiltericoncoral from "../../../images/filtericon_coral.png";

const ReturnFilingTdsMapping = () => {
    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);
    const history = useHistory();
    const headerDataDetails = useSelector(selectHeaderData);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [dragArray, setDragArray] = useState({});
    const [naturePaymentList, setNaturePaymentList] = useState([]);
    const [monthWisePopup, setMonthWisePopup] = useState(false);
    const [challanftr, setChallanFtr] = useState(null);
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [formType, setFormType] = useState('');
    const [consumedAmt, setConsumedAmt] = useState(0);

    const slideUrlUpdate = (intFilter) => {
        let searchStr = '';

        searchStr = `?return_id=${params.return_id}&deductor_id=${params.deductor_id}&filtertable=${params.filtertable ?? "sectionwise"}`;
        if (intFilter) searchStr += `&interestFilter=${intFilter}`

        history.push(searchStr)
    }

    const createHistoryUrlSection = (dataState = [], filtername) => {

        let filterStr = `?return_id=${params.return_id}&deductor_id=${params.deductor_id}&filtertable=${filtername ?? "sectionwise"}`;
        if (params.interestFilter) filterStr += `&interestFilter=${params.interestFilter}`

        if (dataState?.sort?.length > 0 || dataState.filter) {
            filterStr += `&sectionwisefilter=${btoa(JSON.stringify(dataState))}`;
        }

        if (params.monthwisefilter) {
            filterStr += `&monthwisefilter=${params?.monthwisefilter}`
        }

        history.push(filterStr);
    };


    const createHistoryUrlMonth = (dataState = [], filtername) => {

        let filterStr = `?return_id=${params.return_id}&deductor_id=${params.deductor_id}&filtertable=${filtername ?? "monthwise"}`;
        if (params.interestFilter) filterStr += `&interestFilter=${params}`

        if (dataState?.sort?.length > 0 || dataState.filter) {
            filterStr += `&monthwisefilter=${btoa(JSON.stringify(dataState))}`;
        }

        if (params.sectionwisefilter) {
            filterStr += `&sectionwisefilter=${params?.sectionwisefilter}`
        }

        history.push(filterStr);
    };

    // getNatureOfPaymentCodes
    const getNaturePaymentAll = async () => {
        try {
            const result = await getNatureOfPaymentCodes();
            if (result?.data?.length > 0) {
                result.data.forEach((item) => {
                    item.label = item.code;
                    item.value = item.nature_of_payment_id;
                })
            }
            setNaturePaymentList(result?.data ?? []);
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        }
    }


    useEffect(() => {
        getNaturePaymentAll();
    }, []);

    useEffect(() => {
        setFormType(headerDataDetails?.form_type);
    }, [headerDataDetails?.form_type])


    const getTableTitle = (formType) => {
        switch (formType) {
            case '24Q': return 'Employee Transactions';
            case '27EQ': return 'Collectee Transactions';
            case '26Q':
            case '27Q':
            default: return 'Deductee Transactions';
        }
    }

    return (
        <>

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {showMoreLoading && <LoadingIndicator />}

            {monthWisePopup &&
                <TdsMappingMonthWisePopup
                    deductorId={params.deductor_id ?? ""}
                    success={success}
                    setSuccess={setSuccess}
                    error={error}
                    setError={setError}
                    history={history}
                    searchStr={searchStr}
                    params={params}
                    setLoading={setLoading}
                    createHistoryUrl={createHistoryUrlMonth}
                    naturePaymentList={naturePaymentList}
                    location={location}
                    loading1={loading}
                    showMoreLoading={showMoreLoading}
                    handleClose={() => setMonthWisePopup(false)}
                    show={monthWisePopup}
                />
            }


            <ReturnFlowTimeLine returnId={params.return_id} deductorId={params.deductor_id} currentPage={`TdsMappings`} />
            <div className="returnfilingTdsMappingscss">
                <div className="container-fluid pt-2">
                    <div className="row">
                        <div className="col-xl-8 col-lg-5 col-md-12 dedcuteetrans_mapping_left_colwidth">
                            <fieldset className='tdsmapping_deducteetrans_fs'>
                                <legend className='tdsmapping_deducteetrans_leg'>
                                    <div class="tdsmapping_deducteetrans_inndev">
                                        <div class="tdsmapping_deducteetrans_lefts">
                                            <h4 class="tdsmapping_deducteetrans_title">
                                                <span className="">{params?.filtertable === "monthwise" ? "Month - Section Wise" : getTableTitle(formType)} </span>
                                            </h4>
                                        </div>
                                        <div class="tdsmapping_deducteetrans_inner"></div>
                                        <div class="tdsmapping_deducteetrans_right">
                                            <span style={{ cursor: 'pointer' }} className="iconflipright table1_next tdsmapping_deducteetrans_monthwisebtn mr-2"
                                                onClick={() => {
                                                    setMonthWisePopup(true);
                                                }}>
                                                Month- Section wise
                                            </span>
                                            {params.filtertable !== "monthwise" && <span className="showtrans_textfsclr  tdsmapping_deducteetrans_filtewricon">
                                                <span data-toggle="tooltip" data-placement="top" title="Show transactions attracting Interest Due to Late Deposit of Challan">

                                                    <img alt="filter icon" src={params.interestFilter ? MappFiltericoncoral : MappFiltericonBlue} width={29} style={{position:'relative', top:'-6px'}}
                                                        onClick={() => {
                                                            const filterValue = params.interestFilter ? "" : "secIntfilter";
                                                            slideUrlUpdate(filterValue);
                                                            $(".all-parent-item").addClass("collapsed")
                                                            $(".main-parent-item").addClass("collapsed")
                                                            $(".all-child-item").hide()
                                                        }}
                                                    />

                                                    {/* <FontAwesomeIcon icon={faFilter} style={{ width: "15px", height: "15px", color: params.interestFilter ? "#fc7d72" : "#31719b" }}
                                                        onClick={() => {
                                                            const filterValue = params.interestFilter ? "" : "secIntfilter";
                                                            slideUrlUpdate(filterValue);
                                                            $(".all-parent-item").addClass("collapsed")
                                                            $(".main-parent-item").addClass("collapsed")
                                                            $(".all-child-item").hide()
                                                        }}
                                                    /> */}
                                                </span>

                                            </span>}
                                        </div>
                                        <div class="tdsmapping_deducteetrans_middleborder"></div>
                                    </div>
                                </legend>

                                <div className={`mapping_deducttrans_tb chart_1 ${params?.filtertable === "monthwise" ? "roate_div" : ""}`}>
                                    <div className="flip-card-front" style={{ zIndex: params?.filtertable !== "monthwise" ? '100' : '0' }}>
                                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                            <TdsMappingSectionWise
                                                deductorId={params.deductor_id ?? ""}
                                                success={success}
                                                setSuccess={setSuccess}
                                                error={error}
                                                setError={setError}
                                                history={history}
                                                searchStr={searchStr}
                                                params={params}
                                                setLoading={setLoading}
                                                createHistoryUrl={createHistoryUrlSection}
                                                dragArray={dragArray}
                                                setDragArray={setDragArray}
                                                naturePaymentList={naturePaymentList}
                                                location={location}
                                                loading1={loading}
                                                challanftr={challanftr}
                                                showMoreLoading={showMoreLoading}
                                                setShowMoreLoading={setShowMoreLoading}
                                                formType={formType}
                                                setConsumedAmt={setConsumedAmt}
                                            />
                                        </div>
                                    </div>

                                </div>



                            </fieldset>

                            <div className='row pt-2'>
                                <div className='col-md-12'>
                                    <div className='tdsmapping_mapp_unmapped_cardcolorbox'>
                                        <span className='card tdsmapping_mapp_compunmappedbox'>

                                        </span>
                                        <span className='tdsmapping_mapp_titlefs'>
                                            Partial/complete Unmapped
                                        </span>
                                    </div>
                                    <div className='tdsmapping_mapp_unmapped_cardcolorbox'>
                                        <span className='card tdsmapping_mapp_compmappedbox'>

                                        </span>
                                        <span className='tdsmapping_mapp_titlefs'>
                                            Mapped
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <TdsComputationSummary dragArray={dragArray} setShowMoreLoading={setShowMoreLoading} setDragArray={setDragArray} challanftr={challanftr} history={history} setChallanFtr={setChallanFtr} consumedAmt={consumedAmt} />
                    </div>
                </div>

                <div id="drag-element-tooltip-main" />

            </div>
        </>
    )
}

export default ReturnFilingTdsMapping
