import React, { useState, useEffect } from 'react';
// import FileUploadPopupV2 from './FileUploadPopupV2';
import FileUploadPopupV3 from './FileUploadPopupV3';
import ImportReturnProgress from './ImportReturnProgress';
import { selectHeaderData } from '../../header/headerSlice';
import { useSelector } from 'react-redux';
import { createImportRegularReturnLocalStorageKey } from '../../../utils/UtilityFunctions';
import UtilizationSummary from './UtilizationSummary';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';

const ImportReturnFromExcelV2 = ({ history, ...props }) => {

  const { show, handleClose, deductorId, returnId, importExelError, downloadExcelTemp = null,setDisabledTimeline } = props;
  const headerData = useSelector(selectHeaderData);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showFromUtilization, setShowFromUtlization] = useState(false)
  const [showFromErrorReImport, setShowFromErrorReImport] = useState(false)
  const [loading, setLoading] = useState(true);
  const [disableUnmap,setDisableUnmap] = useState(false);

  useEffect(() => {
    if (headerData?.mobile_number && headerData?.tan && headerData?.financial_year_formatted && headerData?.quarter && headerData?.form_type) {
      const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
      if (checkRunningImport) {
        setShowResult(true);
      } else {
        setShowFileUpload(true);

      }
    }
  }, [headerData])

  useEffect(() => {
    if (headerData?.mobile_number && headerData?.tan && headerData?.financial_year_formatted && headerData?.quarter && headerData?.form_type && show) {
      const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
      if (checkRunningImport) {
        setShowResult(true);
      } else {
        setShowFileUpload(true);

      }
    }
  }, [show, headerData]);
  return (

    <>
      {loading ? <LoadingIndicator /> : null}
      <UtilizationSummary deductorId={deductorId} returnId={returnId} disableUnmap={disableUnmap} setShowFromUtlization={setShowFromUtlization} headerData={headerData} setShowResult={setShowResult} setLoading1={setLoading} setDisabledTimeline={setDisabledTimeline}/>
      {showResult && <ImportReturnProgress
        show={showResult}
        handleClose={() => {
          setShowResult(false);
          handleClose();
        }}
        deductorId={deductorId}
        returnId={returnId}
        headerData={headerData}
        setShowFromErrorReImport={() => {
          setShowResult(false);
          handleClose();
          setShowFromErrorReImport(true);
        }}
      />}


      {((!showResult && show && showFileUpload) || showFromUtilization || showFromErrorReImport ) &&
        <FileUploadPopupV3                                     //FileUploadPopupV2 was used before but now V3 is used with some new changes
          key="1"
          show={showFileUpload}
          handleClose={() => {
            handleClose();
            setShowFromUtlization(false)
            setShowFromErrorReImport(false)
            setDisableUnmap(false)
          }}
          handleProceed={() => {
            setShowFileUpload(false);
            setShowResult(true);
            setShowFromUtlization(false)
            setShowFromErrorReImport(false)
            setDisableUnmap(true);
          }}
          deductorId={deductorId}
          returnId={returnId}
          importExelError={importExelError}
          downloadExcelTemp={downloadExcelTemp}
          showFromErrorReImport={showFromErrorReImport}
        />

      }
    </>
  )
};

export default ImportReturnFromExcelV2;
