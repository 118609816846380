/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

// -- Validation Rules
export const travelConcessionValidationSchema = Yup.object().shape({

    air_route_amtp: Yup.number().when('rail_connect_route_amtp', {
        is: 0,
        then: Yup.number().when('other_route_amtp', {
            is: 0,
            then: Yup.number().required('Route required').min(1, 'Route should be greater than 0')
        })
    }),

    rail_connect_route_amtp: Yup.number().required('Route required').min(0, 'Number should be greater Than or Equal to 0'),

    other_route_amtp: Yup.number().required('Route required').min(0, 'Number should be greater Than or Equal to 0'),

    actual_exp_paid_amtp: Yup.number().required('Actual Exp. Paid is required').min(1, 'Number should be greater Than to 0'),

    family_person: Yup.number().when('whether_cent_gov_emp', {
        is: 0,
        then: Yup.number().when('whether_cash_allowance', {
            is: 1,
            then: Yup.number().required('No of family person is required').min(1, 'Number should be greater Than to 0'),
        })
    }),

    exempt_amtp: Yup.number().required('Exempt amount is required').min(0, 'Number should be greater Than or Equal to 0'),

});


export const gratuityValidationSchema = Yup.object().shape({

    gratuity_received_amtp: Yup.number().required('Gratuity received is required').min(1, 'Number should be greater Than to 0'),

    service_period_year: Yup.number()
        .when('whether_gov_emp', {
            is: 0,
            then: Yup.number().when('whether_received_after_death', {
                is: 0,
                then: Yup.number().required('Service period is required').min(1, 'Number should be greater Than to 0')
            })
        }),

    service_period_month: Yup.number().required('Service period is required').min(0, 'Month should be greater Than or Equal to 0').max(11, 'Month should be less Than or Equal to 11'),

    last_month_salary_with_DA_amtp: Yup.number()
        .when('whether_gov_emp', {
            is: 0,
            then: Yup.number().when('whether_received_after_death', {
                is: 0,
                then: Yup.number().when('whether_gratuity_act_app', {
                    is: 1,
                    then: Yup.number().required('Last month salary with DA is required').min(1, 'Number should be greater Than to 0'),
                }),
            })
        }),

    avg_salary_amtp: Yup.number()
        .when('whether_gov_emp', {
            is: 0,
            then: Yup.number().when('whether_received_after_death', {
                is: 0,
                then: Yup.number().when('whether_gratuity_act_app', {
                    is: 0,
                    then: Yup.number().required('Avg salary is required').min(1, 'Number should be greater Than to 0'),
                }),
            })
        }),

    exempt_amtp: Yup.number().required('Exempt amount is required').min(0, 'Number should be greater Than or Equal to 0'),

});


export const commutedPensionValidationSchema = Yup.object().shape({

    commuted_pension_amtp: Yup.number().required('Commuted Pension is required').min(1, 'Number should be greater Than to 0'),
    commuted_pension_percent_b100: Yup.number().required('Commuted Pension % is required').min(1, 'Number should be greater Than to 0').max(100, 'Number should be less Than or Equal to 100'),
    exempt_amtp: Yup.number().required('Exempt amount is required').min(0, 'Number should be greater Than or Equal to 0'),

});


export const leaveSalaryEncashmentValidationSchema = Yup.object().shape({

    leaves_encas_rec_amtp: Yup.number().min(1, 'Number should be greater Than to 0').required('Leave Encashment Rec is required'),
    avg_10_month_salary_amtp: Yup.number().min(1, 'Number should be greater Than to 0').required('salary is required'),
    service_period_year: Yup.number().min(1, 'Year should be greater Than to 0').required('Year is required'),
    service_period_month: Yup.number().max(11, 'Month should be less Than or Equal to 11'),
    exempt_amtp: Yup.number().required('Exempt amount is required').min(0, 'Number should be greater Than or Equal to 0'),

});


export const houseRentYearlyDataValidationSchema = Yup.object().shape({


    details: Yup.object().shape({

        hra_received_amtp: Yup.number().required('HRA received is required').min(1, 'Number should be greater Than to 0'),

        rent_paid_amtp: Yup.number().required('Rent paid is required').min(1, 'Number should be greater Than to 0'),

        annual_salary_amtp: Yup.number().required('Annual Salary is required').min(1, 'Number should be greater Than to 0'),

        exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),

    })

});


export const houseRentMonthlyDataValidationSchema = Yup.object().shape({


    details: Yup.object().shape({
        4: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        5: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        6: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        7: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        8: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        9: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        10: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        11: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        12: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        1: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        2: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        }),
        3: Yup.object().shape({
            hra_received_amtp: Yup.number().required('HRA received is required').min(0, 'Number should be greater Than to 0'),
            rent_paid_amtp: Yup.number().required('Rent paid is required').min(0, 'Number should be greater Than to 0'),
            salary_amtp: Yup.number().required('Salary is required').min(0, 'Number should be greater Than to 0'),
            exempt_amtp: Yup.number().required('Exempt is required').min(0, 'Number should be greater Than to 0'),
        })
    }),

});
