import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const ReviewAndFixError = (props) => {
    const { genrateFvuErrors, viewError, callGenerateFVUFile } = props;

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <fieldset className="efiling_flow_downloaderror_fs">
                        <legend className="efiling_flow_downloaderror_leg">
                            <h4>
                                Review &amp; Fix Errors
                                <span className="reviewfix_errorcount" onClick={() => callGenerateFVUFile()}>
                                    ({genrateFvuErrors.length})
                                </span>
                            </h4>
                        </legend>

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <table className="table table-borderless comp_health_review_checkerrors_tb   mb-0">
                                <thead>
                                    <tr>
                                        
                                        <th width="100">Record Type</th>
                                        <th width="250">Field Name</th>
                                        <th width="100">Error Type</th>
                                        <th width="250">Error Description</th>
                                        <th width="20">View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {genrateFvuErrors ? genrateFvuErrors.map((errors, key) => (
                                        <tr key={key}>
                                            <td  className="text-left" width="100">
                                                {errors.section_or_record_type}
                                                {' '}
                                            </td>
                                            <td className="text-left" width="250">
                                                <span data-toggle="tooltip" data-placement="top" title={errors.field_name} >
                                                    {errors.field_name}
                                                </span>
                                            </td>
                                            <td className="text-left text_red" width="100">
                                                <span className="timeltdtextclr" style={{ color: errors.error_type && errors.error_type.color ? `#${errors.error_type.color}` : '' }}>
                                                    {' '}
                                                    {errors.error_type && errors.error_type.text ? errors.error_type.text : '-'}
                                                </span>                                        
                                            </td>
                                            <td className="text-left" width="250">
                                                {errors.error_code_and_description}
                                            </td>
                                            <td  className="text-center" width="20">
                                                <span className="timelionclr" onClick={() => viewError(errors)} style={(errors.entity_id && (errors.entity_type === "DEDUCTOR" || errors.entity_type === 'TRANSACTION' || errors.entity_type === 'CHALLAN' || errors.entity_type === 'SALARY' || errors.entity_type === 'DEDUCTEE')) ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                                                    <FontAwesomeIcon icon={faEye} style={{ cursor: "pointer", color: "#fc7d72" }} className="align-center" />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                        : null
                                    }
                                </tbody>
                            </table>
                        </div>

                    </fieldset>
                </div>
            </div>
        </>
    )
}

export default ReviewAndFixError