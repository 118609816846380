import React, { useState } from 'react';
import {
    Formik, Form, Field,
} from 'formik';
import DatePicker from '../../components/datePicker/DatePickerV2'
// '../datePicker/DatePickerV2';
import WindowWrapper from "../../components/grid/WindowWrapper";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { InsightFormValidationSchema } from './InsightFormValidationSchema';
import NumericInput from '../../components/NumericInput';
import { CONNECTOR_ERRORS_MAPPING, INSIGHT_NEW_REG_COUNTRTY_LIST, HEADER_CONNECTOR_DIGEST_KEY, INSIGHT_NEW_REG_STATE_LIST } from '../../app/constants';
import "./InsightPortalPopup.scss";
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import { verifyEFilingCredsFromConnector, verifyInsightsRegistrationFromConnector } from '../../api/localConnectorAPI';
import { getInsightsVerifyEfilingRegistration } from '../../api/deductorAPI';
import { convertNum, deepClone } from '../../utils/UtilityFunctions';
import SingleSelect from '../../components/singleSelect/SingleSelect';
import ConfirmAlertPopup from '../../components/ConfirmAlertPopup';

const InsightPortalPopup = (props) => {
    const { show, handleClose, alreadyFillData, itCredentional, deductorId, setSubmitDetailInsightPortal, setSuccess1, setInsightData } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [deductorInfo] = useState(alreadyFillData);
    const [startConnector, setStartConnector] = useState(false);
    const [, setPort] = useState(0);
    const [submitConfirmation, setSubmitConfirmation] = useState(false);


    const fetchSumbitDetail = async (port, submitData) => {
        setLoading(true);
        const request = {
            'itdportal_username': itCredentional.tan ?? '',
            'itdportal_password': itCredentional.efill_password ?? ''
        }
        try {
            const loginResult = await verifyEFilingCredsFromConnector(port, request);
            const captchaRef = loginResult.data.captcha_ref ?? "";
            const payload = {
                deductor_id: deductorId,
                captcha_ref: captchaRef,
                registration_details: deepClone(submitData),
            }

            try {
                const result = await getInsightsVerifyEfilingRegistration(payload);
                const EncryptLoginData = result.data
                const EncryptLoginDataHeader = result.headers ?? null;
                const extraHeader = {};

                const connectorRequest = {
                    "data": EncryptLoginData?.data
                };
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = EncryptLoginDataHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                try {
                    const connectorResult = await verifyInsightsRegistrationFromConnector(port, connectorRequest, extraHeader);
                    if (connectorResult.data) {
                        const successMessage = `Your registration request has been successfully submitted, and your reference number is ${connectorResult.data?.ref_no}. You will receive the ITDREIN and user credentials via email at ${connectorResult.data?.email}. Please enter these credentials in ExpressTDS to complete the compliance check.`
                        if (setSuccess1) setSuccess1(successMessage)
                        setSubmitDetailInsightPortal(true)
                        handleClose()
                        setInsightData(connectorResult?.data)
                    }

                } catch (err) {
                
                    if (err.message) {
                        setError(CONNECTOR_ERRORS_MAPPING[err.message?.[0]] ?? CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message);
                    } else {
                        setError(err);
                    }
                }
            } catch (err) {

                if (err.message) {
                    setError(err.message);
                } else {
                    setError(err);
                }
            }

        } catch (err) {
            if (err.message) {
                setError(CONNECTOR_ERRORS_MAPPING[err.message?.[0]] ?? CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING["default"]);

            } else {
                setError(err);
            }
        } finally {
            setLoading(false)
        }
    }

    const getCountryIndex = (value) => {
        const index = INSIGHT_NEW_REG_COUNTRTY_LIST.findIndex((item) => String(item.value) === String(value))
        if (index >= 0) return index;
        return null;
    }

    const getStateIndex = (value) => {
        const index = INSIGHT_NEW_REG_STATE_LIST.findIndex((item) => String(item.value) === String(value))
        if (index >= 0) return index;
        return null;
    }

    return (
        <>
            {loading ? <LoadingIndicator /> : null}

            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(''); setSuccess("") }}
            />
            {
                submitConfirmation &&
                <ConfirmAlertPopup
                    show={submitConfirmation}
                    handleClose={() => setSubmitConfirmation(false)}
                    handleProceed={() => {
                        setStartConnector(true);
                        setSubmitConfirmation(false);
                    }}
                    confirmText={"You are about to submit the details for Registration on Insights Portal. Kindly check before submitting"}
                    confirmButtonText={"Proceed"}
                    confirmButtonText2={"Relook"}
                    handleProceed2={() => {
                        setSubmitConfirmation(false);
                    }}
                />
            }

            {show &&
                <div className='insightportal_form_popup regist_on_insightsportal_modal'>

                    <Formik
                        initialValues={deductorInfo}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={InsightFormValidationSchema}
                        onSubmit={(values) => {
                            setSubmitConfirmation(true);
                        }}
                    >
                        {({
                            errors, values, setValues, setFieldValue, setFieldError

                        }) => (
                            <Form key={1} className="" autoComplete="off"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}>

                                <WindowWrapper
                                    title={
                                        <div className="col-md-12">
                                            <h6 className="modal-title">
                                                <span className="challanno_textfs">Registration On Insights Portal</span>
                                                <button class="btn btn-default regist_on_insightsportal_submitbtn" type='submit'>Submit</button>
                                            </h6>
                                        </div>
                                    }
                                    onClose={handleClose}
                                    totalPopups={1}
                                    index={0}
                                    initialWidth={1200}
                                    initialHeight={720}
                                    isModalView={true}
                                >

                                    {
                                        startConnector &&
                                        <ConnectorAdapter
                                            show={startConnector}
                                            handleClose={() => setStartConnector(false)}
                                            setPort={setPort}
                                            handleProceed={(port) => {
                                                fetchSumbitDetail(port, values)
                                            }}
                                        />
                                    }
                                    {/* <div class="row">
                                        <div class="col-md-12 text-right">
                                            <button class="btn btn-default regist_on_insightsportal_submitbtn" type='submit'>Submit</button>
                                        </div>
                                    </div> */}
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="regist_on_insightsportal_bodyscroll">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <fieldset class="Entity_details_fs">
                                                            <legend class="Entity_details_leg leg_entitydet_set">
                                                                <h4 class="Entity_details_titlefs">
                                                                    Entity Details
                                                                </h4>
                                                            </legend>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="form form-row Entity_details_frm">
                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">Name<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <Field
                                                                                type="text"
                                                                                id="name"
                                                                                name="name"
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={125}
                                                                            />
                                                                            {errors.name ? <span className="error_message">{errors.name}</span> : null}

                                                                        </div>
                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">PAN<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <Field
                                                                                type="text"
                                                                                id="pan"
                                                                                name="pan"
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={10}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value ? e.target.value.toUpperCase() : e.target.value;
                                                                                    setFieldValue("pan", value);
                                                                                }}
                                                                            />
                                                                            {errors.pan ? <span className="error_message">{errors.pan}</span> : null}

                                                                        </div>

                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">Flat/Door/Building<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <Field
                                                                                type="text"
                                                                                id="building"
                                                                                name="building"
                                                                                autocomplete="no"
                                                                                maxLength={75}
                                                                                className="form-control Entity_details_input"
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z0-9 ()\-,./"']+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("building", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.building ? <span className="error_message">{errors.building}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">Road/Street<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <Field
                                                                                type="text"
                                                                                id="street"
                                                                                name="street"
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={125}
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z0-9 ()\-,./"']+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("street", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.street ? <span className="error_message">{errors.street}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">Area/Locality<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <Field
                                                                                type="text"
                                                                                id="area"
                                                                                name="area"
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z0-9 ()\-,./"']+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("area", e.target.value)
                                                                                    }
                                                                                }}
                                                                                maxLength={75}
                                                                            />
                                                                            {errors.area ? <span className="error_message">{errors.area}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">Town/District/City<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <Field
                                                                                type="text"
                                                                                id="city"
                                                                                name="city"
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z ,]+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("city", e.target.value)
                                                                                    }
                                                                                }}
                                                                                maxLength={50}
                                                                            />
                                                                            {errors.city ? <span className="error_message">{errors.city}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">Pincode<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <Field
                                                                                type="text"
                                                                                id="pincode"
                                                                                name="pincode"
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={6}
                                                                            />
                                                                            {errors.pincode ? <span className="error_message">{errors.pincode}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">State<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <SingleSelect
                                                                                name="state_id"
                                                                                // className={`form-control Entity_details_input`}
                                                                                value={values?.state_id ? INSIGHT_NEW_REG_STATE_LIST[getStateIndex(values.state_id)] : null}
                                                                                onChange={(selected) => {
                                                                                    setFieldValue('state_id', selected.value);
                                                                                    if (convertNum(selected.value) === 99 && convertNum(values.country_id) === 91) {
                                                                                        setFieldValue("country_id", "");
                                                                                    } else if (convertNum(selected.value) !== 99 && convertNum(values?.country_id) !== 91) {
                                                                                        setFieldValue("country_id", 91);
                                                                                    }
                                                                                }}
                                                                                allowNumeric={false}
                                                                                options={INSIGHT_NEW_REG_STATE_LIST}
                                                                                placeholder="Select State"
                                                                            />
                                                                            {errors.state_id ? <span className="error_message">{errors.state_id}</span> : null}
                                                                        </div>
                                                                        <div class="form-group col-md-3">
                                                                            <label class="Entity_details_lebale" for="">Country<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-9">
                                                                            <SingleSelect
                                                                                name="country_id"
                                                                                // className={`form-control Entity_details_input`}
                                                                                value={values?.country_id ? INSIGHT_NEW_REG_COUNTRTY_LIST[getCountryIndex(values.country_id)] : null}
                                                                                onChange={(selected) => {
                                                                                    setFieldValue('country_id', selected.value);
                                                                                    if (selected.value && convertNum(selected.value) !== 91) {
                                                                                        setFieldValue('state_id', 99);
                                                                                    } else if (selected.value && convertNum(selected.value) === 91 && convertNum(values.state_id) === 99) {
                                                                                        setFieldValue('state_id', "");
                                                                                    }
                                                                                }}
                                                                                allowNumeric={false}
                                                                                options={INSIGHT_NEW_REG_COUNTRTY_LIST}
                                                                                placeholder="Select Country"
                                                                                isDisabled={values?.state_id && convertNum(values?.state_id) !== 99}
                                                                            />
                                                                            {errors.country_id ? <span className="error_message">{errors.country_id}</span> : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <fieldset class="Entity_details_fs principofficer_fs_borderclr">
                                                            <legend class="Entity_details_leg leg_principal_off">
                                                                <h4 class="Entity_details_titlefs">
                                                                    Principal Officer Details
                                                                </h4>
                                                            </legend>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="form form-row Entity_details_frm">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" style={{ color: "#222", fontWeight: "500", fontSize: "14px" }} for="">Authorized Person Type</label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="pan"
                                                                                name="pan"
                                                                                autocomplete="no"
                                                                                value={"Principal Officer"}
                                                                                className="form-control Entity_details_input"
                                                                                disabled
                                                                            />
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">PAN<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="pan"
                                                                                name="pan"
                                                                                autocomplete="no"
                                                                                value={values?.principal_officer_details?.pan ?? ""}
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={10}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value ? e.target.value.toUpperCase() : e.target.value;
                                                                                    setFieldValue("principal_officer_details.pan", value);
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.pan ? <span className="error_message">{errors.principal_officer_details?.pan}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Person First Name<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="first_name"
                                                                                name="first_name"
                                                                                value={values?.principal_officer_details?.first_name ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={70}
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z ,]+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("principal_officer_details.first_name", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.first_name ? <span className="error_message">{errors.principal_officer_details?.first_name}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Middle Name</label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="middle_name"
                                                                                name="middle_name"
                                                                                value={values?.principal_officer_details?.middle_name ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={70}
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z ,]+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("principal_officer_details.middle_name", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.middle_name ? <span className="error_message">{errors.principal_officer_details?.middle_name}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Last Name<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="last_name"
                                                                                name="last_name"
                                                                                value={values?.principal_officer_details?.last_name ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={70}                                                        
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z ,]+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("principal_officer_details.last_name", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.last_name ? <span className="error_message">{errors.principal_officer_details?.last_name}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Designation<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="designation"
                                                                                name="designation"
                                                                                value={values?.principal_officer_details?.designation ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={70}
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z0-9 ()\-,./"']+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("principal_officer_details.designation", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.designation ? <span className="error_message">{errors.principal_officer_details?.designation}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Access Type<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                className={`form-control deductordetails_inputbox `}
                                                                                id="access_type"
                                                                                name="access_type"
                                                                                value={"Operational"}
                                                                                disabled
                                                                            />

                                                                            {errors.principal_officer_details?.access_type ? <span className="error_message">{errors.principal_officer_details?.access_type}</span> : null}

                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Date of Birth</label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <DatePicker
                                                                                selectedDate={values?.principal_officer_details?.dob ? values?.principal_officer_details?.dob : null}
                                                                                placeHolder={"DD/MM/YYYY"}
                                                                                onChange={(date) => {
                                                                                    setFieldValue('principal_officer_details.dob', date);
                                                                                }}
                                                                            />

                                                                            {errors.principal_officer_details?.dob ? <span className="error_message">{errors.principal_officer_details?.dob}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Mobile Number<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <NumericInput
                                                                                type="text"
                                                                                className={`form-control Entity_details_input`}
                                                                                id="mobile_no"
                                                                                placeholder="Enter Mobile Number"
                                                                                name="mobile_no"
                                                                                maxLength={10}
                                                                                value={values?.principal_officer_details?.mobile_no === '+91' ? '' : values?.principal_officer_details?.mobile_no}
                                                                                onChange={(e) => {
                                                                                    setFieldValue('principal_officer_details.mobile_no', e.target.value)
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.mobile_no ? <span className="error_message">{errors.principal_officer_details?.mobile_no}</span> : null}

                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Email ID<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="email"
                                                                                name="email"
                                                                                value={values?.principal_officer_details?.email ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={125}
                                                                                onChange={(e) => {
                                                                                    setFieldValue('principal_officer_details.email', e.target.value)
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.email ? <span className="error_message">{errors.principal_officer_details?.email}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Alternate Email ID</label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="alternate_email"
                                                                                name="alternate_email"
                                                                                value={values?.principal_officer_details?.alternate_email ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={125}
                                                                                onChange={(e) => {
                                                                                    setFieldValue('principal_officer_details.alternate_email', e.target.value)
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.alternate_email ? <span className="error_message">{errors.principal_officer_details?.alternate_email}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Flat/Door/Building<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="building"
                                                                                name="building"
                                                                                value={values?.principal_officer_details?.building ?? ""}
                                                                                autocomplete="no"
                                                                                maxLength={75}
                                                                                className="form-control Entity_details_input"
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z0-9 ()\-,./"']+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("principal_officer_details.building", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.building ? <span className="error_message">{errors.principal_officer_details?.building}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Road/Street<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="street"
                                                                                name="street"
                                                                                value={values?.principal_officer_details?.street ?? ""}
                                                                                autocomplete="no"
                                                                                maxLength={125}
                                                                                className="form-control Entity_details_input"
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z0-9 ()\-,./"']+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("principal_officer_details.street", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.street ? <span className="error_message">{errors.principal_officer_details?.street}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Area/Locality<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="area"
                                                                                name="area"
                                                                                maxLength={75}
                                                                                value={values?.principal_officer_details?.area ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z0-9 ()\-,./"']+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("principal_officer_details.area", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.area ? <span className="error_message">{errors.principal_officer_details?.area}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Town/District/City<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="city"
                                                                                name="city"
                                                                                maxLength={50}
                                                                                value={values?.principal_officer_details?.city ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                onChange={(e) => {
                                                                                    const anPattern = new RegExp(/^[a-zA-Z ,]+$/i);
                                                                                    if (anPattern.test(e.target.value) || !e.target.value) {
                                                                                        setFieldValue("principal_officer_details.city", e.target.value)
                                                                                    }
                                                                                }}
                                                                            />

                                                                            {errors.principal_officer_details?.city ? <span className="error_message">{errors.principal_officer_details?.city}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Pincode<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <Field
                                                                                type="text"
                                                                                id="pincode"
                                                                                name="pincode"
                                                                                value={values?.principal_officer_details?.pincode ?? ""}
                                                                                autocomplete="no"
                                                                                className="form-control Entity_details_input"
                                                                                maxLength={6}
                                                                                onChange={(e) => {
                                                                                    setFieldValue('principal_officer_details.pincode', e.target.value)
                                                                                }}
                                                                            />
                                                                            {errors.principal_officer_details?.pincode ? <span className="error_message">{errors.principal_officer_details?.pincode}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">State<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">

                                                                            <SingleSelect
                                                                                name="state_id"
                                                                                // className={`form-control Entity_details_input`}
                                                                                value={values?.principal_officer_details?.state_id ? INSIGHT_NEW_REG_STATE_LIST[getStateIndex(values.principal_officer_details?.state_id)] : null}
                                                                                onChange={(selected) => {
                                                                                    setFieldValue('principal_officer_details.state_id', selected.value);
                                                                                    if (convertNum(selected.value) === 99 && convertNum(values?.principal_officer_details?.country_id) === 91) {
                                                                                        setFieldValue("principal_officer_details.country_id", "");
                                                                                    } else if (convertNum(selected.value) !== 99 && convertNum(values?.principal_officer_details?.country_id) !== 91) {
                                                                                        setFieldValue("principal_officer_details.country_id", 91);

                                                                                    }
                                                                                }}
                                                                                allowNumeric={false}
                                                                                options={INSIGHT_NEW_REG_STATE_LIST}
                                                                                placeholder="Select State"
                                                                            />
                                                                            {errors.principal_officer_details?.state_id ? <span className="error_message">{errors.principal_officer_details?.state_id}</span> : null}
                                                                        </div>

                                                                        <div class="form-group col-md-4">
                                                                            <label class="Entity_details_lebale" for="">Country<span class="red_text">*</span></label>
                                                                        </div>
                                                                        <div class="form-group col-md-8">
                                                                            <SingleSelect
                                                                                name="country_id"
                                                                                // className={`form-control Entity_details_input`}
                                                                                value={values?.principal_officer_details?.country_id ? INSIGHT_NEW_REG_COUNTRTY_LIST[getCountryIndex(values.principal_officer_details.country_id)] : ''}
                                                                                onChange={(selected) => {
                                                                                    setFieldValue('principal_officer_details.country_id', selected.value);
                                                                                    if (selected.value && convertNum(selected.value) !== 91) {
                                                                                        setFieldValue('principal_officer_details.state_id', 99);
                                                                                    } else if (selected.value && convertNum(selected.value) === 91 && convertNum(values?.principal_officer_details?.state_id) === 99) {
                                                                                        setFieldValue('principal_officer_details.state_id', "");
                                                                                    }
                                                                                }}
                                                                                allowNumeric={false}
                                                                                options={INSIGHT_NEW_REG_COUNTRTY_LIST}
                                                                                placeholder="Select Country"
                                                                                isDisabled={values?.principal_officer_details?.state_id && convertNum(values?.principal_officer_details.state_id) !== 99}
                                                                            />
                                                                        </div>
                                                                        {errors.principal_officer_details?.country_id ? <span className="error_message">{errors.principal_officer_details?.country_id}</span> : null}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </WindowWrapper>
                            </Form>)}

                    </Formik>
                </div >}
        </>
    )
}

export default InsightPortalPopup