/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { downloadRegularReturnErrorFile, getImportReturnStatusV2 } from '../../../api/returnAPI';
import ProgressBar from 'react-bootstrap/ProgressBar';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import "../../returnDashboard/importReturnFromExcel/ImportReturnResult.css";
import "../../returnDashboard/importReturnFromExcel/ImportReturnProgress.css";
import { createImportRegularReturnLocalStorageKey } from '../../../utils/UtilityFunctions';
import { HEADER_FILE_NAME } from '../../../app/constants';

const ImportReturnProgress = (props) => {
    const {
        show, handleClose, deductorId, returnId, headerData, fetchTransactionOverView,setShowFromErrorReImport
    } = props;
    const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
    const [importStats, setImportStats] = useState(checkRunningImport ? JSON.parse(checkRunningImport)?.import_stats : {})
    const [hasError, setHasError] = useState(checkRunningImport ? JSON.parse(checkRunningImport)?.has_errored_file : false)
    const [importStatus, setImportStatus] = useState(checkRunningImport ? JSON.parse(checkRunningImport)?.import_status : '')
    const [loading, setLoading] = useState(false);
    const [disabledReimort,setDisabledReimort] =  useState(true)
    const timer = useRef(null);
    const callImportStatus = useCallback(async (deductor_id, returnId) => {
        try {
            const result = await getImportReturnStatusV2(deductor_id, returnId);
            if (result?.data) {
                setImportStats(() => { return { ...result?.data?.import_stats } })
                setImportStatus(result?.data?.import_status)
                localStorage.setItem(createImportRegularReturnLocalStorageKey(headerData), JSON.stringify(result?.data));
                if (result?.data?.has_errored_file) {
                    setHasError(true)
                }
                if (result?.data?.import_status !== "SUCCESS" && result?.data?.import_status !== "FAILURE") {
                    timer.current = setTimeout(() => callImportStatus(deductor_id, returnId), 3000);
                }
                if (result?.data?.import_status === "SUCCESS") {
                    window.location.reload();
                }
            }
        } catch (err) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deductorId, returnId]);

    const downloadErrorFile = async () => {
        setLoading(true);
        try {
            let params = `?deductor_id=${deductorId}&return_id=${returnId}`;
            const result = await downloadRegularReturnErrorFile(params);
            const csiData = new Blob([result?.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            let formType = headerData.form_type;
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS_${formType}_Error_File.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    useEffect(() => {
        const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
        if (!checkRunningImport || (JSON.parse(checkRunningImport)?.import_status !== 'SUCCESS' && JSON.parse(checkRunningImport)?.import_status !== 'FAILURE')) {
            callImportStatus(deductorId, returnId)
        }
        return () => clearTimeout(timer.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onHide = () => {
        handleClose();
        const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerData))
        if (checkRunningImport && (JSON.parse(checkRunningImport)?.import_status === 'SUCCESS' || JSON.parse(checkRunningImport)?.import_status === 'FAILURE')) {
            localStorage.removeItem(createImportRegularReturnLocalStorageKey(headerData));
        }

    }

    const fixErrorsAndReimport = () => {
        onHide();      
        setShowFromErrorReImport(true)
    }

    useEffect(() => {
        if (fetchTransactionOverView !== undefined) {
            fetchTransactionOverView()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, [importStats])

    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
                className="tdsimport_inprogress"
            >
                <Modal.Header closeButton className="tdsimport_inprogress_header">
                    <h4 className="tdsimport_inprogress_title tdsimport_inprogress_disinline ">
                        {importStatus === 'SUCCESS' ? <span style={{ color: 'green' }}>Import Completed</span> : importStatus === 'FAILURE' ? <span style={{ color: 'red' }}>Import Failed</span> : <>
                            <span style={{ color: 'blue' }}>Import in Progress</span>
                            <div style={{ width: "60%", float: "right", marginTop: "3px" }}><ProgressBar animated now={100} label={''} /></div>
                        </>}
                    </h4>
                </Modal.Header>
                <Modal.Body className="pt-2 pb-2 px-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                    <table className="table table-striped tdsimport_inprogress_tb mb-1">
                                        <thead>
                                            <tr>
                                                <th width="250"></th>
                                                <th width="100">Total</th>
                                                <th width="100">Imported</th>
                                                <th width="100">Errored</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {importStats?.transaction &&
                                                <tr>
                                                    <td>Deductee Transactions</td>
                                                    <td className="text-right">{importStats?.transaction?.TotalCnt}</td>
                                                    <td className="text-right">{(importStats?.transaction?.TotalCnt ?? 0) - (importStats?.transaction?.ErrorCnt ?? 0)}</td>
                                                    <td className="text-right">{importStats?.transaction?.ErrorCnt}</td>

                                                </tr>
                                            }
                                            {importStats?.challan &&
                                                <tr>
                                                    <td>Challan Details</td>
                                                    <td className="text-right">{importStats?.challan?.TotalCnt}</td>
                                                    <td className="text-right">{(importStats?.challan?.TotalCnt ?? 0) - (importStats?.challan?.ErrorCnt ?? 0)}</td>
                                                    <td className="text-right">{importStats?.challan?.ErrorCnt}</td>

                                                </tr>
                                            }
                                            {importStats?.salary &&
                                                <tr>
                                                    <td>Salary Details</td>
                                                    <td className="text-right">{importStats?.salary?.TotalCnt}</td>
                                                    <td className="text-right">{(importStats?.salary?.TotalCnt ?? 0) - (importStats?.salary?.ErrorCnt ?? 0)}</td>
                                                    <td className="text-right">{importStats?.salary?.ErrorCnt}</td>

                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {(downloadErrorFile && importStats?.transaction?.OverUtlzChlCnt === 0 && hasError && (importStatus === 'SUCCESS' || importStatus === 'FAILED')) &&
                             <div className='text-center' style={{ display: 'flex', width: "100%" }}>
                                <div className={`err-dld-btn`} onClick={() => {downloadErrorFile();setDisabledReimort(false)}}>Download Error Report</div>
                                <div className={`err-dld-btn ${disabledReimort ?  "disabled" : ""}`} onClick={()=>fixErrorsAndReimport()}>Fix and Re-Import</div>
                                
                                </div>}
                        </div>

                    </div>
                </Modal.Body>


            </Modal>

        </>
    );
};

export default ImportReturnProgress;
