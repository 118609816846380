/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faIdCard, faPhoneVolume, faBuilding, faBan, faPlayCircle, faPauseCircle,
    faIdBadge,
    faCheckCircle,
    faRupeeSign,
    faAddressCard
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import GIF_animation from '../../images/GIF_animation2.gif';
import filing_status from '../../images/filing_status.png';
import { Carousel } from 'react-bootstrap';
import "./TanInfoAnimation.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { formatNumber } from '../../utils/UtilityFunctions';
import { ProgressBar } from '@progress/kendo-react-progressbars';

const TanInfoAnimation = ({ showTanInfoPopup, TanInfo, onHide, firstTime, setFirstTime }) => {
    const [state, setState] = useState(true);
    const [icon, setIcon] = useState('');
    const [interval, setInterval] = useState(7000);
    const [timer, setTimer] = useState(firstTime === false ? 100 : 0);

    const hidePopup = () => {
        let info_icon = $(".profilesmfsize");
        $(".pandashpoup").animate({
            'opacity': '0.5',
            'position': 'absolute',
            'height': '10px',
            'width': '10px',
            'top': `35px`,
            'left': `${info_icon.offset().left + 80}px`,
        }, 800);
        
        setTimeout(() => {
            onHide();
        }, 800);

        if(firstTime){
            setTimeout(() => {
                window.location.reload()
            }, 1100)
        }
        
    }
   
    useEffect(() => {
        if (timer < 100) {
            setTimeout(() => {
                setTimer(() => timer + 1)
            }, 500);
        }
        
        if(timer === 100) { 
            setFirstTime(false); 
        }



        // eslint-disable-next-line
    }, [timer])



    const toggleCarousel = () => {
        if (state === false) {
            const curState = !state;
            setInterval(curState ? 200 : null);
            setTimeout(() => {
                setInterval(7000)
            }, 250);
            setState(curState);
        } else {
            const curState = !state;
            setInterval(curState ? 7000 : null)
            setState(curState);
        }
    }

    useEffect(() => {
        const iconImg = state ? faPauseCircle : faPlayCircle;
        setIcon((prev) => {
            prev = iconImg
            return prev
        })
    }, [state])

    useEffect(() => {
        const keyDownHandler = event => {
            if (!firstTime && event.keyCode === 27) {
                event.preventDefault();
                hidePopup()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            show={showTanInfoPopup}
            animation={false}
            id="panslidepopup"
            className="pandashpoup"
        >
            <Modal.Body className="pt-1 pb-0">
                <div id="bootstrap-touch-slider">
                    {TanInfo !== null ? <>
                        {/* true * */}
                        <span title={state ? 'Pause' : 'Play'} style={{ float: 'right', position: 'relative', top: '220px', right: '16px', zIndex: '300', cursor: 'pointer' }} onClick={toggleCarousel}><FontAwesomeIcon icon={icon} /></span>
                        <Carousel
                            interval={interval}
                            controls={false}
                            pause={false}
                            fade={true}
                            className="bs-slider control-round indicators-line ciinersliderheight">


                            {/* slide 01  */}
                            <Carousel.Item className="item">
                                <h1 className="topcont">
                                    Welcome to TAN Insights!!
                                </h1>
                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right">
                                    <div className="animation_1">
                                        <h1 className="leftpadd">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faIdCard} /></span> Basic Details
                                        </h1>
                                        <p className="secodcontsect01">
                                            {TanInfo?.profile_data?.deductor_name !== "" ? <><span>Hello,</span> <span className='expit_pnainfo_txetclr_red'> {TanInfo?.profile_data?.deductor_name ?? '-'} </span> </> : '-'}
                                            <span >
                                                {TanInfo?.profile_data?.tan && `(${TanInfo?.profile_data?.tan})`}
                                            </span>
                                            <br />

                                        </p>

                                        <p className="secodcontsect02">
                                            {TanInfo?.profile_data?.tan_allot_dt !== "" ? <><span>Your TAN was alloted on</span> <span className='expit_pnainfo_txetclr_red'> {TanInfo?.profile_data?.tan_allot_dt ?? '-'} </span> </> : '-'}

                                            <br />
                                        </p>
                                        <p className="secodcontsect03">
                                            <span >
                                                {TanInfo?.profile_data?.associated_pan !== null ? <><span>The Associated PAN is </span><span className='expit_pnainfo_txetclr_red'>{TanInfo?.profile_data?.associated_pan ?? "-"}</span>  </> : <span className='expit_pnainfo_txetclr_red'>{`You have not linked the PAN with ${TanInfo?.profile_data?.tan}. To link, visit ITD portal - My Profile Section`}</span>
                                                }
                                            </span>
                                            <br />

                                        </p>
                                    </div>
                                    <div className="animation_2">

                                        <h1 className="leftpadd">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faPhoneVolume} /></span> Contact Details
                                        </h1>


                                        <ul className='personaldetails_listbox'>
                                            <li>
                                                <span className='expit_pnainfo_txetclr_blue'>Primary Mobile No. :</span>
                                                <span className='expit_pnainfo_txetclr_red'> {TanInfo?.profile_data?.primary_mobile_no !== "" ? TanInfo?.profile_data?.primary_mobile_no ?? "-" : 'Not Available'} </span>
                                            </li>
                                            <li>
                                                <span className='expit_pnainfo_txetclr_blue'>Primary Email Id. :</span>
                                                <span className='expit_pnainfo_txetclr_red'> {TanInfo?.profile_data?.primary_email !== "" ? TanInfo?.profile_data?.primary_email ?? "-" : 'Not Available'} </span>
                                            </li>

                                        </ul>
                                        {TanInfo?.profile_data?.second_mobile_no && TanInfo?.profile_data?.second_email ?
                                            <ul className='personaldetails_listbox'>
                                                <li>
                                                    <span className='expit_pnainfo_txetclr_blue'>Secondary Mobile No. :</span>
                                                    <span className='expit_pnainfo_txetclr_red'> {TanInfo?.profile_data?.second_mobile_no !== "" ? TanInfo?.profile_data?.second_mobile_no ?? "-" : 'Not Available'} </span>
                                                </li>
                                                <li>
                                                    <span className='expit_pnainfo_txetclr_blue'>Secondary Email Id. :</span>
                                                    <span className='expit_pnainfo_txetclr_red'> {TanInfo?.profile_data?.second_email !== "" ? TanInfo?.profile_data?.second_email ?? "-" : 'Not Available'} </span>
                                                </li>

                                            </ul>

                                            :
                                            <span className='expit_pnainfo_txetclr_red'> {" To improve the communication points from Portal it is recommended to update secondary contact details also. To update, visit ITD portal - My Profile Section."} </span>

                                        }

                                        {/*  */}
                                    </div>
                                    <div className="animation_3">
                                        <p className="secodcontsect03">
                                            <h1 className="leftpadd">
                                                <span className="bussuserbox"><FontAwesomeIcon icon={faBuilding} /></span>     Deductor Address :
                                            </h1>
                                            {TanInfo?.profile_data?.address &&
                                                <span className='expit_pnainfo_txetclr_red'> {TanInfo?.profile_data?.address !== "" ? TanInfo?.profile_data?.address : '-'} </span>
                                            }
                                        </p>
                                    </div>
                                </div>

                            </Carousel.Item>
                            {/* slide 01 */}



                            {/* slide 02  */}
                            <Carousel.Item className="item">
                                <h1 className="topcont">
                                    Key Person and DSC Registration Details
                                </h1>
                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right">
                                    <div className="animation_1">

                                        <h1 className="leftpadd">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faIdBadge} /></span>  Key Person Details
                                        </h1>

                                        {
                                            <p className="secodcontsect01">

                                                <span className="textblodfs ">
                                                    <ul className='bankdetails_listbox'>
                                                        {TanInfo?.key_person?.length > 0 ? TanInfo?.key_person?.map((item, index) => {
                                                            return (
                                                                <li>
                                                                    <span className='expit_pnainfo_txetclr_red'> {`${index + 1}. ${item?.mbr_name ?? '-'} `}<br /> &nbsp;&nbsp;&nbsp;{`${item?.mbr_pan_tan ?? '-'}`} </span>
                                                                    <br />
                                                                    &nbsp;&nbsp;<span className='expit_pnainfo_txetclr_blue'> {item?.contact_flag && "Appointed as Principal Contact"}</span>

                                                                </li>
                                                            )
                                                        })
                                                            : <span className='expit_pnainfo_txetclr_red'> You have not designated any Key person as Principal Contact. To assign, visit ITD portal - My Profile Section.</span>
                                                        }
                                                    </ul>
                                                </span>
                                            </p>
                                        }
                                        <h1 className="leftpadd">
                                            <span className="bussuserbox">
                                                {/* <img src={register} width="16" alt='register' /> */}
                                                <FontAwesomeIcon icon={faIdCard} />
                                            </span>  DSC registration Details
                                        </h1>

                                        {TanInfo?.key_person?.[0]?.dsc_exp_date ?
                                            <p className="secodcontsect01">

                                                <span className='expit_pnainfo_txetclr_red'> {"DSC registered on ITD Portal "} </span>
                                                <span className='expit_pnainfo_txetclr_blue'> {`is expiring on ${TanInfo?.key_person?.[0]?.dsc_exp_date ?? "-"}`} </span>
                                            </p> :
                                            <span className='expit_pnainfo_txetclr_red'> You have not registered DSC on ITD Portal for TDS Filing purpose. To update, visit ITD portal - My profile section.</span>
                                        }

                                    </div>
                                </div>
                            </Carousel.Item>
                            {/* slide 02 */}

                            {/* slide 03  */}
                            <Carousel.Item className="item">

                                <h1 className="topcont">
                                    Filing Status Insights!!
                                </h1>


                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right Electronicsect">
                                    <div className="animation_1">
                                        <h1 className="tablefilingscreen" data-animation="animated fadeInUp ">
                                            <span class="TAN_Information_slider_bussuserbox">
                                                <img src={filing_status} width="16" alt='filing_status' />
                                            </span> Default  Filing Status of Last 6 Months
                                        </h1>
                                        <div className="tbourbor" data-animation="animated fadeInUp ">
                                            <div style={TanInfo?.defaulted_returns?.length >= 5 ? { "overflowX": "hidden", "overflowY": "scroll", maxHeight: "120px" } : {}}
                                                className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <table class="table table-striped TAN_Information_slider_lookinnertb mb-0">

                                                    <tbody>
                                                        {TanInfo?.defaulted_returns?.length ?
                                                            TanInfo?.defaulted_returns.map((item) => {
                                                                return (
                                                                    <tr className="trtd_textcoralclr">
                                                                        <td colSpan={3} >
                                                                            {item ?? "-"} </td>
                                                                    </tr>)
                                                            })
                                                            :
                                                            <tr><td colSpan={3} >Great!! You do not have any defaults in the last 6 months.</td></tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="animation_2">
                                        <h1 className="tablefilingscreen" data-animation="animated fadeInUp ">
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faBan} style={{ color: 'red' }} /></span>  Rejected Filing Status of Last 6 Months
                                        </h1>
                                        <div className="tbourbor" data-animation="animated fadeInUp ">
                                            <div style={TanInfo?.rejected_return?.length >= 5 ? { "overflowX": "hidden", "overflowY": "scroll", maxHeight: "120px" } : {}}
                                                className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <table class="table table-striped TAN_Information_slider_lookinnertb mb-0">

                                                    <tbody>

                                                        {TanInfo?.rejected_return?.length ?
                                                            TanInfo?.rejected_return.map((item) => {
                                                                return (
                                                                    <tr className="trtd_textcoralclr">
                                                                        <td colSpan={3} >
                                                                            {item ?? "-"}            </td>

                                                                    </tr>)

                                                            })

                                                            :

                                                            <tr><td colSpan={3} >Great!! You do not have any rejected returns in the last 6 months</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="animation_3">
                                        <h1 className="tablefilingscreen" data-animation="animated fadeInUp ">

                                            {/* <span class="TAN_Information_slider_bussuserbox">
                                                <img src={success} width="16" alt='success' />
                                            </span> {" "} */}
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /></span>
                                            {" "}Successful Filing Status of Last 6 Months
                                        </h1>
                                        <div className="tbourbor" data-animation="animated fadeInUp ">
                                            <div style={TanInfo?.all_returns?.length >= 5 ? { "overflowX": "hidden", "overflowY": "scroll", maxHeight: "150px" } : {}}
                                                className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <table class="table table-striped TAN_Information_slider_lookinnertb textblueclr mb-0">
                                                    <thead><tr>
                                                        <th>Financial Year</th>
                                                        <th>Form | Quarter</th>
                                                        <th>Filing Type</th>
                                                        <th>Date of Filing </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                        {TanInfo?.all_returns?.length ?
                                                            TanInfo?.all_returns.map((item) => {
                                                                return (
                                                                    <tr className="trtd_textcoralclr">
                                                                        <td className="text-center">
                                                                            {item?.financial_year ?? "-"}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {`${item?.form_type} | ${item?.quarter}` ?? "-"}            </td>
                                                                        <td className="text-center">
                                                                            {item?.statement_type ?? "-"}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {item?.date_of_filing ?? "-"}
                                                                        </td>
                                                                    </tr>)

                                                            })

                                                            :

                                                            <tr><td colSpan={4} className="text-center">No Records Found</td></tr>
                                                        }





                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Carousel.Item>

                            {/* slide 04  */}
                            <Carousel.Item className="item">

                                <h1 className="topcont">
                                    Outstanding Dues & Insights Registration!!
                                </h1>

                                <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                <div className="bs-slider-overlay"></div>

                                <div className="slide-text slide_style_right Electronicsect">
                                    <div className="animation_1">
                                        <h1 className="tablefilingscreen" data-animation="animated fadeInUp ">
                                            {/* < span class="TAN_Information_slider_bussuserbox">
                                                <img src={outstanding} width="16" alt='outstanding' />                                                
                                            </span>  */}
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faRupeeSign} /></span>
                                            {' '}
                                            Total Outstanding Demand
                                        </h1>
                                        <div className="tbourbor" data-animation="animated fadeInUp ">
                                            <div
                                                className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                <table class="table table-striped TAN_Information_slider_lookinnertb mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>FY</th>
                                                            <th>Manual Demand</th>
                                                            <th>Processed Demand</th></tr>
                                                    </thead>
                                                    <tbody>

                                                        {TanInfo?.outstanding_dues?.length ?

                                                            TanInfo?.outstanding_dues.map((item) => {
                                                                return (
                                                                    <tr class="textcolorbldefault">
                                                                        <td class="text-center">
                                                                            {
                                                                                item?.financial_year ?? "-"
                                                                            }
                                                                        </td>
                                                                        <td class="text-right">
                                                                            {formatNumber(item?.manual_demand_amtp) ?? "-"}
                                                                        </td>
                                                                        <td class="text-right">
                                                                            {formatNumber(item?.processed_demand_amtp) ?? "-"}
                                                                        </td>
                                                                    </tr>)

                                                            })

                                                            :

                                                            <tr><td colSpan={3} className="text-center">No Records Found</td></tr>
                                                        }

                                                        {TanInfo?.outstanding_dues && TanInfo?.outstanding_dues?.length ?
                                                            <tr class="outstdemand_trtdbgclr">
                                                                <td class="text-center">
                                                                    Total
                                                                </td>
                                                                <td class="text-right">
                                                                    {formatNumber(TanInfo?.outstanding_dues.reduce((prevSumValue, Current) => prevSumValue + Current?.manual_demand_amtp, 0))}
                                                                </td>
                                                                <td class="text-right">
                                                                    {formatNumber(TanInfo?.outstanding_dues.reduce((prevSumValue, Current) => prevSumValue + Current?.processed_demand_amtp, 0))}
                                                                </td>
                                                            </tr> :
                                                            null
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="animation_2">
                                        <h1 className="tablefilingscreen" data-animation="animated fadeInUp ">
                                            {/* < span class="TAN_Information_slider_bussuserbox">
                                                <img src={insights} width="16" alt='insights' />
                                            </span>&nbsp; */}
                                            <span className="bussuserbox"><FontAwesomeIcon icon={faAddressCard} /></span>
                                            {' '}
                                            Registration On Insights Reporting Portal

                                        </h1>
                                        <p className="secodcontsect01">
                                            <br />

                                            {TanInfo?.insights_login_exists ?
                                                <span className='expit_pnainfo_txetclr_red'>
                                                    {"You are registered on Income Tax Insights portal. Through Express TDS, you would be able to complete the Compliance Check U/s 206CCA & 206AB for Individual/Bulk PAN."}
                                                </span>
                                                :
                                                <span className='expit_pnainfo_txetclr_red'>
                                                    {"You are not Registered on Insights portal, and it is strongly recommended to get yourself registered to avoid the risk of short deductions U/s 206CCA & 206AB Intimations."}
                                                </span>
                                            }



                                        </p>
                                    </div>

                                </div>
                            </Carousel.Item>

                        </Carousel>

                    </>

                        :

                        <>

                            {/* view false */}
                            <Carousel
                                interval={7000}
                                controls={false}
                                pause={false}
                                fade={true}
                                className="bs-slider control-round indicators-line ciinersliderheight">



                                {/* slide 01 */}
                                <Carousel.Item className="item">
                                    <h1 className="topcont">
                                        Here is a quick look at your TAN Information
                                    </h1>
                                    <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                    <div className="bs-slider-overlay"></div>

                                    <div className="slide-text slide_style_right">
                                        <div className="animation_1">

                                            <p className="secodcontsect01" style={{ color: '#33729c' }}>
                                                Your TAN level curated information is not updated here as the Password is Not - Verified on Deductor Master page.
                                            </p>
                                            <p className="secodcontsect01">

                                                {/* Update using below button. */}
                                            </p>
                                            <p className="secodcontsect02">
                                                <span className="textblodfs">

                                                </span>

                                            </p>

                                        </div>
                                    </div>

                                </Carousel.Item>
                                {/* slide 01 */}


                            </Carousel>

                        </>
                    }


                    {timer < 100 ?
                        <div className="progress_bar">
                            <ProgressBar
                                value={timer}
                                style={{ width: '100%' }}
                                labelPlacement="center"
                                label={(props) => (<strong> Loading... {timer}%</strong>
                                )}
                            />
                        </div>
                        :
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                            <Button variant="default" className="GOtodashbtn" onClick={() => hidePopup()}>Close</Button>
                        </div>
                    }

                </div>

            </Modal.Body>
        </Modal >
    );

}
export default TanInfoAnimation;
