import React, { useState, useEffect } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone, formatAccountsNumber } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";



const SdOthers = (props) => {

    const { apiData, formType } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);

    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    s_no: item[0],
                    cd_no: item[1],
                    dd_no: item[2],
                    deductee_ref_no: item[3],
                    pan: item[4],
                    name: item[5],
                    section: item[6],
                    amt_paid: item[7],
                    tds_ded: item[8],
                    rate: item[9],
                    tds_rate: item[10],
                    tax_rate: item[11],
                    tax_rate_ab: item[12],
                    applied_rate: item[13],
                    limit: item[14],
                    tot_amt: item[15],
                    tds_sur: item[16],
                    short_deduction: item[17],
                    excess_tds: item[18]
                })
            })
            return newData
        }
    };

    const dataStateChange = (dataState) => {

        if (dataState.filter || (dataState.sort && dataState?.sort?.length > 0)) {
            const filter_state = deepClone(dataState);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                            if (["gte", "lte", 'lt', 'gt', "neq"].includes(itemObj?.operator)) {
                                itemObj.value = Number(itemObj.value);
                            }
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                itemObj.value = itemObj.value?.[0] ?? "";
                            }
                        })
                    }
                })
            }
            const dataProcess = process(convertData(apiData), { ...filter_state });
            setDataState({ ...dataState });
            setData(dataProcess.data ?? []);

        } else {

            setDataState({});
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData(defaultData ?? []);
        }

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const calculateTotal = (index) => {
        let total = 0
        data.length > 0 && data.forEach((item) => {
            switch (index) {                
                case "18":
                    total += item?.short_deduction;
                    break;
                case "19":
                    total += item?.excess_tds;
                    break;
                default:
                    total = 0
            }

        })
        return formatAccountsNumber(total)
    };


    const renderSubTextTitle = (title, subText) => {

        return (
            <div style={{whiteSpace: 'break-spaces'}}>
                {title}
                {subText && (<div className="text-center">
                    {' '}<span className="b2btbtrthtextclr" style={{ paddingLeft: '20px' }}>{subText ?? 0}</span>
                </div>)}
            </div>
        );
    };


    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;
        const dataItem = props?.dataItem ?? {}

        return (
            <>
                <tr key={key} role="row">
                    <td class="text-center">{dataItem?.s_no}</td>
                    <td class="text-center">{dataItem?.cd_no ?? "-"}</td>
                    <td class="text-center">{dataItem?.dd_no ?? "-"}</td>
                    <td class="text-left">{dataItem?.deductee_ref_no ?? "-"}</td>
                    <td class="text-center">{dataItem?.pan ?? "-"}</td>
                    <td class="text-left">{dataItem?.name ?? "-"}</td>
                    <td class="text-center">{dataItem?.section ?? "-"}</td>
                    <td class="text-right">{formatAccountsNumber(dataItem?.amt_paid ?? 0) ?? "-"}</td>
                    <td class="text-right">{formatAccountsNumber(dataItem?.tds_ded ?? 0) ?? "-"}</td>
                    <td class="text-center">{dataItem?.rate ?? "-"}</td>
                    <td class="text-center notices_orders_rowbgclr_yell">{dataItem?.tds_rate ?? "-"}</td>
                    <td class="text-center notices_orders_rowbgclr_yell">{dataItem?.tax_rate ?? "-"}</td>
                    <td class="text-center notices_orders_rowbgclr_yell">{dataItem?.tax_rate_ab ?? "-"}</td>
                    <td class="text-center notices_orders_rowbgclr_yell">{dataItem?.applied_rate ?? "-"}</td>
                    <td class="text-center notices_orders_rowbgclr_yell">{dataItem?.limit ?? "-"}</td>
                    <td class="text-right notices_orders_rowbgclr_yell">{formatAccountsNumber(dataItem?.tot_amt ?? 0) ?? "-"}</td>
                    <td class="text-right notices_orders_rowbgclr_yell">{formatAccountsNumber(dataItem?.tds_sur ?? 0) ?? "-"}</td>
                    <td class="text-right notices_orders_rowbgclr_grey">{formatAccountsNumber(dataItem?.short_deduction ?? 0) ?? "-"}</td>
                    <td class="text-right notices_orders_rowbgclr_grey">{formatAccountsNumber(dataItem?.excess_tds ?? 0) ?? "-"}</td>
                </tr>

                {
                    key + 1 === data.length &&
                    <tr className="tdsjustification_rowshadow">
                        <td className="text-left" colSpan={2}>Total</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-right">
                            {calculateTotal("18")}
                        </td>
                        <td className="text-right">
                            {calculateTotal("19")}
                        </td>
                    </tr>
                }
            </>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);


    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab16" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">
                    <div className="card">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">


                            <Tooltip anchorElement="pointer" position="top">
                                <Grid
                                    className="table table-striped exptds_justformtype_table mb-0"
                                    style={{ maxHeight: '530px', overflowX: 'auto' }}
                                    data={data}
                                    {...dataState}

                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        pan: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    sortable
                                >
                                    <Column>
                                        <Column>
                                            <Column
                                                field="s_no"
                                                title={renderSubTextTitle('Sr. No.')}
                                                width="50"
                                            />
                                        </Column>
                                    </Column>

                                    <Column title="Data From Statement" headerClassName={'sorting_disabled'}>

                                        <Column>

                                            <Column
                                                field="cd_no"
                                                headerClassName={isColumnActive('cd_no') ? 'active' : ''}
                                                title={renderSubTextTitle('CD Serial No.')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="dd_no"
                                                headerClassName={isColumnActive('dd_no') ? 'active' : ''}
                                                title={renderSubTextTitle('DD Serial No.')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="deductee_ref_no"
                                                headerClassName={isColumnActive('deductee_ref_no') ? 'active' : ''}
                                                title={renderSubTextTitle(`${formType === "24Q" ? "Employee" : "Deductee"} Reference Number`)}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="pan"
                                                headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                title={renderSubTextTitle(`PAN of  ${formType === "24Q" ? "Employee" : "Deductee"}`)}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="name"
                                                headerClassName={isColumnActive('name') ? 'active' : ''}
                                                title={renderSubTextTitle(`Name of  ${formType === "24Q" ? "Employee" : "Deductee"}`)}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="section"
                                                headerClassName={isColumnActive('section') ? 'active' : ''}
                                                title={renderSubTextTitle('Section Code')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="amt_paid"
                                                headerClassName={isColumnActive('amt_paid') ? 'active' : ''}
                                                title={renderSubTextTitle('Amount Paid / Credited')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="tds_ded"
                                                headerClassName={isColumnActive('tds_ded') ? 'active' : ''}
                                                title={renderSubTextTitle('TDS Deducted')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="rate"
                                                headerClassName={isColumnActive('rate') ? 'active' : ''}
                                                title={renderSubTextTitle('Rate as per statement(%)')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                        </Column>
                                    </Column>

                                    <Column title="Processed Data" headerClassName={'sorting_disabled notices_orders_rowbgclr_yell'}>

                                        <Column headerClassName={'notices_orders_rowbgclr_yell'}>

                                            <Column
                                                field="tds_rate"
                                                headerClassName={isColumnActive('tds_rate') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Rate At \n Which TDS \n Deducted(%)')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />

                                            <Column
                                                field="tax_rate"
                                                headerClassName={isColumnActive('tax_rate') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('TDS Rates as \n per Income \n Tax Act, \n 1961(%)')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="tax_rate_ab"
                                                headerClassName={isColumnActive('tax_rate_ab') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('TDS Rate as \n per Section-206AB(%)')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="applied_rate"
                                                headerClassName={isColumnActive('applied_rate') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Applied Tax Rate(%)')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="limit"
                                                headerClassName={isColumnActive('limit') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Threshold\n Limit \n (as per Act)')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="tot_amt"
                                                headerClassName={isColumnActive('tot_amt') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Total Amount \n Paid / Credited \n Till Last Record \n Processed (Same \n PAN and Section Code)')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="tds_sur"
                                                headerClassName={isColumnActive('tds_sur') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('TDS to be Deducted \n (Including Surcharge \n and Edu. Cess. If applicable)')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                        </Column>

                                        <Column title="Short Deduction" headerClassName={'sorting_disabled notices_orders_rowbgclr_grey'}>
                                            <Column
                                                field="short_deduction"
                                                headerClassName={isColumnActive('short_deduction') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                                title={renderSubTextTitle('Short Deduction Before Adjusted Excess Amount Deducted Earlier')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="excess_tds"
                                                headerClassName={isColumnActive('excess_tds') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                                title={renderSubTextTitle('Excess TDS Deducted (u/s \n 194A Adjust \n Against Short Deduction)')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                        </Column>

                                    </Column>

                                    <GridNoRecords>
                                        No Record Found
                                    </GridNoRecords>

                                </Grid>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default SdOthers;