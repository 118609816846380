/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  getReturnCorrectionSummary,
} from '../../api/returnAPI';

import { formatNumber } from '../../utils/UtilityFunctions';

const OverviewSummary = (props) => {
  const { deductorId, returnId, headerDataDetails, transactionData } = props;

  const [summaryData, setSummaryData] = useState({
    challan: '',
    deductee_and_pan: '',
    deductee_transactions: '',
    deductor_details: '',
  });

  const fetchCorrectionSummary = async () => {
    try {
      const result = await getReturnCorrectionSummary(`?deductor_id=${deductorId}&return_id=${returnId}`);
      setSummaryData(result.data);

    } catch (err) {
      console.error('Error in fetching transaction overview data: ', err);
    }
  };

  useEffect(() => {
    fetchCorrectionSummary();
  }, []);

  return (
    <div>
      <div className="row mt-1">
        <div className="col-md-6">
          <fieldset className="ovfsdsborblue">
            <legend className="ovlengentds overflowsect">
              <h4> Overview</h4>
            </legend>
            <table className="table table-striped table-responsive ovtbpanno">
              <thead className="">
                <tr>
                  <th> Overview </th>
                  <th>
                    PRN Number:
                    <span> {transactionData.prn_no}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>No. of Deductee Transactions</td>
                  <td className="text-center">{transactionData.total_deductee_transactions}</td>
                </tr>
                <tr>
                  <td >No. of Challan</td>
                  <td className="text-center">{transactionData.total_challans}</td>
                </tr>
                <tr>
                  <td>{(headerDataDetails && headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '27EQ') ? "Total Tax Collected" : "Total Tax Deducted"}</td>
                  <td className="text-center">
                    {transactionData.total_tax_deducted_amtp > '0' && transactionData.total_tax_deducted_amtp !== '' ? (
                      <>
                        {/* <span>₹ </span> */}
                        {formatNumber(transactionData.total_tax_deducted_amtp)}
                      </>
                    ) : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Total Tax Adjusted</td>
                  <td className="text-center">
                    {transactionData.total_tax_adjusted_amtp > '0' && transactionData.total_tax_adjusted_amtp !== '' ? (
                      <>
                        {/* <span>₹ </span> */}
                        {' '}
                        {formatNumber(transactionData.total_tax_adjusted_amtp)}
                      </>
                    ) : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Total Tax Remaining to be Adjusted</td>
                  <td className="text-center">
                    {transactionData.total_tax_remaining_to_be_adjusted_amtp > '0' && transactionData.total_tax_remaining_to_be_adjusted_amtp !== '' ? (
                      <>
                        {/* <span>₹ </span> */}
                        {' '}
                        {formatNumber(transactionData.total_tax_remaining_to_be_adjusted_amtp)}
                      </>
                    ) : '-'}

                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="cusummfsdsbor">
            <legend className="cusummlengentds">
              <h4>Correction Summary</h4>
            </legend>
            <table className="table table-striped table-responsive currectsumm">
              <thead className="">
                <tr>
                  <th>&nbsp;  </th>
                  <th>New Added</th>
                  <th>Modifed</th>
                  <th>Deleted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Deductor Details</td>
                  <td><span style={{ color: '#c7c7c7' }}>{summaryData.deductor_details.new}</span></td>
                  <td>{summaryData.deductor_details.modified}</td>
                  <td><span style={{ color: '#c7c7c7' }}>{summaryData.deductor_details.deleted}</span></td>
                </tr>
                <tr>
                  <td>Deductee Transactions</td>
                  <td><span>{summaryData.deductee_transactions.new}</span></td>
                  <td><span>{summaryData.deductee_transactions.modified}</span></td>
                  <td><span>{summaryData.deductee_transactions.deleted}</span></td>
                </tr>
                <tr>
                  <td>Deductee and PAN</td>
                  <td><span style={{ color: '#c7c7c7' }}>{summaryData.deductee_and_pan.new}</span></td>
                  <td><span style={{ color: '#c7c7c7' }}>{summaryData.deductee_and_pan.modified}</span></td>
                  <td><span style={{ color: '#c7c7c7' }}>{summaryData.deductee_and_pan.deleted}</span></td>
                </tr>
                <tr>
                  <td>Challan </td>
                  <td><span style={{ color: '#c7c7c7' }}>{summaryData.challan.new}</span></td>
                  <td>{summaryData.challan.modified}</td>
                  <td><span style={{ color: '#c7c7c7' }}>{summaryData.challan.deleted}</span></td>
                </tr>

              </tbody>
            </table>
          </fieldset>

        </div>
      </div>
    </div>

  );
};

export default OverviewSummary;
