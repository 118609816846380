import React from 'react';
import { Skeleton } from '@mui/material'

const Skeleton2ColumnBox = (props) => {

  return (
    <div className="row">
      <div className='col-md-6'>
        <Skeleton animation="wave" variant="text" width={"100%"} height={"120px"} />
      </div>
      <div className='col-md-6'>
        <Skeleton animation="wave" variant="text" width={"100%"} height={"120px"} />
      </div>
      <div className='col-md-6'>
        <Skeleton animation="wave" variant="text" width={"100%"} height={"120px"} />
      </div>
      <div className='col-md-6'>
        <Skeleton animation="wave" variant="text" width={"100%"} height={"120px"} />
      </div>
    </div>

  )
}

export default Skeleton2ColumnBox