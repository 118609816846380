import React, { useState } from 'react';
import $ from "jquery";
import addBtnRoundIcon from "../../images/icons/add_btn_round.svg";
import deleteIcon from "../../images/del-icon.png";
import calcIcon from "../../images/calculator.png";
import salaryannudangle from '../../images/allcol_blue_up.png';
import salaryanngledown from "../../images/allcol_blue_down.png";
import {
    converArrayObj_to_map, SALARY_17_1DROPDOWN, SALARY_17_1, transformDropdown,
    perquisites_17_2DROPDOWN, SALARY_17_2, SALARY_17_3,
    profit_17_3DROPDOWN, OTHERINCOME_OFFERED,
    OTHERINCOMEOFFERED_DROPDOWN,
    RETIRMENTBENFIT_DROPDOWN,
    ALLOWANCEEXEMPT_DROPDOWN
} from './salaryHelper';
import { FormatNumberDecimal, roundUpValueWithoutDecimal } from '../../utils/UtilityFunctions';
import Salary17one from './components/Salary17one';
import Prequisites17Two from './components/Prequisites17Two';
import ProfitSalary17three from './components/ProfitSalary17three';
import Deduction from './components/Deduction';
import IncHouseProperty from './components/IncHouseProperty';
import IncOthSource from './components/IncOthSource';
import DeductionVIA from './components/DeductionVIA';
import OtherDetails from './components/OtherDetails';
import FixedInput from './components/FixedInput';
import PrevEmployeSalary from './components/PrevEmployeSalary';


const EmployeeSalaryDetail = (props) => {
    const { data, showCalcPopupsfunc, USER_TYPE, setData, showCalcHousePrtyPopupFunc, otherDetailError, setOtherDetailError,
        drowdownList, setDropdownList, updateEmployeeDetail, financialYear, deducteeDet, setDeducteeDet, setWarning, relief89usErr
    } = props;
    const taxRegime = data.tax_regime ?? "";

    const dedVI_A_RowObj = { "code": "", "deductible_amount_amtp": "", "gross_amount_amtp": "", "qualifying_amtp": null }
    const landlordRowObj = { "name_of_landlord": "", "pan_of_landlord": "", "rent_amount_amtp": null }
    const allowanceObj = { "allowance_details": null, "allowance_type_id": "", "exempt_amtp": "", "received_amtp": "" }
    const retirmentBenfitObj = { "allowance_details": null, "allowance_type_id": "", "exempt_amtp": "", "received_amtp": "" }

    const [showAll, setShowAll] = useState(false);

    const toggleAll = () => {
        if (showAll) {
            $('.salayannexureII .collapse').collapse('hide');
        } else {

            $('.salayannexureII .collapse').collapse('show');
        }
        setShowAll(!showAll);
    }
    // Convert the DROPDOWN array of objects into a Map, using the "particular_code/code/allowance_type_id" key for mapping.
    // The resulting Map allows for efficient lookup of items based on their "particular_code/code/allowance_type_id".
    const headingCodeLIst17one = converArrayObj_to_map(SALARY_17_1DROPDOWN, "particular_code");
    const headingCodeList17two = converArrayObj_to_map(perquisites_17_2DROPDOWN, "particular_code");
    const headingCodeList17three = converArrayObj_to_map(profit_17_3DROPDOWN, "particular_code");
    const headingCodeListothincoff = converArrayObj_to_map(OTHERINCOMEOFFERED_DROPDOWN, "particular_code");
    const headingCodeListdedVI_A = converArrayObj_to_map(drowdownList?.deductionVI_A?.[taxRegime] ?? [], "code");
    const headingCodeListRetirbenfit = converArrayObj_to_map(RETIRMENTBENFIT_DROPDOWN ?? [], "allowance_type_id");
    const headingCodeListAllowance = converArrayObj_to_map(ALLOWANCEEXEMPT_DROPDOWN?.[USER_TYPE] ?? [], "allowance_type_id");


    const fixedkey17one = SALARY_17_1?.[USER_TYPE] ?? {};
    const fixedkey17two = SALARY_17_2?.[USER_TYPE] ?? {};
    const fixedkey17three = SALARY_17_3?.[USER_TYPE] ?? {};
    const fiexedkeyothincoff = OTHERINCOME_OFFERED?.[USER_TYPE] ?? {};


    const handleParentChildArrayInputChange = (parent, child, index, name, value) => {
        // onchange in  input 
        data[parent][child][index][name] = value;
        setData({ ...data });
    }

    const handleParentChildArrayNumberInputOnBlur = (parent, child, index, name, value) => {
        // onBlur in  input 
        data[parent][child][index][name] = roundUpValueWithoutDecimal(value);
        setData({ ...data });
        updateEmployeeDetail(data);
    }

    // simple obj update function 
    const handleNumberInputOnBlur = (name, value) => {
        data[name] = roundUpValueWithoutDecimal(value);
        setData({ ...data });
        updateEmployeeDetail(data);
    }

    const handleArrayNumberInputOnBlur = (parent, index, name, value) => {
        data[parent][index][name] = roundUpValueWithoutDecimal(value);
        setData({ ...data });
        updateEmployeeDetail(data);
    };

    const handleObjNumberInputOnBlur = (parent, name, value) => {
        data[parent][name] = roundUpValueWithoutDecimal(value);
        setData({ ...data });
        updateEmployeeDetail(data);
    }

    const handleDrpdwn17one = (value) => {
        // add additional row in salary 17 (1)
        const row = { particular_code: value.particular_code, amount_amtp: "" };
        if (!data?.salary_breakup_amtp) data.salary_breakup_amtp = {};
        if (!data?.salary_breakup_amtp?.details) data.salary_breakup_amtp.details = [];
        data.salary_breakup_amtp.details.push(row);
        setData({ ...data });

        // remove selected option in salary 17(1) dropdown list
        const aviailableList = drowdownList.salary17one.filter((item) => item.particular_code !== value.particular_code);
        drowdownList.salary17one = aviailableList;
        setDropdownList({ ...drowdownList });
    }

    const handleDelete17one = (parent, child, index) => {
        // delete row 
        data[parent][child].splice(index, 1);
        setData({ ...data });
        // update api call
        updateEmployeeDetail(data);
        // You need to modify the function so that when an item is deleted from the data array:
        // Add the Deleted Item Back to the Dropdown List: The item that was deleted should be re-added to the dropdown list (drowdownList).
        // Maintain the Correct Order in the Dropdown List: The deleted item should be inserted back into the dropdown list at the correct position
        // based on its original order, ensuring that the order of options in the dropdown remains accurate.
        drowdownList.salary17one = transformDropdown(data[parent][child] ?? [], SALARY_17_1DROPDOWN, "particular_code");
        setDropdownList({ ...drowdownList });
    }

    const handleDrpdwn17two = (value) => {
        // add additional row in salary 17 (2)
        const row = { particular_code: value.particular_code, perquisite_amtp: "", recovered_amtp: "" };
        if (!data?.perquisite_breakup_amtp) data.perquisite_breakup_amtp = [];
        data.perquisite_breakup_amtp.push(row);
        setData({ ...data });

        // remove selected option in salary 17(2) dropdown list
        const aviailableList = drowdownList.perquisites_17_2.filter((item) => item.particular_code !== value.particular_code);
        drowdownList.perquisites_17_2 = aviailableList;
        setDropdownList({ ...drowdownList });
    }

    const handleDrpdwn17three = (value) => {
        // add additional row in salary 17 (3)
        const row = { particular_code: value.particular_code, amount_amtp: "" }; // define default row 
        if (!data?.profit_in_lieu_breakup_amtp) data.profit_in_lieu_breakup_amtp = [];
        data.profit_in_lieu_breakup_amtp.push(row);
        setData({ ...data });

        // remove selected option in salary 17(3) dropdown list
        const aviailableList = drowdownList.profit_17_3.filter((item) => item.particular_code !== value.particular_code);
        drowdownList.profit_17_3 = aviailableList;
        setDropdownList({ ...drowdownList });
    }

    const handleArrayObjDel = (parent, index, drpdwnKeys, defltdrpdwnlist, codekey) => {
        // delete row 
        data[parent].splice(index, 1);
        setData({ ...data });
        // update api call
        updateEmployeeDetail(data);
        // You need to modify the function so that when an item is deleted from the data array:
        // Add the Deleted Item Back to the Dropdown List: The item that was deleted should be re-added to the dropdown list (drowdownList).
        // Maintain the Correct Order in the Dropdown List: The deleted item should be inserted back into the dropdown list at the correct position
        // based on its original order, ensuring that the order of options in the dropdown remains accurate.
        drowdownList[drpdwnKeys] = transformDropdown(data[parent] ?? [], defltdrpdwnlist, codekey);
        setDropdownList({ ...drowdownList });
    }

    const handleArrayObjADD = (parent, row, drpdwnkeys, value, codeKey) => {
        // add additional row 
        if (!data?.[parent]) data[parent] = [];
        data[parent].push(row);
        setData({ ...data });

        // remove selected option in dropdown list
        const aviailableList = drowdownList[drpdwnkeys].filter((item) => item[codeKey] !== value[codeKey]);
        drowdownList[drpdwnkeys] = aviailableList;
        setDropdownList({ ...drowdownList });
    }

    const handleDrpdwnOtherIncome = (value) => {
        // add additional row
        const row = { particular_code: value.particular_code, amount_amtp: "" };
        if (!data?.other_income_offered_details) data.other_income_offered_details = [];
        data.other_income_offered_details.push(row);
        setData({ ...data });

        // remove selected option in dropdown list
        const aviailableList = drowdownList.othinc_off_det.filter((item) => item.particular_code !== value.particular_code);
        drowdownList.othinc_off_det = aviailableList;
        setDropdownList({ ...drowdownList });
    }

    // simple obj update function 
    const handleInputChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const handleArrayTypeInputChange = (parent, index, name, value) => {
        data[parent][index][name] = value;
        setData({ ...data });
    };

    const handleObjInputChange = (parent, name, value) => {
        data[parent][name] = value;
        setData({ ...data });
    }

    // When an item is added to the data, you need to:
    // Remove Items from the Dropdown List: Any items that have been added to the data should be removed from the dropdown list (drowdownList).
    // Keep the Currently Active Item in the Dropdown List: Ensure that the item currently selected or active remains in the dropdown list,
    // even if it has been added to the data. This allows the user to continue interacting with the active item without it disappearing from the dropdown list.
    const unUsedDropdownList = (dfltList = [], list = [], selfkey) => {
        const usableObjList = converArrayObj_to_map(list, "code");
        const nonUsableDropdownList = dfltList.filter((item) => {
            if (item.code === selfkey) {
                return true;
            } else if (item?.code && !usableObjList.has(item?.code)) {
                return true
            } else {
                return false;
            }
        }
        );
        return nonUsableDropdownList;
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <fieldset className="salaryannux_details_fs">
                        <legend className="salaryannux_details_leg mb-0 pb-0">
                            <span className="salaryannux_details_allcall cursor_pointer">
                                <img src={showAll ? salaryannudangle : salaryanngledown} width={11} alt='' onClick={() => toggleAll()} />
                            </span>
                        </legend>
                    
                    
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs salayannexureII">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">


                                <tbody>
                                    <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                                        <th width="10" id="accordion" className="text-left accordion">
                                            <span className="salarytaxregime_edit_mastercollapse collapsed" data-toggle="collapse" href="#salarytaxregime_edit_mastercollap01" aria-expanded="false"></span>

                                        </th>
                                        <th width="550" className="text-left">
                                            Income from Salaries
                                        </th>
                                        <th width="120" className="text-right">
                                            {FormatNumberDecimal(data?.net_salary_amtp ?? 0)}
                                        </th>
                                    </tr>
                                    <tr className="hide-table-padding">
                                        <td colspan="15" className="p-0">
                                            <div id="salarytaxregime_edit_mastercollap01" className="in collapse">

                                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                                                    <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                                        <tbody>

                                                            <Salary17one
                                                                data={data} headingCodeLIst17one={headingCodeLIst17one} handleParentChildArrayNumberInputOnBlur={handleParentChildArrayNumberInputOnBlur}
                                                                handleParentChildArrayInputChange={handleParentChildArrayInputChange} updateEmployeeDetail={updateEmployeeDetail}
                                                                fixedkey17one={fixedkey17one} deleteIcon={deleteIcon} handleDelete17one={handleDelete17one}
                                                                drowdownList={drowdownList} handleDrpdwn17one={handleDrpdwn17one} taxRegime={taxRegime}
                                                                headingCodeListAllowance={headingCodeListAllowance} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                                                calcIcon={calcIcon} showCalcPopupsfunc={showCalcPopupsfunc} 
                                                                handleArrayTypeInputChange={handleArrayTypeInputChange} handleArrayObjDel={handleArrayObjDel}
                                                                allowanceObj={allowanceObj} handleArrayObjADD={handleArrayObjADD}
                                                                headingCodeListRetirbenfit={headingCodeListRetirbenfit} retirmentBenfitObj={retirmentBenfitObj}
                                                                USER_TYPE={USER_TYPE}
                                                            />


                                                            <Prequisites17Two
                                                                data={data} headingCodeList17two={headingCodeList17two} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                                                handleArrayTypeInputChange={handleArrayTypeInputChange} updateEmployeeDetail={updateEmployeeDetail}
                                                                fixedkey17two={fixedkey17two} deleteIcon={deleteIcon} handleArrayObjDel={handleArrayObjDel}
                                                                drowdownList={drowdownList} handleDrpdwn17two={handleDrpdwn17two}
                                                                USER_TYPE={USER_TYPE}

                                                            />

                                                            <ProfitSalary17three
                                                                data={data} headingCodeList17three={headingCodeList17three} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                                                handleArrayTypeInputChange={handleArrayTypeInputChange} updateEmployeeDetail={updateEmployeeDetail}
                                                                fixedkey17three={fixedkey17three} deleteIcon={deleteIcon} handleArrayObjDel={handleArrayObjDel}
                                                                drowdownList={drowdownList} handleDrpdwn17three={handleDrpdwn17three}
                                                                USER_TYPE={USER_TYPE}

                                                            />



                                                            <Deduction
                                                                data={data} handleInputChange={handleInputChange} updateEmployeeDetail={updateEmployeeDetail}
                                                                setWarning={setWarning} handleNumberInputOnBlur={handleNumberInputOnBlur}
                                                            />


                                                            <PrevEmployeSalary
                                                                data={data} handleInputChange={handleInputChange} updateEmployeeDetail={updateEmployeeDetail}
                                                                handleNumberInputOnBlur={handleNumberInputOnBlur}
                                                            />

                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div>
                                        </td>
                                    </tr>

                                    <IncHouseProperty
                                        data={data} calcIcon={calcIcon} showCalcHousePrtyPopupFunc={showCalcHousePrtyPopupFunc} handleNumberInputOnBlur={handleNumberInputOnBlur}
                                        handleInputChange={handleInputChange} updateEmployeeDetail={updateEmployeeDetail} setData={setData}
                                    />


                                    <IncOthSource
                                        data={data} headingCodeListothincoff={headingCodeListothincoff} handleArrayTypeInputChange={handleArrayTypeInputChange}
                                        updateEmployeeDetail={updateEmployeeDetail} fiexedkeyothincoff={fiexedkeyothincoff} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                        deleteIcon={deleteIcon} handleArrayObjDel={handleArrayObjDel} drowdownList={drowdownList}
                                        handleDrpdwnOtherIncome={handleDrpdwnOtherIncome}
                                    />




                                    <tr className="trrow_bgclr_purple">
                                        <th width="10">

                                        </th>
                                        <th width="" className="text-left">
                                            Gross Total Income
                                        </th>
                                        <th width="" className="text-right">
                                            {FormatNumberDecimal(data?.total_gross_income_amtp ?? "", ...Array(2), false)}
                                        </th>
                                    </tr>

                                    <DeductionVIA
                                        data={data} headingCodeListdedVI_A={headingCodeListdedVI_A} unUsedDropdownList={unUsedDropdownList}
                                        drowdownList={drowdownList} taxRegime={taxRegime} setData={setData} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                        updateEmployeeDetail={updateEmployeeDetail} handleArrayTypeInputChange={handleArrayTypeInputChange}
                                        deleteIcon={deleteIcon} addBtnRoundIcon={addBtnRoundIcon} dedVI_A_RowObj={dedVI_A_RowObj}
                                    />

                                    <OtherDetails
                                        data={data} setData={setData} updateEmployeeDetail={updateEmployeeDetail}
                                        otherDetailError={otherDetailError} handleParentChildArrayInputChange={handleParentChildArrayInputChange}
                                        deleteIcon={deleteIcon} financialYear={financialYear} handleParentChildArrayNumberInputOnBlur={handleParentChildArrayNumberInputOnBlur}
                                        addBtnRoundIcon={addBtnRoundIcon} landlordRowObj={landlordRowObj} handleObjInputChange={handleObjInputChange}
                                        setDeducteeDet={setDeducteeDet} deducteeDet={deducteeDet} setOtherDetailError={setOtherDetailError}
                                        handleObjNumberInputOnBlur={handleObjNumberInputOnBlur}
                                    />

                                    <FixedInput
                                        data={data} handleObjInputChange={handleObjInputChange} updateEmployeeDetail={updateEmployeeDetail} 
                                        handleObjNumberInputOnBlur={handleObjNumberInputOnBlur} relief89usErr={relief89usErr}
                                    />
                                </tbody>
                            </table>
                        </div>
                    </fieldset>

                </div>
            </div>
        </>
    )
}

export default EmployeeSalaryDetail