/*eslint-disable no-useless-escape */
import React from 'react';
const nPattern = new RegExp(/^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/);

const NumericInputWithHundredDecimalTwo = (props) => {
  const { value, onChange, ...rest } = props;
  return (
    <input
      value={value}
      onChange={(e) => {
        if ((nPattern.test(e.target.value) && e.target.value <= 100) || e.target.value === '') {
          onChange(e);
        }
      }}
      {...rest}
    />
  );
};

export default NumericInputWithHundredDecimalTwo;
