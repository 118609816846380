/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import './FileUploadPopup.css';
import { importRaiseRequestData } from '../../../api/returnAPI';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';



const FileUploadPopup = ({ history, ...props }) => {
  const {
    show, handleClose, deductorId, returnId, handleProceedImportFUVFile, importFUVFileData
  } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);

  const {
    isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject,
  } = useDropzone({
    // accept: '.txt',
  });

  const location = useLocation();
  const params = queryString.parse(location.search);


  const removeFile = (targetFile) => {
    const newFiles = [];
    files.map((file) => {
      if (file.path === targetFile.path) return;
      newFiles.push(file);
    });
    setFiles(newFiles);
  };

  const renderFiles = files.map((file) => (
    <li key={file.path} style={{ margin: '5px' }}>
      <span>
        {file.path}
        {' '}
        -
        {file.size}
        {' '}
        bytes
      </span>
      <Button
        variant="danger"
        style={{
          fontSize: '12px', padding: '0px', paddingLeft: '5px', paddingRight: '5px', marginLeft: '10px',
        }}
        onClick={() => removeFile(file)}
      >
        Remove
      </Button>
    </li>
  ));

  const uploadFiles = async () => {
    setLoading(true);
    const formData = new FormData();
    files.map((file) => {
      formData.append('file', file);
      formData.append('deductor_id', deductorId);
      if (returnId) {
        formData.append('return_id', returnId);
      }

      if (importFUVFileData && importFUVFileData.ft) {
        formData.append('form_type', importFUVFileData.ft);
      }

      if (importFUVFileData && importFUVFileData.fy) {
        formData.append('financial_year', importFUVFileData.fy);
      }

      if (importFUVFileData && importFUVFileData.quarter) {
        formData.append('quarter', importFUVFileData.quarter);
      }

      if (!params.financial_year) {
        formData.append('import_data', true);
      }

    });

    try {
      const result = await importRaiseRequestData(formData);
      handleProceedImportFUVFile(result);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const newFiles = acceptedFiles;
    const uniqueFiles = [];
    const flags = [];
    const l = newFiles.length;
    let i;
    for (i = 0; i < l; i++) {
      if (flags[newFiles[i].path]) continue;
      flags[newFiles[i].path] = true;
      uniqueFiles.push(newFiles[i]);
    }
    setFiles(uniqueFiles);
  }, [acceptedFiles]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="mkdmdbox"
      >
        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title>Import txt file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps({ accept: '.txt', multiple: false })} />
              <p>
                {!isDragActive && 'Drag \'n\' drop files here, or click to select files'}
                {isDragActive && !isDragReject && 'Drop files here'}
                {isDragReject && 'File type not accepted, sorry!'}
              </p>
            </div>
            <aside>
              <h6>Files</h6>
              <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
            </aside>
          </section>

        </Modal.Body>
        <Modal.Footer className="ftpaddmk">
          {error
            ? (
              <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>
            )
            : null}
          <Button className="mboxcbtn" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="mboxsubmbtn" variant="primary" onClick={uploadFiles} disabled={loading || files.length === 0}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {' '}
                Loading...
              </>
            ) : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileUploadPopup;
