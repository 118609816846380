/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from 'moment';
import './pdf_template.css';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { DEDUCTOR_CATEGORIES, STATE_LIST, DEPARTMENT_NAMES, NATURE_COLLECTIONS } from '../../../app/constants';

const PDF27EQ = (props) => {
    const pdfExportComponent = React.useRef(null);

    const { PDFTemplateData, handleClose, prnData } = props;

    var end_year = PDFTemplateData.batch_header.financial_yr.substring(0, 2) + PDFTemplateData.batch_header.financial_yr.substring(4, 6);
    var start_year = PDFTemplateData.batch_header.financial_yr.substring(0, 4);

    const deductor_address_state = STATE_LIST.filter((data) => { return data.value === parseInt(PDFTemplateData.batch_header.deductor_address_state) });
    const responsible_person_state = STATE_LIST.filter((data) => { return data.value === parseInt(PDFTemplateData.batch_header.responsible_person_state) });
    const quarter_last_month = PDFTemplateData.batch_header.period === 'Q1' ? 6 : PDFTemplateData.batch_header.period === 'Q2' ? 9 : PDFTemplateData.batch_header.period === 'Q3' ? 12 : 3;
    const quarter_last_year = PDFTemplateData.batch_header.period === 'Q4' ? moment(end_year + '-01-01', 'YYYY-MM-DD').format("YYYY") : moment(start_year + '-01-01', 'YYYY-MM-DD').format("YYYY");

    useEffect(() => {

        setTimeout(function () {
            if (pdfExportComponent.current) {
                pdfExportComponent.current.save(() => {
                    handleClose();
                });
            }

        }, 500);


    }, []);


    const convertChallanNumner = (challan_no) => {
        if (challan_no) {
            return String(challan_no).padStart(5, '0');
        }
        else {
            return "-";
        }
    }

    return (
        <div id="pdf_template_area" style={{ position: 'absolute', top: '0px', left: '-100%' }}>
            <PDFExport
                ref={pdfExportComponent}
                forcePageBreak=".page-break"
                paperSize="A3"
                margin="1cm"
                landscape={true}
                title="Return Form No. 27EQ"
                fileName={PDFTemplateData.file_header.tan_of_deductor + '_27EQ_' + PDFTemplateData.batch_header.period + '_' + PDFTemplateData.batch_header.financial_yr + ".pdf"}
            >
                <div className="pdf_template" id="pdf_template">
                    <table style={{ width: '1024px', margin: '10px', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                        <tr>
                            <td>
                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody>
                                        <tr>
                                            <th colSpan="5" style={{ border: 'none', textAlign: 'center', fontSize: '12px' }}>Form No. 27EQ</th>
                                        </tr>
                                        <tr>
                                            <th colSpan="5" style={{ border: 'none', textAlign: 'center' }}>
                                                [See section 206C, 206CC, 206CCA and rule 31AA]</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="text-center">Quarterly Statement of Collection of Tax at Source under Section 206C of the Income-Tax Act for the Quarter Ended<strong> {moment('1969-' + quarter_last_month + '-01').format("MMMM")} {quarter_last_year}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '12px' }}>1.</th>
                                            <td> <span style={{ paddingRight: '7px' }}>(a)</span> Tax Deduction and Collection A/C No. (TAN)</td>
                                            <th style={{ fontSize: '11px', textAlign: 'left', width: '7.3rem' }}>{PDFTemplateData.file_header.tan_of_deductor}	</th>

                                            <td><span style={{ paddingRight: '7px' }}>(d)</span> Has the statement been filed earlier for this quarter (Yes/No)</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{prnData.previous_prn ? 'Yes' : 'No'}</th>
                                        </tr>
                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>&nbsp;</th>
                                            <td><span style={{ paddingRight: '7px' }}>(b)</span> PAN No. or Aadhaar No.</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{PDFTemplateData.batch_header.pan_deductor}</th>

                                            <td><span style={{ paddingRight: '7px' }}>(e)</span> If answer to (d) is "Yes", then Token No. of original statement</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{prnData.previous_prn ? prnData.previous_prn : '-'}</th>
                                        </tr>

                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>&nbsp;</th>
                                            <td><span style={{ paddingRight: '7px' }}>(c)</span> Financial Year</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{PDFTemplateData.batch_header.financial_yr.substring(0, 4)} - {PDFTemplateData.batch_header.financial_yr.substring(4, 6)}</th>

                                            <td><span style={{ paddingRight: '7px' }}>(f)</span> Type of Collector</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{DEDUCTOR_CATEGORIES[PDFTemplateData.batch_header.collector_type]}</th>
                                        </tr>


                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}>2.</th>
                                            <th colSpan="4" style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}>Particulars of the Collector</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', width: '19.7rem' }}>(a) Name of the Collector</td>
                                            <td colSpan="3" style={{ norder: 'none', textAlign: 'left', fontSize: '12px' }}>
                                                <strong>{PDFTemplateData.batch_header.deductor_name}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ paddingLeft: '35px', paddingBottom: '0px' }}>(b) If Central/State Government</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.ministry_name ? DEPARTMENT_NAMES[PDFTemplateData.batch_header.ministry_name] : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>AIN Code of PAO/TO/CDDO </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.account_office_identification_number ? PDFTemplateData.batch_header.account_office_identification_number : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', paddingTop: '0px' }}>(c) TAN Reg. No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>-</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ paddingLeft: '35px' }}>(d) Address</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingBottom: '0px' }}>Flat No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingBottom: '0px' }}><strong>{PDFTemplateData.batch_header.collector_address1 ? PDFTemplateData.batch_header.collector_address1 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name of the Premises/Building </td>
                                            <td colSpan="3" tyle={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_address2 ? PDFTemplateData.batch_header.collector_address2 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Road/Street/Lane </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_address3 ? PDFTemplateData.batch_header.collector_address3 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Area/Location </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_address4 ? PDFTemplateData.batch_header.collector_address4 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Town/City/District</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_address5 ? PDFTemplateData.batch_header.collector_address5 : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>State </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{deductor_address_state.length ? deductor_address_state[0].label : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>PIN Code </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_address_pincode ? PDFTemplateData.batch_header.collector_address_pincode : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Telephone No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_std_code} - {PDFTemplateData.batch_header.collector__phone_no}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Telephone No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_std_code_alternate} - {PDFTemplateData.batch_header.collector_phone_no_alternate}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Email </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_email ? PDFTemplateData.batch_header.collector_email : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Email </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.collector_email_alternate ? PDFTemplateData.batch_header.collector_email_alternate : '-'}</strong></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>

                    <table className="page-break" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                        <tr>
                            <td>

                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '30px' }}>&nbsp;</th>
                                            <td style={{ width: '17.5rem' }}>&nbsp;</td>
                                            <th style={{ width: '7.5rem' }}>&nbsp;</th>
                                            <td>&nbsp;</td>
                                            <th>&nbsp;</th>
                                        </tr>

                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}> 3.</th>
                                            <th colSpan="4" style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}> 3. Particulars of the Person Responsible for Collection of Tax</th>
                                        </tr>

                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', width: '19.7rem' }}>(a) Name</td>
                                            <td colSpan="3" style={{ norder: 'none', textAlign: 'left', fontSize: '12px' }}>
                                                <strong>{PDFTemplateData.batch_header.name_person_responsible_for_collection ? PDFTemplateData.batch_header.name_person_responsible_for_collection : ''}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ paddingLeft: '35px' }}>(b) Address</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Flat No. </td>
                                            <td colSpan="3" style={{ fontSize: '13px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address1 ? PDFTemplateData.batch_header.responsible_person_address1 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name of Premises/Building </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address2 ? PDFTemplateData.batch_header.responsible_person_address2 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Road/Street/Lane</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address3 ? PDFTemplateData.batch_header.responsible_person_address3 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Area/Location</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address4 ? PDFTemplateData.batch_header.responsible_person_address4 : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Town/City/District </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address5 ? PDFTemplateData.batch_header.responsible_person_address5 : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>State</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{responsible_person_state.length ? responsible_person_state[0].label : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>PIN Code </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_pin ? PDFTemplateData.batch_header.responsible_person_pin : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Telephone No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_std_code} - {PDFTemplateData.batch_header.responsible_person_phone_no}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Telephone No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_std_code_alternate} - {PDFTemplateData.batch_header.responsible_person_phone_no_alternate}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Email  </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_email ? PDFTemplateData.batch_header.responsible_person_email : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Email </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_email_alternate ? PDFTemplateData.batch_header.responsible_person_email_alternate : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Mobile No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.mobile_number ? PDFTemplateData.batch_header.mobile_number : '-'}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>4. Details of tax collected at source and paid to the credit of the Central Government:</th>
                                        </tr>
                                    </tbody>
                                </table>

                                <table className="table27bor" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody><tr>
                                        <th>Sr. No</th>
                                        <th>Tax</th>
                                        <th>Surcharge</th>
                                        <th>Health and Education Cess</th>
                                        <th>Interest</th>
                                        <th>Fee</th>
                                        <th>Penalty / Others</th>
                                        <th>Total Amt Deposited as per Challan / Book Adjustment (652+653+654+655+656+657)</th>
                                        <th>Mode of Deposit through Challan (C) / Book Adjustment (B)</th>
                                        <th>BSR Code / Receipt No. of Form No. 24G</th>
                                        <th>Challan Serial No. / DDO Serial No. of Form No. 24G</th>
                                        <th>Date on which Amt Deposited through Challan / Date of Transfer Voucher</th>
                                        <th>Minor Head of Challan</th>
                                    </tr>
                                        <tr>
                                            <td className="text-center">[651]</td>
                                            <td className="text-right">[652]</td>
                                            <td className="text-right">[653]</td>
                                            <td className="text-right">[654]</td>
                                            <td className="text-right">[655]</td>
                                            <td className="text-right">[656]</td>
                                            <td className="text-right">[657]</td>
                                            <td className="text-right">[658]</td>
                                            <td className="text-center">[659]</td>
                                            <td className="text-center">[660]</td>
                                            <td className="text-center">[661]</td>
                                            <td className="text-center">[662]</td>
                                            <td className="text-center">[663]</td>
                                        </tr>
                                        {PDFTemplateData.challan_details && PDFTemplateData.challan_details.map((challan_details, index) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{challan_details.challan_detail_record_number ? challan_details.challan_detail_record_number : '-'}</td>
                                                    <td className="text-right">{challan_details.oltas_TDS_TCS_income_tax ? formatNumber(challan_details.oltas_TDS_TCS_income_tax) : '-'}</td>
                                                    <td className="text-right">{challan_details.oltas_TDS_TCS_surcharge ? formatNumber(challan_details.oltas_TDS_TCS_surcharge) : '-'}</td>
                                                    <td className="text-right">{challan_details.oltas_TDS_TCS_cess ? formatNumber(challan_details.oltas_TDS_TCS_cess) : '-'}</td>
                                                    <td className="text-right">{challan_details.oltas_TDS_TCS_interest_amount ? formatNumber(challan_details.oltas_TDS_TCS_interest_amount) : '-'}</td>
                                                    <td className="text-right">{challan_details.fee ? formatNumber(challan_details.fee) : '-'}</td>
                                                    <td className="text-right">{challan_details.oltas_TDS_TCS_others_amount ? formatNumber(challan_details.oltas_TDS_TCS_others_amount) : '-'}</td>
                                                    <td className="text-right">{challan_details.total_deposit_amount_challan_transfer_voucher_number ? formatNumber(challan_details.total_deposit_amount_challan_transfer_voucher_number) : '-'}</td>
                                                    <td className="text-center">{challan_details.By_book_entry_cash === 'Y' ? 'B' : 'C'}</td>
                                                    <td className="text-center">{challan_details.bank_branch_code_Form_24G_Receipt_Number ? challan_details.bank_branch_code_Form_24G_Receipt_Number : '-'}</td>
                                                    <td className="text-center">
                                                        {PDFTemplateData.batch_header.deductor_type !== 'A' ?
                                                            convertChallanNumner(challan_details.bank_challan_no) :
                                                            convertChallanNumner(challan_details.ddo_serial_number_of_form_24G)
                                                        }
                                                    </td>
                                                    <td className="text-center">{challan_details.date_of_bank_challan_transfer_voucher ? moment(challan_details.date_of_bank_challan_transfer_voucher, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                                    <td className="text-center">{challan_details.minor_head_of_challan ? challan_details.minor_head_of_challan : '-'}</td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>

                                <table style={{ width: '1024px', margin: '10px', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                                    <tr>
                                        <td>
                                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px', marginTop: '15px' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>5. Details of Amt Paid and Tax Collected thereon (see Annexure)</th>
                                                    </tr>
                                                </tbody>
                                            </table>


                                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '25px', marginBottom: '25px' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ fontSize: '13px', textAlign: 'center' }}> Verification</th>
                                                    </tr>
                                                    <tr>
                                                        <td>I, <strong>{PDFTemplateData.batch_header.name_person_responsible_for_collection}</strong>, hereby certify that all the particulars furnished above are correct and complete.</td>
                                                    </tr>
                                                </tbody></table>

                                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '15px', marginBottom: '15px' }}>
                                                <tbody><tr>
                                                    <td style={{ paddingBottom: '0px' }}>Place: <strong>{PDFTemplateData.batch_header.responsible_person_address5}</strong></td>
                                                    <td style={{ paddingBottom: '0px', textAlign: 'right' }}>Signature of the person responsible for collecting tax at source </td>
                                                    <td></td>
                                                </tr>
                                                    <tr>
                                                        <td style={{ paddingTop: '0px' }}>
                                                            Date: <strong>{moment(PDFTemplateData.file_header.file_creation_date, 'DDMMYYYY').format('DD/MM/YYYY')}</strong>
                                                        </td>
                                                        <td style={{ paddingTop: '0px', textAlign: 'right' }}>
                                                            Name and designation of the person responsible for collecting <br />
                                                            Tax at Source
                                                        </td>
                                                        <td style={{ paddingTop: '0px', paddingLeft: '10px', width: '20%' }}>
                                                            <strong>{PDFTemplateData.batch_header.name_person_responsible_for_collection}</strong> <br />
                                                            <strong>{PDFTemplateData.batch_header.designation_person_responsible_collection ? PDFTemplateData.batch_header.designation_person_responsible_deduction : '-'}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </table>

                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', textAlign: 'right', marginTop: '15px' }}>
                                    <tr>
                                        <td className="text-right"><strong>Express TDS - A KDK Software Product</strong></td>
                                    </tr>
                                </table>

                            </td>
                        </tr>
                    </table>


                    {PDFTemplateData.challan_details && PDFTemplateData.challan_details.map((challan_details, index) => {

                        let total_value_of_purchase = 0;
                        let amount_of_receipt_debited = 0;
                        let TDS_TCS_income_tax = 0;
                        let TDS_TCS_surcharge = 0;
                        let TDS_TCS_cess = 0;
                        let total_income_tax_deducted_at_source_TDS_TCS_income_tax = 0;
                        let total_tax_deposited = 0;


                        return (<>
                            <table className="page-break" style={{ width: '1024px', margin: '10px', border: '0px solid #333', marginTop: '25px', marginBottom: '25px' }}>
                                <tr>
                                    <th style={{ textAlign: 'center', fontSize: '14px' }}>ANNEXURE -: PARTY WISE BREAK UP OF TCS<br />
                                        (Please use separate Annexure for each-line item in Table at Sr. No. 04 of main Form 27EQ)
                                    </th>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>
                                        Details of Amt Received / Debited during the quarter ended <strong>{moment(quarter_last_year + '-' + quarter_last_month + '-01').endOf('month').format("DD/MM/YYYY")}</strong> and of tax collected at source
                                    </td>
                                </tr>
                            </table>
                            <table className="table04bor" style={{ width: '1024px', margin: '10px', border: '0px solid #333', marginTop: '15px', marginBottom: '15px' }}>
                                <tbody><tr>
                                    <td>Name of the Collector</td>
                                    <td><strong>{PDFTemplateData.batch_header.deductor_name ? PDFTemplateData.batch_header.deductor_name : '-'}</strong></td>
                                </tr>
                                    <tr>
                                        <td>TAN</td>
                                        <td><strong>{PDFTemplateData.file_header.tan_of_deductor}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>BSR Code of Branch / Receipt No. of Form No. 24G</td>
                                        <td><strong>{challan_details.bank_branch_code_Form_24G_Receipt_Number ? challan_details.bank_branch_code_Form_24G_Receipt_Number : '-'}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Date on which Challan Deposited / Transfer Voucher Date</td>
                                        <td><strong>{challan_details.date_of_bank_challan_transfer_voucher ? moment(challan_details.date_of_bank_challan_transfer_voucher, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Challan Serial No. / DDO Serial No. of Form No. 24G</td>
                                        <td>
                                            <strong> {PDFTemplateData.batch_header.deductor_type !== 'A' ?
                                                convertChallanNumner(challan_details.bank_challan_no) :
                                                convertChallanNumner(challan_details.ddo_serial_number_of_form_24G)
                                            }</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Amt as per Challan</td>
                                        <td><strong>{challan_details.total_deposit_amount_challan_transfer_voucher_number ? formatNumber(challan_details.total_deposit_amount_challan_transfer_voucher_number) : '-'}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Total TCS to be allocated among collectees as in the vertical total of Col. 677</td>
                                        <td>{challan_details.total_tax_deposit_amount_deductee_annexure ? formatNumber(challan_details.total_tax_deposit_amount_deductee_annexure) : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Total interest to be allocated among the collectees mentioned below</td>
                                        <td>{challan_details.oltas_TDS_TCS_interest_amount ? formatNumber(challan_details.oltas_TDS_TCS_interest_amount) : '-'}</td>
                                    </tr>
                                </tbody></table>

                            <table className="table27bor" style={{ width: '1024px', margin: '10px', border: '0px solid #333', marginBottom: '15px', marginTop: '1rem' }}>
                                <tbody>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Collectee reference number provided by the collector, if available</th>
                                        <th>Collectee code</th>
                                        <th>Whether resident or nonresident (Y/N)</th>
                                        <th>PAN of the Collectee</th>
                                        <th>Whether collectee opting out of taxation regime u/s 115BAC(1A)?</th>
                                        <th>Name of Collectee</th>
                                        <th>Total Value of Transaction</th>
                                        <th>Amt Rcvd. / Debited</th>
                                        <th>Date on which Amt  Received / Debited</th>
                                        <th>Collection Code</th>
                                  
                                    </tr>
                                    <tr>
                                        <td className="text-center">[664] </td>
                                        <td className="text-center">[665]</td>
                                        <td className="text-center">[666A]</td>
                                        <td className="text-center">[666B]</td>
                                        <td className="text-center"> [667A]</td>
                                        <td className="text-center"> [667B]</td>
                                        <td className="text-center">[668]</td>
                                        <td className="text-right"> [669]</td>
                                        <td className="text-right"> [670]</td>
                                        <td className="text-center"> [671]</td>
                                        <td className="text-center"> [672]</td>
                                       

                                    </tr>
                                    {challan_details.deductee_details && challan_details.deductee_details.map((deductee_details, index) => {

                                        total_value_of_purchase = parseFloat(deductee_details.total_value_of_purchase) + parseFloat(total_value_of_purchase);
                                        amount_of_receipt_debited = parseFloat(deductee_details.amount_of_receipt_debited) + parseFloat(amount_of_receipt_debited);
                                        TDS_TCS_income_tax = parseFloat(deductee_details.TDS_TCS_income_tax) + parseFloat(TDS_TCS_income_tax);
                                        TDS_TCS_surcharge = parseFloat(deductee_details.TDS_TCS_surcharge) + parseFloat(TDS_TCS_surcharge);
                                        TDS_TCS_cess = parseFloat(deductee_details.TDS_TCS_cess) + parseFloat(TDS_TCS_cess);
                                        total_income_tax_deducted_at_source_TDS_TCS_income_tax = parseFloat(deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax) + parseFloat(total_income_tax_deducted_at_source_TDS_TCS_income_tax);
                                        total_tax_deposited = parseFloat(deductee_details.total_tax_deposited) + parseFloat(total_tax_deposited);

                                        return (<tr>
                                            <td className="text-center">{deductee_details.party_detail_record_no ? deductee_details.party_detail_record_no : '-'}</td>
                                            <td className="text-center">{deductee_details.deductee_ref_no ? deductee_details.deductee_ref_no : '-'}</td>
                                            <td className="text-center">{deductee_details.party_code ? deductee_details.party_code : '-'}</td>
                                            <td className="text-center">{deductee_details.deductee_is_non_resident ? deductee_details.deductee_is_non_resident : '-'}</td>
                                            <td className="text-center">{deductee_details.party_pan ? deductee_details.party_pan : '-'}</td>
                                            <td className="text-center">{deductee_details.whether_collectee_opting_out_115bac ? deductee_details.whether_collectee_opting_out_115bac : '-'}</td>
                                            <td className="text-center">{deductee_details.name_of_party ? deductee_details.name_of_party : '-'}</td>
                                            <td className="text-right">{deductee_details.total_value_of_purchase ? formatNumber(deductee_details.total_value_of_purchase) : '-'}</td>
                                            <td className="text-right">{deductee_details.amount_of_receipt_debited ? formatNumber(deductee_details.amount_of_receipt_debited) : '-'}</td>
                                            <td className="text-center">{deductee_details.date_on_which_amount_received_debited ? moment(deductee_details.date_on_which_amount_received_debited, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                            <td className="text-center">{NATURE_COLLECTIONS[deductee_details.section_code_under_which_payment_made] ? NATURE_COLLECTIONS[deductee_details.section_code_under_which_payment_made] : '-'}</td>
                                        </tr>)
                                    })}


                                    <tr>
                                        <td className="text-center"><strong>Total</strong></td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-right"><strong>{formatNumber(total_value_of_purchase)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(amount_of_receipt_debited)}</strong></td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>


                                    </tr>

                                </tbody>
                            </table>


                            <table className="table27bor" style={{ width: '1024px', margin: '10px', border: '0px solid #333', marginBottom: '15px', marginTop: '1rem' }}>
                                <tbody>
                                    <tr>
                                        <th>Tax</th>
                                        <th>Surcharge</th>
                                        <th>Health and Education Cess</th>
                                        <th>Total tax collected [673+674+675] </th>
                                        <th>Total Tax Deposited</th>
                                        <th>Date of Collection</th>
                                        <th>Rate at which Tax Collected</th>
                                        <th>Reason for Non / Lower / Higher Collection</th>
                                        <th>No. of the Cert u/s 206C issued by Assessing Officer for Lower Collection of Tax</th>
                                        <th>Payment by Collectee Liable and Deducted to TDS under F or G?</th>
                                        <th>If, answer to [681A] is yes, then Challan No.</th>
                                        <th>If, answer to [681A] is yes, then Date of payment of TDS to Central Govt.</th>
                                    </tr>
                                    <tr>

                                        <td className="text-right"> [673]</td>
                                        <td className="text-right"> [674]</td>
                                        <td className="text-right"> [675]</td>
                                        <td className="text-right"> [676]</td>
                                        <td className="text-right"> [677]</td>
                                        <td className="text-center">[678]</td>
                                        <td className="text-center">[679]</td>
                                        <td className="text-center">[680]</td>
                                        <td className="text-center">[681]</td>
                                        <td className="text-center">[681A]</td>
                                        <td className="text-center">[681B]</td>
                                        <td className="text-center">[681C]</td>

                                    </tr>
                                    {challan_details.deductee_details && challan_details.deductee_details.map((deductee_details, index) => {


                                        return (<tr>
                                            <td className="text-right">{deductee_details.TDS_TCS_income_tax ? formatNumber(deductee_details.TDS_TCS_income_tax) : '-'}</td>
                                            <td className="text-right">{deductee_details.TDS_TCS_surcharge ? formatNumber(deductee_details.TDS_TCS_surcharge) : '-'}</td>
                                            <td className="text-right">{deductee_details.TDS_TCS_cess ? formatNumber(deductee_details.TDS_TCS_cess) : '-'}</td>
                                            <td className="text-right">{deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax ? formatNumber(deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax) : '-'}</td>
                                            <td className="text-right">{deductee_details.total_tax_deposited ? formatNumber(deductee_details.total_tax_deposited) : '-'}</td>
                                            <td className="text-center">{deductee_details.date_on_which_tax_collected ? moment(deductee_details.date_on_which_tax_collected, 'DDMMYYYY').format('DD/MM/YYYY') : '-'}</td>
                                            <td className="text-center">{deductee_details.rate_at_which_tax_deducted_collected ? deductee_details.rate_at_which_tax_deducted_collected : '-'}</td>
                                            <td className="text-center">{deductee_details.remarks_1 ? deductee_details.remarks_1 : '-'}</td>
                                            <td className="text-center"> {deductee_details.certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction ? deductee_details.certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction : '-'}</td>
                                            <td className="text-center"> {deductee_details.whether_the_payment_by_collectee_is_liable_to_TDS_as_per_clause ? 'Yes' : 'No'}</td>
                                            <td className="text-center"> {deductee_details.whether_the_payment_by_collectee_is_liable_to_TDS_as_per_clause === 'Y' ? deductee_details.challan_number : '-'}</td>
                                            <td className="text-center"> {deductee_details.whether_the_payment_by_collectee_is_liable_to_TDS_as_per_clause === 'Y' ? moment(deductee_details.date_of_payment_of_TDS_to_central_government, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                        </tr>)
                                    })}


                                    <tr>

                                        <td className="text-right"><strong>{formatNumber(TDS_TCS_income_tax)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(TDS_TCS_surcharge)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(TDS_TCS_cess)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(total_income_tax_deducted_at_source_TDS_TCS_income_tax)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(total_tax_deposited)}</strong></td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>

                                    </tr>

                                </tbody>
                            </table>




                            <table style={{ width: '1024px', margin: '10px', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                                <tr>
                                    <td>
                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '15px', marginBottom: '15px' }}>
                                            <tr>
                                                <th style={{ textAlign: 'center', fontSize: '12px' }}>
                                                    Verification
                                                </th>
                                            </tr>
                                        </table>
                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                            <tbody>
                                                <tr>
                                                    <td>I, <strong>{PDFTemplateData.batch_header.name_person_responsible_for_collection}</strong>, hereby certify that all the particulars furnished above are correct and complete.</td>
                                                </tr>
                                            </tbody></table>
                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '15px', marginBottom: '15px' }}>
                                            <tbody><tr>
                                                <td style={{ paddingBottom: '0px' }}>Place: <strong>{PDFTemplateData.batch_header.responsible_person_address5}</strong></td>
                                                <td style={{ paddingBottom: '0px', textAlign: 'right' }}>Signature of the Person Responsible for Deducting Tax at Source</td>
                                                <td></td>
                                            </tr>
                                                <tr>
                                                    <td style={{ paddingTop: '0px' }}>
                                                        Date: <strong>{moment(PDFTemplateData.file_header.file_creation_date, 'DDMMYYYY').format('DD/MM/YYYY')}</strong>
                                                    </td>
                                                    <td style={{ paddingTop: '0px', textAlign: 'right' }}>
                                                        Name and Designation of the Person Responsible for Deducting <br />
                                                        Tax at Source
                                                    </td>
                                                    <td style={{ paddingTop: '0px', paddingLeft: '10px', width: '20%' }}>
                                                        <strong>{PDFTemplateData.batch_header.name_person_responsible_for_collection}</strong> <br />
                                                        <strong>{PDFTemplateData.batch_header.designation_person_responsible_collection ? PDFTemplateData.batch_header.designation_person_responsible_collection : '-'}</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', textAlign: 'right', marginTop: '15px' }}>
                                            <tr>
                                                <td className="text-right"><strong>Express TDS - A KDK Software Product</strong></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </>)
                    })}
                </div >
            </PDFExport>
        </div>
    );
};

export default PDF27EQ;