import React from 'react'
import { formatNumber } from '../../../utils/UtilityFunctions'

const ViewPrevEmployer = (props) => {
    const { data } = props;
    return (
        <>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                        <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">

                            <tbody>


                                <tr className="">
                                    <td width="37">
                                    </td>
                                    <td className="salaryprompre_textfs pl-2" colspan="2">
                                        Salary From previous Employer
                                    </td>

                                    <td width="" className="text-right">
                                        {formatNumber(data?.previous_employer_amount_received_amtp ?? 0)}
                                    </td>
                                    <td width="">
                                        TDS already dedcuted on such salary
                                    </td>
                                    <td width="" className="text-right">
                                        {formatNumber(data?.previous_employer_tds_amtp ?? 0)}
                                    </td>

                                </tr>


                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewPrevEmployer