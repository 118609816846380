import React, { useState, useEffect, useCallback } from 'react';
import { getSectionWiseTransactionV2 } from '../../../api/deducteeTransactionsAPI';
import Chart from 'react-google-charts';
import SkeletonGraphCircle from '../../../components/Skeleton/SkeletonGraphCircle';
import useIntersection from '../../../utils/useIntersection';


const SectionWiseSummaryChart = (props) => {

  const { deductorId, returnId, sectionWiseSummaryRef } = props;

  const [chartData, setChartData] = useState([]);
  const [skeletonShow, setSkeletonShow] = useState([]);
  const [skeletonChartLoading, setSkeletonChartLoading] = useState(true);
  const inViewport = useIntersection(sectionWiseSummaryRef, "0px");

  const SectionWiseSummaryData = useCallback(async (deductorId, returnId) => {
    try {
      const payload = {
        deductor_id: deductorId,
        return_id: returnId
      }
      const result = await getSectionWiseTransactionV2(payload);
      setSectionWiseChartData(result?.data?.data ?? []);

    } catch (err) {
      console.error(err);
      //setError(err.toString());      
    } finally {
      setSkeletonChartLoading(false);
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (inViewport && deductorId && returnId) {
      SectionWiseSummaryData(deductorId, returnId);
    }
    // eslint-disable-next-line
  }, [inViewport, deductorId, returnId])

  const setSectionWiseChartData = (data) => {
    let chartDataList = [];
    let dataInSkeleton = [];
    if (data.length > 0) {
      chartDataList.push(['Task', 'Sction wise summary'],);
      data.forEach((item) => {
        if (parseInt(item?.deductee_total_tds_amtp ?? 0) > 0) {
          chartDataList.push([`${item?.display_code}`, parseInt(item?.deductee_total_tds_amtp ?? 0)])
        } else {
          dataInSkeleton.push(`TDS Amount for Section ${item?.display_code} = ${parseInt(item?.deductee_total_tds_amtp ?? 0)}`);
        }
      })
    }
    setChartData((prev) => {
      prev = chartDataList
      return [...prev]
    });

    setSkeletonShow((prev) => {
      prev = dataInSkeleton
      return [...prev]
    })
  }

  return (
    <>
      <div className="col-md-5">

        <fieldset className="sectionwisechalla_fs h-100">
          <legend className="sectionwisechalla_leg">
            <h4>
              Section Wise Summary of Trans.
            </h4>
          </legend>
          <div className="row" ref={sectionWiseSummaryRef}>
            <div className="col-md-12">
              <div className="compcheck_chartbox text-center p-1">
                {
                  skeletonChartLoading ? <SkeletonGraphCircle />
                    :
                    chartData.length > 1
                      ?
                      <Chart
                        width="100%"
                        height="295px"
                        chartType="PieChart"
                        //loader={<div style={{ paddingTop: '25px', paddingLeft: '280px', fontSize: '12px' }}>Loading Chart...</div>}
                        data={chartData}
                        options={{
                          title: '',
                          //legend: { position: 'labeled', labeledValueText: 'value', },
                          legend: { position: 'bottom', labeledValueText: 'both', },
                          tooltip: { text: 'both' },
                          sliceVisibilityThreshold: 0,
                          is3D: true
                        }}
                        rootProps={{ 'data-testid': '1' }}
                      />
                      :
                      skeletonShow && skeletonShow.length > 0
                        ?
                        <div className='h-100 pt-4'>
                          <div class="row">
                            <div className='col-12 col-xl-6 col-lg-5 col-md-12 pt-1'>
                              <div class="browserbutton_box browser_sectionwise_summ_chartbgimg">

                              </div>
                            </div>
                            <div className='col-12 col-xl-6 col-lg-7 col-md-12 text-center'>
                              <div class="browserbutton_box pt-3">
                                <div class="clickaablebtn_box pt-4">
                                  {skeletonShow.map((item) => {
                                    return (
                                      <p class="No_Records_to_Display_textfs mb-1 pt-0">
                                        {item}
                                      </p>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className='h-100' style={{ paddingTop: '150px' }}>No Such data to Display</div>
                }

              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </>

  );
};

export default SectionWiseSummaryChart;