/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable require-jsdoc */
import React from 'react';
import Profile from '../../components/profile/Profile';

const SelfProfile = () => {

  return (
    <div>
      <Profile />
    </div>
  );
};

export default SelfProfile;
