import React, { useState, useEffect } from "react";
import WindowWrapper from "../../components/grid/WindowWrapper";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomCell } from "../../components/grid/CustomCell";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ColumnMenu } from "../../components/grid/ColumnMenu";
import { FormatNumberDecimal, formatNumber } from "../../utils/UtilityFunctions";
import ShowMorePagination from "../../components/pagination/ShowMorePagination";
import ChallanListOtherTransaction from "./ChallanListOtherTransaction";
import { getMappedTransactionListV2 } from "../../api/challanAPI";
import './ChallanListV2.scss';

const ChallanListMappedTransV2 = (props) => {

    const { show, onClose, paymentCodes, params, mappedHeadData, setError, challanId, index, totalpopups } = props;

    const [otherTrans, setOtherTrans] = useState(false);
    const [dataState, setDataState] = useState({});
    const [mappedData, setMappedData] = useState([]);
    const [mappedSummary, setMappedSummary] = useState({});
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [showMoreLoading, setShowMoreLoading] = useState(false);

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
    };

    const getMappedTransactionListDataV2 = (data) => {
        async function fetchEverything() {
            async function fetchChallans() {
                const result = await getMappedTransactionListV2(data);
                if (result.data.cursor.current_page > 1) {
                    setMappedData(mappedData.concat(result?.data?.data ?? []));
                    setMappedSummary(result?.data?.summary ?? {});
                } else {
                    setMappedData(result.data.data ?? []);
                    setMappedSummary(result?.data?.summary ?? {});
                }
                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page; setShowMoreLoading(true)
                setCursor(result?.data?.cursor);

            }
            try {
                await Promise.all([
                    fetchChallans(),
                ]);
                setError(null);
            } catch (err) {
                setError(err?.message);
            } finally {
                setShowMoreLoading(false);
            }
        }
        fetchEverything();
    };

    const fetchData = (filterData = dataState) => {
        let request = {
            deductor_id: params?.deductor_id ?? "",
            return_id: params?.return_id ?? "",
            get_by_other_return: false,
            challan_id: challanId,
            limit: 16
        }

        if (filterData.sort && filterData.sort.length > 0) {
            request.sort_on = filterData.sort[0].field;
            request.sort_by = filterData.sort[0].dir;
        }

        if (filterData.filter && filterData.filter.filters) {
            request.filters = filterData.filter.filters;
        }

        if (filterData.page) {
            request.page = filterData.page;
        }

        getMappedTransactionListDataV2(request);
    }

    const rowRender = (trElement, props,) => {

        const key = trElement._owner.index;

        return (
            <tr key={key} class="dash_totalverchall_trth2romamt" role="row">
                <td class="text-left">
                    {props?.dataItem?.deductee_name ?? ''}
                </td>
                <td class="text-left data_padding-left">
                    {props?.dataItem?.pan ?? ''}
                </td>
                <td class="text-left data_padding-left">
                    {props?.dataItem?.display_code ?? ''}
                </td>
                <td class="text-center data_padding_right">
                    {props?.dataItem?.tds_rate_b100 ?? '-'}
                </td>
                <td class="text-center data_padding_right">
                    {props?.dataItem?.payment_date ?? 0}
                </td>
                <td class="text-center data_padding_right">
                    {props?.dataItem?.deduction_date ?? 0}
                </td>
                <td class="text-right" style={{ paddingRight: '20px' }}>
                    {formatNumber(props?.dataItem?.paid_amtp ?? 0)}
                </td>
                <td class="text-right" style={{ paddingRight: '20px' }}>
                    {formatNumber(props?.dataItem?.total_tds_amtp ?? 0)}
                </td>
            </tr>
        );
    };

    const onChangeFilter = (newDataState) => {
        dataStateChange(newDataState);
        fetchData(newDataState);
    }


    const handleClose = () => {
        if (otherTrans) {
            setOtherTrans(false)
        } else {
            onClose()
        }
    }

    useEffect(() => {
        fetchData(dataState);
        // eslint-disable-next-line
    }, [params?.deductor_id, params?.return_id, challanId]);

    return (
        <>
            <div className="mappedtransactionmodal">

                {show &&
                    <WindowWrapper
                        title={<kendo-dialog-titlebar>
                            <h6 className="modal-title">
                                <span className="challanno_textfs"> <span>Challan No: </span><span className="challan_numtextfsclr">{mappedHeadData?.challan_no ?? ""}</span></span>
                                <span className="challanno_vline"> | </span>

                                <span className="challanno_textfs"><span>Deposit Date: </span> <span className="challan_numtextfsclr">{mappedHeadData?.deposit_date ?? ""}</span></span>
                                <span className="challanno_vline"> | </span>

                                <span className="challanno_textfs"><span>BSR Code: </span> <span className="challan_numtextfsclr">{mappedHeadData?.bsr_code ?? ""}</span>	</span>
                                <span className="challanno_vline"> | </span>
                                <span className="challanno_textfs"><span>Challan Amount: </span> <span className="challan_numtextfsclr">{FormatNumberDecimal(mappedHeadData?.total_amtp ?? 0, ...Array(2), false)}</span></span>
                            </h6>
                        </kendo-dialog-titlebar>}
                        onClose={handleClose}
                        totalPopups={totalpopups}
                        index={index}
                        className="mapped_trans_modal"
                        style={{ maxHeight: '340px', overflowX: 'hidden' }}

                    >


                        {show && otherTrans &&
                            <ChallanListOtherTransaction
                                onClose={() => setOtherTrans(false)}
                                mappedData={mappedData}
                                paymentCodes={paymentCodes}
                                params={params}
                                challanId={challanId}
                            />
                        }

                        {show && !otherTrans &&

                            <>
                                <Tooltip anchorElement="pointer" position="right">
                                    <Grid
                                        className="table table-striped listofsalarydetailstb mb-0"
                                        data={mappedData}
                                        {...dataState}
                                        onDataStateChange={(event) => onChangeFilter(event.dataState)}
                                        filterOperators={{
                                            text: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            ],
                                            numeric: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                            ],
                                            date: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                            ],
                                            textWithEmpty: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                            ],
                                            dropdown: [
                                                { text: 'grid.filterContainsOperator', operator: 'in' },
                                            ],
                                            pan: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                { text: 'grid.filterContainsOperator', operator: 'in' },
                                            ],
                                        }}
                                        sortable={{
                                            allowUnsort: true,
                                        }}
                                        rowRender={rowRender}
                                    >
                                        <Column
                                            width="130"
                                            field="deductee_name"
                                            headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                            title={<CustomCell title="Deductee Name" field="deductee_name" dataStateChange={dataStateChange} dataState={dataState} />}
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                            filter="textWithEmpty"
                                        />

                                        <Column
                                            field="pan" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('pan') ? 'active' : ''}
                                            title="PAN"
                                            width="90"
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                            filter="pan"
                                        />

                                        <Column
                                            field="code" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('code') ? 'active' : ''}
                                            title="Section"
                                            width="90"
                                            filter="dropdown"
                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                                        />

                                        <Column
                                            field="tds_rate_b100" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('tds_rate_b100') ? 'active' : ''}
                                            title="Rate (%)"
                                            width="80"
                                            filter="numeric"
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                        />

                                        <Column
                                            field="payment_date" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('payment_date') ? 'active' : ''}
                                            title='Payment Date'
                                            width="100"
                                            filter="date"
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                        />

                                        <Column
                                            field="deduction_date" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('deduction_date') ? 'active' : ''}
                                            title='Deduction Date'
                                            filter="date"
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            width="100"
                                        />

                                        <Column
                                            field="paid_amtp" dataStateChange={dataStateChange}
                                            headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                                            title='Amt Paid'
                                            filter="numeric"
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            width="100"
                                        />

                                        <Column
                                            field="total_tds_amtp" dataStateChange={dataStateChange}
                                            filter="numeric"
                                            headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                                            title='Total Tax Deducted'
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            width="100"
                                        />

                                    </Grid>
                                </Tooltip>

                                <div className="row mt-2" style={{ width: '100%' }}>
                                    <div className="col-md-12 text-center">
                                        <ShowMorePagination
                                            cursor={cursor}
                                            fetchData={(nextPage) => {
                                                fetchData({ ...dataState, page: nextPage.page });
                                            }}
                                            postData={{}}
                                            loading={showMoreLoading}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="text-center mappedchalal_textfs">
                                            There are {FormatNumberDecimal(mappedSummary?.trans_count ?? 0, ...Array(2), false)} other transactions mapped to this challan pertaining to other returns.
                                            {parseInt(mappedSummary?.trans_count ?? 0) > 0 &&
                                                <span className="mappedchalal_showmorefs" data-toggle="modal" data-target="#fothermappedchallansno_popup" onClick={() => { setOtherTrans(true); }}> Show them </span>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </>

                        }


                    </WindowWrapper>
                }
            </div >
        </>
    )

};

export default ChallanListMappedTransV2;
