import axios from 'axios';
import { BASE_URL, BASE_URL_GO } from '../app/constants';
import { handleErrorWithCode, handleSuccess } from './responseHandler';
import { getConfig } from './common';

export async function getCorrectionTDScomputationSumary(getParams) {

  const url = `${BASE_URL}/api/v1/correction/computation/summary${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}


export async function getCorrectionInterestBreakup(getParams) {
  const url = `${BASE_URL}/api/v1/correction/computation/interest-breakup${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getReturnsFilingData(getParams) {
  const url = `${BASE_URL_GO}/api/v1/returns/filing-data${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getVerifiedChallanCountData(getParams) {

  const url = `${BASE_URL_GO}/api/v1/returns/verified-challan-count${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getVerifiedPanCountData(getParams) {
  const url = `${BASE_URL_GO}/api/v1/report/deductor/pan-status-wise-deductee-summary${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
export async function getLateFeeData(getParams) {
  const url = `${BASE_URL_GO}/api/v1/report/fvu/late-fee-info${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function updateFiling(data) {
  const url = `${BASE_URL_GO}/api/v1/returns/filing-data`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function downloadTxtFile(getParams) {

  const url = `${BASE_URL}/api/v1/file/export${getParams}`;
  try {
    const result = await axios.get(url, getConfig());
    return result;
    // const config = { ...getConfig() };
    // config.responseType = 'blob';
    // const result = await axios.get(url, config);
    // return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}




export async function generateCorrectionFVUFile(getParams) {
  const url = `${BASE_URL}/api/v1/correction/file/generate-fvu-file${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    // if (Number(result.data.status) !== 1) {
    //   throw (result.data.message);
    // }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function generateFVUFile(getParams) {

  const url = `${BASE_URL}/api/v1/file/generate-fvu-file${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function downloadCSIFile(getParams) {

  const url = `${BASE_URL}/api/v1/file/download-fvu-file${getParams}`;
  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function downloadCorrectionFVUFile(getParams) {
  const url = `${BASE_URL}/api/v1/correction/file/download-fvu-file${getParams}`;
  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}

export async function generateFVUFileWithConnector(getParams) {
  const url = `${BASE_URL}/api/v1/connector/efiling/get-fvu-file${getParams}`;  

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadCorrectionTxtFile(getParams) {

  const url = `${BASE_URL}/api/v1/correction/file/export${getParams}`;
  try {
    const result = await axios.get(url, getConfig());
    return result;
    // const config = { ...getConfig() };
    // config.responseType = 'blob';
    // const result = await axios.get(url, config);
    // return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getLowerDeductionCertificateData(getParams) {
  const url = `${BASE_URL_GO}/api/v1/report/health/ldc/list${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}