import React from "react";
import { Link } from 'react-router-dom';
import { BASE_PATH } from "../../app/constants";
import './ReturnFlowTimeline.scss';
import { useSelector } from "react-redux";
import { selectHeaderData } from "../header/headerSlice";
import { getCurrentFinancialYear } from "../../utils/UtilityFunctions";

const ReturnFlowTimeLine = (props) => {
    const headerDataDetails = useSelector(selectHeaderData);
    const finanicalYear = headerDataDetails?.financial_year_formatted ? headerDataDetails?.financial_year_formatted?.split("-")?.[0] : getCurrentFinancialYear();
    const { returnId, deductorId, currentPage ,disabledTimeline } = props;

    const addCount = finanicalYear >= 2024 && headerDataDetails.form_type === "24Q" && headerDataDetails.quarter === "Q4" && headerDataDetails.return_type === "REGULAR" ? 1 : 0;
    const showSalaryAnnexure = addCount ? true : false;
    
    let dtTitle = 'Deductee Transactions';
    let tdsMappingTitle = 'TDS Mapping';
    if(headerDataDetails?.form_type === '24Q') {
        dtTitle = 'Employee Trans.';
        if(finanicalYear >= 2024) dtTitle += " (Annex-I)"
    }else if(headerDataDetails?.form_type === '27EQ'){
        dtTitle = 'Collectee Transactions';
        tdsMappingTitle = 'TCS Mapping';
    }

    return (
        <>
       <div class="container-fluid">
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="tds_returnflow_timeline">
                        <Link
                            className="btn btn-default returnflow_timeline_backbtn"
                            to={`${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${finanicalYear}`}>&lt; Dashboard
                        </Link>

                        <div className="btn-group btn-group-md returnflow_btngrpbox returnflow_box01">
                            {
                                currentPage === 'TransactionListing'
                                    ?
                                    <span className="returnflow_linkbox">
                                        <span className="returnflow_nobox active"><span className="noinnerpadd">1</span></span>
                                        <span className="returnflow_innertital active">{dtTitle}</span>
                                    </span>
                                    :
                                    <Link className={`returnflow_linkbox ${disabledTimeline ? "disabled" : ""}`}
                                        to={`${BASE_PATH}deductee-transactions?return_id=${returnId}&deductor_id=${deductorId}`}>
                                        <span className="returnflow_nobox "><span className="noinnerpadd">1</span></span>
                                        <span className="returnflow_innertital">{dtTitle}</span>
                                    </Link>

                            }
                            <span className={`returnflow_timeline_midborder ${finanicalYear >= 2024 ? "midborder_decrease" : ""}`}></span>
                        </div>

                        <div className="btn-group btn-group-md returnflow_btngrpbox returnflow_box02">
                            {
                                currentPage === 'ChallansListing'
                                    ?
                                    <span className="returnflow_linkbox">
                                        <span className="returnflow_nobox active"><span className="noinnerpadd">2</span></span>
                                        <span className="returnflow_innertital active">Challans</span>
                                    </span>
                                    :
                                    <Link className={`returnflow_linkbox ${disabledTimeline ? "disabled" : ""}`}
                                        to={`${BASE_PATH}challans?return_id=${returnId}&deductor_id=${deductorId}`}>
                                        <span className="returnflow_nobox"><span className="noinnerpadd">2</span></span>
                                        <span className="returnflow_innertital">Challans</span>
                                    </Link>

                            }
                            <span className="returnflow_timeline_midborder timelinemid_boder02"></span>
                        </div>

                       {showSalaryAnnexure && <div className="btn-group btn-group-md returnflow_btngrpbox returnflow_box03">
                            {
                                currentPage === 'SalaryAnnexure'
                                    ?
                                    <span className="returnflow_linkbox ">
                                        <span className="returnflow_nobox active"><span className="noinnerpadd">3</span></span>
                                        <span className="returnflow_innertital active">{"Salary Details(Annex-II)"}</span>
                                    </span>
                                    :
                                    <Link className={`returnflow_linkbox ${disabledTimeline ? "disabled" : ""}`}
                                        to={`${BASE_PATH}salary-annexure?page=1&return_id=${returnId}&deductor_id=${deductorId}`}
                                    >
                                        <span className="returnflow_nobox"><span className="noinnerpadd">3</span></span>
                                        <span className="returnflow_innertital">{"Salary Details (Annex-II)"}</span>
                                    </Link>
                            }
                            <span className="returnflow_timeline_midborder timelinemid_boder03salary"></span>
                        </div>}

                        <div className="btn-group btn-group-md returnflow_btngrpbox returnflow_box03 returnflow_box003">
                            {
                                currentPage === 'TdsMappings'
                                    ?
                                    <span className="returnflow_linkbox">
                                        <span className="returnflow_nobox active"><span className="noinnerpadd">{3 + addCount}</span></span>
                                        <span className="returnflow_innertital active">{tdsMappingTitle}</span>
                                    </span>
                                    :
                                    <Link className={`returnflow_linkbox ${disabledTimeline ? "disabled" : ""}`}
                                        to={`${BASE_PATH}return-filing-tds-mapping?page=1&return_id=${returnId}&deductor_id=${deductorId}&filtertable=sectionwise`}
                                    >
                                        <span className="returnflow_nobox"><span className="noinnerpadd">{3 + addCount}</span></span>
                                        <span className="returnflow_innertital">{tdsMappingTitle}</span>
                                    </Link>
                            }
                            <span className="returnflow_timeline_midborder timelinemid_boder03"></span>
                        </div>

                        <div className="btn-group btn-group-md returnflow_btngrpbox returnflow_box04">
                            {
                                currentPage === 'InterestMappings'
                                    ?
                                    <span className="returnflow_linkbox">
                                        <span className="returnflow_nobox active"><span className="noinnerpadd">{4 + addCount}</span></span>
                                        <span className="returnflow_innertital active">Interest Allocation</span>
                                    </span>
                                    :
                                    <Link className={`returnflow_linkbox ${disabledTimeline ? "disabled" : ""}`}
                                        to={`${BASE_PATH}return-filing-interest-mapping?return_id=${returnId}&deductor_id=${deductorId}`}
                                    >
                                        <span className="returnflow_nobox"><span className="noinnerpadd">{4 + addCount}</span></span>
                                        <span className="returnflow_innertital">Interest Allocation</span>
                                    </Link>
                            }
                            <span className="returnflow_timeline_midborder timelinemid_boder04"></span>
                        </div>

                        <div className="btn-group btn-group-md returnflow_btngrpbox returnflow_box05">
                            {
                                currentPage === 'ComputationHealth'
                                    ?
                                    <span className="returnflow_linkbox">
                                        <span className="returnflow_nobox active"><span className="noinnerpadd">{5 + addCount}</span></span>
                                        <span className="returnflow_innertital active">Computation & Health</span>
                                    </span>
                                    :
                                    <Link className={`returnflow_linkbox ${disabledTimeline ? "disabled" : ""}`}
                                        to={`${BASE_PATH}return-filing-step2?return_id=${returnId}&deductor_id=${deductorId}`}
                                    >
                                        <span className="returnflow_nobox "><span className="noinnerpadd">{5 + addCount}</span></span>
                                        <span className="returnflow_innertital ">Computation & Health</span>
                                    </Link>

                            }

                            <span className="returnflow_timeline_midborder timelinemid_boder05"></span>
                        </div>

                        <div className="btn-group btn-group-md returnflow_btngrpbox returnflow_box06">
                            {
                                currentPage === 'FVUgeneration'
                                    ?
                                    <span className="returnflow_linkbox">
                                        <span className="returnflow_nobox active"><span className="noinnerpadd">{6 + addCount}</span></span>
                                        <span className="returnflow_innertital active">Validate & Upload</span>
                                    </span>
                                    :
                                    <Link className={`returnflow_linkbox ${disabledTimeline ? "disabled" : ""}`}
                                        to={`${BASE_PATH}return-filing-step3?return_id=${returnId}&deductor_id=${deductorId}`}
                                    >
                                        <span className="returnflow_nobox"><span className="noinnerpadd">{6 + addCount}</span></span>
                                        <span className="returnflow_innertital">Validate & Upload</span>
                                    </Link>

                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default ReturnFlowTimeLine;