/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './allowancedetails.css';
import TravelConcessionForm from '../../../../../components/salaryDetailsForm/TravelConcessionForm';
import GratuityForm from '../../../../../components/salaryDetailsForm/GratuityForm';
import CommutedPensionForm from '../../../../../components/salaryDetailsForm/CommutedPensionForm';
import LeaveSalaryEncashmentForm from '../../../../../components/salaryDetailsForm/LeaveSalaryEncashmentForm';
import HouseRentAllowanceForm from '../../../../../components/salaryDetailsForm/HouseRentAllowanceForm';


const Allowancedetails = (props) => {
    const { show, handleClose, allowanceList, getTotalAllowanceAmtp, values, setFieldValue } = props;

    let allowanceData = allowanceList.filter((data, index) => { return show >= 0 && parseInt(data.allowance_type_id) === parseInt(values.allowances[show].allowance_type_id) })

    if (allowanceData.length === 0) {
        return false;
    }

    return (<Modal
        show={show >= 0 ? true : false}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
        className="allowancedspopup"
    >
        <Modal.Header closeButton className="MonthWSdetailsH allowance_header">
            <Modal.Title className="pt-0 mt-0">
                <span className="MonthWSdetailsT">{allowanceData[0].allowance_type_name}</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0 px-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {allowanceData[0].allowance_type_id === 1 &&
                            <TravelConcessionForm
                                setFieldValue={setFieldValue}
                                fieldValue={values}
                                allowance_index={show}
                                handleClose={handleClose}
                                getTotalAllowanceAmtp={getTotalAllowanceAmtp}
                            />
                        }

                        {(allowanceData[0].allowance_type_id === 4) &&
                            <GratuityForm
                                setFieldValue={setFieldValue}
                                fieldValue={values}
                                allowance_index={show}
                                handleClose={handleClose}
                                getTotalAllowanceAmtp={getTotalAllowanceAmtp}
                            />
                        }

                        {(allowanceData[0].allowance_type_id === 5) &&
                            <CommutedPensionForm
                                setFieldValue={setFieldValue}
                                fieldValue={values}
                                allowance_index={show}
                                handleClose={handleClose}
                                getTotalAllowanceAmtp={getTotalAllowanceAmtp}
                            />
                        }

                        {(allowanceData[0].allowance_type_id === 6) &&
                            <LeaveSalaryEncashmentForm
                                setFieldValue={setFieldValue}
                                fieldValue={values}
                                allowance_index={show}
                                handleClose={handleClose}
                                getTotalAllowanceAmtp={getTotalAllowanceAmtp}
                            />
                        }

                        {allowanceData[0].allowance_type_id === 2 &&
                            <HouseRentAllowanceForm
                                setFieldValueProps={setFieldValue}
                                fieldValueProps={values}
                                allowance_index={show}
                                handleClose={handleClose}
                                getTotalAllowanceAmtp={getTotalAllowanceAmtp}
                            />
                        }
                    </div>
                </div>
            </div>

        </Modal.Body>
    </Modal >
    );
};

export default Allowancedetails;