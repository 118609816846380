/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from 'moment';
import './pdf_template.css';
import { formatNumber } from '../../../utils/UtilityFunctions';
import { DEDUCTOR_CATEGORIES, STATE_LIST, DEPARTMENT_NAMES, NATURE_COLLECTIONS } from '../../../app/constants';

const PDF26Q = (props) => {
    const pdfExportComponent = React.useRef(null);

    const { PDFTemplateData, handleClose, prnData } = props;

    var end_year = PDFTemplateData.batch_header.financial_yr.substring(0, 2) + PDFTemplateData.batch_header.financial_yr.substring(4, 6);
    var start_year = PDFTemplateData.batch_header.financial_yr.substring(0, 4);

    const deductor_address_state = STATE_LIST.filter((data) => { return data.value === parseInt(PDFTemplateData.batch_header.deductor_address_state) });
    const responsible_person_state = STATE_LIST.filter((data) => { return data.value === parseInt(PDFTemplateData.batch_header.responsible_person_state) });
    const quarter_last_month = PDFTemplateData.batch_header.period === 'Q1' ? 6 : PDFTemplateData.batch_header.period === 'Q2' ? 9 : PDFTemplateData.batch_header.period === 'Q3' ? 12 : 3;
    const quarter_last_year = PDFTemplateData.batch_header.period === 'Q4' ? moment(end_year + '-01-01', 'YYYY-MM-DD').format("YYYY") : moment(start_year + '-01-01', 'YYYY-MM-DD').format("YYYY");



    const newSectionData = (challanData) => {
        const countObj = { "4BP": 0, "4RP": 0, "4SP": 0, "4AP": 0, }
        const data = []
        if (challanData?.length > 0) {
            challanData.forEach((item) => {
                const ccobj = {}
                ccobj.bsrCode = item?.bank_branch_code_Form_24G_Receipt_Number ?? ""
                ccobj.dateOfPayment = item?.date_of_bank_challan_transfer_voucher ?? ""
                ccobj.serialNum = item?.challan_detail_record_number ?? ""
                if (item?.deductee_details?.length > 0) {
                    item?.deductee_details.forEach((dt) => {
                        if (["4BP", "4RP", "4SP", "4AP"].includes(dt?.section_code_under_which_payment_made)) {
                            const ddObj = {}
                            ddObj.sectionCode = dt.section_code_under_which_payment_made
                            ddObj.taxDeposited = dt.total_tax_deposited
                            data.push({ ...ccobj, ...ddObj })
                            countObj[dt?.section_code_under_which_payment_made]++
                        }
                    })
                }
            })

        }
        return { data, countObj }

    }

    const { data: newSection, countObj: maxCountSection } = newSectionData(PDFTemplateData?.challan_details)

    useEffect(() => {

        setTimeout(function () {
            if (pdfExportComponent.current) {
                pdfExportComponent.current.save(() => {
                    handleClose();
                });
            }
        }, 500);


    }, []);


    const convertChallanNumner = (challan_no) => {
        if (challan_no) {
            return String(challan_no).padStart(5, '0');
        }
        else {
            return "-";
        }
    }
    return (
        <div id="pdf_template_area" style={{ position: 'absolute', top: '0px', left: '-100%' }}>
            <PDFExport
                forcePageBreak=".page-break"
                paperSize="A3"
                margin="1cm"
                landscape={true}
                ref={pdfExportComponent}
                title="Return Form No. 26Q"
                fileName={PDFTemplateData.file_header.tan_of_deductor + '_26Q_' + PDFTemplateData.batch_header.period + '_' + PDFTemplateData.batch_header.financial_yr + ".pdf"}
            >
                <div className="pdf_template" id="pdf_template">

                    <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                        <tr>
                            <td>
                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '15px', marginBottom: '15px' }}>
                                    <tbody>
                                        <tr>
                                            <th colSpan="5" style={{ border: 'none', textAlign: 'center', fontSize: '12px' }}>Form No. 26Q</th>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th colSpan="4" style={{ border: 'none', textAlign: 'center' }}>
                                                [See sections 192A, 193, 194, 194A, 194B, 194BA, 194BB, 194C, 194D, 194DA, 194EE, 194F, 194G, 194H, 194-I, 194J, 194K, 194LA, 194LBA, 194LBB, 194LBC, 194N, 194-O,
                                                194P, 194Q, 194R, 194S, 197A, 206AA, 206AB and rule 31A]
                                            </th>
                                        </tr>

                                        <tr>
                                            <td colSpan="5"></td>
                                        </tr>

                                        <tr>
                                            <td></td>
                                            <td colSpan="4" style={{ border: 'none', textAlign: 'center' }}>
                                                Quarterly statement of deduction of tax under sub‐section (3) of section 200 of the Income‐tax Act in respect of
                                                payments other than salary for the quarter ended <strong>{moment('1969-' + quarter_last_month + '-01').format("MMMM")} {quarter_last_year}</strong>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '12px' }}>1.</th>
                                            <td> <span style={{ paddingRight: '7px' }}>(a)</span> Tax Deduction and Collection A/C No. (TAN)</td>
                                            <th style={{ fontSize: '11px', textAlign: 'left', width: '7.3rem' }}>{PDFTemplateData.file_header.tan_of_deductor}	</th>

                                            <td><span style={{ paddingRight: '7px' }}>(d)</span> Has the statement been filed earlier for this quarter (Yes/No)</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{prnData.previous_prn ? 'Yes' : 'No'}</th>
                                        </tr>
                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>&nbsp;</th>
                                            <td><span style={{ paddingRight: '7px' }}>(b)</span> PAN no. or Aadhaar No.</td>
                                            <th style={{ fontSize: '11px', textAlign: 'left' }}>{PDFTemplateData.batch_header.pan_deductor}</th>

                                            <td><span style={{ paddingRight: '7px' }}>(e)</span> If answer to (d) is "Yes", then Token No. of original statement</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{prnData.previous_prn ? prnData.previous_prn : '-'}</th>
                                        </tr>

                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>&nbsp;</th>
                                            <td><span style={{ paddingRight: '7px' }}>(c)</span> Financial Year</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{PDFTemplateData.batch_header.financial_yr.substring(0, 4)} - {PDFTemplateData.batch_header.financial_yr.substring(4, 6)}</th>

                                            <td><span style={{ paddingRight: '7px' }}>(f)</span> Type of [Deductor/Payer]</td>
                                            <th style={{ fontSize: '12px', textAlign: 'left' }}>{DEDUCTOR_CATEGORIES[PDFTemplateData.batch_header.deductor_type]}</th>
                                        </tr>

                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}>2.</th>
                                            <th colSpan="4" style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}>Particulars of the [Deductor/Payer]</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', width: '19.7rem' }}>(a) Name</td>
                                            <td colSpan="3" style={{ norder: 'none', textAlign: 'left', fontSize: '12px' }}>
                                                <strong>{PDFTemplateData.batch_header.deductor_name}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ paddingLeft: '35px', paddingBottom: '0px' }}>(b) If Central/State Government</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.ministry_name ? DEPARTMENT_NAMES[PDFTemplateData.batch_header.ministry_name] : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>AIN Code of PAO/TO/CDDO </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.account_office_identification_number ? PDFTemplateData.batch_header.account_office_identification_number : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '35px', paddingTop: '0px' }}>(c) TAN Reg. No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>-</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ paddingLeft: '35px' }}>(d) Address</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingBottom: '0px' }}>Flat No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingBottom: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address1 ? PDFTemplateData.batch_header.deductor_address1 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name of the Premises/Building </td>
                                            <td colSpan="3" tyle={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address2 ? PDFTemplateData.batch_header.deductor_address2 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Road/Street/Lane </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address3 ? PDFTemplateData.batch_header.deductor_address3 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Area/Location </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address4 ? PDFTemplateData.batch_header.deductor_address4 : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Town/City/District</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address5 ? PDFTemplateData.batch_header.deductor_address5 : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>State </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{deductor_address_state.length ? deductor_address_state[0].label : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>PIN Code </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_address_pincode ? PDFTemplateData.batch_header.deductor_address_pincode : '-'}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Telephone No. </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_std_code} - {PDFTemplateData.batch_header.deductor_phone_no}</strong></td>
                                        </tr><tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Telephone No.</td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_std_code_alternate} - {PDFTemplateData.batch_header.deductor_phone_no_alternate}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Email </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_email ? PDFTemplateData.batch_header.deductor_email : '-'}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Email </td>
                                            <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.deductor_email_alternate ? PDFTemplateData.batch_header.deductor_email_alternate : '-'}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <p className='page-break' />
                    <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                        <tbody>
                            <tr>
                                <th style={{ width: '30px' }}>&nbsp;</th>
                                <td style={{ width: '20.5rem' }}>&nbsp;</td>
                                <th style={{ width: '10.3rem' }}>&nbsp;</th>
                                <td>&nbsp;</td>
                                <th>&nbsp;</th>
                            </tr>

                            <tr>
                                <th colSpan="1" style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}>3.</th>
                                <th colSpan="4" style={{ border: 'none', textAlign: 'left', fontSize: '13px', paddingTop: '15px' }}>Particulars of the Person Responsible for Deduction of Tax</th>
                            </tr>

                            <tr>
                                <td colSpan="2" style={{ paddingLeft: '35px', width: '19.7rem' }}>(a) Name</td>
                                <td colSpan="3" style={{ norder: 'none', textAlign: 'left', fontSize: '12px' }}>
                                    <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction ? PDFTemplateData.batch_header.name_person_responsible_for_deduction : '-'}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{ paddingLeft: '35px' }}>(b) Address</td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Flat No. </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address1 ? PDFTemplateData.batch_header.responsible_person_address1 : '-'}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Name of Premises/Building </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address2 ? PDFTemplateData.batch_header.responsible_person_address2 : '-'}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Road/Street/Lane</td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address3 ? PDFTemplateData.batch_header.responsible_person_address3 : '-'}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Area/Location</td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address4 ? PDFTemplateData.batch_header.responsible_person_address4 : '-'}</strong></td>
                            </tr><tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Town/City/District </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_address5 ? PDFTemplateData.batch_header.responsible_person_address5 : '-'}</strong></td>
                            </tr><tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>State</td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{responsible_person_state.length ? responsible_person_state[0].label : '-'}</strong></td>
                            </tr><tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>PIN Code </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_pin ? PDFTemplateData.batch_header.responsible_person_pin : '-'}</strong></td>
                            </tr><tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Telephone No. </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_std_code} - {PDFTemplateData.batch_header.responsible_person_phone_no}</strong></td>
                            </tr><tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Telephone No. </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_std_code_alternate} - {PDFTemplateData.batch_header.responsible_person_phone_no_alternate}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Email  </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_email ? PDFTemplateData.batch_header.responsible_person_email : ''}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Alternate Email </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.responsible_person_email_alternate ? PDFTemplateData.batch_header.responsible_person_email_alternate : ''}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ paddingLeft: '70px', paddingTop: '0px' }}>Mobile No. </td>
                                <td colSpan="3" style={{ fontSize: '12px', paddingTop: '0px' }}><strong>{PDFTemplateData.batch_header.mobile_number ? PDFTemplateData.batch_header.mobile_number : ''}</strong></td>
                            </tr>
                        </tbody>
                    </table>

                    <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                        <tbody>
                            <tr>
                                <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>4. Details of Tax Deducted and Paid to the Credit of Central Government</th>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table27bor" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '10px' }}>
                        <tbody><tr>
                            <th>Sr. No</th>
                            <th>Tax</th>
                            <th>Interest</th>
                            <th>Fee</th>
                            <th>Penalty / Others</th>
                            <th>Total Amt Deposited as per Challan / Book Adjustment (402+403+404+405)</th>
                            <th>Mode of Deposit through Challan (C) / Book Adjustment (B)</th>
                            <th>BSR Code / Receipt Number of Form No. 24G</th>
                            <th>Challan Serial No. / DDO Serial no. of Form No.24G</th>
                            <th>Date on which
                                Amt deposited
                                through challan /
                                Date of transfer
                                voucher (DD/MM/YYYY)</th>
                            <th>Minor Head of Challan</th>
                        </tr>
                            <tr>
                                <td className="text-center">[401] </td>
                                <td className="text-right">[402]</td>
                                <td className="text-right">[403]</td>
                                <td className="text-right"> [404]</td>
                                <td className="text-right">[405]</td>
                                <td className="text-right"> [406]</td>
                                <td className="text-center"> [407]</td>
                                <td className="text-center"> [408]</td>
                                <td className="text-center"> [409]</td>
                                <td className="text-center"> [410]</td>
                                <td className="text-center"> [411]</td>
                            </tr>
                            {PDFTemplateData.challan_details && PDFTemplateData.challan_details.map((challan_details, index) => {
                                return (
                                    <tr>
                                        <td className="text-center">{challan_details.challan_detail_record_number ? challan_details.challan_detail_record_number : '-'}</td>
                                        <td className="text-right">{challan_details.oltas_TDS_TCS_income_tax ? formatNumber(challan_details.oltas_TDS_TCS_income_tax) : '-'}</td>
                                        <td className="text-right">{challan_details.oltas_TDS_TCS_interest_amount ? formatNumber(challan_details.oltas_TDS_TCS_interest_amount) : '-'}</td>
                                        <td className="text-right">{challan_details.fee ? formatNumber(challan_details.fee) : '-'}</td>
                                        <td className="text-right">{challan_details.oltas_TDS_TCS_others_amount ? formatNumber(challan_details.oltas_TDS_TCS_others_amount) : '-'}</td>
                                        <td className="text-right">{challan_details.total_deposit_amount_challan_transfer_voucher_number ? formatNumber(challan_details.total_deposit_amount_challan_transfer_voucher_number) : '-'}</td>
                                        <td className="text-center">{challan_details.By_book_entry_cash === 'Y' ? 'B' : 'C'}</td>
                                        <td className="text-center">{challan_details.bank_branch_code_Form_24G_Receipt_Number ? challan_details.bank_branch_code_Form_24G_Receipt_Number : '-'}</td>
                                        <td className="text-center">
                                            {PDFTemplateData.batch_header.deductor_type !== 'A' ?
                                                convertChallanNumner(challan_details.bank_challan_no) :
                                                convertChallanNumner(challan_details.ddo_serial_number_of_form_24G)
                                            }</td>
                                        <td className="text-center">{challan_details.date_of_bank_challan_transfer_voucher ? moment(challan_details.date_of_bank_challan_transfer_voucher, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                        <td className="text-center">{challan_details.minor_head_of_challan ? challan_details.minor_head_of_challan : '-'}</td>
                                    </tr>
                                )
                            })
                            }

                        </tbody>
                    </table>

                    <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                        <tr>
                            <td>
                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px', marginTop: '15px' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: 'none', textAlign: 'left', fontSize: '13px' }}>5. Details of amount paid and tax deducted thereon from the deductees and amount paid without deduction (see Annexure)</th>
                                        </tr>
                                    </tbody>
                                </table>

                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', paddingBottom: '10px' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ fontSize: '13px', textAlign: 'center' }}> Verification</th>
                                        </tr>
                                        <tr>
                                            <td>I, <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong>, hereby certify that all the particulars furnished above are correct and complete.</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '15px' }}>
                                    <tbody><tr>
                                        <td style={{ paddingBottom: '0px' }}>Place: <strong>{PDFTemplateData.batch_header.responsible_person_address5}</strong></td>
                                        <td style={{ paddingBottom: '0px', textAlign: 'right' }}>Signature of the Person Responsible for Deducting Tax at Source</td>
                                        <td></td>
                                    </tr>
                                        <tr>
                                            <td style={{ paddingTop: '0px' }}>
                                                Date: <strong>{moment(PDFTemplateData.file_header.file_creation_date, 'DDMMYYYY').format('DD/MM/YYYY')}</strong>
                                            </td>
                                            <td style={{ paddingTop: '0px', textAlign: 'right' }}>
                                                Name and Designation of the Person Responsible for Deducting <br />
                                                Tax at Source
                                            </td>
                                            <td style={{ paddingTop: '0px', paddingLeft: '10px', width: '20%' }}>
                                                <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong> <br />
                                                <strong>{PDFTemplateData.batch_header.designation_person_responsible_deduction ? PDFTemplateData.batch_header.designation_person_responsible_deduction : '-'}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>

                    <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', textAlign: 'right', marginTop: '15px' }}>
                        <tr>
                            <td className="text-right"><strong>Express TDS - A KDK Software Product</strong></td>
                        </tr>
                    </table>

                    {PDFTemplateData.challan_details && PDFTemplateData.challan_details.map((challan_details, index) => {

                        let amount_of_payment_credit = 0;
                        let cash_withdrawal_in_excess_of_1_crore_referreds_section_194N = 0;
                        let cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N = 0;
                        let cash_withdrawal_in_excess_1_crore_cases_covered_section_194N = 0;
                        let cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N = 0;
                        let cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N = 0;
                        let cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N = 0;
                        let total_income_tax_deducted_at_source_TDS_TCS_income_tax = 0;
                        let total_tax_deposited = 0;

                        return (<>
                            <table className="page-break" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '25px', marginBottom: '25px' }}>
                                <tr>
                                    <th style={{ textAlign: 'center', fontSize: '13px' }}>ANNEXURE : DEDUCTEE WISE BREAK UP OF TDS</th>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Details of amount paid/credited during the quarter ended {moment(quarter_last_year + '-' + quarter_last_month + '-01').endOf('month').format("DD/MM/YYYY")} and of tax deducted at source</th>
                                </tr>
                            </table>
                            <table className="table04bor" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '15px', marginBottom: '10px' }}>
                                <tbody><tr>
                                    <td>Name of the Deductor</td>
                                    <td><strong>{PDFTemplateData.batch_header.deductor_name ? PDFTemplateData.batch_header.deductor_name : '-'}</strong></td>
                                </tr>
                                    <tr>
                                        <td>TAN</td>
                                        <td><strong>{PDFTemplateData.file_header.tan_of_deductor ? PDFTemplateData.file_header.tan_of_deductor : '-'}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>BSR Code of Branch / Receipt No. of Form No.24G</td>
                                        <td><strong>{challan_details.bank_branch_code_Form_24G_Receipt_Number ? challan_details.bank_branch_code_Form_24G_Receipt_Number : '-'}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Date on which Challan Deposited / Transfer Coucher Date (DD/MM/YYYY
                                            )</td>
                                        <td><strong>{challan_details.date_of_bank_challan_transfer_voucher ? moment(challan_details.date_of_bank_challan_transfer_voucher, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Challan Serial Number / DDO Serial No. of Form No.24G</td>
                                        <td><strong>{PDFTemplateData.batch_header.deductor_type !== 'A' ?
                                            convertChallanNumner(challan_details.bank_challan_no) :
                                            convertChallanNumner(challan_details.ddo_serial_number_of_form_24G)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Amt as per Challan</td>
                                        <td><strong>{challan_details.total_deposit_amount_challan_transfer_voucher_number ? formatNumber(challan_details.total_deposit_amount_challan_transfer_voucher_number) : '-'}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Total Tax to be allocated</td>
                                        <td>{challan_details.total_tax_deposit_amount_deductee_annexure ? formatNumber(challan_details.total_tax_deposit_amount_deductee_annexure) : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Interest to be allocated among the Deductees mentioned below</td>
                                        <td>{challan_details.oltas_TDS_TCS_interest_amount ? formatNumber(challan_details.oltas_TDS_TCS_interest_amount) : '-'}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table27bor" style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '10px', marginTop: '1rem' }}>
                                <tbody>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Deductee Ref. No. provided by the Deductor, if available</th>
                                        <th>
                                            Deductee
                                            Code (01-
                                            Company
                                            02-Other
                                            than
                                            Company)
                                        </th>
                                        <th>PAN of the Deductee</th>
                                        <th>Name of the Deductee</th>
                                        <th>Section Code</th>
                                        <th>Date of Payment or Credit</th>
                                        <th>Amt Paid or Credited</th>
                                        <th>Amount of cash withdrawal in excess of ₹ 1 crore as referred to in section 194N (in cases not covered by the first proviso or third proviso to section 194N)</th>
                                        <th>Amount of cash withdrawal which is in excess of Rs. 20 lakh but does not exceed Rs 1 crore for cases covered by sub- clause (a) of clause (ii) of first proviso to section 194N (except cooperative societies)</th>
                                        <th>Amount of cash withdrawal in excess of Rs. 1 crore for cases covered by subclause (b) of clause (ii) of first proviso to section 194N (except cooperative societies)</th>
                                        <th>Amount of cash withdrawal in excess of Rs. 3 crore in case of cooperative societies as covered by third proviso to section 194N (in cases not covered by the first proviso to section 194N)</th>
                                        <th>Amount of cash withdrawal which is in excess of Rs. 20 lakh but does not exceed Rs 3 crore for cases covered by sub- clause (a) of clause (ii) of first proviso read with third proviso to section 194N (in case of cooperative societies)</th>
                                        <th>Amount of cash withdrawal which is in excess of Rs 3 crore for cases covered by sub- clause (b) of clause (ii) of first proviso read with third proviso to section 194N (in case of cooperative societies)</th>
                                        <th>Total Tax Deducted</th>
                                        <th>Total Tax Deposited</th>
                                        <th>Date of Deduction</th>
                                        <th>Rate at which deducted</th>
                                        <th>Reason for non / Lower / Higher Deduction / Threshold / Transporter</th>
                                        <th>No. of Cert. u/s 197 issued by Assessing Officer for Non / Lower Deduction</th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">[412] </td>
                                        <td className="text-center">[413]</td>
                                        <td className="text-center">[414]</td>
                                        <td className="text-center"> [415]</td>
                                        <td className="text-center">[416]</td>
                                        <td className="text-center"> [417]</td>
                                        <td className="text-center"> [418]</td>
                                        <td className="text-right"> [419]</td>
                                        <td className="text-right"> [419A]</td>
                                        <td className="text-right"> [419B]</td>
                                        <td className="text-right"> [419C]</td>
                                        <td className="text-right"> [419D]</td>
                                        <td className="text-right"> [419E]</td>
                                        <td className="text-right"> [419F]</td>
                                        <td className="text-right"> [420]</td>
                                        <td className="text-right"> [421]</td>
                                        <td className="text-center"> [422]</td>
                                        <td className="text-center"> [423]</td>
                                        <td className="text-center"> [424]</td>
                                        <td className="text-center"> [425]</td>
                                    </tr>
                                    {challan_details.deductee_details && challan_details.deductee_details.map((deductee_details, index) => {

                                        amount_of_payment_credit = parseFloat(deductee_details.amount_of_payment_credit ? deductee_details.amount_of_payment_credit : 0) + parseFloat(amount_of_payment_credit);
                                        cash_withdrawal_in_excess_of_1_crore_referreds_section_194N = parseFloat(deductee_details.cash_withdrawal_in_excess_of_1_crore_referreds_section_194N ? deductee_details.cash_withdrawal_in_excess_of_1_crore_referreds_section_194N : 0) + parseFloat(cash_withdrawal_in_excess_of_1_crore_referreds_section_194N);
                                        cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N = parseFloat(deductee_details.cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N ? deductee_details.cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N : 0) + parseFloat(cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N);
                                        cash_withdrawal_in_excess_1_crore_cases_covered_section_194N = parseFloat(deductee_details.cash_withdrawal_in_excess_1_crore_cases_covered_section_194N ? deductee_details.cash_withdrawal_in_excess_1_crore_cases_covered_section_194N : 0) + parseFloat(cash_withdrawal_in_excess_1_crore_cases_covered_section_194N);
                                        cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N = parseFloat(deductee_details.cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N ? deductee_details.cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N : 0) + parseFloat(cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N);
                                        cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N = parseFloat(deductee_details.cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N ? deductee_details.cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N : 0) + parseFloat(cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N);
                                        cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N = parseFloat(deductee_details.cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N ? deductee_details.cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N : 0) + parseFloat(cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N);
                                        total_income_tax_deducted_at_source_TDS_TCS_income_tax = parseFloat(deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax ? deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax : 0) + parseFloat(total_income_tax_deducted_at_source_TDS_TCS_income_tax);
                                        total_tax_deposited = parseFloat(deductee_details.total_tax_deposited ? deductee_details.total_tax_deposited : 0) + parseFloat(total_tax_deposited);


                                        return (<tr>
                                            <td className="text-center">{deductee_details.deductee_detail_record_no ? deductee_details.deductee_detail_record_no : '-'}</td>
                                            <td className="text-center">{deductee_details.deductee_ref_no ? deductee_details.deductee_ref_no : '-'}</td>
                                            <td className="text-center">{deductee_details.deductee_code ? deductee_details.deductee_code : '-'}</td>
                                            <td className="text-center">{deductee_details.deductee_pan ? deductee_details.deductee_pan : '-'}</td>
                                            <td className="text-center">{deductee_details.name_of_deductee ? deductee_details.name_of_deductee : '-'}</td>
                                            <td className="text-center">{NATURE_COLLECTIONS[deductee_details.section_code_under_which_payment_made] ? NATURE_COLLECTIONS[deductee_details.section_code_under_which_payment_made] : '-'}</td>
                                            <td className="text-center">{deductee_details.date_on_which_amount_paid_credited ? moment(deductee_details.date_on_which_amount_paid_credited, 'DDMMYYYY').format("DD/MM/YYYY") : '-'}</td>
                                            <td className="text-right">{deductee_details.amount_of_payment_credit ? formatNumber(deductee_details.amount_of_payment_credit) : '-'}</td>
                                            <td className="text-right">{deductee_details.cash_withdrawal_in_excess_of_1_crore_referreds_section_194N ? formatNumber(deductee_details.cash_withdrawal_in_excess_of_1_crore_referreds_section_194N) : '-'}</td>
                                            <td className="text-right">{deductee_details.cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N ? formatNumber(deductee_details.cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N) : '-'}</td>
                                            <td className="text-right">{deductee_details.cash_withdrawal_in_excess_1_crore_cases_covered_section_194N ? formatNumber(deductee_details.cash_withdrawal_in_excess_1_crore_cases_covered_section_194N) : '-'}</td>
                                            <td className="text-right">{deductee_details.cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N ? formatNumber(deductee_details.cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N) : '-'}</td>
                                            <td className="text-right">{deductee_details.cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N ? formatNumber(deductee_details.cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N) : '-'}</td>
                                            <td className="text-right">{deductee_details.cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N ? formatNumber(deductee_details.cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N) : '-'}</td>
                                            <td className="text-right">{deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax ? formatNumber(deductee_details.total_income_tax_deducted_at_source_TDS_TCS_income_tax) : '-'}</td>
                                            <td className="text-right">{deductee_details.total_tax_deposited ? formatNumber(deductee_details.total_tax_deposited) : '-'}</td>
                                            <td className="text-center">{deductee_details.date_on_which_tax_deducted_collected ? moment(deductee_details.date_on_which_tax_deducted_collected, 'DDMMYYYY').format('DD/MM/YYYY') : '-'}</td>
                                            <td className="text-center">{deductee_details.rate_at_which_tax_deducted_collected ? deductee_details.rate_at_which_tax_deducted_collected : '-'}</td>
                                            <td className="text-center">{deductee_details.remarks_1 ? deductee_details.remarks_1 : '-'}</td>
                                            <td className="text-center">{deductee_details.certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction ? deductee_details.certificate_number_issued_by_the_assessing_officer_197_non_deduction_lower_deduction : '-'}</td>
                                        </tr>)
                                    })}

                                    <tr>
                                        <td className="text-center"><strong>Total</strong></td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-right"><strong>{formatNumber(amount_of_payment_credit)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(cash_withdrawal_in_excess_of_1_crore_referreds_section_194N)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(cash_withdrawal_in_excess_of_20_lakhs_to_1_crore_cases_covered_section_194N)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(cash_withdrawal_in_excess_1_crore_cases_covered_section_194N)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(cash_withdrawal_in_excess_3_crore_cases_for_cooperative_covered_section_194N)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(cash_withdrawal_in_excess_20_lac_not_exceed_3_crore_cases_covered_section_194N)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(cash_withdrawal_in_excess_3_crore_cases_subclause_covered_section_194N)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(total_income_tax_deducted_at_source_TDS_TCS_income_tax)}</strong></td>
                                        <td className="text-right"><strong>{formatNumber(total_tax_deposited)}</strong></td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                    </tr>
                                </tbody>
                            </table>



                            {(PDFTemplateData.challan_details?.length === (index + 1) && newSection?.length > 0) ?

                                <table className="table27bor" style={{ width: '1024px', margin: 'auto auto', border: 'none', marginBottom: '10px', marginTop: '1rem' }}>
                                    <tr>
                                        <td style={{ border: 'none', margin: 0, padding: 0 }}>

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th colSpan={4}>If section code 94B-P is selected, then provide </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Amount of tax deposited</th>
                                                        <th>BSR Code of bank</th>
                                                        <th>Date of payment</th>
                                                        <th>Challan serial number </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">[426A] </td>
                                                        <td className="text-center">[426B]</td>
                                                        <td className="text-center">[426C]</td>
                                                        <td className="text-center"> [426D]</td>
                                                    </tr>

                                                    {newSection && newSection.map((item, index) => {
                                                        if (item?.sectionCode !== "4BP") {
                                                            return null
                                                        }

                                                        return (<tr>
                                                            <td className="text-center">{item.taxDeposited ? item.taxDeposited : '-'}</td>
                                                            <td className="text-center">{item.bsrCode ? item.bsrCode : '-'}</td>
                                                            <td className="text-center">{item.dateOfPayment ? moment(item.dateOfPayment, 'DDMMYYYY').format('DD/MM/YYYY') : '-'}</td>
                                                            <td className="text-center">{item.serialNum ? item.serialNum : '-'}</td>

                                                        </tr>)
                                                    })}
                                                    {Array(Math.max(maxCountSection['4BP'], maxCountSection['4RP'], maxCountSection['4SP'], maxCountSection['4AP'])).fill().map((val, index) => {
                                                        if (index < maxCountSection['4BP']) {
                                                            return null
                                                        }
                                                        return (<tr>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>

                                                        </tr>)
                                                    })}


                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ border: 'none', margin: 0, padding: 0 }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th colSpan={4}>If section code 94R-P is selected, then provide </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Amount of tax deposited</th>
                                                        <th>BSR Code of bank</th>
                                                        <th>Date of payment</th>
                                                        <th>Challan serial number </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">[427A] </td>
                                                        <td className="text-center">[427B]</td>
                                                        <td className="text-center">[427C]</td>
                                                        <td className="text-center"> [427D]</td>
                                                    </tr>
                                                    {newSection && newSection.map((item) => {
                                                        if (item?.sectionCode !== "4RP") {
                                                            return null
                                                        }

                                                        return (<tr>
                                                            <td className="text-center">{item.taxDeposited ? item.taxDeposited : '-'}</td>
                                                            <td className="text-center">{item.bsrCode ? item.bsrCode : '-'}</td>
                                                            <td className="text-center">{item.dateOfPayment ? moment(item.dateOfPayment, 'DDMMYYYY').format('DD/MM/YYYY') : '-'}</td>
                                                            <td className="text-center">{item.serialNum ? item.serialNum : '-'}</td>

                                                        </tr>)
                                                    })}
                                                    {Array(Math.max(maxCountSection['4BP'], maxCountSection['4RP'], maxCountSection['4SP'], maxCountSection['4AP'])).fill().map((val, index) => {
                                                        if (index < maxCountSection['4RP']) {
                                                            return null
                                                        }
                                                        return (<tr>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>

                                                        </tr>)
                                                    })}

                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ border: 'none', margin: 0, padding: 0 }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th colSpan={4}>If section code 94S-P is selected, then provide </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Amount of tax deposited</th>
                                                        <th>BSR Code of bank</th>
                                                        <th>Date of payment</th>
                                                        <th>Challan serial number </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">[428A] </td>
                                                        <td className="text-center">[428B]</td>
                                                        <td className="text-center">[428C]</td>
                                                        <td className="text-center"> [428D]</td>
                                                    </tr>
                                                    {newSection && newSection.map((item) => {
                                                        if (item?.sectionCode !== "4SP") {
                                                            return null
                                                        }

                                                        return (<tr>
                                                            <td className="text-center">{item.taxDeposited ? item.taxDeposited : '-'}</td>
                                                            <td className="text-center">{item.bsrCode ? item.bsrCode : '-'}</td>
                                                            <td className="text-center">{item.dateOfPayment ? moment(item.dateOfPayment, 'DDMMYYYY').format('DD/MM/YYYY') : '-'}</td>
                                                            <td className="text-center">{item.serialNum ? item.serialNum : '-'}</td>

                                                        </tr>)
                                                    })}

                                                    {Array(Math.max(maxCountSection['4BP'], maxCountSection['4RP'], maxCountSection['4SP'], maxCountSection['4AP'])).fill().map((val, index) => {
                                                        if (index < maxCountSection['4SP']) {
                                                            return null
                                                        }
                                                        return (<tr>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>

                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ border: 'none', margin: 0, padding: 0 }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th colSpan={4}>If section code 94BA-P is selected, then provide </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Amount of tax deposited</th>
                                                        <th>BSR Code of bank</th>
                                                        <th>Date of payment</th>
                                                        <th>Challan serial number </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">[429A] </td>
                                                        <td className="text-center">[429B]</td>
                                                        <td className="text-center">[429C]</td>
                                                        <td className="text-center"> [429D]</td>
                                                    </tr>
                                                    {newSection && newSection.map((item) => {
                                                        if (item?.sectionCode !== "4AP") {
                                                            return null
                                                        }

                                                        return (<tr>
                                                            <td className="text-center">{item.taxDeposited ? item.taxDeposited : '-'}</td>
                                                            <td className="text-center">{item.bsrCode ? item.bsrCode : '-'}</td>
                                                            <td className="text-center">{item.dateOfPayment ? moment(item.dateOfPayment, 'DDMMYYYY').format('DD/MM/YYYY') : '-'}</td>
                                                            <td className="text-center">{item.serialNum ? item.serialNum : '-'}</td>

                                                        </tr>)
                                                    })}
                                                    {Array(Math.max(maxCountSection['4BP'], maxCountSection['4RP'], maxCountSection['4SP'], maxCountSection['4AP'])).fill().map((val, index) => {
                                                        if (index < maxCountSection['4AP']) {
                                                            return null
                                                        }
                                                        return (<tr>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>
                                                            <td className="text-center">-</td>

                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </table> : null}













                            <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', padding: '2rem 2rem 2rem 3rem' }}>
                                <tr>
                                    <td>
                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginBottom: '10px' }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{ fontSize: '13px', textAlign: 'center' }}> Verification</th>
                                                </tr>
                                                <tr>
                                                    <td>I, <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong>, hereby certify that all the particulars furnished above are correct and complete.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', marginTop: '15px', marginBottom: '15px' }}>
                                            <tbody><tr>
                                                <td style={{ paddingBottom: '0px' }}>Place: <strong>{PDFTemplateData.batch_header.responsible_person_address5}</strong></td>
                                                <td style={{ paddingBottom: '0px', textAlign: 'right' }}>Signature of the Person Responsible for Deducting Tax at Source</td>
                                                <td></td>
                                            </tr>
                                                <tr>
                                                    <td style={{ paddingTop: '0px' }}>
                                                        Date: <strong>{moment(PDFTemplateData.file_header.file_creation_date, 'DDMMYYYY').format('DD/MM/YYYY')}</strong>
                                                    </td>
                                                    <td style={{ paddingTop: '0px', textAlign: 'right' }}>
                                                        Name and Designation of the Person Responsible for Deducting <br />
                                                        Tax at Source
                                                    </td>
                                                    <td style={{ paddingTop: '0px', paddingLeft: '10px', width: '20%' }}>
                                                        <strong>{PDFTemplateData.batch_header.name_person_responsible_for_deduction}</strong> <br />
                                                        <strong>{PDFTemplateData.batch_header.designation_person_responsible_deduction ? PDFTemplateData.batch_header.designation_person_responsible_deduction : '-'}</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table style={{ width: '1024px', margin: 'auto auto', border: '0px solid #333', textAlign: 'right', marginTop: '15px' }}>
                                            <tr>
                                                <td className="text-right"><strong>Express TDS - A KDK Software Product</strong></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>


                        </>)
                    })}


                </div >
            </PDFExport >
        </div >
    );
};

export default PDF26Q;