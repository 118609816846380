/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { parseDate } from '@telerik/kendo-intl';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import {
  getRaiseReturnsRequestV2,  getDownloadConsoForRaiseRequest,
} from '../../../api/returnAPI';
import { getCurrentFinancialYear, getFinancialYears } from '../../../utils/UtilityFunctions';
import { BASE_PATH } from '../../../app/constants';
import './RaiseRequest.css';
import ShowMorePagination from '../../../components/pagination/ShowMorePagination';
import { HEADER_CONNECTOR_DIGEST_KEY } from '../../../app/constants';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../../components/ShowAlert';
import RaiseRequestPopup from '../RaiseRequestPopup';
import { selectHeaderData } from '../../header/headerSlice';
import DownloadForm16 from '../DownloadForm16';
import ConnectorAdapter from '../../../components/kdkConnector/ConnectorAdapter';
import TracesConnector from '../../../components/kdkConnector/TracesConnector';
import { checkRaiseRequestFromConnector, gocallApi } from '../../../api/issueCertificateAPI';
import { HEADER_FILE_NAME } from '../../../app/constants';


const RaiseRequest = ({ history, ...props }) => {
  const { deductorId, returnId } = props;

  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);
  const limit = 25;

  const [loading, setLoading] = useState(false);

  //eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  //eslint-disable-next-line
  const [deductorPan, setDeductorPan] = useState('');

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showRaiseRequestPopup, setShowRaiseRequestPopup] = useState(false);

  const [dataState, setDataState] = useState({});
  const [filterPostData, setFilterPostData] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [downloadForm16Popup, setDownloadForm16Popup] = useState(false);

  //eslint-disable-next-line
  const [popupData, setPopupData] = useState({});
  
  const [pdfConversion, setPdfConversion] = useState(false);

  const [returnList, setReturnList] = useState([]);
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [verifyTraceCredentials, setVerifyTraceCredentials] = useState(false);


  const [isConnectorRunning, setIsConnectorRunning] = useState(false);
  const [credentialAvailable, setCredentialAvailable] = useState(false)
  const [currentStep, setCurrentStep] = useState(null)

  const quarterList = [{ 'label': 'Q1', value: 'Q1' }, { 'label': 'Q2', value: 'Q2' }, { 'label': 'Q3', value: 'Q3' }, { 'label': 'Q4', value: 'Q4' }];
  const formTypeList = [{ 'label': '26Q', value: '26Q' }, { 'label': '24Q', value: '24Q' }, { 'label': '27EQ', value: '27EQ' }, { 'label': '27Q', value: '27Q' }];
  const requestForList = [
    { 'label': 'Consolidated File', value: 'CONSO' },
    { 'label': 'Justification Report', value: 'JUSTIFICATION_REPORT' },
    { 'label': 'Form 16 Part A', value: 'FORM16_PARTA' },
    { 'label': 'Form 16 Part B', value: 'FORM16_PARTB' },
    { 'label': 'Form 16', value: 'FORM16' },
    { 'label': 'Form 16A', value: 'FORM16A' },
    { 'label': 'Form 27D', value: 'FORM27D' }];

  const statusList = [
    { 'label': 'Submitted', value: 'SUBMITTED' },
    { 'label': 'Available', value: 'AVAILABLE' },
    { 'label': 'Not Available', value: 'NOT_AVAILABLE' }
  ];

  

  const yearsList = getFinancialYears();


  const headerDataDetails = useSelector(selectHeaderData);
  const financialYear = headerDataDetails?.financial_year_formatted ? headerDataDetails?.financial_year_formatted?.split("-")?.[0] : getCurrentFinancialYear();


  const getReturnList = async (data) => {
    setFilterPostData(data);
    if (data.page && data.page > 1) { setShowMoreLoading(true); } else { setLoading(true); }
    try {
      const result = await getRaiseReturnsRequestV2(data);
      if (result.data.cursor.current_page > 1) {
        setReturnList(returnList.concat(result.data.data));
      } else {
        setReturnList(result.data.data);
      }
      setCursor(result.data.cursor);
    } catch (err) {
      console.error('Error in fetching return list: ', err);
      setError(err.message);
    }
    setLoading(false);
    setShowMoreLoading(false);
  };

  const downloadConso = (deductorId, processed_files, requestFileId, dataObj) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    async function callDownloadConsoAPI(fileNo) {
      async function downloadCSIFileFnc() {
        const downloadFileStr = `?deductor_id=${deductorId}&request_no=${dataObj?.request_no}&file_no=${fileNo}`;
        const result = await getDownloadConsoForRaiseRequest(downloadFileStr);
        return result;
      }
      try {
        const result = await downloadCSIFileFnc();
        setPdfConversion(true);
        const fullFileName = result?.headers?.[HEADER_FILE_NAME] ?? `${headerDataDetails.tan}_${dataObj.financial_year}_${dataObj.quarter}_${dataObj.form_type}_${dataObj.request_for}.zip`;
        const csiData = new Blob([result?.data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csiData);
        link.setAttribute('download', fullFileName);
        document.body.appendChild(link);
        link.click();

      } catch (err) {
        console.log(err);
        if (err) {
          setError(err.toString());
        }
        else {
          setError("File not found on specified file path");
        }

      } finally {
        setLoading(false);
      }
    }
    for (let i = 1; i <= dataObj?.traces_file_count; i++) {
      callDownloadConsoAPI(i);
    }

  };


  useEffect(() => {
    const postData = getParamsHistoryUrl();
    getReturnList(postData);
  }, [searchStr]);

  const getParamsHistoryUrl = () => {
    const postData = {
      deductor_id: deductorId,
      return_id: returnId,
    };
    if (params.sort) {
      dataState.sort = JSON.parse(atob(params.sort));
      postData.sort = JSON.parse(atob(params.sort));
    }
    if (params.filters) {

      // IN getParamsHistoryUrl
      let frs = JSON.parse(atob(params.filters));
      for (let i in frs) {
        for (let j in frs[i].filters) {
          if (frs[i].filters[j].field === 'request_date') {
            frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
          }
          if(frs[i].filters[j].field === 'status'){
             if(frs[i].filters[j].value?.[0] === 'SUBMITTED'){
              frs[i].filters[j].value = ['PORTAL_AVAILABLE_NOT_DOWNLOADED', 'SUBMITTED'];
             }else if(frs[i].filters[j].value?.[0] === 'AVAILABLE'){
              frs[i].filters[j].value = ['AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF', 'AVAILABLE'];
             }else if(frs[i].filters[j].value?.[0] === 'NOT_AVAILABLE'){
              frs[i].filters[j].value = ['NOT_AVAILABLE'];
             }
          }
        }
      }
      dataState.filter = { filters: [] };
      dataState.filter.filters = frs;
      postData.filters = frs;
    }
    if (params.page) {
      postData.page = params.page;
    }
    postData.limit = params.limit ?? limit;
    setDataState(dataState);
    setFilterPostData(postData);
    return postData;
  };

  const createHistoryUrl = (dataState = []) => {
    let filterStr = dataState.page ? `?page=${dataState.page}` : `?page=1`;

    if (dataState.filter) {
      filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
    }
    if (dataState.sort && dataState.sort.length > 0) {
      filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
    }
    history.push(filterStr);
  };

  const dataStateChange = (dataState) => {
    setDataState(dataState);
    createHistoryUrl(dataState);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };





  const submitProceedBtn = async () => {
    setCurrentStep(2)
    if (credentialAvailable && captchaRef) {
      setVerifyTraceCredentials(true);
      return null;
    }
    setIsConnectorRunning(true);
  };

  const callStatusCheck = async () => {
    setLoading(true)
    try {
      const url = `/api/v1/usuc/traces/pending-raise-request`
      const result = await gocallApi(
        "post", 
        url, 
        { deductor_id: deductorId, captcha_ref: captchaRef, },
        true);
      const responseData = result?.data ?? null;
      const responseHeader = result?.headers ?? null;
      const connectorRequest = {
        "data": responseData?.data
      };
      let extraHeader = {};
      extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

      try {
        const connectorResponse = await checkRaiseRequestFromConnector(port, connectorRequest, extraHeader);
        if (Number(connectorResponse.status) === 1) {
          setSuccess(connectorResponse?.message);
          getReturnList(filterPostData);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(true);
      }

    } catch (err) {
      // setError(err);
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  const submitProceedBtn2 = async () => {
    setCurrentStep(1)
    if (credentialAvailable) {
      setShowRaiseRequestPopup(true);
      return null;
    }
    setIsConnectorRunning(true);
  };



  const downloadForm16 = (dataItem) => {
    if (dataItem.downloadable) {
      downloadConso(deductorId, false, dataItem.raise_request_id, dataItem);

    }
  }

  const getRequestFor = (request_for) => {
    let requestFor = requestForList.filter((data) => { return data.value === request_for; });
    return requestFor.length > 0 ? requestFor[0].label : '-';
  }

const raiseRequestStatusMapping = (status) => {
  switch(status) {
    case 'PORTAL_AVAILABLE_NOT_DOWNLOADED' :  return 'Submitted';
    case 'NOT_AVAILABLE': return 'Not Available';
    case 'AVAILABLE_UNSIGNED_PDF':
    case 'AVAILABLE_SIGNED_PDF':
    case 'AVAILABLE_ALL_PDF':
    case 'AVAILABLE' : return 'Available';
    case 'SUBMITTED': return 'Submitted';
    default: return '';
  }
}

  const rowRender = (trElement, props) => {
    return (
      <tr>
        <td>{props.dataItem.request_no}</td>
        <td className="text-center">{props.dataItem.request_date ? moment(props.dataItem.request_date).format('DD/MM/YYYY') : '-'}</td>
        <td className="text-center">{props.dataItem.financial_year} - {(props.dataItem.financial_year + 1) % 100}</td>
        <td className="text-center">{props.dataItem.quarter}</td>
        <td className="text-center">{props.dataItem.form_type}</td>
        <td className={`${getRequestFor(props.dataItem.request_for) !== "-"} ? 'text-left' : 'text-center'`}>{getRequestFor(props.dataItem.request_for)}</td>
        <td className="text-right">{props.dataItem.traces_file_count}</td>
        <td onClick={() => downloadForm16(props.dataItem)} className={props.dataItem.downloadable === true ? "text-center link" : "text-center"}>
            {raiseRequestStatusMapping(props.dataItem.status)}
        </td>
        <td className={`${(props.dataItem.remark && props.dataItem.remark !== "" && props.dataItem.remark !== "-") ? 'text-left' : 'text-center'}`}>{(props.dataItem.remark && props.dataItem.remark !== "" && props.dataItem.remark !== "-" ) ? props.dataItem.remark : '-'}</td>
      </tr>
    );
  }

  return (
    <div>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {isConnectorRunning
        ? <ConnectorAdapter
          show={isConnectorRunning}
          handleClose={() => setIsConnectorRunning(false)}
          setPort={setPort}
          handleProceed={() => {
            setVerifyTraceCredentials(true);
          }
          }
        />
        : null
      }
      {verifyTraceCredentials && port
        ?
        <TracesConnector
          dontLogin={currentStep === 1 ? true : false}
          deductorId={deductorId}
          returnId={null}
          handleProceed={() => {
            setIsConnectorRunning(false);
            setVerifyTraceCredentials(false);
          }}
          port={port}
          setVerifyCreds={() => {
            setCredentialAvailable(true)
            currentStep === 1 && setShowRaiseRequestPopup(true);
            currentStep === 2 && callStatusCheck();
          }}
          creds={null}
          setCaptchaRef={setCaptchaRef}
          callCaptchaRef={true}
        />
        : null
      }

      {showRaiseRequestPopup ?
        <RaiseRequestPopup
          show={showRaiseRequestPopup}
          handleClose={() => {
            setShowRaiseRequestPopup(false);
            getReturnList(filterPostData);
          }}
          deductorId={deductorId}
          pan={deductorPan}
          captchaRef={captchaRef}
          port={port}
          setSuccess={setSuccess}
        />
        : null}

      <div className="container">
        <div className="row pt-2">
          <div className="col-sm-12">
            <Link className="backtoclr" to={`${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${financialYear}`}>
              &lt;  Back to Deductor Dashboard
            </Link>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-12">
            <fieldset className="reqraisedtranfields">
              <legend className="reqraisedtranlegent reqriasetrcelegn">
                <h4>  Requests Raised to TRACES</h4>
              </legend>

              <Tooltip anchorElement="pointer" position="right">
                <Grid
                  className="table table-striped listofsalarydetailstb"
                  style={{ height: '400px', overflowX: 'auto' }}
                  data={returnList}
                  {...dataState}
                  onDataStateChange={(event) => dataStateChange(event.dataState)}
                  filterOperators={{
                    text: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                    ],
                    numeric: [
                      { text: 'grid.filterGteOperator', operator: 'gte' },
                      { text: 'grid.filterLteOperator', operator: 'lte' },
                      { text: 'grid.filterLtOperator', operator: 'lt' },
                      { text: 'grid.filterGtOperator', operator: 'gt' },
                      { text: 'grid.filterEqOperator', operator: 'eq' },
                      { text: 'grid.filterNotEqOperator', operator: 'neq' },
                    ],
                    date: [
                      { text: 'grid.filterGteOperator', operator: 'gte' },
                      { text: 'grid.filterLteOperator', operator: 'lte' },
                      { text: 'grid.filterLtOperator', operator: 'lt' },
                      { text: 'grid.filterGtOperator', operator: 'gt' },
                      { text: 'grid.filterEqOperator', operator: 'eq' },
                    ],
                    dropdown: [
                      { text: 'grid.filterContainsOperator', operator: 'in' },
                    ],
                  }}
                  rowRender={rowRender}
                  sortable
                >
                  <Column
                    field="request_no"
                    headerClassName={isColumnActive('request_no') ? 'active' : ''}
                    title="Request No."
                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                  />
                  <Column
                    field="request_date"
                    headerClassName={isColumnActive('request_date') ? 'active' : ''}
                    title="Request Date"
                    filter="date"
                    columnMenu={(props) => <ColumnMenu {...props} />}
                  />
                  <Column
                    field="financial_year"
                    headerClassName={isColumnActive('financial_year') ? 'active' : ''}
                    title="Financial Year"
                    filter="dropdown"
                    columnMenu={(props) => <ColumnMenu hideSecondFilter filterList={yearsList} {...props} />}
                  />
                  <Column
                    field="quarter"
                    headerClassName={isColumnActive('quarter') ? 'active' : ''}
                    title="Quarter"
                    filter="dropdown"
                    columnMenu={(props) => <ColumnMenu hideSecondFilter filterList={quarterList} {...props} />}
                    sortable={false}
                  />
                  <Column
                    field="form_type"
                    headerClassName={isColumnActive('form_type') ? 'active' : ''}
                    title="Form Type"
                    filter="dropdown"
                    columnMenu={(props) => <ColumnMenu hideSecondFilter filterList={formTypeList} {...props} />}
                    sortable={false}
                  />
                  <Column
                    field="request_for"
                    headerClassName={isColumnActive('request_for') ? 'active' : ''}
                    title="Request Raised For"
                    filter="dropdown"
                    columnMenu={(props) => <ColumnMenu hideSecondFilter filterList={requestForList} {...props} />}
                    sortable={false}
                    width="160"
                  />
                  <Column
                    headerClassName="text-right"
                    title="Total Files"
                    width="100"
                  />

                  <Column
                    field="status"
                    headerClassName={isColumnActive('status') ? 'active' : ''}
                    title="Status"
                    filter="dropdown"
                    columnMenu={(props) => <ColumnMenu hideSecondFilter filterList={statusList} {...props} />}
                    sortable={false}
                  />
                  <Column
                    field="remark"
                    headerClassName={isColumnActive('remark') ? 'active' : ''}
                    title="Remarks"
                    sortable={false}
                  />
                  <GridNoRecords>
                    No Record Found
                  </GridNoRecords>
                </Grid>
              </Tooltip>

              <ShowMorePagination
                cursor={cursor}
                fetchData={getReturnList}
                postData={filterPostData}
                loading={showMoreLoading}
              />

              <div className="row">
                <div className="col-md-12">
                  <div className="reqraisebtnsect float-right">
                    <span onClick={() => submitProceedBtn2()} className="text-white">
                      {' '}
                      <Button className="btn btn-default raisenewrebtn pr-2 pb-1">Submit Request</Button>
                    </span>
                    <span className="text-white" onClick={() => submitProceedBtn('')}>
                      <Button className="btn btn-default upstraiserebtn pb-1" disabled={loader}>
                        {loader ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            {' '}
                            Loading...
                          </>
                        ) : 'Update Status from TRACES'}
                      </Button>
                    </span>
                  </div>
                </div>
              </div>

              {downloadForm16Popup && <DownloadForm16
                show={downloadForm16Popup}
                handleClose={() => setDownloadForm16Popup(false)}
                popupData={popupData}
                deductorId={deductorId}
                downloadConso={downloadConso}
                pdfConversion={pdfConversion}
              />}

            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaiseRequest;
