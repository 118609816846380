/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
// import FileUploadPopup from './FileUploadPopup';
import FileUploadPopupV2 from './FileUploadPopupV2';
import ImportCorrectionReturnProgress from './ImportCorrectionReturnProgress';
import { selectHeaderData } from '../../header/headerSlice';
import { useSelector } from 'react-redux';
import { createImportCorrectionReturnLocalStorageKey } from '../../../utils/UtilityFunctions';

const ImportCorrectionReturnFromExcel = (props) => {
  const {
    show, handleClose, deductorId, returnId, importExelError, showImportReturnFromTxt, downloadExcelTempLoading, downloadExcelTemp = null,
  } = props;
  const headerData = useSelector(selectHeaderData);

  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showResult, setShowResult] = useState(false);


  useEffect(() => {
    if (headerData?.mobile_number && headerData?.tan && headerData?.financial_year_formatted && headerData?.quarter && headerData?.form_type) {
      const checkRunningImport = localStorage.getItem(createImportCorrectionReturnLocalStorageKey(headerData))
      if (checkRunningImport) {
        setShowResult(true);
      } else {
        setShowFileUpload(true);

      }
    }
  }, [headerData])

  useEffect(() => {
    if (headerData?.mobile_number && headerData?.tan && headerData?.financial_year_formatted && headerData?.quarter && headerData?.form_type && show) {
      const checkRunningImport = localStorage.getItem(createImportCorrectionReturnLocalStorageKey(headerData))
      if (checkRunningImport) {
        setShowResult(true);
      } else {
        setShowFileUpload(true);

      }
    }
  }, [show, headerData]);


  if (showResult) {
    return (
      <>
        <ImportCorrectionReturnProgress
          show={showResult}
          handleClose={() => {
            setShowResult(false);
            handleClose();
          }}
          deductorId={deductorId}
          returnId={returnId}
          headerData={headerData}
        />
      </>
    )

  }


  else if (show) {
    return (
      <>
        {showFileUpload && (
          <FileUploadPopupV2            //Version 2 has been used due to Ui changes
            key="1"
            handleClose={() => {
              handleClose();
            }}
            handleProceed={() => {
              setShowFileUpload(false);
              setShowResult(true);
              setTimeout(()=>{
                window.location.reload();
              },10)
            }}
            deductorId={deductorId}
            returnId={returnId}
            importExelError={importExelError}
            showImportReturnFromTxt={showImportReturnFromTxt}
            downloadExcelTemp={downloadExcelTemp}
            downloadExcelTempLoading={downloadExcelTempLoading}
          />
        )}
      </>
    );
  }

  return null;
};

export default ImportCorrectionReturnFromExcel;
