import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../components/verifyPAN/UpdateTracesCredential.css';
import ShowAlert from '../../components/ShowAlert';
import { editDeductor } from '../../api/deductorAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash
} from '@fortawesome/free-solid-svg-icons';

const UpdateEFilingCredsWithoutVerify = (props) => {
  const { show, handleClose, deductorId, tan, handleProceed, password } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [eFilingPswrd, setEFilingPswrd] = useState(password);
  const [showPswrd, setShowPswrd] = useState(false);

  const updateCredentials = (eFilingPswrd) => {
    const request = {
      "traces_username": tan,
      "traces_password": eFilingPswrd,
      "deductor_id": deductorId,
      "deductor_credentials": [
        {
          "credential_type": "EFILING",
          "username": tan,
          "password": eFilingPswrd,
          "verified": true,
        }
      ]
    }
    async function fetchEverything() {
      setLoading(true);
      try {
        const updateCredsResult = await editDeductor(request);
        if (updateCredsResult.status === 1) {
          handleProceed();
        }

      } catch (err) {
        setError('error in updating deductor credentials');
      } finally {
        setLoading(false)

      }
    }
    fetchEverything();
  };

  const submitForm = (e) => {
    e.preventDefault();
    let fErrors = {};
    if (eFilingPswrd === '') { fErrors['eFilingPassword'] = 'EFiling Password is required'; }
    setFormErrors(fErrors);
    if (Object.keys(fErrors).length > 0) return;
    updateCredentials(eFilingPswrd);
  };



  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="tracescredentials"
      >
        {loading ? <LoadingIndicator /> : null}

        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title>Income Tax Credentials</Modal.Title>
        </Modal.Header>

        <form onSubmit={submitForm}>
          <Modal.Body className="pb-0 px-0">

            <ShowAlert
              error={error}
              success={success}
              onClose={() => { setError(null); setSuccess(null); }}
            />

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {/* <p className="mb-0 pb-0">Please provide Income Tax Credentials below </p> */}

                  <div className="row">
                    <div className="col-md-12">

                      <div className="form-group">
                        <label className="control-label" htmlFor="">EFiling UserName </label>
                        <input
                          disabled
                          type="search"
                          className="form-control"
                          value={tan}
                        />
                      </div>

                      {showPswrd ?

                        <>
                          <div className="form-group">
                            <label className="control-label" htmlFor="">EFiling Password </label>
                            <div class="input-group">
                              <input
                                type="input"
                                className="form-control"
                                id="eFilingPassword"
                                name="eFilingPassword"
                                placeholder="Enter EFiling Password"
                                onChange={(e) => setEFilingPswrd(e.target.value)}
                                value={eFilingPswrd}
                              />
                              <div
                                className="input-group-prepend eyeipbox-create-deductor"
                                onClick={() => setShowPswrd(false)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </div>
                            </div>
                            {formErrors.eFilingPassword ? <span className="error_message">{formErrors.eFilingPassword}</span> : null}
                          </div>
                        </>
                        :
                        <>
                          <div className="form-group">
                            <label className="control-label" htmlFor="">EFiling Password </label>
                            <div class="input-group">
                              <input
                                type="password"
                                className="form-control"
                                id="eFilingPassword"
                                name="eFilingPassword"
                                placeholder="Enter EFiling Password"
                                onChange={(e) => setEFilingPswrd(e.target.value)}
                                value={eFilingPswrd}
                              />
                              <div
                                className="input-group-prepend eyeipbox-create-deductor"
                                onClick={() => setShowPswrd(true)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </div>
                            </div>
                            {formErrors.eFilingPassword ? <span className="error_message">{formErrors.eFilingPassword}</span> : null}
                          </div>
                        </>
                      }
                    </div>

                  </div> <br />


                </div>

              </div>

            </div>

          </Modal.Body>
          <Modal.Footer className="tracecredfoot">
            <Button type="submit" className="tracesbtnsubmit" onClick={submitForm} disabled={loading}> Save </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>

  );
};
export default UpdateEFilingCredsWithoutVerify;
