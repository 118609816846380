import React, { Component } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import $ from "jquery";
import moment from "moment";
import "./DatePicker.css";

const getYear = (date) => {
  const d = new Date(date);
  return new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
};

const years = _.range(1900, parseInt(getYear(new Date())) + 2, 1);
years.reverse();
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let oneDay = 60 * 60 * 24 * 1000;
let todayTimestamp =
  Date.now() -
  (Date.now() % oneDay) +
  new Date().getTimezoneOffset() * 1000 * 60;

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    if (props.maxDate < new Date()) {
      date = props.maxDate;
    }
    let year = date.getFullYear();
    let month = date.getMonth();
    this.state = {
      year,
      month,
      selectedDay: date.getTime(),
      monthDetails: this.getMonthDetails(year, month),
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.addBackDrop);
    if (this.props.selectedDate) {
      this.inputRef.current.value = this.props.selectedDate;
      this.updateDateFromInput();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.addBackDrop);
  }

  addBackDrop = (e) => {
    if (
      this.state.showDatePicker &&
      !ReactDOM.findDOMNode(this).contains(e.target)
    ) {
      this.showDatePicker(false);
    }
  };

  showDatePicker = (showDatePicker = true) => {
    this.setState({ showDatePicker });
  };

  /**
   *  Core
   */

  daysMap = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  monthMap = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  getDayDetails = (args) => {
    let date = args.index - args.firstDay;
    let day = args.index % 7;
    let prevMonth = args.month - 1;
    let prevYear = args.year;
    if (prevMonth < 0) {
      prevMonth = 11;
      prevYear--;
    }
    let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
    let _date =
      (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
    let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
    let timestamp = new Date(args.year, args.month, _date).getTime();
    return {
      date: _date,
      day,
      month,
      timestamp,
      dayString: this.daysMap[day],
    };
  };

  getNumberOfDays = (year, month) => {
    return 40 - new Date(year, month, 40).getDate();
  };

  getMonthDetails = (year, month) => {
    let firstDay = new Date(year, month).getDay();
    let numberOfDays = this.getNumberOfDays(year, month);
    let monthArray = [];
    let rows = 6;
    let currentDay = null;
    let index = 0;
    let cols = 7;

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        currentDay = this.getDayDetails({
          index,
          numberOfDays,
          firstDay,
          year,
          month,
        });
        monthArray.push(currentDay);
        index++;
      }
    }
    return monthArray;
  };

  isCurrentDay = (day) => {
    return day.timestamp === todayTimestamp;
  };

  isSelectedDay = (day) => {
    return day.timestamp === this.state.selectedDay;
  };

  getDateFromDateString = (dateValue) => {
    let dateData = dateValue.split("-").map((d) => parseInt(d, 10));
    if (dateData.length < 3) return null;

    let year = dateData[0];
    let month = dateData[1];
    let date = dateData[2];
    return { year, month, date };
  };

  getMonthStr = (month) =>
    this.monthMap[Math.max(Math.min(11, month), 0)] || "Month";

  getDateStringFromTimestamp = (timestamp) => {
    let dateObject = new Date(timestamp);
    let month = dateObject.getMonth() + 1;
    let date = dateObject.getDate();
    return (
      dateObject.getFullYear() +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (date < 10 ? "0" + date : date)
    );
  };

  setDate = (dateData) => {
    this.setState({ selectedDay: dateData });
    if (this.props.onChange) {
      this.props.onChange(dateData);
    }
  };

  updateDateFromInput = () => {
    let dateValue = this.inputRef.current.value;
    this.setDate(dateValue);
  };

  setDateToInput = (timestamp) => {
    let dateString = this.getDateStringFromTimestamp(timestamp);
    let dateFormat = dateString.split("-").reverse().join("/");
    this.props.onChange(dateFormat);
    this.showDatePicker(false);
    this.inputRef.current.value = dateFormat;
  };

  onDateClick = (e, day) => {
    e.preventDefault();
    this.setState({ selectedDay: day.timestamp }, () =>
      this.setDateToInput(day.timestamp)
    );
    if (this.props.onChange) {
      this.props.onChange(moment(day.timestamp).format("DD/MM/YYYY"));
    }
  };

  setYear = (offset) => {
    let year = this.state.year + offset;
    let month = this.state.month;
    this.setState({
      year,
      monthDetails: this.getMonthDetails(year, month),
    });
  };

  setYearActual = (year) => {
    let month = this.state.month;
    this.setState({
      year,
      monthDetails: this.getMonthDetails(year, month),
    });
  };

  setMonth = (offset) => {
    let year = this.state.year;
    let month = parseInt(this.state.month) + offset;
    if (month === -1) {
      month = 11;
      year--;
    } else if (month === 12) {
      month = 0;
      year++;
    }
    this.setState({
      year,
      month,
      monthDetails: this.getMonthDetails(year, month),
    });
  };

  setMonthActual = (month) => {
    let year = this.state.year;
    if (month === -1) {
      month = 11;
      year--;
    } else if (month === 12) {
      month = 0;
      year++;
    }
    this.setState({
      year,
      month,
      monthDetails: this.getMonthDetails(year, month),
    });
  };

  /**
   *  Renderers
   */

  renderCalendar() {
    let days = this.state.monthDetails.map((day, index) => {
      let disable = false;
      if (day.month !== 0) disable = true;
      if (this.props.maxDate && this.props.maxDate.getTime() < day.timestamp) {
        disable = true;
      }
      if (this.props.minDate) {
        let yesterday = new Date(this.props.minDate.getTime() - 24 * 60 * 60 * 1000);
        if (yesterday.getTime() > day.timestamp) {
          disable = true;
        }
      }

      return (
        <div
          className={
            "c-day-container " +
            (disable ? " disabled" : "") +
            (this.isCurrentDay(day) ? " highlight" : "") +
            (this.isSelectedDay(day) ? " highlight-green" : "")
          }
          key={index}
        >
          <div className="cdc-day">
            <span
              onClick={(e) => this.onDateClick(e, day)}
              id="date-picker-day"
            >
              {day.date}
            </span>
          </div>
        </div>
      );
    });

    return (
      <div className="c-container">
        <div className="cc-head">
          {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map((d, i) => (
            <div key={i} className="cch-name">
              {d}
            </div>
          ))}
        </div>
        <div className="cc-body">{days}</div>
      </div>
    );
  }

  openCalender() {
    this.setState({ showDatePicker: true }, () => {
      const cc = this.props.id ? document.getElementById(this.props.id) : document.getElementById("mdp-calendar-div")
      const position = cc.getBoundingClientRect();
   
      if (position.left + position.width > $(window).width()) {
        cc.style.left = `${cc.style.left -
          (position.left + position.width - $(window).width()) -
          5
          }px`;
      }
      if (position.top + position.height > $(window).height()) {
        cc.style.top = `${cc.style.top -
          (position.top + position.height - $(window).height()) -
          5
          }px`;
      }
    });
  }


  render() {
    return (
      <div className={this.props.disabled ? 'MyDatePicker disabled' : 'MyDatePicker'}>
        <div>
          <input
            type="input"
            placeholder={this.props.placeHolder ?? "Enter Date"}
            className={`form-control ${this.props.errors ? 'input-error1' : ''}`}
            style={{
              fontSize: "11px",
              color: "#636363",
              letterSpacing: "0px",
              minWidth: "110px",
            }}
            onChange={this.updateDateFromInput}
            ref={this.inputRef}
            disabled={this.props.disabled}
            onFocus={() => this.openCalender()}            
          />
          <div
            style={{
              position: "absolute",
              right: "22px",
              top: "5px",
              zIndex: "1000",
              cursor: "pointer",
            }}
            onClick={() => this.openCalender()}
          >
            <span role="img" aria-label="Snowman">&#128197;</span>
          </div>
          <div className="cal_clear_btn"
            style={{
              position: "absolute",
              right: "6px",
              top: "1px",
              zIndex: "1001",
              fontSize: "19px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              this.inputRef.current.value = "";
              this.updateDateFromInput();
              this.props.onChange("");
            }}
          >
            &times;
          </div>
        </div>
        {this.state.showDatePicker ? (
          <div className="mdp-container cdtvpopup" id={this.props.id ? this.props.id : "mdp-calendar-div"}>
            <div className="mdpc-head">
              <div className="col-md-4"></div>
              <div className="mdpch-button">
                <div className="mdpchb-inner" onClick={() => this.setMonth(-1)}>
                  <span className="mdpchbi-left-arrow"></span>
                </div>
              </div>
              <div className="mdpch-container">
              <div className="mdpchc-month">
                  <select
                    className="form-control my-select"
                    style={{ width: "110px" }}
                    value={this.state.month}
                    onChange={(e) => this.setMonthActual(e.target.value)}                    
                  >
                    {months.map((month, index) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mdpchc-year">
                  <select
                    className="form-control my-select"
                    style={{ width: "75px" }}
                    value={parseInt(this.state.year)}
                    onChange={(e) => this.setYearActual(e.target.value)}
                    onKeyDown={(e) => {
                      if(e.keyCode === 9) { //Tab
                        this.showDatePicker(false);
                      }
                    }}  
                  >
                    {years.map((year, index) => (
                      <option key={index} value={parseInt(year)}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
               
              </div>
              <div className="mdpch-button">
                <div className="mdpchb-inner" onClick={() => this.setMonth(1)}>
                  <span className="mdpchbi-right-arrow"></span>
                </div>
              </div>
            </div>
            <div className="mdpc-body">{this.renderCalendar()}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
