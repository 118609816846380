// @flow
/**
 * A generic dropdown component.  It takes the children of the component
 * and hosts it in the component.  When the component is selected, it
 * drops-down the contentComponent and applies the contentProps.
 */
import React, { Component } from 'react';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            hasFocus: false,
        }
    }


    componentWillUpdate() {
        document.addEventListener('touchstart', this.handleDocumentClick);
        document.addEventListener('mousedown', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.handleDocumentClick);
        document.removeEventListener('mousedown', this.handleDocumentClick);
    }

    handleDocumentClick = (event) => {
        if (this.wrapper && !this.wrapper.contains(event.target)) {
            const prevExpanded = this.state.expanded;
            this.setState({ expanded: false });
            if (prevExpanded && this.props.onClose) {
                this.props.onClose();
            }
        }
    }

    handleKeyDown = (e) => {
        switch (e.which) {
            case 27: // Escape
                this.toggleExpanded(false);
                break;
            case 38: // Up Arrow
                this.toggleExpanded(false);
                break;
            case 13: // Enter Key
            case 32: // Space
            case 40: // Down Arrow
                this.toggleExpanded(true);
                break;
            default:
                return;
        }

        e.preventDefault();
    }

    handleFocus = (e) => {
        const { hasFocus } = this.state;

        if (e.target === this.wrapper && !hasFocus) {
            this.setState({ hasFocus: true });
        }
    }

    handleBlur = (e) => {
        const { hasFocus } = this.state;

        if (hasFocus) {
            this.setState({ hasFocus: false });
        }
    }

    handleMouseEnter = (e) => {
        this.handleHover(true);
    }

    handleMouseLeave = (e) => {
        this.handleHover(false);
    }

    handleHover = (toggleExpanded) => {
        const { shouldToggleOnHover } = this.props;

        if (shouldToggleOnHover) {
            this.toggleExpanded(toggleExpanded);
        }
    }

    toggleExpanded = (value) => {
        const { expanded } = this.state;

        const newExpanded = value === undefined ? !expanded : !!value;

        this.setState({ expanded: newExpanded });

        if (!newExpanded && this.wrapper) {
            this.wrapper.focus();
        }
    }

    renderPanel() {
        const { contentComponent: ContentComponent, contentProps } = this.props;

        return <div
            className="dropdown-content"
            style={styles.panelContainer}
        >
            <ContentComponent {...contentProps} />
        </div>;
    }

    render() {
        const { expanded, hasFocus } = this.state;
        const { children, disabled, labelledBy } = this.props;

        const expandedHeaderStyle = expanded
            ? styles.dropdownHeaderExpanded
            : undefined;

        const focusedHeaderStyle = hasFocus
            ? styles.dropdownHeaderFocused
            : undefined;

        const arrowStyle = expanded
            ? styles.dropdownArrowUp
            : styles.dropdownArrowDown;

        // const focusedArrowStyle = hasFocus
        //     ? styles.dropdownArrowDownFocused
        //     : undefined;

        const headingStyle = {
            ...styles.dropdownChildren,
            ...(disabled ? styles.disabledDropdownChildren : {}),
        };

        return <div
            className="dropdown"
            tabIndex="0"
            role="combobox"
            aria-labelledby={labelledBy}
            aria-expanded={expanded}
            aria-readonly="true"
            aria-disabled={disabled}
            aria-controls={{}}
            style={styles.dropdownContainer}
            ref={ref => this.wrapper = ref}
            onKeyDown={this.handleKeyDown}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
        >
            <div
                style={{
                    ...styles.dropdownHeader,
                    ...expandedHeaderStyle,
                    ...focusedHeaderStyle,
                }}
                onClick={() => this.toggleExpanded()}
                className={this.props?.contentProps?.selected.length > 0 ? 'item-selected' : ''}
            >
                <span
                    className="dropdown-heading-value"
                    style={headingStyle}
                >
                    {children}
                </span>
                <span
                    className="dropdown-heading-loading-container"
                    style={styles.loadingContainer}
                >
                </span>
                <span
                    className="dropdown-heading-dropdown-arrow"
                    style={styles.dropdownArrow}
                >
                    {/* <span style={{
                        ...arrowStyle,
                        ...focusedArrowStyle,
                    }} */}
                    <span style={{
                        ...arrowStyle,
                    }}
                    />
                </span>
            </div>
            {expanded && this.renderPanel()}
        </div>;
    }
}

const styles = {
    dropdownArrow: {
        boxSizing: 'border-box',
        cursor: 'pointer',
        display: 'table-cell',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 25,
        paddingRight: 5,
    },
    dropdownArrowDown: {
        boxSizing: 'border-box',
        borderColor: '#ced4da transparent transparent',
        borderStyle: 'solid',
        borderWidth: '5px 5px 2.5px',
        display: 'inline-block',
        height: 0,
        width: 0,
        position: 'relative',
    },
    dropdownArrowDownFocused: {
        border: '1px solid #ced4da',
    },
    dropdownArrowUp: {
        boxSizing: 'border-box',
        top: '-2px',
        borderColor: 'transparent transparent #ced4da',
        borderStyle: 'solid',
        borderWidth: '0px 5px 5px',
        display: 'inline-block',
        height: 0,
        width: 0,
        position: 'relative',
    },
    dropdownChildren: {
        boxSizing: 'border-box',
        bottom: 0,
        color: '#333',
        left: 0,
        lineHeight: '34px',
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        right: 0,
        top: 0,
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    disabledDropdownChildren: {
        opacity: 0.5,
    },
    dropdownContainer: {
        position: 'relative',
        boxSizing: 'border-box',
        outline: 'none',
    },
    dropdownHeader: {
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        borderRadius: 4,
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        color: '#333',
        cursor: 'pointer',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'separate',
        height: 32,
        outline: 'none',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        border: '1px solid #ced4da',
    },
    dropdownHeaderFocused: {
        border: '1px solid #ced4da',
        boxShadow: 'none',
    },
    dropdownHeaderExpanded: {
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
    },
    loadingContainer: {
        cursor: 'pointer',
        display: 'table-cell',
        verticalAlign: 'middle',
        width: '16px',
    },
    panelContainer: {
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #ced4da',
        borderTopColor: '#e6e6e6',
        boxShadow: '0 1px 0 rgba(0, 0, 0, 0.06)',
        boxSizing: 'border-box',
        marginTop: '-1px',
        maxHeight: '359px',
        position: 'absolute',
        top: '100%',
        width: '100%',
        zIndex: 1,
        overflowY: 'auto',
    },    
};

export default Dropdown;
