import React, { useState, useEffect } from "react";
import WindowWrapperFixed from "../../../components/grid/WindowWrapperFixed";
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import { Alert } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import {
    //MAX_IMPORT_FILE_SIZE_MB, 
    SALARY_HEADER_ROW_COLUMNS,
    SALARY_HEADER_ROW_ALLOWED_COLUMNS_COUNT,
    MAX_SALARY_ROW_CHECK,
    HEADER_FILE_NAME,
} from "../../../app/constants";
import * as XLSX from 'xlsx';
import $ from 'jquery'
import { deepClone } from "../../../utils/UtilityFunctions";
import { downloadDefaultSalaryTemplate } from "../../../api/salaryAPI";
import './ImportExcelFile.scss';
import exportIcon from '../../../images/icons/export_icon.png';
import importIcon from '../../../images/icons/import_icon.png';
import excelIcon from '../../../images/excelicon.png';
import { gocallApi } from "../../../api/issueCertificateAPI";
import { MAX_SALARY_TDS_CAL_TEMPLATE_COUNT } from "../../../app/constants";
import './mappingTemplate.scss'
const ImportExcelFile = (props) => {

    const {
        onClose,
        deductorId,
        financial_year,
        setFile,
        uploadFormDataWithDefaultTemplate,
        updateSalaryColumns,
        showMappingPage,
        setSheetName,
        setHeaderRowIndex,
        setColumnList,
        setTemplateName,
        setTemplateId,
        setError1
    } = props;

    const { isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject, } = useDropzone({
        accept: '.xls,.xlsx,.xlsm,'
    });


    const HEADER_ROW_KEYS = SALARY_HEADER_ROW_COLUMNS;
    const MAX_ROW_CHECK = MAX_SALARY_ROW_CHECK;

    //let JSZip = require("jszip");

    const [files, setFiles] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [previousTemplate, setPreviousTemplate] = useState([])
    const [showTemplatePopup, setShowTemplatePopup] = useState(false)
    const [tempColumn, setTempColumn] = useState([])

    const removeFile = (targetFile) => {
        const newFiles = [];
        files.forEach((file) => {
            if (file.path === targetFile.path) return;
            newFiles.push(file);
        });
        setFiles(newFiles);
        setShowTemplatePopup(false)
    };

    const renderFiles = files.map((file) => {
        return (<span key={file.path} style={{ margin: '5px' }}>
            <span style={{ float: 'left' }}>
                {file.path}
                {' '}
                -
                {file.size}
                {' '}
                bytes
            </span>
            <Button
                variant="danger"
                style={{
                    fontSize: '12px', padding: '0px 5px 2px 5px', marginLeft: '10px', float: 'right'
                }}
                onClick={() => { removeFile(file); setError('') }}
            >
                Remove
            </Button>
        </span>)
    });

    const isDefaultTemplateHeaderRow = (data) => {
        const headerRowKeys = sanitizeKeys(['Employee Ref No', 'PAN*', 'Name*', 'DOB', 'Age', 'Category Type*', 'Regime*', 'Email']);
        const rowKeys = sanitizeKeys(Object.values(data));
        return containsAll(headerRowKeys, rowKeys);
    }

    const isHeaderRow = (data) => {
        const rowKeys = sanitizeKeys(Object.values(data));
        const headerRowKeys = sanitizeKeys(Object.keys(HEADER_ROW_KEYS));
        return checkElementsinArray(headerRowKeys, rowKeys);
    }

    const isHeaderRow1 = (data) => {
        const rowKeys = sanitizeKeys(Object.keys(data));
        const headerRowKeys = sanitizeKeys(Object.keys(HEADER_ROW_KEYS));
        return checkElementsinArray(headerRowKeys, rowKeys);
    }


    function checkElementsinArray(fixedArray, inputArray) {
        const checkKeys = deepClone(HEADER_ROW_KEYS);
        for (let i = 0; i < fixedArray.length; i++) {
            for (let j = 0; j < inputArray.length; j++) {
                if (inputArray[j].indexOf(fixedArray[i]) !== -1) {
                    checkKeys[fixedArray[i]] = true;
                    break;
                }
            }
        }

        //count total true values
        const total = Object.values(checkKeys).reduce((a, item) => a + item, 0);
        return Number(total) >= Number(SALARY_HEADER_ROW_ALLOWED_COLUMNS_COUNT ?? 3);
    }


    function containsAll(needles, haystack) {
        for (var i = 0; i < needles.length; i++) {
            if ($.inArray(needles[i], haystack) === -1) return false;
        }
        return true;
    }

    function sanitizeKeys(data) {
        const list = [];
        data.forEach((item) => {
            list.push(String(item).trim().toLowerCase())
        });
        return list;
    }

    const valdiateSheetData = (sheetData) => {
        const maxCounter = MAX_ROW_CHECK;
        let index = -1;
        let columns = [];
        setError('');
        for (let i = 0; i < sheetData.length; i++) {
            if (isHeaderRow(sheetData[i])) {
                index = i;
                columns = Object.values(sheetData[i]);
                break;
            } else if (isHeaderRow1(sheetData[i])) {
                index = i;
                columns = Object.keys(sheetData[i]);
                break;
            } else if (i >= maxCounter) {
                index = -1;
                break;
            }
        }

        return { index, columns }
    }


    const isDefaultTemplate = (sheetData) => {
        let status = false;
        for (let i = 0; i < MAX_ROW_CHECK; i++) {
            if (sheetData?.[i] && isDefaultTemplateHeaderRow(sheetData[i])) {
                status = true;
                return true;
            }
        }
        return status;
    }



    const getPreviousTemplate = async (result) => {
        let url = `/api/v1/salary-tds-calculator/template-list`
        try {
            const apiResult = await gocallApi("get", url);
            if (apiResult?.data?.length > 0) {
                setHeaderRowIndex(result?.index);
                updateSalaryColumns(result?.columns);
                setPreviousTemplate(apiResult?.data ?? [])
                setShowTemplatePopup(true)
                setTempColumn(result?.columns)
            } else {
                setHeaderRowIndex(result?.index);
                updateSalaryColumns(result?.columns);
                showMappingPage()
                setTemplateName("Template 1")
                setTempColumn(result?.columns)

            }

        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        }
    }


    const uploadFiles = async () => {
        setLoading(true);

        const reader = new FileReader();
        reader.onload = async (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            setSheetName(sheetName);
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            //isDefaultTemplate(sheetData); return false;


            if (sheetName === 'Employee Details' && isDefaultTemplate(sheetData)) {

                const formData = new FormData();
                formData.append('deductor_id', deductorId);
                formData.append('financial_year', financial_year)
                for (let file of files) {
                    formData.append('file', file);
                }
                uploadFormDataWithDefaultTemplate(formData);

            } else {
                const result = valdiateSheetData(sheetData);
                if (result?.index === -1) {
                    setError('Invalid Template');
                } else {
                    setFile(files[0]);
                    getPreviousTemplate(result)

                }

            }
            setLoading(false);
        };

        reader.readAsArrayBuffer(files[0]);

    };

    useEffect(() => {
        const newFiles = acceptedFiles;
        const uniqueFiles = [];
        const flags = [];
        const l = newFiles.length;
        let i;
        for (i = 0; i < l; i++) {
            if (flags[newFiles[i].path]) continue;
            flags[newFiles[i].path] = true;
            uniqueFiles.push(newFiles[i]);

        }

        setFiles(uniqueFiles);
    }, [acceptedFiles]);


    const downloadSalaryExcelTemp = async () => {
        setLoading(true);
        try {
            const result = await downloadDefaultSalaryTemplate();
            const fileData = new Blob([result.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(fileData);
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS_Salary_TDSCalculator_Template.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    const updateColumnData = (item,defaultProcced = false) => {
        if (item?.mapper_json) {
            const mapData = JSON.parse(item?.mapper_json);
            if (mapData?.mapping && mapData?.mapping?.length > 0) {
                setColumnList((prev) => {
                    mapData?.mapping.forEach((element, index) => {
                        if (index < prev?.length) {
                            prev[index] = element;
                        }
                    });
                    return [...prev]

                })
            }
            setTemplateName(item?.template_name ?? "")
            setTemplateId(item?.template_id ?? null)
        }
        showMappingPage(defaultProcced)
    }


    const proceedData = (item) => {
        if (item?.mapper_json) {
            const mapData = JSON.parse(item?.mapper_json);
            if (mapData?.header_row_cols?.length > 0) {
                // We will Check Exact Same array of current column list and column from array list 
                if (JSON.stringify(tempColumn) !== JSON.stringify(mapData?.header_row_cols)) {
                    setError1("The template imported is different from the mapping saved, Please use same template that was used while this mapping was saved or try again by overwrite or start new")
                    return
                }
            }
            updateColumnData(item,true)
        }
    }


    return (
        <>


            {showTemplatePopup ? <WindowWrapperFixed
                title={<kendo-dialog-titlebar>
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className='my-3' style={{ fontSize: "16px" }}>
                                {'Choose Option'}
                            </h4>
                        </div>
                    </div>
                </kendo-dialog-titlebar>}
                onClose={onClose}
                totalPopups={0}
                initialTop={(((window.innerHeight) - 280) / 2)}
                index={1}
                className="Deductee_trans_importmodal"
                initialHeight={'auto'}
                initialWidth={550}
                initialLeft={(((window.innerWidth) - 450) / 2)}
                isModalView={true}
            >

                <div class="modal-body ">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="chhosebtn_overwrite_textfs">
                                You have earlier imported the excel file and mapped with different Salary heads.
                                Those mapping are saved as below:
                            </p>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12 text-right">
                            {previousTemplate?.length > 0 && previousTemplate.map((item, index) =>

                            (<div class="show-mapping_sug pb-2">
                                <div style={{
                                    "width": "350px",
                                    "textAlign": "left",
                                    "whiteSpace": "nowrap",
                                    "overflow": "hidden",
                                    "textOverflow": "ellipsis",
                                    "wordWrap": "break-word",
                                    "paddingRight": "3rem"
                                }}
                                    title={item?.template_name}>
                                    {item?.template_name}
                                </div>
                                <button class="btn btn-default chhosebtn_overwrite_btnbgclr mr-2" onClick={() => {
                                    updateColumnData(item)
                                }}>
                                    Overwrite
                                </button>
                                <button class="btn btn-default chhosebtn_overwrite_btnbgclr" onClick={() => {
                                    proceedData(item)
                                }}>
                                    Proceed
                                </button>
                            </div>
                            ))}
                        </div>

                    </div>
                    {previousTemplate?.length < MAX_SALARY_TDS_CAL_TEMPLATE_COUNT &&
                        <div class="row mt-3">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-default chhosebtn_startsave_btnbgclr" onClick={() => {

                                    showMappingPage()
                                    setTemplateName("Template " + String(previousTemplate?.length + 1))

                                }}>
                                    Start & Save New Mapping
                                </button>
                            </div>
                        </div>}

                </div>


            </WindowWrapperFixed>
                :

               <WindowWrapperFixed
                    title={<kendo-dialog-titlebar>

                        <div className="row">
                            <div className="col-md-6">
                                <h4 className='my-3' style={{ fontSize: "16px" }}>
                                    {'Import Excel File'}
                                </h4>
                            </div>
                        </div>
                    </kendo-dialog-titlebar>}
                    onClose={onClose}
                    totalPopups={0}
                    initialTop={(((window.innerHeight) - 220) / 2)}
                    index={1}
                    className="Deductee_trans_importmodal"
                    //style={{ maxHeight: '580px', overflowX: 'hidden' }}
                    initialHeight={'auto'}
                    initialWidth={450}
                    initialLeft={40} // (window innerwidth) - innerwidth / 2 -------
                    isModalView={true}
                >


                    <div className="modal-body">
                        <div className="row">

                            <div className="col-md-12">
                                <span className="Deductee_trans_importmodal_iconbox pr-2">
                                    <img src={exportIcon} width="18" alt='export icon' />
                                </span>

                                <button style={{ width: 'auto' }} className="btn btn-default balnktemplate_textclr" onClick={() => downloadSalaryExcelTemp('')} title='Download Excel Template'>
                                    Download Excel Template
                                </button>


                            </div>

                            {files?.length <= 0 &&
                                <div className="col-md-12 pt-4">
                                    <span className="Deductee_trans_importmodal_iconbox pr-2">
                                        <img src={importIcon} width="18" alt='import icon' />
                                    </span>
                                    <button className="btn btn-default balnktemplate_dragdropfilebtnbgclr" {...getRootProps({ className: 'fileuppopup_dropzone' })} style={{ paddingLeft: '20px' }}>
                                        <input {...getInputProps({ accept: '.xls,.xlsx,.xlsm', multiple: false })} />
                                        {!isDragActive && 'Drag \'n\' drop files here, or click to select files'}
                                        {isDragActive && !isDragReject && 'Drop files here'}
                                        {isDragReject && 'File type not accepted, sorry!'}
                                    </button>
                                </div>
                            }
                            {files?.length > 0 &&
                                <div className="col-md-12 pt-3">
                                    <span className="Deductee_trans_importmodal_iconbox pr-2">
                                        <img src={excelIcon} width="18" alt='excel icon' />
                                    </span>
                                    <button className="btn btn-default balnktemplate_filenamebtn">
                                        <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-1">

                            </div>
                            <div className="col-md-12 pt-3">
                                <p className="importexcel_textfs_style mb-2">
                                    <span style={{ color: '#fc7d72' }}> Note: </span> Ensure that <b style={{ color: '#fc7d72' }}>Name</b>, <b style={{ color: '#fc7d72' }}>PAN</b>, <b style={{ color: '#fc7d72' }}>Regime</b>, and <b style={{ color: '#fc7d72' }}>Category</b> are mandatory if you are using your own customised template.
                                </p>
                            </div>
                            <div className="col-md-1">

                            </div>


                            <div className="col-lg-12 text-center pt-3">
                                {error
                                    ? (
                                        <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>
                                    )
                                    : null}
                                <button class="btn  btn-default Deductee_trans_importmodal_submitbnt" onClick={() =>
                                    uploadFiles()
                                } disabled={loading || files.length === 0}>
                                    {loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {' '}
                                            Loading...
                                        </>
                                    ) : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </div>


                </WindowWrapperFixed>}
        </>
    )
}


export default ImportExcelFile;