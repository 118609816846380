import React from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';
import { profit_17_3DROPDOWN } from '../salaryHelper';

const ProfitSalary17three = (props) => {
    const { data, headingCodeList17three, handleArrayTypeInputChange, handleArrayNumberInputOnBlur,
        fixedkey17three, deleteIcon, handleArrayObjDel, drowdownList, handleDrpdwn17three,
        USER_TYPE
    } = props;

    return (
        <>
            <tr className="">
                <th width="10" id="accordion" className="accordion">
                    <span className="salarytaxregime_edit_collapse collapsed" data-toggle="collapse" href="#salarytaxregime_edit_collap03" aria-expanded="false"></span>
                </th>
                <th className="text-left">
                    Profit in lieu of Salary  17(3)
                </th>
                <th className="text-right">
                    <BlurOnChangeInput
                        type="text"
                        className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right`}
                        value={FormatNumberDecimal(data.profit_in_lieu_amtp ?? 0, ...Array(3))}
                        data-toggle="tooltip" data-placement="top" title={""}
                        placeholder=""
                        disabled

                    />
                </th>

            </tr>

            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_collap03" className="in collapse">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <thead>
                                    <tr>
                                        <th width="50"></th>
                                        <th width="550" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_bordertop"></th>
                                        <th width="140" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_bordertop"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.profit_in_lieu_breakup_amtp?.length > 0 &&
                                        data?.profit_in_lieu_breakup_amtp.map((item, index) => {
                                            return (
                                                <tr className="salarytaxregime_edit_alternetbgclr_grey" key={index}>
                                                    <td width="50"></td>
                                                    <td width="550" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft">
                                                        {headingCodeList17three?.get(item?.particular_code)?.description ?? ""}
                                                    </td>

                                                    <td width="140" className="salarytaxreg_innertb_bgclr_lightyell  salarytaxreg_innertb_borderright">
                                                        <span className="d-flex">

                                                            <BlurOnChangeInput
                                                                type="text"
                                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                                value={FormatNumberDecimal(item.amount_amtp, ...Array(3))}
                                                                data-toggle="tooltip" data-placement="top" title={""}
                                                                placeholder=""
                                                                onChange={(e) => {
                                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e);
                                                                    handleArrayTypeInputChange("profit_in_lieu_breakup_amtp", index, "amount_amtp", value)
                                                                }}
                                                                onBlur={(e) => handleArrayNumberInputOnBlur("profit_in_lieu_breakup_amtp", index, "amount_amtp", item.amount_amtp)}
                                                            />
                                                            {USER_TYPE === "Details" && <span className="deleicon_pos">
                                                                {!fixedkey17three?.[item.particular_code] &&
                                                                    <img src={deleteIcon} alt="addicon"
                                                                        width="16"
                                                                        onClick={() => {
                                                                            handleArrayObjDel("profit_in_lieu_breakup_amtp", index, "profit_17_3", profit_17_3DROPDOWN, "particular_code");
                                                                        }}
                                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                    />
                                                                }
                                                            </span>}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {drowdownList?.profit_17_3?.length > 0 && USER_TYPE === "Details" ?
                                        <tr className="salarytaxregime_edit_alternetbgclr_white">
                                            <td width=""></td>
                                            <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom align-bottom">
                                                <span className="" style={{ display: "flex" }}>
                                                    <DropDownList
                                                        data={drowdownList?.profit_17_3 ?? []}
                                                        className="header_select headerselect w-100 drpdwnlist_changedesign"
                                                        popupSettings={{ className: 'headerselectoption' }}
                                                        textField="description"
                                                        value={{description: "Add additional detail", particular_code: ""}}
                                                        onChange={(e) => {
                                                            handleDrpdwn17three(e.target.value);
                                                        }}
                                                        placeholder="Add additional detail"
                                                        dataItemKey="particular_code"
                                                        id="yearDropDownPopup"
                                                    />
                                                </span>
                                            </td>

                                            <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom">
                                                <BlurOnChangeInput
                                                    type="text"
                                                    className={`form-control salarytaxregime_edit_inputbox text-right disabled`}
                                                    data-toggle="tooltip" data-placement="top"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </td>


                                        </tr>
                                    :
                                    <tr className="salarytaxregime_edit_alternetbgclr_white">
                                        <td></td>
                                        <td colSpan={2} className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom" ></td>
                                    </tr>    
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </tr>

        </>
    )
}

export default ProfitSalary17three