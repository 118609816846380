import React, { useEffect, useState } from 'react'
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Link, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { BASE_PATH } from '../../app/constants';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import PendingStatusTableRow from './PendingStatusTableRow';
import { getPendingRequestList } from '../../api/deductorAPI';
import ShowMorePagination from '../../components/pagination/ShowMorePagination'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import './pendingRequest.css';


const PendingRequestList = () => {
    const location = useLocation();
    const history = useHistory();
    const params = queryString.parse(location.search);
    const [error, setError] = useState(null);
    const [dataState, setDataState] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterPostData, setFilterPostData] = useState({});
    const [cursor, setCursor] = useState({ current_page: 1, next_page: null });
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [pendingRecords, setPendingRecords] = useState([]);
    const [port, setPort] = useState(0);
    const [success, setSuccess] = useState(null);

    const deductorTypeList = [
        { label: "Central Government", value: "A" },
        { label: "State Government", value: "S" },
        { label: "Statutory body (Central Govt.)", value: "D" },
        { label: "Statutory body (State Govt.)", value: "E" },
        { label: "Autonomous body (Central Govt.)", value: "G" },
        { label: "Autonomous body (State Govt.)", value: "H" },
        { label: "Local Authority (Central Govt.)", value: "L" },
        { label: "Local Authority (State Govt.)", value: "N" },
        { label: "Company", value: "K" },
        { label: "Branch / Division of Company", value: "M" },
        { label: "Association of Person (AOP)", value: "P" },
        { label: "Association of Person (Trust)", value: "T" },
        { label: "Artificial Juridical Person", value: "J" },
        { label: "Body of Individuals", value: "B" },
        { label: "Individual/HUF", value: "Q" },
        { label: "Firm", value: "F" },
    ];




    const fetchPendingRequestData = async (postData) => {
        setShowMoreLoading(true);
        try {
            const result = await getPendingRequestList(postData);
            if (result?.data) {
                if (result.data.cursor.current_page > 1) {
                    setPendingRecords(pendingRecords.concat(result?.data?.list));
                } else {
                    setPendingRecords(result?.data?.list);
                }
            }

            setCursor(result.data.cursor);
            setError(null);
        } catch (err) {
            console.error('error: ', err);
            setError(err.message);
        } finally {
            setShowMoreLoading(false);
        }
    }
    const getParamsHistoryUrl = () => {
        const requestData = {};

        if (params.sort) {
            dataState.sort = JSON.parse(atob(params.sort));
            requestData.sort = JSON.parse(atob(params.sort));
        }

        dataState.filter = { filters: [] };
        if (params.filters) {
            let frs = JSON.parse(atob(params.filters));
            dataState.filter.filters = frs;
            requestData.filters = JSON.parse(atob(params.filters));
        }
        if (params.page) {
            requestData.page = Number(params.page);
        }
        requestData.limit = params.limit ?? 10;
        setDataState(dataState);
        setFilterPostData(requestData);
        return requestData;
    };



    const createHistoryUrl = (dataState = []) => {
        let filterStr = dataState.page ? `?page=${dataState.page}` : `?page=1`;

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
        }
        history.push(filterStr);
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        createHistoryUrl(dataState);
    };


    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };
    const rowRender = (trElement, props) => {
        return <PendingStatusTableRow
            trElement={trElement}
            rowProps={props}
            port={port}
            setPort={setPort}
            getParamsHistoryUrl={getParamsHistoryUrl}
            fetchPendingRequestData={fetchPendingRequestData}
        />
    }

    useEffect(() => {
        async function fetchData() {
            const postData = getParamsHistoryUrl();
            setLoading(true);
            await fetchPendingRequestData(postData);
            setLoading(false);
        }
        fetchData();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />
            <div class="container">
                <div class="row mt-2">
                    <div class="col-xl-5 col-lg-5 col-md-4 col-sm-2 col-xs-12">
                        <Link className="tds_pendingreq_backtextclr" to={`${BASE_PATH}`}>
                            &lt;  Back to List of Deductors
                        </Link>
                    </div>
                    <div class=" col-xl-7 col-lg-7 col-md-8 col-sm-10 col-xs-12">
                        <h4 class="tds_pendingreq_titlefs">Pending Requests</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card pending-request-column">
                            <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <Tooltip anchorElement="pointer" position="top" open={true}>
                                    <Grid
                                        data={pendingRecords}
                                        className={`table table-striped tds_pendingreq_tb mb-0`}
                                        {...dataState}
                                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                                        filterOperators={{
                                            text: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            ],
                                            dropdown: [
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                            ],
                                            numeric: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                            ],
                                            date: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                            ],
                                            textWithEmpty: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                            ],
                                        }}
                                        scrollable
                                        editField="inEdit"
                                        rowRender={rowRender}
                                        sortable
                                    >
                                        <Column
                                            width="200"
                                            field="deductor_name"
                                            filter="text"
                                            headerClassName={isColumnActive('deductor_name') ? 'active' : ''}
                                            title="Deductor Name"
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                        />

                                        <Column
                                            width="150"
                                            field="tan"
                                            filter="text"
                                            title="TAN"
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}


                                        />
                                        <Column
                                            width="150"
                                            field="deductor_type"
                                            filter="dropdown"
                                            title="Deductor Type"
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter filterList={deductorTypeList} {...props} />}

                                        />
                                        <Column
                                            width="150"
                                            field="request_raised_on"
                                            filter="date"
                                            title="Request Raised On"
                                        // columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}

                                        />


                                        <Column
                                            width="150"
                                            title=""
                                            headerCell={() => <></>}
                                            headerClassName="text-center"
                                        />
                                    </Grid>
                                </Tooltip>


                            </div>
                            <div class="row">
                                <div class="col-md-12 text-center py-2">
                                    {cursor?.next_page && (
                                        <tr className="showmoretrbgclr" >
                                            <td colSpan="4" className="text-center">
                                                <ShowMorePagination
                                                    cursor={cursor}
                                                    fetchData={fetchPendingRequestData}
                                                    postData={filterPostData}
                                                    loading={showMoreLoading}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default PendingRequestList