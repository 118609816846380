import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonQrtliability = () => {
  return (
      <table className='m-auto'>
        <tbody>
          <tr>
          <td className="align-bottom">
            <Skeleton animation="wave" variant="rectangular" width={"11px"} height={"200px"} />
            </td><td className="align-bottom">
            <Skeleton animation="wave" variant="rectangular" width={"50px"} height={"50px"} />
            </td><td className="align-bottom">
            <Skeleton animation="wave" variant="rectangular" width={"50px"} height={"80px"} />
            </td><td className="align-bottom">
            <Skeleton animation="wave" variant="rectangular" width={"50px"} height={"100px"} />
            </td><td className="align-bottom">
            <Skeleton animation="wave" variant="rectangular" width={"50px"} height={"120px"} />
            </td><td className="align-bottom">
            <Skeleton animation="wave" variant="rectangular" width={"50px"} height={"150px"} />
            </td>
          </tr>
        </tbody>
      </table>
      
  )
}

export default SkeletonQrtliability