import { useEffect } from 'react';
import { verifyPANandCheckCompliance } from '../../../api/localConnectorAPI';
import { getPanListToVerifyAtTracesAndInsightsViaConnector } from '../../../api/serverConnectorAPI';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../../app/constants';

export default function ExcelBulkPanVerification(props) {

  const { deductorId, port, fileData, captchaRef, setSuccess,
    setError, setLoading, setBulkPanVerificationStatusData, statusKey } = props;

  const fetchTracesCredentials = (deductorId, captchaRef, port) => {

    async function fetchEverything() {
      try {
        setLoading(true);

        const payload = {
          'deductor_id': deductorId,
          'action_type': 'VERIFY_BULK_PAN',
          'traces_captcha_ref': captchaRef
        }
        const result = await getPanListToVerifyAtTracesAndInsightsViaConnector(payload);

        const responseHeader = result?.headers ?? null;

        try {
          setLoading(true);
          const dataKey = result?.data?.data ?? '';
          const formData = new FormData();
          formData.append('pan_file', fileData[0]);
          formData.append('data', dataKey);

          let extraHeader = {};
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          const connectorResponse = await verifyPANandCheckCompliance(port, formData, extraHeader);

          if (Number(connectorResponse.status) === 1) {
            //set local storage key to get PAN verify status data
            const statusData = { 'captcha_ref': captchaRef, 'port': port };
            localStorage.setItem(statusKey, JSON.stringify(statusData));
            setBulkPanVerificationStatusData(statusData);
            setSuccess("Your Bulk PAN Verification process has started successfully. You may monitor the status of the process at the bottom of the screen. Please ensure that the Connector service keeps running, and the system is not shut down.");
          }

        } catch (err) {

          const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default'];
          setError(errmsg);
        }

      } catch (err) {
        console.error('error: ', err);

      } finally {
        setLoading(false);
      }

    }

    fetchEverything();
  }

  useEffect(() => {
    fetchTracesCredentials(deductorId, captchaRef, port, statusKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductorId, captchaRef, port]);

  return (null);

};
