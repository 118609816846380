import React, { useEffect, useState } from "react";
import './NoticeOrdersReport.scss';
import { Link, useLocation } from 'react-router-dom';
import { BASE_PATH } from "../../../app/constants";
import queryString from 'query-string';
import DefaultSummary24Q from "./DefaultSummary24Q";
import DefaultSummary from "./DefaultSummary";
import SpMismatch from "./SpMismatch";
import SdPanErr from "./SdPanErr";
import SdOthers from "./SdOthers";
import Sd197Certificate from "./Sd197Certificate";
import PanError from "./PanError";
import PanErrorAnnex2 from "./PanErrorAnnex2";
import InterestLatePayment from "./InterestLatePayment";
import Interest220 from "./Interest220";
import LateFiling from "./LateFiling";
import IntOnLateDeduction from "./IntOnLateDeduction";
import { viewJustificationReport } from "../../../api/deductorAPI";
import ShowAlert from "../../../components/ShowAlert";
import LoadingIndicator from "../../../components/loadingIndicator/LoadingIndicator";
import $ from 'jquery';
import DefaultSummaryCorrection from "./DefaultSummaryCorrection";
import SdOthersAnnex2 from "./SdOthersAnnex2";
import InsufficientChlnBalance from "./InsufficientChlnBalance";
import SdPe24QReg from "./SdPe24QReg";
import SdPe26Q27EQReg from "./SdPe26Q27EQReg";
import SdPe24QFY24Reg from "./SdPe24QFY24Reg";
import SdPe27QFY24Reg from "./SdPe27QFY24Reg";
import SdPeOthThan24Correction from "./SdPeOthThan24Correction";
import SdPeOthThan24FY24Correction from "./SdPeOthThan24FY24Correction";
import SdPe24QFY23Correction from "./SdPe24QFY23Correction";
import SdPe24QFY24Correction from "./SdPe24QFY24Correction";
import SdPe27qFY24Correction from "./SdPe27qFY24Correction";
import DefaultSummary24Qcorrection from "./DefaultSummary24Qcorrection";


const NoticeOrdersReport = () => {

    const location = useLocation();
    const params = queryString.parse(location.search);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [apiData, setApiData] = useState({});


    const fetchFormTypeData = async () => {
        setLoading(true);

        try {
            const result = await viewJustificationReport(params?.deductor_id, params?.request_no);
            setApiData(result?.data?.data ?? {})

            var tabs = $('.nav-item.nav-link.exptds_justformtype_tab'); // Select all tabs
            var activeTabId = null;

            let arr = Object.keys(result?.data?.data)

            for (var i = 0; i < arr.length; i++) {
                var tab = tabs.eq(i);
                var tabId = tab.attr('id');
                var href = tab.attr('href');

                if (result?.data?.data[tabId]) {
                    activeTabId = tabId;
                    break;
                }
            }

            if (activeTabId) {
                $('#' + activeTabId).addClass('active');
                $('#' + activeTabId).siblings().removeClass('active');
                $(href).addClass('active show');
            }
        } catch (err) {
            console.error("err", err)
            setError(err.message)
        } finally {
            setLoading(false);
        }

    };

    useEffect(() => {
        fetchFormTypeData();
        //eslint-disable-next-line
    }, []);


    return (
        <>

            {loading ? <LoadingIndicator /> : null}

            <ShowAlert
                error={error}
                onClose={() => { setError(null); }}
            />
            <div className="container-fluid">

                <div className="row mt-2">
                    <div className="col-md-5">
                        <Link
                            className="exptds_justformtype_backbtn"
                            to={`${BASE_PATH}notice-orders`}
                        >
                            &lt; Back to Notices & Orders
                        </Link>
                    </div>
                    <div className="col-md-7">
                        <h4 className="exptds_justformtype_titlefs">Justification Report</h4>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <nav className="exptds_justformtype_nav">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">

                                {apiData?.default_summary_24q_correction &&
                                    <a className="nav-item nav-link exptds_justformtype_tab" id="default_summary_24q_correction" data-toggle="tab" href="#exptds_justformtype_tab01_24q" role="tab" aria-controls="nav-home" aria-selected="true">
                                        Default Summary - Correction-24Q
                                    </a>
                                }

                                {apiData?.default_summary_oththn24q_correction &&
                                    <a className="nav-item nav-link exptds_justformtype_tab" id="default_summary_oththn24q_correction" data-toggle="tab" href="#exptds_justformtype_tab01" role="tab" aria-controls="nav-home" aria-selected="true">
                                        Default Summary - Correction-oth
                                    </a>
                                }

                                {apiData?.default_summary_24q &&
                                    <a className="nav-item nav-link exptds_justformtype_tab" id="default_summary_24q" data-toggle="tab" href="#exptds_justformtype_tab02" role="tab" aria-controls="nav-home" aria-selected="true">
                                        Default Summary(24Q)
                                    </a>
                                }

                                {apiData?.default_summary_oththn24q_regular &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="default_summary_oththn24q_regular" data-toggle="tab" href="#exptds_justformtype_tab03" role="tab" aria-controls="nav-home" aria-selected="true">
                                        Default Summary - Reg-oth
                                    </a>
                                }

                                {apiData?.sd_oth_rsns_annex_ii &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sd_oth_rsns_annex_ii" data-toggle="tab" href="#exptds_justformtype_tab04" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SD(OthRsns)-AnnexII
                                    </a>
                                }

                                {apiData?.sp_insuff_chln_bal &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sp_insuff_chln_bal" data-toggle="tab" href="#exptds_justformtype_tab05" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SP(Insuff Challan Bal)
                                    </a>
                                }

                                {apiData?.sp_chln_mismatch &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sp_chln_mismatch" data-toggle="tab" href="#exptds_justformtype_tab06" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SP(ChlnMismatch)
                                    </a>
                                }

                                {apiData?.sd_pan_error &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sd_pan_error" data-toggle="tab" href="#exptds_justformtype_tab07" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE Oththn24Q FY23 REG
                                    </a>
                                }

                                {apiData?.sdpe_24q_fy202223_regular &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_24q_fy202223_regular" data-toggle="tab" href="#exptds_justformtype_tab08" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE 24Q FY23 REG
                                    </a>
                                }

                                {apiData?.sdpe_26q_27eq_fy202324_onwards_regular &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_26q_27eq_fy202324_onwards_regular" data-toggle="tab" href="#exptds_justformtype_tab09" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE 26Q 27EQ FY24 REG
                                    </a>
                                }

                                {apiData?.sdpe_24q_fy202324_onwards_regular &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_24q_fy202324_onwards_regular" data-toggle="tab" href="#exptds_justformtype_tab10" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE 24Q FY24 REG
                                    </a>
                                }

                                {apiData?.sdpe_27q_fy202324_onwards_regular &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_27q_fy202324_onwards_regular" data-toggle="tab" href="#exptds_justformtype_tab11" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE 27Q FY24 REG
                                    </a>
                                }

                                {apiData?.sdpe_oththn24q_fy202223_correction &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_oththn24q_fy202223_correction" data-toggle="tab" href="#exptds_justformtype_tab12" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE Oththn24Q FY23 CORREC
                                    </a>
                                }

                                {apiData?.sdpe_27q_fy202324_onwards_correction &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_27q_fy202324_onwards_correction" data-toggle="tab" href="#exptds_justformtype_tab13_27q" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE 27Q FY24 CORREC
                                    </a>
                                }

                                {apiData?.sdpe_oththn24q_fy202324_onwards_correction &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_oththn24q_fy202324_onwards_correction" data-toggle="tab" href="#exptds_justformtype_tab13" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE Oththn24Q FY24 CORREC
                                    </a>
                                }

                                {apiData?.sdpe_24q_fy202223_correction &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_24q_fy202223_correction" data-toggle="tab" href="#exptds_justformtype_tab14" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE 24Q FY23 CORREC
                                    </a>
                                }

                                {apiData?.sdpe_24q_fy202324_onwards_correction &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sdpe_24q_fy202324_onwards_correction" data-toggle="tab" href="#exptds_justformtype_tab15" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SDPE 24Q FY24 CORREC
                                    </a>
                                }

                                {apiData?.sd_oth_rsns &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sd_oth_rsns" data-toggle="tab" href="#exptds_justformtype_tab16" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SD(OthRsns)
                                    </a>
                                }

                                {apiData?.sd_197_cert &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sd_197_cert" data-toggle="tab" href="#exptds_justformtype_tab17" role="tab" aria-controls="nav-home" aria-selected="true">
                                        SD (197Cert)
                                    </a>
                                }

                                {apiData?.pan_error &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="pan_error" data-toggle="tab" href="#exptds_justformtype_tab18" role="tab" aria-controls="nav-home" aria-selected="true">
                                        PAN Error
                                    </a>
                                }

                                {apiData?.pan_error_annex_ii &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="pan_error_annex_ii" data-toggle="tab" href="#exptds_justformtype_tab19" role="tab" aria-controls="nav-home" aria-selected="true">
                                        PAN Error(Annex II)
                                    </a>
                                }

                                {apiData?.int_on_late_payment &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="int_on_late_payment" data-toggle="tab" href="#exptds_justformtype_tab20" role="tab" aria-controls="nav-home" aria-selected="true">
                                        Int on Late Payment
                                    </a>
                                }

                                {apiData?.sec220_2_interest &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="sec220_2_interest" data-toggle="tab" href="#exptds_justformtype_tab21" role="tab" aria-controls="nav-home" aria-selected="true">
                                        220(2) Interest
                                    </a>
                                }

                                {apiData?.late_filing &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="late_filing" data-toggle="tab" href="#exptds_justformtype_tab22" role="tab" aria-controls="nav-home" aria-selected="true">
                                        Late Filing
                                    </a>
                                }

                                {apiData?.int_on_late_deduction &&
                                    <a className="nav-item nav-link exptds_justformtype_tab exptds_justformtype_borderleftnone" id="int_on_late_deduction" data-toggle="tab" href="#exptds_justformtype_tab23" role="tab" aria-controls="nav-home" aria-selected="true">
                                        Int on Late Deduction
                                    </a>
                                }

                            </div>
                        </nav>
                    </div>
                    <div className="col-md-12">

                        <div className="tab-content" id="nav-tabContent">

                            {apiData?.default_summary_24q_correction &&
                                <DefaultSummary24Qcorrection
                                    apiData={apiData?.default_summary_24q_correction?.summary}
                                />
                            }

                            {apiData?.default_summary_oththn24q_correction &&
                                <DefaultSummaryCorrection
                                    apiData={apiData?.default_summary_oththn24q_correction?.summary}
                                />
                            }


                            {apiData?.default_summary_24q &&
                                <DefaultSummary24Q
                                    apiData={apiData?.default_summary_24q?.summary}
                                />
                            }


                            {apiData?.default_summary_oththn24q_regular &&
                                <DefaultSummary
                                    apiData={apiData?.default_summary_oththn24q_regular?.summary}
                                />
                            }


                            {apiData?.sd_oth_rsns_annex_ii &&
                                <SdOthersAnnex2
                                    apiData={apiData?.sd_oth_rsns_annex_ii?.list}
                                    formType={params?.form_type}
                                />
                            }

                            {apiData?.sp_insuff_chln_bal &&
                                <InsufficientChlnBalance
                                    apiData={apiData?.sp_insuff_chln_bal?.list}
                                />
                            }

                            {apiData?.sp_chln_mismatch &&
                                <SpMismatch
                                    apiData={apiData?.sp_chln_mismatch?.list}
                                />
                            }


                            {apiData?.sd_pan_error &&
                                <SdPanErr
                                    apiData={apiData?.sd_pan_error?.list}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.sdpe_24q_fy202223_regular &&
                                <SdPe24QReg
                                    apiData={apiData?.sdpe_24q_fy202223_regular?.list}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.sdpe_26q_27eq_fy202324_onwards_regular &&
                                <SdPe26Q27EQReg
                                    apiData={apiData?.sdpe_26q_27eq_fy202324_onwards_regular?.list}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.sdpe_24q_fy202324_onwards_regular &&
                                <SdPe24QFY24Reg
                                    apiData={apiData?.sdpe_24q_fy202324_onwards_regular?.list}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.sdpe_27q_fy202324_onwards_regular &&
                                <SdPe27QFY24Reg
                                    apiData={apiData?.sdpe_27q_fy202324_onwards_regular?.list}
                                    formType={params?.form_type}
                                />
                            }

                            {apiData?.sdpe_oththn24q_fy202223_correction &&
                                <SdPeOthThan24Correction
                                    apiData={apiData?.sdpe_oththn24q_fy202223_correction?.list}
                                    formType={params?.form_type}
                                />
                            }

                            {apiData?.sdpe_27q_fy202324_onwards_correction &&
                                <SdPe27qFY24Correction
                                    apiData={apiData?.sdpe_27q_fy202324_onwards_correction?.list}
                                    formType={params?.form_type}
                                />
                            }

                            {apiData?.sdpe_oththn24q_fy202324_onwards_correction &&
                                <SdPeOthThan24FY24Correction
                                    apiData={apiData?.sdpe_oththn24q_fy202324_onwards_correction?.list}
                                    formType={params?.form_type}
                                />
                            }

                            {apiData?.sdpe_24q_fy202223_correction &&
                                <SdPe24QFY23Correction
                                    apiData={apiData?.sdpe_24q_fy202223_correction?.list}
                                    formType={params?.form_type}
                                />
                            }

                            {apiData?.sdpe_24q_fy202324_onwards_correction &&
                                <SdPe24QFY24Correction
                                    apiData={apiData?.sdpe_24q_fy202324_onwards_correction?.list}
                                    formType={params?.form_type}
                                />
                            }

                            {apiData?.sd_oth_rsns &&
                                <SdOthers
                                    apiData={apiData?.sd_oth_rsns?.list}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.sd_197_cert &&
                                <Sd197Certificate
                                    apiData={apiData?.sd_197_cert?.list}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.pan_error &&
                                <PanError
                                    apiData={apiData?.pan_error?.list}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.pan_error_annex_ii &&
                                <PanErrorAnnex2
                                    apiData={apiData?.pan_error_annex_ii?.list}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.int_on_late_payment &&
                                <InterestLatePayment
                                    apiData={apiData?.int_on_late_payment}
                                    formType={params?.form_type}
                                />
                            }


                            {apiData?.sec220_2_interest &&
                                <Interest220
                                    apiData={apiData?.sec220_2_interest}
                                />
                            }


                            {apiData?.late_filing &&
                                <LateFiling
                                    apiData={apiData?.late_filing?.list}
                                />
                            }


                            {apiData?.int_on_late_deduction &&
                                <IntOnLateDeduction
                                    apiData={apiData?.int_on_late_deduction}
                                    formType={params?.form_type}
                                />
                            }

                        </div>


                    </div>
                </div>
            </div>
        </>
    )

};

export default NoticeOrdersReport;