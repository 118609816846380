import { useEffect } from "react";
import {
  CONNECTOR_ERRORS_MAPPING,
  HEADER_CONNECTOR_DIGEST_KEY,
} from "../../app/constants";
import { verifySinglePanToConnector } from "../../api/serverConnectorAPI";
import { verifySinglePanFromConnector } from "../../api/localConnectorAPI";

const SinglePanVerify = (props) => {
  const {
    deductorId,
    port,
    captchaRef,
    setError,
    setVerifyPANResult,
    pan,
    setLoading,
    handleProceed,
    setTracesPanData = null,
    setReloadPanList,
    errorPopup,
    setSuccess,
  } = props;

  useEffect(() => {
    const singlePanVerification = (deductorId, port, captchaRef, pan) => {
      async function fetchEverything() {
        try {
          const singlePanResult = await verifySinglePanToConnector(`?captcha_ref=${captchaRef}&deductor_id=${deductorId ?? ""}&pan=${pan}`);
          const responseData = singlePanResult.data ?? null;
          const responseHeader = singlePanResult.headers ?? null;

          //Connector request data
          const connectorRequest = {
            data: responseData.data,
          };
          let extraHeader = {};
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] =
            responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          try {
            setLoading(true);
            const connectorResponse = await verifySinglePanFromConnector(
              port,
              connectorRequest,
              extraHeader
            );
            if (Number(connectorResponse.status) === 1) {
              setVerifyPANResult(connectorResponse.data.status);

              if (setTracesPanData) {
                setTracesPanData(connectorResponse?.data);
              }

              if (errorPopup) {
                setSuccess("Your PAN has been verified successfully.");
                setTimeout(() => { setReloadPanList(true); }, 3000);
              }
            }
          } catch (err) {
            setError(err?.["message"][0] ?? CONNECTOR_ERRORS_MAPPING["default"]);
          }
        } catch (err) {
          console.error("error: ", err);
          setError(err.message);
        } finally {
          setLoading(false);
          handleProceed();
        }
      }

      fetchEverything();
    };

    singlePanVerification(deductorId, port, captchaRef, pan);

    //eslint-disable-next-line
  }, [deductorId, port, captchaRef, pan]);

  return null;
};

export default SinglePanVerify;
