/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './LateDeductionInterestAmountPopup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  faRupeeSign,
} from '@fortawesome/free-solid-svg-icons';
import { getCorrectionInterestBreakup } from '../../api/interestFeesCalculatorAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

import PostPagination from '../../components/pagination/PostPagination';
import ShowAlert from '../../components/ShowAlert';
import { formatNumber } from '../../utils/UtilityFunctions';

export default function LateDeductionInterestAmountPopup(props) {
  const {
    show, onHide, closeModal, deductorId,
    returnId, lateDeductionInterestAmtp, latePaymentInterestAmtp
  } = props;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lateDeductionDetail, setLateDeductionDetail] = useState([]);
  const [latePaymentDetail, setLatePaymentDetail] = useState([]);
  const [, setTotalInterestAmtpLateDeduction] = useState(0);
  const [, setTotalInterestAmtpLatePayment] = useState(0);
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const [deductionCursor, setDeductionCursor] = useState({ current_page: 1, next_page_url: null });
  const [, setSuccess] = useState(null);

  const getFilterStr = (page, interestType) => {
    const filterStr = `?page=${page}&deductor_id=${deductorId}&return_id=${returnId}&interest_type=${interestType}&limit=5`;
    return filterStr;
  };

  const getInterestBreakupLateDeductionFunc = (page) => {
    const filterStr = getFilterStr(page, 'late_deduction');
    async function fetchEverything() {
      async function fetchInterestBreakupLateDeduction() {
        const result = await getCorrectionInterestBreakup(filterStr);
        const arr = [];
        Object.keys(result.data.data).forEach((key) => {
          arr.push(result.data.data[key]);
        });
        setLateDeductionDetail(arr);

        setDeductionCursor(result.data.cursor);

        setTotalInterestAmtpLateDeduction(result.data.total_interest_amtp);
      }
      try {
        await Promise.all([
          fetchInterestBreakupLateDeduction(),
        ]);
        setError(null);
      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const getInterestBreakupLatePaymentFunc = (page) => {
    const filterStr = getFilterStr(page, 'late_payment');
    async function fetchEverything() {
      async function fetchInterestBreakupLatePayment() {
        const result = await getCorrectionInterestBreakup(filterStr);
        const arr = [];
        Object.keys(result.data.data).forEach((key) => {
          arr.push(result.data.data[key]);
        });
        setLatePaymentDetail(arr);
        setCursor(result.data.cursor);
        setTotalInterestAmtpLatePayment(result.data.total_interest_amtp);
      }
      try {
        await Promise.all([
          fetchInterestBreakupLatePayment(),
        ]);
        setError(null);
      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  useEffect(() => {
    setSuccess(null);
    setError(null);
    if (show) {
      getInterestBreakupLatePaymentFunc(1);
      getInterestBreakupLateDeductionFunc(1);
    }
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      deductor_id={deductorId}
      return_id={returnId}
    >
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert error={error} onClose={() => setError(null)} />
      <Modal.Header className="latefeeheadbgclr">
        <h5 className="modal-title" id="exampleModalLongTitle">Interest Liability (Adjusted + Unadjusted)</h5>
        <button type="button" className="close interestclsbtn" onClick={() => closeModal()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-2">
          {lateDeductionDetail.length > 0 ? (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <h4 className="h4bold">
                Late Deduction Interest Amount
                <span className="float-right" title={formatNumber(lateDeductionInterestAmtp)}>
                  Total =
                  <span className=" fsizeicon">
                    {' '}
                    <FontAwesomeIcon icon={faRupeeSign} />
                    {' '}
                  </span>
                  {' '}
                  {formatNumber(lateDeductionInterestAmtp)}
                </span>
              </h4>

              {lateDeductionDetail.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped tilst2latedptb">
                    <thead>
                      <tr>
                        <th>Deductee Name</th>
                        <th>TDS Deducted</th>
                        <th>Payment Date</th>
                        <th>Deduction Date</th>
                        <th>Default Months</th>
                        <th>Interest Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lateDeductionDetail
                        ? lateDeductionDetail.map((tdsLateDeductionDetail, _key) => (
                          <tr>
                            <td>{tdsLateDeductionDetail.deductee_name}</td>
                            <td className="text-right">
                              {' '}
                              {tdsLateDeductionDetail.total_tds_amtp ? (
                                <span className="pr-1" title={formatNumber(tdsLateDeductionDetail.total_tds_amtp)}>
                                  {' '}
                                  <FontAwesomeIcon icon={faRupeeSign} />
                                  {' '}
                                  {' '}
                                  {formatNumber(tdsLateDeductionDetail.total_tds_amtp)}
                                </span>
                              ) : (
                                <span className="pr-1">
                                  {' - '}
                                </span>
                              )}
                            </td>
                            <td className="text-center">
                              {' '}
                              {tdsLateDeductionDetail.payment_date ? moment(tdsLateDeductionDetail.payment_date).format('DD MMM YYYY') : '-'}
                            </td>
                            <td className="text-center">
                              {' '}
                              {tdsLateDeductionDetail.deduction_date ? moment(tdsLateDeductionDetail.deduction_date).format('DD MMM YYYY') : '-'}
                            </td>
                            <td className="text-center">
                              {tdsLateDeductionDetail.deduction_month_of_default_deduct ? tdsLateDeductionDetail.deduction_month_of_default_deduct : '-'}
                              {' '}
                            </td>
                            <td>
                              {tdsLateDeductionDetail.total_tds_amtp
                                && tdsLateDeductionDetail.deduction_month_of_default_deduct
                                && tdsLateDeductionDetail.interest_rate_b100 ? (
                                <span className="">
                                  {' '}
                                  {`( ${tdsLateDeductionDetail.total_tds_amtp} * ${tdsLateDeductionDetail.deduction_month_of_default_deduct} ) * ${tdsLateDeductionDetail.interest_rate_b100}% = `}
                                  <FontAwesomeIcon icon={faRupeeSign} />
                                  {' '}
                                  {' '}
                                  {' '}

                                </span>
                              ) : ''}
                              {' '}
                              {tdsLateDeductionDetail.interest_amtp ? tdsLateDeductionDetail.interest_amtp : '-'}
                            </td>
                          </tr>
                        )) : null}
                      {lateDeductionDetail && lateDeductionDetail.length === 0 && !loading
                        ? (
                          <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>
                              <span className="dark_color_text">No Records Found!</span>
                            </td>
                          </tr>
                        )
                        : null}
                    </tbody>
                  </table>
                  <PostPagination
                    className="paddbpagi"
                    cursor={deductionCursor}
                    fetchData={getInterestBreakupLateDeductionFunc}
                  />
                </div>
              ) : null}

            </div>
          ) : null}
        </div>
        <div><hr /></div>
        <div className="row mt-2">
          {latePaymentDetail.length > 0 ? (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <h4 className="h4bold">
                Late Payment Interest Amount
                <span className="float-right" title={formatNumber(latePaymentInterestAmtp)}>
                  Total =
                  <span className=" fsizeicon">
                    {' '}
                    <FontAwesomeIcon icon={faRupeeSign} />
                    {' '}
                  </span>
                  {' '}
                  {formatNumber(latePaymentInterestAmtp)}
                </span>
              </h4>
              {latePaymentDetail.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped tilst2latedptb">
                    <thead>
                      <tr>
                        <th>Deductee Name</th>
                        <th>TDS Deducted</th>
                        <th>Deduction Date </th>
                        <th>Deposit Date</th>
                        <th>Due Date</th>
                        <th>Default Months</th>
                        <th>Interest Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latePaymentDetail ? latePaymentDetail.map((tdsLatePaymentDetail) => (
                        <tr>
                          <td>{tdsLatePaymentDetail.deductee_name}</td>
                          <td className="text-right">
                            {' '}
                            {tdsLatePaymentDetail && tdsLatePaymentDetail.total_tds_amtp ? (
                              <span className="pr-1" title={formatNumber(tdsLatePaymentDetail.total_tds_amtp)}>
                                {' '}

                                <FontAwesomeIcon icon={faRupeeSign} />
                                {' '}
                                {' '}
                                {formatNumber(tdsLatePaymentDetail.total_tds_amtp)}

                              </span>
                            ) : '-'}
                          </td>
                          <td className="text-center">
                            {' '}
                            {tdsLatePaymentDetail.deduction_date ? moment(tdsLatePaymentDetail.deduction_date).format('DD MMM YYYY') : '-'}
                          </td>
                          <td className="text-center">
                            {' '}
                            {tdsLatePaymentDetail.deposit_date ? moment(tdsLatePaymentDetail.deposit_date).format('DD MMM YYYY') : '-'}
                          </td>
                          <td className="text-center">
                            {' '}
                            {tdsLatePaymentDetail.deposit_date ? moment(tdsLatePaymentDetail.due_date).format('DD MMM YYYY') : '-'}
                          </td>
                          <td className="text-center">
                            {tdsLatePaymentDetail.payment_month_of_default_deduct ? tdsLatePaymentDetail.payment_month_of_default_deduct : '-'}
                            {' '}
                          </td>
                          <td>
                            {tdsLatePaymentDetail.total_tds_amtp
                              && tdsLatePaymentDetail.payment_month_of_default_deduct
                              && tdsLatePaymentDetail.interest_rate_b100 ? (
                              <span className="">
                                {' '}

                                {`( ${tdsLatePaymentDetail.total_tds_amtp} * ${tdsLatePaymentDetail.payment_month_of_default_deduct} ) * ${tdsLatePaymentDetail.interest_rate_b100}% = `}
                                <FontAwesomeIcon icon={faRupeeSign} />
                                {' '}
                                {' '}
                                {' '}

                              </span>
                            ) : ''}
                            {' '}
                            {tdsLatePaymentDetail.interest_amtp ? tdsLatePaymentDetail.interest_amtp : '-'}
                          </td>

                        </tr>
                      )) : null}
                      {latePaymentDetail && latePaymentDetail.length === 0 && !loading
                        ? (
                          <tr>
                            <td colSpan="7" style={{ textAlign: 'center' }}>
                              <span className="dark_color_text">No Records Found!</span>
                            </td>
                          </tr>
                        )
                        : null}

                    </tbody>
                  </table>
                  <PostPagination
                    className="paddbpagi"
                    cursor={cursor}
                    fetchData={getInterestBreakupLatePaymentFunc}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </Modal.Body>

    </Modal>
  );
}
