/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import { DEDUCTOR_CATEGORIES_LIST } from '../../../app/constants';

// -- Validation Rules
export const deductorFormValidationSchema = Yup.object().shape({

  deductor_name: Yup.string()
  .trim()
  .min(1, 'Too Short!')
  .max(75, 'Too Long!')
  .required('This field is required'),

  //deductor_category: Yup.string().required('Category is required'),
 

  deductor_category: Yup.string().test({
    message: () => 'This field is required',
    test(value) {
      return Object.keys(DEDUCTOR_CATEGORIES_LIST).includes(value);
    },
  }).required('This field is required'),


  pan: Yup.string()
    .required('This field is required')
    .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number'),

  gst_no: Yup.string()
    .matches(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/, { message: 'Invalid GST Number', excludeEmptyString: true }),

  responsible_person_designation: Yup.string()
    .trim()
    .required('This field is required'),


  contact_info_primary_mobile_no: Yup.number()
    .required('This field is required.')
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  contact_info_alternate_mobile_no: Yup.number()
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  contact_info_primary_email: Yup.string()
    .required('This field is required')
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

  contact_info_alternate_email: Yup.string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

  responsible_person_contact_info_person_name: Yup.string()
    .trim()
    .required('This field is required'),

  responsible_person_contact_info_primary_mobile_no: Yup.number()
    .required('This field is required.')
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  responsible_person_contact_info_alternate_mobile_no: Yup.number()
    .min(6000000000, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  responsible_person_contact_info_primary_email: Yup.string()
    .required('This field is required')
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

  responsible_person_contact_info_alternate_email: Yup.string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),


  contact_person_contact_info_person_name: Yup.string()
    .when('is_cp_same_as_rp', {
      is: 'no', // alternatively: (val) => val == true
      then: Yup.string().trim().required('This field is required'),
    }),

  contact_person_contact_info_primary_mobile_no: Yup.number()
    .when('is_cp_same_as_rp', {
      is: 'no', // alternatively: (val) => val == true
      then: Yup.number()
        .required('This field is required')
        .min(6000000000, 'Invalid Mobile Number')
        .max(9999999999, 'Invalid Mobile Number'),
    }),

  contact_person_contact_info_primary_email: Yup.string()
    .when('is_cp_same_as_rp', {
      is: 'no', // alternatively: (val) => val == true
      then: Yup.string().required('This field is required')
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),
    }),

  address_building_no: Yup.string()
    .trim()
    .required('This field is required'),
    
    // address_street_name: Yup.string()
    // .trim()
    // .required('Street Name is required'),

    // address_area: Yup.string()
    // .trim()
    // .required('Area is required'),
    
    
  address_state_id: Yup.string()
    .trim()
    .required('This field is required'),

  address_city: Yup.string()
    .trim()
    .required('This field required'),

  address_pincode: Yup.number()
    .required('This field is required')
    .min(110001, 'Invalid Pincode')
    .max(999999, 'Invalid Pincode'),

  branch: Yup.string().trim().required('This field is required'),

  responsible_person_pan: Yup.string()
    .required('This field is required')
    .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANNOTREQD)$/, 'Invalid PAN Number'),

  responsible_person_address_building_no: Yup.string()
    .trim()
    .required('This field is required'),

  responsible_person_address_city: Yup.string()
    .trim()
    .required('This field is required'),

  responsible_person_address_state_id: Yup.number()
    .required('This field is required'),

  responsible_person_address_pincode: Yup.number()
    .required('This field is required')
    .min(110001, 'Invalid Pincode')
    .max(999999, 'Invalid Pincode'),

});
