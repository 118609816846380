import React, { Component } from 'react';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import CreateDeductorForm from '../../components/createDeductorForm/CreateDeductorForm';
import ShowAlert from '../../components/ShowAlert';
import { editDeductor, getCorrectionDeductorById } from '../../api/deductorAPI';
import { getDeductorObject, getFormObject, deductorFormFields } from '../../components/createDeductorForm/DeductorFormHelper';
import { objectDiff } from '../../utils/ObjectHelper';
import CorrectionHeader from './CorrectionHeader';

export default class EditCorrectionDeductor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deductorInfo: { ...deductorFormFields, is_cp_same_as_rp: 'no' },
      initialData: {},
      deductorId: '',
      returnId:'',
      error: '',
      success:'',
      loading: false,
      dataLoaded: false,
    };
  }

  componentDidMount() {
    var deductorId = this.props.match.params.deductorId;
    var returnId = this.props.match.params.returnId;
    var self = this;
    self.setState({ loading: true });
    async function getDeductor2() {
      const result = await getCorrectionDeductorById(deductorId, returnId);

      var deductorInfo = getFormObject(result.data);
      deductorInfo['deductor_id'] = deductorId;
      deductorInfo['is_cp_same_as_rp'] = 'no';

      self.setState({ loading: false, returnId:returnId, deductorId: result.data.deductor_id, deductorInfo: deductorInfo, initialData: result.data, dataLoaded: true });
    }
    
    getDeductor2();
    
  }

  createDeductor = (values) => {
    var self = this;
    self.setState({ error: '', success: ''});
    var deductorData = getDeductorObject(values);
    console.log("updated data: " , deductorData);
    console.log("initial data: " , this.state.initialData);
    
    var requestData = objectDiff(this.state.initialData, deductorData);
    

    if (requestData.hasOwnProperty('ref_code')) delete requestData['ref_code'];
    if (requestData['contact_info']) requestData['contact_info'] = deductorData['contact_info'];
    if (requestData['responsible_person']) requestData['responsible_person'] = deductorData['responsible_person'];
    if (requestData['contact_person']) requestData['contact_person'] = deductorData['contact_person'];
    if (requestData['government_deductor']) requestData['government_deductor'] = deductorData['government_deductor'];

    requestData['deductor_id'] = this.state.deductorId;
    requestData['return_id'] = this.state.returnId;
    console.log("requestdata: ", requestData);

    async function callAPI() {
      async function editDeductor2() {
        const result = await editDeductor(requestData);
        //console.log('result: ', result);
        return result;
      }
      try {
        const result = await editDeductor2();
        self.setState({ error: '', success: result.message});
        setTimeout(() => {
          self.props.history.go();
      }, 3000);
      } catch (err) {
        console.error('error: ', err);
        self.setState({ error: err.toString(), success: ''});
      } finally {
        self.setState({ loading: false });
      }
    }
    self.setState({ loading: true });
    callAPI();
  };

  render() {
    var self = this;
    const { deductorInfo, deductorId, success, error, loading, dataLoaded, returnId, initialData } = this.state;
    return (
      <div>
        {loading ? <LoadingIndicator /> : null}
        
        <CorrectionHeader 
          pageName="EditCorrectionDeductor"
          deductorId={deductorId} 
          returnId={returnId}
        />
        <ShowAlert 
          success={success} 
          error={error} 
          onClose={() => {
            self.setState({ success: null, error: null });
          }}
        />


        
        {dataLoaded ? <CreateDeductorForm
          deductorInfo={deductorInfo}
          deductorId={deductorId}
          createDeductor={this.createDeductor}
          returnType="correction"
          returnId={returnId}
          initialData={initialData}
        /> : null}
      </div>
    );
  }
}
