import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDeductorTracesCredentials } from '../../api/deductorAPI';
import './KDKConnector.css';
import { selectHeaderData } from '../../features/header/headerSlice';
import ShowAlert from '../ShowAlert';
import UpdateTracesCredential from '../verifyPAN/UpdateTracesCredential';
import VerifyPANCloud from '../verifyPAN/VerifyPANCloud';
import VerifyPanCloudV2 from '../verifyPAN/VerifyPanCloudV2';
//import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
//import { useLocation } from 'react-router-dom';
//import { BASE_PATH } from '../../app/constants';

export default function TracesConnector({ ...props }) {

  const { handleProceed, deductorId, returnId, port, setCaptchaRef, creds = null, setVerifyCreds, dontLogin = false, callCaptchaRef = false, tan = null,
    captchaRef, captchaAlready, captcha_text, getCaptchaPortal, setCaptchaError, setCaptchaLoading, latestSyncStatus } = props;

  const headerDataDetails = useSelector(selectHeaderData);
  const [error, setError] = useState(false);
  const [traceTan, setTraceTan] = useState('');
  const [tracesUser, setTracesUser] = useState('');
  const [tracesPassword, setTracesPassword] = useState('');
  const [showTraceValidationFailPopup, setShowTraceValidationFailPopup] = useState(false);
  const [showTracesCaptcha, setShowTracesCaptcha] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [checkTraces, setCheckTraces] = useState("");
  //const history = useHistory();
  //const location = useLocation();
  //const pathName = location.pathname;
  //const DASHBOARD_PATH= `${BASE_PATH}deductor`;


  useEffect(() => {
    const fetchTracesCredentials = (deductorId) => {

      async function fetchEverything() {
        try {
          // Dont update Header, as it is already updated by rest of the API. This component is supposed 
          // to work for both Regular and Correction return, and hence header update will mess display for Correction.
          const result = await getDeductorTracesCredentials(`?deductor_id=${deductorId}`, false);
          if (tan) setTraceTan(tan);
          else {
            setTraceTan(headerDataDetails.tan);

          }
          setTracesUser(result.data.traces_username);
          setTracesPassword(result.data.traces_password);
          if (captchaAlready) {
            setCheckTraces(true);
          } else setShowTracesCaptcha(true);

        } catch (err) {
          console.error('error: ', err);
          if (err.code === 404) {
            setShowTraceValidationFailPopup(true);
          } else {
            setError(err.message);
          }
        } finally {
          //handleClose();
        }
      }

      fetchEverything();
    };

    if (port && creds === null && (headerDataDetails.tan !== '' || tan)) {
      fetchTracesCredentials(deductorId);
    }
    else {
      setTraceTan(creds?.tan)
      setTracesUser(creds?.traces_username);
      setTracesPassword(creds?.traces_password);
      setShowTracesCaptcha(true);
      setResetCaptcha(true);
    }


  }, [deductorId, port, creds, headerDataDetails, tan, captchaAlready]);

  return (
    <>

      {error &&
        <ShowAlert
          error={error}
          onClose={() => setError(null)}
        />
      }

      {showTraceValidationFailPopup &&
        <UpdateTracesCredential
          show={showTraceValidationFailPopup}
          handleClose={(res) => {
          
            setError(null);
            setShowTraceValidationFailPopup(false);
            handleProceed(res);
            resetCaptcha && setCaptchaRef(null);
          }}
          deductorId={deductorId}
          returnId={returnId}
          tan={traceTan}
          tracesUser={tracesUser}
          tracesPassword={tracesPassword}
          handleProceed={handleProceed}
          showKDKConnector={() => { }}
          port={port}
          setCaptchaRef={setCaptchaRef}
          setVerifyCreds={setVerifyCreds}
          latestSyncStatus={latestSyncStatus}

        />
      }

      {showTracesCaptcha &&
        <VerifyPANCloud
          show={showTracesCaptcha}
          handleClose={(res) => {

            setError(null);
            setShowTracesCaptcha(false);
            handleProceed(res);
            //resetCaptcha && setCaptchaRef(null);
          }}
          deductorId={deductorId}
          setVerifyCreds={setVerifyCreds}
          returnId={returnId}
          tan={traceTan}
          tracesUser={tracesUser}
          tracesPassword={tracesPassword}
          handleProceed={handleProceed}
          port={port}
          setShowTraceValidationFailPopup={setShowTraceValidationFailPopup}
          setShowTracesCaptcha={setShowTracesCaptcha}
          setCaptchaRef={setCaptchaRef}
          dontLogin={dontLogin}
          callCaptchaRef={callCaptchaRef}
          latestSyncStatus={latestSyncStatus}
        />
      }

      {checkTraces &&
        <VerifyPanCloudV2
          handleClose={(res) => {
            setError(null);
            setShowTracesCaptcha(false);
            handleProceed(res);
          }}
          handleProceed={handleProceed}
          tan={traceTan}
          port={port}
          tracesPassword={tracesPassword}
          tracesUser={tracesUser}
          captcha_text={captcha_text}
          captcha_ref={captchaRef}
          getCaptchaPortal={getCaptchaPortal}
          setShowTraceValidationFailPopup={setShowTraceValidationFailPopup}
          setCheckTraces={setCheckTraces}
          setVerifyCreds={setVerifyCreds}
          setCaptchaError={setCaptchaError}
          setCaptchaLoading={setCaptchaLoading}
        />
      }

    </>

  )
}
