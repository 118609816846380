import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './NatureOfPaymentSummaryPopup.css';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { getCorrectionNatureOfPaymentSummary } from '../../api/returnAPI';
import { formatNumber } from '../../utils/UtilityFunctions';

export default function NatureOfPaymentSummaryPopup(props) {
  const {
    show, onHide, closeModal, deductorId, returnId, headerDataDetails,
  } = props;

  const [loading, setLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [totalTransactionAmtp, setTotalTransactionAmtp] = useState(0);
  const [totalPaidAmtp, setTotalPaidAmtp] = useState(0);
  const [totalTDSAmtp, setTotalTDSAmtp] = useState(0);
  const [totalUnadjustedAmtp, setTotalUnadjustedAmtp] = useState(0);

  const getPaymentSummaryList = (dedId, rtnId) => {
    async function fetchEverything() {
      async function fetchPaymentSummaryList() {
        const result = await getCorrectionNatureOfPaymentSummary(`?deductor_id=${dedId}&return_id=${rtnId}&limit=20`)
        setPaymentDetails(result.data);
        let totalTransactionAmtpVal = 0;
        let totalPaidAmtpVal = '0';
        let totalTDSAmtpVal = '0';
        let totalUnadjustedAmtpVal = '0';
        let data = result.data;
        data.map((paymentVal) => {
          totalTransactionAmtpVal = Number(totalTransactionAmtpVal) + Number(paymentVal.total_deductee_transactions);
          totalPaidAmtpVal = Number(totalPaidAmtpVal) + Number(paymentVal.total_paid_amtp);
          totalTDSAmtpVal = Number(totalTDSAmtpVal) + Number(paymentVal.sum_total_tds_amtp);
          totalUnadjustedAmtpVal = Number(totalUnadjustedAmtpVal) + Number(paymentVal.total_unadjusted_amtp);
          return true;
        });

        setTotalTransactionAmtp(totalTransactionAmtpVal);
        setTotalPaidAmtp(totalPaidAmtpVal);
        setTotalTDSAmtp(totalTDSAmtpVal);
        setTotalUnadjustedAmtp(totalUnadjustedAmtpVal);
      }
      try {
        await Promise.all([
          fetchPaymentSummaryList(),
        ]);
      } catch (err) {
        // console.error('error: ', err);
      } finally {
        setLoading(false);
      }
    }
    fetchEverything();
  };

  useEffect(() => {
    getPaymentSummaryList(deductorId, returnId);
  }, [deductorId, returnId]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
    >
      <Modal.Header className="naturpaywsheader">
        <h5 className="modal-title" id="exampleModalLongTitle"> {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Nature of Collection Wise Summary' : 'Nature of payment-wise summary'}</h5>
        <button type="button" className="close natureofpay" onClick={() => closeModal()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Tooltip anchorElement="pointer" position="right">
          <table className="kdk_data_table table table-striped table-responsive">
            <thead>
              <tr>
                <th width="120" scope="col" style={{ width: '260px' }}>{(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Code' : 'Nature of payment'}</th>
                <th width="80" scope="col">Rate</th>
                <th width="100" scope="col">Transaction</th>
                <th width="100" scope="col">{(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Amt Received' : 'Amt Paid'}</th>
                <th width="180" scope="col" className="text-right">{(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Total Tax Collected' : 'Total Tax Deducted'}</th>
                <th width="150" scope="col">{(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Unmapped Amt' : 'Unmapped Amt'}</th>
              </tr>
            </thead>
            <tbody>

              {
                paymentDetails.length > 0
                  ? paymentDetails.map((paymentDetail) => (

                    <tr>

                      <td className="overflowtextnatureofcoll">
                        <span data-toggle="tooltip" data-placement="right" title={paymentDetail.section_code}>
                          {paymentDetail.section_code}
                        </span>
                      </td>

                      <td>
                        {paymentDetail.tds_rate_b100 ? `${paymentDetail.tds_rate_b100}%` : 'NA'}
                      </td>
                      <td>{paymentDetail.total_deductee_transactions}</td>
                      <td className="text-right overflowtextnatureofcoll">
                        ₹
                        {' '}
                        <span data-toggle="tooltip" data-placement="top" title={formatNumber(paymentDetail.total_paid_amtp)}>
                          {formatNumber(paymentDetail.total_paid_amtp)}
                        </span>
                      </td>
                      <td className="text-right overflowtextnatureofcoll">
                        ₹
                        {' '}
                        <span data-toggle="tooltip" data-placement="top" title={formatNumber(paymentDetail.sum_total_tds_amtp)}>
                          {formatNumber(paymentDetail.sum_total_tds_amtp)}
                        </span>
                      </td>
                      <td className="text-right overflowtextnatureofcoll">
                        ₹
                        {' '}
                        <span data-toggle="tooltip" data-placement="top" title={formatNumber(paymentDetail.total_unadjusted_amtp)}>
                          {formatNumber(paymentDetail.total_unadjusted_amtp)}
                        </span>
                      </td>

                    </tr>

                  )) : null
              }
              {paymentDetails.length === 0 && !loading
                ? (
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'center' }}>
                      <span className="dark_color_text">No Records Found!</span>
                    </td>
                  </tr>
                )
                : null}
              {
                paymentDetails.length > 0 ? (

                  <tr style={{ backgroundColor: '#fff', color: '#000', fontSize: '14px' }}>
                    <td />
                    <td style={{ fontSize: '14px' }}>Total</td>
                    <td className="overflowtextnatureofcoll" style={{ fontSize: '14px' }}>
                      {totalTransactionAmtp}
                    </td>
                    <td className="overflowtextnatureofcoll" style={{ fontSize: '14px', textAlign: 'right' }}>
                      ₹
                      {' '}
                      <span data-toggle="tooltip" data-placement="top" title={formatNumber(totalPaidAmtp)}>
                        {formatNumber(totalPaidAmtp)}
                      </span>
                    </td>
                    <td className="overflowtextnatureofcoll" style={{ fontSize: '14px', textAlign: 'right' }}>
                      ₹
                      {' '}
                      <span data-toggle="tooltip" data-placement="top" title={formatNumber(totalTDSAmtp)}>
                        {formatNumber(totalTDSAmtp)}
                      </span>
                    </td>
                    <td className="overflowtextnatureofcoll" style={{ fontSize: '14px', textAlign: 'right' }}>
                      ₹
                      {' '}
                      <span data-toggle="tooltip" data-placement="top" title={formatNumber(totalUnadjustedAmtp)}>
                        {formatNumber(totalUnadjustedAmtp)}
                      </span>
                    </td>
                  </tr>
                ) : null
              }

            </tbody>
          </table>
        </Tooltip>
      </Modal.Body>

    </Modal>
  );
}
