/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Verify from './Verify';
import ResetPass from './ResetPass';
import NumericInput from '../../components/NumericInput';
import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import { BASE_PATH } from '../../app/constants';
import { sendOTPForForgotPassword } from '../../api/authenticationAPI';
import ShowAlert from '../../components/ShowAlert';
import './LoginScreen.css';
import { updateAuthToken } from '../../utils/AuthToken';

const ForgetPass = ({ history }) => {
  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isOTPSent, setIsOTPSent] = useState(false);

  const [mobileNo, setMobileNo] = useState('');
  const [userId, setUserId] = useState('');
  const [otpVerificationId, setOTPVerificationId] = useState('');

  const [isOTPVerified, setIsOTPVerified] = useState(false);

  const [formError, setFormError] = useState('');

  const sendOTP = async (e) => {
    e.preventDefault();
    if (mobileNo.trim().length === 0) {
      setFormError('This field is required');
      return
    } else if (mobileNo < 6000000000 || mobileNo > 9999999999) {
      setFormError('Invalid Mobile Number');
      return;
    }

    if (formError !== '') return;

    const data = {
      country_code: '+91',
      mobile_no: mobileNo,
    };
    setLoading(true);
    try {
      const result = await sendOTPForForgotPassword(data);
      setIsOTPSent(true);
      setOTPVerificationId(result.data.otp_verification_id);
      setUserId(result.data.user_id);
      setSuccess(result?.message);
    } catch (err) {
      setError(err.message);
      console.error('Error in sending otp: ', err);
    }
    setLoading(false);
  };

  const handleProceed = (result) => {
    updateAuthToken(result.data.token);
    setTimeout(() => {
      history.push(BASE_PATH);
    }, 3000);

  };

  useEffect(() => {
    if (params.mobile_no) {
      setMobileNo(params.mobile_no);
    }
  }, [searchStr]);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('error')) {
      setError(params?.error);
      queryParams.delete('error');
      history.replace({
        search: queryParams.toString(),
      });
    }

  }, [params?.error])

  return (
    <div>
      <ShowAlert
        error={error}
        success={success}
        //autoHideError={false}
        onClose={() => { setError(null); setSuccess(null) }}
      />

      {isOTPVerified ? (
        <ResetPass
          userId={userId}
          otpVerificationId={otpVerificationId}
          mobileNo={mobileNo}
          handleProceed={handleProceed}
          setError={setError}
          setSuccess={setSuccess}
        />
      ) : (
        <>
          {isOTPSent ? (
            <Verify
              mobile_no={mobileNo}
              otp_verification_id={otpVerificationId}
              handleProceed={(otpId) => {
                setOTPVerificationId(otpId);
                setIsOTPVerified(true);
              }}
              resendOTP={(e) => sendOTP(e)}
              setError={setError}
              setSuccess={setSuccess}
            />
          ) : (
            <div className="container clear-padd ma-0 position-center ng-scope bgclretds mt-5">

              <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
              <div className="col-xs-8 col-lg-8 col-md-12 col-sm-12 login-container mt-28 vertical-center">
                <div className="row content content-sm justify-content-center">
                  <h3 className="bold col-md-offset-4 form-heading pt-35">Forgot Password</h3>
                  <div>
                    <div className="col-sm-12 col-xs-12 col-sm-12 col-md-12 removePadding formDiv">
                      <form className="form-horizontal logforms" onSubmit={(e) => sendOTP(e)} autoComplete="off">
                        <div className="form-group">
                          <div className="col-xs-8 col-sm-10 col-md-10 col-md-offset-2 ">
                            <p className="justify-content-center ml-5 textsizfp">
                              Please enter your registered Mobile number in which
                              you will be sent an OTP to verify yourself
                            </p>
                          </div>
                        </div>
                        <div className="form-group form-group2">
                          <div className="col-xs-10 col-sm-10 col-md-10 col-md-offset-3">

                            <div className="input-group mb-1">
                              <div className="input-group-prepend inpgpbx tdsforget_iconpadd">
                                <img className="" src={Indiaflagexp} alt="mobile" style={{ objectFit: 'scale-down'}}/>
                              </div>
                              <NumericInput
                                type="tel"
                                className="form-control lg-input inputplace inputplace1"
                                name="mobile"
                                placeholder="Enter Mobile Number"
                                required=""
                                maxLength={10}
                                value={mobileNo}
                                onChange={(e) => {
                                  setFormError('');
                                  setMobileNo(e.target.value)
                                }}
                              />
                            </div>
                            {formError !== '' ? <span className="signup_error_msg">{formError}</span> : null}
                          </div>
                        </div>

                        <div className="row signup-form-input-box">
                          <div className="col-xl-12 text-center">
                            <Button
                              type="submit"
                              bsPrefix="btn red-btn semi-bold btnFont forpassbtnclr mr-2"
                              name="Sign Up"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  {' '}
                                  Loading...
                                </>
                              ) : 'SEND'}
                            </Button>

                            <Link to={`${BASE_PATH}login`} style={{ color: '#3e3b3b' }}>
                              <Button type="buton" bsPrefix="btn cancel-button btnclboxm" value="CANCEL">
                                CANCEL
                              </Button>
                            </Link>

                          </div>
                          
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div classNameName="col-sm-2 login-empty-container" />

            </div>
          )}
        </>
      )}
    </div>

  );
};

export default ForgetPass;
