/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
// import FileUploadPopup from './FileUploadPopup';
import FileUploadPopupV2 from './FileUploadPopupV2';

const ImportConsolidateFile = (props) => {
  const {
    show, handleClose, deductorId, returnId, handleSuccessProceed, headerDataDetails, handleProceed
  } = props;
  const [showFileUpload, setShowFileUpload] = useState(true);
  // const [showResult, setShowResult] = useState(false);
  // const [result, setResult] = useState({ data: [] });

  useEffect(() => {
    setShowFileUpload(true);
  }, [show]);

  if (show) {
    return (
      <>
        {showFileUpload && (
          <FileUploadPopupV2          //Now the version 2 component has been called due to ui chanegs
            key="1"
            handleClose={() => {
              handleClose();
            }}
            handleProceed={(result) => {
              handleProceed()
              setShowFileUpload(false);
              handleSuccessProceed(result);

            }}
            deductorId={deductorId}
            returnId={returnId}
            headerDataDetails={headerDataDetails}
          />
        )}
      </>
    );
  }

  return null;
};

export default ImportConsolidateFile;
