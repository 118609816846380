import * as React from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";
import $ from 'jquery';

export class ComplianceFilterUI extends React.Component {

    state = {
        filterList: this.props.filterList ? this.props.filterList : [],
    };

    componentDidMount = () => {
        $(document).on('keydown', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                $("form.k-filter-menu button.k-primary").click();
            }
        });
    }

    handleFirstValueChange = (event) => {
        const { firstFilterProps } = this.props;
        let value = event.value;
        firstFilterProps.onChange({
            value,
            operator: firstFilterProps.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleFirstValueChangeN = (event) => {
        let value = event.value.value ? event.value.value : event.value;
        if (Array.isArray(event.value)) {
            value = [];
            event.value.map((event_val, index) => {
                value.push(event_val.value);
                return true;
            })
            value = value.length > 0 ? value : '';
        }

        const { firstFilterProps } = this.props;
        firstFilterProps.onChange({
            value,
            operator: firstFilterProps.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleFirstOperatorChange = (event) => {
        const { firstFilterProps } = this.props;
        const value = event.value;
        let emptyVal = (value.operator === 'contains') ? '' : 'empty';
        firstFilterProps.onChange({
            value: emptyVal,
            operator: value.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    handleSecondOperatorChange = (event) => {
        const value = event.value
        const { secondFilterProps } = this.props;
        secondFilterProps.onChange({
            value: secondFilterProps.value,
            operator: value.operator,
            syntheticEvent: event.syntheticEvent
        });
    }

    filterChange = (event) => {
        this.setState({
            filterList: filterBy(this.props.filterList.slice(), event.filter),
        });
    };

    itemRender = (li, itemProps) => {
        const itemChildren = (
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'revert' }} title={itemProps.dataItem.label}>
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    render() {
        
        let { column, firstFilterProps } = this.props;

        let firstOperator = firstFilterProps.operators.filter((operator) => {
            if(operator.text === "Is empty"){
                operator.text = "UNVERIFIED";
            } 
             return operator.operator === firstFilterProps.operator 
        });

        let firstDropdownProps = [];
        if (column && firstFilterProps.value) {
            firstDropdownProps = this.state.filterList.filter((data, index) => { return firstFilterProps.value.includes(data.value); });
        }

        return (
            <div className="filter_ui">
                <DropDownList
                    data={firstFilterProps.operators}
                    textField="text"
                    dataItemKey="operator"
                    value={firstOperator[0]}
                    className="filter_ui_select"
                    popupSettings={{ className: 'filter_ui_option' }}
                    onChange={this.handleFirstOperatorChange}
                />

              
                {firstOperator[0].operator === 'in' &&
                    <MultiSelect data={this.state.filterList} textField="label" value={firstDropdownProps} dataItemKey="value" onChange={this.handleFirstValueChangeN} filterable={true} className="filter_ui_select" popupSettings={{ className: 'filter_ui_option' }} onFilterChange={this.filterChange} itemRender={this.itemRender} />
                }

            </div>
        );

    }
}