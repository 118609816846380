import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import blueCheckIcon from "../../../images/bluecheckiocn.png";
import disCheckIcon from "../../../images/disavle_pluscheck.png";
import "./ModalPopup.scss";
import { gocallApi } from '../../../api/issueCertificateAPI';
import { Alert } from 'react-bootstrap';
import moment from 'moment';
import ConfirmPopupNew from '../../../components/ConfirmPopupNew';


const AlreadyFileListPopup = (props) => {
    const { handleClose, data, returnPayload, successProced } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [selectedReturn, setSelectedReturn] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(null);

    const updateReturnOnServer = async (ackNo, filingDate) => {

        setLoading(true);
        const url = `/api/v1/return/correction/mark-done`;
        const payload = {
            deductor_id: returnPayload.deductor_id ?? "",
            return_id: returnPayload.return_id ?? "",
            ack_no: ackNo ?? "",
            filing_date: filingDate ?? ""
        }

        try {
            const result = await gocallApi("put", url, payload);
            if (result.data) {
                handleClose();
                successProced();
            }


        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);

        } finally {
            setLoading(false);
        }
    }



    return (
        <>

            {loading && <LoadingIndicator />}

            {confirmPopup &&
                <ConfirmPopupNew
                    show={confirmPopup}
                    handleClose={() => {
                        setConfirmPopup(null);
                    }}
                    confirmText={confirmPopup.message ?? ""}
                    confirmButtonText={"Ok"}
                    confirmButtonText2={"Cancel"}
                    handleProceed={() => {
                        updateReturnOnServer(confirmPopup?.dataItem?.ack_no, confirmPopup?.dataItem?.filing_date);
                        setSelectedReturn(confirmPopup?.index);
                    }}
                    handleProceed2={() => {
                        setConfirmPopup(null);
                    }}
                />
            }

            <Modal className="Createnewreturn_Q2browser_popup show" backdrop="static" show={true} onHide={handleClose}>
                <Modal.Header closeButton className="Createnewreturn_Q2browser_header">
                    <Modal.Title className="" >Already Filed, Map now</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "1rem" }}>
                    {error
                        ? (
                            <Alert variant="danger" style={{ fontSize: "12px" }} onClose={() => setError('')} dismissible>{error}</Alert>
                        )
                        : null}
                    {success
                        ? (
                            <Alert variant="success" style={{ fontSize: "12px" }} onClose={() => setSuccess('')}>{success}</Alert>
                        )
                        : null}
                    <div class="row">
                        <div class="col-lg-12">
                            <p class="fileselectedtextfs mb-2">
                                {`Choose the already filed return for Form ${returnPayload?.form_type ?? ""} | ${returnPayload?.quarter ?? ""}, so that new correction return can be created`}`
                            </p>

                            <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" style={{ maxHeight: "285px" }}>
                                <table class="table table-striped alreadyfiled_table mb-0">
                                    <thead>
                                        <tr>
                                            <th>
                                                Filing Date
                                            </th>
                                            <th>
                                                Ack. No.
                                            </th>
                                            <th>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? data.map((item, index) => {
                                            return (
                                                <tr class={`${(selectedReturn === index || (item.return_id && item.efiling_status)) ? "textrow_clorgrey " : "cursorpoint"}`}
                                                    onClick={() => {

                                                        if (!item.return_id && item.efiling_status) {
                                                            if(returnPayload.return_id && returnPayload.ack_no && returnPayload.efiling_status && returnPayload.filing_date){
                                                                const Obj = {
                                                                    message: `You have already mapped this return to Ack no ${returnPayload.ack_no ?? "<ack no>"}, do you want to change the selected return?`,
                                                                    dataItem: item,
                                                                    index: index
                                                                 }
                                                                 setConfirmPopup(Obj)
                                                            }else if(returnPayload.return_id && !returnPayload.ack_no && !returnPayload.efiling_status && !returnPayload.filing_date){
                                                                const Obj = {
                                                                    message: `Are you sure you want to map the selected details for this return?`,
                                                                    dataItem: item,
                                                                    index: index
                                                                 }
                                                                 setConfirmPopup(Obj)
                                                            }
                                                          
                                                        }

                                                    }}
                                                >
                                                    <td class="text-center">
                                                        {item?.filing_date ? moment(item?.filing_date).format("DD/MM/YYYY") : ""}
                                                    </td>
                                                    <td class="text-center">
                                                        {item.ack_no ?? ""}
                                                    </td>
                                                    <td class="text-center">
                                                        <img src={selectedReturn === index ? disCheckIcon : blueCheckIcon} alt="check Icon" width="16" />
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td colSpan={3} class="text-center">No Record Found</td></tr>}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default AlreadyFileListPopup