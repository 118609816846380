import React, { useEffect, useState } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone, formatAccountsNumber } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";
import moment from "moment";



const SpMismatch = (props) => {

    const { apiData } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);

    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    s_no: item[0],
                    cd_no: item[1],
                    tax_dep: item[2],
                    bsr_code: item[3],
                    tax_date: moment(item[4], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ssZ'),
                    ch_serial_no: item[5],
                    book_entry: item[6],
                    short_payment: item[7],
                })
            })
            return newData
        }
    };

    const dataStateChange = (data_state) => {
        if (data_state.filter || (data_state.sort && data_state?.sort?.length > 0)) {
            const filter_state = deepClone(data_state);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                             if (["gte", "lte", 'lt', 'gt', "neq", "eq"].includes(itemObj?.operator) && !Array.isArray(itemObj.value)) {
                                
                                if(itemObj.field === "tax_date"){
                                    const date = moment(itemObj.value).format('DD/MM/YYYY');
                                    itemObj.value = moment(date, 'DD/MM/YYYY').format();
                                }else{
                                    itemObj.value = Number(itemObj.value);

                                }
                            }    
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                if(itemObj.field !== "tax_date"){                           
                                    itemObj.value = itemObj.value?.[0] ?? ""; 
                                }
                                                              
                            }else  if (["in"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                let array = []
                                item.logic = "or"
                                if (itemObj?.value?.length > 0) {
                                    itemObj?.value.forEach((keyValue) => {
                                        if(keyValue === 'NA') {
                                            array.push({ field: itemObj.field, operator: 'isNull', value: null })
                                        }else{
                                            array.push({ field: itemObj.field, operator: 'eq', value: Number(keyValue) })
                                        }                                        
                                    })
                                    item.filters = array;
                                }
                            }                            
                        })
                    }
                })
            }
            const dataProcess = process(inititalData, filter_state);
            setDataState({ ...data_state });
            setData(dataProcess.data ?? []);

        } else {
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData([...defaultData]);
            setDataState({});
        }     

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const calculateTotal = (index) => {
        let total = 0
        data.length > 0 && data.forEach((item) => {
            switch (index) {               
                case "7":
                    total += item?.short_payment;
                    break;
                default:
                    total = 0
            }

        })
        return formatAccountsNumber(total)
    };

    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;
        const dataItem = props?.dataItem

        return (
            <>
                <tr key={key} role="row">
                    <td class="text-center">{dataItem?.s_no}</td>
                    <td class="text-center">{dataItem?.cd_no ?? "-"}</td>
                    <td class="text-right">{formatAccountsNumber(dataItem?.tax_dep ?? 0) ?? "-"}</td>
                    <td class="text-center">{dataItem?.bsr_code ?? "-"}</td>
                    <td className="text-center">{moment(dataItem?.tax_date).format("DD/MM/YYYY")=== "Invalid date" ? "Not Available" : moment(dataItem?.tax_date).format("DD/MM/YYYY") ?? "-"}</td>
                    <td class="text-center">{dataItem?.ch_serial_no ?? "-"}</td>
                    <td class="text-center">{dataItem?.book_entry ?? "-"}</td>
                    <td class="text-right notices_orders_rowbgclr_yell">{formatAccountsNumber(dataItem?.short_payment ?? 0) ?? "-"}</td>
                </tr>

                {key + 1 === data.length &&
                    <tr className="tdsjustification_rowshadow">
                        <td className="text-left">Total</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-right">
                            {calculateTotal("7")}
                        </td>
                    </tr>
                }
            </>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);


    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab06" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">
                    <div className="card">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                            <Tooltip anchorElement="pointer" position="top">
                                <Grid
                                    className="table table-striped exptds_justformtype_table mb-0"
                                    style={{ maxHeight: '530px', overflowX: 'auto' }}
                                    data={data}
                                    {...dataState}

                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        pan: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    sortable
                                >
                                    <Column title="Challan Details as per Statement" headerClassName={'sorting_disabled'}>
                                        <Column
                                            field="s_no"
                                            title={`Sr. No.`}
                                            width="50"
                                        />
                                        <Column
                                            field="cd_no"
                                            headerClassName={isColumnActive('cd_no') ? 'active' : ''}
                                            title={`CD Serial No.`}
                                            width="160"
                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                            filter="textWithEmpty"
                                        />
                                        <Column
                                            field="tax_dep"
                                            headerClassName={isColumnActive('tax_dep') ? 'active' : ''}
                                            title={`Total Tax Deposited`}
                                            width="130"
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            filter="numeric"
                                        />
                                        <Column
                                            field="bsr_code"
                                            headerClassName={isColumnActive('bsr_code') ? 'active' : ''}
                                            title={`BSR Code`}
                                            width="120"
                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                            filter="textWithEmpty"
                                        />
                                        <Column
                                            field="tax_date"
                                            headerClassName={isColumnActive('tax_date') ? 'active' : ''}
                                            title={`Date on Which Tax Deposited`}
                                            width="120"
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            filter="date"
                                        />
                                        <Column
                                            field="ch_serial_no"
                                            headerClassName={isColumnActive('ch_serial_no') ? 'active' : ''}
                                            title={`Challan Serial Number`}
                                            width="140"
                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                            filter="textWithEmpty"
                                        />
                                        <Column
                                            field="book_entry"
                                            headerClassName={isColumnActive('book_entry') ? 'active' : ''}
                                            title={`Book Entry Flag`}
                                            width="120"
                                            columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                            filter="textWithEmpty"
                                        />
                                    </Column>

                                    <Column title="Processed Data" headerClassName={'sorting_disabled notices_orders_rowbgclr_yell'}>
                                        <Column
                                            field="short_payment"
                                            headerClassName={isColumnActive('short_payment') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                            title={`Short Payment Due to Challan Mismatch`}
                                            width="150"
                                            columnMenu={(props) => <ColumnMenu {...props} />}
                                            filter="numeric"
                                        />

                                    </Column>

                                    <GridNoRecords>
                                        No Record Found
                                    </GridNoRecords>

                                </Grid>
                            </Tooltip>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default SpMismatch;