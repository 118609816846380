/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Pagination, Icon } from 'semantic-ui-react';
import { DEDUCTOR_RECORD_IN_ONE_PAGE } from '../../app/constants';
// import 'semantic-ui-css/semantic.min.css';
import './semantic-pages.css';
import './Pagination.css';

export default function SemanticPagination(props) {
  const { cursor, summary ,pagelimit, count, handleProcess, setSummaryStatus } = props;  

  const location = useLocation();
  const history = useHistory();
  const deductor_record_page = pagelimit ? pagelimit : DEDUCTOR_RECORD_IN_ONE_PAGE;

  if (cursor.current_page === 1 && !cursor.next_page) {
    return null;
  }
  const params = queryString.parse(location.search);

  let searchStr = '';
  Object.keys(params).map((key) => {
    if (key && key !== 'page') {
      searchStr = `${searchStr}&${key}=${params[key]}`;
    }
    return null;
  });

  const onChange = (e, pagInfo) => {    
    if(handleProcess){ handleProcess() }        
    history.push(`${location.pathname}?page=${pagInfo.activePage}${searchStr}`);
    if(setSummaryStatus) {setSummaryStatus(false) }
  }


  return (

    <div className="col-sm-12 mt-1">
      <div className="align-center-pagination clientmaster_allpagination">
        <Pagination activePage={cursor?.current_page}
          totalPages={Math.ceil((summary?.[count] ?? 0) /  deductor_record_page)}
          onPageChange={onChange}
          boundaryRange={2}
          siblingRange={1}
          ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
          firstItem={cursor?.current_page !== 1 ? { content: <Icon name='angle double left' />, icon: true } : null}
          prevItem={cursor?.current_page !== 1 ? { content: <Icon name='angle left' />, icon: true } : null}
          lastItem={cursor?.next_page ? { content: <Icon name='angle double right' />, icon: true } : null}
          nextItem={cursor?.next_page ? { content: <Icon name='angle right' />, icon: true } : null}
        />
      </div>
    </div>
  );
}
