import React, { useState } from "react";
import ImportExcelFile from "./ImportExcelFile";
import ExcelToGstMapping from "./ExcelToTdsMapping";
import { importForSalaryCalculator } from "../../../api/salaryAPI";
import { getCurrentFinancialYear } from "../../../utils/UtilityFunctions";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

const SalaryMapping = (props) => {
    const { setShowSalaryMappingPage, setLoading, setError, getSalaryData, getSalaryDataSummary } = props
    const location = useLocation();
    const params = queryString.parse(location.search);
    const deductor_id = params?.deductor_id ?? ""
    const financial_year = !isNaN(params?.financial_year) ? params?.financial_year : getCurrentFinancialYear();

    const [showImportExcelFile, setShowImportExcelFile] = useState(true);
    const [file, setFile] = useState(null);
    const [mappingPopup, setMappingPopup] = useState(false);
    const [headerRowIndex, setHeaderRowIndex] = useState(null);
    const [mappingColumn, setMappingColumn] = useState([]);
    const [columnList, setColumnList] = useState('');
    const [sheetName, setSheetName] = useState('');
    const [templateId, setTemplateId] = useState(null)
    const [templateName, setTemplateName] = useState("")
    const [isDefaultProceed, setIsDefaultProcced] = useState(false)

    //let JSZip = require("jszip");

    const uploadFormDataWithDefaultTemplate = async (formData) => {
        setLoading(true);
        try {
            const result = await importForSalaryCalculator(formData);
            if (result?.data) {
                getSalaryData()
                getSalaryDataSummary()
            } else {
                setError('Salary data not correct to import!!');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
            handleFileUploadPopupClose()
        }
    }


    const handleFileUploadClose = () => {
        setShowImportExcelFile(false);
    }

    const handleFileUploadPopupClose = () => {
        handleFileUploadClose()
        setShowSalaryMappingPage(false);
    }

    const updateSalaryColumns = (columns) => {
        if (columns && columns.length > 0) {
            setMappingColumn((prev) => {
                prev = columns
                return [...prev]
            });

            //set blank column list for mapping keys with heading column index
            const list = Array.apply(null, Array(columns.length)).map(function () { return "" })
            setColumnList(list);
        }
    }

    const showMappingPage = (defaultProcced = false) => {
        setIsDefaultProcced(defaultProcced)
        setMappingPopup(true);
        handleFileUploadClose();

    }


    const removeTrailingBlankItemFromMappingColumnList = (columnList) => {
        let lastIndex = 0;
        for (let i = 0; i < columnList.length; i++) {
            if (columnList[i] !== "") {
                lastIndex = i
            }
        }
        return columnList.slice(0, lastIndex + 1);
    }

    const handleMappingProceed = async (mappingList, templateId, templateName, defaultProceed) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('deductor_id', deductor_id);
            formData.append('financial_year', financial_year)
            if (!defaultProceed) {
                if (templateId) {
                    formData.append('template_id', templateId)
                }
                formData.append('template_name', templateName)
            }

            const mapper_json = {
                "sheet_name": sheetName,
                "header_row_idx": headerRowIndex,
                "header_row_cols": mappingColumn,
                "mapping": removeTrailingBlankItemFromMappingColumnList(mappingList),
            }
            formData.append('mapper_json', JSON.stringify(mapper_json));
            formData.append('file', file);
            const result = await importForSalaryCalculator(formData);
            if (result?.data) {
                getSalaryData()
                getSalaryDataSummary()
            } else {
                setError('Error in importing salary data for TDS calculation.')
            }

        } catch (err) {
            setError(err?.message)
        } finally {
            setLoading(false);
        }

    }


    return (
        <>
                       <div className="salary_left_sidepopup" >
                <div className="challans_AnnouncementsIcon" >
                    <div className="challans_Announcements">
                        <div className="challans_Announcements-wrapper challans_has-unseen-items Dropdown">
                            <div className="Dropdown-toggle Button">
                                <div className="challans_Announcements-bubble">&nbsp;</div>
                                <div>
                                    <FontAwesomeIcon icon={faBullhorn} style={{ color: '#fff', fontSize: '11px', transform: 'rotate(-38deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span
                    className="mb-0 btn challans_textfs"
                    onClick={() => {
                        handleFileUploadPopupClose();
                        setTimeout(() => {
                            setShowSalaryMappingPage(true)
                        }, 10);
                    }} type="button"
                > Import Salary Mapping </span>
            </div>


            {showImportExcelFile &&
                <ImportExcelFile
                    onClose={() => handleFileUploadPopupClose()}
                    deductorId={deductor_id}
                    financial_year={financial_year}
                    setFile={setFile}
                    setHeaderRowIndex={setHeaderRowIndex}
                    uploadFormDataWithDefaultTemplate={uploadFormDataWithDefaultTemplate}
                    updateSalaryColumns={updateSalaryColumns}
                    showMappingPage={showMappingPage}
                    setSheetName={setSheetName}
                    setColumnList={setColumnList}
                    setTemplateId={setTemplateId}
                    setTemplateName={setTemplateName}
                    setError1={setError}

                />
            }
            {mappingPopup &&
                <ExcelToGstMapping
                    handleProceed={(mappingList, templateId, templateName, defaultProceed = false) => {
                        setMappingPopup(false);
                        handleMappingProceed(mappingList, templateId, templateName, defaultProceed);
                        handleFileUploadPopupClose();

                    }}
                    mappingColumn={mappingColumn}
                    columnList={columnList}
                    setColumnList={setColumnList}
                    setTemplateName={setTemplateName}
                    templateName={templateName}
                    templateId={templateId}
                    isDefaultProceed={isDefaultProceed}
                    handleFileUploadPopupClose={handleFileUploadPopupClose}
                />
            }

        </>
    )
}

export default SalaryMapping;