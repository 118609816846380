/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './Pagination.css';

export default function Pagination(props) {
  const { cursor, handleProcess } = props;

  if(cursor?.next_page) cursor.next_page_url = cursor.next_page;

  const location = useLocation();
  if (cursor.current_page === 1 && !cursor.next_page_url) {
    return null;
  }
  const params = queryString.parse(location.search);

  let searchStr = '';
  Object.keys(params).map((key) => {
    if (key && key !== 'page') {
      searchStr = `${searchStr}&${key}=${params[key]}`;
    }
    return null;
  });

  return (
    <div className="col-sm-12 mt-1">
      <div className="align-center mb-2">
        <nav aria-label="...">
          <ul className="pagination justify-content-center">
            {cursor.current_page === 1 ? (
              <li className="page-item disabled" >
                <Link
                  className="page-link"
                  to={`${location.pathname}?page=1${searchStr}`}
                  tabIndex="-1"
                >
                  First
                </Link>
              </li>
            ) : (
              <li className="page-item" onClick={() => {if(handleProcess) handleProcess()}}>
                <Link
                  className="page-link"
                  to={`${location.pathname}?page=1${searchStr}`}
                  tabIndex="-1"
                >
                  First
                </Link>
              </li>
            )}

            {cursor.current_page > 1
              ? (
                <li className="page-item" onClick={() => {if(handleProcess) handleProcess()}}>
                  <Link
                    className="page-link"
                    to={`${location.pathname}?page=${cursor.current_page - 1}${searchStr}`}
                  >
                    {cursor.current_page - 1}
                  </Link>
                </li>
              )
              : null}

            <li className="page-item active">
              <Link
                className="page-link"
                to={`${location.pathname}?page=${cursor.current_page}${searchStr}`}
                tabIndex="-1"
              >
                {cursor.current_page}
                <span className="sr-only">(current)</span>
              </Link>
            </li>

            {cursor.next_page_url ? (
              <li className="page-item" onClick={() => {if(handleProcess) handleProcess()}}>
                <Link
                  className="page-link"
                  to={`${location.pathname}?page=${cursor.current_page + 1}${searchStr}`}
                  tabIndex="-1"
                >
                  {cursor.current_page + 1}
                </Link>
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </div>
  );
}
