/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../deducteeTransactionsAndChallan/fvuGeneration/FVUGeneration.css';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import {
  getReturnsFilingData,
  getVerifiedChallanCountData,
  getVerifiedPanCountData,
  generateFVUFile,
  downloadCSIFile,
} from '../../api/interestFeesCalculatorAPI';
import { getCorrectionDeductorById } from '../../api/deductorAPI';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { selectHeaderData } from '../header/headerSlice';

import DownloadCSIForFVU from '../deducteeTransactionsAndChallan/fvuGeneration/DownloadCSIForFVU';

import CorrectionHeader from '../editCorrectionDeductor/CorrectionHeader';

const CorrectionFVUGeneration = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [, setReturnsFilingDataInfo] = useState({});
  const [, setVerifiedChallanCountInfo] = useState({});
  const [, setVerifiedPanCountInfo] = useState({});
  const [, setDeductorDetail] = useState({});
  const [genrateFvuFileBtnLoading, setGenrateFvuFileBtnLoading] = useState(false);
  const [downloadFvuFileBtnLoading] = useState(false);
  const [genrateFvuMessage, setGenrateFvuMessage] = useState({});

  const headerDataDetails = useSelector(selectHeaderData);

  const [downloadCSI, setDownloadCSI] = useState(false);
  const [, setGenrateFvuDiv] = useState(false);
  const [genrateFvuErrors, setGenrateFvuErrors] = useState([]);
  // const [genrateFvuFileBtn, setGenrateFvuFileBtn] = useState(true);
  const [downloadFvuFileBtn, setDownloadFvuFileBtn] = useState(false);
  const [genrateFvuErrMessage, setGenrateFvuErrMessage] = useState({});

  const getReturnsFilingDataFunc = (filterStr) => {
    async function fetchEverything() {
      async function fetchReturnsFilingData() {
        const result = await getReturnsFilingData(filterStr);
        setReturnsFilingDataInfo(result.data);
      }
      async function fetchVerifiedChallanCountData() {
        const result = await getVerifiedChallanCountData(filterStr);
        setVerifiedChallanCountInfo(result.data);
      }
      async function fetchVerifiedPanCountData() {
        const result = await getVerifiedPanCountData(filterStr);
        setVerifiedPanCountInfo(result.data);
      }
      async function fetchDeductorDetail() {
        const result = await getCorrectionDeductorById(params.deductor_id, params.return_id);
        setDeductorDetail(result.data);
      }
      try {
        await Promise.all([
          fetchReturnsFilingData(),
          fetchVerifiedChallanCountData(),
          fetchVerifiedPanCountData(),
          fetchDeductorDetail(),
        ]);
        setError(null);
      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
    setTimeout(function(){ 
    var getFVUError = JSON.parse(localStorage.getItem('FVUError'));
      setGenrateFvuErrMessage(getFVUError); 
      localStorage.removeItem('FVUError');
    },200);
  };

  useEffect(() => {
    const filterStr = `?return_id=${params.return_id}&deductor_id=${params.deductor_id}`;
    getReturnsFilingDataFunc(filterStr);
  }, [1]);

  const callDownloadFVUFile = () => {
    setError(null);
    setSuccess(null);
    async function callDownloadFVUAPI() {
      async function downloadCSIFileFnc() {
        const downloadFileStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
        const result = await downloadCSIFile(downloadFileStr);
        return result;
      }
      try {
        const result = await downloadCSIFileFnc();
        const fullFileName = `${headerDataDetails.tan}_${headerDataDetails.form_type}_${headerDataDetails.financial_year_formatted}_${headerDataDetails.quarter}.zip`;

        const csiData = new Blob([result], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csiData);
        link.setAttribute('download', fullFileName);
        document.body.appendChild(link);
        link.click();

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    callDownloadFVUAPI();
  };
  const callGenerateFVUFile = () => {
    async function callGenerateFVUFileAPI() {
      async function generateFVUFileFnc() {
        const downloadFileStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
        const result = await generateFVUFile(downloadFileStr);
        return result;
      }
      try {
        const result = await generateFVUFileFnc();
        // if (result.status == '1') {
        //   setGenrateFvuFileBtn(false);
        // }
        if (result.data && result.data.errors) {
          setGenrateFvuErrors(result.data.errors);
          setDownloadFvuFileBtn(true);
        } else {
          callDownloadFVUFile();
        }
        if (result.data && result.data.warnings) {
          setGenrateFvuErrors(result.data.warnings);
        }
        if (result.data && result.data.message) {
          setGenrateFvuMessage(result.data.message);
        }
        setDownloadCSI(false);
        setGenrateFvuDiv(true);
      } catch (err) {
        if (parseInt(err.code) === 412) {
          setDownloadCSI(false);
          setGenrateFvuDiv(true);
          setGenrateFvuErrMessage(err);
        } else {
          console.error('error: ', err);
          setError(err.message);
        }
      } finally {
        setLoading(false);
        setGenrateFvuFileBtnLoading(false);
      }
    }
    callGenerateFVUFileAPI();
    setGenrateFvuFileBtnLoading(true);
    setLoading(true);
  };

  return (
    <div>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {downloadCSI
        ? (
          <DownloadCSIForFVU
            show={downloadCSI}
            handleClose={() => setDownloadCSI(false)}
            handleProceed={() => setDownloadCSI(false)}
            deductorId={params.deductor_id}
            returnId={params.return_id}
            callGenerateFVUFile={callGenerateFVUFile}
            loadingError={loading}
          />
        )
        : null}

      <div className="timelst3frbbgclr">
        <CorrectionHeader
          pageName="CorrectionFVUGeneration"
          deductorId={params.deductor_id}
          returnId={params.return_id}
        />
        {/* end main container */}
        <div className="container">
          {' '}
          {/* inner section */}
          {/* } <fieldset className="fvuggfieldbox mb-2 mt-2">
            <legend className="fvugglegentbox"><h4> FVU Generation</h4></legend> */}

          <div className="row mt-2">
            {' '}
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <div className="card timel3patop ">
                <h4 className="h4bgtimelst3">
                  Errors & Warnings
                </h4>
                {!downloadFvuFileBtn && genrateFvuMessage && genrateFvuMessage.text ? (
                  <p className="greenFvuSucess mb-1" style={{ color: genrateFvuMessage.color ? `#${genrateFvuMessage.color}` : '' }}>
                    {genrateFvuMessage.text}
                  </p>
                ) : null}
                {genrateFvuErrMessage ? (
                    <p className="mb-1 pl-2 text-danger">
                      {genrateFvuErrMessage.message}
                    </p>
                ) : null}
                {genrateFvuErrors.length > 0 ? (
                  <div className="table-responsive errorwaringtb">
                    <table className="table table-striped timelst3errortb">
                      <thead>
                        <tr>
                          <th>Record Type</th>
                          <th>Field Name</th>
                          <th>Error Type</th>
                          <th>Error Description</th>
                          {/* <th>View & Fix Error</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {genrateFvuErrors ? genrateFvuErrors.map((errors, key) => (
                          <tr key={key}>
                            <td>
                              {errors.section_or_record_type}
                              {' '}
                            </td>
                            <td>{errors.field_name}</td>
                            <td>
                              <span className="timeltdtextclr" style={{ color: errors.error_type && errors.error_type.color ? `#${errors.error_type.color}` : '' }}>
                                {' '}
                                {errors.error_type && errors.error_type.text ? errors.error_type.text : '-'}
                              </span>
                            </td>
                            <td>{errors.error_code_and_description}</td>
                            {/* <td>
                              <span className="timelionclr">
                                <FontAwesomeIcon icon={faEye} />
                              </span>
                            </td> */}
                          </tr>
                        )) : ''}
                      </tbody>
                    </table>
                  </div>
                ) : ''}
                <div className="row mt-2 mb-2">
                  <div className="col-lg text-center pt-3">
                    <Button 
                    // disabled={genrateFvuFileBtn} 
                    className="btn btn-default timelst3valibtn mr-1" onClick={() => callGenerateFVUFile()}>
                      {genrateFvuFileBtnLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {' '}
                          Loading...
                        </>
                      ) : 'Validate Again'}
                    </Button>
                    <Button disabled={downloadFvuFileBtn} className={downloadFvuFileBtn ? 'btn btn-info timelst3fvu mr-1' : 'btn btn-default timelst3valibtn mr-1'} onClick={() => callDownloadFVUFile()}>

                      {downloadFvuFileBtnLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {' '}
                          Loading...
                        </>
                      ) : 'Download FVU File'}

                    </Button>
                    {/* <button
                    className="btn btn-info timelst3etds">
                    Proceed to eTDS filling
                    </button> */}
                    <a
                      style={{ visibility: 'hidden' }}
                      id="download-csi-file"
                      download="oltas_tan_based.zip"
                      type="text/csi"
                    >
                      Download CSI
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </fieldset> */}
          {/* inner section */}
        </div>
      </div>
      {/*<a id="download-txt-file" target="_blank" style={{ display: 'hidden' }} download="hello.txt" />*/}
    </div>

  );
};

export default CorrectionFVUGeneration;
