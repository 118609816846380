import React from 'react';
import { Skeleton } from '@mui/material'


const SkeletonStickyNotes = () => {
    return (
        <div className="row">
            <div className='col-md-4'>
                <Skeleton animation="wave" variant="text" width={"100%"} height={"140px"} />
            </div>
            <div className='col-md-4'>
                <Skeleton animation="wave" variant="text" width={"100%"} height={"140px"} />
            </div>
            <div className='col-md-4'>
                <Skeleton animation="wave" variant="text" width={"100%"} height={"140px"} />
            </div>
        </div>
    )
}

export default SkeletonStickyNotes