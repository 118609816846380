// eslint-disable-next-line
import React, { useEffect } from "react";
import { getPanListToVerifyAtTracesAndInsightsViaConnector } from "../../api/serverConnectorAPI";
import { verifyPANandCheckCompliance } from "../../api/localConnectorAPI";
import { HEADER_CONNECTOR_DIGEST_KEY, CONNECTOR_ERRORS_MAPPING } from "../../app/constants";
import * as XLSX from "xlsx";


const DeducteeListV2ComplianceCheck = (props) => {

    const { setLoading, deductorId, setComplianceCheckStatusData, setError,
        insightsCaptchaRef, port, coplianceStatusKey, captchaRef, panArray, setSuccess, setReloadPanList, type } = props;


    let wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "PAN_To_Connector",
        Subject: "PAN_for_Compliance",
        Author: "Express_TDS",
    };

    wb.SheetNames.push("PAN");
    let newPanArray = [["PAN"]];
    if (panArray && panArray?.length > 0 && panArray?.forEach((item) => {
        let localArr = [];
        localArr.push(item)
        newPanArray.push(localArr)
    }));
    let ws_data = [...newPanArray];
    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["PAN"] = ws;

    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    function s2ab(s) {

        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;

    }
    //     FileSaver.saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'test.xlsx');

    const fetchTracesCredentials = (deductorId, captchaRef, insightsCaptchaRef, port, coplianceStatusKey) => {

        async function fetchEverything() {
            try {
                setLoading(true);

                const payload = {
                    'deductor_id': deductorId,
                    'action_type': 'INSIGHTS_ONLY',
                    'traces_captcha_ref': captchaRef,
                    'insights_captcha_ref': insightsCaptchaRef,
                }
                const result = await getPanListToVerifyAtTracesAndInsightsViaConnector(payload);

                const responseHeader = result?.headers ?? null;

                try {
                    setLoading(true);
                    const dataKey = result?.data?.data ?? '';
                    const formData = new FormData();
                    if (type === "multiPanByUser") {
                        formData.append('pan_file', new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'test.xlsx');
                        formData.append('data', dataKey);
                    } else {
                        formData.append('data', dataKey);
                    }

                    let extraHeader = {};

                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                    const connectorResponse = await verifyPANandCheckCompliance(port, formData, extraHeader)

                    if (Number(connectorResponse.status) === 1) {
                        //set local storage key to get PAN verify status data
                        const statusData = { 'captcha_ref': captchaRef, 'insights_captcha_ref': insightsCaptchaRef, 'port': port };
                        localStorage.setItem(coplianceStatusKey, JSON.stringify(statusData));
                        setComplianceCheckStatusData(statusData);
                        setSuccess('Your request for verification has been uploaded to the Insights portal. The portal generates results after some time, so kindly check back after a while to view the Status');
                        setTimeout(() => setReloadPanList(true), 4000)
                    }


                } catch (err) {

                    const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default'];
                    setError(errmsg);
                }

            } catch (err) {

                setError(err.message);

            } finally {
                setLoading(false);
            }

        }

        fetchEverything();
    }

    useEffect(() => {
        fetchTracesCredentials(deductorId, captchaRef, insightsCaptchaRef, port, coplianceStatusKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deductorId, captchaRef, insightsCaptchaRef, port, coplianceStatusKey]);


    return (null)

};

export default DeducteeListV2ComplianceCheck;