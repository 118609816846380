export const individualsInDateRangeTaxRates = {
    '1': 7.5,
    '2': 7.5,
    '4': 7.5,
    '5': 30,
    '6': 3.75,
    '7': 7.5,
    '8': 7.5,
    '25': 7.5,
    '26': 30,
    '27': 0.75,
    '28': 3.75,
    '30': 15,
    '31': 3.75,
    '32': 3.75,
    '33': 1.5,
    '34': 1.5,
    '35': 7.5,
    '36': 2,
    '41': 10,
    '42': 7.5,
    '46': 18.75,
    '47': 7.5,
    '48': 7.5,
    '49': 0.75,
    '50': 7.5,
    '51': 7.5,
    '52': 0,
    '53': 0,
    '54': 7.5,
    '55': 7.5,
    '61': 0.75,
    '62': 0.1,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':2,
    '82':5,
};
export const Budget2024_individualsInDateRangeTaxRates = {
    '1': 7.5,
    '2': 7.5,
    '4': 7.5,
    '5': 30,
    '6': 2,
    '7': 7.5,
    '8': 7.5,
    '25': 7.5,
    '26': 30,
    '27': 0.75,
    '28': 3.75,
    '30': 0,
    '31': 2,
    '32': 2,
    '33': 1.5,
    '34': 1.5,
    '35': 7.5,
    '36': 2,
    '41': 10,
    '42': 7.5,
    '46': 18.75,
    '47': 7.5,
    '48': 7.5,
    '49': 0.75,
    '50': 7.5,
    '51': 7.5,
    '52': 0,
    '53': 0,
    '54': 7.5,
    '55': 7.5,
    '61': 0.1,
    '62': 0.1,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':2,
    '82':5,
};

export const NoneIndividualsInDateRangeTaxRates = {
    '1': 7.5,
    '2': 7.5,
    '4': 7.5,
    '5': 30,
    '6': 3.75,
    '7': 7.5,
    '8': 7.5,
    '25': 7.5,
    '26': 30,
    '27': 1.5,
    '28': 7.5,
    '30': 15,
    '31': 3.75,
    '32': 3.75,
    '33': 1.5,
    '34': 1.5,
    '35': 7.5,
    '36': 2,
    '41': 0,
    '42': 7.5,
    '46': 22.5,
    '47': 7.5,
    '48': 7.5,
    '49': 0.75,
    '50': 7.5,
    '51': 7.5,
    '52': 0,
    '53': 0,
    '54': 7.5,
    '55': 7.5,
    '61': 0.75,
    '62': 0.1,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':2,
    '82':5,
};

export const Budget2024_NoneIndividualsInDateRangeTaxRates = {
    '1': 7.5,
    '2': 7.5,
    '4': 7.5,
    '5': 30,
    '6': 2,
    '7': 7.5,
    '8': 7.5,
    '25': 7.5,
    '26': 30,
    '27': 1.5,
    '28': 7.5,
    '30': 0,
    '31': 2,
    '32': 2,
    '33': 1.5,
    '34': 1.5,
    '35': 7.5,
    '36': 2,
    '41': 0,
    '42': 7.5,
    '46': 22.5,
    '47': 7.5,
    '48': 7.5,
    '49': 0.75,
    '50': 7.5,
    '51': 7.5,
    '52': 0,
    '53': 0,
    '54': 7.5,
    '55': 7.5,
    '61': 0.1,
    '62': 0.1,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':2,
    '82':5,
};

export const individualsTaxRates = {
    '1': 10,
    '2': 10,
    '4': 10,
    '5': 30,
    '6': 5,
    '7': 10,
    '8': 10,
    '25': 10,
    '26': 30,
    '27': 1,
    '28': 5,
    '30': 20,
    '31': 5,
    '32': 5,
    '33': 2,
    '34': 2,
    '35': 10,
    '36': 2,
    '41': 10,
    '42': 10,
    '46': 25,
    '47': 10,
    '48': 10,
    '49': 1,
    '50': 10,
    '51': 10,
    '52': 0,
    '53': 0,
    '54': 10,
    '55': 10,
    '61': 1,
    '62': 0.1,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':2,
    '82':5,
};

export const Budget2024_individualsTaxRates = {
    '1': 10,
    '2': 10,
    '4': 10,
    '5': 30,
    '6': 2,
    '7': 10,
    '8': 10,
    '25': 10,
    '26': 30,
    '27': 1,
    '28': 5,
    '30': 0,
    '31': 2,
    '32': 2,
    '33': 2,
    '34': 2,
    '35': 10,
    '36': 2,
    '41': 10,
    '42': 10,
    '46': 25,
    '47': 10,
    '48': 10,
    '49': 1,
    '50': 10,
    '51': 10,
    '52': 0,
    '53': 0,
    '54': 10,
    '55': 10,
    '61': 0.1,
    '62': 0.1,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':2,
    '82':5,
};

export const NoneIndividualsTaxRates = {
    '1': 10,
    '2': 10,
    '4': 10,
    '5': 30,
    '6': 5,
    '7': 10,
    '8': 10,
    '25': 10,
    '26': 30,
    '27': 2,
    '28': 10,
    '30': 20,
    '31': 5,
    '32': 5,
    '33': 2,
    '34': 2,
    '35': 10,
    '36': 2,
    '41': 0,
    '42': 10,
    '46': 30,
    '47': 10,
    '48': 10,
    '49': 1,
    '50': 10,
    '51': 10,
    '52': 0,
    '53': 0,
    '54': 10,
    '55': 10,
    '61': 1,
    '62': 0.1,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':2,
    '82':5,
}

export const Budget2024_NoneIndividualsTaxRates = {
    '1': 10,
    '2': 10,
    '4': 10,
    '5': 30,
    '6': 2,
    '7': 10,
    '8': 10,
    '25': 10,
    '26': 30,
    '27': 2,
    '28': 10,
    '30': 0,
    '31': 2,
    '32': 2,
    '33': 2,
    '34': 2,
    '35': 10,
    '36': 2,
    '41': 0,
    '42': 10,
    '46': 30,
    '47': 10,
    '48': 10,
    '49': 1,
    '50': 10,
    '51': 10,
    '52': 0,
    '53': 0,
    '54': 10,
    '55': 10,
    '61': 0.1,
    '62': 0.1,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':2,
    '82':5,
}

export const NoPanTaxRates = {
    '1': 20,
    '2': 20,
    '4': 0,
    '5': 30,
    '6': 20,
    '7': 20,
    '8': 20,
    '25': 20,
    '26': 30,
    '27': 20,
    '28': 20,
    '30': 20,
    '31': 20,
    '32': 20,
    '33': 20,
    '34': 20,
    '35': 20,
    '36': 0,
    '41': 20,
    '42': 40,
    '46': 40,
    '47': 20,
    '48': 20,
    '49': 20,
    '50': 20,
    '51': 20,
    '52': 0,
    '53': 0,
    '54': 0,
    '55': 0,
    '61': 20,
    '62': 5,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':20,
    '82':20,
};

export const Budget2024_NoPanTaxRates = {
    '1': 20,
    '2': 20,
    '4': 0,
    '5': 30,
    '6': 2,
    '7': 20,
    '8': 20,
    '25': 20,
    '26': 30,
    '27': 20,
    '28': 20,
    '30': 0,
    '31': 2,
    '32': 2,
    '33': 20,
    '34': 20,
    '35': 20,
    '36': 0,
    '41': 20,
    '42': 40,
    '46': 40,
    '47': 20,
    '48': 20,
    '49': 20,
    '50': 20,
    '51': 20,
    '52': 0,
    '53': 0,
    '54': 0,
    '55': 0,
    '61': 0.1,
    '62': 5,
    '74': 10,
    '75': 1,
    '76':30,
    '77':10,
    '78':1,
    '79':30,
    '80':30,
    '81':20,
    '82':20,
};

// new tax rate when reason U is selected 
export const DeductionReasonURate = {
    '1': 20,
    '2': 20,
    '4': 20,
    '5': 30,
    '6': 10,
    '7': 20,
    '8': 20,
    '25': 20,
    '26': 30,
    '27': 5,
    '28': 20,
    '28K': 10,
    '30': 40,
    '31': 10,
    '32': 10,
    '33': 5,
    '34': 5,
    '35': 5,
    '36': 2,
    '41': 10,
    '42': 20,
    '46': 30,
    '46K': 25,
    '47': 10,
    '48': 20,
    '49': 5,
    '50': 20,
    '51': 20,
    '52': 0,
    '53': 0,
    '54': 20,
    '55': 20,
    '61': 5,
    '62': 5,
    '79':30,
    '80':30,
    '81':20,
    '82':20,
};
export const Budget2024_DeductionReasonURate = {
    '1': 20,
    '2': 20,
    '4': 20,
    '5': 30,
    '6': 2,
    '7': 20,
    '8': 20,
    '25': 20,
    '26': 30,
    '27': 5,
    '28': 20,
    '28K': 10,
    '30': 0,
    '31': 2,
    '32': 2,
    '33': 5,
    '34': 5,
    '35': 5,
    '36': 2,
    '41': 10,
    '42': 20,
    '46': 30,
    '46K': 25,
    '47': 10,
    '48': 20,
    '49': 5,
    '50': 20,
    '51': 20,
    '52': 0,
    '53': 0,
    '54': 20,
    '55': 20,
    '61': 0.1,
    '62': 5,
    '79':30,
    '80':30,
    '81':20,
    '82':20,
};

//26Q - NON FiLER & reason U is selected
export const NonFilerDeductionReasonURate = {
    '1': 20,
    '2': 20,
    '4': 20,
    '5': 30,
    '6': 10,
    '7': 20,
    '8': 20,
    '9': 10,
    '25': 20,
    '26': 30,
    '27': 5,
    '28': 20,
    '28K': 20,
    '29': 40,
    '30': 40,
    '31': 10,
    '32': 10,
    '33': 5,
    '34': 5,
    '35': 20,
    '36': 2,
    '41': 10,
    '42': 20,
    '46': 30,
    '46K': 25,
    '47': 20,
    '48': 20,
    '49': 5,
    '50': 20,
    '51': 0,
    '52': 20,
    '53': 20,
    '54': 20,
    '55': 20,
    '61': 5,
    '62': 5,
    '79':30,
    '80':30,
    '81':20,
    '82':20,
}
export const Budget2024_NonFilerDeductionReasonURate = {
    '1': 20,
    '2': 20,
    '4': 20,
    '5': 30,
    '6': 2,
    '7': 20,
    '8': 20,
    '9': 10,
    '25': 20,
    '26': 30,
    '27': 5,
    '28': 20,
    '28K': 20,
    '29': 40,
    '30': 0,
    '31': 2,
    '32': 2,
    '33': 5,
    '34': 5,
    '35': 20,
    '36': 2,
    '41': 10,
    '42': 20,
    '46': 30,
    '46K': 25,
    '47': 20,
    '48': 20,
    '49': 5,
    '50': 20,
    '51': 0,
    '52': 20,
    '53': 20,
    '54': 20,
    '55': 20,
    '61': 0.1,
    '62': 5,
    '79':30,
    '80':30,
    '81':20,
    '82':20,
}

// 27EQ tax rate 
export const DateRangeTaxRate27EQ = {
    '12': 1,
    '13': 1.875,
    '14': 1.875,
    '15': 1.875,
    '16': 0.75,
    '17': 1.5,
    '18': 1.5,
    '19': 1.5,
    '20': 3.75,
    '21': 0.75,
    '22': 1,
    '43': 0.75,
    '44': 1,
    '45': 1,
    '57': 5,
    '58': 0.5,
    '59': 5,
    '60': 0.075,
    '86': 5,
};


export const PanTaxRates27EQ = {
    '12': 1,
    '13': 2.5,
    '14': 2.5,
    '15': 2.5,
    '16': 1,
    '17': 2,
    '18': 2,
    '19': 2,
    '20': 5,
    '21': 1,
    '22': 1,
    '43': 1,
    '44': 1,
    '45': 1,
    '57': 5,
    '58': 0.005,
    '59': 5,
    '60': 0.1,
    '86': 5,
};

export const NoPanTaxRates27EQ = {
    '12': 5,
    '13': 5,
    '14': 5,
    '15': 5,
    '16': 5,
    '17': 5,
    '18': 5,
    '19': 5,
    '20': 10,
    '21': 5,
    '22': 5,
    '43': 5,
    '44': 5,
    '45': 5,
    '57': 10,
    '58': 5,
    '59': 10,
    '60': 1,
    '86': 20,
};

// new tax rate when reason I is selected 
export const DeductionReasonIRate = {
    '12': 5,
    '13': 5,
    '14': 5,
    '15': 5,
    '16': 5,
    '17': 5,
    '18': 5,
    '19': 5,
    '20': 10,
    '21': 5,
    '22': 5,
    '43': 5,
    '44': 5,
    '45': 5,
    '57': 10,
    '58': 5,
    '59': 10,
    '60': 5,
    '86': 20,
};
//NON Filer - reason I rate list
export const NonFilerDeductionReasonIRate = {
    '12': 5,
    '13': 5,
    '14': 5,
    '15': 5,
    '16': 5,
    '17': 5,
    '18': 5,
    '19': 5,
    '20': 10,
    '21': 5,
    '22': 5,
    '43': 5,
    '44': 5,
    '45': 5,
    '57': 10,
    '58': 5,
    '59': 10,
    '60': 5,
    '86': 20,
};



// new tax rate when reason J is selected 
export const DeductionReasonJRate27Q = {
    '29': 40,
    '9': 10,
    '10': 10,
    '11': 10,
    '4': 20,
    '54': 10,
    '55': 20,
    '64': 60,
    '42': 20,
    '37': 40,
    '38': 20,
    '39': 20,
    '40': 40,
    '65': 20,
    '66': 20,
    '67': 20,
    '68': 20,
    '69': 30,
    '70': 40,
    '71': 40,
    '72': 20,
    '73': 20,
    '3': 60,
    '41': '',
    '36': '',
    '53': '',
    '46': '',

    '5': 30,
    '26' : 30,
    '76' : 30,
    '79' : 30,
    '80' : 30,
    '81' : 20,
    '82' : 20,
    '83' : 20,
    '84' : 20,
    '85' : 20,
};


export const TaxRatesPanAvialbleDateRange27Q = {
    '29': 20,
    '9': 5,
    '10': 5,
    '11': 5,
    '4': 7.5,
    '54': 5,
    '55': 7.5,
    '64': 22.5,
    '42': 7.5,
    '46': 22.5,
    '37': 20,
    '38': 10,
    '39': 10,
    '40': 20,
    '65': 10,
    '66': 10,
    '67': 10,
    '68': 10,
    '69': 15,
    '70': 20,
    '71': 20,
    '72': 10,
    '73': 10,
    '3': 30,
    '41': 7.5,
    '36': 2,
    '53': 0,

    '5': 30,
    '26' : 30,
    '76' : 30,
    '79' : 30,
    '80' : 30,
    '81' : 2,
    '82' : 5,
    '83' : 5,
    '84' : 4,
    '85' : 9,
};
export const TaxRatesPanAvialbleDateRange27QOther = {
    '29': 20,
    '9': 5,
    '10': 5,
    '11': 5,
    '4': 7.5,
    '54': 5,
    '55': 7.5,
    '64': 22.5,
    '42': 7.5,
    '46': 18.75,
    '37': 20,
    '38': 10,
    '39': 10,
    '40': 20,
    '65': 10,
    '66': 10,
    '67': 10,
    '68': 10,
    '69': 15,
    '70': 20,
    '71': 20,
    '72': 10,
    '73': 10,
    '3': 30,
    '41': 7.5,
    '36': 2,
    '53': 0,

    '5': 30,
    '26' : 30,
    '76' : 30,
    '79' : 30,
    '80' : 30,
    '81' : 2,
    '82' : 5,
    '83' : 5,
    '84' : 4,
    '85' : 9,
};

export const TaxRatePanAvailable27Q = {
    '29': 20,
    '9': 5,
    '10': 5,
    '11': 5,
    '4': 10,
    '54': 5,
    '55': 10,
    '64': 30,
    '42': 10,
    '37': 20,
    '38': 10,
    '39': 10,
    '40': 20,
    '65': 10,
    '66': 10,
    '67': 10,
    '68': 10,
    '69': 15,
    '70': 20,
    '71': 20,
    '72': 10,
    '73': 10,
    '3': 30,
    '41': 10,
    '36': 2,
    '53': 0,
    '46': 30,
    
    '5': 30,
    '26' : 30,
    '76' : 30,
    '79' : 30,
    '80' : 30,
    '81' : 2,
    '82' : 5,
    '83' : 5,
    '84' : 4,
    '85' : 9,
};

export const TaxRatePanAvailable27QOthers = {
    '29': 20,
    '9': 5,
    '10': 5,
    '11': 5,
    '4': 10,
    '54': 5,
    '55': 10,
    '64': 30,
    '42': 7.5,
    '46': 25,
    '37': 20,
    '38': 10,
    '39': 10,
    '40': 20,
    '65': 10,
    '66': 10,
    '67': 10,
    '68': 10,
    '69': 15,
    '70': 20,
    '71': 20,
    '72': 10,
    '73': 10,
    '3': 30,
    '41': 10,
    '36': 2,
    '53': 0,

    '5': 30,
    '26' : 30,
    '76' : 30,
    '79' : 30,
    '80' : 30,
    '81' : 2,
    '82' : 5,
    '83' : 5,
    '84' : 4,
    '85' : 9,
};

export const TaxRatePanNotAvailable27Q = {
    '29': 20,
    '9': 20,
    '10': 20,
    '11': 20,
    '4': 20,
    '54': 20,
    '55': 20,
    '64': 20,
    '42': 20,
    '46': 20,
    '37': 20,
    '38': 20,
    '39': 20,
    '40': 20,
    '65': 20,
    '66': 20,
    '67': 20,
    '68': 20,
    '69': 20,
    '70': 20,
    '71': 20,
    '72': 20,
    '73': 20,
    '3': 20,
    '41': 20,
    '36': 0,
    '53': 0,

    '5': 30,
    '26' : 30,
    '76' : 30,
    '79' : 30,
    '80' : 30,
    '81' : 20,
    '82' : 20,
    '83' : 20,
    '84' : 20,
    '85' : 20,
};
