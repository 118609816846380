import React, { useState, useEffect } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../components/grid/ColumnMenu';
import { deepClone, formatAccountsNumber } from "../../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";


const SdPe24QFY24Correction = (props) => {

    const { apiData, formType } = props;

    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [inititalData, setInititalData] = useState([]);

    function convertData(data) {
        const newData = [];

        if (data?.length > 0) {
            data.forEach((item) => {
                newData.push({
                    s_no: item[0],
                    sal_no: item[1],
                    pan: item[2],
                    pan_after_correction: item[3],
                    name: item[4],
                    opt_115bac: item[5],
                    tot_tax_inc: item[6],
                    tds_ded: item[7],
                    tds_other: item[8],
                    pan_status: item[9],
                    category: item[10],
                    opt_115bac_oth: item[11],
                    applicable_rate_206aa: item[12],
                    sur_cess: item[13],
                    tds_deducted: item[14],
                    short_deduction: item[15]
                })
            })
            return newData
        }
    };


    const dataStateChange = (dataState) => {

        if (dataState.filter || (dataState.sort && dataState?.sort?.length > 0)) {
            const filter_state = deepClone(dataState);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                            if (["gte", "lte", 'lt', 'gt', "neq"].includes(itemObj?.operator)) {
                                itemObj.value = Number(itemObj.value);
                            }
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                itemObj.value = itemObj.value?.[0] ?? "";
                            }
                        })
                    }
                })
            }
            const dataProcess = process(convertData(apiData), { ...filter_state });
            setDataState({ ...dataState });
            setData(dataProcess.data ?? []);

        } else {

            setDataState({});
            const defaultData = JSON.parse(JSON.stringify(inititalData));
            setData(defaultData ?? []);
        }

    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true
            })
        }
        return active;
    };

    const calculateTotal = (index) => {
        let total = 0
        data.length > 0 && data.forEach((item) => {
            switch (index) {
                case "14":
                    total += item?.tds_deducted;
                    break;
                case "15":
                    total += item?.short_deduction;
                    break;
                default:
                    total = 0
            }

        })
        return formatAccountsNumber(total)
    };

    const renderSubTextTitle = (title, subText) => {

        return (
            <div style={{ whiteSpace: 'break-spaces' }}>
                {title}
                {subText && (<div className="text-center">
                    {' '}<span className="b2btbtrthtextclr" style={{ paddingLeft: '20px' }}>{subText ?? 0}</span>
                </div>)}
            </div>
        );
    };

    const rowRender = (trElement, props) => {

        const key = trElement._owner.index;
        const dataItem = props?.dataItem ?? {}

        return (
            <>
                <tr key={key} role="row">
                    <td className="text-center">{dataItem?.s_no}</td>
                    <td className="text-center">{dataItem?.sal_no ?? "-"}</td>
                    <td className="text-center">{dataItem?.pan ?? "-"}</td>
                    <td className="text-center">{dataItem?.pan_after_correction ?? "-"}</td>
                    <td className="text-left">{dataItem?.name ?? "-"}</td>
                    <td className="text-center">{dataItem?.opt_115bac ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.tot_tax_inc ?? 0) ?? "-"}</td>
                    <td className="text-right">{formatAccountsNumber(dataItem?.tds_ded ?? 0) ?? "-"}</td>
                    <td className="text-right notices_orders_rowbgclr_yell">{formatAccountsNumber(dataItem?.tds_other ?? 0) ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.pan_status ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.category ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.opt_115bac_oth ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{dataItem?.applicable_rate_206aa ?? "-"}</td>
                    <td className="text-center notices_orders_rowbgclr_yell">{formatAccountsNumber(dataItem?.sur_cess ?? 0) ?? "-"}</td>
                    <td className="text-right notices_orders_rowbgclr_grey">{formatAccountsNumber(dataItem?.tds_deducted ?? 0) ?? "-"}</td>
                    <td className="text-right notices_orders_rowbgclr_grey">{formatAccountsNumber(dataItem?.short_deduction ?? 0) ?? "-"}</td>
                </tr>

                {key + 1 === data.length &&
                    <tr className="tdsjustification_rowshadow">
                        <td className="text-left" colSpan={2}>Total</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-right">
                            {calculateTotal("14")}
                        </td>
                        <td className="text-right">
                            {calculateTotal("15")}
                        </td>
                    </tr>
                }
            </>
        )
    };

    useEffect(() => {
        if (apiData) {
            let convertedData = convertData(apiData)
            setInititalData(convertedData);
            setData(convertedData);
        }
        //eslint-disable-next-line
    }, [apiData]);

    return (
        <div className="tab-pane fade" id="exptds_justformtype_tab15" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12 pt-3">
                    <div className="card">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                            <Tooltip anchorElement="pointer" position="top">
                                <Grid
                                    className="table table-striped exptds_justformtype_table mb-0"
                                    style={{ maxHeight: '530px', overflowX: 'auto' }}
                                    data={data}
                                    {...dataState}

                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    sortable
                                >
                                    <Column>
                                        <Column>
                                            <Column
                                                field="s_no"
                                                title={renderSubTextTitle('Sr. No.')}
                                                width="50"
                                            />
                                        </Column>
                                    </Column>

                                    <Column title="Data From Statement(Annexure II)" headerClassName={'sorting_disabled'}>
                                        <Column>
                                            <Column
                                                field="sal_no"
                                                headerClassName={isColumnActive('sal_no') ? 'active' : ''}
                                                title={renderSubTextTitle('Salary Detail Record Serial No. ')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="pan"
                                                headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                title={renderSubTextTitle(`PAN of ${formType === "24Q" ? "Employee" : "Deductee"} before correction`)}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="pan_after_correction"
                                                headerClassName={isColumnActive('pan_after_correction') ? 'active' : ''}
                                                data-toggle="tooltip" data-placement="top"
                                                title={renderSubTextTitle(`Latest PAN of ${formType === "24Q" ? "Employee" : "Deductee"} after Correction`)}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="name"
                                                headerClassName={isColumnActive('name') ? 'active' : ''}
                                                title={renderSubTextTitle(`Name of the ${formType === "24Q" ? "Employee" : "Deductee"}`)}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="opt_115bac"
                                                headerClassName={isColumnActive('opt_115bac') ? 'active' : ''}
                                                title={renderSubTextTitle('Whether \n opting for \n taxation u/s \n 115BAC?')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="tot_tax_inc"
                                                headerClassName={isColumnActive('tot_tax_inc') ? 'active' : ''}
                                                title={renderSubTextTitle('Total Taxable Income')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="tds_ded"
                                                headerClassName={isColumnActive('tds_ded') ? 'active' : ''}
                                                title={renderSubTextTitle('Total Amount Of TDS Deducted(Rs.)')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                        </Column>
                                    </Column>


                                    <Column title="Processed Data" headerClassName={'sorting_disabled notices_orders_rowbgclr_yell'}>
                                        <Column headerClassName={'notices_orders_rowbgclr_yell'}>

                                            <Column
                                                field="tds_other"
                                                headerClassName={isColumnActive('tds_other') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('TDS by Other Employer ')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />

                                            <Column
                                                field="pan_status"
                                                headerClassName={isColumnActive('pan_status') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Status of PAN')}
                                                width="130"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />

                                            <Column
                                                field="category"
                                                headerClassName={isColumnActive('category') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('‘W’ for woman, \n ‘S’ for senior \n citizen, ‘O’ for \n Super Senior \n Citizen and \n ‘G’ for others')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="opt_115bac_oth"
                                                headerClassName={isColumnActive('opt_115bac_oth') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Whether \n opting for taxation u/s 115BAC?')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="applicable_rate_206aa"
                                                headerClassName={isColumnActive('applicable_rate_206aa') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Applicable Tax Rate as per Section 206AA (%)')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                                filter="textWithEmpty"
                                            />
                                            <Column
                                                field="sur_cess"
                                                headerClassName={isColumnActive('sur_cess') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                                title={renderSubTextTitle('Surcharge and Education cess \n as per Income \n Tax  Act,\n 1961(%)')}
                                                width="140"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                        </Column>

                                        <Column title="Short Deduction" headerClassName={'sorting_disabled notices_orders_rowbgclr_grey'}>
                                            <Column
                                                field="tds_deducted"
                                                headerClassName={isColumnActive('tds_deducted') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                                title={renderSubTextTitle('TDS to \n be Deducted \n (Including Surcharge and \n Edu. Cess. \n If applicable)')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                            <Column
                                                field="short_deduction"
                                                headerClassName={isColumnActive('short_deduction') ? 'active notices_orders_rowbgclr_grey' : 'notices_orders_rowbgclr_grey'}
                                                title={renderSubTextTitle('Short Deduction')}
                                                width="150"
                                                columnMenu={(props) => <ColumnMenu {...props} />}
                                                filter="numeric"
                                            />
                                        </Column>
                                    </Column>


                                    <GridNoRecords>
                                        No Record Found
                                    </GridNoRecords>

                                </Grid>
                            </Tooltip>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default SdPe24QFY24Correction;