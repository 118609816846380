/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Formik, Form,
} from 'formik';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import { commutedPensionValidationSchema } from './validationSchema';
import { getCommutedPensionExemptAmt } from './allowancesCalculationScema';


const CommutedPensionForm = (props) => {

    const { fieldValue, setFieldValue, allowance_index, handleClose, getTotalAllowanceAmtp } = props;

    const [commutedPensionData, setCommutedPensionData] = useState(false);

    useEffect(() => {
        if (fieldValue.allowances[allowance_index].allowance_details) {
            setCommutedPensionData(fieldValue.allowances[allowance_index].allowance_details);
        }
        else {
            setCommutedPensionData({
                whether_gov_emp: 0,
                whether_gratuity_rec: 0,
                commuted_pension_amtp: 0,
                commuted_pension_percent_b100: 100,
                exempt_amtp: 0,
            });
        }
    }, [fieldValue]);

    const saveData = (values) => {
        setCommutedPensionData(values);
        let allowances = fieldValue.allowances;
        allowances[allowance_index].allowance_details = values;
        allowances[allowance_index].exempt_amtp = values.exempt_amtp;
        setFieldValue('allowances', allowances);
        getTotalAllowanceAmtp(fieldValue, allowances, setFieldValue);
        handleClose(-1);

    }

    const calculateExemptAmt = (field, value, values, setFieldValue) => {
        let old_value = values[field];
        values[field] = value;
        let exempt_amtp = getCommutedPensionExemptAmt(values);
        setFieldValue('exempt_amtp', exempt_amtp);
        values[field] = old_value;
    }

    if (commutedPensionData) {
        return (<Formik
            initialValues={commutedPensionData}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={commutedPensionValidationSchema}
            onSubmit={(values) => {
                const submitValues = { ...values };
                saveData(submitValues);
            }}
        >
            {({
                errors, values, setFieldValue,
            }) => {
                if (Object.keys(errors).length > 0) {
                    console.log('Form Errors: ', errors);
                }
                return (
                    <Form key={1} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12 pt-2">
                                <div className="card allowance">
                                    <div className="table-responsive">
                                        <table className="table table-striped allowanceWSDTB">
                                            <thead>
                                                <tr>
                                                    <td style={{ width: '183px' }}>Whether Cent Govt Employ</td>
                                                    <td>
                                                        <select
                                                            name="whether_gov_emp"
                                                            className="custom-select slecttextfs"
                                                            value={values.whether_gov_emp}
                                                            onChange={(e) => {
                                                                setFieldValue('whether_gov_emp', parseInt(e.target.value));
                                                                calculateExemptAmt('whether_gov_emp', e.target.value, values, setFieldValue);
                                                            }}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </td>
                                                    <td style={{ width: '147px' }}>Whether Gratuity Rec</td>
                                                    <td>
                                                        <select
                                                            name="whether_gratuity_rec"
                                                            className="custom-select slecttextfs"
                                                            value={values.whether_gratuity_rec}
                                                            onChange={(e) => {
                                                                setFieldValue('whether_gratuity_rec', parseInt(e.target.value));
                                                                calculateExemptAmt('whether_gratuity_rec', e.target.value, values, setFieldValue);
                                                            }}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Commuted Pension Amount</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.commuted_pension_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="gratuity_received_amtp"
                                                            value={values.commuted_pension_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('commuted_pension_amtp', e.target.value);
                                                                calculateExemptAmt('commuted_pension_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>Commuted Pension %</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.commuted_pension_percent_b100 ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="commuted_pension_percent_b100"
                                                            value={values.commuted_pension_percent_b100}
                                                            onChange={(e) => {
                                                                if (e.target.value === '' || parseFloat(e.target.value) <= 100) {
                                                                    setFieldValue('commuted_pension_percent_b100', e.target.value);
                                                                    calculateExemptAmt('commuted_pension_percent_b100', e.target.value, values, setFieldValue);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Exempt Amt</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0.00"
                                                            name="exempt_amtp"
                                                            value={values.exempt_amtp}
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="btn-group mt-2 mb-1">
                                    <button type="submit" className="btn btn-default monthwsdetilssavebtn">Save & Exit</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
        );
    }
    else {
        return false;
    }
};

export default CommutedPensionForm;